const cartridges = [
  {
    "name": "224 Valkyrie",
    "caliber": 0.224,
    "velocities": {
      "60": 3300,
      "75": 3000,
      "90": 2700,
    },
    "length": 24
  },
  {
    "name": "277 Sig Fury",
    "caliber": 0.277,
    "velocities": {
      "135": 3000,
    },
    "length": 16
  },
  {
    "name": "6mm ARC",
    "caliber": 0.243,
    "velocities": {
      "108": 2700,
    },
    "length": 24
  },
  {
    "name": "6mm Creedmoor",
    "caliber": 0.243,
    "velocities": {
      "108": 2950,
    },
    "length": 24
  },
  {
    "name": "6mm GT",
    "caliber": 0.243,
    "velocities": {
      "109": 2910,
    },
    "length": 24
  },
  {
    "name": "6.5-284 Norma",
    "caliber": 0.264,
    "velocities": {
      "130": 2900,
    },
    "length": 24
  },
  {
    "name": "6.5-300 Weatherby Magnum",
    "caliber": 0.264,
    "velocities": {
      "127": 3400,
    },
    "length": 24
  },
  {
    "name": "6.5 PRC",
    "caliber": 0.264,
    "velocities": {
      "147": 2900,
    },
    "length": 24
  },
  {
    "name": "6.5 Weatherby Rebated Precision Magnum",
    "caliber": 0.264,
    "velocities": {
      "140": 3075,
    },
    "length": 24
  },
  {
    "name": "7mm PRC",
    "caliber": 0.284,
    "velocities": {
      "180": 2950,
    },
    "length": 24
  },
  {
    "name": "300 PRC",
    "caliber": 0.308,
    "velocities": {
      "225": 2800,
    },
    "length": 24
  },
  {
    "name": "300 HAMR",
    "caliber": 0.308,
    "velocities": {
      "125": 2450,
      "130": 2425,
    },
    "length": 16
  },
  {
    "name": "300 Norma Magnum",
    "caliber": 0.308,
    "velocities": {
      "230": 2890,
    },
    "length": 24
  },
  {
    "name": "338 Norma Magnum",
    "caliber": 0.338,
    "velocities": {
      "300": 2670,
    },
    "length": 24
  },
  {
    "name": "338 Weatherby Rebated Precision Magnum",
    "caliber": 0.338,
    "velocities": {
      "225": 2800,
    },
    "length": 24
  },
  {
    "name": "350 Legend",
    "caliber": 0.357,
    "velocities": {
      "145": 2250,
    },
    "length": 16
  },
  {
    "name": "6 x 45mm",
    "caliber": 0.243,
    "velocities": {
      "80": 2500
    },
    "length": 24
  },
  {
    "name": "6mm Remington",
    "caliber": 0.243,
    "velocities": {
      "75": 3400,
      "80": 3400,
      "90": 3175,
      "95": 3225,
      "100": 3090
    },
    "length": 24
  },
  {
    "name": "6.5 Creedmoor",
    "caliber": 0.264,
    "velocities": {
      "120": 2900,
      "129": 2940,
      "140": 2690
    },
    "length": 24
  },
  {
    "name": "6.5 Grendel",
    "caliber": 0.264,
    "velocities": {
      "123": 2580
    },
    "length": 24
  },
  {
    "name": "6.5x55 Swedish",
    "caliber": 0.264,
    "velocities": {
      "129": 2750,
      "140": 2550
    },
    "length": 24
  },
  {
    "name": "6.8mm Remington SPC",
    "caliber": 0.277,
    "velocities": {
      "115": 2610
    },
    "length": 24
  },
  {
    "name": "7mm Mauser (7x57)",
    "caliber": 0.284,
    "velocities": {
      "139": 2650,
      "145": 2680,
      "154": 2600,
      "160": 2500,
      "175": 2420
    },
    "length": 24
  },
  {
    "name": "7mm Remington Magnum",
    "caliber": 0.284,
    "velocities": {
      "125": 3290,
      "139": 3150,
      "140": 2700,
      "150": 2990,
      "154": 3035,
      "160": 2940,
      "175": 2850
    },
    "length": 24
  },
  {
    "name": "7mm Remington Short Action Ultra Magnum",
    "caliber": 0.284,
    "velocities": {
      "140": 3165,
      "150": 3095,
      "160": 2950
    },
    "length": 24
  },
  {
    "name": "7mm Remington Ultra Magnum",
    "caliber": 0.284,
    "velocities": {
      "140": 3415,
      "150": 3315,
      "160": 3190,
      "175": 3015
    },
    "length": 24
  },
  {
    "name": "7mm Shooting Times Westerner",
    "caliber": 0.284,
    "velocities": {
      "140": 3310,
      "150": 3280,
      "160": 3250
    },
    "length": 24
  },
  {
    "name": "7mm Weatherby Magnum",
    "caliber": 0.284,
    "velocities": {
      "140": 3165,
      "175": 2840
    },
    "length": 24
  },
  {
    "name": "7mm Winchester Short Magnum",
    "caliber": 0.284,
    "velocities": {
      "140": 3225,
      "150": 3200,
      "160": 2975
    },
    "length": 24
  },
  {
    "name": "7mm-08 Remington",
    "caliber": 0.284,
    "velocities": {
      "120": 2990,
      "139": 2980,
      "140": 2845
    },
    "length": 24
  },
  {
    "name": "7mm Blackburn",
    "caliber": 0.284,
    "velocities": {
      "195": 2450
    },
    "length": 21
  },
  {
    "name": "7x64 Brenneke",
    "caliber": 0.284,
    "velocities": {
      "140": 2950,
      "160": 2600
    },
    "length": 24
  },
  {
    "name": "7-30 Waters",
    "caliber": 0.284,
    "velocities": {
      "120": 2700
    },
    "length": 24
  },
  {
    "name": "7.62x39",
    "caliber": 0.308,
    "velocities": {
      "123": 2350
    },
    "length": 20
  },
  {
    "name": "8mm Mauser (8x57)",
    "caliber": 0.323,
    "velocities": {
      "170": 2340
    },
    "length": 24
  },
  {
    "name": "8mm Remington Magnum",
    "caliber": 0.323,
    "velocities": {
      "185": 3065,
      "200": 2900,
      "220": 2815
    },
    "length": 24
  },
  {
    "name": "9.3 x 62",
    "caliber": 0.365,
    "velocities": {
      "286": 2360
    },
    "length": 24
  },
  {
    "name": "17 Hornet",
    "caliber": 0.172,
    "velocities": {
      "15": 3860,
      "20": 3625
    },
    "length": 24
  },
  {
    "name": "17 Remington",
    "caliber": 0.172,
    "velocities": {
      "20": 4250,
      "25": 4000
    },
    "length": 24
  },
  {
    "name": "17 Remington Fireball",
    "caliber": 0.172,
    "velocities": {
      "20": 3970,
      "25": 3850
    },
    "length": 24
  },
  {
    "name": "204 Ruger",
    "caliber": 0.204,
    "velocities": {
      "32": 4075,
      "34": 3990,
      "40": 3775,
      "45": 3500
    },
    "length": 24
  },
  {
    "name": "218 Bee",
    "caliber": 0.224,
    "velocities": {
      "46": 2725
    },
    "length": 24
  },
  {
    "name": "22 Hornet",
    "caliber": 0.224,
    "velocities": {
      "33": 3040,
      "34": 2985,
      "35": 3040,
      "45": 2655
    },
    "length": 24
  },
  {
    "name": "22-250 Remington",
    "caliber": 0.224,
    "velocities": {
      "35": 4350,
      "40": 3975,
      "45": 4000,
      "50": 3800,
      "52": 3740,
      "53": 3650,
      "60": 3600
    },
    "length": 24
  },
  {
    "name": "220 Swift",
    "caliber": 0.224,
    "velocities": {
      "40": 4040,
      "50": 3840,
      "55": 3650,
      "60": 3600
    },
    "length": 24
  },
  {
    "name": "221 Remington Fireball",
    "caliber": 0.224,
    "velocities": {
      "50": 2975
    },
    "length": 24
  },
  {
    "name": "222 Remington",
    "caliber": 0.224,
    "velocities": {
      "35": 3760,
      "40": 3370,
      "50": 3110,
      "55": 3000
    },
    "length": 24
  },
  {
    "name": "222 Remington Magnum",
    "caliber": 0.224,
    "velocities": {
      "55": 3215
    },
    "length": 24
  },
  {
    "name": "223 Remington",
    "caliber": 0.224,
    "velocities": {
      "35": 3800,
      "40": 3650,
      "42": 3450,
      "45": 3550,
      "50": 3410,
      "53": 3305,
      "55": 3050,
      "60": 3080,
      "62": 3000,
      "64": 3000,
      "69": 2985,
      "75": 2775,
      "77": 2670
    },
    "length": 24
  },
  {
    "name": "223 Winchester Super Short Magnum",
    "caliber": 0.224,
    "velocities": {
      "55": 3850,
      "64": 3600
    },
    "length": 24
  },
  {
    "name": "225 Winchester",
    "caliber": 0.224,
    "velocities": {
      "55": 3540
    },
    "length": 24
  },
  {
    "name": "243 Winchester",
    "caliber": 0.243,
    "velocities": {
      "55": 3880,
      "58": 3730,
      "75": 3325,
      "80": 3325,
      "85": 3300,
      "90": 3110,
      "95": 3050,
      "100": 2950
    },
    "length": 24
  },
  {
    "name": "243 Winchester Super Short Magnum",
    "caliber": 0.243,
    "velocities": {
      "55": 4125,
      "95": 3250,
      "100": 3110
    },
    "length": 24
  },
  {
    "name": "25 Winchester Super Short Magnum",
    "caliber": 0.257,
    "velocities": {
      "85": 3470,
      "110": 3090,
      "115": 3060,
      "120": 2990
    },
    "length": 24
  },
  {
    "name": "25-06 Remington",
    "caliber": 0.257,
    "velocities": {
      "85": 3510,
      "87": 3420,
      "90": 3420,
      "100": 3210,
      "115": 3100,
      "117": 2975,
      "120": 2975
    },
    "length": 24
  },
  {
    "name": "25-20 Winchester",
    "caliber": 0.257,
    "velocities": {
      "86": 1445
    },
    "length": 24
  },
  {
    "name": "25-35 Winchester",
    "caliber": 0.257,
    "velocities": {
      "117": 2210
    },
    "length": 24
  },
  {
    "name": "250 Savage",
    "caliber": 0.257,
    "velocities": {
      "87": 3010,
      "100": 2800
    },
    "length": 24
  },
  {
    "name": "257 Roberts",
    "caliber": 0.257,
    "velocities": {
      "87": 3150,
      "100": 2880,
      "117": 2630
    },
    "length": 24
  },
  {
    "name": "257 Roberts +P",
    "caliber": 0.257,
    "velocities": {
      "100": 2980,
      "117": 2760
    },
    "length": 24
  },
  {
    "name": "257 Weatherby Magnum",
    "caliber": 0.257,
    "velocities": {
      "87": 3845,
      "100": 3580,
      "120": 3335
    },
    "length": 24
  },
  {
    "name": "26 Nosler",
    "caliber": 0.264,
    "velocities": {
      "129": 3400
    },
    "length": 24
  },
  {
    "name": "260 Remington",
    "caliber": 0.264,
    "velocities": {
      "120": 2880,
      "140": 2725
    },
    "length": 24
  },
  {
    "name": "264 Winchester Magnum",
    "caliber": 0.264,
    "velocities": {
      "100": 3300,
      "140": 3015
    },
    "length": 24
  },
  {
    "name": "27 Nosler",
    "caliber": 0.277,
    "velocities": {
      "150": 3200
    },
    "length": 24
  },
  {
    "name": "270 Weatherby Magnum",
    "caliber": 0.277,
    "velocities": {
      "100": 3745,
      "130": 3350,
      "150": 3230
    },
    "length": 24
  },
  {
    "name": "270 Winchester",
    "caliber": 0.277,
    "velocities": {
      "100": 3300,
      "115": 2695,
      "130": 3050,
      "135": 2990,
      "140": 2950,
      "150": 2830,
      "160": 2650
    },
    "length": 24
  },
  {
    "name": "270 Winchester Short Magnum",
    "caliber": 0.277,
    "velocities": {
      "130": 3275,
      "140": 3125,
      "150": 3150
    },
    "length": 24
  },
  {
    "name": "28 Nosler",
    "caliber": 0.284,
    "velocities": {
      "168": 3125
    },
    "length": 24
  },
  {
    "name": "280 Ackley Improved",
    "caliber": 0.284,
    "velocities": {
      "140": 3260
    },
    "length": 24
  },
  {
    "name": "280 Remington",
    "caliber": 0.284,
    "velocities": {
      "120": 3135,
      "139": 3100,
      "140": 2985,
      "150": 2875,
      "165": 2800
    },
    "length": 24
  },
  {
    "name": "284 Winchester",
    "caliber": 0.284,
    "velocities": {
      "125": 3125,
      "150": 2845
    },
    "length": 24
  },
  {
    "name": "30 Carbine",
    "caliber": 0.308,
    "velocities": {
      "110": 1965
    },
    "length": 20
  },
  {
    "name": "30 Nosler",
    "caliber": 0.308,
    "velocities": {
      "210": 2950
    },
    "length": 24
  },
  {
    "name": "30 Remington AR",
    "caliber": 0.308,
    "velocities": {
      "125": 2775
    },
    "length": 24
  },
  {
    "name": "30 Thompson Center",
    "caliber": 0.308,
    "velocities": {
      "150": 2985,
      "165": 2835
    },
    "length": 24
  },
  {
    "name": "30-06 Springfield",
    "caliber": 0.308,
    "velocities": {
      "55": 4050,
      "110": 3300,
      "125": 2645,
      "150": 2900,
      "165": 2790,
      "180": 2690,
      "200": 2540,
      "220": 2400
    },
    "length": 24
  },
  {
    "name": "30-30 Winchester",
    "caliber": 0.308,
    "velocities": {
      "55": 3365,
      "125": 2155,
      "140": 2500,
      "150": 2370,
      "160": 2285,
      "170": 2180
    },
    "length": 24
  },
  {
    "name": "30-40 Krag",
    "caliber": 0.308,
    "velocities": {
      "180": 2420,
      "220": 2150
    },
    "length": 24
  },
  {
    "name": "300 AAC Blackout",
    "caliber": 0.308,
    "velocities": {
      "90": 2500,
      "115": 2270,
      "120": 2100,
      "123": 2280,
      //"220": 1020
    },
    "length": 16
  },
  {
    "name": "300 Holland & Holland Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 3110,
      "180": 2870,
      "220": 2565
    },
    "length": 24
  },
  {
    "name": "300 Remington Short Action Ultra Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 3190,
      "165": 3065,
      "180": 2950
    },
    "length": 24
  },
  {
    "name": "300 Remington Ultra Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 2910,
      "165": 3200,
      "180": 2960,
      "200": 3025
    },
    "length": 24
  },
  {
    "name": "300 Ruger Compact Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 3280,
      "165": 3120,
      "180": 2980
    },
    "length": 24
  },
  {
    "name": "300 Savage",
    "caliber": 0.308,
    "velocities": {
      "150": 2615,
      "180": 2340
    },
    "length": 24
  },
  {
    "name": "300 Weatherby Magnum",
    "caliber": 0.308,
    "velocities": {
      "165": 3140,
      "180": 3125,
      "190": 2955,
      "220": 2775
    },
    "length": 24
  },
  {
    "name": "300 Winchester Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 2635,
      "165": 3120,
      "180": 2950,
      "190": 2875,
      "200": 2800,
      "220": 2665
    },
    "length": 24
  },
  {
    "name": "300 Winchester Short Magnum",
    "caliber": 0.308,
    "velocities": {
      "150": 3300,
      "165": 3120,
      "180": 2970
    },
    "length": 24
  },
  {
    "name": "303 British",
    "caliber": 0.308,
    "velocities": {
      "150": 2685,
      "174": 2500,
      "180": 2450,
      "215": 2155
    },
    "length": 24
  },
  {
    "name": "307 Winchester",
    "caliber": 0.308,
    "velocities": {
      "150": 2705,
      "180": 2450
    },
    "length": 24
  },
  {
    "name": "308 Marlin Express",
    "caliber": 0.308,
    "velocities": {
      "140": 2800,
      "160": 2645
    },
    "length": 24
  },
  {
    "name": "308 Winchester",
    "caliber": 0.308,
    "velocities": {
      "55": 3750,
      "110": 3150,
      "120": 3100,
      "125": 2645,
      "130": 2790,
      "150": 2800,
      "165": 2670,
      "175": 2600,
      "180": 2600,
      "200": 2440
    },
    "length": 24
  },
  {
    "name": "32 Winchester Special",
    "caliber": 0.32,
    "velocities": {
      "165": 2410,
      "170": 2235
    },
    "length": 24
  },
  {
    "name": "32-20 Winchester",
    "caliber": 0.32,
    "velocities": {
      "100": 1200
    },
    "length": 24
  },
  {
    "name": "325 Winchester Short Magnum",
    "caliber": 0.325,
    "velocities": {
      "180": 3050,
      "200": 2940,
      "220": 2830
    },
    "length": 24
  },
  {
    "name": "33 Nosler",
    "caliber": 0.338,
    "velocities": {
      "250": 2850
    },
    "length": 24
  },
  {
    "name": "338 Federal",
    "caliber": 0.338,
    "velocities": {
      "180": 2830,
      "185": 2750,
      "210": 2630
    },
    "length": 24
  },
  {
    "name": "338 Lapua Magnum",
    "caliber": 0.338,
    "velocities": {
      "250": 2950,
      "280": 2600,
      "285": 2745,
      "300": 2620
    },
    "length": 24
  },
  {
    "name": "338 Marlin Express",
    "caliber": 0.338,
    "velocities": {
      "200": 2550
    },
    "length": 24
  },
  {
    "name": "338 Remington Ultra Magnum",
    "caliber": 0.338,
    "velocities": {
      "250": 2850
    },
    "length": 24
  },
  {
    "name": "338 Ruger Compact Magnum",
    "caliber": 0.338,
    "velocities": {
      "185": 2970,
      "200": 2940,
      "225": 2760
    },
    "length": 24
  },
  {
    "name": "338 Winchester Magnum",
    "caliber": 0.338,
    "velocities": {
      "185": 3075,
      "200": 2940,
      "210": 2855,
      "225": 2770,
      "230": 2750,
      "250": 2645,
      "300": 2415
    },
    "length": 24
  },
  {
    "name": "340 Weatherby Magnum",
    "caliber": 0.338,
    "velocities": {
      "200": 3210,
      "210": 3200,
      "225": 2860,
      "250": 2950
    },
    "length": 24
  },
  {
    "name": "348 Winchester",
    "caliber": 0.348,
    "velocities": {
      "200": 2505
    },
    "length": 24
  },
  {
    "name": "35 Nosler",
    "caliber": 0.357,
    "velocities": {
      "225": 3000
    },
    "length": 24
  },
  {
    "name": "35 Remington",
    "caliber": 0.357,
    "velocities": {
      "150": 2275,
      "200": 2055
    },
    "length": 24
  },
  {
    "name": "35 Whelen",
    "caliber": 0.357,
    "velocities": {
      "180": 2900,
      "200": 2660,
      "250": 2385
    },
    "length": 24
  },
  {
    "name": "350 Remington Magnum",
    "caliber": 0.357,
    "velocities": {
      "200": 2690,
      "225": 2635
    },
    "length": 20
  },
  {
    "name": "356 Winchester",
    "caliber": 0.357,
    "velocities": {
      "200": 2370,
      "250": 2075
    },
    "length": 24
  },
  {
    "name": "358 Winchester",
    "caliber": 0.357,
    "velocities": {
      "200": 2475,
      "250": 2215
    },
    "length": 24
  },
  {
    "name": "36 Nosler",
    "caliber": 0.365,
    "velocities": {
      "250": 2925
    },
    "length": 24
  },
  {
    "name": "370 Sako Magnum",
    "caliber": 0.375,
    "velocities": {
      "286": 2550
    },
    "length": 24
  },
  {
    "name": "375 Holland & Holland Magnum",
    "caliber": 0.375,
    "velocities": {
      "250": 2670,
      "270": 2680,
      "300": 2515
    },
    "length": 24
  },
  {
    "name": "375 Remington Ultra Magnum",
    "caliber": 0.375,
    "velocities": {
      "270": 2750
    },
    "length": 24
  },
  {
    "name": "375 Ruger",
    "caliber": 0.375,
    "velocities": {
      "250": 2890,
      "270": 2840,
      "300": 2660
    },
    "length": 24
  },
  {
    "name": "375 Enabler",
    "caliber": 0.375,
    "velocities": {
      "379": 2900,
      "407": 2800
    },
    "minBarrelDiameter": 1.35,
    "length": 30
  },
  {
    "name": "375 CheyTac",
    "caliber": 0.375,
    "velocities": {
      "350": 3300
    },
    "minBarrelDiameter": 1.35,
    "length": 36
  },
  {
    "name": "408 CheyTac",
    "caliber": 0.408,
    "velocities": {
      "419": 2850
    },
    "minBarrelDiameter": 1.35,
    "length": 24
  },
  {
    "name": "375 Lethal Magnum",
    "caliber": 0.375,
    "velocities": {
      "400": 3290
    },
    "minBarrelDiameter": 1.35,
    "length": 24
  },
  {
    "name": "50 BMG",
    "caliber": 0.510,
    "velocities": {
      "400": 3290
    },
    "minBarrelDiameter": 1.7,
    "length": 24
  },
  {
    "name": "375 Winchester",
    "caliber": 0.375,
    "velocities": {
      "200": 2180,
      "250": 1885
    },
    "length": 24
  },
  {
    "name": "376 Steyr",
    "caliber": 0.375,
    "velocities": {
      "270": 2580
    },
    "length": 24
  },
  {
    "name": "38-40 Winchester",
    "caliber": 0.4,
    "velocities": {
      "180": 1150
    },
    "length": 24
  },
  {
    "name": "38-55 Winchester",
    "caliber": 0.379,
    "velocities": {
      "255": 1300
    },
    "length": 24
  },
  {
    "name": "405 Winchester",
    "caliber": 0.405,
    "velocities": {
      "300": 2200
    },
    "length": 24
  },
  {
    "name": "416 Remington Magnum",
    "caliber": 0.416,
    "velocities": {
      "350": 2525,
      "400": 2400
    },
    "length": 24
  },
  {
    "name": "416 Rigby",
    "caliber": 0.416,
    "velocities": {
      "400": 2370
    },
    "length": 24
  },
  {
    "name": "416 Ruger",
    "caliber": 0.416,
    "velocities": {
      "400": 2410
    },
    "length": 24
  },
  {
    "name": "416 Weatherby Magnum",
    "caliber": 0.416,
    "velocities": {
      "400": 2655
    },
    "length": 24
  },
  {
    "name": "416 Barrett",
    "caliber": 0.416,
    "velocities": {
      "500": 3100
    },
    "minBarrelDiameter": 2,
    "length": 40
  },
  {
    "name": "44 Remington Magnum",
    "caliber": 0.43,
    "velocities": {
      "210": 1900,
      "240": 1740,
      "275": 1565
    },
    "length": 20
  },
  {
    "name": "44-40 Winchester",
    "caliber": 0.43,
    "velocities": {
      "200": 1175,
      "225": 1000
    },
    "length": 24
  },
  {
    "name": "444 Marlin",
    "caliber": 0.43,
    "velocities": {
      "240": 2320,
      "265": 2100
    },
    "length": 24
  },
  {
    "name": "45-70 Government",
    "caliber": 0.45,
    "velocities": {
      "250": 2025,
      "300": 1830,
      "325": 2030,
      "375": 1490,
      "405": 1150
    },
    "length": 24
  },
  {
    "name": "450 Bushmaster",
    "caliber": 0.45,
    "velocities": {
      "250": 2250,
      "260": 2150
    },
    "length": 24
  },
  {
    "name": "450 Marlin",
    "caliber": 0.45,
    "velocities": {
      "325": 2175,
      "350": 2125
    },
    "length": 24
  },
  {
    "name": "457 Wild West Guns",
    "caliber": 0.45,
    "velocities": {
      "435": 1950
    },
    "length": 24
  },
  {
    "name": "458 Lott",
    "caliber": 0.45,
    "velocities": {
      "465": 2380,
      "500": 2150
    },
    "length": 24
  },
  {
    "name": "458 Winchester Magnum",
    "caliber": 0.45,
    "velocities": {
      "450": 2220,
      "500": 2025,
      "510": 2025
    },
    "length": 24
  },
  {
    "name": "470 Nitro Express",
    "caliber": 0.47,
    "velocities": {
      "500": 2150
    },
    "length": 24
  },
  {
    "name": "475 Turnbull",
    "caliber": 0.475,
    "velocities": {
      "500": 1900
    },
    "length": 24
  }
]

export default cartridges
