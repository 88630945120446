import calcBarrelWeight from './barrel-weight'
import imbalanceByMfg from './imbalance'
import LRU from 'lru-cache'
const trajectory = require('./trajectory/trajectory').default
const cache = new LRU({ max: 1000 })

const iterations = 1000

export default function predictAccuracy(options) {
  let {
    cartridge,
    contour,
    barrelLength,
    bullet,
    stockWeight,
    actionWeight,
    scopeWeight,
    range,
    rangeSd,
    mvSd,
    windSd,
    maxRifleWeight,
    minMuzzleEnergy,
    maxMuzzleVelocity,
    minTargetVelocity,
    minHitProbability,
    minEnergy,
    targetSize,
    saveImpacts
  } = options
  if (!cartridge) return { error: 'no cartridge' }
  if (!bullet) return { error: 'no bullet' }
  if (!contour) return { error: 'no contour' }
  if (cartridge.caliber != bullet.caliber) return { error: 'cartridge caliber and bullet caliber must be the same' }
  if (barrelLength > contour.length) return { error: 'barrel too long for contour' }
  if (cartridge.minBarrelDiameter && contour.breechDiameter < cartridge.minBarrelDiameter) return { error: 'barrel shank too small for cartridge' }
  if (!cartridge.minBarrelDiameter && contour.breechDiameter > 1.25) return { error: 'barrel too large for cartridge' }
  const mvChangePerInch = 20
  const velocityDelta = (barrelLength - cartridge.length) * mvChangePerInch
  const v0 = estimateVelocity(bullet, cartridge) + velocityDelta
  if (v0 > maxMuzzleVelocity) {
    return { error: 'max muzzle velocity exceeded' }
  }
  const barrelWeight = calcBarrelWeight(contour, cartridge.caliber, barrelLength)
  const rifleWeight = +barrelWeight + +stockWeight + +actionWeight + +scopeWeight
  if (rifleWeight > +maxRifleWeight) {
    return { error: 'max weight exceeded' }
  }
  const energy = (v0 * v0 * bullet.weight) / 450240
  if (energy < minMuzzleEnergy) return { error: 'min muzzle energy not met' }
  const ratio = (energy / rifleWeight)
  let precision = .005 * ratio + .039
  const bc = parseFloat(bullet.G7)
  range = parseInt(range)
  const trajectoryOptions = {
    bc,
    bulletDiameter: parseFloat(bullet.caliber),
    bulletWeight: parseInt(bullet.weight),
    range,
    twistRate: parseFloat(bullet.twist),
    v0,
    wind: -10,
    windDir: 90
  }
  const cacheKey = Object.values(trajectoryOptions).join('-')
  let t = cache.get(cacheKey)
  if (!t) {
    t = trajectory(trajectoryOptions)
    cache.set(cacheKey, t)
  }
  let bulletImbalance
  for (const mfg in imbalanceByMfg) {
    if (bullet.name.includes(mfg)) {
      bulletImbalance = imbalanceByMfg[mfg]
    }
  }
  const twistDispersion = (24 * Math.PI * v0 * t[100].time * bulletImbalance) / bullet.twist
  let targetEnergy = t[range].energy
  if (targetEnergy < minEnergy) {
    return { error: 'min target energy not met' }
  }
  let targetVelocity = t[range].velocity
  if (targetVelocity < minTargetVelocity) {
    return { error: 'min target velocity not met' }
  }
  precision += twistDispersion
  const sectionalDensity = bullet.weight / 7000 / Math.pow(bullet.caliber, 2);
  const formFactor = sectionalDensity / bc
  const bcVariation = Math.max(-.0691 * formFactor + .077, 0)
  const bc2 = bc * (1 + bcVariation)
  const v02 = v0 + +mvSd
  const range2 = range - parseInt(rangeSd)
  const options2 = {
    ...trajectoryOptions,
    range: range2,
    bc: bc2,
    v0: v02,
    wind: -10 - windSd,
  }
  const cacheKey2 = Object.values(options2).join('-')
  let t2 = cache.get(cacheKey2)
  if (!t2) {
    t2 = trajectory(options2)
    cache.set(cacheKey2, t2)
  }
  const [ probability, avgDistToCenter, impacts, probability2, avgDistToCenter2, impacts2 ] = hitProbability(t, t2, targetSize, range, range2, precision, saveImpacts)
  if (probability < minHitProbability) return { error: 'min hit probability not met' }
  return {
    caliber: bullet.caliber,
    bulletName: bullet.name.split('cal')[1],
    cartridgeName: cartridge.name,
    v0,
    bcVariation: (bcVariation * 100).toFixed(1) + '%',
    bc,
    //formFactor,
    contourName: contour.name,
    barrelLength,
    ratio,
    precision,
    probability,
    avgDistToCenter,
    impacts,
    probability2,
    //avgDistToCenter2,
    impacts2,
    energy,
    targetEnergy,
    targetVelocity,
    twist: bullet.twist,
    twistDispersion,
    rifleWeight,
    barrelWeight,
    bulletImbalance,
    dropMOA: t[range].dropMOA,
    //windMOA: t[range].windMOA,
  }
}

function hitProbability(t1, t2, targetSize, range, range2, precision, saveImpacts = false) {
  const impacts = []
  const impacts2 = []
  const dropSd = Math.abs(t1[range].drop - t2[range2].drop)
  const windSd = Math.abs(t1[range].wind - t2[range2].wind)
  let hits = 0
  let dists = 0
  let hits2 = 0
  let dists2 = 0
  const groupError = precision * range * 1.047 / 100
  for (let i = 0; i < iterations; ++i) {
    const errorWind = Math.random() * groupError - groupError / 2
    const errorDrop = Math.random() * groupError - groupError / 2
    const drop = randsd(0, dropSd) + errorDrop
    const wind = randsd(0, windSd) + errorWind
    const dist = Math.sqrt(drop * drop + wind * wind)
    dists += dist
    if (dist < targetSize / 2) ++hits
    if (saveImpacts) {
      impacts.push([ drop, wind ])
    }
    const errorWind2 = Math.random() * groupError - groupError / 2
    const errorDrop2 = Math.random() * groupError - groupError / 2
    const drop2 = randsd(0, dropSd) + errorDrop + errorDrop2
    const wind2 = errorWind2 + errorWind
    const dist2 = Math.sqrt(drop2 * drop2 + wind2 * wind2)
    dists2 += dist2
    if (dist2 < targetSize / 2) ++hits2
    if (saveImpacts) {
      impacts2.push([ drop2, wind2 ])
    }
  }
  return [ 100 * (hits / iterations), dists / iterations, impacts, 100 * (hits2 / iterations), dists2 / iterations, impacts2 ]
}

function estimateVelocity(bullet, cartridge) {
  let v
  Object.keys(cartridge.velocities).forEach(velocityWeight => {
    if (velocityWeight == bullet.weight) v = cartridge.velocities[velocityWeight]
  })
  if (v) return parseInt(v)
  let bestDiff = Math.min()
  let bestWeight
  let bestVelocity
  Object.keys(cartridge.velocities).forEach(velocityWeight => {
    const diff = bullet.weight - velocityWeight
    if (diff < bestDiff) {
      bestDiff = diff
      bestWeight = velocityWeight
      bestVelocity = cartridge.velocities[velocityWeight]
    }
  })
  const velocity = Math.round(Math.sqrt(
    (bestWeight * bestVelocity * bestVelocity) / bullet.weight
  ))
  return velocity
}

function randsd(avg, sd) {
  return randn_bm(+avg - sd * 3, +avg + sd * 3)
}

function randn_bm(min, max, skew = 1) {
  let u = 0, v = 0;
  while(u === 0) u = Math.random() //Converting [0,1) to (0,1)
  while(v === 0) v = Math.random()
  let num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v )

  num = num / 10.0 + 0.5 // Translate to 0 -> 1
  if (num > 1 || num < 0) {
    num = randn_bm(min, max, skew) // resample between 0 and 1 if out of range
  } else {
    num = Math.pow(num, skew) // Skew
    num *= max - min // Stretch to fill range
    num += min // offset to min
  }
  return num
}
