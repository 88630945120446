import { useState, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import bullets from './predict/bullets'
import cartridges from './predict/cartridges'
import contours from './predict/contours'
import predictAccuracy from './predict/predict-accuracy'
import './App.css'

const cartridgeCalibers = []
const bulletCalibers = []

bullets.sort((a, b) => {
  if (a.caliber === b.cartridges) {
    return a.name.localeCompare(b.name)
  } else {
    return a.caliber - b.caliber
  }
})
cartridges.sort((a, b) => {
  //const heaviestA = Object.keys(a.velocities).pop()
  //const heaviestB = Object.keys(b.velocities).pop()
  //const energyA = (a.velocities[heaviestA] * a.velocities[heaviestA] * heaviestA) / 450240
  //const energyB = (b.velocities[heaviestB] * b.velocities[heaviestB] * heaviestB) / 450240
  //return energyB - energyA
  if (a.caliber === b.cartridges) {
    return a.name.localeCompare(b.name)
  } else {
    return a.caliber - b.caliber
  }
})
bullets.forEach((bullet, i) => {
  bullet.label = bullet.name
  bullet.id = i
})
cartridges.forEach((cartridge, i) => {
  cartridge.label = cartridge.name
  cartridge.id = i
})
contours.forEach((contour, i) => {
  contour.label = contour.name
  contour.id = i
})

cartridges.forEach((cartridge, i) => {
  const cal = cartridge.caliber.toFixed(3)
  if (!cartridgeCalibers.includes(cal)) {
    cartridgeCalibers.push(cal)
  }
})
bullets.forEach((bullet, i) => {
  const cal = bullet.caliber.toFixed(3)
  if (!bulletCalibers.includes(cal)) {
    bulletCalibers.push(cal)
  }
})
const calibers = bulletCalibers.filter(caliber => {
  return cartridgeCalibers.includes(caliber)
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

const presets = {
  prs: {
    range: 600,
    targetSize: 12,
    stockWeight: 5.5,
    actionWeight: 2.2,
    scopeWeight: 2.5,
    mvSd: 5,
    windSd: 2,
    barrelLength: 24,
    maxRifleWeight: 30,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    maxMuzzleVelocity: 3200,
    minHitProbability: 90,
    hunting: false
  },
  hunting: {
    range: 500,
    targetSize: 10,
    stockWeight: 3,
    actionWeight: 2,
    scopeWeight: .83,
    mvSd: 10,
    windSd: 3,
    barrelLength: 24,
    maxRifleWeight: 9,
    minEnergy: 1000,
    minMuzzleEnergy: 1000,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    minHitProbability: 90,
    hunting: true
  },
  ultralight: {
    range: 500,
    targetSize: 18,
    stockWeight: 1.25,
    actionWeight: 1.78,
    scopeWeight: .83,
    mvSd: 10,
    windSd: 3,
    barrelLength: 24,
    maxRifleWeight: 7,
    minEnergy: 1500,
    minMuzzleEnergy: 1500,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    minHitProbability: 90,
    hunting: true
  },
  varmint: {
    range: 300,
    targetSize: 3,
    stockWeight: 2,
    actionWeight: 1.78,
    scopeWeight: 1.5,
    mvSd: 10,
    windSd: 3,
    barrelLength: 24,
    maxRifleWeight: 15,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    minHitProbability: 50,
    hunting: true
  },
  benchrest: {
    range: 100,
    targetSize: 1,
    stockWeight: 2,
    actionWeight: 1.5,
    scopeWeight: 2.25,
    mvSd: 10,
    windSd: 0,
    barrelLength: 20,
    maxRifleWeight: 13.5,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    minHitProbability: 0,
    hunting: false
  },
  oneMile: {
    range: 1760,
    targetSize: 36,
    stockWeight: 5.5,
    actionWeight: 2.2,
    scopeWeight: 2.5,
    mvSd: 5,
    windSd: 3,
    barrelLength: 28,
    maxRifleWeight: 40,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 40,
    minHitProbability: 1,
    hunting: false
  },
  twoMile: {
    range: 3520,
    targetSize: 36,
    stockWeight: 5.5,
    actionWeight: 2.2,
    scopeWeight: 2.5,
    mvSd: 5,
    windSd: 3,
    barrelLength: 28,
    maxRifleWeight: 40,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 40,
    minHitProbability: 1,
    hunting: false
  },
  twoMileHeavy: {
    range: 3520,
    targetSize: 36,
    stockWeight: 12,
    actionWeight: 7.5,
    scopeWeight: 2.5,
    mvSd: 5,
    windSd: 3,
    barrelLength: 28,
    maxRifleWeight: 40,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 40,
    minHitProbability: 1,
    hunting: false
  },
  fClass: {
    range: 1000,
    targetSize: 5,
    stockWeight: 5,
    actionWeight: 2,
    scopeWeight: 2,
    mvSd: 5,
    windSd: 1,
    barrelLength: 28,
    maxRifleWeight: 22,
    minEnergy: 1,
    minMuzzleEnergy: 1,
    minBarrelLength: 16,
    maxBarrelLength: 32,
    minHitProbability: 1,
    hunting: false
  },
}

const envInputs = [
  { label: 'Target Range', unit: 'yd', id: 'range', default: 500, width: 6 },
  { label: 'Range SD', unit: 'yd', id: 'rangeSd', default: 5, width: 6 },
  { label: 'Target Size', unit: 'in', id: 'targetSize', default: 10 },
  { label: 'MV SD', unit: 'in', id: 'mvSd', default: 10, width: 6 },
  { label: 'Wind SD', unit: 'mph', id: 'windSd', default: 3, width: 6 },
]

const gunInputs = [
  { label: 'Stock Weight', unit: 'lb', id: 'stockWeight', default: 4, width: 4 },
  { label: 'Action Weight', unit: 'lb', id: 'actionWeight', default: 2, width: 4 },
  { label: 'Scope Weight', unit: 'lb', id: 'scopeWeight', default: 2.5, width: 4 },
  { label: 'Barrel Length', unit: 'in', id: 'barrelLength', default: 24, width: 10 }
]

const searchInputs = [
  { label: 'Max Rifle Weight', unit: 'lb', id: 'maxRifleWeight', default: 100 },
  { label: 'Min Muzzle Energy', unit: 'ft/lb', id: 'minMuzzleEnergy', default: 0, width: 6 },
  { label: 'Min Target Energy', unit: 'ft/lb', id: 'minEnergy', default: 0, width: 6 },
  { label: 'Max Muzzle Velocity', unit: 'fps', id: 'maxMuzzleVelocity', default: 5000, width: 6 },
  { label: 'Min Target Velocity', unit: 'fps', id: 'minTargetVelocity', default: 1, width: 6 },
  { label: 'Min Barrel Length', unit: 'in', id: 'minBarrelLength', default: 16, width: 6 },
  { label: 'Max Barrel Length', unit: 'in', id: 'maxBarrelLength', default: 32, width: 6 },
  { label: 'Min Hit Probability', unit: '%', id: 'minHitProbability', default: 75 },
]

const outputs = {
  caliber: { label: 'Caliber' },
  barrelLength: { label: 'Barrel Length', precision: 1 },
  cartridgeName: { label: 'Cartridge' },
  bulletName: { label: 'Bullet' },
  contourName: { label: 'Contour' },
  formFactor: { label: 'Form Factor', precision: 3 },
  bc: { label: 'BC', precision: 3 },
  bcVariation: { label: 'BC Variation' },
  twist: { label: 'Twist Rate', precision: 1 },
  ratio: { label: 'Energy/Weight Ratio', precision: 0 },
  energy: { label: 'Muzzle Energy (ft/lb)', precision: 0 },
  targetEnergy: { label: 'Target Energy (ft/lb)', precision: 0 },
  targetVelocity: { label: 'Target Velocity (fps)', precision: 0 },
  precision: { label: 'Rifle Precision (moa)', precision: 2 },
  twistDispersion: { label: 'Twist Dispersion (moa)', precision: 2 },
  rifleWeight: { label: 'Rifle Weight (lb)', precision: 1 },
  barrelWeight: { label: 'Barrel Weight (lb)', precision: 1 },
  v0: { label: 'Muzzle Velocity (fps)', precision: 0 },
  bulletImbalance: { label: 'Bullet Imbalance (in)', precision: 5 },
  dropMOA: { label: 'Bullet Drop (moa)', precision: 1 },
  windMOA: { label: 'Wind Drift (moa)', precision: 1 },
  probability: { label: 'Hit Probability (%)', precision: 1 },
  probability2: { label: '2nd Round Hit Probability (%)', precision: 1 },
  avgDistToCenter: { label: 'Avg Distance to Center (in)', precision: 2 },
  avgDistToCenter2: { label: '2nd Round Avg Dist to Center (in)', precision: 2 },
}

const defaults = { hunting: false, minCaliber: null, maxCaliber: null }
envInputs.forEach(input => defaults[input.id] = input.default)
gunInputs.forEach(input => defaults[input.id] = input.default)
searchInputs.forEach(input => defaults[input.id] = input.default)

function isSolid (bullet) {
  const name = bullet.name.toLowerCase()
  if (
    name.includes('brass') ||
    name.includes('cutting edge') ||
    name.includes('g9') ||
    name.includes('gs') ||
    name.includes('warner') ||
    name.includes('target') ||
    name.includes('match') ||
    name.includes('solid')
  ) {
    return true
  }
}

function* getCombos (minBarrelLength, maxBarrelLength, hunting, minCaliber = null, maxCaliber = null, cartridge = null, bullet = null, contour = null, fixedBarrelLength = null) {
  let count = 0
  for (let i = 0; i < cartridges.length; ++i) {
    if (
      (!cartridge || cartridges[i].name === cartridge.name) &&
      (!minCaliber || cartridges[i].caliber >= minCaliber) &&
      (!maxCaliber || cartridges[i].caliber <= maxCaliber)
    ) {
      for (let j = 0; j < bullets.length; ++j) {
        if (!bullet || bullets[j].name === bullet.name) {
          if (!hunting || bullets[j].hunting) {
            if (cartridges[i].caliber === bullets[j].caliber) {
              for (let barrelLength = minBarrelLength; barrelLength <= maxBarrelLength; barrelLength += 2) {
                if (!fixedBarrelLength || barrelLength == fixedBarrelLength) {
                  for (let k = 0; k < contours.length; ++k) {
                    if (!contour || contours[k].name === contour.name) {
                      ++count
                      yield ({
                        cartridge: cartridges[i],
                        bullet: bullets[j],
                        contour: contours[k],
                        barrelLength
                      })
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  yield ({ count })
}

function App() {
  const [form, setForm] = useState({ ...defaults })
  const [cartridge, setCartridge] = useState(null)
  const [cartridgeInputValue, setCartridgeInputValue] = useState('')
  const [bullet, setBullet] = useState(null)
  const [bulletInputValue, setBulletInputValue] = useState('')
  const [contour, setContour] = useState(null)
  const [contourInputValue, setContourInputValue] = useState('')
  const [minCaliberInputValue, setMinCaliberInputValue] = useState('')
  const [maxCaliberInputValue, setMaxCaliberInputValue] = useState('')
  const [result, setResult] = useState({})
  const [isSearching, setIsSearching] = useState(false)
  const [currentSearch, setCurrentSearch] = useState('')
  const [lockCartridge, setLockCartridge] = useState(false)
  const [lockBullet, setLockBullet] = useState(false)
  const [lockContour, setLockContour] = useState(false)
  const [lockLength, setLockLength] = useState(false)

  const canvasRef = useRef(null)

  useEffect(() => {
    if (!result || !result.impacts) return
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = '#222'
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.fillStyle = '#999'
    const targetPx = 100
    ctx.beginPath()
    ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, targetPx / 2, 0, 2 * Math.PI)
    ctx.fill()
    const drawImpact = ([drop, drift]) => {
      const pxScale = targetPx / form.targetSize
      const gx = drift * pxScale + ctx.canvas.width / 2
      const gy = drop * pxScale + ctx.canvas.height / 2
      ctx.beginPath()
      ctx.arc(gx, gy, 2, 0, 2 * Math.PI)
      ctx.fill()
    }
    ctx.fillStyle = '#f00'
    result.impacts.forEach(drawImpact)
    ctx.fillStyle = '#00f'
    result.impacts2.forEach(drawImpact)
  }, [result])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = '#222'
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  }, [])

  useEffect(() => {
    try {
      const data = predictAccuracy({
        ...form,
        saveImpacts: true,
        bullet,
        cartridge,
        contour
      })
      setResult(data)
      console.log(data)
    } catch (e) {
      console.log(e)
      setResult({ error: e.message })
    }
  }, [ form, cartridge, bullet, contour ])

  const startSearch = () => {
    const c = lockCartridge ? cartridge : null
    const b = lockBullet ? bullet : null
    const co = lockContour ? contour : null
    const l = lockLength ? form.barrelLength : null
    const combos = getCombos(form.minBarrelLength, form.maxBarrelLength, form.hunting, form.minCaliber, form.maxCaliber, c, b, co, l)
    console.log(form)
    setResult({})
    setIsSearching(true)
    setTimeout(() => continueSearch(combos, { avgDistToCenter: Math.min(), avgDistToCenter2: Math.min() }), 10)
  }

  const continueSearch = (combos, best) => {
    const maxi = 100
    for (let i = 0; i <= maxi; ++i) {
      const next = combos.next().value
      console.log(next)
      if (!next || next.count) {
        setIsSearching(false)
        setCurrentSearch([ "Search complete ", `Tried ${next?.count || 0} combinations` ])
        return
      }
      const {
        barrelLength,
        bullet,
        cartridge,
        contour
      } = next
      const data = predictAccuracy({
        ...form,
        saveImpacts: false,
        cartridge,
        bullet,
        contour,
        barrelLength
      })
      if (i === maxi) {
        setCurrentSearch([ cartridge?.name, bullet?.name, barrelLength + 'in ' + contour?.name ])
      }
      if (
        !best.cartridgeName || (
          data &&
          data.avgDistToCenter &&
          data.avgDistToCenter < best.avgDistToCenter
        )
      ) {
        best = data
        setForm({ ...form, barrelLength })
        setCartridge(cartridge)
        setCartridgeInputValue(cartridge.label)
        setBullet(bullet)
        setBulletInputValue(bullet.label)
        setContour(contour)
        setContourInputValue(contour.label)
        setResult(data)
      }
    }
    setTimeout(() => continueSearch(combos, best), 20)
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <Container component="main" maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} mt={2}>
              <Card className="grid-container">
                <CardContent style={{ paddingBottom: 16 }}>
                  <Grid container direction="row" justifyContent="space-evenly">
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Presets:
                      </Typography>
                    </Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.hunting })}>Deer Hunting</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.ultralight })}>Ultralight{'\u00A0'}Elk{'\u00A0'}Hunting</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.varmint })}>Varmint Hunting</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.prs })}>PRS</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.benchrest })}>100{'\u00A0'}yard{'\u00A0'}Benchrest</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.oneMile })}>One{'\u00A0'}Mile</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.twoMile})}>Two{'\u00A0'}Miles</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.twoMileHeavy})}>Two{'\u00A0'}Miles{'\u00A0'}Heavy</Button></Grid>
                    <Grid item><Button variant="outlined" onClick={() => setForm({ ...defaults, ...presets.fClass })}>F-Class</Button></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className="grid-container" sx={{ minHeight: '85vh' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h5" gutterBottom>
                        Step 1: Enter Environment
                      </Typography>
                    </Grid>
                    {envInputs.map(input =>
                    <Grid item xs={input.width || 12} key={input.id}>
                      <TextField
                        id={input.id}
                        name={input.id}
                        label={input.label}
                        value={form[input.id]}
                        onChange={e => setForm({ ...form, [input.id]: e.target.value }) }
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>
                        }}
                      />
                    </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Step 2: Enter Gun Details
                      </Typography>
                    </Grid>
                    {gunInputs.map(input =>
                      <Grid item xs={input.width || 12} key={input.id}>
                        <TextField
                          id={input.id}
                          name={input.id}
                          label={input.label}
                          value={form[input.id]}
                          onChange={e => setForm({ ...form, [input.id]: e.target.value }) }
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <Button size="large" onClick={() => setLockLength(!lockLength)} style={{ height: '100%' }}>
                        {lockLength ? <LockIcon /> : <LockOpenIcon />}
                      </Button>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        value={contour}
                        onChange={(event, newValue) => {
                          setContour(newValue)
                        }}
                        inputValue={contourInputValue}
                        onInputChange={(event, newInputValue) => {
                          setContourInputValue(newInputValue)
                        }}
                        options={contours}
                        renderInput={params => <TextField {...params} label="Barrel Contour" />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button size="large" onClick={() => setLockContour(!lockContour)} style={{ height: '100%' }}>
                        {lockContour ? <LockIcon /> : <LockOpenIcon />}
                      </Button>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        value={cartridge}
                        onChange={(event, newValue) => {
                          setCartridge(newValue)
                        }}
                        inputValue={cartridgeInputValue}
                        onInputChange={(event, newInputValue) => {
                          setCartridgeInputValue(newInputValue)
                        }}
                        options={cartridges}
                        renderInput={params => <TextField {...params} label="Cartridge" />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button size="large" onClick={() => setLockCartridge(!lockCartridge)} style={{ height: '100%' }}>
                        {lockCartridge ? <LockIcon /> : <LockOpenIcon />}
                      </Button>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        value={bullet}
                        onChange={(event, newValue) => {
                          setBullet(newValue)
                        }}
                        inputValue={bulletInputValue}
                        onInputChange={(event, newInputValue) => {
                          setBulletInputValue(newInputValue)
                        }}
                        options={bullets.filter(v => {
                          if (!cartridge) return true
                          return v.caliber === cartridge.caliber
                        })}
                        renderInput={params => <TextField {...params} label="Bullet" />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button size="large" onClick={() => setLockBullet(!lockBullet)} style={{ height: '100%' }}>
                        {lockBullet ? <LockIcon /> : <LockOpenIcon />}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className="grid-container" sx={{ minHeight: '85vh' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h5" gutterBottom>
                        Step 3: Enter Constraints
                      </Typography>
                    </Grid>
                    {searchInputs.map(input =>
                      <Grid item xs={input.width || 12} key={input.id}>
                        <TextField
                          id={input.id}
                          name={input.id}
                          label={input.label}
                          value={form[input.id]}
                          onChange={e => setForm({ ...form, [input.id]: e.target.value }) }
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Autocomplete
                        value={form.minCaliber}
                        onChange={(event, newValue) => {
                          setForm({ ...form, minCaliber: newValue })
                        }}
                        inputValue={minCaliberInputValue}
                        onInputChange={(event, newInputValue) => {
                          setMinCaliberInputValue(newInputValue)
                        }}
                        options={calibers}
                        renderInput={params => <TextField {...params} label="Min Caliber" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        value={form.maxCaliber}
                        onChange={(event, newValue) => {
                          setForm({ ...form, maxCaliber: newValue })
                        }}
                        inputValue={maxCaliberInputValue}
                        onInputChange={(event, newInputValue) => {
                          setMaxCaliberInputValue(newInputValue)
                        }}
                        options={calibers}
                        renderInput={params => <TextField {...params} label="Max Caliber" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox
                          checked={form.hunting}
                          onChange={e => setForm({ ...form, hunting: e.target.checked })}
                        />}
                        label="Only use hunting bullets"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        Step 4: Execute Search
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button size="large" variant="contained" onClick={startSearch} disabled={isSearching}>Execute</Button>
                    </Grid>
                    <Grid item xs={12}>
                      {currentSearch && [ "Most accurate combination so far", "shown in results section.", "", ...currentSearch ].map((v, i) => <span key={i}>{v}<br/></span>)}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className="grid-container" sx={{ minHeight: '85vh' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} mt={2}>
                      <Typography variant="h5" gutterBottom>
                        Step 5: View Results
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {result && result.cartridgeName && <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" align="center">{result.cartridgeName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" align="center">{result.bulletName}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>}
                      <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {Object.keys(result).filter(k => k !== 'cartridgeName' && k !== 'bulletName' && !k.includes('impacts')).map(key => (
                              <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                {outputs[key] ? <>
                                  <TableCell component="th" scope="row">
                                    {outputs[key].label}
                                  </TableCell>
                                  <TableCell>
                                    {(outputs[key].hasOwnProperty('precision') && result[key].toFixed) ? result[key].toFixed(outputs[key].precision) : result[key]}
                                  </TableCell>
                                </> : <>
                                  <TableCell component="th" scope="row">
                                    {key}
                                  </TableCell>
                                  <TableCell>
                                    {result[key]}
                                  </TableCell>
                                </>}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className="grid-container" sx={{ minHeight: '15vh' }}>
                <CardContent>
                  <canvas ref={canvasRef} height={500} width={1200} style={{ width: '100%' }} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  )
}

export default App
