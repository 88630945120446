const imbalanceByMfg = {
  'CheyTac': .00003,
  'G9': .00003,
  'GS Custom': .00003,
  'Warner': .00003,
  'Lehigh Defense': .00003,
  'Barrett': .00003,
  'Cutting Edge': .00003,
  'Hammer Hunting': .00003,
  'Peregrine': .00003,
  'Cauterucio': .00007,
  'Berger': .00007,
  'JLK': .00007,
  'Vapor Trail': .00007,
  'Lapua': .00007,
  'Barnes': .0001,
  'Chinchaga': .0001,
  'Alco': .0001,
  'Hornady': .0001,
  'Matrix': .0001,
  'Norma': .0001,
  'Nosler': .0001,
  'Sierra': .0001,
  'Swift': .0001,
  'DAG': .0002,
  'DRT': .0002,
  'Dyer': .0002,
  'HSM': .0002,
  'Hoover': .0002,
  'KP': .0002,
  'Precision Ballistics': .0002,
  'Pri Partizan': .0002,
  'RUAG Swiss': .0002,
  'Rocky Mountain': .0002,
  'Target BT': .0002,
  'Wildcat': .0002,
  'Federal': .0005,
  'Freedom Munitions': .0005,
  'Lake City': .0005,
  'PMC': .0005,
  'PMP': .0005,
}

export default imbalanceByMfg
