const contours = [
{  name:  'Featherweight #2',        breechDiameter:  1.2,    breechLength:  2,     taperLength:  .5,    taperDiameter:  .98,    muzzleDiameter:  .58,   length:  22  },
{  name:  'Win Featherweight',       breechDiameter:  1.15,   breechLength:  2,     taperLength:  .5,    taperDiameter:  .75,    muzzleDiameter:  .56,   length:  22  },

{  name:  'Standard Sporter',        breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  3.25,  taperDiameter:  .8,     muzzleDiameter:  .6,    length:  24  },
{  name:  'Medium Sporter #2B',      breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  3.25,  taperDiameter:  .85,    muzzleDiameter:  .63,   length:  24  },
{  name:  'Heavy Sporter #3',        breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  3.25,  taperDiameter:  .87,    muzzleDiameter:  .67,   length:  24  },
{  name:  'Light Bull Sporter #3B',  breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  3.25,  taperDiameter:  .9,     muzzleDiameter:  .7,    length:  26  },
{  name:  'Bull Sporter #4',         breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  3.25,  taperDiameter:  1,      muzzleDiameter:  .75,   length:  26  },
{  name:  'Rem Varmint/Sendary',     breechDiameter:  1.25,   breechLength:  2,     taperLength:  2.35,  taperDiameter:  .95,    muzzleDiameter:  .83,   length:  27  },

{  name:  'Rem Mag Sporter',         breechDiameter:  1.25,   breechLength:  1.5,   taperLength:  2.75,  taperDiameter:  .92,    muzzleDiameter:  .66,   length:  24  },
{  name:  'Rem Standard Sporter',    breechDiameter:  1.2,    breechLength:  1.25,  taperLength:  4.5,   taperDiameter:  .79,    muzzleDiameter:  .66,   length:  22  },

{  name:  'Standard Target',         breechDiameter:  1.2,    breechLength:  2.75,  taperLength:  0,     taperDiameter:  1.2,    muzzleDiameter:  .82,   length:  26  },
{  name:  'Heavy Target',            breechDiameter:  1.25,   breechLength:  2.75,  taperLength:  0,     taperDiameter:  1.25,   muzzleDiameter:  .875,  length:  26  },
{  name:  'MTU',                     breechDiameter:  1.25,   breechLength:  2.75,  taperLength:  0,     taperDiameter:  1.25,   muzzleDiameter:  .93,   length:  26  },
{  name:  'Light Varmint',           breechDiameter:  1.2,    breechLength:  5,     taperLength:  0,     taperDiameter:  1.2,    muzzleDiameter:  .875,  length:  28  },
{  name:  'Heavy Varmint',           breechDiameter:  1.25,   breechLength:  5,     taperLength:  0,     taperDiameter:  1.25,   muzzleDiameter:  .9,    length:  30  },
{  name:  'M24/M40',                 breechDiameter:  1.2,    breechLength:  3,     taperLength:  0,     taperDiameter:  1.2,    muzzleDiameter:  .9,    length:  26  },
{  name:  'Hunter Class',            breechDiameter:  1.25,   breechLength:  4,     taperLength:  0,     taperDiameter:  1.25,   muzzleDiameter:  .75,   length:  26  },
{  name:  'Win Pre-64 Target',       breechDiameter:  1.14,   breechLength:  2,     taperLength:  0,     taperDiameter:  1.14,   muzzleDiameter:  .78,   length:  24  },

{  name:  'Light Palma',             breechDiameter:  1.25,   breechLength:  3,     taperLength:  2.5,   taperDiameter:  .9,     muzzleDiameter:  .75,   length:  30  },
{  name:  'Medium Palma',            breechDiameter:  1.25,   breechLength:  3,     taperLength:  2.5,   taperDiameter:  .92,    muzzleDiameter:  .82,   length:  30  },
{  name:  'Heavy Palma',             breechDiameter:  1.25,   breechLength:  3,     taperLength:  2.5,   taperDiameter:  1,      muzzleDiameter:  .9,    length:  30  },
{  name:  'Whitley Medium Palma',    breechDiameter:  1.25,   breechLength:  3,     taperLength:  2.5,   taperDiameter:  .95,    muzzleDiameter:  .83,   length:  30  },
{  name:  'Marksman',                breechDiameter:  1.25,   breechLength:  3,     taperLength:  2.5,   taperDiameter:  .97,    muzzleDiameter:  .87,   length:  30  },

{  name:  '1.25 Straight',           breechDiameter:  1.25,   breechLength:  5,     taperLength:  0,     taperDiameter:  1.25,   muzzleDiameter:  1.25,  length:  30  },
{  name:  '1.35 Straight',           breechDiameter:  1.35,   breechLength:  5,     taperLength:  0,     taperDiameter:  1.35,   muzzleDiameter:  1.35,  length:  40  },
{  name:  '1.45 Straight',           breechDiameter:  1.45,   breechLength:  5,     taperLength:  0,     taperDiameter:  1.45,   muzzleDiameter:  1.45,  length:  40  },
{  name:  '2.0 Straight',            breechDiameter:  2,      breechLength:  5,     taperLength:  0,     taperDiameter:  2,      muzzleDiameter:  2,     length:  40  },

{  name:  'Light 50 BMG',            breechDiameter:  1.725,  breechLength:  4,     taperLength:  0,     taperDiameter:  1.725,  muzzleDiameter:  1.25,  length:  36  },
{  name:  'Heavy 50 BMG',            breechDiameter:  2,      breechLength:  4,     taperLength:  0,     taperDiameter:  2,      muzzleDiameter:  1.45,  length:  36  },
]

export default contours
