var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.trajectoryCalculate = void 0;
var drag_curves_1 = require("./drag-curves");
var gravity_lookup_1 = require("./gravity-lookup");
var M_G7 = drag_curves_1.G7.map(function (v) { return v[0]; });
var D_G7 = drag_curves_1.G7.map(function (v) { return v[1]; });
var M_G1 = drag_curves_1.G1.map(function (v) { return v[0]; });
var D_G1 = drag_curves_1.G1.map(function (v) { return v[1]; });
var GRAVITY = 32.174;
//const GRAVITY = 31.50732454500114 // nepal actual = -0.6666754549988596
//const GRAVITY = 32.130778699679674 // utah actual = -0.043221300320325895
var EARTH_ROTATION_RATE = 0.00007292;
var degToRad = function (deg) { return (deg * Math.PI) / 180; };
var inchToMoa = function (inches, yards) { return inches / (yards / 100) / 1.0472; };
var inchToMils = function (inches, yards) { return inchToMoa(inches, yards) / 3.43774677; };
var moaToInch = function (moa, yards) { return (yards / 100) * moa * 1.0472; };
function trajectory(options_) {
    var options = sanitizeOptions(options_);
    var zeroRange = options.zeroRange;
    var result;
    if (zeroRange) {
        var zeroTrajectory = trajectoryCalculate(__assign(__assign({}, options), { range: zeroRange, latitude: NaN, longitude: NaN, azimuth: NaN, useSpinDrift: false, useAerodynamicJump: false }));
        var gunElevationAngleMinutes = inchToMoa(-zeroTrajectory[zeroRange].drop, zeroRange);
        result = trajectoryCalculate(__assign(__assign({}, options), { gunElevationAngleMinutes: gunElevationAngleMinutes }));
        if (result[zeroRange] && Math.abs(result[zeroRange].drop) <= 0.1) {
            result[zeroRange].drop = 0;
            result[zeroRange].dropMOA = 0;
        }
    }
    else {
        result = trajectoryCalculate(options);
    }
    if (result[0]) {
        result[0].dropMOA = 0;
        result[0].windMOA = 0;
    }
    return result;
}
exports["default"] = trajectory;
function trajectoryCalculate(options) {
    var _a, _b;
    var azimuth = options.azimuth, bc = options.bc, bulletDiameter = options.bulletDiameter, bulletLength = options.bulletLength, bulletWeight = options.bulletWeight, dragCurve = options.dragCurve, gunElevationAngleMinutes = options.gunElevationAngleMinutes, humidity = options.humidity, includeRanges = options.includeRanges, latitude = options.latitude, longitude = options.longitude, lookAngle = options.lookAngle, pressure = options.pressure, range = options.range, rangeStep = options.rangeStep, remoteWind = options.remoteWind, remoteWindDir = options.remoteWindDir, scopeHeight = options.scopeHeight, temperature = options.temperature, twistDirection = options.twistDirection, twistRate = options.twistRate, useAerodynamicJump = options.useAerodynamicJump, useSpinDrift = options.useSpinDrift, v0 = options.v0, wind = options.wind, windDir = options.windDir;
    var gravity = GRAVITY;
    if (latitude && longitude) {
        gravity = (0, gravity_lookup_1.lookupGravity)(latitude, longitude);
    }
    var stdDensity = airDensity(29.92, 59, 0);
    var trajDensity = airDensity(pressure, temperature, humidity);
    var altitude = altFromPressureAndTemperature(pressure, temperature);
    var M = dragCurve === 'G1' ? M_G1 : M_G7;
    var D = dragCurve === 'G1' ? D_G1 : D_G7;
    var R0 = trajDensity / stdDensity;
    var W1;
    var W3;
    var W1mph;
    var W3mph;
    var muzzleW3mph;
    var getWind;
    if (typeof wind === 'function') {
        getWind = wind;
    }
    else if (Array.isArray(wind) && wind.length) {
        var windCopy_1 = __spreadArray([], wind, true);
        var currentWind_1 = windCopy_1.shift();
        getWind = function (range) {
            if (windCopy_1.length && range >= windCopy_1[0].range) {
                currentWind_1 = windCopy_1.shift();
            }
            return [currentWind_1.speed, currentWind_1.direction];
        };
    }
    else if (remoteWind) {
        getWind = function (r) {
            if (r < range / 2) {
                return [wind, windDir];
            }
            else {
                return [remoteWind, remoteWindDir];
            }
        };
    }
    else {
        getWind = function (range) {
            return [wind, windDir];
        };
    }
    var windFn = function (range) {
        var _a = getWind(range), wind = _a[0], windDir = _a[1];
        W1mph = wind * Math.cos(degToRad(windDir) + Math.PI); // positive = gun to target
        W3mph = wind * Math.sin(degToRad(windDir) + Math.PI); // positive = left to right
        if (muzzleW3mph === undefined) {
            muzzleW3mph = W3mph;
        }
        W1 = (22 * W1mph) / 15;
        W3 = (22 * W3mph) / 15;
        return [W1, W3];
    };
    _a = windFn(0); W1 = _a[0]; W3 = _a[1];
    var launchSg;
    if (bulletDiameter && bulletLength && bulletWeight && twistRate) {
        launchSg = sg(bulletDiameter, bulletLength, bulletWeight, v0, altitude, twistRate);
    }
    else {
        launchSg = 1.5;
    }
    var coriolisGravityCorrection = 1;
    if (!isNaN(latitude) && !isNaN(azimuth)) {
        coriolisGravityCorrection =
            1 -
                ((2 * EARTH_ROTATION_RATE * v0) / GRAVITY) *
                    Math.cos(degToRad(latitude)) *
                    Math.sin(degToRad(azimuth));
    }
    gravity = gravity * coriolisGravityCorrection;
    var forwardGravity = 0;
    if (lookAngle) {
        var gravityCorrection = Math.cos(degToRad(lookAngle));
        gravity = GRAVITY * gravityCorrection * coriolisGravityCorrection;
        var forwardGravityCorrection = Math.sin(degToRad(lookAngle));
        forwardGravity = GRAVITY * forwardGravityCorrection;
    }
    var E1 = 0.00001;
    var P = {};
    var Q = {};
    var R = {};
    var T = {};
    var V = {};
    var W = {};
    var X = {};
    var Y = {};
    var Z = {};
    var UC = 3;
    var DINT = 1; // distance integration = 1 yard
    var D3 = DINT * UC;
    var RH1 = -0.00003158;
    var RH2 = 0;
    var TK1 = -0.000006015;
    var TK2 = 0;
    var PIR = -0.000208551;
    var VV1 = 49.19;
    var N = 2;
    var PR2 = 0;
    var V3 = v0 * Math.cos(gunElevationAngleMinutes / 3437.74677);
    var V4 = v0 * Math.sin(gunElevationAngleMinutes / 3437.74677);
    var V5 = 0;
    var R1 = 0;
    var H1 = -scopeHeight / 12;
    var D1 = 0;
    var T1 = 0;
    var spinDriftVelocity = 0;
    if (useSpinDrift) {
        spinDriftVelocity = -twistDirection * (0.5349 * launchSg + 0.642) * DINT;
    }
    Q[0] = R1;
    R[0] = -scopeHeight;
    T[0] = D1;
    V[0] = v0;
    W[0] = T1;
    X[0] = V3;
    Y[0] = V4;
    Z[0] = V5;
    var C3 = (PIR * R0) / bc;
    var B1 = Math.sqrt(Math.pow(V3 - W1, 2) + Math.pow(V4, 2) + Math.pow(V5 - W3, 2));
    var T0 = (temperature + 459.67) * Math.exp((TK1 + TK2 * H1) * H1) - 459.67;
    var V1 = VV1 * Math.sqrt(T0 + 459.67);
    var X1 = B1 / V1;
    var C1 = interpolate(X1, M, D);
    var C4 = (C3 * C1 * B1 * Math.exp((RH1 + RH2 * H1) * H1)) / V3;
    var A1 = C4 * (V3 - W1);
    var A2 = C4 * V4 - gravity / V3;
    var A3 = C4 * (V5 - W3);
    while (PR2 < range) {
        if (lookAngle) {
            // NOTE this is valid if lookAngle is 0, and should probably be enabled for that at some point
            var relativeAltitude = PR2 * Math.sin(degToRad(lookAngle)) * D3 + H1;
            var altitudeDensity = pressureAtAlt(relativeAltitude, trajDensity, temperature);
            R0 = altitudeDensity / stdDensity;
            C3 = (PIR * R0) / bc;
        }
        var R2 = R1 + D3;
        _b = windFn(PR2); W1 = _b[0]; W3 = _b[1];
        PR2 += DINT;
        var V6 = V3 + A1 * D3;
        var V7 = V4 + A2 * D3;
        var V8 = V5 + A3 * D3 + spinDriftVelocity;
        var B2 = Math.sqrt(Math.pow(V6 - W1, 2) + Math.pow(V7, 2) + Math.pow(V8 - W3, 2));
        var A4 = void 0, A5 = void 0, A6 = void 0, E2 = void 0;
        var doCount = 0;
        do {
            ++doCount;
            var U = B2;
            T0 = (temperature + 459.67) * Math.exp((TK1 + TK2 * H1) * H1) - 459.67;
            V1 = VV1 * Math.sqrt(T0 + 459.67);
            X1 = B2 / V1;
            var C2 = interpolate(X1, M, D);
            var C5 = (C3 * C2 * B2 * Math.exp((RH1 + RH2 * H1) * H1)) / V6;
            A4 = C5 * (V6 - W1) - forwardGravity / V6; // forward
            A5 = C5 * V7 - gravity / V6; // vertical
            A6 = C5 * (V8 - W3); // wind
            V6 = V3 + 0.5 * (A1 + A4) * D3; // forward
            V7 = V4 + 0.5 * (A2 + A5) * D3; // vertical
            V8 = V5 + 0.5 * (A3 + A6) * D3; // wind
            B2 = Math.sqrt(Math.pow(V6 - W1, 2) + Math.pow(V7, 2) + Math.pow(V8 - W3, 2));
            E2 = Math.abs((B2 - U) / B2);
        } while (doCount < 10 && E2 > E1);
        var H2 = H1 + ((V4 + V7) / (V3 + V6)) * D3;
        var dropAngle = Math.tan((12 * (H1 - H2)) / (D3 * 12));
        if (Math.abs(lookAngle) > 0) {
            gravity =
                GRAVITY *
                    Math.cos(degToRad(lookAngle) - dropAngle) *
                    coriolisGravityCorrection;
            forwardGravity = GRAVITY * Math.sin(degToRad(lookAngle) - dropAngle);
            // this accounts for the angle of flight of the bullet,
            // but it's not a standardized calc, so leaving off for now for trajectories without look angle
            //} else {
            //gravity = GRAVITY * Math.cos(dropAngle) * coriolisGravityCorrection
            //forwardGravity = GRAVITY * Math.sin(dropAngle)
        }
        var D2 = D1 + ((V5 + V8) / (V3 + V6)) * D3;
        var T2 = T1 + (2 * D3) / (V3 + V6);
        var V2 = Math.sqrt(Math.pow(V6, 2) + Math.pow(V7, 2) + Math.pow(V8, 2));
        //  RESET CONDITIONS AT R1 TO NEW CONDITIONS AT R2.
        R1 = R2;
        H1 = H2;
        D1 = D2;
        T1 = T2;
        V3 = V6;
        V4 = V7;
        V5 = V8;
        A1 = A4;
        A2 = A5;
        A3 = A6;
        var P5 = PR2;
        var P6 = 12 * H2;
        var P7 = 12 * D2;
        if (N % rangeStep === 0 || N === range || includeRanges.includes(N)) {
            P[N] = X1;
            Q[N] = P5;
            R[N] = P6;
            T[N] = P7;
            V[N] = V2;
            W[N] = T2;
            X[N] = V6;
            Y[N] = V7;
            Z[N] = V8;
        }
        N += DINT;
    }
    var result = {};
    for (var range_1 in Q) {
        var energy = void 0;
        if (bulletWeight) {
            energy = (V[range_1] * V[range_1] * bulletWeight) / 450240;
        }
        result[range_1] = {
            range: range_1,
            mach: P[range_1],
            energy: energy,
            drop: R[range_1],
            wind: T[range_1],
            velocity: V[range_1],
            windvelocity: Z[range_1],
            time: W[range_1]
        };
        if (range_1 === '0') {
            result[range_1].sg = launchSg;
        }
    }
    for (var range_2 in result) {
        if (options.useOldSpinDrift) {
            var drift = spinDrift(launchSg, result[range_2].time) * twistDirection;
            result[range_2].wind += drift;
        }
        if (bulletLength && useAerodynamicJump) {
            var jump = aerodynamicJump(launchSg, bulletLength, muzzleW3mph) * twistDirection;
            result[range_2].drop += jump * moaToInch(1, +range_2);
        }
        if (!isNaN(latitude)) {
            var horizCoriolis = EARTH_ROTATION_RATE *
                (+range_2 * 3) *
                Math.sin(degToRad(latitude)) *
                result[range_2].time *
                12;
            if (isFinite(horizCoriolis)) {
                result[range_2].wind += horizCoriolis;
            }
        }
        result[range_2].windMOA = inchToMoa(result[range_2].wind, range_2);
        result[range_2].dropMOA = inchToMoa(result[range_2].drop, range_2);
        result[range_2].windMils = inchToMils(result[range_2].wind, range_2);
        result[range_2].dropMils = inchToMils(result[range_2].drop, range_2);
    }
    return result;
}
exports.trajectoryCalculate = trajectoryCalculate;
function interpolate(X1, M, D) {
    var I = 1;
    while (M[I + 1]) {
        if (X1 < M[I + 1]) {
            break;
        }
        I += 1;
    }
    var S = (D[I + 1] - D[I]) / (M[I + 1] - M[I]);
    var X2 = D[I] + S * (X1 - M[I]);
    return X2;
}
// https://www.brisbanehotairballooning.com.au/calculate-air-density/
// this is superceded by the new formula that accounts for humidity, but keeping it for reference
// function airDensity(pressure, temperature) {
//   const kelvin = ((temperature - 32) * 5) / 9 + 273.15
//   const pa = pressure * 3386
//   const gasConstant = 287.05
//   return pa / (gasConstant * kelvin)
// }
// https://collegedunia.com/exams/density-of-air-units-formulas-calculations-and-factors-mathematics-articleid-1794
// verified with https://www.omnicalculator.com/physics/air-density
function airDensity(pressure, temperature, relHumidity) {
    if (relHumidity < 0) {
        relHumidity = 0;
    }
    if (relHumidity > 100) {
        relHumidity = 100;
    }
    var celcius = ((temperature - 32) * 5) / 9;
    var kelvin = celcius + 273.15;
    var p1 = Math.pow(6.1078 * 10, (7.5 * celcius) / (celcius + 237.3));
    var pv = p1 * relHumidity;
    var pd = pressure * 3386 - pv;
    var rho = pd / (287.058 * kelvin) + pv / (461.495 * kelvin);
    return rho;
}
// AB appendix
function spinDrift(sg, tof) {
    return 1.25 * (sg + 1.2) * Math.pow(tof, 1.83);
}
function sg(bulletDiameter, length, grains, velocity, altitude, twistRate) {
    var calibersLength = length / bulletDiameter;
    var twistPerCalibers = twistRate / bulletDiameter;
    var correction = 1;
    if (velocity) {
        correction /= Math.pow(velocity / 2800, 0.3333);
    }
    if (altitude) {
        correction /= Math.pow(Math.E, 3.158e-5 * altitude);
    }
    var sg = (30 * grains) /
        (Math.pow(twistPerCalibers, 2) *
            Math.pow(bulletDiameter, 3) *
            calibersLength *
            (1 + Math.pow(calibersLength, 2)));
    sg /= correction;
    return sg;
}
function altFromPressureAndTemperature(pressure, temperature) {
    return ((3.28 *
        (Math.pow(29.92 / pressure, 1 / 5.257) - 1) *
        (temperature + 273.15)) /
        0.0065);
}
// https://www.omnicalculator.com/physics/air-pressure-at-altitude#how-to-calculate-air-pressure-at-altitude
function pressureAtAlt(altitude, referencePressure, temperature) {
    var celcius = ((temperature - 32) * 5) / 9;
    var kelvin = celcius + 273.15;
    var m = 0.0289644;
    var g = 9.80665;
    var r = 8.3144598;
    var h = altitude / 3.2808;
    var p0 = referencePressure;
    var t = kelvin;
    var pressure = p0 * Math.exp(-(m * g * h) / (r * t));
    return pressure;
}
// AB appendix
function aerodynamicJump(sg, length, wind) {
    return (0.01 * sg - 0.0024 * length + 0.032) * -wind;
}
function sanitizeOptions(options_) {
    var options = __assign({}, options_);
    var floatDefaults = {
        pressure: 29.92,
        temperature: 59,
        humidity: 0,
        bc: 0.3,
        scopeHeight: 1.5,
        gunElevationAngleMinutes: 0,
        lookAngle: 0
    };
    var intDefaults = {
        v0: 2700,
        rangeStep: 100,
        range: 1000
    };
    for (var key in floatDefaults) {
        if (options.hasOwnProperty(key)) {
            options[key] = parseFloat(options[key]);
        }
        if (isNaN(options[key])) {
            options[key] = floatDefaults[key];
        }
    }
    for (var key in intDefaults) {
        if (options.hasOwnProperty(key)) {
            options[key] = parseInt(options[key]);
        }
        if (isNaN(options[key])) {
            options[key] = intDefaults[key];
        }
    }
    if (options.hasOwnProperty('latitude')) {
        if (options.latitude > 90) {
            options.latitude = 90;
        }
        if (options.latitude < -90) {
            options.latitude = -90;
        }
    }
    if (options.hasOwnProperty('longitude')) {
        if (options.longitude > 180) {
            options.longitude = 180;
        }
        if (options.longitude < -180) {
            options.longitude = -180;
        }
    }
    if (options.hasOwnProperty('azimuth')) {
        if (options.azimuth > 360) {
            options.azimuth = 360;
        }
        if (options.azimuth < 0) {
            options.azimuth = 0;
        }
    }
    //if (options.twistDirection !== -1 && options.twistDirection !== '-1') {
    if (options.twistDirection !== -1) {
        options.twistDirection = 1;
    }
    else {
        options.twistDirection = -1;
    }
    if (!Array.isArray(options.includeRanges)) {
        options.includeRanges = [];
    }
    if (options.useAerodynamicJump !== false) {
        options.useAerodynamicJump = true;
    }
    if (options.useSpinDrift !== false) {
        options.useSpinDrift = true;
    }
    if (options.dragCurve !== 'G1') {
        options.dragCurve = 'G7';
    }
    if (!options.hasOwnProperty('wind')) {
        options.wind = 0;
        options.windDir = 0;
    }
    if (Array.isArray(options.wind) && !options.wind.length) {
        options.wind = 0;
        options.windDir = 0;
    }
    return options;
}
/*
const gs = {
  actual: 32.174,
  'nepal actual': 31.50732454500114, // nepal actual
  //'nepal estimate': 32.00276278191815, // nepal estimate
  'utah actual': 32.130778699679674,
  //'utah estimate': 31.909207142068986,
  'cali actual': 32.160713782282194,
  //'cali estimate': 32.091950737593834,
  whittington: 32.12941720738424,
}
let options = {} as TrajectoryOptions
//options.zeroRange = 100
options.latitude = 0
options.longitude = 0
options.azimuth = 0
options.bc = 0.475
options.bulletDiameter = 0.416
options.bulletLength = 2.385
options.bulletWeight = 230
options.dragCurve = 'G7'
options.humidity = 28
options.lookAngle = 0
options.pressure = 25
options.range = 1000
options.scopeHeight = 2
options.temperature = 56
options.twistDirection = 1
options.twistRate = 10
options.useAerodynamicJump = true
options.useSpinDrift = true
options.v0 = 3100
options.wind = 10
options.windDir = 90
//console.log(trajectory(options))
const start_t = process.hrtime()
const start = start_t[0] * 1000000 + start_t[1] / 1000
const base_t = trajectory(options)[options.range]
const end_t = process.hrtime()
const end = end_t[0] * 1000000 + end_t[1] / 1000
console.log('time', end - start, 'us')
for (const name in gs) {
  GRAVITY = gs[name]
  const t = trajectory(options)[options.range]
  console.log(name, '\t', t.dropMOA, t.dropMOA - base_t.dropMOA)
}
*/
/*
const start_t = process.hrtime()
const start = start_t[0] * 1000000 + start_t[1] / 1000
console.log(trajectory({ range: 3000, zeroRange: 3000 })[3000])
const end_t = process.hrtime()
const end = end_t[0] * 1000000 + end_t[1] / 1000
console.log('time', end - start, 'us')
console.log('time', (end - start) / 1000, 'ms')
*/
