export default function calcBarrelWeight (contour, caliber, cutLength, tenonDiameter = 1.062, tenonLength = .885) {
  const {
    breechDiameter,
    breechLength,
    taperLength,
    taperDiameter,
    muzzleDiameter,
    length
  } = contour
  const tenonVolume = cylinderVolume(tenonLength, tenonDiameter / 2)
  const breechVolume = cylinderVolume((breechLength - tenonLength), breechDiameter / 2)
  const taperVolume = truncatedConeVolume(taperLength, breechDiameter / 2, taperDiameter / 2)
  const remainingLength = cutLength - breechLength - taperLength
  const cutMuzzleDiameter = muzzleDiameter + (taperDiameter - muzzleDiameter) * (1 - (remainingLength / (length - breechLength - taperLength)))
  const barrelVolume = truncatedConeVolume(remainingLength, taperDiameter / 2, cutMuzzleDiameter / 2)
  const boreVolume = cylinderVolume(cutLength, caliber / 2)
  const allVolume = tenonVolume + breechVolume + taperVolume + barrelVolume - boreVolume
  const weight = .2904 * allVolume
  return weight
}

function truncatedConeVolume(h, r1, r2) {
  return .333333333 * Math.PI * h * (r1 * r1 + r2 * r2 + r1 * r2)
}

function cylinderVolume (h, r) {
  return h * r * r * Math.PI
}
