const bullets = [
  {
    "name": ".243 cal 105 gr Berger Hybrid",
    "weight": 105,
    "caliber": 0.243,
    "G7": 0.275,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".172 cal 20 gr Hornady Vmax",
    "weight": 20,
    "caliber": 0.172,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/172cal20grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.082,
    "twist": 7.3,
    "hunting": true
  },
  {
    "name": ".172 cal 25 gr Hornady Vmax",
    "weight": 25,
    "caliber": 0.172,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/172cal25grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.099,
    "twist": 6.8,
    "hunting": true
  },
  {
    "name": ".204 cal 35 gr Berger Varmint FB",
    "weight": 35,
    "caliber": 0.204,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/204cal35grBergerVarmintFB_UPDATED-scaled.jpg",
    "G7": 0.092,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".204 cal 40 gr Berger Varmint BT",
    "weight": 40,
    "caliber": 0.204,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/204cal40grBergerVarmintBT_UPDATED-scaled.jpg",
    "G7": 0.114,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".204 cal 55 gr Berger Varmint BT",
    "weight": 55,
    "caliber": 0.204,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/204cal55grBergerVarmintBT_UPDATED-scaled.jpg",
    "G7": 0.182,
    "twist": 7.6,
    "hunting": true
  },
  {
    "name": ".204 cal 32 gr Hornady Vmax",
    "weight": 32,
    "caliber": 0.204,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/204cal32grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.091,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".204 cal 40 gr Hornady Vmax",
    "weight": 40,
    "caliber": 0.204,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/204cal40grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.116,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".224 cal 40 gr Berger Varmint FB",
    "weight": 40,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal40grBergerVarmintFBTEST-scaled.jpg",
    "G7": 0.075,
    "twist": 12.6,
    "hunting": true
  },
  {
    "name": ".224 cal 52 gr Berger Target FB",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grBergerTargetFB_UPDATED-scaled.jpg",
    "G7": 0.12,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".224 cal 52 gr Berger Varmint FB",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grBergerVarmintFB_Scaled-scaled.jpg",
    "G7": 0.103,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".224 cal 55 gr Berger Target FB",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grBergerTargetFB_UPDATED-scaled.jpg",
    "G7": 0.12,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Berger Varmint FB",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grBergerVarmintFB_UPDATED-scaled.jpg",
    "G7": 0.107,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".224 cal 60 gr Berger Varmint FB",
    "weight": 60,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal60grBergerVarmintFB_UPDATED-scaled.jpg",
    "G7": 0.14,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".224 cal 64 gr Berger Varmint FB",
    "weight": 64,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal64grBergerVarmintFB_UPDATED-scaled.jpg",
    "G7": 0.147,
    "twist": 10.5,
    "hunting": true
  },
  {
    "name": ".224 cal 70 gr Berger Target VLD",
    "weight": 70,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal70grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.191,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 73 gr Berger Target Boat Tail",
    "weight": 73,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal73grBergerTargetBoatTail_UPDATED-scaled.jpg",
    "G7": 0.179,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Berger Target VLD",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.216,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Berger Tactical OTM",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grBergerTacticalOTM_UPDATED-scaled.jpg",
    "G7": 0.192,
    "twist": 8.4,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Berger Target VLD",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.233,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".224 cal 80.5 gr Berger Target Fullbore",
    "weight": 80.5,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80.5grBergerTargetFULLBORE_UPDATED-scaled.jpg",
    "G7": 0.226,
    "twist": 7.7,
    "hunting": false
  },
  {
    "name": ".224 cal 82 gr Berger Target Boat Tail",
    "weight": 82,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal82grBergerTargetBoatTail_UPDATED-scaled.jpg",
    "G7": 0.225,
    "twist": 7.6,
    "hunting": false
  },
  {
    "name": ".224 cal 90 gr Berger Target VLD",
    "weight": 90,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal90grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.272,
    "twist": 6.7,
    "hunting": false
  },
  {
    "name": ".224 cal 35 gr Hornady Vmax",
    "weight": 35,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal35grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.055,
    "twist": 14.7,
    "hunting": true
  },
  {
    "name": ".224 cal 40 gr Hornady Vmax",
    "weight": 40,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal40grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.087,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".224 cal 50 gr Hornady Vmax",
    "weight": 50,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal50grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.11,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".224 cal 52 gr Hornady Amax",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.119,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".224 cal 52 gr Hornady BTHP",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grHornadyBTHP_UPDATED-scaled.jpg",
    "G7": 0.105,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".224 cal 52 gr Hornady ELD",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grHornadyELD_UPDATED-scaled.jpg",
    "G7": 0.124,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".224 cal 53 gr Hornady HP Match",
    "weight": 53,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal53grHornadyHPMatch_UPDATED-scaled.jpg",
    "G7": 0.107,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".224 cal 53 gr Hornady Vmax",
    "weight": 53,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal53grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.13,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".224 cal 55 gr Hornady FMJBT",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grHornadyFMJBT_UPDATED-scaled.jpg",
    "G7": 0.124,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Hornady GMX",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.108,
    "twist": 9.5,
    "hunting": true
  },
  {
    "name": ".224 cal 55 gr Hornady Tap",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grHornadyTap_UPDATED-scaled.jpg",
    "G7": 0.121,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Hornady Vmax",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.122,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".224 cal 55 gr Hornady Vmax Cannelure",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grHornadyVmaxcannelure_UPDATED-scaled.jpg",
    "G7": 0.12,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".224 cal 60 gr Hornady Vmax",
    "weight": 60,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal60grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.134,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".224 cal 68 gr Hornady BTHP",
    "weight": 68,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal68grHornadyBTHP_UPDATED-scaled.jpg",
    "G7": 0.166,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".224 cal 68 gr Hornady BTHP Pointed",
    "weight": 68,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal68grHornadyBTHPpointed_UPDATED-scaled.jpg",
    "G7": 0.167,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".224 cal 70 gr Hornady GMX",
    "weight": 70,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal70grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.137,
    "twist": 7.7,
    "hunting": true
  },
  {
    "name": ".224 cal 73 gr Hornady ELD",
    "weight": 73,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal73grHornadyELD_UPDATED-scaled.jpg",
    "G7": 0.21,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Hornady AMax",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.214,
    "twist": 7.3,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Hornady BTHP",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grHornadyBTHP_UPDATED-scaled.jpg",
    "G7": 0.183,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Hornady BTHP Pointed",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grHornadyBTHPpointed_UPDATED-scaled.jpg",
    "G7": 0.181,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Hornady ELD Match",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 7.2,
    "hunting": false
  },
  {
    "name": ".224 cal 75 gr Hornady Tap FPD",
    "weight": 75,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal75grHornadyTapFPD_UPDATED-scaled.jpg",
    "G7": 0.176,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Hornady AMax",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.231,
    "twist": 7,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Hornady ELD Match",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.258,
    "twist": 7.1,
    "hunting": false
  },
  {
    "name": ".224 cal 51 gr Lake City M995 AP",
    "weight": 51,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal51grLake_CityM995AP_UPDATED-scaled.jpg",
    "G7": 0.126,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".224 cal 62 gr Lake City FMJBT",
    "weight": 62,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal62grLake_City_CityFMJBT_UPDATED-scaled.jpg",
    "G7": 0.154,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".224 cal 62 gr Lake City Ball M855 A1",
    "weight": 62,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal62grLake_CityBallM855A1_UPDATED-scaled.jpg",
    "G7": 0.149,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".224 cal 52 gr Nosler Custom Competition",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.113,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Nosler Ballistic Tip",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.13,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".224 cal 60 gr Nosler Ballistic Tip",
    "weight": 60,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal60grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.133,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".224 cal 69 gr Nosler Custom Competition",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.159,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".224 cal 70 gr Nosler RDF",
    "weight": 70,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal70grNoslerRDF_UPDATED-scaled.jpg",
    "G7": 0.197,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Nosler Custom Competition",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.193,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Nosler Custom Competition",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.209,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".224 cal 52 gr Sierra Match King",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.112,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Sierra Blitz King",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grSierraBlitzKing_UPDATED-scaled.jpg",
    "G7": 0.129,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".224 cal 55 gr Sierra FMJ",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grSierraFMJ_UPDATED-scaled.jpg",
    "G7": 0.131,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".224 cal 65 gr Sierra Game King",
    "weight": 65,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal65grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.152,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".224 cal 69 gr Sierra Match King",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.169,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".224 cal 69 gr Sierra Match King Tipped",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.182,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Sierra Match King",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.193,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Sierra Match King Tipped",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.202,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Sierra Match King Pointed",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.235,
    "twist": 7.6,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr Sierra Match King Unpointed",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grSierraMatchKingunpointed_UPDATED-scaled.jpg",
    "G7": 0.221,
    "twist": 8,
    "hunting": false
  },
  {
    "name": ".224 cal 90 gr Sierra Match King Pointed",
    "weight": 90,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal90grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.259,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".224 cal 90 gr Sierra Match King Unpointed",
    "weight": 90,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal90grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.259,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".224 cal 50 gr Barnes TTSXFB",
    "weight": 50,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal50grBarnesTTSXFB_UPDATED-scaled.jpg",
    "G7": 0.099,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".224 cal 52 gr Barnes Match Burner",
    "weight": 52,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal52grBarnesMatchBurner_UPDATED-scaled.jpg",
    "G7": 0.11,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".224 cal 55 gr Barnes TTSXBT",
    "weight": 55,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal55grBarnerTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.129,
    "twist": 8.6,
    "hunting": true
  },
  {
    "name": ".224 cal 62 gr Barnes TTSXBT",
    "weight": 62,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal62grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.142,
    "twist": 8,
    "hunting": true
  },
  {
    "name": ".224 cal 69 Barnes Match Burner",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grBarnesMatchBurner_UPDATED-scaled.jpg",
    "G7": 0.168,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".224 cal 70 Barnes Tac X MIL LE",
    "weight": 70,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal70grBarnesTacXMIL_LE_UPDATED-scaled.jpg",
    "G7": 0.154,
    "twist": 7.8,
    "hunting": true
  },
  {
    "name": ".224 cal 70 Barnes TSXBT",
    "weight": 70,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal70grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.158,
    "twist": 7.8,
    "hunting": true
  },
  {
    "name": ".224 cal 85 Barnes Match Burner",
    "weight": 85,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal85grBarnesMatchBurner_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".224 cal 69 gr Lapua Scenar",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.169,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".224 cal 69 gr Lapua Scenar L",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.179,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Lapua Scenar",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.206,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".224 cal 77 gr Lapua Scenar L",
    "weight": 77,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal77grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.203,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".224 cal 57 gr GS Custom SP",
    "weight": 57,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal57grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.099,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".224 cal 62 gr PMCFMJBT Green Tip Ammo",
    "weight": 62,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal62grPMCFMJBTGreenTipAmmo_UPDATED-scaled.jpg",
    "G7": 0.161,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".224 cal 69 gr GS Custom SP",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.143,
    "twist": 7.5,
    "hunting": true
  },
  {
    "name": ".224 cal 69 gr RUAG Swiss P Target",
    "weight": 69,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal69grRUAGSwissPTarget_UPDATED-scaled.jpg",
    "G7": 0.165,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".224 cal 79 gr DRT Frangible",
    "weight": 79,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal79grDRTFrangible_UPDATED-scaled.jpg",
    "G7": 0.19,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".224 cal 80 gr JLKVLD",
    "weight": 80,
    "caliber": 0.224,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/05/224cal80grJLKVLD_UPDATED-scaled.jpg",
    "G7": 0.23,
    "twist": 7.3,
    "hunting": false
  },
  {
    "name": ".257 cal 75 gr Hornady Vmax",
    "weight": 75,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal75grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.138,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".257 cal 87 gr Hornady Spitzer",
    "weight": 87,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal87grHornadySpitzer_UPDATED-scaled.jpg",
    "G7": 0.145,
    "twist": 12.3,
    "hunting": true
  },
  {
    "name": ".257 cal 90 gr Hornady GMX",
    "weight": 90,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal90grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.151,
    "twist": 8.8,
    "hunting": true
  },
  {
    "name": ".257 cal 110 gr Hornady Interbond",
    "weight": 110,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal110grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".257 cal 117 gr Hornady SST",
    "weight": 117,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal117grHornadySST_UPDATED-scaled.jpg",
    "G7": 0.202,
    "twist": 9.2,
    "hunting": true
  },
  {
    "name": ".257 cal 100 gr Nosler Ballistic Tip",
    "weight": 100,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal100grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.176,
    "twist": 9.5,
    "hunting": true
  },
  {
    "name": ".257 cal 110 gr Nosler Accubond",
    "weight": 110,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal110grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 9,
    "hunting": false
  },
  {
    "name": ".257 cal 115 gr Nosler Ballistic Tip",
    "weight": 115,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal115grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".257 cal 115 gr Nosler Partition",
    "weight": 115,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal115grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.198,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".257 cal 70 gr Sierra BlitzKing",
    "weight": 70,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal70grSierraBlitzKing_UPDATED-scaled.jpg",
    "G7": 0.117,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".257 cal 100 gr Sierra MatchKing",
    "weight": 100,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal100grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.184,
    "twist": 9.9,
    "hunting": false
  },
  {
    "name": ".257 cal 117 gr Sierra Pro Hunter",
    "weight": 117,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal117grSierraProHunter_UPDATED-scaled.jpg",
    "G7": 0.18,
    "twist": 10.8,
    "hunting": true
  },
  {
    "name": ".257 cal 80 gr Barnes TTSXBT",
    "weight": 80,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal80grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.146,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".257 cal 100 gr Barnes TTSX",
    "weight": 100,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal100grBarnesTTSX_UPDATED-scaled.jpg",
    "G7": 0.181,
    "twist": 8.6,
    "hunting": true
  },
  {
    "name": ".257 cal 115 gr Barnes TSX FB",
    "weight": 115,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal115grBarnesTSXFB_UPDATED-scaled.jpg",
    "G7": 0.168,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".257 cal 90 gr GS Custom SP",
    "weight": 90,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal90grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.157,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".257 cal 100 gr Swift Scirocco II",
    "weight": 100,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal100grSwiftSciroccoII_UPDATED-scaled.jpg",
    "G7": 0.174,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".257 cal 115 gr Berger Hunting VLD",
    "weight": 115,
    "caliber": 0.257,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/257cal115grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".264 cal 120 gr Target BT",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grBergerTargetBT_UPDATED-scaled.jpg",
    "G7": 0.24,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".264 cal 130 gr Berger Hunting VLD",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.288,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".264 cal 130 gr Berger Tactical AR Hybrid",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grBergerTacticalARHybrid_UPDATED-scaled.jpg",
    "G7": 0.288,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".264 cal 130 gr Berger Target VLD",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".264 cal 135 gr Berger Classic Hunting",
    "weight": 135,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal135grBergerClassicHunting_UPDATED-scaled.jpg",
    "G7": 0.303,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".264 cal 140 gr Berger Target Hybrid",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.311,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Berger Target Long Range BT",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBergerTargetLongRange_UPDATED-scaled.jpg",
    "G7": 0.306,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Berger Target VLD",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.307,
    "twist": 8.4,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Berger Hunting VLD",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.306,
    "twist": 8.4,
    "hunting": true
  },
  {
    "name": ".264 cal 140 gr Berger Elite Hunter",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.31,
    "twist": 8.3,
    "hunting": true
  },
  {
    "name": ".264 cal 95 gr Hornady Vmax",
    "weight": 95,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal95grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.182,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".264 cal 100 gr Hornady Amax",
    "weight": 100,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal100grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.186,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".264 cal 100 gr Hornady ELD match",
    "weight": 100,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal100grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.196,
    "twist": 10.6,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Hornady Amax ",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Hornady ELD match",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Hornady GMX",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.226,
    "twist": 7.6,
    "hunting": true
  },
  {
    "name": ".264 cal 123 gr Hornady Amax",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".264 cal 123 gr Hornady ELD match",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.259,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".264 cal 129 gr Hornady Interbond",
    "weight": 129,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal129grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.265,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".264 cal 129 gr Hornady SST BT",
    "weight": 129,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal129grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".264 cal 130 gr Hornady ELD match",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Hornady Amax",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.288,
    "twist": 8.5,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Hornady BTHP Match",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grHornadyBTHPMatch_UPDATED-scaled.jpg",
    "G7": 0.285,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Hornady ELD match",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.322,
    "twist": 8.5,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Hornady SST BT",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.265,
    "twist": 8.3,
    "hunting": true
  },
  {
    "name": ".264 cal 143 gr Hornady ELDX",
    "weight": 143,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal143grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.315,
    "twist": 8,
    "hunting": true
  },
  {
    "name": ".264 cal 147 gr Hornady ELD match",
    "weight": 147,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal147grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.335,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 107 gr Sierra MatchKing",
    "weight": 107,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal107grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.23,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Sierra MatchKing",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.213,
    "twist": 10,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Sierra Pro Hunter",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grSierraProHunter_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".264 cal 123 gr Sierra MatchKing pointed",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.272,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".264 cal 123 gr Sierra MatchKing unpointed",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grSierraMatchKingunpointed_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".264 cal 130 gr Sierra Tiped MatchKing",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grSierraTipedMachKing_UPDATED-scaled.jpg",
    "G7": 0.273,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Sierra MatchKing",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.271,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Sierra Spitzer",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grSierraSpitzer_UPDATED-scaled.jpg",
    "G7": 0.234,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".264 cal 142 gr Sierra MatchKing pointed",
    "weight": 142,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal142grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.313,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".264 cal 142 gr Sierra MatchKing unpointed",
    "weight": 142,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal142grSierraMatchKingunpointed_UPDATED-scaled.jpg",
    "G7": 0.301,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".264 cal 100 gr Nosler Ballistic Tip",
    "weight": 100,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal100grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.172,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".264 cal 120 gr Nosler Ballistic Tip",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.214,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".264 cal 123 gr Nosler Custom Competition",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.241,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".264 cal 129 gr Nosler Long Range Accubond",
    "weight": 129,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal129grNoslerLongRangeAccubond_UPDATED-scaled.jpg",
    "G7": 0.283,
    "twist": 8.3,
    "hunting": false
  },
  {
    "name": ".264 cal 130 gr Nosler Accubond",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.225,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Nosler Accubond",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 8.4,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Nosler Custom Competition",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.281,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Nosler RDF",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grNoslerRDF_UPDATED-scaled.jpg",
    "G7": 0.313,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".264 cal 142 gr Nosler Long Range Accubond",
    "weight": 142,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal142grNoslerLongRangeAccubond_UPDATED-scaled.jpg",
    "G7": 0.293,
    "twist": 8,
    "hunting": false
  },
  {
    "name": ".264 cal 108 gr Lapua Scenar",
    "weight": 108,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal108grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.225,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".264 cal 120 gr Lapua Scenar L",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.242,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".264 cal 123 gr Lapua Scenar",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.265,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".264 cal 136 gr Lapua Scenar L",
    "weight": 136,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal136grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.285,
    "twist": 8.5,
    "hunting": false
  },
  {
    "name": ".264 cal 139 gr Lapua Scenar",
    "weight": 139,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal139grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.29,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".264 cal 144 gr Lapua FMJBT",
    "weight": 144,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal144grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".264 cal 123 gr Precision Ballistics HPBT",
    "weight": 123,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal123grPrecision_BallisticsHPBT_UPDATED-scaled.jpg",
    "G7": 0.254,
    "twist": 9.2,
    "hunting": true
  },
  {
    "name": ".264 cal 130 gr Precision Ballistics HPBT",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grPrecision_BallisticsHPBT_UPDATED-scaled.jpg",
    "G7": 0.277,
    "twist": 8.6,
    "hunting": true
  },
  {
    "name": ".264 cal 136 gr Precision Ballistics HPBT",
    "weight": 136,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal136grPrecision_BallisticsHPBT_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".264 cal 139 gr Precision Ballistics HPBT",
    "weight": 139,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal139grPrecision_BallisticsHPBT_UPDATED-scaled.jpg",
    "G7": 0.297,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".264 cal 142 gr Precision Ballistics HPBT",
    "weight": 142,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal142grPrecision_BallisticsHPBT_UPDATED-scaled.jpg",
    "G7": 0.295,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".264 cal 130 gr Cutting Edge MTH",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grCutting_EdgeEdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.267,
    "twist": 7.8,
    "hunting": true
  },
  {
    "name": ".264 cal 133 gr Cutting Edge MTAC",
    "weight": 133,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal133grCutting_EdgeEdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.274,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr Cutting Edge MTH",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grCutting_EdgeEdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.291,
    "twist": 7.4,
    "hunting": true
  },
  {
    "name": ".264 cal 143 gr Cutting Edge MTAC",
    "weight": 143,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal143grCutting_EdgeEdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".264 cal 100 gr Barnes TTSXBT",
    "weight": 100,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal100grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.175,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".264 cal 120 gr Barnes TTSXBT",
    "weight": 120,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal120grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.203,
    "twist": 8.4,
    "hunting": true
  },
  {
    "name": ".264 cal 127 gr Barnes LRXBT",
    "weight": 127,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal127grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.228,
    "twist": 7.9,
    "hunting": true
  },
  {
    "name": ".264 cal 140 gr Barnes Match Burner",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grBarnesMatchBurnerBurner_UPDATED-scaled.jpg",
    "G7": 0.288,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".264 cal 119 gr Cauterucio 10-ogive",
    "weight": 119,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal119grCauterucio10-ogive_UPDATED-scaled.jpg",
    "G7": 0.241,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".264 cal 119 gr Cauterucio 15-ogive",
    "weight": 119,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal119grCauterucio15-ogive_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".264 cal 131 gr Cauterucio VLD",
    "weight": 131,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal131grCauterucioVLD_UPDATED-scaled.jpg",
    "G7": 0.296,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".264 cal 146 gr Cauterucio HPBT",
    "weight": 146,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal146grCautreucioHPBT_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".264 cal 152 gr Cauterucio HPBT",
    "weight": 152,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal152grCautreucioHPBT_UPDATED-scaled.jpg",
    "G7": 0.304,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".264 cal 155 gr Cauterucio ULD",
    "weight": 155,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal155grCauterucioULD_UPDATED-scaled.jpg",
    "G7": 0.336,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 103 gr G9 Competition Solid",
    "weight": 103,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal103grG9_Competitionsolid_UPDATED-scaled.jpg",
    "G7": 0.23,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 112 gr GS Custom SP",
    "weight": 112,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal112grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.189,
    "twist": 8.1,
    "hunting": true
  },
  {
    "name": ".264 cal 113 gr G9 Competition Solid",
    "weight": 113,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal113grG9_Competitionsolid_UPDATED-scaled.jpg",
    "G7": 0.256,
    "twist": 7.7,
    "hunting": false
  },
  {
    "name": ".264 cal 119 gr GS Custom SP",
    "weight": 119,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal119grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.213,
    "twist": 7.6,
    "hunting": true
  },
  {
    "name": ".264 cal 125 gr Hammer Hunting",
    "weight": 125,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal125grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.197,
    "twist": 7.9,
    "hunting": true
  },
  {
    "name": ".264 cal 128 gr GS Custom SP",
    "weight": 128,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal128grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.249,
    "twist": 7.1,
    "hunting": true
  },
  {
    "name": ".264 cal 130 gr Norma Diamond",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grNormaDiamond_UPDATED-scaled.jpg",
    "G7": 0.294,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 130 gr Swift Scirocco",
    "weight": 130,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal130grSwiftScirocco_UPDATED-scaled.jpg",
    "G7": 0.251,
    "twist": 8.4,
    "hunting": true
  },
  {
    "name": ".264 cal 136 gr Hoover",
    "weight": 136,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal136grHoover_UPDATED-scaled.jpg",
    "G7": 0.314,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 137 gr Vapor Trail HPBT",
    "weight": 137,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal137grVapor_TrailHPBT_UPDATED-scaled.jpg",
    "G7": 0.288,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".264 cal 140 gr JLK VLD",
    "weight": 140,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal140grJLKVLD_UPDATED-scaled.jpg",
    "G7": 0.321,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".264 cal 150 gr Matrix BTHP",
    "weight": 150,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal150grMatrixBTHP_UPDATED-scaled.jpg",
    "G7": 0.31,
    "twist": 8.4,
    "hunting": false
  },
  {
    "name": ".264 cal 160 gr Matrix BTHP",
    "weight": 160,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal160grMatrixBTHP_UPDATED-scaled.jpg",
    "G7": 0.332,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".264 cal 168 gr Chinchaga STH",
    "weight": 168,
    "caliber": 0.264,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/264cal168grChinchagaSTH_UPDATED-scaled.jpg",
    "G7": 0.32,
    "twist": 8,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Berger Classic Hunter",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 10.2,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Berger Hunting VLD",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 10.8,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Berger Classic Hunting",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grBergerClassicHunting_UPDATED-scaled.jpg",
    "G7": 0.271,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Berger Hunting VLD",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.258,
    "twist": 10.2,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Berger Hunting VLD",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".277 cal 170 gr Berger Elite Hunter",
    "weight": 170,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal170grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.343,
    "twist": 8.8,
    "hunting": true
  },
  {
    "name": ".277 cal 100 gr Hornady GMX",
    "weight": 100,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal100grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.137,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".277 cal 110 gr Hornady BTHP",
    "weight": 110,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal110grHornadyBTHP_UPDATED-scaled.jpg",
    "G7": 0.181,
    "twist": 12.6,
    "hunting": false
  },
  {
    "name": ".277 cal 110 gr Hornady Vmax",
    "weight": 110,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal110grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.18,
    "twist": 11.8,
    "hunting": true
  },
  {
    "name": ".277 cal 120 gr Hornady SST BT",
    "weight": 120,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal120grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.19,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Hornady GMX",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.212,
    "twist": 8.6,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Hornady Interbond",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.229,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Hornady SST BT",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.223,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Hornady BTSP Interlock",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grHornadyBTSPInterlock_UPDATED-scaled.jpg",
    "G7": 0.228,
    "twist": 10.7,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Hornady SST BT",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.24,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".277 cal 145 gr Hornady ELDX",
    "weight": 145,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal145grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.284,
    "twist": 9,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Hornady SP Interlock",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grHornadySPInterlock_UPDATED-scaled.jpg",
    "G7": 0.207,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Hornady SST BT",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.259,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".277 cal 115 gr Sierra MatchKing",
    "weight": 115,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal115grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.162,
    "twist": 13.6,
    "hunting": false
  },
  {
    "name": ".277 cal 135 gr Sierra MatchKing",
    "weight": 135,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal135grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.253,
    "twist": 10.1,
    "hunting": false
  },
  {
    "name": ".277 cal 140 gr Sierra GameKing",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.187,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Sierra GameKing",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 10.5,
    "hunting": true
  },
  {
    "name": ".277 cal 100 gr Nosler Accubond",
    "weight": 100,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal100grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.149,
    "twist": 11.2,
    "hunting": false
  },
  {
    "name": ".277 cal 115 gr Nosler Custom Competition",
    "weight": 115,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal115grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.159,
    "twist": 13.6,
    "hunting": false
  },
  {
    "name": ".277 cal 130 gr Nosler Accubond",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.198,
    "twist": 10.1,
    "hunting": false
  },
  {
    "name": ".277 cal 130 gr Nosler Ballistic Silver Tip",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grNoslerBallisticSilverTip_UPDATED-scaled.jpg",
    "G7": 0.199,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Nosler E-Tip",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.192,
    "twist": 9,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Nosler Accubond",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.217,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".277 cal 140 gr Nosler Ballistic Tip",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Nosler Partition",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.208,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Nosler LR Accubond",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grNoslerLRAccubond_UPDATED-scaled.jpg",
    "G7": 0.291,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".277 cal 117 gr Hammer Hunting",
    "weight": 117,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal117grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.18,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".277 cal 156 gr Hammer Hunting",
    "weight": 156,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal156grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.233,
    "twist": 7.9,
    "hunting": true
  },
  {
    "name": ".277 cal 168 gr Hammer Hunting",
    "weight": 168,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal168grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 7,
    "hunting": true
  },
  {
    "name": ".277 cal 95 gr Barnes TTSXBT",
    "weight": 95,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal95grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.142,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".277 cal 110 gr Barnes TTSXBT",
    "weight": 110,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal110grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.172,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".277 cal 129 gr Barnes LRX",
    "weight": 129,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal129grBarnesLRX_UPDATED-scaled.jpg",
    "G7": 0.217,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Barnes TSXBT",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.194,
    "twist": 10.2,
    "hunting": true
  },
  {
    "name": ".277 cal 130 gr Barnes TTSXBT",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.191,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".277 cal 140 gr Barnes TSXBT",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.205,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".277 cal 105 gr GS Custom SP",
    "weight": 105,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal105grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.156,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".277 cal 120 gr Cutting Edge HPBT",
    "weight": 120,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal120grCutting_EdgeHPBT_UPDATED-scaled.jpg",
    "G7": 0.209,
    "twist": 10.2,
    "hunting": false
  },
  {
    "name": ".277 cal 130 gr Cutting Edge HPBT",
    "weight": 130,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal130grCutting_EdgeHPBT_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".277 cal 140 gr Swift A-Frame",
    "weight": 140,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal140grSwiftA-Frame_UPDATED-scaled.jpg",
    "G7": 0.172,
    "twist": 11.3,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Swift A-Frame",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grSwiftA-Frame_UPDATED-scaled.jpg",
    "G7": 0.186,
    "twist": 10.7,
    "hunting": true
  },
  {
    "name": ".277 cal 150 gr Matrix RBT",
    "weight": 150,
    "caliber": 0.277,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/277cal150grMatrixRBT_UPDATED-scaled.jpg",
    "G7": 0.231,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".284 cal 140 gr Berger Hunting VLD",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.255,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Berger Classic Hunter",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.258,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Berger Classic Hunter",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.291,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Berger Hunting VLD",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.317,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Berger Target VLD",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.322,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Berger Elite Hunter",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.336,
    "twist": 9,
    "hunting": true
  },
  {
    "name": ".284 cal 180 gr Berger Hunting VLD",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.343,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".284 cal 180 gr Berger Target Hybrid",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.345,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".284 cal 180 gr Berger Target VLD",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.348,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".284 cal 195 gr Berger Hunting Hybrid",
    "weight": 195,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal195grBergerHuntingHybrid_UPDATED-scaled.jpg",
    "G7": 0.38,
    "twist": 8.3,
    "hunting": true
  },
  {
    "name": ".284 cal 120 gr Hornady Vmax",
    "weight": 120,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal120grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.184,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".284 cal 139 gr Hornady GMX",
    "weight": 139,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal139grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".284 cal 139 gr Hornady Interbond",
    "weight": 139,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal139grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.248,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".284 cal 139 gr Hornady SPBT Interlock",
    "weight": 139,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal139grHornadySPBTInterlock_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".284 cal 139 gr Hornady SST BT",
    "weight": 139,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal139grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.231,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Hornady ELDX",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.304,
    "twist": 9.2,
    "hunting": true
  },
  {
    "name": ".284 cal 154 gr Hornady Interbond",
    "weight": 154,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal154grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.273,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".284 cal 154 gr Hornady SP Interlock",
    "weight": 154,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal154grHornadySPInterlock_UPDATED-scaled.jpg",
    "G7": 0.21,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".284 cal 154 gr Hornady SST BT",
    "weight": 154,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal154grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.251,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".284 cal 162 gr Hornady Amax",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.307,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".284 cal 162 gr Hornady BTHP Match",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadyBTHPMatch_UPDATED-scaled.jpg",
    "G7": 0.293,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".284 cal 162 gr Hornady BTSP Interlock",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadyBTSPInterlock_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".284 cal 162 gr Hornady ELDMatch",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.327,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".284 cal 162 gr Hornady ELDX",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.321,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".284 cal 162 gr Hornady SST BT",
    "weight": 162,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal162grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.274,
    "twist": 9.5,
    "hunting": true
  },
  {
    "name": ".284 cal 175 gr Hornady ELDX",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.358,
    "twist": 8.4,
    "hunting": true
  },
  {
    "name": ".284 cal 175 gr Hornady Interlock",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grHornadyInterlock_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 10.5,
    "hunting": true
  },
  {
    "name": ".284 cal 180 gr Hornady ELD Match",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.36,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".284 cal 130 gr Sierra MatchKing",
    "weight": 130,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal130grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.203,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".284 cal 140 gr Sierra HPBT GameKing",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grSierraHPBTGameKing_UPDATED-scaled.jpg",
    "G7": 0.183,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".284 cal 140 gr Sierra SBT GameKing",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grSierraSBTGameKing_UPDATED-scaled.jpg",
    "G7": 0.207,
    "twist": 12.1,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Sierra GameKing",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.22,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Sierra MatchKing",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.232,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".284 cal 160 gr Sierra HPBT GameKing",
    "weight": 160,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal160grSierraHPBTGameKing_UPDATED-scaled.jpg",
    "G7": 0.21,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".284 cal 160 gr Sierra Tiped MatchKing",
    "weight": 160,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal160grSierraTipedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.293,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".284 cal 168 gr Sierra MatchKing",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 10.1,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Sierra GameKing",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.293,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".284 cal 175 gr Sierra MatchKing",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.327,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".284 cal 180 gr Sierra MatchKing",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.337,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".284 cal 183 gr Sierra MatchKing pointed",
    "weight": 183,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal183grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.377,
    "twist": 8.4,
    "hunting": false
  },
  {
    "name": ".284 cal 197 gr Sierra MatchKing pointed",
    "weight": 197,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal197grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.4,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".284 cal 120 gr Nosler Ballistic Tip",
    "weight": 120,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal120grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.187,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".284 cal 140 gr Nosler Accubond",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.211,
    "twist": 10.2,
    "hunting": false
  },
  {
    "name": ".284 cal 140 gr Nosler Ballistic Silver Tip",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grNoslerBallisticSilverTip_UPDATED-scaled.jpg",
    "G7": 0.213,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".284 cal 140 gr Nosler E-Tip",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.207,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Nosler Ballistic Tip",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.235,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Nosler E-Tip",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.221,
    "twist": 9,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Nosler LR Accubond",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grNoslerLRAccubond_UPDATED-scaled.jpg",
    "G7": 0.295,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".284 cal 150 gr Nosler Partition",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.211,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".284 cal 160 gr Nosler Accubond",
    "weight": 160,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal160grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".284 cal 168 gr Nosler Custom Competition",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.269,
    "twist": 10.6,
    "hunting": false
  },
  {
    "name": ".284 cal 168 gr Nosler LR Accubond",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grNoslerLRAccubond_UPDATED-scaled.jpg",
    "G7": 0.332,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Nosler Long Range Accubond",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grNoslerLongRangeAccubond_UPDATED-scaled.jpg",
    "G7": 0.33,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Nosler Partition",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.245,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".284 cal 110 gr Barnes TTSX FB",
    "weight": 110,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal110grBarnesTTSXFB_UPDATED-scaled.jpg",
    "G7": 0.151,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".284 cal 120 gr Barnes TTSXBT",
    "weight": 120,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal120grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.183,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".284 cal 140 gr Barnes TTSXBT",
    "weight": 140,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal140grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.209,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".284 cal 145 gr Barnes LRXBT",
    "weight": 145,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal145grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Barnes TTSXBT",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.22,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Barnes LRXBT",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.256,
    "twist": 8,
    "hunting": true
  },
  {
    "name": ".284 cal 171 gr Barnes Match Burner",
    "weight": 171,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal171grBarnesMatchBurnerBurner_UPDATED-scaled.jpg",
    "G7": 0.31,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Barnes TSXFB",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grBarnesTSXFB_UPDATED-scaled.jpg",
    "G7": 0.207,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".284 cal 155 gr Cutting Edge MTH",
    "weight": 155,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal155grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 8.8,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Cutting Edge MTH max",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grCutting_EdgeMTHmax_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 8.4,
    "hunting": true
  },
  {
    "name": ".284 cal 180 gr Cutting Edge MTH",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.292,
    "twist": 7.7,
    "hunting": true
  },
  {
    "name": ".284 cal 133 gr Hammer Hunting",
    "weight": 133,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal133grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.185,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".284 cal 155 gr Hammer Hunting",
    "weight": 155,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal155grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.206,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".284 cal 177 gr Hammer Hunting",
    "weight": 177,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal177grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 7.8,
    "hunting": true
  },
  {
    "name": ".284 cal 163.5 gr Cauterucio VLD",
    "weight": 163.5,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal163.5grCauterucioVLD_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".284 cal 177 gr Cauterucio VLD",
    "weight": 177,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal177grCauterucioVLD_UPDATED-scaled.jpg",
    "G7": 0.349,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".284 cal 189 gr Cauterucio VLD",
    "weight": 189,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal189grCauterucioVLD_UPDATED-scaled.jpg",
    "G7": 0.36,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".284 cal 168 gr Matrix VLD",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grMatrixVLD_UPDATED-scaled.jpg",
    "G7": 0.311,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".284 cal 175 gr Matrix RBT",
    "weight": 175,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal175grMatrixRBT_UPDATED-scaled.jpg",
    "G7": 0.283,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".284 cal 190 gr Matrix VLD",
    "weight": 190,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal190grMatrixVLD_UPDATED-scaled.jpg",
    "G7": 0.344,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".284 cal 226 gr Chinchaga RBST",
    "weight": 226,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal226grChinchagaRBST_UPDATED-scaled.jpg",
    "G7": 0.362,
    "twist": 7.7,
    "hunting": true
  },
  {
    "name": ".284 cal 168 gr Chinchaga RBST",
    "weight": 168,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal168grChinchagaRBST_UPDATED-scaled.jpg",
    "G7": 0.279,
    "twist": 8.8,
    "hunting": true
  },
  {
    "name": ".284 cal 196 gr Chinchaga RBST",
    "weight": 196,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal196grChinchagaRBST_UPDATED-scaled.jpg",
    "G7": 0.318,
    "twist": 8.2,
    "hunting": true
  },
  {
    "name": ".284 cal 125 gr GS Custom SP",
    "weight": 125,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal125grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.187,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".284 cal 150 gr Lapua Scenar L",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.268,
    "twist": 9.8,
    "hunting": false
  },
  {
    "name": ".284 cal 150 gr Swift Scirocco II",
    "weight": 150,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal150grSwiftSciroccoII_UPDATED-scaled.jpg",
    "G7": 0.242,
    "twist": 9.2,
    "hunting": true
  },
  {
    "name": ".284 cal 151 gr Warner solid",
    "weight": 151,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal151grWarnersolid_UPDATED-scaled.jpg",
    "G7": 0.324,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".284 cal 180 gr JLK BTHP",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grJLKBTHP_UPDATED-scaled.jpg",
    "G7": 0.33,
    "twist": 9,
    "hunting": false
  },
  {
    "name": ".284 cal 180 gr Lapua Scenar L",
    "weight": 180,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal180grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.319,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".284 cal 200 gr Wildcat ULD",
    "weight": 200,
    "caliber": 0.284,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/284cal200grWildcatULD_UPDATED-scaled.jpg",
    "G7": 0.36,
    "twist": 8.7,
    "hunting": false
  },
  {
    "name": ".308 cal 230 gr Berger Target Hybrid",
    "weight": 230,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal230grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.374,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".308 cal 115 gr Berger Target FB",
    "weight": 115,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal115grBergerTargetFB_UPDATED-scaled.jpg",
    "G7": 0.148,
    "twist": 15.4,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Berger Target FB",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grBergerTargetFB_UPDATED-scaled.jpg",
    "G7": 0.2,
    "twist": 13.1,
    "hunting": false
  },
  {
    "name": ".308 cal 155.5 gr Berger Target FULLBORE",
    "weight": 155.5,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155.5grBergerTargetFULLBORE_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 12.3,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Berger Classic Hunter",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 13.3,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Berger Hunting VLD",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 12.3,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Berger Target Hybrid",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.245,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Berger Target VLD",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 11.9,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Berger Classic Hunter",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.25,
    "twist": 12.7,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Berger Hunting VLD",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.254,
    "twist": 12.2,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Berger Target Hybrid",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.262,
    "twist": 11.9,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Berger Target VLD",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.258,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Berger Hunting VLD",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 11.6,
    "hunting": true
  },
  {
    "name": ".308 cal 175 gr Berger Tactical OTM",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBergerTacticalOTM_UPDATED-scaled.jpg",
    "G7": 0.263,
    "twist": 12.5,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Berger Target Long Range BT",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBergerTargetLongRangeBT_UPDATED-scaled.jpg",
    "G7": 0.262,
    "twist": 11.8,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Berger Target VLD",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.262,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Berger Elite Hunter",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.295,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".308 cal 185 gr Berger Classic Hunter",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.273,
    "twist": 12.1,
    "hunting": true
  },
  {
    "name": ".308 cal 185 gr Berger Hunting VLD",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.281,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".308 cal 185 gr Berger Tactical OTM Juggernaut",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerTacticalOTMJuggernaut_UPDATED-scaled.jpg",
    "G7": 0.284,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 185 gr Berger Target Hybrid",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.295,
    "twist": 10.8,
    "hunting": false
  },
  {
    "name": ".308 cal 185 gr Berger Target LRBT Juggernaut",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerTargetLRBTJuggernaut_UPDATED-scaled.jpg",
    "G7": 0.283,
    "twist": 11.7,
    "hunting": false
  },
  {
    "name": ".308 cal 185 gr Berger Target VLD",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.281,
    "twist": 11.2,
    "hunting": false
  },
  {
    "name": ".308 cal 190 gr Berger Hunting VLD",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.292,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".308 cal 200 gr Berger Target Hybrid",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.316,
    "twist": 10.5,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Berger Target 20X",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grBergerTarget20X_UPDATED-scaled.jpg",
    "G7": 0.328,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr Berger Target VLD",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.32,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr Berger Target Long Range BT",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grBergerTargetLongRangeBT_UPDATED-scaled.jpg",
    "G7": 0.326,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr Berger Hunting VLD",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.321,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".308 cal 215 gr Berger Target Hybrid",
    "weight": 215,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal215grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.361,
    "twist": 9.8,
    "hunting": false
  },
  {
    "name": ".308 cal 230 gr Berger Tactical OTM Hybrid",
    "weight": 230,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal230grBergerTacticalOTMHybrid_UPDATED-scaled.jpg",
    "G7": 0.367,
    "twist": 10.1,
    "hunting": false
  },
  {
    "name": ".308 cal 110 gr Hornady GMX",
    "weight": 110,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal110grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.149,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".308 cal 110 gr Hornady Vmax",
    "weight": 110,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal110grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.136,
    "twist": 15.6,
    "hunting": true
  },
  {
    "name": ".308 cal 125 gr Hornady GMX",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.156,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Hornady BTSP",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grHornadyBTSP_UPDATED-scaled.jpg",
    "G7": 0.179,
    "twist": 14.2,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Hornady FMJBT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grHornadyFMJBT_UPDATED-scaled.jpg",
    "G7": 0.205,
    "twist": 13.7,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Hornady SST BT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.208,
    "twist": 12.4,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Hornady SST Savage",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grHornadySSTSavage_UPDATED-scaled.jpg",
    "G7": 0.187,
    "twist": 13.2,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Hornady Amax",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.213,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Hornady ELD Match",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 12.7,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Hornady HPBT Match",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grHornadyHPBTMatch_UPDATED-scaled.jpg",
    "G7": 0.206,
    "twist": 13.4,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Hornady TAP FFD",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grHornadyTAPFFD_UPDATED-scaled.jpg",
    "G7": 0.216,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Hornady BTSP Interlock",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grHornadyBTSPInterlock_UPDATED-scaled.jpg",
    "G7": 0.203,
    "twist": 13,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Hornady GMX",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Hornady SST BT",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Hornady Amax",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.23,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Hornady ELD Match",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.261,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Hornady HPBT Match",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grHornadyHPBTMatch_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 12.7,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Hornady TAP FFP",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grHornadyTAPFFP_UPDATED-scaled.jpg",
    "G7": 0.235,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".308 cal 178 gr Hornady Amax",
    "weight": 178,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal178grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.24,
    "twist": 11.8,
    "hunting": false
  },
  {
    "name": ".308 cal 178 gr Hornady ELD Match",
    "weight": 178,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal178grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.276,
    "twist": 11.8,
    "hunting": false
  },
  {
    "name": ".308 cal 178 gr Hornady HPBT Match",
    "weight": 178,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal178grHornadyHPBTMatch_UPDATED-scaled.jpg",
    "G7": 0.257,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Hornady GMX",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Hornady SST BT",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grHornadySSTBT_UPDATED-scaled.jpg",
    "G7": 0.253,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".308 cal 190 gr Hornady BTSP",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grHornadyBTSP_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 12.1,
    "hunting": true
  },
  {
    "name": ".308 cal 195 gr Hornady HPBT Match",
    "weight": 195,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal195grHornadyHPBTMatch_UPDATED-scaled.jpg",
    "G7": 0.293,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Hornady ELDX",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".308 cal 208 gr Hornady HPBT Match unpointed",
    "weight": 208,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal208grHornadyHPBTMatchunpointed_UPDATED-scaled.jpg",
    "G7": 0.319,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".308 cal 208 gr Hornady HPBT Match pointed",
    "weight": 208,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal208grHornadyHPBTMatchpointed_UPDATED-scaled.jpg",
    "G7": 0.302,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".308 cal 208 gr Hornady ELD Match",
    "weight": 208,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal208grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.338,
    "twist": 10.2,
    "hunting": false
  },
  {
    "name": ".308 cal 208 gr Hornady Amax",
    "weight": 208,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal208grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.325,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".308 cal 212 gr Hornady ELDX",
    "weight": 212,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal212grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.357,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".308 cal 225 gr Hornady HPBT Match pointed",
    "weight": 225,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal225grHornadyHPBTMatchpointed_UPDATED-scaled.jpg",
    "G7": 0.338,
    "twist": 10.2,
    "hunting": false
  },
  {
    "name": ".308 cal 225 gr Hornady ELD Match",
    "weight": 225,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal225grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.385,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr Hornady ELDX",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grHornadyELDX_UPDATED-scaled.jpg",
    "G7": 0.333,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".308 cal 125 gr Nosler AccuBond",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grNoslerAccuBond_UPDATED-scaled.jpg",
    "G7": 0.162,
    "twist": 13,
    "hunting": false
  },
  {
    "name": ".308 cal 125 gr Nosler Ballistic Tip",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.167,
    "twist": 13.4,
    "hunting": true
  },
  {
    "name": ".308 cal 140 gr Nosler Custom Competition",
    "weight": 140,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal140grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.166,
    "twist": 15.6,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Nosler Accubond",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.197,
    "twist": 12.1,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Nosler Ballistic Tip",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.203,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Nosler E-Tip",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.189,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Nosler Custom Competition",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.211,
    "twist": 13.5,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Nosler Accubond",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.228,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Nosler Ballistic Tip",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 11.6,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Nosler Partition",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 13.5,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Nosler Ballistic Tip",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.219,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Nosler Custom Competition",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.218,
    "twist": 13.4,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Nosler E-Tip",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.218,
    "twist": 10.3,
    "hunting": true
  },
  {
    "name": ".308 cal 175 gr Nosler Custom Competition",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.249,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Nosler RDF",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grNoslerRDF_UPDATED-scaled.jpg",
    "G7": 0.271,
    "twist": 11.7,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Nosler Accubond",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Nosler Ballistic Tip",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.241,
    "twist": 11.3,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Nosler E-Tip",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.225,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Nosler Partition",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.184,
    "twist": 13.8,
    "hunting": true
  },
  {
    "name": ".308 cal 190 gr Nosler Custom Competition",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.278,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 190 gr Nosler Long Range Accubond",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grNoslerLongRangeAccubond_UPDATED-scaled.jpg",
    "G7": 0.292,
    "twist": 10.4,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Nosler Accubond",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.268,
    "twist": 10.5,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr Nosler Long Range Accubond",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grNoslerLongRangeAccubond_UPDATED-scaled.jpg",
    "G7": 0.332,
    "twist": 9.9,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr Nosler Custom Competition",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.306,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Nosler Partition",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.249,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".308 cal 240 gr Sierra MatchKing",
    "weight": 240,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal240grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.331,
    "twist": 10.4,
    "hunting": false
  },
  {
    "name": ".308 cal 110 gr Sierra FBHP varmint",
    "weight": 110,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal110grSierraFBHPvarmint_UPDATED-scaled.jpg",
    "G7": 0.098,
    "twist": 19,
    "hunting": true
  },
  {
    "name": ".308 cal 125 gr Sierra MatchKing pointed",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.179,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 125 gr Sierra MatchKing unpointed",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grSierraMatchKingunpointed_UPDATED-scaled.jpg",
    "G7": 0.177,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 125 gr Sierra Tipped MatchKing",
    "weight": 125,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal125grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.176,
    "twist": 12.7,
    "hunting": false
  },
  {
    "name": ".308 cal 135 gr Sierra MatchKing",
    "weight": 135,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal135grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.182,
    "twist": 13.7,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Sierra FMJBT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grSierraFMJBT_UPDATED-scaled.jpg",
    "G7": 0.207,
    "twist": 13.8,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Sierra GameKing",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.19,
    "twist": 14.4,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Sierra MatchKing",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.2,
    "twist": 13.7,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Sierra MatchKing-2155",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grSierraMatchKing-2155_UPDATED-scaled.jpg",
    "G7": 0.214,
    "twist": 13.8,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Sierra Palma-2156",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grSierraPalma-2156_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 12.5,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Sierra Tipped MatchKing",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 11.5,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Sierra GameKing",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.213,
    "twist": 13.1,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Sierra HPBT GameKing",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grSierraHPBTGameKing_UPDATED-scaled.jpg",
    "G7": 0.178,
    "twist": 13.4,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Sierra MatchKing",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.218,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Sierra Tipped MatchKing",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.257,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".308 cal 173 gr Sierra FMJBT",
    "weight": 173,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal173grSierraFMJBT_UPDATED-scaled.jpg",
    "G7": 0.251,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Sierra MatchKing",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.243,
    "twist": 12.8,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Sierra Tipped MatchKing",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Sierra GameKing",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.242,
    "twist": 12.3,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Sierra MatchKing",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 190 gr Sierra MatchKing",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.267,
    "twist": 11.8,
    "hunting": false
  },
  {
    "name": ".308 cal 195 gr Sierra Tipped MatchKing",
    "weight": 195,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal195grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.298,
    "twist": 10.6,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Sierra MatchKing",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.285,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Sierra GameKing",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".308 cal 210 gr Sierra MatchKing",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.316,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr Sierra MatchKing",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.308,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".308 cal 110 gr Barnes TacX FB M/LE",
    "weight": 110,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal110grBarnesTacXFBM_LE_UPDATED-scaled.jpg",
    "G7": 0.138,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".308 cal 110 gr Barnes TTSX",
    "weight": 110,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal110grBarnesTTSX_UPDATED-scaled.jpg",
    "G7": 0.131,
    "twist": 12.9,
    "hunting": true
  },
  {
    "name": ".308 cal 130 gr Barnes TTSX BT",
    "weight": 130,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal130grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.174,
    "twist": 11.9,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Barnes TAC RRLP FB",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grBarnesTACRRLPFB_UPDATED-scaled.jpg",
    "G7": 0.18,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Barnes TAC-XBT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grBarnesTAC-XBT_UPDATED-scaled.jpg",
    "G7": 0.193,
    "twist": 11.9,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Barnes TTSX BT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.198,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Barnes Match Burner",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grBarnesMatchBurnerBurner_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 11.9,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Barnes TSX BT",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.198,
    "twist": 11.6,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Barnes TTSX BT",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.204,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Barnes Tac-X M/LE",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBarnesTac-XM_LE_UPDATED-scaled.jpg",
    "G7": 0.214,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Barnes TSX BT",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.2,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr Barnes TTSX BT",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.233,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".308 cal 175 gr Barnes LRXBT",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".308 cal 175 gr Barnes Match Burner",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grBarnesMatchBurnerBurner_UPDATED-scaled.jpg",
    "G7": 0.265,
    "twist": 11.5,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Barnes TSX BT",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.227,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".308 cal 200 gr Barnes LRXBT",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.281,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Lapua FMJBT",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.215,
    "twist": 13.9,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Lapua Scenar",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Lapua Scenar-L",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grLapuaScenar-L_UPDATED-scaled.jpg",
    "G7": 0.221,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".308 cal 167 gr Lapua Scenar",
    "weight": 167,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal167grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.216,
    "twist": 12.6,
    "hunting": false
  },
  {
    "name": ".308 cal 170 gr Lapua FMJBT",
    "weight": 170,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal170grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr Lapua Scenar-L",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grLapuaScenar-L_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 185 gr Lapua FMJBT (D46)",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grLapuaFMJBTD46_UPDATED-scaled.jpg",
    "G7": 0.261,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 185 gr Lapua Scenar",
    "weight": 185,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal185grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr Lapua FMJBT",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.249,
    "twist": 12.2,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr Lapua Scenar-L",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grLapuaScenar-L_UPDATED-scaled.jpg",
    "G7": 0.321,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".308 cal 140 gr Cutting Edge MTH",
    "weight": 140,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal140grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.202,
    "twist": 12.4,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Cutting Edge Lazer",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 10,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Cutting Edge MTH",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.256,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Cutting Edge HPBT",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grCutting_EdgeHPBT_UPDATED-scaled.jpg",
    "G7": 0.245,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Cutting Edge LRHPBT-Max",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grCutting_EdgeLRHPBT-Max_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Cutting Edge LZR MAX",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grCutting_EdgeLZRMAX_UPDATED-scaled.jpg",
    "G7": 0.279,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Cutting Edge LZR STD",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grCutting_EdgeLZRSTD_UPDATED-scaled.jpg",
    "G7": 0.269,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Cutting Edge MTH C21",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grCutting_EdgeMTHC21_UPDATED-scaled.jpg",
    "G7": 0.283,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".308 cal 190 gr Cutting Edge MTH max",
    "weight": 190,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal190grCutting_EdgeMTHmax_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".308 cal 168 gr RUAG Swiss P Target HPBT",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grRUAG_Swiss_P_TargetHPBT_UPDATED-scaled.jpg",
    "G7": 0.22,
    "twist": 13.1,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr DRT Frangible",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grDRTFrangible_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 13.1,
    "hunting": false
  },
  {
    "name": ".308 cal 175 gr RUAG Swiss Match HPBT",
    "weight": 175,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal175grRUAG_Swiss_MatchHPBT_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 12.8,
    "hunting": false
  },
  {
    "name": ".308 cal 195 gr RUAG Swiss FMJ",
    "weight": 195,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal195grRUAG_SwissFMJ_UPDATED-scaled.jpg",
    "G7": 0.252,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr RUAG Swiss FMJ",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grRUAG_SwissFMJ_UPDATED-scaled.jpg",
    "G7": 0.242,
    "twist": 12.3,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr RUAG Swiss Match HPBT",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grRUAG_Swiss_MatchHPBT_UPDATED-scaled.jpg",
    "G7": 0.284,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr RUAG Swiss Match HPBT",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grRUAG_Swiss_MatchHPBT_UPDATED-scaled.jpg",
    "G7": 0.312,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".308 cal 152 gr Hammer Hunting",
    "weight": 152,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal152grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.176,
    "twist": 11.9,
    "hunting": true
  },
  {
    "name": ".308 cal 166 gr Hammer Hunting",
    "weight": 166,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal166grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.211,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".308 cal 181 gr Hammer Hunting",
    "weight": 181,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal181grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.232,
    "twist": 9.7,
    "hunting": true
  },
  {
    "name": ".308 cal 214 gr Hammer Hunting",
    "weight": 214,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal214grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.272,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".308 cal 227 gr Hammer Hunting",
    "weight": 227,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal227grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.299,
    "twist": 7.9,
    "hunting": true
  },
  {
    "name": ".308 cal 137 gr GS Custom SP",
    "weight": 137,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal137grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.202,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".308 cal 158 gr GS Custom SP",
    "weight": 158,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal158grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.178,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".308 cal 167 gr GS Custom SP",
    "weight": 167,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal167grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.205,
    "twist": 10.2,
    "hunting": true
  },
  {
    "name": ".308 cal 197 gr GS Custom SP",
    "weight": 197,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal197grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.271,
    "twist": 8.7,
    "hunting": true
  },
  {
    "name": ".308 cal 150 gr Swift BT Spitzer",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grSwiftBTSpitzer_UPDATED-scaled.jpg",
    "G7": 0.2,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".308 cal 165 gr Swift BT Spitzer",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grSwiftBTSpitzer_UPDATED-scaled.jpg",
    "G7": 0.221,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".308 cal 180 gr Swift BT Spitzer",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grSwiftBTSpitzer_UPDATED-scaled.jpg",
    "G7": 0.246,
    "twist": 10.5,
    "hunting": true
  },
  {
    "name": ".308 cal 155 gr Warner Solid",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grWarnerSolid_UPDATED-scaled.jpg",
    "G7": 0.259,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Warner Solid",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grWarnerSolid_UPDATED-scaled.jpg",
    "G7": 0.337,
    "twist": 8.9,
    "hunting": false
  },
  {
    "name": ".308 cal 198 gr Warner Solid",
    "weight": 198,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal198grWarnerSolid_UPDATED-scaled.jpg",
    "G7": 0.382,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr JLK VLD",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grJLKVLD_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr JLK VLD Long BT",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grJLKVLDLongBT_UPDATED-scaled.jpg",
    "G7": 0.343,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr JLK VLD",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grJLKVLD_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".308 cal 147 gr DAG FMJ BT",
    "weight": 147,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal147grDAGFMJBT_UPDATED-scaled.jpg",
    "G7": 0.217,
    "twist": 13.6,
    "hunting": false
  },
  {
    "name": ".308 cal 150 gr Pri Partizan FMJ",
    "weight": 150,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal150grPri_PartizanFMJ_UPDATED-scaled.jpg",
    "G7": 0.197,
    "twist": 14.1,
    "hunting": false
  },
  {
    "name": ".308 cal 154 gr G9 Competition Match",
    "weight": 154,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal154grG9_CompetitionMatch_UPDATED-scaled.jpg",
    "G7": 0.247,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr Dyer HBC",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grDyerHBC_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 12.3,
    "hunting": false
  },
  {
    "name": ".308 cal 155 gr PMP HPBT (S. Africa)",
    "weight": 155,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal155grPMPHPBT_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 13.2,
    "hunting": false
  },
  {
    "name": ".308 cal 165 gr Federal Tactical",
    "weight": 165,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal165grFederalTactical_UPDATED-scaled.jpg",
    "G7": 0.17,
    "twist": 13.4,
    "hunting": false
  },
  {
    "name": ".308 cal 168 gr Matrix RBT",
    "weight": 168,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal168grMatrixRBT_UPDATED-scaled.jpg",
    "G7": 0.24,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".308 cal 180 gr Vapor Trail BTHP",
    "weight": 180,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal180grVapor_TrailBTHP_UPDATED-scaled.jpg",
    "G7": 0.274,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr KP Jack HPBT",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grKP_JackHPBT_UPDATED-scaled.jpg",
    "G7": 0.357,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".308 cal 200 gr DRT Frangible",
    "weight": 200,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal200grDRTFrangible_UPDATED-scaled.jpg",
    "G7": 0.295,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".308 cal 207 gr Vapor Trail BTHP",
    "weight": 207,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal207grVapor_TrailBTHP_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".308 cal 210 gr Matrix VLD",
    "weight": 210,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal210grMatrixVLD_UPDATED-scaled.jpg",
    "G7": 0.326,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".308 cal 220 gr Alco Tipped RBBT",
    "weight": 220,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal220grAlcoTippedRBBT_UPDATED-scaled.jpg",
    "G7": 0.322,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".308 cal 236 gr Chinchaga RBT ST",
    "weight": 236,
    "caliber": 0.308,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/308cal236grChinchagaRBTST_UPDATED-scaled.jpg",
    "G7": 0.356,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".338 cal 175 gr Cutting Edge MTH",
    "weight": 175,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal175grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.196,
    "twist": 14.3,
    "hunting": true
  },
  {
    "name": ".338 cal 200 gr Cutting Edge MTH",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.224,
    "twist": 12.3,
    "hunting": true
  },
  {
    "name": ".338 cal 225 gr Cutting Edge Lazer",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.312,
    "twist": 9.9,
    "hunting": false
  },
  {
    "name": ".338 cal 225 gr Cutting Edge MTH",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.304,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".338 cal 250 gr Cutting Edge Lazer",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.357,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".338 cal 275 gr Cutting Edge MTAC",
    "weight": 275,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal275grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.415,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".338 cal 275 gr Cutting Edge MTH",
    "weight": 275,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal275grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.412,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".338 cal 252 gr Cutting Edge MTH",
    "weight": 252,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal252grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.366,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".338 cal 254 gr Cutting Edge MTAC",
    "weight": 254,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal254grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.368,
    "twist": 9.9,
    "hunting": false
  },
  {
    "name": ".338 cal 265 gr Cutting Edge MTH",
    "weight": 265,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal265grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.393,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".338 cal 275 gr Cutting Edge Lazer",
    "weight": 275,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal275grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.4,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Cutting Edge MTH",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.407,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".338 cal 300 gr Cutting Edge HPBT",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grCutting_EdgeHPBT_UPDATED-scaled.jpg",
    "G7": 0.405,
    "twist": 9,
    "hunting": false
  },
  {
    "name": ".338 cal 302 gr Cutting Edge MTAC",
    "weight": 302,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal302grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.406,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".338 cal 185 gr Barnes TTSX BT",
    "weight": 185,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal185grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.205,
    "twist": 12.7,
    "hunting": true
  },
  {
    "name": ".338 cal 210 gr Barnes TTSX BT",
    "weight": 210,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal210grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.232,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".338 cal 225 gr Barnes TAC-TX BT",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grBarnesTAC-TXBT_UPDATED-scaled.jpg",
    "G7": 0.248,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".338 cal 225 gr Barnes TTSX BT",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.253,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".338 cal 265 gr Barnes LRX BT",
    "weight": 265,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal265grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.31,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".338 cal 265 gr Barnes TAC-TX BT",
    "weight": 265,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal265grBarnesTAC-TXBT_UPDATED-scaled.jpg",
    "G7": 0.299,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".338 cal 280 gr Barnes LRX BT",
    "weight": 280,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal280grBarnesLRXBT_UPDATED-scaled.jpg",
    "G7": 0.322,
    "twist": 9.8,
    "hunting": true
  },
  {
    "name": ".338 cal 285 gr Barnes TSX BT",
    "weight": 285,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal285grBarnesTSXBT_UPDATED-scaled.jpg",
    "G7": 0.302,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".338 cal 285 gr Barnes TAC-X BT",
    "weight": 285,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal285grBarnesTAC-XBT_UPDATED-scaled.jpg",
    "G7": 0.306,
    "twist": 10.4,
    "hunting": true
  },
  {
    "name": ".338 cal 185 gr Hornady GMX",
    "weight": 185,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal185grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.206,
    "twist": 11.8,
    "hunting": true
  },
  {
    "name": ".338 cal 200 gr Hornady FXT",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grHornadyFXT_UPDATED-scaled.jpg",
    "G7": 0.214,
    "twist": 15.2,
    "hunting": true
  },
  {
    "name": ".338 cal 200 gr Hornady SST",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grHornadySST_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 13.7,
    "hunting": true
  },
  {
    "name": ".338 cal 225 gr Hornady SST",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grHornadySST_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 12.9,
    "hunting": true
  },
  {
    "name": ".338 cal 250 gr Hornady BTHP Match",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grHornadyBTHPMatch_UPDATED-scaled.jpg",
    "G7": 0.315,
    "twist": 11.9,
    "hunting": false
  },
  {
    "name": ".338 cal 285 gr Hornady ELD match",
    "weight": 285,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal285grHornadyELDmatch_UPDATED-scaled.jpg",
    "G7": 0.397,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".338 cal 285 gr Hornady BTHP match",
    "weight": 285,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal285grHornadyBTHPMatch_UPDATED-scaled.jpg",
    "G7": 0.367,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".338 cal 285 gr Hornady Amax",
    "weight": 285,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal285grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.377,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".338 cal 180 gr Nosler Accubond",
    "weight": 180,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal180grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.209,
    "twist": 14.2,
    "hunting": false
  },
  {
    "name": ".338 cal 200 gr Nosler Accubond",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.236,
    "twist": 13.4,
    "hunting": false
  },
  {
    "name": ".338 cal 200 gr Nosler E-Tip",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.222,
    "twist": 12.2,
    "hunting": true
  },
  {
    "name": ".338 cal 225 gr Nosler Accubond",
    "weight": 225,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal225grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.268,
    "twist": 12.6,
    "hunting": false
  },
  {
    "name": ".338 cal 250 gr Nosler Accubond",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.298,
    "twist": 12.1,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Nosler Custom Competition",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.369,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".338 cal 250 gr Berger Elite Hunter",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.342,
    "twist": 11.3,
    "hunting": true
  },
  {
    "name": ".338 cal 250 gr Berger Tactical OTM",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grBergerTacticalOTM_UPDATED-scaled.jpg",
    "G7": 0.347,
    "twist": 11.2,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Berger Tactical OTM",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grBergerTacticalOTM_UPDATED-scaled.jpg",
    "G7": 0.429,
    "twist": 10.5,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Berger Elite Hunter",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grBergerEliteHunter_UPDATED-scaled.jpg",
    "G7": 0.429,
    "twist": 10.5,
    "hunting": true
  },
  {
    "name": ".338 cal 252 gr RUAG Swiss FMJ",
    "weight": 252,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal252grRuag_SwissFMJ_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 13.3,
    "hunting": false
  },
  {
    "name": ".338 cal 260 gr RUAG Swiss AP",
    "weight": 260,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal260grRuag_SwissAP_UPDATED-scaled.jpg",
    "G7": 0.327,
    "twist": 14,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr RUAG Swiss P Target",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grRUAGSwissPTarget_UPDATED-scaled.jpg",
    "G7": 0.379,
    "twist": 11.5,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr RUAG Swiss AP",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grRuag_SwissAP_UPDATED-scaled.jpg",
    "G7": 0.353,
    "twist": 12.1,
    "hunting": false
  },
  {
    "name": ".338 cal 250 gr Lapua FMJBT",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.308,
    "twist": 13.1,
    "hunting": false
  },
  {
    "name": ".338 cal 250 gr Lapua Scenar",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.305,
    "twist": 12.1,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Lapua Scenar",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.392,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".338 cal 250 gr Sierra SP GameKing",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grSierraSPGameKing_UPDATED-scaled.jpg",
    "G7": 0.266,
    "twist": 13.7,
    "hunting": true
  },
  {
    "name": ".338 cal 250 gr Sierra MatchKing",
    "weight": 250,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal250grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.314,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".338 cal 300 gr Sierra MatchKing",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.38,
    "twist": 11.6,
    "hunting": false
  },
  {
    "name": ".338 cal 200 gr GS Custom SP",
    "weight": 200,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal200grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.218,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".338 cal 232 gr GS Custom SP",
    "weight": 232,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal232grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.292,
    "twist": 9.6,
    "hunting": true
  },
  {
    "name": ".338 cal 267 gr GS Custom SP",
    "weight": 267,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal267grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.304,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".338 cal 230 gr Lehigh Defense Match Solid",
    "weight": 230,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal230grLehigh_DefenseMatchSolid_UPDATED-scaled.jpg",
    "G7": 0.282,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".338 cal 232 gr G9 Competition Match",
    "weight": 232,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal232grG9_CompetitionMatch_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 9.4,
    "hunting": false
  },
  {
    "name": ".338 cal 245 gr Lehigh Defense Match Solid",
    "weight": 245,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal245grLehigh_DefenseMatchSolid_UPDATED-scaled.jpg",
    "G7": 0.354,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".338 cal 256 gr Warner solid",
    "weight": 256,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal256grWarnersolid_UPDATED-scaled.jpg",
    "G7": 0.399,
    "twist": 9.1,
    "hunting": false
  },
  {
    "name": ".338 cal 260 gr Hammer Hunting",
    "weight": 260,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal260grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.291,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".338 cal 300 gr Chinchaga RBT ST",
    "weight": 300,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal300grChinchagaRBTST_UPDATED-scaled.jpg",
    "G7": 0.386,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".338 cal 307 gr Hammer Hunting",
    "weight": 307,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal307grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.362,
    "twist": 8.2,
    "hunting": true
  },
  {
    "name": ".338 cal 325 gr Rocky Mountain RBT",
    "weight": 325,
    "caliber": 0.338,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/338cal325grRocky_MountainRBT_UPDATED-scaled.jpg",
    "G7": 0.386,
    "twist": 9.5,
    "hunting": false
  },
  {
    "name": ".375 cal 300 gr Cutting Edge MTH",
    "weight": 300,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal300grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.331,
    "twist": 12.5,
    "hunting": true
  },
  {
    "name": ".375 cal 320 gr Cutting Edge MTH",
    "weight": 320,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal320grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.35,
    "twist": 12.2,
    "hunting": true
  },
  {
    "name": ".375 cal 325 gr Cutting Edge Lazer",
    "weight": 325,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal325grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.37,
    "twist": 10.8,
    "hunting": false
  },
  {
    "name": ".375 cal 330 gr Cutting Edge MTH",
    "weight": 330,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal330grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.384,
    "twist": 11.7,
    "hunting": true
  },
  {
    "name": ".375 cal 350 gr Cutting Edge Lazer",
    "weight": 350,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal350grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.393,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".375 cal 350 gr Cutting Edge MTH",
    "weight": 350,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal350grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.415,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".375 cal 352 gr Cutting Edge MTAC",
    "weight": 352,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal352grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.408,
    "twist": 10.9,
    "hunting": false
  },
  {
    "name": ".375 cal 375 gr Cutting Edge Lazer",
    "weight": 375,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal375grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.42,
    "twist": 9.9,
    "hunting": false
  },
  {
    "name": ".375 cal 377 gr Cutting Edge MTAC",
    "weight": 377,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal377grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.438,
    "twist": 10.5,
    "hunting": false
  },
  {
    "name": ".375 cal 400 gr Cutting Edge Lazer",
    "weight": 400,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal400grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.445,
    "twist": 9.6,
    "hunting": false
  },
  {
    "name": ".375 cal 402 gr Cutting Edge MTAC",
    "weight": 402,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal402grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.455,
    "twist": 10.1,
    "hunting": false
  },
  {
    "name": ".375 cal 425 gr Cutting Edge Lazer",
    "weight": 425,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal425grCutting_EdgeLazer_UPDATED-scaled.jpg",
    "G7": 0.471,
    "twist": 9.2,
    "hunting": false
  },
  {
    "name": ".375 cal 427 gr Cutting Edge MTAC",
    "weight": 427,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal427grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.481,
    "twist": 9.7,
    "hunting": false
  },
  {
    "name": ".375 cal 452 gr Cutting Edge MTAC",
    "weight": 452,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal452grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.504,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".375 cal 291 gr GS Custom SP",
    "weight": 291,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal291grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.284,
    "twist": 11.5,
    "hunting": true
  },
  {
    "name": ".375 cal 330 gr GS Custom SP",
    "weight": 330,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal330grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.297,
    "twist": 11.4,
    "hunting": true
  },
  {
    "name": ".375 cal 414 gr GS Custom SP",
    "weight": 414,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal414grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.365,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".375 cal 355 gr GS Custom SP",
    "weight": 355,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal355grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.338,
    "twist": 10.6,
    "hunting": true
  },
  {
    "name": ".375 cal 247 gr Peregrine Solid",
    "weight": 247,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal247grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.253,
    "twist": 13.3,
    "hunting": false
  },
  {
    "name": ".375 cal 300 gr Nosler Accubond",
    "weight": 300,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal300grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.253,
    "twist": 14.9,
    "hunting": false
  },
  {
    "name": ".375 cal 300 gr Peregrine Solid",
    "weight": 300,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal300grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.348,
    "twist": 12,
    "hunting": false
  },
  {
    "name": ".375 cal 300 gr Sierra GameKing",
    "weight": 300,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal300grSierraGameKing_UPDATED-scaled.jpg",
    "G7": 0.234,
    "twist": 17.4,
    "hunting": true
  },
  {
    "name": ".375 cal 330 gr Lehigh Defense Match Solid",
    "weight": 330,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal330grLehigh_DefenseMatchSolid_UPDATED-scaled.jpg",
    "G7": 0.39,
    "twist": 10.6,
    "hunting": false
  },
  {
    "name": ".375 cal 350 gr Sierra MatchKing",
    "weight": 350,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal350grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.375,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".375 cal 350 gr Peregrine Solid",
    "weight": 350,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal350grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.419,
    "twist": 11,
    "hunting": false
  },
  {
    "name": ".375 cal 350 gr CheyTac Solid",
    "weight": 350,
    "caliber": 0.375,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/375cal350grCheyTacSolid_UPDATED-scaled.jpg",
    "G7": 0.427,
    "twist": 10.3,
    "hunting": false
  },
  {
    "name": ".408 cal 305 gr CheyTac Solid",
    "weight": 305,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal305grCheyTacSolid_UPDATED-scaled.jpg",
    "G7": 0.279,
    "twist": 14.8,
    "hunting": false
  },
  {
    "name": ".408 cal 385 gr GS Custom SP",
    "weight": 385,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal385grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.323,
    "twist": 12.4,
    "hunting": true
  },
  {
    "name": ".408 cal 390 gr Cutting Edge MTH",
    "weight": 390,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal390grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.373,
    "twist": 13.5,
    "hunting": true
  },
  {
    "name": ".408 cal 395 gr Cutting Edge MTAC",
    "weight": 395,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal395grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.376,
    "twist": 13.4,
    "hunting": false
  },
  {
    "name": ".408 cal 400 gr HSM T50 Solid",
    "weight": 400,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal400grHSMT50Solid_UPDATED-scaled.jpg",
    "G7": 0.426,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".408 cal 400 gr Lehigh Defense Match Solid",
    "weight": 400,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal400grLehigh_DefenseMatchSolid_UPDATED-scaled.jpg",
    "G7": 0.396,
    "twist": 11.9,
    "hunting": false
  },
  {
    "name": ".408 cal 405 gr Peregrine Solid",
    "weight": 405,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal405grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.404,
    "twist": 12.7,
    "hunting": false
  },
  {
    "name": ".408 cal 415 gr Cutting Edge MTH",
    "weight": 415,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal415grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.408,
    "twist": 13,
    "hunting": true
  },
  {
    "name": ".408 cal 419 gr CheyTac Solid",
    "weight": 419,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal419grCheyTacSolid_UPDATED-scaled.jpg",
    "G7": 0.434,
    "twist": 11.5,
    "hunting": false
  },
  {
    "name": ".408 cal 420 gr Cutting Edge MTAC",
    "weight": 420,
    "caliber": 0.408,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/408cal420grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.398,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".416 cal 350 gr Barnes TTSX",
    "weight": 350,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal350grBarnesTTSX_UPDATED-scaled.jpg",
    "G7": 0.352,
    "twist": 11,
    "hunting": true
  },
  {
    "name": ".416 cal 398 gr Barrett Brass Solid",
    "weight": 398,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal398grBarrettBrassSolid_UPDATED-scaled.jpg",
    "G7": 0.357,
    "twist": 12.1,
    "hunting": false
  },
  {
    "name": ".416 cal 416 gr Lehigh Defense Solid Match",
    "weight": 416,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal416grLehigh_DefenseSolidMatch_UPDATED-scaled.jpg",
    "G7": 0.39,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".416 cal 420 gr Cutting Edge MTH",
    "weight": 420,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal420grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.402,
    "twist": 13.5,
    "hunting": true
  },
  {
    "name": ".416 cal 420 gr GS Custom SP",
    "weight": 420,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal420grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.309,
    "twist": 12.5,
    "hunting": true
  },
  {
    "name": ".416 cal 422 gr Cutting Edge MTAC",
    "weight": 422,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal422grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.41,
    "twist": 13.5,
    "hunting": false
  },
  {
    "name": ".416 cal 430 gr Peregrine Solid",
    "weight": 430,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal430grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.419,
    "twist": 12.9,
    "hunting": false
  },
  {
    "name": ".416 cal 444 gr Cutting Edge MTH",
    "weight": 444,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal444grCutting_EdgeMTH_UPDATED-scaled.jpg",
    "G7": 0.395,
    "twist": 13.5,
    "hunting": true
  },
  {
    "name": ".416 cal 446 gr Cutting Edge MTAC",
    "weight": 446,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal446grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.399,
    "twist": 13.6,
    "hunting": false
  },
  {
    "name": ".416 cal 450 gr Cutting Edge MTH max",
    "weight": 450,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal450grCutting_EdgeMTHmax_UPDATED-scaled.jpg",
    "G7": 0.418,
    "twist": 12.7,
    "hunting": true
  },
  {
    "name": ".416 cal 472 gr Cutting Edge MTAC",
    "weight": 472,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal472grCutting_EdgeMTAC_UPDATED-scaled.jpg",
    "G7": 0.413,
    "twist": 12.4,
    "hunting": false
  },
  {
    "name": ".416 cal 500 gr Peregrine Solid",
    "weight": 500,
    "caliber": 0.416,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/416cal500grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.487,
    "twist": 11.8,
    "hunting": false
  },
  {
    "name": ".510 cal 647 gr Barnes Tac-X TSX",
    "weight": 647,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal647grBarnesTac-XTSX_UPDATED-scaled.jpg",
    "G7": 0.301,
    "twist": 21.6,
    "hunting": true
  },
  {
    "name": ".510 cal 647 gr Freedom Munitions FMJ",
    "weight": 647,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal647grFreedom_MunitionsFMJ_UPDATED-scaled.jpg",
    "G7": 0.345,
    "twist": 16.2,
    "hunting": false
  },
  {
    "name": ".510 cal 650 gr Lehigh Defense Solid Match",
    "weight": 650,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal650grLehigh_DefenseSolidMatch_UPDATED-scaled.jpg",
    "G7": 0.37,
    "twist": 18.6,
    "hunting": false
  },
  {
    "name": ".510 cal 660 gr PMC FMJ",
    "weight": 660,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal660grPMC_FMJ_UPDATED-scaled.jpg",
    "G7": 0.328,
    "twist": 16.4,
    "hunting": false
  },
  {
    "name": ".510 cal 720 gr Cutting Edge MTAC X03",
    "weight": 720,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal720grCutting_EdgeMTAC_X03_UPDATED-scaled.jpg",
    "G7": 0.429,
    "twist": 17.9,
    "hunting": false
  },
  {
    "name": ".510 cal 720 gr GS Custom SP",
    "weight": 720,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal720grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.369,
    "twist": 16,
    "hunting": true
  },
  {
    "name": ".510 cal 750 gr Barnes LR Solid Bore Rider",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grBarnesLRSolidBoreRider_UPDATED-scaled.jpg",
    "G7": 0.459,
    "twist": 14.4,
    "hunting": true
  },
  {
    "name": ".510 cal 750 gr Barnes Tac-LR",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grBarnesTac-LR_UPDATED-scaled.jpg",
    "G7": 0.43,
    "twist": 14.8,
    "hunting": true
  },
  {
    "name": ".510 cal 750 gr Hornady Amax",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.508,
    "twist": 15.1,
    "hunting": false
  },
  {
    "name": ".510 cal 750 gr Lehigh Defense Controlled Chaos",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grLehigh_DefenseControlledChaos_UPDATED-scaled.jpg",
    "G7": 0.409,
    "twist": 16.6,
    "hunting": true
  },
  {
    "name": ".510 cal 750 gr Lehigh Defense Solid Match",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grLehigh_DefenseSolidMatch_UPDATED-scaled.jpg",
    "G7": 0.412,
    "twist": 14.9,
    "hunting": false
  },
  {
    "name": ".510 cal 750 gr Peregrine Solid",
    "weight": 750,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal750grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.469,
    "twist": 16.3,
    "hunting": false
  },
  {
    "name": ".510 cal 762 gr Cutting Edge MTAC X02",
    "weight": 762,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal762grCutting_EdgeMTAC_X02_UPDATED-scaled.jpg",
    "G7": 0.487,
    "twist": 16.7,
    "hunting": false
  },
  {
    "name": ".510 cal 800 gr Barnes LR Solid",
    "weight": 800,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal800grBarnesLRSolid_UPDATED-scaled.jpg",
    "G7": 0.525,
    "twist": 13.5,
    "hunting": true
  },
  {
    "name": ".510 cal 800 gr GS Custom SP",
    "weight": 800,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal800grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.427,
    "twist": 15.1,
    "hunting": true
  },
  {
    "name": ".510 cal 800 gr Lapua Bullex-N",
    "weight": 800,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal800grLapuaBullex-N_UPDATED-scaled.jpg",
    "G7": 0.494,
    "twist": 14.3,
    "hunting": false
  },
  {
    "name": ".510 cal 802 gr Cutting Edge MTAC X01",
    "weight": 802,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal802grCutting_EdgeMTAC_X01_UPDATED-scaled.jpg",
    "G7": 0.496,
    "twist": 16,
    "hunting": false
  },
  {
    "name": ".510 cal 808 gr Lehigh Defense Solid Match",
    "weight": 808,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal808grLehigh_DefenseSolidMatch_UPDATED-scaled.jpg",
    "G7": 0.459,
    "twist": 14.6,
    "hunting": false
  },
  {
    "name": ".510 cal 850 gr Peregrine Solid",
    "weight": 850,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal850grPeregrineSolid_UPDATED-scaled.jpg",
    "G7": 0.549,
    "twist": 15.2,
    "hunting": false
  },
  {
    "name": ".510 cal 1030 gr GS Custom SP",
    "weight": 1030,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal1030grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.58,
    "twist": 11.6,
    "hunting": true
  },
  {
    "name": ".510 cal 1100 gr GS Custom SP",
    "weight": 1100,
    "caliber": 0.51,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/510cal1100grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.607,
    "twist": 12,
    "hunting": true
  },
  {
    "name": ".243 cal 100 gr Hornady BTSP",
    "weight": 100,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal100grHornadyBTSP_UPDATED-scaled.jpg",
    "G7": 0.198,
    "twist": 9.4,
    "hunting": true
  },
  {
    "name": ".243 cal 100 gr Sierra ProHunter",
    "weight": 100,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal100grSierraProHunter_UPDATED-scaled.jpg",
    "G7": 0.183,
    "twist": 10.2,
    "hunting": true
  },
  {
    "name": ".243 cal 101 gr Hammer Hunting",
    "weight": 101,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal101grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.187,
    "twist": 7.1,
    "hunting": true
  },
  {
    "name": ".243 cal 103 gr Vapor Trail BTHP",
    "weight": 103,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal103grVapor_TrailBTHP_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 8,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Barts RBT",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grBartsRBT_UPDATED-scaled.jpg",
    "G7": 0.257,
    "twist": 8,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Berger Hunting VLD",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.281,
    "twist": 8.1,
    "hunting": true
  },
  {
    "name": ".243 cal 105 gr Berger Target Hybrid",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grBergerTargetHybrid_UPDATED-scaled.jpg",
    "G7": 0.273,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Berger Target VLD",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.265,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Hornady Amax",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grHornadyAmax_UPDATED-scaled.jpg",
    "G7": 0.25,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Hornady BTHP Match pointed",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grHornadyBTHPMatchpointed_UPDATED-scaled.jpg",
    "G7": 0.234,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr JLK VLD",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grJLKVLD_UPDATED-scaled.jpg",
    "G7": 0.267,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Lapua Scenar L",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.258,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Lapua Scenar",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.248,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Nosler Custom Competition",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grNoslerCustomCompetition_UPDATED-scaled.jpg",
    "G7": 0.277,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".243 cal 105 gr Nosler RDF",
    "weight": 105,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal105grNoslerRDF_UPDATED-scaled.jpg",
    "G7": 0.277,
    "twist": 7.9,
    "hunting": false
  },
  {
    "name": ".243 cal 107 gr Matrix VLD",
    "weight": 107,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal107grMatrixVLD_UPDATED-scaled.jpg",
    "G7": 0.264,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".243 cal 107 gr Sierra MatchKing",
    "weight": 107,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal107grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.26,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".243 cal 107 gr Sierra MatchKing pointed",
    "weight": 107,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal107grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.256,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".243 cal 108 gr Hornady ELD Match",
    "weight": 108,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal108grHornadyELDMatch_UPDATED-scaled.jpg",
    "G7": 0.268,
    "twist": 7.8,
    "hunting": false
  },
  {
    "name": ".243 cal 108 gr Precision Ballistics VLD",
    "weight": 108,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal108grPrecision_BallisticsVLD_UPDATED-scaled.jpg",
    "G7": 0.252,
    "twist": 8.3,
    "hunting": true
  },
  {
    "name": ".243 cal 109 gr GS Custom SP",
    "weight": 109,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal109grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.251,
    "twist": 6.1,
    "hunting": true
  },
  {
    "name": ".243 cal 110 gr Sierra MatchKing pointed",
    "weight": 110,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal110grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.302,
    "twist": 7.2,
    "hunting": false
  },
  {
    "name": ".243 cal 113 gr Precision Ballistics VLD",
    "weight": 113,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal113grPrecision_BallisticsVLD_UPDATED-scaled.jpg",
    "G7": 0.256,
    "twist": 7.4,
    "hunting": true
  },
  {
    "name": ".243 cal 115 gr Berger Hunting VLD",
    "weight": 115,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal115grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.291,
    "twist": 7.3,
    "hunting": true
  },
  {
    "name": ".243 cal 115 gr Berger Target VLD",
    "weight": 115,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal115grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.289,
    "twist": 7.5,
    "hunting": false
  },
  {
    "name": ".243 cal 115 gr DTAC RBTHP",
    "weight": 115,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal115grDTACRBTHP_UPDATED-scaled.jpg",
    "G7": 0.302,
    "twist": 7.4,
    "hunting": false
  },
  {
    "name": ".243 cal 115 gr Precision Ballistics VLD",
    "weight": 115,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal115grPrecision_BallisticsVLD_UPDATED-scaled.jpg",
    "G7": 0.254,
    "twist": 7.5,
    "hunting": true
  },
  {
    "name": ".243 cal 58 gr Hornady Vmax",
    "weight": 58,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal58grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.119,
    "twist": 11.2,
    "hunting": true
  },
  {
    "name": ".243 cal 62 gr Berger Target EUWIN",
    "weight": 62,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal62grBergerTargetEUWIN_UPDATED-scaled.jpg",
    "G7": 0.116,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".243 cal 64.25 gr Berger Target Column",
    "weight": 64.25,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal64.25grBergerTargetColumn_UPDATED-scaled.jpg",
    "G7": 0.12,
    "twist": 11.4,
    "hunting": false
  },
  {
    "name": ".243 cal 65 gr Berger Target BT",
    "weight": 65,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal65grBergerTargetBT_UPDATED-scaled.jpg",
    "G7": 0.143,
    "twist": 11.1,
    "hunting": false
  },
  {
    "name": ".243 cal 65 gr Berger Target WEB BR",
    "weight": 65,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal65grBergerTargetWEBBR_UPDATED-scaled.jpg",
    "G7": 0.124,
    "twist": 10.8,
    "hunting": false
  },
  {
    "name": ".243 cal 65 gr Hornady Vmax",
    "weight": 65,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal65grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.134,
    "twist": 10.9,
    "hunting": true
  },
  {
    "name": ".243 cal 68 gr Berger Target FB",
    "weight": 68,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal68grBergerTargetFB_UPDATED-scaled.jpg",
    "G7": 0.128,
    "twist": 11.3,
    "hunting": false
  },
  {
    "name": ".243 cal 69 gr Berger Varmint High BC FB",
    "weight": 69,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal69grBergerVarmintHighBCFB_UPDATED-scaled.jpg",
    "G7": 0.148,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".243 cal 70 gr Nosler Ballistic Tip",
    "weight": 70,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal70grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.143,
    "twist": 10.1,
    "hunting": true
  },
  {
    "name": ".243 cal 70 gr Sierra MatchKing",
    "weight": 70,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal70grSierraMatchKing_UPDATED-scaled.jpg",
    "G7": 0.132,
    "twist": 11.5,
    "hunting": false
  },
  {
    "name": ".243 cal 75 gr Hornady Vmax",
    "weight": 75,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal75grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.163,
    "twist": 10,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Barnes TTSXBT",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grBarnesTTSXBT_UPDATED-scaled.jpg",
    "G7": 0.159,
    "twist": 8.5,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Berger Varmint FB",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grBergerVarmintFB_UPDATED-scaled.jpg",
    "G7": 0.154,
    "twist": 10.8,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Hammer Hunting",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.143,
    "twist": 9,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Hornady GMX",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grHornadyGMX_UPDATED-scaled.jpg",
    "G7": 0.144,
    "twist": 8.2,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Nosler Ballistic Tip",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grNoslerBallisticTip_UPDATED-scaled.jpg",
    "G7": 0.165,
    "twist": 9.5,
    "hunting": true
  },
  {
    "name": ".243 cal 80 gr Sierra Blitz",
    "weight": 80,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal80grSierraBlitz_UPDATED-scaled.jpg",
    "G7": 0.154,
    "twist": 11.1,
    "hunting": true
  },
  {
    "name": ".243 cal 81 gr GS Custom SP",
    "weight": 81,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal81grGS_CustomSP_UPDATED-scaled.jpg",
    "G7": 0.164,
    "twist": 8.1,
    "hunting": true
  },
  {
    "name": ".243 cal 85 gr G9 Competition Solid",
    "weight": 85,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal85grG9_CompetitionSolid_UPDATED-scaled.jpg",
    "G7": 0.24,
    "twist": 7,
    "hunting": false
  },
  {
    "name": ".243 cal 85 gr Hornady Interbond",
    "weight": 85,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal85grHornadyInterbond_UPDATED-scaled.jpg",
    "G7": 0.19,
    "twist": 8.8,
    "hunting": true
  },
  {
    "name": ".243 cal 87 gr Berger Hunting VLD",
    "weight": 87,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal87grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.217,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".243 cal 87 gr Hornady BTHP",
    "weight": 87,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal87grHornadyBTHP_UPDATED-scaled.jpg",
    "G7": 0.178,
    "twist": 10.4,
    "hunting": false
  },
  {
    "name": ".243 cal 87 gr Hornady Vmax",
    "weight": 87,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal87grHornadyVmax_UPDATED-scaled.jpg",
    "G7": 0.196,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".243 cal 88 gr Berger Varmint HBC FB",
    "weight": 88,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal88grBergerVarmintHBCFB_UPDATED-scaled.jpg",
    "G7": 0.191,
    "twist": 9.1,
    "hunting": true
  },
  {
    "name": ".243 cal 88 gr Hammer Hunting",
    "weight": 88,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal88grHammerHunting_UPDATED-scaled.jpg",
    "G7": 0.165,
    "twist": 7.8,
    "hunting": true
  },
  {
    "name": ".243 cal 90 gr Berger Target BT",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grBergerTargetBT_UPDATED-scaled.jpg",
    "G7": 0.21,
    "twist": 9.3,
    "hunting": false
  },
  {
    "name": ".243 cal 90 gr Lapua FMJBT",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grLapuaFMJBT_UPDATED-scaled.jpg",
    "G7": 0.185,
    "twist": 10.7,
    "hunting": false
  },
  {
    "name": ".243 cal 90 gr Lapua Scenar L",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grLapuaScenarL_UPDATED-scaled.jpg",
    "G7": 0.211,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".243 cal 90 gr Lapua Scenar",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grLapuaScenar_UPDATED-scaled.jpg",
    "G7": 0.218,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".243 cal 90 gr Nosler Accubond",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grNoslerAccubond_UPDATED-scaled.jpg",
    "G7": 0.182,
    "twist": 8.8,
    "hunting": false
  },
  {
    "name": ".243 cal 90 gr Nosler E-Tip",
    "weight": 90,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal90grNoslerE-Tip_UPDATED-scaled.jpg",
    "G7": 0.164,
    "twist": 8,
    "hunting": true
  },
  {
    "name": ".243 cal 95 gr Berger Classic Hunter",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grBergerClassicHunter_UPDATED-scaled.jpg",
    "G7": 0.223,
    "twist": 9.3,
    "hunting": true
  },
  {
    "name": ".243 cal 95 gr Berger Hunting VLD",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grBergerHuntingVLD_UPDATED-scaled.jpg",
    "G7": 0.239,
    "twist": 8.6,
    "hunting": true
  },
  {
    "name": ".243 cal 95 gr Berger Target VLD",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grBergerTargetVLD_UPDATED-scaled.jpg",
    "G7": 0.237,
    "twist": 8.6,
    "hunting": false
  },
  {
    "name": ".243 cal 95 gr Hornady SST",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grHornadySST_UPDATED-scaled.jpg",
    "G7": 0.18,
    "twist": 8.9,
    "hunting": true
  },
  {
    "name": ".243 cal 95 gr Nosler Partition",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grNoslerPartition_UPDATED-scaled.jpg",
    "G7": 0.177,
    "twist": 9.9,
    "hunting": true
  },
  {
    "name": ".243 cal 95 gr Sierra MatchKing pointed",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grSierraMatchKingpointed_UPDATED-scaled.jpg",
    "G7": 0.235,
    "twist": 8.2,
    "hunting": false
  },
  {
    "name": ".243 cal 95 gr Sierra MatchKing unpointed",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grSierraMatchKingunpointed_UPDATED-scaled.jpg",
    "G7": 0.231,
    "twist": 8.1,
    "hunting": false
  },
  {
    "name": ".243 cal 95 gr Sierra Tipped MatchKing",
    "weight": 95,
    "caliber": 0.243,
    "url": "https://thescienceofaccuracy.com/wp-content/uploads/2022/06/243cal95grSierraTippedMatchKing_UPDATED-scaled.jpg",
    "G7": 0.238,
    "twist": 8.1,
    "hunting": false
  }
]

export default bullets
