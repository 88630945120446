"use strict";
exports.__esModule = true;
exports.lookupGravity = void 0;
var table = {
    '0': {
        '0': 32.0876314738596,
        '1': 32.087666542783516,
        '2': 32.08766083850143,
        '3': 32.08770438977407,
        '4': 32.08789187302197,
        '5': 32.08810498994202,
        '6': 32.088199265362306,
        '7': 32.08819996715296,
        '8': 32.08809838281608,
        '9': 32.08780622706475,
        '10': 32.08721445362249,
        '11': 32.08538239248869,
        '12': 32.08500630990023,
        '13': 32.08259056087866,
        '14': 32.081699171575984,
        '15': 32.08290601975597,
        '16': 32.083462946346515,
        '17': 32.08354857870549,
        '18': 32.08308308240187,
        '19': 32.082741219393306,
        '20': 32.08277793607075,
        '21': 32.08291473571675,
        '22': 32.082297422529926,
        '23': 32.08130299678578,
        '24': 32.0820110724973,
        '25': 32.081674434443954,
        '26': 32.081566557627774,
        '27': 32.079648566588034,
        '28': 32.08006037046051,
        '29': 32.075708701476366,
        '30': 32.078484912759656,
        '31': 32.074544122092604,
        '32': 32.075471877736945,
        '33': 32.08322068763836,
        '34': 32.07594732769232,
        '35': 32.0706427000678,
        '36': 32.07309211594149,
        '37': 32.070255087068254,
        '38': 32.08079838754148,
        '39': 32.08477652119354,
        '40': 32.08511714612029,
        '41': 32.08635309119159,
        '42': 32.08723418369073,
        '43': 32.087053946306845,
        '44': 32.08654481728706,
        '45': 32.08647143497077,
        '46': 32.08652812716375,
        '47': 32.086340874211075,
        '48': 32.08624921083832,
        '49': 32.08663615542564,
        '50': 32.08713863829301,
        '51': 32.0872438788135,
        '52': 32.08713401166925,
        '53': 32.087262608380776,
        '54': 32.08755852065643,
        '55': 32.08766900938937,
        '56': 32.08758418901433,
        '57': 32.0874790051319,
        '58': 32.08730748381868,
        '59': 32.087016498680406,
        '60': 32.08682193684758,
        '61': 32.08688556438622,
        '62': 32.087017842180195,
        '63': 32.08699752352099,
        '64': 32.086905724056905,
        '65': 32.08691580147163,
        '66': 32.08700372442409,
        '67': 32.08703714883433,
        '68': 32.08694862463391,
        '69': 32.08673121532809,
        '70': 32.086452245146916,
        '71': 32.086301729959324,
        '72': 32.086420266813164,
        '73': 32.086650623963806,
        '74': 32.086668728065725,
        '75': 32.08641108754349,
        '76': 32.08616059776019,
        '77': 32.08611063632819,
        '78': 32.08607724581839,
        '79': 32.085856132121144,
        '80': 32.08564682334663,
        '81': 32.08576387654707,
        '82': 32.08607447436267,
        '83': 32.08619162228528,
        '84': 32.08614080259152,
        '85': 32.08627529583769,
        '86': 32.08658461063993,
        '87': 32.086746760660255,
        '88': 32.08682037957871,
        '89': 32.08711113248446,
        '90': 32.08741030646956,
        '91': 32.087290093522206,
        '92': 32.08710220873281,
        '93': 32.087584802384534,
        '94': 32.08845431365416,
        '95': 32.088613507630434,
        '96': 32.08791355865706,
        '97': 32.08747300019305,
        '98': 32.087978292890526,
        '99': 32.08880938222204,
        '100': 32.08540586556271,
        '101': 32.088207468442135,
        '102': 32.08859025725024,
        '103': 32.08826979042642,
        '104': 32.088523344260565,
        '105': 32.08871529499349,
        '106': 32.0888641693791,
        '107': 32.088758223828684,
        '108': 32.0885667443429,
        '109': 32.08858215469299,
        '110': 32.08840054125871,
        '111': 32.088851766361245,
        '112': 32.08850064802412,
        '113': 32.08752615934242,
        '114': 32.08546852808363,
        '115': 32.087043775763966,
        '116': 32.088410436533664,
        '117': 32.088855400825786,
        '118': 32.08843935548731,
        '119': 32.08830198784114,
        '120': 32.08507198406096,
        '121': 32.08974502153546,
        '122': 32.08972708245974,
        '123': 32.088621630261265,
        '124': 32.08662087362647,
        '125': 32.084692801233174,
        '126': 32.084713315943695,
        '127': 32.08750503556369,
        '128': 32.09087309389378,
        '129': 32.09173867818467,
        '130': 32.090179339588694,
        '131': 32.08900132190184,
        '132': 32.08939903290236,
        '133': 32.08978560487539,
        '134': 32.089083712402285,
        '135': 32.08826765549551,
        '136': 32.08810964698231,
        '137': 32.08802024707029,
        '138': 32.08774111542678,
        '139': 32.08793166903797,
        '140': 32.088595741687676,
        '141': 32.08885686514206,
        '142': 32.08852780864473,
        '143': 32.08823377195738,
        '144': 32.088056395898924,
        '145': 32.08760009145619,
        '146': 32.08712919284352,
        '147': 32.08722528674067,
        '148': 32.08767886798793,
        '149': 32.087878997646634,
        '150': 32.087795749743464,
        '151': 32.08767782506944,
        '152': 32.08746692605912,
        '153': 32.0872381190217,
        '154': 32.08742633563443,
        '155': 32.088041636986794,
        '156': 32.088458350938694,
        '157': 32.08834850775799,
        '158': 32.088040097419466,
        '159': 32.08781483056342,
        '160': 32.087641225076055,
        '161': 32.08757010237802,
        '162': 32.087656597529836,
        '163': 32.08767218326074,
        '164': 32.08750800681805,
        '165': 32.08748388514681,
        '166': 32.08773890580107,
        '167': 32.08787527499519,
        '168': 32.087666262469924,
        '169': 32.087456313929124,
        '170': 32.0874803509745,
        '171': 32.08750126192432,
        '172': 32.08740459355305,
        '173': 32.08742214767265,
        '174': 32.08758089244943,
        '175': 32.08761984598306,
        '176': 32.0875168578878,
        '177': 32.08748026714208,
        '178': 32.08749878749959,
        '179': 32.08744675880112,
        '180': 32.08742793127531,
        '-180': 32.08742793127531,
        '-179': 32.08754700643061,
        '-178': 32.08764204885433,
        '-177': 32.087601676470925,
        '-176': 32.08756614431585,
        '-175': 32.08758244403358,
        '-174': 32.08751317714313,
        '-173': 32.08740302267735,
        '-172': 32.0874442246405,
        '-171': 32.08757158226373,
        '-170': 32.08757496727027,
        '-169': 32.08750383162527,
        '-168': 32.087518387870745,
        '-167': 32.08757053185262,
        '-166': 32.087606786014014,
        '-165': 32.087737021096686,
        '-164': 32.08791350395153,
        '-163': 32.087901979106405,
        '-162': 32.08775707592591,
        '-161': 32.0877796896836,
        '-160': 32.08794573708762,
        '-159': 32.088002301463824,
        '-158': 32.08804019603457,
        '-157': 32.08826307836997,
        '-156': 32.08841241848934,
        '-155': 32.08819735659473,
        '-154': 32.087960772682216,
        '-153': 32.0881142057381,
        '-152': 32.08835455801815,
        '-151': 32.08825302477127,
        '-150': 32.08807807000823,
        '-149': 32.08822239895112,
        '-148': 32.088416769860515,
        '-147': 32.08829340752503,
        '-146': 32.088103198468716,
        '-145': 32.08817216141612,
        '-144': 32.08828227125883,
        '-143': 32.088179725926786,
        '-142': 32.088057708887895,
        '-141': 32.08807973833752,
        '-140': 32.08806021049082,
        '-139': 32.087930068198254,
        '-138': 32.087894090185564,
        '-137': 32.087957013255604,
        '-136': 32.08790026740333,
        '-135': 32.08776510577722,
        '-134': 32.08778129670916,
        '-133': 32.08789351861273,
        '-132': 32.08788807938072,
        '-131': 32.08781716971961,
        '-130': 32.08783363201416,
        '-129': 32.08785365656026,
        '-128': 32.0877667296763,
        '-127': 32.087679093643956,
        '-126': 32.087667145519994,
        '-125': 32.087628789037346,
        '-124': 32.08754715225258,
        '-123': 32.087533289233555,
        '-122': 32.087551420496865,
        '-121': 32.08748065479315,
        '-120': 32.087395760852594,
        '-119': 32.08741746234519,
        '-118': 32.08744571196715,
        '-117': 32.087387308080274,
        '-116': 32.08738563061546,
        '-115': 32.08751188323795,
        '-114': 32.087574944033044,
        '-113': 32.08750190435541,
        '-112': 32.087493078532034,
        '-111': 32.08759981679413,
        '-110': 32.087619290986886,
        '-109': 32.087535846342185,
        '-108': 32.08755575208575,
        '-107': 32.087654508231424,
        '-106': 32.08762169800232,
        '-105': 32.08751696962871,
        '-104': 32.087542721023446,
        '-103': 32.08759863157168,
        '-102': 32.08750201762155,
        '-101': 32.087420121152135,
        '-100': 32.087550106152115,
        '-99': 32.08768901670944,
        '-98': 32.08763249780435,
        '-97': 32.08758315722411,
        '-96': 32.0876756729412,
        '-95': 32.087626232041345,
        '-94': 32.08734716309767,
        '-93': 32.087287500155384,
        '-92': 32.08764692490985,
        '-91': 32.08793347785048,
        '-90': 32.08782353473531,
        '-89': 32.08770502657623,
        '-88': 32.087822653388294,
        '-87': 32.08774083781148,
        '-86': 32.08730372096806,
        '-85': 32.08720838976583,
        '-84': 32.087806956541314,
        '-83': 32.08825932672558,
        '-82': 32.087921267232446,
        '-81': 32.08773848648797,
        '-80': 32.08445236040188,
        '-79': 32.08278480823024,
        '-78': 32.04431786807942,
        '-77': 32.0857595933979,
        '-76': 32.08472341751139,
        '-75': 32.0853804778863,
        '-74': 32.086513447084364,
        '-73': 32.08721596618905,
        '-72': 32.08710305230539,
        '-71': 32.08668461732826,
        '-70': 32.08665018371999,
        '-69': 32.08722729832133,
        '-68': 32.08735500916646,
        '-67': 32.08699494662401,
        '-66': 32.08705575324507,
        '-65': 32.08693915052568,
        '-64': 32.08714586454709,
        '-63': 32.087140388921426,
        '-62': 32.087519200004174,
        '-61': 32.087562015298914,
        '-60': 32.08660021789825,
        '-59': 32.0841323370209,
        '-58': 32.08383082981606,
        '-57': 32.08439601631358,
        '-56': 32.08454629061991,
        '-55': 32.081696319729154,
        '-54': 32.08393731904587,
        '-53': 32.085601043967365,
        '-52': 32.08650214365005,
        '-51': 32.087866025222084,
        '-50': 32.08739336916457,
        '-49': 32.087101618129225,
        '-48': 32.087238379025045,
        '-47': 32.087673984369495,
        '-46': 32.08795097950897,
        '-45': 32.08782814351375,
        '-44': 32.08736661321601,
        '-43': 32.0868879328998,
        '-42': 32.086761321824895,
        '-41': 32.086982817082315,
        '-40': 32.0871643425767,
        '-39': 32.08712118925334,
        '-38': 32.087115904714665,
        '-37': 32.08732189281097,
        '-36': 32.087500912316926,
        '-35': 32.08746659222619,
        '-34': 32.08741269032545,
        '-33': 32.087531498464806,
        '-32': 32.08772992283325,
        '-31': 32.08790986265828,
        '-30': 32.08811771921557,
        '-29': 32.08830225341711,
        '-28': 32.08832747571517,
        '-27': 32.088267728758964,
        '-26': 32.088307793637,
        '-25': 32.088390748402894,
        '-24': 32.08833092124343,
        '-23': 32.08816113964371,
        '-22': 32.0880219516479,
        '-21': 32.08788871639035,
        '-20': 32.087737592074646,
        '-19': 32.08775081632625,
        '-18': 32.088035086092795,
        '-17': 32.08835852084039,
        '-16': 32.088432607133754,
        '-15': 32.08825339149713,
        '-14': 32.08800134602425,
        '-13': 32.08782158474111,
        '-12': 32.08778830037679,
        '-11': 32.08787263873923,
        '-10': 32.08793186351949,
        '-9': 32.087902588764,
        '-8': 32.08790439759499,
        '-7': 32.087991801302024,
        '-6': 32.088008431086315,
        '-5': 32.087863832691106,
        '-4': 32.087704812787905,
        '-3': 32.08765218254621,
        '-2': 32.08763813296249,
        '-1': 32.08761419593336
    },
    '1': {
        '0': 32.08765827741231,
        '1': 32.08782564633164,
        '2': 32.087828980454425,
        '3': 32.08764558933357,
        '4': 32.08752408759031,
        '5': 32.08761490849253,
        '6': 32.087808000531574,
        '7': 32.08791567711429,
        '8': 32.087839686892146,
        '9': 32.087659616335074,
        '10': 32.08717081299256,
        '11': 32.08284739340083,
        '12': 32.08279047729926,
        '13': 32.082655041954396,
        '14': 32.08187810439,
        '15': 32.08235529783043,
        '16': 32.08327720801218,
        '17': 32.08357224431537,
        '18': 32.08311927853528,
        '19': 32.08267217436197,
        '20': 32.08269595142629,
        '21': 32.082580880812124,
        '22': 32.0822656766069,
        '23': 32.081177392093764,
        '24': 32.082456106434094,
        '25': 32.081601203051086,
        '26': 32.0819232425872,
        '27': 32.080697981544766,
        '28': 32.08111269436855,
        '29': 32.0788550622869,
        '30': 32.079214270007874,
        '31': 32.07588562164162,
        '32': 32.076659566628884,
        '33': 32.076721353618126,
        '34': 32.076787708178344,
        '35': 32.06916599325848,
        '36': 32.07975653160784,
        '37': 32.07621207919076,
        '38': 32.08097611623628,
        '39': 32.08463601619861,
        '40': 32.08501254476039,
        '41': 32.0864774884684,
        '42': 32.087446849512595,
        '43': 32.08728126904516,
        '44': 32.08717918771401,
        '45': 32.08698550590637,
        '46': 32.08673477631638,
        '47': 32.08622578815064,
        '48': 32.08595084942364,
        '49': 32.08631378903903,
        '50': 32.086909058269434,
        '51': 32.08717807059057,
        '52': 32.08719988981085,
        '53': 32.08730804237521,
        '54': 32.08746913191334,
        '55': 32.087513143052455,
        '56': 32.08750199554405,
        '57': 32.08749347339485,
        '58': 32.087366842502576,
        '59': 32.087142134931526,
        '60': 32.087059304936574,
        '61': 32.0871672478921,
        '62': 32.08722427642845,
        '63': 32.08710870058227,
        '64': 32.08697423822272,
        '65': 32.08695424869745,
        '66': 32.0870093616209,
        '67': 32.08705097993895,
        '68': 32.086983439302685,
        '69': 32.08672021631667,
        '70': 32.08636380567528,
        '71': 32.08622474032941,
        '72': 32.086454413734195,
        '73': 32.08678401528734,
        '74': 32.08681429747872,
        '75': 32.086492946200984,
        '76': 32.086158654509056,
        '77': 32.086112203170636,
        '78': 32.08625298327322,
        '79': 32.08625527768554,
        '80': 32.08606262592774,
        '81': 32.0859601041197,
        '82': 32.08608607249171,
        '83': 32.08621191714837,
        '84': 32.086185374891556,
        '85': 32.086212105713216,
        '86': 32.086468100443355,
        '87': 32.08683299583437,
        '88': 32.087195341842616,
        '89': 32.087551357620676,
        '90': 32.087705826035425,
        '91': 32.08749844115747,
        '92': 32.08735406845719,
        '93': 32.087793076889554,
        '94': 32.0883885981988,
        '95': 32.088228801329485,
        '96': 32.087485571732074,
        '97': 32.08736335314734,
        '98': 32.08827400631755,
        '99': 32.088874216583,
        '100': 32.087967576739885,
        '101': 32.08826266760153,
        '102': 32.08798856812347,
        '103': 32.08813920795215,
        '104': 32.08868552705657,
        '105': 32.088829518995574,
        '106': 32.088692500262624,
        '107': 32.088513363521315,
        '108': 32.08863632172427,
        '109': 32.08897388705983,
        '110': 32.082491654466324,
        '111': 32.08551140377265,
        '112': 32.08866639249865,
        '113': 32.088348426702126,
        '114': 32.078368852526886,
        '115': 32.07704059654338,
        '116': 32.08343446204825,
        '117': 32.08879429022697,
        '118': 32.08868481334163,
        '119': 32.08814212733839,
        '120': 32.088156077667286,
        '121': 32.08107287230871,
        '122': 32.088187351792314,
        '123': 32.0895651504292,
        '124': 32.08908347401547,
        '125': 32.08763633503417,
        '126': 32.08671461025153,
        '127': 32.087938777583794,
        '128': 32.08571222913395,
        '129': 32.090964056687,
        '130': 32.089658503884436,
        '131': 32.08864665496525,
        '132': 32.08902466062824,
        '133': 32.08931699451171,
        '134': 32.08858533155725,
        '135': 32.087964783789616,
        '136': 32.08825469793742,
        '137': 32.08861841947591,
        '138': 32.08851292195631,
        '139': 32.088581160760675,
        '140': 32.08898720842029,
        '141': 32.088964171462756,
        '142': 32.08842983008754,
        '143': 32.08816566467135,
        '144': 32.08824460941422,
        '145': 32.08801523527317,
        '146': 32.08756418871003,
        '147': 32.087574916896095,
        '148': 32.087943947078124,
        '149': 32.087970578297565,
        '150': 32.08761213442696,
        '151': 32.087372674282456,
        '152': 32.08738612535712,
        '153': 32.08753956161755,
        '154': 32.0879220003525,
        '155': 32.08841656768744,
        '156': 32.088521272542216,
        '157': 32.08813145506191,
        '158': 32.08779796935642,
        '159': 32.08784455121601,
        '160': 32.08795317557944,
        '161': 32.08784611278451,
        '162': 32.087684775717285,
        '163': 32.08766599734925,
        '164': 32.08777906124805,
        '165': 32.087948836043914,
        '166': 32.08803137446293,
        '167': 32.08786142636847,
        '168': 32.087559250867194,
        '169': 32.08744891900668,
        '170': 32.08754623753724,
        '171': 32.087584260002494,
        '172': 32.087544509390305,
        '173': 32.08761205710023,
        '174': 32.08770207932638,
        '175': 32.08761630454519,
        '176': 32.08748457940583,
        '177': 32.08750046384487,
        '178': 32.087520468102866,
        '179': 32.08742524688144,
        '180': 32.087452915034184,
        '-180': 32.087452915034184,
        '-179': 32.087717585868795,
        '-178': 32.0879027695125,
        '-177': 32.08781549788981,
        '-176': 32.08768981316274,
        '-175': 32.08767055640108,
        '-174': 32.08760120270288,
        '-173': 32.08748486873829,
        '-172': 32.087527244773504,
        '-171': 32.0876634559377,
        '-170': 32.087644874761686,
        '-169': 32.08753132296845,
        '-168': 32.087547015704075,
        '-167': 32.08764753919162,
        '-166': 32.087713507119645,
        '-165': 32.087840003702155,
        '-164': 32.088025065579295,
        '-163': 32.08802414253313,
        '-162': 32.08783977629606,
        '-161': 32.0878169512506,
        '-160': 32.08803843139312,
        '-159': 32.088229363981235,
        '-158': 32.08832878237704,
        '-157': 32.0884753612666,
        '-156': 32.08850305406721,
        '-155': 32.08821065307529,
        '-154': 32.08794556011518,
        '-153': 32.088119046229345,
        '-152': 32.08842821456494,
        '-151': 32.088370310475995,
        '-150': 32.08814280239674,
        '-149': 32.08821118267135,
        '-148': 32.08842651419266,
        '-147': 32.088378004551956,
        '-146': 32.08817281587547,
        '-145': 32.088140070789805,
        '-144': 32.08820542613651,
        '-143': 32.08815691765728,
        '-142': 32.08808040034152,
        '-141': 32.08808010565146,
        '-140': 32.0880290628598,
        '-139': 32.08789794612903,
        '-138': 32.08786022428877,
        '-137': 32.0879143533353,
        '-136': 32.0878792141579,
        '-135': 32.087793431747535,
        '-134': 32.08784239842279,
        '-133': 32.08795738931227,
        '-132': 32.087940326047104,
        '-131': 32.08784651513337,
        '-130': 32.08784215751893,
        '-129': 32.08787691018632,
        '-128': 32.08782807833861,
        '-127': 32.08774004997367,
        '-126': 32.087682353010024,
        '-125': 32.08762022076425,
        '-124': 32.087558195412896,
        '-123': 32.087552697982474,
        '-122': 32.08755935033542,
        '-121': 32.087519086558345,
        '-120': 32.087502872885544,
        '-119': 32.08753790005423,
        '-118': 32.087498273445334,
        '-117': 32.087379900371275,
        '-116': 32.08737674609732,
        '-115': 32.08750694736662,
        '-114': 32.08755358462782,
        '-113': 32.08750178281756,
        '-112': 32.08756587352612,
        '-111': 32.0877117544387,
        '-110': 32.087687023531906,
        '-109': 32.087547295968925,
        '-108': 32.087577883413,
        '-107': 32.08772394113548,
        '-106': 32.087692639718725,
        '-105': 32.08753587456833,
        '-104': 32.08753667291096,
        '-103': 32.08765331594046,
        '-102': 32.08764919766825,
        '-101': 32.087586238825246,
        '-100': 32.08765559738119,
        '-99': 32.087757622714214,
        '-98': 32.087725792552106,
        '-97': 32.087671549718785,
        '-96': 32.08770146951476,
        '-95': 32.08766566111736,
        '-94': 32.087539766719814,
        '-93': 32.0876005343638,
        '-92': 32.08788666339203,
        '-91': 32.08800040263129,
        '-90': 32.08778279757102,
        '-89': 32.087632529447696,
        '-88': 32.08778944046334,
        '-87': 32.08787857273311,
        '-86': 32.0876117508392,
        '-85': 32.087394200417975,
        '-84': 32.08765470529503,
        '-83': 32.087993303046,
        '-82': 32.0877304459817,
        '-81': 32.08715960134283,
        '-80': 32.08743316437778,
        '-79': 32.088473337875534,
        '-78': 32.06875031644357,
        '-77': 32.05631249629363,
        '-76': 32.08647726921649,
        '-75': 32.08499200187795,
        '-74': 32.08603393279421,
        '-73': 32.08694706486668,
        '-72': 32.08736894718153,
        '-71': 32.08636002799534,
        '-70': 32.08659037221102,
        '-69': 32.086856888056126,
        '-68': 32.08724240879178,
        '-67': 32.08696867161374,
        '-66': 32.07744687571578,
        '-65': 32.08377914104052,
        '-64': 32.08698546942702,
        '-63': 32.086821160787125,
        '-62': 32.087611425288,
        '-61': 32.087823227293065,
        '-60': 32.086542922161414,
        '-59': 32.083297143228364,
        '-58': 32.0817482192835,
        '-57': 32.085136770921395,
        '-56': 32.084541009610376,
        '-55': 32.083533676516495,
        '-54': 32.08463159805775,
        '-53': 32.084366765718336,
        '-52': 32.086150518137366,
        '-51': 32.08745470144951,
        '-50': 32.087262032092255,
        '-49': 32.08718437744495,
        '-48': 32.08709017359358,
        '-47': 32.087084536990126,
        '-46': 32.08711238556978,
        '-45': 32.08710277567439,
        '-44': 32.08697383680124,
        '-43': 32.08686728507468,
        '-42': 32.087040754003944,
        '-41': 32.08740533351623,
        '-40': 32.0875844399339,
        '-39': 32.08751887617751,
        '-38': 32.087528235762534,
        '-37': 32.08769921604934,
        '-36': 32.08776094242554,
        '-35': 32.0876589753208,
        '-34': 32.08769076177928,
        '-33': 32.08796185984574,
        '-32': 32.08822631302431,
        '-31': 32.08833993275952,
        '-30': 32.08839603034474,
        '-29': 32.08840457169327,
        '-28': 32.08829272209477,
        '-27': 32.08817874750671,
        '-26': 32.088222466613544,
        '-25': 32.08830132572968,
        '-24': 32.088210268910466,
        '-23': 32.088018595836516,
        '-22': 32.087890492512045,
        '-21': 32.0877776478884,
        '-20': 32.08760452776029,
        '-19': 32.087500305187255,
        '-18': 32.087562340727544,
        '-17': 32.08764725918165,
        '-16': 32.08761987331817,
        '-15': 32.087555445022744,
        '-14': 32.087549189114746,
        '-13': 32.08757792495578,
        '-12': 32.08762021489775,
        '-11': 32.08767199552419,
        '-10': 32.08765580255193,
        '-9': 32.08754536764477,
        '-8': 32.08749094032499,
        '-7': 32.08760225900776,
        '-6': 32.08775553819782,
        '-5': 32.087804991674574,
        '-4': 32.087782733205714,
        '-3': 32.08773969025137,
        '-2': 32.087647193119324,
        '-1': 32.087573284483035
    },
    '2': {
        '0': 32.0875950022189,
        '1': 32.08788273174988,
        '2': 32.08793546436156,
        '3': 32.087684587158,
        '4': 32.08747231836847,
        '5': 32.08755773123819,
        '6': 32.08779568778302,
        '7': 32.08789670075044,
        '8': 32.08780052506566,
        '9': 32.08771674742172,
        '10': 32.08614436879292,
        '11': 32.08188571472154,
        '12': 32.08213699937744,
        '13': 32.08200248327416,
        '14': 32.08201379480221,
        '15': 32.08330733674091,
        '16': 32.08341280750597,
        '17': 32.0836598306684,
        '18': 32.08357250759971,
        '19': 32.08323758569098,
        '20': 32.08316804620757,
        '21': 32.085611314944245,
        '22': 32.08213263543092,
        '23': 32.08269068853063,
        '24': 32.081424802039166,
        '25': 32.082441732952816,
        '26': 32.08274239283578,
        '27': 32.082359815036725,
        '28': 32.07956638165761,
        '29': 32.07860571340855,
        '30': 32.07553722132305,
        '31': 32.079192387951416,
        '32': 32.076649369650724,
        '33': 32.07716695987047,
        '34': 32.07700211301603,
        '35': 32.074655635488206,
        '36': 32.081637419216335,
        '37': 32.07817374726102,
        '38': 32.083461847317224,
        '39': 32.084096175956795,
        '40': 32.084139215635446,
        '41': 32.08526327284879,
        '42': 32.08582855741128,
        '43': 32.086128198290844,
        '44': 32.08717493551542,
        '45': 32.08714276503442,
        '46': 32.08750145239132,
        '47': 32.086743837480064,
        '48': 32.08628839538923,
        '49': 32.086502872670344,
        '50': 32.087019868278105,
        '51': 32.087366629057996,
        '52': 32.08750554195635,
        '53': 32.08753475514741,
        '54': 32.087439871783765,
        '55': 32.08732893363943,
        '56': 32.08739484861798,
        '57': 32.08755430464283,
        '58': 32.08757538806462,
        '59': 32.08750443132337,
        '60': 32.087519188690976,
        '61': 32.08753006757908,
        '62': 32.08738318454149,
        '63': 32.0872284764195,
        '64': 32.0872579840158,
        '65': 32.087350717158046,
        '66': 32.08733681643894,
        '67': 32.08727759264333,
        '68': 32.087184971270496,
        '69': 32.08687745515344,
        '70': 32.086410341799855,
        '71': 32.0862203365562,
        '72': 32.08652952211055,
        '73': 32.08695181861067,
        '74': 32.086966699701165,
        '75': 32.08655601694778,
        '76': 32.0861807366193,
        '77': 32.08622904181906,
        '78': 32.086574683011385,
        '79': 32.086723655755875,
        '80': 32.08645182726548,
        '81': 32.08612239669782,
        '82': 32.08614281452689,
        '83': 32.08636059753435,
        '84': 32.08639314545999,
        '85': 32.08629811963595,
        '86': 32.08642955547033,
        '87': 32.08684996445442,
        '88': 32.087336670871956,
        '89': 32.08773170304484,
        '90': 32.0879330183219,
        '91': 32.087868381755335,
        '92': 32.08773202728867,
        '93': 32.08780864650402,
        '94': 32.08793161240057,
        '95': 32.08772422078115,
        '96': 32.08743596832836,
        '97': 32.08773977315795,
        '98': 32.08859208542569,
        '99': 32.078545712087546,
        '100': 32.08829667412242,
        '101': 32.08825971242551,
        '102': 32.0883629683481,
        '103': 32.088449806502595,
        '104': 32.0885051732693,
        '105': 32.08881908748129,
        '106': 32.088616127657474,
        '107': 32.08849765756587,
        '108': 32.08875398114224,
        '109': 32.089089937799216,
        '110': 32.08897922888862,
        '111': 32.0885585544502,
        '112': 32.08796276730942,
        '113': 32.08817911086798,
        '114': 32.08313431551833,
        '115': 32.082547563750175,
        '116': 32.07842469329087,
        '117': 32.089145186422805,
        '118': 32.08909819187227,
        '119': 32.08825297408031,
        '120': 32.08789776649951,
        '121': 32.08789431055392,
        '122': 32.08834243303423,
        '123': 32.08963482892529,
        '124': 32.090969396887516,
        '125': 32.09068435178193,
        '126': 32.08893400827398,
        '127': 32.08793433528113,
        '128': 32.08861543342404,
        '129': 32.08935950608853,
        '130': 32.08904321825152,
        '131': 32.08873256675357,
        '132': 32.089187321743495,
        '133': 32.0893347796903,
        '134': 32.08855846553112,
        '135': 32.088025066621505,
        '136': 32.08845414702693,
        '137': 32.08885014088482,
        '138': 32.08853195268845,
        '139': 32.08826240793738,
        '140': 32.08849212033669,
        '141': 32.088530993866314,
        '142': 32.088143247048876,
        '143': 32.088068879405334,
        '144': 32.08849084342145,
        '145': 32.08870078661672,
        '146': 32.08851607258002,
        '147': 32.08846386389103,
        '148': 32.088543584637364,
        '149': 32.08824080267876,
        '150': 32.08766019198927,
        '151': 32.08741812239905,
        '152': 32.08763557886543,
        '153': 32.08799230343289,
        '154': 32.08836558685675,
        '155': 32.08865641415089,
        '156': 32.088553931694236,
        '157': 32.08810126812487,
        '158': 32.087867738448395,
        '159': 32.088087066943714,
        '160': 32.08828471075828,
        '161': 32.088101112445784,
        '162': 32.087824764799606,
        '163': 32.0878371501525,
        '164': 32.08807937213799,
        '165': 32.08825270299369,
        '166': 32.08817802866786,
        '167': 32.08791449970188,
        '168': 32.08771320088502,
        '169': 32.08774485428179,
        '170': 32.08785371448529,
        '171': 32.08783442911025,
        '172': 32.08781169978566,
        '173': 32.08794883312255,
        '174': 32.08803410098621,
        '175': 32.08785249157537,
        '176': 32.08764164708068,
        '177': 32.08766363181167,
        '178': 32.08773439336843,
        '179': 32.08766945170065,
        '180': 32.08768857738842,
        '-180': 32.08768857738842,
        '-179': 32.0879165305568,
        '-178': 32.08805694374753,
        '-177': 32.08796193679999,
        '-176': 32.087907479987514,
        '-175': 32.088002493910885,
        '-174': 32.08798538026085,
        '-173': 32.08780664061258,
        '-172': 32.087736870366975,
        '-171': 32.08780656037764,
        '-170': 32.087797268026684,
        '-169': 32.08774606763839,
        '-168': 32.08782875984385,
        '-167': 32.08792078039646,
        '-166': 32.08786243239553,
        '-165': 32.08784934715297,
        '-164': 32.08802427777002,
        '-163': 32.08811859842936,
        '-162': 32.08798542372286,
        '-161': 32.08795504852547,
        '-160': 32.08822840002576,
        '-159': 32.08849988229711,
        '-158': 32.088526166948334,
        '-157': 32.088459563244015,
        '-156': 32.08839466098294,
        '-155': 32.0882298544953,
        '-154': 32.08809074137714,
        '-153': 32.088225862280964,
        '-152': 32.088466313392225,
        '-151': 32.08843988048056,
        '-150': 32.08823414285563,
        '-149': 32.08821590749415,
        '-148': 32.088366145032346,
        '-147': 32.08839745219602,
        '-146': 32.08830874785789,
        '-145': 32.08828250269345,
        '-144': 32.08828418312044,
        '-143': 32.08820883819551,
        '-142': 32.08814753862475,
        '-141': 32.08817786866877,
        '-140': 32.08819068652899,
        '-139': 32.08813407731372,
        '-138': 32.08810633549198,
        '-137': 32.088109952462375,
        '-136': 32.088050431215606,
        '-135': 32.08798142621768,
        '-134': 32.08801693128968,
        '-133': 32.08807482130165,
        '-132': 32.08801820422357,
        '-131': 32.08791620687028,
        '-130': 32.087896344440516,
        '-129': 32.08791716277381,
        '-128': 32.087896726241716,
        '-127': 32.08786105980807,
        '-126': 32.087828867670964,
        '-125': 32.08776434216056,
        '-124': 32.087696295591144,
        '-123': 32.087675730355,
        '-122': 32.087657746816625,
        '-121': 32.087614699047,
        '-120': 32.087622846371104,
        '-119': 32.08767149638927,
        '-118': 32.0876261256531,
        '-117': 32.08751674940662,
        '-116': 32.08753966729456,
        '-115': 32.087677313068454,
        '-114': 32.08771086497687,
        '-113': 32.08765472483028,
        '-112': 32.08771887764383,
        '-111': 32.08784549940358,
        '-110': 32.0877961520131,
        '-109': 32.0876538680438,
        '-108': 32.087697806816465,
        '-107': 32.0878612898832,
        '-106': 32.08785755523119,
        '-105': 32.08772501742116,
        '-104': 32.08772168286654,
        '-103': 32.087827916055026,
        '-102': 32.087841016605886,
        '-101': 32.08778696673263,
        '-100': 32.08781045040519,
        '-99': 32.08785784473469,
        '-98': 32.08782487454334,
        '-97': 32.08779566540523,
        '-96': 32.08786216480124,
        '-95': 32.087949831321055,
        '-94': 32.0880095483407,
        '-93': 32.08811183687018,
        '-92': 32.08822648259753,
        '-91': 32.088193337211756,
        '-90': 32.088006940653095,
        '-89': 32.08787688486111,
        '-88': 32.0879313665369,
        '-87': 32.08804452910877,
        '-86': 32.08802057506135,
        '-85': 32.08787474727684,
        '-84': 32.08785457722147,
        '-83': 32.088052772359006,
        '-82': 32.088062993793756,
        '-81': 32.0874834320448,
        '-80': 32.08688805135634,
        '-79': 32.08751146766571,
        '-78': 32.08832359065982,
        '-77': 32.08326434293239,
        '-76': 32.07960701762054,
        '-75': 32.08635454897385,
        '-74': 32.08582224465376,
        '-73': 32.08595222215552,
        '-72': 32.087259320773754,
        '-71': 32.08722132099824,
        '-70': 32.08688956381213,
        '-69': 32.086610574225794,
        '-68': 32.086729472946786,
        '-67': 32.0867486389549,
        '-66': 32.086577918034735,
        '-65': 32.08646159592009,
        '-64': 32.08328825923284,
        '-63': 32.08480688106052,
        '-62': 32.08641341587536,
        '-61': 32.087249716680056,
        '-60': 32.08601420108106,
        '-59': 32.08478645917432,
        '-58': 32.08504776905143,
        '-57': 32.08518789655295,
        '-56': 32.0853479490653,
        '-55': 32.08430966508911,
        '-54': 32.08461233338898,
        '-53': 32.08539355218197,
        '-52': 32.08594680949422,
        '-51': 32.086889099737824,
        '-50': 32.087526385707186,
        '-49': 32.087713305557195,
        '-48': 32.087579620221895,
        '-47': 32.08707195125023,
        '-46': 32.08668796424598,
        '-45': 32.0867444245063,
        '-44': 32.08702967820289,
        '-43': 32.08729786370992,
        '-42': 32.0875594151918,
        '-41': 32.08778026555254,
        '-40': 32.08782187813395,
        '-39': 32.087772372554106,
        '-38': 32.087865102936945,
        '-37': 32.08804469810111,
        '-36': 32.088072920951845,
        '-35': 32.08801833631147,
        '-34': 32.0881547081466,
        '-33': 32.08844091693089,
        '-32': 32.08858367745862,
        '-31': 32.088532136268704,
        '-30': 32.08846163538407,
        '-29': 32.088392680665635,
        '-28': 32.088249935610236,
        '-27': 32.08813602541949,
        '-26': 32.08815217694545,
        '-25': 32.088142750752404,
        '-24': 32.08796988642023,
        '-23': 32.087803195642955,
        '-22': 32.08781661020254,
        '-21': 32.08786513825037,
        '-20': 32.0877507422103,
        '-19': 32.08753522895395,
        '-18': 32.08735668441628,
        '-17': 32.08722207241255,
        '-16': 32.087160142273774,
        '-15': 32.08728183846891,
        '-14': 32.08754766220702,
        '-13': 32.08774623657239,
        '-12': 32.087778351387925,
        '-11': 32.08771697918247,
        '-10': 32.0875976579858,
        '-9': 32.08740735826619,
        '-8': 32.0872360321258,
        '-7': 32.087204119097436,
        '-6': 32.087299907800045,
        '-5': 32.08744201918277,
        '-4': 32.08757530510135,
        '-3': 32.08761564836347,
        '-2': 32.087513298410926,
        '-1': 32.087433227403075
    },
    '3': {
        '0': 32.08756061559295,
        '1': 32.08771487492894,
        '2': 32.087700605428076,
        '3': 32.08759771351375,
        '4': 32.08771942171149,
        '5': 32.088105466506825,
        '6': 32.08841829100031,
        '7': 32.08841040415304,
        '8': 32.08822254422745,
        '9': 32.08813248460076,
        '10': 32.087559892148136,
        '11': 32.080597842886185,
        '12': 32.081289921554784,
        '13': 32.081813163002714,
        '14': 32.081863592676655,
        '15': 32.08253251596936,
        '16': 32.08392656346485,
        '17': 32.082835596754336,
        '18': 32.084159056454304,
        '19': 32.083697674303224,
        '20': 32.08330565228116,
        '21': 32.082593185790884,
        '22': 32.082741272775884,
        '23': 32.0818315758489,
        '24': 32.08343496868432,
        '25': 32.08320144768836,
        '26': 32.08153701823192,
        '27': 32.08095676090524,
        '28': 32.080254945559226,
        '29': 32.07972748586752,
        '30': 32.074778962704066,
        '31': 32.07808746413815,
        '32': 32.076256196893326,
        '33': 32.07745264909064,
        '34': 32.07584859685825,
        '35': 32.07700777183496,
        '36': 32.08351081454216,
        '37': 32.08250685791918,
        '38': 32.082506841046566,
        '39': 32.082462711153816,
        '40': 32.0823530102338,
        '41': 32.08369554630685,
        '42': 32.08362136449436,
        '43': 32.08440409325308,
        '44': 32.085978031516966,
        '45': 32.087527767149275,
        '46': 32.08621124023501,
        '47': 32.08759603816248,
        '48': 32.08715805657143,
        '49': 32.087116001077604,
        '50': 32.08724256689116,
        '51': 32.08742548852692,
        '52': 32.0876830184904,
        '53': 32.08783485878307,
        '54': 32.08772748383592,
        '55': 32.08759948712052,
        '56': 32.0876969919203,
        '57': 32.08781724538035,
        '58': 32.08773018796629,
        '59': 32.08763767712085,
        '60': 32.08772002298137,
        '61': 32.0877372197835,
        '62': 32.08756378483501,
        '63': 32.087534013819,
        '64': 32.087794032025194,
        '65': 32.08794652279583,
        '66': 32.08775698561892,
        '67': 32.087534283565745,
        '68': 32.08744669340918,
        '69': 32.08719386463523,
        '70': 32.08671888619974,
        '71': 32.086517471548234,
        '72': 32.086851978347624,
        '73': 32.087266527087436,
        '74': 32.08722905588998,
        '75': 32.086809521270645,
        '76': 32.086480840324874,
        '77': 32.086531867636246,
        '78': 32.08678886301953,
        '79': 32.08680903854303,
        '80': 32.08641534813363,
        '81': 32.08600418221059,
        '82': 32.086055852144916,
        '83': 32.08645235269671,
        '84': 32.08670965322316,
        '85': 32.08673681622998,
        '86': 32.08683285103422,
        '87': 32.08709762659912,
        '88': 32.087411044821735,
        '89': 32.08780239359965,
        '90': 32.08828599732394,
        '91': 32.088553140247164,
        '92': 32.08833665270606,
        '93': 32.087861874726656,
        '94': 32.087567469898104,
        '95': 32.0876484922544,
        '96': 32.088073860592274,
        '97': 32.08866847437979,
        '98': 32.08506802532431,
        '99': 32.08446486302203,
        '100': 32.088837058704506,
        '101': 32.08881971484382,
        '102': 32.08465847670279,
        '103': 32.088442029038774,
        '104': 32.08891442279156,
        '105': 32.088876772219876,
        '106': 32.08880303249299,
        '107': 32.08875998861021,
        '108': 32.0889029230992,
        '109': 32.08902235068697,
        '110': 32.08879945071924,
        '111': 32.08851182743546,
        '112': 32.088674397791955,
        '113': 32.08805177790464,
        '114': 32.08729363858935,
        '115': 32.0866651070735,
        '116': 32.08214977662685,
        '117': 32.08630673012733,
        '118': 32.08928876604684,
        '119': 32.08871854831947,
        '120': 32.08864816230638,
        '121': 32.088393102373345,
        '122': 32.08803890219345,
        '123': 32.088934698469664,
        '124': 32.09096981230552,
        '125': 32.091653362919935,
        '126': 32.08976203315652,
        '127': 32.087598161327875,
        '128': 32.08757360095396,
        '129': 32.08883575954408,
        '130': 32.08940861152861,
        '131': 32.08936634337978,
        '132': 32.08963551589167,
        '133': 32.08978133549143,
        '134': 32.089195358719046,
        '135': 32.08865637532925,
        '136': 32.08883792973839,
        '137': 32.08896120887876,
        '138': 32.0884163787196,
        '139': 32.088001644118634,
        '140': 32.08832583872885,
        '141': 32.08867320138011,
        '142': 32.088459566228586,
        '143': 32.088220101658266,
        '144': 32.0883899609566,
        '145': 32.08857917896872,
        '146': 32.08856952603822,
        '147': 32.08868161858089,
        '148': 32.088874724395765,
        '149': 32.08869993173001,
        '150': 32.088230954918174,
        '151': 32.08799553660708,
        '152': 32.088092006556714,
        '153': 32.08824123616985,
        '154': 32.088408742446106,
        '155': 32.08863723341882,
        '156': 32.088695032249866,
        '157': 32.08849758901785,
        '158': 32.08838051065689,
        '159': 32.088509023695295,
        '160': 32.08857336935334,
        '161': 32.08838568349672,
        '162': 32.088201132936746,
        '163': 32.08822000211645,
        '164': 32.08829187009825,
        '165': 32.08824960788433,
        '166': 32.08813081000101,
        '167': 32.088039160251796,
        '168': 32.088046138449016,
        '169': 32.0881395318941,
        '170': 32.08816829755216,
        '171': 32.088055706574984,
        '172': 32.088013376006934,
        '173': 32.08819810425338,
        '174': 32.088337511312545,
        '175': 32.08815169377447,
        '176': 32.087878890298114,
        '177': 32.08787113119562,
        '178': 32.088004802969316,
        '179': 32.088007574572416,
        '180': 32.08796679376358,
        '-180': 32.08796679376358,
        '-179': 32.08803140115647,
        '-178': 32.088068714399505,
        '-177': 32.08802526703865,
        '-176': 32.08811137991622,
        '-175': 32.088317172030806,
        '-174': 32.088321041232525,
        '-173': 32.08808907065738,
        '-172': 32.08795416201809,
        '-171': 32.08800547942528,
        '-170': 32.08803145102826,
        '-169': 32.08803532186572,
        '-168': 32.088156495198106,
        '-167': 32.08823091486954,
        '-166': 32.088080171659776,
        '-165': 32.08797496850508,
        '-164': 32.08817841175707,
        '-163': 32.08840318003186,
        '-162': 32.08834331867946,
        '-161': 32.088280096122304,
        '-160': 32.08853842035199,
        '-159': 32.08883989198491,
        '-158': 32.08879468949003,
        '-157': 32.08855651099821,
        '-156': 32.08846632058757,
        '-155': 32.08850088777697,
        '-154': 32.08849937566519,
        '-153': 32.0885089920187,
        '-152': 32.08857931472383,
        '-151': 32.088591253848236,
        '-150': 32.08850078507833,
        '-149': 32.08843000049464,
        '-148': 32.08843303510898,
        '-147': 32.08845476487987,
        '-146': 32.088495413978514,
        '-145': 32.088564311723616,
        '-144': 32.08857292086606,
        '-143': 32.08848462706407,
        '-142': 32.08841959183231,
        '-141': 32.08845615239649,
        '-140': 32.088511226162744,
        '-139': 32.088510383810096,
        '-138': 32.088476614868,
        '-137': 32.08841778726241,
        '-136': 32.08832356133271,
        '-135': 32.08825051788532,
        '-134': 32.088237860542044,
        '-133': 32.08822002362649,
        '-132': 32.088157221566036,
        '-131': 32.088114146883115,
        '-130': 32.08811477748305,
        '-129': 32.08809925253279,
        '-128': 32.08807198703334,
        '-127': 32.08808775891096,
        '-126': 32.0880992246529,
        '-125': 32.08803311554874,
        '-124': 32.087950231372396,
        '-123': 32.08792298231285,
        '-122': 32.087887598906185,
        '-121': 32.08780641673632,
        '-120': 32.087778279402215,
        '-119': 32.08782643514019,
        '-118': 32.08783192652057,
        '-117': 32.08779678414222,
        '-116': 32.08786318499605,
        '-115': 32.08799740213818,
        '-114': 32.08801035502299,
        '-113': 32.0879212343695,
        '-112': 32.087919292721274,
        '-111': 32.08798766147835,
        '-110': 32.08796234336891,
        '-109': 32.08789336294617,
        '-108': 32.08794932688468,
        '-107': 32.08807167090698,
        '-106': 32.08809330678778,
        '-105': 32.08805218211374,
        '-104': 32.08806431970917,
        '-103': 32.08807821842993,
        '-102': 32.08802810112243,
        '-101': 32.08800723423706,
        '-100': 32.08807494295702,
        '-99': 32.08813040484415,
        '-98': 32.088123157665066,
        '-97': 32.08814779611705,
        '-96': 32.08825250689832,
        '-95': 32.08836915642841,
        '-94': 32.08843614452561,
        '-93': 32.08843028548635,
        '-92': 32.08836191855404,
        '-91': 32.08832292738203,
        '-90': 32.08836432157373,
        '-89': 32.08835024677849,
        '-88': 32.088209520321804,
        '-87': 32.08816436915013,
        '-86': 32.08831363585202,
        '-85': 32.08833804618477,
        '-84': 32.088168561691475,
        '-83': 32.088365137910614,
        '-82': 32.089024419907155,
        '-81': 32.089110288265815,
        '-80': 32.0880835158101,
        '-79': 32.087362028352224,
        '-78': 32.08852321657314,
        '-77': 32.083549692639345,
        '-76': 32.05478202419149,
        '-75': 32.073657668021596,
        '-74': 32.08142543548428,
        '-73': 32.08622107349223,
        '-72': 32.08667953303566,
        '-71': 32.087013514290405,
        '-70': 32.087058004098544,
        '-69': 32.08774516852259,
        '-68': 32.0872807817273,
        '-67': 32.08647740550833,
        '-66': 32.08646556484221,
        '-65': 32.08683404893736,
        '-64': 32.07638088087332,
        '-63': 32.08265738890699,
        '-62': 32.08508990645392,
        '-61': 32.087138868811905,
        '-60': 32.08685381811611,
        '-59': 32.08599962372643,
        '-58': 32.0851480284228,
        '-57': 32.08578442635093,
        '-56': 32.08500332327996,
        '-55': 32.08369955366506,
        '-54': 32.085802428715226,
        '-53': 32.0856912188798,
        '-52': 32.0861101318244,
        '-51': 32.08714651652042,
        '-50': 32.087702821908884,
        '-49': 32.088322270359605,
        '-48': 32.08833605742524,
        '-47': 32.08765134486,
        '-46': 32.08706533579641,
        '-45': 32.087120112571164,
        '-44': 32.087529306390344,
        '-43': 32.08782215890413,
        '-42': 32.08790755812216,
        '-41': 32.087888138109946,
        '-40': 32.08784139903317,
        '-39': 32.087886063155814,
        '-38': 32.088083121244004,
        '-37': 32.08826878200754,
        '-36': 32.08829235730292,
        '-35': 32.088300775975554,
        '-34': 32.088476245926344,
        '-33': 32.08868261814704,
        '-32': 32.08870332950048,
        '-31': 32.08861219604036,
        '-30': 32.08858107405871,
        '-29': 32.08855934412727,
        '-28': 32.08845514689493,
        '-27': 32.08837225392375,
        '-26': 32.08837648771123,
        '-25': 32.088311508407806,
        '-24': 32.0881154460932,
        '-23': 32.088014525820164,
        '-22': 32.08814545427304,
        '-21': 32.08829107517509,
        '-20': 32.08822454703575,
        '-19': 32.08802507501095,
        '-18': 32.08786499785711,
        '-17': 32.08777341273853,
        '-16': 32.08775878867088,
        '-15': 32.08787907951427,
        '-14': 32.088066579041474,
        '-13': 32.088138139510114,
        '-12': 32.08805932902217,
        '-11': 32.0879767648455,
        '-10': 32.08796224580061,
        '-9': 32.08793069964803,
        '-8': 32.08781322067232,
        '-7': 32.087647103379034,
        '-6': 32.08751518698168,
        '-5': 32.08748953750424,
        '-4': 32.087563463953835,
        '-3': 32.08760860620873,
        '-2': 32.08753339538675,
        '-1': 32.08746349595516
    },
    '4': {
        '0': 32.088074793864955,
        '1': 32.08784254467999,
        '2': 32.08753636492581,
        '3': 32.08752502175184,
        '4': 32.088040272783395,
        '5': 32.08875506243936,
        '6': 32.08911822252935,
        '7': 32.08904092721428,
        '8': 32.0889103108887,
        '9': 32.08895026678136,
        '10': 32.08812185088645,
        '11': 32.08314081545716,
        '12': 32.08181705114282,
        '13': 32.08152596289986,
        '14': 32.08220918327329,
        '15': 32.08268565484422,
        '16': 32.08392293192272,
        '17': 32.08257368673917,
        '18': 32.08327671569373,
        '19': 32.08397782946385,
        '20': 32.083330087885415,
        '21': 32.08237093318393,
        '22': 32.08350922798335,
        '23': 32.08296416392755,
        '24': 32.08336833743084,
        '25': 32.082536433667336,
        '26': 32.08193536349549,
        '27': 32.080711386972474,
        '28': 32.08090125540313,
        '29': 32.08091573980029,
        '30': 32.08006980650461,
        '31': 32.07887537849674,
        '32': 32.07908302029455,
        '33': 32.07052371624961,
        '34': 32.0739872670793,
        '35': 32.08302001119077,
        '36': 32.08429796737418,
        '37': 32.08120274978965,
        '38': 32.07995948363361,
        '39': 32.07474174175801,
        '40': 32.080192070921065,
        '41': 32.08394623061782,
        '42': 32.08568598131632,
        '43': 32.08459095445693,
        '44': 32.083452646105094,
        '45': 32.0858285048841,
        '46': 32.08600001016182,
        '47': 32.08539736501385,
        '48': 32.088037829367174,
        '49': 32.08776915725437,
        '50': 32.0873520555559,
        '51': 32.08719983131965,
        '52': 32.08756521013057,
        '53': 32.08803785105999,
        '54': 32.08819810744136,
        '55': 32.088226098677076,
        '56': 32.088341833837326,
        '57': 32.08829571734033,
        '58': 32.08797487166308,
        '59': 32.08780629931963,
        '60': 32.08800422706208,
        '61': 32.08815972316633,
        '62': 32.08805712814106,
        '63': 32.08806815754652,
        '64': 32.08830594395571,
        '65': 32.08829825141693,
        '66': 32.087907470752505,
        '67': 32.087637766818865,
        '68': 32.08767245017746,
        '69': 32.08756295976727,
        '70': 32.08718807797538,
        '71': 32.087066239249715,
        '72': 32.0874051100475,
        '73': 32.08770818397122,
        '74': 32.08759677919941,
        '75': 32.08729272350629,
        '76': 32.08709950315058,
        '77': 32.08699608087252,
        '78': 32.086846845580375,
        '79': 32.08659619016521,
        '80': 32.086273627090236,
        '81': 32.08605644825064,
        '82': 32.08616418145336,
        '83': 32.08655629807421,
        '84': 32.08698243903091,
        '85': 32.0873263566795,
        '86': 32.0875852126118,
        '87': 32.08768429013192,
        '88': 32.087729123867724,
        '89': 32.088130421253894,
        '90': 32.088942270974215,
        '91': 32.08944073458402,
        '92': 32.088990994246004,
        '93': 32.08803337866933,
        '94': 32.08761574701446,
        '95': 32.08816057180453,
        '96': 32.08912593613258,
        '97': 32.073912074277914,
        '98': 32.08917661349351,
        '99': 32.089109744104945,
        '100': 32.08903910669011,
        '101': 32.08936766965644,
        '102': 32.08840848993233,
        '103': 32.08835117782515,
        '104': 32.08904535011681,
        '105': 32.08896772136644,
        '106': 32.089047593029356,
        '107': 32.08910186989527,
        '108': 32.08903293348154,
        '109': 32.08918463112706,
        '110': 32.08903275024751,
        '111': 32.089033870527864,
        '112': 32.0894179483658,
        '113': 32.089720206567044,
        '114': 32.0893140863315,
        '115': 32.083004041362884,
        '116': 32.08017822455421,
        '117': 32.089950184037534,
        '118': 32.08957487693984,
        '119': 32.08937850199398,
        '120': 32.08995697868158,
        '121': 32.08995224671304,
        '122': 32.08889444407835,
        '123': 32.08868508874501,
        '124': 32.09022670360932,
        '125': 32.09110476599078,
        '126': 32.08937266460849,
        '127': 32.08717505945692,
        '128': 32.087493379969146,
        '129': 32.089501137333116,
        '130': 32.09040068810222,
        '131': 32.0899147723331,
        '132': 32.08971334646902,
        '133': 32.09001448374731,
        '134': 32.08982347661055,
        '135': 32.08929753444524,
        '136': 32.089253713160474,
        '137': 32.089380963740076,
        '138': 32.089021506760595,
        '139': 32.08866169434004,
        '140': 32.088975246288044,
        '141': 32.089466227213144,
        '142': 32.089387831230425,
        '143': 32.08895699673187,
        '144': 32.08868413360571,
        '145': 32.08853931517727,
        '146': 32.088456595767646,
        '147': 32.08864779216999,
        '148': 32.088981332709764,
        '149': 32.08898166325899,
        '150': 32.08864177108221,
        '151': 32.08840815769896,
        '152': 32.088400457056764,
        '153': 32.088408874515096,
        '154': 32.08846346310999,
        '155': 32.088711443772375,
        '156': 32.08899319016885,
        '157': 32.089081978429874,
        '158': 32.08903266850555,
        '159': 32.08895310948455,
        '160': 32.088824433878365,
        '161': 32.08872233552869,
        '162': 32.088762910237655,
        '163': 32.08878927667769,
        '164': 32.08858082821958,
        '165': 32.08828988293698,
        '166': 32.08821502383563,
        '167': 32.088322996463084,
        '168': 32.0883987192569,
        '169': 32.08840374492353,
        '170': 32.08836766454766,
        '171': 32.08826440558859,
        '172': 32.08820627693991,
        '173': 32.08835136364988,
        '174': 32.08852812647192,
        '175': 32.088430829465715,
        '176': 32.088173837592635,
        '177': 32.08812177888176,
        '178': 32.08826364291753,
        '179': 32.08830234239512,
        '180': 32.08821179847751,
        '-180': 32.08821179847751,
        '-179': 32.08818830529513,
        '-178': 32.08825200764144,
        '-177': 32.08832864916801,
        '-176': 32.08845468102798,
        '-175': 32.08857788428886,
        '-174': 32.08851432166119,
        '-173': 32.08832302233516,
        '-172': 32.088285898878006,
        '-171': 32.08840413012838,
        '-170': 32.08842226893243,
        '-169': 32.088355371705525,
        '-168': 32.08841743201123,
        '-167': 32.08851574519063,
        '-166': 32.08844050636149,
        '-165': 32.08838723567662,
        '-164': 32.088634349202735,
        '-163': 32.088938374263,
        '-162': 32.08892582002821,
        '-161': 32.088798832333914,
        '-160': 32.08896102619987,
        '-159': 32.089247984160075,
        '-158': 32.089224252694116,
        '-157': 32.08896706898232,
        '-156': 32.08889392850848,
        '-155': 32.08903553502548,
        '-154': 32.089069692191266,
        '-153': 32.088915905178425,
        '-152': 32.088804500078396,
        '-151': 32.08884841244198,
        '-150': 32.08891246469812,
        '-149': 32.08887014047605,
        '-148': 32.088762491201734,
        '-147': 32.088710017148706,
        '-146': 32.08876821315401,
        '-145': 32.08887116307744,
        '-144': 32.08891236150427,
        '-143': 32.08887842896125,
        '-142': 32.088845738680696,
        '-141': 32.088851351762614,
        '-140': 32.0888576249465,
        '-139': 32.088832364233944,
        '-138': 32.08877400636957,
        '-137': 32.088687756625575,
        '-136': 32.08860394950167,
        '-135': 32.08855995836462,
        '-134': 32.088532959779336,
        '-133': 32.0884861898267,
        '-132': 32.088465958068184,
        '-131': 32.088514723147625,
        '-130': 32.08854749543811,
        '-129': 32.088488245491135,
        '-128': 32.0884285250703,
        '-127': 32.08845603745377,
        '-126': 32.088471127856174,
        '-125': 32.08838121896028,
        '-124': 32.08829169205991,
        '-123': 32.08829472387105,
        '-122': 32.0882872885786,
        '-121': 32.088194063742336,
        '-120': 32.08812643821,
        '-119': 32.08815333760146,
        '-118': 32.0881771197473,
        '-117': 32.088160758795645,
        '-116': 32.08819914624475,
        '-115': 32.088286490875014,
        '-114': 32.08829671853778,
        '-113': 32.0882285228198,
        '-112': 32.08820361080345,
        '-111': 32.08824224892727,
        '-110': 32.08827591841606,
        '-109': 32.08830362810727,
        '-108': 32.08834190177334,
        '-107': 32.08835305460556,
        '-106': 32.088356652320414,
        '-105': 32.08842133369246,
        '-104': 32.08848360924758,
        '-103': 32.08842812236444,
        '-102': 32.08834631191084,
        '-101': 32.08841338080342,
        '-100': 32.088565982079906,
        '-99': 32.08862305465948,
        '-98': 32.08860937346977,
        '-97': 32.08864635009208,
        '-96': 32.088697056054244,
        '-95': 32.08868652676419,
        '-94': 32.08864265997019,
        '-93': 32.088556582188986,
        '-92': 32.088425826808624,
        '-91': 32.088449574300846,
        '-90': 32.088721131747064,
        '-89': 32.088872078624476,
        '-88': 32.088643276194375,
        '-87': 32.08848095486039,
        '-86': 32.08874938328718,
        '-85': 32.0889027088781,
        '-84': 32.08854613087605,
        '-83': 32.08856202742132,
        '-82': 32.08964190746966,
        '-81': 32.09049837739286,
        '-80': 32.08955365594536,
        '-79': 32.08782658124318,
        '-78': 32.08783189261045,
        '-77': 32.089486293692104,
        '-76': 32.05894743874949,
        '-75': 32.08796501833146,
        '-74': 32.06253860049211,
        '-73': 32.086880365428804,
        '-72': 32.08603959536341,
        '-71': 32.08612483702437,
        '-70': 32.08640946766098,
        '-69': 32.08729810819589,
        '-68': 32.08765516881136,
        '-67': 32.087209585213806,
        '-66': 32.084898931063016,
        '-65': 32.07473284944634,
        '-64': 32.07946119462514,
        '-63': 32.08462746718574,
        '-62': 32.08520283327786,
        '-61': 32.087465313674,
        '-60': 32.08462947960231,
        '-59': 32.08733589370987,
        '-58': 32.08712438741215,
        '-57': 32.081739988507145,
        '-56': 32.08564995479801,
        '-55': 32.08552804343396,
        '-54': 32.085397899716334,
        '-53': 32.08555049852715,
        '-52': 32.08686303363019,
        '-51': 32.08763225916796,
        '-50': 32.0879463280998,
        '-49': 32.088510814481914,
        '-48': 32.088584374608196,
        '-47': 32.08807227627154,
        '-46': 32.08766416764293,
        '-45': 32.08776753697964,
        '-44': 32.08807110664528,
        '-43': 32.08819183235518,
        '-42': 32.08813883254256,
        '-41': 32.08807884526279,
        '-40': 32.08809446714445,
        '-39': 32.08821666163464,
        '-38': 32.08840241506084,
        '-37': 32.088517140993375,
        '-36': 32.08853192702484,
        '-35': 32.088600765398056,
        '-34': 32.08878713458433,
        '-33': 32.088929327729815,
        '-32': 32.08892839300697,
        '-31': 32.08891041153972,
        '-30': 32.08895265179482,
        '-29': 32.08893502023798,
        '-28': 32.08881352671715,
        '-27': 32.08874150833252,
        '-26': 32.08877405007358,
        '-25': 32.08875800150337,
        '-24': 32.0886500280114,
        '-23': 32.088627969724996,
        '-22': 32.0887512477816,
        '-21': 32.08882326823396,
        '-20': 32.08873093585411,
        '-19': 32.08863188654164,
        '-18': 32.08867025843112,
        '-17': 32.088760027065085,
        '-16': 32.08876508102795,
        '-15': 32.088678160521866,
        '-14': 32.088539813961624,
        '-13': 32.088374200918366,
        '-12': 32.0882687215443,
        '-11': 32.08835108666672,
        '-10': 32.088623130224875,
        '-9': 32.088913761547154,
        '-8': 32.089023278965364,
        '-7': 32.088877969366806,
        '-6': 32.08857816703128,
        '-5': 32.088318763882654,
        '-4': 32.088212599409964,
        '-3': 32.08820158362595,
        '-2': 32.08818768024383,
        '-1': 32.088156205767056
    },
    '5': {
        '0': 32.08923119004807,
        '1': 32.088696785640224,
        '2': 32.08808102191278,
        '3': 32.08794426098162,
        '4': 32.088464767497605,
        '5': 32.08913350597275,
        '6': 32.089161589476866,
        '7': 32.089005016678385,
        '8': 32.08881908739795,
        '9': 32.08435840851483,
        '10': 32.08292165592325,
        '11': 32.08259974912213,
        '12': 32.08318252404856,
        '13': 32.08304892477831,
        '14': 32.081875843725186,
        '15': 32.08262522081399,
        '16': 32.082593451513446,
        '17': 32.08244916367787,
        '18': 32.082481423503395,
        '19': 32.08443411246685,
        '20': 32.08414244192034,
        '21': 32.08206605338282,
        '22': 32.08341340664732,
        '23': 32.08357998562587,
        '24': 32.08305467870126,
        '25': 32.08232329848567,
        '26': 32.08123171218232,
        '27': 32.081374273557444,
        '28': 32.08206674383328,
        '29': 32.08171583190324,
        '30': 32.081127234243276,
        '31': 32.08242186036176,
        '32': 32.08376962924067,
        '33': 32.08358495687131,
        '34': 32.083138639030174,
        '35': 32.08435525152213,
        '36': 32.084618688424094,
        '37': 32.08269577507523,
        '38': 32.073003014780184,
        '39': 32.079317828646005,
        '40': 32.07743125477021,
        '41': 32.078121298039605,
        '42': 32.08543819212994,
        '43': 32.08353673161978,
        '44': 32.0839480210551,
        '45': 32.08660367956536,
        '46': 32.08593882901062,
        '47': 32.08701509976008,
        '48': 32.085127204782324,
        '49': 32.08841263272301,
        '50': 32.087626673613094,
        '51': 32.087157584822705,
        '52': 32.08750772761755,
        '53': 32.08818010218072,
        '54': 32.088561726830804,
        '55': 32.08874264796929,
        '56': 32.08892660801493,
        '57': 32.088882616035,
        '58': 32.08857682974398,
        '59': 32.08850338587705,
        '60': 32.08882535870572,
        '61': 32.08902348220114,
        '62': 32.088838405049465,
        '63': 32.088657138352815,
        '64': 32.08862854524763,
        '65': 32.08839227042699,
        '66': 32.0879624237142,
        '67': 32.087834348274264,
        '68': 32.0879994369342,
        '69': 32.08791530891263,
        '70': 32.0875852239362,
        '71': 32.08758601694704,
        '72': 32.08795102022262,
        '73': 32.08809604718135,
        '74': 32.08788590141364,
        '75': 32.087750039685865,
        '76': 32.08774625677649,
        '77': 32.08747755672395,
        '78': 32.08694574566139,
        '79': 32.08664297194175,
        '80': 32.08676134151442,
        '81': 32.08696211324188,
        '82': 32.086962380101696,
        '83': 32.08691030918713,
        '84': 32.087130893347435,
        '85': 32.087674011835944,
        '86': 32.08815775507315,
        '87': 32.08821368390928,
        '88': 32.08813888379359,
        '89': 32.08863344803645,
        '90': 32.089626661191225,
        '91': 32.09003245223428,
        '92': 32.08921007406166,
        '93': 32.08803900133167,
        '94': 32.08786487613877,
        '95': 32.08884060197635,
        '96': 32.07916415649523,
        '97': 32.08660088806803,
        '98': 32.089792265403695,
        '99': 32.089345600024025,
        '100': 32.08946239362791,
        '101': 32.08604024822591,
        '102': 32.08875796561487,
        '103': 32.08932700893642,
        '104': 32.08919652213336,
        '105': 32.0891068091704,
        '106': 32.08923581396675,
        '107': 32.08938840062821,
        '108': 32.08955806945017,
        '109': 32.08960265899543,
        '110': 32.089443862804806,
        '111': 32.08943633210702,
        '112': 32.08972181464705,
        '113': 32.08972858135237,
        '114': 32.0892886138563,
        '115': 32.08917781393615,
        '116': 32.08408310433488,
        '117': 32.085551744140986,
        '118': 32.087605029667266,
        '119': 32.0900538798766,
        '120': 32.09098020689123,
        '121': 32.09147444438426,
        '122': 32.090411455980394,
        '123': 32.089586881966575,
        '124': 32.09040891512681,
        '125': 32.090842931076565,
        '126': 32.0889226772432,
        '127': 32.086827884346384,
        '128': 32.08765844344534,
        '129': 32.090247258897385,
        '130': 32.091150780376765,
        '131': 32.09008650794574,
        '132': 32.08948390953728,
        '133': 32.089974751011475,
        '134': 32.0900806391816,
        '135': 32.089502627026185,
        '136': 32.08939272803725,
        '137': 32.08987119643997,
        '138': 32.08995780152942,
        '139': 32.08956194281842,
        '140': 32.08946427633541,
        '141': 32.08976026255713,
        '142': 32.089893201513114,
        '143': 32.089742237098875,
        '144': 32.08955684532441,
        '145': 32.08937386573204,
        '146': 32.08923083434439,
        '147': 32.08931136677706,
        '148': 32.08945499009992,
        '149': 32.08923850619769,
        '150': 32.08874716657111,
        '151': 32.08852767084202,
        '152': 32.08872703516122,
        '153': 32.08898242174795,
        '154': 32.08910482225683,
        '155': 32.08923900543551,
        '156': 32.08944768506955,
        '157': 32.08961392702059,
        '158': 32.089629323562356,
        '159': 32.08944752343093,
        '160': 32.089170568731014,
        '161': 32.089084762405356,
        '162': 32.08926950887653,
        '163': 32.08934126187313,
        '164': 32.08902400749464,
        '165': 32.08866321583094,
        '166': 32.08866167961549,
        '167': 32.08881693716582,
        '168': 32.08877314117119,
        '169': 32.08865754773562,
        '170': 32.088712791069476,
        '171': 32.08879674422285,
        '172': 32.08875137539381,
        '173': 32.08875195259238,
        '174': 32.088878021687805,
        '175': 32.08888051042035,
        '176': 32.088689793262034,
        '177': 32.08859127367211,
        '178': 32.088675758443394,
        '179': 32.088690578686325,
        '180': 32.08857967061491,
        '-180': 32.08857967061491,
        '-179': 32.08860694262826,
        '-178': 32.08884914620248,
        '-177': 32.08906062744679,
        '-176': 32.08907209104582,
        '-175': 32.0889401267538,
        '-174': 32.08877401601958,
        '-173': 32.08869786886001,
        '-172': 32.08880513373229,
        '-171': 32.08895916541608,
        '-170': 32.088916738535,
        '-169': 32.08874750680729,
        '-168': 32.088749646214325,
        '-167': 32.088924254845885,
        '-166': 32.08901112390515,
        '-165': 32.089026584218885,
        '-164': 32.08921241349065,
        '-163': 32.08948479038992,
        '-162': 32.08952588051392,
        '-161': 32.08938859966161,
        '-160': 32.08941748041516,
        '-159': 32.08960819611717,
        '-158': 32.08963107757703,
        '-157': 32.08946053066473,
        '-156': 32.089412907951974,
        '-155': 32.08955189487137,
        '-154': 32.0895770631163,
        '-153': 32.08936167137272,
        '-152': 32.08916270321668,
        '-151': 32.08919548689359,
        '-150': 32.08933523793784,
        '-149': 32.0893634255704,
        '-148': 32.08927304062941,
        '-147': 32.08919775711576,
        '-146': 32.08918932244833,
        '-145': 32.08919992368195,
        '-144': 32.0892086592961,
        '-143': 32.08923847991221,
        '-142': 32.08927095226251,
        '-141': 32.08925375625048,
        '-140': 32.08919056719312,
        '-139': 32.08912857245116,
        '-138': 32.08907029906521,
        '-137': 32.08899754381478,
        '-136': 32.08894762401942,
        '-135': 32.088952064599845,
        '-134': 32.08895858078956,
        '-133': 32.088935064028675,
        '-132': 32.08895290328193,
        '-131': 32.089034116862436,
        '-130': 32.08905870950077,
        '-129': 32.08897336842332,
        '-128': 32.088910955248586,
        '-127': 32.08894460222011,
        '-126': 32.088941884762136,
        '-125': 32.08882705501778,
        '-124': 32.088740022005396,
        '-123': 32.088770280233476,
        '-122': 32.08879431377486,
        '-121': 32.08872712506074,
        '-120': 32.088669335911476,
        '-119': 32.08868073961849,
        '-118': 32.088669136504265,
        '-117': 32.08859564932446,
        '-116': 32.08854971724876,
        '-115': 32.08857098153451,
        '-114': 32.08860295506536,
        '-113': 32.08862154876541,
        '-112': 32.08864802581154,
        '-111': 32.08868726854985,
        '-110': 32.088750591172285,
        '-109': 32.08883046478835,
        '-108': 32.08883747676439,
        '-107': 32.08874170731482,
        '-106': 32.0887092149591,
        '-105': 32.08884959501407,
        '-104': 32.08897680260578,
        '-103': 32.08892814233239,
        '-102': 32.088888139601124,
        '-101': 32.08903710307872,
        '-100': 32.08918412226552,
        '-99': 32.08913459264877,
        '-98': 32.089052064798686,
        '-97': 32.08908357183889,
        '-96': 32.08906805214471,
        '-95': 32.08894058368902,
        '-94': 32.08890494398588,
        '-93': 32.088969565270425,
        '-92': 32.08892718755769,
        '-91': 32.08889675029189,
        '-90': 32.089138727998005,
        '-89': 32.08938636523257,
        '-88': 32.08926951342441,
        '-87': 32.08916806067522,
        '-86': 32.08952437894148,
        '-85': 32.08974129675972,
        '-84': 32.08919218318799,
        '-83': 32.0887555668097,
        '-82': 32.0895205265182,
        '-81': 32.09051254503628,
        '-80': 32.08992085054299,
        '-79': 32.08827790174836,
        '-78': 32.08801201777319,
        '-77': 32.08897476201473,
        '-76': 32.077176048962365,
        '-75': 32.07980938204752,
        '-74': 32.065413751694734,
        '-73': 32.07960436074175,
        '-72': 32.08738677843421,
        '-71': 32.08677122203043,
        '-70': 32.08677743946405,
        '-69': 32.087327812592314,
        '-68': 32.08819022349168,
        '-67': 32.07663292095797,
        '-66': 32.087895319422564,
        '-65': 32.08314546257593,
        '-64': 32.08568029110064,
        '-63': 32.0854705206735,
        '-62': 32.08060891893433,
        '-61': 32.07882101471744,
        '-60': 32.08048897329909,
        '-59': 32.08736715111661,
        '-58': 32.087965610823645,
        '-57': 32.087858582157025,
        '-56': 32.08745055162925,
        '-55': 32.086820746668096,
        '-54': 32.08683096251925,
        '-53': 32.08691747353831,
        '-52': 32.08874425396176,
        '-51': 32.08839522718928,
        '-50': 32.088205861254984,
        '-49': 32.08830341682999,
        '-48': 32.08827270490728,
        '-47': 32.08806510359277,
        '-46': 32.08803908445757,
        '-45': 32.08826012117666,
        '-44': 32.088435510857025,
        '-43': 32.088447879523834,
        '-42': 32.08845361848113,
        '-41': 32.08853984208403,
        '-40': 32.08864772243664,
        '-39': 32.08874663441505,
        '-38': 32.088840830532895,
        '-37': 32.08891182292213,
        '-36': 32.08899558122304,
        '-35': 32.089168678263874,
        '-34': 32.0893819023746,
        '-33': 32.089491477941856,
        '-32': 32.08948892964212,
        '-31': 32.08948925998592,
        '-30': 32.0894902435921,
        '-29': 32.08938670536001,
        '-28': 32.08922014066171,
        '-27': 32.089157018384135,
        '-26': 32.089215467357654,
        '-25': 32.08925920709733,
        '-24': 32.089255844610065,
        '-23': 32.08929448871047,
        '-22': 32.08935382062882,
        '-21': 32.08930360558084,
        '-20': 32.08915564299971,
        '-19': 32.08908704633707,
        '-18': 32.089171753855126,
        '-17': 32.08926611445073,
        '-16': 32.08920493635513,
        '-15': 32.08898536480901,
        '-14': 32.08873429225201,
        '-13': 32.08859535662106,
        '-12': 32.08866635324379,
        '-11': 32.08896597671148,
        '-10': 32.089412072458664,
        '-9': 32.0896843817185,
        '-8': 32.08868255408537,
        '-7': 32.088365074412195,
        '-6': 32.08930765030839,
        '-5': 32.089411928057295,
        '-4': 32.08928682126999,
        '-3': 32.08929157214304,
        '-2': 32.088512496417216,
        '-1': 32.08940962592755
    },
    '6': {
        '0': 32.08947979087958,
        '1': 32.09001972905809,
        '2': 32.08944810252359,
        '3': 32.089183539463384,
        '4': 32.0893615172752,
        '5': 32.089579718515616,
        '6': 32.088787431170566,
        '7': 32.086829893473265,
        '8': 32.089495808884685,
        '9': 32.08928886006538,
        '10': 32.07733086732752,
        '11': 32.08213204692826,
        '12': 32.08104444484042,
        '13': 32.08190031402771,
        '14': 32.08099763273919,
        '15': 32.08107586911044,
        '16': 32.08216297152519,
        '17': 32.08360742896452,
        '18': 32.08327176138455,
        '19': 32.08383214729733,
        '20': 32.08348214228712,
        '21': 32.08415563105666,
        '22': 32.083464138635776,
        '23': 32.082863849606035,
        '24': 32.08280267069055,
        '25': 32.082679094183376,
        '26': 32.082185236200914,
        '27': 32.082862538985,
        '28': 32.08310493378242,
        '29': 32.0839431817311,
        '30': 32.08437511318213,
        '31': 32.084995952462386,
        '32': 32.08471787516329,
        '33': 32.08466337499973,
        '34': 32.08479667249092,
        '35': 32.079327088987625,
        '36': 32.0850400464114,
        '37': 32.079771846355435,
        '38': 32.07739241816506,
        '39': 32.07592063781138,
        '40': 32.07804046074741,
        '41': 32.07990943975315,
        '42': 32.07541403432166,
        '43': 32.08531538167895,
        '44': 32.08323112798048,
        '45': 32.08347454169518,
        '46': 32.085326264922124,
        '47': 32.08647065927614,
        '48': 32.08815396414129,
        '49': 32.08920607102074,
        '50': 32.088387888621895,
        '51': 32.08771997112868,
        '52': 32.08785332631728,
        '53': 32.088409673574816,
        '54': 32.08878835795621,
        '55': 32.089029478735775,
        '56': 32.089318598014124,
        '57': 32.08945763582837,
        '58': 32.08939411890406,
        '59': 32.08948934746141,
        '60': 32.08979069651957,
        '61': 32.0898297704462,
        '62': 32.08947440633637,
        '63': 32.08913855774573,
        '64': 32.08895715662181,
        '65': 32.088669608695774,
        '66': 32.08838104662374,
        '67': 32.08843677865419,
        '68': 32.08858369250811,
        '69': 32.0883194554767,
        '70': 32.087930547058185,
        '71': 32.08806283013652,
        '72': 32.088489518162525,
        '73': 32.08848515303096,
        '74': 32.08814876917875,
        '75': 32.08812865417192,
        '76': 32.08830369497098,
        '77': 32.08800588634203,
        '78': 32.08739003787077,
        '79': 32.08735777756409,
        '80': 32.088089673680884,
        '81': 32.08867435672046,
        '82': 32.08839337491004,
        '83': 32.087667732025935,
        '84': 32.08743690232171,
        '85': 32.08794970639491,
        '86': 32.088526084874395,
        '87': 32.0885853411363,
        '88': 32.08856086021688,
        '89': 32.0892245388847,
        '90': 32.09022865742235,
        '91': 32.09028216409666,
        '92': 32.089064313709244,
        '93': 32.08794410566461,
        '94': 32.08820659327683,
        '95': 32.089430547233356,
        '96': 32.090297572831126,
        '97': 32.09029247062537,
        '98': 32.0899326961936,
        '99': 32.08982375395855,
        '100': 32.09005332259394,
        '101': 32.08641498155271,
        '102': 32.09000690081844,
        '103': 32.08983164234281,
        '104': 32.08957644853035,
        '105': 32.08956071602167,
        '106': 32.08963515876989,
        '107': 32.08978395552644,
        '108': 32.0900402697203,
        '109': 32.0901197868232,
        '110': 32.08985582909815,
        '111': 32.089681619405255,
        '112': 32.08984486875239,
        '113': 32.089789776495564,
        '114': 32.0893598932222,
        '115': 32.08957050464659,
        '116': 32.090806492321725,
        '117': 32.08498916803465,
        '118': 32.09090396097553,
        '119': 32.09053393259262,
        '120': 32.091279018578845,
        '121': 32.090775623058384,
        '122': 32.09148827723666,
        '123': 32.09101261969193,
        '124': 32.09176843779103,
        '125': 32.085996320297134,
        '126': 32.089459731135435,
        '127': 32.08712438833457,
        '128': 32.088013076683715,
        '129': 32.090768947079624,
        '130': 32.09165119270081,
        '131': 32.090382035648794,
        '132': 32.08969558693169,
        '133': 32.09031824469789,
        '134': 32.09047805532798,
        '135': 32.08971393195709,
        '136': 32.08947695343564,
        '137': 32.09015467214824,
        '138': 32.09050115290947,
        '139': 32.0900010204553,
        '140': 32.08950462688895,
        '141': 32.08956841739885,
        '142': 32.08986410378521,
        '143': 32.090083510925545,
        '144': 32.090229604367714,
        '145': 32.09027304446194,
        '146': 32.090267370502794,
        '147': 32.09038312382924,
        '148': 32.09044402235303,
        '149': 32.0900573680382,
        '150': 32.089409407573434,
        '151': 32.08919049118244,
        '152': 32.08958923929949,
        '153': 32.09006321199368,
        '154': 32.090176915049014,
        '155': 32.090061308947476,
        '156': 32.09002352519614,
        '157': 32.09014598775528,
        '158': 32.090257670848466,
        '159': 32.09011451937891,
        '160': 32.08973539118392,
        '161': 32.08949783996717,
        '162': 32.08960754711056,
        '163': 32.08971460104306,
        '164': 32.08949627941524,
        '165': 32.08924127375309,
        '166': 32.08929539263463,
        '167': 32.089404692244365,
        '168': 32.08925841862026,
        '169': 32.089150846343735,
        '170': 32.08940823104311,
        '171': 32.089709967673,
        '172': 32.089661779087415,
        '173': 32.08948240340519,
        '174': 32.08949852436416,
        '175': 32.0895414587168,
        '176': 32.08940049794182,
        '177': 32.08927927224077,
        '178': 32.089327185315106,
        '179': 32.08932010901685,
        '180': 32.089193742720475,
        '-180': 32.089193742720475,
        '-179': 32.08927648839542,
        '-178': 32.089655961113586,
        '-177': 32.08990370958812,
        '-176': 32.08972280827816,
        '-175': 32.089362884164245,
        '-174': 32.08918310434886,
        '-173': 32.0892281597786,
        '-172': 32.089353926315326,
        '-171': 32.08942677563336,
        '-170': 32.08937037987126,
        '-169': 32.08926806645625,
        '-168': 32.08932993743856,
        '-167': 32.08957124451183,
        '-166': 32.0897358851584,
        '-165': 32.08970950742848,
        '-164': 32.08970713289631,
        '-163': 32.089869116949984,
        '-162': 32.090014504101596,
        '-161': 32.08999346808289,
        '-160': 32.089940578974335,
        '-159': 32.08997849646728,
        '-158': 32.08999662620148,
        '-157': 32.089916708941104,
        '-156': 32.08987579859742,
        '-155': 32.089956723468894,
        '-154': 32.09000383015311,
        '-153': 32.08988995617773,
        '-152': 32.08974199257675,
        '-151': 32.08972810983048,
        '-150': 32.08980527198449,
        '-149': 32.089839138357775,
        '-148': 32.08982064932177,
        '-147': 32.08980672439743,
        '-146': 32.0897736041609,
        '-145': 32.089688247232644,
        '-144': 32.089625295069034,
        '-143': 32.08965687819721,
        '-142': 32.08970825094075,
        '-141': 32.08967266087002,
        '-140': 32.08958889837428,
        '-139': 32.089554201098416,
        '-138': 32.089548374267885,
        '-137': 32.08950306719698,
        '-136': 32.08945444098069,
        '-135': 32.0894668879992,
        '-134': 32.08950086523884,
        '-133': 32.08950261125155,
        '-132': 32.089509946458506,
        '-131': 32.089540899600586,
        '-130': 32.089524161498325,
        '-129': 32.089454566459,
        '-128': 32.08943938619344,
        '-127': 32.08949057868344,
        '-126': 32.089477922569536,
        '-125': 32.089371394433925,
        '-124': 32.08930110640138,
        '-123': 32.0893136758613,
        '-122': 32.0893107849524,
        '-121': 32.08926710396709,
        '-120': 32.08926295449502,
        '-119': 32.08928835639968,
        '-118': 32.08924915134065,
        '-117': 32.089152894480925,
        '-116': 32.089089305568365,
        '-115': 32.08908172905007,
        '-114': 32.08911012653111,
        '-113': 32.08917595288361,
        '-112': 32.08924385201683,
        '-111': 32.089266814589074,
        '-110': 32.08929666260025,
        '-109': 32.08937782519383,
        '-108': 32.08939866778027,
        '-107': 32.08929957170846,
        '-106': 32.08926994326859,
        '-105': 32.089432023785314,
        '-104': 32.08957572027128,
        '-103': 32.08954866752241,
        '-102': 32.0895602205858,
        '-101': 32.08972846587463,
        '-100': 32.089781381592296,
        '-99': 32.08959882494583,
        '-98': 32.08950006664459,
        '-97': 32.08961391789442,
        '-96': 32.08962136463793,
        '-95': 32.08946809989779,
        '-94': 32.08954730554339,
        '-93': 32.08986162029792,
        '-92': 32.08990430175522,
        '-91': 32.08964015161681,
        '-90': 32.08960769576853,
        '-89': 32.08986854413075,
        '-88': 32.08995997387799,
        '-87': 32.08994401441206,
        '-86': 32.09024826137078,
        '-85': 32.090531114052,
        '-84': 32.090142599139945,
        '-83': 32.08956130295537,
        '-82': 32.08975327207634,
        '-81': 32.09031439788829,
        '-80': 32.09007654163949,
        '-79': 32.08937344036396,
        '-78': 32.08970811437554,
        '-77': 32.08956084475166,
        '-76': 32.073265142174634,
        '-75': 32.08142251201094,
        '-74': 32.084783270257844,
        '-73': 32.05531839813448,
        '-72': 32.08206779851232,
        '-71': 32.08838165354905,
        '-70': 32.0879427511658,
        '-69': 32.08833011576914,
        '-68': 32.08888866305521,
        '-67': 32.08902266162114,
        '-66': 32.086150969140796,
        '-65': 32.089143274132425,
        '-64': 32.08694177786372,
        '-63': 32.08588684013134,
        '-62': 32.079677630119086,
        '-61': 32.07939456501877,
        '-60': 32.08840210917058,
        '-59': 32.08856164306579,
        '-58': 32.08900822935063,
        '-57': 32.08968088977578,
        '-56': 32.08954128898007,
        '-55': 32.08932751090909,
        '-54': 32.089622191479684,
        '-53': 32.08997538058502,
        '-52': 32.08969392710231,
        '-51': 32.088948107527855,
        '-50': 32.08840447491827,
        '-49': 32.08822076392616,
        '-48': 32.0881533055868,
        '-47': 32.08819610346018,
        '-46': 32.08845084582213,
        '-45': 32.08872398391569,
        '-44': 32.0887891710853,
        '-43': 32.0887694578477,
        '-42': 32.08888014649931,
        '-41': 32.08906377068813,
        '-40': 32.089162871148666,
        '-39': 32.089202455839484,
        '-38': 32.089289515923504,
        '-37': 32.08944286835244,
        '-36': 32.08964838373733,
        '-35': 32.08989461380807,
        '-34': 32.09009779294211,
        '-33': 32.09016336666181,
        '-32': 32.090124354028184,
        '-31': 32.090070267754115,
        '-30': 32.08999642027895,
        '-29': 32.08987292980092,
        '-28': 32.08977013955406,
        '-27': 32.089759537206646,
        '-26': 32.089787606553,
        '-25': 32.08978696952606,
        '-24': 32.089803692175224,
        '-23': 32.08988528200302,
        '-22': 32.08995315418993,
        '-21': 32.089908627532836,
        '-20': 32.089772516390596,
        '-19': 32.08962341303874,
        '-18': 32.089482561162626,
        '-17': 32.089336935446745,
        '-16': 32.08920766375772,
        '-15': 32.08914038943596,
        '-14': 32.089172326587516,
        '-13': 32.089327184856515,
        '-12': 32.08960349928597,
        '-11': 32.08996175277911,
        '-10': 32.090187780460084,
        '-9': 32.08880449162016,
        '-8': 32.088256835195224,
        '-7': 32.088213627518186,
        '-6': 32.088211962591444,
        '-5': 32.089406545394155,
        '-4': 32.0892250558389,
        '-3': 32.08856782019771,
        '-2': 32.088686997148805,
        '-1': 32.08904916447394
    },
    '7': {
        '0': 32.08971896113824,
        '1': 32.089536163645995,
        '2': 32.09032223421757,
        '3': 32.09051122883622,
        '4': 32.089855753005004,
        '5': 32.08857337966385,
        '6': 32.088647458019025,
        '7': 32.08984399925467,
        '8': 32.08920429770984,
        '9': 32.08879969862767,
        '10': 32.08496258859736,
        '11': 32.07718107063202,
        '12': 32.080505830821124,
        '13': 32.0798987585397,
        '14': 32.077662729590166,
        '15': 32.07820862963903,
        '16': 32.08279905580056,
        '17': 32.0847078547806,
        '18': 32.08584306193626,
        '19': 32.08526914886158,
        '20': 32.08446692485886,
        '21': 32.08455060645219,
        '22': 32.08392512004114,
        '23': 32.08304333354585,
        '24': 32.08312894330166,
        '25': 32.082927907768585,
        '26': 32.08204115049333,
        '27': 32.084875143682844,
        '28': 32.085346335647266,
        '29': 32.0856396077155,
        '30': 32.085850923240066,
        '31': 32.08582670963359,
        '32': 32.08544974255766,
        '33': 32.08530232743056,
        '34': 32.085392328854034,
        '35': 32.08071334480249,
        '36': 32.08108352375346,
        '37': 32.07048952629548,
        '38': 32.07339505380707,
        '39': 32.06558921664762,
        '40': 32.06390388072173,
        '41': 32.077537501346036,
        '42': 32.083576879120706,
        '43': 32.0830796161408,
        '44': 32.0810082506927,
        '45': 32.08276658367528,
        '46': 32.08486989463844,
        '47': 32.08597088719689,
        '48': 32.08735834120427,
        '49': 32.08925238837889,
        '50': 32.089541387352774,
        '51': 32.0887318751172,
        '52': 32.08850107763143,
        '53': 32.08877100180479,
        '54': 32.089103289785236,
        '55': 32.08943954406942,
        '56': 32.08983937537003,
        '57': 32.09011387728534,
        '58': 32.090197175071985,
        '59': 32.09030042776326,
        '60': 32.0904019670634,
        '61': 32.09022138524759,
        '62': 32.08983730671206,
        '63': 32.08961645490286,
        '64': 32.089528453630955,
        '65': 32.08930587273227,
        '66': 32.089121559180036,
        '67': 32.08923705952733,
        '68': 32.089282815186216,
        '69': 32.08886548735624,
        '70': 32.088481212640545,
        '71': 32.088748011494864,
        '72': 32.08921215031872,
        '73': 32.08907644824654,
        '74': 32.08865943275829,
        '75': 32.088711939686895,
        '76': 32.08902876843617,
        '77': 32.08883421096689,
        '78': 32.088360210033244,
        '79': 32.0886129842987,
        '80': 32.0893785201771,
        '81': 32.08196947612128,
        '82': 32.08975328022267,
        '83': 32.08866959638721,
        '84': 32.088241742423925,
        '85': 32.088706656574324,
        '86': 32.08922619665026,
        '87': 32.08922866763415,
        '88': 32.08928777669975,
        '89': 32.0901053439549,
        '90': 32.09103674948705,
        '91': 32.09072861270086,
        '92': 32.08924858145601,
        '93': 32.08830487309351,
        '94': 32.08893727643128,
        '95': 32.09019686768007,
        '96': 32.090719720729794,
        '97': 32.09050306667772,
        '98': 32.09037816865099,
        '99': 32.09060662244246,
        '100': 32.08926895979167,
        '101': 32.090653551447005,
        '102': 32.09035227193512,
        '103': 32.090184000239915,
        '104': 32.09025873656669,
        '105': 32.090389541386344,
        '106': 32.09039421715919,
        '107': 32.090420138325854,
        '108': 32.09061127706519,
        '109': 32.0906788831001,
        '110': 32.09042073520753,
        '111': 32.09028273636953,
        '112': 32.09056712288761,
        '113': 32.09069551844666,
        '114': 32.090334321239844,
        '115': 32.09035588113839,
        '116': 32.091297417689674,
        '117': 32.092003449149594,
        '118': 32.091487674258815,
        '119': 32.090802280559224,
        '120': 32.0911376399466,
        '121': 32.09168881363008,
        '122': 32.08649334208037,
        '123': 32.09191188806899,
        '124': 32.0920827802554,
        '125': 32.09248504132846,
        '126': 32.09079663952731,
        '127': 32.08853915622597,
        '128': 32.089091253525666,
        '129': 32.09152554740126,
        '130': 32.09227794578724,
        '131': 32.09107672486241,
        '132': 32.09050716854298,
        '133': 32.09119679508438,
        '134': 32.09136148512565,
        '135': 32.09050756261521,
        '136': 32.09006037234226,
        '137': 32.090511827727916,
        '138': 32.09080641406719,
        '139': 32.09049728736136,
        '140': 32.09028056394167,
        '141': 32.090479308261735,
        '142': 32.090678719839985,
        '143': 32.090688726976595,
        '144': 32.09068022189522,
        '145': 32.090686880577564,
        '146': 32.09074626090293,
        '147': 32.09103342061334,
        '148': 32.0913757807513,
        '149': 32.09129793268595,
        '150': 32.09084764971515,
        '151': 32.090634804130865,
        '152': 32.09086544997226,
        '153': 32.091080147291585,
        '154': 32.09095933234548,
        '155': 32.09071950628181,
        '156': 32.09065175228539,
        '157': 32.09077021526349,
        '158': 32.090896748722,
        '159': 32.09080446973653,
        '160': 32.09043520794748,
        '161': 32.090075740101206,
        '162': 32.09001659769858,
        '163': 32.0900942592667,
        '164': 32.09000210056545,
        '165': 32.089858012119024,
        '166': 32.08991575445609,
        '167': 32.090011584763424,
        '168': 32.08992712741084,
        '169': 32.089927067962904,
        '170': 32.09026286992129,
        '171': 32.09057538199561,
        '172': 32.09047104884136,
        '173': 32.09021213930683,
        '174': 32.09018679764138,
        '175': 32.09023219132471,
        '176': 32.090096765054966,
        '177': 32.08998688787917,
        '178': 32.09008063219554,
        '179': 32.09011482170188,
        '180': 32.089968771535474,
        '-180': 32.089968771535474,
        '-179': 32.09000239846918,
        '-178': 32.09033909761838,
        '-177': 32.09048961764578,
        '-176': 32.09015125338938,
        '-175': 32.089738110199825,
        '-174': 32.08969068422631,
        '-173': 32.0898481754729,
        '-172': 32.08988393161921,
        '-171': 32.08983618903924,
        '-170': 32.08987818135041,
        '-169': 32.08999761059955,
        '-168': 32.090142675022946,
        '-167': 32.0903228968228,
        '-166': 32.090440481256074,
        '-165': 32.09036656925519,
        '-164': 32.09023194268919,
        '-163': 32.090300117573264,
        '-162': 32.09054583278792,
        '-161': 32.09069156780201,
        '-160': 32.090643863745186,
        '-159': 32.090569139226346,
        '-158': 32.09055815909733,
        '-157': 32.090528034771026,
        '-156': 32.09045700917535,
        '-155': 32.090440504343476,
        '-154': 32.09049567072841,
        '-153': 32.09052896701248,
        '-152': 32.09050583275523,
        '-151': 32.09047740110148,
        '-150': 32.090455565704026,
        '-149': 32.090417233196064,
        '-148': 32.09039682096453,
        '-147': 32.09042856350445,
        '-146': 32.090448014216555,
        '-145': 32.09039033409667,
        '-144': 32.09031651701411,
        '-143': 32.09030127205031,
        '-142': 32.090286696650764,
        '-141': 32.09020178427147,
        '-140': 32.090124172846835,
        '-139': 32.09015216665887,
        '-138': 32.09021745885041,
        '-137': 32.090199358644824,
        '-136': 32.09012637861423,
        '-135': 32.0900985955217,
        '-134': 32.09010960846084,
        '-133': 32.09009548753671,
        '-132': 32.090060481899336,
        '-131': 32.09003379938023,
        '-130': 32.09000674699329,
        '-129': 32.089991153499795,
        '-128': 32.09001895575189,
        '-127': 32.090048493278054,
        '-126': 32.090011279197064,
        '-125': 32.089944157195006,
        '-124': 32.089920218846395,
        '-123': 32.089904175253196,
        '-122': 32.089846345946924,
        '-121': 32.089818532582655,
        '-120': 32.089874667003514,
        '-119': 32.08991527119684,
        '-118': 32.089867198427896,
        '-117': 32.08982253455844,
        '-116': 32.08984388161647,
        '-115': 32.08984883490589,
        '-114': 32.089812908918205,
        '-113': 32.089832133248706,
        '-112': 32.08990060724463,
        '-111': 32.08991054546787,
        '-110': 32.08989624272422,
        '-109': 32.08996625317064,
        '-108': 32.09004642622604,
        '-107': 32.09002734920714,
        '-106': 32.0900278427889,
        '-105': 32.090157818233514,
        '-104': 32.090255578326605,
        '-103': 32.090219947057136,
        '-102': 32.09025351915636,
        '-101': 32.09041854899387,
        '-100': 32.090430425210975,
        '-99': 32.09024830778214,
        '-98': 32.090257226464075,
        '-97': 32.090513840812164,
        '-96': 32.09056526092178,
        '-95': 32.09035667633213,
        '-94': 32.090413629474796,
        '-93': 32.090774337732334,
        '-92': 32.09078276234403,
        '-91': 32.09031983704358,
        '-90': 32.09011370404818,
        '-89': 32.090441108131344,
        '-88': 32.09069365356329,
        '-87': 32.09059166301171,
        '-86': 32.090623656846056,
        '-85': 32.090967295905216,
        '-84': 32.091171941053226,
        '-83': 32.09113437587443,
        '-82': 32.09122448484305,
        '-81': 32.09139807534936,
        '-80': 32.09137166081882,
        '-79': 32.091508801774786,
        '-78': 32.09222616372439,
        '-77': 32.09276012336126,
        '-76': 32.06766826056825,
        '-75': 32.07811618499377,
        '-74': 32.09052265349651,
        '-73': 32.07255442983461,
        '-72': 32.08907381335705,
        '-71': 32.08941855931038,
        '-70': 32.08894813045646,
        '-69': 32.08905978469602,
        '-68': 32.089467242474804,
        '-67': 32.08670966493495,
        '-66': 32.08745760498812,
        '-65': 32.08997735291494,
        '-64': 32.08725546965466,
        '-63': 32.087596060476095,
        '-62': 32.088704579948825,
        '-61': 32.08910701995561,
        '-60': 32.088348961588224,
        '-59': 32.08913374682368,
        '-58': 32.089982849118336,
        '-57': 32.090448180245694,
        '-56': 32.09058131883562,
        '-55': 32.09059264110342,
        '-54': 32.09071523939463,
        '-53': 32.09054483082198,
        '-52': 32.08980142721093,
        '-51': 32.08899067511302,
        '-50': 32.08866870945608,
        '-49': 32.08870478763195,
        '-48': 32.088773170002256,
        '-47': 32.08892534231478,
        '-46': 32.08922647454295,
        '-45': 32.08942312614339,
        '-44': 32.0893770780628,
        '-43': 32.08933741498507,
        '-42': 32.0894866340886,
        '-41': 32.08965563504485,
        '-40': 32.08969877006914,
        '-39': 32.0897480691422,
        '-38': 32.08992928101856,
        '-37': 32.09016038458487,
        '-36': 32.09034264486258,
        '-35': 32.09048433212008,
        '-34': 32.090582659506005,
        '-33': 32.090595585267096,
        '-32': 32.090541274724046,
        '-31': 32.090478777277994,
        '-30': 32.090430827191895,
        '-29': 32.09041642309542,
        '-28': 32.090460747437454,
        '-27': 32.09050902281023,
        '-26': 32.090466022380014,
        '-25': 32.090367209875446,
        '-24': 32.09036008719802,
        '-23': 32.09048815781868,
        '-22': 32.09064213812238,
        '-21': 32.09071990910477,
        '-20': 32.09067952394597,
        '-19': 32.09047111385587,
        '-18': 32.09010273302013,
        '-17': 32.08975912144318,
        '-16': 32.089683275297624,
        '-15': 32.08992458451688,
        '-14': 32.090307685269245,
        '-13': 32.090639080122486,
        '-12': 32.090872124314146,
        '-11': 32.09004915031309,
        '-10': 32.08916513957526,
        '-9': 32.088920492585146,
        '-8': 32.08852459849647,
        '-7': 32.08842858520018,
        '-6': 32.08843460867361,
        '-5': 32.08889367473808,
        '-4': 32.088727494798526,
        '-3': 32.08860652929065,
        '-2': 32.08806448896393,
        '-1': 32.08994971386743
    },
    '8': {
        '0': 32.08979491995738,
        '1': 32.089093464254525,
        '2': 32.09044798576256,
        '3': 32.09019456846188,
        '4': 32.08891748810466,
        '5': 32.08627905490958,
        '6': 32.087032308333946,
        '7': 32.09016884991075,
        '8': 32.090832792647696,
        '9': 32.08944554241683,
        '10': 32.08964642361625,
        '11': 32.08845543490398,
        '12': 32.08548944146296,
        '13': 32.08691574273592,
        '14': 32.08560341687302,
        '15': 32.0792820054732,
        '16': 32.08565570859178,
        '17': 32.08632643059654,
        '18': 32.08666581624172,
        '19': 32.08666971304149,
        '20': 32.086195968668875,
        '21': 32.0840569751756,
        '22': 32.083581831501576,
        '23': 32.083398647285904,
        '24': 32.081780994459606,
        '25': 32.08300459925893,
        '26': 32.08486409757084,
        '27': 32.085293510827185,
        '28': 32.086369359531034,
        '29': 32.08632145199791,
        '30': 32.08656605605441,
        '31': 32.086541232853314,
        '32': 32.08632555636313,
        '33': 32.086297897537136,
        '34': 32.08655973171891,
        '35': 32.07326317239624,
        '36': 32.06933753606677,
        '37': 32.072814230121374,
        '38': 32.064474497128046,
        '39': 32.074434865899306,
        '40': 32.07164268741108,
        '41': 32.08240461409917,
        '42': 32.08102948724932,
        '43': 32.08066904233619,
        '44': 32.08056623094039,
        '45': 32.08325218620797,
        '46': 32.08369464480669,
        '47': 32.08428643539576,
        '48': 32.08396093936954,
        '49': 32.08578663376488,
        '50': 32.09087843548044,
        '51': 32.08989770765019,
        '52': 32.089272394640425,
        '53': 32.08927171035071,
        '54': 32.08965438452742,
        '55': 32.090198656100554,
        '56': 32.09073218031679,
        '57': 32.0910443025901,
        '58': 32.09111926901562,
        '59': 32.09111136225159,
        '60': 32.09099117841989,
        '61': 32.09066324733472,
        '62': 32.09035650462407,
        '63': 32.09032230761198,
        '64': 32.0903250722794,
        '65': 32.090072568666294,
        '66': 32.08983303929778,
        '67': 32.089905940601966,
        '68': 32.08993872032812,
        '69': 32.0895960300032,
        '70': 32.08934864361444,
        '71': 32.08966404933233,
        '72': 32.090028703988,
        '73': 32.089800732977,
        '74': 32.089404102991104,
        '75': 32.089574387698185,
        '76': 32.09001498542191,
        '77': 32.0899699138697,
        '78': 32.089649154945526,
        '79': 32.0898918836837,
        '80': 32.090128935672396,
        '81': 32.090433477105165,
        '82': 32.090374804600636,
        '83': 32.08951626100168,
        '84': 32.08935495381117,
        '85': 32.089881152387164,
        '86': 32.09028033479038,
        '87': 32.09019051193499,
        '88': 32.09028957941266,
        '89': 32.09114894668054,
        '90': 32.091962525432855,
        '91': 32.09145554076072,
        '92': 32.089949999592164,
        '93': 32.089223249188336,
        '94': 32.09005521093537,
        '95': 32.091243202375175,
        '96': 32.09153365802455,
        '97': 32.09123613149814,
        '98': 32.09123625276072,
        '99': 32.09141258241651,
        '100': 32.0914626123684,
        '101': 32.091140294252334,
        '102': 32.090789227930195,
        '103': 32.09077323791066,
        '104': 32.091018121763504,
        '105': 32.091224320147454,
        '106': 32.091204879503294,
        '107': 32.091123577230505,
        '108': 32.09119826659709,
        '109': 32.09127396587584,
        '110': 32.09117359256432,
        '111': 32.09122223452003,
        '112': 32.091666893102236,
        '113': 32.091958392890945,
        '114': 32.09160343543726,
        '115': 32.09120706512733,
        '116': 32.09148114092787,
        '117': 32.091686360784145,
        '118': 32.09174163490197,
        '119': 32.09140928884578,
        '120': 32.091488631869886,
        '121': 32.09148586275424,
        '122': 32.09127767139976,
        '123': 32.08558540262954,
        '124': 32.08796829339827,
        '125': 32.08806138650346,
        '126': 32.09250237782353,
        '127': 32.09042494619113,
        '128': 32.09060931714263,
        '129': 32.092426734887965,
        '130': 32.0928692033603,
        '131': 32.091757665167634,
        '132': 32.09129152081379,
        '133': 32.09196130269125,
        '134': 32.0922416695454,
        '135': 32.09164859586925,
        '136': 32.09119970880925,
        '137': 32.09127695098198,
        '138': 32.09137332701597,
        '139': 32.09152284511091,
        '140': 32.09209282656873,
        '141': 32.092652950533996,
        '142': 32.09254422408819,
        '143': 32.09200760047262,
        '144': 32.091646587459294,
        '145': 32.09147921849483,
        '146': 32.09135185438196,
        '147': 32.091466288563915,
        '148': 32.09183662413694,
        '149': 32.092037585137156,
        '150': 32.09192870413617,
        '151': 32.091833605039625,
        '152': 32.09180798270877,
        '153': 32.09156802010127,
        '154': 32.09120131588759,
        '155': 32.091119567979796,
        '156': 32.09132291686152,
        '157': 32.09143585908169,
        '158': 32.091351664915756,
        '159': 32.09123234343071,
        '160': 32.091095597131705,
        '161': 32.090905064065346,
        '162': 32.09078240208238,
        '163': 32.09076709994671,
        '164': 32.090710869024136,
        '165': 32.090613880614505,
        '166': 32.09064886796025,
        '167': 32.090783328823626,
        '168': 32.09084490738781,
        '169': 32.0909017983237,
        '170': 32.09107898517837,
        '171': 32.09118164829384,
        '172': 32.09102684805365,
        '173': 32.09086130723632,
        '174': 32.09092357847925,
        '175': 32.09098212469287,
        '176': 32.090826333191835,
        '177': 32.09072311142828,
        '178': 32.09086647140101,
        '179': 32.09094549502193,
        '180': 32.090773028548035,
        '-180': 32.090773028548035,
        '-179': 32.090703979564566,
        '-178': 32.09091502335009,
        '-177': 32.09096129672892,
        '-176': 32.0905836374373,
        '-175': 32.090256124096506,
        '-174': 32.090385706284344,
        '-173': 32.09064285673264,
        '-172': 32.09062470376099,
        '-171': 32.09054240461487,
        '-170': 32.090718949175255,
        '-169': 32.090978704455395,
        '-168': 32.09103767723741,
        '-167': 32.09100762629045,
        '-166': 32.091078382073945,
        '-165': 32.09113882747565,
        '-164': 32.09108354307922,
        '-163': 32.091093575091726,
        '-162': 32.09128922826754,
        '-161': 32.091463124914206,
        '-160': 32.0914322641311,
        '-159': 32.091330071908146,
        '-158': 32.09131819658471,
        '-157': 32.09131197791652,
        '-156': 32.091203749106604,
        '-155': 32.091093445431014,
        '-154': 32.091105596063514,
        '-153': 32.09118887013813,
        '-152': 32.09124215657552,
        '-151': 32.0912586470355,
        '-150': 32.0912473095566,
        '-149': 32.0911825120342,
        '-148': 32.09110024692441,
        '-147': 32.09108908997621,
        '-146': 32.09114688904413,
        '-145': 32.0911841034681,
        '-144': 32.09116724525264,
        '-143': 32.091123234361866,
        '-142': 32.091040653248214,
        '-141': 32.09091555215049,
        '-140': 32.09083962727602,
        '-139': 32.09088794680191,
        '-138': 32.090978232712736,
        '-137': 32.0909831633552,
        '-136': 32.090910419573284,
        '-135': 32.090844040901835,
        '-134': 32.09079432943308,
        '-133': 32.0907269412922,
        '-132': 32.090659415484915,
        '-131': 32.09063181352478,
        '-130': 32.09064715814895,
        '-129': 32.09068424274326,
        '-128': 32.09070349428108,
        '-127': 32.09065722100075,
        '-126': 32.09057100798303,
        '-125': 32.0905423128595,
        '-124': 32.09057669408403,
        '-123': 32.09056090987873,
        '-122': 32.0904851145612,
        '-121': 32.09048790179759,
        '-120': 32.09058218477549,
        '-119': 32.09060010369445,
        '-118': 32.09051749999134,
        '-117': 32.09051853755832,
        '-116': 32.09063023654104,
        '-115': 32.09065109220267,
        '-114': 32.090544636075435,
        '-113': 32.090516030783135,
        '-112': 32.09061573226412,
        '-111': 32.09067157847443,
        '-110': 32.09065781302346,
        '-109': 32.09071742620711,
        '-108': 32.090826969288756,
        '-107': 32.09085060298513,
        '-106': 32.09084838103614,
        '-105': 32.0909347322815,
        '-104': 32.09100299982369,
        '-103': 32.090969212588895,
        '-102': 32.09100679784314,
        '-101': 32.09117884485317,
        '-100': 32.091242538839346,
        '-99': 32.09115855219893,
        '-98': 32.09125116830477,
        '-97': 32.09153292862747,
        '-96': 32.09155425198715,
        '-95': 32.09125571793137,
        '-94': 32.09115804603338,
        '-93': 32.09137135701265,
        '-92': 32.09135259965957,
        '-91': 32.091003709380466,
        '-90': 32.09097066301114,
        '-89': 32.09143938236786,
        '-88': 32.091705754674706,
        '-87': 32.09140344846621,
        '-86': 32.091144998735615,
        '-85': 32.09149321953244,
        '-84': 32.09218729456845,
        '-83': 32.09272772413167,
        '-82': 32.09296395363356,
        '-81': 32.092336635783994,
        '-80': 32.09297189866285,
        '-79': 32.093306237196444,
        '-78': 32.091932532026405,
        '-77': 32.09313185328869,
        '-76': 32.08511822375539,
        '-75': 32.08969009924297,
        '-74': 32.08686100603117,
        '-73': 32.067269164078425,
        '-72': 32.06078574413107,
        '-71': 32.088292049195665,
        '-70': 32.089461170787565,
        '-69': 32.0900122337094,
        '-68': 32.09048104596121,
        '-67': 32.09077889104935,
        '-66': 32.09072767703027,
        '-65': 32.09034759269274,
        '-64': 32.090433582011684,
        '-63': 32.08915593721621,
        '-62': 32.08703386635105,
        '-61': 32.0893455314531,
        '-60': 32.09066986851088,
        '-59': 32.090868593386936,
        '-58': 32.0908311148153,
        '-57': 32.09071248045545,
        '-56': 32.09050545783367,
        '-55': 32.090510163274566,
        '-54': 32.09063708259347,
        '-53': 32.09033760007556,
        '-52': 32.08960009856449,
        '-51': 32.08915573057842,
        '-50': 32.08936339831319,
        '-49': 32.089739196639705,
        '-48': 32.08987297132801,
        '-47': 32.08996484857773,
        '-46': 32.090182531357364,
        '-45': 32.090291521086,
        '-44': 32.090205011897545,
        '-43': 32.090212662608536,
        '-42': 32.09043301828183,
        '-41': 32.09062084914891,
        '-40': 32.090666112079944,
        '-39': 32.09076157153094,
        '-38': 32.090973880407226,
        '-37': 32.09110618404899,
        '-36': 32.091062095651715,
        '-35': 32.09097646085414,
        '-34': 32.090948527335335,
        '-33': 32.09093473589622,
        '-32': 32.090906712480525,
        '-31': 32.09090029068466,
        '-30': 32.09093233599276,
        '-29': 32.0909985690757,
        '-28': 32.09108651695398,
        '-27': 32.091129025060376,
        '-26': 32.091062835110975,
        '-25': 32.0909633635409,
        '-24': 32.09098130879642,
        '-23': 32.09113894072464,
        '-22': 32.091324273524805,
        '-21': 32.091449628566025,
        '-20': 32.09147096306567,
        '-19': 32.09132601287434,
        '-18': 32.09103800264094,
        '-17': 32.09082991861398,
        '-16': 32.09095046169594,
        '-15': 32.09137916052159,
        '-14': 32.0918171485817,
        '-13': 32.09199166545465,
        '-12': 32.09135416435842,
        '-11': 32.09004539565851,
        '-10': 32.08730712549375,
        '-9': 32.087381388749606,
        '-8': 32.08724694382428,
        '-7': 32.08876729078996,
        '-6': 32.08835178964664,
        '-5': 32.088460162243344,
        '-4': 32.08965262903246,
        '-3': 32.08822273397013,
        '-2': 32.0893293026061,
        '-1': 32.09011484957949
    },
    '9': {
        '0': 32.089575593871125,
        '1': 32.08747512904592,
        '2': 32.08918648967189,
        '3': 32.0891408197688,
        '4': 32.08891882474185,
        '5': 32.09080051148088,
        '6': 32.091029761669134,
        '7': 32.090266912767014,
        '8': 32.089406083713705,
        '9': 32.08550935336985,
        '10': 32.089862129756334,
        '11': 32.09065291156801,
        '12': 32.08898539746777,
        '13': 32.08981996170715,
        '14': 32.08972742592935,
        '15': 32.08820766868501,
        '16': 32.08773428049225,
        '17': 32.08730463364688,
        '18': 32.0876989195662,
        '19': 32.087545718380404,
        '20': 32.08735802330904,
        '21': 32.086984741230104,
        '22': 32.086616828984184,
        '23': 32.08507997633941,
        '24': 32.085339178298916,
        '25': 32.08471113776212,
        '26': 32.08648214365832,
        '27': 32.087055284737175,
        '28': 32.08712305551572,
        '29': 32.087169898206554,
        '30': 32.0873829578076,
        '31': 32.08747269161613,
        '32': 32.08744815731779,
        '33': 32.0875302247497,
        '34': 32.086854400915236,
        '35': 32.07484178474371,
        '36': 32.075968132773845,
        '37': 32.07636698823702,
        '38': 32.06872039092079,
        '39': 32.06758681487914,
        '40': 32.08046896146018,
        '41': 32.06948287538015,
        '42': 32.0786197287176,
        '43': 32.07775982352505,
        '44': 32.079138134099296,
        '45': 32.08275257244755,
        '46': 32.08248312674571,
        '47': 32.086122654970566,
        '48': 32.08584583727777,
        '49': 32.085162251115584,
        '50': 32.08831336655563,
        '51': 32.09122337318941,
        '52': 32.09031206536931,
        '53': 32.090051727924205,
        '54': 32.09039546453238,
        '55': 32.09106359844184,
        '56': 32.091714595033736,
        '57': 32.092091766310695,
        '58': 32.09220298814499,
        '59': 32.09216811471509,
        '60': 32.09194965590793,
        '61': 32.09154772253414,
        '62': 32.0912499965126,
        '63': 32.09123597169824,
        '64': 32.09120560229421,
        '65': 32.090902388717694,
        '66': 32.09062758554873,
        '67': 32.09067578496781,
        '68': 32.09074481556172,
        '69': 32.09053848183687,
        '70': 32.09039097102772,
        '71': 32.09059519218728,
        '72': 32.09073251157634,
        '73': 32.09043243023527,
        '74': 32.09016335156926,
        '75': 32.09045989579237,
        '76': 32.09096259134423,
        '77': 32.09007422941396,
        '78': 32.09026230300785,
        '79': 32.09080095971709,
        '80': 32.09096584151527,
        '81': 32.09083703941851,
        '82': 32.090376744702056,
        '83': 32.09008833591634,
        '84': 32.09033029751573,
        '85': 32.09084877691463,
        '86': 32.091097312484656,
        '87': 32.09100081094501,
        '88': 32.09116465182884,
        '89': 32.091940900369295,
        '90': 32.09254930999749,
        '91': 32.091968954863844,
        '92': 32.090638990618096,
        '93': 32.09014918690531,
        '94': 32.09104414305708,
        '95': 32.09214141787588,
        '96': 32.092390786595004,
        '97': 32.09216801731256,
        '98': 32.092194969974194,
        '99': 32.0916057804758,
        '100': 32.09214788988716,
        '101': 32.09175578598934,
        '102': 32.091550031932925,
        '103': 32.0916014262621,
        '104': 32.09174534502418,
        '105': 32.09182262869075,
        '106': 32.09182915758887,
        '107': 32.09178433017648,
        '108': 32.0918606229088,
        '109': 32.09199160246196,
        '110': 32.092008469234614,
        '111': 32.09207394498613,
        '112': 32.09242877274669,
        '113': 32.09275927819915,
        '114': 32.09257738513574,
        '115': 32.092118332205544,
        '116': 32.09201553871715,
        '117': 32.09231015069736,
        '118': 32.09206125049163,
        '119': 32.09287012994628,
        '120': 32.092877203688836,
        '121': 32.092284630835884,
        '122': 32.09165278568426,
        '123': 32.09241831253271,
        '124': 32.09444572002921,
        '125': 32.09529990177347,
        '126': 32.09188117353495,
        '127': 32.091644267825345,
        '128': 32.09172995512003,
        '129': 32.09305041781763,
        '130': 32.093240224587284,
        '131': 32.09226938318535,
        '132': 32.09186053449805,
        '133': 32.09235782711815,
        '134': 32.09269482035405,
        '135': 32.09255826003973,
        '136': 32.09244260022732,
        '137': 32.09235890069663,
        '138': 32.092196592150046,
        '139': 32.09247055603806,
        '140': 32.09332031587925,
        '141': 32.0937995490271,
        '142': 32.09332465000323,
        '143': 32.09269821305881,
        '144': 32.092715503402594,
        '145': 32.09295407063572,
        '146': 32.09278928126843,
        '147': 32.09242153539101,
        '148': 32.09219960870926,
        '149': 32.092027923710916,
        '150': 32.091913121991624,
        '151': 32.09205481961736,
        '152': 32.09218589695408,
        '153': 32.09187212757812,
        '154': 32.09145627730453,
        '155': 32.091611447653484,
        '156': 32.09210462969999,
        '157': 32.09213507172735,
        '158': 32.091702352162486,
        '159': 32.091522153632916,
        '160': 32.09177314986227,
        '161': 32.091957019360045,
        '162': 32.091843497041516,
        '163': 32.091682509557785,
        '164': 32.09162585344754,
        '165': 32.0916140305786,
        '166': 32.091670183609615,
        '167': 32.0918281857793,
        '168': 32.091966980167086,
        '169': 32.09199680220666,
        '170': 32.09196580806085,
        '171': 32.091886719243774,
        '172': 32.09176470029687,
        '173': 32.09174666197557,
        '174': 32.091889502813906,
        '175': 32.09195333344962,
        '176': 32.09179794179772,
        '177': 32.09168338104332,
        '178': 32.09178072187726,
        '179': 32.09180812194921,
        '180': 32.09158964072759,
        '-180': 32.09158964072759,
        '-179': 32.091457876961286,
        '-178': 32.09161838280507,
        '-177': 32.09169199455401,
        '-176': 32.09141465569075,
        '-175': 32.09118666259281,
        '-174': 32.09137063330286,
        '-173': 32.091651430243786,
        '-172': 32.09166181974962,
        '-171': 32.09163895038187,
        '-170': 32.09187284161683,
        '-169': 32.09208159220828,
        '-168': 32.09194283287975,
        '-167': 32.091737275231736,
        '-166': 32.09187087534776,
        '-165': 32.09218544421344,
        '-164': 32.09228629930237,
        '-163': 32.092187434975315,
        '-162': 32.092145104706184,
        '-161': 32.09216660613333,
        '-160': 32.09211561025159,
        '-159': 32.09204344957726,
        '-158': 32.09205402055671,
        '-157': 32.09206154887977,
        '-156': 32.091966881593514,
        '-155': 32.091867212291184,
        '-154': 32.09187140269052,
        '-153': 32.09191322496987,
        '-152': 32.09191863025848,
        '-151': 32.091947020234784,
        '-150': 32.092024593979524,
        '-149': 32.092046098610226,
        '-148': 32.09196616084612,
        '-147': 32.091898478362715,
        '-146': 32.09192677357734,
        '-145': 32.09198637914609,
        '-144': 32.091999392247956,
        '-143': 32.091971833135126,
        '-142': 32.09191905817744,
        '-141': 32.09183829104151,
        '-140': 32.09177165997403,
        '-139': 32.091771946554765,
        '-138': 32.09181029022749,
        '-137': 32.091816304521274,
        '-136': 32.09177567329613,
        '-135': 32.091708764381984,
        '-134': 32.09161452874935,
        '-133': 32.09150721448586,
        '-132': 32.09144102636687,
        '-131': 32.09144502467245,
        '-130': 32.09148995812099,
        '-129': 32.09152800030029,
        '-128': 32.09151148266457,
        '-127': 32.09141734095815,
        '-126': 32.09131197749941,
        '-125': 32.09129846272225,
        '-124': 32.0913465168213,
        '-123': 32.09132663621481,
        '-122': 32.091255076211795,
        '-121': 32.0912791483548,
        '-120': 32.091374805781065,
        '-119': 32.091355692305896,
        '-118': 32.09123814613383,
        '-117': 32.09124410846703,
        '-116': 32.09137928674538,
        '-115': 32.09140711213255,
        '-114': 32.09130541767714,
        '-113': 32.091317448930944,
        '-112': 32.091489887044155,
        '-111': 32.09160462692245,
        '-110': 32.0916060981726,
        '-109': 32.09165049349206,
        '-108': 32.09173574220624,
        '-107': 32.09172638166506,
        '-106': 32.09168981855385,
        '-105': 32.09177759826122,
        '-104': 32.091894581576696,
        '-103': 32.091894675210554,
        '-102': 32.09189964478526,
        '-101': 32.09203243739326,
        '-100': 32.09212614106915,
        '-99': 32.0920701033471,
        '-98': 32.092067286725616,
        '-97': 32.092200801017576,
        '-96': 32.09220944934155,
        '-95': 32.09201524843593,
        '-94': 32.091933180729946,
        '-93': 32.0920754093218,
        '-92': 32.092165039903534,
        '-91': 32.09217237786325,
        '-90': 32.092418442527475,
        '-89': 32.09282649683724,
        '-88': 32.09286195383984,
        '-87': 32.092468568553215,
        '-86': 32.092290846542575,
        '-85': 32.09270816453211,
        '-84': 32.093325631730586,
        '-83': 32.0835344094092,
        '-82': 32.09348872768826,
        '-81': 32.093225980126036,
        '-80': 32.09263943369043,
        '-79': 32.092776848589615,
        '-78': 32.090130850502035,
        '-77': 32.09235828340851,
        '-76': 32.09023829441349,
        '-75': 32.09039448308838,
        '-74': 32.09129052378365,
        '-73': 32.09080838873688,
        '-72': 32.091585971353496,
        '-71': 32.07763935623435,
        '-70': 32.08897894289595,
        '-69': 32.091729660954144,
        '-68': 32.092133977000415,
        '-67': 32.09135411320757,
        '-66': 32.090729718551806,
        '-65': 32.09069450300091,
        '-64': 32.08881999446729,
        '-63': 32.089404782534345,
        '-62': 32.08999971107015,
        '-61': 32.090608698292534,
        '-60': 32.09148640862825,
        '-59': 32.091748511710385,
        '-58': 32.091475981101645,
        '-57': 32.09079529654587,
        '-56': 32.090209493665974,
        '-55': 32.090221831754846,
        '-54': 32.090559500921785,
        '-53': 32.09051357777256,
        '-52': 32.09011841570714,
        '-51': 32.090095371191765,
        '-50': 32.09058686563298,
        '-49': 32.09094718189185,
        '-48': 32.090885576834445,
        '-47': 32.09082737289131,
        '-46': 32.09100814651187,
        '-45': 32.091138282170405,
        '-44': 32.09111893851139,
        '-43': 32.09125763130268,
        '-42': 32.091622632158085,
        '-41': 32.09189266514067,
        '-40': 32.0919482692926,
        '-39': 32.09200160281693,
        '-38': 32.09209831924151,
        '-37': 32.092038138919676,
        '-36': 32.091808770697305,
        '-35': 32.09163392401495,
        '-34': 32.09159453912558,
        '-33': 32.09157599531975,
        '-32': 32.09154629038222,
        '-31': 32.09157466389718,
        '-30': 32.09163674185055,
        '-29': 32.09164956754735,
        '-28': 32.091629420765365,
        '-27': 32.09163787152362,
        '-26': 32.091663550898964,
        '-25': 32.09168545142518,
        '-24': 32.091746184372234,
        '-23': 32.09186086345132,
        '-22': 32.09195083438179,
        '-21': 32.09194395989811,
        '-20': 32.09187026191843,
        '-19': 32.09182246586057,
        '-18': 32.09188150990663,
        '-17': 32.09209117810304,
        '-16': 32.092440245902544,
        '-15': 32.09282681642129,
        '-14': 32.093061203953596,
        '-13': 32.09281715486818,
        '-12': 32.09159587186772,
        '-11': 32.0883967343718,
        '-10': 32.08664676321108,
        '-9': 32.08747010068291,
        '-8': 32.086469286554205,
        '-7': 32.08837273434415,
        '-6': 32.08864923886272,
        '-5': 32.088886010305835,
        '-4': 32.08970795605033,
        '-3': 32.089059047929865,
        '-2': 32.089294208776565,
        '-1': 32.09013433756959
    },
    '10': {
        '0': 32.09056597071047,
        '1': 32.09014684168304,
        '2': 32.088592096967496,
        '3': 32.08908641612385,
        '4': 32.090270942621466,
        '5': 32.08987965691084,
        '6': 32.09102749895379,
        '7': 32.0891629519829,
        '8': 32.085770163350894,
        '9': 32.0844170523106,
        '10': 32.08796624912383,
        '11': 32.08878545059112,
        '12': 32.09112165301366,
        '13': 32.08843311688809,
        '14': 32.08939631700039,
        '15': 32.089471118249904,
        '16': 32.08931493436495,
        '17': 32.08903779803345,
        '18': 32.08872918405349,
        '19': 32.0885858899384,
        '20': 32.08824580304955,
        '21': 32.08783961850626,
        '22': 32.0876867853161,
        '23': 32.087144575582776,
        '24': 32.086649379771124,
        '25': 32.08761710185739,
        '26': 32.08795671047764,
        '27': 32.08804370858035,
        '28': 32.08806151178457,
        '29': 32.08835691242612,
        '30': 32.08851066725562,
        '31': 32.088578839146585,
        '32': 32.08870375230473,
        '33': 32.08865229421879,
        '34': 32.08776644006221,
        '35': 32.0759629315545,
        '36': 32.081915938410496,
        '37': 32.069372770418326,
        '38': 32.07837093944292,
        '39': 32.07127869368004,
        '40': 32.08087581849031,
        '41': 32.08641765964492,
        '42': 32.08556635661033,
        '43': 32.07853698169213,
        '44': 32.08351239302565,
        '45': 32.077567578014595,
        '46': 32.083450973437714,
        '47': 32.08077385583092,
        '48': 32.08350049951686,
        '49': 32.08434548727132,
        '50': 32.08760922750886,
        '51': 32.09274142968959,
        '52': 32.09183153029297,
        '53': 32.09132451384425,
        '54': 32.09136672744133,
        '55': 32.09185682418033,
        '56': 32.092477855918666,
        '57': 32.092915322687865,
        '58': 32.0931014355476,
        '59': 32.09309923988081,
        '60': 32.09288337546223,
        '61': 32.09249498044019,
        '62': 32.09219990579121,
        '63': 32.09215090300081,
        '64': 32.09213014062896,
        '65': 32.09195988145185,
        '66': 32.09183339555659,
        '67': 32.09188319836803,
        '68': 32.09185441740287,
        '69': 32.091606968619004,
        '70': 32.09144730488261,
        '71': 32.09153912028702,
        '72': 32.0915353652596,
        '73': 32.09124178392563,
        '74': 32.091066674731,
        '75': 32.09134576014345,
        '76': 32.09177116264333,
        '77': 32.08541082888292,
        '78': 32.0901844534106,
        '79': 32.09127942498072,
        '80': 32.09117061812631,
        '81': 32.09075540380932,
        '82': 32.09060864636819,
        '83': 32.090857039632276,
        '84': 32.09125964428716,
        '85': 32.091531304821444,
        '86': 32.091606387758,
        '87': 32.09166147238581,
        '88': 32.09201050551851,
        '89': 32.0926715163996,
        '90': 32.09299709470529,
        '91': 32.092351897158046,
        '92': 32.09124699211323,
        '93': 32.09095158986982,
        '94': 32.091775745706585,
        '95': 32.09269802256699,
        '96': 32.092951521951285,
        '97': 32.0929078394612,
        '98': 32.09303412761558,
        '99': 32.09208469648532,
        '100': 32.09279029461644,
        '101': 32.09251626173376,
        '102': 32.09255776968499,
        '103': 32.09268427619296,
        '104': 32.09263478208494,
        '105': 32.092518223481065,
        '106': 32.09249313130081,
        '107': 32.09263722406083,
        '108': 32.09284330258301,
        '109': 32.09300560339003,
        '110': 32.09295986615008,
        '111': 32.09281774942941,
        '112': 32.092916185248825,
        '113': 32.09326155349533,
        '114': 32.093468323413674,
        '115': 32.093389136325364,
        '116': 32.09332491326576,
        '117': 32.09355638392048,
        '118': 32.09407643366118,
        '119': 32.093889321624175,
        '120': 32.09464537532154,
        '121': 32.09386152838476,
        '122': 32.093102681376344,
        '123': 32.08554379180026,
        '124': 32.09506628346462,
        '125': 32.09531120975291,
        '126': 32.093682597081035,
        '127': 32.092181441091746,
        '128': 32.09253859753678,
        '129': 32.09369338038261,
        '130': 32.09379864954101,
        '131': 32.09304718098941,
        '132': 32.092730107715376,
        '133': 32.09296134174288,
        '134': 32.09310646461873,
        '135': 32.09322161549885,
        '136': 32.09351577159172,
        '137': 32.093535734240774,
        '138': 32.09311531277267,
        '139': 32.09291557532816,
        '140': 32.09308520378586,
        '141': 32.092804546169376,
        '142': 32.09197102470002,
        '143': 32.09176263976978,
        '144': 32.092720566657455,
        '145': 32.093805656213476,
        '146': 32.09404764953305,
        '147': 32.09366329360614,
        '148': 32.0930725756343,
        '149': 32.09231715654744,
        '150': 32.09179098496707,
        '151': 32.09204289451641,
        '152': 32.09263741614796,
        '153': 32.09264564911084,
        '154': 32.092258212076516,
        '155': 32.09241795453622,
        '156': 32.09300235342726,
        '157': 32.09299573030355,
        '158': 32.09237088501643,
        '159': 32.09217215784887,
        '160': 32.092704232288085,
        '161': 32.093123579778265,
        '162': 32.09293376098078,
        '163': 32.09261092407338,
        '164': 32.09260783693703,
        '165': 32.092771897826836,
        '166': 32.09288096837362,
        '167': 32.0929729988963,
        '168': 32.09306181051692,
        '169': 32.09306412992923,
        '170': 32.093003009716355,
        '171': 32.092956508198384,
        '172': 32.09292233417625,
        '173': 32.092909020917915,
        '174': 32.09295174294441,
        '175': 32.0929740322477,
        '176': 32.092893619590356,
        '177': 32.092814866754686,
        '178': 32.09281969657246,
        '179': 32.09273972761141,
        '180': 32.09248730118101,
        '-180': 32.09248730118101,
        '-179': 32.09234531567341,
        '-178': 32.09251658609,
        '-177': 32.092708296374575,
        '-176': 32.09261470146028,
        '-175': 32.092451254961354,
        '-174': 32.092534963578245,
        '-173': 32.09272385056549,
        '-172': 32.092765521150305,
        '-171': 32.092796214647194,
        '-170': 32.09298599128286,
        '-169': 32.09309735378478,
        '-168': 32.09292247441451,
        '-167': 32.092774395302825,
        '-166': 32.09301031386362,
        '-165': 32.093398437033905,
        '-164': 32.09346667182199,
        '-163': 32.09320304921968,
        '-162': 32.0929538747653,
        '-161': 32.09286707890922,
        '-160': 32.09284208667644,
        '-159': 32.09283132164372,
        '-158': 32.09284870654128,
        '-157': 32.092840222581046,
        '-156': 32.09279419711493,
        '-155': 32.0928126203848,
        '-154': 32.09290406295705,
        '-153': 32.092912852968425,
        '-152': 32.09279184663666,
        '-151': 32.092715224640415,
        '-150': 32.09279251618774,
        '-149': 32.09290035582112,
        '-148': 32.09291394636918,
        '-147': 32.09288522097745,
        '-146': 32.09288757169204,
        '-145': 32.09288708734797,
        '-144': 32.09285981681541,
        '-143': 32.09286086219814,
        '-142': 32.09290383620526,
        '-141': 32.09291590732001,
        '-140': 32.09285914370783,
        '-139': 32.092781909277605,
        '-138': 32.092732656563314,
        '-137': 32.09271135978301,
        '-136': 32.092696858646875,
        '-135': 32.09265975435086,
        '-134': 32.092582057810915,
        '-133': 32.09249962777538,
        '-132': 32.09246868677946,
        '-131': 32.092479892465185,
        '-130': 32.09247796475653,
        '-129': 32.09245007448621,
        '-128': 32.092413197656846,
        '-127': 32.09235965234791,
        '-126': 32.092299142098184,
        '-125': 32.09227766147155,
        '-124': 32.09227939536799,
        '-123': 32.092227540778495,
        '-122': 32.092143877641114,
        '-121': 32.09213928526361,
        '-120': 32.09219552966595,
        '-119': 32.09217703877712,
        '-118': 32.09210064079058,
        '-117': 32.092124151572996,
        '-116': 32.092235787859536,
        '-115': 32.09226805919211,
        '-114': 32.09223904079675,
        '-113': 32.09232943090514,
        '-112': 32.09251549711207,
        '-111': 32.09259957074583,
        '-110': 32.09258681802553,
        '-109': 32.09264191603148,
        '-108': 32.09272796392454,
        '-107': 32.0926979812622,
        '-106': 32.09265235984325,
        '-105': 32.09277981571086,
        '-104': 32.0929650286799,
        '-103': 32.092979103577065,
        '-102': 32.09290290052622,
        '-101': 32.092942291866514,
        '-100': 32.0930127088402,
        '-99': 32.09291975243941,
        '-98': 32.09275559413167,
        '-97': 32.0927423886057,
        '-96': 32.09287863895603,
        '-95': 32.09303513096526,
        '-94': 32.09318584399736,
        '-93': 32.09334261453566,
        '-92': 32.09349677873179,
        '-91': 32.09369873766175,
        '-90': 32.09391012685024,
        '-89': 32.093858286871225,
        '-88': 32.093452559668975,
        '-87': 32.093185595494795,
        '-86': 32.09356960373691,
        '-85': 32.09429136780923,
        '-84': 32.080161007009174,
        '-83': 32.09401598850398,
        '-82': 32.09312969071893,
        '-81': 32.09252145611719,
        '-80': 32.09246833525402,
        '-79': 32.09270952586676,
        '-78': 32.092609274524584,
        '-77': 32.0919983354185,
        '-76': 32.09181348418179,
        '-75': 32.091080908470765,
        '-74': 32.09386906295783,
        '-73': 32.067554365691485,
        '-72': 32.09339914324293,
        '-71': 32.0922462285368,
        '-70': 32.08452026157631,
        '-69': 32.09196589063377,
        '-68': 32.08927201835692,
        '-67': 32.08731755605781,
        '-66': 32.08798008378929,
        '-65': 32.0896831411741,
        '-64': 32.077595223714226,
        '-63': 32.09074687898182,
        '-62': 32.09047771443188,
        '-61': 32.09084421865153,
        '-60': 32.09147600209977,
        '-59': 32.091881027718166,
        '-58': 32.09178951168384,
        '-57': 32.091242825353824,
        '-56': 32.09077955309272,
        '-55': 32.090929153169675,
        '-54': 32.09141228625425,
        '-53': 32.09154636785561,
        '-52': 32.091371914339156,
        '-51': 32.0914912962843,
        '-50': 32.09189628761964,
        '-49': 32.091977885020775,
        '-48': 32.091670204209684,
        '-47': 32.091559107231504,
        '-46': 32.09182781935751,
        '-45': 32.09205640950877,
        '-44': 32.09210754221415,
        '-43': 32.09231413488293,
        '-42': 32.09272816197962,
        '-41': 32.092979448072036,
        '-40': 32.092938996200466,
        '-39': 32.09285292587022,
        '-38': 32.092814289074575,
        '-37': 32.09268848356871,
        '-36': 32.09252123189388,
        '-35': 32.092498234055924,
        '-34': 32.09256493400999,
        '-33': 32.09253131941661,
        '-32': 32.09244772623714,
        '-31': 32.092481166721726,
        '-30': 32.09255690951945,
        '-29': 32.09249755103492,
        '-28': 32.09238437958162,
        '-27': 32.0924314095804,
        '-26': 32.092610839209875,
        '-25': 32.09271757335236,
        '-24': 32.092715634451885,
        '-23': 32.092726060876196,
        '-22': 32.092727114912,
        '-21': 32.092563909419596,
        '-20': 32.09228728705823,
        '-19': 32.092224149585995,
        '-18': 32.09258585114236,
        '-17': 32.0931752038141,
        '-16': 32.09362986661741,
        '-15': 32.09382459591609,
        '-14': 32.09384624534199,
        '-13': 32.0899998092645,
        '-12': 32.091021826187884,
        '-11': 32.08857844690409,
        '-10': 32.088914448882555,
        '-9': 32.08904918754634,
        '-8': 32.08898142014152,
        '-7': 32.088959234368545,
        '-6': 32.089365996265514,
        '-5': 32.090079945527336,
        '-4': 32.089311058571695,
        '-3': 32.09008884334103,
        '-2': 32.09004507700996,
        '-1': 32.09144105223234
    },
    '11': {
        '0': 32.09126965084259,
        '1': 32.09195801840872,
        '2': 32.08995839483846,
        '3': 32.09117127833183,
        '4': 32.0907892739716,
        '5': 32.091207873713216,
        '6': 32.088951415529245,
        '7': 32.0877474733281,
        '8': 32.08789946585876,
        '9': 32.08598243573294,
        '10': 32.089095295820634,
        '11': 32.09012352897195,
        '12': 32.088260442414025,
        '13': 32.08967682798367,
        '14': 32.08953902920194,
        '15': 32.090896331287205,
        '16': 32.090585546008796,
        '17': 32.090318959867034,
        '18': 32.08999999401045,
        '19': 32.08915682827998,
        '20': 32.08937719603183,
        '21': 32.088752168091574,
        '22': 32.08860970040907,
        '23': 32.08815334407241,
        '24': 32.08828538602016,
        '25': 32.08870513709357,
        '26': 32.08884758061371,
        '27': 32.08898476446616,
        '28': 32.08906368793741,
        '29': 32.089003665092555,
        '30': 32.08880353421503,
        '31': 32.088505038403525,
        '32': 32.08973253290733,
        '33': 32.08957890222039,
        '34': 32.0870236383219,
        '35': 32.086228266769645,
        '36': 32.08460847273386,
        '37': 32.06737233868036,
        '38': 32.07373522067551,
        '39': 32.062658212354506,
        '40': 32.07934334538676,
        '41': 32.08641817278057,
        '42': 32.09102274275406,
        '43': 32.090189064684054,
        '44': 32.09390117162559,
        '45': 32.09367542644126,
        '46': 32.09376348697809,
        '47': 32.09414445533714,
        '48': 32.08532922655259,
        '49': 32.0829441536883,
        '50': 32.08247049355303,
        '51': 32.093627857287736,
        '52': 32.09367963793009,
        '53': 32.09310664272708,
        '54': 32.0928075883198,
        '55': 32.09295981312758,
        '56': 32.09338305545233,
        '57': 32.093716622116,
        '58': 32.09379596038143,
        '59': 32.09368971808062,
        '60': 32.093485131121845,
        '61': 32.09325851632918,
        '62': 32.09313276673375,
        '63': 32.09314244920995,
        '64': 32.093171554872114,
        '65': 32.09317149555204,
        '66': 32.093214285672744,
        '67': 32.09321981596933,
        '68': 32.09298461799378,
        '69': 32.09261615800205,
        '70': 32.09250190563755,
        '71': 32.09268560078332,
        '72': 32.09275876078464,
        '73': 32.09254322115386,
        '74': 32.09237035184293,
        '75': 32.09251007809629,
        '76': 32.09226747635905,
        '77': 32.0889801389985,
        '78': 32.09132244484891,
        '79': 32.092063094221935,
        '80': 32.092023296102425,
        '81': 32.091529926497365,
        '82': 32.091692729030626,
        '83': 32.09225123773227,
        '84': 32.09255682189677,
        '85': 32.092464599521776,
        '86': 32.092385945387676,
        '87': 32.092653192943025,
        '88': 32.09324560519367,
        '89': 32.093861843743774,
        '90': 32.09397590956483,
        '91': 32.09328517431826,
        '92': 32.09234524155507,
        '93': 32.09213622586935,
        '94': 32.09277352876253,
        '95': 32.093399562744516,
        '96': 32.0935382535175,
        '97': 32.0936222957825,
        '98': 32.09392292619367,
        '99': 32.091512487139305,
        '100': 32.09366163837489,
        '101': 32.09341945101693,
        '102': 32.093632264859835,
        '103': 32.093889250537636,
        '104': 32.086617144787155,
        '105': 32.093473411353656,
        '106': 32.09346755803789,
        '107': 32.09363681935983,
        '108': 32.09388329970005,
        '109': 32.094275540336035,
        '110': 32.09403795376813,
        '111': 32.09371731295374,
        '112': 32.09368739897996,
        '113': 32.09404533919581,
        '114': 32.09448941646786,
        '115': 32.094719283413,
        '116': 32.09475349217707,
        '117': 32.094820278183406,
        '118': 32.09511060760719,
        '119': 32.09553643100281,
        '120': 32.09566831316797,
        '121': 32.095298472865615,
        '122': 32.095047429352334,
        '123': 32.09559305325936,
        '124': 32.09562403122764,
        '125': 32.09550006624521,
        '126': 32.093873286296166,
        '127': 32.09299715944354,
        '128': 32.093848349323075,
        '129': 32.09492495279268,
        '130': 32.094813336888414,
        '131': 32.09413971976142,
        '132': 32.09398759559698,
        '133': 32.0941148673531,
        '134': 32.09399126176425,
        '135': 32.09401975604782,
        '136': 32.094489470660605,
        '137': 32.094717570504116,
        '138': 32.09425342759971,
        '139': 32.09359130866989,
        '140': 32.0929785539273,
        '141': 32.09202162201853,
        '142': 32.0910305370952,
        '143': 32.09109056528313,
        '144': 32.09226738821194,
        '145': 32.09337484846878,
        '146': 32.0938605371882,
        '147': 32.09422035998604,
        '148': 32.09441957172158,
        '149': 32.09383302057526,
        '150': 32.09290490419085,
        '151': 32.09287317902443,
        '152': 32.09365324826165,
        '153': 32.093933304247244,
        '154': 32.09348493443411,
        '155': 32.093403346488245,
        '156': 32.09393372624786,
        '157': 32.094066683634644,
        '158': 32.09353987983155,
        '159': 32.093342749796946,
        '160': 32.093868742118396,
        '161': 32.09425154607398,
        '162': 32.09395328473556,
        '163': 32.093576980147795,
        '164': 32.093687830806545,
        '165': 32.09399801274778,
        '166': 32.09408705450199,
        '167': 32.094021485034645,
        '168': 32.09398860286655,
        '169': 32.09398825375158,
        '170': 32.094025828827505,
        '171': 32.09412427123131,
        '172': 32.09415208443059,
        '173': 32.09400401988278,
        '174': 32.09382956447522,
        '175': 32.093802757871565,
        '176': 32.09386204374925,
        '177': 32.09387939532428,
        '178': 32.09384058187438,
        '179': 32.093733609333825,
        '180': 32.09354626533428,
        '-180': 32.09354626533428,
        '-179': 32.09343231763122,
        '-178': 32.09356175312156,
        '-177': 32.093800762915414,
        '-176': 32.09387136700022,
        '-175': 32.093780686271515,
        '-174': 32.09375015551695,
        '-173': 32.093812574961305,
        '-172': 32.093836222304375,
        '-171': 32.09383614646433,
        '-170': 32.09391213648999,
        '-169': 32.09400427728141,
        '-168': 32.094030783924524,
        '-167': 32.09410781378793,
        '-166': 32.09432149987076,
        '-165': 32.094464578245315,
        '-164': 32.094313678353444,
        '-163': 32.094000753477985,
        '-162': 32.093824112427384,
        '-161': 32.093846948377035,
        '-160': 32.09392312767808,
        '-159': 32.093958671473416,
        '-158': 32.09394206847545,
        '-157': 32.09387497946451,
        '-156': 32.0938327674808,
        '-155': 32.093926846085004,
        '-154': 32.09409401041801,
        '-153': 32.094115295428246,
        '-152': 32.09393436615138,
        '-151': 32.09375149784402,
        '-150': 32.093727706996034,
        '-149': 32.09380673868112,
        '-148': 32.093888477899114,
        '-147': 32.09396123356236,
        '-146': 32.09400523994444,
        '-145': 32.09397026908284,
        '-144': 32.09390200594757,
        '-143': 32.09391351647719,
        '-142': 32.09399892654046,
        '-141': 32.09403037102949,
        '-140': 32.09395003599824,
        '-139': 32.093828565482006,
        '-138': 32.09373978861179,
        '-137': 32.09369378765691,
        '-136': 32.09367639062174,
        '-135': 32.09366768256754,
        '-134': 32.09364849648833,
        '-133': 32.09363226685921,
        '-132': 32.09363734345058,
        '-131': 32.09362208322149,
        '-130': 32.093537242021974,
        '-129': 32.093431288276584,
        '-128': 32.09339150398846,
        '-127': 32.093403820666424,
        '-126': 32.09338980421029,
        '-125': 32.09334139557241,
        '-124': 32.09329816871535,
        '-123': 32.09324965762649,
        '-122': 32.09317719567067,
        '-121': 32.09312200192567,
        '-120': 32.09311487787948,
        '-119': 32.09312563356069,
        '-118': 32.09314557444906,
        '-117': 32.09320356494816,
        '-116': 32.09327028601482,
        '-115': 32.09329485763769,
        '-114': 32.09333071093361,
        '-113': 32.093444601478204,
        '-112': 32.093547861540934,
        '-111': 32.09353776358354,
        '-110': 32.09352159536184,
        '-109': 32.09363994412718,
        '-108': 32.09378452185328,
        '-107': 32.09379122364206,
        '-106': 32.09377057671749,
        '-105': 32.09390756437761,
        '-104': 32.094085946105906,
        '-103': 32.09407910164002,
        '-102': 32.09396710205591,
        '-101': 32.093985181668415,
        '-100': 32.09408789962849,
        '-99': 32.094041734354846,
        '-98': 32.09386611173365,
        '-97': 32.09384497686005,
        '-96': 32.09412123027278,
        '-95': 32.09451165662231,
        '-94': 32.094735766197545,
        '-93': 32.09472568283821,
        '-92': 32.094677396441035,
        '-91': 32.09473280705783,
        '-90': 32.094650496028564,
        '-89': 32.09410425664369,
        '-88': 32.09340298012499,
        '-87': 32.093410266071174,
        '-86': 32.09440344584725,
        '-85': 32.09512176260718,
        '-84': 32.095206207671964,
        '-83': 32.09467822143756,
        '-82': 32.09355892238836,
        '-81': 32.09295024058048,
        '-80': 32.093021107120414,
        '-79': 32.09340755732603,
        '-78': 32.09347057669638,
        '-77': 32.09308509827898,
        '-76': 32.09313517111853,
        '-75': 32.094347594950776,
        '-74': 32.07622980118551,
        '-73': 32.08876237790759,
        '-72': 32.09432566843357,
        '-71': 32.09350135120854,
        '-70': 32.09257437909011,
        '-69': 32.093959777992865,
        '-68': 32.0946580396264,
        '-67': 32.093539603869544,
        '-66': 32.09322084655839,
        '-65': 32.09351415222793,
        '-64': 32.092122454003075,
        '-63': 32.09272325196477,
        '-62': 32.09209073481675,
        '-61': 32.09181616941297,
        '-60': 32.09184544144807,
        '-59': 32.09210801523274,
        '-58': 32.09238592372284,
        '-57': 32.09240029997708,
        '-56': 32.092315180624176,
        '-55': 32.092528563664565,
        '-54': 32.09289484555585,
        '-53': 32.09291591790341,
        '-52': 32.092670388962226,
        '-51': 32.09267837136171,
        '-50': 32.09290619063548,
        '-49': 32.09284858917177,
        '-48': 32.09254891653807,
        '-47': 32.09257465445621,
        '-46': 32.092993966869834,
        '-45': 32.093296305214416,
        '-44': 32.093325247382126,
        '-43': 32.09342751117823,
        '-42': 32.09367581431146,
        '-41': 32.09373996028618,
        '-40': 32.0935453141885,
        '-39': 32.09337474898408,
        '-38': 32.093332376920145,
        '-37': 32.093289332704884,
        '-36': 32.09328113947313,
        '-35': 32.0934273817764,
        '-34': 32.093578031379415,
        '-33': 32.09352559695052,
        '-32': 32.09341363096096,
        '-31': 32.09348572371696,
        '-30': 32.09362372587848,
        '-29': 32.09358650385123,
        '-28': 32.093502605597884,
        '-27': 32.09364887942981,
        '-26': 32.09390642078012,
        '-25': 32.093927785236396,
        '-24': 32.093739636996794,
        '-23': 32.09368090666603,
        '-22': 32.09376905119641,
        '-21': 32.09364344341613,
        '-20': 32.09324737173599,
        '-19': 32.09308339310639,
        '-18': 32.093523803170584,
        '-17': 32.0942298989787,
        '-16': 32.09460385783317,
        '-15': 32.094493066091786,
        '-14': 32.09340665264809,
        '-13': 32.08873040341201,
        '-12': 32.08729276147855,
        '-11': 32.09047318009272,
        '-10': 32.09011366616208,
        '-9': 32.09019729729366,
        '-8': 32.09002664820708,
        '-7': 32.09028828479556,
        '-6': 32.09046438096801,
        '-5': 32.08853425939379,
        '-4': 32.09087912163155,
        '-3': 32.090815874432685,
        '-2': 32.090976081942955,
        '-1': 32.091878537690484
    },
    '12': {
        '0': 32.09223319714631,
        '1': 32.09267389545696,
        '2': 32.09191733125401,
        '3': 32.09296310726611,
        '4': 32.092323492587894,
        '5': 32.091859346307835,
        '6': 32.09084494448076,
        '7': 32.089621415752895,
        '8': 32.089843472362126,
        '9': 32.091304845242114,
        '10': 32.09157082227339,
        '11': 32.09147822997271,
        '12': 32.09136098987815,
        '13': 32.09173618570989,
        '14': 32.09199093762972,
        '15': 32.09209481126058,
        '16': 32.09198477515054,
        '17': 32.09185862974002,
        '18': 32.09127104636804,
        '19': 32.089095080669715,
        '20': 32.090497436468944,
        '21': 32.08957277026739,
        '22': 32.08901959203791,
        '23': 32.088688029391825,
        '24': 32.08767904764838,
        '25': 32.08880139572209,
        '26': 32.08945467394097,
        '27': 32.08991679715129,
        '28': 32.089958631161046,
        '29': 32.089221641921434,
        '30': 32.0887299557664,
        '31': 32.0876434496586,
        '32': 32.090403663654605,
        '33': 32.09085886745673,
        '34': 32.0898418287998,
        '35': 32.08944374919106,
        '36': 32.08792083028426,
        '37': 32.07685245504497,
        '38': 32.074908738948295,
        '39': 32.07455099404594,
        '40': 32.08637897140133,
        '41': 32.091548359724015,
        '42': 32.08942028950567,
        '43': 32.08670262457277,
        '44': 32.094960160062115,
        '45': 32.09478560510722,
        '46': 32.09480652993216,
        '47': 32.0949597100922,
        '48': 32.09508660293052,
        '49': 32.09519294955336,
        '50': 32.09536297685556,
        '51': 32.09550598404845,
        '52': 32.09540129568309,
        '53': 32.09503508976768,
        '54': 32.09471673540206,
        '55': 32.09472790464904,
        '56': 32.09496048213553,
        '57': 32.09505706916889,
        '58': 32.094848723543215,
        '59': 32.094505503764104,
        '60': 32.09429538804582,
        '61': 32.09431964290931,
        '62': 32.09445670974797,
        '63': 32.09449884201262,
        '64': 32.09438846876991,
        '65': 32.09429365857798,
        '66': 32.09432441992852,
        '67': 32.0942842528562,
        '68': 32.09397576701755,
        '69': 32.09363733711361,
        '70': 32.09367126728774,
        '71': 32.09399836024634,
        '72': 32.09414723646528,
        '73': 32.09397195117772,
        '74': 32.09381276461136,
        '75': 32.093945912083,
        '76': 32.08565397980675,
        '77': 32.087898579732155,
        '78': 32.090188364378044,
        '79': 32.092924775413024,
        '80': 32.09351544603005,
        '81': 32.09300123143571,
        '82': 32.09328608978717,
        '83': 32.09389333066493,
        '84': 32.09405397792047,
        '85': 32.09375620064948,
        '86': 32.093600746313555,
        '87': 32.093930944088854,
        '88': 32.09459307190725,
        '89': 32.09521558069946,
        '90': 32.09534347436956,
        '91': 32.09474664061092,
        '92': 32.09391604516647,
        '93': 32.093101188014316,
        '94': 32.09417733520138,
        '95': 32.09459583331819,
        '96': 32.09459782508814,
        '97': 32.094667820789304,
        '98': 32.09501336576555,
        '99': 32.0937355833014,
        '100': 32.0947966803528,
        '101': 32.094464087806706,
        '102': 32.09468038860858,
        '103': 32.09059278524339,
        '104': 32.09329440278633,
        '105': 32.09454762692433,
        '106': 32.09399323605541,
        '107': 32.09272427634672,
        '108': 32.08858595999952,
        '109': 32.087575809304546,
        '110': 32.095069939184874,
        '111': 32.094785065495984,
        '112': 32.094865647484816,
        '113': 32.09514703924095,
        '114': 32.09540528885572,
        '115': 32.09559026832055,
        '116': 32.09568876985932,
        '117': 32.09563809877335,
        '118': 32.0955313389609,
        '119': 32.0955992466468,
        '120': 32.095830394725624,
        '121': 32.09618506993805,
        '122': 32.096678676171116,
        '123': 32.09733314117769,
        '124': 32.09734912376692,
        '125': 32.09272696782247,
        '126': 32.094602892062646,
        '127': 32.09443448143695,
        '128': 32.095692116583415,
        '129': 32.096487608503054,
        '130': 32.09586445687006,
        '131': 32.09503660783483,
        '132': 32.095108952848264,
        '133': 32.09544510112572,
        '134': 32.09526191430928,
        '135': 32.09507375193448,
        '136': 32.095443998981324,
        '137': 32.095821119469406,
        '138': 32.09561454228226,
        '139': 32.09509764585766,
        '140': 32.09454168985657,
        '141': 32.09379676773206,
        '142': 32.093157653110225,
        '143': 32.09318532470477,
        '144': 32.09347237729103,
        '145': 32.09321209558402,
        '146': 32.09297035199241,
        '147': 32.09396021262785,
        '148': 32.0956049556945,
        '149': 32.09602752194541,
        '150': 32.09505756245457,
        '151': 32.09445550289609,
        '152': 32.094926643418354,
        '153': 32.09525254436051,
        '154': 32.09478085838607,
        '155': 32.09447827272162,
        '156': 32.0949039805503,
        '157': 32.095214061621355,
        '158': 32.09488741906242,
        '159': 32.0946451031194,
        '160': 32.094964439398616,
        '161': 32.09522859517423,
        '162': 32.094990060313336,
        '163': 32.094759440514906,
        '164': 32.09497352681224,
        '165': 32.095269361129674,
        '166': 32.0952435043896,
        '167': 32.095055713267165,
        '168': 32.09495925341531,
        '169': 32.09493796601109,
        '170': 32.09497746268283,
        '171': 32.09511091895458,
        '172': 32.09516491940684,
        '173': 32.094964293785885,
        '174': 32.09471034369165,
        '175': 32.094706984676854,
        '176': 32.09486940856274,
        '177': 32.09491380691416,
        '178': 32.0948290012246,
        '179': 32.094782749923645,
        '180': 32.094781590073765,
        '-180': 32.094781590073765,
        '-179': 32.09476192333641,
        '-178': 32.09480202579357,
        '-177': 32.09495671957804,
        '-176': 32.09508710695298,
        '-175': 32.09507977076402,
        '-174': 32.095031066213174,
        '-173': 32.09504143665816,
        '-172': 32.09503736743176,
        '-171': 32.094949943859305,
        '-170': 32.09488471403145,
        '-169': 32.094979241486854,
        '-168': 32.095213722923305,
        '-167': 32.09543422542715,
        '-166': 32.09548231122705,
        '-165': 32.0953087065628,
        '-164': 32.09504309853848,
        '-163': 32.09490691760754,
        '-162': 32.09498212002558,
        '-161': 32.09513537350158,
        '-160': 32.09521457399025,
        '-159': 32.095201797172926,
        '-158': 32.09513418848184,
        '-157': 32.09503261494847,
        '-156': 32.094962794646484,
        '-155': 32.09500451371905,
        '-154': 32.0951067028204,
        '-153': 32.09512521631109,
        '-152': 32.095033746231735,
        '-151': 32.09494466211536,
        '-150': 32.09492028451626,
        '-149': 32.094929261319585,
        '-148': 32.094972535315776,
        '-147': 32.095073584686105,
        '-146': 32.095165667584766,
        '-145': 32.095164763076525,
        '-144': 32.095122867332805,
        '-143': 32.095141927248285,
        '-142': 32.09518568099191,
        '-141': 32.095144508171515,
        '-140': 32.095029527719426,
        '-139': 32.09493969322145,
        '-138': 32.094894667267546,
        '-137': 32.09484753065639,
        '-136': 32.09480244924032,
        '-135': 32.09479253483236,
        '-134': 32.0948008272268,
        '-133': 32.09479648312089,
        '-132': 32.09477963592226,
        '-131': 32.094734836181175,
        '-130': 32.094635428207745,
        '-129': 32.094527515387206,
        '-128': 32.0944922776662,
        '-127': 32.09450572699924,
        '-126': 32.09446783545437,
        '-125': 32.09437781500633,
        '-124': 32.094334679417294,
        '-123': 32.094358010112714,
        '-122': 32.09435741441697,
        '-121': 32.09429422525406,
        '-120': 32.09424087911208,
        '-119': 32.0942596934782,
        '-118': 32.09432257431709,
        '-117': 32.094367877922224,
        '-116': 32.094377927519545,
        '-115': 32.094392771666264,
        '-114': 32.09445714428715,
        '-113': 32.0945427253498,
        '-112': 32.094567799005304,
        '-111': 32.09453926138541,
        '-110': 32.094583711287996,
        '-109': 32.09474980344438,
        '-108': 32.09490172223984,
        '-107': 32.094933559360896,
        '-106': 32.094945286556595,
        '-105': 32.0950493130587,
        '-104': 32.09516160401308,
        '-103': 32.09517366340732,
        '-102': 32.09517648609375,
        '-101': 32.09530226429736,
        '-100': 32.09547266834568,
        '-99': 32.09551656844948,
        '-98': 32.09545301848423,
        '-97': 32.09547249607809,
        '-96': 32.09566082216023,
        '-95': 32.095855239472655,
        '-94': 32.095809130498566,
        '-93': 32.09549970687881,
        '-92': 32.095197855868314,
        '-91': 32.095089395918144,
        '-90': 32.09493309698609,
        '-89': 32.09444982910507,
        '-88': 32.09400277843555,
        '-87': 32.094320363454614,
        '-86': 32.09465220601404,
        '-85': 32.093174135090365,
        '-84': 32.0961461280461,
        '-83': 32.09581376550773,
        '-82': 32.09531759667045,
        '-81': 32.095117884724765,
        '-80': 32.09521170419717,
        '-79': 32.09550743047294,
        '-78': 32.09558617307852,
        '-77': 32.09505324584941,
        '-76': 32.094321443229965,
        '-75': 32.094194740157725,
        '-74': 32.09455820012741,
        '-73': 32.09452753552642,
        '-72': 32.093606911431515,
        '-71': 32.093917061363285,
        '-70': 32.093455299412156,
        '-69': 32.094437874908564,
        '-68': 32.093740640045276,
        '-67': 32.093296103726196,
        '-66': 32.093875955715845,
        '-65': 32.094845213857525,
        '-64': 32.0952586605003,
        '-63': 32.09505197462945,
        '-62': 32.09456556044401,
        '-61': 32.09390248441717,
        '-60': 32.093305634822485,
        '-59': 32.09325339739879,
        '-58': 32.093709201401694,
        '-57': 32.094066716269914,
        '-56': 32.09410548964425,
        '-55': 32.094184521921335,
        '-54': 32.09436588585101,
        '-53': 32.094263894581765,
        '-52': 32.09388801259974,
        '-51': 32.093721357754255,
        '-50': 32.09384962917791,
        '-49': 32.09388263564484,
        '-48': 32.09380055079532,
        '-47': 32.09401154696361,
        '-46': 32.09451307901081,
        '-45': 32.09481948709584,
        '-44': 32.094775865698395,
        '-43': 32.09469912729821,
        '-42': 32.09470195081991,
        '-41': 32.09457590544945,
        '-40': 32.09433625303254,
        '-39': 32.09424312757798,
        '-38': 32.09430518708468,
        '-37': 32.09431482746622,
        '-36': 32.09429490728533,
        '-35': 32.09440629243892,
        '-34': 32.09454610517272,
        '-33': 32.09452194921796,
        '-32': 32.09446258221219,
        '-31': 32.0945908554086,
        '-30': 32.094778789787334,
        '-29': 32.09479281979073,
        '-28': 32.09477097858771,
        '-27': 32.0949581512111,
        '-26': 32.095161007384746,
        '-25': 32.095015963358016,
        '-24': 32.09467304150964,
        '-23': 32.09462627967467,
        '-22': 32.094887729601346,
        '-21': 32.094937462201976,
        '-20': 32.09461068545618,
        '-19': 32.09444449759426,
        '-18': 32.09485657098833,
        '-17': 32.09546935052256,
        '-16': 32.09557048724001,
        '-15': 32.09519780954608,
        '-14': 32.09410179710196,
        '-13': 32.09379596934044,
        '-12': 32.0928034912916,
        '-11': 32.089770975073726,
        '-10': 32.091614283711905,
        '-9': 32.09198988533902,
        '-8': 32.091362826568435,
        '-7': 32.0914680220635,
        '-6': 32.09065780861933,
        '-5': 32.09228154930684,
        '-4': 32.09254248217693,
        '-3': 32.092317907958375,
        '-2': 32.091962008175635,
        '-1': 32.0926533788375
    },
    '13': {
        '0': 32.093480678675064,
        '1': 32.0937870388435,
        '2': 32.09383052131309,
        '3': 32.09396621847879,
        '4': 32.09347733241451,
        '5': 32.09291270635222,
        '6': 32.09269606853957,
        '7': 32.09154970164203,
        '8': 32.091249802722,
        '9': 32.0924333662161,
        '10': 32.09280774241682,
        '11': 32.09300874966905,
        '12': 32.09307055241153,
        '13': 32.09313504440288,
        '14': 32.093279755327345,
        '15': 32.09338086759091,
        '16': 32.093352110614134,
        '17': 32.09338670131769,
        '18': 32.093098375562704,
        '19': 32.09252003019815,
        '20': 32.09168466661949,
        '21': 32.090570450693576,
        '22': 32.088712930446135,
        '23': 32.089229960324055,
        '24': 32.08572891077965,
        '25': 32.0876078535946,
        '26': 32.08984045422478,
        '27': 32.09083291500526,
        '28': 32.09098874655669,
        '29': 32.08982505880538,
        '30': 32.09060821424657,
        '31': 32.09154143434889,
        '32': 32.09198815161427,
        '33': 32.092195414429995,
        '34': 32.091934874405624,
        '35': 32.091657491163865,
        '36': 32.087766618423224,
        '37': 32.08547667670102,
        '38': 32.075373379516996,
        '39': 32.0841892520334,
        '40': 32.08516177934356,
        '41': 32.09634716561737,
        '42': 32.0918864159485,
        '43': 32.09656751276961,
        '44': 32.09255276698377,
        '45': 32.096358351082266,
        '46': 32.09652800776912,
        '47': 32.09633685120573,
        '48': 32.09622288229663,
        '49': 32.09625786195466,
        '50': 32.096446960926784,
        '51': 32.09665577120391,
        '52': 32.09671978864152,
        '53': 32.096644013304754,
        '54': 32.096608374739404,
        '55': 32.09673287230597,
        '56': 32.09689023752787,
        '57': 32.09681765156062,
        '58': 32.09641930469165,
        '59': 32.09592213966733,
        '60': 32.09569124851874,
        '61': 32.09585351977868,
        '62': 32.09612161270442,
        '63': 32.09608953102983,
        '64': 32.09572560926191,
        '65': 32.09540628098332,
        '66': 32.09537279505814,
        '67': 32.09540030833964,
        '68': 32.09522187391424,
        '69': 32.095008426388894,
        '70': 32.095064434971164,
        '71': 32.09526389606356,
        '72': 32.095242585896514,
        '73': 32.09502472797066,
        '74': 32.09500356200741,
        '75': 32.094634624399454,
        '76': 32.08646016238984,
        '77': 32.08859049330718,
        '78': 32.08750834092387,
        '79': 32.09280401572927,
        '80': 32.09455626352381,
        '81': 32.094459697696195,
        '82': 32.094660343599365,
        '83': 32.095092712988695,
        '84': 32.09519249370128,
        '85': 32.0950107046153,
        '86': 32.09493611929273,
        '87': 32.09512094219616,
        '88': 32.09553314785125,
        '89': 32.096091851543505,
        '90': 32.09642556213855,
        '91': 32.096079710062625,
        '92': 32.09530455087559,
        '93': 32.0949834762825,
        '94': 32.095425142574804,
        '95': 32.09594657489095,
        '96': 32.09601907358826,
        '97': 32.095999887250635,
        '98': 32.09625240670742,
        '99': 32.09056106227039,
        '100': 32.09601783781758,
        '101': 32.095090412223996,
        '102': 32.09491502313544,
        '103': 32.095915125588746,
        '104': 32.09602637442169,
        '105': 32.095374858646935,
        '106': 32.09553405065257,
        '107': 32.09506748398888,
        '108': 32.0940662736252,
        '109': 32.09382485681162,
        '110': 32.096027294648025,
        '111': 32.095936288803294,
        '112': 32.09624605844779,
        '113': 32.09637571423197,
        '114': 32.09623514332526,
        '115': 32.0962865035573,
        '116': 32.0966158758978,
        '117': 32.096689601386664,
        '118': 32.09628434790284,
        '119': 32.095958791523856,
        '120': 32.096243730616635,
        '121': 32.08707156941422,
        '122': 32.097753371162376,
        '123': 32.098202106888785,
        '124': 32.096966351036535,
        '125': 32.096675147121125,
        '126': 32.09561727385186,
        '127': 32.09597298155682,
        '128': 32.0972793010088,
        '129': 32.09769262657701,
        '130': 32.09668885123507,
        '131': 32.09579750760179,
        '132': 32.09609007656016,
        '133': 32.096689021557026,
        '134': 32.0965905643164,
        '135': 32.09626775433821,
        '136': 32.096459692939526,
        '137': 32.09684761356802,
        '138': 32.09690741988736,
        '139': 32.096826285612934,
        '140': 32.0968432245701,
        '141': 32.096835488205166,
        '142': 32.0969188439926,
        '143': 32.097174748158864,
        '144': 32.09677901203173,
        '145': 32.09507616944667,
        '146': 32.09343892344603,
        '147': 32.093978762862555,
        '148': 32.0962844592066,
        '149': 32.097683279669205,
        '150': 32.097047490240136,
        '151': 32.09603293024927,
        '152': 32.09605478253121,
        '153': 32.096394519049525,
        '154': 32.096132638936915,
        '155': 32.095802047231956,
        '156': 32.09604077001287,
        '157': 32.0963293719587,
        '158': 32.09611727611469,
        '159': 32.09584437684299,
        '160': 32.09598139445412,
        '161': 32.09619619324099,
        '162': 32.09615193812805,
        '163': 32.09613404924871,
        '164': 32.096352612956075,
        '165': 32.09649562808857,
        '166': 32.09637130595653,
        '167': 32.0962360143014,
        '168': 32.09623227697413,
        '169': 32.09618113436617,
        '170': 32.096060113426454,
        '171': 32.09605989704257,
        '172': 32.09613407104395,
        '173': 32.0960722803454,
        '174': 32.095968450026945,
        '175': 32.09606341122971,
        '176': 32.09623187888649,
        '177': 32.096166375289656,
        '178': 32.09594565595964,
        '179': 32.09590830062296,
        '180': 32.09608388235489,
        '-180': 32.09608388235489,
        '-179': 32.096216340294625,
        '-178': 32.0962329595837,
        '-177': 32.09626918155542,
        '-176': 32.096336627033885,
        '-175': 32.09633661997026,
        '-174': 32.09630635850353,
        '-173': 32.09633742255962,
        '-172': 32.09634895336844,
        '-171': 32.09622405629645,
        '-170': 32.096084074483954,
        '-169': 32.09616028125082,
        '-168': 32.096430932490804,
        '-167': 32.096610437968465,
        '-166': 32.09651373632236,
        '-165': 32.09627410244837,
        '-164': 32.09615494151222,
        '-163': 32.0962400967915,
        '-162': 32.09637716366838,
        '-161': 32.09639384884504,
        '-160': 32.09628843056365,
        '-159': 32.09617501817264,
        '-158': 32.09611607122219,
        '-157': 32.09609011564696,
        '-156': 32.09607214295399,
        '-155': 32.09604770944819,
        '-154': 32.095990495462594,
        '-153': 32.09592416799841,
        '-152': 32.095949822851146,
        '-151': 32.09610388270797,
        '-150': 32.09626009142013,
        '-149': 32.09629360055146,
        '-148': 32.096259430474554,
        '-147': 32.09627769706951,
        '-146': 32.0963396946324,
        '-145': 32.096373240389134,
        '-144': 32.09639518506596,
        '-143': 32.09644096537213,
        '-142': 32.09644669758408,
        '-141': 32.09636128288561,
        '-140': 32.09627272481261,
        '-139': 32.09626964654008,
        '-138': 32.096285397983735,
        '-137': 32.096226614250924,
        '-136': 32.09614027983901,
        '-135': 32.096106705732105,
        '-134': 32.09608285033254,
        '-133': 32.09599566750856,
        '-132': 32.09588487488897,
        '-131': 32.09582525615868,
        '-130': 32.0958052675875,
        '-129': 32.095783435722616,
        '-128': 32.09576228185308,
        '-127': 32.095728843506684,
        '-126': 32.095637439882225,
        '-125': 32.09551813282874,
        '-124': 32.09547973508636,
        '-123': 32.095543971082414,
        '-122': 32.09559905023608,
        '-121': 32.09557287423615,
        '-120': 32.095529390058495,
        '-119': 32.09553644485977,
        '-118': 32.09555714300118,
        '-117': 32.09554206505954,
        '-116': 32.0955311080523,
        '-115': 32.09558333824825,
        '-114': 32.09567079353388,
        '-113': 32.09572335308899,
        '-112': 32.09574628645706,
        '-111': 32.095811489164916,
        '-110': 32.095931591544286,
        '-109': 32.09602623322981,
        '-108': 32.096050444883446,
        '-107': 32.096074414093785,
        '-106': 32.096165212586655,
        '-105': 32.09627329543963,
        '-104': 32.09634081328971,
        '-103': 32.096433927963425,
        '-102': 32.0966237673189,
        '-101': 32.09681809123609,
        '-100': 32.0968821137216,
        '-99': 32.09685303407406,
        '-98': 32.09685381512803,
        '-97': 32.09686725893511,
        '-96': 32.096776326414314,
        '-95': 32.0965558290504,
        '-94': 32.09627434151863,
        '-93': 32.09600697162421,
        '-92': 32.0958528895909,
        '-91': 32.0958792528037,
        '-90': 32.095983624230044,
        '-89': 32.096051983588914,
        '-88': 32.09625778593762,
        '-87': 32.09661256276717,
        '-86': 32.087966062359804,
        '-85': 32.09604666713701,
        '-84': 32.09679256539252,
        '-83': 32.097071835402446,
        '-82': 32.09729960567683,
        '-81': 32.097422176101915,
        '-80': 32.097239375198264,
        '-79': 32.09714681167199,
        '-78': 32.09720637398414,
        '-77': 32.09681004071889,
        '-76': 32.095719632779755,
        '-75': 32.09463317242505,
        '-74': 32.0942475345585,
        '-73': 32.09444943119793,
        '-72': 32.09478095780535,
        '-71': 32.09499382258775,
        '-70': 32.09488602755584,
        '-69': 32.09436628343292,
        '-68': 32.093912680891705,
        '-67': 32.09419528759634,
        '-66': 32.095095470166406,
        '-65': 32.09587583444706,
        '-64': 32.09629935235397,
        '-63': 32.0966498947992,
        '-62': 32.096747980665086,
        '-61': 32.09611051744961,
        '-60': 32.09511539663982,
        '-59': 32.09478561475818,
        '-58': 32.095261950582824,
        '-57': 32.09564499698965,
        '-56': 32.09550533925072,
        '-55': 32.09538337598404,
        '-54': 32.09558894158253,
        '-53': 32.095664042039566,
        '-52': 32.095351724187424,
        '-51': 32.09507721423232,
        '-50': 32.095136485592924,
        '-49': 32.09527866487824,
        '-48': 32.095358713200625,
        '-47': 32.09561128175003,
        '-46': 32.09604945361254,
        '-45': 32.0962971889357,
        '-44': 32.09620358431496,
        '-43': 32.09602433640296,
        '-42': 32.095906653548425,
        '-41': 32.09575299989438,
        '-40': 32.095612607110006,
        '-39': 32.09566149227919,
        '-38': 32.09579737954399,
        '-37': 32.09575410742898,
        '-36': 32.09557998499758,
        '-35': 32.095545857232736,
        '-34': 32.09566186055586,
        '-33': 32.09572655928981,
        '-32': 32.09574594690381,
        '-31': 32.09586921367239,
        '-30': 32.0960255476893,
        '-29': 32.09604774345563,
        '-28': 32.09603305963297,
        '-27': 32.09616035788685,
        '-26': 32.09627764345822,
        '-25': 32.096119144054896,
        '-24': 32.09583107084211,
        '-23': 32.09580274863632,
        '-22': 32.096018168377334,
        '-21': 32.09607902043974,
        '-20': 32.09591406141401,
        '-19': 32.095947263986176,
        '-18': 32.09639185991863,
        '-17': 32.09684872450879,
        '-16': 32.09656186832259,
        '-15': 32.0961035161375,
        '-14': 32.09589717520353,
        '-13': 32.095831761343476,
        '-12': 32.094711354319735,
        '-11': 32.093904944751095,
        '-10': 32.09390664614183,
        '-9': 32.0928950126422,
        '-8': 32.092675256178204,
        '-7': 32.092758189931516,
        '-6': 32.09353773477136,
        '-5': 32.093451057515175,
        '-4': 32.09371584691238,
        '-3': 32.09335273930439,
        '-2': 32.093093861799254,
        '-1': 32.09314366644536
    },
    '14': {
        '0': 32.094411556149495,
        '1': 32.09483144471537,
        '2': 32.095228334088496,
        '3': 32.0948372420221,
        '4': 32.09430031755133,
        '5': 32.093805035130465,
        '6': 32.093751144687474,
        '7': 32.09351716991585,
        '8': 32.093338028273536,
        '9': 32.09281127897035,
        '10': 32.093607291458675,
        '11': 32.0940772417425,
        '12': 32.09414979256447,
        '13': 32.0944108604497,
        '14': 32.094421100354765,
        '15': 32.09405705289629,
        '16': 32.09455573571081,
        '17': 32.09458599588998,
        '18': 32.094367177716066,
        '19': 32.09371801615472,
        '20': 32.09313755007538,
        '21': 32.09092446292236,
        '22': 32.08842549550981,
        '23': 32.089607081172375,
        '24': 32.0878117973147,
        '25': 32.08851421494751,
        '26': 32.09040110883789,
        '27': 32.09047394052413,
        '28': 32.09170148342947,
        '29': 32.092089691857495,
        '30': 32.09270999013278,
        '31': 32.09319951422321,
        '32': 32.09337000227533,
        '33': 32.093225698607256,
        '34': 32.09353711667466,
        '35': 32.09269016757993,
        '36': 32.092081195601935,
        '37': 32.08625951356824,
        '38': 32.084620122633034,
        '39': 32.079082147246694,
        '40': 32.091633321927546,
        '41': 32.09268001966097,
        '42': 32.09757018024749,
        '43': 32.09812592931163,
        '44': 32.082858345948615,
        '45': 32.08108021832271,
        '46': 32.085270797860346,
        '47': 32.09075120609198,
        '48': 32.098102271532106,
        '49': 32.09812324155983,
        '50': 32.098104217242884,
        '51': 32.09799253194402,
        '52': 32.09790882847017,
        '53': 32.09796904046495,
        '54': 32.09814233092197,
        '55': 32.09831043966025,
        '56': 32.09837926274959,
        '57': 32.0982729925817,
        '58': 32.0979332911779,
        '59': 32.097458975449236,
        '60': 32.097153840081155,
        '61': 32.09722636806243,
        '62': 32.09746285766347,
        '63': 32.09743767233619,
        '64': 32.09708573785825,
        '65': 32.0967957332225,
        '66': 32.09683027612105,
        '67': 32.09696032777118,
        '68': 32.09687935995041,
        '69': 32.09667213168811,
        '70': 32.09656534043319,
        '71': 32.096485019252306,
        '72': 32.0962571464249,
        '73': 32.09605803383225,
        '74': 32.096237184589846,
        '75': 32.09101308379559,
        '76': 32.09018753681525,
        '77': 32.09105583451263,
        '78': 32.091043223222584,
        '79': 32.093874806195586,
        '80': 32.09582505732766,
        '81': 32.095757123738586,
        '82': 32.09583197783437,
        '83': 32.0959467604773,
        '84': 32.0960145980879,
        '85': 32.09618811354256,
        '86': 32.09641995842976,
        '87': 32.096475507793244,
        '88': 32.09649478754961,
        '89': 32.09688186640645,
        '90': 32.097434069824594,
        '91': 32.09736570624943,
        '92': 32.09656028325271,
        '93': 32.09599003616809,
        '94': 32.09637363090453,
        '95': 32.09716698685521,
        '96': 32.09749415789216,
        '97': 32.09738454728413,
        '98': 32.09738062602448,
        '99': 32.09243366130924,
        '100': 32.09735557005152,
        '101': 32.09720835516255,
        '102': 32.09693240121529,
        '103': 32.09690954498462,
        '104': 32.09585088653035,
        '105': 32.09629413567215,
        '106': 32.09625873178774,
        '107': 32.09625086760094,
        '108': 32.09067727401884,
        '109': 32.097474415999606,
        '110': 32.09713513707328,
        '111': 32.09724336159897,
        '112': 32.09775382463289,
        '113': 32.09779674154514,
        '114': 32.09742296522353,
        '115': 32.097501353269436,
        '116': 32.09814495222729,
        '117': 32.09839684941797,
        '118': 32.09782069229044,
        '119': 32.09728657466034,
        '120': 32.0975990965487,
        '121': 32.09749078169614,
        '122': 32.098668078247,
        '123': 32.09748259729727,
        '124': 32.098052455206556,
        '125': 32.097434534732095,
        '126': 32.09703639229346,
        '127': 32.097450806157255,
        '128': 32.0983395309534,
        '129': 32.09851252395752,
        '130': 32.097720925703356,
        '131': 32.097152747143426,
        '132': 32.09752737370639,
        '133': 32.09803595046581,
        '134': 32.097896790304034,
        '135': 32.097593072926855,
        '136': 32.0977460508794,
        '137': 32.098041921957744,
        '138': 32.09807694123257,
        '139': 32.098101749141506,
        '140': 32.09831107531791,
        '141': 32.09854438997841,
        '142': 32.099005366074515,
        '143': 32.099891581897225,
        '144': 32.10018041876439,
        '145': 32.098596650131086,
        '146': 32.096086354095576,
        '147': 32.09527586978832,
        '148': 32.09686031618083,
        '149': 32.098566818008564,
        '150': 32.09850291385782,
        '151': 32.09752301238235,
        '152': 32.097247930876904,
        '153': 32.097610466972455,
        '154': 32.09767545491887,
        '155': 32.09742559526408,
        '156': 32.09738397880504,
        '157': 32.097474767457776,
        '158': 32.097370706093464,
        '159': 32.09723939918129,
        '160': 32.09733899352818,
        '161': 32.097494026835825,
        '162': 32.09751800167714,
        '163': 32.09755881816209,
        '164': 32.09766968813177,
        '165': 32.097643072896005,
        '166': 32.09749790465083,
        '167': 32.09752050420683,
        '168': 32.097685523972885,
        '169': 32.09764668391561,
        '170': 32.097376619952556,
        '171': 32.097220578056636,
        '172': 32.09729036154456,
        '173': 32.097377474509734,
        '174': 32.097442917127346,
        '175': 32.097623147207514,
        '176': 32.09778802629987,
        '177': 32.097652076980346,
        '178': 32.097310975510695,
        '179': 32.097168578792996,
        '180': 32.097341793785496,
        '-180': 32.097341793785496,
        '-179': 32.09756478106946,
        '-178': 32.09764617856294,
        '-177': 32.097635140152015,
        '-176': 32.09758199592807,
        '-175': 32.09748403385326,
        '-174': 32.09742709077121,
        '-173': 32.09748865083889,
        '-172': 32.09755938102773,
        '-171': 32.097511500357065,
        '-170': 32.097454030925604,
        '-169': 32.0975682626595,
        '-168': 32.09777654621363,
        '-167': 32.09783762607901,
        '-166': 32.09772452497415,
        '-165': 32.09765492912852,
        '-164': 32.09773888453886,
        '-163': 32.09781655627538,
        '-162': 32.09770746074206,
        '-161': 32.0974582603416,
        '-160': 32.09725246387056,
        '-159': 32.09718199183789,
        '-158': 32.0972107535695,
        '-157': 32.09729045777592,
        '-156': 32.097382611887134,
        '-155': 32.0973952323199,
        '-154': 32.09725259546954,
        '-153': 32.09706038635488,
        '-152': 32.09705360180896,
        '-151': 32.09730326245319,
        '-150': 32.09760013577207,
        '-149': 32.09771837710321,
        '-148': 32.0976720581713,
        '-147': 32.09761188715459,
        '-146': 32.09760050860008,
        '-145': 32.09761687302034,
        '-144': 32.097663726493,
        '-143': 32.097732192534174,
        '-142': 32.09775715276503,
        '-141': 32.097726070883674,
        '-140': 32.09772742343536,
        '-139': 32.09778845777307,
        '-138': 32.09779567905537,
        '-137': 32.097686577799884,
        '-136': 32.09757163489505,
        '-135': 32.09753811248396,
        '-134': 32.097486822808094,
        '-133': 32.09731428131013,
        '-132': 32.097117281164124,
        '-131': 32.09705345833961,
        '-130': 32.09710884191427,
        '-129': 32.0971537888621,
        '-128': 32.09713618452448,
        '-127': 32.09708610835482,
        '-126': 32.097013136244065,
        '-125': 32.09692022135013,
        '-124': 32.096853112060124,
        '-123': 32.09684206147067,
        '-122': 32.09685391524089,
        '-121': 32.09686068660098,
        '-120': 32.096878152980565,
        '-119': 32.09689419660222,
        '-118': 32.0968617377785,
        '-117': 32.09681119727102,
        '-116': 32.09685098945701,
        '-115': 32.09699199359365,
        '-114': 32.09710049133707,
        '-113': 32.09711221533072,
        '-112': 32.09715158067409,
        '-111': 32.097307315080165,
        '-110': 32.09743212090578,
        '-109': 32.09736411875242,
        '-108': 32.097239241993556,
        '-107': 32.097323352436035,
        '-106': 32.09758671136763,
        '-105': 32.09775252890172,
        '-104': 32.097771702097255,
        '-103': 32.09788643415057,
        '-102': 32.09813615536084,
        '-101': 32.09820647400511,
        '-100': 32.097967086445216,
        '-99': 32.09775613957951,
        '-98': 32.09783080849069,
        '-97': 32.097906043627646,
        '-96': 32.097624319983645,
        '-95': 32.097182471233225,
        '-94': 32.09706398400888,
        '-93': 32.097357607584215,
        '-92': 32.09774104167148,
        '-91': 32.097832347748806,
        '-90': 32.09179895943197,
        '-89': 32.09542235860209,
        '-88': 32.083026913678296,
        '-87': 32.09189781487561,
        '-86': 32.08876175731928,
        '-85': 32.09514671405989,
        '-84': 32.098077570662106,
        '-83': 32.09830008314253,
        '-82': 32.09867309342716,
        '-81': 32.098742771078314,
        '-80': 32.09819154109547,
        '-79': 32.0977195125555,
        '-78': 32.0978508212634,
        '-77': 32.098060738194015,
        '-76': 32.09765678132837,
        '-75': 32.09689484271161,
        '-74': 32.096579938152594,
        '-73': 32.096941238779486,
        '-72': 32.09742527505787,
        '-71': 32.09740437789397,
        '-70': 32.09680184704738,
        '-69': 32.09614883289596,
        '-68': 32.09604332051782,
        '-67': 32.09640582469333,
        '-66': 32.09656308083848,
        '-65': 32.09636033843113,
        '-64': 32.0965766703961,
        '-63': 32.09757861790563,
        '-62': 32.09829173630949,
        '-61': 32.09628549625836,
        '-60': 32.096220021695764,
        '-59': 32.09577837441009,
        '-58': 32.09645181372711,
        '-57': 32.096912876061126,
        '-56': 32.096563102710334,
        '-55': 32.09625114099385,
        '-54': 32.0965904826326,
        '-53': 32.0970123439642,
        '-52': 32.09693344489007,
        '-51': 32.09667623591496,
        '-50': 32.09670739758817,
        '-49': 32.0968950935255,
        '-48': 32.097000720136144,
        '-47': 32.09715407188225,
        '-46': 32.09743108644785,
        '-45': 32.097571486579504,
        '-44': 32.09743572164357,
        '-43': 32.0972450511662,
        '-42': 32.09716867688482,
        '-41': 32.09712398993823,
        '-40': 32.09709105109228,
        '-39': 32.097175810048725,
        '-38': 32.09729361742666,
        '-37': 32.09722012345364,
        '-36': 32.09700367545326,
        '-35': 32.09693250301165,
        '-34': 32.09707318815525,
        '-33': 32.097211872901035,
        '-32': 32.097251923494206,
        '-31': 32.097311295017604,
        '-30': 32.09742267305583,
        '-29': 32.09747201295078,
        '-28': 32.09745287404841,
        '-27': 32.09749370795717,
        '-26': 32.09761659445087,
        '-25': 32.09770646498481,
        '-24': 32.09770681109233,
        '-23': 32.09763550005103,
        '-22': 32.09746647187621,
        '-21': 32.09723304867331,
        '-20': 32.097175375554365,
        '-19': 32.097495664139764,
        '-18': 32.097995650238815,
        '-17': 32.09825894946271,
        '-16': 32.0980252711366,
        '-15': 32.097603530122186,
        '-14': 32.097859508035256,
        '-13': 32.09728884841203,
        '-12': 32.09683011689645,
        '-11': 32.095706005047454,
        '-10': 32.095871224790045,
        '-9': 32.095792597406074,
        '-8': 32.09375173555762,
        '-7': 32.094512241614524,
        '-6': 32.094825640727095,
        '-5': 32.09505977077872,
        '-4': 32.09404160796914,
        '-3': 32.095041335752946,
        '-2': 32.09405705135395,
        '-1': 32.094205195913034
    },
    '15': {
        '0': 32.095440668891854,
        '1': 32.0955469264356,
        '2': 32.09593148788527,
        '3': 32.095907028458626,
        '4': 32.095978263490416,
        '5': 32.095031733379535,
        '6': 32.09329836995673,
        '7': 32.09422102677529,
        '8': 32.094263227190915,
        '9': 32.09343685415463,
        '10': 32.09448892926711,
        '11': 32.09500583789468,
        '12': 32.09518651252524,
        '13': 32.09498532294906,
        '14': 32.09503488372337,
        '15': 32.0950797226398,
        '16': 32.095450707758374,
        '17': 32.09593236782764,
        '18': 32.09600717203542,
        '19': 32.095422161544406,
        '20': 32.09487242329741,
        '21': 32.094113926676776,
        '22': 32.089731949664554,
        '23': 32.090769896599035,
        '24': 32.08936289463528,
        '25': 32.08986223358229,
        '26': 32.090576243960484,
        '27': 32.09218881339636,
        '28': 32.092024218471735,
        '29': 32.09404694850774,
        '30': 32.094374712447525,
        '31': 32.094341049795105,
        '32': 32.09440376030482,
        '33': 32.094921356497615,
        '34': 32.094720223119324,
        '35': 32.09345541853663,
        '36': 32.094242842851365,
        '37': 32.089612151000104,
        '38': 32.08933473932248,
        '39': 32.081262139161986,
        '40': 32.097495135013254,
        '41': 32.0988508182075,
        '42': 32.098764615985566,
        '43': 32.099131619828945,
        '44': 32.08523739318379,
        '45': 32.08608481490375,
        '46': 32.089790743724116,
        '47': 32.087192661967656,
        '48': 32.08557752265047,
        '49': 32.08700754714121,
        '50': 32.09734259015228,
        '51': 32.0997019750595,
        '52': 32.09950833115978,
        '53': 32.0995537696164,
        '54': 32.09964071499863,
        '55': 32.09957997343984,
        '56': 32.09944619848782,
        '57': 32.099351943459716,
        '58': 32.09917505294606,
        '59': 32.098772673134434,
        '60': 32.098339232172265,
        '61': 32.098227908774916,
        '62': 32.09843805044818,
        '63': 32.09860362818806,
        '64': 32.098536353110575,
        '65': 32.098448600845046,
        '66': 32.09851747693265,
        '67': 32.09856215349716,
        '68': 32.09837303536219,
        '69': 32.09807984291051,
        '70': 32.09791847492374,
        '71': 32.09785845562327,
        '72': 32.097764840088956,
        '73': 32.09774544599107,
        '74': 32.097993244818795,
        '75': 32.09295697224152,
        '76': 32.09253291156533,
        '77': 32.09372012854527,
        '78': 32.095050841531865,
        '79': 32.09538104424113,
        '80': 32.0970490888934,
        '81': 32.09747675273622,
        '82': 32.097483667422004,
        '83': 32.09719849269457,
        '84': 32.09706968479427,
        '85': 32.09749312397723,
        '86': 32.09811672372992,
        '87': 32.09828791375667,
        '88': 32.09812183803888,
        '89': 32.09835516549766,
        '90': 32.09901650138008,
        '91': 32.09915527677174,
        '92': 32.09832177477061,
        '93': 32.097459012648855,
        '94': 32.09763342084034,
        '95': 32.09853461388796,
        '96': 32.09904493711036,
        '97': 32.09884505618744,
        '98': 32.097716340715074,
        '99': 32.090582484692675,
        '100': 32.098760807507446,
        '101': 32.09839148948772,
        '102': 32.09682314738363,
        '103': 32.096884738184336,
        '104': 32.09712867554891,
        '105': 32.096967651897685,
        '106': 32.096744591406214,
        '107': 32.092928160193715,
        '108': 32.07958289410656,
        '109': 32.0990019048552,
        '110': 32.09847386458058,
        '111': 32.09867733966241,
        '112': 32.09927640897639,
        '113': 32.099371146526586,
        '114': 32.09904180520725,
        '115': 32.09919855007438,
        '116': 32.0998827595319,
        '117': 32.10005830048161,
        '118': 32.099441890298,
        '119': 32.09914322053342,
        '120': 32.09980279425783,
        '121': 32.099942675506426,
        '122': 32.09952368701646,
        '123': 32.09903257327902,
        '124': 32.098728347172,
        '125': 32.09903422160081,
        '126': 32.09925143671117,
        '127': 32.09928580402289,
        '128': 32.099399774202,
        '129': 32.09943618757898,
        '130': 32.099241568428575,
        '131': 32.09917148748454,
        '132': 32.09939877468799,
        '133': 32.09947245331488,
        '134': 32.0992008556197,
        '135': 32.099096139446154,
        '136': 32.09938135404609,
        '137': 32.09954440865281,
        '138': 32.099364130930226,
        '139': 32.09927299655886,
        '140': 32.09932689996264,
        '141': 32.09914233988551,
        '142': 32.09917369333832,
        '143': 32.1003652527664,
        '144': 32.10201301735283,
        '145': 32.10191885953358,
        '146': 32.0996113793906,
        '147': 32.09754126270167,
        '148': 32.09781702769389,
        '149': 32.09940770382123,
        '150': 32.10003394662763,
        '151': 32.09939537979147,
        '152': 32.098873775408364,
        '153': 32.099065897364284,
        '154': 32.09930188241698,
        '155': 32.09912118822872,
        '156': 32.098834357603096,
        '157': 32.09876302202704,
        '158': 32.09886049243639,
        '159': 32.09901302553987,
        '160': 32.0991440985454,
        '161': 32.09915078654763,
        '162': 32.09906047191582,
        '163': 32.09903006046753,
        '164': 32.09904164782274,
        '165': 32.098932415454094,
        '166': 32.09878280876747,
        '167': 32.098844495128255,
        '168': 32.09904803058373,
        '169': 32.09905374524398,
        '170': 32.09882127387812,
        '171': 32.09865652803897,
        '172': 32.098681742429775,
        '173': 32.09873043832326,
        '174': 32.09876826450097,
        '175': 32.098939939900696,
        '176': 32.099171673716654,
        '177': 32.09917395610348,
        '178': 32.098901792551814,
        '179': 32.098648885628805,
        '180': 32.09863421592083,
        '-180': 32.09863421592083,
        '-179': 32.0987810133544,
        '-178': 32.09891447998081,
        '-177': 32.09893617715278,
        '-176': 32.09882226646554,
        '-175': 32.09865010483702,
        '-174': 32.09858224008361,
        '-173': 32.09867459400248,
        '-172': 32.09879624452893,
        '-171': 32.09886025604012,
        '-170': 32.098968156746686,
        '-169': 32.099176668945844,
        '-168': 32.09932159277143,
        '-167': 32.09927483529044,
        '-166': 32.09919085597865,
        '-165': 32.09925777483608,
        '-164': 32.09935216448751,
        '-163': 32.09921233523965,
        '-162': 32.098871386502566,
        '-161': 32.09864323734109,
        '-160': 32.09868670832292,
        '-159': 32.09883045991832,
        '-158': 32.09888061315491,
        '-157': 32.09888270531723,
        '-156': 32.098958088423316,
        '-155': 32.09904326722179,
        '-154': 32.09897559812407,
        '-153': 32.09877557926837,
        '-152': 32.09865973971208,
        '-151': 32.098755220099356,
        '-150': 32.098948149576955,
        '-149': 32.09906336750463,
        '-148': 32.0990688561996,
        '-147': 32.099034316471624,
        '-146': 32.098999657833225,
        '-145': 32.09897408524336,
        '-144': 32.09898207413654,
        '-143': 32.099026422732045,
        '-142': 32.09906984111742,
        '-141': 32.09910654074296,
        '-140': 32.099168784054534,
        '-139': 32.099221923828914,
        '-138': 32.09917070356707,
        '-137': 32.09902651957587,
        '-136': 32.09893200534173,
        '-135': 32.098940769367076,
        '-134': 32.09891727211975,
        '-133': 32.098758889828254,
        '-132': 32.09857539235388,
        '-131': 32.098518275885745,
        '-130': 32.09855441838643,
        '-129': 32.098553158031436,
        '-128': 32.09850112063618,
        '-127': 32.09848125516081,
        '-126': 32.098502006389886,
        '-125': 32.09848538851328,
        '-124': 32.09839674490284,
        '-123': 32.09828699321293,
        '-122': 32.0982244345279,
        '-121': 32.09824097293244,
        '-120': 32.098309719181216,
        '-119': 32.09834691879038,
        '-118': 32.098302224984856,
        '-117': 32.098262810702884,
        '-116': 32.09836053725498,
        '-115': 32.09855266323697,
        '-114': 32.09864925512798,
        '-113': 32.09861466431729,
        '-112': 32.09864124829072,
        '-111': 32.09880317107045,
        '-110': 32.09887257871379,
        '-109': 32.09870491951275,
        '-108': 32.0985809727651,
        '-107': 32.098824457403786,
        '-106': 32.09924421443732,
        '-105': 32.09938705042629,
        '-104': 32.099252275318925,
        '-103': 32.09923884717922,
        '-102': 32.099386674372525,
        '-101': 32.09928589242302,
        '-100': 32.09889043661964,
        '-99': 32.09875721862694,
        '-98': 32.09913238721559,
        '-97': 32.099425399124605,
        '-96': 32.09911037146043,
        '-95': 32.09864107199681,
        '-94': 32.098836980484684,
        '-93': 32.099695833352605,
        '-92': 32.08769078581413,
        '-91': 32.08158342257163,
        '-90': 32.088073771899474,
        '-89': 32.09424996114199,
        '-88': 32.09546531286436,
        '-87': 32.085492399037605,
        '-86': 32.09270820696163,
        '-85': 32.09922597779926,
        '-84': 32.099229160450776,
        '-83': 32.099729798439704,
        '-82': 32.0998728867053,
        '-81': 32.099816408452234,
        '-80': 32.09924485327354,
        '-79': 32.09869433338764,
        '-78': 32.09878231892877,
        '-77': 32.09929639935733,
        '-76': 32.09956042161177,
        '-75': 32.09941706088207,
        '-74': 32.09930917222394,
        '-73': 32.0994911506324,
        '-72': 32.09963459361289,
        '-71': 32.09933832129633,
        '-70': 32.0987782817599,
        '-69': 32.09857062564698,
        '-68': 32.098888818439164,
        '-67': 32.09901760597003,
        '-66': 32.0983063183626,
        '-65': 32.09743262358839,
        '-64': 32.09777977110758,
        '-63': 32.0992675894728,
        '-62': 32.09995393583553,
        '-61': 32.09862363917337,
        '-60': 32.09671388114667,
        '-59': 32.09640363361725,
        '-58': 32.097593057992896,
        '-57': 32.09834691694324,
        '-56': 32.09787420031004,
        '-55': 32.09732187408683,
        '-54': 32.09761678914242,
        '-53': 32.09819683613377,
        '-52': 32.098302848566426,
        '-51': 32.09816227139645,
        '-50': 32.098274237119355,
        '-49': 32.09852663903147,
        '-48': 32.09862863919511,
        '-47': 32.09868130127327,
        '-46': 32.09881482847763,
        '-45': 32.09883581958011,
        '-44': 32.09863201829879,
        '-43': 32.098452137880436,
        '-42': 32.098483692370536,
        '-41': 32.09856848061614,
        '-40': 32.09855716077845,
        '-39': 32.09855464840242,
        '-38': 32.09863273610243,
        '-37': 32.09866322715421,
        '-36': 32.09859947170149,
        '-35': 32.09860168006706,
        '-34': 32.09874070967654,
        '-33': 32.098861872934684,
        '-32': 32.09886858787489,
        '-31': 32.0988863075156,
        '-30': 32.099016570953495,
        '-29': 32.099145266327945,
        '-28': 32.09914542621284,
        '-27': 32.09912765747295,
        '-26': 32.099328163328146,
        '-25': 32.099768963416174,
        '-24': 32.100122688920116,
        '-23': 32.09999129794041,
        '-22': 32.099369735268155,
        '-21': 32.098771120559135,
        '-20': 32.098735781590996,
        '-19': 32.09921267348998,
        '-18': 32.09963262582262,
        '-17': 32.09945684989397,
        '-16': 32.099056224831166,
        '-15': 32.09912804441955,
        '-14': 32.099378004654085,
        '-13': 32.09871118048993,
        '-12': 32.09804965923861,
        '-11': 32.09816039019,
        '-10': 32.096368843102354,
        '-9': 32.096297792245004,
        '-8': 32.09654268571019,
        '-7': 32.096198726080154,
        '-6': 32.09562647698868,
        '-5': 32.09640762722936,
        '-4': 32.096717380772,
        '-3': 32.09552581918466,
        '-2': 32.09630737107921,
        '-1': 32.095936406465384
    },
    '16': {
        '0': 32.097239637969174,
        '1': 32.09683386620281,
        '2': 32.096823328434304,
        '3': 32.09705530127561,
        '4': 32.09732563068681,
        '5': 32.096362090424705,
        '6': 32.09562885011757,
        '7': 32.09486323349829,
        '8': 32.095111102159315,
        '9': 32.09531070811085,
        '10': 32.09640091097429,
        '11': 32.09647144827354,
        '12': 32.09612620496126,
        '13': 32.09636082829512,
        '14': 32.09673821661072,
        '15': 32.097149897463176,
        '16': 32.097449962080994,
        '17': 32.097191658853895,
        '18': 32.097523906914475,
        '19': 32.09697584853749,
        '20': 32.096639827185115,
        '21': 32.095876552685546,
        '22': 32.09466540328413,
        '23': 32.0914777058823,
        '24': 32.09203955312243,
        '25': 32.09346729933975,
        '26': 32.09221170783094,
        '27': 32.093903459004544,
        '28': 32.09479869501078,
        '29': 32.09602464764291,
        '30': 32.09666882561824,
        '31': 32.09472980776396,
        '32': 32.096728938844755,
        '33': 32.09532253538202,
        '34': 32.096395857728126,
        '35': 32.09660777483334,
        '36': 32.09618989709084,
        '37': 32.0955208476441,
        '38': 32.09012088727066,
        '39': 32.09842653641037,
        '40': 32.10076763587511,
        '41': 32.10012479361428,
        '42': 32.10027118168216,
        '43': 32.10032024613633,
        '44': 32.07638665313528,
        '45': 32.090905288690884,
        '46': 32.09138955998858,
        '47': 32.09230464454316,
        '48': 32.09021862670039,
        '49': 32.09446490106134,
        '50': 32.09588774636083,
        '51': 32.09405699443141,
        '52': 32.10056146989758,
        '53': 32.10152976882163,
        '54': 32.1014087316801,
        '55': 32.101087057707225,
        '56': 32.100817987067245,
        '57': 32.10075937946634,
        '58': 32.10066326651966,
        '59': 32.100247690765464,
        '60': 32.09971630166271,
        '61': 32.09955391555364,
        '62': 32.099853301581426,
        '63': 32.10020086273915,
        '64': 32.10026292776231,
        '65': 32.100135540450616,
        '66': 32.100006815928936,
        '67': 32.09981974937042,
        '68': 32.099491790809196,
        '69': 32.09920778682857,
        '70': 32.099213966889735,
        '71': 32.09946270549003,
        '72': 32.0997055318681,
        '73': 32.09984020032376,
        '74': 32.09460977717932,
        '75': 32.09333234808657,
        '76': 32.09407307743092,
        '77': 32.09566490628347,
        '78': 32.09603841512239,
        '79': 32.09248279621608,
        '80': 32.098769042613675,
        '81': 32.09979774660329,
        '82': 32.09980839503425,
        '83': 32.09914552314132,
        '84': 32.09856336087351,
        '85': 32.098764481532776,
        '86': 32.099489460840296,
        '87': 32.09991277574122,
        '88': 32.099894210016295,
        '89': 32.100100635645404,
        '90': 32.100737315546766,
        '91': 32.10100236044454,
        '92': 32.10031590507159,
        '93': 32.09940049340748,
        '94': 32.0993725373758,
        '95': 32.10011440927417,
        '96': 32.10069719569902,
        '97': 32.10046105436821,
        '98': 32.09994288588027,
        '99': 32.090940230907634,
        '100': 32.10011826091096,
        '101': 32.09889757889886,
        '102': 32.09407933513997,
        '103': 32.09765833702714,
        '104': 32.09837524160461,
        '105': 32.09808273998823,
        '106': 32.09696077298894,
        '107': 32.0931089995751,
        '108': 32.08914162311097,
        '109': 32.100306189926535,
        '110': 32.09992208724345,
        '111': 32.10007527597415,
        '112': 32.100596591275355,
        '113': 32.100786046417284,
        '114': 32.10063298451951,
        '115': 32.10078037929261,
        '116': 32.10119401382171,
        '117': 32.10111663795804,
        '118': 32.100659925918336,
        '119': 32.100997257276894,
        '120': 32.10104466375738,
        '121': 32.09874142556583,
        '122': 32.101784878834074,
        '123': 32.10022793658068,
        '124': 32.10010849210682,
        '125': 32.10120238450204,
        '126': 32.101840973239376,
        '127': 32.10141860273249,
        '128': 32.10079570006323,
        '129': 32.10067563831757,
        '130': 32.10088506328227,
        '131': 32.10104445093814,
        '132': 32.10098986217771,
        '133': 32.10072045016706,
        '134': 32.10051711346805,
        '135': 32.10073009397122,
        '136': 32.10111774368739,
        '137': 32.10108149109639,
        '138': 32.10075799283939,
        '139': 32.100837273461,
        '140': 32.10110821430588,
        '141': 32.1006531674119,
        '142': 32.099886080372414,
        '143': 32.10053732589845,
        '144': 32.102700251083945,
        '145': 32.10382931947289,
        '146': 32.1021716277678,
        '147': 32.099570322688635,
        '148': 32.09901640350488,
        '149': 32.10059983277224,
        '150': 32.10189111073999,
        '151': 32.1015965765073,
        '152': 32.10077612173979,
        '153': 32.10061660769301,
        '154': 32.10085023777923,
        '155': 32.1007506245593,
        '156': 32.10037921466438,
        '157': 32.10025033502242,
        '158': 32.10048400728929,
        '159': 32.10077218445037,
        '160': 32.100849604163066,
        '161': 32.100727593726845,
        '162': 32.10059660142769,
        '163': 32.100590859369746,
        '164': 32.10063101342554,
        '165': 32.100560991495975,
        '166': 32.100412320084146,
        '167': 32.10035423609889,
        '168': 32.10040454537782,
        '169': 32.100423902284234,
        '170': 32.100381015347374,
        '171': 32.10036621362913,
        '172': 32.10035898210755,
        '173': 32.10025842657861,
        '174': 32.10014152301238,
        '175': 32.100212851149315,
        '176': 32.10046873664892,
        '177': 32.10064991501642,
        '178': 32.100571499063925,
        '179': 32.10032539452056,
        '180': 32.10013282335915,
        '-180': 32.10013282335915,
        '-179': 32.100121221739066,
        '-178': 32.100242926433545,
        '-177': 32.100334342675914,
        '-176': 32.100287924518355,
        '-175': 32.10019089255454,
        '-174': 32.100205534173256,
        '-173': 32.10032526470041,
        '-172': 32.10041473332131,
        '-171': 32.10047737906065,
        '-170': 32.100642693858525,
        '-169': 32.10086408169432,
        '-168': 32.10091594399451,
        '-167': 32.100770325377546,
        '-166': 32.10067648091224,
        '-165': 32.10073636127819,
        '-164': 32.10070756911487,
        '-163': 32.1004304628599,
        '-162': 32.10017573240223,
        '-161': 32.10028753420606,
        '-160': 32.10066207503376,
        '-159': 32.10087829803919,
        '-158': 32.100760196980296,
        '-157': 32.100537139577234,
        '-156': 32.100464748557656,
        '-155': 32.10052965818446,
        '-154': 32.10058010611709,
        '-153': 32.1005605164111,
        '-152': 32.10051638368189,
        '-151': 32.100478393557,
        '-150': 32.100448056206545,
        '-149': 32.10044695893875,
        '-148': 32.10049227604588,
        '-147': 32.100542575819084,
        '-146': 32.10053623542285,
        '-145': 32.10047652341392,
        '-144': 32.100422741935496,
        '-143': 32.10040549167365,
        '-142': 32.10040867604003,
        '-141': 32.10042507609811,
        '-140': 32.10045789589495,
        '-139': 32.10046938370555,
        '-138': 32.10041203940068,
        '-137': 32.10032198169277,
        '-136': 32.1002909836582,
        '-135': 32.10032408194543,
        '-134': 32.1003223375003,
        '-133': 32.10024016350428,
        '-132': 32.100153557712865,
        '-131': 32.100120452904065,
        '-130': 32.10008345513178,
        '-129': 32.099989228204876,
        '-128': 32.099906190347866,
        '-127': 32.099923358939975,
        '-126': 32.10000143551723,
        '-125': 32.10002488105664,
        '-124': 32.099958346971725,
        '-123': 32.0998674506552,
        '-122': 32.09981893486296,
        '-121': 32.099828234248925,
        '-120': 32.099871875661584,
        '-119': 32.09989995251777,
        '-118': 32.099879540526786,
        '-117': 32.09986098359471,
        '-116': 32.0999299410205,
        '-115': 32.10006033481965,
        '-114': 32.10012580704464,
        '-113': 32.10010976425838,
        '-112': 32.10014876517923,
        '-111': 32.10027144472266,
        '-110': 32.100296562286545,
        '-109': 32.10016926430252,
        '-108': 32.10017304562595,
        '-107': 32.10051212074801,
        '-106': 32.100878997648785,
        '-105': 32.10083892086793,
        '-104': 32.100507049944724,
        '-103': 32.10035465819678,
        '-102': 32.10042632589811,
        '-101': 32.10036322604512,
        '-100': 32.100230643660815,
        '-99': 32.10057242733986,
        '-98': 32.10138699371288,
        '-97': 32.09836917487513,
        '-96': 32.0968980332984,
        '-95': 32.100738947526956,
        '-94': 32.10093556263784,
        '-93': 32.09444292526643,
        '-92': 32.09414319503658,
        '-91': 32.10007031293447,
        '-90': 32.10046733790088,
        '-89': 32.101345600906996,
        '-88': 32.101143815732016,
        '-87': 32.100994446668174,
        '-86': 32.10111655006649,
        '-85': 32.1013737797623,
        '-84': 32.10147421457421,
        '-83': 32.101411296247306,
        '-82': 32.10140035527375,
        '-81': 32.10141947028898,
        '-80': 32.10124181898283,
        '-79': 32.10091160733481,
        '-78': 32.10075984133553,
        '-77': 32.10088284591293,
        '-76': 32.10097280463653,
        '-75': 32.10078802529111,
        '-74': 32.10049333426811,
        '-73': 32.10035128260717,
        '-72': 32.10030121019574,
        '-71': 32.10013412870934,
        '-70': 32.09999641916097,
        '-69': 32.1002976697476,
        '-68': 32.100941158793056,
        '-67': 32.10112847705768,
        '-66': 32.10047923195105,
        '-65': 32.09995407194096,
        '-64': 32.10069172584739,
        '-63': 32.10199544177362,
        '-62': 32.10177833986644,
        '-61': 32.099500181043624,
        '-60': 32.09735498748164,
        '-59': 32.097522888966914,
        '-58': 32.09929544291573,
        '-57': 32.10028569501548,
        '-56': 32.09973383374366,
        '-55': 32.099003924679295,
        '-54': 32.09913640845761,
        '-53': 32.09960465830747,
        '-52': 32.09968947473797,
        '-51': 32.09962299991074,
        '-50': 32.099839444430124,
        '-49': 32.10015289291884,
        '-48': 32.10025376034042,
        '-47': 32.100268165209975,
        '-46': 32.1003470721921,
        '-45': 32.10030056436808,
        '-44': 32.100038255819804,
        '-43': 32.099863471871934,
        '-42': 32.0999815744419,
        '-41': 32.10014914165776,
        '-40': 32.10011476947653,
        '-39': 32.100026837082204,
        '-38': 32.10011965194486,
        '-37': 32.10031823245313,
        '-36': 32.10042681673369,
        '-35': 32.10045561480553,
        '-34': 32.100514435728975,
        '-33': 32.100579626417286,
        '-32': 32.10058963356491,
        '-31': 32.100622205076526,
        '-30': 32.10076531243093,
        '-29': 32.100912907021616,
        '-28': 32.1009071129719,
        '-27': 32.100859297559126,
        '-26': 32.10110381844504,
        '-25': 32.10171884252369,
        '-24': 32.102241972878645,
        '-23': 32.10209637956932,
        '-22': 32.10131675927802,
        '-21': 32.10061185921747,
        '-20': 32.100556685021594,
        '-19': 32.100929741737225,
        '-18': 32.10110954779935,
        '-17': 32.10096198807658,
        '-16': 32.10079270117117,
        '-15': 32.10074208545449,
        '-14': 32.10100490518828,
        '-13': 32.1002978614737,
        '-12': 32.09903599223211,
        '-11': 32.098187825140094,
        '-10': 32.09846528802921,
        '-9': 32.0984543198561,
        '-8': 32.09813964314423,
        '-7': 32.09754831906737,
        '-6': 32.097507525081426,
        '-5': 32.097374706866454,
        '-4': 32.09835042587021,
        '-3': 32.09842538995653,
        '-2': 32.09771380732476,
        '-1': 32.09731081750242
    },
    '17': {
        '0': 32.09904506746212,
        '1': 32.09896406815343,
        '2': 32.098549761446485,
        '3': 32.0976201039217,
        '4': 32.09881509256358,
        '5': 32.09815895067129,
        '6': 32.097969758374866,
        '7': 32.09774204931351,
        '8': 32.09715421346219,
        '9': 32.096619756124156,
        '10': 32.097511870922034,
        '11': 32.09803834851599,
        '12': 32.09784187949232,
        '13': 32.09760047850444,
        '14': 32.098184028360336,
        '15': 32.09892458047205,
        '16': 32.09933556738489,
        '17': 32.09950606275138,
        '18': 32.09982817765826,
        '19': 32.099116890733605,
        '20': 32.09820406251972,
        '21': 32.097695060944666,
        '22': 32.09415348304951,
        '23': 32.09123475887025,
        '24': 32.09519761551065,
        '25': 32.09608098535304,
        '26': 32.09652910718374,
        '27': 32.095986739153,
        '28': 32.09757797660686,
        '29': 32.09818156788037,
        '30': 32.099196534187264,
        '31': 32.09649761191306,
        '32': 32.09796399165455,
        '33': 32.09797467911794,
        '34': 32.09839632072949,
        '35': 32.09848160414859,
        '36': 32.09816429945808,
        '37': 32.093211275933335,
        '38': 32.095922704002405,
        '39': 32.10222883814752,
        '40': 32.10196540117698,
        '41': 32.101831667919875,
        '42': 32.10243383736119,
        '43': 32.10120851823676,
        '44': 32.082717212340434,
        '45': 32.09087970543692,
        '46': 32.09342745437757,
        '47': 32.09361304959095,
        '48': 32.09392913525279,
        '49': 32.09371049109721,
        '50': 32.093837314716396,
        '51': 32.09427165130107,
        '52': 32.094070780047,
        '53': 32.09458435652175,
        '54': 32.102657609493896,
        '55': 32.10274115652836,
        '56': 32.10262931669392,
        '57': 32.10269213334158,
        '58': 32.10257295095485,
        '59': 32.10204789215679,
        '60': 32.101475556343686,
        '61': 32.10141343449669,
        '62': 32.10186467916644,
        '63': 32.10225805658363,
        '64': 32.10218659494407,
        '65': 32.101820936172615,
        '66': 32.10150418695139,
        '67': 32.101279744328366,
        '68': 32.101031411251405,
        '69': 32.10084119310224,
        '70': 32.100914817157744,
        '71': 32.101235305505334,
        '72': 32.10152962412302,
        '73': 32.10159049758753,
        '74': 32.09572028662678,
        '75': 32.09496863201672,
        '76': 32.096570625610745,
        '77': 32.09735252063272,
        '78': 32.09470536329476,
        '79': 32.098479682794306,
        '80': 32.100632390456546,
        '81': 32.10081983106058,
        '82': 32.10165641074566,
        '83': 32.10149503538641,
        '84': 32.100505555181634,
        '85': 32.10008265032471,
        '86': 32.10041096644454,
        '87': 32.10090335103151,
        '88': 32.10113965905458,
        '89': 32.101368093955394,
        '90': 32.10181934036098,
        '91': 32.10207816637674,
        '92': 32.10168737106373,
        '93': 32.10104366711254,
        '94': 32.10100585032246,
        '95': 32.101629826611195,
        '96': 32.1021519431929,
        '97': 32.10209528397485,
        '98': 32.10163195005876,
        '99': 32.10031145313466,
        '100': 32.10172503605919,
        '101': 32.08978842009933,
        '102': 32.09812332041824,
        '103': 32.099150607455,
        '104': 32.09834727242719,
        '105': 32.0997972649173,
        '106': 32.09920893321625,
        '107': 32.10155875130408,
        '108': 32.101782413057904,
        '109': 32.101742895249664,
        '110': 32.101547106199924,
        '111': 32.10155747336583,
        '112': 32.10184193948268,
        '113': 32.102013651694726,
        '114': 32.10198582137622,
        '115': 32.10210537948109,
        '116': 32.10230720376093,
        '117': 32.10213321259683,
        '118': 32.10192535161141,
        '119': 32.10273559159914,
        '120': 32.1043944719729,
        '121': 32.087458325241386,
        '122': 32.10270926519476,
        '123': 32.10185238887904,
        '124': 32.10172835270629,
        '125': 32.10301302785018,
        '126': 32.1037396294262,
        '127': 32.103155971434205,
        '128': 32.10232843408398,
        '129': 32.102184094105716,
        '130': 32.10243121417777,
        '131': 32.10245374355354,
        '132': 32.10218079701962,
        '133': 32.10192859317817,
        '134': 32.10200590404809,
        '135': 32.102423611336356,
        '136': 32.1027119508454,
        '137': 32.10245114231868,
        '138': 32.10212174374976,
        '139': 32.1025589312036,
        '140': 32.103363824256895,
        '141': 32.10318212145354,
        '142': 32.10207115384037,
        '143': 32.10192867148526,
        '144': 32.10350021397238,
        '145': 32.10465032701001,
        '146': 32.10330629435609,
        '147': 32.10079830021722,
        '148': 32.10017581589498,
        '149': 32.101941121573724,
        '150': 32.10364798025023,
        '151': 32.10355088494281,
        '152': 32.10255099172742,
        '153': 32.1021744314397,
        '154': 32.10242107794633,
        '155': 32.10244297883664,
        '156': 32.102117741944895,
        '157': 32.10195774648287,
        '158': 32.10209786640761,
        '159': 32.102201585638326,
        '160': 32.102111209148276,
        '161': 32.102018135618415,
        '162': 32.102075939125704,
        '163': 32.10221219008029,
        '164': 32.10230701470509,
        '165': 32.102329426257,
        '166': 32.10228470085242,
        '167': 32.10217437106826,
        '168': 32.10204994092629,
        '169': 32.10201860066263,
        '170': 32.102125858116935,
        '171': 32.10226221269357,
        '172': 32.102261640884365,
        '173': 32.10210050168497,
        '174': 32.1019310210799,
        '175': 32.10189971372645,
        '176': 32.10199852457516,
        '177': 32.10210929195378,
        '178': 32.10212283419782,
        '179': 32.10200818377227,
        '180': 32.101844918395464,
        '-180': 32.101844918395464,
        '-179': 32.10178226327728,
        '-178': 32.10188237114535,
        '-177': 32.102034714110765,
        '-176': 32.10211866488357,
        '-175': 32.102174747239474,
        '-174': 32.102278736193036,
        '-173': 32.10235301114267,
        '-172': 32.102304214470024,
        '-171': 32.10224650721298,
        '-170': 32.102334774464914,
        '-169': 32.10245851440894,
        '-168': 32.10239384819202,
        '-167': 32.10219819532223,
        '-166': 32.102122889871715,
        '-165': 32.102166487914445,
        '-164': 32.10209135084981,
        '-163': 32.101908893114626,
        '-162': 32.10194037313492,
        '-161': 32.10227679978582,
        '-160': 32.10255914909956,
        '-159': 32.10248727971536,
        '-158': 32.102225435355706,
        '-157': 32.10207792055276,
        '-156': 32.102044378540796,
        '-155': 32.10196765093412,
        '-154': 32.101900466378325,
        '-153': 32.1020078643926,
        '-152': 32.10222154970764,
        '-151': 32.10229409439149,
        '-150': 32.10217084909338,
        '-149': 32.10205820748789,
        '-148': 32.10210134673634,
        '-147': 32.10219994942996,
        '-146': 32.10220033703999,
        '-145': 32.10210723799431,
        '-144': 32.10201909165674,
        '-143': 32.101966107446565,
        '-142': 32.10191064719929,
        '-141': 32.101846327371106,
        '-140': 32.10180809573279,
        '-139': 32.101808463496184,
        '-138': 32.10182697141419,
        '-137': 32.10184773449205,
        '-136': 32.101859640279244,
        '-135': 32.10183875702015,
        '-134': 32.1017801632536,
        '-133': 32.10172840974877,
        '-132': 32.10172080539946,
        '-131': 32.101717447009314,
        '-130': 32.10165506515924,
        '-129': 32.10156080656943,
        '-128': 32.101529088773674,
        '-127': 32.101575658799284,
        '-126': 32.10160671592075,
        '-125': 32.10156622635333,
        '-124': 32.101519974840045,
        '-123': 32.10153462199595,
        '-122': 32.10156224899965,
        '-121': 32.10153086555843,
        '-120': 32.10147711250942,
        '-119': 32.101483558411346,
        '-118': 32.101534207089664,
        '-117': 32.10154621449845,
        '-116': 32.1015157906398,
        '-115': 32.10152047142353,
        '-114': 32.1015934256134,
        '-113': 32.10169761763545,
        '-112': 32.101800664344324,
        '-111': 32.10187649340211,
        '-110': 32.10188275150467,
        '-109': 32.101853962913964,
        '-108': 32.10193385555635,
        '-107': 32.10214974703135,
        '-106': 32.10226083874621,
        '-105': 32.10206775599396,
        '-104': 32.10176847471152,
        '-103': 32.10170001049821,
        '-102': 32.10183943910678,
        '-101': 32.101980443066545,
        '-100': 32.1014197127658,
        '-99': 32.09472338512128,
        '-98': 32.092289574232765,
        '-97': 32.080197229927165,
        '-96': 32.085221532652795,
        '-95': 32.10149517448813,
        '-94': 32.09171453691117,
        '-93': 32.08881272201982,
        '-92': 32.0902943090871,
        '-91': 32.101170149196705,
        '-90': 32.10058371214019,
        '-89': 32.098821538992546,
        '-88': 32.10213270238104,
        '-87': 32.10158519586147,
        '-86': 32.101537810214815,
        '-85': 32.10217184751304,
        '-84': 32.10283902931098,
        '-83': 32.10300569686064,
        '-82': 32.102916032366004,
        '-81': 32.10300527996669,
        '-80': 32.10318769711383,
        '-79': 32.103130554680725,
        '-78': 32.102850353936844,
        '-77': 32.10259228472747,
        '-76': 32.10234993643586,
        '-75': 32.10195069959195,
        '-74': 32.10151630809243,
        '-73': 32.10139378666212,
        '-72': 32.101593769888474,
        '-71': 32.10171725687582,
        '-70': 32.101589350748256,
        '-69': 32.101551304428945,
        '-68': 32.10184863792099,
        '-67': 32.10216971673067,
        '-66': 32.10226555344726,
        '-65': 32.10257310537728,
        '-64': 32.10341181727231,
        '-63': 32.10385427394084,
        '-62': 32.102584432762654,
        '-61': 32.10006273143698,
        '-60': 32.09858237444603,
        '-59': 32.0994997251507,
        '-58': 32.1014530937896,
        '-57': 32.10225376267319,
        '-56': 32.101657282107666,
        '-55': 32.10109020823427,
        '-54': 32.10127977836919,
        '-53': 32.10157882965697,
        '-52': 32.101461929060875,
        '-51': 32.10131747903471,
        '-50': 32.10152298565824,
        '-49': 32.10180062140562,
        '-48': 32.10185808383758,
        '-47': 32.10187112096207,
        '-46': 32.10197861485536,
        '-45': 32.101949664582726,
        '-44': 32.10168734900411,
        '-43': 32.10151738573499,
        '-42': 32.10165234458331,
        '-41': 32.10182620338734,
        '-40': 32.101771183251394,
        '-39': 32.10166705447181,
        '-38': 32.10179955912464,
        '-37': 32.10208123253903,
        '-36': 32.102230616959034,
        '-35': 32.10221498976665,
        '-34': 32.10222311598877,
        '-33': 32.10233018350121,
        '-32': 32.102443292210054,
        '-31': 32.10250991163137,
        '-30': 32.10256494128357,
        '-29': 32.10259515850244,
        '-28': 32.10255266121683,
        '-27': 32.10253335305418,
        '-26': 32.10275594444484,
        '-25': 32.1032375108155,
        '-24': 32.1036128762533,
        '-23': 32.1034819716616,
        '-22': 32.10292198262367,
        '-21': 32.10243446958315,
        '-20': 32.10232399610847,
        '-19': 32.1023757311466,
        '-18': 32.10230592160665,
        '-17': 32.10226091802713,
        '-16': 32.102291787270914,
        '-15': 32.102653053823204,
        '-14': 32.10248501836335,
        '-13': 32.101451386966744,
        '-12': 32.099838872726025,
        '-11': 32.100183688723966,
        '-10': 32.09883709974365,
        '-9': 32.100003519472644,
        '-8': 32.09965604638224,
        '-7': 32.098539172764454,
        '-6': 32.09911036695082,
        '-5': 32.09943365061014,
        '-4': 32.099625725063824,
        '-3': 32.09985045244943,
        '-2': 32.09984967380812,
        '-1': 32.09975333529141
    },
    '18': {
        '0': 32.100688122504664,
        '1': 32.100590032978836,
        '2': 32.099399389854185,
        '3': 32.099560451475696,
        '4': 32.0998552242796,
        '5': 32.09982457676618,
        '6': 32.09994341471739,
        '7': 32.09958774028419,
        '8': 32.09805926188536,
        '9': 32.09410331193253,
        '10': 32.09942348063645,
        '11': 32.099863592269784,
        '12': 32.09950532622524,
        '13': 32.099368308053236,
        '14': 32.09924255090802,
        '15': 32.099311739297065,
        '16': 32.102629537679306,
        '17': 32.09962610938225,
        '18': 32.099413607235086,
        '19': 32.10020250263339,
        '20': 32.099228009371885,
        '21': 32.09844753534422,
        '22': 32.09890403936738,
        '23': 32.09838331501031,
        '24': 32.095726998995076,
        '25': 32.09788005810008,
        '26': 32.098537375534285,
        '27': 32.09836124879871,
        '28': 32.099199373660944,
        '29': 32.09931663367497,
        '30': 32.1004739839859,
        '31': 32.10125263574802,
        '32': 32.10072625052464,
        '33': 32.09873816204407,
        '34': 32.10046071210361,
        '35': 32.09974496212753,
        '36': 32.0986362571934,
        '37': 32.094001167864825,
        '38': 32.099493423043675,
        '39': 32.10376640647731,
        '40': 32.10346764044519,
        '41': 32.10395131363519,
        '42': 32.10350538446279,
        '43': 32.08594542255445,
        '44': 32.09000359825289,
        '45': 32.09420610577216,
        '46': 32.095499497840926,
        '47': 32.09647020226684,
        '48': 32.09702334453517,
        '49': 32.098440745272036,
        '50': 32.09915674925111,
        '51': 32.098926410196825,
        '52': 32.09865133562851,
        '53': 32.09989112253248,
        '54': 32.10056935762756,
        '55': 32.10087262577167,
        '56': 32.10225439041423,
        '57': 32.104566176472716,
        '58': 32.10444567277529,
        '59': 32.103807475520846,
        '60': 32.10314827975707,
        '61': 32.1030925618338,
        '62': 32.10360581965924,
        '63': 32.10403752190935,
        '64': 32.10395411973827,
        '65': 32.10359147556353,
        '66': 32.10338772908031,
        '67': 32.1033930159022,
        '68': 32.10336077842245,
        '69': 32.1032077824748,
        '70': 32.103099487840275,
        '71': 32.10313412189078,
        '72': 32.10318061184502,
        '73': 32.103092702560836,
        '74': 32.09448957523331,
        '75': 32.09806659055903,
        '76': 32.09791780072401,
        '77': 32.09732566876047,
        '78': 32.0983758737739,
        '79': 32.09878110650808,
        '80': 32.1004820749511,
        '81': 32.102391736690585,
        '82': 32.09947926767872,
        '83': 32.102963325517685,
        '84': 32.10316490619928,
        '85': 32.102266953329675,
        '86': 32.10198491239147,
        '87': 32.10225018980747,
        '88': 32.10257116087894,
        '89': 32.10273611612715,
        '90': 32.102875881301465,
        '91': 32.10297487262462,
        '92': 32.102813351193504,
        '93': 32.10248331902197,
        '94': 32.102486680499986,
        '95': 32.10079970674901,
        '96': 32.10163346452209,
        '97': 32.10085304964044,
        '98': 32.09220559847471,
        '99': 32.097193727139235,
        '100': 32.10200771143243,
        '101': 32.09793321428275,
        '102': 32.098704436372834,
        '103': 32.101437580705266,
        '104': 32.10153851848275,
        '105': 32.0966073240389,
        '106': 32.1015253755427,
        '107': 32.10309499144933,
        '108': 32.10322377268755,
        '109': 32.10347689750316,
        '110': 32.10350245690916,
        '111': 32.103430623335804,
        '112': 32.103464969773356,
        '113': 32.10347121013615,
        '114': 32.10340859519311,
        '115': 32.10354361745273,
        '116': 32.10382363184205,
        '117': 32.10380045903053,
        '118': 32.1036326405397,
        '119': 32.104194770612565,
        '120': 32.10550794069587,
        '121': 32.09587052347818,
        '122': 32.102069056368826,
        '123': 32.103645727570466,
        '124': 32.10341609339134,
        '125': 32.104275936365454,
        '126': 32.10473091809489,
        '127': 32.10429905026315,
        '128': 32.103869405083,
        '129': 32.103982390965896,
        '130': 32.10415156054355,
        '131': 32.1039431697533,
        '132': 32.10362185756528,
        '133': 32.10356426105245,
        '134': 32.103784056204304,
        '135': 32.10408603096188,
        '136': 32.104203815265976,
        '137': 32.103983654375085,
        '138': 32.103786868910994,
        '139': 32.1041987794039,
        '140': 32.10496560147669,
        '141': 32.10506016970569,
        '142': 32.104366299722884,
        '143': 32.104181931051514,
        '144': 32.10509567332872,
        '145': 32.105569665639294,
        '146': 32.10411869034421,
        '147': 32.10196442269652,
        '148': 32.10166031281476,
        '149': 32.10349228214216,
        '150': 32.10516353715487,
        '151': 32.10508755619937,
        '152': 32.104166787278544,
        '153': 32.103869808859535,
        '154': 32.10417050822781,
        '155': 32.104238093565115,
        '156': 32.10397182645386,
        '157': 32.10382480620517,
        '158': 32.103814073683,
        '159': 32.103641170263,
        '160': 32.10341365228766,
        '161': 32.1034847528663,
        '162': 32.10378651866878,
        '163': 32.10393721595281,
        '164': 32.103885402871875,
        '165': 32.103918359744,
        '166': 32.10409061781053,
        '167': 32.10413102639654,
        '168': 32.103951352302595,
        '169': 32.1038217714104,
        '170': 32.10392353417082,
        '171': 32.10407177410748,
        '172': 32.10404720925679,
        '173': 32.10392453360234,
        '174': 32.103873209864965,
        '175': 32.103854751405514,
        '176': 32.10374578769312,
        '177': 32.10360220821183,
        '178': 32.103567887398654,
        '179': 32.10362697622866,
        '180': 32.10366119697403,
        '-180': 32.10366119697403,
        '-179': 32.10367249848947,
        '-178': 32.10375280280418,
        '-177': 32.10390238645582,
        '-176': 32.10405175226261,
        '-175': 32.10418263684516,
        '-174': 32.10426925965548,
        '-173': 32.104216468899324,
        '-172': 32.10403134644692,
        '-171': 32.10391424548827,
        '-170': 32.10397844814542,
        '-169': 32.10404143736181,
        '-168': 32.10392548907846,
        '-167': 32.10377655729481,
        '-166': 32.10379972612508,
        '-165': 32.103880911846936,
        '-164': 32.10382917924401,
        '-163': 32.10379809302464,
        '-162': 32.10401491549662,
        '-161': 32.104241161293956,
        '-160': 32.104055125366855,
        '-159': 32.103631811800206,
        '-158': 32.10362595551426,
        '-157': 32.104158055579894,
        '-156': 32.104522525031776,
        '-155': 32.10419044897134,
        '-154': 32.1035830507844,
        '-153': 32.10343633572077,
        '-152': 32.10378401446151,
        '-151': 32.10406145741794,
        '-150': 32.10399023411853,
        '-149': 32.10384302383388,
        '-148': 32.10388056069456,
        '-147': 32.10398561991076,
        '-146': 32.103955656112795,
        '-145': 32.103826988169914,
        '-144': 32.10374507276168,
        '-143': 32.103716264768686,
        '-142': 32.10365028190564,
        '-141': 32.10354057382781,
        '-140': 32.10347218057004,
        '-139': 32.103496744176915,
        '-138': 32.10358019793991,
        '-137': 32.103647249788644,
        '-136': 32.10363382692319,
        '-135': 32.103528348281834,
        '-134': 32.103394859616486,
        '-133': 32.10332213109122,
        '-132': 32.10332368525173,
        '-131': 32.10332856671817,
        '-130': 32.10329793177558,
        '-129': 32.103292151168674,
        '-128': 32.10336345993099,
        '-127': 32.1034348741963,
        '-126': 32.103386064339546,
        '-125': 32.10324598515893,
        '-124': 32.103178879461,
        '-123': 32.10324273568356,
        '-122': 32.10328724212413,
        '-121': 32.10318425476794,
        '-120': 32.103049064807806,
        '-119': 32.103079886509654,
        '-118': 32.10324209157812,
        '-117': 32.103306437399915,
        '-116': 32.10320299163194,
        '-115': 32.10313391855795,
        '-114': 32.10326693283487,
        '-113': 32.10349683493242,
        '-112': 32.10362416170504,
        '-111': 32.103628289725975,
        '-110': 32.10362841555733,
        '-109': 32.10367132604305,
        '-108': 32.103690741557315,
        '-107': 32.103630316555254,
        '-106': 32.10352302594596,
        '-105': 32.10347766346231,
        '-104': 32.10359482547076,
        '-103': 32.10383584269108,
        '-102': 32.10365164996223,
        '-101': 32.088601438503716,
        '-100': 32.09671198139065,
        '-99': 32.09679609682939,
        '-98': 32.089770031285944,
        '-97': 32.09521882420939,
        '-96': 32.10424749533059,
        '-95': 32.10268609221631,
        '-94': 32.10348309808332,
        '-93': 32.10387899603319,
        '-92': 32.103894425087724,
        '-91': 32.10313479390034,
        '-90': 32.10158845068878,
        '-89': 32.10391137845372,
        '-88': 32.104329974893226,
        '-87': 32.103595641600435,
        '-86': 32.10311982969284,
        '-85': 32.10349018243774,
        '-84': 32.10413939338196,
        '-83': 32.104303917494065,
        '-82': 32.104079862737215,
        '-81': 32.1040109316017,
        '-80': 32.104158896392775,
        '-79': 32.104192399683306,
        '-78': 32.10407621904493,
        '-77': 32.10371052320853,
        '-76': 32.10410323922501,
        '-75': 32.10398494418023,
        '-74': 32.10383721982615,
        '-73': 32.10409851934723,
        '-72': 32.10470093655368,
        '-71': 32.10487736654691,
        '-70': 32.10410757249285,
        '-69': 32.10286981788786,
        '-68': 32.10207792424673,
        '-67': 32.102003316483795,
        '-66': 32.10229142250712,
        '-65': 32.103371820810665,
        '-64': 32.10393073502517,
        '-63': 32.10364166483004,
        '-62': 32.102251271142904,
        '-61': 32.10077299577087,
        '-60': 32.10071763673756,
        '-59': 32.10218766460863,
        '-58': 32.10359865807457,
        '-57': 32.10371713324628,
        '-56': 32.10312624918083,
        '-55': 32.10305020055985,
        '-54': 32.103533250959266,
        '-53': 32.103711454334366,
        '-52': 32.10335742150929,
        '-51': 32.103091037429344,
        '-50': 32.103236482856644,
        '-49': 32.103438466597396,
        '-48': 32.10345260944493,
        '-47': 32.103498644719146,
        '-46': 32.103676096938614,
        '-45': 32.103709220790435,
        '-44': 32.103488753517105,
        '-43': 32.1033190303309,
        '-42': 32.10339686920768,
        '-41': 32.103497568498376,
        '-40': 32.103412296687914,
        '-39': 32.103313204292476,
        '-38': 32.10342806407531,
        '-37': 32.10365169322497,
        '-36': 32.10374840756527,
        '-35': 32.103738938218136,
        '-34': 32.10383465040496,
        '-33': 32.104094754353994,
        '-32': 32.104352717936614,
        '-31': 32.10444427555738,
        '-30': 32.104370491112405,
        '-29': 32.10424919110859,
        '-28': 32.10420112700695,
        '-27': 32.104284129139806,
        '-26': 32.10446471041285,
        '-25': 32.104627951219825,
        '-24': 32.10465868337918,
        '-23': 32.10454850028334,
        '-22': 32.104394065525014,
        '-21': 32.104249840748935,
        '-20': 32.10403906126689,
        '-19': 32.103725793687126,
        '-18': 32.1035403125445,
        '-17': 32.10381034321848,
        '-16': 32.104454876208,
        '-15': 32.10448751794289,
        '-14': 32.10409306252217,
        '-13': 32.10299936649973,
        '-12': 32.10035422837086,
        '-11': 32.1006681791399,
        '-10': 32.10090999450471,
        '-9': 32.10104560320062,
        '-8': 32.10120483776574,
        '-7': 32.100453275178644,
        '-6': 32.10058115951551,
        '-5': 32.10051877373616,
        '-4': 32.10071701986309,
        '-3': 32.1009403720464,
        '-2': 32.101018279922634,
        '-1': 32.10101783277377
    },
    '19': {
        '0': 32.102450228982036,
        '1': 32.101860357310265,
        '2': 32.10025155032179,
        '3': 32.100970255751434,
        '4': 32.101290261155775,
        '5': 32.10152611846258,
        '6': 32.101567854000535,
        '7': 32.10120814094995,
        '8': 32.099862640431034,
        '9': 32.0975663725349,
        '10': 32.10115101820821,
        '11': 32.101857278377494,
        '12': 32.101521116534784,
        '13': 32.10046304113434,
        '14': 32.10094664770841,
        '15': 32.10032190702651,
        '16': 32.10073653220919,
        '17': 32.10048105578512,
        '18': 32.10099326804747,
        '19': 32.10020029630751,
        '20': 32.100401760338414,
        '21': 32.0994836094413,
        '22': 32.0975542441519,
        '23': 32.09935090934711,
        '24': 32.09780084063294,
        '25': 32.09966905189136,
        '26': 32.10018430927694,
        '27': 32.0996534679676,
        '28': 32.10170031370904,
        '29': 32.10242270577162,
        '30': 32.102867660136546,
        '31': 32.1029358480613,
        '32': 32.1022771255742,
        '33': 32.10142088219787,
        '34': 32.10207283433271,
        '35': 32.101442772748236,
        '36': 32.09926256285712,
        '37': 32.09805187421178,
        '38': 32.10587404690325,
        '39': 32.10538665671388,
        '40': 32.105395879525084,
        '41': 32.10617737509399,
        '42': 32.100130976962134,
        '43': 32.09085185996493,
        '44': 32.09145602607587,
        '45': 32.096634409085084,
        '46': 32.09734780889936,
        '47': 32.098619734386936,
        '48': 32.09975697480352,
        '49': 32.1005535649968,
        '50': 32.10147926291797,
        '51': 32.10162845421504,
        '52': 32.101835241001936,
        '53': 32.10254583689474,
        '54': 32.1031568126556,
        '55': 32.10319727406264,
        '56': 32.10364700720194,
        '57': 32.10505475061409,
        '58': 32.1062358042416,
        '59': 32.10566912844982,
        '60': 32.104853091209634,
        '61': 32.10452283892871,
        '62': 32.10487422252642,
        '63': 32.10538689637085,
        '64': 32.10555117551425,
        '65': 32.10543234525896,
        '66': 32.105376805194446,
        '67': 32.105463854705654,
        '68': 32.1055009871704,
        '69': 32.105393926899744,
        '70': 32.10525333752672,
        '71': 32.1051730003253,
        '72': 32.10511187558528,
        '73': 32.105030474709174,
        '74': 32.09875776202476,
        '75': 32.097998974541895,
        '76': 32.10015717591373,
        '77': 32.10149665536374,
        '78': 32.10162984702568,
        '79': 32.10270348352883,
        '80': 32.10340167021769,
        '81': 32.10149733795298,
        '82': 32.10001314551153,
        '83': 32.09785230091183,
        '84': 32.10293532051349,
        '85': 32.10543909654663,
        '86': 32.10480602401371,
        '87': 32.104745837315825,
        '88': 32.104948921577176,
        '89': 32.104997955821766,
        '90': 32.10491820333501,
        '91': 32.104911526248905,
        '92': 32.10488596678078,
        '93': 32.10467745590472,
        '94': 32.104532176390165,
        '95': 32.10404085637254,
        '96': 32.1022309919937,
        '97': 32.096901167575645,
        '98': 32.09870145182843,
        '99': 32.10161381620958,
        '100': 32.10058102833733,
        '101': 32.09976389474897,
        '102': 32.09919097408793,
        '103': 32.092465828300966,
        '104': 32.090548730670605,
        '105': 32.10518616731336,
        '106': 32.105094663917185,
        '107': 32.10475739543025,
        '108': 32.104922070475,
        '109': 32.10400160468567,
        '110': 32.101146133186184,
        '111': 32.10556964633442,
        '112': 32.10554908211758,
        '113': 32.10538677034138,
        '114': 32.10515392179091,
        '115': 32.10527083344106,
        '116': 32.10575764634534,
        '117': 32.105946361038576,
        '118': 32.10555221385296,
        '119': 32.10532138265489,
        '120': 32.105851247125,
        '121': 32.1064858490603,
        '122': 32.10632374556835,
        '123': 32.10568476222373,
        '124': 32.10550462810718,
        '125': 32.105788292509544,
        '126': 32.10582281035638,
        '127': 32.10556097382076,
        '128': 32.1055979212099,
        '129': 32.10595853151813,
        '130': 32.10604337865838,
        '131': 32.10573153097679,
        '132': 32.10552841625308,
        '133': 32.10562002076168,
        '134': 32.10567986210528,
        '135': 32.10561942541679,
        '136': 32.10571567582053,
        '137': 32.105984342025856,
        '138': 32.10612885264805,
        '139': 32.10607731380366,
        '140': 32.10599587765024,
        '141': 32.10595234405122,
        '142': 32.10608758018481,
        '143': 32.10669612035654,
        '144': 32.10747111763916,
        '145': 32.10726665185468,
        '146': 32.1056021621065,
        '147': 32.10390055201946,
        '148': 32.10398988702981,
        '149': 32.105627337174774,
        '150': 32.106824669952445,
        '151': 32.10657080678454,
        '152': 32.10584837648796,
        '153': 32.10575504157501,
        '154': 32.10601934659682,
        '155': 32.10595385315154,
        '156': 32.10569665164597,
        '157': 32.105682272291844,
        '158': 32.105724083992286,
        '159': 32.10548314451275,
        '160': 32.10525989921926,
        '161': 32.105493480404796,
        '162': 32.10589053260868,
        '163': 32.10584940406599,
        '164': 32.10547724679323,
        '165': 32.1054106970297,
        '166': 32.105759547110885,
        '167': 32.10597610844153,
        '168': 32.10578603493415,
        '169': 32.10555637349153,
        '170': 32.10559166177349,
        '171': 32.105672917949356,
        '172': 32.10555581018288,
        '173': 32.105425463656125,
        '174': 32.1055162199878,
        '175': 32.105655695863476,
        '176': 32.105559794628306,
        '177': 32.10532431349967,
        '178': 32.105262203444624,
        '179': 32.105416817352264,
        '180': 32.10556185675737,
        '-180': 32.10556185675737,
        '-179': 32.105582102064076,
        '-178': 32.105574566953216,
        '-177': 32.105633059903326,
        '-176': 32.10575014182861,
        '-175': 32.10587703854719,
        '-174': 32.10592839351673,
        '-173': 32.105827337203294,
        '-172': 32.10566803881109,
        '-171': 32.1056709011421,
        '-170': 32.10584726848792,
        '-169': 32.10593204664174,
        '-168': 32.10580905553022,
        '-167': 32.10571797338631,
        '-166': 32.10581761821861,
        '-165': 32.10588343141667,
        '-164': 32.10575678220408,
        '-163': 32.105719063328884,
        '-162': 32.10596201301769,
        '-161': 32.106059143533166,
        '-160': 32.10561877694065,
        '-159': 32.105205170789866,
        '-158': 32.105762738938864,
        '-157': 32.10710458180031,
        '-156': 32.10784687671326,
        '-155': 32.107156389059384,
        '-154': 32.10583459291577,
        '-153': 32.10519469757846,
        '-152': 32.10544566918497,
        '-151': 32.10579758952003,
        '-150': 32.1057614639805,
        '-149': 32.105611727140854,
        '-148': 32.105667529243824,
        '-147': 32.10578920591368,
        '-146': 32.10574084348454,
        '-145': 32.10559331281765,
        '-144': 32.10553421352086,
        '-143': 32.10554987876854,
        '-142': 32.10551134408243,
        '-141': 32.10541880158577,
        '-140': 32.10538107578016,
        '-139': 32.105430879042956,
        '-138': 32.10549077420538,
        '-137': 32.105486109298,
        '-136': 32.10540819366829,
        '-135': 32.105290613967,
        '-134': 32.105180976505366,
        '-133': 32.10511838527091,
        '-132': 32.10510017805208,
        '-131': 32.10508833109533,
        '-130': 32.10507320775358,
        '-129': 32.105097747444674,
        '-128': 32.10517867045992,
        '-127': 32.10523766097392,
        '-126': 32.105181248861996,
        '-125': 32.105043643847424,
        '-124': 32.104962296255025,
        '-123': 32.10497935287277,
        '-122': 32.104966942951606,
        '-121': 32.10483512243279,
        '-120': 32.10471884074205,
        '-119': 32.10480337789733,
        '-118': 32.10501524650734,
        '-117': 32.10509431111885,
        '-116': 32.10499049008711,
        '-115': 32.10495628714703,
        '-114': 32.10515086439567,
        '-113': 32.10538348142351,
        '-112': 32.10542328371653,
        '-111': 32.10536130453121,
        '-110': 32.105416555976156,
        '-109': 32.10552146991309,
        '-108': 32.10540877675727,
        '-107': 32.10509147313947,
        '-106': 32.10496673101683,
        '-105': 32.10534120428267,
        '-104': 32.10500204516248,
        '-103': 32.09817118826185,
        '-102': 32.10188415683772,
        '-101': 32.09734195280659,
        '-100': 32.084858111528035,
        '-99': 32.08739935648063,
        '-98': 32.083231599620746,
        '-97': 32.094653755323,
        '-96': 32.10523030085029,
        '-95': 32.10473796145036,
        '-94': 32.10506698096297,
        '-93': 32.10571838337447,
        '-92': 32.10579787992385,
        '-91': 32.10556885588196,
        '-90': 32.105210760045836,
        '-89': 32.10559137112421,
        '-88': 32.10660801575548,
        '-87': 32.10593127755484,
        '-86': 32.10514973871503,
        '-85': 32.10510205200002,
        '-84': 32.10549235661777,
        '-83': 32.10560326438772,
        '-82': 32.10533622519948,
        '-81': 32.10508526192174,
        '-80': 32.104999531203525,
        '-79': 32.104961475552415,
        '-78': 32.10504826749807,
        '-77': 32.10542369002071,
        '-76': 32.105880844426416,
        '-75': 32.106060289984725,
        '-74': 32.106109795411776,
        '-73': 32.10653633244178,
        '-72': 32.10389357073544,
        '-71': 32.08277058748531,
        '-70': 32.104907279429675,
        '-69': 32.10408848671102,
        '-68': 32.10253037244773,
        '-67': 32.10203304414294,
        '-66': 32.10242396285942,
        '-65': 32.10307989825695,
        '-64': 32.10343962854213,
        '-63': 32.103232795766495,
        '-62': 32.102771146177375,
        '-61': 32.10287840848308,
        '-60': 32.10397182487699,
        '-59': 32.10528796012816,
        '-58': 32.105646856472056,
        '-57': 32.10499500172644,
        '-56': 32.10447904906154,
        '-55': 32.10482878957102,
        '-54': 32.10543779548111,
        '-53': 32.10543781259576,
        '-52': 32.10496703787318,
        '-51': 32.10475143404608,
        '-50': 32.104948446402176,
        '-49': 32.10513759496119,
        '-48': 32.105167649847125,
        '-47': 32.10528728740189,
        '-46': 32.10552773373593,
        '-45': 32.10559324811648,
        '-44': 32.105403212488774,
        '-43': 32.10523416518725,
        '-42': 32.10524160834165,
        '-41': 32.105255188984636,
        '-40': 32.10514590889192,
        '-39': 32.105045682007734,
        '-38': 32.105081749193815,
        '-37': 32.10517357036579,
        '-36': 32.10522528188917,
        '-35': 32.10531326787269,
        '-34': 32.10555489877697,
        '-33': 32.10591564282866,
        '-32': 32.106230086081894,
        '-31': 32.10634539989772,
        '-30': 32.10623429159062,
        '-29': 32.10604206971268,
        '-28': 32.10599156790011,
        '-27': 32.10614080355889,
        '-26': 32.10627772938004,
        '-25': 32.10618293711059,
        '-24': 32.10595629424617,
        '-23': 32.10590670357679,
        '-22': 32.106095225505264,
        '-21': 32.10617965056551,
        '-20': 32.10585190794447,
        '-19': 32.105327777177976,
        '-18': 32.10519601754676,
        '-17': 32.105726858778134,
        '-16': 32.106485679515046,
        '-15': 32.10652304690728,
        '-14': 32.10568722832748,
        '-13': 32.1043836229955,
        '-12': 32.10408802497658,
        '-11': 32.10131100039912,
        '-10': 32.10150369325158,
        '-9': 32.10165476251957,
        '-8': 32.10187199857531,
        '-7': 32.10203613836217,
        '-6': 32.102147932015626,
        '-5': 32.10221291189453,
        '-4': 32.10240020371156,
        '-3': 32.102595487273206,
        '-2': 32.102757602503345,
        '-1': 32.10240725118129
    },
    '20': {
        '0': 32.103997475109395,
        '1': 32.103556355812394,
        '2': 32.10175763261619,
        '3': 32.101516918809644,
        '4': 32.102119763902145,
        '5': 32.10228526275708,
        '6': 32.102170423560096,
        '7': 32.10208575195778,
        '8': 32.10096099963342,
        '9': 32.09953378385033,
        '10': 32.10272255101368,
        '11': 32.10358066579097,
        '12': 32.10312482751362,
        '13': 32.1019087310287,
        '14': 32.102395128097186,
        '15': 32.1021367515473,
        '16': 32.101264571464824,
        '17': 32.10179484258845,
        '18': 32.10031486047343,
        '19': 32.099023952108865,
        '20': 32.10171103734386,
        '21': 32.10129490504352,
        '22': 32.10168816417392,
        '23': 32.100335265183176,
        '24': 32.10075782255318,
        '25': 32.10109577726846,
        '26': 32.10204231583076,
        '27': 32.10180273355877,
        '28': 32.104056974209975,
        '29': 32.10407821628824,
        '30': 32.10449862954628,
        '31': 32.10466950267285,
        '32': 32.10403617184067,
        '33': 32.10433578771276,
        '34': 32.10388737059829,
        '35': 32.10299439248183,
        '36': 32.1015409523611,
        '37': 32.10596741648828,
        '38': 32.1077224544117,
        '39': 32.10737408984468,
        '40': 32.1076398797302,
        '41': 32.1040432173567,
        '42': 32.09242746717581,
        '43': 32.09604396477736,
        '44': 32.09833873752316,
        '45': 32.100140778350344,
        '46': 32.10063744205355,
        '47': 32.10172793967478,
        '48': 32.10233136946007,
        '49': 32.102854320865625,
        '50': 32.10344953177413,
        '51': 32.104004909555634,
        '52': 32.10439663211018,
        '53': 32.10485845068462,
        '54': 32.1054732754274,
        '55': 32.105735156976074,
        '56': 32.10567051702225,
        '57': 32.10621153809789,
        '58': 32.10839448234401,
        '59': 32.10820458143738,
        '60': 32.107330014899176,
        '61': 32.106615192410686,
        '62': 32.10663968979954,
        '63': 32.10712184291913,
        '64': 32.107434799336545,
        '65': 32.10735437702703,
        '66': 32.10709445242139,
        '67': 32.10689326059692,
        '68': 32.10684264190495,
        '69': 32.106967930241005,
        '70': 32.10721195597173,
        '71': 32.10739660685099,
        '72': 32.10739561471843,
        '73': 32.10549661644718,
        '74': 32.10184623082427,
        '75': 32.10209240752502,
        '76': 32.10155777699614,
        '77': 32.10214623019261,
        '78': 32.10366943994503,
        '79': 32.10475707743265,
        '80': 32.10473239520837,
        '81': 32.10335102908996,
        '82': 32.10095079095504,
        '83': 32.10521464140277,
        '84': 32.10150390065309,
        '85': 32.10637580177531,
        '86': 32.107487971632615,
        '87': 32.10733780181342,
        '88': 32.107380594027155,
        '89': 32.10738985635835,
        '90': 32.107363552654675,
        '91': 32.10752077921012,
        '92': 32.10770891688572,
        '93': 32.10753037863179,
        '94': 32.10491622745712,
        '95': 32.106688773555604,
        '96': 32.10569954816546,
        '97': 32.098327748447154,
        '98': 32.09997806224468,
        '99': 32.09544185988163,
        '100': 32.103093915766856,
        '101': 32.09734811702254,
        '102': 32.09861459437485,
        '103': 32.101166959789204,
        '104': 32.09823283812622,
        '105': 32.09901729583205,
        '106': 32.10679129168953,
        '107': 32.10658466346991,
        '108': 32.10670587110981,
        '109': 32.107009321839854,
        '110': 32.10726040265692,
        '111': 32.10748551289364,
        '112': 32.107643659445834,
        '113': 32.10748319611635,
        '114': 32.10711419575447,
        '115': 32.10714639460239,
        '116': 32.107730748109766,
        '117': 32.10806399650345,
        '118': 32.10752468330666,
        '119': 32.10673771423194,
        '120': 32.106703570093586,
        '121': 32.107349451145,
        '122': 32.10783204431786,
        '123': 32.10788062764408,
        '124': 32.10788430742221,
        '125': 32.10793672416746,
        '126': 32.10778298780247,
        '127': 32.10755665431103,
        '128': 32.107632286240005,
        '129': 32.10784282969022,
        '130': 32.10772064505231,
        '131': 32.10740772325925,
        '132': 32.107433506659326,
        '133': 32.10768714030912,
        '134': 32.10757295475066,
        '135': 32.107182992154286,
        '136': 32.107307610794905,
        '137': 32.1081004504369,
        '138': 32.108659076909724,
        '139': 32.108287940746365,
        '140': 32.107483813707304,
        '141': 32.107291912265524,
        '142': 32.1081146776275,
        '143': 32.10935123110181,
        '144': 32.10989203194082,
        '145': 32.10902563075085,
        '146': 32.10728598621307,
        '147': 32.10623382456357,
        '148': 32.10682802767778,
        '149': 32.10820580382012,
        '150': 32.108760365032545,
        '151': 32.10819659599152,
        '152': 32.107616787002605,
        '153': 32.10768837595366,
        '154': 32.10785847239701,
        '155': 32.107602887412234,
        '156': 32.10732504729341,
        '157': 32.10747010397374,
        '158': 32.10767605751614,
        '159': 32.10751251622992,
        '160': 32.10733493371324,
        '161': 32.107617267860164,
        '162': 32.10799252820616,
        '163': 32.10780901259587,
        '164': 32.10726906891096,
        '165': 32.10713897387307,
        '166': 32.1075119531453,
        '167': 32.10771923731854,
        '168': 32.10745718432551,
        '169': 32.107185528625195,
        '170': 32.107250694326865,
        '171': 32.10734757742161,
        '172': 32.10716179452466,
        '173': 32.10695043964217,
        '174': 32.10707666825337,
        '175': 32.1073957854494,
        '176': 32.107528875919634,
        '177': 32.10745435908733,
        '178': 32.107427721334815,
        '179': 32.10750685714174,
        '180': 32.10752057673234,
        '-180': 32.10752057673234,
        '-179': 32.10740289825258,
        '-178': 32.10726878267573,
        '-177': 32.10722017480334,
        '-176': 32.10727720402949,
        '-175': 32.10742225889984,
        '-174': 32.10757157213859,
        '-173': 32.10762490520518,
        '-172': 32.107646492152085,
        '-171': 32.107808357849244,
        '-170': 32.10805673284798,
        '-169': 32.108107033740566,
        '-168': 32.10791145052156,
        '-167': 32.10779142229581,
        '-166': 32.10789217316583,
        '-165': 32.107903310091196,
        '-164': 32.10764298143171,
        '-163': 32.10748469519001,
        '-162': 32.107727415360316,
        '-161': 32.1079648063576,
        '-160': 32.107756806748526,
        '-159': 32.107610228053936,
        '-158': 32.10839459490335,
        '-157': 32.109812998669256,
        '-156': 32.11041265605633,
        '-155': 32.10943199389672,
        '-154': 32.10784574669902,
        '-153': 32.107078318841744,
        '-152': 32.10730096161567,
        '-151': 32.107629255707515,
        '-150': 32.10755042921409,
        '-149': 32.1073806685864,
        '-148': 32.10745402729338,
        '-147': 32.10759552644403,
        '-146': 32.10754654536108,
        '-145': 32.107397547869965,
        '-144': 32.10735329588308,
        '-143': 32.10738677426681,
        '-142': 32.107361955398886,
        '-141': 32.107300732565626,
        '-140': 32.10731426174542,
        '-139': 32.107381114421415,
        '-138': 32.10737456805865,
        '-137': 32.107262951267806,
        '-136': 32.10714500089337,
        '-135': 32.10709845532329,
        '-134': 32.10709733033111,
        '-133': 32.10708807605692,
        '-132': 32.1070661987342,
        '-131': 32.107046936628635,
        '-130': 32.107017128820004,
        '-129': 32.106962144817324,
        '-128': 32.106909139698146,
        '-127': 32.10689388676209,
        '-126': 32.10689981081068,
        '-125': 32.10687982890324,
        '-124': 32.10682839962821,
        '-123': 32.1067761964817,
        '-122': 32.10672621866642,
        '-121': 32.10667004300283,
        '-120': 32.106655745219015,
        '-119': 32.10673441687228,
        '-118': 32.10683722448279,
        '-117': 32.106843262679426,
        '-116': 32.1067946584268,
        '-115': 32.10686937832522,
        '-114': 32.107074889422755,
        '-113': 32.107181858800445,
        '-112': 32.10709692904174,
        '-111': 32.1070668542152,
        '-110': 32.107274552448864,
        '-109': 32.107440600994394,
        '-108': 32.10718664647408,
        '-107': 32.10671079447634,
        '-106': 32.10669283507852,
        '-105': 32.099944447731815,
        '-104': 32.09577544961214,
        '-103': 32.08796729442622,
        '-102': 32.08938657375818,
        '-101': 32.089900368323555,
        '-100': 32.079890317333316,
        '-99': 32.08473369351918,
        '-98': 32.085544992230034,
        '-97': 32.10696724156176,
        '-96': 32.10701824019618,
        '-95': 32.10645697853291,
        '-94': 32.10698670288714,
        '-93': 32.10787585117183,
        '-92': 32.108232124883514,
        '-91': 32.108100797108676,
        '-90': 32.107403579031875,
        '-89': 32.10813981232457,
        '-88': 32.108292779460704,
        '-87': 32.107850268195556,
        '-86': 32.10707187279562,
        '-85': 32.10686685838744,
        '-84': 32.10714850043814,
        '-83': 32.10734340246018,
        '-82': 32.10722383217821,
        '-81': 32.107012966647716,
        '-80': 32.10689460610071,
        '-79': 32.10687353778691,
        '-78': 32.10699725445722,
        '-77': 32.097794582178494,
        '-76': 32.10382847458786,
        '-75': 32.10702780374082,
        '-74': 32.10695373775096,
        '-73': 32.107007332020146,
        '-72': 32.10745782798084,
        '-71': 32.10760946831316,
        '-70': 32.106880004904326,
        '-69': 32.10553186852312,
        '-68': 32.10435180080899,
        '-67': 32.10384296639858,
        '-66': 32.10395477152659,
        '-65': 32.104371171164395,
        '-64': 32.104791786895774,
        '-63': 32.105105521567054,
        '-62': 32.10553192650482,
        '-61': 32.10639361179043,
        '-60': 32.10750219075346,
        '-59': 32.10806207954924,
        '-58': 32.10759136867492,
        '-57': 32.106696771530366,
        '-56': 32.106399819245844,
        '-55': 32.10682315167853,
        '-54': 32.107156191725906,
        '-53': 32.10691399140359,
        '-52': 32.10654281011654,
        '-51': 32.10658367414108,
        '-50': 32.10688642179996,
        '-49': 32.10704117913123,
        '-48': 32.10708243565354,
        '-47': 32.107276659273325,
        '-46': 32.10754323322243,
        '-45': 32.10758066793276,
        '-44': 32.10738797891232,
        '-43': 32.10723556096736,
        '-42': 32.107220381955166,
        '-41': 32.10720444242702,
        '-40': 32.10712568345096,
        '-39': 32.1070649914511,
        '-38': 32.10704856831301,
        '-37': 32.107038666102596,
        '-36': 32.10708504027019,
        '-35': 32.107274921664576,
        '-34': 32.10757014349029,
        '-33': 32.10785749477324,
        '-32': 32.10808919242247,
        '-31': 32.10822863565458,
        '-30': 32.10818067955849,
        '-29': 32.107959853645745,
        '-28': 32.1077964665615,
        '-27': 32.10785084720095,
        '-26': 32.107939324334836,
        '-25': 32.107808455157844,
        '-24': 32.1075868101901,
        '-23': 32.10762937370187,
        '-22': 32.10791423327192,
        '-21': 32.107966209637226,
        '-20': 32.10753754019565,
        '-19': 32.107063212376694,
        '-18': 32.10715314813997,
        '-17': 32.107779618932625,
        '-16': 32.10811166362916,
        '-15': 32.107130082304195,
        '-14': 32.10676573554214,
        '-13': 32.10499669676286,
        '-12': 32.10449867365623,
        '-11': 32.10411656064534,
        '-10': 32.10364471311249,
        '-9': 32.10332363620207,
        '-8': 32.103893086200365,
        '-7': 32.103987701180046,
        '-6': 32.1042357517736,
        '-5': 32.10422384946037,
        '-4': 32.10426136402842,
        '-3': 32.10442547098343,
        '-2': 32.10464904315731,
        '-1': 32.10465616925181
    },
    '21': {
        '0': 32.106210171698216,
        '1': 32.10597736158656,
        '2': 32.104799982062495,
        '3': 32.10449762891877,
        '4': 32.10305606646505,
        '5': 32.10382463466428,
        '6': 32.103561506670374,
        '7': 32.10349111187413,
        '8': 32.102746212491795,
        '9': 32.1029044600232,
        '10': 32.104471871173764,
        '11': 32.104894553015676,
        '12': 32.104510641897214,
        '13': 32.10162047287696,
        '14': 32.104230178258526,
        '15': 32.10417917987484,
        '16': 32.10311620306103,
        '17': 32.085944416432625,
        '18': 32.09631645741429,
        '19': 32.10094515255343,
        '20': 32.102328428488725,
        '21': 32.10302544802877,
        '22': 32.10274213705204,
        '23': 32.10283310419666,
        '24': 32.10208412295377,
        '25': 32.1029302607791,
        '26': 32.103363380593784,
        '27': 32.105083536441406,
        '28': 32.105226872754464,
        '29': 32.10598532485063,
        '30': 32.10655957170387,
        '31': 32.10674751285915,
        '32': 32.10456344362276,
        '33': 32.106243217318074,
        '34': 32.10530799312778,
        '35': 32.1038326383139,
        '36': 32.10576613700126,
        '37': 32.10802497728785,
        '38': 32.10943768597562,
        '39': 32.10940337028529,
        '40': 32.10508836864842,
        '41': 32.0947645088374,
        '42': 32.09616008952577,
        '43': 32.10039542285818,
        '44': 32.101586943182774,
        '45': 32.102385675711375,
        '46': 32.10253400707424,
        '47': 32.10399809501737,
        '48': 32.104992651527674,
        '49': 32.105612333085894,
        '50': 32.10598883071749,
        '51': 32.106585311234284,
        '52': 32.10715751784826,
        '53': 32.107613430120914,
        '54': 32.107697723944526,
        '55': 32.107812751437294,
        '56': 32.10792996551737,
        '57': 32.108400939676024,
        '58': 32.11025267776453,
        '59': 32.11099517972156,
        '60': 32.11022233098376,
        '61': 32.10935531956486,
        '62': 32.1091674890746,
        '63': 32.109486915728425,
        '64': 32.109654385280415,
        '65': 32.1093722320942,
        '66': 32.10885685823584,
        '67': 32.10846717169824,
        '68': 32.10845774915609,
        '69': 32.1088821391024,
        '70': 32.10947095805873,
        '71': 32.10806233287875,
        '72': 32.109552018199714,
        '73': 32.10906022153762,
        '74': 32.10266767873866,
        '75': 32.10702775808549,
        '76': 32.10643183754243,
        '77': 32.10639198856003,
        '78': 32.10573005775415,
        '79': 32.106700217328566,
        '80': 32.107099050943454,
        '81': 32.10618622811343,
        '82': 32.1061565391132,
        '83': 32.10698500626065,
        '84': 32.108198248344586,
        '85': 32.10821748945384,
        '86': 32.10865872082254,
        '87': 32.10907782582574,
        '88': 32.10902647923256,
        '89': 32.10904566323856,
        '90': 32.10915319635722,
        '91': 32.109526411828554,
        '92': 32.10992726034882,
        '93': 32.10961776595382,
        '94': 32.09786717033892,
        '95': 32.10597647716731,
        '96': 32.10764362446702,
        '97': 32.099617479816274,
        '98': 32.101336992818396,
        '99': 32.10125255278537,
        '100': 32.09969401442041,
        '101': 32.099494461796134,
        '102': 32.099263381316575,
        '103': 32.09983179813024,
        '104': 32.101966105420246,
        '105': 32.10502503913584,
        '106': 32.10863945117496,
        '107': 32.10861601978897,
        '108': 32.1086378310346,
        '109': 32.10868242033432,
        '110': 32.10850966449748,
        '111': 32.10909404561511,
        '112': 32.109439531391445,
        '113': 32.10943992315378,
        '114': 32.109123962289466,
        '115': 32.10909427833534,
        '116': 32.109606118511806,
        '117': 32.11001095270481,
        '118': 32.10969525924912,
        '119': 32.109087234764864,
        '120': 32.10901196185782,
        '121': 32.109443414070455,
        '122': 32.109710669194186,
        '123': 32.10965523356752,
        '124': 32.109709931109656,
        '125': 32.10997902296699,
        '126': 32.11009874517828,
        '127': 32.10996902650362,
        '128': 32.109838242747294,
        '129': 32.10969288602491,
        '130': 32.10933268428654,
        '131': 32.10901486862577,
        '132': 32.10920242853862,
        '133': 32.10965447998946,
        '134': 32.109639066017145,
        '135': 32.10917034544023,
        '136': 32.10912902586177,
        '137': 32.10986424052188,
        '138': 32.11053843566575,
        '139': 32.1103585473161,
        '140': 32.10978330960346,
        '141': 32.10987644014846,
        '142': 32.110817047149155,
        '143': 32.111612407322845,
        '144': 32.111224361961916,
        '145': 32.10971362858591,
        '146': 32.10828447212125,
        '147': 32.108184235894875,
        '148': 32.109406185002605,
        '149': 32.11061894818314,
        '150': 32.110659842077084,
        '151': 32.10985640442801,
        '152': 32.109387621037115,
        '153': 32.10960689997423,
        '154': 32.10978986271253,
        '155': 32.1094949511017,
        '156': 32.10921364985823,
        '157': 32.10937384767696,
        '158': 32.10958215276836,
        '159': 32.10940179850189,
        '160': 32.109171026084326,
        '161': 32.109368567581136,
        '162': 32.109704107371954,
        '163': 32.10959780496661,
        '164': 32.109198337633785,
        '165': 32.109129963867574,
        '166': 32.10942445575758,
        '167': 32.10950211478994,
        '168': 32.10918472622332,
        '169': 32.108963281403916,
        '170': 32.10912643024928,
        '171': 32.10930548765689,
        '172': 32.109154694481745,
        '173': 32.108927764445106,
        '174': 32.10902691866411,
        '175': 32.109385037865124,
        '176': 32.10965381407262,
        '177': 32.1097145178851,
        '178': 32.109673205790564,
        '179': 32.109566450937116,
        '180': 32.10937277065606,
        '-180': 32.10937277065606,
        '-179': 32.109170239788334,
        '-178': 32.10904883675899,
        '-177': 32.10898944646853,
        '-176': 32.108989213047074,
        '-175': 32.10913974894933,
        '-174': 32.10943428883758,
        '-173': 32.109695269119555,
        '-172': 32.10983814367292,
        '-171': 32.10997636834762,
        '-170': 32.11011993650597,
        '-169': 32.1100679826777,
        '-168': 32.109804918192914,
        '-167': 32.10966559765498,
        '-166': 32.10981810588962,
        '-165': 32.10992795504045,
        '-164': 32.10971505788306,
        '-163': 32.10950413616808,
        '-162': 32.109724697402804,
        '-161': 32.110153230133925,
        '-160': 32.11027447084668,
        '-159': 32.11020861519172,
        '-158': 32.110529790736365,
        '-157': 32.11115364270575,
        '-156': 32.11121161240503,
        '-155': 32.11031389810894,
        '-154': 32.109249163346696,
        '-153': 32.10896500690629,
        '-152': 32.1093616722823,
        '-151': 32.10964161171173,
        '-150': 32.109494291116796,
        '-149': 32.109311423920744,
        '-148': 32.10938250845054,
        '-147': 32.109497452720625,
        '-146': 32.109419084255016,
        '-145': 32.10926545318814,
        '-144': 32.109234244022566,
        '-143': 32.109275281690365,
        '-142': 32.10924952718141,
        '-141': 32.10919704152341,
        '-140': 32.10923426341114,
        '-139': 32.109313350119336,
        '-138': 32.109283060742435,
        '-137': 32.109135817693,
        '-136': 32.10902612567377,
        '-135': 32.1090451385026,
        '-134': 32.109112830816635,
        '-133': 32.109127176588075,
        '-132': 32.109106372697966,
        '-131': 32.109108874830916,
        '-130': 32.10909401660115,
        '-129': 32.10897676236529,
        '-128': 32.108795800404785,
        '-127': 32.108702365981095,
        '-126': 32.108753229852255,
        '-125': 32.10882360772767,
        '-124': 32.10878607188431,
        '-123': 32.10868509859134,
        '-122': 32.10865204208028,
        '-121': 32.10871473149724,
        '-120': 32.10878128776907,
        '-119': 32.10877143824929,
        '-118': 32.10869308069537,
        '-117': 32.10862607958909,
        '-116': 32.10867032079075,
        '-115': 32.10884026866725,
        '-114': 32.1089853657975,
        '-113': 32.10894484136635,
        '-112': 32.10882895680641,
        '-111': 32.108942197927874,
        '-110': 32.109294601382736,
        '-109': 32.10943542487964,
        '-108': 32.10905111505097,
        '-107': 32.1085497827509,
        '-106': 32.108665118282275,
        '-105': 32.10278594885709,
        '-104': 32.09570812395591,
        '-103': 32.0907880785789,
        '-102': 32.090632747977736,
        '-101': 32.08938852470389,
        '-100': 32.08566778184125,
        '-99': 32.1032711532048,
        '-98': 32.10858561487034,
        '-97': 32.109772776418104,
        '-96': 32.10882506843336,
        '-95': 32.10834042669914,
        '-94': 32.108706102951714,
        '-93': 32.10954243993236,
        '-92': 32.11012283361302,
        '-91': 32.11022976364026,
        '-90': 32.110220415019334,
        '-89': 32.11032016247472,
        '-88': 32.11021070361479,
        '-87': 32.109779751408766,
        '-86': 32.109251610595216,
        '-85': 32.10899612566598,
        '-84': 32.10925038306279,
        '-83': 32.10953734659939,
        '-82': 32.10954000524808,
        '-81': 32.10943461456415,
        '-80': 32.10950048561969,
        '-79': 32.10972927429226,
        '-78': 32.109323034992684,
        '-77': 32.10879155227836,
        '-76': 32.108618656000786,
        '-75': 32.1085361666763,
        '-74': 32.107754575886574,
        '-73': 32.10736031657348,
        '-72': 32.10741518663927,
        '-71': 32.107636652932584,
        '-70': 32.107763439817006,
        '-69': 32.10777666193565,
        '-68': 32.10774461950278,
        '-67': 32.10768840126379,
        '-66': 32.107685802885456,
        '-65': 32.10788202974908,
        '-64': 32.10827799348715,
        '-63': 32.108701163095645,
        '-62': 32.10907419946867,
        '-61': 32.10946717150303,
        '-60': 32.10977662432898,
        '-59': 32.10968549377662,
        '-58': 32.10917422189482,
        '-57': 32.10874703564521,
        '-56': 32.10880412482822,
        '-55': 32.10905471150543,
        '-54': 32.108974128068674,
        '-53': 32.10863096985063,
        '-52': 32.108547750385405,
        '-51': 32.10884540273959,
        '-50': 32.10909662041673,
        '-49': 32.109070220029636,
        '-48': 32.109063591694735,
        '-47': 32.10931877213971,
        '-46': 32.109592582144764,
        '-45': 32.10957654937241,
        '-44': 32.10936967432191,
        '-43': 32.10924406625719,
        '-42': 32.10922930573382,
        '-41': 32.10920695312019,
        '-40': 32.1091834495889,
        '-39': 32.10920870529744,
        '-38': 32.109217245969404,
        '-37': 32.10918123874471,
        '-36': 32.10924150378203,
        '-35': 32.10947755703252,
        '-34': 32.10973144324324,
        '-33': 32.10985974691759,
        '-32': 32.10995556014262,
        '-31': 32.11009873715237,
        '-30': 32.11010807646993,
        '-29': 32.10982540758233,
        '-28': 32.109460914871036,
        '-27': 32.109335461159134,
        '-26': 32.10941605262635,
        '-25': 32.10944203718943,
        '-24': 32.10939893344706,
        '-23': 32.109473235498676,
        '-22': 32.10957660591686,
        '-21': 32.10936955918282,
        '-20': 32.108876727881956,
        '-19': 32.108659917235066,
        '-18': 32.10910355267483,
        '-17': 32.109814834536465,
        '-16': 32.10910310379874,
        '-15': 32.10836916967906,
        '-14': 32.10796689151694,
        '-13': 32.10545607145568,
        '-12': 32.10437282780029,
        '-11': 32.10576213007641,
        '-10': 32.10579913268356,
        '-9': 32.105536623954315,
        '-8': 32.10592816602756,
        '-7': 32.10594768627065,
        '-6': 32.10635093219802,
        '-5': 32.106343471077196,
        '-4': 32.10631215169847,
        '-3': 32.106220749145784,
        '-2': 32.10656147881922,
        '-1': 32.10636605766583
    },
    '22': {
        '0': 32.10824455793303,
        '1': 32.10797558414056,
        '2': 32.10756898997723,
        '3': 32.10574365248881,
        '4': 32.10598033605445,
        '5': 32.10423686870284,
        '6': 32.10368672813667,
        '7': 32.104377953919084,
        '8': 32.1045806769518,
        '9': 32.10476609681447,
        '10': 32.10536849737664,
        '11': 32.10601349788733,
        '12': 32.10383235678948,
        '13': 32.10477072555858,
        '14': 32.10570590536795,
        '15': 32.10570894978671,
        '16': 32.10138413269132,
        '17': 32.10318451539338,
        '18': 32.1044601883591,
        '19': 32.09491305741167,
        '20': 32.10521777471701,
        '21': 32.105435964990484,
        '22': 32.10553844264515,
        '23': 32.10521317556708,
        '24': 32.10561770543496,
        '25': 32.10313303809982,
        '26': 32.10636122257622,
        '27': 32.10753716379386,
        '28': 32.10819705716993,
        '29': 32.108395047402304,
        '30': 32.108333490290725,
        '31': 32.10857089500983,
        '32': 32.10775228132658,
        '33': 32.10888497953732,
        '34': 32.107295661305855,
        '35': 32.10615807825092,
        '36': 32.10999211145945,
        '37': 32.11141595801295,
        '38': 32.111044237139936,
        '39': 32.11142935555994,
        '40': 32.1056071604037,
        '41': 32.1024271805236,
        '42': 32.102354584762494,
        '43': 32.10339270725634,
        '44': 32.100611653294955,
        '45': 32.103283298808186,
        '46': 32.104527370592784,
        '47': 32.10621732851802,
        '48': 32.10722318131729,
        '49': 32.10784690920923,
        '50': 32.10839139758878,
        '51': 32.108993275099,
        '52': 32.10965353006324,
        '53': 32.109852531576166,
        '54': 32.109779589646244,
        '55': 32.10937553799606,
        '56': 32.10997955258111,
        '57': 32.10969793200866,
        '58': 32.11075338261073,
        '59': 32.11023790564935,
        '60': 32.11214228562997,
        '61': 32.11143331028933,
        '62': 32.11134097031167,
        '63': 32.111544536017185,
        '64': 32.11151793143503,
        '65': 32.11123902943788,
        '66': 32.111012707814716,
        '67': 32.1110101556716,
        '68': 32.11124292644081,
        '69': 32.11166574917397,
        '70': 32.11069336454999,
        '71': 32.11022278588858,
        '72': 32.111254387357114,
        '73': 32.110609475776336,
        '74': 32.107423479229496,
        '75': 32.10918840802738,
        '76': 32.10820802096105,
        '77': 32.108056600094464,
        '78': 32.10627405280535,
        '79': 32.10519788533767,
        '80': 32.10864846831285,
        '81': 32.10385311163745,
        '82': 32.108112059379806,
        '83': 32.108121269640975,
        '84': 32.108874915810894,
        '85': 32.10925375267745,
        '86': 32.10717378046955,
        '87': 32.110482781552435,
        '88': 32.11085106730341,
        '89': 32.1108016321775,
        '90': 32.110822820276844,
        '91': 32.11106622762761,
        '92': 32.11094377036355,
        '93': 32.10055470449142,
        '94': 32.106611923265454,
        '95': 32.10872276647166,
        '96': 32.109398864598425,
        '97': 32.10513402001555,
        '98': 32.10249074256689,
        '99': 32.103275598402156,
        '100': 32.100433136951146,
        '101': 32.09930608851741,
        '102': 32.10036760831809,
        '103': 32.097944490511225,
        '104': 32.09631622643918,
        '105': 32.10959248335202,
        '106': 32.10677252286864,
        '107': 32.10877383083468,
        '108': 32.10832001435392,
        '109': 32.110223988314985,
        '110': 32.109715007296636,
        '111': 32.1100641300205,
        '112': 32.10767448779979,
        '113': 32.11126798154739,
        '114': 32.11117629339075,
        '115': 32.11116388229784,
        '116': 32.11147253528067,
        '117': 32.111826893279414,
        '118': 32.11192602381485,
        '119': 32.111957865109304,
        '120': 32.11214006333182,
        '121': 32.1121254508475,
        '122': 32.11152667147103,
        '123': 32.11076962747942,
        '124': 32.11067468110746,
        '125': 32.111295422225034,
        '126': 32.11189550391052,
        '127': 32.11203272912126,
        '128': 32.111938283630344,
        '129': 32.111815753459,
        '130': 32.111544505461765,
        '131': 32.11122236996708,
        '132': 32.111256718588535,
        '133': 32.11165148343673,
        '134': 32.111849692886345,
        '135': 32.11158883771836,
        '136': 32.11133144551128,
        '137': 32.111513515112684,
        '138': 32.11191396537571,
        '139': 32.11217134892396,
        '140': 32.11242786295244,
        '141': 32.1129304957628,
        '142': 32.11330717775304,
        '143': 32.11282339210015,
        '144': 32.11135893414221,
        '145': 32.10978315773313,
        '146': 32.10924911734269,
        '147': 32.11014986401411,
        '148': 32.111712638882686,
        '149': 32.11265422787033,
        '150': 32.11240052278218,
        '151': 32.1116131465142,
        '152': 32.1113103435563,
        '153': 32.11163018578642,
        '154': 32.11187873639594,
        '155': 32.11168159322084,
        '156': 32.11143855773682,
        '157': 32.111501345967504,
        '158': 32.11157565259228,
        '159': 32.111325397150374,
        '160': 32.11102404310217,
        '161': 32.111086418248135,
        '162': 32.111343885348255,
        '163': 32.111350044754374,
        '164': 32.11114254273834,
        '165': 32.11111784298092,
        '166': 32.11130081570807,
        '167': 32.11132692133406,
        '168': 32.11111695487736,
        '169': 32.11101159507955,
        '170': 32.11116474078906,
        '171': 32.111301212466316,
        '172': 32.111223128668264,
        '173': 32.11113172004986,
        '174': 32.11125242779668,
        '175': 32.111482231131546,
        '176': 32.1116081605669,
        '177': 32.11159790860059,
        '178': 32.11149877281611,
        '179': 32.111305034792935,
        '180': 32.11109082215522,
        '-180': 32.11109082215522,
        '-179': 32.11102951673206,
        '-178': 32.111123166873774,
        '-177': 32.111156642105165,
        '-176': 32.11106989557594,
        '-175': 32.11110209576193,
        '-174': 32.111384584513736,
        '-173': 32.111677335172715,
        '-172': 32.11173849366204,
        '-171': 32.11167945724618,
        '-170': 32.11168986138867,
        '-169': 32.111688402820214,
        '-168': 32.11157036343701,
        '-167': 32.1115424650556,
        '-166': 32.11181362081426,
        '-165': 32.11214983161727,
        '-164': 32.1121792473952,
        '-163': 32.11201049970662,
        '-162': 32.112073529670525,
        '-161': 32.11240041131389,
        '-160': 32.11256133092808,
        '-159': 32.11235088904786,
        '-158': 32.11204453630993,
        '-157': 32.11185266286041,
        '-156': 32.11160624236815,
        '-155': 32.11121561834072,
        '-154': 32.11100364648642,
        '-153': 32.11123520859978,
        '-152': 32.11162860880243,
        '-151': 32.111719694674385,
        '-150': 32.111511750269194,
        '-149': 32.11138278761347,
        '-148': 32.111467338692734,
        '-147': 32.111520597623816,
        '-146': 32.11138355503405,
        '-145': 32.1112234574627,
        '-144': 32.111221107391245,
        '-143': 32.11129703440915,
        '-142': 32.11129393728519,
        '-141': 32.111237809747614,
        '-140': 32.11124954938365,
        '-139': 32.111313177425494,
        '-138': 32.111303069911415,
        '-137': 32.1111982963145,
        '-136': 32.111119546256916,
        '-135': 32.1111421899389,
        '-134': 32.11119377040992,
        '-133': 32.1111855850231,
        '-132': 32.11115041352597,
        '-131': 32.111163670498726,
        '-130': 32.11118887924742,
        '-129': 32.1111202622216,
        '-128': 32.11096871259172,
        '-127': 32.1108762861783,
        '-126': 32.11090558413311,
        '-125': 32.1109355518082,
        '-124': 32.11084904006798,
        '-123': 32.11072304770478,
        '-122': 32.11071383944491,
        '-121': 32.11081515961069,
        '-120': 32.11086408829366,
        '-119': 32.110773135044575,
        '-118': 32.11064319026011,
        '-117': 32.110628818166596,
        '-116': 32.110760051541895,
        '-115': 32.11091227050963,
        '-114': 32.110933974980824,
        '-113': 32.11083360411257,
        '-112': 32.11082629481713,
        '-111': 32.11107912835251,
        '-110': 32.11139604094812,
        '-109': 32.11136407631547,
        '-108': 32.110935154845116,
        '-107': 32.11063637131799,
        '-106': 32.11095820156022,
        '-105': 32.10888434836805,
        '-104': 32.097054234447306,
        '-103': 32.090357942779306,
        '-102': 32.09168274643932,
        '-101': 32.08840362357171,
        '-100': 32.102148028942686,
        '-99': 32.11096514668781,
        '-98': 32.11178695255221,
        '-97': 32.111255995667065,
        '-96': 32.11055692055196,
        '-95': 32.110077942655494,
        '-94': 32.11018415342294,
        '-93': 32.11080111776397,
        '-92': 32.11140860645139,
        '-91': 32.11171054939986,
        '-90': 32.1119798117901,
        '-89': 32.11246061389965,
        '-88': 32.112787409009805,
        '-87': 32.112460766514104,
        '-86': 32.11169881800582,
        '-85': 32.11126296919136,
        '-84': 32.11136335520617,
        '-83': 32.11178111352898,
        '-82': 32.1117528242635,
        '-81': 32.11156770951628,
        '-80': 32.10492080871765,
        '-79': 32.11063164003575,
        '-78': 32.11262212049579,
        '-77': 32.112391408540944,
        '-76': 32.11164372231816,
        '-75': 32.11080040674012,
        '-74': 32.1101814324198,
        '-73': 32.10981580921178,
        '-72': 32.10961270378189,
        '-71': 32.10960987106692,
        '-70': 32.109950994027884,
        '-69': 32.110595440974556,
        '-68': 32.11119537032731,
        '-67': 32.11142459863824,
        '-66': 32.11135912803995,
        '-65': 32.111332419144915,
        '-64': 32.11144859890422,
        '-63': 32.11148099919619,
        '-62': 32.11126917303673,
        '-61': 32.110946700088654,
        '-60': 32.110696625743124,
        '-59': 32.11054749755651,
        '-58': 32.11052499353065,
        '-57': 32.11071764699437,
        '-56': 32.11101658682506,
        '-55': 32.11108296593785,
        '-54': 32.11082446464291,
        '-53': 32.11063510595226,
        '-52': 32.11084920511556,
        '-51': 32.11120251426059,
        '-50': 32.11122794011753,
        '-49': 32.11099668092368,
        '-48': 32.11099421563293,
        '-47': 32.111339756728164,
        '-46': 32.11162608345592,
        '-45': 32.11157301626387,
        '-44': 32.11137502196158,
        '-43': 32.111279803905404,
        '-42': 32.11123922325125,
        '-41': 32.111157443322476,
        '-40': 32.11112638033055,
        '-39': 32.11120393467879,
        '-38': 32.11125649653427,
        '-37': 32.11122681623172,
        '-36': 32.111286781237155,
        '-35': 32.111522761688406,
        '-34': 32.11173689791583,
        '-33': 32.11177662449877,
        '-32': 32.1117910764626,
        '-31': 32.111906541577035,
        '-30': 32.11191072297102,
        '-29': 32.11158039858154,
        '-28': 32.111122015950684,
        '-27': 32.110936960470966,
        '-26': 32.11107807333441,
        '-25': 32.111271774140626,
        '-24': 32.111365596924024,
        '-23': 32.111389802755646,
        '-22': 32.11125609036563,
        '-21': 32.11083362468516,
        '-20': 32.11037464646889,
        '-19': 32.1104321877449,
        '-18': 32.111154650335315,
        '-17': 32.11198233183456,
        '-16': 32.110968208419266,
        '-15': 32.109973979192674,
        '-14': 32.109571350507714,
        '-13': 32.1087771892217,
        '-12': 32.10840476405427,
        '-11': 32.10830004096827,
        '-10': 32.108032374030934,
        '-9': 32.10813328039809,
        '-8': 32.10808536893634,
        '-7': 32.10801301593536,
        '-6': 32.10777489972287,
        '-5': 32.10777136202683,
        '-4': 32.10833158580822,
        '-3': 32.10876692149112,
        '-2': 32.107742222178835,
        '-1': 32.107953345761324
    },
    '23': {
        '0': 32.110385032618,
        '1': 32.1103541786637,
        '2': 32.11002795096671,
        '3': 32.10885366431608,
        '4': 32.10781917502184,
        '5': 32.10463390815229,
        '6': 32.10042706115893,
        '7': 32.10463833184664,
        '8': 32.10241655896207,
        '9': 32.10576052435616,
        '10': 32.106411805834085,
        '11': 32.10481231402577,
        '12': 32.10527019515708,
        '13': 32.10596392331859,
        '14': 32.10627182676765,
        '15': 32.10599852894351,
        '16': 32.107122492553124,
        '17': 32.1082830291697,
        '18': 32.109035864115995,
        '19': 32.10638968558508,
        '20': 32.10703952421854,
        '21': 32.10751923550121,
        '22': 32.10769254146763,
        '23': 32.10775845614437,
        '24': 32.10830832440475,
        '25': 32.107582386819445,
        '26': 32.10564245690084,
        '27': 32.10960569695321,
        '28': 32.11070816653873,
        '29': 32.11105726524387,
        '30': 32.111871889150365,
        '31': 32.1120344356103,
        '32': 32.11018387577764,
        '33': 32.111447807175175,
        '34': 32.10687642929514,
        '35': 32.10964819704782,
        '36': 32.11371247018562,
        '37': 32.113166910759894,
        '38': 32.113093358961585,
        '39': 32.11261994230116,
        '40': 32.0999872877614,
        '41': 32.104902529202654,
        '42': 32.105268662109516,
        '43': 32.10405996458976,
        '44': 32.10491907692377,
        '45': 32.10751391992474,
        '46': 32.108052285211414,
        '47': 32.10726514761428,
        '48': 32.1090517818436,
        '49': 32.11027032876793,
        '50': 32.110698688771514,
        '51': 32.111226066495504,
        '52': 32.11195871580782,
        '53': 32.11150914634518,
        '54': 32.11172631091992,
        '55': 32.111942939028836,
        '56': 32.111462738458265,
        '57': 32.10802110642202,
        '58': 32.10634532934441,
        '59': 32.1035204565684,
        '60': 32.113036806378645,
        '61': 32.11259397313619,
        '62': 32.11275814966093,
        '63': 32.11292153237084,
        '64': 32.112806255124475,
        '65': 32.11280584175491,
        '66': 32.113236197818814,
        '67': 32.11382491960062,
        '68': 32.11416517023897,
        '69': 32.11408726643304,
        '70': 32.113749088816476,
        '71': 32.113334764310395,
        '72': 32.11299622820923,
        '73': 32.11218952029298,
        '74': 32.110459791355616,
        '75': 32.10950963901178,
        '76': 32.10876422898869,
        '77': 32.10851479247515,
        '78': 32.10970956694716,
        '79': 32.11045172676925,
        '80': 32.10950888828904,
        '81': 32.106322351948485,
        '82': 32.107632876703924,
        '83': 32.10756049678284,
        '84': 32.10505564216378,
        '85': 32.109044659154314,
        '86': 32.11222970522284,
        '87': 32.11303330376829,
        '88': 32.11354560261187,
        '89': 32.11343268981976,
        '90': 32.1131623665373,
        '91': 32.1130049062101,
        '92': 32.11260154885318,
        '93': 32.101337818390185,
        '94': 32.10987856232411,
        '95': 32.109119242029465,
        '96': 32.11105031755277,
        '97': 32.10398250609963,
        '98': 32.10417280666599,
        '99': 32.10234880801304,
        '100': 32.09533166287342,
        '101': 32.098556457275166,
        '102': 32.0969816141549,
        '103': 32.0902525451306,
        '104': 32.09696326081345,
        '105': 32.1026458465258,
        '106': 32.105904279695594,
        '107': 32.10773747882779,
        '108': 32.11200582259001,
        '109': 32.11153425680619,
        '110': 32.112299423902336,
        '111': 32.1109114800629,
        '112': 32.111119302249115,
        '113': 32.11317735939082,
        '114': 32.11321743580403,
        '115': 32.109416025710956,
        '116': 32.11215964290499,
        '117': 32.11356586760596,
        '118': 32.11389837510559,
        '119': 32.11442286466151,
        '120': 32.11481154788821,
        '121': 32.09220527886339,
        '122': 32.11328347787546,
        '123': 32.11210332837126,
        '124': 32.11190031177795,
        '125': 32.11260391638884,
        '126': 32.11330456681794,
        '127': 32.11353376910424,
        '128': 32.113674496141364,
        '129': 32.1140476413822,
        '130': 32.114321310591755,
        '131': 32.11413745569913,
        '132': 32.11375330491986,
        '133': 32.11363717580589,
        '134': 32.11379377486157,
        '135': 32.11388063831647,
        '136': 32.11375803201638,
        '137': 32.11361444067493,
        '138': 32.113714496862364,
        '139': 32.1142116797303,
        '140': 32.114982476842016,
        '141': 32.11546775688481,
        '142': 32.11497710979377,
        '143': 32.11346005762722,
        '144': 32.11181447772663,
        '145': 32.111151569163255,
        '146': 32.1117977377767,
        '147': 32.11312554458797,
        '148': 32.11420743155714,
        '149': 32.114508100319284,
        '150': 32.11414213986446,
        '151': 32.11367419942459,
        '152': 32.113584536933175,
        '153': 32.11381569467103,
        '154': 32.1139401475592,
        '155': 32.113779479150054,
        '156': 32.113601303322014,
        '157': 32.11362411140617,
        '158': 32.11366498046076,
        '159': 32.11350140885698,
        '160': 32.11328578732264,
        '161': 32.113284227733004,
        '162': 32.113413242426574,
        '163': 32.11338347318531,
        '164': 32.113176771709256,
        '165': 32.11304098271725,
        '166': 32.113078151139796,
        '167': 32.113153151861695,
        '168': 32.11317897128443,
        '169': 32.11320032703061,
        '170': 32.11320680460147,
        '171': 32.11313974331845,
        '172': 32.11308425078643,
        '173': 32.11318644514167,
        '174': 32.11337184575037,
        '175': 32.11341065975954,
        '176': 32.113274043785104,
        '177': 32.11315301148136,
        '178': 32.113113672885035,
        '179': 32.11304269414222,
        '180': 32.1129712646637,
        '-180': 32.1129712646637,
        '-179': 32.11309938158182,
        '-178': 32.11339275775345,
        '-177': 32.11351820555136,
        '-176': 32.11335706724965,
        '-175': 32.11323480875796,
        '-174': 32.11339973043489,
        '-173': 32.11360444504846,
        '-172': 32.11352547789063,
        '-171': 32.113317086226495,
        '-170': 32.11335584285414,
        '-169': 32.113633548838635,
        '-168': 32.11383546122011,
        '-167': 32.11390943614623,
        '-166': 32.11410138045929,
        '-165': 32.1144339651181,
        '-164': 32.11459678293733,
        '-163': 32.11445441112971,
        '-162': 32.114277045737765,
        '-161': 32.114296081309234,
        '-160': 32.11434489201789,
        '-159': 32.11415566926769,
        '-158': 32.11378167899993,
        '-157': 32.11350205734991,
        '-156': 32.11346249783864,
        '-155': 32.11360109758416,
        '-154': 32.113801900056814,
        '-153': 32.1139412334281,
        '-152': 32.11388965783741,
        '-151': 32.11365389239467,
        '-150': 32.11344918961173,
        '-149': 32.11346409240064,
        '-148': 32.11360260628564,
        '-147': 32.11361638224964,
        '-146': 32.11345442260333,
        '-145': 32.11331387612585,
        '-144': 32.113343248414644,
        '-143': 32.113457485895424,
        '-142': 32.11349813931478,
        '-141': 32.113449263281595,
        '-140': 32.11340747379989,
        '-139': 32.11341284925492,
        '-138': 32.11340924465764,
        '-137': 32.11335566721671,
        '-136': 32.113293997399325,
        '-135': 32.11328017063423,
        '-134': 32.11329884955222,
        '-133': 32.1132916648163,
        '-132': 32.113247795944204,
        '-131': 32.11321387621996,
        '-130': 32.11321473151395,
        '-129': 32.11321968235796,
        '-128': 32.113202829508744,
        '-127': 32.11317846558528,
        '-126': 32.113147009755075,
        '-125': 32.11306939716485,
        '-124': 32.11294554537701,
        '-123': 32.11285713643839,
        '-122': 32.11286119783248,
        '-121': 32.11289107075262,
        '-120': 32.11284992838702,
        '-119': 32.11276896190471,
        '-118': 32.11277333172802,
        '-117': 32.11289519775272,
        '-116': 32.11301287660892,
        '-115': 32.113004036673104,
        '-114': 32.11290679913697,
        '-113': 32.1128944316219,
        '-112': 32.11308336321368,
        '-111': 32.11335593952525,
        '-110': 32.109876193997,
        '-109': 32.113158862771286,
        '-108': 32.11287092939531,
        '-107': 32.1130156515341,
        '-106': 32.11321171860297,
        '-105': 32.10617114568225,
        '-104': 32.09537197015098,
        '-103': 32.08892781525168,
        '-102': 32.093444785254775,
        '-101': 32.09639371198217,
        '-100': 32.10023080583413,
        '-99': 32.11331293386651,
        '-98': 32.109246622236846,
        '-97': 32.11295669777301,
        '-96': 32.112360055516746,
        '-95': 32.111914822667124,
        '-94': 32.11195674686772,
        '-93': 32.11245490352051,
        '-92': 32.11289464443496,
        '-91': 32.11302940948992,
        '-90': 32.1133011792196,
        '-89': 32.11406216373826,
        '-88': 32.11476811192567,
        '-87': 32.11459167530939,
        '-86': 32.113674888141034,
        '-85': 32.11306177297847,
        '-84': 32.113308640892505,
        '-83': 32.11372029679131,
        '-82': 32.112552762525944,
        '-81': 32.113391675647144,
        '-80': 32.113487837363856,
        '-79': 32.114090457018534,
        '-78': 32.11458508181478,
        '-77': 32.11440546423828,
        '-76': 32.1137755506241,
        '-75': 32.11331541681084,
        '-74': 32.11324547140499,
        '-73': 32.11323921848228,
        '-72': 32.11297807705548,
        '-71': 32.11260273034304,
        '-70': 32.11251106311686,
        '-69': 32.11282405450188,
        '-68': 32.11322887929274,
        '-67': 32.11337696477826,
        '-66': 32.11327059651528,
        '-65': 32.11312938545005,
        '-64': 32.1130073775308,
        '-63': 32.11276434945247,
        '-62': 32.11238022704928,
        '-61': 32.11205858409384,
        '-60': 32.11197074099994,
        '-59': 32.11209124902696,
        '-58': 32.112332095892974,
        '-57': 32.11263110997238,
        '-56': 32.11284981905472,
        '-55': 32.11283316197405,
        '-54': 32.11268604599559,
        '-53': 32.11273434443279,
        '-52': 32.113045786031954,
        '-51': 32.113255763139406,
        '-50': 32.113104162413926,
        '-49': 32.112885470761235,
        '-48': 32.11303644006183,
        '-47': 32.11347365204032,
        '-46': 32.11373277600179,
        '-45': 32.11365297017101,
        '-44': 32.113503728214624,
        '-43': 32.11346316370723,
        '-42': 32.11340086912102,
        '-41': 32.113242469693695,
        '-40': 32.11315022161754,
        '-39': 32.11320448947252,
        '-38': 32.11324455153283,
        '-37': 32.11318649422274,
        '-36': 32.11319773302997,
        '-35': 32.11338039768468,
        '-34': 32.11356187741627,
        '-33': 32.113594806445235,
        '-32': 32.11359921981799,
        '-31': 32.11368315017937,
        '-30': 32.113674689145235,
        '-29': 32.11340708401939,
        '-28': 32.11307207269329,
        '-27': 32.112990903175664,
        '-26': 32.113168609578565,
        '-25': 32.11335584231167,
        '-24': 32.113433219116835,
        '-23': 32.11344043973457,
        '-22': 32.11332895405934,
        '-21': 32.11303390242959,
        '-20': 32.112771598457265,
        '-19': 32.11291777975326,
        '-18': 32.11351852540262,
        '-17': 32.11420002117369,
        '-16': 32.113949479897286,
        '-15': 32.11262952757361,
        '-14': 32.112106389926886,
        '-13': 32.111503663032245,
        '-12': 32.11057074129002,
        '-11': 32.11123023964555,
        '-10': 32.11101551386761,
        '-9': 32.11091273880807,
        '-8': 32.11048174093621,
        '-7': 32.10987435847996,
        '-6': 32.10996428097524,
        '-5': 32.110063740591826,
        '-4': 32.109813372398484,
        '-3': 32.10992333491609,
        '-2': 32.109297167106654,
        '-1': 32.11003240841024
    },
    '24': {
        '0': 32.11266111663069,
        '1': 32.11248916815202,
        '2': 32.1115067463587,
        '3': 32.1108616930323,
        '4': 32.110185296693174,
        '5': 32.107244381273574,
        '6': 32.10562296243124,
        '7': 32.10036022064215,
        '8': 32.104645413416776,
        '9': 32.10793162868724,
        '10': 32.10547002664065,
        '11': 32.106842483781286,
        '12': 32.106616031015584,
        '13': 32.108404017586984,
        '14': 32.109779404655924,
        '15': 32.10977287300614,
        '16': 32.10905166317048,
        '17': 32.111142370349086,
        '18': 32.11177781815051,
        '19': 32.111766781270234,
        '20': 32.1105110850366,
        '21': 32.111623710996135,
        '22': 32.11102245354592,
        '23': 32.11096731419322,
        '24': 32.11085028405605,
        '25': 32.11027594775996,
        '26': 32.10737314313931,
        '27': 32.10940007742683,
        '28': 32.11213958229791,
        '29': 32.11211247659581,
        '30': 32.113267027295784,
        '31': 32.112916013592105,
        '32': 32.11147134532518,
        '33': 32.113821566457894,
        '34': 32.11306474899458,
        '35': 32.110073117781326,
        '36': 32.1157635396955,
        '37': 32.11550906536806,
        '38': 32.115767520528905,
        '39': 32.10978817131135,
        '40': 32.10698900935479,
        '41': 32.10756120252429,
        '42': 32.10655999005979,
        '43': 32.10599444444087,
        '44': 32.10699418779359,
        '45': 32.1074357100443,
        '46': 32.10992085791756,
        '47': 32.11137029311025,
        '48': 32.11062417228278,
        '49': 32.11195018862623,
        '50': 32.11253305425267,
        '51': 32.11425467009874,
        '52': 32.11476599631815,
        '53': 32.114605501907064,
        '54': 32.1149393099959,
        '55': 32.11406608905253,
        '56': 32.1120666447875,
        '57': 32.11642580796849,
        '58': 32.11658467724995,
        '59': 32.115578110106384,
        '60': 32.11459402661397,
        '61': 32.11441153454199,
        '62': 32.11472284274613,
        '63': 32.114775276786965,
        '64': 32.11454633040757,
        '65': 32.11466526367519,
        '66': 32.11532352155947,
        '67': 32.115937888876914,
        '68': 32.11598932618233,
        '69': 32.115753183858615,
        '70': 32.115600965005775,
        '71': 32.11565610593221,
        '72': 32.11496508753457,
        '73': 32.11386604442028,
        '74': 32.11396670471124,
        '75': 32.111646373752464,
        '76': 32.11153704669395,
        '77': 32.111435205672386,
        '78': 32.1113053726772,
        '79': 32.11064480081825,
        '80': 32.11148812845383,
        '81': 32.112135528281,
        '82': 32.111366412300534,
        '83': 32.11260823444119,
        '84': 32.11424119179864,
        '85': 32.11133835851978,
        '86': 32.11340867821145,
        '87': 32.114097866985304,
        '88': 32.11557661089123,
        '89': 32.115427171589204,
        '90': 32.115238769628526,
        '91': 32.11524441341437,
        '92': 32.114803674948554,
        '93': 32.111957142551546,
        '94': 32.104193593276094,
        '95': 32.111550436064356,
        '96': 32.11329857382671,
        '97': 32.11407781312172,
        '98': 32.10413728860913,
        '99': 32.095749953128895,
        '100': 32.09443483014922,
        '101': 32.09516172738287,
        '102': 32.09641894069539,
        '103': 32.097881234431256,
        '104': 32.095886104280844,
        '105': 32.102737825474826,
        '106': 32.110249751628785,
        '107': 32.109109463654306,
        '108': 32.11241266102297,
        '109': 32.11322640205693,
        '110': 32.1120016130178,
        '111': 32.11145176193656,
        '112': 32.11444137793853,
        '113': 32.11211960287328,
        '114': 32.107487050091024,
        '115': 32.11285128469365,
        '116': 32.114044592421315,
        '117': 32.11414658373757,
        '118': 32.11579119355797,
        '119': 32.11637638111164,
        '120': 32.11676080108241,
        '121': 32.10664485278802,
        '122': 32.11552624705853,
        '123': 32.114710533178865,
        '124': 32.114633246008665,
        '125': 32.11506284374386,
        '126': 32.11526790767187,
        '127': 32.11508364668715,
        '128': 32.11513271523921,
        '129': 32.115845280126294,
        '130': 32.116729210392464,
        '131': 32.116953522766295,
        '132': 32.11638295145131,
        '133': 32.115676599810826,
        '134': 32.11547122635075,
        '135': 32.11575929638311,
        '136': 32.11608125034231,
        '137': 32.11615005345479,
        '138': 32.11618688208343,
        '139': 32.116586236659096,
        '140': 32.117229367893565,
        '141': 32.11733978550659,
        '142': 32.11633523551254,
        '143': 32.114762548466764,
        '144': 32.113952823232,
        '145': 32.11458527064057,
        '146': 32.11593511705861,
        '147': 32.116766699373585,
        '148': 32.116661250315495,
        '149': 32.11616695939054,
        '150': 32.11590680749471,
        '151': 32.11596378453924,
        '152': 32.11607501629667,
        '153': 32.11604189849723,
        '154': 32.11585396241711,
        '155': 32.115628517520186,
        '156': 32.1155180880718,
        '157': 32.11557008776796,
        '158': 32.1156639661457,
        '159': 32.115672146874566,
        '160': 32.115635359204326,
        '161': 32.115661149187375,
        '162': 32.115707813716426,
        '163': 32.115623263309914,
        '164': 32.11537811437118,
        '165': 32.115123608736376,
        '166': 32.115020257606254,
        '167': 32.11510307451287,
        '168': 32.115282134945,
        '169': 32.11538926933117,
        '170': 32.11529545308546,
        '171': 32.1150886682469,
        '172': 32.11503697571212,
        '173': 32.11523608751732,
        '174': 32.11541680837065,
        '175': 32.11530118195555,
        '176': 32.11502820005636,
        '177': 32.11495443538444,
        '178': 32.115116566780806,
        '179': 32.115230900708035,
        '180': 32.11521077937668,
        '-180': 32.11521077937668,
        '-179': 32.115291810305905,
        '-178': 32.11555197166861,
        '-177': 32.115707896412744,
        '-176': 32.11559507368662,
        '-175': 32.11549159447716,
        '-174': 32.11566564321209,
        '-173': 32.11590018306861,
        '-172': 32.11585122996429,
        '-171': 32.115669521337736,
        '-170': 32.115813509610774,
        '-169': 32.116317451784205,
        '-168': 32.11670166176076,
        '-167': 32.116665833719104,
        '-166': 32.11646045697247,
        '-165': 32.116416658475835,
        '-164': 32.11645930104765,
        '-163': 32.11633430828814,
        '-162': 32.11607251286208,
        '-161': 32.11593071056419,
        '-160': 32.11598941166874,
        '-159': 32.11608580082944,
        '-158': 32.11612413074385,
        '-157': 32.11621736612246,
        '-156': 32.116459227435506,
        '-155': 32.11670980069876,
        '-154': 32.11671055566192,
        '-153': 32.11636715003025,
        '-152': 32.115863382307744,
        '-151': 32.115504149375916,
        '-150': 32.11545647102973,
        '-149': 32.1156274464526,
        '-148': 32.11578272525372,
        '-147': 32.11577793892548,
        '-146': 32.11566528629205,
        '-145': 32.11558953393817,
        '-144': 32.11562302390967,
        '-143': 32.115716520761794,
        '-142': 32.1157749010477,
        '-141': 32.11575102140405,
        '-140': 32.11567662039918,
        '-139': 32.11561616061263,
        '-138': 32.115590016877356,
        '-137': 32.11555643771288,
        '-136': 32.115485402708714,
        '-135': 32.11542100827429,
        '-134': 32.115423010744244,
        '-133': 32.11546300021855,
        '-132': 32.11544513806638,
        '-131': 32.11534084389167,
        '-130': 32.11524035281968,
        '-129': 32.11523707527726,
        '-128': 32.11530347739776,
        '-127': 32.115324245301395,
        '-126': 32.11523639108067,
        '-125': 32.11510156763543,
        '-124': 32.115031858859346,
        '-123': 32.11505608471853,
        '-122': 32.1150815597761,
        '-121': 32.11501369783658,
        '-120': 32.11490172994706,
        '-119': 32.114902974516546,
        '-118': 32.1150702271777,
        '-117': 32.1152396389579,
        '-116': 32.115210837009506,
        '-115': 32.115016704564894,
        '-114': 32.114923057817734,
        '-113': 32.11512407065579,
        '-112': 32.11548259460674,
        '-111': 32.11563384630668,
        '-110': 32.11505234719233,
        '-109': 32.11501068955869,
        '-108': 32.11502971292842,
        '-107': 32.11557230621784,
        '-106': 32.10555761171293,
        '-105': 32.091882344122176,
        '-104': 32.09669373935895,
        '-103': 32.09686221036925,
        '-102': 32.0978464136211,
        '-101': 32.09894075032386,
        '-100': 32.099198024021256,
        '-99': 32.11468544348751,
        '-98': 32.114932120247836,
        '-97': 32.115278223437734,
        '-96': 32.11460457879803,
        '-95': 32.114054473510635,
        '-94': 32.11410455650421,
        '-93': 32.11464758312778,
        '-92': 32.114941872220214,
        '-91': 32.114698858695746,
        '-90': 32.11461148549401,
        '-89': 32.115278657554015,
        '-88': 32.11610695014893,
        '-87': 32.11603850532536,
        '-86': 32.1151359446089,
        '-85': 32.11459046346955,
        '-84': 32.11507004180352,
        '-83': 32.11585626641329,
        '-82': 32.11598180154669,
        '-81': 32.115577839428404,
        '-80': 32.11549699732225,
        '-79': 32.115966202924994,
        '-78': 32.11631208296727,
        '-77': 32.11600205212466,
        '-76': 32.115368267971476,
        '-75': 32.115077210621834,
        '-74': 32.11525082060708,
        '-73': 32.115424141963466,
        '-72': 32.115217205381335,
        '-71': 32.114755713117326,
        '-70': 32.11441741671117,
        '-69': 32.11437205210169,
        '-68': 32.11448240268214,
        '-67': 32.114556848925936,
        '-66': 32.114543949553976,
        '-65': 32.11446969206302,
        '-64': 32.11433167054253,
        '-63': 32.114161663507225,
        '-62': 32.11410280672819,
        '-61': 32.11427181535741,
        '-60': 32.1145664095211,
        '-59': 32.11475480475775,
        '-58': 32.11475992635393,
        '-57': 32.11471306266714,
        '-56': 32.11472233641904,
        '-55': 32.114762620438654,
        '-54': 32.114825586144356,
        '-53': 32.11497171453886,
        '-52': 32.11514963306599,
        '-51': 32.11517962259174,
        '-50': 32.11505666822321,
        '-49': 32.11506312736838,
        '-48': 32.115384227551736,
        '-47': 32.11578924295493,
        '-46': 32.115932768830795,
        '-45': 32.11582907884243,
        '-44': 32.11575391336018,
        '-43': 32.11578267827062,
        '-42': 32.115743428641714,
        '-41': 32.115589843329495,
        '-40': 32.11549041493315,
        '-39': 32.115508237308454,
        '-38': 32.115493155674976,
        '-37': 32.11537619738713,
        '-36': 32.1153035391593,
        '-35': 32.11536935215804,
        '-34': 32.115457463122915,
        '-33': 32.11547228970873,
        '-32': 32.115499860363116,
        '-31': 32.115596578490724,
        '-30': 32.11563355572563,
        '-29': 32.11552221524363,
        '-28': 32.115405106727536,
        '-27': 32.11543354005933,
        '-26': 32.11551967707346,
        '-25': 32.11551067779565,
        '-24': 32.11546583941402,
        '-23': 32.11554247040386,
        '-22': 32.11571719520849,
        '-21': 32.11583984357747,
        '-20': 32.11587853330435,
        '-19': 32.115905378635674,
        '-18': 32.11593385801247,
        '-17': 32.11599367722148,
        '-16': 32.1162545085459,
        '-15': 32.11472959204212,
        '-14': 32.11387989928371,
        '-13': 32.11452838561965,
        '-12': 32.11400250283572,
        '-11': 32.113736429477235,
        '-10': 32.113436147541165,
        '-9': 32.11328069977844,
        '-8': 32.111887775594276,
        '-7': 32.11202195521564,
        '-6': 32.111929083630905,
        '-5': 32.11186455333055,
        '-4': 32.112307545718465,
        '-3': 32.11229244911203,
        '-2': 32.11182231664454,
        '-1': 32.11231718615045
    },
    '25': {
        '0': 32.11466406303752,
        '1': 32.11452708944245,
        '2': 32.114852253141045,
        '3': 32.11359167555103,
        '4': 32.11022025490179,
        '5': 32.10994547257962,
        '6': 32.10903164426143,
        '7': 32.1082662490213,
        '8': 32.102358974721696,
        '9': 32.104336755364955,
        '10': 32.11004597956582,
        '11': 32.111122754877066,
        '12': 32.10986953748395,
        '13': 32.11066318538293,
        '14': 32.111808551355644,
        '15': 32.112051279551,
        '16': 32.11325902592496,
        '17': 32.11434763273861,
        '18': 32.11465779802642,
        '19': 32.114441418391365,
        '20': 32.11436685763413,
        '21': 32.11489820723359,
        '22': 32.1151249265103,
        '23': 32.113128974418544,
        '24': 32.11272508039311,
        '25': 32.11301006511937,
        '26': 32.112958059767756,
        '27': 32.112740738397896,
        '28': 32.11447387778414,
        '29': 32.114427225162345,
        '30': 32.115500097776284,
        '31': 32.11410464415015,
        '32': 32.11341749667311,
        '33': 32.11738589246341,
        '34': 32.11360705246486,
        '35': 32.118171531970134,
        '36': 32.1178792305409,
        '37': 32.118043099301694,
        '38': 32.11048975869597,
        '39': 32.11446793752623,
        '40': 32.10823062607226,
        '41': 32.10773636391156,
        '42': 32.10982312909707,
        '43': 32.10989503022211,
        '44': 32.11083412686231,
        '45': 32.11105582365236,
        '46': 32.110112034320366,
        '47': 32.11175913100758,
        '48': 32.11345082626957,
        '49': 32.11416183450112,
        '50': 32.115364957253206,
        '51': 32.11631515018198,
        '52': 32.11710768098947,
        '53': 32.11702883051109,
        '54': 32.116696690116,
        '55': 32.116763292808905,
        '56': 32.114497076091766,
        '57': 32.11852637266943,
        '58': 32.11872525009225,
        '59': 32.1181556805483,
        '60': 32.11765519158531,
        '61': 32.117741869960014,
        '62': 32.118008339702016,
        '63': 32.117899593121166,
        '64': 32.1175751608619,
        '65': 32.11754285763441,
        '66': 32.11777195525651,
        '67': 32.117237647593626,
        '68': 32.11709245935984,
        '69': 32.11717056434675,
        '70': 32.116907825063386,
        '71': 32.117609177691826,
        '72': 32.117991503026396,
        '73': 32.11553565971167,
        '74': 32.11325006647061,
        '75': 32.11397757930451,
        '76': 32.11483455125872,
        '77': 32.113214438313996,
        '78': 32.11265664737013,
        '79': 32.11481091509579,
        '80': 32.11599844982693,
        '81': 32.11535924868489,
        '82': 32.11640179900171,
        '83': 32.11582533936241,
        '84': 32.11588085944944,
        '85': 32.11548697039015,
        '86': 32.11543747669975,
        '87': 32.11540997323534,
        '88': 32.11594135690481,
        '89': 32.11606313562212,
        '90': 32.116275154754064,
        '91': 32.11699743653925,
        '92': 32.11744363455789,
        '93': 32.11298347365791,
        '94': 32.10797561038529,
        '95': 32.11538713314428,
        '96': 32.11394487594844,
        '97': 32.11603238258807,
        '98': 32.094751585733576,
        '99': 32.10146649979892,
        '100': 32.10155698676424,
        '101': 32.09310883808229,
        '102': 32.1025105759396,
        '103': 32.09523861517647,
        '104': 32.09761970312477,
        '105': 32.107094723437065,
        '106': 32.113076178002196,
        '107': 32.11180928347551,
        '108': 32.11277146128499,
        '109': 32.115957930597055,
        '110': 32.115896395749054,
        '111': 32.107280985926785,
        '112': 32.107808979303975,
        '113': 32.10799420325108,
        '114': 32.116566024417324,
        '115': 32.11268853520318,
        '116': 32.11415616872949,
        '117': 32.1131836799095,
        '118': 32.11538060511555,
        '119': 32.11839014534674,
        '120': 32.118594430780945,
        '121': 32.11850701154222,
        '122': 32.11829658485507,
        '123': 32.118234820927874,
        '124': 32.118410909164986,
        '125': 32.118537943459685,
        '126': 32.118207035914686,
        '127': 32.11745852779574,
        '128': 32.11694015628089,
        '129': 32.11726595243138,
        '130': 32.11825463477785,
        '131': 32.11902164106012,
        '132': 32.11889673906045,
        '133': 32.11812398017353,
        '134': 32.117552264421256,
        '135': 32.11769504473132,
        '136': 32.11825080733876,
        '137': 32.118615308036254,
        '138': 32.118683876456025,
        '139': 32.11884346772429,
        '140': 32.11916923652368,
        '141': 32.11908778910055,
        '142': 32.11821432151375,
        '143': 32.11722844711678,
        '144': 32.11729219137296,
        '145': 32.11853707347277,
        '146': 32.11970897762331,
        '147': 32.119619311438754,
        '148': 32.11852723865584,
        '149': 32.11767402125804,
        '150': 32.11773649442454,
        '151': 32.11824741979903,
        '152': 32.118447474538605,
        '153': 32.11817977354103,
        '154': 32.11780161730857,
        '155': 32.117600192333015,
        '156': 32.11756946346484,
        '157': 32.117594749280244,
        '158': 32.11761564792746,
        '159': 32.11762714570019,
        '160': 32.117646954447174,
        '161': 32.117691170683585,
        '162': 32.1177372323527,
        '163': 32.11772511580358,
        '164': 32.11761960232451,
        '165': 32.117458312058524,
        '166': 32.11733348997918,
        '167': 32.11732820123466,
        '168': 32.11743555957218,
        '169': 32.117528507645424,
        '170': 32.11747999941287,
        '171': 32.117347475976764,
        '172': 32.11733139829869,
        '173': 32.11747543294711,
        '174': 32.11754826954367,
        '175': 32.11737695166765,
        '176': 32.11716494023404,
        '177': 32.11723731609793,
        '178': 32.11754177711888,
        '179': 32.11770198242851,
        '180': 32.11757936776264,
        '-180': 32.11757936776264,
        '-179': 32.11745812177871,
        '-178': 32.117567188902676,
        '-177': 32.11774268055989,
        '-176': 32.11777519394568,
        '-175': 32.11780776972887,
        '-174': 32.11807517247434,
        '-173': 32.11844095533667,
        '-172': 32.11857342883561,
        '-171': 32.11850722248584,
        '-170': 32.11862814216415,
        '-169': 32.11904231753859,
        '-168': 32.11933035567777,
        '-167': 32.11910931571071,
        '-166': 32.11856333521617,
        '-165': 32.11817355032979,
        '-164': 32.11810104031679,
        '-163': 32.118111188538414,
        '-162': 32.118025177017884,
        '-161': 32.11795871291403,
        '-160': 32.11807579226587,
        '-159': 32.11833433563096,
        '-158': 32.11858689579465,
        '-157': 32.11878177071118,
        '-156': 32.11892154194423,
        '-155': 32.11891059051321,
        '-154': 32.11862039464929,
        '-153': 32.11812182376963,
        '-152': 32.11771348416999,
        '-151': 32.11763545134935,
        '-150': 32.1178194103431,
        '-149': 32.118000826030084,
        '-148': 32.11803203886725,
        '-147': 32.1179872236741,
        '-146': 32.11798020886463,
        '-145': 32.11800841382666,
        '-144': 32.11802072801738,
        '-143': 32.1180282058498,
        '-142': 32.1180577332282,
        '-141': 32.11806629825928,
        '-140': 32.11800186605019,
        '-139': 32.11790700510049,
        '-138': 32.117859878667446,
        '-137': 32.11783973641368,
        '-136': 32.11776312267655,
        '-135': 32.11764608571763,
        '-134': 32.11760457598921,
        '-133': 32.117663821594014,
        '-132': 32.117688933146226,
        '-131': 32.11757554048852,
        '-130': 32.11741347960607,
        '-129': 32.11736101049373,
        '-128': 32.11741437630932,
        '-127': 32.11741949885534,
        '-126': 32.11730458863889,
        '-125': 32.11718790945633,
        '-124': 32.11720677906874,
        '-123': 32.11731631478943,
        '-122': 32.11734510814998,
        '-121': 32.117236167302565,
        '-120': 32.11714143841391,
        '-119': 32.11722380967939,
        '-118': 32.11741636143761,
        '-117': 32.11747491317957,
        '-116': 32.117292999780496,
        '-115': 32.11708354294611,
        '-114': 32.117161424147234,
        '-113': 32.117552964914516,
        '-112': 32.11775094756149,
        '-111': 32.11280277160264,
        '-110': 32.117418491443836,
        '-109': 32.117164216144765,
        '-108': 32.11751036969902,
        '-107': 32.11343242163507,
        '-106': 32.0934580246361,
        '-105': 32.098818754446754,
        '-104': 32.09961520855403,
        '-103': 32.1029840642529,
        '-102': 32.10265106466479,
        '-101': 32.09905845302849,
        '-100': 32.10428207577022,
        '-99': 32.11679493907942,
        '-98': 32.11823312249046,
        '-97': 32.118023140613005,
        '-96': 32.1172230523758,
        '-95': 32.11636687019095,
        '-94': 32.11626806310427,
        '-93': 32.11688940768786,
        '-92': 32.117275670646485,
        '-91': 32.11693822678358,
        '-90': 32.116582086544554,
        '-89': 32.1169684816584,
        '-88': 32.11766544105282,
        '-87': 32.1176902688353,
        '-86': 32.11706291421888,
        '-85': 32.11682276806315,
        '-84': 32.11747607589286,
        '-83': 32.118276332219885,
        '-82': 32.11837587971197,
        '-81': 32.11800402778466,
        '-80': 32.11794517847567,
        '-79': 32.11828265486467,
        '-78': 32.11824885132879,
        '-77': 32.117753897504805,
        '-76': 32.11698602196945,
        '-75': 32.11664073616905,
        '-74': 32.116718421920226,
        '-73': 32.116788923581474,
        '-72': 32.116626807943184,
        '-71': 32.116392653959124,
        '-70': 32.11630623480367,
        '-69': 32.11639612808775,
        '-68': 32.11657285505463,
        '-67': 32.11676238001887,
        '-66': 32.116898024880726,
        '-65': 32.11689558091352,
        '-64': 32.116748775624544,
        '-63': 32.11661989925178,
        '-62': 32.11672193163935,
        '-61': 32.11706120937755,
        '-60': 32.117368582604215,
        '-59': 32.11737130160458,
        '-58': 32.1171053196908,
        '-57': 32.11686938640508,
        '-56': 32.116881043572086,
        '-55': 32.11707982101424,
        '-54': 32.117275680141034,
        '-53': 32.11736670415747,
        '-52': 32.117364871223806,
        '-51': 32.11733088164297,
        '-50': 32.117365640139525,
        '-49': 32.11756348168534,
        '-48': 32.117876621323525,
        '-47': 32.118109551392536,
        '-46': 32.11814352482876,
        '-45': 32.11808302808879,
        '-44': 32.11808010268653,
        '-43': 32.118113397013,
        '-42': 32.11807647862304,
        '-41': 32.11799336726581,
        '-40': 32.117971580226154,
        '-39': 32.117994125422065,
        '-38': 32.11794250165926,
        '-37': 32.117807117046524,
        '-36': 32.11769799867485,
        '-35': 32.11765132311189,
        '-34': 32.11760331013368,
        '-33': 32.11756398467446,
        '-32': 32.11762924538711,
        '-31': 32.11778733775195,
        '-30': 32.1178975377549,
        '-29': 32.11791072953117,
        '-28': 32.11792718157704,
        '-27': 32.11797173806252,
        '-26': 32.11791011944112,
        '-25': 32.11769702266232,
        '-24': 32.117531878257296,
        '-23': 32.11763338919788,
        '-22': 32.117987544596076,
        '-21': 32.11841097374979,
        '-20': 32.11870134351387,
        '-19': 32.11864286517565,
        '-18': 32.1181202640407,
        '-17': 32.117426629116046,
        '-16': 32.11720390859527,
        '-15': 32.11775296889726,
        '-14': 32.116494881989645,
        '-13': 32.116586236700115,
        '-12': 32.115381796040275,
        '-11': 32.11547949108592,
        '-10': 32.11539107233005,
        '-9': 32.11515183379369,
        '-8': 32.113872505632294,
        '-7': 32.114173891105715,
        '-6': 32.113742629328286,
        '-5': 32.11551528196633,
        '-4': 32.1156621016132,
        '-3': 32.114923872791714,
        '-2': 32.11459972615479,
        '-1': 32.11481027981318
    },
    '26': {
        '0': 32.11695673705626,
        '1': 32.117270643908704,
        '2': 32.11617787038002,
        '3': 32.116145185396014,
        '4': 32.11606361538191,
        '5': 32.11351433026855,
        '6': 32.1120967986677,
        '7': 32.10889912087187,
        '8': 32.1119716253078,
        '9': 32.110005823078836,
        '10': 32.11343576166623,
        '11': 32.114526414262755,
        '12': 32.11196419477996,
        '13': 32.114665726311834,
        '14': 32.11504848576499,
        '15': 32.11541303628774,
        '16': 32.11519712749042,
        '17': 32.11551824348075,
        '18': 32.11682296486051,
        '19': 32.117276141028256,
        '20': 32.11727154206852,
        '21': 32.11780336298644,
        '22': 32.117946666810276,
        '23': 32.117377781141336,
        '24': 32.11649286093596,
        '25': 32.116692727135984,
        '26': 32.116308289349845,
        '27': 32.11630478589257,
        '28': 32.1175259289856,
        '29': 32.116350382797265,
        '30': 32.11509217247885,
        '31': 32.116756788592255,
        '32': 32.11911940914109,
        '33': 32.119072248911635,
        '34': 32.116453382482256,
        '35': 32.12004786582204,
        '36': 32.12001026405675,
        '37': 32.11960362609209,
        '38': 32.11521661943241,
        '39': 32.11113610317508,
        '40': 32.10714303487345,
        '41': 32.111729696844655,
        '42': 32.11282670399314,
        '43': 32.11297550314264,
        '44': 32.1140878682216,
        '45': 32.11300526350879,
        '46': 32.11398308914629,
        '47': 32.115358316475636,
        '48': 32.11682471840485,
        '49': 32.11805087273296,
        '50': 32.11894341931794,
        '51': 32.11894376637613,
        '52': 32.119195316334846,
        '53': 32.11900805774245,
        '54': 32.118389828449466,
        '55': 32.11811768186524,
        '56': 32.1187212109323,
        '57': 32.119804655823316,
        '58': 32.11946344722891,
        '59': 32.11920017362646,
        '60': 32.11816948106771,
        '61': 32.1189906675268,
        '62': 32.11830026379591,
        '63': 32.119946759144945,
        '64': 32.115465544882035,
        '65': 32.117210745405,
        '66': 32.11721719930017,
        '67': 32.11713199034262,
        '68': 32.118569999410596,
        '69': 32.11952862680039,
        '70': 32.12008427315762,
        '71': 32.118957728284016,
        '72': 32.120147949942734,
        '73': 32.1192191458556,
        '74': 32.11696038290302,
        '75': 32.1163338682971,
        '76': 32.11715990030784,
        '77': 32.1172793556046,
        '78': 32.11730235149009,
        '79': 32.118269513571946,
        '80': 32.11814916283428,
        '81': 32.11752013216853,
        '82': 32.11680583761059,
        '83': 32.11586890209137,
        '84': 32.115090791884114,
        '85': 32.1148492643488,
        '86': 32.11550998708927,
        '87': 32.11655521669117,
        '88': 32.117280322225625,
        '89': 32.11746863986157,
        '90': 32.118101251910325,
        '91': 32.11887556593837,
        '92': 32.11619511644147,
        '93': 32.118340112280414,
        '94': 32.111567125743996,
        '95': 32.10630657135443,
        '96': 32.108801605375675,
        '97': 32.11490516966415,
        '98': 32.104446799079454,
        '99': 32.08575536345028,
        '100': 32.09294792412119,
        '101': 32.09617451860324,
        '102': 32.107319995379406,
        '103': 32.09161744169458,
        '104': 32.098951430358106,
        '105': 32.10702372945528,
        '106': 32.10462546836796,
        '107': 32.10864987479505,
        '108': 32.11462781186035,
        '109': 32.11302233009095,
        '110': 32.106684716754046,
        '111': 32.117978100602585,
        '112': 32.11161045429387,
        '113': 32.11837668388201,
        '114': 32.10769960000787,
        '115': 32.116407984364805,
        '116': 32.11689692847434,
        '117': 32.11331966876859,
        '118': 32.1149226385042,
        '119': 32.114616830198095,
        '120': 32.12062430751903,
        '121': 32.12064041871657,
        '122': 32.12089133576475,
        '123': 32.12125852459919,
        '124': 32.121561111034026,
        '125': 32.12169381317674,
        '126': 32.12148214633133,
        '127': 32.120741485697145,
        '128': 32.11969972296824,
        '129': 32.1190966616746,
        '130': 32.11949249046815,
        '131': 32.1205229652595,
        '132': 32.12118381109386,
        '133': 32.120949264641915,
        '134': 32.12032851017209,
        '135': 32.120135521089225,
        '136': 32.120476529759806,
        '137': 32.120811747893356,
        '138': 32.120867399588235,
        '139': 32.12095900627143,
        '140': 32.12127922141034,
        '141': 32.12139794013217,
        '142': 32.12092002657779,
        '143': 32.12031366365037,
        '144': 32.120447962995016,
        '145': 32.12132030195412,
        '146': 32.1218707566293,
        '147': 32.12131716127129,
        '148': 32.1201907710153,
        '149': 32.119634773676694,
        '150': 32.11998335202594,
        '151': 32.12052985144878,
        '152': 32.120580785946686,
        '153': 32.12022152258288,
        '154': 32.11994310643239,
        '155': 32.119931764947786,
        '156': 32.11998793243419,
        '157': 32.11992754681305,
        '158': 32.11978734043553,
        '159': 32.11968303242037,
        '160': 32.119653281670615,
        '161': 32.119668007170226,
        '162': 32.11969674823467,
        '163': 32.11973575957135,
        '164': 32.1197916360659,
        '165': 32.11984554533402,
        '166': 32.11984717294851,
        '167': 32.11977423902554,
        '168': 32.11968152811849,
        '169': 32.11964173482607,
        '170': 32.11965339151307,
        '171': 32.11966540470802,
        '172': 32.11966719043637,
        '173': 32.11967486747437,
        '174': 32.119658126874995,
        '175': 32.119594256343895,
        '176': 32.11957765180595,
        '177': 32.119725096901924,
        '178': 32.119952091715476,
        '179': 32.120017288451685,
        '180': 32.11986286228132,
        '-180': 32.11986286228132,
        '-179': 32.11973668552364,
        '-178': 32.1198491081892,
        '-177': 32.12006946367481,
        '-176': 32.12015142819504,
        '-175': 32.12013513485774,
        '-174': 32.12027332836849,
        '-173': 32.1206011418175,
        '-172': 32.120858029136336,
        '-171': 32.1208930167175,
        '-170': 32.1208869632889,
        '-169': 32.121024052028396,
        '-168': 32.121148340015736,
        '-167': 32.120975352489985,
        '-166': 32.120546073678476,
        '-165': 32.12022661810442,
        '-164': 32.12025168973619,
        '-163': 32.12047239612316,
        '-162': 32.12061876092206,
        '-161': 32.12064027325435,
        '-160': 32.12066646416458,
        '-159': 32.12074561464854,
        '-158': 32.120781609308345,
        '-157': 32.120697002821785,
        '-156': 32.120519056546726,
        '-155': 32.120296913431105,
        '-154': 32.120060492694186,
        '-153': 32.11989795657563,
        '-152': 32.11994375879026,
        '-151': 32.120194101594464,
        '-150': 32.120425349256706,
        '-149': 32.120424821083056,
        '-148': 32.120260605938384,
        '-147': 32.12018801429323,
        '-146': 32.120301403834986,
        '-145': 32.12043112880061,
        '-144': 32.120418649752665,
        '-143': 32.12033325111271,
        '-142': 32.12031758853567,
        '-141': 32.120349506956906,
        '-140': 32.120307390918114,
        '-139': 32.120196304177334,
        '-138': 32.120137925996296,
        '-137': 32.1201535393734,
        '-136': 32.120124682490776,
        '-135': 32.120000355296604,
        '-134': 32.11989254575756,
        '-133': 32.119894737758734,
        '-132': 32.11992885961957,
        '-131': 32.11987378268697,
        '-130': 32.11975686306127,
        '-129': 32.11969673007316,
        '-128': 32.119705779516984,
        '-127': 32.11968120487952,
        '-126': 32.119588012513255,
        '-125': 32.11952442681349,
        '-124': 32.1195625831377,
        '-123': 32.11962552770771,
        '-122': 32.11960297832664,
        '-121': 32.119528342201995,
        '-120': 32.11953631484501,
        '-119': 32.119650508573976,
        '-118': 32.11971706750717,
        '-117': 32.119606979966505,
        '-116': 32.11942618303282,
        '-115': 32.119433782281725,
        '-114': 32.119734140763065,
        '-113': 32.12010624609714,
        '-112': 32.118485619549126,
        '-111': 32.11991045572857,
        '-110': 32.119570752441,
        '-109': 32.11900449226381,
        '-108': 32.11574413256027,
        '-107': 32.09582571256008,
        '-106': 32.09262683748058,
        '-105': 32.1004694986974,
        '-104': 32.106996812467635,
        '-103': 32.10945912217019,
        '-102': 32.10619714150994,
        '-101': 32.11120340786093,
        '-100': 32.11462247041582,
        '-99': 32.11894944342416,
        '-98': 32.12012551575999,
        '-97': 32.12054807402706,
        '-96': 32.11987832175251,
        '-95': 32.11887541364362,
        '-94': 32.11858924965208,
        '-93': 32.1191983933203,
        '-92': 32.11979543297905,
        '-91': 32.11972993893353,
        '-90': 32.11944285854099,
        '-89': 32.11959334758149,
        '-88': 32.11998761458959,
        '-87': 32.1200164847715,
        '-86': 32.119766635562996,
        '-85': 32.119906335483506,
        '-84': 32.12054389974277,
        '-83': 32.120975304249825,
        '-82': 32.12075775459754,
        '-81': 32.12030165296648,
        '-80': 32.12042894653028,
        '-79': 32.120704925592904,
        '-78': 32.12051620841782,
        '-77': 32.11977389761772,
        '-76': 32.11910495686589,
        '-75': 32.11895898083759,
        '-74': 32.119092742816626,
        '-73': 32.11906311354267,
        '-72': 32.1188457771342,
        '-71': 32.11873934183196,
        '-70': 32.118897655830416,
        '-69': 32.11921088246176,
        '-68': 32.119535641575276,
        '-67': 32.119810879671554,
        '-66': 32.11996557571081,
        '-65': 32.11990248047229,
        '-64': 32.119636802229785,
        '-63': 32.11935003525161,
        '-62': 32.119227919102656,
        '-61': 32.119271294954025,
        '-60': 32.1193151571879,
        '-59': 32.11923273417565,
        '-58': 32.119086304579994,
        '-57': 32.11905477840642,
        '-56': 32.11922549851953,
        '-55': 32.11949167185059,
        '-54': 32.11966893065743,
        '-53': 32.11968761460446,
        '-52': 32.1196449114686,
        '-51': 32.11968219580176,
        '-50': 32.119841329659835,
        '-49': 32.12004447153529,
        '-48': 32.120195908605815,
        '-47': 32.120285840122996,
        '-46': 32.12037740433211,
        '-45': 32.12049103757424,
        '-44': 32.12054760227486,
        '-43': 32.12047645782546,
        '-42': 32.120345191708196,
        '-41': 32.12029334800331,
        '-40': 32.120341072794965,
        '-39': 32.12036852709954,
        '-38': 32.12030683285407,
        '-37': 32.1202277334304,
        '-36': 32.12018882797688,
        '-35': 32.120124398575484,
        '-34': 32.11999483597189,
        '-33': 32.119922679692976,
        '-32': 32.120030553150016,
        '-31': 32.12023046614118,
        '-30': 32.12034118454351,
        '-29': 32.12035112591454,
        '-28': 32.120378688431586,
        '-27': 32.120413690418026,
        '-26': 32.120314199923975,
        '-25': 32.1200699933544,
        '-24': 32.1198649431263,
        '-23': 32.11984572773996,
        '-22': 32.120015108255856,
        '-21': 32.12035227504172,
        '-20': 32.12077448769132,
        '-19': 32.12094105139713,
        '-18': 32.120460020457145,
        '-17': 32.11949809153113,
        '-16': 32.118864576891575,
        '-15': 32.11915714306889,
        '-14': 32.11879223518589,
        '-13': 32.11848112355629,
        '-12': 32.117165500076545,
        '-11': 32.116605078122326,
        '-10': 32.11687488520783,
        '-9': 32.11689902783857,
        '-8': 32.1160038565831,
        '-7': 32.11562502039972,
        '-6': 32.11614974285317,
        '-5': 32.116313297679184,
        '-4': 32.11658121894528,
        '-3': 32.11659144616563,
        '-2': 32.1164532187201,
        '-1': 32.1169562810857
    },
    '27': {
        '0': 32.1187205902396,
        '1': 32.11844832894754,
        '2': 32.11867261841582,
        '3': 32.11833183984531,
        '4': 32.117848550637895,
        '5': 32.11683379305963,
        '6': 32.11804592873875,
        '7': 32.11781125479824,
        '8': 32.117726325119065,
        '9': 32.117310948403954,
        '10': 32.117127624315536,
        '11': 32.11699377368845,
        '12': 32.117526708310656,
        '13': 32.1177304954531,
        '14': 32.11803043972978,
        '15': 32.11785119225295,
        '16': 32.1176321543279,
        '17': 32.11607556980135,
        '18': 32.118272550474245,
        '19': 32.12009242906823,
        '20': 32.120217394737445,
        '21': 32.1207885910768,
        '22': 32.121587492333504,
        '23': 32.121130121652676,
        '24': 32.1204256704592,
        '25': 32.12005876832927,
        '26': 32.11939114526966,
        '27': 32.12004177920365,
        '28': 32.1213778167419,
        '29': 32.119823245787636,
        '30': 32.120576948112685,
        '31': 32.12002230316087,
        '32': 32.118069029751254,
        '33': 32.118241933948504,
        '34': 32.12265906075229,
        '35': 32.122510936312516,
        '36': 32.12145011018191,
        '37': 32.113496824055204,
        '38': 32.11398934755663,
        '39': 32.113104645931166,
        '40': 32.11294416067991,
        '41': 32.11150593795431,
        '42': 32.11302191619174,
        '43': 32.11568643124726,
        '44': 32.116642294609385,
        '45': 32.11647482031805,
        '46': 32.11801093360283,
        '47': 32.119047532721055,
        '48': 32.120386182316146,
        '49': 32.121157136974965,
        '50': 32.12072696446195,
        '51': 32.120787180223616,
        '52': 32.12129116385703,
        '53': 32.121447485143456,
        '54': 32.11717914875315,
        '55': 32.11927348929908,
        '56': 32.12116234511081,
        '57': 32.12190467135766,
        '58': 32.11359061926075,
        '59': 32.11896398021611,
        '60': 32.11791334548197,
        '61': 32.10917454647072,
        '62': 32.11107745379496,
        '63': 32.113130897605636,
        '64': 32.11363119957823,
        '65': 32.11255976288184,
        '66': 32.109981996567846,
        '67': 32.11426800933165,
        '68': 32.121533089258264,
        '69': 32.12168591225808,
        '70': 32.1220916391221,
        '71': 32.12107099138168,
        '72': 32.120556750104946,
        '73': 32.11966339551659,
        '74': 32.11957685334754,
        '75': 32.11916166986594,
        '76': 32.118837678690284,
        '77': 32.12032551436199,
        '78': 32.1203371405004,
        '79': 32.11931808785408,
        '80': 32.11846112707819,
        '81': 32.11770655710089,
        '82': 32.11753596094527,
        '83': 32.11728786655682,
        '84': 32.116945341132144,
        '85': 32.11749186110434,
        '86': 32.11669646196702,
        '87': 32.10388808637673,
        '88': 32.103701179001845,
        '89': 32.110615828863885,
        '90': 32.10293726619728,
        '91': 32.10519307292822,
        '92': 32.113722920091924,
        '93': 32.11805467976084,
        '94': 32.11857350262893,
        '95': 32.11749815686785,
        '96': 32.10643498239566,
        '97': 32.10266014522851,
        '98': 32.10786439354618,
        '99': 32.08629303293655,
        '100': 32.094736599756516,
        '101': 32.09405482376274,
        '102': 32.10335550073092,
        '103': 32.08995240169723,
        '104': 32.100117754832496,
        '105': 32.10423364968596,
        '106': 32.11002884151303,
        '107': 32.11064185258141,
        '108': 32.113680119446435,
        '109': 32.11495655754459,
        '110': 32.11958531148586,
        '111': 32.11833084250342,
        '112': 32.11931105303764,
        '113': 32.12066076962804,
        '114': 32.120689348792766,
        '115': 32.12138330575608,
        '116': 32.11745050735001,
        '117': 32.117700998258684,
        '118': 32.11904732099358,
        '119': 32.11284147560315,
        '120': 32.116555304791405,
        '121': 32.12274975316502,
        '122': 32.123004696957345,
        '123': 32.12326077409824,
        '124': 32.12343436748353,
        '125': 32.123773615113514,
        '126': 32.124235019053394,
        '127': 32.12417717261026,
        '128': 32.12315481009804,
        '129': 32.12178944118031,
        '130': 32.12128371981787,
        '131': 32.12202007111331,
        '132': 32.12311052332214,
        '133': 32.12351684559226,
        '134': 32.12320392835498,
        '135': 32.12286760253291,
        '136': 32.12287294269018,
        '137': 32.1229944858276,
        '138': 32.123092238714605,
        '139': 32.123412531962884,
        '140': 32.124001706917085,
        '141': 32.12432977835007,
        '142': 32.12393975861277,
        '143': 32.12319550726005,
        '144': 32.12288050680838,
        '145': 32.123139456445,
        '146': 32.12332733089593,
        '147': 32.1229868184422,
        '148': 32.12248521599982,
        '149': 32.12241391717875,
        '150': 32.122726720551356,
        '151': 32.1228751383815,
        '152': 32.1226181422797,
        '153': 32.12227863377965,
        '154': 32.1222053599098,
        '155': 32.12233525402704,
        '156': 32.12239667500648,
        '157': 32.12229753190496,
        '158': 32.12215731915168,
        '159': 32.122093523817064,
        '160': 32.122112408958714,
        '161': 32.1221476009615,
        '162': 32.12212781908691,
        '163': 32.12205150562724,
        '164': 32.12201967994173,
        '165': 32.12211964551574,
        '166': 32.12226375745892,
        '167': 32.12225726569153,
        '168': 32.12206459789311,
        '169': 32.12187975910093,
        '170': 32.12185910353978,
        '171': 32.121912103973784,
        '172': 32.12187240048772,
        '173': 32.12177100411539,
        '174': 32.12178541599865,
        '175': 32.12196861206175,
        '176': 32.12218174331665,
        '177': 32.12228782555769,
        '178': 32.122284614977296,
        '179': 32.12224564480729,
        '180': 32.122251153433794,
        '-180': 32.122251153433794,
        '-179': 32.12238095035453,
        '-178': 32.122639399855316,
        '-177': 32.122857666659975,
        '-176': 32.12281478081451,
        '-175': 32.122528990479374,
        '-174': 32.12230283392604,
        '-173': 32.122374248767855,
        '-172': 32.12262553503728,
        '-171': 32.12276839530771,
        '-170': 32.122734240798515,
        '-169': 32.12270617404505,
        '-168': 32.122791739548035,
        '-167': 32.12286897500736,
        '-166': 32.12282406015566,
        '-165': 32.12277008367266,
        '-164': 32.12288845089708,
        '-163': 32.12314556975396,
        '-162': 32.123323176177,
        '-161': 32.123301037825115,
        '-160': 32.12316426531148,
        '-159': 32.123019345792805,
        '-158': 32.122845674315606,
        '-157': 32.12259511786404,
        '-156': 32.12233440865664,
        '-155': 32.122193927164254,
        '-154': 32.122226893940166,
        '-153': 32.12239182528678,
        '-152': 32.122619700957245,
        '-151': 32.12281541905521,
        '-150': 32.12285187148851,
        '-149': 32.122690168756904,
        '-148': 32.12249290759889,
        '-147': 32.12248421265931,
        '-146': 32.12266939483719,
        '-145': 32.12281599214447,
        '-144': 32.12276258300036,
        '-143': 32.122619909105005,
        '-142': 32.12257510657317,
        '-141': 32.12261296473935,
        '-140': 32.122578010075124,
        '-139': 32.122448176706904,
        '-138': 32.12237119342634,
        '-137': 32.12242258134093,
        '-136': 32.122487195248205,
        '-135': 32.12244011007914,
        '-134': 32.12232195484639,
        '-133': 32.12224942603796,
        '-132': 32.122239497889815,
        '-131': 32.12222066577088,
        '-130': 32.12216521409192,
        '-129': 32.12211130856828,
        '-128': 32.12207800260339,
        '-127': 32.12204764126234,
        '-126': 32.122024433401116,
        '-125': 32.12202767122967,
        '-124': 32.12202588524251,
        '-123': 32.12196531333814,
        '-122': 32.121883085234934,
        '-121': 32.12189723244876,
        '-120': 32.12202746826331,
        '-119': 32.122109254585304,
        '-118': 32.12198920740605,
        '-117': 32.121772036698054,
        '-116': 32.121751296667824,
        '-115': 32.122057942357436,
        '-114': 32.121863335081905,
        '-113': 32.122344654410554,
        '-112': 32.12232122118101,
        '-111': 32.12194277722817,
        '-110': 32.121887662818416,
        '-109': 32.11076625853451,
        '-108': 32.11676183425618,
        '-107': 32.09961067441749,
        '-106': 32.102996134072654,
        '-105': 32.10823024136704,
        '-104': 32.11065754093173,
        '-103': 32.11057016025377,
        '-102': 32.11546690386988,
        '-101': 32.11704193956033,
        '-100': 32.12046065038641,
        '-99': 32.12027323597311,
        '-98': 32.12189580081197,
        '-97': 32.12262033042243,
        '-96': 32.12242302590277,
        '-95': 32.12177488371216,
        '-94': 32.121553030508764,
        '-93': 32.122042499117455,
        '-92': 32.12263765696359,
        '-91': 32.122784207317764,
        '-90': 32.12263374540277,
        '-89': 32.122548478843804,
        '-88': 32.122493169842805,
        '-87': 32.12233292482243,
        '-86': 32.12233214471817,
        '-85': 32.12279290343636,
        '-84': 32.12338958403697,
        '-83': 32.123465680783184,
        '-82': 32.122950487006726,
        '-81': 32.12265160681647,
        '-80': 32.12282357597116,
        '-79': 32.122999028637956,
        '-78': 32.12257122386387,
        '-77': 32.12178478921041,
        '-76': 32.12142085037983,
        '-75': 32.12172549361323,
        '-74': 32.122145012638576,
        '-73': 32.12212694001205,
        '-72': 32.121761478830116,
        '-71': 32.121500795846984,
        '-70': 32.121565647473766,
        '-69': 32.12183848351569,
        '-68': 32.12213899039943,
        '-67': 32.12236548430224,
        '-66': 32.122439765711796,
        '-65': 32.12231089591277,
        '-64': 32.12202805975226,
        '-63': 32.12170916664269,
        '-62': 32.121435622332164,
        '-61': 32.12123807860303,
        '-60': 32.1211535875687,
        '-59': 32.121213083621335,
        '-58': 32.12137881954715,
        '-57': 32.12156283549358,
        '-56': 32.12170975485388,
        '-55': 32.121813783760125,
        '-54': 32.121872601169166,
        '-53': 32.12189352220043,
        '-52': 32.12193735688274,
        '-51': 32.12206994608154,
        '-50': 32.12225035314002,
        '-49': 32.1223564049312,
        '-48': 32.12237131446086,
        '-47': 32.1224555266352,
        '-46': 32.12273328582789,
        '-45': 32.12306440385873,
        '-44': 32.123168239386025,
        '-43': 32.122979594757616,
        '-42': 32.1227381020795,
        '-41': 32.12266826522768,
        '-40': 32.122711592498305,
        '-39': 32.12268986451,
        '-38': 32.12260920712827,
        '-37': 32.12261429010067,
        '-36': 32.12269983427208,
        '-35': 32.122696282671896,
        '-34': 32.122570085506624,
        '-33': 32.12251586684436,
        '-32': 32.12265158271878,
        '-31': 32.12281592321534,
        '-30': 32.12281693885644,
        '-29': 32.1227209207491,
        '-28': 32.122707204817885,
        '-27': 32.122760970739556,
        '-26': 32.12272759016637,
        '-25': 32.12258613337514,
        '-24': 32.122436851547114,
        '-23': 32.12228765763926,
        '-22': 32.122127521933976,
        '-21': 32.12216404761903,
        '-20': 32.12262229496013,
        '-19': 32.123239696166166,
        '-18': 32.123361647324415,
        '-17': 32.12276797140689,
        '-16': 32.12207728908576,
        '-15': 32.122011899321976,
        '-14': 32.12251420104371,
        '-13': 32.1218613327522,
        '-12': 32.120916591676526,
        '-11': 32.12090825835482,
        '-10': 32.11966039652604,
        '-9': 32.11853782307913,
        '-8': 32.118539955521975,
        '-7': 32.118783745798616,
        '-6': 32.118249614384695,
        '-5': 32.11802457336449,
        '-4': 32.117376543239224,
        '-3': 32.11826373254888,
        '-2': 32.11878938183474,
        '-1': 32.11896225760401
    },
    '28': {
        '0': 32.12094607690757,
        '1': 32.120176096922265,
        '2': 32.11805271175213,
        '3': 32.11919951469131,
        '4': 32.119109560121586,
        '5': 32.11924702736316,
        '6': 32.12063710065281,
        '7': 32.12021626377389,
        '8': 32.1197873452316,
        '9': 32.11955779777024,
        '10': 32.119178150769116,
        '11': 32.119352480386986,
        '12': 32.12012366596158,
        '13': 32.120276381764114,
        '14': 32.11873458194012,
        '15': 32.1207011833066,
        '16': 32.120032957460126,
        '17': 32.120608504749555,
        '18': 32.121932467653515,
        '19': 32.12275122909831,
        '20': 32.123396828866774,
        '21': 32.123747987544796,
        '22': 32.12455645597191,
        '23': 32.12423931526904,
        '24': 32.12391608336915,
        '25': 32.123318040962204,
        '26': 32.12304455602565,
        '27': 32.123009084149174,
        '28': 32.1229681390345,
        '29': 32.12263508011946,
        '30': 32.123814410040445,
        '31': 32.123218390797305,
        '32': 32.11943166021715,
        '33': 32.12223852942291,
        '34': 32.12288941998228,
        '35': 32.12566354344216,
        '36': 32.114559158295236,
        '37': 32.11499387710634,
        '38': 32.1161398030023,
        '39': 32.116638297268786,
        '40': 32.11564114755851,
        '41': 32.11757918847475,
        '42': 32.11834712462821,
        '43': 32.118151034182766,
        '44': 32.11924243813408,
        '45': 32.12026960392294,
        '46': 32.12136566432584,
        '47': 32.12252663540013,
        '48': 32.123156821619204,
        '49': 32.12364285115943,
        '50': 32.1229427187858,
        '51': 32.1232014454565,
        '52': 32.115665558323705,
        '53': 32.11833937954086,
        '54': 32.115705182441644,
        '55': 32.117563827910764,
        '56': 32.114905398227016,
        '57': 32.116638598899,
        '58': 32.12117184437006,
        '59': 32.1166315033413,
        '60': 32.10815895793197,
        '61': 32.11134448139774,
        '62': 32.11068155519359,
        '63': 32.11998368443934,
        '64': 32.11971408540181,
        '65': 32.119076579896046,
        '66': 32.105705648759205,
        '67': 32.11437228837537,
        '68': 32.123877442864114,
        '69': 32.12341638765031,
        '70': 32.123191921511,
        '71': 32.12257876886124,
        '72': 32.12236969684227,
        '73': 32.12226736554569,
        '74': 32.12255775952503,
        '75': 32.12266194466204,
        '76': 32.12201069414005,
        '77': 32.12257810612366,
        '78': 32.12104744181598,
        '79': 32.119659578083905,
        '80': 32.118952867858965,
        '81': 32.119673616086054,
        '82': 32.12011028285975,
        '83': 32.11338800580104,
        '84': 32.115052444229754,
        '85': 32.10589753347023,
        '86': 32.09398266537056,
        '87': 32.073707775025824,
        '88': 32.06912645957279,
        '89': 32.07728554093504,
        '90': 32.08798678020664,
        '91': 32.08638544716124,
        '92': 32.078953771706956,
        '93': 32.09479802538729,
        '94': 32.1063283427368,
        '95': 32.11393290569512,
        '96': 32.120890845516705,
        '97': 32.10030947464579,
        '98': 32.09565642344196,
        '99': 32.08330948599662,
        '100': 32.09050679326413,
        '101': 32.10379924200032,
        '102': 32.098826674942366,
        '103': 32.102039986532645,
        '104': 32.11669932553809,
        '105': 32.11634431649487,
        '106': 32.11491516833039,
        '107': 32.114040999436945,
        '108': 32.11752559316513,
        '109': 32.118115355462244,
        '110': 32.1226512042876,
        '111': 32.1182025905629,
        '112': 32.121899238027375,
        '113': 32.12387343655899,
        '114': 32.121732668476234,
        '115': 32.123806537005194,
        '116': 32.1238420472535,
        '117': 32.12326902977348,
        '118': 32.10892652274023,
        '119': 32.12148895713556,
        '120': 32.119055562321044,
        '121': 32.12508013795799,
        '122': 32.12519325154852,
        '123': 32.12515797376283,
        '124': 32.12506820439999,
        '125': 32.1254324964876,
        '126': 32.12633209827072,
        '127': 32.12693893478363,
        '128': 32.126381239942184,
        '129': 32.1249354247974,
        '130': 32.12385016943057,
        '131': 32.123946447394296,
        '132': 32.124818014196116,
        '133': 32.12553616482341,
        '134': 32.12571199872427,
        '135': 32.12558446920226,
        '136': 32.12543175382055,
        '137': 32.12537131847746,
        '138': 32.12558702940399,
        '139': 32.12621430023727,
        '140': 32.12690674093171,
        '141': 32.12697310849275,
        '142': 32.12620390840255,
        '143': 32.125256115494054,
        '144': 32.124912441257926,
        '145': 32.12517351957915,
        '146': 32.1254256569668,
        '147': 32.12534820047408,
        '148': 32.12521157363386,
        '149': 32.125271827307884,
        '150': 32.12533192306988,
        '151': 32.12511589727868,
        '152': 32.12474801939841,
        '153': 32.12455799390832,
        '154': 32.12459325588612,
        '155': 32.124623239636435,
        '156': 32.12453718910885,
        '157': 32.12445913289468,
        '158': 32.124491839579505,
        '159': 32.12459166708311,
        '160': 32.12470950934423,
        '161': 32.12483664219574,
        '162': 32.12488328575551,
        '163': 32.12473282198442,
        '164': 32.124478820948,
        '165': 32.12439162973438,
        '166': 32.12454593267802,
        '167': 32.12468296295529,
        '168': 32.124582921976135,
        '169': 32.12438292623755,
        '170': 32.12433016705026,
        '171': 32.12438318270217,
        '172': 32.12432606139662,
        '173': 32.12419423445756,
        '174': 32.124274249182214,
        '175': 32.12464257816346,
        '176': 32.12498439076371,
        '177': 32.12500173535799,
        '178': 32.12480136884512,
        '179': 32.124717746003554,
        '180': 32.12489280659055,
        '-180': 32.12489280659055,
        '-179': 32.12519672773794,
        '-178': 32.125452432035104,
        '-177': 32.12554696914139,
        '-176': 32.12539145736148,
        '-175': 32.124994985785875,
        '-174': 32.12459006695121,
        '-173': 32.12448269740425,
        '-172': 32.124705279286545,
        '-171': 32.12496851844147,
        '-170': 32.125036492513594,
        '-169': 32.12500449822063,
        '-168': 32.12509162231167,
        '-167': 32.125292466800026,
        '-166': 32.125413304431305,
        '-165': 32.12538752956908,
        '-164': 32.12535270672057,
        '-163': 32.125400364569956,
        '-162': 32.12543185854157,
        '-161': 32.125336492395085,
        '-160': 32.12517029450873,
        '-159': 32.12505037032477,
        '-158': 32.124974765345634,
        '-157': 32.124877063958735,
        '-156': 32.124797417409646,
        '-155': 32.124852837375165,
        '-154': 32.12504308995385,
        '-153': 32.12521288378818,
        '-152': 32.125235603199,
        '-151': 32.12514077772793,
        '-150': 32.12503226352665,
        '-149': 32.12497264098807,
        '-148': 32.12498760335295,
        '-147': 32.12508828248736,
        '-146': 32.12521788534255,
        '-145': 32.12525060692799,
        '-144': 32.12513204685306,
        '-143': 32.12497355250878,
        '-142': 32.12491809966424,
        '-141': 32.1249474740334,
        '-140': 32.124923269008214,
        '-139': 32.12480519031108,
        '-138': 32.12471480198675,
        '-137': 32.124755216177945,
        '-136': 32.124865952232334,
        '-135': 32.12491424953517,
        '-134': 32.124861967583875,
        '-133': 32.12477476555691,
        '-132': 32.124708388120816,
        '-131': 32.124654908183956,
        '-130': 32.1245867833248,
        '-129': 32.124501426441206,
        '-128': 32.12442489495495,
        '-127': 32.12439926609581,
        '-126': 32.12444532473151,
        '-125': 32.12450905585716,
        '-124': 32.12448784895637,
        '-123': 32.124368138081195,
        '-122': 32.12429453730961,
        '-121': 32.124401865563414,
        '-120': 32.1245873978516,
        '-119': 32.124583947890024,
        '-118': 32.12432269301585,
        '-117': 32.12410420256681,
        '-116': 32.12427029502002,
        '-115': 32.124748984862215,
        '-114': 32.12500984376169,
        '-113': 32.11769340847656,
        '-112': 32.124490389231646,
        '-111': 32.12378429559062,
        '-110': 32.12207238977283,
        '-109': 32.10483663611594,
        '-108': 32.10213487637294,
        '-107': 32.10342517832751,
        '-106': 32.11001888656524,
        '-105': 32.11184426025357,
        '-104': 32.112322437344865,
        '-103': 32.11182600596455,
        '-102': 32.11162571356968,
        '-101': 32.12096383739376,
        '-100': 32.12290555023794,
        '-99': 32.12289897719049,
        '-98': 32.12348906270986,
        '-97': 32.12463586497857,
        '-96': 32.12485215345448,
        '-95': 32.124808826212615,
        '-94': 32.1248789104219,
        '-93': 32.12520073249655,
        '-92': 32.125513186888945,
        '-91': 32.125587678429156,
        '-90': 32.125460784843064,
        '-89': 32.12518801764476,
        '-88': 32.12474327531568,
        '-87': 32.12431912606321,
        '-86': 32.12435279225006,
        '-85': 32.124947423488194,
        '-84': 32.12554377396198,
        '-83': 32.125558278004966,
        '-82': 32.12471092768186,
        '-81': 32.12485905597639,
        '-80': 32.1253252115757,
        '-79': 32.125328237010656,
        '-78': 32.124657090350155,
        '-77': 32.12382986779935,
        '-76': 32.12366252216288,
        '-75': 32.12420699483674,
        '-74': 32.12473554106286,
        '-73': 32.12467907993194,
        '-72': 32.12419525008901,
        '-71': 32.12379074615174,
        '-70': 32.12372533876453,
        '-69': 32.12391672011248,
        '-68': 32.12418130461588,
        '-67': 32.12437425329744,
        '-66': 32.12441277210209,
        '-65': 32.12432212704674,
        '-64': 32.12421404900655,
        '-63': 32.12414062393152,
        '-62': 32.1240419684593,
        '-61': 32.12390557846272,
        '-60': 32.12386764061079,
        '-59': 32.12403488876604,
        '-58': 32.12428483313545,
        '-57': 32.12438979316997,
        '-56': 32.124306187008166,
        '-55': 32.124201381591604,
        '-54': 32.12420715812543,
        '-53': 32.124293479348786,
        '-52': 32.12440025155523,
        '-51': 32.124534879182534,
        '-50': 32.124677308957324,
        '-49': 32.124739235573244,
        '-48': 32.124729798166975,
        '-47': 32.12483758056175,
        '-46': 32.12518781357125,
        '-45': 32.1255921103211,
        '-44': 32.12572766516931,
        '-43': 32.125550174323735,
        '-42': 32.12533608825104,
        '-41': 32.12528418897893,
        '-40': 32.125283675311096,
        '-39': 32.12518059443824,
        '-38': 32.1250717844452,
        '-37': 32.1251347244824,
        '-36': 32.125297756160585,
        '-35': 32.12533282475591,
        '-34': 32.12523094437662,
        '-33': 32.125218724154735,
        '-32': 32.12536964376203,
        '-31': 32.12546280026889,
        '-30': 32.12533688070956,
        '-29': 32.125147657130974,
        '-28': 32.12510331653155,
        '-27': 32.12514326562766,
        '-26': 32.12509658813402,
        '-25': 32.12499224531125,
        '-24': 32.12494357854348,
        '-23': 32.124858009548916,
        '-22': 32.12460864156278,
        '-21': 32.124443539537005,
        '-20': 32.12480487156503,
        '-19': 32.125635060337906,
        '-18': 32.12625870870999,
        '-17': 32.12619177770964,
        '-16': 32.125748231294885,
        '-15': 32.12553037035619,
        '-14': 32.125587387583714,
        '-13': 32.125508307561965,
        '-12': 32.12344279141462,
        '-11': 32.12235794749034,
        '-10': 32.12127591403008,
        '-9': 32.120027387552085,
        '-8': 32.120361013760544,
        '-7': 32.120550719891874,
        '-6': 32.120071440386624,
        '-5': 32.120560011517874,
        '-4': 32.121130477628455,
        '-3': 32.121409772745906,
        '-2': 32.121087947080326,
        '-1': 32.12146943677461
    },
    '29': {
        '0': 32.12498011629395,
        '1': 32.123303898624826,
        '2': 32.12249566209176,
        '3': 32.121833029128666,
        '4': 32.12263441128794,
        '5': 32.1235822186317,
        '6': 32.123611690793204,
        '7': 32.123068027913064,
        '8': 32.12305770585108,
        '9': 32.121827775177834,
        '10': 32.120404855621054,
        '11': 32.120708990803465,
        '12': 32.1211940915625,
        '13': 32.121906955262375,
        '14': 32.122788431123766,
        '15': 32.12342303495293,
        '16': 32.12502383171073,
        '17': 32.12538602202756,
        '18': 32.124817193413364,
        '19': 32.12666308149534,
        '20': 32.126684117713694,
        '21': 32.12642970249765,
        '22': 32.126932949870415,
        '23': 32.12647475035067,
        '24': 32.12687186397957,
        '25': 32.127292992272004,
        '26': 32.12723080044228,
        '27': 32.127570598509706,
        '28': 32.12677534746883,
        '29': 32.12579464735246,
        '30': 32.12621897910162,
        '31': 32.127331484454764,
        '32': 32.12536200809982,
        '33': 32.128401419269004,
        '34': 32.115639042403146,
        '35': 32.12070696719672,
        '36': 32.12075214564706,
        '37': 32.11690343991914,
        '38': 32.12019233422832,
        '39': 32.1195801514172,
        '40': 32.11908652553498,
        '41': 32.12002172508237,
        '42': 32.12076546750514,
        '43': 32.12188385924344,
        '44': 32.122529790107436,
        '45': 32.123815055261716,
        '46': 32.12470202466023,
        '47': 32.12506263590457,
        '48': 32.12619358681112,
        '49': 32.125911772122656,
        '50': 32.12578551973698,
        '51': 32.12643804306059,
        '52': 32.119487285749486,
        '53': 32.113429746435855,
        '54': 32.11169811482508,
        '55': 32.11354627990649,
        '56': 32.11179367802632,
        '57': 32.11117068715679,
        '58': 32.10493883504684,
        '59': 32.12294805105202,
        '60': 32.11895747898103,
        '61': 32.10957761498474,
        '62': 32.11929460919442,
        '63': 32.11898149120207,
        '64': 32.11764805766823,
        '65': 32.11977457733937,
        '66': 32.11117102760226,
        '67': 32.10634975374827,
        '68': 32.126475742079144,
        '69': 32.1214468931618,
        '70': 32.12469015146629,
        '71': 32.12424849610976,
        '72': 32.1244516377793,
        '73': 32.125615643120085,
        '74': 32.12610318363085,
        '75': 32.125993693496746,
        '76': 32.12466241544364,
        '77': 32.12336084152498,
        '78': 32.122501786919926,
        '79': 32.122219451465504,
        '80': 32.1222165123113,
        '81': 32.112740467196645,
        '82': 32.097355828319245,
        '83': 32.081888108751315,
        '84': 32.08734341698994,
        '85': 32.075100170451535,
        '86': 32.080694769515084,
        '87': 32.07887161308807,
        '88': 32.07955962458313,
        '89': 32.08250657394051,
        '90': 32.07524632565574,
        '91': 32.0839178294648,
        '92': 32.075721347085256,
        '93': 32.090403589657875,
        '94': 32.08052674668437,
        '95': 32.113689678883524,
        '96': 32.09198221619229,
        '97': 32.09236832680093,
        '98': 32.082154371711255,
        '99': 32.0953113932296,
        '100': 32.08445255276822,
        '101': 32.08387026000586,
        '102': 32.10039970522489,
        '103': 32.09494887521637,
        '104': 32.120164891986065,
        '105': 32.12180280280339,
        '106': 32.12275805641155,
        '107': 32.11656666775085,
        '108': 32.11828091344529,
        '109': 32.121797015018856,
        '110': 32.12115067331273,
        '111': 32.123973084167964,
        '112': 32.126681052394716,
        '113': 32.12640923692777,
        '114': 32.1223172609752,
        '115': 32.11906158904747,
        '116': 32.12700564057874,
        '117': 32.12688659300374,
        '118': 32.12474671788925,
        '119': 32.12708661957611,
        '120': 32.124495672704924,
        '121': 32.12520678839863,
        '122': 32.127813668433184,
        '123': 32.127708216846145,
        '124': 32.127491367031105,
        '125': 32.127611520509774,
        '126': 32.12827017892044,
        '127': 32.128948979042484,
        '128': 32.12889100292569,
        '129': 32.12799141550328,
        '130': 32.126935871947026,
        '131': 32.12647006295615,
        '132': 32.12676586430366,
        '133': 32.12747881606167,
        '134': 32.12812657849769,
        '135': 32.1283493657721,
        '136': 32.12812025011668,
        '137': 32.12787957385306,
        '138': 32.12818599823113,
        '139': 32.1289687942568,
        '140': 32.129356677860734,
        '141': 32.12863243618894,
        '142': 32.12725483880958,
        '143': 32.126483928015105,
        '144': 32.12692279819804,
        '145': 32.12785796272045,
        '146': 32.128242905592856,
        '147': 32.12792511387931,
        '148': 32.1275275001687,
        '149': 32.12742490042228,
        '150': 32.12739340150605,
        '151': 32.12722022724955,
        '152': 32.127091376565446,
        '153': 32.127175985388995,
        '154': 32.12725495707727,
        '155': 32.127078414258655,
        '156': 32.126811270538646,
        '157': 32.126777633893546,
        '158': 32.126944693164425,
        '159': 32.12702665587825,
        '160': 32.12699810879722,
        '161': 32.12710399089025,
        '162': 32.127354460982396,
        '163': 32.12741609093343,
        '164': 32.12713967558096,
        '165': 32.12684748794482,
        '166': 32.12687075555527,
        '167': 32.12707403972672,
        '168': 32.127121293204084,
        '169': 32.127010636813864,
        '170': 32.12700424268395,
        '171': 32.12713693423114,
        '172': 32.12717409386793,
        '173': 32.12707008504089,
        '174': 32.127098827419594,
        '175': 32.12739532171584,
        '176': 32.12767512251966,
        '177': 32.12763102738508,
        '178': 32.12739167079363,
        '179': 32.127322840376316,
        '180': 32.12750380313446,
        '-180': 32.12750380313446,
        '-179': 32.12767928756428,
        '-178': 32.12767670085893,
        '-177': 32.12758777985368,
        '-176': 32.12751307565331,
        '-175': 32.127398929345304,
        '-174': 32.127236929729186,
        '-173': 32.12719968320503,
        '-172': 32.1274030645418,
        '-171': 32.12768122788158,
        '-170': 32.12778996887825,
        '-169': 32.12774277596592,
        '-168': 32.12774608832346,
        '-167': 32.12784790641587,
        '-166': 32.12786763754459,
        '-165': 32.12769749049182,
        '-164': 32.12747163702466,
        '-163': 32.12735199476946,
        '-162': 32.12730872216644,
        '-161': 32.12723481984303,
        '-160': 32.127154523825915,
        '-159': 32.12716515541044,
        '-158': 32.12724642590735,
        '-157': 32.127293241413355,
        '-156': 32.12731161630081,
        '-155': 32.12741362166353,
        '-154': 32.12759476315711,
        '-153': 32.12767672075487,
        '-152': 32.12755183354682,
        '-151': 32.12736701603324,
        '-150': 32.127355011701574,
        '-149': 32.12754900465637,
        '-148': 32.127770333398516,
        '-147': 32.127859534618565,
        '-146': 32.127812111816006,
        '-145': 32.12769964955374,
        '-144': 32.12757007687138,
        '-143': 32.12745710921485,
        '-142': 32.12740160740189,
        '-141': 32.12740610873395,
        '-140': 32.12741244890524,
        '-139': 32.12737197169549,
        '-138': 32.1273118343553,
        '-137': 32.12729501944678,
        '-136': 32.127335087784495,
        '-135': 32.12738413600728,
        '-134': 32.127391619371494,
        '-133': 32.12734529924876,
        '-132': 32.1272616646109,
        '-131': 32.12716192184857,
        '-130': 32.127059380009925,
        '-129': 32.12696091130591,
        '-128': 32.12688405918249,
        '-127': 32.12686513039786,
        '-126': 32.12691853991762,
        '-125': 32.12698481736004,
        '-124': 32.12697402468542,
        '-123': 32.12689534016459,
        '-122': 32.12688196041825,
        '-121': 32.12700975809982,
        '-120': 32.12713643124541,
        '-119': 32.12704953284926,
        '-118': 32.12679149396678,
        '-117': 32.1266918112971,
        '-116': 32.1269702172267,
        '-115': 32.12740428339669,
        '-114': 32.12369173565944,
        '-113': 32.127285527621844,
        '-112': 32.126036365118665,
        '-111': 32.12497281261602,
        '-110': 32.120847482201555,
        '-109': 32.11505995640018,
        '-108': 32.10757455124385,
        '-107': 32.1061592188188,
        '-106': 32.114161125752815,
        '-105': 32.11600226307116,
        '-104': 32.113370895218814,
        '-103': 32.12030978753465,
        '-102': 32.11440674778055,
        '-101': 32.124315538954775,
        '-100': 32.12528869323273,
        '-99': 32.12543273704602,
        '-98': 32.125954044550916,
        '-97': 32.126445483528194,
        '-96': 32.12695793840947,
        '-95': 32.12741101223079,
        '-94': 32.12767857211862,
        '-93': 32.12785164044107,
        '-92': 32.12787866815118,
        '-91': 32.127846724179385,
        '-90': 32.127813353259484,
        '-89': 32.12763569121043,
        '-88': 32.12718981614139,
        '-87': 32.12672611129142,
        '-86': 32.126702253531356,
        '-85': 32.12716897960685,
        '-84': 32.12761837228301,
        '-83': 32.12764317449471,
        '-82': 32.12717882532076,
        '-81': 32.127462955535755,
        '-80': 32.12787131442957,
        '-79': 32.12772738925629,
        '-78': 32.12698876619148,
        '-77': 32.126254813909114,
        '-76': 32.126161993454915,
        '-75': 32.12658833874116,
        '-74': 32.12687646658203,
        '-73': 32.126668160383154,
        '-72': 32.12621342927137,
        '-71': 32.12593218776572,
        '-70': 32.125974509594855,
        '-69': 32.126238120364576,
        '-68': 32.126559812139035,
        '-67': 32.126787179229424,
        '-66': 32.126836123828724,
        '-65': 32.12678846654594,
        '-64': 32.12682109006322,
        '-63': 32.126966091350965,
        '-62': 32.12706110141179,
        '-61': 32.12700912279992,
        '-60': 32.12694156091253,
        '-59': 32.12700032043924,
        '-58': 32.127089320724465,
        '-57': 32.12702066479067,
        '-56': 32.12682999635147,
        '-55': 32.126742757769506,
        '-54': 32.12684284505681,
        '-53': 32.126977752026036,
        '-52': 32.12702820392702,
        '-51': 32.12707470011948,
        '-50': 32.12720576844854,
        '-49': 32.127338499075584,
        '-48': 32.127378226129814,
        '-47': 32.12742468831752,
        '-46': 32.127630461869394,
        '-45': 32.12792591785271,
        '-44': 32.128087532136476,
        '-43': 32.12806104737156,
        '-42': 32.128011076491006,
        '-41': 32.12802414816485,
        '-40': 32.12798081231745,
        '-39': 32.12781624026948,
        '-38': 32.127700543577404,
        '-37': 32.12778896527754,
        '-36': 32.127948209138665,
        '-35': 32.12795069370182,
        '-34': 32.1278427728093,
        '-33': 32.12786404518655,
        '-32': 32.12803300323166,
        '-31': 32.12809515960344,
        '-30': 32.12793380851052,
        '-29': 32.1277519644056,
        '-28': 32.127714857270725,
        '-27': 32.127674108725664,
        '-26': 32.12746315074332,
        '-25': 32.12724596730878,
        '-24': 32.127270537129554,
        '-23': 32.127418567510055,
        '-22': 32.12736874935564,
        '-21': 32.127172141103756,
        '-20': 32.12725957196854,
        '-19': 32.12777627526198,
        '-18': 32.128294424096666,
        '-17': 32.12841123323377,
        '-16': 32.12830178186863,
        '-15': 32.128341459552075,
        '-14': 32.12842949055648,
        '-13': 32.12815055940736,
        '-12': 32.12753651318407,
        '-11': 32.127169024086534,
        '-10': 32.12360341893882,
        '-9': 32.121881542377544,
        '-8': 32.12374016986072,
        '-7': 32.12216851564768,
        '-6': 32.12127313084182,
        '-5': 32.1225035878643,
        '-4': 32.12298972641144,
        '-3': 32.12326849570386,
        '-2': 32.12303874956294,
        '-1': 32.12380008440228
    },
    '30': {
        '0': 32.12508842264998,
        '1': 32.12539826745984,
        '2': 32.12538198715873,
        '3': 32.12565074534256,
        '4': 32.12626095006337,
        '5': 32.12674446071726,
        '6': 32.126574996968216,
        '7': 32.1268103587381,
        '8': 32.126498119888986,
        '9': 32.126071500458984,
        '10': 32.12642314230686,
        '11': 32.124203627029615,
        '12': 32.12396036493234,
        '13': 32.12432507075424,
        '14': 32.125588429851256,
        '15': 32.126866073740445,
        '16': 32.127034255072275,
        '17': 32.12803828949571,
        '18': 32.1280353995334,
        '19': 32.129209057136435,
        '20': 32.1297623891229,
        '21': 32.12965023032261,
        '22': 32.12926682828031,
        '23': 32.12943216282326,
        '24': 32.12970691712019,
        '25': 32.129622269370685,
        '26': 32.12906471035532,
        '27': 32.13080318472833,
        '28': 32.13028927680376,
        '29': 32.128866675447455,
        '30': 32.12840361944839,
        '31': 32.128378847174595,
        '32': 32.12762312242528,
        '33': 32.125750154080116,
        '34': 32.1264720741517,
        '35': 32.12614846666567,
        '36': 32.12122771795464,
        '37': 32.120660245477765,
        '38': 32.12349271224858,
        '39': 32.124307004787525,
        '40': 32.123677837199956,
        '41': 32.12403558455702,
        '42': 32.12506685391546,
        '43': 32.12589538826815,
        '44': 32.12609737386616,
        '45': 32.1272747473009,
        '46': 32.12812608775248,
        '47': 32.12794802136278,
        '48': 32.12809189283925,
        '49': 32.127949255555464,
        '50': 32.12890606937307,
        '51': 32.12160584186702,
        '52': 32.11363782429986,
        '53': 32.11460213186358,
        '54': 32.10664884897302,
        '55': 32.11476648388272,
        '56': 32.10619524287172,
        '57': 32.111852933496294,
        '58': 32.125281502195215,
        '59': 32.1282379113288,
        '60': 32.115721969995974,
        '61': 32.1256705004377,
        '62': 32.12558967265373,
        '63': 32.1245556583564,
        '64': 32.119833230144295,
        '65': 32.12080769166157,
        '66': 32.12096421693215,
        '67': 32.112730633183794,
        '68': 32.12313289727148,
        '69': 32.119775384876014,
        '70': 32.1122272412124,
        '71': 32.12740708325083,
        '72': 32.12761949259611,
        '73': 32.128454571074045,
        '74': 32.12834356536945,
        '75': 32.12691041882742,
        '76': 32.12541707582855,
        '77': 32.12523492689993,
        '78': 32.1260826427158,
        '79': 32.11090952161434,
        '80': 32.11253446027597,
        '81': 32.07349636999125,
        '82': 32.09934984841992,
        '83': 32.08727255792942,
        '84': 32.08735561516649,
        '85': 32.074946944258876,
        '86': 32.07947088929149,
        '87': 32.078524967024315,
        '88': 32.078915121094546,
        '89': 32.083521652350775,
        '90': 32.074436345524354,
        '91': 32.086706974520325,
        '92': 32.09048998599184,
        '93': 32.09148047534364,
        '94': 32.09383689887186,
        '95': 32.09970818984843,
        '96': 32.08661409283137,
        '97': 32.091793852167676,
        '98': 32.089777987810876,
        '99': 32.09308620914932,
        '100': 32.0821130745445,
        '101': 32.09824730610784,
        '102': 32.0878725876484,
        '103': 32.12320067515096,
        '104': 32.12301114340795,
        '105': 32.12302111859621,
        '106': 32.12493845929523,
        '107': 32.122079366458365,
        '108': 32.11756195837731,
        '109': 32.12086193868772,
        '110': 32.120126658280064,
        '111': 32.12145419425033,
        '112': 32.12897295357996,
        '113': 32.12895088465221,
        '114': 32.12916059299854,
        '115': 32.12793009881213,
        '116': 32.12960496210056,
        '117': 32.12825972674204,
        '118': 32.12631611079525,
        '119': 32.122363740222866,
        '120': 32.12973448198807,
        '121': 32.12983799288641,
        '122': 32.13043797825434,
        '123': 32.1305141557453,
        '124': 32.130448335009774,
        '125': 32.13035063679985,
        '126': 32.13045740921924,
        '127': 32.1308028388908,
        '128': 32.13108775980834,
        '129': 32.13092496987012,
        '130': 32.1302281022961,
        '131': 32.12940434102686,
        '132': 32.129121358694555,
        '133': 32.12969708067041,
        '134': 32.13064786409701,
        '135': 32.131074444136885,
        '136': 32.13070854985528,
        '137': 32.130351062691005,
        '138': 32.13086444785138,
        '139': 32.131823993031624,
        '140': 32.131776743927354,
        '141': 32.13011223902478,
        '142': 32.12815373768874,
        '143': 32.12779662647806,
        '144': 32.12926621050081,
        '145': 32.130891008832386,
        '146': 32.13114991256969,
        '147': 32.13027274248786,
        '148': 32.12950650567721,
        '149': 32.12941762429743,
        '150': 32.12957306487363,
        '151': 32.12958456238911,
        '152': 32.129642746783205,
        '153': 32.129905691016724,
        '154': 32.130052184934634,
        '155': 32.12982116109652,
        '156': 32.12953292198135,
        '157': 32.129606499962065,
        '158': 32.129849827674626,
        '159': 32.12974968500455,
        '160': 32.1293318456608,
        '161': 32.12917612240414,
        '162': 32.12952406435206,
        '163': 32.12989291211564,
        '164': 32.12979883457322,
        '165': 32.12944018511432,
        '166': 32.12931970859133,
        '167': 32.12948880249241,
        '168': 32.129593320799906,
        '169': 32.12951828900432,
        '170': 32.12952732260147,
        '171': 32.129747623927294,
        '172': 32.12993384298124,
        '173': 32.12988644104196,
        '174': 32.12977295172639,
        '175': 32.12981892523671,
        '176': 32.12993131498017,
        '177': 32.12989280447562,
        '178': 32.129757228870815,
        '179': 32.12974966551197,
        '180': 32.12985406701277,
        '-180': 32.129854067012765,
        '-179': 32.12981488078083,
        '-178': 32.12956867015742,
        '-177': 32.12938713175221,
        '-176': 32.129489282280524,
        '-175': 32.12974468388339,
        '-174': 32.12991129591678,
        '-173': 32.12998001955834,
        '-172': 32.13008718253857,
        '-171': 32.13021484285446,
        '-170': 32.13021323384685,
        '-169': 32.13007822638293,
        '-168': 32.12998027115782,
        '-167': 32.12999244239637,
        '-166': 32.12998619647774,
        '-165': 32.12986560658744,
        '-164': 32.129734818022136,
        '-163': 32.12972372948861,
        '-162': 32.129780767588336,
        '-161': 32.12977639528946,
        '-160': 32.1297191293415,
        '-159': 32.12971273465679,
        '-158': 32.1297520590575,
        '-157': 32.12973269047991,
        '-156': 32.12966035485663,
        '-155': 32.12967845725198,
        '-154': 32.12983542689364,
        '-153': 32.12997681581251,
        '-152': 32.129969272267054,
        '-151': 32.129916614307966,
        '-150': 32.13001448052502,
        '-149': 32.13024954842788,
        '-148': 32.130395887353416,
        '-147': 32.13031356417199,
        '-146': 32.13011706298506,
        '-145': 32.12999995015582,
        '-144': 32.12999736730227,
        '-143': 32.13000309937894,
        '-142': 32.12995731071352,
        '-141': 32.12991588373726,
        '-140': 32.129939835783205,
        '-139': 32.129997506574576,
        '-138': 32.13000919634898,
        '-137': 32.12994961367563,
        '-136': 32.12987107670701,
        '-135': 32.129834743823054,
        '-134': 32.12984151482016,
        '-133': 32.129836631265114,
        '-132': 32.12977568157485,
        '-131': 32.12967607317518,
        '-130': 32.12959576923748,
        '-129': 32.12956768639146,
        '-128': 32.12957275881997,
        '-127': 32.129577757171795,
        '-126': 32.12957349267481,
        '-125': 32.129564195369426,
        '-124': 32.12954888732771,
        '-123': 32.12953766869902,
        '-122': 32.129556029191896,
        '-121': 32.12959353770385,
        '-120': 32.12957974103366,
        '-119': 32.12948086170979,
        '-118': 32.12940915172628,
        '-117': 32.129533552330244,
        '-116': 32.129835744079124,
        '-115': 32.12419748077121,
        '-114': 32.1299655866365,
        '-113': 32.12970639413833,
        '-112': 32.12325269626615,
        '-111': 32.123381866772036,
        '-110': 32.11878002952052,
        '-109': 32.119055947269516,
        '-108': 32.112869061349215,
        '-107': 32.11532703860783,
        '-106': 32.11639587966261,
        '-105': 32.11783305304176,
        '-104': 32.117631279056724,
        '-103': 32.118642634501256,
        '-102': 32.12444431499619,
        '-101': 32.125046491341706,
        '-100': 32.12370421327574,
        '-99': 32.12515722434964,
        '-98': 32.12741128330416,
        '-97': 32.12873288924445,
        '-96': 32.128865191030904,
        '-95': 32.12943686638414,
        '-94': 32.12980394380553,
        '-93': 32.12991986165165,
        '-92': 32.12982000497027,
        '-91': 32.12983688657329,
        '-90': 32.130002423922505,
        '-89': 32.13015838267492,
        '-88': 32.13004873597312,
        '-87': 32.12985025295911,
        '-86': 32.12986002437203,
        '-85': 32.129978582355974,
        '-84': 32.13022989481847,
        '-83': 32.12998831512999,
        '-82': 32.12950558268894,
        '-81': 32.1301148062462,
        '-80': 32.13025547799568,
        '-79': 32.1300323522963,
        '-78': 32.12947756558339,
        '-77': 32.12907149386002,
        '-76': 32.12910528669283,
        '-75': 32.12930753986796,
        '-74': 32.12925203409403,
        '-73': 32.12891504725376,
        '-72': 32.12862482177598,
        '-71': 32.12860592702701,
        '-70': 32.12880564403116,
        '-69': 32.12909626807703,
        '-68': 32.12940566726818,
        '-67': 32.12964303478197,
        '-66': 32.12971359448636,
        '-65': 32.12967096951251,
        '-64': 32.129688791263526,
        '-63': 32.12980775000876,
        '-62': 32.12985844183796,
        '-61': 32.129721128889976,
        '-60': 32.12951608278141,
        '-59': 32.12941479549394,
        '-58': 32.12939243402711,
        '-57': 32.12933105625333,
        '-56': 32.129271345879324,
        '-55': 32.129354012874614,
        '-54': 32.12954312919652,
        '-53': 32.129630769416444,
        '-52': 32.129559132652616,
        '-51': 32.129534044097426,
        '-50': 32.12971655569895,
        '-49': 32.12997210261854,
        '-48': 32.13007579716073,
        '-47': 32.13004373157584,
        '-46': 32.130079194968395,
        '-45': 32.13025852499591,
        '-44': 32.13046290285569,
        '-43': 32.130601148392,
        '-42': 32.13070215468384,
        '-41': 32.130759317853474,
        '-40': 32.13068312110317,
        '-39': 32.13049450858501,
        '-38': 32.13038828407359,
        '-37': 32.13047047875898,
        '-36': 32.130576446590226,
        '-35': 32.13051691893548,
        '-34': 32.13038656514995,
        '-33': 32.13041467116154,
        '-32': 32.13058137832683,
        '-31': 32.13063945250088,
        '-30': 32.13051625823879,
        '-29': 32.13041229063886,
        '-28': 32.130414908856274,
        '-27': 32.13030192502258,
        '-26': 32.12992917788508,
        '-25': 32.12957789469019,
        '-24': 32.12961668196413,
        '-23': 32.129946132411995,
        '-22': 32.13011676594182,
        '-21': 32.12996739508391,
        '-20': 32.129792047882596,
        '-19': 32.1298129158257,
        '-18': 32.129864911925786,
        '-17': 32.12980370560988,
        '-16': 32.12988188905818,
        '-15': 32.13035256539401,
        '-14': 32.130900749053296,
        '-13': 32.13093297509346,
        '-12': 32.130438849804,
        '-11': 32.1301299958625,
        '-10': 32.130544769222,
        '-9': 32.119770834382265,
        '-8': 32.11707835292152,
        '-7': 32.12586134576352,
        '-6': 32.12227608907678,
        '-5': 32.1238931181086,
        '-4': 32.124975810148875,
        '-3': 32.124417147350364,
        '-2': 32.12558672227636,
        '-1': 32.12502759014702
    },
    '31': {
        '0': 32.126861533578634,
        '1': 32.126959999211714,
        '2': 32.12690063099799,
        '3': 32.12699672363316,
        '4': 32.12912496397663,
        '5': 32.13032381528383,
        '6': 32.13013764819303,
        '7': 32.130206371263974,
        '8': 32.13007653354663,
        '9': 32.1298411835136,
        '10': 32.12931633644707,
        '11': 32.12694118476356,
        '12': 32.12692900392043,
        '13': 32.12565353887159,
        '14': 32.130250026230776,
        '15': 32.13191418410052,
        '16': 32.13241421622779,
        '17': 32.131847750039356,
        '18': 32.13261204417896,
        '19': 32.13268697499446,
        '20': 32.132988895001404,
        '21': 32.13261275507825,
        '22': 32.13219218641622,
        '23': 32.13213829307515,
        '24': 32.131876756381466,
        '25': 32.13177740640823,
        '26': 32.13156089938763,
        '27': 32.131274290470095,
        '28': 32.13255185155518,
        '29': 32.132802511841206,
        '30': 32.132868500339576,
        '31': 32.13292477339077,
        '32': 32.132905950738156,
        '33': 32.1326179841553,
        '34': 32.13187204773856,
        '35': 32.12697011529797,
        '36': 32.124677998483094,
        '37': 32.12601776100279,
        '38': 32.12742933775861,
        '39': 32.12435669973555,
        '40': 32.12475932194409,
        '41': 32.12742412006562,
        '42': 32.129108987408216,
        '43': 32.12943418105636,
        '44': 32.12986528220562,
        '45': 32.13112444705681,
        '46': 32.131228949397176,
        '47': 32.130158406298456,
        '48': 32.129548962599,
        '49': 32.13029649184465,
        '50': 32.12668273669796,
        '51': 32.11252547036887,
        '52': 32.10952687179528,
        '53': 32.11587388882785,
        '54': 32.11600483879927,
        '55': 32.11804174053788,
        '56': 32.12012250014766,
        '57': 32.116350502556166,
        '58': 32.1299082171497,
        '59': 32.12374188638027,
        '60': 32.11988689275033,
        '61': 32.12806574181931,
        '62': 32.12843831666138,
        '63': 32.125409523384896,
        '64': 32.125474321301255,
        '65': 32.12146956039871,
        '66': 32.1209696723203,
        '67': 32.110155172250536,
        '68': 32.11446382578232,
        '69': 32.117824834949694,
        '70': 32.11572507008634,
        '71': 32.1304034437515,
        '72': 32.13025531923523,
        '73': 32.130329280746494,
        '74': 32.12978645067486,
        '75': 32.12843257490706,
        '76': 32.127779511745814,
        '77': 32.12190459664666,
        '78': 32.118917971486795,
        '79': 32.095255936822944,
        '80': 32.08905993720999,
        '81': 32.0893523365782,
        '82': 32.08326336956212,
        '83': 32.07732401086841,
        '84': 32.08767177835307,
        '85': 32.084394353610634,
        '86': 32.08579691042245,
        '87': 32.08566886798415,
        '88': 32.08298957454109,
        '89': 32.085100370144275,
        '90': 32.08415265528991,
        '91': 32.084280827896215,
        '92': 32.081713369832386,
        '93': 32.080494410378094,
        '94': 32.07869944251271,
        '95': 32.086970424450406,
        '96': 32.08158731728367,
        '97': 32.08665733603284,
        '98': 32.08380458191842,
        '99': 32.08621130582897,
        '100': 32.08396783242592,
        '101': 32.090711554378714,
        '102': 32.1053361048137,
        '103': 32.08872993974692,
        '104': 32.125528979218494,
        '105': 32.12573827438825,
        '106': 32.12644166294413,
        '107': 32.126440166872165,
        '108': 32.1248959333795,
        '109': 32.12620869408639,
        '110': 32.126389193473535,
        '111': 32.12398444661782,
        '112': 32.13060920830823,
        '113': 32.13020862674575,
        '114': 32.13148662821486,
        '115': 32.131680054056346,
        '116': 32.1256392857296,
        '117': 32.1320332507527,
        '118': 32.13244221478859,
        '119': 32.13257155776753,
        '120': 32.13273343828747,
        '121': 32.13266370818501,
        '122': 32.13277104625571,
        '123': 32.133032085013205,
        '124': 32.133227929590035,
        '125': 32.13312350499114,
        '126': 32.132903045466385,
        '127': 32.13301133207739,
        '128': 32.133534232531424,
        '129': 32.13390414643078,
        '130': 32.13346939676719,
        '131': 32.132391081671074,
        '132': 32.13168352841498,
        '133': 32.1320858088669,
        '134': 32.13306558983167,
        '135': 32.13341259834099,
        '136': 32.1328825264532,
        '137': 32.13266278398849,
        '138': 32.13372697143103,
        '139': 32.135147036733386,
        '140': 32.13491983026289,
        '141': 32.13259702718753,
        '142': 32.13024954715832,
        '143': 32.13019247459792,
        '144': 32.13223184925964,
        '145': 32.13399517101926,
        '146': 32.13385046214345,
        '147': 32.132575813831636,
        '148': 32.13188625748815,
        '149': 32.1321937547533,
        '150': 32.132573707504555,
        '151': 32.13243834848181,
        '152': 32.13222598031699,
        '153': 32.13239368674612,
        '154': 32.132633644887996,
        '155': 32.13252217867128,
        '156': 32.13232166279325,
        '157': 32.13252019891125,
        '158': 32.132915910286535,
        '159': 32.132840490949924,
        '160': 32.132217460707885,
        '161': 32.13174925902747,
        '162': 32.131907004756066,
        '163': 32.13228647102834,
        '164': 32.13227797240426,
        '165': 32.13194814552222,
        '166': 32.13181428197773,
        '167': 32.1319991258285,
        '168': 32.132124727328964,
        '169': 32.13199619513047,
        '170': 32.131899698336674,
        '171': 32.132089442823535,
        '172': 32.13236533341966,
        '173': 32.132398931126815,
        '174': 32.13221819606962,
        '175': 32.13209481844992,
        '176': 32.13211263255651,
        '177': 32.132132996361996,
        '178': 32.13210705877372,
        '179': 32.13213251367266,
        '180': 32.13219701657531,
        '-180': 32.13219701657531,
        '-179': 32.13213610095809,
        '-178': 32.13192875102767,
        '-177': 32.13181290205697,
        '-176': 32.13197212848855,
        '-175': 32.13226845043881,
        '-174': 32.13243875659413,
        '-173': 32.13243484752392,
        '-172': 32.13239640278733,
        '-171': 32.13237247432511,
        '-170': 32.13227633967168,
        '-169': 32.132104598010336,
        '-168': 32.132002638462524,
        '-167': 32.132058220174095,
        '-166': 32.13218442462549,
        '-165': 32.1322839873985,
        '-164': 32.13239627124348,
        '-163': 32.13257531705742,
        '-162': 32.13272989919208,
        '-161': 32.132725103102956,
        '-160': 32.13258673925287,
        '-159': 32.132463563877124,
        '-158': 32.13240893381728,
        '-157': 32.13234485818322,
        '-156': 32.132255127534215,
        '-155': 32.13226218718736,
        '-154': 32.13244002770122,
        '-153': 32.132667793911864,
        '-152': 32.1327750601539,
        '-151': 32.13275770088926,
        '-150': 32.13273159873352,
        '-149': 32.13271697241864,
        '-148': 32.132611093436246,
        '-147': 32.13239158878047,
        '-146': 32.13221551488958,
        '-145': 32.132237994280956,
        '-144': 32.13240654027086,
        '-143': 32.132526262766,
        '-142': 32.13250066142966,
        '-141': 32.13242377213119,
        '-140': 32.13242930420365,
        '-139': 32.13251998778143,
        '-138': 32.132583280375265,
        '-137': 32.1325386651305,
        '-136': 32.13242412720554,
        '-135': 32.13233828086915,
        '-134': 32.13232554169685,
        '-133': 32.13233635445519,
        '-132': 32.13229975846221,
        '-131': 32.13222021802899,
        '-130': 32.13218007664961,
        '-129': 32.1322366572601,
        '-128': 32.13233843499231,
        '-127': 32.132373958531815,
        '-126': 32.13230211720457,
        '-125': 32.132200961282415,
        '-124': 32.13217055463025,
        '-123': 32.1322097207152,
        '-122': 32.13221774100755,
        '-121': 32.13211966619118,
        '-120': 32.13197412209051,
        '-119': 32.13194345127777,
        '-118': 32.13214013824651,
        '-117': 32.13248395006044,
        '-116': 32.130160716500185,
        '-115': 32.12607075207028,
        '-114': 32.13234445107483,
        '-113': 32.131900606155256,
        '-112': 32.12655771373007,
        '-111': 32.119078339072594,
        '-110': 32.11450060379915,
        '-109': 32.11177760253558,
        '-108': 32.11913982554462,
        '-107': 32.12065312211399,
        '-106': 32.11885141436939,
        '-105': 32.1196551774546,
        '-104': 32.12005054134143,
        '-103': 32.1237008336904,
        '-102': 32.12564362892254,
        '-101': 32.124788009596514,
        '-100': 32.12616443650702,
        '-99': 32.12768102263132,
        '-98': 32.12977754398847,
        '-97': 32.131065765967826,
        '-96': 32.13128839427002,
        '-95': 32.13103166376444,
        '-94': 32.131022111438426,
        '-93': 32.131514815988155,
        '-92': 32.13215685466524,
        '-91': 32.131379690233274,
        '-90': 32.1315803935891,
        '-89': 32.132026833434786,
        '-88': 32.132634411483025,
        '-87': 32.13209034302298,
        '-86': 32.132582660359745,
        '-85': 32.13273109528098,
        '-84': 32.13223496862653,
        '-83': 32.13225171458073,
        '-82': 32.13243074968436,
        '-81': 32.132699058268926,
        '-80': 32.1326233604132,
        '-79': 32.13234064287419,
        '-78': 32.13201489026773,
        '-77': 32.13192790612244,
        '-76': 32.132047211636866,
        '-75': 32.13203877963076,
        '-74': 32.13173992203028,
        '-73': 32.13139431922571,
        '-72': 32.13131188318684,
        '-71': 32.13148048770717,
        '-70': 32.13167506861827,
        '-69': 32.131813439999846,
        '-68': 32.13199284232972,
        '-67': 32.13223363123886,
        '-66': 32.13241026340687,
        '-65': 32.13245904785474,
        '-64': 32.13246456349187,
        '-63': 32.13247523829206,
        '-62': 32.132396209965215,
        '-61': 32.132166494490484,
        '-60': 32.131912793502224,
        '-59': 32.13180009434647,
        '-58': 32.13182023495902,
        '-57': 32.1318606858418,
        '-56': 32.13190680056498,
        '-55': 32.13201511807336,
        '-54': 32.13212850836539,
        '-53': 32.1321103679279,
        '-52': 32.1319893099139,
        '-51': 32.13198799748301,
        '-50': 32.132222054501156,
        '-49': 32.13251540425031,
        '-48': 32.13263601151702,
        '-47': 32.13260688990459,
        '-46': 32.13263845126293,
        '-45': 32.13282373204138,
        '-44': 32.13306345046728,
        '-43': 32.133261389192434,
        '-42': 32.13341741164866,
        '-41': 32.13350960199248,
        '-40': 32.13346106324517,
        '-39': 32.13329650737857,
        '-38': 32.133182449709416,
        '-37': 32.133206061495315,
        '-36': 32.13323734789703,
        '-35': 32.13313926848683,
        '-34': 32.13300424872341,
        '-33': 32.133011415964496,
        '-32': 32.13312550631571,
        '-31': 32.13314903534484,
        '-30': 32.13305120389312,
        '-29': 32.13300272866263,
        '-28': 32.13303232200687,
        '-27': 32.132906590059825,
        '-26': 32.1325104012079,
        '-25': 32.13213420466966,
        '-24': 32.13213667227728,
        '-23': 32.13244346032274,
        '-22': 32.132657562515,
        '-21': 32.13261327904741,
        '-20': 32.132509469210476,
        '-19': 32.13246781695126,
        '-18': 32.13232072491627,
        '-17': 32.132024833677356,
        '-16': 32.131964703832985,
        '-15': 32.13248439404245,
        '-14': 32.13326654449973,
        '-13': 32.13360073352262,
        '-12': 32.13331725127327,
        '-11': 32.13308282187183,
        '-10': 32.13357357185446,
        '-9': 32.12291009520621,
        '-8': 32.113058006996184,
        '-7': 32.12303759240582,
        '-6': 32.11865588590568,
        '-5': 32.125462127937986,
        '-4': 32.12680588799567,
        '-3': 32.12593644517919,
        '-2': 32.126149469505656,
        '-1': 32.12649879025611
    },
    '32': {
        '0': 32.12754887283945,
        '1': 32.128777982324024,
        '2': 32.12937734137207,
        '3': 32.129754291531064,
        '4': 32.13108944378017,
        '5': 32.13245464264773,
        '6': 32.13282797103021,
        '7': 32.132885316908485,
        '8': 32.132921923972916,
        '9': 32.13275979778456,
        '10': 32.132275545487445,
        '11': 32.133405591158514,
        '12': 32.13283735075561,
        '13': 32.12739065805747,
        '14': 32.132783728503505,
        '15': 32.13505546007538,
        '16': 32.1352207358854,
        '17': 32.134886398833544,
        '18': 32.1348846927752,
        '19': 32.13556256067983,
        '20': 32.13663869452275,
        '21': 32.134483374754296,
        '22': 32.13585658731798,
        '23': 32.13467930472458,
        '24': 32.13446187872018,
        '25': 32.135165846399595,
        '26': 32.13476993332946,
        '27': 32.13463531650592,
        '28': 32.13484637016076,
        '29': 32.13539903006882,
        '30': 32.13597994463626,
        '31': 32.13613325456259,
        '32': 32.13573478097303,
        '33': 32.135215684114115,
        '34': 32.13518221597372,
        '35': 32.1336220889588,
        '36': 32.12914305026976,
        '37': 32.130471925048255,
        '38': 32.12902198909628,
        '39': 32.12711376731074,
        '40': 32.12787433479541,
        '41': 32.12940347414091,
        '42': 32.13203335683266,
        '43': 32.132632821591585,
        '44': 32.13403711622527,
        '45': 32.13381598742454,
        '46': 32.1332831676539,
        '47': 32.13284369692272,
        '48': 32.13159610765041,
        '49': 32.13252032311077,
        '50': 32.12159930897847,
        '51': 32.11024518286351,
        '52': 32.11707347056809,
        '53': 32.12167933563903,
        '54': 32.1240010866158,
        '55': 32.1255130188486,
        '56': 32.12221757728517,
        '57': 32.127019051037685,
        '58': 32.12881272199194,
        '59': 32.12256205851966,
        '60': 32.12207781847197,
        '61': 32.129191830496616,
        '62': 32.12961740236742,
        '63': 32.128240891933636,
        '64': 32.1252868390433,
        '65': 32.12425944038101,
        '66': 32.122235944640856,
        '67': 32.11833350289805,
        '68': 32.11389356790424,
        '69': 32.11512262484557,
        '70': 32.127065591107005,
        '71': 32.1318203724131,
        '72': 32.13112052382594,
        '73': 32.1313891441349,
        '74': 32.13136098389711,
        '75': 32.13120442260649,
        '76': 32.130400829646305,
        '77': 32.11028738138453,
        '78': 32.08583553285755,
        '79': 32.09209859335617,
        '80': 32.08253453532373,
        '81': 32.09011182194591,
        '82': 32.089718678243656,
        '83': 32.08585491887363,
        '84': 32.08912739067973,
        '85': 32.08840966129087,
        '86': 32.08522304798099,
        '87': 32.090701690199765,
        '88': 32.09030280029483,
        '89': 32.09058225056436,
        '90': 32.088683551375794,
        '91': 32.08860715970849,
        '92': 32.085533457352696,
        '93': 32.090182852781524,
        '94': 32.08954929634269,
        '95': 32.081924112957154,
        '96': 32.094539391506515,
        '97': 32.09452406303222,
        '98': 32.09156133205269,
        '99': 32.08996256899515,
        '100': 32.093907251187,
        '101': 32.10312693035357,
        '102': 32.10757705411709,
        '103': 32.10044531293289,
        '104': 32.11719204343458,
        '105': 32.127393747126895,
        '106': 32.125784017857576,
        '107': 32.125107820217856,
        '108': 32.12671992344946,
        '109': 32.114593529918146,
        '110': 32.12565870819366,
        '111': 32.12632267580926,
        '112': 32.132986040928664,
        '113': 32.1329091563543,
        '114': 32.132928273205955,
        '115': 32.13410232297451,
        '116': 32.13440213773258,
        '117': 32.13433110482102,
        '118': 32.13466976834513,
        '119': 32.13457857950414,
        '120': 32.13534606112129,
        '121': 32.135203305088815,
        '122': 32.1352229046156,
        '123': 32.135509296155476,
        '124': 32.135808785342384,
        '125': 32.13575496966185,
        '126': 32.1354876206391,
        '127': 32.13558016357953,
        '128': 32.13623981987222,
        '129': 32.136819026271695,
        '130': 32.13648211889928,
        '131': 32.13341618712605,
        '132': 32.1345505575784,
        '133': 32.13485728068505,
        '134': 32.13561694880159,
        '135': 32.135609009923854,
        '136': 32.134876841637364,
        '137': 32.13491445257823,
        '138': 32.13657768464366,
        '139': 32.138439193162604,
        '140': 32.13818266212983,
        '141': 32.135601781378085,
        '142': 32.1332233364939,
        '143': 32.1334170110142,
        '144': 32.13557568404632,
        '145': 32.13702174948358,
        '146': 32.136375521631955,
        '147': 32.13495082631383,
        '148': 32.1346322114271,
        '149': 32.135422406530125,
        '150': 32.13587796546513,
        '151': 32.1353478169182,
        '152': 32.13467542286062,
        '153': 32.13470031611582,
        '154': 32.1350873748978,
        '155': 32.13510594614198,
        '156': 32.134852439505536,
        '157': 32.134970623916196,
        '158': 32.1354896879645,
        '159': 32.13571866672564,
        '160': 32.13530924321476,
        '161': 32.13475602152155,
        '162': 32.13460831744306,
        '163': 32.13471026235242,
        '164': 32.134607472440216,
        '165': 32.13434024890438,
        '166': 32.13433238175669,
        '167': 32.134643596886896,
        '168': 32.134845510019986,
        '169': 32.13469395861826,
        '170': 32.13449266751169,
        '171': 32.13460003376717,
        '172': 32.134896215166165,
        '173': 32.13501039040909,
        '174': 32.13487622858201,
        '175': 32.13475783731011,
        '176': 32.13480710208761,
        '177': 32.134889345047654,
        '178': 32.13486677057125,
        '179': 32.134805774403766,
        '180': 32.13481496116734,
        '-180': 32.13481496116734,
        '-179': 32.13486392162597,
        '-178': 32.134881671758045,
        '-177': 32.13490069644153,
        '-176': 32.13497082448648,
        '-175': 32.13502291042789,
        '-174': 32.13495865327605,
        '-173': 32.13482271348064,
        '-172': 32.134747683185445,
        '-171': 32.13475187238523,
        '-170': 32.13472916857271,
        '-169': 32.13464593887619,
        '-168': 32.13461206694452,
        '-167': 32.13471217552572,
        '-166': 32.13488316484976,
        '-165': 32.13502879620263,
        '-164': 32.13515617585544,
        '-163': 32.135301592206496,
        '-162': 32.135395394562735,
        '-161': 32.13532812847987,
        '-160': 32.13513091587142,
        '-159': 32.134971663968926,
        '-158': 32.13495631487649,
        '-157': 32.135032465924944,
        '-156': 32.13511902848293,
        '-155': 32.13523016167328,
        '-154': 32.13540128429856,
        '-153': 32.13556485069177,
        '-152': 32.13559629461982,
        '-151': 32.13546244604954,
        '-150': 32.135246336296056,
        '-149': 32.135032112374795,
        '-148': 32.13484205196181,
        '-147': 32.13469765076837,
        '-146': 32.13466121203801,
        '-145': 32.13476317796987,
        '-144': 32.13492972875982,
        '-143': 32.135040181422674,
        '-142': 32.13504872062845,
        '-141': 32.13501332460688,
        '-140': 32.135009898252925,
        '-139': 32.13505063087377,
        '-138': 32.13508951266224,
        '-137': 32.13508193313636,
        '-136': 32.135028702852054,
        '-135': 32.134972479371505,
        '-134': 32.13495255615927,
        '-133': 32.134957317582575,
        '-132': 32.13493475836596,
        '-131': 32.13486832855296,
        '-130': 32.13482303433987,
        '-129': 32.13487299410118,
        '-128': 32.13498365830177,
        '-127': 32.135023021553536,
        '-126': 32.134923703298824,
        '-125': 32.13478760712295,
        '-124': 32.134765390284514,
        '-123': 32.134847800715846,
        '-122': 32.13485929946341,
        '-121': 32.13469618106753,
        '-120': 32.13450939384505,
        '-119': 32.134567779797905,
        '-118': 32.13493374643167,
        '-117': 32.135339110007585,
        '-116': 32.120806475224136,
        '-115': 32.13510320222206,
        '-114': 32.133354820200104,
        '-113': 32.1303529663336,
        '-112': 32.12807525574753,
        '-111': 32.12630649548879,
        '-110': 32.11801655576727,
        '-109': 32.1224715157824,
        '-108': 32.121350853795455,
        '-107': 32.12238050052904,
        '-106': 32.119184575901606,
        '-105': 32.124372627435534,
        '-104': 32.126731419089744,
        '-103': 32.12606834770895,
        '-102': 32.126801061379574,
        '-101': 32.1270763957115,
        '-100': 32.12893706105131,
        '-99': 32.12998986114081,
        '-98': 32.13150366051068,
        '-97': 32.13321897264531,
        '-96': 32.13435958590033,
        '-95': 32.13426966035183,
        '-94': 32.13456094309141,
        '-93': 32.13475086022215,
        '-92': 32.134918442311005,
        '-91': 32.1349136353087,
        '-90': 32.133838994401835,
        '-89': 32.13344695262017,
        '-88': 32.1342753080171,
        '-87': 32.134283663518474,
        '-86': 32.133878810524024,
        '-85': 32.134305446953405,
        '-84': 32.13473098153501,
        '-83': 32.13470782926375,
        '-82': 32.134939394658964,
        '-81': 32.13545026717509,
        '-80': 32.135247970573076,
        '-79': 32.13493349842543,
        '-78': 32.134705585913174,
        '-77': 32.134674750567314,
        '-76': 32.13465052977667,
        '-75': 32.134374118325965,
        '-74': 32.13391586286236,
        '-73': 32.13364270223751,
        '-72': 32.13375501331699,
        '-71': 32.13403947661157,
        '-70': 32.134183741200914,
        '-69': 32.134185767018344,
        '-68': 32.13428892751341,
        '-67': 32.13459786201375,
        '-66': 32.134951262991684,
        '-65': 32.135167328652216,
        '-64': 32.135235513733974,
        '-63': 32.13521665572437,
        '-62': 32.135109999913034,
        '-61': 32.13492075397747,
        '-60': 32.13475280472249,
        '-59': 32.13471011270232,
        '-58': 32.13475601954286,
        '-57': 32.134775163627445,
        '-56': 32.13473902636859,
        '-55': 32.13470743840079,
        '-54': 32.13469606351802,
        '-53': 32.134666053825185,
        '-52': 32.134646795772014,
        '-51': 32.134736962970656,
        '-50': 32.13493947679628,
        '-49': 32.135112817122,
        '-48': 32.13516191043919,
        '-47': 32.13518160637198,
        '-46': 32.13531353098743,
        '-45': 32.135537445553155,
        '-44': 32.135726363022265,
        '-43': 32.13585780638211,
        '-42': 32.13602130724007,
        '-41': 32.13621968072524,
        '-40': 32.1363192708447,
        '-39': 32.13624946781845,
        '-38': 32.13611878383006,
        '-37': 32.13604901045177,
        '-36': 32.13600564456815,
        '-35': 32.13590349716851,
        '-34': 32.135788370031136,
        '-33': 32.13576797015181,
        '-32': 32.135814547266236,
        '-31': 32.13580021156792,
        '-30': 32.13571588686483,
        '-29': 32.13567694632917,
        '-28': 32.13567862990791,
        '-27': 32.13553883734452,
        '-26': 32.135189649390576,
        '-25': 32.134857532921856,
        '-24': 32.13478790503289,
        '-23': 32.13491572107955,
        '-22': 32.13501995590584,
        '-21': 32.135117121129085,
        '-20': 32.13541294605848,
        '-19': 32.13584260141952,
        '-18': 32.135985303168106,
        '-17': 32.135627337355416,
        '-16': 32.13516851689971,
        '-15': 32.13516563582631,
        '-14': 32.13557592528589,
        '-13': 32.13579984684888,
        '-12': 32.135525020394226,
        '-11': 32.13521061364064,
        '-10': 32.13553475533509,
        '-9': 32.132029723075796,
        '-8': 32.13308109633995,
        '-7': 32.13086127434726,
        '-6': 32.11129014628731,
        '-5': 32.123964529467735,
        '-4': 32.1258382071713,
        '-3': 32.127915440509994,
        '-2': 32.125902570400356,
        '-1': 32.12690941399115
    },
    '33': {
        '0': 32.127516301859316,
        '1': 32.12900959733373,
        '2': 32.13049834913284,
        '3': 32.13099087274849,
        '4': 32.132053026741346,
        '5': 32.13470492032561,
        '6': 32.13568932229339,
        '7': 32.13531677922632,
        '8': 32.135899841284285,
        '9': 32.13625139839829,
        '10': 32.134506686977744,
        '11': 32.137903920346986,
        '12': 32.138157955672675,
        '13': 32.138133699445646,
        '14': 32.13823030944911,
        '15': 32.13815268825525,
        '16': 32.13773987714473,
        '17': 32.137325240819294,
        '18': 32.137479462507486,
        '19': 32.138365363567736,
        '20': 32.139445754865584,
        '21': 32.139914410326725,
        '22': 32.139397918482146,
        '23': 32.13819485039255,
        '24': 32.13688933583427,
        '25': 32.135865720328596,
        '26': 32.13523743874582,
        '27': 32.135090355230965,
        '28': 32.13555950995851,
        '29': 32.1365734540565,
        '30': 32.13765194348451,
        '31': 32.138174526121155,
        '32': 32.13796145487883,
        '33': 32.137524486305864,
        '34': 32.137604009709335,
        '35': 32.13840315623198,
        '36': 32.132510149381005,
        '37': 32.13251712345959,
        '38': 32.13237862548082,
        '39': 32.13088954919677,
        '40': 32.131094149351426,
        '41': 32.13315313252051,
        '42': 32.13453574836601,
        '43': 32.13628380232263,
        '44': 32.136305908013455,
        '45': 32.136279159728105,
        '46': 32.136570393821714,
        '47': 32.12959662654778,
        '48': 32.128885367674215,
        '49': 32.12630010678615,
        '50': 32.110641847473104,
        '51': 32.120096726584045,
        '52': 32.120067571689766,
        '53': 32.12028939714395,
        '54': 32.12602561519601,
        '55': 32.12576779641111,
        '56': 32.129023367311724,
        '57': 32.127585507236255,
        '58': 32.1286607091931,
        '59': 32.12192921975235,
        '60': 32.12184567353024,
        '61': 32.13037662459279,
        '62': 32.12636025838322,
        '63': 32.125372482345256,
        '64': 32.114844941144675,
        '65': 32.122454407746936,
        '66': 32.11612477543725,
        '67': 32.11066063444073,
        '68': 32.11862620358102,
        '69': 32.113441706843226,
        '70': 32.12657478892658,
        '71': 32.13071692389828,
        '72': 32.13131083588582,
        '73': 32.12998342444024,
        '74': 32.132703677685065,
        '75': 32.12486919756489,
        '76': 32.110251132382764,
        '77': 32.085530011058864,
        '78': 32.091868263886425,
        '79': 32.08722109827225,
        '80': 32.08134247840909,
        '81': 32.086667945550964,
        '82': 32.09024417117656,
        '83': 32.091737277262254,
        '84': 32.092228035883196,
        '85': 32.08974105483054,
        '86': 32.089181473502045,
        '87': 32.08848990263272,
        '88': 32.088453273098345,
        '89': 32.08877639741608,
        '90': 32.08750636237973,
        '91': 32.0855322129633,
        '92': 32.08697971466167,
        '93': 32.089088568936184,
        '94': 32.08920105333976,
        '95': 32.09267047980757,
        '96': 32.08871051424588,
        '97': 32.100225538842764,
        '98': 32.09427575183615,
        '99': 32.09068151217508,
        '100': 32.09550797780291,
        '101': 32.09384101489225,
        '102': 32.100643419131025,
        '103': 32.10344624292221,
        '104': 32.10346926212526,
        '105': 32.12159909536891,
        '106': 32.129597668619546,
        '107': 32.13131570680315,
        '108': 32.12365497565141,
        '109': 32.125977276667996,
        '110': 32.13278237947313,
        '111': 32.13302573296598,
        '112': 32.135434611045255,
        '113': 32.13577482616749,
        '114': 32.13622818635041,
        '115': 32.136941573782636,
        '116': 32.13722072198134,
        '117': 32.13743117114589,
        '118': 32.13753275640231,
        '119': 32.13800120963999,
        '120': 32.138167090957886,
        '121': 32.13808399971435,
        '122': 32.1380854057079,
        '123': 32.13831554600328,
        '124': 32.138540256134455,
        '125': 32.13846019281815,
        '126': 32.13821335106664,
        '127': 32.1383049891048,
        '128': 32.13890126176442,
        '129': 32.139426681985704,
        '130': 32.13422083568885,
        '131': 32.129283961471984,
        '132': 32.137978337240625,
        '133': 32.13798804811443,
        '134': 32.138908217798964,
        '135': 32.13846137882535,
        '136': 32.13747894169192,
        '137': 32.13755093663927,
        '138': 32.13926341499229,
        '139': 32.14092484603345,
        '140': 32.14034001822045,
        '141': 32.1376887074022,
        '142': 32.13566164926153,
        '143': 32.136335288035724,
        '144': 32.13867000354321,
        '145': 32.139888605841584,
        '146': 32.13890971472053,
        '147': 32.13737640104077,
        '148': 32.13719897014899,
        '149': 32.138144868311414,
        '150': 32.138571873021554,
        '151': 32.1378690487589,
        '152': 32.13708860615946,
        '153': 32.13721071784285,
        '154': 32.13780855001432,
        '155': 32.13790491835699,
        '156': 32.13745963512724,
        '157': 32.13726924686252,
        '158': 32.13766886748462,
        '159': 32.138091582659975,
        '160': 32.13798581892828,
        '161': 32.13754808834993,
        '162': 32.137265818881474,
        '163': 32.137187229889484,
        '164': 32.13705550258105,
        '165': 32.13689790781981,
        '166': 32.137001777600304,
        '167': 32.137361508542,
        '168': 32.1375894627937,
        '169': 32.1374811434773,
        '170': 32.13732526730268,
        '171': 32.13745955550558,
        '172': 32.13777244323253,
        '173': 32.13790360076633,
        '174': 32.13778750474918,
        '175': 32.137704065778316,
        '176': 32.1378207140938,
        '177': 32.137960741520374,
        '178': 32.1379035036475,
        '179': 32.137707947822875,
        '180': 32.137602698743116,
        '-180': 32.137602698743116,
        '-179': 32.13767700667086,
        '-178': 32.13781964121969,
        '-177': 32.13789763384469,
        '-176': 32.137866697895525,
        '-175': 32.13773710711667,
        '-174': 32.137554553756395,
        '-173': 32.137420277496,
        '-172': 32.13742676871611,
        '-171': 32.13754463410353,
        '-170': 32.13764771679507,
        '-169': 32.13767468852873,
        '-168': 32.13769181180277,
        '-167': 32.13776272305056,
        '-166': 32.13783653407717,
        '-165': 32.13783055969828,
        '-164': 32.13776410138128,
        '-163': 32.13772185969122,
        '-162': 32.13771093529622,
        '-161': 32.13765003995959,
        '-160': 32.13751383267483,
        '-159': 32.13740611792321,
        '-158': 32.13744573188352,
        '-157': 32.13762847001538,
        '-156': 32.137846370589564,
        '-155': 32.138008703458446,
        '-154': 32.13809398284427,
        '-153': 32.138103376252815,
        '-152': 32.13802533450659,
        '-151': 32.13786585635113,
        '-150': 32.13768119845185,
        '-149': 32.13755256913786,
        '-148': 32.137526575631284,
        '-147': 32.137580077470496,
        '-146': 32.13763905601338,
        '-145': 32.13764190290679,
        '-144': 32.13759920479219,
        '-143': 32.13758362270329,
        '-142': 32.137642999235595,
        '-141': 32.137734210561575,
        '-140': 32.13777185867751,
        '-139': 32.137736261562054,
        '-138': 32.1376914121432,
        '-137': 32.137686808453395,
        '-136': 32.137690324377616,
        '-135': 32.13765171415041,
        '-134': 32.13759072490084,
        '-133': 32.137565324585296,
        '-132': 32.13757333720024,
        '-131': 32.13755556181462,
        '-130': 32.13750199119237,
        '-129': 32.137480338606686,
        '-128': 32.1375219707058,
        '-127': 32.13754661902741,
        '-126': 32.13747235774098,
        '-125': 32.137359923512236,
        '-124': 32.13734913995321,
        '-123': 32.13744827477339,
        '-122': 32.137494513758384,
        '-121': 32.137386807586374,
        '-120': 32.13727555043534,
        '-119': 32.13740225006807,
        '-118': 32.13776019287409,
        '-117': 32.13403414242226,
        '-116': 32.13552512838818,
        '-115': 32.135983613424465,
        '-114': 32.13242374522773,
        '-113': 32.13496278980223,
        '-112': 32.133802803884805,
        '-111': 32.12763846243122,
        '-110': 32.129039884760736,
        '-109': 32.11863089130276,
        '-108': 32.11733269920092,
        '-107': 32.12398485400222,
        '-106': 32.12459968306613,
        '-105': 32.12256677138244,
        '-104': 32.12611875025585,
        '-103': 32.12690003384853,
        '-102': 32.128481522491285,
        '-101': 32.1301585015598,
        '-100': 32.13200976468694,
        '-99': 32.133366003867216,
        '-98': 32.133793804067615,
        '-97': 32.13632741223532,
        '-96': 32.13680324420347,
        '-95': 32.13689746453403,
        '-94': 32.1374750041643,
        '-93': 32.13746659616802,
        '-92': 32.13812737394788,
        '-91': 32.138140045374236,
        '-90': 32.137129201453405,
        '-89': 32.13629876361979,
        '-88': 32.13651210424304,
        '-87': 32.13623746244053,
        '-86': 32.1355937526037,
        '-85': 32.135741609465406,
        '-84': 32.13612225847942,
        '-83': 32.1371158966297,
        '-82': 32.137435415481214,
        '-81': 32.137830397309216,
        '-80': 32.13791981284169,
        '-79': 32.13777172124486,
        '-78': 32.13759584977321,
        '-77': 32.13747803083802,
        '-76': 32.13721596495942,
        '-75': 32.1367039295965,
        '-74': 32.136172947422935,
        '-73': 32.13599708036938,
        '-72': 32.13625721654141,
        '-71': 32.13663903418429,
        '-70': 32.13681955769602,
        '-69': 32.13683269533982,
        '-68': 32.136950377779414,
        '-67': 32.13729102103933,
        '-66': 32.13769129266257,
        '-65': 32.13794337473777,
        '-64': 32.138012796239565,
        '-63': 32.137984681911576,
        '-62': 32.13791608847666,
        '-61': 32.13782312079672,
        '-60': 32.13773936893588,
        '-59': 32.137691374019234,
        '-58': 32.137643302522584,
        '-57': 32.137542804647474,
        '-56': 32.13741222588471,
        '-55': 32.13733375102596,
        '-54': 32.13735218567886,
        '-53': 32.13744750746257,
        '-52': 32.13758916087183,
        '-51': 32.13774770156716,
        '-50': 32.137859003886774,
        '-49': 32.137867222079244,
        '-48': 32.137828444771074,
        '-47': 32.13788193494115,
        '-46': 32.13806462267697,
        '-45': 32.138236486431815,
        '-44': 32.13827434728466,
        '-43': 32.138273309331055,
        '-42': 32.13843405361772,
        '-41': 32.13877091934043,
        '-40': 32.139064823729655,
        '-39': 32.13913452059252,
        '-38': 32.1390353852254,
        '-37': 32.1389282207456,
        '-36': 32.13885950591232,
        '-35': 32.13877481123966,
        '-34': 32.13867531395989,
        '-33': 32.13862773020174,
        '-32': 32.13863874866209,
        '-31': 32.13864192493615,
        '-30': 32.13861460202695,
        '-29': 32.138590064848096,
        '-28': 32.13853218702655,
        '-27': 32.138330719998486,
        '-26': 32.1379865820232,
        '-25': 32.137677780328865,
        '-24': 32.13753773760728,
        '-23': 32.137484333637794,
        '-22': 32.137427188936336,
        '-21': 32.13754874929844,
        '-20': 32.13811996681921,
        '-19': 32.138997149690915,
        '-18': 32.13956375190484,
        '-17': 32.139362637496845,
        '-16': 32.13864455102482,
        '-15': 32.13806710539546,
        '-14': 32.137911200704735,
        '-13': 32.13784612446582,
        '-12': 32.137509375617284,
        '-11': 32.13706841034716,
        '-10': 32.137030166574085,
        '-9': 32.137624003693126,
        '-8': 32.136438358523755,
        '-7': 32.13174644078855,
        '-6': 32.12991709090233,
        '-5': 32.12031768765383,
        '-4': 32.13093759185138,
        '-3': 32.12423156738646,
        '-2': 32.12414883285121,
        '-1': 32.12567383595861
    },
    '34': {
        '0': 32.13150443862653,
        '1': 32.13037900008426,
        '2': 32.12673867928161,
        '3': 32.131966529361485,
        '4': 32.13447467590377,
        '5': 32.13645982825696,
        '6': 32.13929107508198,
        '7': 32.13934495362068,
        '8': 32.13987373784419,
        '9': 32.14016818983946,
        '10': 32.14043610046946,
        '11': 32.140500750492144,
        '12': 32.140526257503936,
        '13': 32.14079538804959,
        '14': 32.14104619885714,
        '15': 32.14095733098566,
        '16': 32.1405981051242,
        '17': 32.140398901405476,
        '18': 32.14063528251444,
        '19': 32.14103998982834,
        '20': 32.1410370852653,
        '21': 32.14035663950005,
        '22': 32.13932760178897,
        '23': 32.13851699462925,
        '24': 32.13813710792219,
        '25': 32.13789296217443,
        '26': 32.13742069367833,
        '27': 32.13679664402229,
        '28': 32.13652945226968,
        '29': 32.13702864312809,
        '30': 32.13812464858754,
        '31': 32.1391778582782,
        '32': 32.13969283319505,
        '33': 32.13979404393448,
        '34': 32.14003593276025,
        '35': 32.140740229372135,
        '36': 32.12841236390453,
        '37': 32.13182446963163,
        '38': 32.13489911210764,
        '39': 32.13458370697674,
        '40': 32.13645763493979,
        '41': 32.13739460286604,
        '42': 32.1379699154819,
        '43': 32.13913000557546,
        '44': 32.138767688959426,
        '45': 32.13888861839742,
        '46': 32.128960152420326,
        '47': 32.12333371240811,
        '48': 32.12496820191349,
        '49': 32.12038677378308,
        '50': 32.123919223132674,
        '51': 32.117746703648145,
        '52': 32.12644312065801,
        '53': 32.13165429067735,
        '54': 32.13305385610881,
        '55': 32.130438881855554,
        '56': 32.13343033802527,
        '57': 32.12852660905017,
        '58': 32.12894118149432,
        '59': 32.12665564880143,
        '60': 32.133347353165455,
        '61': 32.134313440439215,
        '62': 32.129165984645354,
        '63': 32.11371134564556,
        '64': 32.116379977840886,
        '65': 32.11526288548198,
        '66': 32.113323631698904,
        '67': 32.11153043840584,
        '68': 32.11244347419099,
        '69': 32.1211433336662,
        '70': 32.10880281403624,
        '71': 32.12404194259918,
        '72': 32.13504370884349,
        '73': 32.13119300030131,
        '74': 32.11521841409639,
        '75': 32.12505733977729,
        '76': 32.088631301286426,
        '77': 32.096840045478885,
        '78': 32.09157610927,
        '79': 32.09075177584566,
        '80': 32.08326393122474,
        '81': 32.0885511978607,
        '82': 32.084850151294745,
        '83': 32.09275455086554,
        '84': 32.08732179569485,
        '85': 32.0918501531763,
        '86': 32.091056210558506,
        '87': 32.09146320541142,
        '88': 32.08927341583676,
        '89': 32.08494656057377,
        '90': 32.090169331816895,
        '91': 32.091508465811756,
        '92': 32.093449633071344,
        '93': 32.096267534411034,
        '94': 32.09437359704028,
        '95': 32.09222106294654,
        '96': 32.093621655520195,
        '97': 32.094900985038336,
        '98': 32.09506950541432,
        '99': 32.09811675200128,
        '100': 32.096869343122336,
        '101': 32.09855363449588,
        '102': 32.10616115915646,
        '103': 32.10580162925236,
        '104': 32.11956615896019,
        '105': 32.12203352754821,
        '106': 32.124829763131096,
        '107': 32.12398337037539,
        '108': 32.12194162103823,
        '109': 32.130237073166064,
        '110': 32.129617684946744,
        '111': 32.13376866689321,
        '112': 32.13331625686966,
        '113': 32.1383773253777,
        '114': 32.13918261193465,
        '115': 32.13945143598793,
        '116': 32.139807497583675,
        '117': 32.139630872100845,
        '118': 32.140501622977766,
        '119': 32.14086041628984,
        '120': 32.14094350678047,
        '121': 32.14093005169876,
        '122': 32.14099528655408,
        '123': 32.14119588690203,
        '124': 32.14135937382247,
        '125': 32.1412994688123,
        '126': 32.14112166641561,
        '127': 32.14115646264241,
        '128': 32.141498943827905,
        '129': 32.14178464881279,
        '130': 32.14165175955521,
        '131': 32.14134393738719,
        '132': 32.140561538801954,
        '133': 32.14089645188572,
        '134': 32.13529551684339,
        '135': 32.14194415248686,
        '136': 32.137966252742466,
        '137': 32.14093687070318,
        '138': 32.142188960555295,
        '139': 32.143048132777245,
        '140': 32.141796349903004,
        '141': 32.13903654118046,
        '142': 32.13739972425919,
        '143': 32.138563373948394,
        '144': 32.14121571863296,
        '145': 32.142605318985105,
        '146': 32.14173110389056,
        '147': 32.14017488698308,
        '148': 32.139788307865686,
        '149': 32.1404809097556,
        '150': 32.14084178117423,
        '151': 32.14030297385611,
        '152': 32.13973970321616,
        '153': 32.13998288376813,
        '154': 32.14063440126028,
        '155': 32.14078630529854,
        '156': 32.14034838726437,
        '157': 32.140031453951885,
        '158': 32.140222989071304,
        '159': 32.14051228300399,
        '160': 32.14041191256856,
        '161': 32.140037992710496,
        '162': 32.13980976921194,
        '163': 32.13981847858633,
        '164': 32.13984532136314,
        '165': 32.13982268010628,
        '166': 32.13990015435841,
        '167': 32.14008446297764,
        '168': 32.14016165861127,
        '169': 32.140067317616854,
        '170': 32.140053245261974,
        '171': 32.14031354129097,
        '172': 32.14064500623315,
        '173': 32.14070572702214,
        '174': 32.140504288387646,
        '175': 32.14038552853327,
        '176': 32.14054091180289,
        '177': 32.14076390535775,
        '178': 32.14076799731448,
        '179': 32.14056782458406,
        '180': 32.14040845458498,
        '-180': 32.14040845458498,
        '-179': 32.140421714170486,
        '-178': 32.140507389356465,
        '-177': 32.14052089464571,
        '-176': 32.1404381903533,
        '-175': 32.140317926250844,
        '-174': 32.14021083996099,
        '-173': 32.14015397651957,
        '-172': 32.14017934800426,
        '-171': 32.140270604889174,
        '-170': 32.14035495913728,
        '-169': 32.140387057434,
        '-168': 32.14040612224031,
        '-167': 32.14046099173353,
        '-166': 32.14051114827291,
        '-165': 32.1404697338158,
        '-164': 32.140338370910975,
        '-163': 32.14022894365426,
        '-162': 32.14022660281858,
        '-161': 32.140284156369134,
        '-160': 32.14029573012142,
        '-159': 32.14024412921643,
        '-158': 32.14022049656262,
        '-157': 32.14029780363505,
        '-156': 32.14043136384974,
        '-155': 32.14050941598076,
        '-154': 32.140478502615245,
        '-153': 32.14038771646404,
        '-152': 32.14031495368377,
        '-151': 32.14028565305819,
        '-150': 32.140284848484434,
        '-149': 32.14032155492471,
        '-148': 32.14042691289534,
        '-147': 32.14057100170896,
        '-146': 32.140632291086064,
        '-145': 32.14051537300984,
        '-144': 32.140296203445075,
        '-143': 32.14018073494627,
        '-142': 32.14027958269564,
        '-141': 32.140470602133725,
        '-140': 32.14054349981593,
        '-139': 32.14045161106683,
        '-138': 32.14034034479048,
        '-137': 32.14032472149401,
        '-136': 32.140336618132906,
        '-135': 32.14025221025973,
        '-134': 32.140097542494225,
        '-133': 32.14002211714547,
        '-132': 32.140083743361885,
        '-131': 32.14016873859197,
        '-130': 32.14016034548121,
        '-129': 32.14009482887772,
        '-128': 32.140078156765625,
        '-127': 32.14011122594321,
        '-126': 32.140101042046595,
        '-125': 32.14002755402492,
        '-124': 32.13998704768751,
        '-123': 32.14003908736983,
        '-122': 32.140111892197474,
        '-121': 32.14013418195048,
        '-120': 32.14018237016815,
        '-119': 32.140373593340016,
        '-118': 32.13771526107292,
        '-117': 32.13040553920789,
        '-116': 32.127499020289584,
        '-115': 32.13648891917532,
        '-114': 32.13689898417186,
        '-113': 32.13309582015943,
        '-112': 32.12966839994864,
        '-111': 32.12388211741327,
        '-110': 32.1202513694683,
        '-109': 32.11384004741741,
        '-108': 32.11975145169173,
        '-107': 32.12408687976731,
        '-106': 32.12245705662854,
        '-105': 32.12545824172915,
        '-104': 32.127570495847955,
        '-103': 32.1282070497186,
        '-102': 32.129955222384424,
        '-101': 32.131376701537626,
        '-100': 32.1349605261726,
        '-99': 32.13653601183691,
        '-98': 32.13799299792392,
        '-97': 32.13858435070463,
        '-96': 32.138892394001104,
        '-95': 32.13905350880378,
        '-94': 32.13867640126864,
        '-93': 32.140107144414635,
        '-92': 32.140237003208725,
        '-91': 32.14071874710856,
        '-90': 32.14034782504229,
        '-89': 32.139580009886444,
        '-88': 32.138814748949684,
        '-87': 32.13850869783511,
        '-86': 32.139070340542006,
        '-85': 32.13777239534557,
        '-84': 32.13757071865278,
        '-83': 32.13889808623458,
        '-82': 32.13925867802141,
        '-81': 32.13986313510806,
        '-80': 32.14027975333153,
        '-79': 32.140295559225244,
        '-78': 32.14042513913363,
        '-77': 32.14041899266907,
        '-76': 32.140089920928936,
        '-75': 32.1395611974262,
        '-74': 32.13910429108807,
        '-73': 32.13901899670048,
        '-72': 32.139312636785974,
        '-71': 32.139688964537946,
        '-70': 32.1398779174676,
        '-69': 32.13990194181152,
        '-68': 32.139969976077275,
        '-67': 32.14017996554411,
        '-66': 32.140419645635035,
        '-65': 32.1405403684965,
        '-64': 32.14052929053147,
        '-63': 32.14047887401771,
        '-62': 32.14045494987927,
        '-61': 32.14044617969272,
        '-60': 32.14040891948338,
        '-59': 32.14031468115705,
        '-58': 32.14016919993354,
        '-57': 32.14002210566505,
        '-56': 32.13994900560553,
        '-55': 32.13999352948574,
        '-54': 32.14013100117274,
        '-53': 32.140305237448615,
        '-52': 32.140478887773924,
        '-51': 32.14062155513336,
        '-50': 32.14068660083715,
        '-49': 32.14066425719948,
        '-48': 32.14064276496183,
        '-47': 32.140731469133755,
        '-46': 32.140900828658204,
        '-45': 32.14098439784102,
        '-44': 32.14090072598487,
        '-43': 32.140805290862076,
        '-42': 32.140925960444875,
        '-41': 32.14127456209041,
        '-40': 32.14162555518639,
        '-39': 32.14178816131475,
        '-38': 32.14179558196705,
        '-37': 32.14178368538834,
        '-36': 32.14178408349349,
        '-35': 32.14173033829991,
        '-34': 32.14161183289793,
        '-33': 32.14150900210624,
        '-32': 32.141479802468076,
        '-31': 32.14150050184636,
        '-30': 32.141526841605256,
        '-29': 32.14152856872348,
        '-28': 32.14144647607955,
        '-27': 32.14121444112623,
        '-26': 32.140876864698406,
        '-25': 32.14059372596904,
        '-24': 32.14044735144046,
        '-23': 32.140328185450784,
        '-22': 32.140140963054506,
        '-21': 32.140067979337246,
        '-20': 32.140428045629704,
        '-19': 32.14121125396096,
        '-18': 32.141922127438185,
        '-17': 32.14204012972734,
        '-16': 32.141569154093254,
        '-15': 32.14098273279621,
        '-14': 32.140651770524634,
        '-13': 32.14049201404601,
        '-12': 32.14021497874416,
        '-11': 32.1397628221542,
        '-10': 32.139366042419894,
        '-9': 32.139258748301565,
        '-8': 32.1394933390125,
        '-7': 32.14001940757804,
        '-6': 32.1380550629957,
        '-5': 32.1371750940776,
        '-4': 32.13204265145501,
        '-3': 32.13047183508273,
        '-2': 32.13273370746091,
        '-1': 32.130372150275655
    },
    '35': {
        '0': 32.137439189621865,
        '1': 32.13471999544151,
        '2': 32.13278685810659,
        '3': 32.13614938394728,
        '4': 32.133685047064496,
        '5': 32.13831384225002,
        '6': 32.138546268480184,
        '7': 32.131471036997645,
        '8': 32.133125734727734,
        '9': 32.1371706888468,
        '10': 32.14210083568659,
        '11': 32.14318453794319,
        '12': 32.14329201222388,
        '13': 32.143695285169294,
        '14': 32.14390609450583,
        '15': 32.14373274261847,
        '16': 32.143522593701626,
        '17': 32.14366788517596,
        '18': 32.14397385700293,
        '19': 32.14373301247305,
        '20': 32.14252798297888,
        '21': 32.140868659537794,
        '22': 32.13987667913223,
        '23': 32.140260757999016,
        '24': 32.14160767893542,
        '25': 32.140380478413356,
        '26': 32.14254704645247,
        '27': 32.14120284086967,
        '28': 32.13966812638677,
        '29': 32.13905667782161,
        '30': 32.13974079421061,
        '31': 32.141168375913686,
        '32': 32.142439949761645,
        '33': 32.135836681361816,
        '34': 32.14239319265957,
        '35': 32.14356908520147,
        '36': 32.14036022897842,
        '37': 32.13997979780667,
        '38': 32.135138385623385,
        '39': 32.14021217327197,
        '40': 32.14174205621295,
        '41': 32.14222119618789,
        '42': 32.14066154893414,
        '43': 32.1419856707069,
        '44': 32.14079461740689,
        '45': 32.137934866040034,
        '46': 32.13341466730982,
        '47': 32.12752928366504,
        '48': 32.127559243880235,
        '49': 32.12953326540422,
        '50': 32.12975035890213,
        '51': 32.13674385414211,
        '52': 32.13679573283158,
        '53': 32.136595014595144,
        '54': 32.13405152115617,
        '55': 32.136426597207866,
        '56': 32.13645925840496,
        '57': 32.135886257689336,
        '58': 32.13548274392426,
        '59': 32.133933997466535,
        '60': 32.12016550771172,
        '61': 32.13657639286301,
        '62': 32.134828900529726,
        '63': 32.13545400532841,
        '64': 32.13261246340183,
        '65': 32.11565438495554,
        '66': 32.11525856782849,
        '67': 32.10938210066499,
        '68': 32.120955451938485,
        '69': 32.120543407435314,
        '70': 32.12356791658549,
        '71': 32.11673099827371,
        '72': 32.12882676630346,
        '73': 32.118374159854866,
        '74': 32.105124555529116,
        '75': 32.100664425777104,
        '76': 32.102565028538265,
        '77': 32.09242391844384,
        '78': 32.10071501625361,
        '79': 32.08916866850601,
        '80': 32.0950694370306,
        '81': 32.09397389523088,
        '82': 32.09468412442346,
        '83': 32.095919217520645,
        '84': 32.093325676782285,
        '85': 32.09410598151523,
        '86': 32.0928791831904,
        '87': 32.094799953743184,
        '88': 32.09214418880413,
        '89': 32.091326356147384,
        '90': 32.09345782993279,
        '91': 32.09232979157453,
        '92': 32.09475469872755,
        '93': 32.098168086195585,
        '94': 32.09496125097563,
        '95': 32.096644809092986,
        '96': 32.096959372521546,
        '97': 32.09978645800618,
        '98': 32.10093778647912,
        '99': 32.09601513136373,
        '100': 32.104388814294104,
        '101': 32.10294677976516,
        '102': 32.105116736927904,
        '103': 32.111392743256935,
        '104': 32.11583383950283,
        '105': 32.12310363783487,
        '106': 32.12743431663506,
        '107': 32.129641730315164,
        '108': 32.131091736840915,
        '109': 32.134200346307885,
        '110': 32.1372399660221,
        '111': 32.13955524478026,
        '112': 32.13683666259925,
        '113': 32.141963799458814,
        '114': 32.14204953785771,
        '115': 32.14209786476193,
        '116': 32.14252306838711,
        '117': 32.14292459439922,
        '118': 32.14271862522342,
        '119': 32.14313623906846,
        '120': 32.14371076317009,
        '121': 32.14365170422151,
        '122': 32.14367821195457,
        '123': 32.143818082434045,
        '124': 32.143991114216654,
        '125': 32.144092392018905,
        '126': 32.144123917397735,
        '127': 32.142413149835534,
        '128': 32.14410655830984,
        '129': 32.14427929152536,
        '130': 32.144077687852196,
        '131': 32.143946893227636,
        '132': 32.14426523334396,
        '133': 32.139092711604064,
        '134': 32.142267424807166,
        '135': 32.14420419340231,
        '136': 32.14307358591075,
        '137': 32.1445768450552,
        '138': 32.14055137440444,
        '139': 32.143401907458205,
        '140': 32.144298823053035,
        '141': 32.14147945929717,
        '142': 32.13982310917205,
        '143': 32.14079625004008,
        '144': 32.143322601013445,
        '145': 32.144997940403044,
        '146': 32.144705581859455,
        '147': 32.14349049880347,
        '148': 32.14290874658892,
        '149': 32.143200174088825,
        '150': 32.143470145685015,
        '151': 32.14318295453853,
        '152': 32.14276807077019,
        '153': 32.14277384487538,
        '154': 32.143064569614374,
        '155': 32.143174164557166,
        '156': 32.14306379237326,
        '157': 32.14308142340769,
        '158': 32.143310729325805,
        '159': 32.14340338893732,
        '160': 32.143123219458644,
        '161': 32.142715017512096,
        '162': 32.142558395112374,
        '163': 32.14268229874479,
        '164': 32.14283376105877,
        '165': 32.14287728581111,
        '166': 32.14288046343458,
        '167': 32.14287153799869,
        '168': 32.14278001990703,
        '169': 32.14265700107451,
        '170': 32.14271520277724,
        '171': 32.14301862902992,
        '172': 32.14329993067123,
        '173': 32.14326690600321,
        '174': 32.14300409396447,
        '175': 32.14287823490341,
        '176': 32.14306237012282,
        '177': 32.14334027351314,
        '178': 32.14342970588927,
        '179': 32.14333273466119,
        '180': 32.143251924058994,
        '-180': 32.143251924058994,
        '-179': 32.143272605858726,
        '-178': 32.14328756805313,
        '-177': 32.14320642428775,
        '-176': 32.14308682031243,
        '-175': 32.14301950991576,
        '-174': 32.14299387657567,
        '-173': 32.142946956309366,
        '-172': 32.14287656894432,
        '-171': 32.14282692198806,
        '-170': 32.142800265479465,
        '-169': 32.142765633551484,
        '-168': 32.14274496288035,
        '-167': 32.14280655186062,
        '-166': 32.14294954861963,
        '-165': 32.143065113831085,
        '-164': 32.14306503626455,
        '-163': 32.14300768620815,
        '-162': 32.14303047698812,
        '-161': 32.1431672659971,
        '-160': 32.14329938626713,
        '-159': 32.14330891330944,
        '-158': 32.143225042888645,
        '-157': 32.14316872300069,
        '-156': 32.14318196288013,
        '-155': 32.14318180932525,
        '-154': 32.143096195745045,
        '-153': 32.1429817985372,
        '-152': 32.14295400206932,
        '-151': 32.1430305632024,
        '-150': 32.14311457503551,
        '-149': 32.14313782995323,
        '-148': 32.143147915981245,
        '-147': 32.143206384187856,
        '-146': 32.143257681678705,
        '-145': 32.143189175634575,
        '-144': 32.143015864661685,
        '-143': 32.14290700260694,
        '-142': 32.14297752200334,
        '-141': 32.1431233999205,
        '-140': 32.14314994927515,
        '-139': 32.14303022812509,
        '-138': 32.14292857442752,
        '-137': 32.142951815646676,
        '-136': 32.14298902228579,
        '-135': 32.14287892935209,
        '-134': 32.14266437991087,
        '-133': 32.14255774821787,
        '-132': 32.14265764918996,
        '-131': 32.14281861477316,
        '-130': 32.14285329862398,
        '-129': 32.14277215464581,
        '-128': 32.14273104922135,
        '-127': 32.142791426011584,
        '-126': 32.14284841823013,
        '-125': 32.14280478701526,
        '-124': 32.142711674633745,
        '-123': 32.14268627899887,
        '-122': 32.14276731320114,
        '-121': 32.142923626567104,
        '-120': 32.12968172204984,
        '-119': 32.13867650602933,
        '-118': 32.13566842434459,
        '-117': 32.134938969351275,
        '-116': 32.13626455411373,
        '-115': 32.13427283799572,
        '-114': 32.1286127742727,
        '-113': 32.1236500658867,
        '-112': 32.12710464825549,
        '-111': 32.126834206608194,
        '-110': 32.12719181539853,
        '-109': 32.124547842388615,
        '-108': 32.11984823523879,
        '-107': 32.12726242645991,
        '-106': 32.125005608927985,
        '-105': 32.1273846859609,
        '-104': 32.13005066248889,
        '-103': 32.12988925923754,
        '-102': 32.131751923189896,
        '-101': 32.13403819163239,
        '-100': 32.13711657792784,
        '-99': 32.13876302665739,
        '-98': 32.139934399587254,
        '-97': 32.14045759403324,
        '-96': 32.14076179356315,
        '-95': 32.141034988611054,
        '-94': 32.140202151753435,
        '-93': 32.140592420444506,
        '-92': 32.14262147106982,
        '-91': 32.143038091526,
        '-90': 32.14265421404939,
        '-89': 32.14199091591011,
        '-88': 32.141818127533824,
        '-87': 32.141950675687035,
        '-86': 32.13917071486834,
        '-85': 32.140756171431875,
        '-84': 32.13666508080403,
        '-83': 32.13836351151991,
        '-82': 32.140821222731546,
        '-81': 32.14178442147801,
        '-80': 32.14243366452642,
        '-79': 32.142905246774696,
        '-78': 32.14304601589846,
        '-77': 32.14328460095913,
        '-76': 32.143129554441245,
        '-75': 32.142749544909435,
        '-74': 32.14244485449493,
        '-73': 32.14239147731932,
        '-72': 32.142570768556084,
        '-71': 32.14278717629994,
        '-70': 32.14286643675204,
        '-69': 32.14281297682098,
        '-68': 32.14275970454075,
        '-67': 32.14279558943158,
        '-66': 32.142881985873515,
        '-65': 32.14292957375608,
        '-64': 32.1429132558085,
        '-63': 32.142889913366105,
        '-62': 32.142920752920354,
        '-61': 32.14299638154152,
        '-60': 32.14304002847959,
        '-59': 32.14298498532868,
        '-58': 32.14285488877377,
        '-57': 32.1427597587095,
        '-56': 32.14279277122214,
        '-55': 32.14293197042495,
        '-54': 32.14306898115985,
        '-53': 32.14313741838002,
        '-52': 32.143176412425255,
        '-51': 32.143249965445726,
        '-50': 32.14334943596361,
        '-49': 32.14342565864764,
        '-48': 32.143487778376596,
        '-47': 32.143594013833294,
        '-46': 32.143737023793484,
        '-45': 32.14381780701197,
        '-44': 32.14378137334103,
        '-43': 32.1437216926922,
        '-42': 32.143781199008885,
        '-41': 32.143973320043486,
        '-40': 32.14418176579778,
        '-39': 32.14433001017891,
        '-38': 32.14446319186634,
        '-37': 32.144630524827704,
        '-36': 32.1447672710167,
        '-35': 32.14476617407338,
        '-34': 32.14462658988262,
        '-33': 32.144457037560024,
        '-32': 32.144343350968235,
        '-31': 32.144284406699136,
        '-30': 32.14425349959521,
        '-29': 32.14423949277846,
        '-28': 32.144202162489016,
        '-27': 32.14407006541894,
        '-26': 32.14384364094864,
        '-25': 32.14363824048168,
        '-24': 32.14354045618299,
        '-23': 32.143460889501895,
        '-22': 32.14324237428474,
        '-21': 32.14292465017087,
        '-20': 32.1427841231857,
        '-19': 32.14303444986103,
        '-18': 32.143553051316246,
        '-17': 32.143993558353685,
        '-16': 32.14413999618614,
        '-15': 32.14406201607291,
        '-14': 32.14393238904976,
        '-13': 32.143801625951866,
        '-12': 32.14359834406202,
        '-11': 32.14326508294573,
        '-10': 32.1428095796192,
        '-9': 32.142271779332916,
        '-8': 32.141751718852724,
        '-7': 32.14147545680633,
        '-6': 32.1408418893188,
        '-5': 32.131411401756516,
        '-4': 32.134143604868406,
        '-3': 32.142604181775916,
        '-2': 32.143016599229874,
        '-1': 32.13817870105842
    },
    '36': {
        '0': 32.14655753751416,
        '1': 32.14587935581837,
        '2': 32.140658038232935,
        '3': 32.13778684209594,
        '4': 32.13584962855703,
        '5': 32.13675166778258,
        '6': 32.13685810784027,
        '7': 32.13784302165286,
        '8': 32.14156488391619,
        '9': 32.14107787294294,
        '10': 32.14532851511417,
        '11': 32.14650215105034,
        '12': 32.14675127647671,
        '13': 32.1471112875877,
        '14': 32.14702034200606,
        '15': 32.146499401286796,
        '16': 32.14614497668324,
        '17': 32.14633753436599,
        '18': 32.14663536059349,
        '19': 32.146182833207945,
        '20': 32.14475847339025,
        '21': 32.14326597297091,
        '22': 32.1430320260273,
        '23': 32.14456812116788,
        '24': 32.14701668155744,
        '25': 32.14878679740348,
        '26': 32.148763492490204,
        '27': 32.147077555158376,
        '28': 32.14492573986173,
        '29': 32.14368436380248,
        '30': 32.14398376392451,
        '31': 32.145373985425124,
        '32': 32.146793650814026,
        '33': 32.14744805273988,
        '34': 32.1473435785361,
        '35': 32.147037023641865,
        '36': 32.1416722142967,
        '37': 32.14386281686073,
        '38': 32.1432332871169,
        '39': 32.144127742258206,
        '40': 32.14332459571834,
        '41': 32.14383322359388,
        '42': 32.143537850718204,
        '43': 32.14326199068267,
        '44': 32.14228979025212,
        '45': 32.14084116745485,
        '46': 32.127741852763755,
        '47': 32.12778498722361,
        '48': 32.13353787116885,
        '49': 32.126897875270544,
        '50': 32.136105183230995,
        '51': 32.12437950951687,
        '52': 32.11400571790596,
        '53': 32.12441067514157,
        '54': 32.13362917311028,
        '55': 32.13651442953199,
        '56': 32.13427285102933,
        '57': 32.13847988114583,
        '58': 32.135879024984284,
        '59': 32.13540119416686,
        '60': 32.1369967142771,
        '61': 32.14081085130482,
        '62': 32.14024383346223,
        '63': 32.139856770570255,
        '64': 32.13815595199676,
        '65': 32.13669128513341,
        '66': 32.132798770534734,
        '67': 32.129806885719944,
        '68': 32.1257317893188,
        '69': 32.13424822492646,
        '70': 32.11049300022057,
        '71': 32.10224620757903,
        '72': 32.12418208191721,
        '73': 32.104251784658544,
        '74': 32.106725336208285,
        '75': 32.100643490149906,
        '76': 32.09862805245316,
        '77': 32.090808028656554,
        '78': 32.1022980942281,
        '79': 32.09301383511632,
        '80': 32.11229381846876,
        '81': 32.101841039500364,
        '82': 32.103124278758315,
        '83': 32.096165093577916,
        '84': 32.094940189239814,
        '85': 32.09555405826698,
        '86': 32.097567103506904,
        '87': 32.098571309283734,
        '88': 32.09838697850089,
        '89': 32.09762944189613,
        '90': 32.09626081150966,
        '91': 32.09362977305759,
        '92': 32.097434079218715,
        '93': 32.09880261607742,
        '94': 32.100943443547834,
        '95': 32.10251016303479,
        '96': 32.10631500011086,
        '97': 32.10195672612013,
        '98': 32.11164982878411,
        '99': 32.10006428870802,
        '100': 32.11264287316848,
        '101': 32.11500775996361,
        '102': 32.125500236456986,
        '103': 32.12401777153691,
        '104': 32.12791592876669,
        '105': 32.128935187686636,
        '106': 32.12466503623783,
        '107': 32.13116964709042,
        '108': 32.133801308191224,
        '109': 32.134077934025434,
        '110': 32.133594284965234,
        '111': 32.132185798580586,
        '112': 32.1340180781349,
        '113': 32.135656415224084,
        '114': 32.14250971100749,
        '115': 32.14515705591239,
        '116': 32.14532919538653,
        '117': 32.145284275496664,
        '118': 32.1425150939604,
        '119': 32.14490471122546,
        '120': 32.14572486122258,
        '121': 32.14654657043499,
        '122': 32.14643694452393,
        '123': 32.1464135835189,
        '124': 32.146532018897524,
        '125': 32.14676632223613,
        '126': 32.14702584150214,
        '127': 32.146989984087895,
        '128': 32.14454111454366,
        '129': 32.14574772006794,
        '130': 32.14681253033519,
        '131': 32.146494163576385,
        '132': 32.14647581159427,
        '133': 32.14678316425686,
        '134': 32.147082390983606,
        '135': 32.14711719029534,
        '136': 32.142452826847496,
        '137': 32.13735325142699,
        '138': 32.13996676723913,
        '139': 32.14536514005041,
        '140': 32.14824102352504,
        '141': 32.14589779556978,
        '142': 32.14386846674334,
        '143': 32.14373239271202,
        '144': 32.145250351577026,
        '145': 32.14684084426199,
        '146': 32.147285431358114,
        '147': 32.14675972708896,
        '148': 32.14621496037935,
        '149': 32.14616321910433,
        '150': 32.14634007754295,
        '151': 32.14631762166776,
        '152': 32.146033233256496,
        '153': 32.14568103246837,
        '154': 32.145409208453515,
        '155': 32.145300810610635,
        '156': 32.14545510354037,
        '157': 32.14585753394561,
        '158': 32.14624172753818,
        '159': 32.14629491543626,
        '160': 32.146016474032564,
        '161': 32.145716886065344,
        '162': 32.14563214470547,
        '163': 32.14568918896029,
        '164': 32.14571083057471,
        '165': 32.14569069884431,
        '166': 32.14572677848306,
        '167': 32.14579169409017,
        '168': 32.14576180822668,
        '169': 32.145655199051696,
        '170': 32.14564962651232,
        '171': 32.14580660980443,
        '172': 32.14593845523124,
        '173': 32.14586694434678,
        '174': 32.14570306517284,
        '175': 32.145710181635636,
        '176': 32.145932511767434,
        '177': 32.1461319825384,
        '178': 32.146122390318425,
        '179': 32.14600989714775,
        '180': 32.14600838420174,
        '-180': 32.14600838420174,
        '-179': 32.146128277834045,
        '-178': 32.14618911837566,
        '-177': 32.146097275710446,
        '-176': 32.145960282902756,
        '-175': 32.145900656878496,
        '-174': 32.14588514174651,
        '-173': 32.145815844716545,
        '-172': 32.14570294342568,
        '-171': 32.14563771172005,
        '-170': 32.145630767915,
        '-169': 32.145596829015496,
        '-168': 32.14551761577563,
        '-167': 32.1455113976402,
        '-166': 32.14567016738971,
        '-165': 32.14589453310034,
        '-164': 32.14598862212414,
        '-163': 32.14590599147988,
        '-162': 32.145807643950135,
        '-161': 32.14584863408015,
        '-160': 32.14598643167756,
        '-159': 32.146064761603924,
        '-158': 32.14603588927936,
        '-157': 32.14600391434404,
        '-156': 32.146046677797536,
        '-155': 32.14609366135279,
        '-154': 32.14604117077573,
        '-153': 32.14592617041029,
        '-152': 32.145889598986734,
        '-151': 32.145972668239956,
        '-150': 32.14604490478551,
        '-149': 32.14597942012605,
        '-148': 32.14583062063745,
        '-147': 32.1457601963305,
        '-146': 32.145817538063376,
        '-145': 32.145886244894534,
        '-144': 32.145860413338056,
        '-143': 32.145782672288554,
        '-142': 32.14574993059574,
        '-141': 32.14575112002821,
        '-140': 32.14569664406905,
        '-139': 32.145589068848786,
        '-138': 32.1455514330393,
        '-137': 32.14564445627514,
        '-136': 32.145746085480454,
        '-135': 32.14569996738293,
        '-134': 32.14553924681622,
        '-133': 32.145457193484056,
        '-132': 32.145549306242295,
        '-131': 32.14568540755838,
        '-130': 32.145690617436266,
        '-129': 32.145577495694695,
        '-128': 32.145509448495304,
        '-127': 32.14556520605416,
        '-126': 32.14564243899089,
        '-125': 32.145616906273844,
        '-124': 32.14551512079395,
        '-123': 32.145476129536405,
        '-122': 32.14559353504191,
        '-121': 32.14152095734815,
        '-120': 32.14420915280296,
        '-119': 32.14487671053176,
        '-118': 32.12697250996516,
        '-117': 32.133777234316504,
        '-116': 32.13815874675871,
        '-115': 32.13786647069416,
        '-114': 32.13663671723162,
        '-113': 32.12693758114646,
        '-112': 32.124298304276344,
        '-111': 32.12877318846707,
        '-110': 32.12594977507354,
        '-109': 32.12334553114204,
        '-108': 32.12721675660804,
        '-107': 32.12482561521473,
        '-106': 32.12886632306899,
        '-105': 32.12529404723432,
        '-104': 32.12941720738424,
        '-103': 32.132663575467326,
        '-102': 32.13451015356581,
        '-101': 32.13680959100729,
        '-100': 32.138293261178625,
        '-99': 32.14054988227145,
        '-98': 32.143244184799656,
        '-97': 32.14339046287566,
        '-96': 32.14386607280885,
        '-95': 32.14299396816908,
        '-94': 32.142040586503924,
        '-93': 32.14346256727415,
        '-92': 32.14402402746457,
        '-91': 32.14562192797552,
        '-90': 32.14562014150389,
        '-89': 32.14541505894154,
        '-88': 32.145373419403974,
        '-87': 32.14413426626412,
        '-86': 32.14423422713295,
        '-85': 32.14087084814433,
        '-84': 32.14296409975778,
        '-83': 32.14171965273837,
        '-82': 32.13517532123217,
        '-81': 32.142055603380584,
        '-80': 32.14380958928739,
        '-79': 32.14516207519646,
        '-78': 32.14579367263061,
        '-77': 32.14612116224806,
        '-76': 32.146062223108764,
        '-75': 32.145786373364295,
        '-74': 32.145563033080016,
        '-73': 32.14545081051316,
        '-72': 32.14544490862591,
        '-71': 32.145477455311735,
        '-70': 32.145468063484934,
        '-69': 32.145395812424965,
        '-68': 32.14532346349206,
        '-67': 32.145336674801314,
        '-66': 32.14545185106684,
        '-65': 32.1455933568375,
        '-64': 32.14567373399854,
        '-63': 32.14569095570201,
        '-62': 32.145725267885915,
        '-61': 32.14583075978435,
        '-60': 32.14595321657189,
        '-59': 32.14598843887049,
        '-58': 32.14591939746028,
        '-57': 32.14585265794106,
        '-56': 32.14589083332535,
        '-55': 32.14599941293916,
        '-54': 32.146050565632066,
        '-53': 32.145994867359036,
        '-52': 32.14593131392974,
        '-51': 32.14597850855552,
        '-50': 32.146122517327555,
        '-49': 32.14624610476958,
        '-48': 32.146288335119365,
        '-47': 32.146310796486596,
        '-46': 32.14639588133974,
        '-45': 32.14653843057746,
        '-44': 32.14667263051462,
        '-43': 32.14676295343064,
        '-42': 32.14681983399797,
        '-41': 32.14685362486228,
        '-40': 32.1468760136291,
        '-39': 32.14694258143626,
        '-38': 32.147123682546884,
        '-37': 32.14740152178998,
        '-36': 32.147644718304264,
        '-35': 32.14772957558426,
        '-34': 32.147658897318,
        '-33': 32.14752109729166,
        '-32': 32.14736580903366,
        '-31': 32.1471869225559,
        '-30': 32.14701628422713,
        '-29': 32.14694238427466,
        '-28': 32.14699154323427,
        '-27': 32.14705499078129,
        '-26': 32.14700629761285,
        '-25': 32.14686180700068,
        '-24': 32.14674327354128,
        '-23': 32.146679506904995,
        '-22': 32.14653438823441,
        '-21': 32.14618977857519,
        '-20': 32.145755608770266,
        '-19': 32.145525692908954,
        '-18': 32.14571367345675,
        '-17': 32.14625724966018,
        '-16': 32.146870748311585,
        '-15': 32.14725545938142,
        '-14': 32.14727934328938,
        '-13': 32.147030296269946,
        '-12': 32.14673542570476,
        '-11': 32.146577036577696,
        '-10': 32.146517077915874,
        '-9': 32.146311736167,
        '-8': 32.14577294355349,
        '-7': 32.145049519315855,
        '-6': 32.14459523884643,
        '-5': 32.14477969971894,
        '-4': 32.14551119001954,
        '-3': 32.14628660713961,
        '-2': 32.14665321880135,
        '-1': 32.146615008270906
    },
    '37': {
        '0': 32.148936638776924,
        '1': 32.14895676248518,
        '2': 32.14928917760368,
        '3': 32.14956199425817,
        '4': 32.14954657255158,
        '5': 32.149434378851986,
        '6': 32.149560142424825,
        '7': 32.14992970591782,
        '8': 32.150181201112474,
        '9': 32.149553300522484,
        '10': 32.14974251552019,
        '11': 32.14963154928686,
        '12': 32.15025303439747,
        '13': 32.15056454182278,
        '14': 32.150270208717245,
        '15': 32.14457258147955,
        '16': 32.148772596818574,
        '17': 32.148553426095724,
        '18': 32.148530435466235,
        '19': 32.14816291495974,
        '20': 32.14743718905916,
        '21': 32.14706291650653,
        '22': 32.14780627370581,
        '23': 32.148327050566124,
        '24': 32.15179800629901,
        '25': 32.15307482180891,
        '26': 32.15294313751905,
        '27': 32.14925869119458,
        '28': 32.15017272988605,
        '29': 32.1419820502098,
        '30': 32.132937484571684,
        '31': 32.15001744186608,
        '32': 32.12602726580704,
        '33': 32.137328417977116,
        '34': 32.12923915472129,
        '35': 32.14958754636435,
        '36': 32.148367361069674,
        '37': 32.14144319533012,
        '38': 32.14566941874032,
        '39': 32.145350359394556,
        '40': 32.14450872008014,
        '41': 32.144867270989494,
        '42': 32.145138933929985,
        '43': 32.1426887275209,
        '44': 32.13237352347723,
        '45': 32.13425505798286,
        '46': 32.13815256947169,
        '47': 32.130546964381615,
        '48': 32.138315441585846,
        '49': 32.13513180880074,
        '50': 32.14184090006563,
        '51': 32.1498281632727,
        '52': 32.14971496530648,
        '53': 32.149703699242515,
        '54': 32.149717332409416,
        '55': 32.149455281936454,
        '56': 32.1373070938677,
        '57': 32.14060241287427,
        '58': 32.12741425030381,
        '59': 32.12949840097353,
        '60': 32.14239380765643,
        '61': 32.14601472226718,
        '62': 32.14464968377956,
        '63': 32.14298557401976,
        '64': 32.14313868313772,
        '65': 32.14453323042204,
        '66': 32.14440518527574,
        '67': 32.14347717771436,
        '68': 32.14255404737856,
        '69': 32.137908081332455,
        '70': 32.13547688715797,
        '71': 32.12311051366829,
        '72': 32.09851220284056,
        '73': 32.10299125420653,
        '74': 32.098829375473414,
        '75': 32.099464157495305,
        '76': 32.11471962950042,
        '77': 32.119457992527344,
        '78': 32.10705317471591,
        '79': 32.12699941016644,
        '80': 32.133811300068935,
        '81': 32.1339340667249,
        '82': 32.133097251050444,
        '83': 32.13256648067916,
        '84': 32.124376482012195,
        '85': 32.10709498140159,
        '86': 32.100790143854255,
        '87': 32.10747042992753,
        '88': 32.104520635547296,
        '89': 32.1068055774548,
        '90': 32.10878307596124,
        '91': 32.10534231617134,
        '92': 32.11065390374724,
        '93': 32.11795184901171,
        '94': 32.119528098372896,
        '95': 32.11988643563784,
        '96': 32.12025456161076,
        '97': 32.117890361235894,
        '98': 32.11862467648659,
        '99': 32.109695952693585,
        '100': 32.11673123615414,
        '101': 32.117946673798585,
        '102': 32.119346754888795,
        '103': 32.119538524403694,
        '104': 32.12839872159049,
        '105': 32.13114674460489,
        '106': 32.133855641691596,
        '107': 32.13353541922229,
        '108': 32.13323114927269,
        '109': 32.13510288852252,
        '110': 32.13830730867358,
        '111': 32.13582527005591,
        '112': 32.139036442247445,
        '113': 32.13839346841047,
        '114': 32.14414234673152,
        '115': 32.148256757477135,
        '116': 32.148256599205375,
        '117': 32.14861502281913,
        '118': 32.14906150768393,
        '119': 32.14942649247286,
        '120': 32.147543088069646,
        '121': 32.148607310738086,
        '122': 32.14945289799477,
        '123': 32.149324524151595,
        '124': 32.14930455304895,
        '125': 32.14947760947877,
        '126': 32.14975062519381,
        '127': 32.14993563703474,
        '128': 32.14810991359023,
        '129': 32.14266684245094,
        '130': 32.14983546164622,
        '131': 32.14941257109482,
        '132': 32.14908901541308,
        '133': 32.14907058105063,
        '134': 32.149256350796904,
        '135': 32.14938513525504,
        '136': 32.149491231901045,
        '137': 32.1464299530384,
        '138': 32.14713197647812,
        '139': 32.14086295565077,
        '140': 32.148595636657014,
        '141': 32.151159278527054,
        '142': 32.14898749029182,
        '143': 32.147504274531116,
        '144': 32.14754384703099,
        '145': 32.148626143404144,
        '146': 32.14958537786418,
        '147': 32.1497096425514,
        '148': 32.14921363855706,
        '149': 32.14876634695079,
        '150': 32.1487580897182,
        '151': 32.14903076899221,
        '152': 32.14916439780222,
        '153': 32.14892388563041,
        '154': 32.14846203473985,
        '155': 32.148148213994425,
        '156': 32.14820177105656,
        '157': 32.148487334655016,
        '158': 32.14869150171132,
        '159': 32.14868434722563,
        '160': 32.14862737069934,
        '161': 32.14869675373814,
        '162': 32.14880854579849,
        '163': 32.14874099893863,
        '164': 32.14848606257059,
        '165': 32.148314617537864,
        '166': 32.148436468069974,
        '167': 32.14872308564781,
        '168': 32.14887860099397,
        '169': 32.148807617617344,
        '170': 32.148672649479586,
        '171': 32.148616380777895,
        '172': 32.14859817808068,
        '173': 32.14855972240996,
        '174': 32.148594079924884,
        '175': 32.14880248913176,
        '176': 32.14905715639917,
        '177': 32.14908766655149,
        '178': 32.14883066870839,
        '179': 32.14854935736058,
        '180': 32.14852310644514,
        '-180': 32.14852310644514,
        '-179': 32.14871639011326,
        '-178': 32.148865155973176,
        '-177': 32.14883037178939,
        '-176': 32.148724896372016,
        '-175': 32.14869078571681,
        '-174': 32.14870223264678,
        '-173': 32.14867004639314,
        '-172': 32.14863265240351,
        '-171': 32.14870416638776,
        '-170': 32.14886177953659,
        '-169': 32.148930359371874,
        '-168': 32.14882548104346,
        '-167': 32.148700369686196,
        '-166': 32.148753197122666,
        '-165': 32.148945168666316,
        '-164': 32.14903061255073,
        '-163': 32.14887280595394,
        '-162': 32.14861949879723,
        '-161': 32.148508020576465,
        '-160': 32.148577666166034,
        '-159': 32.14867163531095,
        '-158': 32.1486870804087,
        '-157': 32.1486988545142,
        '-156': 32.14879931413517,
        '-155': 32.148920755740335,
        '-154': 32.14891854554136,
        '-153': 32.1487923548249,
        '-152': 32.14870930121204,
        '-151': 32.14877964100855,
        '-150': 32.14890075979575,
        '-149': 32.14889476119249,
        '-148': 32.14874931925049,
        '-147': 32.148628715806126,
        '-146': 32.14864932030448,
        '-145': 32.14873528178614,
        '-144': 32.14873754731634,
        '-143': 32.14862731508288,
        '-142': 32.14850040515486,
        '-141': 32.148425675845445,
        '-140': 32.148378722753606,
        '-139': 32.14833430032075,
        '-138': 32.14833761611089,
        '-137': 32.14842818912344,
        '-136': 32.14854525920681,
        '-135': 32.14858539748705,
        '-134': 32.1485420734326,
        '-133': 32.14851456340932,
        '-132': 32.148561846196785,
        '-131': 32.14861372243984,
        '-130': 32.14857213417861,
        '-129': 32.148455086398684,
        '-128': 32.14837404275587,
        '-127': 32.14838202324139,
        '-126': 32.14841383820498,
        '-125': 32.148396689459084,
        '-124': 32.14836514907837,
        '-123': 32.14842557323425,
        '-122': 32.1477859485327,
        '-121': 32.14700589385939,
        '-120': 32.14834249542139,
        '-119': 32.1279981380787,
        '-118': 32.12402446110856,
        '-117': 32.13475517327328,
        '-116': 32.13678387255787,
        '-115': 32.140706509444655,
        '-114': 32.14225417361832,
        '-113': 32.13387822002809,
        '-112': 32.13396266859791,
        '-111': 32.13510899967355,
        '-110': 32.133805979478524,
        '-109': 32.13443746792477,
        '-108': 32.129086069709274,
        '-107': 32.124231739305834,
        '-106': 32.12552812577166,
        '-105': 32.12234240299887,
        '-104': 32.13080513284098,
        '-103': 32.1354862072071,
        '-102': 32.13732999607948,
        '-101': 32.13951446681117,
        '-100': 32.14243576080846,
        '-99': 32.14326770019449,
        '-98': 32.1450997189781,
        '-97': 32.1451829554546,
        '-96': 32.1458139539116,
        '-95': 32.14629443975833,
        '-94': 32.14504769947316,
        '-93': 32.14518528750836,
        '-92': 32.145273529903186,
        '-91': 32.14729676272944,
        '-90': 32.14809184544846,
        '-89': 32.14770603003117,
        '-88': 32.14761004881633,
        '-87': 32.147544357427755,
        '-86': 32.146621359468405,
        '-85': 32.14620601002583,
        '-84': 32.14520824058609,
        '-83': 32.14322679964028,
        '-82': 32.14251092529442,
        '-81': 32.14142914669224,
        '-80': 32.1450523141489,
        '-79': 32.14778888087395,
        '-78': 32.14838359807201,
        '-77': 32.14881906014316,
        '-76': 32.148902690037815,
        '-75': 32.1486581946754,
        '-74': 32.14843490956955,
        '-73': 32.148224172599924,
        '-72': 32.14807070499644,
        '-71': 32.148024151006055,
        '-70': 32.14805924340856,
        '-69': 32.148098149451485,
        '-68': 32.1481190614416,
        '-67': 32.14819388988259,
        '-66': 32.148383557089474,
        '-65': 32.14862543297333,
        '-64': 32.148779065995654,
        '-63': 32.148788702269975,
        '-62': 32.148750719445665,
        '-61': 32.14878720343595,
        '-60': 32.14888619760051,
        '-59': 32.14892199123732,
        '-58': 32.1488306290632,
        '-57': 32.14870442582476,
        '-56': 32.14867787531582,
        '-55': 32.14876164701041,
        '-54': 32.14884876553673,
        '-53': 32.148874067655086,
        '-52': 32.14889700913371,
        '-51': 32.14899477183699,
        '-50': 32.149126274395286,
        '-49': 32.14916754953094,
        '-48': 32.149074600433856,
        '-47': 32.14895350111875,
        '-46': 32.148949232681005,
        '-45': 32.14910165684523,
        '-44': 32.149332695195184,
        '-43': 32.14953961184816,
        '-42': 32.14966406503143,
        '-41': 32.14969673986788,
        '-40': 32.14967330480523,
        '-39': 32.149674191974775,
        '-38': 32.14977938887991,
        '-37': 32.14999244725497,
        '-36': 32.1502313929993,
        '-35': 32.150414642667535,
        '-34': 32.15052815935941,
        '-33': 32.15058128006947,
        '-32': 32.15053695854549,
        '-31': 32.1503573539203,
        '-30': 32.15012040152882,
        '-29': 32.15000905167899,
        '-28': 32.15012205757195,
        '-27': 32.15032876610964,
        '-26': 32.15038392993053,
        '-25': 32.15019426134267,
        '-24': 32.14991340439077,
        '-23': 32.149743213733636,
        '-22': 32.149686699380325,
        '-21': 32.149557984083074,
        '-20': 32.149237776731,
        '-19': 32.14887057629989,
        '-18': 32.14876959669319,
        '-17': 32.14911478006,
        '-16': 32.14973935089064,
        '-15': 32.150217350076396,
        '-14': 32.15020024428093,
        '-13': 32.14973043983299,
        '-12': 32.14924688601271,
        '-11': 32.14922828012355,
        '-10': 32.1497508512133,
        '-9': 32.15037622737692,
        '-8': 32.15052659624997,
        '-7': 32.150027560272335,
        '-6': 32.149254905587455,
        '-5': 32.14398883261003,
        '-4': 32.1389806088221,
        '-3': 32.14101154645775,
        '-2': 32.14795553427726,
        '-1': 32.149263294323376
    },
    '38': {
        '0': 32.15205587275524,
        '1': 32.15180438126349,
        '2': 32.15194617665375,
        '3': 32.152123966681934,
        '4': 32.15202854364435,
        '5': 32.15176702199195,
        '6': 32.15169654813068,
        '7': 32.151958478886954,
        '8': 32.15231079967264,
        '9': 32.15245647627665,
        '10': 32.15242484981209,
        '11': 32.15250889325892,
        '12': 32.152850501720536,
        '13': 32.15152544509284,
        '14': 32.14955127526957,
        '15': 32.14267735514489,
        '16': 32.14431095269979,
        '17': 32.151423949674026,
        '18': 32.15073735901649,
        '19': 32.15021714175725,
        '20': 32.1501571846562,
        '21': 32.150829559108814,
        '22': 32.14148308658036,
        '23': 32.14428302672506,
        '24': 32.15395968745252,
        '25': 32.15462896721146,
        '26': 32.15439879340409,
        '27': 32.15405747005286,
        '28': 32.14656748110773,
        '29': 32.1522524438844,
        '30': 32.144310110805705,
        '31': 32.140611102126364,
        '32': 32.138836717704,
        '33': 32.141410700937485,
        '34': 32.141997433512294,
        '35': 32.13529746149871,
        '36': 32.138353589050915,
        '37': 32.139476048606596,
        '38': 32.13961173212942,
        '39': 32.1473363429241,
        '40': 32.14474904200148,
        '41': 32.14612018064763,
        '42': 32.139894204993176,
        '43': 32.13179929352164,
        '44': 32.13248811274002,
        '45': 32.138899874206466,
        '46': 32.14150072531861,
        '47': 32.13815879257969,
        '48': 32.1374049247788,
        '49': 32.15261236623735,
        '50': 32.15107440664712,
        '51': 32.15014647730578,
        '52': 32.14998070347305,
        '53': 32.150278535454646,
        '54': 32.150750597991454,
        '55': 32.150682154101155,
        '56': 32.1475943261935,
        '57': 32.13917189502054,
        '58': 32.146809681903285,
        '59': 32.150618536645965,
        '60': 32.14980273254494,
        '61': 32.149019429588904,
        '62': 32.148380235339154,
        '63': 32.148296131332245,
        '64': 32.14814250409691,
        '65': 32.148105765782894,
        '66': 32.14516945969862,
        '67': 32.14203095488569,
        '68': 32.142057105220275,
        '69': 32.13890936098875,
        '70': 32.135777490489545,
        '71': 32.10828971510462,
        '72': 32.11457721283477,
        '73': 32.10792793165138,
        '74': 32.1132562406813,
        '75': 32.11430384258545,
        '76': 32.1199345349833,
        '77': 32.13519538065626,
        '78': 32.13644941147607,
        '79': 32.136554226832004,
        '80': 32.136711253554566,
        '81': 32.13694690338353,
        '82': 32.137288709415984,
        '83': 32.13747445154939,
        '84': 32.13795425503606,
        '85': 32.13826282772442,
        '86': 32.137101870415236,
        '87': 32.118596828769846,
        '88': 32.11116542130678,
        '89': 32.11389989224951,
        '90': 32.11788205807882,
        '91': 32.12243910304095,
        '92': 32.12302532359476,
        '93': 32.122505868460756,
        '94': 32.12182822493522,
        '95': 32.118321509011146,
        '96': 32.10899307430186,
        '97': 32.10880578023632,
        '98': 32.11009568390235,
        '99': 32.10742608970226,
        '100': 32.114593912782105,
        '101': 32.11316344667449,
        '102': 32.12549983766147,
        '103': 32.13518901471333,
        '104': 32.13514252348215,
        '105': 32.13653925751259,
        '106': 32.13959297211843,
        '107': 32.13701436225841,
        '108': 32.13744311170079,
        '109': 32.13928760621278,
        '110': 32.14088786915772,
        '111': 32.14151283221398,
        '112': 32.14105797566317,
        '113': 32.13949988465185,
        '114': 32.14881551337001,
        '115': 32.15104906224178,
        '116': 32.1511885419411,
        '117': 32.15130740301024,
        '118': 32.15160833249256,
        '119': 32.15192606625794,
        '120': 32.15225108917712,
        '121': 32.152475303716415,
        '122': 32.152505947895456,
        '123': 32.15241224630265,
        '124': 32.152356649402684,
        '125': 32.152409181450345,
        '126': 32.15205236694984,
        '127': 32.15182608634932,
        '128': 32.149211409804444,
        '129': 32.15274742001886,
        '130': 32.15266510138769,
        '131': 32.152407932986385,
        '132': 32.15219677515937,
        '133': 32.15229047167009,
        '134': 32.152581829684266,
        '135': 32.152618346437485,
        '136': 32.15221273871029,
        '137': 32.15191630803033,
        '138': 32.15256783362859,
        '139': 32.15422718662458,
        '140': 32.15261639238212,
        '141': 32.15574117294906,
        '142': 32.15395186095998,
        '143': 32.151687055269086,
        '144': 32.15060285682667,
        '145': 32.151185213908015,
        '146': 32.152435846040945,
        '147': 32.15299318264213,
        '148': 32.15243220454032,
        '149': 32.151461096911696,
        '150': 32.15101179139784,
        '151': 32.151312460475545,
        '152': 32.15185048292923,
        '153': 32.15206647421672,
        '154': 32.15190577953321,
        '155': 32.15168447737416,
        '156': 32.15158789511839,
        '157': 32.15148260694596,
        '158': 32.15122638253505,
        '159': 32.150983633129165,
        '160': 32.15106586587607,
        '161': 32.151487359165245,
        '162': 32.15185453023019,
        '163': 32.151793954287236,
        '164': 32.15139826063594,
        '165': 32.1511141592836,
        '166': 32.15121081074684,
        '167': 32.15150026888491,
        '168': 32.151625241315706,
        '169': 32.15148931298686,
        '170': 32.15128642470739,
        '171': 32.15118883944017,
        '172': 32.151181794295596,
        '173': 32.151219963026286,
        '174': 32.1513674046286,
        '175': 32.15165573347168,
        '176': 32.151902501014824,
        '177': 32.15184972808117,
        '178': 32.151498237121984,
        '179': 32.15116277956147,
        '180': 32.15112249043961,
        '-180': 32.15112249043961,
        '-179': 32.15131100744518,
        '-178': 32.151448477056704,
        '-177': 32.151412638299036,
        '-176': 32.15134288692666,
        '-175': 32.15138367574414,
        '-174': 32.15147894426071,
        '-173': 32.151504650573614,
        '-172': 32.15149734700483,
        '-171': 32.1516052442883,
        '-170': 32.151832620647035,
        '-169': 32.15198210082363,
        '-168': 32.15191279320159,
        '-167': 32.15175058010341,
        '-166': 32.15173213988273,
        '-165': 32.15188488519075,
        '-164': 32.15198880437214,
        '-163': 32.1518759528555,
        '-162': 32.15165338047117,
        '-161': 32.1515499574552,
        '-160': 32.1516139959563,
        '-159': 32.151679959100385,
        '-158': 32.15162599759851,
        '-157': 32.15153986492348,
        '-156': 32.15156534345039,
        '-155': 32.15167010505241,
        '-154': 32.15168098321376,
        '-153': 32.15153735591733,
        '-152': 32.15139642819738,
        '-151': 32.15143501986467,
        '-150': 32.15161975297264,
        '-149': 32.15175545145279,
        '-148': 32.15173208034407,
        '-147': 32.1516320316648,
        '-146': 32.15157761306685,
        '-145': 32.15155844129798,
        '-144': 32.15147571180303,
        '-143': 32.151313248069556,
        '-142': 32.15117760474512,
        '-141': 32.15115834645525,
        '-140': 32.15121643118693,
        '-139': 32.151250445119345,
        '-138': 32.151231476517225,
        '-137': 32.15121931006158,
        '-136': 32.15126004723495,
        '-135': 32.15132329471801,
        '-134': 32.15135367770027,
        '-133': 32.15134285415856,
        '-132': 32.15132267129032,
        '-131': 32.15131147615822,
        '-130': 32.15129916895398,
        '-129': 32.15127507498467,
        '-128': 32.15124074087306,
        '-127': 32.15119807574585,
        '-126': 32.15115572777836,
        '-125': 32.1511542673897,
        '-124': 32.15125536363483,
        '-123': 32.151039330588866,
        '-122': 32.15139415878916,
        '-121': 32.15163687784661,
        '-120': 32.135858015143434,
        '-119': 32.132824581172954,
        '-118': 32.138378323967096,
        '-117': 32.135996914769414,
        '-116': 32.137024905419736,
        '-115': 32.135365802051034,
        '-114': 32.130655274820406,
        '-113': 32.13358318964601,
        '-112': 32.12978830933603,
        '-111': 32.13326333579478,
        '-110': 32.13308632389507,
        '-109': 32.12916304076559,
        '-108': 32.1268101718388,
        '-107': 32.11520177923396,
        '-106': 32.12996240246102,
        '-105': 32.12959726809518,
        '-104': 32.13870053482539,
        '-103': 32.13962924085411,
        '-102': 32.14121519889636,
        '-101': 32.14263358588359,
        '-100': 32.1440025948185,
        '-99': 32.14541925044123,
        '-98': 32.14673310227885,
        '-97': 32.147193707717136,
        '-96': 32.148058821106595,
        '-95': 32.148280500002656,
        '-94': 32.14921567661362,
        '-93': 32.14903367921907,
        '-92': 32.14918713784094,
        '-91': 32.14830762527133,
        '-90': 32.15090600448234,
        '-89': 32.150844773275985,
        '-88': 32.150886848321065,
        '-87': 32.150320876853016,
        '-86': 32.150218739026556,
        '-85': 32.14916841920094,
        '-84': 32.14862234146949,
        '-83': 32.14893026375453,
        '-82': 32.14923049288105,
        '-81': 32.14637746314276,
        '-80': 32.14349455088033,
        '-79': 32.14779443178321,
        '-78': 32.15095378782925,
        '-77': 32.15153137890864,
        '-76': 32.15178047767629,
        '-75': 32.151618799036456,
        '-74': 32.15142708794661,
        '-73': 32.15116631450704,
        '-72': 32.150937661544134,
        '-71': 32.15087803758416,
        '-70': 32.15098199555692,
        '-69': 32.15109995005153,
        '-68': 32.15112542630864,
        '-67': 32.15112229820587,
        '-66': 32.15122192288976,
        '-65': 32.15143249542603,
        '-64': 32.15161876595994,
        '-63': 32.151673173637114,
        '-62': 32.15164005723342,
        '-61': 32.15162839849286,
        '-60': 32.15164560036475,
        '-59': 32.15159005917342,
        '-58': 32.1514127701637,
        '-57': 32.15122050767157,
        '-56': 32.151174227204194,
        '-55': 32.15131563265245,
        '-54': 32.151543028560205,
        '-53': 32.15174548495081,
        '-52': 32.15190131847604,
        '-51': 32.152027978810864,
        '-50': 32.15209455860106,
        '-49': 32.15204559942217,
        '-48': 32.1518994048831,
        '-47': 32.15176469463839,
        '-46': 32.15173941249769,
        '-45': 32.151825892979616,
        '-44': 32.151965273039856,
        '-43': 32.152120800571105,
        '-42': 32.1522878152775,
        '-41': 32.15244023689281,
        '-40': 32.15252294535717,
        '-39': 32.15251735646722,
        '-38': 32.152483702774546,
        '-37': 32.152513283222106,
        '-36': 32.15265313175306,
        '-35': 32.152890561474045,
        '-34': 32.153181217266805,
        '-33': 32.153453755539076,
        '-32': 32.153607013979745,
        '-31': 32.153572086677045,
        '-30': 32.15341096034338,
        '-29': 32.15330725025701,
        '-28': 32.15339258563873,
        '-27': 32.153578039226126,
        '-26': 32.153612044111256,
        '-25': 32.1533422387801,
        '-24': 32.15289825484959,
        '-23': 32.152574634036384,
        '-22': 32.152528018429024,
        '-21': 32.152620009986784,
        '-20': 32.15258163298312,
        '-19': 32.152323800148054,
        '-18': 32.15205716701976,
        '-17': 32.152079441851285,
        '-16': 32.15243408052686,
        '-15': 32.15279717810543,
        '-14': 32.15275110893755,
        '-13': 32.15222123469484,
        '-12': 32.15164404113368,
        '-11': 32.1516387180919,
        '-10': 32.152431427976396,
        '-9': 32.15357983038695,
        '-8': 32.15258855297327,
        '-7': 32.14965297921687,
        '-6': 32.14946973690237,
        '-5': 32.14853620522637,
        '-4': 32.150326775721354,
        '-3': 32.14571651500142,
        '-2': 32.14396963052302,
        '-1': 32.152015459625915
    },
    '39': {
        '0': 32.15540345729542,
        '1': 32.15513915921037,
        '2': 32.15523230102192,
        '3': 32.15536068514587,
        '4': 32.15519211975906,
        '5': 32.15479770149349,
        '6': 32.15456790598539,
        '7': 32.15474676535724,
        '8': 32.15514094735328,
        '9': 32.155241582280325,
        '10': 32.15513717133152,
        '11': 32.15479757139212,
        '12': 32.15474147003449,
        '13': 32.15517816632674,
        '14': 32.15590507934439,
        '15': 32.15643035634318,
        '16': 32.15628350348693,
        '17': 32.15382326306058,
        '18': 32.154011008635436,
        '19': 32.153063873431236,
        '20': 32.15313848908851,
        '21': 32.154202055502296,
        '22': 32.14805980692208,
        '23': 32.15270086591289,
        '24': 32.15609835392379,
        '25': 32.15561915282428,
        '26': 32.15545564176292,
        '27': 32.155797998692336,
        '28': 32.14641614208238,
        '29': 32.14613266221557,
        '30': 32.14362470088813,
        '31': 32.14593222704289,
        '32': 32.14723298578129,
        '33': 32.145750647563496,
        '34': 32.14673166970488,
        '35': 32.14459706500738,
        '36': 32.14243552837002,
        '37': 32.13978100896334,
        '38': 32.13542728938468,
        '39': 32.14679617008047,
        '40': 32.14583344907814,
        '41': 32.13967658006528,
        '42': 32.1422982155503,
        '43': 32.13574270490861,
        '44': 32.13489097494658,
        '45': 32.1434028459803,
        '46': 32.13833857643799,
        '47': 32.147949124248825,
        '48': 32.14410050780483,
        '49': 32.153818408056196,
        '50': 32.151826214813475,
        '51': 32.15095633069447,
        '52': 32.15135961815756,
        '53': 32.1523842296897,
        '54': 32.15330807529084,
        '55': 32.152832700046936,
        '56': 32.148322664127264,
        '57': 32.153600752542054,
        '58': 32.15322466316365,
        '59': 32.15266298442925,
        '60': 32.15223896020835,
        '61': 32.15206847201033,
        '62': 32.151976027317524,
        '63': 32.15201463567871,
        '64': 32.15208459147949,
        '65': 32.15151383943644,
        '66': 32.1504503847753,
        '67': 32.14658198422984,
        '68': 32.12473823934651,
        '69': 32.118626884030235,
        '70': 32.1320030188255,
        '71': 32.12040906301676,
        '72': 32.10733630865837,
        '73': 32.105783686925705,
        '74': 32.1089069101968,
        '75': 32.11584907456159,
        '76': 32.13909720139691,
        '77': 32.14026058359776,
        '78': 32.140160517578266,
        '79': 32.14022494150311,
        '80': 32.14049998940236,
        '81': 32.14100223836907,
        '82': 32.141254107559476,
        '83': 32.14135196153513,
        '84': 32.14123267003083,
        '85': 32.14092233511592,
        '86': 32.140800067489636,
        '87': 32.14198907812113,
        '88': 32.14352055827616,
        '89': 32.13579471321114,
        '90': 32.12231913720934,
        '91': 32.11968125626234,
        '92': 32.120569163516045,
        '93': 32.1252461040511,
        '94': 32.12224010389116,
        '95': 32.12028077603847,
        '96': 32.12306170236802,
        '97': 32.11843975521623,
        '98': 32.12022026352292,
        '99': 32.114600895096146,
        '100': 32.135031155083844,
        '101': 32.13192520191005,
        '102': 32.13978426673973,
        '103': 32.13905539509529,
        '104': 32.140098302046304,
        '105': 32.14005864365836,
        '106': 32.12881551569639,
        '107': 32.14223251293065,
        '108': 32.140289232763465,
        '109': 32.14035982425771,
        '110': 32.14172083882291,
        '111': 32.14647850505356,
        '112': 32.139305913139395,
        '113': 32.14234917963,
        '114': 32.14637195003188,
        '115': 32.15135588505903,
        '116': 32.15442008067276,
        '117': 32.154366718224736,
        '118': 32.15435508210051,
        '119': 32.15449330267697,
        '120': 32.15478937281272,
        '121': 32.15509134088998,
        '122': 32.155267358209386,
        '123': 32.155349849605095,
        '124': 32.15544796933228,
        '125': 32.155563226345365,
        '126': 32.15440658855783,
        '127': 32.14663040564719,
        '128': 32.15537225142983,
        '129': 32.15527365615083,
        '130': 32.15513395865142,
        '131': 32.15494698430537,
        '132': 32.15494889381264,
        '133': 32.155381576180744,
        '134': 32.1560181109855,
        '135': 32.1561694837992,
        '136': 32.15544474291573,
        '137': 32.154468615116244,
        '138': 32.154505465897124,
        '139': 32.15610893971133,
        '140': 32.1558074628645,
        '141': 32.157236653761935,
        '142': 32.15768508500662,
        '143': 32.1552114862527,
        '144': 32.15366291207761,
        '145': 32.15408176954233,
        '146': 32.1556507480279,
        '147': 32.15668902717773,
        '148': 32.15633131299252,
        '149': 32.15513918769332,
        '150': 32.154233610973534,
        '151': 32.15411350472623,
        '152': 32.15441682895755,
        '153': 32.15461511506821,
        '154': 32.15462714600968,
        '155': 32.154679807812265,
        '156': 32.15480294236506,
        '157': 32.154734104111846,
        '158': 32.154339627599,
        '159': 32.15391209187261,
        '160': 32.153878125808696,
        '161': 32.15427131126224,
        '162': 32.154667265609774,
        '163': 32.1546800919445,
        '164': 32.15438772928135,
        '165': 32.1541637763701,
        '166': 32.154178424349865,
        '167': 32.15423160336437,
        '168': 32.15408998169947,
        '169': 32.15382410089872,
        '170': 32.15369685733676,
        '171': 32.15380590901488,
        '172': 32.15399287568041,
        '173': 32.15410417429283,
        '174': 32.15418567399473,
        '175': 32.15433759136235,
        '176': 32.1544904448442,
        '177': 32.15447451139461,
        '178': 32.15428964838729,
        '179': 32.15415443249756,
        '180': 32.1542275353752,
        '-180': 32.15422753537519,
        '-179': 32.15438946510419,
        '-178': 32.154407610416115,
        '-177': 32.154261362939444,
        '-176': 32.154169122206824,
        '-175': 32.15428086364562,
        '-174': 32.1544727819293,
        '-173': 32.15452776301983,
        '-172': 32.15443498920244,
        '-171': 32.15438523386897,
        '-170': 32.15448544161725,
        '-169': 32.15461159714535,
        '-168': 32.154602522799735,
        '-167': 32.15450041501853,
        '-166': 32.154487421282354,
        '-165': 32.154617808659836,
        '-164': 32.154739815130384,
        '-163': 32.15471922249028,
        '-162': 32.15463644573415,
        '-161': 32.15466845723796,
        '-160': 32.15482390057772,
        '-159': 32.15491392263651,
        '-158': 32.15480695035376,
        '-157': 32.154612002221135,
        '-156': 32.15453397970398,
        '-155': 32.15460139020764,
        '-154': 32.1546395118305,
        '-153': 32.15451811805374,
        '-152': 32.154330798328786,
        '-151': 32.1542705550272,
        '-150': 32.15438302825888,
        '-149': 32.15452483643038,
        '-148': 32.154555567365875,
        '-147': 32.15448697505564,
        '-146': 32.15440995271733,
        '-145': 32.15434326977358,
        '-144': 32.154231753803835,
        '-143': 32.15407616220853,
        '-142': 32.15397973604931,
        '-141': 32.154026038342664,
        '-140': 32.1541561560317,
        '-139': 32.154223576296694,
        '-138': 32.15416357074984,
        '-137': 32.15406054825466,
        '-136': 32.154033533053294,
        '-135': 32.154091282738115,
        '-134': 32.1541335750643,
        '-133': 32.15408344437638,
        '-132': 32.15398272359932,
        '-131': 32.15394266084392,
        '-130': 32.15401446599577,
        '-129': 32.15412676709133,
        '-128': 32.15415768331724,
        '-127': 32.15406870889479,
        '-126': 32.15396384996451,
        '-125': 32.15400622277416,
        '-124': 32.15426574270945,
        '-123': 32.14666402063381,
        '-122': 32.1548558176378,
        '-121': 32.14894888564046,
        '-120': 32.13612159116133,
        '-119': 32.13984840836995,
        '-118': 32.140024332577376,
        '-117': 32.13735398300473,
        '-116': 32.13472852455357,
        '-115': 32.136523231369644,
        '-114': 32.13942829817699,
        '-113': 32.1403261404468,
        '-112': 32.136101238916055,
        '-111': 32.1368371788216,
        '-110': 32.14088640480656,
        '-109': 32.136306671827526,
        '-108': 32.1256178520031,
        '-107': 32.12418167136973,
        '-106': 32.127621934317226,
        '-105': 32.12732549586194,
        '-104': 32.136282795932644,
        '-103': 32.139514974242395,
        '-102': 32.142350673429554,
        '-101': 32.14500746882701,
        '-100': 32.147267952995,
        '-99': 32.14923266291803,
        '-98': 32.1504891430728,
        '-97': 32.150649617254565,
        '-96': 32.15070175193788,
        '-95': 32.15157288760957,
        '-94': 32.151277061728,
        '-93': 32.151813615862835,
        '-92': 32.15130692112741,
        '-91': 32.15258864749492,
        '-90': 32.15294862096084,
        '-89': 32.15334928298402,
        '-88': 32.15335144880677,
        '-87': 32.15296162371209,
        '-86': 32.15259577829876,
        '-85': 32.15249466490222,
        '-84': 32.15167637285991,
        '-83': 32.152529584443876,
        '-82': 32.15259045835294,
        '-81': 32.15133310830945,
        '-80': 32.1481355321268,
        '-79': 32.15066033576314,
        '-78': 32.150828944146326,
        '-77': 32.15391694288969,
        '-76': 32.15447512249295,
        '-75': 32.15467711252266,
        '-74': 32.15459440728852,
        '-73': 32.15437507896983,
        '-72': 32.15413744309127,
        '-71': 32.154061114400356,
        '-70': 32.154152815563044,
        '-69': 32.15422307010253,
        '-68': 32.15411389150379,
        '-67': 32.153890884995036,
        '-66': 32.153764129444646,
        '-65': 32.15385002693646,
        '-64': 32.15406795924314,
        '-63': 32.15426502656429,
        '-62': 32.15437488070367,
        '-61': 32.15441909262585,
        '-60': 32.1544054161491,
        '-59': 32.15430383272589,
        '-58': 32.15413208417187,
        '-57': 32.154001428633805,
        '-56': 32.15402776521046,
        '-55': 32.154212821560655,
        '-54': 32.15444887886752,
        '-53': 32.15463329240956,
        '-52': 32.15474258785902,
        '-51': 32.15479651800494,
        '-50': 32.15480218816945,
        '-49': 32.1547686597455,
        '-48': 32.15474340959871,
        '-47': 32.154777068895534,
        '-46': 32.15484879944901,
        '-45': 32.15487755559234,
        '-44': 32.15483597492966,
        '-43': 32.15481729445888,
        '-42': 32.154939948648,
        '-41': 32.15519052200908,
        '-40': 32.155408482953455,
        '-39': 32.15544866616722,
        '-38': 32.15533418021894,
        '-37': 32.155227497800745,
        '-36': 32.1552715682849,
        '-35': 32.15548678413621,
        '-34': 32.15580222143588,
        '-33': 32.1561307691351,
        '-32': 32.15639435704402,
        '-31': 32.15652599156091,
        '-30': 32.15650953147833,
        '-29': 32.15641930131035,
        '-28': 32.1563679078391,
        '-27': 32.15637948812984,
        '-26': 32.15633770983494,
        '-25': 32.15610525058107,
        '-24': 32.155704403992644,
        '-23': 32.15534334268231,
        '-22': 32.15522548459394,
        '-21': 32.15533264400044,
        '-20': 32.15542921200528,
        '-19': 32.15530785290816,
        '-18': 32.15502324103405,
        '-17': 32.15484648785064,
        '-16': 32.15496026676634,
        '-15': 32.155214487256586,
        '-14': 32.15523982756073,
        '-13': 32.15485173576777,
        '-12': 32.15433122292058,
        '-11': 32.15423549469135,
        '-10': 32.15486141647096,
        '-9': 32.15528296247696,
        '-8': 32.155628780493224,
        '-7': 32.15444645044401,
        '-6': 32.15343149604162,
        '-5': 32.15110358072082,
        '-4': 32.149367979985854,
        '-3': 32.147721252410605,
        '-2': 32.14925302900119,
        '-1': 32.147396237879306
    },
    '40': {
        '0': 32.15833842099794,
        '1': 32.15824897511823,
        '2': 32.15840195325652,
        '3': 32.158535013405825,
        '4': 32.15698638520557,
        '5': 32.157966323547384,
        '6': 32.15773292300437,
        '7': 32.157941802785864,
        '8': 32.158421650852375,
        '9': 32.15444765412876,
        '10': 32.158394722446815,
        '11': 32.157748809611626,
        '12': 32.157345910251145,
        '13': 32.15769998733389,
        '14': 32.15875781561194,
        '15': 32.15983952658107,
        '16': 32.1554408816256,
        '17': 32.159198530652446,
        '18': 32.15766844227961,
        '19': 32.15656772571736,
        '20': 32.15483958952565,
        '21': 32.14692943705699,
        '22': 32.149302908920696,
        '23': 32.15920594811173,
        '24': 32.1587661445542,
        '25': 32.15821945811136,
        '26': 32.15817865845332,
        '27': 32.15758667720067,
        '28': 32.15727127070448,
        '29': 32.148878901592134,
        '30': 32.15228484059219,
        '31': 32.1464856917559,
        '32': 32.14899812435948,
        '33': 32.14774833874353,
        '34': 32.14880249479121,
        '35': 32.14913060192864,
        '36': 32.14955523685312,
        '37': 32.145172594061656,
        '38': 32.141366700894686,
        '39': 32.14038907796898,
        '40': 32.13991910106907,
        '41': 32.14136067124211,
        '42': 32.14403038714032,
        '43': 32.132621878410234,
        '44': 32.150662750642745,
        '45': 32.139914160917215,
        '46': 32.136575667013226,
        '47': 32.156612671484524,
        '48': 32.15816717520039,
        '49': 32.15430562356576,
        '50': 32.15422066205373,
        '51': 32.15404563034284,
        '52': 32.15536031499226,
        '53': 32.15706280510482,
        '54': 32.15524182215812,
        '55': 32.156367635187074,
        '56': 32.1569082321483,
        '57': 32.15672514170415,
        '58': 32.15618042979748,
        '59': 32.155638977534316,
        '60': 32.154955027658694,
        '61': 32.154991197699,
        '62': 32.15531613702623,
        '63': 32.1555715090414,
        '64': 32.15529127532105,
        '65': 32.15390052617293,
        '66': 32.15343922375991,
        '67': 32.15167449516853,
        '68': 32.153842253387005,
        '69': 32.149643508950675,
        '70': 32.1425453337986,
        '71': 32.14190861573435,
        '72': 32.13677629833515,
        '73': 32.132501613734405,
        '74': 32.116914971958,
        '75': 32.124756960903596,
        '76': 32.137780424485136,
        '77': 32.13737457265112,
        '78': 32.14212358428881,
        '79': 32.145660374487335,
        '80': 32.145643107124556,
        '81': 32.145162770798166,
        '82': 32.14480607148478,
        '83': 32.14464984945968,
        '84': 32.1442382211059,
        '85': 32.1439520817179,
        '86': 32.14394668345548,
        '87': 32.14424238370476,
        '88': 32.14551824304441,
        '89': 32.147065546632646,
        '90': 32.147908418041666,
        '91': 32.148047144177156,
        '92': 32.145097913222315,
        '93': 32.144845740522804,
        '94': 32.144304769511365,
        '95': 32.140522810767166,
        '96': 32.138584661638866,
        '97': 32.136183694278195,
        '98': 32.136572778356275,
        '99': 32.14346459188057,
        '100': 32.14046625897098,
        '101': 32.14251559302834,
        '102': 32.142499642046026,
        '103': 32.140445667544334,
        '104': 32.14230397046087,
        '105': 32.1409841693025,
        '106': 32.146448525085106,
        '107': 32.141921015365604,
        '108': 32.14413769271588,
        '109': 32.14187172082368,
        '110': 32.14365500531846,
        '111': 32.14535135375331,
        '112': 32.14177874090546,
        '113': 32.14363041211111,
        '114': 32.14930329713798,
        '115': 32.14670349256615,
        '116': 32.15456269433465,
        '117': 32.157600480615955,
        '118': 32.15621251783443,
        '119': 32.156914342558764,
        '120': 32.15778738081842,
        '121': 32.157918716562826,
        '122': 32.15744313177853,
        '123': 32.156843322282825,
        '124': 32.15832192761968,
        '125': 32.15662643488482,
        '126': 32.156440923783336,
        '127': 32.147941184297096,
        '128': 32.15864284676569,
        '129': 32.15828436587934,
        '130': 32.15783066615492,
        '131': 32.15737722894918,
        '132': 32.15724031792334,
        '133': 32.15770499331578,
        '134': 32.15854534605006,
        '135': 32.15901078425083,
        '136': 32.15857246405524,
        '137': 32.15769209462598,
        '138': 32.157561166088314,
        '139': 32.15882520659932,
        '140': 32.16063725446223,
        '141': 32.15634072618693,
        '142': 32.159816401554096,
        '143': 32.1572574539576,
        '144': 32.155596626191866,
        '145': 32.15600363794626,
        '146': 32.157828454282175,
        '147': 32.15943181063808,
        '148': 32.159793311902106,
        '149': 32.159193533090736,
        '150': 32.158496667058785,
        '151': 32.15810777001229,
        '152': 32.157791592622544,
        '153': 32.15730953575458,
        '154': 32.15688283810648,
        '155': 32.15689760667929,
        '156': 32.157335853754226,
        '157': 32.15773322299473,
        '158': 32.15771290618228,
        '159': 32.157391046641,
        '160': 32.15716191902918,
        '161': 32.15719592192079,
        '162': 32.15730555805024,
        '163': 32.15728242703845,
        '164': 32.15718390218598,
        '165': 32.1571852089916,
        '166': 32.15726059462884,
        '167': 32.15718817394634,
        '168': 32.15688558019489,
        '169': 32.15659128652534,
        '170': 32.15660910011422,
        '171': 32.15693978367005,
        '172': 32.157282961398764,
        '173': 32.15739262753381,
        '174': 32.15731511344529,
        '175': 32.15724066363214,
        '176': 32.157235172639574,
        '177': 32.15722869610281,
        '178': 32.15721256980036,
        '179': 32.15729197773019,
        '180': 32.1574953438146,
        '-180': 32.1574953438146,
        '-179': 32.157650461413645,
        '-178': 32.15756846277207,
        '-177': 32.157314725838624,
        '-176': 32.15717370316764,
        '-175': 32.15731327414653,
        '-174': 32.15757752367828,
        '-173': 32.157682184265035,
        '-172': 32.15755612944533,
        '-171': 32.15739201772651,
        '-170': 32.15737291488025,
        '-169': 32.157460141841675,
        '-168': 32.15750112760185,
        '-167': 32.15746010436077,
        '-166': 32.1574375047124,
        '-165': 32.15748395886623,
        '-164': 32.15751272474778,
        '-163': 32.15744643954548,
        '-162': 32.15736852877202,
        '-161': 32.15743224594411,
        '-160': 32.15763777896986,
        '-159': 32.15779786880278,
        '-158': 32.15776244025536,
        '-157': 32.1576094552106,
        '-156': 32.15754446130453,
        '-155': 32.15764000713781,
        '-154': 32.15775506119722,
        '-153': 32.157726569661506,
        '-152': 32.15756953183664,
        '-151': 32.15743458676901,
        '-150': 32.157408228069414,
        '-149': 32.15742997337662,
        '-148': 32.15740970650563,
        '-147': 32.15735337817547,
        '-146': 32.15732502834551,
        '-145': 32.157326792346,
        '-144': 32.15728910499161,
        '-143': 32.15718593838777,
        '-142': 32.15709720878983,
        '-141': 32.157113319537984,
        '-140': 32.15720719496257,
        '-139': 32.157256223479905,
        '-138': 32.15719606859514,
        '-137': 32.15710505481008,
        '-136': 32.157102941781176,
        '-135': 32.15719071707273,
        '-134': 32.157239982679286,
        '-133': 32.157154019169475,
        '-132': 32.157003118808866,
        '-131': 32.15695883244384,
        '-130': 32.15709171461987,
        '-129': 32.15726945663777,
        '-128': 32.15729005059126,
        '-127': 32.157117138439915,
        '-126': 32.15695364499417,
        '-125': 32.15705274532564,
        '-124': 32.15166499854166,
        '-123': 32.13982294216705,
        '-122': 32.15705150836151,
        '-121': 32.14572204755906,
        '-120': 32.14004839829884,
        '-119': 32.14405378085338,
        '-118': 32.13568587768399,
        '-117': 32.14217243409305,
        '-116': 32.1403073549781,
        '-115': 32.138661039735965,
        '-114': 32.14151099277275,
        '-113': 32.144625232513796,
        '-112': 32.14273437779909,
        '-111': 32.130778699679674,
        '-110': 32.141298123363015,
        '-109': 32.14109235594053,
        '-108': 32.13926024599029,
        '-107': 32.12910228214859,
        '-106': 32.13065156210387,
        '-105': 32.14250779076228,
        '-104': 32.143161345739536,
        '-103': 32.14353364534109,
        '-102': 32.14700442845681,
        '-101': 32.14871261219081,
        '-100': 32.15106155604188,
        '-99': 32.151731478697904,
        '-98': 32.15305310501788,
        '-97': 32.15357557764024,
        '-96': 32.15378441153671,
        '-95': 32.15453877997123,
        '-94': 32.15434976498947,
        '-93': 32.15408801936633,
        '-92': 32.15456364106221,
        '-91': 32.15490612162597,
        '-90': 32.155748391727826,
        '-89': 32.15556993908939,
        '-88': 32.15543896449,
        '-87': 32.15501326310756,
        '-86': 32.1546448549132,
        '-85': 32.153640534126616,
        '-84': 32.15406259542382,
        '-83': 32.15488314918197,
        '-82': 32.15478572709385,
        '-81': 32.15364715704036,
        '-80': 32.15490333404312,
        '-79': 32.150487576485524,
        '-78': 32.15466017603313,
        '-77': 32.156103619095795,
        '-76': 32.15595484360364,
        '-75': 32.15746358285373,
        '-74': 32.15773967092582,
        '-73': 32.15763477407709,
        '-72': 32.15745423198,
        '-71': 32.157381711991086,
        '-70': 32.157453214669104,
        '-69': 32.157495504952216,
        '-68': 32.15733087695295,
        '-67': 32.156992717148185,
        '-66': 32.156697151087705,
        '-65': 32.15662255258407,
        '-64': 32.156757677222316,
        '-63': 32.15695728746515,
        '-62': 32.157093538767626,
        '-61': 32.157130647398255,
        '-60': 32.15709756628061,
        '-59': 32.15704725041478,
        '-58': 32.15704266138755,
        '-57': 32.15712943747687,
        '-56': 32.157287528420106,
        '-55': 32.15742949309714,
        '-54': 32.15747906099441,
        '-53': 32.15744796460081,
        '-52': 32.15741227951626,
        '-51': 32.157419658223425,
        '-50': 32.15745471177887,
        '-49': 32.15750215269591,
        '-48': 32.15759659322487,
        '-47': 32.15776358870615,
        '-46': 32.15793149398152,
        '-45': 32.15796980513993,
        '-44': 32.15784755851627,
        '-43': 32.1577131842526,
        '-42': 32.157760432820716,
        '-41': 32.158013524854724,
        '-40': 32.15828920489718,
        '-39': 32.15839143165846,
        '-38': 32.15831291336451,
        '-37': 32.15821624119905,
        '-36': 32.15824247271536,
        '-35': 32.15838931471852,
        '-34': 32.15857910412934,
        '-33': 32.15878331348142,
        '-32': 32.15902361966276,
        '-31': 32.15926888904912,
        '-30': 32.15940266744975,
        '-29': 32.159334674720505,
        '-28': 32.159118375605914,
        '-27': 32.158909169523525,
        '-26': 32.158798057815964,
        '-25': 32.15872211833538,
        '-24': 32.15856447062819,
        '-23': 32.15831961861992,
        '-22': 32.158114231844536,
        '-21': 32.15805156270872,
        '-20': 32.15807295851088,
        '-19': 32.15801981303525,
        '-18': 32.15783395650664,
        '-17': 32.157648174675145,
        '-16': 32.157632205492106,
        '-15': 32.15776001436407,
        '-14': 32.15779372124937,
        '-13': 32.157552001618114,
        '-12': 32.15717970542185,
        '-11': 32.15707796124801,
        '-10': 32.15750492543142,
        '-9': 32.158252841770526,
        '-8': 32.15280799506359,
        '-7': 32.15454219335527,
        '-6': 32.153940487763535,
        '-5': 32.15375881412527,
        '-4': 32.15279171359766,
        '-3': 32.15019634132262,
        '-2': 32.14701160671537,
        '-1': 32.14499475636204
    },
    '41': {
        '0': 32.155875478258565,
        '1': 32.16110913295177,
        '2': 32.16118978423135,
        '3': 32.161196066824886,
        '4': 32.16095869577483,
        '5': 32.160582554852866,
        '6': 32.16040845726442,
        '7': 32.16069456908963,
        '8': 32.161332639092926,
        '9': 32.158380388444684,
        '10': 32.16200521199004,
        '11': 32.1616724143093,
        '12': 32.16132468684463,
        '13': 32.16143184222332,
        '14': 32.16205191569103,
        '15': 32.15851327008989,
        '16': 32.1591846582938,
        '17': 32.160503427460654,
        '18': 32.16074001394588,
        '19': 32.160110452148515,
        '20': 32.1596115600666,
        '21': 32.153030489808216,
        '22': 32.159768254811326,
        '23': 32.15855609155285,
        '24': 32.160897096315125,
        '25': 32.161551275728904,
        '26': 32.159948491895655,
        '27': 32.16075455667843,
        '28': 32.16194448605374,
        '29': 32.16148641263811,
        '30': 32.15971319916678,
        '31': 32.159039087352944,
        '32': 32.15397115734136,
        '33': 32.150720390786965,
        '34': 32.15402958974938,
        '35': 32.1566256781514,
        '36': 32.15447242636342,
        '37': 32.156136268244985,
        '38': 32.16136323616475,
        '39': 32.15955059403122,
        '40': 32.16120721740302,
        '41': 32.15208365108643,
        '42': 32.14029408753506,
        '43': 32.13846729318208,
        '44': 32.13796060841869,
        '45': 32.14903344688629,
        '46': 32.1612141388793,
        '47': 32.16131754475875,
        '48': 32.14294937482682,
        '49': 32.152565613492825,
        '50': 32.1580898098756,
        '51': 32.15853982405719,
        '52': 32.160244981257165,
        '53': 32.16191002994995,
        '54': 32.16248942218966,
        '55': 32.16044497207242,
        '56': 32.16093085954496,
        '57': 32.159588052382496,
        '58': 32.15974268927187,
        '59': 32.15921676327275,
        '60': 32.15905847707793,
        '61': 32.159149039822715,
        '62': 32.15909474135482,
        '63': 32.15898236845676,
        '64': 32.158164875231265,
        '65': 32.15846061726247,
        '66': 32.15699394863043,
        '67': 32.15690739367581,
        '68': 32.157000595321115,
        '69': 32.15630988685304,
        '70': 32.148943085638805,
        '71': 32.15046976029553,
        '72': 32.154884493880154,
        '73': 32.1515611743063,
        '74': 32.12709655037463,
        '75': 32.130804875512965,
        '76': 32.12428919504759,
        '77': 32.125248635816,
        '78': 32.11600428823473,
        '79': 32.13927134510346,
        '80': 32.1463811552674,
        '81': 32.14858383479811,
        '82': 32.148176397264805,
        '83': 32.14793200834378,
        '84': 32.148222753754396,
        '85': 32.148787131749266,
        '86': 32.14926248874691,
        '87': 32.14972598252345,
        '88': 32.1491704010243,
        '89': 32.14779428195572,
        '90': 32.14693578850629,
        '91': 32.150685513504,
        '92': 32.14801465334005,
        '93': 32.144428876495795,
        '94': 32.14635173013911,
        '95': 32.140361090186225,
        '96': 32.142106533826436,
        '97': 32.13987539002622,
        '98': 32.14325217050296,
        '99': 32.14321605359387,
        '100': 32.145909967160506,
        '101': 32.14831303226043,
        '102': 32.14713223476149,
        '103': 32.145083931701706,
        '104': 32.14623186649002,
        '105': 32.145868734612,
        '106': 32.14616384098528,
        '107': 32.149250618944194,
        '108': 32.14942354347001,
        '109': 32.14988643994267,
        '110': 32.14758261397194,
        '111': 32.141243977269,
        '112': 32.14350255260196,
        '113': 32.14610100869127,
        '114': 32.14508882488239,
        '115': 32.1506432759749,
        '116': 32.14891284490355,
        '117': 32.156147593523535,
        '118': 32.15569445517701,
        '119': 32.155790129656964,
        '120': 32.15594376851426,
        '121': 32.160695317281814,
        '122': 32.16088768593634,
        '123': 32.159937084707465,
        '124': 32.15724999451332,
        '125': 32.15641938662147,
        '126': 32.157608896184094,
        '127': 32.14780914339426,
        '128': 32.14937178394493,
        '129': 32.146939148257545,
        '130': 32.16120566529155,
        '131': 32.16050470602807,
        '132': 32.160029369327596,
        '133': 32.16012631714511,
        '134': 32.16072715635678,
        '135': 32.161272562194156,
        '136': 32.16126643150518,
        '137': 32.160912318227716,
        '138': 32.16098652606274,
        '139': 32.16190686588776,
        '140': 32.163014357462174,
        '141': 32.163031822073364,
        '142': 32.1613735349468,
        '143': 32.15891354866046,
        '144': 32.15730187247581,
        '145': 32.15749063907926,
        '146': 32.15899594454079,
        '147': 32.160579963954,
        '148': 32.16148153068066,
        '149': 32.161856960324464,
        '150': 32.162153892350425,
        '151': 32.162368357325526,
        '152': 32.162101429025185,
        '153': 32.16122390810797,
        '154': 32.16021728502278,
        '155': 32.159740671338405,
        '156': 32.159966454846455,
        '157': 32.1604716935099,
        '158': 32.160745655172406,
        '159': 32.16066332025066,
        '160': 32.160433759510205,
        '161': 32.16023880109752,
        '162': 32.160079068868924,
        '163': 32.15993838067239,
        '164': 32.159914792998826,
        '165': 32.16008236834441,
        '166': 32.16030811830467,
        '167': 32.16035047396568,
        '168': 32.16015749248506,
        '169': 32.15996224079791,
        '170': 32.160017202508904,
        '171': 32.160293711100806,
        '172': 32.16052616614555,
        '173': 32.16052864605125,
        '174': 32.16037259607197,
        '175': 32.16023435534509,
        '176': 32.16016775895416,
        '177': 32.16011118260253,
        '178': 32.16006302571511,
        '179': 32.16011940494566,
        '180': 32.16030227147205,
        '-180': 32.16030227147205,
        '-179': 32.1604517607764,
        '-178': 32.16038947282228,
        '-177': 32.160162117407026,
        '-176': 32.16002297910629,
        '-175': 32.1601423886725,
        '-174': 32.16040861880324,
        '-173': 32.16057261101961,
        '-172': 32.160544172420394,
        '-171': 32.160464695416636,
        '-170': 32.16049354868745,
        '-169': 32.160612640357755,
        '-168': 32.160690061028056,
        '-167': 32.16067338613916,
        '-166': 32.16063160144963,
        '-165': 32.16061443543461,
        '-164': 32.160564161512596,
        '-163': 32.160419190935485,
        '-162': 32.16024953778002,
        '-161': 32.160205741645406,
        '-160': 32.1603307729508,
        '-159': 32.16049208745604,
        '-158': 32.160537742621564,
        '-157': 32.16047696939165,
        '-156': 32.16045202226242,
        '-155': 32.16054694614568,
        '-154': 32.16068654458424,
        '-153': 32.160743242782154,
        '-152': 32.16069244982212,
        '-151': 32.160614863794564,
        '-150': 32.16057010671107,
        '-149': 32.16053164017703,
        '-148': 32.16046121998766,
        '-147': 32.16039327307898,
        '-146': 32.16039492852751,
        '-145': 32.16045969754179,
        '-144': 32.160496577953715,
        '-143': 32.16044216766117,
        '-142': 32.16034469188473,
        '-141': 32.16030140180543,
        '-140': 32.160331781695,
        '-139': 32.160359197079565,
        '-138': 32.160325955280776,
        '-137': 32.16028166763965,
        '-136': 32.160316860889814,
        '-135': 32.16042555683255,
        '-134': 32.16048888243158,
        '-133': 32.160422164970456,
        '-132': 32.16030449686597,
        '-131': 32.16030660688824,
        '-130': 32.16047650716184,
        '-129': 32.1606414355124,
        '-128': 32.16057768485049,
        '-127': 32.16028541053958,
        '-126': 32.16004928065707,
        '-125': 32.160176739916174,
        '-124': 32.15749140714128,
        '-123': 32.141562300327315,
        '-122': 32.15295834128322,
        '-121': 32.14448271235908,
        '-120': 32.144830030900835,
        '-119': 32.14820714558578,
        '-118': 32.146242338080754,
        '-117': 32.14687396719029,
        '-116': 32.14184948008103,
        '-115': 32.14313228952799,
        '-114': 32.14684052524079,
        '-113': 32.1465566500116,
        '-112': 32.14824730508745,
        '-111': 32.137211502875665,
        '-110': 32.137752655071985,
        '-109': 32.13540895841279,
        '-108': 32.14217852484659,
        '-107': 32.13624691941902,
        '-106': 32.13774334384501,
        '-105': 32.1419136982639,
        '-104': 32.14454908489094,
        '-103': 32.14708564633132,
        '-102': 32.149546681358196,
        '-101': 32.15123032073088,
        '-100': 32.15291198676003,
        '-99': 32.154490555921655,
        '-98': 32.155353639720865,
        '-97': 32.15632444117011,
        '-96': 32.15741142681936,
        '-95': 32.15743392381963,
        '-94': 32.15684151748401,
        '-93': 32.15736609882823,
        '-92': 32.1578744560438,
        '-91': 32.15878749776323,
        '-90': 32.15842550929679,
        '-89': 32.15847088237186,
        '-88': 32.15817291684267,
        '-87': 32.158173229631096,
        '-86': 32.15767982537685,
        '-85': 32.15792824465608,
        '-84': 32.15815519400733,
        '-83': 32.157445290217396,
        '-82': 32.157382940462895,
        '-81': 32.15717351703509,
        '-80': 32.15672282979076,
        '-79': 32.155873080750915,
        '-78': 32.15608525162608,
        '-77': 32.158649408222544,
        '-76': 32.15688134402321,
        '-75': 32.15790039760962,
        '-74': 32.160609608080314,
        '-73': 32.16084374141736,
        '-72': 32.1607381144302,
        '-71': 32.160687033037625,
        '-70': 32.160785733628636,
        '-69': 32.16092647345588,
        '-68': 32.16092604869449,
        '-67': 32.16072565432439,
        '-66': 32.160434983810966,
        '-65': 32.16020105447981,
        '-64': 32.16007123261678,
        '-63': 32.159985128113306,
        '-62': 32.15986700713368,
        '-61': 32.15970855161919,
        '-60': 32.15958061423001,
        '-59': 32.159583197103665,
        '-58': 32.15976589937551,
        '-57': 32.16006347375553,
        '-56': 32.160310486798444,
        '-55': 32.16036107459778,
        '-54': 32.16022655456695,
        '-53': 32.16008143121191,
        '-52': 32.16010114785,
        '-51': 32.160290598006895,
        '-50': 32.160496282269634,
        '-49': 32.16059002509575,
        '-48': 32.160604399420976,
        '-47': 32.1606594235854,
        '-46': 32.16078516754044,
        '-45': 32.16087737784688,
        '-44': 32.16084563957899,
        '-43': 32.16075522750526,
        '-42': 32.1607671952547,
        '-41': 32.160945772150974,
        '-40': 32.161179028607,
        '-39': 32.161313252283406,
        '-38': 32.161326354465615,
        '-37': 32.16132390198735,
        '-36': 32.1613822435812,
        '-35': 32.161460840883414,
        '-34': 32.16149832292152,
        '-33': 32.16154448918748,
        '-32': 32.16172206072487,
        '-31': 32.16204315637823,
        '-30': 32.16232187560819,
        '-29': 32.162330125219206,
        '-28': 32.16204226395845,
        '-27': 32.16168316285095,
        '-26': 32.16150842950977,
        '-25': 32.16155430878856,
        '-24': 32.16162953465077,
        '-23': 32.161543074500734,
        '-22': 32.16130184342542,
        '-21': 32.16106900966189,
        '-20': 32.16096155754569,
        '-19': 32.16093671793933,
        '-18': 32.160881891409225,
        '-17': 32.16076660370526,
        '-16': 32.160656149623115,
        '-15': 32.160585666250135,
        '-14': 32.160487351771835,
        '-13': 32.16029751929881,
        '-12': 32.16011954238136,
        '-11': 32.16020482603384,
        '-10': 32.16070721150899,
        '-9': 32.16145767905483,
        '-8': 32.15086581234357,
        '-7': 32.159027076880626,
        '-6': 32.15327821273863,
        '-5': 32.152809213731175,
        '-4': 32.1492045032781,
        '-3': 32.15229376758087,
        '-2': 32.14904603930305,
        '-1': 32.14919811325951
    },
    '42': {
        '0': 32.160506016275825,
        '1': 32.15567262539215,
        '2': 32.157103809503816,
        '3': 32.162921180933125,
        '4': 32.16363549275366,
        '5': 32.16329121351713,
        '6': 32.16313102657778,
        '7': 32.16332860113933,
        '8': 32.16387516286243,
        '9': 32.15463994988816,
        '10': 32.16508042031871,
        '11': 32.16528864247037,
        '12': 32.1652017324173,
        '13': 32.160732319936486,
        '14': 32.15597656059196,
        '15': 32.1647160302506,
        '16': 32.164518014513895,
        '17': 32.16392213401561,
        '18': 32.16347858744496,
        '19': 32.16360522137891,
        '20': 32.15950743677811,
        '21': 32.16082916684841,
        '22': 32.15868844017355,
        '23': 32.160311598776595,
        '24': 32.155748649296605,
        '25': 32.16268805193965,
        '26': 32.16244855594289,
        '27': 32.16161539940042,
        '28': 32.164051357514836,
        '29': 32.16378816473742,
        '30': 32.163202954911604,
        '31': 32.16322010275091,
        '32': 32.16371698541336,
        '33': 32.16427049546732,
        '34': 32.164542772552124,
        '35': 32.16356445416392,
        '36': 32.16397936273375,
        '37': 32.1632693185599,
        '38': 32.16257302734125,
        '39': 32.16234404286315,
        '40': 32.16291120532606,
        '41': 32.16410037658507,
        '42': 32.164537062656514,
        '43': 32.15716810553017,
        '44': 32.15981262084792,
        '45': 32.15520385753826,
        '46': 32.14450407968773,
        '47': 32.13440595833276,
        '48': 32.15661040002692,
        '49': 32.16254247958123,
        '50': 32.161976568540105,
        '51': 32.162605054178094,
        '52': 32.16393002414937,
        '53': 32.16487684929818,
        '54': 32.16293013731326,
        '55': 32.162301764293524,
        '56': 32.16200764884777,
        '57': 32.16245037659413,
        '58': 32.16286600276242,
        '59': 32.162818691523924,
        '60': 32.16252942660981,
        '61': 32.16238849310728,
        '62': 32.16238908922491,
        '63': 32.16281118780308,
        '64': 32.16221400724301,
        '65': 32.160698028264164,
        '66': 32.15996850336714,
        '67': 32.15939503620441,
        '68': 32.15970773515449,
        '69': 32.15793161714456,
        '70': 32.142550957730506,
        '71': 32.130177997314796,
        '72': 32.130705334224345,
        '73': 32.138238436790836,
        '74': 32.12745814548373,
        '75': 32.14186798065038,
        '76': 32.12780950497062,
        '77': 32.136128188294755,
        '78': 32.1261954209623,
        '79': 32.13231005128844,
        '80': 32.10790034669741,
        '81': 32.11974238493989,
        '82': 32.14395709386801,
        '83': 32.14499572486066,
        '84': 32.14939540539104,
        '85': 32.153986251238244,
        '86': 32.13812911587013,
        '87': 32.1534739709686,
        '88': 32.149636975572115,
        '89': 32.15478843297773,
        '90': 32.15383613637209,
        '91': 32.149617969209736,
        '92': 32.15135673305639,
        '93': 32.15312502130202,
        '94': 32.15175015710636,
        '95': 32.148277839228044,
        '96': 32.13917004693502,
        '97': 32.14455234193237,
        '98': 32.14621039984138,
        '99': 32.1476421385171,
        '100': 32.152427510235846,
        '101': 32.15283841227878,
        '102': 32.1524173638651,
        '103': 32.15044263708859,
        '104': 32.15053430906412,
        '105': 32.15093293417582,
        '106': 32.152928898778384,
        '107': 32.15137199728181,
        '108': 32.149658801450975,
        '109': 32.14791029073241,
        '110': 32.149501399560705,
        '111': 32.15074506268146,
        '112': 32.14779829253257,
        '113': 32.148286732743394,
        '114': 32.14825740252713,
        '115': 32.149159413966395,
        '116': 32.149460183535304,
        '117': 32.15029622648281,
        '118': 32.15284828667398,
        '119': 32.15662965361724,
        '120': 32.15518446391762,
        '121': 32.16183292947453,
        '122': 32.162295437394036,
        '123': 32.16349540752213,
        '124': 32.160478137319906,
        '125': 32.159877228223394,
        '126': 32.15940013949644,
        '127': 32.15693052607541,
        '128': 32.144656886801926,
        '129': 32.1543493530631,
        '130': 32.16443560459512,
        '131': 32.16422955731297,
        '132': 32.163713435492824,
        '133': 32.16349144937099,
        '134': 32.16362974842326,
        '135': 32.163854406116826,
        '136': 32.163865533510524,
        '137': 32.16375228047451,
        '138': 32.163941954847935,
        '139': 32.16463098925083,
        '140': 32.16535039924559,
        '141': 32.16528446693595,
        '142': 32.16410712381892,
        '143': 32.16241794227971,
        '144': 32.16123597960445,
        '145': 32.16106249799148,
        '146': 32.161532193423774,
        '147': 32.16198897615839,
        '148': 32.16226490215235,
        '149': 32.162756143771475,
        '150': 32.16377178305364,
        '151': 32.164991993875724,
        '152': 32.165697125955624,
        '153': 32.16548571538477,
        '154': 32.16465060057564,
        '155': 32.16383619003879,
        '156': 32.16343083228823,
        '157': 32.16335717782444,
        '158': 32.163360177768375,
        '159': 32.1633333634559,
        '160': 32.16331904684091,
        '161': 32.163318470219025,
        '162': 32.16325053831415,
        '163': 32.163101296971774,
        '164': 32.16299865365,
        '165': 32.16306607557394,
        '166': 32.163250458927344,
        '167': 32.16337363807154,
        '168': 32.16334664126244,
        '169': 32.163258423940086,
        '170': 32.1632289556517,
        '171': 32.16324348256503,
        '172': 32.163198590058556,
        '173': 32.16307978820519,
        '174': 32.16300246285723,
        '175': 32.16304961449,
        '176': 32.16313966118002,
        '177': 32.16312404052939,
        '178': 32.162989865504585,
        '179': 32.16288602788857,
        '180': 32.162928726377174,
        '-180': 32.162928726377174,
        '-179': 32.16304756032572,
        '-178': 32.163081370696496,
        '-177': 32.16299393238419,
        '-176': 32.162919713382756,
        '-175': 32.1629843926005,
        '-174': 32.16314654588095,
        '-173': 32.16326645102991,
        '-172': 32.16329414288773,
        '-171': 32.163317976144626,
        '-170': 32.163421609641865,
        '-169': 32.163560542508996,
        '-168': 32.16362739928778,
        '-167': 32.16360328278292,
        '-166': 32.16357795610941,
        '-165': 32.16361532176146,
        '-164': 32.163659820760586,
        '-163': 32.16361737979424,
        '-162': 32.163496474228246,
        '-161': 32.1634108985995,
        '-160': 32.163438007118906,
        '-159': 32.163522992509066,
        '-158': 32.16355323003556,
        '-157': 32.16349775757271,
        '-156': 32.163430517795646,
        '-155': 32.16342304840759,
        '-154': 32.163461471478655,
        '-153': 32.16348904913864,
        '-152': 32.16349367356085,
        '-151': 32.16350991361936,
        '-150': 32.16354402447019,
        '-149': 32.16354617069587,
        '-148': 32.16348039846269,
        '-147': 32.163391852728395,
        '-146': 32.16336494788685,
        '-145': 32.16341837807365,
        '-144': 32.163478192892676,
        '-143': 32.1634701676162,
        '-142': 32.16341127307986,
        '-141': 32.16337943791304,
        '-140': 32.163404484847284,
        '-139': 32.16342934219329,
        '-138': 32.16339404495737,
        '-137': 32.16332556537399,
        '-136': 32.16330566222957,
        '-135': 32.16335641228987,
        '-134': 32.163404214232024,
        '-133': 32.16338526008996,
        '-132': 32.16335628243642,
        '-131': 32.163441952973905,
        '-130': 32.163653172927816,
        '-129': 32.16380534529485,
        '-128': 32.16368562610945,
        '-127': 32.16332060296899,
        '-126': 32.1630297570247,
        '-125': 32.16314841396403,
        '-124': 32.15745207751449,
        '-123': 32.14987597745626,
        '-122': 32.150196081078484,
        '-121': 32.149360113002565,
        '-120': 32.147505751819224,
        '-119': 32.14667328992059,
        '-118': 32.148129765470976,
        '-117': 32.14817036882148,
        '-116': 32.14137362979957,
        '-115': 32.1422300062144,
        '-114': 32.14837884358372,
        '-113': 32.14969756338389,
        '-112': 32.147352873969254,
        '-111': 32.144219218231285,
        '-110': 32.1443716288449,
        '-109': 32.14050299141088,
        '-108': 32.14410530989696,
        '-107': 32.1440030244335,
        '-106': 32.14376892508256,
        '-105': 32.149452616638975,
        '-104': 32.15176589256435,
        '-103': 32.15128622924456,
        '-102': 32.15191368177007,
        '-101': 32.15383408674804,
        '-100': 32.156059882102795,
        '-99': 32.15687398220882,
        '-98': 32.15814750813275,
        '-97': 32.15947955457248,
        '-96': 32.16062424358527,
        '-95': 32.159453601120724,
        '-94': 32.16036294151387,
        '-93': 32.16044768972101,
        '-92': 32.16074668625654,
        '-91': 32.16126074989438,
        '-90': 32.16132402681703,
        '-89': 32.1609518621975,
        '-88': 32.1611867726008,
        '-87': 32.16241673560205,
        '-86': 32.16097677645991,
        '-85': 32.16077722814404,
        '-84': 32.16101843106566,
        '-83': 32.161635733853494,
        '-82': 32.16162602754269,
        '-81': 32.161741786162885,
        '-80': 32.15977940885043,
        '-79': 32.15861149408311,
        '-78': 32.15678313533532,
        '-77': 32.15795386272646,
        '-76': 32.15977895406955,
        '-75': 32.15799100758851,
        '-74': 32.16333571907113,
        '-73': 32.16076457962597,
        '-72': 32.16231717687437,
        '-71': 32.16352560660465,
        '-70': 32.163879869594425,
        '-69': 32.16410144331343,
        '-68': 32.164308470973786,
        '-67': 32.164348672242184,
        '-66': 32.16419094846223,
        '-65': 32.163904348613556,
        '-64': 32.163571979540684,
        '-63': 32.16323472735481,
        '-62': 32.16290104534085,
        '-61': 32.16259451593328,
        '-60': 32.16238594620966,
        '-59': 32.16236548498429,
        '-58': 32.162556153518324,
        '-57': 32.16284394134513,
        '-56': 32.16302634400509,
        '-55': 32.162984446599005,
        '-54': 32.16282632563865,
        '-53': 32.162822736350996,
        '-52': 32.16314988186141,
        '-51': 32.16368659976778,
        '-50': 32.164098240880925,
        '-49': 32.164146228057014,
        '-48': 32.16390641830707,
        '-47': 32.16366903294524,
        '-46': 32.16364107265393,
        '-45': 32.163778325702765,
        '-44': 32.163902798643804,
        '-43': 32.16392964532873,
        '-42': 32.163933285747696,
        '-41': 32.164012546722866,
        '-40': 32.164158032013724,
        '-39': 32.16428454871835,
        '-38': 32.1643536928785,
        '-37': 32.16440510824,
        '-36': 32.16446658923215,
        '-35': 32.164495115807775,
        '-34': 32.164454207894764,
        '-33': 32.16442245194247,
        '-32': 32.16455282623192,
        '-31': 32.164887236392474,
        '-30': 32.165245257620974,
        '-29': 32.16535704249917,
        '-28': 32.16512911749058,
        '-27': 32.16475414386166,
        '-26': 32.16452990830969,
        '-25': 32.16457231927742,
        '-24': 32.16472431354455,
        '-23': 32.164744018530406,
        '-22': 32.16455517226257,
        '-21': 32.164296830214596,
        '-20': 32.16414880165413,
        '-19': 32.164146153837784,
        '-18': 32.164173687031365,
        '-17': 32.16410863802512,
        '-16': 32.16393158029564,
        '-15': 32.16370448009176,
        '-14': 32.1634910672306,
        '-13': 32.16334172287436,
        '-12': 32.163342668790804,
        '-11': 32.16361599199782,
        '-10': 32.16421161593897,
        '-9': 32.16499007571701,
        '-8': 32.15781313536184,
        '-7': 32.154613542819,
        '-6': 32.15815060780323,
        '-5': 32.157707877480235,
        '-4': 32.155843826141194,
        '-3': 32.15164198612022,
        '-2': 32.15690010040122,
        '-1': 32.157933979656725
    },
    '43': {
        '0': 32.153376343754786,
        '1': 32.15899009649595,
        '2': 32.16325741451688,
        '3': 32.1662177994834,
        '4': 32.16706328890998,
        '5': 32.16690635934657,
        '6': 32.16676535152976,
        '7': 32.16665648303274,
        '8': 32.166648150857604,
        '9': 32.16680257811336,
        '10': 32.16708426225501,
        '11': 32.16586013553195,
        '12': 32.164589482445415,
        '13': 32.158247211569204,
        '14': 32.16707813188982,
        '15': 32.16680026435603,
        '16': 32.166591131457785,
        '17': 32.16654397660931,
        '18': 32.16071767058035,
        '19': 32.15625192476735,
        '20': 32.15436337349254,
        '21': 32.154992566919965,
        '22': 32.166103170887375,
        '23': 32.16135636725869,
        '24': 32.16333139635395,
        '25': 32.16293581090043,
        '26': 32.16314359827452,
        '27': 32.16442432807505,
        '28': 32.16730013376442,
        '29': 32.16668563667754,
        '30': 32.16612447336462,
        '31': 32.16584766147378,
        '32': 32.16581398834075,
        '33': 32.16585106533481,
        '34': 32.165861745494546,
        '35': 32.16583556671322,
        '36': 32.16573760380985,
        '37': 32.16553198083902,
        '38': 32.16535923401754,
        '39': 32.16556987453844,
        '40': 32.166421297967,
        '41': 32.167654854257364,
        '42': 32.15247863059368,
        '43': 32.14141458382379,
        '44': 32.15864675541896,
        '45': 32.15983321179985,
        '46': 32.16072708644231,
        '47': 32.160635331901474,
        '48': 32.16571153727147,
        '49': 32.16532318361984,
        '50': 32.1653887177848,
        '51': 32.16599084740301,
        '52': 32.166250878207016,
        '53': 32.16578036097019,
        '54': 32.16554165379429,
        '55': 32.16301846563113,
        '56': 32.16340829818712,
        '57': 32.16357181289126,
        '58': 32.165096860843214,
        '59': 32.16572607668756,
        '60': 32.16548353497892,
        '61': 32.165280838021886,
        '62': 32.165277478478075,
        '63': 32.16469376878119,
        '64': 32.164140958318235,
        '65': 32.16394525579083,
        '66': 32.163675629386184,
        '67': 32.16361075087644,
        '68': 32.163810837349956,
        '69': 32.16374086948068,
        '70': 32.15751611541443,
        '71': 32.161932139454606,
        '72': 32.160774390239936,
        '73': 32.16022928633931,
        '74': 32.160439112409136,
        '75': 32.15827318290493,
        '76': 32.147626208712744,
        '77': 32.13448082299927,
        '78': 32.1437724328691,
        '79': 32.14971667220568,
        '80': 32.14783936285139,
        '81': 32.150422889035866,
        '82': 32.1488195648578,
        '83': 32.131229223695264,
        '84': 32.14162769295972,
        '85': 32.142384738590614,
        '86': 32.130393642272224,
        '87': 32.12778746872625,
        '88': 32.156799125312524,
        '89': 32.16349121767755,
        '90': 32.15911739234504,
        '91': 32.16035777143863,
        '92': 32.16103326309832,
        '93': 32.15703518341385,
        '94': 32.146472457156946,
        '95': 32.14519789535668,
        '96': 32.155122162573136,
        '97': 32.1516141612393,
        '98': 32.151810735417335,
        '99': 32.15172555543698,
        '100': 32.151496815326176,
        '101': 32.15036878993071,
        '102': 32.14885639167737,
        '103': 32.15310617509329,
        '104': 32.150904017546104,
        '105': 32.149058819525656,
        '106': 32.152864563550146,
        '107': 32.15385164231429,
        '108': 32.15650015475952,
        '109': 32.15455857098725,
        '110': 32.1540365929994,
        '111': 32.15510500244235,
        '112': 32.1548926033061,
        '113': 32.15598497818008,
        '114': 32.15548826289289,
        '115': 32.15452559926708,
        '116': 32.153754993290086,
        '117': 32.15411781662418,
        '118': 32.153115348844956,
        '119': 32.16063256582932,
        '120': 32.162740704755066,
        '121': 32.16351447877142,
        '122': 32.164041798462314,
        '123': 32.16511165960063,
        '124': 32.165721869588616,
        '125': 32.164582706003415,
        '126': 32.16399133530273,
        '127': 32.16386123911378,
        '128': 32.15908791123835,
        '129': 32.161928508112034,
        '130': 32.16684633972028,
        '131': 32.16445876220878,
        '132': 32.16747877529288,
        '133': 32.1621816691176,
        '134': 32.16541907024777,
        '135': 32.16711181578549,
        '136': 32.16684344520618,
        '137': 32.16655517202245,
        '138': 32.166575866274144,
        '139': 32.167078223048755,
        '140': 32.167807314572634,
        '141': 32.1586998851341,
        '142': 32.16544939927578,
        '143': 32.16612851735341,
        '144': 32.16675043077845,
        '145': 32.1667666284206,
        '146': 32.166747721027974,
        '147': 32.16574087308834,
        '148': 32.164533541472686,
        '149': 32.163868969681246,
        '150': 32.164274043634016,
        '151': 32.165566108452495,
        '152': 32.16701095527542,
        '153': 32.16792521342921,
        '154': 32.16810564319412,
        '155': 32.16776084371023,
        '156': 32.16718463610898,
        '157': 32.16657637878182,
        '158': 32.16608990520218,
        '159': 32.16588022491224,
        '160': 32.16600271325632,
        '161': 32.166308952995166,
        '162': 32.1665358126962,
        '163': 32.16653075612815,
        '164': 32.166362271541495,
        '165': 32.16620059693156,
        '166': 32.16613172479969,
        '167': 32.16611851050887,
        '168': 32.16610415777542,
        '169': 32.1660821736433,
        '170': 32.166052399804954,
        '171': 32.165974719997664,
        '172': 32.16582841115857,
        '173': 32.16569802472813,
        '174': 32.16572807788971,
        '175': 32.1659584664084,
        '176': 32.16624202507922,
        '177': 32.16637345388045,
        '178': 32.16629627316854,
        '179': 32.16614626444537,
        '180': 32.16608712585924,
        '-180': 32.16608712585924,
        '-179': 32.16614384987982,
        '-178': 32.16622184924252,
        '-177': 32.166252863662,
        '-176': 32.166264620821096,
        '-175': 32.166304098557795,
        '-174': 32.166350042397156,
        '-173': 32.16634620596563,
        '-172': 32.16629796750507,
        '-171': 32.16628020858059,
        '-170': 32.16633560701174,
        '-169': 32.16640671839844,
        '-168': 32.16640537731069,
        '-167': 32.166332677166515,
        '-166': 32.1662878424294,
        '-165': 32.16635006072658,
        '-164': 32.16648512841954,
        '-163': 32.16659295694594,
        '-162': 32.166626508012776,
        '-161': 32.16662853844603,
        '-160': 32.16665280071599,
        '-159': 32.16668749433776,
        '-158': 32.166680026764595,
        '-157': 32.166615293351676,
        '-156': 32.166533975950244,
        '-155': 32.166473469741675,
        '-154': 32.166424295332085,
        '-153': 32.16636354528048,
        '-152': 32.16630798916257,
        '-151': 32.166299623788206,
        '-150': 32.16633885082252,
        '-149': 32.16636514460767,
        '-148': 32.166325359316055,
        '-147': 32.16624494164029,
        '-146': 32.1662036315522,
        '-145': 32.16624070275332,
        '-144': 32.166310360173,
        '-143': 32.16634381332907,
        '-142': 32.16633418792568,
        '-141': 32.16633200261853,
        '-140': 32.16636117662035,
        '-139': 32.1663740227632,
        '-138': 32.166311088082054,
        '-137': 32.16618721250076,
        '-136': 32.16608460931055,
        '-135': 32.16605689797628,
        '-134': 32.16607251304078,
        '-133': 32.1660784547379,
        '-132': 32.166098117401056,
        '-131': 32.166213538743534,
        '-130': 32.166427444284366,
        '-129': 32.1665830570121,
        '-128': 32.16649019633497,
        '-127': 32.16616103530187,
        '-126': 32.16588180896262,
        '-125': 32.165982422067735,
        '-124': 32.16534811545975,
        '-123': 32.16105216847503,
        '-122': 32.150505168896096,
        '-121': 32.14951587521592,
        '-120': 32.153655984436575,
        '-119': 32.151816048269076,
        '-118': 32.15401716555501,
        '-117': 32.15129679553761,
        '-116': 32.15884877499634,
        '-115': 32.156536871596735,
        '-114': 32.153553164174554,
        '-113': 32.152542252935966,
        '-112': 32.150414678833535,
        '-111': 32.149751532926494,
        '-110': 32.14486298144258,
        '-109': 32.1484211388257,
        '-108': 32.150863515173924,
        '-107': 32.14882236869501,
        '-106': 32.15020131627918,
        '-105': 32.15275315962656,
        '-104': 32.15555752658474,
        '-103': 32.15693666072938,
        '-102': 32.15615397110707,
        '-101': 32.157555566866236,
        '-100': 32.15924198737216,
        '-99': 32.16065534150293,
        '-98': 32.16184707552194,
        '-97': 32.16294988625377,
        '-96': 32.16257190163624,
        '-95': 32.162304277432106,
        '-94': 32.16300232915396,
        '-93': 32.16313850421116,
        '-92': 32.16314496146286,
        '-91': 32.162842793810704,
        '-90': 32.16247003448534,
        '-89': 32.16321317672678,
        '-88': 32.163698797572486,
        '-87': 32.16552961037737,
        '-86': 32.165013944381705,
        '-85': 32.16458054493859,
        '-84': 32.1643786274967,
        '-83': 32.163713881866244,
        '-82': 32.16372581702443,
        '-81': 32.16332167776754,
        '-80': 32.164026476048605,
        '-79': 32.16441004591189,
        '-78': 32.16345338524823,
        '-77': 32.16448964761331,
        '-76': 32.16432745868437,
        '-75': 32.16492581468072,
        '-74': 32.16466268406617,
        '-73': 32.16024583658158,
        '-72': 32.163316837250655,
        '-71': 32.166380252877026,
        '-70': 32.166657191878585,
        '-69': 32.16678998507933,
        '-68': 32.16700073419154,
        '-67': 32.16712818966626,
        '-66': 32.16708553875766,
        '-65': 32.16690215613662,
        '-64': 32.16667367622605,
        '-63': 32.166476747732865,
        '-62': 32.16632097782504,
        '-61': 32.16617507950977,
        '-60': 32.166034397722946,
        '-59': 32.165947881526215,
        '-58': 32.16595958116058,
        '-57': 32.16602783998661,
        '-56': 32.16604144707377,
        '-55': 32.16595613982635,
        '-54': 32.165907922169346,
        '-53': 32.166132584198586,
        '-52': 32.16671725180957,
        '-51': 32.167433424260395,
        '-50': 32.167869299862005,
        '-49': 32.16777706704731,
        '-48': 32.167298758891,
        '-47': 32.16683487871843,
        '-46': 32.1666859868078,
        '-45': 32.166827837047315,
        '-44': 32.167020157991814,
        '-43': 32.167088360792775,
        '-42': 32.167065466722484,
        '-41': 32.16708370236271,
        '-40': 32.16719329260345,
        '-39': 32.16732536874443,
        '-38': 32.16740232449374,
        '-37': 32.16742533289926,
        '-36': 32.167437264125645,
        '-35': 32.16744453465076,
        '-34': 32.16742542450816,
        '-33': 32.16740923759434,
        '-32': 32.167491251645416,
        '-31': 32.167726251381225,
        '-30': 32.16802035946113,
        '-29': 32.16817628068149,
        '-28': 32.16807567189313,
        '-27': 32.167807137304386,
        '-26': 32.16759004659687,
        '-25': 32.16756559625289,
        '-24': 32.16767339184069,
        '-23': 32.167736286365,
        '-22': 32.16765073289422,
        '-21': 32.16747841471802,
        '-20': 32.167358093684044,
        '-19': 32.167351487424305,
        '-18': 32.16738895334059,
        '-17': 32.167354104998786,
        '-16': 32.16720056264289,
        '-15': 32.166983835426635,
        '-14': 32.166801305516906,
        '-13': 32.16672343076971,
        '-12': 32.1667793516494,
        '-11': 32.16697833977797,
        '-10': 32.167311603692006,
        '-9': 32.16483391842463,
        '-8': 32.16091897335055,
        '-7': 32.161986113584895,
        '-6': 32.15551574604338,
        '-5': 32.156188853582535,
        '-4': 32.15886001266935,
        '-3': 32.16417921056523,
        '-2': 32.1585706125045,
        '-1': 32.1538493013052
    },
    '44': {
        '0': 32.16842325303782,
        '1': 32.16884797380341,
        '2': 32.16771013913602,
        '3': 32.165016610136725,
        '4': 32.168918548696,
        '5': 32.17046209390976,
        '6': 32.16345186353071,
        '7': 32.163176695496176,
        '8': 32.16292675236668,
        '9': 32.16927715548593,
        '10': 32.168896488611125,
        '11': 32.15928044451514,
        '12': 32.1633486609094,
        '13': 32.168819754633894,
        '14': 32.168930949612204,
        '15': 32.16919494100325,
        '16': 32.167192369893954,
        '17': 32.15490223595332,
        '18': 32.16398286846259,
        '19': 32.159864685050906,
        '20': 32.164514450241924,
        '21': 32.16742070975563,
        '22': 32.16764481350712,
        '23': 32.16968023538563,
        '24': 32.16883349045868,
        '25': 32.16888590108074,
        '26': 32.169360644082865,
        '27': 32.168820671584896,
        '28': 32.16908822987303,
        '29': 32.17005405563881,
        '30': 32.169834962094995,
        '31': 32.16952553984765,
        '32': 32.169136191346475,
        '33': 32.16873472893136,
        '34': 32.1684616222954,
        '35': 32.16841160126363,
        '36': 32.16852810679889,
        '37': 32.16868770142732,
        '38': 32.16890427747234,
        '39': 32.1693704719119,
        '40': 32.15462970312188,
        '41': 32.160948414765215,
        '42': 32.1625568021763,
        '43': 32.16466732983762,
        '44': 32.167739984313364,
        '45': 32.167740052446135,
        '46': 32.16781451703635,
        '47': 32.16782015823885,
        '48': 32.16803410519198,
        '49': 32.16842503842244,
        '50': 32.16888820114778,
        '51': 32.16896079506635,
        '52': 32.16853551422082,
        '53': 32.16851330984203,
        '54': 32.1668775735071,
        '55': 32.16725449901429,
        '56': 32.167377514469564,
        '57': 32.16784529849739,
        '58': 32.167823918423196,
        '59': 32.16879367018899,
        '60': 32.16846740765335,
        '61': 32.168108336381025,
        '62': 32.168153128764494,
        '63': 32.16824410978947,
        '64': 32.168204480400966,
        '65': 32.16771444703155,
        '66': 32.16738386039525,
        '67': 32.16725571426665,
        '68': 32.160597023614145,
        '69': 32.165794858937936,
        '70': 32.166328842579134,
        '71': 32.16583155705937,
        '72': 32.165353167743135,
        '73': 32.16505774313521,
        '74': 32.16358352654528,
        '75': 32.15895901037212,
        '76': 32.16135021996674,
        '77': 32.16208260358785,
        '78': 32.15361658591444,
        '79': 32.16078469522866,
        '80': 32.164720863094686,
        '81': 32.163394166277286,
        '82': 32.15820889524236,
        '83': 32.14227340461648,
        '84': 32.14001167318364,
        '85': 32.14916340538005,
        '86': 32.15793548036926,
        '87': 32.160886021535255,
        '88': 32.15697778267754,
        '89': 32.15940730559023,
        '90': 32.15947925948881,
        '91': 32.15703468757407,
        '92': 32.151307972061794,
        '93': 32.152659682081925,
        '94': 32.1604589214152,
        '95': 32.16273939721803,
        '96': 32.158453553557635,
        '97': 32.15683971046143,
        '98': 32.1525822913604,
        '99': 32.154672980712085,
        '100': 32.15601201530226,
        '101': 32.156859444356265,
        '102': 32.15787345975675,
        '103': 32.154220703991406,
        '104': 32.15519039821264,
        '105': 32.15320476380058,
        '106': 32.15515665697586,
        '107': 32.15460990325382,
        '108': 32.158713387181734,
        '109': 32.16056399691752,
        '110': 32.15931886396912,
        '111': 32.1569947050715,
        '112': 32.15784114752687,
        '113': 32.159506942277005,
        '114': 32.158411567830164,
        '115': 32.15853973733637,
        '116': 32.15987107969491,
        '117': 32.15685837575196,
        '118': 32.15967131909227,
        '119': 32.16127039143922,
        '120': 32.164877706588015,
        '121': 32.16700584170068,
        '122': 32.16775498698381,
        '123': 32.16826075770406,
        '124': 32.168069057549644,
        '125': 32.16820832048397,
        '126': 32.16760197040723,
        '127': 32.1671674539594,
        '128': 32.16341474946779,
        '129': 32.16628635083257,
        '130': 32.16372412810328,
        '131': 32.16735475756154,
        '132': 32.16997769203323,
        '133': 32.16661193850557,
        '134': 32.1659083649212,
        '135': 32.16526971127599,
        '136': 32.170252297615654,
        '137': 32.16978014928918,
        '138': 32.16952679812711,
        '139': 32.16975068523851,
        '140': 32.17039396651368,
        '141': 32.17115340827927,
        '142': 32.167046947986826,
        '143': 32.16617600418841,
        '144': 32.17070324422342,
        '145': 32.16457099558791,
        '146': 32.17233380744023,
        '147': 32.17102378482513,
        '148': 32.169118737023865,
        '149': 32.16731398077177,
        '150': 32.16631286584818,
        '151': 32.166387292182705,
        '152': 32.167294551993514,
        '153': 32.168530672659706,
        '154': 32.169627684519796,
        '155': 32.17028903614974,
        '156': 32.17040346266247,
        '157': 32.170052748965986,
        '158': 32.169502050755455,
        '159': 32.1690824703877,
        '160': 32.16898970788815,
        '161': 32.16916816303589,
        '162': 32.16939910701952,
        '163': 32.16950127009117,
        '164': 32.169442967732145,
        '165': 32.16929010256753,
        '166': 32.16910448929871,
        '167': 32.16892315148795,
        '168': 32.16879935779347,
        '169': 32.168795559480905,
        '170': 32.16890941477924,
        '171': 32.169042182194076,
        '172': 32.16908552206014,
        '173': 32.16904164147144,
        '174': 32.169026529483496,
        '175': 32.169139796607496,
        '176': 32.16935074460065,
        '177': 32.169532207324785,
        '178': 32.169594301279304,
        '179': 32.16955946375275,
        '180': 32.16950945730324,
        '-180': 32.16950945730324,
        '-179': 32.169493459974476,
        '-178': 32.169509224832396,
        '-177': 32.16954941280931,
        '-176': 32.169622018216735,
        '-175': 32.1697146028353,
        '-174': 32.16977232247037,
        '-173': 32.169745806398794,
        '-172': 32.169656519057334,
        '-171': 32.169585492233224,
        '-170': 32.169585332855476,
        '-169': 32.16962055534173,
        '-168': 32.16961148603415,
        '-167': 32.169527933741676,
        '-166': 32.1694198828206,
        '-165': 32.16935691279194,
        '-164': 32.169359176247255,
        '-163': 32.169398038277166,
        '-162': 32.16944464158164,
        '-161': 32.16949200359912,
        '-160': 32.16953278082987,
        '-159': 32.16954570306134,
        '-158': 32.16952332354105,
        '-157': 32.169498135855235,
        '-156': 32.16951335029972,
        '-155': 32.169563334750215,
        '-154': 32.16958487282085,
        '-153': 32.16952414193382,
        '-152': 32.169402639897626,
        '-151': 32.16929752996021,
        '-150': 32.169257186358614,
        '-149': 32.169256033513925,
        '-148': 32.16924243621266,
        '-147': 32.16921312752211,
        '-146': 32.16921519251882,
        '-145': 32.16927462846772,
        '-144': 32.16934904036834,
        '-143': 32.1693704267516,
        '-142': 32.16932416714234,
        '-141': 32.16926135396789,
        '-140': 32.16922894596683,
        '-139': 32.16921053142811,
        '-138': 32.16915583753008,
        '-137': 32.16905802604813,
        '-136': 32.16897269390249,
        '-135': 32.168950472878166,
        '-134': 32.16897445430367,
        '-133': 32.16899176594414,
        '-132': 32.16899961650963,
        '-131': 32.169059810483915,
        '-130': 32.16920055473381,
        '-129': 32.16932689434632,
        '-128': 32.16928551959466,
        '-127': 32.16905277803675,
        '-126': 32.168833714358136,
        '-125': 32.16891371196636,
        '-124': 32.169068427513615,
        '-123': 32.168488109731385,
        '-122': 32.155720326903676,
        '-121': 32.159348277599854,
        '-120': 32.15578407381464,
        '-119': 32.15475869280697,
        '-118': 32.15461538439355,
        '-117': 32.16363857989217,
        '-116': 32.1530103976637,
        '-115': 32.143003631260115,
        '-114': 32.14960812383386,
        '-113': 32.14407312492816,
        '-112': 32.155017654732305,
        '-111': 32.15079319313337,
        '-110': 32.144900723695514,
        '-109': 32.14980215223502,
        '-108': 32.15762898611695,
        '-107': 32.14546967456843,
        '-106': 32.15598761910769,
        '-105': 32.155868879839765,
        '-104': 32.14969272591174,
        '-103': 32.16120988066431,
        '-102': 32.16196530523532,
        '-101': 32.16267097011272,
        '-100': 32.16337276499696,
        '-99': 32.16410269888317,
        '-98': 32.16546372296766,
        '-97': 32.16437419386259,
        '-96': 32.16433227640813,
        '-95': 32.165592449338995,
        '-94': 32.1665167081712,
        '-93': 32.16565415904439,
        '-92': 32.16598743180473,
        '-91': 32.1658712018677,
        '-90': 32.16591578669182,
        '-89': 32.16607859756669,
        '-88': 32.16598274911346,
        '-87': 32.168268346051896,
        '-86': 32.16643837989211,
        '-85': 32.16619965586777,
        '-84': 32.16738056135284,
        '-83': 32.16723234657148,
        '-82': 32.16785549249231,
        '-81': 32.16527718060107,
        '-80': 32.165763713545715,
        '-79': 32.1663794090689,
        '-78': 32.16756279504018,
        '-77': 32.16815791996145,
        '-76': 32.16814584162984,
        '-75': 32.16347016861345,
        '-74': 32.163497701126936,
        '-73': 32.16493175708469,
        '-72': 32.16616745064064,
        '-71': 32.1681425239967,
        '-70': 32.16857190844643,
        '-69': 32.16947040647229,
        '-68': 32.16950455688949,
        '-67': 32.16951256836591,
        '-66': 32.16882142296883,
        '-65': 32.168409188313746,
        '-64': 32.16939662008691,
        '-63': 32.16952063688182,
        '-62': 32.16970744259324,
        '-61': 32.169835299807254,
        '-60': 32.169819411076354,
        '-59': 32.16968530116401,
        '-58': 32.16953411522659,
        '-57': 32.16943892361725,
        '-56': 32.16939628125788,
        '-55': 32.16939331247673,
        '-54': 32.16949685747027,
        '-53': 32.16981984299887,
        '-52': 32.17036228861169,
        '-51': 32.170907293667916,
        '-50': 32.17113803291747,
        '-49': 32.1709070917363,
        '-48': 32.17039107601856,
        '-47': 32.1699505711241,
        '-46': 32.16981891796616,
        '-45': 32.16992965642804,
        '-44': 32.170044783288816,
        '-43': 32.17002509873009,
        '-42': 32.1699489821346,
        '-41': 32.169977628667276,
        '-40': 32.170149570437225,
        '-39': 32.17034154420083,
        '-38': 32.17041774950361,
        '-37': 32.17037541836861,
        '-36': 32.170324298159436,
        '-35': 32.17034871319339,
        '-34': 32.17042800946086,
        '-33': 32.17049634131345,
        '-32': 32.17054427829912,
        '-31': 32.17062565256472,
        '-30': 32.170766866714935,
        '-29': 32.17090374394575,
        '-28': 32.17093519368559,
        '-27': 32.17083532998034,
        '-26': 32.1706907296918,
        '-25': 32.1706153978647,
        '-24': 32.17063651611098,
        '-23': 32.170675065676676,
        '-22': 32.17063725638888,
        '-21': 32.17051354819789,
        '-20': 32.1703820895957,
        '-19': 32.17032207772577,
        '-18': 32.17033473050375,
        '-17': 32.17035116160841,
        '-16': 32.17030896115094,
        '-15': 32.17021154407812,
        '-14': 32.170111736324024,
        '-13': 32.170045365037275,
        '-12': 32.16999062566073,
        '-11': 32.16989636235361,
        '-10': 32.16974537903879,
        '-9': 32.16957947265202,
        '-8': 32.169453895416346,
        '-7': 32.16936981398415,
        '-6': 32.169266992315684,
        '-5': 32.16910026606351,
        '-4': 32.168925090665816,
        '-3': 32.16888747297566,
        '-2': 32.16910154758725,
        '-1': 32.16860454233132
    },
    '45': {
        '0': 32.172048172750756,
        '1': 32.17060796523114,
        '2': 32.16717755603211,
        '3': 32.16302201331352,
        '4': 32.166002434674766,
        '5': 32.17237034620874,
        '6': 32.15091316198803,
        '7': 32.148115155217944,
        '8': 32.170941360439244,
        '9': 32.17164259646542,
        '10': 32.17154853998493,
        '11': 32.171517831514244,
        '12': 32.17159563621706,
        '13': 32.171731592609746,
        '14': 32.17002937855861,
        '15': 32.164454665943765,
        '16': 32.16890866235422,
        '17': 32.16818272695987,
        '18': 32.172628925248794,
        '19': 32.17281336263966,
        '20': 32.172507288090294,
        '21': 32.17182093931305,
        '22': 32.16707746541035,
        '23': 32.170501219866246,
        '24': 32.16914978644356,
        '25': 32.168442235582596,
        '26': 32.17066253699471,
        '27': 32.17214868074645,
        '28': 32.17285508243395,
        '29': 32.17308593886316,
        '30': 32.17325086647568,
        '31': 32.17322404117642,
        '32': 32.172969197000306,
        '33': 32.17258550102417,
        '34': 32.17020298995946,
        '35': 32.16810591584169,
        '36': 32.171912679696284,
        '37': 32.171854389640856,
        '38': 32.17187586957884,
        '39': 32.17199736494558,
        '40': 32.17193647446316,
        '41': 32.171136032495724,
        '42': 32.168518274409394,
        '43': 32.16891206092782,
        '44': 32.169873700752674,
        '45': 32.17074976981121,
        '46': 32.170893202809495,
        '47': 32.17118789669353,
        '48': 32.17175073480457,
        '49': 32.17221899934163,
        '50': 32.172461113402605,
        '51': 32.17255570812044,
        '52': 32.172601234968795,
        '53': 32.172608420512695,
        '54': 32.17255415820271,
        '55': 32.17194280368849,
        '56': 32.171655839093006,
        '57': 32.17091384150837,
        '58': 32.17021668596525,
        '59': 32.171488866565845,
        '60': 32.17145468829345,
        '61': 32.17135155431742,
        '62': 32.171333163768345,
        '63': 32.17101243498725,
        '64': 32.1708246411083,
        '65': 32.17043977160941,
        '66': 32.17027739186106,
        '67': 32.170312552693204,
        '68': 32.17035542314835,
        '69': 32.16949564275591,
        '70': 32.16931691430014,
        '71': 32.16824780131279,
        '72': 32.16817885689094,
        '73': 32.166541833982535,
        '74': 32.167724295797605,
        '75': 32.167279160658545,
        '76': 32.1667493279096,
        '77': 32.16656993168927,
        '78': 32.1662604825618,
        '79': 32.15259243062193,
        '80': 32.13935396700555,
        '81': 32.158189161407776,
        '82': 32.16509207641831,
        '83': 32.16930292176634,
        '84': 32.16825246835327,
        '85': 32.16689056783365,
        '86': 32.16550701156681,
        '87': 32.16398561409555,
        '88': 32.162584872555215,
        '89': 32.16364425858192,
        '90': 32.16117079258481,
        '91': 32.159155261665425,
        '92': 32.157320607042394,
        '93': 32.15388765814103,
        '94': 32.153679925805825,
        '95': 32.146548745851234,
        '96': 32.15874835380566,
        '97': 32.15616531332754,
        '98': 32.14656964215058,
        '99': 32.151773418119845,
        '100': 32.15116750537286,
        '101': 32.158232108944425,
        '102': 32.158175258371635,
        '103': 32.15884559138614,
        '104': 32.15972915930701,
        '105': 32.158078265121006,
        '106': 32.159652294636466,
        '107': 32.16022527250118,
        '108': 32.16076798209243,
        '109': 32.161478556017265,
        '110': 32.16210371192293,
        '111': 32.161498243729866,
        '112': 32.16108174092492,
        '113': 32.16039909495951,
        '114': 32.15778750881105,
        '115': 32.159133216722886,
        '116': 32.16406725149317,
        '117': 32.16226306876192,
        '118': 32.16313739448354,
        '119': 32.162697771423055,
        '120': 32.16291621869699,
        '121': 32.16827154587495,
        '122': 32.17076525445883,
        '123': 32.171187309801496,
        '124': 32.171231642639576,
        '125': 32.17118788239812,
        '126': 32.17094505073439,
        '127': 32.17132360570335,
        '128': 32.17081734891988,
        '129': 32.163560509602924,
        '130': 32.16898843028833,
        '131': 32.169175478685965,
        '132': 32.17237375164577,
        '133': 32.17264841947423,
        '134': 32.17137781456534,
        '135': 32.16864810228385,
        '136': 32.168051051488646,
        '137': 32.17331653302932,
        '138': 32.1729165232294,
        '139': 32.17280205203115,
        '140': 32.173036531277354,
        '141': 32.17343250278877,
        '142': 32.172225874875544,
        '143': 32.17408627208676,
        '144': 32.17446821701717,
        '145': 32.17495766948543,
        '146': 32.175313160559625,
        '147': 32.17515176814985,
        '148': 32.17425579929444,
        '149': 32.17278035426299,
        '150': 32.171194891330785,
        '151': 32.170033135282814,
        '152': 32.16964445426776,
        '153': 32.17007313074311,
        '154': 32.1710746443204,
        '155': 32.17223077282904,
        '156': 32.17312499425333,
        '157': 32.1735150799287,
        '158': 32.173410460218086,
        '159': 32.17300602806501,
        '160': 32.17253434447342,
        '161': 32.17215787155434,
        '162': 32.171956013100164,
        '163': 32.17195002508438,
        '164': 32.17209474838556,
        '165': 32.172260236350716,
        '166': 32.172285548245654,
        '167': 32.172108299721046,
        '168': 32.17184774316021,
        '169': 32.17172486049537,
        '170': 32.17186692664997,
        '171': 32.17218414964772,
        '172': 32.172447056833455,
        '173': 32.1724890878647,
        '174': 32.17233596352384,
        '175': 32.17215231045753,
        '176': 32.172084322519886,
        '177': 32.17215684187333,
        '178': 32.172293766132434,
        '179': 32.172402316298154,
        '180': 32.17243334235405,
        '-180': 32.17243334235405,
        '-179': 32.17239270604244,
        '-178': 32.17232807428419,
        '-177': 32.17230487814875,
        '-176': 32.17236699350666,
        '-175': 32.17249875137472,
        '-174': 32.17262943702915,
        '-173': 32.17269211683442,
        '-172': 32.17268579760442,
        '-171': 32.172675260659574,
        '-170': 32.1727258765153,
        '-169': 32.172840336514575,
        '-168': 32.17295817012682,
        '-167': 32.17300899806238,
        '-166': 32.17296254416506,
        '-165': 32.17283904829025,
        '-164': 32.172690126628275,
        '-163': 32.17257251459082,
        '-162': 32.172520299916734,
        '-161': 32.1725207226654,
        '-160': 32.17251800637071,
        '-159': 32.17246180374831,
        '-158': 32.17236590609278,
        '-157': 32.172309905467706,
        '-156': 32.17236382345048,
        '-155': 32.172506228197875,
        '-154': 32.17262947130022,
        '-153': 32.17263713046432,
        '-152': 32.17253324771041,
        '-151': 32.17240839161142,
        '-150': 32.17234350029392,
        '-149': 32.17234227121126,
        '-148': 32.17235968936473,
        '-147': 32.172374711766956,
        '-146': 32.172408577225724,
        '-145': 32.17247072535242,
        '-144': 32.1725140405829,
        '-143': 32.17247018911141,
        '-142': 32.17232890249648,
        '-141': 32.17216167160826,
        '-140': 32.172054571650364,
        '-139': 32.172026856195735,
        '-138': 32.17202940749559,
        '-137': 32.17201775022502,
        '-136': 32.17200448021593,
        '-135': 32.17202900026922,
        '-134': 32.17209323352635,
        '-133': 32.172154853546004,
        '-132': 32.17218747080493,
        '-131': 32.17221921982421,
        '-130': 32.17228375021409,
        '-129': 32.17234185282022,
        '-128': 32.17229487780559,
        '-127': 32.17211055790012,
        '-126': 32.17192321269615,
        '-125': 32.171957451754714,
        '-124': 32.17196397110665,
        '-123': 32.1723216840418,
        '-122': 32.16311593277533,
        '-121': 32.162830289545205,
        '-120': 32.1591244271793,
        '-119': 32.16173069481487,
        '-118': 32.1619874582351,
        '-117': 32.15727211662866,
        '-116': 32.15044159452666,
        '-115': 32.15400931857613,
        '-114': 32.15276103458533,
        '-113': 32.15599677845348,
        '-112': 32.151146029096935,
        '-111': 32.1497303897889,
        '-110': 32.15051963534809,
        '-109': 32.159612988220786,
        '-108': 32.155716890912025,
        '-107': 32.16081389917321,
        '-106': 32.16082718264098,
        '-105': 32.1610218874402,
        '-104': 32.163459048462514,
        '-103': 32.16443086530423,
        '-102': 32.165041297603224,
        '-101': 32.16556571543463,
        '-100': 32.16582305075314,
        '-99': 32.16783163864888,
        '-98': 32.1682968607623,
        '-97': 32.16666961919702,
        '-96': 32.16958602458976,
        '-95': 32.16938106601849,
        '-94': 32.16974957682503,
        '-93': 32.16982453295122,
        '-92': 32.16940436214541,
        '-91': 32.1690823546256,
        '-90': 32.16795961658024,
        '-89': 32.16781606859521,
        '-88': 32.169467636089884,
        '-87': 32.17054094060687,
        '-86': 32.170433076874865,
        '-85': 32.16931078264309,
        '-84': 32.169749527441624,
        '-83': 32.17072877611894,
        '-82': 32.171883723940596,
        '-81': 32.171198115659244,
        '-80': 32.17041311166083,
        '-79': 32.16872080966126,
        '-78': 32.16832261120315,
        '-77': 32.16910277088516,
        '-76': 32.1707315842667,
        '-75': 32.17148097838268,
        '-74': 32.17055305590868,
        '-73': 32.17173915795081,
        '-72': 32.16839652602714,
        '-71': 32.16770509021649,
        '-70': 32.16819240723917,
        '-69': 32.171599625191355,
        '-68': 32.171259836796054,
        '-67': 32.17235089402456,
        '-66': 32.17223922258728,
        '-65': 32.171473184909075,
        '-64': 32.171844314328354,
        '-63': 32.171191560614446,
        '-62': 32.17257244992822,
        '-61': 32.17270559566555,
        '-60': 32.17268556736622,
        '-59': 32.17256011793512,
        '-58': 32.1724447276752,
        '-57': 32.17243318019153,
        '-56': 32.17253001890143,
        '-55': 32.17268370850033,
        '-54': 32.1728693208229,
        '-53': 32.17310785513474,
        '-52': 32.17339225576932,
        '-51': 32.17362245109171,
        '-50': 32.17366000389694,
        '-49': 32.173472609722864,
        '-48': 32.173198923899825,
        '-47': 32.173037772595066,
        '-46': 32.17305625567836,
        '-45': 32.17312698667456,
        '-44': 32.17307541360612,
        '-43': 32.17288321678479,
        '-42': 32.172721375683864,
        '-41': 32.172770107258174,
        '-40': 32.17302079394275,
        '-39': 32.173277471815624,
        '-38': 32.1733556954982,
        '-37': 32.173262459918966,
        '-36': 32.1731694534595,
        '-35': 32.1732223911063,
        '-34': 32.17339950313182,
        '-33': 32.17356130126051,
        '-32': 32.17361652067286,
        '-31': 32.17361312511647,
        '-30': 32.17366467170923,
        '-29': 32.17381027484834,
        '-28': 32.173968618887606,
        '-27': 32.174028100869066,
        '-26': 32.17396284390017,
        '-25': 32.17384670631486,
        '-24': 32.1737604104554,
        '-23': 32.17370390350954,
        '-22': 32.1736108453032,
        '-21': 32.17344224407158,
        '-20': 32.17324947186163,
        '-19': 32.1731346854022,
        '-18': 32.17315085707702,
        '-17': 32.17324768834144,
        '-16': 32.173317624139074,
        '-15': 32.17328887459574,
        '-14': 32.17316705815551,
        '-13': 32.17299027868376,
        '-12': 32.17276147563604,
        '-11': 32.17244525563188,
        '-10': 32.17203685329407,
        '-9': 32.171618316487454,
        '-8': 32.17132119538785,
        '-7': 32.1712194589934,
        '-6': 32.17126559212824,
        '-5': 32.17134756566143,
        '-4': 32.171412265845014,
        '-3': 32.17151880236895,
        '-2': 32.17175648268518,
        '-1': 32.171814825647836
    },
    '46': {
        '0': 32.174684736649695,
        '1': 32.17222159325524,
        '2': 32.16996267274398,
        '3': 32.16955989837925,
        '4': 32.17259116323964,
        '5': 32.17374683870047,
        '6': 32.17208781032666,
        '7': 32.14644595312095,
        '8': 32.149613946075604,
        '9': 32.17306690847007,
        '10': 32.15751848827579,
        '11': 32.166610476204085,
        '12': 32.16810056182973,
        '13': 32.17561524419765,
        '14': 32.17048355404024,
        '15': 32.17152053077089,
        '16': 32.174513257471894,
        '17': 32.174616578057545,
        '18': 32.1752668154274,
        '19': 32.17512277521567,
        '20': 32.17502606245021,
        '21': 32.17510543103966,
        '22': 32.17433534231084,
        '23': 32.17128325336174,
        '24': 32.1733504740903,
        '25': 32.17082303948463,
        '26': 32.168845776607235,
        '27': 32.17084754559676,
        '28': 32.17361581828664,
        '29': 32.17441292525781,
        '30': 32.17552846999215,
        '31': 32.17606940978562,
        '32': 32.17615610090873,
        '33': 32.17613751053957,
        '34': 32.17600383602849,
        '35': 32.1758277492924,
        '36': 32.17549435147838,
        '37': 32.175087381295356,
        '38': 32.174767400752394,
        '39': 32.17442448089236,
        '40': 32.17442601309523,
        '41': 32.17467047521604,
        '42': 32.174044016751175,
        '43': 32.17444794372987,
        '44': 32.17468460379425,
        '45': 32.17440393272424,
        '46': 32.175050054955925,
        '47': 32.17547499406261,
        '48': 32.17577087767852,
        '49': 32.17572693170862,
        '50': 32.17541510952036,
        '51': 32.175097443430374,
        '52': 32.17498434797995,
        '53': 32.17507718488924,
        '54': 32.17521097807273,
        '55': 32.175216316736055,
        '56': 32.17468079853834,
        '57': 32.17373183430173,
        '58': 32.17320686021709,
        '59': 32.17377737843861,
        '60': 32.17439667366403,
        '61': 32.17442543166321,
        '62': 32.17415312688209,
        '63': 32.17389812280334,
        '64': 32.173702602752996,
        '65': 32.17283737914509,
        '66': 32.17385524848502,
        '67': 32.17267460041386,
        '68': 32.172559928587184,
        '69': 32.171769708989395,
        '70': 32.17081882733974,
        '71': 32.17012104011983,
        '72': 32.16912102217263,
        '73': 32.170017449447855,
        '74': 32.17111363676394,
        '75': 32.17089611666044,
        '76': 32.170510665656984,
        '77': 32.17027307637643,
        '78': 32.17038351587675,
        '79': 32.16981184914626,
        '80': 32.16947169393324,
        '81': 32.16899390177757,
        '82': 32.17125597830267,
        '83': 32.160238584132664,
        '84': 32.15832966754293,
        '85': 32.16653734800947,
        '86': 32.16898452996224,
        '87': 32.16699959515448,
        '88': 32.16556731583315,
        '89': 32.165506200338726,
        '90': 32.16448115293427,
        '91': 32.16057974636786,
        '92': 32.161830703067324,
        '93': 32.1527644513149,
        '94': 32.15141345527141,
        '95': 32.16243780949007,
        '96': 32.16213411131491,
        '97': 32.15711683895019,
        '98': 32.15776186781992,
        '99': 32.15785411442553,
        '100': 32.15746249954867,
        '101': 32.15549532858205,
        '102': 32.153745379358135,
        '103': 32.158781940145026,
        '104': 32.159801790875626,
        '105': 32.16057282601698,
        '106': 32.16086255395557,
        '107': 32.16020339397173,
        '108': 32.161749838577784,
        '109': 32.16284229789778,
        '110': 32.16463322526684,
        '111': 32.162819963206715,
        '112': 32.16557749789007,
        '113': 32.16399272696434,
        '114': 32.16441071293879,
        '115': 32.16475777380986,
        '116': 32.1655598077215,
        '117': 32.165455709146826,
        '118': 32.16563237579321,
        '119': 32.16570360822626,
        '120': 32.167758249679096,
        '121': 32.1686818302966,
        '122': 32.17260854840251,
        '123': 32.17424377559883,
        '124': 32.174242471375536,
        '125': 32.174080267342546,
        '126': 32.17422996040154,
        '127': 32.17425925021251,
        '128': 32.174837348076245,
        '129': 32.17542820642377,
        '130': 32.17438877925078,
        '131': 32.17276590642238,
        '132': 32.17467187475579,
        '133': 32.17511159014275,
        '134': 32.17446859650005,
        '135': 32.173263608630045,
        '136': 32.17037062548241,
        '137': 32.16877282102385,
        '138': 32.17629614577927,
        '139': 32.176000567796635,
        '140': 32.175886539063306,
        '141': 32.17582367788773,
        '142': 32.174707404426144,
        '143': 32.17527818368113,
        '144': 32.17507927733473,
        '145': 32.1753398162076,
        '146': 32.17611867917759,
        '147': 32.17710322735061,
        '148': 32.17778621097031,
        '149': 32.177785210299284,
        '150': 32.17524265117913,
        '151': 32.17595109015487,
        '152': 32.1749054980761,
        '153': 32.17431793321061,
        '154': 32.17433571314846,
        '155': 32.1748543194953,
        '156': 32.17561303770563,
        '157': 32.17631859791962,
        '158': 32.17673346022662,
        '159': 32.17672293332057,
        '160': 32.17629122068449,
        '161': 32.17560822260187,
        '162': 32.174974445369514,
        '163': 32.174680552683405,
        '164': 32.17481898710158,
        '165': 32.175199461636815,
        '166': 32.17547609960674,
        '167': 32.17541314109468,
        '168': 32.17506867066787,
        '169': 32.174729673425006,
        '170': 32.17465564671047,
        '171': 32.17486447404598,
        '172': 32.175145385581835,
        '173': 32.175263228878826,
        '174': 32.17515150653364,
        '175': 32.174932628007966,
        '176': 32.174784040686895,
        '177': 32.174795546888795,
        '178': 32.174931792648486,
        '179': 32.175093999888496,
        '180': 32.17520039374088,
        '-180': 32.17520039374088,
        '-179': 32.175224789753635,
        '-178': 32.17519062102178,
        '-177': 32.17514536979141,
        '-176': 32.17513253316011,
        '-175': 32.17516889440894,
        '-174': 32.175237423139755,
        '-173': 32.17530358546223,
        '-172': 32.175345638397495,
        '-171': 32.175374821468786,
        '-170': 32.17542822324465,
        '-169': 32.1755394479891,
        '-168': 32.175708623821876,
        '-167': 32.17589320016309,
        '-166': 32.176028971643014,
        '-165': 32.1760724068882,
        '-164': 32.17603566189105,
        '-163': 32.175980522897,
        '-162': 32.17596513365525,
        '-161': 32.1759855747535,
        '-160': 32.17597341263257,
        '-159': 32.175863894461685,
        '-158': 32.17567263009217,
        '-157': 32.17549692380062,
        '-156': 32.17542984778653,
        '-155': 32.17547150223634,
        '-154': 32.17553230295902,
        '-153': 32.175526516617076,
        '-152': 32.17545528670206,
        '-151': 32.17539151161272,
        '-150': 32.17539096949194,
        '-149': 32.17543484612669,
        '-148': 32.17546462279964,
        '-147': 32.17545707658504,
        '-146': 32.17544298911066,
        '-145': 32.17544964129635,
        '-144': 32.17544671528326,
        '-143': 32.17537237841098,
        '-142': 32.17521373715678,
        '-141': 32.1750417132201,
        '-140': 32.17494961821099,
        '-139': 32.174960197355524,
        '-138': 32.175007215781214,
        '-137': 32.17501291401154,
        '-136': 32.17497261419304,
        '-135': 32.17495308897186,
        '-134': 32.17501426495005,
        '-133': 32.17514830788623,
        '-132': 32.17529782123222,
        '-131': 32.17541396804599,
        '-130': 32.17547590911681,
        '-129': 32.17546113468022,
        '-128': 32.175335652620404,
        '-127': 32.17510934082011,
        '-126': 32.17489611499745,
        '-125': 32.174872096718374,
        '-124': 32.17512304397017,
        '-123': 32.17215956390834,
        '-122': 32.1659815744552,
        '-121': 32.165032583149795,
        '-120': 32.17076556440406,
        '-119': 32.17325106922235,
        '-118': 32.16425408476166,
        '-117': 32.16506310127869,
        '-116': 32.168654922374905,
        '-115': 32.15628628144073,
        '-114': 32.15900814412906,
        '-113': 32.15590529148166,
        '-112': 32.15541566278418,
        '-111': 32.15667143910954,
        '-110': 32.161372720375994,
        '-109': 32.16349476087305,
        '-108': 32.166778420855266,
        '-107': 32.16543693580766,
        '-106': 32.16730050256565,
        '-105': 32.16744965265212,
        '-104': 32.1672169547864,
        '-103': 32.16802671340792,
        '-102': 32.16828823584258,
        '-101': 32.16890252814899,
        '-100': 32.16966793659014,
        '-99': 32.16936891257079,
        '-98': 32.171644383381235,
        '-97': 32.17202135142156,
        '-96': 32.1720073420557,
        '-95': 32.17147935005927,
        '-94': 32.171921523833866,
        '-93': 32.17291567210633,
        '-92': 32.17320871231916,
        '-91': 32.17171797009244,
        '-90': 32.17080652927043,
        '-89': 32.17001024655691,
        '-88': 32.17134258531397,
        '-87': 32.17281989833883,
        '-86': 32.173199348921116,
        '-85': 32.17338591603501,
        '-84': 32.173204718589695,
        '-83': 32.17389505616821,
        '-82': 32.17343673766191,
        '-81': 32.173066986584665,
        '-80': 32.17281799076322,
        '-79': 32.17158056431233,
        '-78': 32.17184796666298,
        '-77': 32.172061067525895,
        '-76': 32.17336502968166,
        '-75': 32.17214557799352,
        '-74': 32.17131903857276,
        '-73': 32.17514358053078,
        '-72': 32.17420642220981,
        '-71': 32.17198743510627,
        '-70': 32.16994255972894,
        '-69': 32.170037679411394,
        '-68': 32.173725216759316,
        '-67': 32.17442953481156,
        '-66': 32.1755429018106,
        '-65': 32.175124176130126,
        '-64': 32.17544265815783,
        '-63': 32.17533351104055,
        '-62': 32.17519512262321,
        '-61': 32.174631801326676,
        '-60': 32.174605670097776,
        '-59': 32.17495217101577,
        '-58': 32.175064092295166,
        '-57': 32.17527242846632,
        '-56': 32.17549772977554,
        '-55': 32.17565611519856,
        '-54': 32.17572493644123,
        '-53': 32.175752799521,
        '-52': 32.17580011475091,
        '-51': 32.17587801100304,
        '-50': 32.17595797729867,
        '-49': 32.17603620672667,
        '-48': 32.17615762085148,
        '-47': 32.17635084044074,
        '-46': 32.17654369246275,
        '-45': 32.17658165113203,
        '-44': 32.17636850705775,
        '-43': 32.17599492684817,
        '-42': 32.17570085430452,
        '-41': 32.17567873694758,
        '-40': 32.175901867546415,
        '-39': 32.17615399097333,
        '-38': 32.17623638252953,
        '-37': 32.17614599816516,
        '-36': 32.17605066123035,
        '-35': 32.17610049067904,
        '-34': 32.17627976709666,
        '-33': 32.17644737711378,
        '-32': 32.17650596906194,
        '-31': 32.176508406011266,
        '-30': 32.17659013813423,
        '-29': 32.17680959656412,
        '-28': 32.17707274231072,
        '-27': 32.177218872029854,
        '-26': 32.177170447840794,
        '-25': 32.17698988014254,
        '-24': 32.17679588304103,
        '-23': 32.17664260307665,
        '-22': 32.17649327287723,
        '-21': 32.176304896563906,
        '-20': 32.176115223285436,
        '-19': 32.17602509790334,
        '-18': 32.17609089818413,
        '-17': 32.17624566096645,
        '-16': 32.176339382241096,
        '-15': 32.17626129573629,
        '-14': 32.17602048363451,
        '-13': 32.175709415499114,
        '-12': 32.17540234398879,
        '-11': 32.175105164230196,
        '-10': 32.17480524036135,
        '-9': 32.17454868583884,
        '-8': 32.174438722763696,
        '-7': 32.174542604920354,
        '-6': 32.17480839469588,
        '-5': 32.17509200700047,
        '-4': 32.17527492484706,
        '-3': 32.17535006917787,
        '-2': 32.17538901955049,
        '-1': 32.17539962234795
    },
    '47': {
        '0': 32.17763789280227,
        '1': 32.17712456332023,
        '2': 32.177010350673385,
        '3': 32.1763988508269,
        '4': 32.17196365179554,
        '5': 32.17692265165763,
        '6': 32.174384745687725,
        '7': 32.174882701128254,
        '8': 32.16922688121406,
        '9': 32.15286140234367,
        '10': 32.168182093111405,
        '11': 32.16269466029484,
        '12': 32.16688116991077,
        '13': 32.15344990637767,
        '14': 32.16423750360782,
        '15': 32.17079674491678,
        '16': 32.176280141823256,
        '17': 32.17750599417391,
        '18': 32.17758546792387,
        '19': 32.1775448981439,
        '20': 32.177733433285894,
        '21': 32.17829306553671,
        '22': 32.17798357460575,
        '23': 32.17515356092749,
        '24': 32.17658538931115,
        '25': 32.16980296473992,
        '26': 32.16975928884093,
        '27': 32.176096024478184,
        '28': 32.178231089774755,
        '29': 32.177525985235256,
        '30': 32.17766157514802,
        '31': 32.178412627988095,
        '32': 32.178576703309005,
        '33': 32.17843670778487,
        '34': 32.178501313869816,
        '35': 32.17854253123156,
        '36': 32.17809631898953,
        '37': 32.17795930963678,
        '38': 32.17837960858735,
        '39': 32.17819088866854,
        '40': 32.17731772348444,
        '41': 32.17774532159498,
        '42': 32.17720265635375,
        '43': 32.177564835732284,
        '44': 32.17669616972181,
        '45': 32.17829805171816,
        '46': 32.178517050151214,
        '47': 32.17864536001787,
        '48': 32.17851561417704,
        '49': 32.1781400144685,
        '50': 32.177719999707946,
        '51': 32.17748897528405,
        '52': 32.17752944028639,
        '53': 32.177724253426035,
        '54': 32.177872399944334,
        '55': 32.177218454948076,
        '56': 32.176848754691754,
        '57': 32.176607882339084,
        '58': 32.17646746794852,
        '59': 32.176224569255155,
        '60': 32.17588223803206,
        '61': 32.177118778639255,
        '62': 32.176846162646534,
        '63': 32.176921689156444,
        '64': 32.17631336958331,
        '65': 32.176240001359034,
        '66': 32.17550409297442,
        '67': 32.17514922593183,
        '68': 32.17546675949374,
        '69': 32.17495161021411,
        '70': 32.17417491041201,
        '71': 32.17216147900479,
        '72': 32.172193135523806,
        '73': 32.172053103819295,
        '74': 32.17346368501209,
        '75': 32.1731669020584,
        '76': 32.17323053906606,
        '77': 32.17220713818593,
        '78': 32.17233371938247,
        '79': 32.173685189725965,
        '80': 32.173312956847774,
        '81': 32.17361998512363,
        '82': 32.17139942890786,
        '83': 32.16703216805473,
        '84': 32.158629026611955,
        '85': 32.159196268580956,
        '86': 32.15229586127866,
        '87': 32.16922837804202,
        '88': 32.17167205875432,
        '89': 32.16867584764081,
        '90': 32.160975830000474,
        '91': 32.15626948406293,
        '92': 32.14752013630449,
        '93': 32.1614761678031,
        '94': 32.163379856581834,
        '95': 32.1619576851982,
        '96': 32.16324095952171,
        '97': 32.15548710194385,
        '98': 32.14957914720154,
        '99': 32.15614914469477,
        '100': 32.14916908818576,
        '101': 32.15244308653036,
        '102': 32.15802412040096,
        '103': 32.1619388672862,
        '104': 32.16142674332768,
        '105': 32.162166765985084,
        '106': 32.164613204799295,
        '107': 32.164105269176545,
        '108': 32.16363833656843,
        '109': 32.166032947781005,
        '110': 32.166851481098874,
        '111': 32.16517625042317,
        '112': 32.164660146275544,
        '113': 32.167340843529495,
        '114': 32.16802632915485,
        '115': 32.16715387035091,
        '116': 32.17140982291252,
        '117': 32.17116938165747,
        '118': 32.1707938233237,
        '119': 32.16891076902686,
        '120': 32.16622606065152,
        '121': 32.17044722785372,
        '122': 32.17439599734357,
        '123': 32.176867307651634,
        '124': 32.17720032567107,
        '125': 32.17696824731082,
        '126': 32.17635231668865,
        '127': 32.177116006467976,
        '128': 32.17699625912564,
        '129': 32.175961544915076,
        '130': 32.17809129808062,
        '131': 32.17830975204736,
        '132': 32.17806151164877,
        '133': 32.17792469058585,
        '134': 32.17815267415511,
        '135': 32.177868212466976,
        '136': 32.17656175936205,
        '137': 32.16705228747966,
        '138': 32.170958676405455,
        '139': 32.179085189704736,
        '140': 32.17898684851705,
        '141': 32.17896849610839,
        '142': 32.17879186715653,
        '143': 32.174028248311906,
        '144': 32.177733506273924,
        '145': 32.17738324184705,
        '146': 32.17759139475472,
        '147': 32.178384713267576,
        '148': 32.17948099012348,
        '149': 32.180466496313144,
        '150': 32.1810151405629,
        '151': 32.18100395868707,
        '152': 32.18049806485905,
        '153': 32.179678187626415,
        '154': 32.17878537413493,
        '155': 32.17808689008604,
        '156': 32.177815238081294,
        '157': 32.178060478544595,
        '158': 32.17867776467811,
        '159': 32.17931412400024,
        '160': 32.17959229257021,
        '161': 32.179351200196436,
        '162': 32.17875958549503,
        '163': 32.17819262224385,
        '164': 32.17795238544447,
        '165': 32.17805482379174,
        '166': 32.17825633368598,
        '167': 32.17828478029718,
        '168': 32.17806446592689,
        '169': 32.177746725252646,
        '170': 32.17754842717659,
        '171': 32.17756870397854,
        '172': 32.17774419100921,
        '173': 32.177947604740474,
        '174': 32.17810456095274,
        '175': 32.178220043167364,
        '176': 32.178323290081735,
        '177': 32.17841815548342,
        '178': 32.17849246308325,
        '179': 32.17855502413273,
        '180': 32.17863871927296,
        '-180': 32.17863871927296,
        '-179': 32.17875930024903,
        '-178': 32.17888138151221,
        '-177': 32.17893953147566,
        '-176': 32.17889945088712,
        '-175': 32.17879584135008,
        '-174': 32.178704346367425,
        '-173': 32.1786724096713,
        '-172': 32.178677015080275,
        '-171': 32.178651989075945,
        '-170': 32.1785593082487,
        '-169': 32.178434930236754,
        '-168': 32.178363300119806,
        '-167': 32.17840382180258,
        '-166': 32.178540114208786,
        '-165': 32.17870062640786,
        '-164': 32.17882736647934,
        '-163': 32.17892078961292,
        '-162': 32.17901571992702,
        '-161': 32.17911923665592,
        '-160': 32.17918437498962,
        '-159': 32.17915335415423,
        '-158': 32.179023255803855,
        '-157': 32.17885785772384,
        '-156': 32.1787286196654,
        '-155': 32.17864977247512,
        '-154': 32.17858074183903,
        '-153': 32.17849084925021,
        '-152': 32.17840700481001,
        '-151': 32.1783845516867,
        '-150': 32.178434332394026,
        '-149': 32.17849516427643,
        '-148': 32.17849033942676,
        '-147': 32.17840671870816,
        '-146': 32.178304987417086,
        '-145': 32.178248413283875,
        '-144': 32.178233399307416,
        '-143': 32.178203332403044,
        '-142': 32.178127629789714,
        '-141': 32.17804827528066,
        '-140': 32.17803307940551,
        '-139': 32.1780872478324,
        '-138': 32.17813005037761,
        '-137': 32.178073452073065,
        '-136': 32.17792259652474,
        '-135': 32.17778824456405,
        '-134': 32.17779331616491,
        '-133': 32.17796597647044,
        '-132': 32.178219650942445,
        '-131': 32.17842508914348,
        '-130': 32.17849374408073,
        '-129': 32.17840514514178,
        '-128': 32.17818987500246,
        '-127': 32.17791878285542,
        '-126': 32.177705932651826,
        '-125': 32.177678598864674,
        '-124': 32.17769904923153,
        '-123': 32.177579287271946,
        '-122': 32.17320303100358,
        '-121': 32.161523272501185,
        '-120': 32.17643432370467,
        '-119': 32.174410329793496,
        '-118': 32.17291445801285,
        '-117': 32.169514270529184,
        '-116': 32.16355232021714,
        '-115': 32.16355704670557,
        '-114': 32.16329375907159,
        '-113': 32.16610089155902,
        '-112': 32.1679932334631,
        '-111': 32.157412988455995,
        '-110': 32.165947070810425,
        '-109': 32.1661928984804,
        '-108': 32.16950501410865,
        '-107': 32.16856300963182,
        '-106': 32.16976979249029,
        '-105': 32.17101730347525,
        '-104': 32.170948003439634,
        '-103': 32.17152924603573,
        '-102': 32.17233522621197,
        '-101': 32.17326938844713,
        '-100': 32.172748300052085,
        '-99': 32.173493120426805,
        '-98': 32.17425201046589,
        '-97': 32.175702392047356,
        '-96': 32.174532446842704,
        '-95': 32.173744605264226,
        '-94': 32.174451498916554,
        '-93': 32.17490543373699,
        '-92': 32.17461316579389,
        '-91': 32.17771095881738,
        '-90': 32.17880376051003,
        '-89': 32.176806340574544,
        '-88': 32.17721299642109,
        '-87': 32.178247190600686,
        '-86': 32.177906502267575,
        '-85': 32.17790107134316,
        '-84': 32.17245977925279,
        '-83': 32.1732597331881,
        '-82': 32.17354775401813,
        '-81': 32.17354162584197,
        '-80': 32.17466365443928,
        '-79': 32.1749876455021,
        '-78': 32.1747324738973,
        '-77': 32.17381497636018,
        '-76': 32.1752670012082,
        '-75': 32.174048692897166,
        '-74': 32.17347412225333,
        '-73': 32.17455067796494,
        '-72': 32.17348278375848,
        '-71': 32.17720544023956,
        '-70': 32.174837925072886,
        '-69': 32.174121496792765,
        '-68': 32.17617271788401,
        '-67': 32.17312132903472,
        '-66': 32.177192855654326,
        '-65': 32.1786268057515,
        '-64': 32.17861650165059,
        '-63': 32.1783849510192,
        '-62': 32.17798788087135,
        '-61': 32.177701885823055,
        '-60': 32.17767492873806,
        '-59': 32.17792287259739,
        '-58': 32.17832322320962,
        '-57': 32.178684033659586,
        '-56': 32.178848733104324,
        '-55': 32.1787805071059,
        '-54': 32.17674419127246,
        '-53': 32.17749551127529,
        '-52': 32.17836109880423,
        '-51': 32.17849198000165,
        '-50': 32.178735731701146,
        '-49': 32.179038266291755,
        '-48': 32.17937698808027,
        '-47': 32.17971926521571,
        '-46': 32.179968344838315,
        '-45': 32.17998863530222,
        '-44': 32.17971817182775,
        '-43': 32.1792624546014,
        '-42': 32.1788546549426,
        '-41': 32.17869118327534,
        '-40': 32.178785530135315,
        '-39': 32.178978641644555,
        '-38': 32.17909450097869,
        '-37': 32.17908781738256,
        '-36': 32.179046459722024,
        '-35': 32.179066663292154,
        '-34': 32.17914688892431,
        '-33': 32.17921208629034,
        '-32': 32.17922763838477,
        '-31': 32.17926215269448,
        '-30': 32.17941908704093,
        '-29': 32.1797082162338,
        '-28': 32.18000065870941,
        '-27': 32.180125468174595,
        '-26': 32.18001556892004,
        '-25': 32.17975854782907,
        '-24': 32.17950749490741,
        '-23': 32.17934931480424,
        '-22': 32.17926188659745,
        '-21': 32.179186995032104,
        '-20': 32.17912149446711,
        '-19': 32.17911919742315,
        '-18': 32.179205520978535,
        '-17': 32.179310411338214,
        '-16': 32.17930693809577,
        '-15': 32.179124308868545,
        '-14': 32.17881767551875,
        '-13': 32.178519734565775,
        '-12': 32.17832454843985,
        '-11': 32.17822442539943,
        '-10': 32.178159209707154,
        '-9': 32.17811311211538,
        '-8': 32.1781452297029,
        '-7': 32.178318869530706,
        '-6': 32.17861100332618,
        '-5': 32.17890573670392,
        '-4': 32.179081381962504,
        '-3': 32.1790989374872,
        '-2': 32.17900475813163,
        '-1': 32.177930165060516
    },
    '48': {
        '0': 32.18044198007404,
        '1': 32.17949343988263,
        '2': 32.1798797748442,
        '3': 32.179550436090075,
        '4': 32.179559766829996,
        '5': 32.17832309560351,
        '6': 32.178442891059554,
        '7': 32.17311162014173,
        '8': 32.17602659135336,
        '9': 32.17431903785088,
        '10': 32.17563098224688,
        '11': 32.17576067700903,
        '12': 32.17737683281778,
        '13': 32.17796541297335,
        '14': 32.177784462786555,
        '15': 32.17722864523142,
        '16': 32.176383622703426,
        '17': 32.18052889742338,
        '18': 32.18073783468459,
        '19': 32.177244773045686,
        '20': 32.17953263115999,
        '21': 32.18128970228662,
        '22': 32.181194781907365,
        '23': 32.18109663577393,
        '24': 32.17605088396571,
        '25': 32.173869959200644,
        '26': 32.17880440064322,
        '27': 32.1803377853364,
        '28': 32.18020798281827,
        '29': 32.18026062543926,
        '30': 32.17994198102085,
        '31': 32.18085905533934,
        '32': 32.17964626772285,
        '33': 32.18063671108274,
        '34': 32.18073497227215,
        '35': 32.18108519148657,
        '36': 32.18099195546033,
        '37': 32.181109795281166,
        '38': 32.18038539316323,
        '39': 32.18087130942162,
        '40': 32.17988250474269,
        '41': 32.18141145829555,
        '42': 32.18083584249845,
        '43': 32.18100920201577,
        '44': 32.18009643552583,
        '45': 32.18085544228115,
        '46': 32.180737573020416,
        '47': 32.18052809198845,
        '48': 32.180255225043176,
        '49': 32.18008031590783,
        '50': 32.18014171823708,
        '51': 32.180436225507805,
        '52': 32.180793968852406,
        '53': 32.181004146485684,
        '54': 32.180632864346066,
        '55': 32.179656870499436,
        '56': 32.17906078884607,
        '57': 32.17874984222222,
        '58': 32.17899366943865,
        '59': 32.17947801559071,
        '60': 32.179794207001834,
        '61': 32.18045622938445,
        '62': 32.179711572666726,
        '63': 32.17988144599061,
        '64': 32.17939872860652,
        '65': 32.179009914350196,
        '66': 32.17731209656721,
        '67': 32.17686207152493,
        '68': 32.17761810460346,
        '69': 32.177826657203475,
        '70': 32.177268628786514,
        '71': 32.17594565449491,
        '72': 32.17401003918255,
        '73': 32.17318946377379,
        '74': 32.17338166401357,
        '75': 32.17313719066215,
        '76': 32.17436073586301,
        '77': 32.17383492916031,
        '78': 32.17445594025795,
        '79': 32.17467995542801,
        '80': 32.17245260728649,
        '81': 32.17255857590172,
        '82': 32.17321968891429,
        '83': 32.176916678154555,
        '84': 32.177443059136735,
        '85': 32.177302709081246,
        '86': 32.17685804331873,
        '87': 32.17325735281433,
        '88': 32.16342139453706,
        '89': 32.15985874585491,
        '90': 32.15199448877982,
        '91': 32.151726033855205,
        '92': 32.169569892543194,
        '93': 32.16870106170741,
        '94': 32.168613582852714,
        '95': 32.16309935427207,
        '96': 32.16209640845621,
        '97': 32.15639466872762,
        '98': 32.153683753321175,
        '99': 32.154731739632915,
        '100': 32.157222022483566,
        '101': 32.16372547612529,
        '102': 32.16313505234517,
        '103': 32.16255388172358,
        '104': 32.17013191826133,
        '105': 32.16939677050558,
        '106': 32.167101282405945,
        '107': 32.16491690298683,
        '108': 32.16267201975334,
        '109': 32.16524119475145,
        '110': 32.168098924037764,
        '111': 32.16798359751625,
        '112': 32.17085501670053,
        '113': 32.17246071686127,
        '114': 32.173356792667875,
        '115': 32.172924882082015,
        '116': 32.173923832766704,
        '117': 32.17507464085433,
        '118': 32.175299443349644,
        '119': 32.17394227670566,
        '120': 32.1725364380127,
        '121': 32.17170054184621,
        '122': 32.17468533759035,
        '123': 32.17924192918536,
        '124': 32.180298786102355,
        '125': 32.179648681140314,
        '126': 32.179485783919844,
        '127': 32.17895069566194,
        '128': 32.178221206135305,
        '129': 32.17909329691333,
        '130': 32.17731413113688,
        '131': 32.17994475320534,
        '132': 32.18107290321717,
        '133': 32.180943041179304,
        '134': 32.18109763153309,
        '135': 32.181386968370774,
        '136': 32.18074848563995,
        '137': 32.175310365763686,
        '138': 32.178668058464694,
        '139': 32.17614230073475,
        '140': 32.18197765720269,
        '141': 32.18221059772136,
        '142': 32.18247277069953,
        '143': 32.18248825021683,
        '144': 32.18213349215923,
        '145': 32.181530194524264,
        '146': 32.180963254135676,
        '147': 32.180716467224016,
        '148': 32.18094471228567,
        '149': 32.18162944714363,
        '150': 32.182588241608045,
        '151': 32.183507077659456,
        '152': 32.18401148131299,
        '153': 32.1838060530996,
        '154': 32.18285073961681,
        '155': 32.181460647622906,
        '156': 32.180213512325516,
        '157': 32.17966454383336,
        '158': 32.180026310837405,
        '159': 32.18103352637548,
        '160': 32.18210321583189,
        '161': 32.18268957186822,
        '162': 32.182588912796525,
        '163': 32.18199515898168,
        '164': 32.18130211236803,
        '165': 32.1808297956039,
        '166': 32.18067352622086,
        '167': 32.18073997757848,
        '168': 32.18088161926453,
        '169': 32.18100094226749,
        '170': 32.18107264122513,
        '171': 32.18112029726906,
        '172': 32.18119569533703,
        '173': 32.18136093331147,
        '174': 32.18164871325581,
        '175': 32.18201265771149,
        '176': 32.182324054023724,
        '177': 32.18245005986969,
        '178': 32.18236546005939,
        '179': 32.182194581561404,
        '180': 32.182126550156894,
        '-180': 32.182126550156894,
        '-179': 32.1822640456889,
        '-178': 32.182538187547436,
        '-177': 32.18277020473805,
        '-176': 32.18282693624131,
        '-175': 32.182729517184825,
        '-174': 32.18261850896933,
        '-173': 32.18261279224094,
        '-172': 32.18269620947554,
        '-171': 32.18273532890299,
        '-170': 32.18260637780469,
        '-169': 32.18231003952424,
        '-168': 32.18197085410521,
        '-167': 32.18173023451469,
        '-166': 32.18163790681041,
        '-165': 32.181637139744346,
        '-164': 32.1816417611568,
        '-163': 32.18161868027548,
        '-162': 32.18159875688748,
        '-161': 32.181621905106866,
        '-160': 32.18168675878523,
        '-159': 32.181756808613294,
        '-158': 32.181803788211724,
        '-157': 32.18183017045994,
        '-156': 32.18184704785445,
        '-155': 32.18184395510923,
        '-154': 32.18179703021549,
        '-153': 32.18170911686795,
        '-152': 32.18162719482112,
        '-151': 32.18160334013788,
        '-150': 32.181635335456306,
        '-149': 32.18165728540186,
        '-148': 32.18160107255745,
        '-147': 32.18146782096059,
        '-146': 32.18133053979941,
        '-145': 32.181261621991396,
        '-144': 32.181262856025775,
        '-143': 32.18127406852676,
        '-142': 32.18124806917399,
        '-141': 32.18120405029223,
        '-140': 32.181196750847384,
        '-139': 32.1812375596776,
        '-138': 32.18126306573024,
        '-137': 32.1811960019844,
        '-136': 32.18103768152674,
        '-135': 32.18088913714847,
        '-134': 32.18086941347575,
        '-133': 32.18100737321398,
        '-132': 32.181211626813905,
        '-131': 32.1813439997392,
        '-130': 32.181323005825504,
        '-129': 32.18116721117477,
        '-128': 32.18096000746926,
        '-127': 32.18079009114944,
        '-126': 32.18072116673996,
        '-125': 32.18078874820187,
        '-124': 32.174556066786906,
        '-123': 32.17836849245062,
        '-122': 32.180134975211324,
        '-121': 32.16605509977377,
        '-120': 32.16928287702555,
        '-119': 32.175205426835944,
        '-118': 32.171783685065975,
        '-117': 32.17362509635832,
        '-116': 32.17288611877131,
        '-115': 32.17134145292448,
        '-114': 32.17011440980201,
        '-113': 32.16412038640365,
        '-112': 32.170472319481725,
        '-111': 32.171395928519225,
        '-110': 32.17217124710203,
        '-109': 32.17263715153282,
        '-108': 32.17414281853331,
        '-107': 32.17461589440384,
        '-106': 32.17554574971457,
        '-105': 32.17496247151239,
        '-104': 32.1760929899695,
        '-103': 32.17473044268009,
        '-102': 32.17576814086471,
        '-101': 32.17663087616473,
        '-100': 32.176971302396616,
        '-99': 32.17728062315059,
        '-98': 32.17690306428085,
        '-97': 32.17893296147059,
        '-96': 32.17785274964389,
        '-95': 32.17772847007418,
        '-94': 32.177391137327284,
        '-93': 32.17722252266183,
        '-92': 32.17647361853326,
        '-91': 32.17610120126171,
        '-90': 32.176758397392476,
        '-89': 32.17924169261928,
        '-88': 32.181009766193654,
        '-87': 32.18090014202956,
        '-86': 32.17999629396069,
        '-85': 32.176752716587906,
        '-84': 32.1767918287383,
        '-83': 32.17710314699351,
        '-82': 32.177205003564104,
        '-81': 32.176772918556544,
        '-80': 32.177988214252025,
        '-79': 32.17771168744172,
        '-78': 32.17787570986657,
        '-77': 32.17745281880044,
        '-76': 32.17642866567406,
        '-75': 32.17509179441907,
        '-74': 32.176439519043115,
        '-73': 32.1776873976603,
        '-72': 32.1758190161603,
        '-71': 32.175828829944216,
        '-70': 32.17826429244966,
        '-69': 32.1790108906292,
        '-68': 32.17719480807763,
        '-67': 32.1801639391169,
        '-66': 32.18138127950881,
        '-65': 32.181532147314456,
        '-64': 32.18144227249711,
        '-63': 32.18110534619422,
        '-62': 32.18070605473524,
        '-61': 32.18051475185489,
        '-60': 32.18070668948715,
        '-59': 32.17940418276559,
        '-58': 32.177178736101155,
        '-57': 32.17942035958381,
        '-56': 32.180274727889135,
        '-55': 32.18029504736108,
        '-54': 32.180301641675825,
        '-53': 32.180686815499165,
        '-52': 32.1815956421094,
        '-51': 32.18181348043081,
        '-50': 32.182057606498596,
        '-49': 32.18224162822948,
        '-48': 32.18238255610457,
        '-47': 32.18253446937458,
        '-46': 32.18268739830166,
        '-45': 32.182743765545624,
        '-44': 32.1826044978522,
        '-43': 32.18228108203148,
        '-42': 32.18191718031963,
        '-41': 32.181689965682914,
        '-40': 32.181679744282505,
        '-39': 32.18182565123797,
        '-38': 32.18199763535588,
        '-37': 32.18210449372604,
        '-36': 32.18213749727748,
        '-35': 32.18213087423452,
        '-34': 32.182107465714545,
        '-33': 32.18207623763358,
        '-32': 32.18206964185992,
        '-31': 32.182151734819435,
        '-30': 32.182362270140494,
        '-29': 32.18264800669692,
        '-28': 32.18286671516944,
        '-27': 32.18288394569469,
        '-26': 32.18268310972296,
        '-25': 32.18238486114054,
        '-24': 32.18215403925577,
        '-23': 32.1820790265329,
        '-22': 32.182129719688604,
        '-21': 32.18221689621495,
        '-20': 32.1822778130349,
        '-19': 32.18230175769957,
        '-18': 32.18228886806908,
        '-17': 32.18221206569204,
        '-16': 32.18204017699168,
        '-15': 32.18179773847325,
        '-14': 32.18158220819351,
        '-13': 32.18149946805829,
        '-12': 32.18157179839233,
        '-11': 32.18171327461894,
        '-10': 32.18180483760859,
        '-9': 32.181798787232594,
        '-8': 32.18175065556814,
        '-7': 32.1817509249939,
        '-6': 32.18183046308025,
        '-5': 32.1819343399367,
        '-4': 32.18134292061676,
        '-3': 32.18107365067039,
        '-2': 32.18072518539915,
        '-1': 32.180660622144366
    },
    '49': {
        '0': 32.18345812536241,
        '1': 32.18258821883901,
        '2': 32.18228260047157,
        '3': 32.18333110394327,
        '4': 32.18227138986449,
        '5': 32.18294721886011,
        '6': 32.18132272380368,
        '7': 32.18264882383303,
        '8': 32.18345609803608,
        '9': 32.18167152521834,
        '10': 32.17978049522359,
        '11': 32.17885126895194,
        '12': 32.17982247358863,
        '13': 32.17899388357496,
        '14': 32.17795664036156,
        '15': 32.17985708021116,
        '16': 32.181675801471776,
        '17': 32.18293664724026,
        '18': 32.18021072997962,
        '19': 32.179113399420395,
        '20': 32.17548900833929,
        '21': 32.179491078583446,
        '22': 32.182441627651706,
        '23': 32.17743113986054,
        '24': 32.179756460084185,
        '25': 32.18162619214122,
        '26': 32.1819502413724,
        '27': 32.18239603015779,
        '28': 32.1828488162349,
        '29': 32.18303277643926,
        '30': 32.18305652831075,
        '31': 32.18347477422896,
        '32': 32.182800871238314,
        '33': 32.18302910427457,
        '34': 32.18363682513716,
        '35': 32.18339978929113,
        '36': 32.18347497683795,
        '37': 32.18267255555992,
        '38': 32.183592850950475,
        '39': 32.18415106931826,
        '40': 32.18379254134569,
        '41': 32.18389670302011,
        '42': 32.18336160230021,
        '43': 32.18264355975602,
        '44': 32.18341395724635,
        '45': 32.1833990574716,
        '46': 32.18298485433559,
        '47': 32.18262401660897,
        '48': 32.18218781917453,
        '49': 32.18263996138118,
        '50': 32.18308419712031,
        '51': 32.18367214280283,
        '52': 32.18407124598856,
        '53': 32.183993569262256,
        '54': 32.18338196525535,
        '55': 32.182334910960634,
        '56': 32.18126104015374,
        '57': 32.181840389636285,
        '58': 32.18151546352223,
        '59': 32.181651308417315,
        '60': 32.18296475587114,
        '61': 32.18264731303803,
        '62': 32.182938657718495,
        '63': 32.181765042199096,
        '64': 32.18227578385298,
        '65': 32.181575851189265,
        '66': 32.18108149617203,
        '67': 32.17903195078319,
        '68': 32.17889197541302,
        '69': 32.179053006393545,
        '70': 32.17907779561794,
        '71': 32.178285396459714,
        '72': 32.17859296691802,
        '73': 32.17622495791337,
        '74': 32.17478549752165,
        '75': 32.17510225301352,
        '76': 32.17352541075502,
        '77': 32.17379613597506,
        '78': 32.17587550092891,
        '79': 32.17748704069926,
        '80': 32.17818185556667,
        '81': 32.17802990445931,
        '82': 32.176298085572355,
        '83': 32.17472984878492,
        '84': 32.17715247699594,
        '85': 32.159791941815406,
        '86': 32.16085930414838,
        '87': 32.16552000186241,
        '88': 32.16068090367204,
        '89': 32.16573443424268,
        '90': 32.165615812280755,
        '91': 32.16813943510197,
        '92': 32.16747459804279,
        '93': 32.16941470050276,
        '94': 32.16845355278848,
        '95': 32.16889370486489,
        '96': 32.16555760819409,
        '97': 32.16275979553191,
        '98': 32.160125252472646,
        '99': 32.15948643314204,
        '100': 32.165231812127224,
        '101': 32.16686789885934,
        '102': 32.16807244845122,
        '103': 32.16651708473269,
        '104': 32.169728189368904,
        '105': 32.17539526384512,
        '106': 32.174699366617574,
        '107': 32.170298320692126,
        '108': 32.16976906398209,
        '109': 32.16632873603407,
        '110': 32.17053718638072,
        '111': 32.171602826192924,
        '112': 32.17019266510711,
        '113': 32.17430342518167,
        '114': 32.17449381410021,
        '115': 32.17609139341261,
        '116': 32.17692538384609,
        '117': 32.17827104759974,
        '118': 32.17881593246099,
        '119': 32.177823581788154,
        '120': 32.17724105309989,
        '121': 32.17687004315848,
        '122': 32.17479120192136,
        '123': 32.178462170698516,
        '124': 32.18059903046319,
        '125': 32.18287840443109,
        '126': 32.18200788054278,
        '127': 32.18082878515107,
        '128': 32.18197998349829,
        '129': 32.18161912041837,
        '130': 32.184177042127956,
        '131': 32.18097186155346,
        '132': 32.18306294525458,
        '133': 32.18339896221432,
        '134': 32.18419605330894,
        '135': 32.183742813824466,
        '136': 32.184912271576366,
        '137': 32.18075128008106,
        '138': 32.17846886248903,
        '139': 32.175995281058505,
        '140': 32.18222116200932,
        '141': 32.184813152235655,
        '142': 32.18513930648911,
        '143': 32.18549690088161,
        '144': 32.18565047875423,
        '145': 32.18545320053146,
        '146': 32.18492097977103,
        '147': 32.18423390255573,
        '148': 32.183680933486585,
        '149': 32.183554461071274,
        '150': 32.18400915063973,
        '151': 32.184937366525624,
        '152': 32.185950511703304,
        '153': 32.18652836977736,
        '154': 32.18629371319181,
        '155': 32.1852571134961,
        '156': 32.18385888859042,
        '157': 32.182750530732086,
        '158': 32.182435189412615,
        '159': 32.182992145828166,
        '160': 32.18405719587253,
        '161': 32.18505674229362,
        '162': 32.18553111328228,
        '163': 32.18534853077724,
        '164': 32.18471149650568,
        '165': 32.18399742012985,
        '166': 32.18355246965988,
        '167': 32.18354365370306,
        '168': 32.18391509090639,
        '169': 32.18444519541767,
        '170': 32.18487380792315,
        '171': 32.185043959734806,
        '172': 32.18498284748782,
        '173': 32.184863590881456,
        '174': 32.1848624078963,
        '175': 32.185012745862615,
        '176': 32.1851767266243,
        '177': 32.18516686419774,
        '178': 32.18492012637011,
        '179': 32.184571279119844,
        '180': 32.18435433709175,
        '-180': 32.18435433709175,
        '-179': 32.18441375233745,
        '-178': 32.18469100665789,
        '-177': 32.18498603471934,
        '-176': 32.18513510038637,
        '-175': 32.18514371818485,
        '-174': 32.1851596455484,
        '-173': 32.18532031735951,
        '-172': 32.18562023708682,
        '-171': 32.18591624116307,
        '-170': 32.186055128406736,
        '-169': 32.18599869428061,
        '-168': 32.185835237100775,
        '-167': 32.18568121633878,
        '-166': 32.18557642762975,
        '-165': 32.18546891323837,
        '-164': 32.18528954874227,
        '-163': 32.18503226813918,
        '-162': 32.18476334184734,
        '-161': 32.184562993220034,
        '-160': 32.184466477035464,
        '-159': 32.18445825863804,
        '-158': 32.18450806182432,
        '-157': 32.18459772149607,
        '-156': 32.184712282331375,
        '-155': 32.18482014498916,
        '-154': 32.18488043531278,
        '-153': 32.18487672749344,
        '-152': 32.18483594483788,
        '-151': 32.18480280812189,
        '-150': 32.184792826033835,
        '-149': 32.184776977023596,
        '-148': 32.184718743824895,
        '-147': 32.1846231979803,
        '-146': 32.184539966481985,
        '-145': 32.184511954240904,
        '-144': 32.1845251338536,
        '-143': 32.184517867694005,
        '-142': 32.18444391943767,
        '-141': 32.184322329887266,
        '-140': 32.18421947202559,
        '-139': 32.184182301490004,
        '-138': 32.18419528988272,
        '-137': 32.18420646147852,
        '-136': 32.18419030268897,
        '-135': 32.184174359684306,
        '-134': 32.18419844585543,
        '-133': 32.18425349902889,
        '-132': 32.18427334135545,
        '-131': 32.18419640961758,
        '-130': 32.18403683789907,
        '-129': 32.18388982269605,
        '-128': 32.183860812892966,
        '-127': 32.1839831684318,
        '-126': 32.184197462228,
        '-125': 32.184403429254125,
        '-124': 32.18014765790005,
        '-123': 32.18456935058021,
        '-122': 32.17544179612343,
        '-121': 32.16757887642162,
        '-120': 32.16408921404029,
        '-119': 32.17454659017294,
        '-118': 32.17195037290023,
        '-117': 32.16448926893322,
        '-116': 32.16913226212444,
        '-115': 32.16785202839626,
        '-114': 32.1681122120369,
        '-113': 32.17169734176375,
        '-112': 32.17406366884438,
        '-111': 32.17402103486432,
        '-110': 32.17622387111294,
        '-109': 32.17556857090531,
        '-108': 32.17662474588334,
        '-107': 32.17639351622884,
        '-106': 32.17576754797171,
        '-105': 32.1775978417488,
        '-104': 32.1782994801269,
        '-103': 32.179184128299056,
        '-102': 32.17944926596792,
        '-101': 32.1803665593038,
        '-100': 32.17779654469914,
        '-99': 32.17996744251892,
        '-98': 32.18163972882729,
        '-97': 32.182164152520414,
        '-96': 32.18129592969982,
        '-95': 32.18127348480953,
        '-94': 32.180762488703884,
        '-93': 32.17990275697461,
        '-92': 32.17967802963653,
        '-91': 32.17933100071381,
        '-90': 32.17909664672466,
        '-89': 32.178904846318105,
        '-88': 32.17969262924597,
        '-87': 32.179110103945995,
        '-86': 32.18069623859632,
        '-85': 32.18014837957678,
        '-84': 32.180495769744155,
        '-83': 32.181613626594,
        '-82': 32.181647710593,
        '-81': 32.18159336175838,
        '-80': 32.18083919316885,
        '-79': 32.18065653897831,
        '-78': 32.1805520761053,
        '-77': 32.18063969916073,
        '-76': 32.179605852991614,
        '-75': 32.17897664087623,
        '-74': 32.179168871956264,
        '-73': 32.180904737888284,
        '-72': 32.18140383787418,
        '-71': 32.17903890176155,
        '-70': 32.17816291076146,
        '-69': 32.18241907501,
        '-68': 32.1839618614693,
        '-67': 32.18388649188308,
        '-66': 32.17398090809099,
        '-65': 32.17892760371883,
        '-64': 32.18376328947286,
        '-63': 32.18341613531696,
        '-62': 32.183084276635846,
        '-61': 32.18300508951943,
        '-60': 32.18331704739277,
        '-59': 32.183941946805604,
        '-58': 32.182398031616415,
        '-57': 32.181784284172096,
        '-56': 32.18384952343788,
        '-55': 32.183094428549715,
        '-54': 32.18419822846351,
        '-53': 32.18490404980436,
        '-52': 32.185130775210034,
        '-51': 32.18535496852724,
        '-50': 32.18538372730972,
        '-49': 32.18517584071029,
        '-48': 32.18487527262403,
        '-47': 32.184690734526036,
        '-46': 32.184726531903294,
        '-45': 32.18490793087688,
        '-44': 32.18505814753162,
        '-43': 32.18505191385306,
        '-42': 32.1849109236877,
        '-41': 32.18476806857212,
        '-40': 32.184746460257955,
        '-39': 32.184864774921095,
        '-38': 32.18504076531852,
        '-37': 32.185170196349134,
        '-36': 32.18520234341404,
        '-35': 32.18515756897653,
        '-34': 32.185095959074516,
        '-33': 32.185079357566124,
        '-32': 32.1851503915422,
        '-31': 32.18531970526633,
        '-30': 32.18555085445019,
        '-29': 32.185757475626495,
        '-28': 32.185836966801126,
        '-27': 32.18573489492383,
        '-26': 32.18549337408164,
        '-25': 32.18523541757593,
        '-24': 32.18508762766249,
        '-23': 32.18509936672109,
        '-22': 32.18522071385561,
        '-21': 32.18534858243614,
        '-20': 32.18539381260828,
        '-19': 32.18531683533667,
        '-18': 32.18512342821052,
        '-17': 32.18485130021517,
        '-16': 32.18457010711835,
        '-15': 32.18437890758303,
        '-14': 32.18437079977569,
        '-13': 32.184569999629055,
        '-12': 32.184893571581334,
        '-11': 32.18518738548825,
        '-10': 32.18532298396316,
        '-9': 32.18527893536594,
        '-8': 32.18513658785892,
        '-7': 32.184996915971766,
        '-6': 32.184897572706156,
        '-5': 32.18480378305778,
        '-4': 32.18466862911398,
        '-3': 32.18449124728979,
        '-2': 32.184313511047186,
        '-1': 32.1830667502131
    },
    '50': {
        '0': 32.18687731126054,
        '1': 32.186835785297134,
        '2': 32.18619324164398,
        '3': 32.18555959618095,
        '4': 32.18523294942393,
        '5': 32.183306504587335,
        '6': 32.18444473174356,
        '7': 32.186554575355316,
        '8': 32.18743914763746,
        '9': 32.186970551999266,
        '10': 32.18517051846537,
        '11': 32.18432963586216,
        '12': 32.17991303277173,
        '13': 32.18068796838266,
        '14': 32.185114920741334,
        '15': 32.185645810041,
        '16': 32.18600976650103,
        '17': 32.18480203464489,
        '18': 32.18577442750968,
        '19': 32.18619134605185,
        '20': 32.18586741484685,
        '21': 32.18583769919195,
        '22': 32.185353685089915,
        '23': 32.18495954325776,
        '24': 32.18397092823895,
        '25': 32.184569265468745,
        '26': 32.184815666613886,
        '27': 32.18538115165593,
        '28': 32.18593924840927,
        '29': 32.18623498160116,
        '30': 32.18612137855238,
        '31': 32.186102070825974,
        '32': 32.18642937368773,
        '33': 32.18631221273287,
        '34': 32.18645654524819,
        '35': 32.185766705559864,
        '36': 32.18562510479649,
        '37': 32.18523022136797,
        '38': 32.18563267425228,
        '39': 32.18565343764897,
        '40': 32.185636776878056,
        '41': 32.18618201908824,
        '42': 32.185745593523876,
        '43': 32.186055598281925,
        '44': 32.18562237910616,
        '45': 32.18593195783922,
        '46': 32.18640797452948,
        '47': 32.18596532353056,
        '48': 32.1857463929549,
        '49': 32.185838142678904,
        '50': 32.18625017345309,
        '51': 32.186558784634904,
        '52': 32.186686410771294,
        '53': 32.18620272266673,
        '54': 32.185163332029326,
        '55': 32.18414324646347,
        '56': 32.18381694749207,
        '57': 32.18326059337165,
        '58': 32.183407107541655,
        '59': 32.185170470005865,
        '60': 32.185082921982875,
        '61': 32.18469084115666,
        '62': 32.18504663234879,
        '63': 32.18521165977022,
        '64': 32.1857400860461,
        '65': 32.185780821710125,
        '66': 32.18558576333363,
        '67': 32.183400600779066,
        '68': 32.183198391644744,
        '69': 32.18327955193882,
        '70': 32.18316285219154,
        '71': 32.18304683803855,
        '72': 32.18187666575239,
        '73': 32.181504150026015,
        '74': 32.1815004753788,
        '75': 32.1803917330712,
        '76': 32.179857681142465,
        '77': 32.18107639470891,
        '78': 32.182813271867346,
        '79': 32.18402591199681,
        '80': 32.18345793259789,
        '81': 32.182298011454165,
        '82': 32.181961694387425,
        '83': 32.18014087137672,
        '84': 32.17681759073444,
        '85': 32.170081070432886,
        '86': 32.16438773517431,
        '87': 32.17220214544455,
        '88': 32.16459239883595,
        '89': 32.164207521831585,
        '90': 32.168592944750365,
        '91': 32.16655671513082,
        '92': 32.17731551312498,
        '93': 32.17849839989545,
        '94': 32.176923770858586,
        '95': 32.17458806816303,
        '96': 32.17010171251715,
        '97': 32.162375999175566,
        '98': 32.161866035335095,
        '99': 32.16668557045497,
        '100': 32.16949542770308,
        '101': 32.16681468040371,
        '102': 32.16948045869552,
        '103': 32.17223054786027,
        '104': 32.17379330750252,
        '105': 32.17521423652491,
        '106': 32.17806305418352,
        '107': 32.17878161562722,
        '108': 32.17663179031237,
        '109': 32.17463225316795,
        '110': 32.17277894078301,
        '111': 32.168133201685215,
        '112': 32.174964705934876,
        '113': 32.17633726292623,
        '114': 32.17885694113101,
        '115': 32.17978097126754,
        '116': 32.179770927533454,
        '117': 32.179802186047965,
        '118': 32.180421947319466,
        '119': 32.18230107372379,
        '120': 32.17975673007848,
        '121': 32.178366350152345,
        '122': 32.178873185402296,
        '123': 32.178964815081365,
        '124': 32.18357915366144,
        '125': 32.185464213962625,
        '126': 32.182844441970985,
        '127': 32.184230582967764,
        '128': 32.18596184559506,
        '129': 32.18573036376712,
        '130': 32.18636696294053,
        '131': 32.18524100685465,
        '132': 32.18539693228108,
        '133': 32.18090485330741,
        '134': 32.17994134662838,
        '135': 32.17818158198497,
        '136': 32.18716997987027,
        '137': 32.18770438958722,
        '138': 32.18370789138645,
        '139': 32.181997872738414,
        '140': 32.18506603669393,
        '141': 32.18737902827156,
        '142': 32.18725115998368,
        '143': 32.18652316861066,
        '144': 32.18760896187808,
        '145': 32.18790522357231,
        '146': 32.188047981876394,
        '147': 32.187912686025754,
        '148': 32.18752581441308,
        '149': 32.187092026525065,
        '150': 32.1869117604851,
        '151': 32.187209355001954,
        '152': 32.18796720454509,
        '153': 32.18888309820774,
        '154': 32.18950270386675,
        '155': 32.18946389900656,
        '156': 32.18870781042352,
        '157': 32.18752583594302,
        '158': 32.18641347165176,
        '159': 32.1858185650322,
        '160': 32.185926706708486,
        '161': 32.186593023473606,
        '162': 32.18744107631521,
        '163': 32.1880645033769,
        '164': 32.188225935479615,
        '165': 32.18795774390224,
        '166': 32.18751813456411,
        '167': 32.187226635617556,
        '168': 32.1872697702016,
        '169': 32.18759323336797,
        '170': 32.1879514691636,
        '171': 32.188083083405736,
        '172': 32.18788845455194,
        '173': 32.187480495646575,
        '174': 32.18707616952768,
        '175': 32.18682277488978,
        '176': 32.186702791689875,
        '177': 32.186588391235624,
        '178': 32.186384208161826,
        '179': 32.186123449577,
        '180': 32.185933318899444,
        '-180': 32.185933318899444,
        '-179': 32.185910928424434,
        '-178': 32.18603198965561,
        '-177': 32.18617750362752,
        '-176': 32.1862481269925,
        '-175': 32.18625466743388,
        '-174': 32.18630173057164,
        '-173': 32.186486959428336,
        '-172': 32.186815652050974,
        '-171': 32.187207511871684,
        '-170': 32.18757771176497,
        '-169': 32.18790506943117,
        '-168': 32.18822202551813,
        '-167': 32.188546128760954,
        '-166': 32.188831582431824,
        '-165': 32.18899421963355,
        '-164': 32.18898434106288,
        '-163': 32.18883268728607,
        '-162': 32.18862442965163,
        '-161': 32.18843024304587,
        '-160': 32.18826386691602,
        '-159': 32.188102562285266,
        '-158': 32.18793921946716,
        '-157': 32.18780530280969,
        '-156': 32.1877408915167,
        '-155': 32.18774809845559,
        '-154': 32.187781343070505,
        '-153': 32.18778608155486,
        '-152': 32.18774506691272,
        '-151': 32.18768541507414,
        '-150': 32.18764411143608,
        '-149': 32.18763267123454,
        '-148': 32.187636726156825,
        '-147': 32.18764280121359,
        '-146': 32.1876544893266,
        '-145': 32.18767724865086,
        '-144': 32.1876927496734,
        '-143': 32.18765949098781,
        '-142': 32.18754700904217,
        '-141': 32.18737053253193,
        '-140': 32.18718869906402,
        '-139': 32.18706448099615,
        '-138': 32.18702642447634,
        '-137': 32.18706280269082,
        '-136': 32.18714288648099,
        '-135': 32.18723345532418,
        '-134': 32.18729483504866,
        '-133': 32.187277091267866,
        '-132': 32.18714531941893,
        '-131': 32.18692710107973,
        '-130': 32.186733889848,
        '-129': 32.186713893770495,
        '-128': 32.186952495444935,
        '-127': 32.18190791355414,
        '-126': 32.176958242196555,
        '-125': 32.188123917677366,
        '-124': 32.18642714739244,
        '-123': 32.172050497567525,
        '-122': 32.1784082916316,
        '-121': 32.178261416905585,
        '-120': 32.172990682990495,
        '-119': 32.17182265108158,
        '-118': 32.168530713091755,
        '-117': 32.172495558184934,
        '-116': 32.16691668831838,
        '-115': 32.170259440778274,
        '-114': 32.17469630026841,
        '-113': 32.17726155214441,
        '-112': 32.17943682691377,
        '-111': 32.17935313491614,
        '-110': 32.17996990261766,
        '-109': 32.17970616969737,
        '-108': 32.178723143304666,
        '-107': 32.1801532975448,
        '-106': 32.180774316212556,
        '-105': 32.181682395169545,
        '-104': 32.1819382422865,
        '-103': 32.181253713027594,
        '-102': 32.18145624488651,
        '-101': 32.18312719801348,
        '-100': 32.1829434925594,
        '-99': 32.1844186411853,
        '-98': 32.184951513674754,
        '-97': 32.18513936743416,
        '-96': 32.18477123307082,
        '-95': 32.18397572163573,
        '-94': 32.183327612517125,
        '-93': 32.18322516750397,
        '-92': 32.18323575277078,
        '-91': 32.182813858330995,
        '-90': 32.18237802087045,
        '-89': 32.18336584097589,
        '-88': 32.1842241983028,
        '-87': 32.183439097719464,
        '-86': 32.18385232175699,
        '-85': 32.18502634275275,
        '-84': 32.185355315750236,
        '-83': 32.18516547034086,
        '-82': 32.18527223607063,
        '-81': 32.18459205502019,
        '-80': 32.18353301123552,
        '-79': 32.18382904720114,
        '-78': 32.18356759461608,
        '-77': 32.183437250568176,
        '-76': 32.18334981234506,
        '-75': 32.18289020465724,
        '-74': 32.1822187145724,
        '-73': 32.18214293681555,
        '-72': 32.18162810455499,
        '-71': 32.182883607186454,
        '-70': 32.18241784449167,
        '-69': 32.18339777833639,
        '-68': 32.184184640327565,
        '-67': 32.18590176301729,
        '-66': 32.186965530475106,
        '-65': 32.18673177368794,
        '-64': 32.18643590203265,
        '-63': 32.186093239957415,
        '-62': 32.185803517064365,
        '-61': 32.185715544507055,
        '-60': 32.185921626358144,
        '-59': 32.18636974754769,
        '-58': 32.18687817387276,
        '-57': 32.18329830894631,
        '-56': 32.18741525961905,
        '-55': 32.187517090336556,
        '-54': 32.1876675730681,
        '-53': 32.18796496736606,
        '-52': 32.18830777502882,
        '-51': 32.188475203791924,
        '-50': 32.18829914596876,
        '-49': 32.18781342793973,
        '-48': 32.18725690913302,
        '-47': 32.186920674029444,
        '-46': 32.1869544487334,
        '-45': 32.1872797084523,
        '-44': 32.187669435479464,
        '-43': 32.187921558990894,
        '-42': 32.1879864512172,
        '-41': 32.18795960154606,
        '-40': 32.18796754623242,
        '-39': 32.18805531733408,
        '-38': 32.1881651102147,
        '-37': 32.18820702480995,
        '-36': 32.18814771939179,
        '-35': 32.188041338005036,
        '-34': 32.18798676583297,
        '-33': 32.18805518026532,
        '-32': 32.18824434642443,
        '-31': 32.18848406875789,
        '-30': 32.18867824137465,
        '-29': 32.188753129790506,
        '-28': 32.1886889418712,
        '-27': 32.188525290449206,
        '-26': 32.18834061921065,
        '-25': 32.18821377284006,
        '-24': 32.18818515358305,
        '-23': 32.18823821941016,
        '-22': 32.188311362569564,
        '-21': 32.18833070907642,
        '-20': 32.18824305015448,
        '-19': 32.188034906311536,
        '-18': 32.18773862392216,
        '-17': 32.18743062065278,
        '-16': 32.18721688690232,
        '-15': 32.18719512241442,
        '-14': 32.18739940151765,
        '-13': 32.187761972189875,
        '-12': 32.18813319043754,
        '-11': 32.18836348995561,
        '-10': 32.18839502076244,
        '-9': 32.18828994180297,
        '-8': 32.188167635874635,
        '-7': 32.18810113319383,
        '-6': 32.18806212952155,
        '-5': 32.187961123716605,
        '-4': 32.18774211191987,
        '-3': 32.187441629011595,
        '-2': 32.187158667596286,
        '-1': 32.186970046961626
    },
    '51': {
        '0': 32.189679393246564,
        '1': 32.190026875043415,
        '2': 32.19007889673816,
        '3': 32.19003033045949,
        '4': 32.190210596264954,
        '5': 32.19032572313478,
        '6': 32.19006777387649,
        '7': 32.19071376638022,
        '8': 32.18701974398742,
        '9': 32.18730362143176,
        '10': 32.187725416290284,
        '11': 32.18941808350838,
        '12': 32.189490557158265,
        '13': 32.18878004271679,
        '14': 32.1890726512706,
        '15': 32.18814077861567,
        '16': 32.1870865608962,
        '17': 32.189673820770665,
        '18': 32.18919849238862,
        '19': 32.18869371077425,
        '20': 32.18815664301124,
        '21': 32.18738595555174,
        '22': 32.1884382514877,
        '23': 32.18751763479298,
        '24': 32.18805888290592,
        '25': 32.18831333786845,
        '26': 32.18852181970334,
        '27': 32.188660255786786,
        '28': 32.188548541615276,
        '29': 32.18865734628124,
        '30': 32.189114519850264,
        '31': 32.18926974290536,
        '32': 32.189410947617404,
        '33': 32.18942224090208,
        '34': 32.18902290377642,
        '35': 32.18874240292125,
        '36': 32.18796267645709,
        '37': 32.18734366300982,
        '38': 32.187536557546665,
        '39': 32.188184178718316,
        '40': 32.18942860480338,
        '41': 32.18865658170283,
        '42': 32.18919110060219,
        '43': 32.18899986840018,
        '44': 32.18914412485581,
        '45': 32.189007334133905,
        '46': 32.19036593343125,
        '47': 32.189385874866225,
        '48': 32.18890660840917,
        '49': 32.18886513089283,
        '50': 32.18865925040504,
        '51': 32.18932079746269,
        '52': 32.18899905497011,
        '53': 32.188769722909655,
        '54': 32.18815186982322,
        '55': 32.186850841908694,
        '56': 32.186745946834264,
        '57': 32.18624716543038,
        '58': 32.18778694830461,
        '59': 32.18835102042802,
        '60': 32.187064345101184,
        '61': 32.18702726773246,
        '62': 32.18742188814057,
        '63': 32.18681981592413,
        '64': 32.18824827241685,
        '65': 32.18871668704652,
        '66': 32.18750062524075,
        '67': 32.18749275522541,
        '68': 32.18740971260295,
        '69': 32.186785697474896,
        '70': 32.187045733541616,
        '71': 32.18705578949678,
        '72': 32.18656190599234,
        '73': 32.184537871654506,
        '74': 32.186509397254035,
        '75': 32.18672956183807,
        '76': 32.18725107012031,
        '77': 32.187602919609255,
        '78': 32.18807762286063,
        '79': 32.18772791833902,
        '80': 32.18701463716371,
        '81': 32.18711395504612,
        '82': 32.1867240789967,
        '83': 32.178434547978306,
        '84': 32.17987487650384,
        '85': 32.17734966173841,
        '86': 32.17512311760782,
        '87': 32.173729020992944,
        '88': 32.177701556777116,
        '89': 32.16817662468747,
        '90': 32.179826106647525,
        '91': 32.17821672444992,
        '92': 32.17080114854559,
        '93': 32.16577326470201,
        '94': 32.172428017570525,
        '95': 32.18014241634468,
        '96': 32.17327732662709,
        '97': 32.17912184377313,
        '98': 32.16777702690242,
        '99': 32.169065222490055,
        '100': 32.16901080977236,
        '101': 32.17057716477812,
        '102': 32.171483787701014,
        '103': 32.17421568804995,
        '104': 32.1765558542843,
        '105': 32.17470134582718,
        '106': 32.179847403580034,
        '107': 32.182467432793125,
        '108': 32.17794317762844,
        '109': 32.18215672011081,
        '110': 32.17884610724853,
        '111': 32.179362157171006,
        '112': 32.181272277090756,
        '113': 32.17818499409916,
        '114': 32.180236579690664,
        '115': 32.182477503478445,
        '116': 32.18154623875271,
        '117': 32.178378096765485,
        '118': 32.18249966674142,
        '119': 32.18307850689644,
        '120': 32.182108240926446,
        '121': 32.181961631470145,
        '122': 32.18186577532373,
        '123': 32.18329062681871,
        '124': 32.185299016848006,
        '125': 32.18630576463957,
        '126': 32.18676451963977,
        '127': 32.18892842255534,
        '128': 32.18888622842412,
        '129': 32.18769034750757,
        '130': 32.18825973441722,
        '131': 32.184405350779876,
        '132': 32.183402064935855,
        '133': 32.1864422569633,
        '134': 32.18081043922783,
        '135': 32.18249001267476,
        '136': 32.188751731461444,
        '137': 32.190264803963395,
        '138': 32.188719370520424,
        '139': 32.182455944916114,
        '140': 32.18423388699865,
        '141': 32.19075994344443,
        '142': 32.1902797171787,
        '143': 32.18623533479588,
        '144': 32.18967598733055,
        '145': 32.189875533107035,
        '146': 32.19035054620011,
        '147': 32.19084575163767,
        '148': 32.19108319614597,
        '149': 32.19093975667508,
        '150': 32.190550513776586,
        '151': 32.19025582507718,
        '152': 32.190402726041626,
        '153': 32.191110592057285,
        '154': 32.1921481865945,
        '155': 32.19301327685667,
        '156': 32.19318754307041,
        '157': 32.192431007367176,
        '158': 32.19094785181246,
        '159': 32.189319910394204,
        '160': 32.188230449119814,
        '161': 32.18812068581628,
        '162': 32.188966651500756,
        '163': 32.19030322557955,
        '164': 32.19148333923149,
        '165': 32.192022351945084,
        '166': 32.191826919344656,
        '167': 32.19118142363355,
        '168': 32.1905199243812,
        '169': 32.190145794597996,
        '170': 32.19008222122839,
        '171': 32.1901310603964,
        '172': 32.19006589244066,
        '173': 32.189801942848916,
        '174': 32.18942736403533,
        '175': 32.18910211429808,
        '176': 32.18892813759296,
        '177': 32.18889451314526,
        '178': 32.18891944141879,
        '179': 32.188929061759865,
        '180': 32.18889998935813,
        '-180': 32.18889998935813,
        '-179': 32.18884498091258,
        '-178': 32.188777771083295,
        '-177': 32.18870022406506,
        '-176': 32.188616840105176,
        '-175': 32.188547539810294,
        '-174': 32.18851673943751,
        '-173': 32.18853175665992,
        '-172': 32.18858161610284,
        '-171': 32.18866454483602,
        '-170': 32.18881405788578,
        '-169': 32.189086005457646,
        '-168': 32.18950688545616,
        '-167': 32.19002992100819,
        '-166': 32.19054805654194,
        '-165': 32.19096236511663,
        '-164': 32.19124783573325,
        '-163': 32.19145499817561,
        '-162': 32.19164403478453,
        '-161': 32.19181355411651,
        '-160': 32.19189369787087,
        '-159': 32.1918125494352,
        '-158': 32.1915735820584,
        '-157': 32.19126972656062,
        '-156': 32.19101890294937,
        '-155': 32.190879172956066,
        '-154': 32.19081875479927,
        '-153': 32.190762664777175,
        '-152': 32.19066752648738,
        '-151': 32.19055590126492,
        '-150': 32.19048587630698,
        '-149': 32.190492827077094,
        '-148': 32.19055954767635,
        '-147': 32.19063606114774,
        '-146': 32.19068192595097,
        '-145': 32.1906892228298,
        '-144': 32.19067122780697,
        '-143': 32.190636737065795,
        '-142': 32.19057839053136,
        '-141': 32.19048322324477,
        '-140': 32.190351036434286,
        '-139': 32.190202778920714,
        '-138': 32.190073780008305,
        '-137': 32.18999780093454,
        '-136': 32.18998912783736,
        '-135': 32.19002868594404,
        '-134': 32.190063711567745,
        '-133': 32.19003192517665,
        '-132': 32.18990770133357,
        '-131': 32.189742646786364,
        '-130': 32.18966082770755,
        '-129': 32.18979210146286,
        '-128': 32.19017664362567,
        '-127': 32.18957540755322,
        '-126': 32.179101933455385,
        '-125': 32.181071666865705,
        '-124': 32.17641338505755,
        '-123': 32.17511476968066,
        '-122': 32.178576764627145,
        '-121': 32.17732954405157,
        '-120': 32.17500069876976,
        '-119': 32.18685989938849,
        '-118': 32.17249620767266,
        '-117': 32.17129735354905,
        '-116': 32.169793346676386,
        '-115': 32.17492784517802,
        '-114': 32.180143757450125,
        '-113': 32.18140159135772,
        '-112': 32.182692181477826,
        '-111': 32.18271579386338,
        '-110': 32.18362325127497,
        '-109': 32.18321654307572,
        '-108': 32.18430939939992,
        '-107': 32.18445637856603,
        '-106': 32.18417620512156,
        '-105': 32.18508070722463,
        '-104': 32.1843305066872,
        '-103': 32.184795072090786,
        '-102': 32.18530817546618,
        '-101': 32.184719225149664,
        '-100': 32.185881999671324,
        '-99': 32.187325461848786,
        '-98': 32.18699081227637,
        '-97': 32.187717494793695,
        '-96': 32.187475464479476,
        '-95': 32.18668926617795,
        '-94': 32.186395674805894,
        '-93': 32.18620697655956,
        '-92': 32.185719180881804,
        '-91': 32.186313731015126,
        '-90': 32.186115518181154,
        '-89': 32.18656368104093,
        '-88': 32.1867704813497,
        '-87': 32.187066238959694,
        '-86': 32.18777080648489,
        '-85': 32.18823886302222,
        '-84': 32.1886398385419,
        '-83': 32.18834687973445,
        '-82': 32.18873095730039,
        '-81': 32.18907973289464,
        '-80': 32.188753733870406,
        '-79': 32.188012753765314,
        '-78': 32.18700032327738,
        '-77': 32.18673620628392,
        '-76': 32.18660445375436,
        '-75': 32.18584531603195,
        '-74': 32.18565312907897,
        '-73': 32.185234525645726,
        '-72': 32.18349318286588,
        '-71': 32.18410757833789,
        '-70': 32.183265563817194,
        '-69': 32.184464506232445,
        '-68': 32.18315940775294,
        '-67': 32.18486470459729,
        '-66': 32.18391907711309,
        '-65': 32.18246304319535,
        '-64': 32.18394161950563,
        '-63': 32.18434145101538,
        '-62': 32.187064055194256,
        '-61': 32.186049982600856,
        '-60': 32.18667724485248,
        '-59': 32.18920505763964,
        '-58': 32.18977744919055,
        '-57': 32.189975605329934,
        '-56': 32.18968897329696,
        '-55': 32.190243994072894,
        '-54': 32.190432746580974,
        '-53': 32.19071226240602,
        '-52': 32.19098386219172,
        '-51': 32.191079883717755,
        '-50': 32.19089311483072,
        '-49': 32.19048166493565,
        '-48': 32.1900568061825,
        '-47': 32.189851801632884,
        '-46': 32.18996755060026,
        '-45': 32.19031293959366,
        '-44': 32.19068331630957,
        '-43': 32.19091101628445,
        '-42': 32.19096889146934,
        '-41': 32.19095352842942,
        '-40': 32.190976904184424,
        '-39': 32.191065827444916,
        '-38': 32.191151353760425,
        '-37': 32.19114673311228,
        '-36': 32.1910379082658,
        '-35': 32.19090650430762,
        '-34': 32.19086824072784,
        '-33': 32.19098111132085,
        '-32': 32.1911984729046,
        '-31': 32.19140128436808,
        '-30': 32.191480583917695,
        '-29': 32.19140719283087,
        '-28': 32.191241964555786,
        '-27': 32.191087099158835,
        '-26': 32.191018672452415,
        '-25': 32.19104640066934,
        '-24': 32.19112060539881,
        '-23': 32.191171661516535,
        '-22': 32.19114933507452,
        '-21': 32.19103833644734,
        '-20': 32.1908512032279,
        '-19': 32.19061772868178,
        '-18': 32.19038566258849,
        '-17': 32.19022553829113,
        '-16': 32.19021691691544,
        '-15': 32.190405032364986,
        '-14': 32.190750770323774,
        '-13': 32.19112087665725,
        '-12': 32.19134771439062,
        '-11': 32.191332642164134,
        '-10': 32.191119062576085,
        '-9': 32.19086976737012,
        '-8': 32.19075301644015,
        '-7': 32.19081925608705,
        '-6': 32.190966003966416,
        '-5': 32.19102121873131,
        '-4': 32.19047353894511,
        '-3': 32.19031224235365,
        '-2': 32.18865558504593,
        '-1': 32.188804439942494
    },
    '52': {
        '0': 32.1915607319395,
        '1': 32.192567795905575,
        '2': 32.19309940282888,
        '3': 32.19312782971675,
        '4': 32.19311807992358,
        '5': 32.19311756835048,
        '6': 32.192988924156644,
        '7': 32.19261986222217,
        '8': 32.192849492462614,
        '9': 32.191748225258415,
        '10': 32.191764873555115,
        '11': 32.192843697281866,
        '12': 32.19322898890799,
        '13': 32.19285805969657,
        '14': 32.19301495967943,
        '15': 32.19282034187027,
        '16': 32.192417605034535,
        '17': 32.19187821476384,
        '18': 32.19165158652139,
        '19': 32.19177707811209,
        '20': 32.19178480926278,
        '21': 32.19180665897172,
        '22': 32.19119828192116,
        '23': 32.19159989671138,
        '24': 32.19178070243313,
        '25': 32.19182681710959,
        '26': 32.191897438926176,
        '27': 32.191655964047236,
        '28': 32.19151546362617,
        '29': 32.19093680583524,
        '30': 32.191322370176046,
        '31': 32.19172677132545,
        '32': 32.19215136323987,
        '33': 32.19189613790915,
        '34': 32.19187882073,
        '35': 32.191615746186734,
        '36': 32.19036377983379,
        '37': 32.19080547727499,
        '38': 32.19110528313205,
        '39': 32.1914616004751,
        '40': 32.191886652252094,
        '41': 32.19175612048172,
        '42': 32.19161459077619,
        '43': 32.19159874890883,
        '44': 32.191123038556945,
        '45': 32.19126546887305,
        '46': 32.19272589332478,
        '47': 32.19174189400832,
        '48': 32.19254911416032,
        '49': 32.19257684462645,
        '50': 32.19207095140646,
        '51': 32.19199665086027,
        '52': 32.19149867838877,
        '53': 32.19127823255784,
        '54': 32.19080481268885,
        '55': 32.19085965361699,
        '56': 32.19143328545622,
        '57': 32.18841325566174,
        '58': 32.18895947189388,
        '59': 32.19056548439174,
        '60': 32.189926900062915,
        '61': 32.18986616534516,
        '62': 32.190260683505045,
        '63': 32.19028960428455,
        '64': 32.190352101513206,
        '65': 32.19103856593697,
        '66': 32.190493297973376,
        '67': 32.18972551078552,
        '68': 32.18953793582461,
        '69': 32.19008934958888,
        '70': 32.19034755726533,
        '71': 32.18957257408195,
        '72': 32.190010700813644,
        '73': 32.19034027927392,
        '74': 32.190716212870434,
        '75': 32.19095249221649,
        '76': 32.191268590247766,
        '77': 32.19121529763547,
        '78': 32.190627494703655,
        '79': 32.190456221142064,
        '80': 32.19003145568419,
        '81': 32.19009138809553,
        '82': 32.19095250715328,
        '83': 32.19101269763034,
        '84': 32.19076089023007,
        '85': 32.19025779791495,
        '86': 32.18898156483657,
        '87': 32.18795523325875,
        '88': 32.184216676069575,
        '89': 32.178524524181185,
        '90': 32.179181326517416,
        '91': 32.17532193106066,
        '92': 32.18605723993252,
        '93': 32.18194948039552,
        '94': 32.18357257161294,
        '95': 32.17674492007318,
        '96': 32.17175552961467,
        '97': 32.180708111938024,
        '98': 32.17617824322774,
        '99': 32.17034533475699,
        '100': 32.173071996860045,
        '101': 32.172785640786735,
        '102': 32.16960841845706,
        '103': 32.18222764402185,
        '104': 32.185445108931845,
        '105': 32.1840013239653,
        '106': 32.18743208843576,
        '107': 32.186394501602905,
        '108': 32.18499920674204,
        '109': 32.18560765114141,
        '110': 32.18267490398793,
        '111': 32.184553075010434,
        '112': 32.18218359366597,
        '113': 32.18453784708798,
        '114': 32.184872767194506,
        '115': 32.18373114802397,
        '116': 32.18523876790823,
        '117': 32.18678954967149,
        '118': 32.18575182851233,
        '119': 32.18414356446199,
        '120': 32.185874362768836,
        '121': 32.186539593795764,
        '122': 32.18644970196075,
        '123': 32.18409837906732,
        '124': 32.18606688194289,
        '125': 32.18846165090034,
        '126': 32.19039072107955,
        '127': 32.19034141149581,
        '128': 32.19025493566524,
        '129': 32.1905039005137,
        '130': 32.19114718436196,
        '131': 32.18971389018036,
        '132': 32.18963324467962,
        '133': 32.18297251521847,
        '134': 32.18337292344118,
        '135': 32.180297646127435,
        '136': 32.19222300413265,
        '137': 32.19231661522895,
        '138': 32.18967733884016,
        '139': 32.189218093929505,
        '140': 32.19419285120151,
        '141': 32.19235737963458,
        '142': 32.19358005701861,
        '143': 32.1929488145559,
        '144': 32.192808957058546,
        '145': 32.19244120258991,
        '146': 32.19254335380505,
        '147': 32.19303375872314,
        '148': 32.19359892071834,
        '149': 32.19387952490363,
        '150': 32.19370848724674,
        '151': 32.19324761883596,
        '152': 32.19291658783765,
        '153': 32.19313392160831,
        '154': 32.19401885659253,
        '155': 32.19524782736913,
        '156': 32.19618073945762,
        '157': 32.19295924639392,
        '158': 32.19403846159096,
        '159': 32.19329874010846,
        '160': 32.19152315366837,
        '161': 32.19059728542447,
        '162': 32.19090082050895,
        '163': 32.192192807991404,
        '164': 32.19376202581991,
        '165': 32.19483191461916,
        '166': 32.19497269181101,
        '167': 32.19428135223958,
        '168': 32.19324795996004,
        '169': 32.19242427839175,
        '170': 32.1921174655162,
        '171': 32.192285165435564,
        '172': 32.1926552585987,
        '173': 32.192949170605424,
        '174': 32.193047500540345,
        '175': 32.19300969145036,
        '176': 32.19297415062367,
        '177': 32.19303540921165,
        '178': 32.19318415202575,
        '179': 32.19333242939148,
        '180': 32.19338663678847,
        '-180': 32.19338663678847,
        '-179': 32.19331177156152,
        '-178': 32.19314993721999,
        '-177': 32.19298938362509,
        '-176': 32.192906604674796,
        '-175': 32.19291590411427,
        '-174': 32.19295745413212,
        '-173': 32.19293520287349,
        '-172': 32.192784612110756,
        '-171': 32.192524880136595,
        '-170': 32.192254204362634,
        '-169': 32.19208573916572,
        '-168': 32.19207049508562,
        '-167': 32.1921694176198,
        '-166': 32.19229976406668,
        '-165': 32.19241702567711,
        '-164': 32.19255895393518,
        '-163': 32.19280820179046,
        '-162': 32.193202789006605,
        '-161': 32.19367468291217,
        '-160': 32.194077011063655,
        '-159': 32.19428369748196,
        '-158': 32.194279530594635,
        '-157': 32.1941643784639,
        '-156': 32.19406884662221,
        '-155': 32.19405446018884,
        '-154': 32.19408174924733,
        '-153': 32.19406676148878,
        '-152': 32.19396896592133,
        '-151': 32.193830670409355,
        '-150': 32.19373689228233,
        '-149': 32.19373711312423,
        '-148': 32.193800905619845,
        '-147': 32.19384465277149,
        '-146': 32.19380248470382,
        '-145': 32.19367911944276,
        '-144': 32.19354253187095,
        '-143': 32.19346696390814,
        '-142': 32.193474821061244,
        '-141': 32.19352147956322,
        '-140': 32.193530102038665,
        '-139': 32.19344701034102,
        '-138': 32.193277677232395,
        '-137': 32.19308101231654,
        '-136': 32.19292900393028,
        '-135': 32.19286055062631,
        '-134': 32.192860920825545,
        '-133': 32.19288104391403,
        '-132': 32.1928833444227,
        '-131': 32.19286987049716,
        '-130': 32.192931250993794,
        '-129': 32.19309899669273,
        '-128': 32.189540042613366,
        '-127': 32.17781733403525,
        '-126': 32.16968866113942,
        '-125': 32.18400417520723,
        '-124': 32.17986799370699,
        '-123': 32.18175029293518,
        '-122': 32.18406088476655,
        '-121': 32.18042742905383,
        '-120': 32.18312099482441,
        '-119': 32.17301277390311,
        '-118': 32.171711238859785,
        '-117': 32.16552966739211,
        '-116': 32.174737206894186,
        '-115': 32.18121701970938,
        '-114': 32.18384230336754,
        '-113': 32.18396753708962,
        '-112': 32.18468664567982,
        '-111': 32.18498508240925,
        '-110': 32.18606034260305,
        '-109': 32.18670072028483,
        '-108': 32.1871585999073,
        '-107': 32.18825127785537,
        '-106': 32.188102362620405,
        '-105': 32.18770772074223,
        '-104': 32.188069308177724,
        '-103': 32.18723250128305,
        '-102': 32.18830029966957,
        '-101': 32.18823887810957,
        '-100': 32.19012498673237,
        '-99': 32.18979094331942,
        '-98': 32.19022352643061,
        '-97': 32.19033606375717,
        '-96': 32.18984217616106,
        '-95': 32.18983085640152,
        '-94': 32.189420558906114,
        '-93': 32.189351096871206,
        '-92': 32.18860117303669,
        '-91': 32.18896796559702,
        '-90': 32.18909451038643,
        '-89': 32.189401618709034,
        '-88': 32.18947252904394,
        '-87': 32.189975974053326,
        '-86': 32.19033089807848,
        '-85': 32.190597156490874,
        '-84': 32.19123524671865,
        '-83': 32.191546246432566,
        '-82': 32.19172215288536,
        '-81': 32.1918568991525,
        '-80': 32.19170470560032,
        '-79': 32.19170052804216,
        '-78': 32.19078113692405,
        '-77': 32.189984220685844,
        '-76': 32.18975860066814,
        '-75': 32.18957518277092,
        '-74': 32.1890243210482,
        '-73': 32.18814951163164,
        '-72': 32.18490202459794,
        '-71': 32.18573619428215,
        '-70': 32.18486035960626,
        '-69': 32.18805503009504,
        '-68': 32.186731193998305,
        '-67': 32.18742612737314,
        '-66': 32.18572426692341,
        '-65': 32.18699785070605,
        '-64': 32.18790106103455,
        '-63': 32.18772594276991,
        '-62': 32.18864609237787,
        '-61': 32.18792785047655,
        '-60': 32.18898983200818,
        '-59': 32.18962434054652,
        '-58': 32.189281068078444,
        '-57': 32.1894039292438,
        '-56': 32.191314904577176,
        '-55': 32.19335752294295,
        '-54': 32.193368233484485,
        '-53': 32.19342500664665,
        '-52': 32.193509473382655,
        '-51': 32.19355112573666,
        '-50': 32.193493788047775,
        '-49': 32.19335969345899,
        '-48': 32.193248424090335,
        '-47': 32.19326342948428,
        '-46': 32.19342506067261,
        '-45': 32.1936460384815,
        '-44': 32.19379511324899,
        '-43': 32.193799318437826,
        '-42': 32.19370046097988,
        '-41': 32.19361865058821,
        '-40': 32.19365346044113,
        '-39': 32.193805039781644,
        '-38': 32.19397924623137,
        '-37': 32.194069299395274,
        '-36': 32.19404336464201,
        '-35': 32.19396553311675,
        '-34': 32.1939359723486,
        '-33': 32.1940033798094,
        '-32': 32.19412322522808,
        '-31': 32.194194449185396,
        '-30': 32.19414208619999,
        '-29': 32.19397793770966,
        '-28': 32.193792327118246,
        '-27': 32.19368662064227,
        '-26': 32.19370131333859,
        '-25': 32.19379355602643,
        '-24': 32.19387549703554,
        '-23': 32.19387767326828,
        '-22': 32.19378762107566,
        '-21': 32.19364104351029,
        '-20': 32.1934849737881,
        '-19': 32.19335215514194,
        '-18': 32.19326722667241,
        '-17': 32.193267397634564,
        '-16': 32.19340079381027,
        '-15': 32.193686389095724,
        '-14': 32.19406400167182,
        '-13': 32.19438823691068,
        '-12': 32.19449548183438,
        '-11': 32.19431141787204,
        '-10': 32.19150062029536,
        '-9': 32.18958299777477,
        '-8': 32.191753994506236,
        '-7': 32.19342805291638,
        '-6': 32.193674300132734,
        '-5': 32.19337593111664,
        '-4': 32.19243455663787,
        '-3': 32.19120694465707,
        '-2': 32.19269616416734,
        '-1': 32.192102227987945
    },
    '53': {
        '0': 32.195783215712844,
        '1': 32.19569493397363,
        '2': 32.19567462332548,
        '3': 32.195682425337004,
        '4': 32.19566800246544,
        '5': 32.19562293407279,
        '6': 32.19557221683694,
        '7': 32.19553242149685,
        '8': 32.19551268015278,
        '9': 32.19565293049355,
        '10': 32.19498051187191,
        '11': 32.19550524730794,
        '12': 32.195730597069414,
        '13': 32.195522078659515,
        '14': 32.195395736232555,
        '15': 32.19496228560187,
        '16': 32.19466980067354,
        '17': 32.194462692157146,
        '18': 32.1945835914042,
        '19': 32.194557776446906,
        '20': 32.1944797224538,
        '21': 32.1946953893595,
        '22': 32.19471222608501,
        '23': 32.194769320474165,
        '24': 32.19439836312475,
        '25': 32.1943330568796,
        '26': 32.19437967077372,
        '27': 32.19434055994857,
        '28': 32.19417103286399,
        '29': 32.193964566757366,
        '30': 32.1940617453782,
        '31': 32.19417713250109,
        '32': 32.19449852040561,
        '33': 32.19428117427168,
        '34': 32.19446997387792,
        '35': 32.19365604652887,
        '36': 32.19379674020457,
        '37': 32.19350755988066,
        '38': 32.19397005014076,
        '39': 32.19439129076042,
        '40': 32.19502882435861,
        '41': 32.194299607964126,
        '42': 32.194182871076436,
        '43': 32.194006753247294,
        '44': 32.1935670312717,
        '45': 32.19359716348046,
        '46': 32.19360646951883,
        '47': 32.192932007872905,
        '48': 32.193919564356584,
        '49': 32.19574578126382,
        '50': 32.195198372116245,
        '51': 32.195513807298255,
        '52': 32.19605014039268,
        '53': 32.195597549946704,
        '54': 32.194400684491484,
        '55': 32.193352718329,
        '56': 32.1945193530401,
        '57': 32.19224188104513,
        '58': 32.1901695325417,
        '59': 32.19348245633894,
        '60': 32.19266716082373,
        '61': 32.19371000959657,
        '62': 32.19386271628029,
        '63': 32.19361806004011,
        '64': 32.193371234127426,
        '65': 32.194374393086065,
        '66': 32.19341050944783,
        '67': 32.19330519607024,
        '68': 32.192658353439654,
        '69': 32.19221887078052,
        '70': 32.1926510472034,
        '71': 32.19301201060651,
        '72': 32.193820211621954,
        '73': 32.194890058324766,
        '74': 32.194504553721444,
        '75': 32.19452955537712,
        '76': 32.19438178469321,
        '77': 32.19425111194132,
        '78': 32.193826863765445,
        '79': 32.19392474678178,
        '80': 32.19419989667492,
        '81': 32.193903362695906,
        '82': 32.19292827617548,
        '83': 32.192865757935735,
        '84': 32.19333055627659,
        '85': 32.191452285845614,
        '86': 32.19130276502638,
        '87': 32.191046452542636,
        '88': 32.18744964611824,
        '89': 32.186178416640296,
        '90': 32.18908347326955,
        '91': 32.18976441199038,
        '92': 32.18993750339487,
        '93': 32.18880431273523,
        '94': 32.18394766745714,
        '95': 32.185688905880944,
        '96': 32.18431063858957,
        '97': 32.185093878419416,
        '98': 32.17772575213797,
        '99': 32.180121449797475,
        '100': 32.172275030352665,
        '101': 32.18159294933643,
        '102': 32.18955309080827,
        '103': 32.18959363377274,
        '104': 32.18901811022049,
        '105': 32.188920864817156,
        '106': 32.186921405114404,
        '107': 32.19030645897403,
        '108': 32.1902390744048,
        '109': 32.1866425102627,
        '110': 32.18367016105346,
        '111': 32.18226473756162,
        '112': 32.186179185793165,
        '113': 32.1847490295809,
        '114': 32.18635243657097,
        '115': 32.186083594600504,
        '116': 32.18793652305222,
        '117': 32.18635757311216,
        '118': 32.18775345095328,
        '119': 32.191220952002524,
        '120': 32.19009711651333,
        '121': 32.18935510195177,
        '122': 32.19072311437258,
        '123': 32.190761055381124,
        '124': 32.192149301542166,
        '125': 32.19216141529997,
        '126': 32.193894491110576,
        '127': 32.19306741964324,
        '128': 32.19295914881312,
        '129': 32.192544133927335,
        '130': 32.19287199010321,
        '131': 32.191512654027285,
        '132': 32.19165888519836,
        '133': 32.19001153031117,
        '134': 32.18560973817975,
        '135': 32.183024770089446,
        '136': 32.19494970366361,
        '137': 32.19219300689425,
        '138': 32.192174117239915,
        '139': 32.194413376579746,
        '140': 32.196126080141916,
        '141': 32.19711527261677,
        '142': 32.197158491742265,
        '143': 32.19687279501464,
        '144': 32.1962393793235,
        '145': 32.19553419779908,
        '146': 32.19515795979747,
        '147': 32.19529493799223,
        '148': 32.19582473866616,
        '149': 32.196383015590186,
        '150': 32.19659264995771,
        '151': 32.196325974362644,
        '152': 32.195820612645974,
        '153': 32.19555234586511,
        '154': 32.19592485620963,
        '155': 32.196968001715014,
        '156': 32.19825024705696,
        '157': 32.197766397459354,
        '158': 32.19035217587827,
        '159': 32.19770681497054,
        '160': 32.19591867328321,
        '161': 32.19435218834228,
        '162': 32.193666211708745,
        '163': 32.19403431162373,
        '164': 32.19508177289087,
        '165': 32.196138055150314,
        '166': 32.19663574406111,
        '167': 32.19640919958653,
        '168': 32.19572822533987,
        '169': 32.19507916960309,
        '170': 32.19485660328464,
        '171': 32.19516031540911,
        '172': 32.19579886792943,
        '173': 32.19645864879586,
        '174': 32.196904464128764,
        '175': 32.19708521049553,
        '176': 32.19710299063215,
        '177': 32.19709758465674,
        '178': 32.19713988766712,
        '179': 32.197202157354496,
        '180': 32.19720919886198,
        '-180': 32.19720919886198,
        '-179': 32.197119811358284,
        '-178': 32.19697443843359,
        '-177': 32.19687521150395,
        '-176': 32.196914537612734,
        '-175': 32.1971047290483,
        '-174': 32.19736045033129,
        '-173': 32.19754826038266,
        '-172': 32.1975686445577,
        '-171': 32.197411175643744,
        '-170': 32.19714329125084,
        '-169': 32.196844956107995,
        '-168': 32.196545228910594,
        '-167': 32.19621529407161,
        '-166': 32.19582392293785,
        '-165': 32.19540475606753,
        '-164': 32.195069946791975,
        '-163': 32.194948101219204,
        '-162': 32.195091513832445,
        '-161': 32.19542983527754,
        '-160': 32.19581324885083,
        '-159': 32.19611432058254,
        '-158': 32.19630737294569,
        '-157': 32.19646245641772,
        '-156': 32.196663204576346,
        '-155': 32.1969216648714,
        '-154': 32.19716318742415,
        '-153': 32.19729139879028,
        '-152': 32.19727330229462,
        '-151': 32.19716876017287,
        '-150': 32.19707916171121,
        '-149': 32.19706096910803,
        '-148': 32.1970791729584,
        '-147': 32.19703994849103,
        '-146': 32.19687413071062,
        '-145': 32.196601812890144,
        '-144': 32.19632598165471,
        '-143': 32.196161454109934,
        '-142': 32.19615503714661,
        '-141': 32.196255933854324,
        '-140': 32.19635421121703,
        '-139': 32.19635520312874,
        '-138': 32.19623641236237,
        '-137': 32.19605198014373,
        '-136': 32.19588939040157,
        '-135': 32.19581354995636,
        '-134': 32.19583575162022,
        '-133': 32.195922180766615,
        '-132': 32.1920603711966,
        '-131': 32.196125960800906,
        '-130': 32.19621495768401,
        '-129': 32.194173099520874,
        '-128': 32.193015846631084,
        '-127': 32.18166305385025,
        '-126': 32.18669104393314,
        '-125': 32.184942147804215,
        '-124': 32.18303177486079,
        '-123': 32.18632506856007,
        '-122': 32.184896383625244,
        '-121': 32.1790054550409,
        '-120': 32.171819712449924,
        '-119': 32.183222300769586,
        '-118': 32.17664067439017,
        '-117': 32.18050132466364,
        '-116': 32.18574389768703,
        '-115': 32.18683937949479,
        '-114': 32.18725154730961,
        '-113': 32.18805821480207,
        '-112': 32.188514661410586,
        '-111': 32.188885485233705,
        '-110': 32.189877396478835,
        '-109': 32.190672989277076,
        '-108': 32.19055485443548,
        '-107': 32.191016750315875,
        '-106': 32.191366567953544,
        '-105': 32.19155251594669,
        '-104': 32.19208394054593,
        '-103': 32.190172506231036,
        '-102': 32.192822221548006,
        '-101': 32.19330600221806,
        '-100': 32.192885543538445,
        '-99': 32.19313995644358,
        '-98': 32.193158371204085,
        '-97': 32.193016847077175,
        '-96': 32.19274334959542,
        '-95': 32.19265253198073,
        '-94': 32.19269933482101,
        '-93': 32.1930683193904,
        '-92': 32.19268016175565,
        '-91': 32.192353694015,
        '-90': 32.19253281037244,
        '-89': 32.1924574408385,
        '-88': 32.19262312473147,
        '-87': 32.192809312294685,
        '-86': 32.19320557616621,
        '-85': 32.19370201420055,
        '-84': 32.19398568555228,
        '-83': 32.19462775749799,
        '-82': 32.19493208541215,
        '-81': 32.194690890899494,
        '-80': 32.19462031541286,
        '-79': 32.19453780496112,
        '-78': 32.19331199291833,
        '-77': 32.192827118656574,
        '-76': 32.19294534121957,
        '-75': 32.191609924500675,
        '-74': 32.191680295783506,
        '-73': 32.190525383965756,
        '-72': 32.189835015208416,
        '-71': 32.18998347237265,
        '-70': 32.18882164013178,
        '-69': 32.189036908469724,
        '-68': 32.189164266412256,
        '-67': 32.18828749845095,
        '-66': 32.18986748385127,
        '-65': 32.18984410492702,
        '-64': 32.1892243533084,
        '-63': 32.19145805353063,
        '-62': 32.19182513254636,
        '-61': 32.19184165156,
        '-60': 32.19387564051079,
        '-59': 32.19187343493112,
        '-58': 32.19230519124188,
        '-57': 32.19188110570177,
        '-56': 32.19620577537042,
        '-55': 32.19641103021595,
        '-54': 32.19633069308127,
        '-53': 32.19624435618784,
        '-52': 32.19621320611521,
        '-51': 32.19624121748678,
        '-50': 32.19629736589185,
        '-49': 32.19636127432487,
        '-48': 32.19644408621369,
        '-47': 32.1965647196953,
        '-46': 32.19670827034307,
        '-45': 32.19681376016765,
        '-44': 32.19681261549464,
        '-43': 32.19669019439474,
        '-42': 32.196515764514764,
        '-41': 32.19640801964428,
        '-40': 32.196456564087136,
        '-39': 32.19665881906333,
        '-38': 32.196921948697806,
        '-37': 32.197128077362734,
        '-36': 32.197211365012656,
        '-35': 32.197188732975604,
        '-34': 32.19712642115805,
        '-33': 32.19707675996743,
        '-32': 32.19703989900357,
        '-31': 32.196978844891525,
        '-30': 32.19686810362292,
        '-29': 32.19672849838653,
        '-28': 32.196615874134864,
        '-27': 32.1965742316988,
        '-26': 32.19659601440564,
        '-25': 32.19662591572645,
        '-24': 32.19660593236146,
        '-23': 32.196522781084724,
        '-22': 32.19641655801884,
        '-21': 32.19634382517645,
        '-20': 32.19632908013162,
        '-19': 32.196349330497554,
        '-18': 32.19636647563811,
        '-17': 32.19637696545466,
        '-16': 32.1964286236307,
        '-15': 32.19658086345637,
        '-14': 32.19683670815717,
        '-13': 32.19710754336324,
        '-12': 32.197252253153344,
        '-11': 32.1971726305404,
        '-10': 32.19689500857954,
        '-9': 32.195993249764754,
        '-8': 32.195319308491015,
        '-7': 32.1958381948107,
        '-6': 32.196643554678964,
        '-5': 32.19686747212088,
        '-4': 32.192157646980746,
        '-3': 32.195825498658756,
        '-2': 32.19453173638772,
        '-1': 32.19586753994274
    },
    '54': {
        '0': 32.19866606917545,
        '1': 32.19837635064073,
        '2': 32.19823460317394,
        '3': 32.198224440741214,
        '4': 32.19829644702278,
        '5': 32.198395420176944,
        '6': 32.19848181461689,
        '7': 32.19854205138221,
        '8': 32.19858601893346,
        '9': 32.19863307904927,
        '10': 32.198440183285506,
        '11': 32.19875427405131,
        '12': 32.198605394775754,
        '13': 32.198706870284305,
        '14': 32.19869583725727,
        '15': 32.19841421638772,
        '16': 32.19831473481475,
        '17': 32.197111919484854,
        '18': 32.19714448852812,
        '19': 32.198304051300546,
        '20': 32.1974996244516,
        '21': 32.19748287138466,
        '22': 32.197395207829764,
        '23': 32.19723865363023,
        '24': 32.19783682321789,
        '25': 32.197435115520605,
        '26': 32.19748368457752,
        '27': 32.19666935950311,
        '28': 32.19664394450266,
        '29': 32.19686859724071,
        '30': 32.196637864797204,
        '31': 32.19668701621131,
        '32': 32.196785832622986,
        '33': 32.19677453014272,
        '34': 32.19658311175534,
        '35': 32.19660728469516,
        '36': 32.19621395733006,
        '37': 32.19618673061573,
        '38': 32.196695638148185,
        '39': 32.19666031237353,
        '40': 32.197674395755165,
        '41': 32.19706767367791,
        '42': 32.197275092966585,
        '43': 32.19734677081304,
        '44': 32.197544697025286,
        '45': 32.19726915465401,
        '46': 32.196678519696086,
        '47': 32.19675214192965,
        '48': 32.19772511765523,
        '49': 32.19852975130391,
        '50': 32.19855569512306,
        '51': 32.19742560916693,
        '52': 32.19812608618567,
        '53': 32.19707305294268,
        '54': 32.196651222381426,
        '55': 32.197716364500415,
        '56': 32.196365335197044,
        '57': 32.19292276904214,
        '58': 32.191794504747726,
        '59': 32.193795127579946,
        '60': 32.19587344094528,
        '61': 32.19681001373317,
        '62': 32.197051858533115,
        '63': 32.19688194305081,
        '64': 32.19664975307884,
        '65': 32.196919277119854,
        '66': 32.1969562789348,
        '67': 32.19716155108854,
        '68': 32.1972291278029,
        '69': 32.197416371145366,
        '70': 32.19740827457292,
        '71': 32.19746939130381,
        '72': 32.19800045801134,
        '73': 32.19742619296097,
        '74': 32.197569416113346,
        '75': 32.19752370400372,
        '76': 32.197482643537036,
        '77': 32.19740915339512,
        '78': 32.19724460419562,
        '79': 32.19705268485406,
        '80': 32.1966430424763,
        '81': 32.19669111125965,
        '82': 32.19572959837107,
        '83': 32.19628831912236,
        '84': 32.19616323720663,
        '85': 32.195615103739314,
        '86': 32.19254541772079,
        '87': 32.195283366044755,
        '88': 32.19500453312493,
        '89': 32.184792219335264,
        '90': 32.188549948235234,
        '91': 32.191928831165235,
        '92': 32.19407485961676,
        '93': 32.19338453996186,
        '94': 32.19376883445553,
        '95': 32.1864361278864,
        '96': 32.18309714247754,
        '97': 32.179421409053965,
        '98': 32.18279877713682,
        '99': 32.18517993112227,
        '100': 32.192795498417155,
        '101': 32.19251058800239,
        '102': 32.193080936537335,
        '103': 32.19399970068337,
        '104': 32.19135286333152,
        '105': 32.18813709665892,
        '106': 32.19069331131557,
        '107': 32.18963348990235,
        '108': 32.1843690420639,
        '109': 32.19332104073385,
        '110': 32.18838654048177,
        '111': 32.17810879902009,
        '112': 32.185776419086295,
        '113': 32.18866865018529,
        '114': 32.18900440254728,
        '115': 32.190817237424255,
        '116': 32.190588791729276,
        '117': 32.19142907198878,
        '118': 32.186952931957634,
        '119': 32.18880121975275,
        '120': 32.190107611924226,
        '121': 32.192356395879166,
        '122': 32.19254196795961,
        '123': 32.19323551330796,
        '124': 32.1947126583889,
        '125': 32.19465139429207,
        '126': 32.194186853097044,
        '127': 32.1943986186023,
        '128': 32.1931837478074,
        '129': 32.1947558814254,
        '130': 32.190505363418715,
        '131': 32.194764166377,
        '132': 32.19673629604241,
        '133': 32.19816745630084,
        '134': 32.19321575275879,
        '135': 32.183968301348955,
        '136': 32.19471283596884,
        '137': 32.19921062170766,
        '138': 32.19881398363971,
        '139': 32.1961523887916,
        '140': 32.19450896526713,
        '141': 32.199038501322114,
        '142': 32.199386694913365,
        '143': 32.19949751706393,
        '144': 32.19925574557691,
        '145': 32.1987814247642,
        '146': 32.19837589488483,
        '147': 32.19833248545844,
        '148': 32.19872904756489,
        '149': 32.199348532885956,
        '150': 32.19980025369046,
        '151': 32.199779549162784,
        '152': 32.19929826669325,
        '153': 32.19872079699693,
        '154': 32.19855840856847,
        '155': 32.19913603584492,
        '156': 32.19986680138118,
        '157': 32.19109261300126,
        '158': 32.1951713226262,
        '159': 32.199156276854396,
        '160': 32.200580955398244,
        '161': 32.19873894583174,
        '162': 32.19717126214921,
        '163': 32.196427356110654,
        '164': 32.196608910021794,
        '165': 32.19738003044888,
        '166': 32.19821217294468,
        '167': 32.19869554518997,
        '168': 32.19873002020326,
        '169': 32.19850573863186,
        '170': 32.19832200273954,
        '171': 32.19838144254184,
        '172': 32.19868709537449,
        '173': 32.19908363513111,
        '174': 32.19938715490906,
        '175': 32.19950366819664,
        '176': 32.199463787407744,
        '177': 32.19936959822574,
        '178': 32.19930802153877,
        '179': 32.19929681355055,
        '180': 32.19929468982024,
        '-180': 32.19929468982024,
        '-179': 32.19925632997979,
        '-178': 32.19918318998299,
        '-177': 32.19913037067804,
        '-176': 32.19916746492504,
        '-175': 32.199327268968425,
        '-174': 32.199583514364704,
        '-173': 32.19987306658112,
        '-172': 32.20014082705899,
        '-171': 32.200367578310036,
        '-170': 32.20055753978796,
        '-169': 32.2006999281854,
        '-168': 32.20074486606179,
        '-167': 32.200624118995684,
        '-166': 32.20030726985944,
        '-165': 32.19984769801858,
        '-164': 32.19937372414523,
        '-163': 32.19902176098849,
        '-162': 32.19885748526864,
        '-161': 32.198845300624384,
        '-160': 32.19889049665882,
        '-159': 32.19892028159369,
        '-158': 32.19893808602798,
        '-157': 32.19900811954879,
        '-156': 32.19918592444551,
        '-155': 32.19945594041138,
        '-154': 32.199730264121555,
        '-153': 32.19990991682793,
        '-152': 32.19995664488235,
        '-151': 32.199915061148985,
        '-150': 32.19986789220122,
        '-149': 32.19986354875217,
        '-148': 32.19987712518852,
        '-147': 32.19983602719955,
        '-146': 32.1996867365486,
        '-145': 32.19944624372423,
        '-144': 32.19919642068811,
        '-143': 32.19902727810311,
        '-142': 32.198975098738266,
        '-141': 32.19900253761592,
        '-140': 32.19903229445437,
        '-139': 32.19900490938969,
        '-138': 32.19891674434761,
        '-137': 32.19881417633518,
        '-136': 32.19875492881596,
        '-135': 32.198769049914574,
        '-134': 32.198846209489815,
        '-133': 32.195794026703716,
        '-132': 32.19836294212136,
        '-131': 32.19912587266898,
        '-130': 32.191170906463086,
        '-129': 32.18983245024072,
        '-128': 32.182501929526545,
        '-127': 32.19025845256782,
        '-126': 32.19061781652862,
        '-125': 32.19128620322418,
        '-124': 32.19153995877542,
        '-123': 32.19133030899427,
        '-122': 32.1916483170791,
        '-121': 32.19132297779795,
        '-120': 32.182257814219966,
        '-119': 32.18701328799273,
        '-118': 32.18749430605359,
        '-117': 32.189757626578555,
        '-116': 32.18891163949171,
        '-115': 32.191405509404724,
        '-114': 32.19129676955117,
        '-113': 32.19182047750679,
        '-112': 32.1917363802497,
        '-111': 32.19154533724134,
        '-110': 32.19180601995952,
        '-109': 32.1934933780109,
        '-108': 32.19329678011474,
        '-107': 32.19387228030038,
        '-106': 32.19327786700199,
        '-105': 32.192610215194975,
        '-104': 32.19466956384632,
        '-103': 32.19600627164934,
        '-102': 32.19598853431285,
        '-101': 32.19625080620276,
        '-100': 32.19602186625209,
        '-99': 32.196219645617184,
        '-98': 32.19620934382876,
        '-97': 32.19595144758037,
        '-96': 32.19586889135889,
        '-95': 32.19601262864851,
        '-94': 32.195904731225866,
        '-93': 32.19584318994031,
        '-92': 32.19558312168008,
        '-91': 32.195658728203504,
        '-90': 32.19572456008587,
        '-89': 32.19604364799069,
        '-88': 32.19635832436183,
        '-87': 32.19652518344105,
        '-86': 32.19646166881164,
        '-85': 32.196644548260814,
        '-84': 32.19672483904983,
        '-83': 32.197602609199805,
        '-82': 32.19803685767374,
        '-81': 32.19787221591898,
        '-80': 32.19760741447485,
        '-79': 32.19737134170519,
        '-78': 32.19578537537429,
        '-77': 32.1953978171863,
        '-76': 32.19496540274736,
        '-75': 32.19562668605475,
        '-74': 32.19525745698012,
        '-73': 32.194320240837065,
        '-72': 32.193823361046164,
        '-71': 32.193277352212334,
        '-70': 32.192609998787525,
        '-69': 32.19297040323397,
        '-68': 32.19230788572913,
        '-67': 32.192137954132384,
        '-66': 32.1929172375629,
        '-65': 32.1934941647843,
        '-64': 32.19334141271093,
        '-63': 32.19290450881327,
        '-62': 32.19140478052764,
        '-61': 32.19409752593474,
        '-60': 32.19410591951191,
        '-59': 32.1969848165955,
        '-58': 32.19752579105251,
        '-57': 32.198738605662506,
        '-56': 32.199073540153826,
        '-55': 32.19920026196198,
        '-54': 32.19917283341862,
        '-53': 32.19910414915616,
        '-52': 32.19908595773804,
        '-51': 32.19914140827635,
        '-50': 32.19923737362071,
        '-49': 32.19933373161749,
        '-48': 32.19942131438893,
        '-47': 32.19951773963595,
        '-46': 32.199632344448965,
        '-45': 32.19973973801428,
        '-44': 32.199791869392776,
        '-43': 32.199761085454455,
        '-42': 32.199675774535066,
        '-41': 32.19961363237133,
        '-40': 32.19965248338538,
        '-39': 32.199814700170066,
        '-38': 32.2000477559361,
        '-37': 32.20025531294493,
        '-36': 32.20035468075715,
        '-35': 32.200319144672434,
        '-34': 32.20017971815654,
        '-33': 32.19999411861469,
        '-32': 32.199812481942395,
        '-31': 32.199663566218184,
        '-30': 32.19956120536405,
        '-29': 32.19951265963361,
        '-28': 32.19951443358229,
        '-27': 32.19954097202085,
        '-26': 32.19954568324518,
        '-25': 32.19948580630851,
        '-24': 32.199358195675075,
        '-23': 32.199215302068126,
        '-22': 32.19913936654062,
        '-21': 32.1991852212305,
        '-20': 32.19933233826691,
        '-19': 32.19948708140219,
        '-18': 32.19954146774094,
        '-17': 32.19944998144713,
        '-16': 32.19926736933954,
        '-15': 32.19911571114735,
        '-14': 32.19910137528055,
        '-13': 32.199241642858546,
        '-12': 32.19945517799314,
        '-11': 32.19962310329475,
        '-10': 32.19906698810616,
        '-9': 32.19804319897122,
        '-8': 32.19891105940942,
        '-7': 32.198444486160355,
        '-6': 32.19976006856158,
        '-5': 32.19990431205933,
        '-4': 32.1999454837215,
        '-3': 32.19980377224817,
        '-2': 32.19557490880716,
        '-1': 32.19888350409315
    },
    '55': {
        '0': 32.201600986429426,
        '1': 32.2013360487718,
        '2': 32.20116859440649,
        '3': 32.201131473531774,
        '4': 32.201220240778056,
        '5': 32.201394896487216,
        '6': 32.20159574187711,
        '7': 32.20176727252213,
        '8': 32.201878819326694,
        '9': 32.201830098264544,
        '10': 32.20194747999609,
        '11': 32.20195230821505,
        '12': 32.201329869290625,
        '13': 32.20195815979673,
        '14': 32.20194121545964,
        '15': 32.20185653050644,
        '16': 32.20182499434795,
        '17': 32.20173183387193,
        '18': 32.20162786076177,
        '19': 32.20152700119628,
        '20': 32.20144972308821,
        '21': 32.201421859977174,
        '22': 32.201271521071654,
        '23': 32.20106785214829,
        '24': 32.20084840502357,
        '25': 32.20055763090797,
        '26': 32.20039764465827,
        '27': 32.20007689411905,
        '28': 32.20001415100875,
        '29': 32.20025924299569,
        '30': 32.19991701040344,
        '31': 32.19946425673219,
        '32': 32.1993444333578,
        '33': 32.19976212867928,
        '34': 32.19939881097603,
        '35': 32.199997901531425,
        '36': 32.20003118873862,
        '37': 32.20013943703695,
        '38': 32.20005462546929,
        '39': 32.20040915268371,
        '40': 32.20027334736788,
        '41': 32.20023578456064,
        '42': 32.19994908209272,
        '43': 32.20013053009949,
        '44': 32.1998697944204,
        '45': 32.20030135753346,
        '46': 32.200843300366934,
        '47': 32.20055443390107,
        '48': 32.200177219305864,
        '49': 32.20143960738253,
        '50': 32.20076876499101,
        '51': 32.20054168095915,
        '52': 32.19974747577825,
        '53': 32.199691259738984,
        '54': 32.19961497154758,
        '55': 32.20010424141856,
        '56': 32.200195198818044,
        '57': 32.19949598571249,
        '58': 32.197734194003175,
        '59': 32.196531961278225,
        '60': 32.19839036931275,
        '61': 32.199556210886264,
        '62': 32.20021802366822,
        '63': 32.19990093229743,
        '64': 32.19983000801964,
        '65': 32.2007354304084,
        '66': 32.200054814857154,
        '67': 32.200348808564286,
        '68': 32.20048363783475,
        '69': 32.20072475618501,
        '70': 32.20023070041889,
        '71': 32.200219029370466,
        '72': 32.20032135717318,
        '73': 32.200497459727686,
        '74': 32.2003969243591,
        '75': 32.2003348828026,
        '76': 32.2001694268611,
        '77': 32.200061210006204,
        '78': 32.20007687853655,
        '79': 32.20002855354783,
        '80': 32.199962807798535,
        '81': 32.20010458618781,
        '82': 32.20008992571936,
        '83': 32.20034348293646,
        '84': 32.199220465087585,
        '85': 32.199897895595775,
        '86': 32.19972088517783,
        '87': 32.199857879056125,
        '88': 32.19596013299116,
        '89': 32.195287893231885,
        '90': 32.19480841382679,
        '91': 32.199245559391365,
        '92': 32.198117727846366,
        '93': 32.19522393764999,
        '94': 32.19305869482033,
        '95': 32.19645625681208,
        '96': 32.19569077861356,
        '97': 32.194824299897235,
        '98': 32.19657045959977,
        '99': 32.195605719231295,
        '100': 32.194548189769094,
        '101': 32.19586217284487,
        '102': 32.19426069091372,
        '103': 32.195863332277895,
        '104': 32.19371530002725,
        '105': 32.196060274106706,
        '106': 32.19570248718979,
        '107': 32.1943066256421,
        '108': 32.19296332169929,
        '109': 32.19650553638393,
        '110': 32.19110967288547,
        '111': 32.18613658667998,
        '112': 32.18550854381328,
        '113': 32.187357124894504,
        '114': 32.18817641779956,
        '115': 32.19374181000517,
        '116': 32.191917670390744,
        '117': 32.1935842994567,
        '118': 32.19191429426307,
        '119': 32.19097215082295,
        '120': 32.194823470717104,
        '121': 32.19520338366733,
        '122': 32.194872562136915,
        '123': 32.190032567678394,
        '124': 32.192635202455314,
        '125': 32.19531917840744,
        '126': 32.19636030141857,
        '127': 32.197570099494584,
        '128': 32.19716721142917,
        '129': 32.19545511891637,
        '130': 32.190969998223565,
        '131': 32.1934688895388,
        '132': 32.18781914722772,
        '133': 32.19365121363822,
        '134': 32.198857930825376,
        '135': 32.194973745987035,
        '136': 32.20249529729858,
        '137': 32.20124874072901,
        '138': 32.20009203297697,
        '139': 32.20162298218895,
        '140': 32.201328958043064,
        '141': 32.201312918497344,
        '142': 32.201557390085824,
        '143': 32.201887086891105,
        '144': 32.20209182282631,
        '145': 32.20207920691584,
        '146': 32.20194453451182,
        '147': 32.20189913963933,
        '148': 32.202101111512626,
        '149': 32.20251067033768,
        '150': 32.20288580299135,
        '151': 32.20293961037322,
        '152': 32.20256157476176,
        '153': 32.20194600448244,
        '154': 32.20151972341791,
        '155': 32.20169135955428,
        '156': 32.20170101970851,
        '157': 32.19866780013719,
        '158': 32.19410722889164,
        '159': 32.20303921668678,
        '160': 32.19566446928125,
        '161': 32.19051062040258,
        '162': 32.20096489417303,
        '163': 32.199751571545406,
        '164': 32.19942311941324,
        '165': 32.19996563249619,
        '166': 32.2009692841211,
        '167': 32.20190895708792,
        '168': 32.20241010949097,
        '169': 32.20238807460936,
        '170': 32.202011951450366,
        '171': 32.201549930586154,
        '172': 32.20120698038375,
        '173': 32.20104538212861,
        '174': 32.201009498624224,
        '175': 32.20100951839678,
        '176': 32.20099511871076,
        '177': 32.20097489760035,
        '178': 32.20098472495282,
        '179': 32.20104221068587,
        '180': 32.2011253393243,
        '-180': 32.2011253393243,
        '-179': 32.20118725993645,
        '-178': 32.20119003006092,
        '-177': 32.20112954981414,
        '-176': 32.201036284981534,
        '-175': 32.20095780138971,
        '-174': 32.20094074553591,
        '-173': 32.20102365913095,
        '-172': 32.20123647102422,
        '-171': 32.20159419554219,
        '-170': 32.202080246487,
        '-169': 32.20263147357722,
        '-168': 32.20314501939343,
        '-167': 32.2035150960619,
        '-166': 32.20368288836315,
        '-165': 32.20366570852161,
        '-164': 32.20354163661561,
        '-163': 32.20339632712771,
        '-162': 32.20326840575041,
        '-161': 32.20313429582898,
        '-160': 32.2029431661072,
        '-159': 32.20267356873689,
        '-158': 32.20236615202435,
        '-157': 32.2021056863902,
        '-156': 32.20196628489677,
        '-155': 32.20196280322493,
        '-154': 32.20204541946415,
        '-153': 32.202139061698894,
        '-152': 32.20219452114082,
        '-151': 32.20221206213074,
        '-150': 32.20222413602951,
        '-149': 32.2022578010754,
        '-148': 32.20231079520176,
        '-147': 32.2023586438439,
        '-146': 32.20238071577399,
        '-145': 32.202377634782835,
        '-144': 32.202363563160674,
        '-143': 32.20234273127915,
        '-142': 32.2022956467342,
        '-141': 32.202191910945345,
        '-140': 32.202021402491056,
        '-139': 32.20181669969868,
        '-138': 32.20164434524183,
        '-137': 32.20156745535531,
        '-136': 32.20160624593668,
        '-135': 32.201725931169946,
        '-134': 32.20186065049764,
        '-133': 32.19724870867294,
        '-132': 32.20006786516504,
        '-131': 32.201885782996506,
        '-130': 32.201929185482875,
        '-129': 32.19877159989478,
        '-128': 32.1865997920171,
        '-127': 32.1890825890416,
        '-126': 32.19304745796949,
        '-125': 32.19304969980924,
        '-124': 32.189034999995705,
        '-123': 32.19337353188909,
        '-122': 32.18707086877042,
        '-121': 32.19271367892704,
        '-120': 32.19280500777335,
        '-119': 32.19473875774412,
        '-118': 32.19493791275365,
        '-117': 32.19468057399804,
        '-116': 32.19370814714523,
        '-115': 32.19119847609564,
        '-114': 32.19504466046901,
        '-113': 32.19559806076078,
        '-112': 32.19565674039168,
        '-111': 32.19497706340687,
        '-110': 32.1943541278535,
        '-109': 32.19407150429052,
        '-108': 32.19661101043412,
        '-107': 32.19611375264531,
        '-106': 32.19674203006827,
        '-105': 32.197574927452436,
        '-104': 32.1973932522836,
        '-103': 32.198088669712796,
        '-102': 32.198063533290174,
        '-101': 32.198145916354946,
        '-100': 32.198562454899466,
        '-99': 32.19907981761538,
        '-98': 32.199388723328795,
        '-97': 32.19908321855236,
        '-96': 32.19868706865198,
        '-95': 32.19880041159296,
        '-94': 32.199114165360605,
        '-93': 32.1990720284073,
        '-92': 32.19939559687441,
        '-91': 32.19956008373655,
        '-90': 32.199677928248256,
        '-89': 32.199956294642746,
        '-88': 32.19973484024261,
        '-87': 32.199388580506415,
        '-86': 32.19978895888061,
        '-85': 32.20006531050006,
        '-84': 32.200314168855826,
        '-83': 32.20056863829703,
        '-82': 32.20078655059227,
        '-81': 32.200690003212934,
        '-80': 32.200486005148676,
        '-79': 32.20027066918251,
        '-78': 32.19918556360903,
        '-77': 32.19801304163405,
        '-76': 32.19712969322501,
        '-75': 32.19780829428471,
        '-74': 32.19661230496342,
        '-73': 32.197080203017535,
        '-72': 32.19684957618076,
        '-71': 32.19634029099283,
        '-70': 32.19600236882813,
        '-69': 32.195197691097455,
        '-68': 32.19497508678918,
        '-67': 32.19648188454107,
        '-66': 32.19573522564316,
        '-65': 32.195865892991456,
        '-64': 32.195574440100195,
        '-63': 32.19576881375778,
        '-62': 32.19466450358934,
        '-61': 32.1990264576553,
        '-60': 32.19901789311966,
        '-59': 32.200033318182676,
        '-58': 32.201041159412256,
        '-57': 32.20154862256999,
        '-56': 32.201860885062885,
        '-55': 32.20194959640414,
        '-54': 32.20190249900255,
        '-53': 32.20184987450535,
        '-52': 32.201877163698164,
        '-51': 32.20198329014558,
        '-50': 32.202106490821286,
        '-49': 32.20218872008564,
        '-48': 32.202224649023684,
        '-47': 32.20225920009904,
        '-46': 32.20234164413586,
        '-45': 32.202478398522054,
        '-44': 32.20262466530626,
        '-43': 32.20272088917286,
        '-42': 32.20274292494179,
        '-41': 32.20272454308667,
        '-40': 32.20273405500394,
        '-39': 32.202823170421226,
        '-38': 32.20298659897072,
        '-37': 32.20316097567824,
        '-36': 32.203261692167466,
        '-35': 32.203230608759974,
        '-34': 32.20306442279789,
        '-33': 32.20281116078821,
        '-32': 32.20254400612234,
        '-31': 32.202331168936276,
        '-30': 32.20221485400466,
        '-29': 32.202201586597404,
        '-28': 32.20226148866911,
        '-27': 32.20233715126469,
        '-26': 32.20236529908808,
        '-25': 32.20230854034993,
        '-24': 32.20218241916592,
        '-23': 32.202056821903454,
        '-22': 32.202021195098986,
        '-21': 32.2021273238885,
        '-20': 32.20234457763619,
        '-19': 32.20256171994681,
        '-18': 32.202642385426785,
        '-17': 32.20250466254204,
        '-16': 32.20217535035719,
        '-15': 32.20178263384873,
        '-14': 32.20148976008496,
        '-13': 32.20141021614759,
        '-12': 32.2015555194831,
        '-11': 32.201843347817295,
        '-10': 32.20215396203584,
        '-9': 32.202394816990065,
        '-8': 32.19989216396162,
        '-7': 32.20066951338498,
        '-6': 32.20167405573373,
        '-5': 32.20045157196499,
        '-4': 32.20178846201155,
        '-3': 32.20229742498048,
        '-2': 32.2008196603904,
        '-1': 32.20190529976108
    },
    '56': {
        '0': 32.20447883378258,
        '1': 32.204406670810975,
        '2': 32.20432193035546,
        '3': 32.20425855604608,
        '4': 32.204255441297555,
        '5': 32.20432855719343,
        '6': 32.20445785091759,
        '7': 32.20459755202585,
        '8': 32.20470282688168,
        '9': 32.20441070539083,
        '10': 32.20430244305666,
        '11': 32.204774718154155,
        '12': 32.20472729383431,
        '13': 32.204032873484834,
        '14': 32.20415438706053,
        '15': 32.20478385040315,
        '16': 32.2046299787083,
        '17': 32.20439550947791,
        '18': 32.204135353634705,
        '19': 32.20392498517679,
        '20': 32.203829151884605,
        '21': 32.20387423997636,
        '22': 32.202529614101636,
        '23': 32.203213353773506,
        '24': 32.203949659216924,
        '25': 32.203751595827164,
        '26': 32.20314815006728,
        '27': 32.202938820137106,
        '28': 32.202889654795754,
        '29': 32.20280398926724,
        '30': 32.20286402179406,
        '31': 32.20270320715662,
        '32': 32.202622732043096,
        '33': 32.20245341608637,
        '34': 32.202384633988274,
        '35': 32.202477794884466,
        '36': 32.20285814611401,
        '37': 32.20270964338949,
        '38': 32.203174300820265,
        '39': 32.20346583135428,
        '40': 32.20356085890355,
        '41': 32.20314384416699,
        '42': 32.20319509099768,
        '43': 32.20355148146348,
        '44': 32.2030458655985,
        '45': 32.20348932949338,
        '46': 32.203312411876595,
        '47': 32.20337593915305,
        '48': 32.20404632497085,
        '49': 32.20315055927318,
        '50': 32.203109274985486,
        '51': 32.20359443958363,
        '52': 32.20272469528466,
        '53': 32.20352432570694,
        '54': 32.203286048317466,
        '55': 32.20275620069425,
        '56': 32.20290422485601,
        '57': 32.20246031257445,
        '58': 32.202353860015265,
        '59': 32.201736212970914,
        '60': 32.201031986033065,
        '61': 32.202604028473715,
        '62': 32.20306275261184,
        '63': 32.20281136353317,
        '64': 32.202813721360364,
        '65': 32.20266667987553,
        '66': 32.203161188883634,
        '67': 32.20288481419009,
        '68': 32.20297074318448,
        '69': 32.202900134023544,
        '70': 32.202776795552595,
        '71': 32.20291624985824,
        '72': 32.20307648899041,
        '73': 32.20315588636523,
        '74': 32.203140149296296,
        '75': 32.20260658250622,
        '76': 32.20259538719047,
        '77': 32.20254962695905,
        '78': 32.202398032796054,
        '79': 32.20255573201034,
        '80': 32.20273670546919,
        '81': 32.20293638846409,
        '82': 32.20292458598147,
        '83': 32.20319386749555,
        '84': 32.20366353685052,
        '85': 32.20285885369643,
        '86': 32.20197661695707,
        '87': 32.201913658087534,
        '88': 32.20308687105754,
        '89': 32.201509388773616,
        '90': 32.20117746197304,
        '91': 32.20105926414338,
        '92': 32.19868345750599,
        '93': 32.20211456207451,
        '94': 32.200960229326334,
        '95': 32.200787010456935,
        '96': 32.200775604157045,
        '97': 32.19968141791055,
        '98': 32.2005105020541,
        '99': 32.199167286477746,
        '100': 32.19856858227902,
        '101': 32.1956967745585,
        '102': 32.199582068484965,
        '103': 32.19993244593407,
        '104': 32.19844079805192,
        '105': 32.19612269341768,
        '106': 32.199055951713035,
        '107': 32.19655219259339,
        '108': 32.19922917677477,
        '109': 32.18809678758993,
        '110': 32.18934572477822,
        '111': 32.19915002014626,
        '112': 32.19731297663848,
        '113': 32.18943097364895,
        '114': 32.1926484651441,
        '115': 32.18764469220727,
        '116': 32.192046311182025,
        '117': 32.18725671876954,
        '118': 32.192113162100384,
        '119': 32.19329919337209,
        '120': 32.19439242453053,
        '121': 32.193309597660466,
        '122': 32.193318735289,
        '123': 32.19643420212068,
        '124': 32.192994390369584,
        '125': 32.195601629045626,
        '126': 32.19490730798183,
        '127': 32.19401244299304,
        '128': 32.197020546850624,
        '129': 32.1956260134383,
        '130': 32.1930343687669,
        '131': 32.19475776859302,
        '132': 32.19304919043819,
        '133': 32.19147617521358,
        '134': 32.192822140963074,
        '135': 32.198930586873665,
        '136': 32.1953450465762,
        '137': 32.201334714998026,
        '138': 32.205348533959665,
        '139': 32.20502783604063,
        '140': 32.20460730966752,
        '141': 32.20432193253179,
        '142': 32.2043252867313,
        '143': 32.204594210009,
        '144': 32.20495488211804,
        '145': 32.20520839828752,
        '146': 32.205263815677775,
        '147': 32.205183381860174,
        '148': 32.20511064134884,
        '149': 32.20513971195739,
        '150': 32.205229073605366,
        '151': 32.20523136802224,
        '152': 32.205023301748525,
        '153': 32.20463979737416,
        '154': 32.20430380714981,
        '155': 32.20431071801153,
        '156': 32.20446568129165,
        '157': 32.203899898051844,
        '158': 32.20141973012124,
        '159': 32.19749705632732,
        '160': 32.20514119631944,
        '161': 32.20313981560933,
        '162': 32.20339859938594,
        '163': 32.203705735365034,
        '164': 32.2033066380135,
        '165': 32.2036402232699,
        '166': 32.20445521030571,
        '167': 32.20531006713318,
        '168': 32.20580508296442,
        '169': 32.20576224937904,
        '170': 32.2052679242699,
        '171': 32.20457951367252,
        '172': 32.20396972607851,
        '173': 32.20360122971861,
        '174': 32.20348862855861,
        '175': 32.2035442471259,
        '176': 32.20365920476218,
        '177': 32.20376485180428,
        '178': 32.203846885686964,
        '179': 32.203920787116054,
        '180': 32.20399797105187,
        '-180': 32.20399797105187,
        '-179': 32.20406835254167,
        '-178': 32.20410607040873,
        '-177': 32.204087819779055,
        '-176': 32.20400872642823,
        '-175': 32.20388773468727,
        '-174': 32.20376365496005,
        '-173': 32.20368638205969,
        '-172': 32.2037053210533,
        '-171': 32.20385479786108,
        '-170': 32.20413888160287,
        '-169': 32.20452342483148,
        '-168': 32.20494408314,
        '-167': 32.20533112078975,
        '-166': 32.20563908758379,
        '-165': 32.20586250841249,
        '-164': 32.2060255910037,
        '-163': 32.20615249685438,
        '-162': 32.2062391540613,
        '-161': 32.20624900016565,
        '-160': 32.20150709124295,
        '-159': 32.20553589838598,
        '-158': 32.20554399314315,
        '-157': 32.20517771572627,
        '-156': 32.20487800109337,
        '-155': 32.204699526290206,
        '-154': 32.20464687673809,
        '-153': 32.204683100530445,
        '-152': 32.204755403997716,
        '-151': 32.204820449912305,
        '-150': 32.20485808493579,
        '-149': 32.20487314054385,
        '-148': 32.2048900932879,
        '-147': 32.2049427657654,
        '-146': 32.20505780275538,
        '-145': 32.20523378977448,
        '-144': 32.20542654584131,
        '-143': 32.20555559731049,
        '-142': 32.2055382024409,
        '-141': 32.205337802513554,
        '-140': 32.204997676114104,
        '-139': 32.20463318498299,
        '-138': 32.204379811132796,
        '-137': 32.20432435816559,
        '-136': 32.20446099448924,
        '-135': 32.20469990654658,
        '-134': 32.20492248287873,
        '-133': 32.20438890836394,
        '-132': 32.20493185750131,
        '-131': 32.198007072328004,
        '-130': 32.19105783625949,
        '-129': 32.196627463928074,
        '-128': 32.194759188788574,
        '-127': 32.193456714505054,
        '-126': 32.190957682500695,
        '-125': 32.189433784174845,
        '-124': 32.19828012347955,
        '-123': 32.194724536867945,
        '-122': 32.19830170934673,
        '-121': 32.19577512378939,
        '-120': 32.19797359157709,
        '-119': 32.198461123664636,
        '-118': 32.19826125335819,
        '-117': 32.19823110760629,
        '-116': 32.197470051544606,
        '-115': 32.19806045056471,
        '-114': 32.198883091430595,
        '-113': 32.198811487394664,
        '-112': 32.19761881285894,
        '-111': 32.19813082295549,
        '-110': 32.19872635321361,
        '-109': 32.19971175240535,
        '-108': 32.19940030366882,
        '-107': 32.199598650519306,
        '-106': 32.199119861039186,
        '-105': 32.19981417561145,
        '-104': 32.200121775167915,
        '-103': 32.20043001850297,
        '-102': 32.20067515929473,
        '-101': 32.20095967180056,
        '-100': 32.20118315246308,
        '-99': 32.20163232310762,
        '-98': 32.20161480833634,
        '-97': 32.202042570906954,
        '-96': 32.20188626644201,
        '-95': 32.201975111286416,
        '-94': 32.20216606697513,
        '-93': 32.2025276835709,
        '-92': 32.2020130897271,
        '-91': 32.20186476070725,
        '-90': 32.20184115870242,
        '-89': 32.20227646269631,
        '-88': 32.20295489701247,
        '-87': 32.203120993179816,
        '-86': 32.2029978203176,
        '-85': 32.20297526983177,
        '-84': 32.2030566837469,
        '-83': 32.20318705573765,
        '-82': 32.20328621849044,
        '-81': 32.203300889587524,
        '-80': 32.20324073030557,
        '-79': 32.20290985969778,
        '-78': 32.20317747367698,
        '-77': 32.203292097998975,
        '-76': 32.20093268552377,
        '-75': 32.20085690801177,
        '-74': 32.201323473144676,
        '-73': 32.20031014450975,
        '-72': 32.19989488840683,
        '-71': 32.19945773983832,
        '-70': 32.19825931494553,
        '-69': 32.19962330421901,
        '-68': 32.199508575230865,
        '-67': 32.199303551661764,
        '-66': 32.20006390244528,
        '-65': 32.19904393201101,
        '-64': 32.19981717976143,
        '-63': 32.20021785811393,
        '-62': 32.202756756393846,
        '-61': 32.203472849917304,
        '-60': 32.203647070020686,
        '-59': 32.20404179558245,
        '-58': 32.20448277226374,
        '-57': 32.20478471432198,
        '-56': 32.204851483904676,
        '-55': 32.204721273318285,
        '-54': 32.20453128217251,
        '-53': 32.20442747926441,
        '-52': 32.20447893659233,
        '-51': 32.20464994656317,
        '-50': 32.20484157292609,
        '-49': 32.20496721833326,
        '-48': 32.20500726923396,
        '-47': 32.20500808491575,
        '-46': 32.2050335834089,
        '-45': 32.20511095505697,
        '-44': 32.205212163610945,
        '-43': 32.20528227917843,
        '-42': 32.205289281973656,
        '-41': 32.20525524277302,
        '-40': 32.20524501293709,
        '-39': 32.20532133808637,
        '-38': 32.20549925518549,
        '-37': 32.20573118542892,
        '-36': 32.20593092080016,
        '-35': 32.20601862890136,
        '-34': 32.20595846153144,
        '-33': 32.20576982292699,
        '-32': 32.20551239211105,
        '-31': 32.20525868987166,
        '-30': 32.20506897648055,
        '-29': 32.204975602997614,
        '-28': 32.20497695324504,
        '-27': 32.20504007003586,
        '-26': 32.20511316274823,
        '-25': 32.205147959222806,
        '-24': 32.20512498834596,
        '-23': 32.20506773091229,
        '-22': 32.205032726730906,
        '-21': 32.205075414499476,
        '-20': 32.20520890066566,
        '-19': 32.20538208750016,
        '-18': 32.2054956220267,
        '-17': 32.20545186742061,
        '-16': 32.20521252646027,
        '-15': 32.20483009560036,
        '-14': 32.20443277026974,
        '-13': 32.20416869181584,
        '-12': 32.20413826701409,
        '-11': 32.20434921518667,
        '-10': 32.2047156342336,
        '-9': 32.20509883640573,
        '-8': 32.205367689066314,
        '-7': 32.20544945895296,
        '-6': 32.20534974711397,
        '-5': 32.20313280092455,
        '-4': 32.203130549650346,
        '-3': 32.20471389847143,
        '-2': 32.20459827179582,
        '-1': 32.20453343832588
    },
    '57': {
        '0': 32.2072299290721,
        '1': 32.2072927170056,
        '2': 32.2072887309127,
        '3': 32.20722586731346,
        '4': 32.207154505343965,
        '5': 32.20712543583245,
        '6': 32.20715407856546,
        '7': 32.207213986521786,
        '8': 32.20726110453661,
        '9': 32.20726911462345,
        '10': 32.20716849941652,
        '11': 32.207241163747106,
        '12': 32.20727746873407,
        '13': 32.20591548985066,
        '14': 32.205925974464186,
        '15': 32.20536262592352,
        '16': 32.20631165113126,
        '17': 32.20693319210425,
        '18': 32.20660020445657,
        '19': 32.20634012776046,
        '20': 32.206248866937024,
        '21': 32.20635426249092,
        '22': 32.20620859067077,
        '23': 32.2061107393988,
        '24': 32.207009055727596,
        '25': 32.2062168081417,
        '26': 32.205192797953444,
        '27': 32.20613982227431,
        '28': 32.20614363495604,
        '29': 32.206054963246345,
        '30': 32.20597784050786,
        '31': 32.206292387127405,
        '32': 32.204304853994465,
        '33': 32.20456368300813,
        '34': 32.204618717215126,
        '35': 32.20574808394265,
        '36': 32.20602422704156,
        '37': 32.20618842735531,
        '38': 32.206385931266,
        '39': 32.20623498192394,
        '40': 32.20600648647035,
        '41': 32.206035682575475,
        '42': 32.205798030983175,
        '43': 32.20584712319188,
        '44': 32.2058502505825,
        '45': 32.20585387154251,
        '46': 32.20641161107637,
        '47': 32.206258336465304,
        '48': 32.20614171546736,
        '49': 32.20605318669317,
        '50': 32.20648856755181,
        '51': 32.206644686533615,
        '52': 32.20560976306441,
        '53': 32.20576496937824,
        '54': 32.20615192813656,
        '55': 32.20527723724908,
        '56': 32.20397536802233,
        '57': 32.20543582149176,
        '58': 32.20540179910352,
        '59': 32.20481433144803,
        '60': 32.204232595671606,
        '61': 32.205082565521494,
        '62': 32.20562813967272,
        '63': 32.20562461280931,
        '64': 32.20574469071562,
        '65': 32.20615655287555,
        '66': 32.206229830231095,
        '67': 32.20604238071713,
        '68': 32.20586954329697,
        '69': 32.20597155555905,
        '70': 32.20580101697289,
        '71': 32.20574300933281,
        '72': 32.205797632531436,
        '73': 32.20591051606295,
        '74': 32.20613482177277,
        '75': 32.20587723020507,
        '76': 32.20538195629371,
        '77': 32.20525780508296,
        '78': 32.20525122795607,
        '79': 32.20531935962056,
        '80': 32.205393142976384,
        '81': 32.20570138818598,
        '82': 32.20563462120832,
        '83': 32.20539267558229,
        '84': 32.20542863534676,
        '85': 32.20503469659917,
        '86': 32.20508257314712,
        '87': 32.20548874298591,
        '88': 32.20485632192644,
        '89': 32.204592515755856,
        '90': 32.20366229951701,
        '91': 32.203821427635994,
        '92': 32.203253296575674,
        '93': 32.20426785492543,
        '94': 32.20208060340443,
        '95': 32.20449141051442,
        '96': 32.20330496962363,
        '97': 32.20254473585257,
        '98': 32.2020954529116,
        '99': 32.20131383876424,
        '100': 32.200982777426745,
        '101': 32.201409389601814,
        '102': 32.20190400328905,
        '103': 32.202218253708786,
        '104': 32.2020143743303,
        '105': 32.20214694892058,
        '106': 32.199847005842535,
        '107': 32.20070037432409,
        '108': 32.202574771243775,
        '109': 32.1976355952234,
        '110': 32.19523579390354,
        '111': 32.197764521530665,
        '112': 32.20165455948041,
        '113': 32.20162097213433,
        '114': 32.19554996315628,
        '115': 32.197412701341115,
        '116': 32.19416750749224,
        '117': 32.19405509971803,
        '118': 32.18924051853813,
        '119': 32.197878991005055,
        '120': 32.191174269539715,
        '121': 32.20009614178806,
        '122': 32.19983636528047,
        '123': 32.198449812750475,
        '124': 32.19923205744519,
        '125': 32.20007447481624,
        '126': 32.197052050880316,
        '127': 32.198415135366965,
        '128': 32.192759597646834,
        '129': 32.19527720822764,
        '130': 32.19907452359564,
        '131': 32.19853337986941,
        '132': 32.19727433488455,
        '133': 32.199566032433644,
        '134': 32.20069284147829,
        '135': 32.20390689704459,
        '136': 32.20385325624118,
        '137': 32.2011120110453,
        '138': 32.19611766841828,
        '139': 32.20809927217622,
        '140': 32.20774301192428,
        '141': 32.20736760141433,
        '142': 32.20718229467355,
        '143': 32.20727506441923,
        '144': 32.207562277560896,
        '145': 32.20785115318806,
        '146': 32.20796806006702,
        '147': 32.20786366654947,
        '148': 32.20762673285323,
        '149': 32.207404976228005,
        '150': 32.20729600376265,
        '151': 32.20728864334588,
        '152': 32.20729433274789,
        '153': 32.20724013006445,
        '154': 32.207148943472646,
        '155': 32.20714168224239,
        '156': 32.207353418269,
        '157': 32.20732443743222,
        '158': 32.204420046392315,
        '159': 32.202781713272984,
        '160': 32.20326810978538,
        '161': 32.20724514994401,
        '162': 32.204759545623915,
        '163': 32.20760862772309,
        '164': 32.20721962280405,
        '165': 32.20718712588861,
        '166': 32.20744882812442,
        '167': 32.20779872811211,
        '168': 32.20801007334674,
        '169': 32.20795611424257,
        '170': 32.20766280322431,
        '171': 32.207273737077145,
        '172': 32.20695919063832,
        '173': 32.206827041833286,
        '174': 32.20688237078846,
        '175': 32.20704702227499,
        '176': 32.20721560244621,
        '177': 32.207310432904144,
        '178': 32.2073084150907,
        '179': 32.20723542226532,
        '180': 32.20714198296373,
        '-180': 32.20714198296373,
        '-179': 32.207078157053765,
        '-178': 32.20707788318344,
        '-177': 32.20715309342518,
        '-176': 32.2072934445753,
        '-175': 32.207469440242846,
        '-174': 32.207640002890855,
        '-173': 32.20776518477169,
        '-172': 32.20782052004357,
        '-171': 32.20780597678679,
        '-170': 32.20774400015347,
        '-169': 32.207667615589266,
        '-168': 32.20760606136574,
        '-167': 32.20757650259321,
        '-166': 32.20758499197019,
        '-165': 32.20763242424307,
        '-164': 32.207717970353755,
        '-163': 32.20783599606277,
        '-162': 32.20796959013241,
        '-161': 32.208088327075224,
        '-160': 32.20815579398402,
        '-159': 32.208145238961976,
        '-158': 32.20548382547023,
        '-157': 32.204097807023224,
        '-156': 32.207770658324726,
        '-155': 32.20767886194283,
        '-154': 32.207668531861216,
        '-153': 32.20773416523452,
        '-152': 32.207834318036284,
        '-151': 32.20790854850998,
        '-150': 32.20790571963728,
        '-149': 32.20781195634331,
        '-148': 32.20766327394962,
        '-147': 32.207532158890984,
        '-146': 32.20748991253979,
        '-145': 32.207562361980095,
        '-144': 32.20770545411467,
        '-143': 32.207820466650325,
        '-142': 32.20780651988962,
        '-141': 32.207622963729044,
        '-140': 32.20732336013222,
        '-139': 32.20703626909605,
        '-138': 32.20689983297601,
        '-137': 32.206987271724174,
        '-136': 32.207268024234395,
        '-135': 32.194656093423845,
        '-134': 32.207883605468496,
        '-133': 32.20810571520803,
        '-132': 32.19509393567539,
        '-131': 32.19072940153267,
        '-130': 32.192739100247465,
        '-129': 32.192770149772535,
        '-128': 32.19586840814386,
        '-127': 32.1906310582173,
        '-126': 32.191051503807984,
        '-125': 32.19949445110086,
        '-124': 32.18874365987374,
        '-123': 32.19704614365069,
        '-122': 32.197785370543876,
        '-121': 32.199805637709886,
        '-120': 32.19953075825296,
        '-119': 32.199317480988014,
        '-118': 32.1996183746843,
        '-117': 32.20164049724884,
        '-116': 32.199680335148656,
        '-115': 32.20230998905422,
        '-114': 32.20160710534096,
        '-113': 32.201952746943576,
        '-112': 32.20241542914212,
        '-111': 32.20185380894997,
        '-110': 32.200955939211944,
        '-109': 32.20198992579862,
        '-108': 32.20200564376672,
        '-107': 32.20171534593004,
        '-106': 32.20144810530157,
        '-105': 32.201863780212236,
        '-104': 32.20276244498049,
        '-103': 32.20341151788752,
        '-102': 32.203478837566294,
        '-101': 32.20324380964583,
        '-100': 32.203691931241785,
        '-99': 32.203827702357785,
        '-98': 32.20414395655298,
        '-97': 32.20375849214374,
        '-96': 32.20442382190741,
        '-95': 32.20413911531753,
        '-94': 32.205202684372445,
        '-93': 32.205642335497814,
        '-92': 32.20595259142853,
        '-91': 32.20552310343718,
        '-90': 32.205851583333256,
        '-89': 32.205760352304175,
        '-88': 32.20562107680285,
        '-87': 32.20550951441236,
        '-86': 32.20547461199989,
        '-85': 32.20553696785799,
        '-84': 32.20567025072721,
        '-83': 32.205811013310985,
        '-82': 32.20589441551587,
        '-81': 32.20589503285046,
        '-80': 32.205845814282725,
        '-79': 32.205820063457736,
        '-78': 32.20588458697258,
        '-77': 32.20605198241311,
        '-76': 32.204127574750316,
        '-75': 32.20368003027922,
        '-74': 32.2031509213481,
        '-73': 32.20323786157441,
        '-72': 32.20206084210592,
        '-71': 32.20237837023373,
        '-70': 32.20203435538391,
        '-69': 32.2033620537655,
        '-68': 32.20337813659803,
        '-67': 32.203967324276185,
        '-66': 32.20353450279505,
        '-65': 32.20147699666859,
        '-64': 32.20037169988339,
        '-63': 32.20227819215306,
        '-62': 32.20531309063134,
        '-61': 32.20677468875426,
        '-60': 32.207003759156294,
        '-59': 32.20734395677048,
        '-58': 32.207635978168085,
        '-57': 32.20774453103962,
        '-56': 32.20763384368795,
        '-55': 32.20738487244719,
        '-54': 32.207145613666015,
        '-53': 32.207046399559104,
        '-52': 32.20713201870178,
        '-51': 32.20734933812556,
        '-50': 32.20759208605961,
        '-49': 32.20776871551201,
        '-48': 32.207848386848255,
        '-47': 32.20785975916838,
        '-46': 32.20785188580182,
        '-45': 32.20785109027923,
        '-44': 32.207845980336465,
        '-43': 32.207808251965815,
        '-42': 32.20772921151469,
        '-41': 32.20764139299459,
        '-40': 32.20760756719732,
        '-39': 32.207684500013045,
        '-38': 32.207886792049635,
        '-37': 32.20817468305515,
        '-36': 32.208471830317514,
        '-35': 32.208699367139104,
        '-34': 32.208805118203976,
        '-33': 32.20877482433423,
        '-32': 32.208626843791244,
        '-31': 32.208400788493535,
        '-30': 32.208148341524236,
        '-29': 32.207925851394364,
        '-28': 32.20778305028846,
        '-27': 32.20774612185827,
        '-26': 32.20780278688589,
        '-25': 32.20790238195509,
        '-24': 32.20797793774043,
        '-23': 32.20798230787065,
        '-22': 32.20791731699731,
        '-21': 32.20783494597569,
        '-20': 32.2078052585807,
        '-19': 32.20786792464154,
        '-18': 32.20799774393451,
        '-17': 32.20810906909285,
        '-16': 32.208101349882696,
        '-15': 32.207921781520795,
        '-14': 32.207607916519294,
        '-13': 32.20728208167614,
        '-12': 32.20709605421746,
        '-11': 32.20715341614515,
        '-10': 32.20745134963325,
        '-9': 32.20787465528884,
        '-8': 32.20824711318107,
        '-7': 32.208414362811304,
        '-6': 32.20831520456427,
        '-5': 32.20220480370278,
        '-4': 32.20167113030051,
        '-3': 32.20118875284163,
        '-2': 32.207154062314004,
        '-1': 32.20715326375968
    },
    '58': {
        '0': 32.20987797071303,
        '1': 32.20990464634982,
        '2': 32.20990359110642,
        '3': 32.209877675491754,
        '4': 32.20986822709805,
        '5': 32.20991262420499,
        '6': 32.210007331532616,
        '7': 32.21007308667001,
        '8': 32.21013810418632,
        '9': 32.21007955330611,
        '10': 32.209953466391894,
        '11': 32.20983028889311,
        '12': 32.20840365896395,
        '13': 32.20829259690901,
        '14': 32.20719976485183,
        '15': 32.2074887892946,
        '16': 32.20852156403465,
        '17': 32.20955606809754,
        '18': 32.209219641459235,
        '19': 32.20893749891914,
        '20': 32.208826837082334,
        '21': 32.20892958774802,
        '22': 32.20918992321074,
        '23': 32.20948504369055,
        '24': 32.209690871145035,
        '25': 32.209066353247785,
        '26': 32.20914250246205,
        '27': 32.20855595261726,
        '28': 32.20918754896939,
        '29': 32.20858332864608,
        '30': 32.20913411682654,
        '31': 32.209042840798624,
        '32': 32.208883529008055,
        '33': 32.208398178657454,
        '34': 32.20807966685823,
        '35': 32.20788247316389,
        '36': 32.20801891418381,
        '37': 32.208353171157526,
        '38': 32.20857888917295,
        '39': 32.20883958090971,
        '40': 32.208547632748555,
        '41': 32.20907431553679,
        '42': 32.20851914614867,
        '43': 32.20893169678573,
        '44': 32.20880347254357,
        '45': 32.20874449213193,
        '46': 32.20913598309003,
        '47': 32.20922382086877,
        '48': 32.20935460711183,
        '49': 32.208856898160434,
        '50': 32.20901408935656,
        '51': 32.208705095831505,
        '52': 32.20815554099667,
        '53': 32.20846880703423,
        '54': 32.20743336780974,
        '55': 32.20801789524153,
        '56': 32.20911701220123,
        '57': 32.20890448850839,
        '58': 32.20738548776152,
        '59': 32.20790890506113,
        '60': 32.208347247977606,
        '61': 32.207877534478975,
        '62': 32.20860831907661,
        '63': 32.20836708841634,
        '64': 32.208721730209085,
        '65': 32.20879436214308,
        '66': 32.209125006386145,
        '67': 32.209163584391966,
        '68': 32.20922526817645,
        '69': 32.2091138038914,
        '70': 32.208995677178244,
        '71': 32.20859875701113,
        '72': 32.208408851240854,
        '73': 32.20846160698016,
        '74': 32.20834486456028,
        '75': 32.20837373340299,
        '76': 32.20840949951538,
        '77': 32.20862004771812,
        '78': 32.20836133584419,
        '79': 32.20865932627986,
        '80': 32.20845616633682,
        '81': 32.20818267839681,
        '82': 32.208143831886495,
        '83': 32.208422495625925,
        '84': 32.20814923868627,
        '85': 32.20783483154202,
        '86': 32.20779230689523,
        '87': 32.20787321901946,
        '88': 32.20748420057698,
        '89': 32.20718104413558,
        '90': 32.20709381927904,
        '91': 32.20699000233625,
        '92': 32.20791568031782,
        '93': 32.20834298254385,
        '94': 32.20757036696157,
        '95': 32.20730579748488,
        '96': 32.205671616264944,
        '97': 32.20499681663686,
        '98': 32.20589766418255,
        '99': 32.205505128173535,
        '100': 32.20601220170604,
        '101': 32.20573180644261,
        '102': 32.20628732040555,
        '103': 32.20596297414956,
        '104': 32.20273785800031,
        '105': 32.20333367045337,
        '106': 32.20379150161896,
        '107': 32.203692120013365,
        '108': 32.20342610502242,
        '109': 32.2038024207592,
        '110': 32.2043393985016,
        '111': 32.20428067702506,
        '112': 32.20328292937065,
        '113': 32.19940867732443,
        '114': 32.20266899202457,
        '115': 32.20273951374325,
        '116': 32.19893723307518,
        '117': 32.197550394327344,
        '118': 32.20431423493236,
        '119': 32.1991014249656,
        '120': 32.20265076242995,
        '121': 32.201135950244776,
        '122': 32.20241580121835,
        '123': 32.20168596661605,
        '124': 32.20383123682156,
        '125': 32.20275609442962,
        '126': 32.19968448729019,
        '127': 32.20501917511757,
        '128': 32.20208072900923,
        '129': 32.195366948885955,
        '130': 32.19455364032562,
        '131': 32.20389387475613,
        '132': 32.203661465568565,
        '133': 32.20378605180724,
        '134': 32.202382730114515,
        '135': 32.20359975838127,
        '136': 32.206698235402044,
        '137': 32.204712809007496,
        '138': 32.20293222025408,
        '139': 32.2035545616798,
        '140': 32.20322727728664,
        '141': 32.21004599984111,
        '142': 32.209780652952375,
        '143': 32.20972712593659,
        '144': 32.20988633209378,
        '145': 32.21013487689872,
        '146': 32.210309475429526,
        '147': 32.210312014779085,
        '148': 32.2101656548709,
        '149': 32.20998812310342,
        '150': 32.20990505821033,
        '151': 32.20996503150085,
        '152': 32.21011348439272,
        '153': 32.210240114336486,
        '154': 32.210263678109456,
        '155': 32.21019414001167,
        '156': 32.21012947532225,
        '157': 32.21018971607299,
        '158': 32.21043265751968,
        '159': 32.209673049062495,
        '160': 32.2094088445812,
        '161': 32.207072431660364,
        '162': 32.21130504230194,
        '163': 32.21100144041285,
        '164': 32.21056498230157,
        '165': 32.21013460265577,
        '166': 32.20981425694289,
        '167': 32.20963744859784,
        '168': 32.20957453101369,
        '169': 32.20956889794197,
        '170': 32.20957540438358,
        '171': 32.209579657402756,
        '172': 32.2095931186661,
        '173': 32.209633876914936,
        '174': 32.20970797331677,
        '175': 32.20980126669261,
        '176': 32.20988327364489,
        '177': 32.20991871541849,
        '178': 32.209881659690126,
        '179': 32.20976859386729,
        '180': 32.209607118696105,
        '-180': 32.209607118696105,
        '-179': 32.209455774177634,
        '-178': 32.20939068831962,
        '-177': 32.20947923113894,
        '-176': 32.20974891924877,
        '-175': 32.21016633848848,
        '-174': 32.21063983735253,
        '-173': 32.21104939924103,
        '-172': 32.2112923976895,
        '-171': 32.21132340202177,
        '-170': 32.21116849060032,
        '-169': 32.21090761737276,
        '-168': 32.21063502538977,
        '-167': 32.21041978655881,
        '-166': 32.21028610750194,
        '-165': 32.21022002237569,
        '-164': 32.210193629815436,
        '-163': 32.21018902345406,
        '-162': 32.21020746001869,
        '-161': 32.21026067260574,
        '-160': 32.21035342138802,
        '-159': 32.21047148248916,
        '-158': 32.210584600787094,
        '-157': 32.21052130438411,
        '-156': 32.20725486737759,
        '-155': 32.210700219129684,
        '-154': 32.21070622506614,
        '-153': 32.207527680317426,
        '-152': 32.210784776263836,
        '-151': 32.21080889675581,
        '-150': 32.210754214238925,
        '-149': 32.21058641187538,
        '-148': 32.210318939357094,
        '-147': 32.21001411867377,
        '-146': 32.20975418120506,
        '-145': 32.20959786246257,
        '-144': 32.20954946909022,
        '-143': 32.209561356065784,
        '-142': 32.20956985126221,
        '-141': 32.20954199627879,
        '-140': 32.2095017272394,
        '-139': 32.209516465135074,
        '-138': 32.20965130503179,
        '-137': 32.209920591278475,
        '-136': 32.20387572338835,
        '-135': 32.20824219825093,
        '-134': 32.210825371479025,
        '-133': 32.19385611982439,
        '-132': 32.189743464915445,
        '-131': 32.2073348846365,
        '-130': 32.20077577093207,
        '-129': 32.1951526205127,
        '-128': 32.19440996624781,
        '-127': 32.19115759682365,
        '-126': 32.195868675822815,
        '-125': 32.18837849671613,
        '-124': 32.19687850602974,
        '-123': 32.201154858774856,
        '-122': 32.20239054055092,
        '-121': 32.20480124682027,
        '-120': 32.20284295403183,
        '-119': 32.20250620210977,
        '-118': 32.205494147251045,
        '-117': 32.20606801779829,
        '-116': 32.206075820760205,
        '-115': 32.206480621819956,
        '-114': 32.206705512197864,
        '-113': 32.203059192537246,
        '-112': 32.20615370503779,
        '-111': 32.20628721064911,
        '-110': 32.20508192489647,
        '-109': 32.203913860705214,
        '-108': 32.20391432589334,
        '-107': 32.20468507088233,
        '-106': 32.20534106357482,
        '-105': 32.20438493602812,
        '-104': 32.20541692485056,
        '-103': 32.205032735559044,
        '-102': 32.20582339756719,
        '-101': 32.205435267668605,
        '-100': 32.20554044899561,
        '-99': 32.20616519588644,
        '-98': 32.20595343653892,
        '-97': 32.206236297265455,
        '-96': 32.20663094537547,
        '-95': 32.20720975288871,
        '-94': 32.207895019259034,
        '-93': 32.20828816404122,
        '-92': 32.208450520024485,
        '-91': 32.20843561899644,
        '-90': 32.20840968341391,
        '-89': 32.20836701364298,
        '-88': 32.20832804561399,
        '-87': 32.208326771966526,
        '-86': 32.20838585346184,
        '-85': 32.20849472809561,
        '-84': 32.20860648000212,
        '-83': 32.2086592693439,
        '-82': 32.20861273116914,
        '-81': 32.20847834748193,
        '-80': 32.20832333474105,
        '-79': 32.20824140424875,
        '-78': 32.20830336310354,
        '-77': 32.207786096541234,
        '-76': 32.20716388884679,
        '-75': 32.207118579502364,
        '-74': 32.2066847984599,
        '-73': 32.206552255398954,
        '-72': 32.2057899743044,
        '-71': 32.20560782253294,
        '-70': 32.206466379679355,
        '-69': 32.20671226490035,
        '-68': 32.20706188454633,
        '-67': 32.20803258565054,
        '-66': 32.2060952627482,
        '-65': 32.20554408814956,
        '-64': 32.20230887621304,
        '-63': 32.20546384679573,
        '-62': 32.209547430485024,
        '-61': 32.20961361782731,
        '-60': 32.20977700236292,
        '-59': 32.20997911976097,
        '-58': 32.210133511040524,
        '-57': 32.21017873008979,
        '-56': 32.21011490119621,
        '-55': 32.21000228396345,
        '-54': 32.20992429666993,
        '-53': 32.20993929192309,
        '-52': 32.21005118353075,
        '-51': 32.210215324578705,
        '-50': 32.21037236847495,
        '-49': 32.2104853209508,
        '-48': 32.21055517399112,
        '-47': 32.21060742753862,
        '-46': 32.21066286565519,
        '-45': 32.21071613860789,
        '-44': 32.21073832568053,
        '-43': 32.210700917202466,
        '-42': 32.210602710090676,
        '-41': 32.210479423519025,
        '-40': 32.210388886426024,
        '-39': 32.21038207922416,
        '-38': 32.21047917171942,
        '-37': 32.21066422488342,
        '-36': 32.21089777363083,
        '-35': 32.21113481521051,
        '-34': 32.21133543558577,
        '-33': 32.21146531981106,
        '-32': 32.21149422026941,
        '-31': 32.21140228480655,
        '-30': 32.21119508849868,
        '-29': 32.21091608112461,
        '-28': 32.21064124819593,
        '-27': 32.2104504671632,
        '-26': 32.21038768104387,
        '-25': 32.21043439711017,
        '-24': 32.2105171515159,
        '-23': 32.21054959205143,
        '-22': 32.21048610460391,
        '-21': 32.210353064327315,
        '-20': 32.21023496314553,
        '-19': 32.21022040578581,
        '-18': 32.21033998605712,
        '-17': 32.21053623904198,
        '-16': 32.210688419557094,
        '-15': 32.21068144610901,
        '-14': 32.21047950595044,
        '-13': 32.210159045987666,
        '-12': 32.20987781313077,
        '-11': 32.20979382777464,
        '-10': 32.2099785351872,
        '-9': 32.21037338719979,
        '-8': 32.21081524457864,
        '-7': 32.207116001564344,
        '-6': 32.2111596440739,
        '-5': 32.20698419296728,
        '-4': 32.208878094725996,
        '-3': 32.21021619624756,
        '-2': 32.20997123404841,
        '-1': 32.20987440775943
    },
    '59': {
        '0': 32.21250607236843,
        '1': 32.21242146918416,
        '2': 32.212370668665386,
        '3': 32.21238770544021,
        '4': 32.21251141528619,
        '5': 32.21274447932627,
        '6': 32.21302854825765,
        '7': 32.20214322687747,
        '8': 32.206241425372134,
        '9': 32.21053466750807,
        '10': 32.212444138734476,
        '11': 32.21258112521981,
        '12': 32.210504290092814,
        '13': 32.211790816585584,
        '14': 32.211707548199136,
        '15': 32.211072669542304,
        '16': 32.211517032182876,
        '17': 32.21158716689444,
        '18': 32.21168694267837,
        '19': 32.21146369517822,
        '20': 32.2113582488319,
        '21': 32.2114686876523,
        '22': 32.211747311163954,
        '23': 32.212064053331694,
        '24': 32.21182972461237,
        '25': 32.211549082639394,
        '26': 32.21113023899949,
        '27': 32.21143712422363,
        '28': 32.211265675355406,
        '29': 32.211068941977786,
        '30': 32.211195623819705,
        '31': 32.21149208092236,
        '32': 32.21195397159527,
        '33': 32.21164811264313,
        '34': 32.21059112294434,
        '35': 32.2111075067127,
        '36': 32.2112227922904,
        '37': 32.21121465462199,
        '38': 32.21147870069206,
        '39': 32.21103304090763,
        '40': 32.21141549774366,
        '41': 32.211574988312556,
        '42': 32.21120211950138,
        '43': 32.211290725620245,
        '44': 32.21114390966375,
        '45': 32.21116861629071,
        '46': 32.2111147535742,
        '47': 32.211327563493356,
        '48': 32.21112266159745,
        '49': 32.211896150467325,
        '50': 32.211478721491986,
        '51': 32.21124782011237,
        '52': 32.21100212511862,
        '53': 32.21032040779962,
        '54': 32.21043463457749,
        '55': 32.211510473615114,
        '56': 32.211385101938696,
        '57': 32.211095343921464,
        '58': 32.209527254283906,
        '59': 32.20760009371448,
        '60': 32.21082018372232,
        '61': 32.211502964890094,
        '62': 32.21167246017732,
        '63': 32.21106590361617,
        '64': 32.21164777454079,
        '65': 32.21153126920956,
        '66': 32.211478521705054,
        '67': 32.21195342384893,
        '68': 32.21194298355904,
        '69': 32.21190470559923,
        '70': 32.211299604917826,
        '71': 32.211233357228586,
        '72': 32.211340693925536,
        '73': 32.21112694493196,
        '74': 32.21135357257005,
        '75': 32.211024087351745,
        '76': 32.21113813405353,
        '77': 32.211198986547544,
        '78': 32.211511626030294,
        '79': 32.21122908299785,
        '80': 32.211579520589844,
        '81': 32.21143899765391,
        '82': 32.21121463601062,
        '83': 32.210839617977896,
        '84': 32.21079057235094,
        '85': 32.21081691707994,
        '86': 32.2111135090783,
        '87': 32.210894943218086,
        '88': 32.210921721590644,
        '89': 32.21022114711668,
        '90': 32.21030823275899,
        '91': 32.21044684822843,
        '92': 32.21034729694919,
        '93': 32.20972104421909,
        '94': 32.207321370495386,
        '95': 32.207864522334255,
        '96': 32.20876982221152,
        '97': 32.20782751863436,
        '98': 32.2078450612445,
        '99': 32.20946487099823,
        '100': 32.20858064711349,
        '101': 32.20949906236177,
        '102': 32.20895468792545,
        '103': 32.20922438017777,
        '104': 32.20781626842257,
        '105': 32.20539589973096,
        '106': 32.206131256537155,
        '107': 32.20754787653116,
        '108': 32.20771543484807,
        '109': 32.20837331615776,
        '110': 32.20654934566998,
        '111': 32.208645373671246,
        '112': 32.208118106065996,
        '113': 32.20686089910038,
        '114': 32.20052000532019,
        '115': 32.19857837184387,
        '116': 32.206243179434416,
        '117': 32.20727833253783,
        '118': 32.205165545739234,
        '119': 32.20896385698606,
        '120': 32.20777337533791,
        '121': 32.2069163700703,
        '122': 32.207074960929106,
        '123': 32.206376840173846,
        '124': 32.208471325663346,
        '125': 32.20832966784888,
        '126': 32.20846543680413,
        '127': 32.206850847671475,
        '128': 32.20701746988386,
        '129': 32.207580889076574,
        '130': 32.20918588912315,
        '131': 32.21079230913106,
        '132': 32.21008117025057,
        '133': 32.20895158806237,
        '134': 32.20774030246303,
        '135': 32.207435627731485,
        '136': 32.208126319815634,
        '137': 32.20666882539752,
        '138': 32.207142343831265,
        '139': 32.20591705341533,
        '140': 32.206569331390156,
        '141': 32.20936471661255,
        '142': 32.2125056744885,
        '143': 32.21238780986346,
        '144': 32.212411151000694,
        '145': 32.21256998837123,
        '146': 32.21276341916392,
        '147': 32.212896737801266,
        '148': 32.21294136802109,
        '149': 32.21294387591071,
        '150': 32.21298044920962,
        '151': 32.21309093100319,
        '152': 32.20991953643778,
        '153': 32.213333650707796,
        '154': 32.213282855223234,
        '155': 32.21307126746365,
        '156': 32.21278282336602,
        '157': 32.21256709949913,
        '158': 32.212560387315705,
        '159': 32.21280691013676,
        '160': 32.21230487640221,
        '161': 32.20578586693884,
        '162': 32.2086658176319,
        '163': 32.21353787568089,
        '164': 32.213065528896585,
        '165': 32.21291265956073,
        '166': 32.21239374177257,
        '167': 32.212031065466554,
        '168': 32.211885167028484,
        '169': 32.21191496234387,
        '170': 32.21201026771425,
        '171': 32.21205208885815,
        '172': 32.21197076324469,
        '173': 32.211775555025824,
        '174': 32.211544049481724,
        '175': 32.2113786869689,
        '176': 32.21135281624923,
        '177': 32.211473232911715,
        '178': 32.21167800057342,
        '179': 32.21187073460399,
        '180': 32.211973628075704,
        '-180': 32.211973628075704,
        '-179': 32.21197097818677,
        '-178': 32.211919050776245,
        '-177': 32.211915652875305,
        '-176': 32.212044954344364,
        '-175': 32.21232764768333,
        '-174': 32.212704632567565,
        '-173': 32.21306428681936,
        '-172': 32.2132989115431,
        '-171': 32.21335923192882,
        '-170': 32.213276850835136,
        '-169': 32.21314290296625,
        '-168': 32.21305615232858,
        '-167': 32.213070738656334,
        '-166': 32.213172863064614,
        '-165': 32.21329778579022,
        '-164': 32.21337460694517,
        '-163': 32.213370699300256,
        '-162': 32.213309385319306,
        '-161': 32.21135913239709,
        '-160': 32.21221319407241,
        '-159': 32.212507495379725,
        '-158': 32.21292456896836,
        '-157': 32.21335662647519,
        '-156': 32.21253031864377,
        '-155': 32.20818361948607,
        '-154': 32.20897708417794,
        '-153': 32.213189616710416,
        '-152': 32.213088768242564,
        '-151': 32.213046854499886,
        '-150': 32.21302983201426,
        '-149': 32.212983908446084,
        '-148': 32.212870738591036,
        '-147': 32.2126922519683,
        '-146': 32.2124897164073,
        '-145': 32.212320812025354,
        '-144': 32.21223097833899,
        '-143': 32.21223709012808,
        '-142': 32.21233078671219,
        '-141': 32.2124940307097,
        '-140': 32.21271163813452,
        '-139': 32.21297016696539,
        '-138': 32.20897533061714,
        '-137': 32.2129887884461,
        '-136': 32.20367896990917,
        '-135': 32.209740140627154,
        '-134': 32.19499349675343,
        '-133': 32.20024433106375,
        '-132': 32.203997839645254,
        '-131': 32.19849053367652,
        '-130': 32.198951219509546,
        '-129': 32.20001016236895,
        '-128': 32.19785107392188,
        '-127': 32.20277317604009,
        '-126': 32.195047106372115,
        '-125': 32.2059055631057,
        '-124': 32.206826608025374,
        '-123': 32.207118014429135,
        '-122': 32.207218752450096,
        '-121': 32.20569965549467,
        '-120': 32.208264639649215,
        '-119': 32.208686423976154,
        '-118': 32.20901208382613,
        '-117': 32.208058826228026,
        '-116': 32.20415470227191,
        '-115': 32.20299088474201,
        '-114': 32.20529639162198,
        '-113': 32.20945925882206,
        '-112': 32.209523107137734,
        '-111': 32.20904086673526,
        '-110': 32.209510836945434,
        '-109': 32.20846586480342,
        '-108': 32.20771282210184,
        '-107': 32.207537004616874,
        '-106': 32.20882381261441,
        '-105': 32.208340066595504,
        '-104': 32.2080961547918,
        '-103': 32.20744496129938,
        '-102': 32.20796970116563,
        '-101': 32.20815808163641,
        '-100': 32.2088548843512,
        '-99': 32.2083499276859,
        '-98': 32.208703825640114,
        '-97': 32.20904531631849,
        '-96': 32.20924318941733,
        '-95': 32.21045298439066,
        '-94': 32.21074094250672,
        '-93': 32.21079519181406,
        '-92': 32.21093804065202,
        '-91': 32.21111153958622,
        '-90': 32.21125886587667,
        '-89': 32.21134882266202,
        '-88': 32.21138439035335,
        '-87': 32.21139148345845,
        '-86': 32.21139557098171,
        '-85': 32.21140111689799,
        '-84': 32.21138737664445,
        '-83': 32.211324309668676,
        '-82': 32.211199638757,
        '-81': 32.21103997257887,
        '-80': 32.21091070677099,
        '-79': 32.21089078345161,
        '-78': 32.21057817825174,
        '-77': 32.2104012082586,
        '-76': 32.21009419051974,
        '-75': 32.210624044905344,
        '-74': 32.21028040472319,
        '-73': 32.2103192716372,
        '-72': 32.21001896626959,
        '-71': 32.2095270288246,
        '-70': 32.21031842783567,
        '-69': 32.21113094923271,
        '-68': 32.211389280885825,
        '-67': 32.21157586051771,
        '-66': 32.211860394505784,
        '-65': 32.20657666556259,
        '-64': 32.201333176289324,
        '-63': 32.21257641556165,
        '-62': 32.2126492290911,
        '-61': 32.212649573508564,
        '-60': 32.21260551138134,
        '-59': 32.21254717397291,
        '-58': 32.21250398435416,
        '-57': 32.21250226492876,
        '-56': 32.212558877515,
        '-55': 32.21267186012488,
        '-54': 32.2128148166842,
        '-53': 32.21294278406832,
        '-52': 32.2130113375545,
        '-51': 32.213001063569926,
        '-50': 32.21293309488752,
        '-49': 32.212863850052976,
        '-48': 32.21285841639871,
        '-47': 32.21295561905192,
        '-46': 32.213144614468725,
        '-45': 32.213367455818855,
        '-44': 32.213547082208414,
        '-43': 32.21362495815814,
        '-42': 32.21358712615784,
        '-41': 32.213465370284666,
        '-40': 32.213316085981866,
        '-39': 32.21319247548807,
        '-38': 32.213127212488395,
        '-37': 32.21313245459801,
        '-36': 32.21321022951133,
        '-35': 32.213359381362565,
        '-34': 32.213570491311934,
        '-33': 32.213813097036486,
        '-32': 32.21402950743189,
        '-31': 32.21414774715549,
        '-30': 32.21411269970534,
        '-29': 32.21391877788208,
        '-28': 32.213621692846985,
        '-27': 32.21331706739801,
        '-26': 32.21309437159793,
        '-25': 32.21299224098545,
        '-24': 32.21298288114687,
        '-23': 32.21299633176984,
        '-22': 32.212969986965135,
        '-21': 32.2128915939522,
        '-20': 32.212807020178985,
        '-19': 32.21278639325062,
        '-18': 32.212869857166396,
        '-17': 32.21302907722722,
        '-16': 32.21317235205691,
        '-15': 32.213194150189935,
        '-14': 32.213040888967484,
        '-13': 32.212752425319664,
        '-12': 32.21245147585246,
        '-11': 32.21228376400728,
        '-10': 32.21234152287444,
        '-9': 32.212613670609784,
        '-8': 32.21299079448137,
        '-7': 32.21332124778125,
        '-6': 32.21348560776595,
        '-5': 32.213447904165236,
        '-4': 32.213258051664525,
        '-3': 32.21270645397068,
        '-2': 32.212786127528545,
        '-1': 32.21262107022428
    },
    '60': {
        '0': 32.21523215728964,
        '1': 32.21508745669012,
        '2': 32.21499061404892,
        '3': 32.21501796701678,
        '4': 32.21522207948738,
        '5': 32.21558747972905,
        '6': 32.21323127546032,
        '7': 32.20529580289212,
        '8': 32.20435151421159,
        '9': 32.20692103190893,
        '10': 32.21443181539074,
        '11': 32.21386431172524,
        '12': 32.21164128539335,
        '13': 32.212304346132846,
        '14': 32.21155200364243,
        '15': 32.21180788218715,
        '16': 32.21344673142311,
        '17': 32.213665338635934,
        '18': 32.213792041607526,
        '19': 32.21396428670416,
        '20': 32.21387516710938,
        '21': 32.213999956491385,
        '22': 32.214305102203724,
        '23': 32.214663356513455,
        '24': 32.21491702844244,
        '25': 32.214959397479106,
        '26': 32.214790225579456,
        '27': 32.21451610119541,
        '28': 32.21429647964193,
        '29': 32.2142646521981,
        '30': 32.21446440126519,
        '31': 32.21452925206767,
        '32': 32.214940831480334,
        '33': 32.214898963482945,
        '34': 32.214550365529426,
        '35': 32.213219357646096,
        '36': 32.21348777433539,
        '37': 32.21360831117988,
        '38': 32.21358825777808,
        '39': 32.21370109435067,
        '40': 32.213767504823664,
        '41': 32.213670698103385,
        '42': 32.213223036804855,
        '43': 32.21438206396623,
        '44': 32.21378284309069,
        '45': 32.21370911161605,
        '46': 32.21424280865241,
        '47': 32.21359411022175,
        '48': 32.2135955513418,
        '49': 32.21398798616684,
        '50': 32.21355034156227,
        '51': 32.21365386940104,
        '52': 32.21357737461032,
        '53': 32.2137968462178,
        '54': 32.21379463671463,
        '55': 32.2141430629233,
        '56': 32.21406497998894,
        '57': 32.214257282138604,
        '58': 32.21220901162156,
        '59': 32.21078925861299,
        '60': 32.213296178990625,
        '61': 32.21408454027201,
        '62': 32.214448571574295,
        '63': 32.21426157789394,
        '64': 32.21409297330587,
        '65': 32.214182175353635,
        '66': 32.21438271085263,
        '67': 32.2142903337924,
        '68': 32.214404841367966,
        '69': 32.21445197273709,
        '70': 32.21418558064119,
        '71': 32.21376933012818,
        '72': 32.213973654571575,
        '73': 32.21365006872396,
        '74': 32.21354687160242,
        '75': 32.21363856463602,
        '76': 32.21356508998491,
        '77': 32.213566636146616,
        '78': 32.213670998617275,
        '79': 32.213979383695026,
        '80': 32.21375057017857,
        '81': 32.21354889173431,
        '82': 32.21355456385409,
        '83': 32.213362051131234,
        '84': 32.213229985707706,
        '85': 32.213496346293695,
        '86': 32.213320217519765,
        '87': 32.213383906355794,
        '88': 32.21309551230241,
        '89': 32.21331814978436,
        '90': 32.213129484295784,
        '91': 32.21075432140074,
        '92': 32.20983406014305,
        '93': 32.20962325835887,
        '94': 32.209055889037444,
        '95': 32.21014660651759,
        '96': 32.21024160206855,
        '97': 32.2096815805823,
        '98': 32.21070318654434,
        '99': 32.20994413303251,
        '100': 32.21107469682222,
        '101': 32.21171622997985,
        '102': 32.2108530028053,
        '103': 32.21098469607139,
        '104': 32.21073125082759,
        '105': 32.210991200569104,
        '106': 32.21077168946612,
        '107': 32.21114664878074,
        '108': 32.211653706902716,
        '109': 32.21058687306454,
        '110': 32.21042740107183,
        '111': 32.21012727870431,
        '112': 32.20994618068426,
        '113': 32.21009008360054,
        '114': 32.209553505429604,
        '115': 32.207264434237054,
        '116': 32.208036294825426,
        '117': 32.21235842044375,
        '118': 32.21029047238526,
        '119': 32.21188747440775,
        '120': 32.211440427950464,
        '121': 32.21036904830172,
        '122': 32.21035777884902,
        '123': 32.210895339398746,
        '124': 32.21041096666125,
        '125': 32.210030483678715,
        '126': 32.209279776694785,
        '127': 32.210400785603966,
        '128': 32.21193534145407,
        '129': 32.21154019406502,
        '130': 32.21150930633483,
        '131': 32.211638529544565,
        '132': 32.21276688495856,
        '133': 32.21269735022565,
        '134': 32.212538805762165,
        '135': 32.21204636612343,
        '136': 32.212602539012096,
        '137': 32.20929735058891,
        '138': 32.20864420991358,
        '139': 32.20703227489151,
        '140': 32.20891444739296,
        '141': 32.20714191286736,
        '142': 32.210540749930665,
        '143': 32.21338282058686,
        '144': 32.2123528946432,
        '145': 32.21172741683484,
        '146': 32.21187312955135,
        '147': 32.21191120850284,
        '148': 32.214851533932496,
        '149': 32.21478514905124,
        '150': 32.20921034982671,
        '151': 32.20847302900751,
        '152': 32.21123347203858,
        '153': 32.2142941437804,
        '154': 32.21503070065631,
        '155': 32.21569667516922,
        '156': 32.21533352636592,
        '157': 32.21500996630905,
        '158': 32.21486062942686,
        '159': 32.21495880370512,
        '160': 32.2152725832353,
        '161': 32.21567304888949,
        '162': 32.21279297953432,
        '163': 32.21095988760269,
        '164': 32.215981739153584,
        '165': 32.21537328178225,
        '166': 32.21542359032882,
        '167': 32.21525604166878,
        '168': 32.21524509277668,
        '169': 32.21532366019456,
        '170': 32.21535236640106,
        '171': 32.21519481347094,
        '172': 32.214794901974564,
        '173': 32.21421672399485,
        '174': 32.2136258386341,
        '175': 32.213219664221974,
        '176': 32.21314002789817,
        '177': 32.21341000676877,
        '178': 32.2139253591474,
        '179': 32.21450384129343,
        '180': 32.214967166777036,
        '-180': 32.214967166777036,
        '-179': 32.215214911652915,
        '-178': 32.21525521521283,
        '-177': 32.2151805823241,
        '-176': 32.21510588857297,
        '-175': 32.21510431409503,
        '-174': 32.21517583952717,
        '-173': 32.2152623620031,
        '-172': 32.21529662739166,
        '-171': 32.21525315250705,
        '-170': 32.21516970647435,
        '-169': 32.21512671860125,
        '-168': 32.21519777865791,
        '-167': 32.215209659130394,
        '-166': 32.214431971412864,
        '-165': 32.2159557476685,
        '-164': 32.21607748031846,
        '-163': 32.2160898970436,
        '-162': 32.2160230176305,
        '-161': 32.213362795676154,
        '-160': 32.20806126902388,
        '-159': 32.21071915238149,
        '-158': 32.21480443860718,
        '-157': 32.214867805479955,
        '-156': 32.21495073087683,
        '-155': 32.21183310733051,
        '-154': 32.20459559489573,
        '-153': 32.20464231476694,
        '-152': 32.21507117431305,
        '-151': 32.2082926523742,
        '-150': 32.201619562259474,
        '-149': 32.2092539251903,
        '-148': 32.21559801264521,
        '-147': 32.215808888665634,
        '-146': 32.21594493349957,
        '-145': 32.21602114315351,
        '-144': 32.2160805864717,
        '-143': 32.216169003554946,
        '-142': 32.21631407410392,
        '-141': 32.213123277292546,
        '-140': 32.209188137131605,
        '-139': 32.20200690459724,
        '-138': 32.202719637391255,
        '-137': 32.19907290066533,
        '-136': 32.201370182715415,
        '-135': 32.208887382434575,
        '-134': 32.20539053515233,
        '-133': 32.206570445352106,
        '-132': 32.20434064191734,
        '-131': 32.204168344824176,
        '-130': 32.20406809323829,
        '-129': 32.20796473008563,
        '-128': 32.20852429054264,
        '-127': 32.205983057797276,
        '-126': 32.20510452591717,
        '-125': 32.20495982334123,
        '-124': 32.21001179326838,
        '-123': 32.211057954872004,
        '-122': 32.20983059058762,
        '-121': 32.208299397110835,
        '-120': 32.210208810828384,
        '-119': 32.20892143207283,
        '-118': 32.20812875076906,
        '-117': 32.21211930966434,
        '-116': 32.212086374984764,
        '-115': 32.21169296983495,
        '-114': 32.211606461883655,
        '-113': 32.2118178893935,
        '-112': 32.21226873066198,
        '-111': 32.21144199283975,
        '-110': 32.21067532627488,
        '-109': 32.209981266096506,
        '-108': 32.20962625397771,
        '-107': 32.209403094775446,
        '-106': 32.20974038219248,
        '-105': 32.20948637819474,
        '-104': 32.21018659668352,
        '-103': 32.21027196975069,
        '-102': 32.210697571679134,
        '-101': 32.210498800603496,
        '-100': 32.211415986560574,
        '-99': 32.210987549650945,
        '-98': 32.211185047260045,
        '-97': 32.21138354153802,
        '-96': 32.211784613220615,
        '-95': 32.212924987438655,
        '-94': 32.21317654818482,
        '-93': 32.213290328082756,
        '-92': 32.21351927767899,
        '-91': 32.21378710918238,
        '-90': 32.21401273627482,
        '-89': 32.21414101857124,
        '-88': 32.214159226368736,
        '-87': 32.21409328827837,
        '-86': 32.213988309263286,
        '-85': 32.21388529156775,
        '-84': 32.21380662208183,
        '-83': 32.21375665490613,
        '-82': 32.21373433991385,
        '-81': 32.21374771758726,
        '-80': 32.213819234474094,
        '-79': 32.213976631997596,
        '-78': 32.2142332534219,
        '-77': 32.2142144587691,
        '-76': 32.213889329699704,
        '-75': 32.21338136893962,
        '-74': 32.213372649933866,
        '-73': 32.212878843214575,
        '-72': 32.21306222618501,
        '-71': 32.21267939790271,
        '-70': 32.214087602152965,
        '-69': 32.21377795670273,
        '-68': 32.21369703480504,
        '-67': 32.21388697069068,
        '-66': 32.21431287664494,
        '-65': 32.21377880672837,
        '-64': 32.215422568783104,
        '-63': 32.215828426455985,
        '-62': 32.21599880749327,
        '-61': 32.21591794205873,
        '-60': 32.21564956860759,
        '-59': 32.21531469627296,
        '-58': 32.215048291604475,
        '-57': 32.21494942066431,
        '-56': 32.21504378179772,
        '-55': 32.21527474447954,
        '-54': 32.21552859580863,
        '-53': 32.215685091796274,
        '-52': 32.21567202272354,
        '-51': 32.215498908615814,
        '-50': 32.21525321899145,
        '-49': 32.21505982073395,
        '-48': 32.21502254642313,
        '-47': 32.215176234787144,
        '-46': 32.215472621808175,
        '-45': 32.21580604342633,
        '-44': 32.21593268233115,
        '-43': 32.2161774911086,
        '-42': 32.21614109384529,
        '-41': 32.21600442786501,
        '-40': 32.21583672733939,
        '-39': 32.21569301637709,
        '-38': 32.21559914389161,
        '-37': 32.215560076993555,
        '-36': 32.215579303614106,
        '-35': 32.21567064963003,
        '-34': 32.21585111425103,
        '-33': 32.216119044067746,
        '-32': 32.216434832806755,
        '-31': 32.2167219539953,
        '-30': 32.216893513166085,
        '-29': 32.216891913717006,
        '-28': 32.21671869381067,
        '-27': 32.21643571545706,
        '-26': 32.21613557177188,
        '-25': 32.2158977472952,
        '-24': 32.21575560219452,
        '-23': 32.21569204398335,
        '-22': 32.21566348693131,
        '-21': 32.215634303172855,
        '-20': 32.215598685954156,
        '-19': 32.2155767971178,
        '-18': 32.21559028372845,
        '-17': 32.215636402536475,
        '-16': 32.2156806578653,
        '-15': 32.215674746666146,
        '-14': 32.21558868307411,
        '-13': 32.21543541853067,
        '-12': 32.21527029901247,
        '-11': 32.215163761436116,
        '-10': 32.21516317179793,
        '-9': 32.2152670471298,
        '-8': 32.21542717902709,
        '-7': 32.215576461443234,
        '-6': 32.215664215649966,
        '-5': 32.215677029267376,
        '-4': 32.215633915183325,
        '-3': 32.2155625142575,
        '-2': 32.215475854445124,
        '-1': 32.21536797616414
    },
    '61': {
        '0': 32.218024933269525,
        '1': 32.21789271002155,
        '2': 32.217775337683385,
        '3': 32.21777482556235,
        '4': 32.21796378884927,
        '5': 32.218184049121376,
        '6': 32.20745247812334,
        '7': 32.209538143842636,
        '8': 32.2048893315584,
        '9': 32.21110863620618,
        '10': 32.21413992095135,
        '11': 32.212875556320554,
        '12': 32.213038582023856,
        '13': 32.21164642389612,
        '14': 32.21353641886446,
        '15': 32.214431521846905,
        '16': 32.21336181930247,
        '17': 32.21665718651175,
        '18': 32.21697422779278,
        '19': 32.216709410902695,
        '20': 32.21655550720486,
        '21': 32.216602332948746,
        '22': 32.21650510823845,
        '23': 32.21624709315395,
        '24': 32.21615655281651,
        '25': 32.21596839905377,
        '26': 32.21656525623504,
        '27': 32.216391398833395,
        '28': 32.216389525579714,
        '29': 32.21678468491781,
        '30': 32.217100108922466,
        '31': 32.21736850339402,
        '32': 32.21768305330601,
        '33': 32.217746256500426,
        '34': 32.2173392711244,
        '35': 32.2173681650765,
        '36': 32.21736628734871,
        '37': 32.2156128838535,
        '38': 32.2151462609832,
        '39': 32.21582223338314,
        '40': 32.21568160934271,
        '41': 32.21572549279218,
        '42': 32.21671110870772,
        '43': 32.216830035102525,
        '44': 32.2161111542477,
        '45': 32.2167789181234,
        '46': 32.21587135609275,
        '47': 32.216432440488404,
        '48': 32.2158132392007,
        '49': 32.21617028530662,
        '50': 32.21646922151859,
        '51': 32.21632989765455,
        '52': 32.215978034370124,
        '53': 32.21593637872285,
        '54': 32.21623519967871,
        '55': 32.21589785365271,
        '56': 32.21593403706482,
        '57': 32.215760541692106,
        '58': 32.21567597194776,
        '59': 32.211270123755654,
        '60': 32.21588453308457,
        '61': 32.2161431159583,
        '62': 32.2170740896801,
        '63': 32.216497316329836,
        '64': 32.21628956105379,
        '65': 32.21651975169398,
        '66': 32.21655060789325,
        '67': 32.21684344251432,
        '68': 32.21695288908905,
        '69': 32.216651659150145,
        '70': 32.21674794685664,
        '71': 32.216956966187134,
        '72': 32.21673413058094,
        '73': 32.21673529052523,
        '74': 32.216580933411706,
        '75': 32.21662303598937,
        '76': 32.2168425320168,
        '77': 32.21678396038598,
        '78': 32.21662226775049,
        '79': 32.21650563156471,
        '80': 32.216204671109374,
        '81': 32.2158819770582,
        '82': 32.216057023605174,
        '83': 32.21568326551431,
        '84': 32.21605451802781,
        '85': 32.216095574837524,
        '86': 32.21630539510131,
        '87': 32.215980455604424,
        '88': 32.215620084571604,
        '89': 32.21604830102324,
        '90': 32.215542715666274,
        '91': 32.213737431806436,
        '92': 32.21334432816092,
        '93': 32.216178768647225,
        '94': 32.215693448745135,
        '95': 32.214282766330086,
        '96': 32.21134142986694,
        '97': 32.21414549493967,
        '98': 32.21198199699406,
        '99': 32.21258040953614,
        '100': 32.212207485022205,
        '101': 32.213189353876004,
        '102': 32.21325209721968,
        '103': 32.21332242899991,
        '104': 32.21296466712342,
        '105': 32.21330291098815,
        '106': 32.214162702102186,
        '107': 32.214213430811114,
        '108': 32.2142270527845,
        '109': 32.21418082302616,
        '110': 32.213459187938824,
        '111': 32.2121926822385,
        '112': 32.21184846424808,
        '113': 32.21208353929864,
        '114': 32.212339161659905,
        '115': 32.2133151319431,
        '116': 32.213178676509806,
        '117': 32.21336068114549,
        '118': 32.21283757955208,
        '119': 32.2138175801935,
        '120': 32.21357075770909,
        '121': 32.214013568435234,
        '122': 32.21303464471464,
        '123': 32.21362883414752,
        '124': 32.21367808629593,
        '125': 32.213960534176984,
        '126': 32.21675460750139,
        '127': 32.21399173714408,
        '128': 32.214669064718784,
        '129': 32.21456061882752,
        '130': 32.21494672184433,
        '131': 32.21426459027571,
        '132': 32.21607747130891,
        '133': 32.214805815321526,
        '134': 32.21526501512491,
        '135': 32.21542121624517,
        '136': 32.21315315112792,
        '137': 32.20560508221896,
        '138': 32.211560639096,
        '139': 32.201450083098194,
        '140': 32.203008100898735,
        '141': 32.20999918447046,
        '142': 32.20847218944063,
        '143': 32.2060981753337,
        '144': 32.209257853345854,
        '145': 32.20884276823496,
        '146': 32.210080184737926,
        '147': 32.20899701433991,
        '148': 32.20408483844679,
        '149': 32.211158632291685,
        '150': 32.206906363204254,
        '151': 32.20925142331661,
        '152': 32.207492352762564,
        '153': 32.20903630337254,
        '154': 32.20683931820182,
        '155': 32.20828401505593,
        '156': 32.21462070249408,
        '157': 32.21785762751653,
        '158': 32.21765740523882,
        '159': 32.21758822526126,
        '160': 32.2148008542328,
        '161': 32.21416118744574,
        '162': 32.2180158001931,
        '163': 32.218139255560125,
        '164': 32.21800081025324,
        '165': 32.21459401682003,
        '166': 32.215540655998616,
        '167': 32.21691666634143,
        '168': 32.21724385952304,
        '169': 32.2158280771132,
        '170': 32.21226752532386,
        '171': 32.21198802662286,
        '172': 32.21147245518169,
        '173': 32.2174554475055,
        '174': 32.216804707421375,
        '175': 32.21635086468146,
        '176': 32.216233173714116,
        '177': 32.21647370803542,
        '178': 32.216971326479594,
        '179': 32.217548876902505,
        '180': 32.2180299100601,
        '-180': 32.2180299100601,
        '-179': 32.21830773828749,
        '-178': 32.21837535513479,
        '-177': 32.21830599926551,
        '-176': 32.218199490444306,
        '-175': 32.218126474769576,
        '-174': 32.21809924072909,
        '-173': 32.218081730785165,
        '-172': 32.218027942072474,
        '-171': 32.21792228173023,
        '-170': 32.217796079116155,
        '-169': 32.21771052929647,
        '-168': 32.21771755449831,
        '-167': 32.2178238475887,
        '-166': 32.21798161396942,
        '-165': 32.21810318012741,
        '-164': 32.218146550260315,
        '-163': 32.21791122867043,
        '-162': 32.21793713342269,
        '-161': 32.21790713913697,
        '-160': 32.21558484726724,
        '-159': 32.21600468373451,
        '-158': 32.21613474139049,
        '-157': 32.21729765333087,
        '-156': 32.21656357449412,
        '-155': 32.21403385743544,
        '-154': 32.21426248288466,
        '-153': 32.19722312674152,
        '-152': 32.217187937576384,
        '-151': 32.21740218538309,
        '-150': 32.217610130486136,
        '-149': 32.20965061282414,
        '-148': 32.2185396199802,
        '-147': 32.219040213697156,
        '-146': 32.20682249262795,
        '-145': 32.21749289898527,
        '-144': 32.21463621642983,
        '-143': 32.20387299064552,
        '-142': 32.20134769362041,
        '-141': 32.19982410846157,
        '-140': 32.180102932012645,
        '-139': 32.198184941024614,
        '-138': 32.20484163216415,
        '-137': 32.210190739400396,
        '-136': 32.2058099101774,
        '-135': 32.210638605622066,
        '-134': 32.2038892847051,
        '-133': 32.208837239149574,
        '-132': 32.20423752557137,
        '-131': 32.203554501781866,
        '-130': 32.20179694247359,
        '-129': 32.206406760452204,
        '-128': 32.19926350874719,
        '-127': 32.20565224522658,
        '-126': 32.20961904609467,
        '-125': 32.20893029832611,
        '-124': 32.20798822692894,
        '-123': 32.21536742101561,
        '-122': 32.21287544225083,
        '-121': 32.21330811644977,
        '-120': 32.214934749130805,
        '-119': 32.214865481055185,
        '-118': 32.21520515432722,
        '-117': 32.21540596173713,
        '-116': 32.21550805433976,
        '-115': 32.21529533913652,
        '-114': 32.21514144106557,
        '-113': 32.21440878996284,
        '-112': 32.214631014826615,
        '-111': 32.21381815067333,
        '-110': 32.21368879098619,
        '-109': 32.21248580122645,
        '-108': 32.21254274219944,
        '-107': 32.212496831378,
        '-106': 32.21285921318501,
        '-105': 32.212892165096775,
        '-104': 32.212515363475596,
        '-103': 32.21294173864579,
        '-102': 32.21324181268187,
        '-101': 32.2131906232753,
        '-100': 32.21331800785514,
        '-99': 32.21349379197191,
        '-98': 32.21442874256232,
        '-97': 32.21464439303638,
        '-96': 32.214459235664286,
        '-95': 32.21559577494125,
        '-94': 32.21579390352675,
        '-93': 32.21582634916938,
        '-92': 32.21595856552176,
        '-91': 32.21613909718592,
        '-90': 32.21630481831517,
        '-89': 32.216406687277605,
        '-88': 32.21642768679062,
        '-87': 32.216385774619106,
        '-86': 32.21632207609802,
        '-85': 32.21628127162919,
        '-84': 32.21629395297857,
        '-83': 32.21636856447916,
        '-82': 32.21649488514218,
        '-81': 32.2166549672069,
        '-80': 32.21683410611618,
        '-79': 32.21702526789337,
        '-78': 32.21722473418041,
        '-77': 32.21603488331787,
        '-76': 32.21533291188011,
        '-75': 32.2146007333808,
        '-74': 32.21395258159919,
        '-73': 32.21392192511739,
        '-72': 32.21168739382753,
        '-71': 32.213656016477955,
        '-70': 32.215543682872585,
        '-69': 32.216040212863845,
        '-68': 32.215987226093745,
        '-67': 32.21623194672445,
        '-66': 32.21675078135952,
        '-65': 32.2174346792971,
        '-64': 32.21811667150838,
        '-63': 32.218623719695834,
        '-62': 32.21883552528191,
        '-61': 32.21872783659802,
        '-60': 32.2183816687955,
        '-59': 32.217952324370785,
        '-58': 32.21760871607607,
        '-57': 32.217467150907886,
        '-56': 32.21754771037844,
        '-55': 32.21777271717119,
        '-54': 32.21800841986646,
        '-53': 32.218131022751066,
        '-52': 32.21808620781289,
        '-51': 32.21791376960258,
        '-50': 32.21772548058687,
        '-49': 32.21764721151913,
        '-48': 32.21669259877436,
        '-47': 32.215322460459454,
        '-46': 32.216514365506725,
        '-45': 32.215865882339294,
        '-44': 32.20170632699983,
        '-43': 32.210705895843184,
        '-42': 32.218535773331055,
        '-41': 32.21829211800123,
        '-40': 32.21810818987506,
        '-39': 32.218032981985544,
        '-38': 32.21805460712431,
        '-37': 32.21812342015777,
        '-36': 32.21819292678281,
        '-35': 32.218252145216084,
        '-34': 32.21833009514254,
        '-33': 32.21847121382531,
        '-32': 32.21869846357477,
        '-31': 32.21898796418229,
        '-30': 32.219271381435014,
        '-29': 32.21946521881599,
        '-28': 32.21951024286155,
        '-27': 32.219398926750664,
        '-26': 32.21917656398816,
        '-25': 32.21891716555273,
        '-24': 32.21868884107166,
        '-23': 32.21852740916967,
        '-22': 32.21843014937457,
        '-21': 32.218369113656706,
        '-20': 32.21831320400981,
        '-19': 32.218245818937554,
        '-18': 32.21817029479531,
        '-17': 32.21810388400245,
        '-16': 32.21806684159795,
        '-15': 32.2180734117977,
        '-14': 32.21812752784494,
        '-13': 32.218221906283254,
        '-12': 32.21833823118407,
        '-11': 32.2184483619532,
        '-10': 32.2185190274492,
        '-9': 32.21852199204333,
        '-8': 32.218447521420295,
        '-7': 32.218314044097525,
        '-6': 32.218165727636716,
        '-5': 32.218054841166875,
        '-4': 32.21801530878289,
        '-3': 32.21804183390724,
        '-2': 32.218089134369876,
        '-1': 32.21809614074571
    },
    '62': {
        '0': 32.22067548627737,
        '1': 32.22057600772297,
        '2': 32.22044476798133,
        '3': 32.22038835062607,
        '4': 32.220494604658036,
        '5': 32.217441304052976,
        '6': 32.21570785074503,
        '7': 32.20995590360904,
        '8': 32.207834549486,
        '9': 32.208521725109804,
        '10': 32.2136566097406,
        '11': 32.21160809701028,
        '12': 32.21303201105718,
        '13': 32.213091732447694,
        '14': 32.21471200008125,
        '15': 32.216736645329874,
        '16': 32.21839993981684,
        '17': 32.219643192110176,
        '18': 32.21988231130735,
        '19': 32.219529784277086,
        '20': 32.21923242320028,
        '21': 32.219102767539965,
        '22': 32.21826935907441,
        '23': 32.21809894293179,
        '24': 32.218475912695574,
        '25': 32.2189446590071,
        '26': 32.218601885716964,
        '27': 32.219085149809175,
        '28': 32.219171750038974,
        '29': 32.21928781746435,
        '30': 32.219204404216114,
        '31': 32.2195739831461,
        '32': 32.218478121701814,
        '33': 32.219082983331084,
        '34': 32.21926691771057,
        '35': 32.21971441558123,
        '36': 32.218812644298715,
        '37': 32.21799194787985,
        '38': 32.218177836233636,
        '39': 32.21877919235227,
        '40': 32.21842373400065,
        '41': 32.2189050478918,
        '42': 32.2187619830158,
        '43': 32.219898226263744,
        '44': 32.21984970669671,
        '45': 32.22008980211338,
        '46': 32.2196481773072,
        '47': 32.218834040906906,
        '48': 32.218819258005965,
        '49': 32.21973611120036,
        '50': 32.21940339800617,
        '51': 32.21898778711392,
        '52': 32.219032219446014,
        '53': 32.21853076278229,
        '54': 32.217843687183446,
        '55': 32.21819324397308,
        '56': 32.21824039051632,
        '57': 32.21837334875921,
        '58': 32.21835009040668,
        '59': 32.21715830753759,
        '60': 32.218522199433714,
        '61': 32.21982304872765,
        '62': 32.21922078499588,
        '63': 32.21854337898763,
        '64': 32.21842272755486,
        '65': 32.219139724735385,
        '66': 32.21911631747776,
        '67': 32.21972110677156,
        '68': 32.21974750275917,
        '69': 32.21898568877668,
        '70': 32.218887476455336,
        '71': 32.21881062702318,
        '72': 32.21915534832906,
        '73': 32.218981444787346,
        '74': 32.21922933565273,
        '75': 32.21906197285588,
        '76': 32.21930929750464,
        '77': 32.219312824048856,
        '78': 32.219160119678634,
        '79': 32.21894576924579,
        '80': 32.219044271362385,
        '81': 32.21875298137258,
        '82': 32.21888134365717,
        '83': 32.21862001574067,
        '84': 32.2186954045141,
        '85': 32.21871126864714,
        '86': 32.21947207663048,
        '87': 32.21845761998061,
        '88': 32.21788324385933,
        '89': 32.21798194790656,
        '90': 32.217325741338584,
        '91': 32.21760574721607,
        '92': 32.21624036199287,
        '93': 32.21781439853416,
        '94': 32.21613617760951,
        '95': 32.21459295118823,
        '96': 32.21367883702212,
        '97': 32.215110090033065,
        '98': 32.215160566474815,
        '99': 32.21550405743945,
        '100': 32.21529087090757,
        '101': 32.215452118219325,
        '102': 32.21569787487448,
        '103': 32.2152683647458,
        '104': 32.21463714237534,
        '105': 32.215291968832396,
        '106': 32.21521084492867,
        '107': 32.21525733167617,
        '108': 32.21648480580323,
        '109': 32.21601873574568,
        '110': 32.21599619347392,
        '111': 32.21644551274668,
        '112': 32.21527317828007,
        '113': 32.21507915952671,
        '114': 32.21565107816972,
        '115': 32.215631156273055,
        '116': 32.2167953829082,
        '117': 32.21757439953533,
        '118': 32.21721709738053,
        '119': 32.21683932519814,
        '120': 32.21702049516059,
        '121': 32.21642817929547,
        '122': 32.216450885858016,
        '123': 32.21643186160989,
        '124': 32.217446391635185,
        '125': 32.21773676348798,
        '126': 32.21724724523347,
        '127': 32.217048906277604,
        '128': 32.21652180585915,
        '129': 32.21707082915359,
        '130': 32.21829044070862,
        '131': 32.21794724706684,
        '132': 32.217293826387284,
        '133': 32.21723668142572,
        '134': 32.21817447258181,
        '135': 32.21749156654626,
        '136': 32.216262241220676,
        '137': 32.21411309866288,
        '138': 32.206933299387316,
        '139': 32.20948075141545,
        '140': 32.207003303057675,
        '141': 32.19942796639859,
        '142': 32.206569367422226,
        '143': 32.19752880602681,
        '144': 32.20672862469239,
        '145': 32.20871031335592,
        '146': 32.206973933908536,
        '147': 32.212169441176805,
        '148': 32.21442151479491,
        '149': 32.212729349351896,
        '150': 32.216465603083556,
        '151': 32.21368863008195,
        '152': 32.21173200759339,
        '153': 32.21348504950391,
        '154': 32.2160524061192,
        '155': 32.21469726628995,
        '156': 32.21676331492288,
        '157': 32.213910714856965,
        '158': 32.21619023792074,
        '159': 32.220044811423975,
        '160': 32.218626992192185,
        '161': 32.21812584754215,
        '162': 32.21718281328995,
        '163': 32.21715554597006,
        '164': 32.220302481668476,
        '165': 32.220098583567236,
        '166': 32.2179345553063,
        '167': 32.21978875937744,
        '168': 32.216648191064984,
        '169': 32.21388887893229,
        '170': 32.20962215794294,
        '171': 32.20884175286409,
        '172': 32.21724122877225,
        '173': 32.215951358995056,
        '174': 32.22033378328576,
        '175': 32.21794280886134,
        '176': 32.21992275354577,
        '177': 32.219954489750755,
        '178': 32.22010957575167,
        '179': 32.22029644966072,
        '180': 32.22043065687299,
        '-180': 32.22043065687299,
        '-179': 32.220472565519735,
        '-178': 32.22043904398962,
        '-177': 32.22038510993857,
        '-176': 32.22036758270594,
        '-175': 32.220411726413126,
        '-174': 32.22049901312925,
        '-173': 32.220581345994425,
        '-172': 32.22061179877454,
        '-171': 32.22057289558456,
        '-170': 32.22048565516612,
        '-169': 32.220394700512855,
        '-168': 32.220339252542715,
        '-167': 32.22032833828097,
        '-166': 32.220335979918545,
        '-165': 32.22026936330791,
        '-164': 32.22024306444524,
        '-163': 32.2201079252978,
        '-162': 32.22001912499376,
        '-161': 32.21925826792464,
        '-160': 32.220016197829466,
        '-159': 32.21747073076758,
        '-158': 32.21848023433655,
        '-157': 32.217669329400806,
        '-156': 32.22011568639922,
        '-155': 32.21260932467183,
        '-154': 32.20666220393208,
        '-153': 32.20850093834717,
        '-152': 32.217746912812316,
        '-151': 32.21958328831788,
        '-150': 32.21983141467743,
        '-149': 32.20470695347491,
        '-148': 32.20494505350152,
        '-147': 32.213869662294954,
        '-146': 32.21551664039185,
        '-145': 32.21500971965565,
        '-144': 32.180879153649,
        '-143': 32.20681107003614,
        '-142': 32.21001102427509,
        '-141': 32.209066793721846,
        '-140': 32.21236819472514,
        '-139': 32.21259591081676,
        '-138': 32.211764483245055,
        '-137': 32.209852830856256,
        '-136': 32.21292769097285,
        '-135': 32.2122200063079,
        '-134': 32.207645450598044,
        '-133': 32.205124373560174,
        '-132': 32.21206664784107,
        '-131': 32.211855133275506,
        '-130': 32.210713008490806,
        '-129': 32.20271403253891,
        '-128': 32.201890774178565,
        '-127': 32.20269301610121,
        '-126': 32.209441165788334,
        '-125': 32.208655447799266,
        '-124': 32.21135031261938,
        '-123': 32.21737495203816,
        '-122': 32.218615396049124,
        '-121': 32.21754838283143,
        '-120': 32.213171816534036,
        '-119': 32.21219604402163,
        '-118': 32.21710191224556,
        '-117': 32.217555657208926,
        '-116': 32.21721355233194,
        '-115': 32.217205800366145,
        '-114': 32.2172206093025,
        '-113': 32.21722627393837,
        '-112': 32.21736025104119,
        '-111': 32.216079177527675,
        '-110': 32.21594236732669,
        '-109': 32.21481011085077,
        '-108': 32.21515622582256,
        '-107': 32.21593831161806,
        '-106': 32.215892618753074,
        '-105': 32.215901876703924,
        '-104': 32.215846461159835,
        '-103': 32.21608471746933,
        '-102': 32.21583670927109,
        '-101': 32.21579195982506,
        '-100': 32.215761488019446,
        '-99': 32.215678985284626,
        '-98': 32.216588896575594,
        '-97': 32.217324912560784,
        '-96': 32.218110315277016,
        '-95': 32.21777500129823,
        '-94': 32.21825549571342,
        '-93': 32.21833858582006,
        '-92': 32.21834206863164,
        '-91': 32.21840140420639,
        '-90': 32.21849557185316,
        '-89': 32.21860012747646,
        '-88': 32.21869856499951,
        '-87': 32.2187880176814,
        '-86': 32.218876982956026,
        '-85': 32.21897694015199,
        '-84': 32.21909276779045,
        '-83': 32.219217297340435,
        '-82': 32.21933303193361,
        '-81': 32.219420219335085,
        '-80': 32.219386051027186,
        '-79': 32.219476251145046,
        '-78': 32.21905967832369,
        '-77': 32.21759159767303,
        '-76': 32.21625006678737,
        '-75': 32.21639720971013,
        '-74': 32.216685278867914,
        '-73': 32.21758889542356,
        '-72': 32.21933879507803,
        '-71': 32.21917879375648,
        '-70': 32.21900303815756,
        '-69': 32.21888389455972,
        '-68': 32.218899323802866,
        '-67': 32.21910005039401,
        '-66': 32.219481742135194,
        '-65': 32.219976010646675,
        '-64': 32.22046744431367,
        '-63': 32.22083270462992,
        '-62': 32.220986899754585,
        '-61': 32.22091735456741,
        '-60': 32.22068876645621,
        '-59': 32.220415718315095,
        '-58': 32.22021358051565,
        '-57': 32.220149801380856,
        '-56': 32.22021857700292,
        '-55': 32.220351517907396,
        '-54': 32.22045953749095,
        '-53': 32.22048515838696,
        '-52': 32.22043813192696,
        '-51': 32.2203946180621,
        '-50': 32.2204582541045,
        '-49': 32.216169562649775,
        '-48': 32.207854244373806,
        '-47': 32.202107292558544,
        '-46': 32.198006916357315,
        '-45': 32.19727576091255,
        '-44': 32.19901904369282,
        '-43': 32.21061234861136,
        '-42': 32.22124265120558,
        '-41': 32.220807190633046,
        '-40': 32.22055286147221,
        '-39': 32.22052054630312,
        '-38': 32.22065269163933,
        '-37': 32.22083223404613,
        '-36': 32.22094645646692,
        '-35': 32.2209428014704,
        '-34': 32.22084956300884,
        '-33': 32.22075367732943,
        '-32': 32.220749923311566,
        '-31': 32.220889226299185,
        '-30': 32.22115214085191,
        '-29': 32.22145887851139,
        '-28': 32.221708028018774,
        '-27': 32.22182260634302,
        '-26': 32.221780595902715,
        '-25': 32.221617145094726,
        '-24': 32.221400804892134,
        '-23': 32.22119837082325,
        '-22': 32.22104648656844,
        '-21': 32.220942563888784,
        '-20': 32.22085676943405,
        '-19': 32.220756668640874,
        '-18': 32.22063115467143,
        '-17': 32.22050191079284,
        '-16': 32.2204171498859,
        '-15': 32.22043041866703,
        '-14': 32.220573612283786,
        '-13': 32.22083593397065,
        '-12': 32.22115867911039,
        '-11': 32.221450008103055,
        '-10': 32.22161599230028,
        '-9': 32.22159682002743,
        '-8': 32.221393224184766,
        '-7': 32.21891411489834,
        '-6': 32.22073467921623,
        '-5': 32.22049163604348,
        '-4': 32.220401524159996,
        '-3': 32.22045450828706,
        '-2': 32.22057801140928,
        '-1': 32.22067497168802
    },
    '63': {
        '0': 32.223133613946445,
        '1': 32.22303388569845,
        '2': 32.222862650174946,
        '3': 32.22272134573531,
        '4': 32.222705574860925,
        '5': 32.222855830569216,
        '6': 32.22313450813893,
        '7': 32.22344103639333,
        '8': 32.223658068600884,
        '9': 32.22228789247656,
        '10': 32.21787712895102,
        '11': 32.21622353513201,
        '12': 32.2156767166828,
        '13': 32.21152750163199,
        '14': 32.21704421460908,
        '15': 32.218954565335295,
        '16': 32.219364279727756,
        '17': 32.22044394848163,
        '18': 32.22125612784063,
        '19': 32.22209011394472,
        '20': 32.2216984675757,
        '21': 32.2214141690828,
        '22': 32.22104349988277,
        '23': 32.22090431711479,
        '24': 32.2204447316345,
        '25': 32.2204397479321,
        '26': 32.221423656801214,
        '27': 32.22173055091692,
        '28': 32.22202681520086,
        '29': 32.221068308380424,
        '30': 32.221686350276805,
        '31': 32.22103697399663,
        '32': 32.220893541136135,
        '33': 32.22092237734353,
        '34': 32.22113824375218,
        '35': 32.22098958126923,
        '36': 32.22094669495216,
        '37': 32.22077529486858,
        '38': 32.22088311832026,
        '39': 32.22147677848205,
        '40': 32.22195047912015,
        '41': 32.22223677178312,
        '42': 32.222520894576704,
        '43': 32.222451237427514,
        '44': 32.22104416471447,
        '45': 32.22154348617112,
        '46': 32.221577736890595,
        '47': 32.22119748278086,
        '48': 32.22209445331334,
        '49': 32.2213122689695,
        '50': 32.221666803966244,
        '51': 32.22197644411059,
        '52': 32.22121025823215,
        '53': 32.22056391501143,
        '54': 32.22033808559802,
        '55': 32.22028103299002,
        '56': 32.22099955826722,
        '57': 32.22128836402334,
        '58': 32.22039209498178,
        '59': 32.2189894844732,
        '60': 32.22110578987382,
        '61': 32.2220442175026,
        '62': 32.22229016029538,
        '63': 32.22214281785022,
        '64': 32.22224912259072,
        '65': 32.22227759772007,
        '66': 32.221017593975105,
        '67': 32.22089574852413,
        '68': 32.22175326056767,
        '69': 32.22155935578247,
        '70': 32.221440971254296,
        '71': 32.22134014026115,
        '72': 32.22130478696708,
        '73': 32.22116525425279,
        '74': 32.22116136032106,
        '75': 32.22107610283474,
        '76': 32.221131015909236,
        '77': 32.221169453195316,
        '78': 32.221838832286075,
        '79': 32.22166482700719,
        '80': 32.22112104622918,
        '81': 32.22042503118137,
        '82': 32.22053419614423,
        '83': 32.22167991220854,
        '84': 32.22031967844291,
        '85': 32.221609078014204,
        '86': 32.22141424538259,
        '87': 32.22199385163695,
        '88': 32.22223226442489,
        '89': 32.22081707865107,
        '90': 32.219966015550774,
        '91': 32.21956899490182,
        '92': 32.21866069727678,
        '93': 32.2180217432723,
        '94': 32.21903352430872,
        '95': 32.2178846511875,
        '96': 32.21741533401837,
        '97': 32.21628139810118,
        '98': 32.21719874316575,
        '99': 32.21880942445548,
        '100': 32.21959229932411,
        '101': 32.21814124673003,
        '102': 32.218415740343694,
        '103': 32.21567232532605,
        '104': 32.218572022292015,
        '105': 32.218157140909135,
        '106': 32.218539835503755,
        '107': 32.21843978602168,
        '108': 32.21811635768996,
        '109': 32.21794406735588,
        '110': 32.21852393461016,
        '111': 32.21954100562712,
        '112': 32.21864582794279,
        '113': 32.21985156654822,
        '114': 32.218570907719865,
        '115': 32.218754379868166,
        '116': 32.219271729570906,
        '117': 32.22014855326841,
        '118': 32.2197299255648,
        '119': 32.21943559233994,
        '120': 32.219546776850784,
        '121': 32.21974061695747,
        '122': 32.219796162721224,
        '123': 32.21959871315485,
        '124': 32.219278534326676,
        '125': 32.219298966125955,
        '126': 32.21974561469159,
        '127': 32.21908470633007,
        '128': 32.21939970044189,
        '129': 32.21942682186306,
        '130': 32.22014879448988,
        '131': 32.21974959289541,
        '132': 32.21977126170151,
        '133': 32.220348480917856,
        '134': 32.220630261416005,
        '135': 32.22049920437966,
        '136': 32.220009753229675,
        '137': 32.21794472359932,
        '138': 32.21087681316886,
        '139': 32.20601842614683,
        '140': 32.204000694263904,
        '141': 32.20986858864172,
        '142': 32.21350510193932,
        '143': 32.214003816802055,
        '144': 32.21294894556869,
        '145': 32.21122556776098,
        '146': 32.215229195639125,
        '147': 32.213651123656916,
        '148': 32.21491264729507,
        '149': 32.21390198946501,
        '150': 32.218708830917926,
        '151': 32.2181294991262,
        '152': 32.21971155400222,
        '153': 32.21877154354596,
        '154': 32.2201422363777,
        '155': 32.216867739463964,
        '156': 32.21840741039641,
        '157': 32.21682968048317,
        '158': 32.21457877394809,
        '159': 32.21100486082093,
        '160': 32.218020678963406,
        '161': 32.22069103104348,
        '162': 32.21834957564738,
        '163': 32.219549142871934,
        '164': 32.21837618274172,
        '165': 32.219788287668784,
        '166': 32.22192318919431,
        '167': 32.222396967115806,
        '168': 32.2218175899575,
        '169': 32.21980707769061,
        '170': 32.21904284596038,
        '171': 32.21977524237092,
        '172': 32.21972632255861,
        '173': 32.21883374679455,
        '174': 32.21726606520137,
        '175': 32.2210403874808,
        '176': 32.21793544335839,
        '177': 32.21889888482045,
        '178': 32.2198127316729,
        '179': 32.219332272481296,
        '180': 32.22248888241016,
        '-180': 32.22248888241016,
        '-179': 32.22234266186425,
        '-178': 32.22223678450657,
        '-177': 32.22220309382737,
        '-176': 32.222253341366745,
        '-175': 32.22236967423242,
        '-174': 32.22250965671088,
        '-173': 32.222624423048984,
        '-172': 32.22268102734668,
        '-171': 32.22267720295401,
        '-170': 32.22264043173377,
        '-169': 32.222611620169864,
        '-168': 32.22262218278684,
        '-167': 32.222677126191186,
        '-166': 32.222753518928414,
        '-165': 32.222815223103346,
        '-164': 32.22282544912568,
        '-163': 32.22245075972853,
        '-162': 32.219738352484704,
        '-161': 32.220256467962734,
        '-160': 32.22054916534699,
        '-159': 32.22270513021452,
        '-158': 32.22295975153891,
        '-157': 32.21949713842902,
        '-156': 32.22226839037551,
        '-155': 32.221970168738885,
        '-154': 32.22192125786122,
        '-153': 32.21963198524196,
        '-152': 32.21525443865487,
        '-151': 32.194866726738255,
        '-150': 32.21673353540151,
        '-149': 32.20917478036643,
        '-148': 32.215149791167356,
        '-147': 32.215940516784144,
        '-146': 32.21271910541115,
        '-145': 32.21394654807914,
        '-144': 32.211646980858184,
        '-143': 32.21120041644838,
        '-142': 32.218591991033016,
        '-141': 32.21633167730896,
        '-140': 32.2168642747441,
        '-139': 32.21343452931029,
        '-138': 32.21570151953409,
        '-137': 32.213614650584226,
        '-136': 32.210453953725086,
        '-135': 32.214164858383846,
        '-134': 32.215208932436695,
        '-133': 32.21189146978149,
        '-132': 32.210682013446544,
        '-131': 32.21210005253814,
        '-130': 32.21038667948635,
        '-129': 32.20915717518249,
        '-128': 32.20551838614926,
        '-127': 32.20655415413776,
        '-126': 32.20888768658069,
        '-125': 32.21726109199651,
        '-124': 32.21999911161565,
        '-123': 32.21722397945912,
        '-122': 32.21922732860967,
        '-121': 32.21983102621781,
        '-120': 32.220304810041256,
        '-119': 32.219976377964805,
        '-118': 32.21941544115838,
        '-117': 32.21955160125612,
        '-116': 32.22022102501178,
        '-115': 32.21947087708794,
        '-114': 32.21850599596363,
        '-113': 32.21844710225833,
        '-112': 32.21741850646208,
        '-111': 32.21640285048852,
        '-110': 32.2185983147861,
        '-109': 32.218054455789556,
        '-108': 32.21836721136505,
        '-107': 32.21826348320362,
        '-106': 32.218679281051045,
        '-105': 32.2192339819927,
        '-104': 32.218575687001064,
        '-103': 32.21837267306029,
        '-102': 32.21885026300403,
        '-101': 32.21869869081801,
        '-100': 32.2178943942796,
        '-99': 32.217907799106214,
        '-98': 32.21950401160209,
        '-97': 32.219951595070654,
        '-96': 32.220022082847535,
        '-95': 32.219631166234635,
        '-94': 32.22005638886977,
        '-93': 32.22052437552751,
        '-92': 32.220620228695914,
        '-91': 32.22106775066224,
        '-90': 32.22118557235573,
        '-89': 32.22127322935368,
        '-88': 32.221380529044104,
        '-87': 32.22149409924622,
        '-86': 32.221601361104305,
        '-85': 32.22169139760892,
        '-84': 32.22175406432386,
        '-83': 32.22177929985946,
        '-82': 32.22175857389291,
        '-81': 32.22168892935835,
        '-80': 32.221578035632525,
        '-79': 32.22144722945702,
        '-78': 32.22132956823636,
        '-77': 32.22126187841659,
        '-76': 32.22127278141195,
        '-75': 32.221371314356894,
        '-74': 32.221541519287385,
        '-73': 32.22174650356883,
        '-72': 32.22194149767472,
        '-71': 32.22201004269875,
        '-70': 32.219622157401545,
        '-69': 32.215724619239545,
        '-68': 32.215694032107706,
        '-67': 32.22233761628782,
        '-66': 32.2202531187152,
        '-65': 32.21928916867268,
        '-64': 32.22275517870454,
        '-63': 32.222882569214214,
        '-62': 32.222956135638356,
        '-61': 32.22297735993156,
        '-60': 32.22297199056689,
        '-59': 32.2229738501194,
        '-58': 32.2230024578736,
        '-57': 32.223047637799304,
        '-56': 32.223071951956385,
        '-55': 32.2230328160001,
        '-54': 32.22291469940103,
        '-53': 32.22275401666481,
        '-52': 32.222640090686596,
        '-51': 32.222685757876796,
        '-50': 32.21804130232554,
        '-49': 32.20852951122647,
        '-48': 32.202910869926875,
        '-47': 32.19971836003131,
        '-46': 32.1981731772744,
        '-45': 32.19680760003047,
        '-44': 32.200440301193176,
        '-43': 32.2153862152927,
        '-42': 32.22191902927049,
        '-41': 32.22328961292652,
        '-40': 32.22294888086104,
        '-39': 32.222910278155474,
        '-38': 32.2230928939105,
        '-37': 32.22334136352553,
        '-36': 32.223500623151516,
        '-35': 32.223484362960654,
        '-34': 32.22330714833027,
        '-33': 32.2230684027572,
        '-32': 32.22289910299721,
        '-31': 32.22289828231652,
        '-30': 32.22308905146937,
        '-29': 32.22341250367989,
        '-28': 32.22375890963822,
        '-27': 32.22401854730005,
        '-26': 32.22412719538727,
        '-25': 32.22408627797679,
        '-24': 32.22395138640135,
        '-23': 32.22379817338608,
        '-22': 32.22368418512614,
        '-21': 32.22362519565247,
        '-20': 32.22359593460637,
        '-19': 32.22355253074514,
        '-18': 32.22346363733825,
        '-17': 32.22333364704279,
        '-16': 32.22320583983855,
        '-15': 32.22314333596929,
        '-14': 32.223196635708966,
        '-13': 32.22337338828741,
        '-12': 32.2236257308952,
        '-11': 32.22386305369121,
        '-10': 32.22398650861112,
        '-9': 32.22393105258318,
        '-8': 32.22369619520845,
        '-7': 32.223350449467034,
        '-6': 32.2230056136951,
        '-5': 32.22277079520459,
        '-4': 32.22270619489235,
        '-3': 32.22279795696216,
        '-2': 32.22296652994299,
        '-1': 32.2231055711451
    },
    '64': {
        '0': 32.225604996741495,
        '1': 32.22546063449693,
        '2': 32.225221999047534,
        '3': 32.2249841452102,
        '4': 32.22484384762993,
        '5': 32.22485636815653,
        '6': 32.22501121451227,
        '7': 32.22523797807442,
        '8': 32.22543915902338,
        '9': 32.225534230101715,
        '10': 32.22549417097074,
        '11': 32.22132370928073,
        '12': 32.222191940520446,
        '13': 32.21710693389711,
        '14': 32.22044938971003,
        '15': 32.22037298168008,
        '16': 32.221226663250164,
        '17': 32.22115321768993,
        '18': 32.22104919679137,
        '19': 32.22163944783438,
        '20': 32.22156086029156,
        '21': 32.22390805969979,
        '22': 32.22369189265915,
        '23': 32.223657022507574,
        '24': 32.22310739695253,
        '25': 32.22309105562153,
        '26': 32.2231840553399,
        '27': 32.22291244972933,
        '28': 32.22305490071565,
        '29': 32.223403743336576,
        '30': 32.22345660676268,
        '31': 32.22322955950228,
        '32': 32.22352579154483,
        '33': 32.22370244109419,
        '34': 32.22395345633468,
        '35': 32.224276853100385,
        '36': 32.22488512656724,
        '37': 32.22533756535074,
        '38': 32.225490962640635,
        '39': 32.22384527604021,
        '40': 32.22466967512041,
        '41': 32.224884490850414,
        '42': 32.224899262406055,
        '43': 32.22396742614946,
        '44': 32.22420197025886,
        '45': 32.22353784714791,
        '46': 32.22305495822683,
        '47': 32.224443225494284,
        '48': 32.22343916337106,
        '49': 32.22389765884005,
        '50': 32.22344770412975,
        '51': 32.22349540536405,
        '52': 32.22323613487435,
        '53': 32.22307642434416,
        '54': 32.22342255562275,
        '55': 32.223427844362455,
        '56': 32.22398192046449,
        '57': 32.22434288469567,
        '58': 32.22349850618841,
        '59': 32.22383571863781,
        '60': 32.22302985152091,
        '61': 32.22509388078545,
        '62': 32.22423035258677,
        '63': 32.223302430451206,
        '64': 32.224408874017385,
        '65': 32.22457306206324,
        '66': 32.22461897024703,
        '67': 32.22386151829654,
        '68': 32.22388294648257,
        '69': 32.223988399175745,
        '70': 32.22396413347391,
        '71': 32.223870518954506,
        '72': 32.22359933857465,
        '73': 32.22376320007047,
        '74': 32.22323824716759,
        '75': 32.22365051506945,
        '76': 32.224122341954015,
        '77': 32.224115722202825,
        '78': 32.22441993409893,
        '79': 32.22433617096055,
        '80': 32.22411670316379,
        '81': 32.224069726451134,
        '82': 32.2241838933243,
        '83': 32.223900064829515,
        '84': 32.22419323817853,
        '85': 32.22385175311992,
        '86': 32.2237957757563,
        '87': 32.22433922831453,
        '88': 32.224006087834326,
        '89': 32.22132773635778,
        '90': 32.22081109885268,
        '91': 32.219884059120545,
        '92': 32.22017734964799,
        '93': 32.22130037450643,
        '94': 32.222130982333894,
        '95': 32.21985495215644,
        '96': 32.220105385042,
        '97': 32.218014201578626,
        '98': 32.2183494618117,
        '99': 32.22065124178521,
        '100': 32.222165835908896,
        '101': 32.218757156406745,
        '102': 32.221856150124836,
        '103': 32.2217284583703,
        '104': 32.2194792303402,
        '105': 32.219548818240426,
        '106': 32.22093280582945,
        '107': 32.220947320624305,
        '108': 32.220572431942585,
        '109': 32.22109883916225,
        '110': 32.221185074386604,
        '111': 32.220747480628695,
        '112': 32.22020247359274,
        '113': 32.22143196643368,
        '114': 32.22065646372619,
        '115': 32.22087443954452,
        '116': 32.22129166323905,
        '117': 32.22228056895984,
        '118': 32.221763097378364,
        '119': 32.222168313177356,
        '120': 32.22224989511443,
        '121': 32.222449112345174,
        '122': 32.22243887580687,
        '123': 32.22252034244552,
        '124': 32.222993348882895,
        '125': 32.22265658454187,
        '126': 32.2230359657858,
        '127': 32.22328266533343,
        '128': 32.22130961573187,
        '129': 32.21892102547017,
        '130': 32.220998419541246,
        '131': 32.21842463393,
        '132': 32.2183184158914,
        '133': 32.21303961197185,
        '134': 32.2171534326049,
        '135': 32.21477280709316,
        '136': 32.217693587704986,
        '137': 32.21768954120622,
        '138': 32.21417180851567,
        '139': 32.214173682807385,
        '140': 32.21477743172969,
        '141': 32.214134596751336,
        '142': 32.213894183821914,
        '143': 32.218169378707564,
        '144': 32.20998784131896,
        '145': 32.21425182366721,
        '146': 32.214299691885046,
        '147': 32.21204703346708,
        '148': 32.21630782823261,
        '149': 32.21632765280696,
        '150': 32.218104275652,
        '151': 32.22275749906761,
        '152': 32.22295500680612,
        '153': 32.21951721467643,
        '154': 32.22161891515982,
        '155': 32.21954769250532,
        '156': 32.22203318695447,
        '157': 32.220043409664825,
        '158': 32.21498447843909,
        '159': 32.215327516149294,
        '160': 32.21708980562947,
        '161': 32.22132728396991,
        '162': 32.21695609562831,
        '163': 32.218053124461726,
        '164': 32.21784536741436,
        '165': 32.21915303317045,
        '166': 32.21962386424013,
        '167': 32.222090249733064,
        '168': 32.223716121162894,
        '169': 32.22142820118362,
        '170': 32.22421063305877,
        '171': 32.224472810743634,
        '172': 32.22332466810393,
        '173': 32.21973307686404,
        '174': 32.22033767586048,
        '175': 32.2214684854541,
        '176': 32.22514476614429,
        '177': 32.22475102520186,
        '178': 32.224897338647374,
        '179': 32.22488362851658,
        '180': 32.22476841499464,
        '-180': 32.22476841499464,
        '-179': 32.224692876895304,
        '-178': 32.224678116724824,
        '-177': 32.224727104025106,
        '-176': 32.22482093254253,
        '-175': 32.22492367565158,
        '-174': 32.22499523255793,
        '-173': 32.22500761874465,
        '-172': 32.2249577398715,
        '-171': 32.22487049220413,
        '-170': 32.2247899633993,
        '-169': 32.224761875682375,
        '-168': 32.22481469765807,
        '-167': 32.22494773294906,
        '-166': 32.22513142707705,
        '-165': 32.225319518644845,
        '-164': 32.225467156179306,
        '-163': 32.225546985310615,
        '-162': 32.22555643060085,
        '-161': 32.22551416101753,
        '-160': 32.22498342424616,
        '-159': 32.22498293222907,
        '-158': 32.22482134800803,
        '-157': 32.22378760230414,
        '-156': 32.224645914278504,
        '-155': 32.22214731410928,
        '-154': 32.22428808491656,
        '-153': 32.221002535540826,
        '-152': 32.22299936247129,
        '-151': 32.2242400483817,
        '-150': 32.221769596040524,
        '-149': 32.22018333550187,
        '-148': 32.212901819796485,
        '-147': 32.214971640399035,
        '-146': 32.22192404579587,
        '-145': 32.22250417613237,
        '-144': 32.21534018558397,
        '-143': 32.21565871627443,
        '-142': 32.22037909950664,
        '-141': 32.214476419909296,
        '-140': 32.21662013030841,
        '-139': 32.219322041314086,
        '-138': 32.21935147667038,
        '-137': 32.213354880148174,
        '-136': 32.21656905730606,
        '-135': 32.21911710551946,
        '-134': 32.21690402004125,
        '-133': 32.218527530881204,
        '-132': 32.21291803630552,
        '-131': 32.21149479142689,
        '-130': 32.212483004560774,
        '-129': 32.21023505961136,
        '-128': 32.219343580847365,
        '-127': 32.21105280739163,
        '-126': 32.21919992294397,
        '-125': 32.22215217219286,
        '-124': 32.22201011851353,
        '-123': 32.22186887269555,
        '-122': 32.22188914617193,
        '-121': 32.222221007750356,
        '-120': 32.221290423358376,
        '-119': 32.22140508646023,
        '-118': 32.22148572367997,
        '-117': 32.22250255152588,
        '-116': 32.22230018453467,
        '-115': 32.22173992104988,
        '-114': 32.221276091898154,
        '-113': 32.22034734353065,
        '-112': 32.22020710785858,
        '-111': 32.21890534380265,
        '-110': 32.21980290523833,
        '-109': 32.220744850862424,
        '-108': 32.22061750866257,
        '-107': 32.22062755268339,
        '-106': 32.2204809309067,
        '-105': 32.22073977975989,
        '-104': 32.222288586415196,
        '-103': 32.22219569950993,
        '-102': 32.221505886723435,
        '-101': 32.221813509210776,
        '-100': 32.22199391290462,
        '-99': 32.221861986299814,
        '-98': 32.22221335731969,
        '-97': 32.22196155248206,
        '-96': 32.22234063843491,
        '-95': 32.2236801526493,
        '-94': 32.2234511546068,
        '-93': 32.22406380642861,
        '-92': 32.22365071061469,
        '-91': 32.22350136438875,
        '-90': 32.22423137278154,
        '-89': 32.22421919063252,
        '-88': 32.224178801159965,
        '-87': 32.22412372255405,
        '-86': 32.22389486618186,
        '-85': 32.22339449040334,
        '-84': 32.223476447817646,
        '-83': 32.223800163306976,
        '-82': 32.22373075855084,
        '-81': 32.22366482510928,
        '-80': 32.223595953186994,
        '-79': 32.223542189820684,
        '-78': 32.22350671690034,
        '-77': 32.22353670852094,
        '-76': 32.223617236882184,
        '-75': 32.223765825533626,
        '-74': 32.223976529443576,
        '-73': 32.22422864098768,
        '-72': 32.22336693795006,
        '-71': 32.221153676996785,
        '-70': 32.220921512088005,
        '-69': 32.222767561762105,
        '-68': 32.21945332835312,
        '-67': 32.21851547242245,
        '-66': 32.216595034915265,
        '-65': 32.22277263354758,
        '-64': 32.22495039535437,
        '-63': 32.224963081816206,
        '-62': 32.225034765293714,
        '-61': 32.22517369186401,
        '-60': 32.225371123148314,
        '-59': 32.22559515946796,
        '-58': 32.22579111912091,
        '-57': 32.22589302188654,
        '-56': 32.22584626342838,
        '-55': 32.2256350729776,
        '-54': 32.22530307374515,
        '-53': 32.22495463094107,
        '-52': 32.22473035203358,
        '-51': 32.2175154315158,
        '-50': 32.218705279547116,
        '-49': 32.2112232837604,
        '-48': 32.206042258607745,
        '-47': 32.20371823660165,
        '-46': 32.20191521575332,
        '-45': 32.20027939606806,
        '-44': 32.19946824975886,
        '-43': 32.201902091699374,
        '-42': 32.21287129560975,
        '-41': 32.21780768817826,
        '-40': 32.22492568400618,
        '-39': 32.22489566185018,
        '-38': 32.225122228162355,
        '-37': 32.22545168464928,
        '-36': 32.225722039016944,
        '-35': 32.22582913633748,
        '-34': 32.225762154286606,
        '-33': 32.22559608181872,
        '-32': 32.225448193204606,
        '-31': 32.225420617908526,
        '-30': 32.225555419874844,
        '-29': 32.22582086773968,
        '-28': 32.226132073019855,
        '-27': 32.22639346115189,
        '-26': 32.226541778196854,
        '-25': 32.22656997942798,
        '-24': 32.22652282389961,
        '-23': 32.22646882926308,
        '-22': 32.22528971273434,
        '-21': 32.22319396578577,
        '-20': 32.22554836917624,
        '-19': 32.22043325867549,
        '-18': 32.22203118033035,
        '-17': 32.22572554665082,
        '-16': 32.22647370329305,
        '-15': 32.22630237971738,
        '-14': 32.226169480267934,
        '-13': 32.22610689290028,
        '-12': 32.22610452314768,
        '-11': 32.22611432813251,
        '-10': 32.226074305871215,
        '-9': 32.2259412202393,
        '-8': 32.22571606078639,
        '-7': 32.22544905592019,
        '-6': 32.22522041705486,
        '-5': 32.225104659987856,
        '-4': 32.22513480217022,
        '-3': 32.225283719762054,
        '-2': 32.225472612355354,
        '-1': 32.225604019927665
    },
    '65': {
        '0': 32.22814594247158,
        '1': 32.22795907364592,
        '2': 32.2276776642301,
        '3': 32.2273879413495,
        '4': 32.22718029250272,
        '5': 32.22711353932368,
        '6': 32.22719221871915,
        '7': 32.22736654256079,
        '8': 32.22755477664224,
        '9': 32.227678010077184,
        '10': 32.227692094862014,
        '11': 32.227603182497646,
        '12': 32.22580174839204,
        '13': 32.222022942521974,
        '14': 32.22126581756563,
        '15': 32.21847854110159,
        '16': 32.222582937651545,
        '17': 32.223224310533574,
        '18': 32.22410032935002,
        '19': 32.22422813078723,
        '20': 32.224750569959596,
        '21': 32.22601246437123,
        '22': 32.226565243137784,
        '23': 32.22635018167215,
        '24': 32.226257519066166,
        '25': 32.22630133245541,
        '26': 32.22566910871339,
        '27': 32.2254932929619,
        '28': 32.22471479064761,
        '29': 32.22495652581643,
        '30': 32.22499340099625,
        '31': 32.22534798890302,
        '32': 32.22612933354482,
        '33': 32.22657434293518,
        '34': 32.22700504797279,
        '35': 32.22753096802109,
        '36': 32.227679547786884,
        '37': 32.22683531250319,
        '38': 32.22789752862155,
        '39': 32.22788330169849,
        '40': 32.22776252762598,
        '41': 32.226271436649675,
        '42': 32.22612298199411,
        '43': 32.22672433370706,
        '44': 32.226576239922046,
        '45': 32.22622396212616,
        '46': 32.226697224223,
        '47': 32.22597443682594,
        '48': 32.225482760153376,
        '49': 32.22540301997792,
        '50': 32.22483519765268,
        '51': 32.22531224942813,
        '52': 32.22601915712991,
        '53': 32.225896545027474,
        '54': 32.22635086158186,
        '55': 32.22585609151495,
        '56': 32.226537685310475,
        '57': 32.22664571111801,
        '58': 32.22658089108991,
        '59': 32.22345317806749,
        '60': 32.21758022784642,
        '61': 32.21965388006725,
        '62': 32.22690975957929,
        '63': 32.226077396864355,
        '64': 32.226434158079776,
        '65': 32.227091603129004,
        '66': 32.22723232940265,
        '67': 32.226795204991426,
        '68': 32.22694298630074,
        '69': 32.226682425526064,
        '70': 32.22650368795574,
        '71': 32.22644364896132,
        '72': 32.22635790248676,
        '73': 32.22642762886877,
        '74': 32.22639701707259,
        '75': 32.22601990348149,
        '76': 32.2261419235327,
        '77': 32.22667999281443,
        '78': 32.226908553302366,
        '79': 32.226728478130575,
        '80': 32.226797406116454,
        '81': 32.226854923497534,
        '82': 32.22675467199159,
        '83': 32.22662145333113,
        '84': 32.22594889399904,
        '85': 32.226353291499095,
        '86': 32.22654334237087,
        '87': 32.22663627536148,
        '88': 32.22596707135231,
        '89': 32.22297895931201,
        '90': 32.222302856528344,
        '91': 32.224069215309676,
        '92': 32.22147521484432,
        '93': 32.222153097338534,
        '94': 32.22343416109017,
        '95': 32.22317454301135,
        '96': 32.220679585829984,
        '97': 32.21984064354067,
        '98': 32.21971722260712,
        '99': 32.223108822397776,
        '100': 32.222395545965554,
        '101': 32.221215643430284,
        '102': 32.22222575605722,
        '103': 32.22170143979804,
        '104': 32.220933280141956,
        '105': 32.2215764741303,
        '106': 32.221131669562645,
        '107': 32.22275687502936,
        '108': 32.22259223659534,
        '109': 32.22240808459012,
        '110': 32.22349398992892,
        '111': 32.22207636933403,
        '112': 32.223767193873464,
        '113': 32.221904798262386,
        '114': 32.22357051281548,
        '115': 32.22369459532446,
        '116': 32.223681058986514,
        '117': 32.2241820800357,
        '118': 32.22458454730527,
        '119': 32.22469087986043,
        '120': 32.22493829463329,
        '121': 32.22462285341517,
        '122': 32.223875608988706,
        '123': 32.2248968612289,
        '124': 32.225214956167925,
        '125': 32.22586486606324,
        '126': 32.224194452212565,
        '127': 32.22238774920887,
        '128': 32.21846882729502,
        '129': 32.21464238429463,
        '130': 32.21455816255372,
        '131': 32.216837121059264,
        '132': 32.21709530403206,
        '133': 32.21994315717617,
        '134': 32.21650275521324,
        '135': 32.21986637431645,
        '136': 32.22055119141866,
        '137': 32.21751060100836,
        '138': 32.21672150510857,
        '139': 32.21644830071548,
        '140': 32.21610185955203,
        '141': 32.21660427609699,
        '142': 32.21341937189822,
        '143': 32.2198830732773,
        '144': 32.213548034956084,
        '145': 32.21254102455927,
        '146': 32.21023739461797,
        '147': 32.2214414377568,
        '148': 32.210180686882914,
        '149': 32.2190878480442,
        '150': 32.22518601949812,
        '151': 32.22640311463063,
        '152': 32.226369304870296,
        '153': 32.222819398150975,
        '154': 32.22155300606741,
        '155': 32.224523808373206,
        '156': 32.225028189916834,
        '157': 32.223756198282985,
        '158': 32.22278254690435,
        '159': 32.22429849132995,
        '160': 32.223136157483474,
        '161': 32.220914703295705,
        '162': 32.22118859922149,
        '163': 32.219082369296515,
        '164': 32.22174384958236,
        '165': 32.22125917419658,
        '166': 32.22181279252949,
        '167': 32.225077686828755,
        '168': 32.22352241227227,
        '169': 32.22223624905405,
        '170': 32.22672206720387,
        '171': 32.22723916267763,
        '172': 32.22708106712272,
        '173': 32.22564130846227,
        '174': 32.227030358188415,
        '175': 32.22647837536086,
        '176': 32.22695948706397,
        '177': 32.22685154746453,
        '178': 32.22699099764427,
        '179': 32.22557540969276,
        '180': 32.22717202152735,
        '-180': 32.22717202152735,
        '-179': 32.22723215539185,
        '-178': 32.227327812495616,
        '-177': 32.22744833959279,
        '-176': 32.22756915641649,
        '-175': 32.222617103968275,
        '-174': 32.22478334260365,
        '-173': 32.22192800204554,
        '-172': 32.22754220556602,
        '-171': 32.227402562027734,
        '-170': 32.22726983356544,
        '-169': 32.22718458292939,
        '-168': 32.22717351062799,
        '-167': 32.227241036879775,
        '-166': 32.22512226098293,
        '-165': 32.22210684754235,
        '-164': 32.225541540665375,
        '-163': 32.22734868726791,
        '-162': 32.22616177890878,
        '-161': 32.22738504853957,
        '-160': 32.22232118114004,
        '-159': 32.221886543438984,
        '-158': 32.224775588022396,
        '-157': 32.22685278311141,
        '-156': 32.22390679352449,
        '-155': 32.2210465560838,
        '-154': 32.22689148973941,
        '-153': 32.22558528947139,
        '-152': 32.22657624075764,
        '-151': 32.2255727305498,
        '-150': 32.22537074942716,
        '-149': 32.227004578172284,
        '-148': 32.223965386055774,
        '-147': 32.22568673325335,
        '-146': 32.22243642135947,
        '-145': 32.216088796255264,
        '-144': 32.217948491665005,
        '-143': 32.21456708637317,
        '-142': 32.222222253656824,
        '-141': 32.224353471511755,
        '-140': 32.21870224725267,
        '-139': 32.21751692404282,
        '-138': 32.21478055274638,
        '-137': 32.21627586101074,
        '-136': 32.216278952317765,
        '-135': 32.21873330372017,
        '-134': 32.21465787123269,
        '-133': 32.21324047435737,
        '-132': 32.21241441642003,
        '-131': 32.21463686108751,
        '-130': 32.21502767776104,
        '-129': 32.220223022979944,
        '-128': 32.21223026544197,
        '-127': 32.22471138642432,
        '-126': 32.22583402706741,
        '-125': 32.226098520969444,
        '-124': 32.22516464245544,
        '-123': 32.22497195247713,
        '-122': 32.22498431023332,
        '-121': 32.22497509182616,
        '-120': 32.22329716407715,
        '-119': 32.22408513220577,
        '-118': 32.22500213249251,
        '-117': 32.22408518048231,
        '-116': 32.2242886784134,
        '-115': 32.22219072646856,
        '-114': 32.22271407080501,
        '-113': 32.22280338681524,
        '-112': 32.22299692473275,
        '-111': 32.222497240154645,
        '-110': 32.2222285654509,
        '-109': 32.22253992906345,
        '-108': 32.222640800584784,
        '-107': 32.223320338560555,
        '-106': 32.223496262717276,
        '-105': 32.22408974173838,
        '-104': 32.22366266949745,
        '-103': 32.224526698711585,
        '-102': 32.22414375403254,
        '-101': 32.2241418544193,
        '-100': 32.223535921820755,
        '-99': 32.22424449666048,
        '-98': 32.22422660578016,
        '-97': 32.224793177903905,
        '-96': 32.2246894360077,
        '-95': 32.22489552696319,
        '-94': 32.22483577675293,
        '-93': 32.22528473791488,
        '-92': 32.22468202124294,
        '-91': 32.22513953865922,
        '-90': 32.22386564513665,
        '-89': 32.22422722505343,
        '-88': 32.22506342483085,
        '-87': 32.22643225239455,
        '-86': 32.226085662093226,
        '-85': 32.22583012121068,
        '-84': 32.22285338796745,
        '-83': 32.22586085298329,
        '-82': 32.225851797554995,
        '-81': 32.225887684289695,
        '-80': 32.2259486048292,
        '-79': 32.22601293823182,
        '-78': 32.226053869604264,
        '-77': 32.22508350557953,
        '-76': 32.225282557576115,
        '-75': 32.22605924077993,
        '-74': 32.225763395635326,
        '-73': 32.22517963064907,
        '-72': 32.224819572170325,
        '-71': 32.225374136242806,
        '-70': 32.22445732159437,
        '-69': 32.2230249634879,
        '-68': 32.222681818056046,
        '-67': 32.22242182432897,
        '-66': 32.22705134095028,
        '-65': 32.22704896739778,
        '-64': 32.22705263263391,
        '-63': 32.22710331634804,
        '-62': 32.22723520697221,
        '-61': 32.22746109614665,
        '-60': 32.22776157251075,
        '-59': 32.228082869773324,
        '-58': 32.228347006823284,
        '-57': 32.22847393755688,
        '-56': 32.22841038293242,
        '-55': 32.228155742960794,
        '-54': 32.227774129870845,
        '-53': 32.227384591857685,
        '-52': 32.225359964558194,
        '-51': 32.2231530285683,
        '-50': 32.21867791750387,
        '-49': 32.21203439861032,
        '-48': 32.208662534048116,
        '-47': 32.206196027896716,
        '-46': 32.20479676753194,
        '-45': 32.203308482709694,
        '-44': 32.2033511144769,
        '-43': 32.204948015947856,
        '-42': 32.209364345007266,
        '-41': 32.225928943478976,
        '-40': 32.22693119081623,
        '-39': 32.226930610620045,
        '-38': 32.22718313009238,
        '-37': 32.227561339184334,
        '-36': 32.227922458031685,
        '-35': 32.22816202335767,
        '-34': 32.228246151121404,
        '-33': 32.22821188951341,
        '-32': 32.228139059198114,
        '-31': 32.228108612476476,
        '-30': 32.22816677905335,
        '-29': 32.22830982927095,
        '-28': 32.2284937275571,
        '-27': 32.22866139352631,
        '-26': 32.228772983211755,
        '-25': 32.22882452944266,
        '-24': 32.22884696344157,
        '-23': 32.22599221975623,
        '-22': 32.227939578783534,
        '-21': 32.226505258828766,
        '-20': 32.22137408874028,
        '-19': 32.22130108708326,
        '-18': 32.2210392956725,
        '-17': 32.220872040762174,
        '-16': 32.222960538538224,
        '-15': 32.22761189615942,
        '-14': 32.227996787357554,
        '-13': 32.22878304993239,
        '-12': 32.2286085222802,
        '-11': 32.22845429452555,
        '-10': 32.22829637428741,
        '-9': 32.22811794296419,
        '-8': 32.227925573063736,
        '-7': 32.22775239700355,
        '-6': 32.22764535872647,
        '-5': 32.227641823452366,
        '-4': 32.22774674732949,
        '-3': 32.22792229171333,
        '-2': 32.22809660316631,
        '-1': 32.22818968600581
    },
    '66': {
        '0': 32.23051577959678,
        '1': 32.230353729344074,
        '2': 32.23010974804625,
        '3': 32.229854389030066,
        '4': 32.2296633970556,
        '5': 32.229589684629175,
        '6': 32.22964366879079,
        '7': 32.229789944695895,
        '8': 32.22996168080111,
        '9': 32.23008692610807,
        '10': 32.23011626184554,
        '11': 32.230040867805506,
        '12': 32.22989420681899,
        '13': 32.22472918536239,
        '14': 32.221894257661226,
        '15': 32.22250868691721,
        '16': 32.22073803910827,
        '17': 32.22432434632244,
        '18': 32.225610305219085,
        '19': 32.226261426307154,
        '20': 32.227914348561676,
        '21': 32.22794739668774,
        '22': 32.22934343643352,
        '23': 32.22847197259079,
        '24': 32.22858398580438,
        '25': 32.22803375129952,
        '26': 32.22735851439359,
        '27': 32.22662299555858,
        '28': 32.22619223289354,
        '29': 32.22573087967358,
        '30': 32.22636839491695,
        '31': 32.22825145100729,
        '32': 32.22779467785972,
        '33': 32.228573641026884,
        '34': 32.22894830736616,
        '35': 32.229937643438326,
        '36': 32.23002541346997,
        '37': 32.230069552046864,
        '38': 32.230058987204416,
        '39': 32.2299911772796,
        '40': 32.22987772489491,
        '41': 32.229583209096546,
        '42': 32.2291231231712,
        '43': 32.22930023428207,
        '44': 32.22934524965067,
        '45': 32.22900192666836,
        '46': 32.22891020127649,
        '47': 32.22897536073844,
        '48': 32.22894940216301,
        '49': 32.227577459453954,
        '50': 32.22860175960905,
        '51': 32.22856772133713,
        '52': 32.228717302612495,
        '53': 32.227786620279666,
        '54': 32.228561854110154,
        '55': 32.22862224423898,
        '56': 32.22861186805549,
        '57': 32.22873121383501,
        '58': 32.22899868680843,
        '59': 32.228074976059645,
        '60': 32.22907047353041,
        '61': 32.22784632030037,
        '62': 32.22740629914542,
        '63': 32.22692921767985,
        '64': 32.22926955082047,
        '65': 32.22971173132647,
        '66': 32.229880622686686,
        '67': 32.22995302613644,
        '68': 32.22950073316065,
        '69': 32.22956233237508,
        '70': 32.22875717110689,
        '71': 32.228655589236986,
        '72': 32.22886446996586,
        '73': 32.22858116178615,
        '74': 32.22824553070646,
        '75': 32.228357219044824,
        '76': 32.22849105003658,
        '77': 32.229051776629895,
        '78': 32.22928331636103,
        '79': 32.22901052141077,
        '80': 32.22906020561757,
        '81': 32.22930026446137,
        '82': 32.22934687497471,
        '83': 32.229226446709916,
        '84': 32.22861342050892,
        '85': 32.229169285404566,
        '86': 32.22920788879607,
        '87': 32.22922050493246,
        '88': 32.229304539869126,
        '89': 32.22855258644389,
        '90': 32.22634211893849,
        '91': 32.22630705892245,
        '92': 32.225216870277684,
        '93': 32.22513682520236,
        '94': 32.223686527366965,
        '95': 32.22299719135945,
        '96': 32.22533352197191,
        '97': 32.22204680330556,
        '98': 32.224218704218046,
        '99': 32.223810874769356,
        '100': 32.22311604371457,
        '101': 32.22494627559463,
        '102': 32.2227805003824,
        '103': 32.22430440637868,
        '104': 32.22493275549613,
        '105': 32.223977935671876,
        '106': 32.22395658701453,
        '107': 32.22429641549361,
        '108': 32.22520092867988,
        '109': 32.22310968546729,
        '110': 32.221805024425585,
        '111': 32.223159171152055,
        '112': 32.22573873912538,
        '113': 32.22488290510731,
        '114': 32.225011436174306,
        '115': 32.2255243312566,
        '116': 32.22629984345961,
        '117': 32.22660596389987,
        '118': 32.22672228392491,
        '119': 32.22725451347707,
        '120': 32.22735710173513,
        '121': 32.22711268661405,
        '122': 32.22698803294806,
        '123': 32.22726788458535,
        '124': 32.22805263259772,
        '125': 32.22742285764482,
        '126': 32.225603766711906,
        '127': 32.21983079970633,
        '128': 32.21624501118441,
        '129': 32.21468557707775,
        '130': 32.22148379081709,
        '131': 32.22504299513629,
        '132': 32.223748064578714,
        '133': 32.22653813819059,
        '134': 32.221965450641406,
        '135': 32.22266647384552,
        '136': 32.2177913894397,
        '137': 32.221789633538634,
        '138': 32.21845334092007,
        '139': 32.22029908472213,
        '140': 32.2176103712244,
        '141': 32.20745761464817,
        '142': 32.22146147967949,
        '143': 32.222445604111016,
        '144': 32.22489803954116,
        '145': 32.22515890994494,
        '146': 32.220250016995976,
        '147': 32.217865434328004,
        '148': 32.223497699614136,
        '149': 32.22669822301426,
        '150': 32.22832967193945,
        '151': 32.22908623356932,
        '152': 32.227539250542435,
        '153': 32.22661477931026,
        '154': 32.224059954740575,
        '155': 32.224930069462125,
        '156': 32.22658605648114,
        '157': 32.22356032272875,
        '158': 32.2254893779958,
        '159': 32.22808006589697,
        '160': 32.224813597150366,
        '161': 32.226129007461765,
        '162': 32.22243300140968,
        '163': 32.21964380953241,
        '164': 32.21910538457706,
        '165': 32.22277185730547,
        '166': 32.22300735695778,
        '167': 32.22429056641661,
        '168': 32.22232374933593,
        '169': 32.225493081083705,
        '170': 32.226092299961806,
        '171': 32.22519173023843,
        '172': 32.22425481285245,
        '173': 32.22715933887856,
        '174': 32.22797556908453,
        '175': 32.22532899783506,
        '176': 32.227735838000534,
        '177': 32.228711542741806,
        '178': 32.226449368371654,
        '179': 32.22844026528705,
        '180': 32.22877216028817,
        '-180': 32.22877216028817,
        '-179': 32.229500824401676,
        '-178': 32.22876688920422,
        '-177': 32.22678922957684,
        '-176': 32.22863429903131,
        '-175': 32.22869555075273,
        '-174': 32.22920326846871,
        '-173': 32.22824979545653,
        '-172': 32.226180993237,
        '-171': 32.228675506362855,
        '-170': 32.2295601007806,
        '-169': 32.22948929248925,
        '-168': 32.229432697160895,
        '-167': 32.22940486342731,
        '-166': 32.22907907096894,
        '-165': 32.22920213878645,
        '-164': 32.22938992240649,
        '-163': 32.229131678011115,
        '-162': 32.22956466455718,
        '-161': 32.22888520573053,
        '-160': 32.22662213803372,
        '-159': 32.22909144154715,
        '-158': 32.228787730927756,
        '-157': 32.22903597995128,
        '-156': 32.22923536126464,
        '-155': 32.22789802253094,
        '-154': 32.226558357218,
        '-153': 32.226241318825664,
        '-152': 32.2275374038333,
        '-151': 32.225501571283615,
        '-150': 32.227398903281724,
        '-149': 32.228949436191606,
        '-148': 32.227484966718734,
        '-147': 32.22634453863595,
        '-146': 32.22672835774786,
        '-145': 32.22776656678392,
        '-144': 32.22780006516901,
        '-143': 32.22739472460879,
        '-142': 32.22707941174864,
        '-141': 32.22373458707518,
        '-140': 32.22077576476676,
        '-139': 32.22464232225815,
        '-138': 32.22547982945987,
        '-137': 32.22504554413019,
        '-136': 32.2246296108318,
        '-135': 32.22688448312668,
        '-134': 32.22641307181407,
        '-133': 32.22568347923995,
        '-132': 32.22753576956577,
        '-131': 32.22729738657342,
        '-130': 32.228335462703214,
        '-129': 32.229052332971385,
        '-128': 32.22694297147311,
        '-127': 32.22397210431629,
        '-126': 32.22581062408014,
        '-125': 32.22733049864685,
        '-124': 32.22711267282194,
        '-123': 32.22702686172003,
        '-122': 32.225694547259174,
        '-121': 32.22717305823068,
        '-120': 32.22721394842532,
        '-119': 32.22731612215614,
        '-118': 32.22597236710423,
        '-117': 32.22618658581597,
        '-116': 32.22431041658996,
        '-115': 32.22582148885165,
        '-114': 32.22581106469183,
        '-113': 32.22567235649565,
        '-112': 32.22449896799664,
        '-111': 32.22319404370212,
        '-110': 32.22332509749031,
        '-109': 32.224740053186274,
        '-108': 32.2247733708552,
        '-107': 32.225658963748906,
        '-106': 32.22569765184407,
        '-105': 32.225720120844315,
        '-104': 32.225927087840475,
        '-103': 32.226664814577326,
        '-102': 32.22690824723769,
        '-101': 32.226803040595975,
        '-100': 32.22668578575506,
        '-99': 32.226593552358274,
        '-98': 32.22720881051956,
        '-97': 32.22665255328879,
        '-96': 32.22680081781877,
        '-95': 32.22575568791707,
        '-94': 32.22577573664225,
        '-93': 32.22592550893757,
        '-92': 32.22662774833278,
        '-91': 32.22911334068547,
        '-90': 32.22806217379782,
        '-89': 32.225954323273236,
        '-88': 32.225884394988356,
        '-87': 32.22712294590658,
        '-86': 32.228513137153044,
        '-85': 32.22799120846938,
        '-84': 32.22694268730244,
        '-83': 32.22833948858324,
        '-82': 32.22836643365013,
        '-81': 32.228408404711615,
        '-80': 32.22844026322135,
        '-79': 32.2284388329666,
        '-78': 32.22838960749012,
        '-77': 32.22829213996283,
        '-76': 32.22816219843586,
        '-75': 32.22802942864602,
        '-74': 32.227920446830204,
        '-73': 32.227272992725666,
        '-72': 32.22735201329109,
        '-71': 32.22779563962463,
        '-70': 32.22713952430233,
        '-69': 32.22699582694581,
        '-68': 32.228819901887334,
        '-67': 32.22906757847483,
        '-66': 32.2292274094751,
        '-65': 32.22933548793373,
        '-64': 32.218265260969844,
        '-63': 32.225595984002865,
        '-62': 32.228693263231754,
        '-61': 32.22989977750158,
        '-60': 32.23018314771935,
        '-59': 32.2304793671102,
        '-58': 32.230722057255086,
        '-57': 32.230844801682956,
        '-56': 32.230806269936856,
        '-55': 32.23061046598852,
        '-54': 32.23031361756869,
        '-53': 32.228991112772285,
        '-52': 32.22514660167114,
        '-51': 32.21509837528577,
        '-50': 32.22038619080039,
        '-49': 32.21597760830209,
        '-48': 32.213119883811565,
        '-47': 32.21067515337246,
        '-46': 32.20888293009014,
        '-45': 32.20738767453402,
        '-44': 32.205991699884436,
        '-43': 32.20711110026544,
        '-42': 32.20898226776022,
        '-41': 32.210974499795036,
        '-40': 32.21361412748356,
        '-39': 32.214643963170175,
        '-38': 32.22798503019847,
        '-37': 32.22527684958116,
        '-36': 32.226556390335375,
        '-35': 32.230679315907516,
        '-34': 32.23075371549607,
        '-33': 32.23070952788255,
        '-32': 32.230597955374144,
        '-31': 32.230481405390286,
        '-30': 32.23040796739632,
        '-29': 32.230396842450745,
        '-28': 32.230438901707764,
        '-27': 32.23050946547252,
        '-26': 32.23058537201467,
        '-25': 32.2306575011944,
        '-24': 32.23073316297566,
        '-23': 32.23080806266131,
        '-22': 32.22623874071055,
        '-21': 32.23110836744123,
        '-20': 32.229911433927775,
        '-19': 32.23030685203197,
        '-18': 32.22594464854479,
        '-17': 32.228269114752024,
        '-16': 32.22779517496824,
        '-15': 32.229241021167766,
        '-14': 32.23109509326424,
        '-13': 32.230930595510806,
        '-12': 32.230772628519134,
        '-11': 32.230618589059446,
        '-10': 32.23046100185641,
        '-9': 32.230298886483894,
        '-8': 32.23014589611187,
        '-7': 32.23002989909112,
        '-6': 32.22998283423553,
        '-5': 32.23002476670204,
        '-4': 32.23014962118035,
        '-3': 32.23032020712365,
        '-2': 32.230476558294924,
        '-1': 32.230555742616
    },
    '67': {
        '0': 32.23257432593511,
        '1': 32.23250942886496,
        '2': 32.23237200761789,
        '3': 32.2322111369274,
        '4': 32.23208329156139,
        '5': 32.23203134773251,
        '6': 32.23206831206842,
        '7': 32.232172113495764,
        '8': 32.23229362700101,
        '9': 32.2323750365979,
        '10': 32.2323716847749,
        '11': 32.23226923837597,
        '12': 32.232089799333274,
        '13': 32.23188486015246,
        '14': 32.231707282640414,
        '15': 32.2252692441949,
        '16': 32.224496114861076,
        '17': 32.22043476362395,
        '18': 32.2212901572009,
        '19': 32.2268819727264,
        '20': 32.228082740648375,
        '21': 32.22799507732389,
        '22': 32.22973671914738,
        '23': 32.23008167604251,
        '24': 32.23037565576402,
        '25': 32.22976996247332,
        '26': 32.22870644881783,
        '27': 32.228790757777546,
        '28': 32.22919684403527,
        '29': 32.22932663821784,
        '30': 32.22788493383341,
        '31': 32.22855248835822,
        '32': 32.23088574372779,
        '33': 32.22762611213995,
        '34': 32.23144296785799,
        '35': 32.23097164769808,
        '36': 32.23123514184336,
        '37': 32.22981560426491,
        '38': 32.230243327779,
        '39': 32.230545603657724,
        '40': 32.230046544263466,
        '41': 32.230303943589846,
        '42': 32.23196857991247,
        '43': 32.23199816508214,
        '44': 32.232063925109685,
        '45': 32.23202497394107,
        '46': 32.23221966211413,
        '47': 32.232257740092756,
        '48': 32.23176688677337,
        '49': 32.229700281169116,
        '50': 32.23150486724888,
        '51': 32.23164533112245,
        '52': 32.2315958816841,
        '53': 32.231196378242785,
        '54': 32.23078143318402,
        '55': 32.23032910358053,
        '56': 32.23005206244868,
        '57': 32.230294376948706,
        '58': 32.23057860606091,
        '59': 32.2302062969233,
        '60': 32.230783482900314,
        '61': 32.230577399659026,
        '62': 32.231028364763105,
        '63': 32.23139947950386,
        '64': 32.23112129179022,
        '65': 32.23062128473876,
        '66': 32.22491196118117,
        '67': 32.22971709990716,
        '68': 32.231804554516906,
        '69': 32.23160429519487,
        '70': 32.23107664431279,
        '71': 32.2309038862216,
        '72': 32.23092132269446,
        '73': 32.230895932133876,
        '74': 32.23076038142487,
        '75': 32.23053993979351,
        '76': 32.230839214792354,
        '77': 32.23134083993919,
        '78': 32.23157447896221,
        '79': 32.2309462116531,
        '80': 32.23129272009719,
        '81': 32.23150902342209,
        '82': 32.23126079758132,
        '83': 32.23099710893509,
        '84': 32.2309134104101,
        '85': 32.231415801397056,
        '86': 32.231347688531656,
        '87': 32.231147017000815,
        '88': 32.23047107240504,
        '89': 32.22961435085501,
        '90': 32.23032572826426,
        '91': 32.22868779737799,
        '92': 32.22941205056245,
        '93': 32.22478752993767,
        '94': 32.22752450501055,
        '95': 32.22600911446307,
        '96': 32.22918522776221,
        '97': 32.223166611623256,
        '98': 32.22509674393076,
        '99': 32.22472388079771,
        '100': 32.226013462871414,
        '101': 32.226720732739764,
        '102': 32.22573845350229,
        '103': 32.2268046670579,
        '104': 32.2271228271845,
        '105': 32.22617880033645,
        '106': 32.22702462613979,
        '107': 32.226888079332014,
        '108': 32.22746856585407,
        '109': 32.22766816443297,
        '110': 32.22922189524978,
        '111': 32.22717304403074,
        '112': 32.227113550055066,
        '113': 32.22785700009045,
        '114': 32.2269058244152,
        '115': 32.22862480324297,
        '116': 32.22886843245402,
        '117': 32.22901171328282,
        '118': 32.229557895044486,
        '119': 32.22928092277761,
        '120': 32.228966152403025,
        '121': 32.22870689481589,
        '122': 32.2287896666304,
        '123': 32.22980789299504,
        '124': 32.22973463302018,
        '125': 32.22821101342215,
        '126': 32.22504246933365,
        '127': 32.22276744826701,
        '128': 32.22232959557085,
        '129': 32.22125109422695,
        '130': 32.225508746668524,
        '131': 32.22596940819723,
        '132': 32.22852049551862,
        '133': 32.230343696848735,
        '134': 32.22678316786346,
        '135': 32.22637978490453,
        '136': 32.227871874836914,
        '137': 32.225295344625955,
        '138': 32.22387155325139,
        '139': 32.220820173003936,
        '140': 32.21667717422418,
        '141': 32.22833878032062,
        '142': 32.230031748737666,
        '143': 32.22491415368484,
        '144': 32.21888838060795,
        '145': 32.22675722690597,
        '146': 32.22985280996351,
        '147': 32.23066174833092,
        '148': 32.23036425237079,
        '149': 32.23005547930761,
        '150': 32.230803339966634,
        '151': 32.2314258409935,
        '152': 32.23140141690156,
        '153': 32.23151081754354,
        '154': 32.2296537020438,
        '155': 32.23054043463172,
        '156': 32.229214011469615,
        '157': 32.23032311197262,
        '158': 32.228135292964424,
        '159': 32.23004711044798,
        '160': 32.22991006605969,
        '161': 32.22747114116834,
        '162': 32.22969288691021,
        '163': 32.230536717949164,
        '164': 32.227514305251496,
        '165': 32.22878190579071,
        '166': 32.22610999171314,
        '167': 32.22181354982707,
        '168': 32.22603404895289,
        '169': 32.22754218326383,
        '170': 32.226985162257876,
        '171': 32.22499237656782,
        '172': 32.228071244280294,
        '173': 32.22753160069348,
        '174': 32.22886116456527,
        '175': 32.226732172572795,
        '176': 32.22845186661889,
        '177': 32.22479728560941,
        '178': 32.22550711654885,
        '179': 32.229353815943455,
        '180': 32.22779114874711,
        '-180': 32.227791148747116,
        '-179': 32.23005127055146,
        '-178': 32.22962224733895,
        '-177': 32.229242458554296,
        '-176': 32.22702022485212,
        '-175': 32.230754935814204,
        '-174': 32.23110269854895,
        '-173': 32.23119986941982,
        '-172': 32.231301778783696,
        '-171': 32.23138904245842,
        '-170': 32.23144860727599,
        '-169': 32.23146956764946,
        '-168': 32.23145560582522,
        '-167': 32.23142318607249,
        '-166': 32.23139624664465,
        '-165': 32.23139897503265,
        '-164': 32.23144874458435,
        '-163': 32.23155125224909,
        '-162': 32.231699248809775,
        '-161': 32.2307823113673,
        '-160': 32.23187408364191,
        '-159': 32.23031879781585,
        '-158': 32.231729818417854,
        '-157': 32.22747779231386,
        '-156': 32.2290453688435,
        '-155': 32.2304672646151,
        '-154': 32.228327752096455,
        '-153': 32.22791329415236,
        '-152': 32.22775308764848,
        '-151': 32.22862165283611,
        '-150': 32.22593270750984,
        '-149': 32.22352805400858,
        '-148': 32.227430118278164,
        '-147': 32.2302539159188,
        '-146': 32.23074577309483,
        '-145': 32.230739829613604,
        '-144': 32.23053837699423,
        '-143': 32.23042754433511,
        '-142': 32.22872804941808,
        '-141': 32.22772307126304,
        '-140': 32.225290411524384,
        '-139': 32.227468535076234,
        '-138': 32.22770558845977,
        '-137': 32.2287861706715,
        '-136': 32.22602884246358,
        '-135': 32.231158314208436,
        '-134': 32.23051611818735,
        '-133': 32.23004194196554,
        '-132': 32.229523235569665,
        '-131': 32.228100888222535,
        '-130': 32.230553792451836,
        '-129': 32.22889871328203,
        '-128': 32.22920128361886,
        '-127': 32.228245311832616,
        '-126': 32.228581983435056,
        '-125': 32.22873760114106,
        '-124': 32.22870706778267,
        '-123': 32.2279930245633,
        '-122': 32.22893640504445,
        '-121': 32.22963350645044,
        '-120': 32.23008597394556,
        '-119': 32.22928259946808,
        '-118': 32.22852321845855,
        '-117': 32.225669050021025,
        '-116': 32.22600783258002,
        '-115': 32.225890699391734,
        '-114': 32.226557123128636,
        '-113': 32.22717194969513,
        '-112': 32.22619525082237,
        '-111': 32.227065705818966,
        '-110': 32.22647215793135,
        '-109': 32.22728020717924,
        '-108': 32.23060523048142,
        '-107': 32.228525912876776,
        '-106': 32.22879138853143,
        '-105': 32.22862513542417,
        '-104': 32.22879242700247,
        '-103': 32.228967358156225,
        '-102': 32.22951825100584,
        '-101': 32.229291465810604,
        '-100': 32.22911464621289,
        '-99': 32.22930806199847,
        '-98': 32.22944269442568,
        '-97': 32.22955814042647,
        '-96': 32.230466245469216,
        '-95': 32.22866888584344,
        '-94': 32.22803967180588,
        '-93': 32.2296580491385,
        '-92': 32.22840347702431,
        '-91': 32.2272534901192,
        '-90': 32.22797187340772,
        '-89': 32.22701533897647,
        '-88': 32.22919530401398,
        '-87': 32.23039331631846,
        '-86': 32.22765504724286,
        '-85': 32.229988103482185,
        '-84': 32.22891155325832,
        '-83': 32.228186948693555,
        '-82': 32.23020294039755,
        '-81': 32.230664529251705,
        '-80': 32.23051538497818,
        '-79': 32.23034215013535,
        '-78': 32.23016149144503,
        '-77': 32.22999572626555,
        '-76': 32.2298705211135,
        '-75': 32.22981131669765,
        '-74': 32.22983864188719,
        '-73': 32.22996310434361,
        '-72': 32.229938804536715,
        '-71': 32.23017145464646,
        '-70': 32.2292134099675,
        '-69': 32.22906764703729,
        '-68': 32.22804797778818,
        '-67': 32.227027956322424,
        '-66': 32.21854303110764,
        '-65': 32.219045294267126,
        '-64': 32.221520835893884,
        '-63': 32.23217620769448,
        '-62': 32.23230078724492,
        '-61': 32.232468128849796,
        '-60': 32.232663697072766,
        '-59': 32.232850006890196,
        '-58': 32.23297731093911,
        '-57': 32.233000608998445,
        '-56': 32.23289770294366,
        '-55': 32.23268189412376,
        '-54': 32.232404002523865,
        '-53': 32.226718742276496,
        '-52': 32.23082412315955,
        '-51': 32.22991010990147,
        '-50': 32.22969243840398,
        '-49': 32.220447646041514,
        '-48': 32.21710029262807,
        '-47': 32.214566993067784,
        '-46': 32.21248606171416,
        '-45': 32.21077338536108,
        '-44': 32.20921314349908,
        '-43': 32.207851909592804,
        '-42': 32.20824548539852,
        '-41': 32.20947746079519,
        '-40': 32.211046096221466,
        '-39': 32.21199475723036,
        '-38': 32.21243285786564,
        '-37': 32.20835935234327,
        '-36': 32.21081917098085,
        '-35': 32.21428554145587,
        '-34': 32.23105807836115,
        '-33': 32.233114863203184,
        '-32': 32.2329204686131,
        '-31': 32.2327122835654,
        '-30': 32.232536914783545,
        '-29': 32.23242448642142,
        '-28': 32.23238363632966,
        '-27': 32.2324048283355,
        '-26': 32.23246865075923,
        '-25': 32.23255464530072,
        '-24': 32.23264705951473,
        '-23': 32.23273617304306,
        '-22': 32.23281626495681,
        '-21': 32.232882695182184,
        '-20': 32.232930422308165,
        '-19': 32.23295486055536,
        '-18': 32.23295420121739,
        '-17': 32.232931221923785,
        '-16': 32.232892798692426,
        '-15': 32.23284671291358,
        '-14': 32.23279713805845,
        '-13': 32.23274136912318,
        '-12': 32.232670171962866,
        '-11': 32.23257252066879,
        '-10': 32.23244316329736,
        '-9': 32.23228956547422,
        '-8': 32.23213437883262,
        '-7': 32.23201107559182,
        '-6': 32.2319532912666,
        '-5': 32.23198149601864,
        '-4': 32.232092430650056,
        '-3': 32.23225630634176,
        '-2': 32.23242403948987,
        '-1': 32.232542777340804
    },
    '68': {
        '0': 32.234469847334516,
        '1': 32.2344981402801,
        '2': 32.23446021349401,
        '3': 32.234385695357844,
        '4': 32.234314154942155,
        '5': 32.23427978637104,
        '6': 32.234298180425114,
        '7': 32.234360056538605,
        '8': 32.23443432674924,
        '9': 32.23447947089353,
        '10': 32.23445917231367,
        '11': 32.23435660590454,
        '12': 32.234182237205204,
        '13': 32.2325764539619,
        '14': 32.23377825693596,
        '15': 32.23365115260239,
        '16': 32.23285693853545,
        '17': 32.22124289650058,
        '18': 32.22398380620517,
        '19': 32.222091428034176,
        '20': 32.22946679276826,
        '21': 32.22840996803868,
        '22': 32.23085955446563,
        '23': 32.23119624841784,
        '24': 32.23021348524725,
        '25': 32.231839838421266,
        '26': 32.23094331856266,
        '27': 32.23066178805483,
        '28': 32.23106985759913,
        '29': 32.23100680270116,
        '30': 32.23200216764235,
        '31': 32.232152497203145,
        '32': 32.230610802756885,
        '33': 32.23285052952261,
        '34': 32.23147250189765,
        '35': 32.23273638914249,
        '36': 32.231720301732764,
        '37': 32.231772539376415,
        '38': 32.2317106377057,
        '39': 32.23226344353909,
        '40': 32.234021937725736,
        '41': 32.23398661615632,
        '42': 32.23397576183288,
        '43': 32.23397973551222,
        '44': 32.23398613709197,
        '45': 32.23300248157391,
        '46': 32.23188311718089,
        '47': 32.23393788859341,
        '48': 32.23390239347277,
        '49': 32.233872220352104,
        '50': 32.233736170870685,
        '51': 32.23286149551704,
        '52': 32.233713322580904,
        '53': 32.233861084071734,
        '54': 32.23376323534939,
        '55': 32.233677671945614,
        '56': 32.233071807790715,
        '57': 32.232925007646685,
        '58': 32.2325210394426,
        '59': 32.23228634920647,
        '60': 32.232828414623704,
        '61': 32.232303839561744,
        '62': 32.232154258797664,
        '63': 32.233634380921345,
        '64': 32.232320703240454,
        '65': 32.231687546123396,
        '66': 32.22847939249132,
        '67': 32.23172878951762,
        '68': 32.23387898596754,
        '69': 32.23351390789468,
        '70': 32.23322621887465,
        '71': 32.232945269477796,
        '72': 32.23266925435567,
        '73': 32.23288509007716,
        '74': 32.233231438657725,
        '75': 32.2332264489506,
        '76': 32.233420452086484,
        '77': 32.23355727392572,
        '78': 32.23352138761209,
        '79': 32.23323253877258,
        '80': 32.23312442224463,
        '81': 32.23315654167013,
        '82': 32.232734199797996,
        '83': 32.23320126151835,
        '84': 32.232919132653,
        '85': 32.23276463771278,
        '86': 32.23315128595402,
        '87': 32.232912716598285,
        '88': 32.233221538967015,
        '89': 32.232088519824764,
        '90': 32.229446614362686,
        '91': 32.228146468037544,
        '92': 32.2274253274979,
        '93': 32.22716576126891,
        '94': 32.22488862896104,
        '95': 32.224155720011964,
        '96': 32.223721315360464,
        '97': 32.2256097537503,
        '98': 32.224634930442626,
        '99': 32.226331829281136,
        '100': 32.22403504343111,
        '101': 32.22961380437566,
        '102': 32.23126181500197,
        '103': 32.23086256691303,
        '104': 32.23003797384118,
        '105': 32.23008417615096,
        '106': 32.230661523848966,
        '107': 32.23100536527169,
        '108': 32.23043718602211,
        '109': 32.231041608401775,
        '110': 32.230410550869735,
        '111': 32.229091732394004,
        '112': 32.23003869211779,
        '113': 32.22996991136671,
        '114': 32.23125290298928,
        '115': 32.23103611711389,
        '116': 32.23239771882093,
        '117': 32.23369788805947,
        '118': 32.23161615176666,
        '119': 32.231276204185185,
        '120': 32.230868655907095,
        '121': 32.23230733273749,
        '122': 32.2311600142641,
        '123': 32.23231857749374,
        '124': 32.231097808867666,
        '125': 32.22987477733594,
        '126': 32.23036134130207,
        '127': 32.22281284205621,
        '128': 32.21900346027114,
        '129': 32.223014248546306,
        '130': 32.22309215973011,
        '131': 32.22699815410322,
        '132': 32.22946914181767,
        '133': 32.22944045079747,
        '134': 32.231162604800154,
        '135': 32.22855317062446,
        '136': 32.228648936442625,
        '137': 32.22555874212752,
        '138': 32.227963424887356,
        '139': 32.21686617910221,
        '140': 32.22735510850301,
        '141': 32.23216998446673,
        '142': 32.23244901992256,
        '143': 32.23344742092208,
        '144': 32.23347039714559,
        '145': 32.23336279441373,
        '146': 32.23322004840767,
        '147': 32.23285957505797,
        '148': 32.230922158642294,
        '149': 32.23258390278571,
        '150': 32.23260198302996,
        '151': 32.233453300352565,
        '152': 32.23352254332623,
        '153': 32.23332202917782,
        '154': 32.233598179206126,
        '155': 32.23344795267295,
        '156': 32.23336465531897,
        '157': 32.23380309586592,
        '158': 32.233218801130334,
        '159': 32.23248080773121,
        '160': 32.23329127000587,
        '161': 32.23387195470294,
        '162': 32.233613222685264,
        '163': 32.23336315360414,
        '164': 32.226887420593194,
        '165': 32.23256905877275,
        '166': 32.22883584279286,
        '167': 32.22984715851863,
        '168': 32.22738228621422,
        '169': 32.22839287924425,
        '170': 32.23172809509369,
        '171': 32.23238902543954,
        '172': 32.2325476838066,
        '173': 32.228465490964574,
        '174': 32.22929537638627,
        '175': 32.22682007953766,
        '176': 32.22601220580209,
        '177': 32.22900816617387,
        '178': 32.227076902237954,
        '179': 32.22899578435536,
        '180': 32.22962295848468,
        '-180': 32.22962295848468,
        '-179': 32.232534068031306,
        '-178': 32.232829776954,
        '-177': 32.23335111297839,
        '-176': 32.233251548777936,
        '-175': 32.233116958207745,
        '-174': 32.23307787973066,
        '-173': 32.23312247094753,
        '-172': 32.23322243677148,
        '-171': 32.23334159592741,
        '-170': 32.23344600393465,
        '-169': 32.23351283429739,
        '-168': 32.23353563279834,
        '-167': 32.23352466555799,
        '-166': 32.233502482429245,
        '-165': 32.233101728098745,
        '-164': 32.23246689704932,
        '-163': 32.23178202607884,
        '-162': 32.232602908401574,
        '-161': 32.2310099313384,
        '-160': 32.22925613958165,
        '-159': 32.23103213169772,
        '-158': 32.230845615796156,
        '-157': 32.228333791517976,
        '-156': 32.222903183228276,
        '-155': 32.222519665120466,
        '-154': 32.22279513216282,
        '-153': 32.21998884457163,
        '-152': 32.2206354518589,
        '-151': 32.222238127171074,
        '-150': 32.22238561046245,
        '-149': 32.224532419803126,
        '-148': 32.225523351070265,
        '-147': 32.228032539140955,
        '-146': 32.22897362893536,
        '-145': 32.22812373022962,
        '-144': 32.228955150153155,
        '-143': 32.230252196513135,
        '-142': 32.23087284093744,
        '-141': 32.231180377289924,
        '-140': 32.23144657640926,
        '-139': 32.23117483388605,
        '-138': 32.22782515340104,
        '-137': 32.226922674580045,
        '-136': 32.22352185270004,
        '-135': 32.233422009969374,
        '-134': 32.233371149936424,
        '-133': 32.23129762498657,
        '-132': 32.23133908841414,
        '-131': 32.22970952280394,
        '-130': 32.23132137231754,
        '-129': 32.23272662781795,
        '-128': 32.232201393172346,
        '-127': 32.231297527221436,
        '-126': 32.23246313451742,
        '-125': 32.23160676298855,
        '-124': 32.23047845819241,
        '-123': 32.22952103313668,
        '-122': 32.23003750460512,
        '-121': 32.22986839299612,
        '-120': 32.22765679858364,
        '-119': 32.23124142591647,
        '-118': 32.23232440378837,
        '-117': 32.232855910585776,
        '-116': 32.230991251965214,
        '-115': 32.23354816607209,
        '-114': 32.23332971914648,
        '-113': 32.23311727989008,
        '-112': 32.23291992024703,
        '-111': 32.23274606902309,
        '-110': 32.23260177285657,
        '-109': 32.23247887253203,
        '-108': 32.232404780054935,
        '-107': 32.23121992745078,
        '-106': 32.23154467973434,
        '-105': 32.23164373455556,
        '-104': 32.232203383511006,
        '-103': 32.232187492266846,
        '-102': 32.23218290410881,
        '-101': 32.232211665888194,
        '-100': 32.23228198310578,
        '-99': 32.232393758515194,
        '-98': 32.23230385201027,
        '-97': 32.23210395608549,
        '-96': 32.23283275145627,
        '-95': 32.23268878484331,
        '-94': 32.23239855911645,
        '-93': 32.23238774586598,
        '-92': 32.230584994394825,
        '-91': 32.231816194551016,
        '-90': 32.23216624549928,
        '-89': 32.23151678047277,
        '-88': 32.23301461977975,
        '-87': 32.233055097943506,
        '-86': 32.232859069746944,
        '-85': 32.228878342570994,
        '-84': 32.22950105729905,
        '-83': 32.22998274449869,
        '-82': 32.23263755612946,
        '-81': 32.232390655043815,
        '-80': 32.232141753863594,
        '-79': 32.23193184504771,
        '-78': 32.23179847708796,
        '-77': 32.2317484071253,
        '-76': 32.2315507670774,
        '-75': 32.23204907362861,
        '-74': 32.23232343766017,
        '-73': 32.23267548733068,
        '-72': 32.23131781947088,
        '-71': 32.230307043412964,
        '-70': 32.230929785503164,
        '-69': 32.22808767856567,
        '-68': 32.225319840146334,
        '-67': 32.23419703795559,
        '-66': 32.23353451166271,
        '-65': 32.23433380922334,
        '-64': 32.23443414308292,
        '-63': 32.234559714968356,
        '-62': 32.234719369739565,
        '-61': 32.234892400984904,
        '-60': 32.23504151869709,
        '-59': 32.235121280744906,
        '-58': 32.23509059906766,
        '-57': 32.23492624390207,
        '-56': 32.23463339944832,
        '-55': 32.23424959018467,
        '-54': 32.23383982375104,
        '-53': 32.23245215290615,
        '-52': 32.23237511677144,
        '-51': 32.23260745074438,
        '-50': 32.227422084227904,
        '-49': 32.221173625226676,
        '-48': 32.21851605062671,
        '-47': 32.21653039629273,
        '-46': 32.21464649645583,
        '-45': 32.2132282521093,
        '-44': 32.21167375466957,
        '-43': 32.21026658653029,
        '-42': 32.209063113424584,
        '-41': 32.20842135855219,
        '-40': 32.20845693739214,
        '-39': 32.20831198041727,
        '-38': 32.207767638513126,
        '-37': 32.20673315174484,
        '-36': 32.20610771474255,
        '-35': 32.214067286126536,
        '-34': 32.21579193362072,
        '-33': 32.22423021647679,
        '-32': 32.23526589173224,
        '-31': 32.235095652303045,
        '-30': 32.23494154956611,
        '-29': 32.23483207933927,
        '-28': 32.234780809561556,
        '-27': 32.2347851712781,
        '-26': 32.23482969949002,
        '-25': 32.23489205330499,
        '-24': 32.23494970687335,
        '-23': 32.23498540376307,
        '-22': 32.234990377685754,
        '-21': 32.234965166823116,
        '-20': 32.23491836416611,
        '-19': 32.23486381257223,
        '-18': 32.23481674442361,
        '-17': 32.234789412508185,
        '-16': 32.2347869746091,
        '-15': 32.234804683742894,
        '-14': 32.23482753797167,
        '-13': 32.23483319053797,
        '-12': 32.23479802807381,
        '-11': 32.23470510373819,
        '-10': 32.23455153515502,
        '-9': 32.23435258785005,
        '-8': 32.23414031820065,
        '-7': 32.23395629878453,
        '-6': 32.233840092000676,
        '-5': 32.23381697111576,
        '-4': 32.233889116962125,
        '-3': 32.23403370580051,
        '-2': 32.23420909895445,
        '-1': 32.23436748220926
    },
    '69': {
        '0': 32.2363998585044,
        '1': 32.23643581485983,
        '2': 32.23641839566052,
        '3': 32.236367554186735,
        '4': 32.236313642451165,
        '5': 32.23628621475874,
        '6': 32.23630336799623,
        '7': 32.23636520792324,
        '8': 32.23645361562808,
        '9': 32.23653834175482,
        '10': 32.23658732028519,
        '11': 32.23657769906469,
        '12': 32.23650389255105,
        '13': 32.236380011449214,
        '14': 32.23623592823502,
        '15': 32.23610833494168,
        '16': 32.23602973734834,
        '17': 32.236018903964386,
        '18': 32.228773477653604,
        '19': 32.235291640037644,
        '20': 32.22414614358672,
        '21': 32.22805736020688,
        '22': 32.230926898303835,
        '23': 32.232699498305685,
        '24': 32.23240498684682,
        '25': 32.23306514802761,
        '26': 32.23343783163568,
        '27': 32.23425423572449,
        '28': 32.23492288624539,
        '29': 32.235226029215006,
        '30': 32.23429742323505,
        '31': 32.23336810730387,
        '32': 32.234021322347616,
        '33': 32.23594190351181,
        '34': 32.234329430337695,
        '35': 32.23397530352435,
        '36': 32.234334518994004,
        '37': 32.23584599030482,
        '38': 32.23580980496228,
        '39': 32.23579830001183,
        '40': 32.235806570978944,
        '41': 32.235821724257775,
        '42': 32.23582678628878,
        '43': 32.23580630726683,
        '44': 32.23575197058967,
        '45': 32.23566643294057,
        '46': 32.23556404182951,
        '47': 32.235467921233,
        '48': 32.235403971027935,
        '49': 32.234200133822725,
        '50': 32.23544530127253,
        '51': 32.23555337719245,
        '52': 32.235695180251895,
        '53': 32.2358374662149,
        '54': 32.23594471259841,
        '55': 32.23598915313743,
        '56': 32.235959274539226,
        '57': 32.23586401087541,
        '58': 32.23573098160163,
        '59': 32.23559888663497,
        '60': 32.235506072056054,
        '61': 32.235468567870235,
        '62': 32.23319587088318,
        '63': 32.23340395257,
        '64': 32.23506746746103,
        '65': 32.23512033076293,
        '66': 32.235764438868124,
        '67': 32.23583088156716,
        '68': 32.235733585179965,
        '69': 32.23550357944935,
        '70': 32.235315209344634,
        '71': 32.2350065000505,
        '72': 32.23525908068625,
        '73': 32.23551638860798,
        '74': 32.23565232834592,
        '75': 32.235797540906155,
        '76': 32.23590815108288,
        '77': 32.23589158850445,
        '78': 32.23557647052975,
        '79': 32.2350290216058,
        '80': 32.235113474986555,
        '81': 32.235213149792834,
        '82': 32.23441127242061,
        '83': 32.23521207627422,
        '84': 32.23493915709042,
        '85': 32.234731618606325,
        '86': 32.23584271033552,
        '87': 32.23498671521742,
        '88': 32.23235251466652,
        '89': 32.23517002098708,
        '90': 32.231284054344115,
        '91': 32.23536014276189,
        '92': 32.227249757539,
        '93': 32.22793928434521,
        '94': 32.22479803928581,
        '95': 32.2238890117993,
        '96': 32.2240903126306,
        '97': 32.225351232540596,
        '98': 32.22911599415948,
        '99': 32.22936244604937,
        '100': 32.23049712165034,
        '101': 32.231509679863166,
        '102': 32.232869912203086,
        '103': 32.23237929058331,
        '104': 32.233670095937505,
        '105': 32.233384217555866,
        '106': 32.23291446947818,
        '107': 32.23280625479282,
        '108': 32.23248727156784,
        '109': 32.23318994767442,
        '110': 32.23257979386994,
        '111': 32.232760761384824,
        '112': 32.23283586082474,
        '113': 32.232857748131934,
        '114': 32.233614285616596,
        '115': 32.23343901174692,
        '116': 32.233848416747946,
        '117': 32.233010838631465,
        '118': 32.23523180690126,
        '119': 32.233362703722655,
        '120': 32.23293299901132,
        '121': 32.234106318237075,
        '122': 32.23327330042568,
        '123': 32.233414124981174,
        '124': 32.23457403313294,
        '125': 32.23431926275897,
        '126': 32.23253302537898,
        '127': 32.23004447412945,
        '128': 32.22194801211521,
        '129': 32.22200118054813,
        '130': 32.22949208201768,
        '131': 32.23271014871961,
        '132': 32.23129452362776,
        '133': 32.23257815594138,
        '134': 32.23294783825697,
        '135': 32.232809346066276,
        '136': 32.23297072411604,
        '137': 32.232588792957344,
        '138': 32.23210997286379,
        '139': 32.23194304917682,
        '140': 32.23039219685556,
        '141': 32.23453894990518,
        '142': 32.23391107687312,
        '143': 32.23472975560531,
        '144': 32.2344722905952,
        '145': 32.23496638513802,
        '146': 32.23492984352534,
        '147': 32.23511900378763,
        '148': 32.235723595349086,
        '149': 32.23523002954235,
        '150': 32.235680223206245,
        '151': 32.23567498076076,
        '152': 32.23566824732896,
        '153': 32.2356484998861,
        '154': 32.235802913557855,
        '155': 32.23566288547205,
        '156': 32.235496504609586,
        '157': 32.23512225755434,
        '158': 32.235415568402296,
        '159': 32.23553730068206,
        '160': 32.23567584701098,
        '161': 32.23587423060463,
        '162': 32.23361206203246,
        '163': 32.231081434747104,
        '164': 32.23265900772807,
        '165': 32.22733215505943,
        '166': 32.23228239704797,
        '167': 32.234302905878415,
        '168': 32.23449314828368,
        '169': 32.234307065248046,
        '170': 32.23576145498698,
        '171': 32.23563558147606,
        '172': 32.23495362783801,
        '173': 32.2333843246101,
        '174': 32.22925763709643,
        '175': 32.23422770568844,
        '176': 32.22669462125133,
        '177': 32.231204900651306,
        '178': 32.23261715517885,
        '179': 32.2353722954632,
        '180': 32.236184538436426,
        '-180': 32.236184538436426,
        '-179': 32.236075738448385,
        '-178': 32.23592961907116,
        '-177': 32.23577667168245,
        '-176': 32.235646880278594,
        '-175': 32.23556242997596,
        '-174': 32.23553274460998,
        '-173': 32.23555310596022,
        '-172': 32.235607075949055,
        '-171': 32.235671911185996,
        '-170': 32.23572532677679,
        '-169': 32.235751637701874,
        '-168': 32.235745558873155,
        '-167': 32.23571269538601,
        '-166': 32.235666768994605,
        '-165': 32.235624607755746,
        '-164': 32.23560056390762,
        '-163': 32.23298239436133,
        '-162': 32.231248543510915,
        '-161': 32.23243289912386,
        '-160': 32.230292721389624,
        '-159': 32.23119815704287,
        '-158': 32.23207848741006,
        '-157': 32.232621799663676,
        '-156': 32.232534325471946,
        '-155': 32.23337857524039,
        '-154': 32.23344143649027,
        '-153': 32.23329205831779,
        '-152': 32.23415004844459,
        '-151': 32.23215777079035,
        '-150': 32.2326142372845,
        '-149': 32.23168595319867,
        '-148': 32.23134358636597,
        '-147': 32.22545876947951,
        '-146': 32.22193807006229,
        '-145': 32.221900144119296,
        '-144': 32.21709657219116,
        '-143': 32.22265945345539,
        '-142': 32.22746226919784,
        '-141': 32.22886961333928,
        '-140': 32.22568426748933,
        '-139': 32.23370789543464,
        '-138': 32.23523589271734,
        '-137': 32.235616489375005,
        '-136': 32.23550404039274,
        '-135': 32.235449208721384,
        '-134': 32.234634806720734,
        '-133': 32.23558089884658,
        '-132': 32.23543179017014,
        '-131': 32.235195241213404,
        '-130': 32.23521719786048,
        '-129': 32.23302899321258,
        '-128': 32.23340677854088,
        '-127': 32.233928792582866,
        '-126': 32.233977539244236,
        '-125': 32.233490873950984,
        '-124': 32.23341391441487,
        '-123': 32.231557932797784,
        '-122': 32.229574300233125,
        '-121': 32.22954612711271,
        '-120': 32.230145935730775,
        '-119': 32.23310606849481,
        '-118': 32.23580177247094,
        '-117': 32.23595833294958,
        '-116': 32.23579137734766,
        '-115': 32.235606674161815,
        '-114': 32.23541716230542,
        '-113': 32.234353848228764,
        '-112': 32.23414373661622,
        '-111': 32.233616054588445,
        '-110': 32.233669491045255,
        '-109': 32.23379451283953,
        '-108': 32.23330983149928,
        '-107': 32.234408478151074,
        '-106': 32.23430296333582,
        '-105': 32.234174659219136,
        '-104': 32.233530307999295,
        '-103': 32.23356555070647,
        '-102': 32.23403719939207,
        '-101': 32.23427019332751,
        '-100': 32.234276776178845,
        '-99': 32.23445332033594,
        '-98': 32.234299003736254,
        '-97': 32.23440340832335,
        '-96': 32.23481486211908,
        '-95': 32.23502566654652,
        '-94': 32.23494685540061,
        '-93': 32.233653419480255,
        '-92': 32.233466701487416,
        '-91': 32.232885517572186,
        '-90': 32.23477149348909,
        '-89': 32.233902874253516,
        '-88': 32.234801716709704,
        '-87': 32.23483646071071,
        '-86': 32.23484787516062,
        '-85': 32.2348095020387,
        '-84': 32.23295681897451,
        '-83': 32.233632633581905,
        '-82': 32.233876051647705,
        '-81': 32.23414066057509,
        '-80': 32.23398764306935,
        '-79': 32.233914434042845,
        '-78': 32.23397946744142,
        '-77': 32.23415876405312,
        '-76': 32.234434499144115,
        '-75': 32.23478825208591,
        '-74': 32.23415710462574,
        '-73': 32.234147177531916,
        '-72': 32.23220616035621,
        '-71': 32.23283534144558,
        '-70': 32.23002323055999,
        '-69': 32.23155736777851,
        '-68': 32.234877217315045,
        '-67': 32.23583353119813,
        '-66': 32.23584973684906,
        '-65': 32.235958982986006,
        '-64': 32.23616912345026,
        '-63': 32.2364606314162,
        '-62': 32.23678868577463,
        '-61': 32.237091063053505,
        '-60': 32.237300567232964,
        '-59': 32.237359646303894,
        '-58': 32.23723413992803,
        '-57': 32.236922992769706,
        '-56': 32.23646137598239,
        '-55': 32.23591595407616,
        '-54': 32.235372797213344,
        '-53': 32.23492028511776,
        '-52': 32.234630777062264,
        '-51': 32.23251602418534,
        '-50': 32.2314949986085,
        '-49': 32.22463273598503,
        '-48': 32.220867932781566,
        '-47': 32.217972779141896,
        '-46': 32.2163929975736,
        '-45': 32.21464616676912,
        '-44': 32.2129477005124,
        '-43': 32.21148367220785,
        '-42': 32.21022082547561,
        '-41': 32.209065260754116,
        '-40': 32.20803989165951,
        '-39': 32.20734330758378,
        '-38': 32.20698025325818,
        '-37': 32.20765193006724,
        '-36': 32.209267977323925,
        '-35': 32.21187576321544,
        '-34': 32.21834065494306,
        '-33': 32.221862396521416,
        '-32': 32.21574368306945,
        '-31': 32.21290893218713,
        '-30': 32.21962979729237,
        '-29': 32.21876730885396,
        '-28': 32.222020405042606,
        '-27': 32.22583696306318,
        '-26': 32.22947597745644,
        '-25': 32.23715768016204,
        '-24': 32.23716762618144,
        '-23': 32.237165405362305,
        '-22': 32.23714558149787,
        '-21': 32.23711081755284,
        '-20': 32.23707108218834,
        '-19': 32.237040423670216,
        '-18': 32.237032152512825,
        '-17': 32.2370537275775,
        '-16': 32.237102825791354,
        '-15': 32.23716591634531,
        '-14': 32.237220125637236,
        '-13': 32.23723833058029,
        '-12': 32.23719643467229,
        '-11': 32.23708094267743,
        '-10': 32.23689456123228,
        '-9': 32.23665783397814,
        '-8': 32.236405797723535,
        '-7': 32.23618010065766,
        '-6': 32.2360185235864,
        '-5': 32.235944875735306,
        '-4': 32.23596238208599,
        '-3': 32.23605279387374,
        '-2': 32.236181749896055,
        '-1': 32.23630892112394
    },
    '70': {
        '0': 32.238510822824594,
        '1': 32.238470932674424,
        '2': 32.238391701473006,
        '3': 32.238293101261355,
        '4': 32.2382034911878,
        '5': 32.23815133499005,
        '6': 32.23815682366466,
        '7': 32.23822586734696,
        '8': 32.23834818382123,
        '9': 32.238499957204915,
        '10': 32.23865017193517,
        '11': 32.23876867529305,
        '12': 32.23883360338051,
        '13': 32.23883612363495,
        '14': 32.238781365434356,
        '15': 32.23868560914068,
        '16': 32.238570892308836,
        '17': 32.238458838317705,
        '18': 32.2383655487131,
        '19': 32.23606260117657,
        '20': 32.23825843742314,
        '21': 32.23809635817396,
        '22': 32.238228918814116,
        '23': 32.23374021580574,
        '24': 32.2333783543459,
        '25': 32.236766837542355,
        '26': 32.23059798216445,
        '27': 32.2348763668936,
        '28': 32.23798320051381,
        '29': 32.23573335373074,
        '30': 32.2380290132104,
        '31': 32.237944273586606,
        '32': 32.237837775980594,
        '33': 32.237716499090304,
        '34': 32.23759382120523,
        '35': 32.2374874024198,
        '36': 32.23741517981284,
        '37': 32.23739047004286,
        '38': 32.23741759190196,
        '39': 32.23748942164098,
        '40': 32.237587851519145,
        '41': 32.23768734526087,
        '42': 32.23776088698527,
        '43': 32.23778686257617,
        '44': 32.237755025785084,
        '45': 32.23766982092879,
        '46': 32.23754995676101,
        '47': 32.23742410507571,
        '48': 32.237323675942,
        '49': 32.237274497946686,
        '50': 32.23728964300307,
        '51': 32.237365433465214,
        '52': 32.23748187402962,
        '53': 32.23760755663299,
        '54': 32.23770781828697,
        '55': 32.23775395672611,
        '56': 32.237730939972394,
        '57': 32.237641405175864,
        '58': 32.2375047760861,
        '59': 32.237017964653,
        '60': 32.236679749494904,
        '61': 32.23712382882618,
        '62': 32.237090074454144,
        '63': 32.237112689227615,
        '64': 32.2371763444165,
        '65': 32.23725831747227,
        '66': 32.23733648296358,
        '67': 32.23739642351351,
        '68': 32.237030666668716,
        '69': 32.23730973310927,
        '70': 32.23705463885269,
        '71': 32.237352139571385,
        '72': 32.23756212202657,
        '73': 32.23769111296144,
        '74': 32.23761353724662,
        '75': 32.237460712986284,
        '76': 32.23730409969458,
        '77': 32.237182559926254,
        '78': 32.23745098712531,
        '79': 32.2376268986713,
        '80': 32.23740892973021,
        '81': 32.237580421610346,
        '82': 32.23729018282809,
        '83': 32.23757820774137,
        '84': 32.2372058071713,
        '85': 32.237302221703615,
        '86': 32.23752603684529,
        '87': 32.236729161347846,
        '88': 32.23766790066323,
        '89': 32.23588215516711,
        '90': 32.23280914736992,
        '91': 32.23029158376709,
        '92': 32.22836526095059,
        '93': 32.22795688504005,
        '94': 32.22984189513656,
        '95': 32.231774126096596,
        '96': 32.23224562598633,
        '97': 32.230536697156,
        '98': 32.23223764606255,
        '99': 32.231752505810185,
        '100': 32.23274756580848,
        '101': 32.234168446179325,
        '102': 32.232649594922606,
        '103': 32.23560134735565,
        '104': 32.234289496032474,
        '105': 32.233855110013444,
        '106': 32.231998290680366,
        '107': 32.23287151172575,
        '108': 32.23263832478456,
        '109': 32.23039386019518,
        '110': 32.23258752355231,
        '111': 32.236546054375395,
        '112': 32.23608897369266,
        '113': 32.23628039403613,
        '114': 32.236933407910676,
        '115': 32.236400731003805,
        '116': 32.23642643876033,
        '117': 32.23629953310638,
        '118': 32.23545977524096,
        '119': 32.23574153944156,
        '120': 32.23502822844064,
        '121': 32.23508832967921,
        '122': 32.23516260704568,
        '123': 32.23664711818598,
        '124': 32.23650212129324,
        '125': 32.237305202191386,
        '126': 32.236562473387785,
        '127': 32.233748632651505,
        '128': 32.23254026206722,
        '129': 32.22894230751228,
        '130': 32.23170878101996,
        '131': 32.2341186456498,
        '132': 32.23683995877134,
        '133': 32.23619597816531,
        '134': 32.23607597619853,
        '135': 32.23532873277539,
        '136': 32.23710646948991,
        '137': 32.23508183373286,
        '138': 32.23635942383881,
        '139': 32.23427955807449,
        '140': 32.23172926966241,
        '141': 32.23293505160798,
        '142': 32.23636910914142,
        '143': 32.236879952802816,
        '144': 32.236079646824216,
        '145': 32.23683787150804,
        '146': 32.23744786326889,
        '147': 32.237778309865135,
        '148': 32.236488532979024,
        '149': 32.23532779408751,
        '150': 32.23636050146484,
        '151': 32.23648297968179,
        '152': 32.23794055497537,
        '153': 32.23698841790584,
        '154': 32.23744871478051,
        '155': 32.237627671054135,
        '156': 32.23739480871453,
        '157': 32.237348839582516,
        '158': 32.23741069082751,
        '159': 32.237482390041535,
        '160': 32.23767231261023,
        '161': 32.237752229577694,
        '162': 32.23782791078781,
        '163': 32.237886148146664,
        '164': 32.23791746579784,
        '165': 32.237916729053744,
        '166': 32.23788327732646,
        '167': 32.237820757839025,
        '168': 32.23773668933488,
        '169': 32.23764166405986,
        '170': 32.23754807996335,
        '171': 32.234778587538614,
        '172': 32.23741316142135,
        '173': 32.23738902740937,
        '174': 32.237397514870786,
        '175': 32.237434649903626,
        '176': 32.237491845540006,
        '177': 32.23755784744106,
        '178': 32.237621308065556,
        '179': 32.23767333318648,
        '180': 32.23770931761077,
        '-180': 32.23770931761077,
        '-179': 32.23772956667775,
        '-178': 32.237738540993874,
        '-177': 32.23774296423393,
        '-176': 32.23774937427332,
        '-175': 32.23776186803478,
        '-174': 32.23778073171134,
        '-173': 32.237802373834576,
        '-172': 32.237820567576584,
        '-171': 32.237828591302055,
        '-170': 32.23782156277649,
        '-169': 32.23779819083827,
        '-168': 32.2377613480222,
        '-167': 32.237717245655006,
        '-166': 32.237673451972334,
        '-165': 32.23763638573092,
        '-164': 32.23760911332351,
        '-163': 32.237590206800185,
        '-162': 32.23722003690017,
        '-161': 32.236895301537515,
        '-160': 32.23721529721718,
        '-159': 32.237184461165185,
        '-158': 32.236793801599106,
        '-157': 32.23682905998505,
        '-156': 32.23676629087439,
        '-155': 32.23677172975549,
        '-154': 32.23648516784556,
        '-153': 32.2366747110757,
        '-152': 32.23683854121228,
        '-151': 32.23717526255708,
        '-150': 32.23632951317352,
        '-149': 32.23714791159309,
        '-148': 32.237084472875026,
        '-147': 32.23749768774114,
        '-146': 32.237715783540494,
        '-145': 32.23807351098934,
        '-144': 32.23788955324912,
        '-143': 32.23776412850705,
        '-142': 32.23797935813252,
        '-141': 32.23789081203539,
        '-140': 32.23779758835624,
        '-139': 32.23771517918587,
        '-138': 32.23765601649503,
        '-137': 32.23762697876484,
        '-136': 32.237627800410934,
        '-135': 32.237650890191865,
        '-134': 32.23768280390911,
        '-133': 32.23770721906885,
        '-132': 32.23770881961574,
        '-131': 32.237677180168,
        '-130': 32.23759955135456,
        '-129': 32.237512533830916,
        '-128': 32.236863861940506,
        '-127': 32.23703762914756,
        '-126': 32.237203564545965,
        '-125': 32.23715468876732,
        '-124': 32.2371518058814,
        '-123': 32.237193922384265,
        '-122': 32.237271511832446,
        '-121': 32.23736890168108,
        '-120': 32.23746785140912,
        '-119': 32.237551355741886,
        '-118': 32.23760672882394,
        '-117': 32.23571508533162,
        '-116': 32.23528536570858,
        '-115': 32.23497617693241,
        '-114': 32.233833051928386,
        '-113': 32.23521157988877,
        '-112': 32.23519178170179,
        '-111': 32.236377147862164,
        '-110': 32.23599746307384,
        '-109': 32.23603760637198,
        '-108': 32.235509786468725,
        '-107': 32.23577615845332,
        '-106': 32.23532942461731,
        '-105': 32.235112239764,
        '-104': 32.235935131992434,
        '-103': 32.2359556983471,
        '-102': 32.236257393105845,
        '-101': 32.23653967690806,
        '-100': 32.236796645729065,
        '-99': 32.23692497314691,
        '-98': 32.23703179067084,
        '-97': 32.23709626041242,
        '-96': 32.236418191516634,
        '-95': 32.23535166326233,
        '-94': 32.234657544177296,
        '-93': 32.23521682270116,
        '-92': 32.23675108871389,
        '-91': 32.236684084036106,
        '-90': 32.236647334885205,
        '-89': 32.23665145007935,
        '-88': 32.23668403100135,
        '-87': 32.2367236851053,
        '-86': 32.23674687969066,
        '-85': 32.23673585112221,
        '-84': 32.235967216510865,
        '-83': 32.23638300844355,
        '-82': 32.23514638998015,
        '-81': 32.23646668847548,
        '-80': 32.23645455129235,
        '-79': 32.236346722035286,
        '-78': 32.2367452460367,
        '-77': 32.236349681413444,
        '-76': 32.23498132805233,
        '-75': 32.23455328002352,
        '-74': 32.22834542391584,
        '-73': 32.228117057043356,
        '-72': 32.23216881285295,
        '-71': 32.23078168725778,
        '-70': 32.23472505447815,
        '-69': 32.23743681811673,
        '-68': 32.234769805861525,
        '-67': 32.23717926738238,
        '-66': 32.237250602267004,
        '-65': 32.23748567941282,
        '-64': 32.23786568400499,
        '-63': 32.23833938819571,
        '-62': 32.23883196428747,
        '-61': 32.23925909039316,
        '-60': 32.23954354866038,
        '-59': 32.239630914006625,
        '-58': 32.239501028922376,
        '-57': 32.23917275738218,
        '-56': 32.23870086674533,
        '-55': 32.23816553742852,
        '-54': 32.23477545141132,
        '-53': 32.22809231465284,
        '-52': 32.237021165718026,
        '-51': 32.23660208859782,
        '-50': 32.233165487194725,
        '-49': 32.223955582285754,
        '-48': 32.2200982179477,
        '-47': 32.21810024245337,
        '-46': 32.21642976482129,
        '-45': 32.2149927040382,
        '-44': 32.21348138488524,
        '-43': 32.212070294326566,
        '-42': 32.21064982998551,
        '-41': 32.2096829268519,
        '-40': 32.20867696773395,
        '-39': 32.20799146072246,
        '-38': 32.207580205941184,
        '-37': 32.20791081467437,
        '-36': 32.2085971829365,
        '-35': 32.20966048385828,
        '-34': 32.21101383992027,
        '-33': 32.211722105960185,
        '-32': 32.21246322619806,
        '-31': 32.21672276626996,
        '-30': 32.22009134239989,
        '-29': 32.22831987324066,
        '-28': 32.234273981655015,
        '-27': 32.22334708186411,
        '-26': 32.22292246685441,
        '-25': 32.22377631289721,
        '-24': 32.22712706473378,
        '-23': 32.233250488146695,
        '-22': 32.238882063754936,
        '-21': 32.23892522810536,
        '-20': 32.23897371440501,
        '-19': 32.239029537351826,
        '-18': 32.23909526028901,
        '-17': 32.23917079390501,
        '-16': 32.23925084857668,
        '-15': 32.239324101675216,
        '-14': 32.23937464320633,
        '-13': 32.23938554754379,
        '-12': 32.23934368621657,
        '-11': 32.239244373772415,
        '-10': 32.239094313205975,
        '-9': 32.238911662765354,
        '-8': 32.23872282065356,
        '-7': 32.23855651316463,
        '-6': 32.23843667670567,
        '-5': 32.2383761422114,
        '-4': 32.238373056546735,
        '-3': 32.23841128206759,
        '-2': 32.238464878797245,
        '-1': 32.23850553067917
    },
    '71': {
        '0': 32.240850937149915,
        '1': 32.24074006054482,
        '2': 32.24059202881891,
        '3': 32.24042843688812,
        '4': 32.240276696935986,
        '5': 32.240164182045326,
        '6': 32.2401121181103,
        '7': 32.24013072589711,
        '8': 32.24021679130717,
        '9': 32.24035420638054,
        '10': 32.24051726384389,
        '11': 32.24067582162236,
        '12': 32.24080106073141,
        '13': 32.24087052972349,
        '14': 32.2408714783165,
        '15': 32.24080201150422,
        '16': 32.24067017026916,
        '17': 32.24049149841474,
        '18': 32.24028587660788,
        '19': 32.24007437018301,
        '20': 32.23987660683484,
        '21': 32.23970888933952,
        '22': 32.23958298184766,
        '23': 32.239505375676536,
        '24': 32.23863749420708,
        '25': 32.239441851777556,
        '26': 32.23820630830578,
        '27': 32.239608856389715,
        '28': 32.238280457012685,
        '29': 32.23972688757492,
        '30': 32.23974452968913,
        '31': 32.23972079451453,
        '32': 32.239655309800376,
        '33': 32.23955685493638,
        '34': 32.23944210094209,
        '35': 32.23933240856614,
        '36': 32.23924925013795,
        '37': 32.2392092587816,
        '38': 32.23922011494755,
        '39': 32.239278370777406,
        '40': 32.239369890044046,
        '41': 32.239472939751984,
        '42': 32.239563273436396,
        '43': 32.2396199864108,
        '44': 32.239630662811045,
        '45': 32.23959446084889,
        '46': 32.239522279892526,
        '47': 32.2394339005272,
        '48': 32.239352791476634,
        '49': 32.23929991654976,
        '50': 32.23928816885152,
        '51': 32.239318916395625,
        '52': 32.239381590898326,
        '53': 32.23944631760534,
        '54': 32.23934765808267,
        '55': 32.23794085840043,
        '56': 32.238186729827,
        '57': 32.23946523680869,
        '58': 32.23935676172699,
        '59': 32.239224835924595,
        '60': 32.23909167193602,
        '61': 32.2389784479178,
        '62': 32.238900632598394,
        '63': 32.238865283083626,
        '64': 32.2388708656517,
        '65': 32.2389093545245,
        '66': 32.23896969283006,
        '67': 32.23897056579744,
        '68': 32.23898542490377,
        '69': 32.238909526586475,
        '70': 32.23864101981441,
        '71': 32.23902979968659,
        '72': 32.23930593926856,
        '73': 32.23948584822452,
        '74': 32.23914837452473,
        '75': 32.2393081390685,
        '76': 32.239321980841154,
        '77': 32.23940037831705,
        '78': 32.23954632896147,
        '79': 32.239423473892124,
        '80': 32.23926470950913,
        '81': 32.23859463536493,
        '82': 32.23905917566606,
        '83': 32.23931573674647,
        '84': 32.237936478096486,
        '85': 32.238791215922895,
        '86': 32.23865921592701,
        '87': 32.23845938659663,
        '88': 32.238821186457606,
        '89': 32.238708320603145,
        '90': 32.2385620815931,
        '91': 32.238972820846946,
        '92': 32.23903809501198,
        '93': 32.239082211032624,
        '94': 32.2395549299569,
        '95': 32.23934522931175,
        '96': 32.239510493232636,
        '97': 32.2392850704923,
        '98': 32.239251540636076,
        '99': 32.23894304204391,
        '100': 32.23845924553938,
        '101': 32.23856057976785,
        '102': 32.23694811975794,
        '103': 32.2361851221968,
        '104': 32.235418784016424,
        '105': 32.235426708854845,
        '106': 32.23624418099507,
        '107': 32.236330225625125,
        '108': 32.236008295817626,
        '109': 32.23514844667482,
        '110': 32.23636391117103,
        '111': 32.23687968539056,
        '112': 32.23808099076459,
        '113': 32.23839758096651,
        '114': 32.23878313636972,
        '115': 32.23802865527455,
        '116': 32.23855361535287,
        '117': 32.23892479829183,
        '118': 32.23771169045383,
        '119': 32.238701537015075,
        '120': 32.23901698205363,
        '121': 32.23894811569067,
        '122': 32.238023260447605,
        '123': 32.23598579904878,
        '124': 32.236126863450195,
        '125': 32.23783190207197,
        '126': 32.236600318076825,
        '127': 32.23777454170938,
        '128': 32.23671900012166,
        '129': 32.23749308406689,
        '130': 32.238916434258584,
        '131': 32.239150215179606,
        '132': 32.23896247071787,
        '133': 32.239182245865344,
        '134': 32.23889349879346,
        '135': 32.239193018179876,
        '136': 32.23935220015628,
        '137': 32.239441489697064,
        '138': 32.23946292219292,
        '139': 32.23925876114279,
        '140': 32.23905456038574,
        '141': 32.23878603276154,
        '142': 32.238891554895964,
        '143': 32.23914342093278,
        '144': 32.2392406166348,
        '145': 32.239364020066404,
        '146': 32.23938003625859,
        '147': 32.239614553083946,
        '148': 32.239665773808404,
        '149': 32.239695392757454,
        '150': 32.23983621019869,
        '151': 32.239798880348495,
        '152': 32.239710349412455,
        '153': 32.23962375460468,
        '154': 32.239512769028195,
        '155': 32.23928629757133,
        '156': 32.23925972235395,
        '157': 32.239263122095934,
        '158': 32.23935019364593,
        '159': 32.23944721790136,
        '160': 32.23949872418514,
        '161': 32.23954232951547,
        '162': 32.239570056550804,
        '163': 32.239580119477765,
        '164': 32.23957633295472,
        '165': 32.23956610860607,
        '166': 32.239557753037516,
        '167': 32.239557872694014,
        '168': 32.239569558488114,
        '169': 32.239591733141175,
        '170': 32.23961969546801,
        '171': 32.239646588655276,
        '172': 32.23966531982898,
        '173': 32.23967041553044,
        '174': 32.23965937820309,
        '175': 32.23963326739105,
        '176': 32.23959644130273,
        '177': 32.23955559030125,
        '178': 32.239518311435425,
        '179': 32.23702372218827,
        '180': 32.237770840384556,
        '-180': 32.237770840384556,
        '-179': 32.237361882461755,
        '-178': 32.23950713781544,
        '-177': 32.23953915812563,
        '-176': 32.239574893113435,
        '-175': 32.23960645398988,
        '-174': 32.23962671808312,
        '-173': 32.23963088983462,
        '-172': 32.23961767716094,
        '-171': 32.2395898036828,
        '-170': 32.23955368394548,
        '-169': 32.23951825072683,
        '-168': 32.2394931128505,
        '-167': 32.239486395910795,
        '-166': 32.239502729393045,
        '-165': 32.23954185326118,
        '-164': 32.23959820994338,
        '-163': 32.239661688294724,
        '-162': 32.239719363015325,
        '-161': 32.239757930526416,
        '-160': 32.23976629528116,
        '-159': 32.23973771562096,
        '-158': 32.23967104087901,
        '-157': 32.23944936945894,
        '-156': 32.239385423481764,
        '-155': 32.239298860819076,
        '-154': 32.23917183221672,
        '-153': 32.239045756204625,
        '-152': 32.2389389218859,
        '-151': 32.23885597433975,
        '-150': 32.23879820600258,
        '-149': 32.238764354769366,
        '-148': 32.238751722382794,
        '-147': 32.238757296175066,
        '-146': 32.23877862439997,
        '-145': 32.23881430619622,
        '-144': 32.23886406828842,
        '-143': 32.23892848033131,
        '-142': 32.239008399555445,
        '-141': 32.23910424439531,
        '-140': 32.23921519835993,
        '-139': 32.23933845834221,
        '-138': 32.23946866929412,
        '-137': 32.239597714153085,
        '-136': 32.239715026105436,
        '-135': 32.23980853348057,
        '-134': 32.23986622682803,
        '-133': 32.23987817110676,
        '-132': 32.239838617390674,
        '-131': 32.23974775527102,
        '-130': 32.23961263955982,
        '-129': 32.23944694634245,
        '-128': 32.2392694480909,
        '-127': 32.23910139129431,
        '-126': 32.23896323484547,
        '-125': 32.23887138481656,
        '-124': 32.2388355872625,
        '-123': 32.23885750338446,
        '-122': 32.23893072543448,
        '-121': 32.23904216823644,
        '-120': 32.239174476253936,
        '-119': 32.239308895345644,
        '-118': 32.23741481177596,
        '-117': 32.23590585926583,
        '-116': 32.23677643723013,
        '-115': 32.235813527735814,
        '-114': 32.23635654695844,
        '-113': 32.236315059986445,
        '-112': 32.237132956647244,
        '-111': 32.236471855492574,
        '-110': 32.2368621656436,
        '-109': 32.23744578181265,
        '-108': 32.23800292582702,
        '-107': 32.23760571433234,
        '-106': 32.237894228745134,
        '-105': 32.23809778396898,
        '-104': 32.23873546978883,
        '-103': 32.238822741887425,
        '-102': 32.23894592483542,
        '-101': 32.23908816656223,
        '-100': 32.239228447728884,
        '-99': 32.23934502803704,
        '-98': 32.239419512747325,
        '-97': 32.239440646015964,
        '-96': 32.23758524037377,
        '-95': 32.23643302420952,
        '-94': 32.23492862994083,
        '-93': 32.23866721318764,
        '-92': 32.239001165694745,
        '-91': 32.23893142002256,
        '-90': 32.23890048976656,
        '-89': 32.23857186742184,
        '-88': 32.23893534539089,
        '-87': 32.23897323410444,
        '-86': 32.2379615003375,
        '-85': 32.23874316202564,
        '-84': 32.23750409429161,
        '-83': 32.237481913112376,
        '-82': 32.23742045415222,
        '-81': 32.23778627546509,
        '-80': 32.23727200356771,
        '-79': 32.237675466411844,
        '-78': 32.236925817008526,
        '-77': 32.235404893875675,
        '-76': 32.236144774371255,
        '-75': 32.2338542916761,
        '-74': 32.23835798402335,
        '-73': 32.22647751530367,
        '-72': 32.23366363304154,
        '-71': 32.236116030580014,
        '-70': 32.239602576433576,
        '-69': 32.23939497941817,
        '-68': 32.23926259469461,
        '-67': 32.23925155565968,
        '-66': 32.239386843179325,
        '-65': 32.23966563921814,
        '-64': 32.24005630329485,
        '-63': 32.24050340866334,
        '-62': 32.24093794795368,
        '-61': 32.24129066324639,
        '-60': 32.24150573319427,
        '-59': 32.241551924131294,
        '-58': 32.24142881645169,
        '-57': 32.241166748163245,
        '-56': 32.240820456011434,
        '-55': 32.24045777807965,
        '-54': 32.24014577776806,
        '-53': 32.23993727702775,
        '-52': 32.239860812688796,
        '-51': 32.239915996242395,
        '-50': 32.225520967645195,
        '-49': 32.22218315247129,
        '-48': 32.220089921717275,
        '-47': 32.218475046850244,
        '-46': 32.216729518796114,
        '-45': 32.21514680225617,
        '-44': 32.213882036475084,
        '-43': 32.212704966599006,
        '-42': 32.211587946263776,
        '-41': 32.21062407195684,
        '-40': 32.20983485312951,
        '-39': 32.20922345370254,
        '-38': 32.20880216069436,
        '-37': 32.20879671590145,
        '-36': 32.20954198652762,
        '-35': 32.21044986759793,
        '-34': 32.211493763506155,
        '-33': 32.212721340379616,
        '-32': 32.21425881785094,
        '-31': 32.21599762017026,
        '-30': 32.219056806819815,
        '-29': 32.22774798861193,
        '-28': 32.23253242863245,
        '-27': 32.223686737539786,
        '-26': 32.235405196731875,
        '-25': 32.240241396222515,
        '-24': 32.239681093026576,
        '-23': 32.23519353727392,
        '-22': 32.23582902354785,
        '-21': 32.24062964426775,
        '-20': 32.24074883134475,
        '-19': 32.2408497042277,
        '-18': 32.24092791678167,
        '-17': 32.24098313564918,
        '-16': 32.24101699449161,
        '-15': 32.241031344638,
        '-14': 32.24102740982683,
        '-13': 32.24100604330137,
        '-12': 32.240968829338286,
        '-11': 32.240919419092066,
        '-10': 32.24086436172519,
        '-9': 32.240812835947764,
        '-8': 32.240775065993034,
        '-7': 32.24075970137792,
        '-6': 32.24077088733306,
        '-5': 32.24080599362528,
        '-4': 32.2408549028565,
        '-3': 32.24090137974806,
        '-2': 32.240926448286125,
        '-1': 32.240913069317415
    },
    '72': {
        '0': 32.24306697099981,
        '1': 32.2429746136446,
        '2': 32.242843471836885,
        '3': 32.24268998074903,
        '4': 32.24253450429952,
        '5': 32.242398018912816,
        '6': 32.24229849396009,
        '7': 32.24224768792284,
        '8': 32.242249001072295,
        '9': 32.24229679877549,
        '10': 32.24237730649527,
        '11': 32.24247085984263,
        '12': 32.24255504734505,
        '13': 32.24260816012542,
        '14': 32.24261237357003,
        '15': 32.242556206542595,
        '16': 32.242435984681755,
        '17': 32.242256221078826,
        '18': 32.24202897784463,
        '19': 32.24177236701766,
        '20': 32.24150839503693,
        '21': 32.24126037418389,
        '22': 32.24105014216015,
        '23': 32.24089536136087,
        '24': 32.24080720731383,
        '25': 32.2407887777947,
        '26': 32.24083452825346,
        '27': 32.240930939503336,
        '28': 32.24105844476476,
        '29': 32.241194408443626,
        '30': 32.241316708679555,
        '31': 32.241407294731495,
        '32': 32.241455029302756,
        '33': 32.241457220912864,
        '34': 32.24141949894461,
        '35': 32.24135403716639,
        '36': 32.24127650896077,
        '37': 32.24120246332133,
        '38': 32.24114396059765,
        '39': 32.241107252893016,
        '40': 32.241092040096305,
        '41': 32.24109243718166,
        '42': 32.24109935099248,
        '43': 32.24110359989477,
        '44': 32.24109891626848,
        '45': 32.24108400499422,
        '46': 32.24106308592394,
        '47': 32.241044759474,
        '48': 32.241039492815176,
        '49': 32.24105640583208,
        '50': 32.241100236453825,
        '51': 32.241169328017214,
        '52': 32.240901119664024,
        '53': 32.237448799023504,
        '54': 32.238241760257175,
        '55': 32.239551894632754,
        '56': 32.2414690531648,
        '57': 32.241428029781524,
        '58': 32.241344521578,
        '59': 32.24122870630268,
        '60': 32.24109571225108,
        '61': 32.24096267049379,
        '62': 32.24084567360627,
        '63': 32.24075731942596,
        '64': 32.24070528521577,
        '65': 32.240692053181256,
        '66': 32.240715596802985,
        '67': 32.24077063019913,
        '68': 32.24084996965501,
        '69': 32.240773704620004,
        '70': 32.24068549287257,
        '71': 32.24084060693407,
        '72': 32.240948530474334,
        '73': 32.241336484852354,
        '74': 32.241401857862975,
        '75': 32.241241622582855,
        '76': 32.24146073609083,
        '77': 32.241290298111124,
        '78': 32.24142067451707,
        '79': 32.241107037694796,
        '80': 32.24122420365048,
        '81': 32.241230349054774,
        '82': 32.24114951989079,
        '83': 32.24062058067581,
        '84': 32.24061716375935,
        '85': 32.2405455750382,
        '86': 32.239773184364054,
        '87': 32.24003501294914,
        '88': 32.24018530796337,
        '89': 32.2403520874741,
        '90': 32.24041839140073,
        '91': 32.240050580046436,
        '92': 32.24030455175898,
        '93': 32.240471464808614,
        '94': 32.239828093587924,
        '95': 32.24046188354553,
        '96': 32.24085284167233,
        '97': 32.24038737982819,
        '98': 32.240463838758785,
        '99': 32.240497029020354,
        '100': 32.23987865386836,
        '101': 32.24008663016081,
        '102': 32.2406242866094,
        '103': 32.240684274428794,
        '104': 32.23973120461417,
        '105': 32.24032236632593,
        '106': 32.23739931107837,
        '107': 32.238299061345046,
        '108': 32.23816071854643,
        '109': 32.23858394837314,
        '110': 32.239136352382644,
        '111': 32.24005684897405,
        '112': 32.240524543644284,
        '113': 32.24001108793481,
        '114': 32.24025666614283,
        '115': 32.24015493213867,
        '116': 32.24010684035989,
        '117': 32.24022660093467,
        '118': 32.24044216526146,
        '119': 32.24104376032169,
        '120': 32.240978759776,
        '121': 32.24073122877088,
        '122': 32.241347842707164,
        '123': 32.24045402870692,
        '124': 32.241347447343095,
        '125': 32.2391906187323,
        '126': 32.23795385635919,
        '127': 32.24111435748941,
        '128': 32.23797944680507,
        '129': 32.24082231125537,
        '130': 32.24074015115939,
        '131': 32.24072113781167,
        '132': 32.2407589024084,
        '133': 32.240837987089186,
        '134': 32.24093785069579,
        '135': 32.24103766983588,
        '136': 32.241120852194214,
        '137': 32.24117826273955,
        '138': 32.24120946965304,
        '139': 32.241221786703186,
        '140': 32.241106035803554,
        '141': 32.241087743459,
        '142': 32.240994764375166,
        '143': 32.24110387116955,
        '144': 32.24121897792455,
        '145': 32.24140027504609,
        '146': 32.241440936137394,
        '147': 32.24133647253518,
        '148': 32.24132939887482,
        '149': 32.241507584049586,
        '150': 32.24145141413399,
        '151': 32.24136617815871,
        '152': 32.24127975090819,
        '153': 32.24121040529874,
        '154': 32.24117252420754,
        '155': 32.241173133593406,
        '156': 32.24121026741708,
        '157': 32.24127357918252,
        '158': 32.241347036575874,
        '159': 32.24141298936497,
        '160': 32.241456592279505,
        '161': 32.24146947246263,
        '162': 32.241451735664285,
        '163': 32.24141184799145,
        '164': 32.2413644745332,
        '165': 32.24132685576977,
        '166': 32.24131460166892,
        '167': 32.24133793210982,
        '168': 32.241399230478116,
        '169': 32.24149243738269,
        '170': 32.2416043616447,
        '171': 32.24171754296572,
        '172': 32.24181396665074,
        '173': 32.241878745526556,
        '174': 32.24190301416441,
        '175': 32.24188541848488,
        '176': 32.241831954711124,
        '177': 32.241754287032684,
        '178': 32.2416669940879,
        '179': 32.24158439771424,
        '180': 32.241517675878185,
        '-180': 32.241517675878185,
        '-179': 32.24147286027659,
        '-178': 32.24145009357645,
        '-177': 32.241444228813194,
        '-176': 32.241446577671155,
        '-175': 32.241447369057624,
        '-174': 32.241438348843765,
        '-173': 32.241414942688294,
        '-172': 32.24137751497177,
        '-171': 32.24133146128683,
        '-170': 32.24128612536627,
        '-169': 32.2412527810131,
        '-168': 32.24124211452111,
        '-167': 32.241261743912546,
        '-166': 32.24131429829423,
        '-165': 32.24139645777143,
        '-164': 32.24149915014235,
        '-163': 32.241608851742164,
        '-162': 32.24170972311844,
        '-161': 32.24178615171193,
        '-160': 32.24182518140003,
        '-159': 32.241818385767935,
        '-158': 32.241762891552206,
        '-157': 32.24166141680641,
        '-156': 32.24152142971298,
        '-155': 32.24135368727361,
        '-154': 32.24117050654802,
        '-153': 32.24098412330454,
        '-152': 32.24080541575547,
        '-151': 32.240643143495326,
        '-150': 32.24050371241634,
        '-149': 32.24039136166446,
        '-148': 32.24030860283195,
        '-147': 32.24025673121845,
        '-146': 32.24023626395475,
        '-145': 32.24024721909066,
        '-144': 32.24028921038394,
        '-143': 32.24036137762379,
        '-142': 32.240462195836066,
        '-141': 32.240589213205325,
        '-140': 32.24073876761565,
        '-139': 32.2409057353553,
        '-138': 32.241083376357324,
        '-137': 32.24126335319229,
        '-136': 32.24143600398838,
        '-135': 32.241590929576056,
        '-134': 32.24171790534233,
        '-133': 32.24180805251916,
        '-132': 32.24185511810513,
        '-131': 32.241856641929104,
        '-130': 32.24181475890818,
        '-129': 32.24173641169092,
        '-128': 32.24163283604126,
        '-127': 32.241518313625555,
        '-126': 32.24140833525699,
        '-125': 32.240406887318144,
        '-124': 32.23939567853013,
        '-123': 32.237996876060414,
        '-122': 32.239185218655535,
        '-121': 32.23886870184142,
        '-120': 32.241367100909734,
        '-119': 32.24144505816378,
        '-118': 32.239338304856936,
        '-117': 32.23958507186229,
        '-116': 32.238457380062044,
        '-115': 32.23843588380578,
        '-114': 32.23947644418444,
        '-113': 32.239415821934706,
        '-112': 32.237282257899736,
        '-111': 32.23811953680937,
        '-110': 32.23647375194836,
        '-109': 32.239810093781465,
        '-108': 32.24034249950071,
        '-107': 32.23927347131471,
        '-106': 32.23852714631132,
        '-105': 32.24022188298037,
        '-104': 32.24038132237106,
        '-103': 32.240560372866604,
        '-102': 32.24077981917791,
        '-101': 32.241014973212444,
        '-100': 32.24112825630861,
        '-99': 32.24064015039636,
        '-98': 32.24123343432495,
        '-97': 32.24123849924787,
        '-96': 32.24165789456628,
        '-95': 32.24032415656137,
        '-94': 32.24154558442015,
        '-93': 32.241453626495804,
        '-92': 32.24136205180488,
        '-91': 32.24128438061214,
        '-90': 32.24122750278315,
        '-89': 32.23954196303085,
        '-88': 32.23896421517256,
        '-87': 32.23862586015559,
        '-86': 32.24114022549552,
        '-85': 32.239653938565844,
        '-84': 32.23972492857522,
        '-83': 32.238175159675805,
        '-82': 32.238174040470334,
        '-81': 32.23852098360948,
        '-80': 32.23819407095995,
        '-79': 32.24136349648607,
        '-78': 32.23580148984517,
        '-77': 32.22863387210279,
        '-76': 32.23454480442337,
        '-75': 32.23282274726874,
        '-74': 32.24222676431564,
        '-73': 32.24228900465223,
        '-72': 32.242289148114494,
        '-71': 32.24223930283787,
        '-70': 32.242162455111945,
        '-69': 32.2420875615469,
        '-68': 32.24204301712406,
        '-67': 32.24204998167922,
        '-66': 32.242117146333065,
        '-65': 32.24223823581153,
        '-64': 32.242392936298074,
        '-63': 32.24255114923131,
        '-62': 32.242679683922645,
        '-61': 32.24274990342503,
        '-60': 32.24274457407938,
        '-59': 32.24266230818029,
        '-58': 32.24251850655056,
        '-57': 32.2423424917586,
        '-56': 32.24217146551802,
        '-55': 32.23511306520269,
        '-54': 32.23050431203618,
        '-53': 32.227344182045535,
        '-52': 32.22528314079726,
        '-51': 32.224501878425194,
        '-50': 32.22360210779578,
        '-49': 32.22137230584115,
        '-48': 32.21931577677606,
        '-47': 32.21775429776908,
        '-46': 32.216439469161735,
        '-45': 32.215191350612805,
        '-44': 32.21409803363026,
        '-43': 32.21311507553756,
        '-42': 32.21225952737717,
        '-41': 32.21155088793225,
        '-40': 32.21096388836353,
        '-39': 32.21055622146653,
        '-38': 32.21031738581664,
        '-37': 32.21074912939356,
        '-36': 32.211619304348154,
        '-35': 32.212646844200705,
        '-34': 32.2136309990629,
        '-33': 32.214649527351746,
        '-32': 32.215666758921934,
        '-31': 32.21780644085648,
        '-30': 32.22321468833019,
        '-29': 32.22975626379873,
        '-28': 32.23738842765126,
        '-27': 32.22742914862443,
        '-26': 32.23631383759945,
        '-25': 32.22264307227043,
        '-24': 32.23505438938403,
        '-23': 32.238022255474384,
        '-22': 32.242403132787125,
        '-21': 32.2425532186492,
        '-20': 32.24266839104772,
        '-19': 32.24273551120479,
        '-18': 32.24275126089422,
        '-17': 32.242721135279524,
        '-16': 32.24265707424552,
        '-15': 32.2425744692545,
        '-14': 32.2424892674291,
        '-13': 32.24241570472286,
        '-12': 32.24236491202305,
        '-11': 32.24234434690268,
        '-10': 32.242357795154874,
        '-9': 32.24240561225049,
        '-8': 32.242484940470774,
        '-7': 32.24258980178674,
        '-6': 32.24271115793755,
        '-5': 32.24283716959947,
        '-4': 32.242953917715624,
        '-3': 32.243046752812425,
        '-2': 32.24310223916035,
        '-1': 32.24311042421109
    },
    '73': {
        '0': 32.24456665323584,
        '1': 32.244572466738894,
        '2': 32.244557475347186,
        '3': 32.24452523297359,
        '4': 32.24448129900199,
        '5': 32.2444326137973,
        '6': 32.244386548015804,
        '7': 32.244349756194524,
        '8': 32.24432701845695,
        '9': 32.244320269717534,
        '10': 32.24432799293534,
        '11': 32.24434509961072,
        '12': 32.24436334926145,
        '13': 32.244372282857945,
        '14': 32.24436057334372,
        '15': 32.24431763608606,
        '16': 32.24423529751953,
        '17': 32.24410929486062,
        '18': 32.24394037798332,
        '19': 32.24373481164034,
        '20': 32.24350413646266,
        '21': 32.243264140590234,
        '22': 32.243033112934754,
        '23': 32.24282957748303,
        '24': 32.24266982160097,
        '25': 32.24256560283178,
        '26': 32.24252242501303,
        '27': 32.24253870391579,
        '28': 32.24260600025361,
        '29': 32.24271030744052,
        '30': 32.242834181212785,
        '31': 32.24295933276137,
        '32': 32.24306921612595,
        '33': 32.24315114840722,
        '34': 32.243197609000156,
        '35': 32.24320654807001,
        '36': 32.24318075178195,
        '37': 32.24312651008005,
        '38': 32.243051964006035,
        '39': 32.242965542450136,
        '40': 32.242874827050464,
        '41': 32.242786030204144,
        '42': 32.242704078121825,
        '43': 32.24263311207249,
        '44': 32.24257710564813,
        '45': 32.24254027540103,
        '46': 32.24252704137205,
        '47': 32.24254144963082,
        '48': 32.24258615612437,
        '49': 32.242661235771855,
        '50': 32.24276317457743,
        '51': 32.242884396649735,
        '52': 32.24301357104521,
        '53': 32.2431367619512,
        '54': 32.23890819003629,
        '55': 32.23679078251647,
        '56': 32.24089382420347,
        '57': 32.24331029978561,
        '58': 32.24324162231442,
        '59': 32.24313477316841,
        '60': 32.24300242957367,
        '61': 32.24286030552868,
        '62': 32.242724943592215,
        '63': 32.242611522592625,
        '64': 32.24253202790035,
        '65': 32.24249402369783,
        '66': 32.24250013001397,
        '67': 32.242548176609446,
        '68': 32.242631908192564,
        '69': 32.24274206344607,
        '70': 32.24286764125412,
        '71': 32.24299718799533,
        '72': 32.2431199750558,
        '73': 32.243226954457924,
        '74': 32.24331143332317,
        '75': 32.24336941400773,
        '76': 32.24339958014924,
        '77': 32.24340295489816,
        '78': 32.24338230798787,
        '79': 32.243300949956016,
        '80': 32.24328449136489,
        '81': 32.24266896062026,
        '82': 32.24051718805434,
        '83': 32.24047541768803,
        '84': 32.24146398245015,
        '85': 32.24250536332702,
        '86': 32.2421172307749,
        '87': 32.24231787390621,
        '88': 32.2426470396961,
        '89': 32.24145021175999,
        '90': 32.24218799761332,
        '91': 32.24255327601317,
        '92': 32.24203532890601,
        '93': 32.241829313315556,
        '94': 32.24171231914852,
        '95': 32.24279642616549,
        '96': 32.24247278955671,
        '97': 32.24187939789432,
        '98': 32.24233150891258,
        '99': 32.241548324602995,
        '100': 32.241957328176774,
        '101': 32.24206333765438,
        '102': 32.24229263190088,
        '103': 32.24143009694272,
        '104': 32.24216881808379,
        '105': 32.241786188824555,
        '106': 32.242488914524664,
        '107': 32.242122539300276,
        '108': 32.24177812587478,
        '109': 32.24222591157818,
        '110': 32.24226954213412,
        '111': 32.24216492188498,
        '112': 32.24209295247019,
        '113': 32.24186449132212,
        '114': 32.2421809729108,
        '115': 32.24214442424156,
        '116': 32.24175119982571,
        '117': 32.24191030134689,
        '118': 32.24120834218454,
        '119': 32.24198671201746,
        '120': 32.242478722895214,
        '121': 32.24287898632766,
        '122': 32.24294070903202,
        '123': 32.24295969363594,
        '124': 32.24292609335918,
        '125': 32.24283781472981,
        '126': 32.24273927386997,
        '127': 32.24270838661127,
        '128': 32.24264078095958,
        '129': 32.242610712666675,
        '130': 32.242605752435004,
        '131': 32.242637020163066,
        '132': 32.242699191796895,
        '133': 32.242782360117,
        '134': 32.24287460845583,
        '135': 32.24296477261483,
        '136': 32.243044733761224,
        '137': 32.24311072054405,
        '138': 32.24316335752337,
        '139': 32.24320651724417,
        '140': 32.2432453371747,
        '141': 32.24328397512247,
        '142': 32.24332374341835,
        '143': 32.243362163781676,
        '144': 32.24339324289086,
        '145': 32.243408941883224,
        '146': 32.243401483635296,
        '147': 32.24336589571692,
        '148': 32.24330209153333,
        '149': 32.243215876856304,
        '150': 32.243118530296925,
        '151': 32.24302494370752,
        '152': 32.242950689781175,
        '153': 32.242908674892966,
        '154': 32.24290617028174,
        '155': 32.24294295623568,
        '156': 32.2430110703851,
        '157': 32.243096281652626,
        '158': 32.24318100195558,
        '159': 32.24324798328769,
        '160': 32.24328401046445,
        '161': 32.243282734421655,
        '162': 32.24324600816258,
        '163': 32.24318343011696,
        '164': 32.2431102093052,
        '165': 32.2430438409831,
        '166': 32.24300035759086,
        '167': 32.24299097224504,
        '168': 32.24301982079188,
        '169': 32.243083273569496,
        '170': 32.24317090687953,
        '171': 32.24326786174296,
        '172': 32.243358026130856,
        '173': 32.24342732367846,
        '174': 32.24346640183642,
        '175': 32.24347217847894,
        '176': 32.243447992284054,
        '177': 32.24340240069722,
        '178': 32.24334697122434,
        '179': 32.243293607557874,
        '180': 32.2432520226204,
        '-180': 32.2432520226204,
        '-179': 32.24322789467355,
        '-178': 32.243222062363785,
        '-177': 32.243230866724474,
        '-176': 32.24324748614585,
        '-175': 32.243263900094014,
        '-174': 32.24327299271955,
        '-173': 32.24327030964921,
        '-172': 32.243255077549556,
        '-171': 32.24323027940643,
        '-170': 32.243201800808386,
        '-169': 32.24317687126753,
        '-168': 32.24316217624327,
        '-167': 32.24316207899854,
        '-166': 32.24317735633495,
        '-165': 32.243204729687356,
        '-164': 32.24323728029689,
        '-163': 32.243265674340115,
        '-162': 32.24327992855512,
        '-161': 32.24327133119132,
        '-160': 32.24323413547778,
        '-159': 32.24316668723903,
        '-158': 32.243071778437354,
        '-157': 32.242956181985015,
        '-156': 32.24282948669562,
        '-155': 32.24270248072297,
        '-154': 32.24258540319659,
        '-153': 32.24248638739509,
        '-152': 32.24241036033599,
        '-151': 32.242358561096204,
        '-150': 32.242328718352866,
        '-149': 32.242315811780216,
        '-148': 32.24231325204536,
        '-147': 32.24231426156577,
        '-146': 32.24231322505386,
        '-145': 32.242306799990686,
        '-144': 32.242294623369325,
        '-143': 32.24227951257757,
        '-142': 32.24226712709398,
        '-141': 32.24226512798211,
        '-140': 32.24228193954753,
        '-139': 32.24232527718559,
        '-138': 32.24240065092944,
        '-137': 32.24251007730431,
        '-136': 32.24265122441189,
        '-135': 32.24281717096945,
        '-134': 32.24299687933109,
        '-133': 32.243176373285564,
        '-132': 32.24334048971556,
        '-131': 32.24347496089221,
        '-130': 32.2435685045557,
        '-129': 32.24361457128103,
        '-128': 32.243612433229046,
        '-127': 32.24356739337828,
        '-126': 32.2434900353397,
        '-125': 32.24339460183797,
        '-124': 32.242466810198906,
        '-123': 32.241672632582386,
        '-122': 32.241933529746646,
        '-121': 32.24066440918521,
        '-120': 32.24156763157531,
        '-119': 32.24179245739376,
        '-118': 32.24159926576742,
        '-117': 32.243147989692346,
        '-116': 32.24234335944042,
        '-115': 32.24146867841334,
        '-114': 32.24297416745519,
        '-113': 32.2428094559828,
        '-112': 32.24262032914723,
        '-111': 32.242390574504306,
        '-110': 32.2421509842365,
        '-109': 32.241926229214705,
        '-108': 32.2403066584161,
        '-107': 32.240084071495176,
        '-106': 32.2414358658283,
        '-105': 32.24162161556193,
        '-104': 32.24175054511329,
        '-103': 32.241951984808836,
        '-102': 32.241608975114055,
        '-101': 32.24248532354264,
        '-100': 32.242601765000444,
        '-99': 32.24195211935037,
        '-98': 32.243177626762,
        '-97': 32.242075336221156,
        '-96': 32.24343932842078,
        '-95': 32.24198919337671,
        '-94': 32.2405400006961,
        '-93': 32.24164651745371,
        '-92': 32.24141660571912,
        '-91': 32.24317301612261,
        '-90': 32.24307985454531,
        '-89': 32.24150756476552,
        '-88': 32.23999645758618,
        '-87': 32.24003338037264,
        '-86': 32.24280137409978,
        '-85': 32.24065504433353,
        '-84': 32.23984989163979,
        '-83': 32.23471020223061,
        '-82': 32.2398137338922,
        '-81': 32.24083228088425,
        '-80': 32.24237229373038,
        '-79': 32.236709511777896,
        '-78': 32.230533680438995,
        '-77': 32.23449042547596,
        '-76': 32.24379884319103,
        '-75': 32.24405264850491,
        '-74': 32.24428647165192,
        '-73': 32.2444841562134,
        '-72': 32.244634152742385,
        '-71': 32.244730565076544,
        '-70': 32.244772984598896,
        '-69': 32.244765261013285,
        '-68': 32.24471362898259,
        '-67': 32.2446247666744,
        '-66': 32.2445043618027,
        '-65': 32.2443566008396,
        '-64': 32.244184717592,
        '-63': 32.243992410924974,
        '-62': 32.24378565536068,
        '-61': 32.24357426228487,
        '-60': 32.2433725548452,
        '-59': 32.24319870508603,
        '-58': 32.24307260985299,
        '-57': 32.24301260256489,
        '-56': 32.24303164569395,
        '-55': 32.24313356386326,
        '-54': 32.23582912186966,
        '-53': 32.23025711384178,
        '-52': 32.22656998905341,
        '-51': 32.22412747104703,
        '-50': 32.22212773764618,
        '-49': 32.220543276472036,
        '-48': 32.21906427927589,
        '-47': 32.21787054701358,
        '-46': 32.21678271306403,
        '-45': 32.21569516356922,
        '-44': 32.21474320028293,
        '-43': 32.213892405013546,
        '-42': 32.21315427799851,
        '-41': 32.212549989111196,
        '-40': 32.21207623010218,
        '-39': 32.21181528799262,
        '-38': 32.21179569903714,
        '-37': 32.211915949794005,
        '-36': 32.21207178152997,
        '-35': 32.212627762306425,
        '-34': 32.21338188893644,
        '-33': 32.21387217885966,
        '-32': 32.214762733935615,
        '-31': 32.21627480311672,
        '-30': 32.21976942322207,
        '-29': 32.22441726157556,
        '-28': 32.22468697912612,
        '-27': 32.226499069567375,
        '-26': 32.22854680785419,
        '-25': 32.24393137470192,
        '-24': 32.241602641180165,
        '-23': 32.24177156170931,
        '-22': 32.244353327591774,
        '-21': 32.24446681300256,
        '-20': 32.244534042708366,
        '-19': 32.24454699528758,
        '-18': 32.24450697404288,
        '-17': 32.24442341167419,
        '-16': 32.24431141532962,
        '-15': 32.244188666154045,
        '-14': 32.244072346677854,
        '-13': 32.24397666055976,
        '-12': 32.24391129510357,
        '-11': 32.24388092050676,
        '-10': 32.24388558643077,
        '-9': 32.24392171789823,
        '-8': 32.243983355170315,
        '-7': 32.244063323401484,
        '-6': 32.24415412962509,
        '-5': 32.244248524285844,
        '-4': 32.24433978788741,
        '-3': 32.244421877023655,
        '-2': 32.244489573759175,
        '-1': 32.24453873458537
    },
    '74': {
        '0': 32.24541769483152,
        '1': 32.24551139829554,
        '2': 32.24562363650717,
        '3': 32.245744120125536,
        '4': 32.245861868722876,
        '5': 32.24596686255218,
        '6': 32.246051450402184,
        '7': 32.2461112653912,
        '8': 32.24614550032329,
        '9': 32.246156518308666,
        '10': 32.24614889956818,
        '11': 32.24612812917909,
        '12': 32.24609919474007,
        '13': 32.24606537642063,
        '14': 32.246027472381016,
        '15': 32.24598361717676,
        '16': 32.245929734879894,
        '17': 32.245860543503014,
        '18': 32.245770916434374,
        '19': 32.24565733151242,
        '20': 32.245519114428575,
        '21': 32.24535921618421,
        '22': 32.24518434906377,
        '23': 32.245004426194534,
        '24': 32.24483138271477,
        '25': 32.24467757529945,
        '26': 32.24455403726532,
        '27': 32.24446889246511,
        '28': 32.24442619793981,
        '29': 32.244425400744106,
        '30': 32.24446147730767,
        '31': 32.24452569952156,
        '32': 32.24460686646255,
        '33': 32.24469277490883,
        '34': 32.24477168614888,
        '35': 32.2448335795298,
        '36': 32.24487105213949,
        '37': 32.244879809044086,
        '38': 32.2448587678391,
        '39': 32.244809857003936,
        '40': 32.2447376100325,
        '41': 32.244648647161135,
        '42': 32.24455110366836,
        '43': 32.244454023946865,
        '44': 32.24436671041136,
        '45': 32.244298008042065,
        '46': 32.24425552316074,
        '47': 32.24424481373298,
        '48': 32.24426863510146,
        '49': 32.24432636264554,
        '50': 32.24441372567501,
        '51': 32.24452296520849,
        '52': 32.244643473700634,
        '53': 32.24476288551725,
        '54': 32.244868502308464,
        '55': 32.24354213467831,
        '56': 32.23985434652063,
        '57': 32.23874901982472,
        '58': 32.24471866140369,
        '59': 32.24490565677448,
        '60': 32.24481316788409,
        '61': 32.24470522161635,
        '62': 32.244594102864276,
        '63': 32.244491751115525,
        '64': 32.244408273063364,
        '65': 32.244350791234275,
        '66': 32.24432277755828,
        '67': 32.24432393428826,
        '68': 32.24435059907359,
        '69': 32.24439657619548,
        '70': 32.24445424149536,
        '71': 32.24451574024452,
        '72': 32.2445740957104,
        '73': 32.24462407335195,
        '74': 32.24466268911659,
        '75': 32.24468931176857,
        '76': 32.24470537578553,
        '77': 32.24471378512538,
        '78': 32.24471813781281,
        '79': 32.24472192597551,
        '80': 32.24472786571099,
        '81': 32.24473747723583,
        '82': 32.24475097766609,
        '83': 32.24476747662488,
        '84': 32.24457287299313,
        '85': 32.244802975132956,
        '86': 32.24481873831862,
        '87': 32.24479127017028,
        '88': 32.24465947485347,
        '89': 32.243502335833874,
        '90': 32.242554502932165,
        '91': 32.242604718565914,
        '92': 32.2441475490827,
        '93': 32.243193373930815,
        '94': 32.2432210055436,
        '95': 32.24293248945194,
        '96': 32.240201311207294,
        '97': 32.24451851558164,
        '98': 32.24377385971517,
        '99': 32.24338500225546,
        '100': 32.24435904518732,
        '101': 32.243526106465296,
        '102': 32.243836436814156,
        '103': 32.243059999858446,
        '104': 32.24360692446053,
        '105': 32.24285856070765,
        '106': 32.243239892464075,
        '107': 32.24275498123404,
        '108': 32.24273371809626,
        '109': 32.24418259171157,
        '110': 32.24418562456252,
        '111': 32.24422724590484,
        '112': 32.24424649669489,
        '113': 32.24426469998122,
        '114': 32.24428359366862,
        '115': 32.24430467301687,
        '116': 32.24432842708456,
        '117': 32.24435391736415,
        '118': 32.24437883033755,
        '119': 32.244400007005034,
        '120': 32.244414320794846,
        '121': 32.244419674745586,
        '122': 32.244415845890636,
        '123': 32.244404931471536,
        '124': 32.24439124269197,
        '125': 32.24438062576634,
        '126': 32.24437933455114,
        '127': 32.24439269839898,
        '128': 32.244423892761354,
        '129': 32.24447311101025,
        '130': 32.244537353727516,
        '131': 32.24461091379369,
        '132': 32.24468647339776,
        '133': 32.24475658079177,
        '134': 32.24481517632179,
        '135': 32.24485881430025,
        '136': 32.243895646558535,
        '137': 32.244903496395615,
        '138': 32.24491257136993,
        '139': 32.24492045333217,
        '140': 32.24493225251184,
        '141': 32.244799012638715,
        '142': 32.24497566913324,
        '143': 32.24500317568935,
        '144': 32.24502698404871,
        '145': 32.24503960763991,
        '146': 32.24503424303997,
        '147': 32.2450065860513,
        '148': 32.24495618144499,
        '149': 32.244886961455975,
        '150': 32.24480680726239,
        '151': 32.24472619187736,
        '152': 32.24465617925988,
        '153': 32.24460621100549,
        '154': 32.244582170416706,
        '155': 32.244585159567,
        '156': 32.244611271206885,
        '157': 32.24465240924251,
        '158': 32.24469798916085,
        '159': 32.244737145612994,
        '160': 32.24476096056594,
        '161': 32.24476423255283,
        '162': 32.244746409657104,
        '163': 32.24471150134515,
        '164': 32.244667014668096,
        '165': 32.24462217565989,
        '166': 32.24458584750465,
        '167': 32.24456461767572,
        '168': 32.24456146354486,
        '169': 32.2445752704865,
        '170': 32.24460127755177,
        '171': 32.244632315779015,
        '172': 32.24466053418225,
        '173': 32.24467921268089,
        '174': 32.244684258590375,
        '175': 32.244675070380715,
        '176': 32.24465460576282,
        '177': 32.244628673500905,
        '178': 32.24460463763915,
        '179': 32.24458984195759,
        '180': 32.24459010734436,
        '-180': 32.24459010734436,
        '-179': 32.24460861908486,
        '-178': 32.244645416897306,
        '-177': 32.24469755474144,
        '-176': 32.24475984491044,
        '-175': 32.24482597642703,
        '-174': 32.2448897280703,
        '-173': 32.24494599459194,
        '-172': 32.24499140800423,
        '-171': 32.245024446773165,
        '-170': 32.24504505676337,
        '-169': 32.24505392777802,
        '-168': 32.245051651007934,
        '-167': 32.245038007741165,
        '-166': 32.24501160367968,
        '-165': 32.24496997547881,
        '-164': 32.24491017746087,
        '-163': 32.24482973420771,
        '-162': 32.24472774661994,
        '-161': 32.244605887040244,
        '-160': 32.24446902483759,
        '-159': 32.244325286543976,
        '-158': 32.244185461339804,
        '-157': 32.24406179159563,
        '-156': 32.24396631337554,
        '-155': 32.2439090086738,
        '-154': 32.243896081207694,
        '-153': 32.24392866180189,
        '-152': 32.244002189398934,
        '-151': 32.24410661082458,
        '-150': 32.24422741486895,
        '-149': 32.24434738542716,
        '-148': 32.24444884506125,
        '-147': 32.244516081099235,
        '-146': 32.244537611904434,
        '-145': 32.24450796498301,
        '-144': 32.244428698355726,
        '-143': 32.24430849381155,
        '-142': 32.244162272886086,
        '-141': 32.24400941858639,
        '-140': 32.24387131162661,
        '-139': 32.24376849295782,
        '-138': 32.24371783003706,
        '-137': 32.24373008140054,
        '-136': 32.24380821682742,
        '-135': 32.243946759843325,
        '-134': 32.24413228458884,
        '-133': 32.24434503694796,
        '-132': 32.24456148306502,
        '-131': 32.24475744260869,
        '-130': 32.244911363723524,
        '-129': 32.24500726023337,
        '-128': 32.24503686859047,
        '-127': 32.2450006898447,
        '-126': 32.244907746072435,
        '-125': 32.244774079479654,
        '-124': 32.2444178911115,
        '-123': 32.24300140040272,
        '-122': 32.242102142046235,
        '-121': 32.241249106641924,
        '-120': 32.24269414324791,
        '-119': 32.24417200105973,
        '-118': 32.2412351225041,
        '-117': 32.24143235646432,
        '-116': 32.24430173596265,
        '-115': 32.244332788740095,
        '-114': 32.244331655454985,
        '-113': 32.2442885097433,
        '-112': 32.24420108536376,
        '-111': 32.244075301227674,
        '-110': 32.24392454959079,
        '-109': 32.243767758114345,
        '-108': 32.243626535526545,
        '-107': 32.243521859569704,
        '-106': 32.243470839858354,
        '-105': 32.24348407430616,
        '-104': 32.243564017612684,
        '-103': 32.24370461039169,
        '-102': 32.24389220670677,
        '-101': 32.24410762251164,
        '-100': 32.24432894547688,
        '-99': 32.2445346299962,
        '-98': 32.24236890900721,
        '-97': 32.244831302655996,
        '-96': 32.244903229011456,
        '-95': 32.24361717771138,
        '-94': 32.24340866255389,
        '-93': 32.24305364752817,
        '-92': 32.24475032809023,
        '-91': 32.244656563638415,
        '-90': 32.24413979504292,
        '-89': 32.2444833804635,
        '-88': 32.24441885342482,
        '-87': 32.24437468417223,
        '-86': 32.2443529237139,
        '-85': 32.24435501172373,
        '-84': 32.24438258658854,
        '-83': 32.24443789583802,
        '-82': 32.244523689043575,
        '-81': 32.24464261851504,
        '-80': 32.244796289551154,
        '-79': 32.24498419587528,
        '-78': 32.245202814324664,
        '-77': 32.245445073579866,
        '-76': 32.2457003435024,
        '-75': 32.24595497375672,
        '-74': 32.24619330249624,
        '-73': 32.24639897576911,
        '-72': 32.24655638290686,
        '-71': 32.24665202526723,
        '-70': 32.24667568473133,
        '-69': 32.24662132418874,
        '-68': 32.24648771160673,
        '-67': 32.246278792931655,
        '-66': 32.24600383774986,
        '-65': 32.24567734905263,
        '-64': 32.24531868044878,
        '-63': 32.244951264167135,
        '-62': 32.24460134507552,
        '-61': 32.244296156337406,
        '-60': 32.24406156434453,
        '-59': 32.2439193409455,
        '-58': 32.24388436162743,
        '-57': 32.243962181864894,
        '-56': 32.24288446429057,
        '-55': 32.23389369380898,
        '-54': 32.23047882611956,
        '-53': 32.22823117733885,
        '-52': 32.226193429642066,
        '-51': 32.22459196698087,
        '-50': 32.22315366396525,
        '-49': 32.22182776480703,
        '-48': 32.22061685225198,
        '-47': 32.219527215259575,
        '-46': 32.21838578448542,
        '-45': 32.21733270016235,
        '-44': 32.216280898356914,
        '-43': 32.215497045642465,
        '-42': 32.21482863820649,
        '-41': 32.21443740411705,
        '-40': 32.21443326260518,
        '-39': 32.21449049553056,
        '-38': 32.21473752165246,
        '-37': 32.2150433604805,
        '-36': 32.215061984879796,
        '-35': 32.21532722736501,
        '-34': 32.21556005368262,
        '-33': 32.21591292368813,
        '-32': 32.216667607241604,
        '-31': 32.21765837713944,
        '-30': 32.2203711986139,
        '-29': 32.226062456307154,
        '-28': 32.22917593173973,
        '-27': 32.23333759693946,
        '-26': 32.23791381558112,
        '-25': 32.229703114466325,
        '-24': 32.23698321660594,
        '-23': 32.24029865325759,
        '-22': 32.245941187753104,
        '-21': 32.24597719786643,
        '-20': 32.24598869585419,
        '-19': 32.24597596604277,
        '-18': 32.24594324003097,
        '-17': 32.24589744361217,
        '-16': 32.245846497511764,
        '-15': 32.24579738730361,
        '-14': 32.24575467060992,
        '-13': 32.24571968571089,
        '-12': 32.24569061248376,
        '-11': 32.245663332978786,
        '-10': 32.24563285693551,
        '-9': 32.24559495669972,
        '-8': 32.24554762010626,
        '-7': 32.24549198261878,
        '-6': 32.24543252535613,
        '-5': 32.245376492675064,
        '-4': 32.24533265362421,
        '-3': 32.24530966925616,
        '-2': 32.24531440514227,
        '-1': 32.245350532471846
    },
    '75': {
        '0': 32.24648347952396,
        '1': 32.24659085775333,
        '2': 32.24674987958541,
        '3': 32.246943167144416,
        '4': 32.247149959717156,
        '5': 32.24734895162859,
        '6': 32.24752107701783,
        '7': 32.24765182892001,
        '8': 32.247732788691806,
        '9': 32.24776217893716,
        '10': 32.247744414562554,
        '11': 32.24768878514427,
        '12': 32.24760753164463,
        '13': 32.24751366156966,
        '14': 32.24741886766382,
        '15': 32.24733187536877,
        '16': 32.24725745298017,
        '17': 32.247196193498134,
        '18': 32.24714504137258,
        '19': 32.247098414339845,
        '20': 32.24704968064825,
        '21': 32.24699270867929,
        '22': 32.2469232140735,
        '23': 32.24683968222516,
        '24': 32.24674373781218,
        '25': 32.24663992594142,
        '26': 32.24653498425124,
        '27': 32.24643675432141,
        '28': 32.24635293374576,
        '29': 32.24628987844542,
        '30': 32.246251639308916,
        '31': 32.246239364532265,
        '32': 32.246251131530556,
        '33': 32.24628220326494,
        '34': 32.2463256448117,
        '35': 32.24637319479307,
        '36': 32.24641626603221,
        '37': 32.24644694922214,
        '38': 32.24645890805517,
        '39': 32.2464480782543,
        '40': 32.24641311068794,
        '41': 32.246355526230076,
        '42': 32.2462795753397,
        '43': 32.2461918183808,
        '44': 32.246100464316285,
        '45': 32.24601452621245,
        '46': 32.245942871466085,
        '47': 32.24589326073533,
        '48': 32.24587147884717,
        '49': 32.24588065971817,
        '50': 32.24592089268756,
        '51': 32.2459891688756,
        '52': 32.24607968541699,
        '53': 32.24618447785494,
        '54': 32.24629430405439,
        '55': 32.24639966548137,
        '56': 32.24569237037165,
        '57': 32.242839627622104,
        '58': 32.238697226505316,
        '59': 32.24094253263202,
        '60': 32.2450840627817,
        '61': 32.24659039506761,
        '62': 32.24653787963538,
        '63': 32.24646974880277,
        '64': 32.2463909496631,
        '65': 32.24630590144819,
        '66': 32.246218091722845,
        '67': 32.24612993148755,
        '68': 32.246042868063576,
        '69': 32.245957707903884,
        '70': 32.24587506435393,
        '71': 32.24579582462776,
        '72': 32.2457215288937,
        '73': 32.24565457244765,
        '74': 32.245598176312114,
        '75': 32.24555611629706,
        '76': 32.24553224781198,
        '77': 32.245529905082286,
        '78': 32.245551281215675,
        '79': 32.245596904234844,
        '80': 32.24566531149636,
        '81': 32.24575299254775,
        '82': 32.24585462402892,
        '83': 32.24596356832502,
        '84': 32.24607256040222,
        '85': 32.24617447409113,
        '86': 32.24626304724087,
        '87': 32.24633346019203,
        '88': 32.24607903475521,
        '89': 32.245508693689175,
        '90': 32.24549342238429,
        '91': 32.244739658116885,
        '92': 32.2430784034401,
        '93': 32.24453048510782,
        '94': 32.24377657946581,
        '95': 32.24466541189404,
        '96': 32.24418518926349,
        '97': 32.24478767768951,
        '98': 32.244250564272384,
        '99': 32.24387209959652,
        '100': 32.244859068426244,
        '101': 32.24336120058807,
        '102': 32.24382110457088,
        '103': 32.24298435723272,
        '104': 32.24538262242571,
        '105': 32.24422981544218,
        '106': 32.24491489699821,
        '107': 32.24511899331167,
        '108': 32.245212270570676,
        '109': 32.24444108599934,
        '110': 32.24444902256874,
        '111': 32.24376340583507,
        '112': 32.24519210056854,
        '113': 32.24653466889704,
        '114': 32.24657242233006,
        '115': 32.246588092470944,
        '116': 32.24658053260524,
        '117': 32.24655028100764,
        '118': 32.24649969028354,
        '119': 32.24643293871104,
        '120': 32.24635587527316,
        '121': 32.24627565815962,
        '122': 32.246200171462256,
        '123': 32.2461372456187,
        '124': 32.24609375788042,
        '125': 32.24607472156236,
        '126': 32.24608251511134,
        '127': 32.24611639921557,
        '128': 32.24617244277967,
        '129': 32.24624392065043,
        '130': 32.246322166362994,
        '131': 32.24639777681202,
        '132': 32.24646198747379,
        '133': 32.24650799586418,
        '134': 32.24653199656965,
        '135': 32.24653373030626,
        '136': 32.246516435459824,
        '137': 32.24648619521252,
        '138': 32.24587412579081,
        '139': 32.2453256899234,
        '140': 32.24630423023443,
        '141': 32.24631545928495,
        '142': 32.24639230517847,
        '143': 32.246401024436366,
        '144': 32.24643769663164,
        '145': 32.24646510599476,
        '146': 32.246486079616695,
        '147': 32.24649431310676,
        '148': 32.24612143592225,
        '149': 32.246094746855185,
        '150': 32.246112588834436,
        '151': 32.246361586545376,
        '152': 32.24630171031112,
        '153': 32.24624332742641,
        '154': 32.24619263768198,
        '155': 32.246154208550884,
        '156': 32.246130380140634,
        '157': 32.24612115089724,
        '158': 32.246124525219145,
        '159': 32.24613720176344,
        '160': 32.246155418256805,
        '161': 32.246175749427664,
        '162': 32.24619568301845,
        '163': 32.24621386574695,
        '164': 32.246229999042974,
        '165': 32.24624445173398,
        '166': 32.24625772300238,
        '167': 32.24626991914716,
        '168': 32.24628039630423,
        '169': 32.24628767273745,
        '170': 32.24628964168508,
        '171': 32.246284037359096,
        '172': 32.24626904159544,
        '173': 32.24624388207653,
        '174': 32.24620927296059,
        '175': 32.246167584226384,
        '176': 32.24612268778609,
        '177': 32.24607950125645,
        '178': 32.246043316907986,
        '179': 32.24601904843896,
        '180': 32.24601054207351,
        '-180': 32.24601054207351,
        '-179': 32.24602007892227,
        '-178': 32.246048148322856,
        '-177': 32.2460935090558,
        '-176': 32.246153492241234,
        '-175': 32.24622445128755,
        '-174': 32.24630224147454,
        '-173': 32.24638261918484,
        '-172': 32.2464614856523,
        '-171': 32.246534952999944,
        '-170': 32.24659926764649,
        '-169': 32.246650673196285,
        '-168': 32.2466853194542,
        '-167': 32.246699319281475,
        '-166': 32.24668902063106,
        '-165': 32.246651504291435,
        '-164': 32.24658525116335,
        '-163': 32.246490861630036,
        '-162': 32.24637166854746,
        '-161': 32.2462340755968,
        '-160': 32.24608747901494,
        '-159': 32.245943690516526,
        '-158': 32.24581586302454,
        '-157': 32.24571701389538,
        '-156': 32.245658325488456,
        '-155': 32.24564746381328,
        '-154': 32.24568718008241,
        '-153': 32.24577444097109,
        '-152': 32.24590027224413,
        '-151': 32.24605040534071,
        '-150': 32.24620670147998,
        '-149': 32.24634921027731,
        '-148': 32.24645861780078,
        '-147': 32.24651876834105,
        '-146': 32.24651891630876,
        '-145': 32.24645538492258,
        '-144': 32.246332375267805,
        '-143': 32.24616177494573,
        '-142': 32.245961946482566,
        '-141': 32.24575561472167,
        '-140': 32.24556710071723,
        '-139': 32.245419249032985,
        '-138': 32.245330450800324,
        '-137': 32.24531216670436,
        '-136': 32.24536729953896,
        '-135': 32.2454896602577,
        '-134': 32.24566462737212,
        '-133': 32.24587093623197,
        '-132': 32.24608337510441,
        '-131': 32.24627603254384,
        '-130': 32.24642565575168,
        '-129': 32.24651465654156,
        '-128': 32.246533345373145,
        '-127': 32.246481079907674,
        '-126': 32.24636616823566,
        '-125': 32.24620454618526,
        '-124': 32.24601742631227,
        '-123': 32.24582826923601,
        '-122': 32.24565950357983,
        '-121': 32.24552952185498,
        '-120': 32.245450328546674,
        '-119': 32.24542619413064,
        '-118': 32.2454535069104,
        '-117': 32.24552178178232,
        '-116': 32.245615651507826,
        '-115': 32.24426115442541,
        '-114': 32.245810331667265,
        '-113': 32.245880366356296,
        '-112': 32.2457470537749,
        '-111': 32.24578251213605,
        '-110': 32.2444427716523,
        '-109': 32.24585521542149,
        '-108': 32.24476137856615,
        '-107': 32.24517163223589,
        '-106': 32.245732892248704,
        '-105': 32.24573640629325,
        '-104': 32.245773453295676,
        '-103': 32.24584306501805,
        '-102': 32.245939033197295,
        '-101': 32.246051024150546,
        '-100': 32.24615619407621,
        '-99': 32.24617063087873,
        '-98': 32.24635617421287,
        '-97': 32.24641125544957,
        '-96': 32.24591719693093,
        '-95': 32.245057228723866,
        '-94': 32.244706913746946,
        '-93': 32.24633151311379,
        '-92': 32.24470017125501,
        '-91': 32.24313238717549,
        '-90': 32.243093781985245,
        '-89': 32.24302362460388,
        '-88': 32.24281518350585,
        '-87': 32.24193421487831,
        '-86': 32.242220521183555,
        '-85': 32.23916147573889,
        '-84': 32.24250255081991,
        '-83': 32.23690878582634,
        '-82': 32.23546376126263,
        '-81': 32.24201938520753,
        '-80': 32.246805407583764,
        '-79': 32.246944105002946,
        '-78': 32.24708761687261,
        '-77': 32.247232990616965,
        '-76': 32.24737581396409,
        '-75': 32.24750993179924,
        '-74': 32.2476274651028,
        '-73': 32.24771917270983,
        '-72': 32.247775148206465,
        '-71': 32.24778579872441,
        '-70': 32.24774301345703,
        '-69': 32.24764139938759,
        '-68': 32.24747944109221,
        '-67': 32.24726043176618,
        '-66': 32.24699302581991,
        '-65': 32.246691282273986,
        '-64': 32.2463741054867,
        '-63': 32.24606404673824,
        '-62': 32.24578550503122,
        '-61': 32.2455624519504,
        '-60': 32.24541589268319,
        '-59': 32.24536134998066,
        '-58': 32.24540674886074,
        '-57': 32.24198498835374,
        '-56': 32.23614429247435,
        '-55': 32.23319705150088,
        '-54': 32.23035846907707,
        '-53': 32.22803185529533,
        '-52': 32.22626654192781,
        '-51': 32.22486199460526,
        '-50': 32.22374506176646,
        '-49': 32.222537676752836,
        '-48': 32.22170166698749,
        '-47': 32.22064726764097,
        '-46': 32.21973506545932,
        '-45': 32.218746215121776,
        '-44': 32.21793096670222,
        '-43': 32.217327500599545,
        '-42': 32.216991080164206,
        '-41': 32.21699121373386,
        '-40': 32.217238202700976,
        '-39': 32.21757337576351,
        '-38': 32.21792091056823,
        '-37': 32.218309461512604,
        '-36': 32.21843002319639,
        '-35': 32.21869874431274,
        '-34': 32.219269850391484,
        '-33': 32.219673491415,
        '-32': 32.22010002065081,
        '-31': 32.2208249117912,
        '-30': 32.2212785357255,
        '-29': 32.22225944215019,
        '-28': 32.22355797498443,
        '-27': 32.22535057682206,
        '-26': 32.22732964392143,
        '-25': 32.22878399204847,
        '-24': 32.22911458413228,
        '-23': 32.22895700734405,
        '-22': 32.24186158245104,
        '-21': 32.23893298015149,
        '-20': 32.24693279987388,
        '-19': 32.246902162267745,
        '-18': 32.24690788401498,
        '-17': 32.24695073568526,
        '-16': 32.247026913359896,
        '-15': 32.24712795608113,
        '-14': 32.24724118589127,
        '-13': 32.24735097274561,
        '-12': 32.24744064477838,
        '-11': 32.247494797839884,
        '-10': 32.24750165724692,
        '-9': 32.24745510182468,
        '-8': 32.247355988055105,
        '-7': 32.24721250842328,
        '-6': 32.24703946739816,
        '-5': 32.246856534955775,
        '-4': 32.24668570970797,
        '-3': 32.24654836054297,
        '-2': 32.246462292456634,
        '-1': 32.246439284976
    },
    '76': {
        '0': 32.248180915216494,
        '1': 32.24824985597018,
        '2': 32.24837572016998,
        '3': 32.24854292805891,
        '4': 32.24873109310129,
        '5': 32.24891778992649,
        '6': 32.24908146690289,
        '7': 32.24920410496656,
        '8': 32.24927327655108,
        '9': 32.24928335816273,
        '10': 32.249235780654956,
        '11': 32.249138341968084,
        '12': 32.24900373679725,
        '13': 32.24884755777664,
        '14': 32.248686080145916,
        '15': 32.2485341504618,
        '16': 32.24840346142845,
        '17': 32.24830141810488,
        '18': 32.24823069969204,
        '19': 32.24818951268663,
        '20': 32.2481724322598,
        '21': 32.248171653401954,
        '22': 32.24817843112263,
        '23': 32.248184482341614,
        '24': 32.24818315278319,
        '25': 32.248170201495476,
        '26': 32.24814413236419,
        '27': 32.248106070545965,
        '28': 32.248059250419175,
        '29': 32.248008230379604,
        '30': 32.247957977496704,
        '31': 32.24791296935889,
        '32': 32.24787644365893,
        '33': 32.24784989312633,
        '34': 32.24783286080812,
        '35': 32.24782304530374,
        '36': 32.24781668357141,
        '37': 32.24780914513031,
        '38': 32.24779564891895,
        '39': 32.247772003992026,
        '40': 32.247735277378155,
        '41': 32.24768430533736,
        '42': 32.247619985721904,
        '43': 32.24754531644475,
        '44': 32.247465175227056,
        '45': 32.24738586576233,
        '46': 32.24731448217488,
        '47': 32.24725816436815,
        '48': 32.24722332922894,
        '49': 32.24721496511979,
        '50': 32.24723606915643,
        '51': 32.24728728920533,
        '52': 32.24736680743292,
        '53': 32.247470472819714,
        '54': 32.24759216027668,
        '55': 32.2477243079793,
        '56': 32.24785856586296,
        '57': 32.24798647937954,
        '58': 32.24810013450982,
        '59': 32.24819270185128,
        '60': 32.248258836962805,
        '61': 32.24718125151569,
        '62': 32.24079802001182,
        '63': 32.24193432171096,
        '64': 32.240864359298705,
        '65': 32.24272226819446,
        '66': 32.24695521036024,
        '67': 32.24788993330782,
        '68': 32.24774875020342,
        '69': 32.24760049269047,
        '70': 32.24745148205114,
        '71': 32.24730809969826,
        '72': 32.2471765374951,
        '73': 32.247062505189035,
        '74': 32.246970899831034,
        '75': 32.24690545840898,
        '76': 32.24686843013046,
        '77': 32.246860315138825,
        '78': 32.246879718912986,
        '79': 32.24692336451096,
        '80': 32.24698628828901,
        '81': 32.24706222079464,
        '82': 32.247144126955824,
        '83': 32.24722485393508,
        '84': 32.24729780925469,
        '85': 32.247357591782404,
        '86': 32.24740049225238,
        '87': 32.247424796979764,
        '88': 32.24743085631263,
        '89': 32.24742091238058,
        '90': 32.24739871552792,
        '91': 32.24736898899915,
        '92': 32.24733682179212,
        '93': 32.24720593784402,
        '94': 32.24579726916644,
        '95': 32.244620877407385,
        '96': 32.24721773078353,
        '97': 32.24723795614267,
        '98': 32.24624862967221,
        '99': 32.24700950309446,
        '100': 32.24722361168569,
        '101': 32.24721308674737,
        '102': 32.246481020966854,
        '103': 32.24609850199392,
        '104': 32.245771511295956,
        '105': 32.24540844033843,
        '106': 32.24529189186927,
        '107': 32.244561012220174,
        '108': 32.24243520672791,
        '109': 32.244699366524415,
        '110': 32.24202207106214,
        '111': 32.244103255817826,
        '112': 32.247529941979906,
        '113': 32.24799618361379,
        '114': 32.24826135532768,
        '115': 32.24826216882147,
        '116': 32.248240556604344,
        '117': 32.24819734504339,
        '118': 32.248135396718595,
        '119': 32.24805955945003,
        '120': 32.24797635279892,
        '121': 32.247893401937795,
        '122': 32.247818666826426,
        '123': 32.247759553910214,
        '124': 32.24772202448187,
        '125': 32.24770981426788,
        '126': 32.2477238937274,
        '127': 32.24776226167303,
        '128': 32.24782012415503,
        '129': 32.24789045839587,
        '130': 32.24796488814338,
        '131': 32.24803476676718,
        '132': 32.24809231553295,
        '133': 32.248131646488694,
        '134': 32.24814951548105,
        '135': 32.24814568816484,
        '136': 32.24812286132547,
        '137': 32.24808615267671,
        '138': 32.24803212380018,
        '139': 32.246784219538924,
        '140': 32.247960903847876,
        '141': 32.24792491553475,
        '142': 32.24778180949942,
        '143': 32.24792633381964,
        '144': 32.247941516313126,
        '145': 32.24796487450371,
        '146': 32.24799116478822,
        '147': 32.24801492194566,
        '148': 32.248031345237905,
        '149': 32.24803701702342,
        '150': 32.24803034907283,
        '151': 32.248011706015795,
        '152': 32.24798321447441,
        '153': 32.24794831910256,
        '154': 32.24791118359498,
        '155': 32.24787605016065,
        '156': 32.24784666404714,
        '157': 32.24782584407454,
        '158': 32.2478152428717,
        '159': 32.24781530038861,
        '160': 32.24782535975848,
        '161': 32.247843892055286,
        '162': 32.247868768960366,
        '163': 32.24789752915091,
        '164': 32.247927601502205,
        '165': 32.24795647012905,
        '166': 32.24798178665461,
        '167': 32.24800144881314,
        '168': 32.24801366879761,
        '169': 32.24801704968726,
        '170': 32.24801067644019,
        '171': 32.24799421361393,
        '172': 32.24796798993478,
        '173': 32.24793304395843,
        '174': 32.2478911073582,
        '175': 32.247844512464425,
        '176': 32.24779602595889,
        '177': 32.247748626996184,
        '178': 32.24770526099705,
        '179': 32.24766860627696,
        '180': 32.247640887697365,
        '-180': 32.247640887697365,
        '-179': 32.247623760157836,
        '-178': 32.24761826774624,
        '-177': 32.24762486609387,
        '-176': 32.247643480809025,
        '-175': 32.24767356792273,
        '-174': 32.247714145369144,
        '-173': 32.24776377743019,
        '-172': 32.247820513987136,
        '-171': 32.24788180849219,
        '-170': 32.24794445696674,
        '-169': 32.24800460960246,
        '-168': 32.24805790294722,
        '-167': 32.24809974318972,
        '-166': 32.24812574187324,
        '-165': 32.24813226956146,
        '-164': 32.2481170576741,
        '-163': 32.248079751691535,
        '-162': 32.248022307035455,
        '-161': 32.24794912660028,
        '-160': 32.24786686708342,
        '-159': 32.24778388700127,
        '-158': 32.24770936604342,
        '-157': 32.24765218410379,
        '-156': 32.24761969875902,
        '-155': 32.247616592689596,
        '-154': 32.247643970512655,
        '-153': 32.24769886443244,
        '-152': 32.24777426119562,
        '-151': 32.24785969470752,
        '-150': 32.247942368656,
        '-149': 32.24800869325443,
        '-148': 32.248046051952365,
        '-147': 32.24804456853983,
        '-146': 32.24799863035458,
        '-145': 32.247907942899126,
        '-144': 32.24777794381875,
        '-143': 32.24761948379661,
        '-142': 32.24744777831609,
        '-141': 32.247280734485074,
        '-140': 32.24713684722057,
        '-139': 32.24703292594669,
        '-138': 32.24698194621761,
        '-137': 32.24699131434838,
        '-136': 32.24706178664084,
        '-135': 32.247187203284895,
        '-134': 32.24735509090075,
        '-133': 32.247548071288335,
        '-132': 32.24774590355851,
        '-131': 32.24792789832522,
        '-130': 32.24807538915668,
        '-129': 32.24817393631432,
        '-128': 32.24821497308413,
        '-127': 32.2481966812347,
        '-126': 32.24812398874422,
        '-125': 32.248007704737326,
        '-124': 32.24786292594702,
        '-123': 32.24770694853589,
        '-122': 32.24755698416212,
        '-121': 32.24742800117694,
        '-120': 32.24579393073392,
        '-119': 32.24727181935753,
        '-118': 32.24718021863873,
        '-117': 32.24726405857075,
        '-116': 32.24598791571113,
        '-115': 32.244524246779655,
        '-114': 32.24589641160108,
        '-113': 32.244197870939765,
        '-112': 32.24749416655708,
        '-111': 32.24752651350424,
        '-110': 32.247532308296634,
        '-109': 32.24556213537224,
        '-108': 32.247257846847624,
        '-107': 32.24749640242903,
        '-106': 32.2469321581939,
        '-105': 32.24749290169265,
        '-104': 32.247512006416244,
        '-103': 32.24711090432708,
        '-102': 32.24759216036955,
        '-101': 32.24606684060987,
        '-100': 32.247699702716105,
        '-99': 32.24610895629622,
        '-98': 32.246237886103344,
        '-97': 32.247809911879514,
        '-96': 32.2478183382533,
        '-95': 32.2478134488318,
        '-94': 32.247799505377024,
        '-93': 32.24778249708711,
        '-92': 32.24639315855428,
        '-91': 32.24770532850912,
        '-90': 32.24726225330065,
        '-89': 32.247808949668965,
        '-88': 32.247858842115086,
        '-87': 32.247926098327355,
        '-86': 32.24800674299223,
        '-85': 32.248095296535034,
        '-84': 32.24818561466781,
        '-83': 32.248271774047645,
        '-82': 32.248348861288456,
        '-81': 32.24841354471004,
        '-80': 32.24846434845292,
        '-79': 32.24850159879252,
        '-78': 32.24852705331061,
        '-77': 32.24854334346033,
        '-76': 32.248553270206145,
        '-75': 32.248559093611966,
        '-74': 32.2485619503777,
        '-73': 32.2485614982021,
        '-72': 32.24855585641138,
        '-71': 32.248541867108,
        '-70': 32.24851565122226,
        '-69': 32.24847338589558,
        '-68': 32.24841219013504,
        '-67': 32.244850852783955,
        '-66': 32.24823115582503,
        '-65': 32.24811699133502,
        '-64': 32.24799556188586,
        '-63': 32.24787629146901,
        '-62': 32.24777006665482,
        '-61': 32.24768800601811,
        '-60': 32.24705370909739,
        '-59': 32.23969929300276,
        '-58': 32.23618035791857,
        '-57': 32.234098924586114,
        '-56': 32.23169038868903,
        '-55': 32.22927743391181,
        '-54': 32.227684793661986,
        '-53': 32.226482554297185,
        '-52': 32.22538222913377,
        '-51': 32.224511686547984,
        '-50': 32.22365885322645,
        '-49': 32.22276839983925,
        '-48': 32.222003199878785,
        '-47': 32.221218678546656,
        '-46': 32.22055850726009,
        '-45': 32.22000844328673,
        '-44': 32.21960672262955,
        '-43': 32.21951259974489,
        '-42': 32.21963052546244,
        '-41': 32.21987101798274,
        '-40': 32.2201997568894,
        '-39': 32.22064693440305,
        '-38': 32.2212264353385,
        '-37': 32.2218556082172,
        '-36': 32.22267979684217,
        '-35': 32.223366362562494,
        '-34': 32.22412740639276,
        '-33': 32.224538534360875,
        '-32': 32.224855455131575,
        '-31': 32.225145680451206,
        '-30': 32.22561330477967,
        '-29': 32.22618463881161,
        '-28': 32.2272068199632,
        '-27': 32.228587302706586,
        '-26': 32.232586054402255,
        '-25': 32.23179543466739,
        '-24': 32.23787010723046,
        '-23': 32.239020226928616,
        '-22': 32.245937627327585,
        '-21': 32.24569322337404,
        '-20': 32.24737376201193,
        '-19': 32.247311590972345,
        '-18': 32.24734016319258,
        '-17': 32.247455277789626,
        '-16': 32.247644735383496,
        '-15': 32.24788930476356,
        '-14': 32.24816449725187,
        '-13': 32.24844301429831,
        '-12': 32.24869763830802,
        '-11': 32.24890422641763,
        '-10': 32.24904443824226,
        '-9': 32.249107840471815,
        '-8': 32.24909309597135,
        '-7': 32.24900805610326,
        '-6': 32.24886871777427,
        '-5': 32.248697160839065,
        '-4': 32.24851872394615,
        '-3': 32.24835878573799,
        '-2': 32.24823957625747,
        '-1': 32.24817744082325
    },
    '77': {
        '0': 32.249888758366026,
        '1': 32.24992064784999,
        '2': 32.24999496344008,
        '3': 32.250102902774174,
        '4': 32.25023095022779,
        '5': 32.25036279489947,
        '6': 32.250481502396475,
        '7': 32.25057165540319,
        '8': 32.250621195800655,
        '9': 32.2506227531572,
        '10': 32.25057432210779,
        '11': 32.25047924271415,
        '12': 32.25034553025999,
        '13': 32.25018468158271,
        '14': 32.25001014379383,
        '15': 32.24983566153813,
        '16': 32.2496737185615,
        '17': 32.2478150758132,
        '18': 32.249423842257876,
        '19': 32.24934522830318,
        '20': 32.24929753095705,
        '21': 32.24927675170492,
        '22': 32.249276633994455,
        '23': 32.24928968723683,
        '24': 32.249308231860404,
        '25': 32.24932535141853,
        '26': 32.249335609508826,
        '27': 32.249335481162106,
        '28': 32.249323476113474,
        '29': 32.24929997493136,
        '30': 32.249266835630394,
        '31': 32.249226852791146,
        '32': 32.24918316215955,
        '33': 32.24913868119743,
        '34': 32.24909566184731,
        '35': 32.249055408901064,
        '36': 32.249018189627,
        '37': 32.248983331706334,
        '38': 32.24894948073336,
        '39': 32.24891496851043,
        '40': 32.248878231104996,
        '41': 32.248838212030535,
        '42': 32.24879469078385,
        '43': 32.24874848917563,
        '44': 32.248701525531956,
        '45': 32.24865670751783,
        '46': 32.248617675422835,
        '47': 32.24858842670768,
        '48': 32.24857286724548,
        '49': 32.24857434339301,
        '50': 32.24859521094373,
        '51': 32.24863649213319,
        '52': 32.24869766100779,
        '53': 32.24877658215565,
        '54': 32.24886961005609,
        '55': 32.2489718383832,
        '56': 32.24907747265748,
        '57': 32.24918028747539,
        '58': 32.24927412235545,
        '59': 32.24935336850138,
        '60': 32.24941340223321,
        '61': 32.24945092858168,
        '62': 32.24946420923022,
        '63': 32.24945316107294,
        '64': 32.249419323634065,
        '65': 32.24936570423066,
        '66': 32.24929651823851,
        '67': 32.24921684779304,
        '68': 32.24913224580796,
        '69': 32.24904831372018,
        '70': 32.24897028140982,
        '71': 32.24890261680105,
        '72': 32.248848691040344,
        '73': 32.24881052291476,
        '74': 32.24878862308468,
        '75': 32.24878195456908,
        '76': 32.248788021609265,
        '77': 32.24880307887344,
        '78': 32.24882246685985,
        '79': 32.24884104810437,
        '80': 32.24885371159339,
        '81': 32.248855903852096,
        '82': 32.248844136306936,
        '83': 32.24881641516458,
        '84': 32.24877254267337,
        '85': 32.24871424789079,
        '86': 32.2486451206858,
        '87': 32.24857034150759,
        '88': 32.24849622656806,
        '89': 32.24842964037623,
        '90': 32.248377311447655,
        '91': 32.24834514115906,
        '92': 32.248337580890386,
        '93': 32.24835715019215,
        '94': 32.24840415411994,
        '95': 32.2484766349447,
        '96': 32.248570565358904,
        '97': 32.248680260763024,
        '98': 32.248798961236886,
        '99': 32.248919513075535,
        '100': 32.24903506814849,
        '101': 32.24913972031012,
        '102': 32.248702805198725,
        '103': 32.24850072470981,
        '104': 32.249008268429016,
        '105': 32.2491940294606,
        '106': 32.249141113828294,
        '107': 32.249267533220056,
        '108': 32.24938449225589,
        '109': 32.24939417523599,
        '110': 32.24938037227615,
        '111': 32.24936530753621,
        '112': 32.24934999775849,
        '113': 32.24933454843389,
        '114': 32.24931837357865,
        '115': 32.24930053096299,
        '116': 32.24928012208437,
        '117': 32.24925667983867,
        '118': 32.24923047125266,
        '119': 32.24920266573627,
        '120': 32.2491753320537,
        '121': 32.24915125648479,
        '122': 32.2491336038443,
        '123': 32.249125469671604,
        '124': 32.24912939172597,
        '125': 32.24914689854635,
        '126': 32.249178170361425,
        '127': 32.24922187303068,
        '128': 32.24927520088055,
        '129': 32.2493341329292,
        '130': 32.249393873922266,
        '131': 32.24944942212414,
        '132': 32.24949618477871,
        '133': 32.24953055672254,
        '134': 32.24955037311869,
        '135': 32.249555149537684,
        '136': 32.24954613358309,
        '137': 32.24952611311487,
        '138': 32.24949902266273,
        '139': 32.249469409394784,
        '140': 32.24944183840573,
        '141': 32.24942032370155,
        '142': 32.24940786402542,
        '143': 32.24940614323197,
        '144': 32.24941542675854,
        '145': 32.249434653126734,
        '146': 32.2494616996324,
        '147': 32.2494937555085,
        '148': 32.24952774570874,
        '149': 32.24956073869851,
        '150': 32.249590279575884,
        '151': 32.24961460847799,
        '152': 32.249632746661185,
        '153': 32.24964445563234,
        '154': 32.24965009429862,
        '155': 32.249650412645984,
        '156': 32.24964632525535,
        '157': 32.249638702163736,
        '158': 32.24962821524163,
        '159': 32.249615254244,
        '160': 32.249599917154,
        '161': 32.249582065776735,
        '162': 32.24956142776755,
        '163': 32.24953772120686,
        '164': 32.249510777436626,
        '165': 32.249480637893036,
        '166': 32.249447618261385,
        '167': 32.24941233271989,
        '168': 32.249375666677004,
        '169': 32.24933871253727,
        '170': 32.249302675964024,
        '171': 32.24926876499375,
        '172': 32.24923807555451,
        '173': 32.249211486972285,
        '174': 32.249189580109565,
        '175': 32.24917258889277,
        '176': 32.249160393040455,
        '177': 32.24915255567331,
        '178': 32.24914840418396,
        '179': 32.24914714658589,
        '180': 32.24914800924255,
        '-180': 32.24914800924255,
        '-179': 32.24915037644513,
        '-178': 32.249153908990024,
        '-177': 32.24915861886169,
        '-176': 32.24916488111637,
        '-175': 32.24917337218889,
        '-174': 32.24918493536362,
        '-173': 32.24920038747531,
        '-172': 32.24922029379388,
        '-171': 32.249244747965,
        '-170': 32.24927319848587,
        '-169': 32.24930436084787,
        '-168': 32.24933624465152,
        '-167': 32.24936630852821,
        '-166': 32.24939173478226,
        '-165': 32.24940979356574,
        '-164': 32.24941824696346,
        '-163': 32.24941573034854,
        '-162': 32.24940204473754,
        '-161': 32.24937830120073,
        '-160': 32.249346876460045,
        '-159': 32.249311165548576,
        '-158': 32.249275149070904,
        '-157': 32.249242824343966,
        '-156': 32.24921757628714,
        '-155': 32.24920158063939,
        '-154': 32.24919533547618,
        '-153': 32.249197405583466,
        '-152': 32.24920443880342,
        '-151': 32.24921147704341,
        '-150': 32.24921254217086,
        '-149': 32.24920143462641,
        '-148': 32.24917264669176,
        '-147': 32.249122268674306,
        '-146': 32.24904875896605,
        '-145': 32.24895345988855,
        '-144': 32.24884076971915,
        '-143': 32.24871792398658,
        '-142': 32.24859439050776,
        '-141': 32.24848093573391,
        '-140': 32.24838846730053,
        '-139': 32.2483267923152,
        '-138': 32.24830344753621,
        '-137': 32.248322753268624,
        '-136': 32.248385217522994,
        '-135': 32.24848737374248,
        '-134': 32.2486220798649,
        '-133': 32.24877924620795,
        '-132': 32.24894690304744,
        '-131': 32.24911247382401,
        '-130': 32.2492640931093,
        '-129': 32.249391803699716,
        '-128': 32.24948848522972,
        '-127': 32.24955040496097,
        '-126': 32.24957733440635,
        '-125': 32.249572235530984,
        '-124': 32.24954057874383,
        '-123': 32.24948940331928,
        '-122': 32.24942626235379,
        '-121': 32.24935820452043,
        '-120': 32.249280812540235,
        '-119': 32.24883359883945,
        '-118': 32.24862544126118,
        '-117': 32.2484637440011,
        '-116': 32.24906500717799,
        '-115': 32.249030261027194,
        '-114': 32.24898396524445,
        '-113': 32.24893394538073,
        '-112': 32.24887902535084,
        '-111': 32.248819459226276,
        '-110': 32.248756962531395,
        '-109': 32.248694482137736,
        '-108': 32.24863573476159,
        '-107': 32.248584610888926,
        '-106': 32.24854455274634,
        '-105': 32.24851801419961,
        '-104': 32.248506094863544,
        '-103': 32.248508408798614,
        '-102': 32.24852320674612,
        '-101': 32.24854772699364,
        '-100': 32.24857871102038,
        '-99': 32.24861299244757,
        '-98': 32.248648055930815,
        '-97': 32.248682468224025,
        '-96': 32.24720908344132,
        '-95': 32.24875013597209,
        '-94': 32.24878675622757,
        '-93': 32.2488287244678,
        '-92': 32.248878765811014,
        '-91': 32.2489389432704,
        '-90': 32.24901010072974,
        '-89': 32.24909147661915,
        '-88': 32.24709639092372,
        '-87': 32.24538794952352,
        '-86': 32.239418684432415,
        '-85': 32.23951088215439,
        '-84': 32.24020653594692,
        '-83': 32.24566965178134,
        '-82': 32.24462478696443,
        '-81': 32.24318210662553,
        '-80': 32.24365978658177,
        '-79': 32.249541947670515,
        '-78': 32.24949087264215,
        '-77': 32.249433441228454,
        '-76': 32.249377601096676,
        '-75': 32.249331258409136,
        '-74': 32.24930143071717,
        '-73': 32.24929351797164,
        '-72': 32.24931077269994,
        '-71': 32.247492987109425,
        '-70': 32.24432406005681,
        '-69': 32.24305669143185,
        '-68': 32.237708503993225,
        '-67': 32.24171415943052,
        '-66': 32.241990691657236,
        '-65': 32.239005846049494,
        '-64': 32.23473735326737,
        '-63': 32.23315719454332,
        '-62': 32.2326543348901,
        '-61': 32.23216522328427,
        '-60': 32.23206374391493,
        '-59': 32.23167217420926,
        '-58': 32.231091984562966,
        '-57': 32.23047507208775,
        '-56': 32.229639178891134,
        '-55': 32.22867484031705,
        '-54': 32.227712860369344,
        '-53': 32.226823543868186,
        '-52': 32.22597679568157,
        '-51': 32.22514379486113,
        '-50': 32.22433787595516,
        '-49': 32.22363499029442,
        '-48': 32.22309289624883,
        '-47': 32.2225489772666,
        '-46': 32.2221449059324,
        '-45': 32.221881389854325,
        '-44': 32.2218898121226,
        '-43': 32.22208788565256,
        '-42': 32.222370387693935,
        '-41': 32.22275960729106,
        '-40': 32.22329364466837,
        '-39': 32.22392511581591,
        '-38': 32.22466262356229,
        '-37': 32.22535907609152,
        '-36': 32.226187681328206,
        '-35': 32.226834441732194,
        '-34': 32.22777404996925,
        '-33': 32.228622645730105,
        '-32': 32.229322974673,
        '-31': 32.229883076730275,
        '-30': 32.23024594746447,
        '-29': 32.23101931431895,
        '-28': 32.23148139860948,
        '-27': 32.23183868368237,
        '-26': 32.23390135343673,
        '-25': 32.23733847048787,
        '-24': 32.23679052912093,
        '-23': 32.24695911528223,
        '-22': 32.247058487121315,
        '-21': 32.24124375199356,
        '-20': 32.24656437789511,
        '-19': 32.24519444013705,
        '-18': 32.24750343409104,
        '-17': 32.24765165072789,
        '-16': 32.247895553352166,
        '-15': 32.24821349078103,
        '-14': 32.24857863607567,
        '-13': 32.248961513563735,
        '-12': 32.2493328028974,
        '-11': 32.24966610687503,
        '-10': 32.24994040987799,
        '-9': 32.25014193857993,
        '-8': 32.250265218431686,
        '-7': 32.250313209921295,
        '-6': 32.250296514742885,
        '-5': 32.25023175378284,
        '-4': 32.25013931873538,
        '-3': 32.25004077430178,
        '-2': 32.2499562280381,
        '-1': 32.24990198439975
    },
    '78': {
        '0': 32.25111247350131,
        '1': 32.25112567852359,
        '2': 32.251166141334345,
        '3': 32.25123143113265,
        '4': 32.251315389815154,
        '5': 32.25140910550442,
        '6': 32.25150214098488,
        '7': 32.2515838586509,
        '8': 32.25164468175007,
        '9': 32.251677149365484,
        '10': 32.2516766564627,
        '11': 32.251641815264456,
        '12': 32.25157442392967,
        '13': 32.25147907648218,
        '14': 32.24852905085199,
        '15': 32.245506271359844,
        '16': 32.25078432971074,
        '17': 32.2443613767784,
        '18': 32.24574628221949,
        '19': 32.2507350968413,
        '20': 32.25064350003396,
        '21': 32.250569456550714,
        '22': 32.2481456464784,
        '23': 32.24995257593342,
        '24': 32.25043540888493,
        '25': 32.250409473409746,
        '26': 32.25038701941524,
        '27': 32.2503651515208,
        '28': 32.2503418374727,
        '29': 32.250316025784194,
        '30': 32.25028761458573,
        '31': 32.250257295387215,
        '32': 32.250226309133375,
        '33': 32.2501961592645,
        '34': 32.250168327176105,
        '35': 32.25014403007684,
        '36': 32.2501240510667,
        '37': 32.25010865807938,
        '38': 32.25009761414471,
        '39': 32.25009026817489,
        '40': 32.250085704819384,
        '41': 32.25008292505802,
        '42': 32.25008102673299,
        '43': 32.250079356176755,
        '44': 32.25007760793233,
        '45': 32.25007585829324,
        '46': 32.25007452870746,
        '47': 32.250074285567706,
        '48': 32.25007589216523,
        '49': 32.25008003545444,
        '50': 32.25008715394293,
        '51': 32.250097293096424,
        '52': 32.25011001120989,
        '53': 32.25012435224616,
        '54': 32.25013889355105,
        '55': 32.2501518667136,
        '56': 32.25016134035086,
        '57': 32.25016544539868,
        '58': 32.25016261753673,
        '59': 32.25015182833684,
        '60': 32.25013277690573,
        '61': 32.250106017140865,
        '62': 32.25007300183129,
        '63': 32.2500360330376,
        '64': 32.24999811761328,
        '65': 32.24996273643524,
        '66': 32.24993354495993,
        '67': 32.249914030274304,
        '68': 32.24990715519567,
        '69': 32.249915022718085,
        '70': 32.249938593957076,
        '71': 32.24997748967843,
        '72': 32.250029899694034,
        '73': 32.25009261623768,
        '74': 32.25016119744059,
        '75': 32.25023025589919,
        '76': 32.25029385588167,
        '77': 32.250345991855546,
        '78': 32.250381111690444,
        '79': 32.25039464103017,
        '80': 32.250383461782924,
        '81': 32.25034629810699,
        '82': 32.25028396805267,
        '83': 32.250199468166095,
        '84': 32.25009787144057,
        '85': 32.249986035118674,
        '86': 32.24987213267463,
        '87': 32.24976504215927,
        '88': 32.2496736390949,
        '89': 32.24960605439057,
        '90': 32.249568964676364,
        '91': 32.24956698285656,
        '92': 32.249602210045786,
        '93': 32.24967399665759,
        '94': 32.249778941351295,
        '95': 32.24991113365053,
        '96': 32.250062621712765,
        '97': 32.250224063795876,
        '98': 32.25038550812491,
        '99': 32.250537204627115,
        '100': 32.25024529526301,
        '101': 32.250778015706565,
        '102': 32.25085519144738,
        '103': 32.25089955915963,
        '104': 32.25091131651135,
        '105': 32.25089304728599,
        '106': 32.250849334616625,
        '107': 32.250786213319174,
        '108': 32.25071052358927,
        '109': 32.25062923023417,
        '110': 32.25054881863554,
        '111': 32.25047477409939,
        '112': 32.25041121631137,
        '113': 32.250360714538864,
        '114': 32.25032428488532,
        '115': 32.250301551430475,
        '116': 32.25029103506249,
        '117': 32.25029052141056,
        '118': 32.25029745385703,
        '119': 32.25030929952037,
        '120': 32.250323844734325,
        '121': 32.250339390386614,
        '122': 32.25035483434866,
        '123': 32.25036964560316,
        '124': 32.25038375006645,
        '125': 32.25039735939129,
        '126': 32.250410779795295,
        '127': 32.250424237633894,
        '128': 32.250437752408025,
        '129': 32.250451077400584,
        '130': 32.25046371504426,
        '131': 32.25047500064002,
        '132': 32.25048423634041,
        '133': 32.25049084918345,
        '134': 32.25049454355824,
        '135': 32.25049542013543,
        '136': 32.250494039498655,
        '137': 32.250491418240564,
        '138': 32.25048895641776,
        '139': 32.25048830609619,
        '140': 32.250491199503635,
        '141': 32.25049926086491,
        '142': 32.25051382844346,
        '143': 32.250535801565604,
        '144': 32.25056554430392,
        '145': 32.25060284309671,
        '146': 32.2506469197294,
        '147': 32.250696493623074,
        '148': 32.25074988005619,
        '149': 32.25080510812575,
        '150': 32.25086004251704,
        '151': 32.25091249609809,
        '152': 32.25096032513156,
        '153': 32.251001504384895,
        '154': 32.25103418446294,
        '155': 32.251056737314386,
        '156': 32.25106779744521,
        '157': 32.25106630572611,
        '158': 32.25105156006551,
        '159': 32.25102327326722,
        '160': 32.25098163396775,
        '161': 32.25092736257558,
        '162': 32.25086174417893,
        '163': 32.25078663769938,
        '164': 32.250704470156215,
        '165': 32.25061816057241,
        '166': 32.25053100374248,
        '167': 32.2504465091659,
        '168': 32.25036820359508,
        '169': 32.25029941116711,
        '170': 32.25024302921174,
        '171': 32.250201320159974,
        '172': 32.25017574015216,
        '173': 32.250166822826515,
        '174': 32.250174132468516,
        '175': 32.250196294555394,
        '176': 32.2502311042983,
        '177': 32.25027570579289,
        '178': 32.250326826687505,
        '179': 32.250381046755905,
        '180': 32.25043507426186,
        '-180': 32.25043507426186,
        '-179': 32.250486002211936,
        '-178': 32.25053151793823,
        '-177': 32.250570044015625,
        '-176': 32.250600795967834,
        '-175': 32.25062375181377,
        '-174': 32.25063953914712,
        '-173': 32.25064925577875,
        '-172': 32.25065424858429,
        '-171': 32.25065588079254,
        '-170': 32.2506553195704,
        '-169': 32.250653372963626,
        '-168': 32.25065039822368,
        '-167': 32.250646293120035,
        '-166': 32.25064056940106,
        '-165': 32.25063249491085,
        '-164': 32.25062127990896,
        '-163': 32.25060627563182,
        '-162': 32.25058715038605,
        '-161': 32.250564011105396,
        '-160': 32.25053744615376,
        '-159': 32.25050847721269,
        '-158': 32.25047842264609,
        '-157': 32.25044868961519,
        '-156': 32.250420525104715,
        '-155': 32.250394764807865,
        '-154': 32.25037162194263,
        '-153': 32.25035055478384,
        '-152': 32.250330242199595,
        '-151': 32.250308681942535,
        '-150': 32.25028340884107,
        '-149': 32.25025181188013,
        '-148': 32.25021151314079,
        '-147': 32.25016076014469,
        '-146': 32.25009877817787,
        '-145': 32.2500260316141,
        '-144': 32.24994435301835,
        '-143': 32.249856914724326,
        '-142': 32.24976803755223,
        '-141': 32.24968285264459,
        '-140': 32.24960685207423,
        '-139': 32.24954537912576,
        '-138': 32.24950311776705,
        '-137': 32.24948364152435,
        '-136': 32.24948907459184,
        '-135': 32.2495199035371,
        '-134': 32.249574958429214,
        '-133': 32.24965156037392,
        '-132': 32.2497458113794,
        '-131': 32.24985298517022,
        '-130': 32.24996796641742,
        '-129': 32.2500856823447,
        '-128': 32.25020147514179,
        '-127': 32.2503113752151,
        '-126': 32.2504122521276,
        '-125': 32.25050183946121,
        '-124': 32.25057864877035,
        '-123': 32.25064180340059,
        '-122': 32.25069083290818,
        '-121': 32.25072547174843,
        '-120': 32.25074550155019,
        '-119': 32.250750665577854,
        '-118': 32.25074066882503,
        '-117': 32.25071526020255,
        '-116': 32.25067437735812,
        '-115': 32.2506183224677,
        '-114': 32.250547930896765,
        '-113': 32.25046469500055,
        '-112': 32.25036069563814,
        '-111': 32.250188216998126,
        '-110': 32.24990008988869,
        '-109': 32.25005623560198,
        '-108': 32.24995237357312,
        '-107': 32.24985490470554,
        '-106': 32.24976674045185,
        '-105': 32.249690079393645,
        '-104': 32.24962631239392,
        '-103': 32.24957603679651,
        '-102': 32.249539175127936,
        '-101': 32.249515176402134,
        '-100': 32.24805794264692,
        '-99': 32.24950268574286,
        '-98': 32.249512920033006,
        '-97': 32.24947315157164,
        '-96': 32.249221844801426,
        '-95': 32.2496085492266,
        '-94': 32.249663391028776,
        '-93': 32.24973031714412,
        '-92': 32.24980903709975,
        '-91': 32.2498984034132,
        '-90': 32.24999619713099,
        '-89': 32.25009902873144,
        '-88': 32.25020240521432,
        '-87': 32.25030096873493,
        '-86': 32.25038889429761,
        '-85': 32.24435015018954,
        '-84': 32.24744477189895,
        '-83': 32.2486226971142,
        '-82': 32.24718323142418,
        '-81': 32.23844474663235,
        '-80': 32.24125247504072,
        '-79': 32.24427299735808,
        '-78': 32.24437814173669,
        '-77': 32.24419603043632,
        '-76': 32.24511132636089,
        '-75': 32.25003540641511,
        '-74': 32.24998967404986,
        '-73': 32.249977059990016,
        '-72': 32.24428211212647,
        '-71': 32.24196572729636,
        '-70': 32.24069291354828,
        '-69': 32.239066531579745,
        '-68': 32.23658609314651,
        '-67': 32.23649983119102,
        '-66': 32.2371820741516,
        '-65': 32.235719578840055,
        '-64': 32.2341646190261,
        '-63': 32.233528907522164,
        '-62': 32.2328920970499,
        '-61': 32.232498650791115,
        '-60': 32.232230115442505,
        '-59': 32.231878545852105,
        '-58': 32.23150113422906,
        '-57': 32.23106588997408,
        '-56': 32.23061364763057,
        '-55': 32.23010611916404,
        '-54': 32.22958781070431,
        '-53': 32.228993573478654,
        '-52': 32.22839137619084,
        '-51': 32.22775946273108,
        '-50': 32.22717831208314,
        '-49': 32.226607834131585,
        '-48': 32.22630136467277,
        '-47': 32.22564326478116,
        '-46': 32.22522956362517,
        '-45': 32.2249275409854,
        '-44': 32.22479444508362,
        '-43': 32.224915248054685,
        '-42': 32.22521035633758,
        '-41': 32.22569770894207,
        '-40': 32.22617957564363,
        '-39': 32.22683834575193,
        '-38': 32.227600762529974,
        '-37': 32.22831003233617,
        '-36': 32.22915119566276,
        '-35': 32.22992457039959,
        '-34': 32.230734333374855,
        '-33': 32.23168665790726,
        '-32': 32.23226355594167,
        '-31': 32.2332868344839,
        '-30': 32.23447040826231,
        '-29': 32.23555402407805,
        '-28': 32.23658679763135,
        '-27': 32.237603106059495,
        '-26': 32.23830857725528,
        '-25': 32.23827156643996,
        '-24': 32.240142706412115,
        '-23': 32.24199677577145,
        '-22': 32.24543154975481,
        '-21': 32.24837807218362,
        '-20': 32.24821912011817,
        '-19': 32.24816238066117,
        '-18': 32.248209357845596,
        '-17': 32.24835443825202,
        '-16': 32.24858539031726,
        '-15': 32.24888444022076,
        '-14': 32.249229811249855,
        '-13': 32.249597628623434,
        '-12': 32.24996398778548,
        '-11': 32.25030698505173,
        '-10': 32.250608494086215,
        '-9': 32.25085549998527,
        '-8': 32.251040976194304,
        '-7': 32.25116409298779,
        '-6': 32.25122981470288,
        '-5': 32.25124793704873,
        '-4': 32.25123168323149,
        '-3': 32.25119602444772,
        '-2': 32.251155916443565,
        '-1': 32.25112464742936
    },
    '79': {
        '0': 32.25225655507736,
        '1': 32.25226099216929,
        '2': 32.25228196675825,
        '3': 32.252320293473424,
        '4': 32.25237435909019,
        '5': 32.252440466701806,
        '6': 32.252513365268456,
        '7': 32.252586895971476,
        '8': 32.25265467947801,
        '9': 32.25271076972824,
        '10': 32.25275020943643,
        '11': 32.252769438498845,
        '12': 32.25276652662046,
        '13': 32.24597027365842,
        '14': 32.24674379740753,
        '15': 32.24833892437154,
        '16': 32.251427056232025,
        '17': 32.240723646472304,
        '18': 32.241831145292394,
        '19': 32.24869156879969,
        '20': 32.25216427297052,
        '21': 32.2520692693175,
        '22': 32.25198012878003,
        '23': 32.25189815325379,
        '24': 32.25182396027863,
        '25': 32.25175762571681,
        '26': 32.25169885524486,
        '27': 32.25164715290521,
        '28': 32.25160198340181,
        '29': 32.2515628833219,
        '30': 32.251529521658526,
        '31': 32.251501717371674,
        '32': 32.25147941487642,
        '33': 32.251462628354176,
        '34': 32.251451368792004,
        '35': 32.25144556833897,
        '36': 32.251445015099385,
        '37': 32.251449308299044,
        '38': 32.25145783949818,
        '39': 32.251469800897524,
        '40': 32.251484217497044,
        '41': 32.25149999651399,
        '42': 32.25151598546257,
        '43': 32.25153102983505,
        '44': 32.251544022364094,
        '45': 32.251553938134435,
        '46': 32.25155985293184,
        '47': 32.25156094564406,
        '48': 32.251556488718634,
        '49': 32.25154583313392,
        '50': 32.25152839567035,
        '51': 32.25150365625704,
        '52': 32.2514711717854,
        '53': 32.25143061018885,
        '54': 32.251381805118626,
        '55': 32.25132482766165,
        '56': 32.251260067774375,
        '57': 32.25118831498024,
        '58': 32.25111082586378,
        '59': 32.25102936533984,
        '60': 32.25094620976324,
        '61': 32.25086410266663,
        '62': 32.2507861580729,
        '63': 32.25071571155257,
        '64': 32.250656124976075,
        '65': 32.250610556652376,
        '66': 32.25058171362755,
        '67': 32.250571606757354,
        '68': 32.250581331278184,
        '69': 32.25061089564209,
        '70': 32.25065911919756,
        '71': 32.25072361494012,
        '72': 32.25080086728722,
        '73': 32.25088640710606,
        '74': 32.25097507766349,
        '75': 32.2510613765085,
        '76': 32.25113985033643,
        '77': 32.251205513401864,
        '78': 32.251254255748286,
        '79': 32.251283205958536,
        '80': 32.25129101464406,
        '81': 32.25127802956664,
        '82': 32.25124634091908,
        '83': 32.251199685387135,
        '84': 32.25114320940693,
        '85': 32.251083104534246,
        '86': 32.25102613992427,
        '87': 32.25097912740967,
        '88': 32.250948362457265,
        '89': 32.2509390884656,
        '90': 32.2509550318213,
        '91': 32.25099805324621,
        '92': 32.251067944710975,
        '93': 32.251162387031606,
        '94': 32.251277096743195,
        '95': 32.25140613678171,
        '96': 32.25146143657836,
        '97': 32.25143517950238,
        '98': 32.25065163653213,
        '99': 32.24396307207006,
        '100': 32.25200698544954,
        '101': 32.2520709388769,
        '102': 32.249292244314574,
        '103': 32.25144462011487,
        '104': 32.25093785807522,
        '105': 32.252046980217784,
        '106': 32.25198321891266,
        '107': 32.25190648596351,
        '108': 32.251823221380626,
        '109': 32.2517397831625,
        '110': 32.251661927590845,
        '111': 32.25159437132174,
        '112': 32.25154047162907,
        '113': 32.25150204805137,
        '114': 32.25147935379237,
        '115': 32.2514711901805,
        '116': 32.25147514390708,
        '117': 32.25148791602145,
        '118': 32.25150570476551,
        '119': 32.25152460180211,
        '120': 32.25154096323311,
        '121': 32.251551722518286,
        '122': 32.25155462108946,
        '123': 32.25154834291975,
        '124': 32.25153255025191,
        '125': 32.25150782785266,
        '126': 32.251475551481285,
        '127': 32.25143770198992,
        '128': 32.251396649239105,
        '129': 32.25135492983857,
        '130': 32.251315039983055,
        '131': 32.2512792599943,
        '132': 32.25124952140466,
        '133': 32.25122732136492,
        '134': 32.2512136835857,
        '135': 32.251209160513866,
        '136': 32.25121386835781,
        '137': 32.25122754501817,
        '138': 32.251249620842586,
        '139': 32.251279293119154,
        '140': 32.25131559697764,
        '141': 32.25135746747787,
        '142': 32.25140378978033,
        '143': 32.251453436157675,
        '144': 32.25150529007809,
        '145': 32.25155825864737,
        '146': 32.25161127539644,
        '147': 32.25166329585367,
        '148': 32.25171328865852,
        '149': 32.25176022522855,
        '150': 32.25180307120608,
        '151': 32.25184078303464,
        '152': 32.2518723129548,
        '153': 32.251896625343676,
        '154': 32.251912726551744,
        '155': 32.25191970916063,
        '156': 32.25191680992575,
        '157': 32.25190347869668,
        '158': 32.25187945353317,
        '159': 32.25184483532809,
        '160': 32.25180015380583,
        '161': 32.25174641606726,
        '162': 32.251685129110875,
        '163': 32.251618289084035,
        '164': 32.25154833238485,
        '165': 32.25147804698239,
        '166': 32.25141044615943,
        '167': 32.25134861091871,
        '168': 32.25129551108251,
        '169': 32.25125381820384,
        '170': 32.25122572539449,
        '171': 32.25121278975433,
        '172': 32.251215812098785,
        '173': 32.25123476612656,
        '174': 32.251268785229456,
        '175': 32.25131621016275,
        '176': 32.25137469524347,
        '177': 32.25144136519407,
        '178': 32.25151300979319,
        '179': 32.251586299695035,
        '180': 32.2516580045892,
        '-180': 32.2516580045892,
        '-179': 32.251725194593185,
        '-178': 32.25178540749053,
        '-177': 32.25183676801625,
        '-176': 32.25187805048872,
        '-175': 32.25190868214557,
        '-174': 32.25192869087372,
        '-173': 32.25193860688916,
        '-172': 32.25193933261216,
        '-171': 32.25193199791408,
        '-170': 32.25191781870757,
        '-169': 32.25189797539851,
        '-168': 32.251873524193364,
        '-167': 32.251845349112855,
        '-166': 32.25181415647883,
        '-165': 32.25178050743435,
        '-164': 32.25174487857437,
        '-163': 32.25170773676931,
        '-162': 32.25166961232386,
        '-161': 32.25163115502028,
        '-160': 32.25159316031245,
        '-159': 32.25155655760366,
        '-158': 32.251522358512226,
        '-157': 32.25149156946734,
        '-156': 32.251465078961054,
        '-155': 32.251443534446516,
        '-154': 32.25142722652012,
        '-153': 32.25141599823874,
        '-152': 32.25140919512256,
        '-151': 32.251405666849664,
        '-150': 32.25140382544917,
        '-149': 32.25140175776847,
        '-148': 32.25139738306927,
        '-147': 32.25138864072389,
        '-146': 32.251373688925675,
        '-145': 32.25135109362262,
        '-144': 32.25131998774167,
        '-143': 32.25128018404642,
        '-142': 32.25123223019651,
        '-141': 32.25117740103179,
        '-140': 32.25111762992269,
        '-139': 32.251055387324534,
        '-138': 32.250993519667986,
        '-137': 32.2509350648441,
        '-136': 32.25088306152237,
        '-135': 32.25084036840887,
        '-134': 32.250809506643,
        '-133': 32.25079253441083,
        '-132': 32.25079095822313,
        '-131': 32.25080568088763,
        '-130': 32.25083698262953,
        '-129': 32.250884529518025,
        '-128': 32.250947402529675,
        '-127': 32.25102414113927,
        '-126': 32.25111279695077,
        '-125': 32.25121099506576,
        '-124': 32.251316003058044,
        '-123': 32.25142480903316,
        '-122': 32.25153421088724,
        '-121': 32.25164091833704,
        '-120': 32.25174166761887,
        '-119': 32.251833346240076,
        '-118': 32.25191312229758,
        '-117': 32.2519785702513,
        '-116': 32.252027783247,
        '-115': 32.25205946160668,
        '-114': 32.25207296822056,
        '-113': 32.252068344282684,
        '-112': 32.25204628283165,
        '-111': 32.252008062347414,
        '-110': 32.25195544749731,
        '-109': 32.25189056824183,
        '-108': 32.251815791185756,
        '-107': 32.25173359776878,
        '-106': 32.25164648239837,
        '-105': 32.251556880043196,
        '-104': 32.25121423081435,
        '-103': 32.24964987075662,
        '-102': 32.24979919403447,
        '-101': 32.2512188830779,
        '-100': 32.25115011752364,
        '-99': 32.25109173752896,
        '-98': 32.25104570064168,
        '-97': 32.25101382547263,
        '-96': 32.250997669956064,
        '-95': 32.25099837022884,
        '-94': 32.24994480598453,
        '-93': 32.248584844860396,
        '-92': 32.247068840946994,
        '-91': 32.24190708138084,
        '-90': 32.24659102865627,
        '-89': 32.23624883087062,
        '-88': 32.251404044048414,
        '-87': 32.25132793660726,
        '-86': 32.25134140754167,
        '-85': 32.25159036959757,
        '-84': 32.248538842239576,
        '-83': 32.2497199062427,
        '-82': 32.24964797177159,
        '-81': 32.24205208098945,
        '-80': 32.23599943079196,
        '-79': 32.24552311292959,
        '-78': 32.24011631615081,
        '-77': 32.25042869144778,
        '-76': 32.25090206217341,
        '-75': 32.2507663791104,
        '-74': 32.25064805561168,
        '-73': 32.25055620907836,
        '-72': 32.25049880895477,
        '-71': 32.250482138335016,
        '-70': 32.25051037043927,
        '-69': 32.2505852888804,
        '-68': 32.24865488869951,
        '-67': 32.24864635695618,
        '-66': 32.24809879828567,
        '-65': 32.24311236644736,
        '-64': 32.24117800506976,
        '-63': 32.23975924493638,
        '-62': 32.2386237028935,
        '-61': 32.23772110889945,
        '-60': 32.236941118166456,
        '-59': 32.236295866659106,
        '-58': 32.23557629022523,
        '-57': 32.23496981189001,
        '-56': 32.23443304550324,
        '-55': 32.23398539044442,
        '-54': 32.233466085216904,
        '-53': 32.23290870912872,
        '-52': 32.2323688819468,
        '-51': 32.231711478642644,
        '-50': 32.23116685713548,
        '-49': 32.23058163584154,
        '-48': 32.23000511516951,
        '-47': 32.229486368713474,
        '-46': 32.2290434099401,
        '-45': 32.22872336281953,
        '-44': 32.228511047094585,
        '-43': 32.228439778960094,
        '-42': 32.22858097139285,
        '-41': 32.22888232853131,
        '-40': 32.22928908888234,
        '-39': 32.22979469654936,
        '-38': 32.23037039560886,
        '-37': 32.23104658818369,
        '-36': 32.23173151610512,
        '-35': 32.23243439910538,
        '-34': 32.23324609140252,
        '-33': 32.234006140951706,
        '-32': 32.234920475846565,
        '-31': 32.235833817383465,
        '-30': 32.23671563705405,
        '-29': 32.23786263471337,
        '-28': 32.23894805491903,
        '-27': 32.240093439118844,
        '-26': 32.24107939061458,
        '-25': 32.241931175939236,
        '-24': 32.24231140936372,
        '-23': 32.24418518482338,
        '-22': 32.24686970533006,
        '-21': 32.24845887934756,
        '-20': 32.24982022146679,
        '-19': 32.24996934290797,
        '-18': 32.248916707062506,
        '-17': 32.250111338104574,
        '-16': 32.250273782632114,
        '-15': 32.25048300501136,
        '-14': 32.25072507753888,
        '-13': 32.25098475650207,
        '-12': 32.251246696144555,
        '-11': 32.251496637050714,
        '-10': 32.25172242549517,
        '-9': 32.251914879380145,
        '-8': 32.252068337877795,
        '-7': 32.25218086566306,
        '-6': 32.25225412109917,
        '-5': 32.25229290694653,
        '-4': 32.252304459215864,
        '-3': 32.25229755394972,
        '-2': 32.252281527014155,
        '-1': 32.252265306930354
    },
    '80': {
        '0': 32.25362945791893,
        '1': 32.25363391119296,
        '2': 32.25364326711077,
        '3': 32.25365891529156,
        '4': 32.25368112178494,
        '5': 32.25370908893204,
        '6': 32.25374111212028,
        '7': 32.25377481115414,
        '8': 32.253807408507285,
        '9': 32.25383602441304,
        '10': 32.2538579595972,
        '11': 32.25387094013301,
        '12': 32.25387330479588,
        '13': 32.25386412456104,
        '14': 32.2538432710011,
        '15': 32.253811318885795,
        '16': 32.25376948940738,
        '17': 32.25371953373777,
        '18': 32.25366356651113,
        '19': 32.25133671900835,
        '20': 32.24812861719584,
        '21': 32.247724567286724,
        '22': 32.25010559788644,
        '23': 32.251033669907926,
        '24': 32.24805140711286,
        '25': 32.2477158389144,
        '26': 32.248165386995616,
        '27': 32.253153539197235,
        '28': 32.25318995436388,
        '29': 32.25317081898071,
        '30': 32.253156090555294,
        '31': 32.253144928816724,
        '32': 32.25313646461008,
        '33': 32.25312984828923,
        '34': 32.2531242867066,
        '35': 32.25311906910268,
        '36': 32.25311358294678,
        '37': 32.253107321128695,
        '38': 32.253099881945424,
        '39': 32.2530909629605,
        '40': 32.25308035006735,
        '41': 32.25306790206135,
        '42': 32.253053531540246,
        '43': 32.253037183099,
        '44': 32.25301880966755,
        '45': 32.252998348651346,
        '46': 32.25297569989727,
        '47': 32.25295070794648,
        '48': 32.252923151256596,
        '49': 32.252892740987654,
        '50': 32.25285913148205,
        '51': 32.25031296181733,
        '52': 32.25278080169214,
        '53': 32.252735380572794,
        '54': 32.25268546314906,
        '55': 32.25263100050021,
        '56': 32.25257217084375,
        '57': 32.25250943010918,
        '58': 32.25244354751919,
        '59': 32.250312555062315,
        '60': 32.252307060707615,
        '61': 32.2522395582834,
        '62': 32.25217500722018,
        '63': 32.25211541096247,
        '64': 32.25206276352686,
        '65': 32.25201891745954,
        '66': 32.25198544827539,
        '67': 32.251963526861616,
        '68': 32.25195381170579,
        '69': 32.25195637207745,
        '70': 32.25197065144089,
        '71': 32.25199547749365,
        '72': 32.252029121512784,
        '73': 32.25206940544344,
        '74': 32.25211385074951,
        '75': 32.252159858870364,
        '76': 32.2522049096047,
        '77': 32.252246761246134,
        '78': 32.25228363512988,
        '79': 32.252314367607944,
        '80': 32.2523385144162,
        '81': 32.25235639584425,
        '82': 32.25236907583564,
        '83': 32.25237827376461,
        '84': 32.2523862143651,
        '85': 32.25239542897822,
        '86': 32.25240850882,
        '87': 32.252427852798334,
        '88': 32.252455420353975,
        '89': 32.252492512660204,
        '90': 32.25253960420915,
        '91': 32.252596242142765,
        '92': 32.25192252724101,
        '93': 32.252246036445946,
        '94': 32.25273431322441,
        '95': 32.25204814573322,
        '96': 32.252187157848454,
        '97': 32.24699533092045,
        '98': 32.25305442003185,
        '99': 32.25273407786751,
        '100': 32.25310593064273,
        '101': 32.253106391056754,
        '102': 32.25309000318277,
        '103': 32.2530581891022,
        '104': 32.25301338961836,
        '105': 32.2529588738257,
        '106': 32.252898478859834,
        '107': 32.25283630236567,
        '108': 32.252776374498005,
        '109': 32.25272233805343,
        '110': 32.252677164459925,
        '111': 32.25264292991652,
        '112': 32.25262067033269,
        '113': 32.2526103264291,
        '114': 32.252610782146185,
        '115': 32.252619991165645,
        '116': 32.25263517867397,
        '117': 32.25265309919585,
        '118': 32.25267032695253,
        '119': 32.252683553102045,
        '120': 32.252689864505555,
        '121': 32.25268698121486,
        '122': 32.25267343434965,
        '123': 32.252648671926366,
        '124': 32.25261308689653,
        '125': 32.25256796849548,
        '126': 32.25251538436508,
        '127': 32.25245800625063,
        '128': 32.252398895977585,
        '129': 32.25234127064234,
        '130': 32.252288266430526,
        '131': 32.25224271930506,
        '132': 32.25220697821673,
        '133': 32.252182762827374,
        '134': 32.25217107340132,
        '135': 32.252172155938474,
        '136': 32.25218552118962,
        '137': 32.25221001225743,
        '138': 32.25224391230813,
        '139': 32.25228508167313,
        '140': 32.25233111238704,
        '141': 32.252379487980726,
        '142': 32.252427737043504,
        '143': 32.252473570547615,
        '144': 32.25251499501018,
        '145': 32.25255039604784,
        '146': 32.25257858954913,
        '147': 32.252598840337306,
        '148': 32.25261085063856,
        '149': 32.25261472273769,
        '150': 32.252610901767746,
        '151': 32.252600105550876,
        '152': 32.2525832487378,
        '153': 32.2525613681874,
        '154': 32.25253555563703,
        '155': 32.252506902338396,
        '156': 32.25247645861128,
        '157': 32.2524452093702,
        '158': 32.25241406479246,
        '159': 32.25238386360684,
        '160': 32.25235538515922,
        '161': 32.25232936558743,
        '162': 32.25230651319397,
        '163': 32.25228751846721,
        '164': 32.25227305512248,
        '165': 32.25226376991347,
        '166': 32.2522602606423,
        '167': 32.2522630435805,
        '168': 32.25227251319472,
        '169': 32.2522888984467,
        '170': 32.25231222083618,
        '171': 32.25234225965557,
        '172': 32.25237852957296,
        '173': 32.25242027467208,
        '174': 32.25246648155063,
        '175': 32.252515912169784,
        '176': 32.25256715506783,
        '177': 32.252618691537315,
        '178': 32.25266897165284,
        '179': 32.252716493836886,
        '180': 32.252759881118656,
        '-180': 32.252759881118656,
        '-179': 32.25279794745845,
        '-178': 32.25282974847471,
        '-177': 32.25285461252753,
        '-176': 32.25287215021028,
        '-175': 32.25288224263993,
        '-174': 32.2528850112452,
        '-173': 32.252880773750455,
        '-172': 32.25286999249428,
        '-171': 32.25285322191637,
        '-170': 32.252831061895044,
        '-169': 32.252804122621264,
        '-168': 32.252773004959415,
        '-167': 32.25273829797448,
        '-166': 32.25270059277385,
        '-165': 32.2526605093366,
        '-164': 32.25261873090215,
        '-163': 32.252576039044094,
        '-162': 32.252533341969446,
        '-161': 32.25249168896275,
        '-160': 32.25245226522458,
        '-159': 32.2524163635032,
        '-158': 32.25238533164976,
        '-157': 32.25236049822993,
        '-156': 32.252343081244256,
        '-155': 32.25233408749914,
        '-154': 32.25233421192679,
        '-153': 32.252343746954516,
        '-152': 32.252362511763714,
        '-151': 32.25238980996355,
        '-150': 32.252424421968115,
        '-149': 32.25246463543855,
        '-148': 32.25250831384085,
        '-147': 32.2525529998177,
        '-146': 32.25259604701422,
        '-145': 32.25263477153115,
        '-144': 32.252666612520294,
        '-143': 32.25268929071637,
        '-142': 32.25270095393564,
        '-141': 32.25270029969286,
        '-140': 32.25268666693539,
        '-139': 32.25266009125465,
        '-138': 32.2526213205701,
        '-137': 32.252571790948984,
        '-136': 32.252513564717106,
        '-135': 32.25244923516384,
        '-134': 32.25238180384193,
        '-133': 32.25231453765523,
        '-132': 32.25225081361603,
        '-131': 32.25219395937287,
        '-130': 32.25214709742125,
        '-129': 32.25211300037921,
        '-128': 32.25209396390284,
        '-127': 32.25209170278631,
        '-126': 32.25210727457856,
        '-125': 32.25214103368872,
        '-124': 32.25219261747897,
        '-123': 32.25226096429839,
        '-122': 32.25234436185236,
        '-121': 32.252440522807134,
        '-120': 32.25254668318976,
        '-119': 32.252659718065566,
        '-118': 32.252776268254024,
        '-117': 32.25289287156123,
        '-116': 32.25300609220237,
        '-115': 32.25311264275666,
        '-114': 32.2532094940723,
        '-113': 32.25329396990521,
        '-112': 32.25336382456293,
        '-111': 32.2534173032494,
        '-110': 32.253453185972404,
        '-109': 32.25347081662674,
        '-108': 32.25347011909463,
        '-107': 32.25345160188506,
        '-106': 32.2534163520285,
        '-105': 32.253366017799756,
        '-104': 32.25330277858477,
        '-103': 32.253229299100376,
        '-102': 32.25314866449559,
        '-101': 32.25306429308303,
        '-100': 32.25296970599312,
        '-99': 32.251866823408065,
        '-98': 32.25282539808556,
        '-97': 32.25276243916136,
        '-96': 32.25191381721094,
        '-95': 32.252274731867274,
        '-94': 32.247676485938186,
        '-93': 32.239416817189316,
        '-92': 32.23628077474415,
        '-91': 32.24003339886579,
        '-90': 32.24744958183736,
        '-89': 32.250435631022604,
        '-88': 32.25266662938165,
        '-87': 32.25292236853141,
        '-86': 32.25228930174203,
        '-85': 32.251267466685626,
        '-84': 32.25147059222398,
        '-83': 32.25116366952668,
        '-82': 32.25216474895647,
        '-81': 32.24363561449326,
        '-80': 32.24048076318771,
        '-79': 32.2364189894574,
        '-78': 32.23864064826944,
        '-77': 32.23464562611978,
        '-76': 32.242610710802815,
        '-75': 32.23732016443831,
        '-74': 32.239475726221805,
        '-73': 32.241870784343696,
        '-72': 32.245401955113415,
        '-71': 32.247360518352835,
        '-70': 32.25107535190828,
        '-69': 32.25098878250411,
        '-68': 32.2509416543543,
        '-67': 32.25093593368289,
        '-66': 32.25097179923428,
        '-65': 32.24995737494555,
        '-64': 32.24609231759059,
        '-63': 32.24783178972745,
        '-62': 32.24812395142195,
        '-61': 32.24637571198235,
        '-60': 32.24246826277406,
        '-59': 32.24153611736848,
        '-58': 32.24140176745171,
        '-57': 32.24052361672753,
        '-56': 32.23955341449731,
        '-55': 32.238689534744914,
        '-54': 32.237686806456516,
        '-53': 32.23679671518284,
        '-52': 32.235776565885644,
        '-51': 32.23505219408622,
        '-50': 32.23440321915012,
        '-49': 32.23399426259465,
        '-48': 32.233717352416924,
        '-47': 32.23341410650137,
        '-46': 32.2330574759232,
        '-45': 32.23271099441363,
        '-44': 32.23252850672994,
        '-43': 32.23228871991026,
        '-42': 32.23220413953162,
        '-41': 32.232374874595294,
        '-40': 32.23270771674947,
        '-39': 32.233088151545054,
        '-38': 32.23354240979662,
        '-37': 32.23396460547369,
        '-36': 32.234359885139135,
        '-35': 32.23480438451902,
        '-34': 32.23530397095105,
        '-33': 32.23595654123325,
        '-32': 32.23655768565596,
        '-31': 32.23722842058894,
        '-30': 32.23810163038819,
        '-29': 32.23880608631026,
        '-28': 32.239943688495266,
        '-27': 32.24178383312867,
        '-26': 32.24448582678035,
        '-25': 32.24612631123249,
        '-24': 32.24893948081904,
        '-23': 32.247796980110174,
        '-22': 32.24513510865134,
        '-21': 32.24447833025184,
        '-20': 32.25225958054045,
        '-19': 32.24432282057901,
        '-18': 32.24665638889662,
        '-17': 32.25225481192231,
        '-16': 32.25232901184783,
        '-15': 32.25243303081746,
        '-14': 32.25255976224407,
        '-13': 32.25270129582805,
        '-12': 32.25284949009721,
        '-11': 32.252996513204046,
        '-10': 32.25313541522171,
        '-9': 32.253260550730985,
        '-8': 32.253367882102836,
        '-7': 32.253455141952955,
        '-6': 32.25352184220409,
        '-5': 32.25356913608001,
        '-4': 32.25359955234041,
        '-3': 32.25361663184431,
        '-2': 32.25362450410097,
        '-1': 32.25362744517522
    },
    '81': {
        '0': 32.254767596477535,
        '1': 32.25477627428132,
        '2': 32.254777452745515,
        '3': 32.25477209866654,
        '4': 32.254761067296585,
        '5': 32.25474507707335,
        '6': 32.254724713151354,
        '7': 32.25470045534037,
        '8': 32.25467272382207,
        '9': 32.25464193461377,
        '10': 32.25460855626154,
        '11': 32.25457315966087,
        '12': 32.25453645412005,
        '13': 32.25449930462204,
        '14': 32.254462727484814,
        '15': 32.254427864016776,
        '16': 32.25439593407196,
        '17': 32.25436817341216,
        '18': 32.25434576031097,
        '19': 32.25432973777948,
        '20': 32.25432093810882,
        '21': 32.2543199161312,
        '22': 32.254326896769484,
        '23': 32.254341741192,
        '24': 32.254363934355304,
        '25': 32.254392595051954,
        '26': 32.2544265079313,
        '27': 32.254464175452085,
        '28': 32.25450388645929,
        '29': 32.25454379711889,
        '30': 32.25458201932913,
        '31': 32.25461671145878,
        '32': 32.2546461663217,
        '33': 32.25466889164274,
        '34': 32.254683678853574,
        '35': 32.25468965682249,
        '36': 32.25468632801544,
        '37': 32.25467358555616,
        '38': 32.254651710655786,
        '39': 32.25462135087562,
        '40': 32.254583480637876,
        '41': 32.254539346271685,
        '42': 32.25449039970789,
        '43': 32.254438220968034,
        '44': 32.25438443522009,
        '45': 32.254330635261915,
        '46': 32.254278304665384,
        '47': 32.25422874914927,
        '48': 32.254183040062884,
        '49': 32.254141973074006,
        '50': 32.25410604423082,
        '51': 32.2540754444804,
        '52': 32.25405007254357,
        '53': 32.25402956484752,
        '54': 32.254013340086416,
        '55': 32.25259418540514,
        '56': 32.25304962104468,
        '57': 32.25308191123589,
        '58': 32.25397530868617,
        '59': 32.25396833027269,
        '60': 32.253960936356364,
        '61': 32.25019853308667,
        '62': 32.253943225131756,
        '63': 32.25393252571909,
        '64': 32.25392063543483,
        '65': 32.252197591091814,
        '66': 32.25389425799902,
        '67': 32.25388049284195,
        '68': 32.253866898011175,
        '69': 32.25385388420838,
        '70': 32.2538418177152,
        '71': 32.25383099748757,
        '72': 32.253821643679125,
        '73': 32.2538138980383,
        '74': 32.25380783527469,
        '75': 32.2538034831973,
        '76': 32.25380084835708,
        '77': 32.253799943198295,
        '78': 32.253800810429574,
        '79': 32.25380354051204,
        '80': 32.25380827883219,
        '81': 32.253815220228276,
        '82': 32.25382458997603,
        '83': 32.253836611973284,
        '84': 32.25385146652944,
        '85': 32.25386924169521,
        '86': 32.25388988336516,
        '87': 32.25391314933376,
        '88': 32.25393857459174,
        '89': 32.253965452818385,
        '90': 32.25399283790409,
        '91': 32.25401956808304,
        '92': 32.254044315539865,
        '93': 32.2540656573917,
        '94': 32.25397082786468,
        '95': 32.25368764393508,
        '96': 32.2537412975642,
        '97': 32.25409047128003,
        '98': 32.254076831512414,
        '99': 32.25405465102198,
        '100': 32.25402444009082,
        '101': 32.25398719985818,
        '102': 32.25394438494456,
        '103': 32.253897829322725,
        '104': 32.253849639312755,
        '105': 32.253802060577115,
        '106': 32.2537573285131,
        '107': 32.25371751323629,
        '108': 32.25368437123254,
        '109': 32.25365921562479,
        '110': 32.25364281583537,
        '111': 32.253635335308545,
        '112': 32.253636313057314,
        '113': 32.25364469135664,
        '114': 32.25365888821543,
        '115': 32.253676909640006,
        '116': 32.25369649346506,
        '117': 32.253715273953766,
        '118': 32.25373095467552,
        '119': 32.253741476498575,
        '120': 32.253745167935584,
        '121': 32.25374086650771,
        '122': 32.253728002118294,
        '123': 32.25370663644123,
        '124': 32.253677455775154,
        '125': 32.253641718400594,
        '126': 32.253601160912446,
        '127': 32.253557871010166,
        '128': 32.25351413658916,
        '129': 32.25347228251898,
        '130': 32.253434507127544,
        '131': 32.25340273011616,
        '132': 32.2533784624685,
        '133': 32.25336270701061,
        '134': 32.253355895804816,
        '135': 32.2533578677317,
        '136': 32.2533678866569,
        '137': 32.25338469771993,
        '138': 32.25340661672557,
        '139': 32.253431645536104,
        '140': 32.25345760487886,
        '141': 32.253482275173845,
        '142': 32.253503535869335,
        '143': 32.25351949432033,
        '144': 32.253528596374814,
        '145': 32.25352971243696,
        '146': 32.25352219470984,
        '147': 32.2535059034311,
        '148': 32.25348120204353,
        '149': 32.25344892324231,
        '150': 32.25341030957819,
        '151': 32.25336693367218,
        '152': 32.253320604037434,
        '153': 32.253273262977984,
        '154': 32.25322688303885,
        '155': 32.25318336805821,
        '156': 32.25314446408109,
        '157': 32.25311168432544,
        '158': 32.253086251146286,
        '159': 32.25306905662904,
        '160': 32.25306064216233,
        '161': 32.253061196185,
        '162': 32.25307056834132,
        '163': 32.25308829756439,
        '164': 32.25311365115766,
        '165': 32.25314567175784,
        '166': 32.25318322911002,
        '167': 32.25322507382349,
        '168': 32.25326989064625,
        '169': 32.25331634923814,
        '170': 32.253363150877135,
        '171': 32.253409069953314,
        '172': 32.25345298945631,
        '173': 32.25349392992698,
        '174': 32.253531071527085,
        '175': 32.25356376899581,
        '176': 32.25359155934217,
        '177': 32.253614162201,
        '178': 32.253631472895144,
        '179': 32.253643548424485,
        '180': 32.25365058686216,
        '-180': 32.25365058686216,
        '-179': 32.25365290097668,
        '-178': 32.25365088729968,
        '-177': 32.25364499228306,
        '-176': 32.253635677589195,
        '-175': 32.25362338687024,
        '-174': 32.25360851656277,
        '-173': 32.25359139319498,
        '-172': 32.25357225944286,
        '-171': 32.25355127066358,
        '-170': 32.25352850289377,
        '-169': 32.25350397236972,
        '-168': 32.253477665575886,
        '-167': 32.253449577749244,
        '-166': 32.25341975676512,
        '-165': 32.25338834851218,
        '-164': 32.253355639330024,
        '-163': 32.25332209091062,
        '-162': 32.25328836330079,
        '-161': 32.25325532230132,
        '-160': 32.25322402860405,
        '-159': 32.253195707375916,
        '-158': 32.25317169857969,
        '-157': 32.2531533899859,
        '-156': 32.253142136435414,
        '-155': 32.25313917031019,
        '-154': 32.2531455092328,
        '-153': 32.25316186763838,
        '-152': 32.25318857897825,
        '-151': 32.253225534900196,
        '-150': 32.253272146828145,
        '-149': 32.25332733399976,
        '-148': 32.253389540317336,
        '-147': 32.253456780457434,
        '-146': 32.25352671371214,
        '-145': 32.25359674215089,
        '-144': 32.253664128032504,
        '-143': 32.25372612408203,
        '-142': 32.2537801093607,
        '-141': 32.25382372305364,
        '-140': 32.25385498859138,
        '-139': 32.25387242108709,
        '-138': 32.253875112057706,
        '-137': 32.25386278672428,
        '-136': 32.253835830758746,
        '-135': 32.253795285053684,
        '-134': 32.25374280883053,
        '-133': 32.253680613067985,
        '-132': 32.25361136773376,
        '-131': 32.25353808756474,
        '-130': 32.25346400210608,
        '-129': 32.25339241635178,
        '-128': 32.253326568614014,
        '-127': 32.253269492187535,
        '-126': 32.25322388699419,
        '-125': 32.253192006728156,
        '-124': 32.253175566127034,
        '-123': 32.25317567192946,
        '-122': 32.25319277991165,
        '-121': 32.253226679192004,
        '-120': 32.253276503818824,
        '-119': 32.25334077056534,
        '-118': 32.25341744089586,
        '-117': 32.253504004264954,
        '-116': 32.25359757928227,
        '-115': 32.25369502881877,
        '-114': 32.25379308482941,
        '-113': 32.25388847849841,
        '-112': 32.25397807124597,
        '-111': 32.25405898214226,
        '-110': 32.25412870733115,
        '-109': 32.2541852271649,
        '-108': 32.25422709689414,
        '-107': 32.25425351696588,
        '-106': 32.25426437929124,
        '-105': 32.254260286294816,
        '-104': 32.25424254019838,
        '-103': 32.25421310085853,
        '-102': 32.25417451159655,
        '-101': 32.2541297938261,
        '-100': 32.254082312868114,
        '-99': 32.25403561907422,
        '-98': 32.25399327015788,
        '-97': 32.25395864232623,
        '-96': 32.253934739264054,
        '-95': 32.25345862502977,
        '-94': 32.25388771588869,
        '-93': 32.25151006250471,
        '-92': 32.2532452380617,
        '-91': 32.25403407212788,
        '-90': 32.25409689599111,
        '-89': 32.25416925044299,
        '-88': 32.25401452147539,
        '-87': 32.25432653152039,
        '-86': 32.25228642357245,
        '-85': 32.250692661331826,
        '-84': 32.24534994086979,
        '-83': 32.24523124533626,
        '-82': 32.24531249117904,
        '-81': 32.24223019833861,
        '-80': 32.25166079051225,
        '-79': 32.25443199941972,
        '-78': 32.250177744475636,
        '-77': 32.25134179592051,
        '-76': 32.24813871129658,
        '-75': 32.2514202337522,
        '-74': 32.24381041405902,
        '-73': 32.241046552505445,
        '-72': 32.24441297739535,
        '-71': 32.24125081646593,
        '-70': 32.24331924205279,
        '-69': 32.24306614473975,
        '-68': 32.245526988815875,
        '-67': 32.248766872337974,
        '-66': 32.25216369010253,
        '-65': 32.252065381605036,
        '-64': 32.2430755190925,
        '-63': 32.24126479816044,
        '-62': 32.24593354551188,
        '-61': 32.251754117399166,
        '-60': 32.2438661556564,
        '-59': 32.23895214164206,
        '-58': 32.2391468547612,
        '-57': 32.23869536226419,
        '-56': 32.23915876188279,
        '-55': 32.24035260009218,
        '-54': 32.2413553745044,
        '-53': 32.24104308975341,
        '-52': 32.24097840352293,
        '-51': 32.24192723193165,
        '-50': 32.24381772104482,
        '-49': 32.24345466272618,
        '-48': 32.24191739191169,
        '-47': 32.23987168562351,
        '-46': 32.238600434166884,
        '-45': 32.23831511284894,
        '-44': 32.238104583694266,
        '-43': 32.23746161653555,
        '-42': 32.23638432930174,
        '-41': 32.235690121243515,
        '-40': 32.235761417901685,
        '-39': 32.23643421426259,
        '-38': 32.23717998679812,
        '-37': 32.23793562044121,
        '-36': 32.23874953380741,
        '-35': 32.23983251515357,
        '-34': 32.241142804700345,
        '-33': 32.242143137068695,
        '-32': 32.24252990021453,
        '-31': 32.242668731587074,
        '-30': 32.243209650510735,
        '-29': 32.24437824276735,
        '-28': 32.24799015979326,
        '-27': 32.251812669936136,
        '-26': 32.246093801530314,
        '-25': 32.24821771934235,
        '-24': 32.25296646912906,
        '-23': 32.24846731207048,
        '-22': 32.252804853322914,
        '-21': 32.2466324673518,
        '-20': 32.25058632424429,
        '-19': 32.2517438455419,
        '-18': 32.24597114094121,
        '-17': 32.24555364483686,
        '-16': 32.247345202550974,
        '-15': 32.25209286826395,
        '-14': 32.25263430610928,
        '-13': 32.253870241085366,
        '-12': 32.253955650952186,
        '-11': 32.25404942046383,
        '-10': 32.25414733720398,
        '-9': 32.25424545819487,
        '-8': 32.25434028916346,
        '-7': 32.25442891756287,
        '-6': 32.254509093556656,
        '-5': 32.25457925772195,
        '-4': 32.25463851865278,
        '-3': 32.25468658754927,
        '-2': 32.25472367993104,
        '-1': 32.254750396570365
    },
    '82': {
        '0': 32.255418953684284,
        '1': 32.255421072403905,
        '2': 32.25540878505913,
        '3': 32.25538271049479,
        '4': 32.25534384006031,
        '5': 32.255293502172684,
        '6': 32.2552333226795,
        '7': 32.25516518155152,
        '8': 32.255091166110105,
        '9': 32.2550135207917,
        '10': 32.25493459339746,
        '11': 32.25485677787809,
        '12': 32.254782453951776,
        '13': 32.254713924215665,
        '14': 32.25465334984926,
        '15': 32.25460268646635,
        '16': 32.25456362209885,
        '17': 32.25453751963588,
        '18': 32.25452536625232,
        '19': 32.25452773240565,
        '20': 32.25454474284324,
        '21': 32.254576061736586,
        '22': 32.25462089356051,
        '23': 32.25467800068863,
        '24': 32.25474573792081,
        '25': 32.25482210334084,
        '26': 32.25490480407457,
        '27': 32.25499133473161,
        '28': 32.25507906561829,
        '29': 32.255165337245074,
        '30': 32.25524755725635,
        '31': 32.25532329570329,
        '32': 32.25539037457758,
        '33': 32.25544694772215,
        '34': 32.255491567628226,
        '35': 32.25552323619151,
        '36': 32.25554143721039,
        '37': 32.25554614922633,
        '38': 32.25553783819339,
        '39': 32.25551743037669,
        '40': 32.255486266773644,
        '41': 32.25544604118531,
        '42': 32.255398724796585,
        '43': 32.2553464807193,
        '44': 32.25529157238046,
        '45': 32.255236269879035,
        '46': 32.25518275847622,
        '47': 32.255133053222536,
        '48': 32.255088923369776,
        '49': 32.25505182968463,
        '50': 32.25502287710144,
        '51': 32.25500278436061,
        '52': 32.25499187142058,
        '53': 32.25499006454986,
        '54': 32.25499691815353,
        '55': 32.25501165160818,
        '56': 32.25503319871707,
        '57': 32.255060266885486,
        '58': 32.25509140277917,
        '59': 32.25512506108363,
        '60': 32.25515967302414,
        '61': 32.255193711529856,
        '62': 32.25522575030403,
        '63': 32.25525451456462,
        '64': 32.25527892180793,
        '65': 32.25529811157714,
        '66': 32.2553114638461,
        '67': 32.2553186062154,
        '68': 32.25531941062907,
        '69': 32.25531398073039,
        '70': 32.255302631268314,
        '71': 32.255285861139356,
        '72': 32.25526432170737,
        '73': 32.2552387820027,
        '74': 32.25521009228527,
        '75': 32.25517914728895,
        '76': 32.25514685027645,
        '77': 32.255114078850546,
        '78': 32.25508165330903,
        '79': 32.25505030821135,
        '80': 32.25502066774996,
        '81': 32.25499322548245,
        '82': 32.25496832897263,
        '83': 32.25494616988515,
        '84': 32.25492678006228,
        '85': 32.25491003405183,
        '86': 32.254895658437725,
        '87': 32.254883248128685,
        '88': 32.254872289483636,
        '89': 32.25486218979892,
        '90': 32.25485231226847,
        '91': 32.25484201508393,
        '92': 32.254830692903106,
        '93': 32.254817818528956,
        '94': 32.25480298234973,
        '95': 32.25478592694121,
        '96': 32.25476657425715,
        '97': 32.25474504305786,
        '98': 32.25472165465684,
        '99': 32.254696925690084,
        '100': 32.25467154740134,
        '101': 32.254646351841565,
        '102': 32.25462226633842,
        '103': 32.25460025852774,
        '104': 32.25458127507695,
        '105': 32.25456617789399,
        '106': 32.25455568203774,
        '107': 32.25455029968038,
        '108': 32.25455029428506,
        '109': 32.254555648655135,
        '110': 32.254566049702994,
        '111': 32.25458089172846,
        '112': 32.25459929875892,
        '113': 32.25462016517526,
        '114': 32.254642212528616,
        '115': 32.254664059244924,
        '116': 32.25468429891218,
        '117': 32.25470158213246,
        '118': 32.254714696556206,
        '119': 32.25472263973473,
        '120': 32.25472467983382,
        '121': 32.25472040001996,
        '122': 32.25470972340811,
        '123': 32.25469291676977,
        '124': 32.25467057264378,
        '125': 32.25464357096825,
        '126': 32.254613022748785,
        '127': 32.25458019949772,
        '128': 32.25454645313176,
        '129': 32.254513131637395,
        '130': 32.25448149606096,
        '131': 32.2544526442432,
        '132': 32.25442744620851,
        '133': 32.254406495281984,
        '134': 32.25439007790544,
        '135': 32.25437816383881,
        '136': 32.25437041706027,
        '137': 32.2543662263121,
        '138': 32.254364752973565,
        '139': 32.25436499286067,
        '140': 32.2543658477229,
        '141': 32.254366201676916,
        '142': 32.25436499761306,
        '143': 32.25436130873478,
        '144': 32.254354400823374,
        '145': 32.25434378152263,
        '146': 32.254329233852694,
        '147': 32.254310832222345,
        '148': 32.25428894034191,
        '149': 32.25426419156575,
        '150': 32.25423745324621,
        '151': 32.25420977759536,
        '152': 32.254182342275065,
        '153': 32.25415638443598,
        '154': 32.2541331321799,
        '155': 32.254113737427396,
        '156': 32.25409921394251,
        '157': 32.254090383829606,
        '158': 32.25408783520809,
        '159': 32.25409189303825,
        '160': 32.25410260426216,
        '161': 32.25411973759251,
        '162': 32.254142797475836,
        '163': 32.25417105101829,
        '164': 32.25420356602946,
        '165': 32.25423925783767,
        '166': 32.25427694217786,
        '167': 32.254315391257016,
        '168': 32.254353390062036,
        '169': 32.25438979008142,
        '170': 32.25442355785052,
        '171': 32.25445381608144,
        '172': 32.254479875579264,
        '173': 32.2545012566537,
        '174': 32.25451769928374,
        '175': 32.25452916186033,
        '176': 32.25453580889334,
        '177': 32.25453798860482,
        '178': 32.25453620181907,
        '179': 32.25453106398199,
        '180': 32.254523262481804,
        '-180': 32.254523262481804,
        '-179': 32.25451351168306,
        '-178': 32.2545025082135,
        '-177': 32.254490889047965,
        '-176': 32.25447919480969,
        '-175': 32.254467840454346,
        '-174': 32.254457095123065,
        '-173': 32.25444707245706,
        '-172': 32.25443773208005,
        '-171': 32.25442889230057,
        '-170': 32.25442025339925,
        '-169': 32.254411430184504,
        '-168': 32.254401991866494,
        '-167': 32.254391506756455,
        '-166': 32.25437958888655,
        '-165': 32.25436594340019,
        '-164': 32.25435040750857,
        '-163': 32.25433298396224,
        '-162': 32.254313864346194,
        '-161': 32.25429344006144,
        '-160': 32.25427229957572,
        '-159': 32.254251211371574,
        '-158': 32.254231092937474,
        '-157': 32.25421296708068,
        '-156': 32.25419790772321,
        '-155': 32.25418697811575,
        '-154': 32.25418116501287,
        '-153': 32.254181312749246,
        '-152': 32.25418806131159,
        '-151': 32.25420179239742,
        '-150': 32.25422258709138,
        '-149': 32.254250198193986,
        '-148': 32.25428403943896,
        '-147': 32.25432319288619,
        '-146': 32.25436643473445,
        '-145': 32.254412278727834,
        '-144': 32.2544590352995,
        '-143': 32.254504883666314,
        '-142': 32.25454795331844,
        '-141': 32.25458641077864,
        '-140': 32.25461854717416,
        '-139': 32.254642862079955,
        '-138': 32.25465813926258,
        '-137': 32.254663510360324,
        '-136': 32.25465850315249,
        '-135': 32.25464307186038,
        '-134': 32.25461760783611,
        '-133': 32.254582929983115,
        '-132': 32.25454025525867,
        '-131': 32.254491150582574,
        '-130': 32.2544374683682,
        '-129': 32.25438126866149,
        '-128': 32.25432473148539,
        '-127': 32.254270063419206,
        '-126': 32.25421940267879,
        '-125': 32.25417472700171,
        '-124': 32.25413776848624,
        '-123': 32.25410993919868,
        '-122': 32.25409227086995,
        '-121': 32.25408537137672,
        '-120': 32.25408939997398,
        '-119': 32.2541040624459,
        '-118': 32.25412862650377,
        '-117': 32.25416195691374,
        '-116': 32.25420256901328,
        '-115': 32.25424869850263,
        '-114': 32.25429838469806,
        '-113': 32.25434956383361,
        '-112': 32.25440016851389,
        '-111': 32.254448229073056,
        '-110': 32.254491972397176,
        '-109': 32.254529913733116,
        '-108': 32.254560937147104,
        '-107': 32.25458436061432,
        '-106': 32.25459998222076,
        '-105': 32.254608104633235,
        '-104': 32.25460953583047,
        '-103': 32.25460556506753,
        '-102': 32.25459791413347,
        '-101': 32.25458866512052,
        '-100': 32.25458016710029,
        '-99': 32.25457492524125,
        '-98': 32.254575476941426,
        '-97': 32.25458426042482,
        '-96': 32.25460348190065,
        '-95': 32.254634987755885,
        '-94': 32.25468014830389,
        '-93': 32.2547397593185,
        '-92': 32.254813966939274,
        '-91': 32.25490222055635,
        '-90': 32.25500325701127,
        '-89': 32.25508476211508,
        '-88': 32.25491135736158,
        '-87': 32.25536035457889,
        '-86': 32.25528450090348,
        '-85': 32.25561111971069,
        '-84': 32.25245789266068,
        '-83': 32.251612338725785,
        '-82': 32.255016201457664,
        '-81': 32.244789397061204,
        '-80': 32.25605469285836,
        '-79': 32.24951817269339,
        '-78': 32.23832982540657,
        '-77': 32.23924767835782,
        '-76': 32.242650474557145,
        '-75': 32.23934421054622,
        '-74': 32.24249377201777,
        '-73': 32.24277562940886,
        '-72': 32.24314914174421,
        '-71': 32.2462189573337,
        '-70': 32.252069451083266,
        '-69': 32.25167491806291,
        '-68': 32.25173154383906,
        '-67': 32.250988811871665,
        '-66': 32.24801538827588,
        '-65': 32.24771064150559,
        '-64': 32.248145485122336,
        '-63': 32.25174874378247,
        '-62': 32.25387293802777,
        '-61': 32.25376276129775,
        '-60': 32.25078441300688,
        '-59': 32.25073943073895,
        '-58': 32.248364323983836,
        '-57': 32.248336453285745,
        '-56': 32.249281469597584,
        '-55': 32.25047153252489,
        '-54': 32.253633294885205,
        '-53': 32.24948123479892,
        '-52': 32.253793849440385,
        '-51': 32.24946473699643,
        '-50': 32.24937358872244,
        '-49': 32.25013366076393,
        '-48': 32.2507725679078,
        '-47': 32.2544521156482,
        '-46': 32.25027078362274,
        '-45': 32.24949817924403,
        '-44': 32.24888614776891,
        '-43': 32.24754180136988,
        '-42': 32.24746480238907,
        '-41': 32.24708473869559,
        '-40': 32.24675291239152,
        '-39': 32.24687157497343,
        '-38': 32.24524263383336,
        '-37': 32.24782465903878,
        '-36': 32.245032268776114,
        '-35': 32.24591217524465,
        '-34': 32.25331955413084,
        '-33': 32.24564174115437,
        '-32': 32.24770038829106,
        '-31': 32.25522530523452,
        '-30': 32.25566549267906,
        '-29': 32.25557307234707,
        '-28': 32.25097397817909,
        '-27': 32.250302541470944,
        '-26': 32.25414738159376,
        '-25': 32.25431690189891,
        '-24': 32.254983681333854,
        '-23': 32.25459384029009,
        '-22': 32.252979587496206,
        '-21': 32.25466424092075,
        '-20': 32.25458438194768,
        '-19': 32.2542388527123,
        '-18': 32.25447838749611,
        '-17': 32.254454601975446,
        '-16': 32.25445068742042,
        '-15': 32.25446606350863,
        '-14': 32.25449949468319,
        '-13': 32.25454915917671,
        '-12': 32.25461273889424,
        '-11': 32.2546875249164,
        '-10': 32.25477053286725,
        '-9': 32.25485862226932,
        '-8': 32.25494861426657,
        '-7': 32.25503740269044,
        '-6': 32.25512205430437,
        '-5': 32.25519989510192,
        '-4': 32.25526858065649,
        '-3': 32.25532614963025,
        '-2': 32.255371060559,
        '-1': 32.25540221286725
    },
    '83': {
        '0': 32.25608756998909,
        '1': 32.25607656573051,
        '2': 32.25605211006114,
        '3': 32.25601463222326,
        '4': 32.255964947828424,
        '5': 32.25590423387174,
        '6': 32.255833993421746,
        '7': 32.25575601102015,
        '8': 32.255672300041915,
        '9': 32.25558504346088,
        '10': 32.25549652963846,
        '11': 32.25540908489855,
        '12': 32.25532500476778,
        '13': 32.25524648583879,
        '14': 32.2551755602503,
        '15': 32.25511403476287,
        '16': 32.25506343633752,
        '17': 32.25502496599151,
        '18': 32.2549994625071,
        '19': 32.254987377307884,
        '20': 32.25498876149422,
        '21': 32.2550032656544,
        '22': 32.25503015265043,
        '23': 32.255068323132456,
        '24': 32.255116353079906,
        '25': 32.25517254221974,
        '26': 32.25523497175206,
        '27': 32.25530156944067,
        '28': 32.25537017981891,
        '29': 32.25543863703411,
        '30': 32.25550483772209,
        '31': 32.255566811271926,
        '32': 32.25562278491635,
        '33': 32.25567124126422,
        '34': 32.255710966169346,
        '35': 32.25574108519928,
        '36': 32.25576108740773,
        '37': 32.25577083561242,
        '38': 32.25577056291038,
        '39': 32.2557608557061,
        '40': 32.25574262405833,
        '41': 32.25571706064717,
        '42': 32.25568559010241,
        '43': 32.2556498107974,
        '44': 32.25561143148497,
        '45': 32.25557220532184,
        '46': 32.25553386388764,
        '47': 32.25549805375467,
        '48': 32.2554662780057,
        '49': 32.25543984484059,
        '50': 32.25541982506971,
        '51': 32.2554070198805,
        '52': 32.2554019398033,
        '53': 32.25540479531587,
        '54': 32.25541549903442,
        '55': 32.255433678967215,
        '56': 32.2554587018731,
        '57': 32.255489705393224,
        '58': 32.25552563732107,
        '59': 32.25556530015862,
        '60': 32.25560739897748,
        '61': 32.25565059056776,
        '62': 32.25569353190957,
        '63': 32.255734926135695,
        '64': 32.25577356435825,
        '65': 32.25580836199436,
        '66': 32.25583838852862,
        '67': 32.25586288997949,
        '68': 32.25588130367422,
        '69': 32.255893265268945,
        '70': 32.25589860826147,
        '71': 32.25589735652434,
        '72': 32.255889710624196,
        '73': 32.255876028885154,
        '74': 32.25585680429468,
        '75': 32.255832638438285,
        '76': 32.255804213687476,
        '77': 32.255772264852936,
        '78': 32.2557375514598,
        '79': 32.25570083170593,
        '80': 32.25566283903383,
        '81': 32.255624262090016,
        '82': 32.255585728663725,
        '83': 32.255547794001416,
        '84': 32.2555109336866,
        '85': 32.2554755410644,
        '86': 32.25544192898362,
        '87': 32.255410335433034,
        '88': 32.255380932469436,
        '89': 32.25535383768209,
        '90': 32.255329127318205,
        '91': 32.25530685011311,
        '92': 32.25528704083544,
        '93': 32.25526973257455,
        '94': 32.25525496686757,
        '95': 32.25524280088813,
        '96': 32.25523331109331,
        '97': 32.25522659294428,
        '98': 32.255222756569225,
        '99': 32.25522191851181,
        '100': 32.25522418998863,
        '101': 32.25522966234799,
        '102': 32.25523839066137,
        '103': 32.25525037657074,
        '104': 32.25526555164353,
        '105': 32.25528376253947,
        '106': 32.255304759263055,
        '107': 32.25532818765589,
        '108': 32.25535358708125,
        '109': 32.25538039397448,
        '110': 32.25540795159415,
        '111': 32.2554355259292,
        '112': 32.255462327320465,
        '113': 32.255487536966434,
        '114': 32.25551033713083,
        '115': 32.25552994357738,
        '116': 32.25554563854717,
        '117': 32.25555680248479,
        '118': 32.25556294271893,
        '119': 32.255563717417715,
        '120': 32.2555589533615,
        '121': 32.25554865639686,
        '122': 32.25553301383364,
        '123': 32.255512388499916,
        '124': 32.2554873046471,
        '125': 32.25545842636985,
        '126': 32.255426529641625,
        '127': 32.255392469438284,
        '128': 32.25535714370537,
        '129': 32.25532145610037,
        '130': 32.25528627949905,
        '131': 32.25525242219112,
        '132': 32.255220598508906,
        '133': 32.25519140534793,
        '134': 32.25516530566702,
        '135': 32.25514261962374,
        '136': 32.25512352353696,
        '137': 32.255108056401056,
        '138': 32.255096133236485,
        '139': 32.255087564174985,
        '140': 32.25508207787001,
        '141': 32.255079347609005,
        '142': 32.255079018397886,
        '143': 32.255080733291045,
        '144': 32.25508415735207,
        '145': 32.25508899783822,
        '146': 32.2550950194925,
        '147': 32.255102054179176,
        '148': 32.25511000448751,
        '149': 32.25511884133023,
        '150': 32.25512859595161,
        '151': 32.25513934711144,
        '152': 32.25515120450445,
        '153': 32.25516428969468,
        '154': 32.25517871597802,
        '155': 32.25519456862779,
        '156': 32.25521188693059,
        '157': 32.25523064928244,
        '158': 32.25525076240573,
        '159': 32.255272055474094,
        '160': 32.25529427961682,
        '161': 32.255317112934335,
        '162': 32.255340170812744,
        '163': 32.2553630209978,
        '164': 32.25538520259704,
        '165': 32.25540624793583,
        '166': 32.25542570601592,
        '167': 32.255443166218186,
        '168': 32.255458280862676,
        '169': 32.255470785288026,
        '170': 32.25548051423542,
        '171': 32.255487413512284,
        '172': 32.25549154615847,
        '173': 32.2554930926272,
        '174': 32.2554923448125,
        '175': 32.25548969408453,
        '176': 32.25548561381997,
        '177': 32.255480637218184,
        '178': 32.25547533145945,
        '179': 32.255470269477144,
        '180': 32.25546600076696,
        '-180': 32.25546600076696,
        '-179': 32.25546302273719,
        '-178': 32.255461754107074,
        '-177': 32.255462511785844,
        '-176': 32.25546549251343,
        '-175': 32.25547076032249,
        '-174': 32.255478240599395,
        '-173': 32.25548772119283,
        '-172': 32.255498860657696,
        '-171': 32.25551120334885,
        '-170': 32.25552420071148,
        '-169': 32.25553723777366,
        '-168': 32.25554966355083,
        '-167': 32.25556082383757,
        '-166': 32.255570094705554,
        '-165': 32.25557691495667,
        '-164': 32.255580815804265,
        '-163': 32.2555814461734,
        '-162': 32.2555785922202,
        '-161': 32.255572189958784,
        '-160': 32.25556233024007,
        '-159': 32.25554925572944,
        '-158': 32.25553334995944,
        '-157': 32.255515118964176,
        '-156': 32.255495166411656,
        '-155': 32.25547416351317,
        '-154': 32.255452815285004,
        '-153': 32.255431824951174,
        '-152': 32.25541185838922,
        '-151': 32.255393510532876,
        '-150': 32.25537727554881,
        '-149': 32.25536352240882,
        '-148': 32.25535247719077,
        '-147': 32.25534421308024,
        '-146': 32.25533864862834,
        '-145': 32.25533555437335,
        '-144': 32.255334567480034,
        '-143': 32.25533521361503,
        '-142': 32.255336934884554,
        '-141': 32.255339122331876,
        '-140': 32.25534115124487,
        '-139': 32.25534241737194,
        '-138': 32.25534237209329,
        '-137': 32.255340554648186,
        '-136': 32.25533661967232,
        '-135': 32.25533035854358,
        '-134': 32.25532171335816,
        '-133': 32.25531078274094,
        '-132': 32.25529781911838,
        '-131': 32.25528321752363,
        '-130': 32.255267496442734,
        '-129': 32.2552512716229,
        '-128': 32.25523522413234,
        '-127': 32.25522006426497,
        '-126': 32.255206493111054,
        '-125': 32.25519516375379,
        '-124': 32.255186644098,
        '-123': 32.25518138328556,
        '-122': 32.25517968350879,
        '-121': 32.25518167880106,
        '-120': 32.255187322075145,
        '-119': 32.255196381307854,
        '-118': 32.25520844534862,
        '-117': 32.255222939380694,
        '-116': 32.25523914960204,
        '-115': 32.255256256243335,
        '-114': 32.25527337361762,
        '-113': 32.255289595522996,
        '-112': 32.255304044011424,
        '-111': 32.25531591930804,
        '-110': 32.25532454853109,
        '-109': 32.255329430827736,
        '-108': 32.2553302766141,
        '-107': 32.2553270387875,
        '-106': 32.25531993406116,
        '-105': 32.255309452949724,
        '-104': 32.2552963573923,
        '-103': 32.255281665524016,
        '-102': 32.25526662367217,
        '-101': 32.25525266623942,
        '-100': 32.2552413647136,
        '-99': 32.25523436758625,
        '-98': 32.255233333443634,
        '-97': 32.255239859886416,
        '-96': 32.25525541121913,
        '-95': 32.25528124800657,
        '-94': 32.25531836161237,
        '-93': 32.25536741670755,
        '-92': 32.25542870446689,
        '-91': 32.25550210876819,
        '-90': 32.25558708718941,
        '-89': 32.25568266798536,
        '-88': 32.255787463547634,
        '-87': 32.25589970014186,
        '-86': 32.25601726300865,
        '-85': 32.2561377552461,
        '-84': 32.25625856829367,
        '-83': 32.25637696134056,
        '-82': 32.25649014660992,
        '-81': 32.25659537760928,
        '-80': 32.25669004671432,
        '-79': 32.25677174781057,
        '-78': 32.25683835509382,
        '-77': 32.255337875658654,
        '-76': 32.25646361499971,
        '-75': 32.25659755946552,
        '-74': 32.25692474410304,
        '-73': 32.253797238466305,
        '-72': 32.25384272773122,
        '-71': 32.25391077119613,
        '-70': 32.25551231955901,
        '-69': 32.2566024615954,
        '-68': 32.256504586009605,
        '-67': 32.25638625647143,
        '-66': 32.256260193417745,
        '-65': 32.25612911629068,
        '-64': 32.25599580442216,
        '-63': 32.255863028159816,
        '-62': 32.25573348038994,
        '-61': 32.25560971670873,
        '-60': 32.25549410840768,
        '-59': 32.2553887890142,
        '-58': 32.25529561333266,
        '-57': 32.25521612433948,
        '-56': 32.25515152793174,
        '-55': 32.25510267546668,
        '-54': 32.25507005389992,
        '-53': 32.25505378324438,
        '-52': 32.25505362102902,
        '-51': 32.25506897342382,
        '-50': 32.25509891270569,
        '-49': 32.25514220075598,
        '-48': 32.25519731829179,
        '-47': 32.25455469898081,
        '-46': 32.25520431220824,
        '-45': 32.2500455956562,
        '-44': 32.25032019257027,
        '-43': 32.251850201562355,
        '-42': 32.25019374990203,
        '-41': 32.25199299400716,
        '-40': 32.25582086983152,
        '-39': 32.25451242033184,
        '-38': 32.25508684969331,
        '-37': 32.248285198900035,
        '-36': 32.250890618141014,
        '-35': 32.250741900428444,
        '-34': 32.25593296767274,
        '-33': 32.255747899885506,
        '-32': 32.2520084591588,
        '-31': 32.24487948214907,
        '-30': 32.24530643654354,
        '-29': 32.248222085675266,
        '-28': 32.24949145771844,
        '-27': 32.25021897271013,
        '-26': 32.251124927330515,
        '-25': 32.25080591187779,
        '-24': 32.25561521128001,
        '-23': 32.25555315107439,
        '-22': 32.25549669064179,
        '-21': 32.25544781332604,
        '-20': 32.25540823762509,
        '-19': 32.255379357934395,
        '-18': 32.25536219653722,
        '-17': 32.25535736863785,
        '-16': 32.25536506170578,
        '-15': 32.255385029819124,
        '-14': 32.2554166030953,
        '-13': 32.25545871170469,
        '-12': 32.25550992340854,
        '-11': 32.25556849307122,
        '-10': 32.25563242218828,
        '-9': 32.25569952616222,
        '-8': 32.25576750685381,
        '-7': 32.255834027841324,
        '-6': 32.25589678982852,
        '-5': 32.25595360374455,
        '-4': 32.25600245926314,
        '-3': 32.25604158671728,
        '-2': 32.2560695106823,
        '-1': 32.25608509382871
    },
    '84': {
        '0': 32.25695875617911,
        '1': 32.256954493784235,
        '2': 32.25694190672617,
        '3': 32.256921152329554,
        '4': 32.25689259863205,
        '5': 32.256856813024505,
        '6': 32.256814544872725,
        '7': 32.25676670266855,
        '8': 32.25671432642554,
        '9': 32.25665855618148,
        '10': 32.2566005975894,
        '11': 32.256541685671394,
        '12': 32.25648304786849,
        '13': 32.25642586754444,
        '14': 32.256371249091856,
        '15': 32.25632018574212,
        '16': 32.25627353109928,
        '17': 32.25623197530368,
        '18': 32.256196026585705,
        '19': 32.256165998799,
        '20': 32.25614200532919,
        '21': 32.256123959566914,
        '22': 32.256111581916215,
        '23': 32.25610441309186,
        '24': 32.256101833247,
        '25': 32.2561030862737,
        '26': 32.2561073084419,
        '27': 32.25611356039263,
        '28': 32.25612086138424,
        '29': 32.25612822461333,
        '30': 32.256134692394234,
        '31': 32.25613936998892,
        '32': 32.25614145692769,
        '33': 32.25614027475446,
        '34': 32.256135290260445,
        '35': 32.25612613343449,
        '36': 32.25611260955168,
        '37': 32.25609470503369,
        '38': 32.25607258694084,
        '39': 32.25604659618425,
        '40': 32.25601723477114,
        '41': 32.25598514760626,
        '42': 32.255951099562,
        '43': 32.255915948688866,
        '44': 32.25588061656403,
        '45': 32.25584605686137,
        '46': 32.25581322327049,
        '47': 32.2557830378934,
        '48': 32.25575636120603,
        '49': 32.25573396459049,
        '50': 32.25571650632668,
        '51': 32.255704511784174,
        '52': 32.25569835838376,
        '53': 32.2556982657099,
        '54': 32.25570429095896,
        '55': 32.25571632971074,
        '56': 32.25573412182143,
        '57': 32.25575726206091,
        '58': 32.255785214963275,
        '59': 32.25581733323174,
        '60': 32.25585287894184,
        '61': 32.255891046722205,
        '62': 32.25593098806137,
        '63': 32.25597183589258,
        '64': 32.256012728642155,
        '65': 32.256052832991465,
        '66': 32.25609136468951,
        '67': 32.25612760686107,
        '68': 32.25616092537776,
        '69': 32.256190780989314,
        '70': 32.25621673804634,
        '71': 32.25623846977658,
        '72': 32.256255760199814,
        '73': 32.25626850287802,
        '74': 32.25627669679201,
        '75': 32.256280439713564,
        '76': 32.25627991949764,
        '77': 32.25627540375434,
        '78': 32.25626722837519,
        '79': 32.256255785379835,
        '80': 32.25624151052612,
        '81': 32.256224871083155,
        '82': 32.256206354113836,
        '83': 32.25618645554701,
        '84': 32.25616567024959,
        '85': 32.256144483234856,
        '86': 32.25612336207063,
        '87': 32.25610275048398,
        '88': 32.25608306309892,
        '89': 32.25606468119515,
        '90': 32.25604794933952,
        '91': 32.25603317272051,
        '92': 32.25602061500946,
        '93': 32.25601049658054,
        '94': 32.25600299294376,
        '95': 32.25599823328023,
        '96': 32.25599629901265,
        '97': 32.255997222395315,
        '98': 32.25600098516147,
        '99': 32.25600751731897,
        '100': 32.25601669623266,
        '101': 32.256028346171824,
        '102': 32.25604223852717,
        '103': 32.25605809291483,
        '104': 32.256075579378624,
        '105': 32.25609432188057,
        '106': 32.256113903227785,
        '107': 32.25613387152793,
        '108': 32.256153748194315,
        '109': 32.25617303744077,
        '110': 32.25619123711988,
        '111': 32.25620785067058,
        '112': 32.25622239985776,
        '113': 32.25623443791417,
        '114': 32.25624356263724,
        '115': 32.25624942895468,
        '116': 32.256251760458476,
        '117': 32.25625035941597,
        '118': 32.25624511480296,
        '119': 32.25623600796496,
        '120': 32.256223115595354,
        '121': 32.256206609823266,
        '122': 32.256186755318886,
        '123': 32.25616390345021,
        '124': 32.25613848364995,
        '125': 32.256110992273335,
        '126': 32.25608197933575,
        '127': 32.25605203361073,
        '128': 32.25602176663751,
        '129': 32.25599179622881,
        '130': 32.25596273008347,
        '131': 32.255935150092455,
        '132': 32.25590959788261,
        '133': 32.25588656207442,
        '134': 32.25586646763875,
        '135': 32.25584966763229,
        '136': 32.255836437475885,
        '137': 32.25582697182102,
        '138': 32.25582138393535,
        '139': 32.25581970743246,
        '140': 32.25582190008172,
        '141': 32.255827849363456,
        '142': 32.25583737938752,
        '143': 32.25585025876973,
        '144': 32.25586620906269,
        '145': 32.25588491336039,
        '146': 32.25590602474162,
        '147': 32.25592917427655,
        '148': 32.25595397839336,
        '149': 32.255980045479134,
        '150': 32.256006981668015,
        '151': 32.256034395842356,
        '152': 32.25606190393676,
        '153': 32.25608913268425,
        '154': 32.25611572297723,
        '155': 32.256141333029156,
        '156': 32.256165641519345,
        '157': 32.256188350878446,
        '158': 32.256209190833516,
        '159': 32.25622792227786,
        '160': 32.25624434146865,
        '161': 32.256258284488275,
        '162': 32.25626963183777,
        '163': 32.256278312969414,
        '164': 32.2562843105127,
        '165': 32.25628766390975,
        '166': 32.256288472154914,
        '167': 32.25628689533126,
        '168': 32.25628315465573,
        '169': 32.256277530783535,
        '170': 32.25627036018169,
        '171': 32.256262029456565,
        '172': 32.25625296760982,
        '173': 32.25624363629511,
        '174': 32.25623451825032,
        '175': 32.25622610418161,
        '176': 32.25621887846982,
        '177': 32.25621330415285,
        '178': 32.2562098077032,
        '179': 32.25620876416501,
        '180': 32.25621048323576,
        '-180': 32.25621048323576,
        '-179': 32.25621519687228,
        '-178': 32.256223048968096,
        '-177': 32.256234087590315,
        '-176': 32.25624826018025,
        '-175': 32.25626541201619,
        '-174': 32.25628528811472,
        '-173': 32.25630753861079,
        '-172': 32.25633172751721,
        '-171': 32.25635734462202,
        '-170': 32.2563838201497,
        '-169': 32.25641054169084,
        '-168': 32.25643687280458,
        '-167': 32.25646217262081,
        '-166': 32.2564858157209,
        '-165': 32.25650721155893,
        '-164': 32.25652582269967,
        '-163': 32.25654118119714,
        '-162': 32.25655290251434,
        '-161': 32.25656069648828,
        '-160': 32.25656437496996,
        '-159': 32.25656385591045,
        '-158': 32.25655916381439,
        '-157': 32.25655042663545,
        '-156': 32.25653786933529,
        '-155': 32.25652180446251,
        '-154': 32.256502620224836,
        '-153': 32.25648076661942,
        '-152': 32.25645674024972,
        '-151': 32.25643106849047,
        '-150': 32.256404293661966,
        '-149': 32.256376957843145,
        '-148': 32.256349588893016,
        '-147': 32.256322688161454,
        '-146': 32.25629672026408,
        '-145': 32.25627210517215,
        '-144': 32.256249212738126,
        '-143': 32.25622835964556,
        '-142': 32.25620980864541,
        '-141': 32.25619376982772,
        '-140': 32.25618040358179,
        '-139': 32.25616982482576,
        '-138': 32.25616210804053,
        '-137': 32.256157292625694,
        '-136': 32.25615538810651,
        '-135': 32.2561563787607,
        '-134': 32.25616022729861,
        '-133': 32.25616687731659,
        '-132': 32.256176254346194,
        '-131': 32.25618826543546,
        '-130': 32.256202797316156,
        '-129': 32.256219713326,
        '-128': 32.2562388493624,
        '-127': 32.256260009235326,
        '-126': 32.25628295985927,
        '-125': 32.25630742677173,
        '-124': 32.25633309048605,
        '-123': 32.25635958417695,
        '-122': 32.25638649315933,
        '-121': 32.25641335655376,
        '-120': 32.25643967143997,
        '-119': 32.256464899685646,
        '-118': 32.25648847750603,
        '-117': 32.25650982766922,
        '-116': 32.25652837411477,
        '-115': 32.25654355861035,
        '-114': 32.25655485893739,
        '-113': 32.2565618079796,
        '-112': 32.25656401299353,
        '-111': 32.25656117427467,
        '-110': 32.256553102398335,
        '-109': 32.256539733216734,
        '-108': 32.25652113983188,
        '-107': 32.25649754083914,
        '-106': 32.25646930424687,
        '-105': 32.25643694661813,
        '-104': 32.25640112714949,
        '-103': 32.25636263658909,
        '-102': 32.2563223810972,
        '-101': 32.2562813613575,
        '-100': 32.25624064744768,
        '-99': 32.25620135016589,
        '-98': 32.25616458967461,
        '-97': 32.256131462460786,
        '-96': 32.25610300771013,
        '-95': 32.25608017425318,
        '-94': 32.2560637892542,
        '-93': 32.25605452978172,
        '-92': 32.25605289832082,
        '-91': 32.256059203165705,
        '-90': 32.25607354447006,
        '-89': 32.256095806539655,
        '-88': 32.256125656732536,
        '-87': 32.25616255109932,
        '-86': 32.25620574665424,
        '-85': 32.256254319931955,
        '-84': 32.25630719126088,
        '-83': 32.256363153983266,
        '-82': 32.256420907681346,
        '-81': 32.25647909433458,
        '-80': 32.256536336241076,
        '-79': 32.25659127448767,
        '-78': 32.25664260675067,
        '-77': 32.25668912324989,
        '-76': 32.256729739761155,
        '-75': 32.256763526711005,
        '-74': 32.2567897335263,
        '-73': 32.25680780758431,
        '-72': 32.25681740729765,
        '-71': 32.25681840906491,
        '-70': 32.25681090801612,
        '-69': 32.25679521267218,
        '-68': 32.25677183381669,
        '-67': 32.256741468037205,
        '-66': 32.25670497653134,
        '-65': 32.256663359884904,
        '-64': 32.256617729614625,
        '-63': 32.25656927732541,
        '-62': 32.25651924236374,
        '-61': 32.256468878853866,
        '-60': 32.2564194229866,
        '-59': 32.256372061391914,
        '-58': 32.25632790137259,
        '-57': 32.25628794370521,
        '-56': 32.25625305863442,
        '-55': 32.25622396559605,
        '-54': 32.256201217108206,
        '-53': 32.25618518716794,
        '-52': 32.25617606438721,
        '-51': 32.256173849995164,
        '-50': 32.25617836072718,
        '-49': 32.256189236513684,
        '-48': 32.25620595277543,
        '-47': 32.256227837027595,
        '-46': 32.256254089392854,
        '-45': 32.2562838065266,
        '-44': 32.256316008366,
        '-43': 32.25634966703208,
        '-42': 32.25638373714197,
        '-41': 32.256417186730545,
        '-40': 32.2564490279399,
        '-39': 32.25647834661302,
        '-38': 32.25650432992907,
        '-37': 32.2565262912437,
        '-36': 32.25654369134851,
        '-35': 32.25655615544281,
        '-34': 32.25656348521534,
        '-33': 32.25656566556377,
        '-32': 32.256562865630926,
        '-31': 32.256555434007694,
        '-30': 32.25654388813507,
        '-29': 32.256528898128145,
        '-28': 32.25651126543555,
        '-27': 32.256491896930456,
        '-26': 32.25647177519856,
        '-25': 32.25645192593508,
        '-24': 32.25643338348161,
        '-23': 32.25641715561845,
        '-22': 32.256404188774475,
        '-21': 32.25639533482182,
        '-20': 32.25639132058572,
        '-19': 32.25639272112161,
        '-18': 32.25639993769278,
        '-17': 32.25641318123045,
        '-16': 32.25643246187442,
        '-15': 32.25645758498869,
        '-14': 32.256488153826254,
        '-13': 32.25652357879164,
        '-12': 32.256563093025534,
        '-11': 32.25660577382317,
        '-10': 32.25665056920159,
        '-9': 32.25669632876196,
        '-8': 32.256741837851756,
        '-7': 32.25678585392826,
        '-6': 32.2568271439568,
        '-5': 32.25686452164994,
        '-4': 32.25689688336591,
        '-3': 32.25692324153368,
        '-2': 32.256942754558175,
        '-1': 32.25695475227629
    },
    '85': {
        '0': 32.257424460841214,
        '1': 32.257450666922125,
        '2': 32.257473699622885,
        '3': 32.257493404000876,
        '4': 32.25750969369478,
        '5': 32.25752254912956,
        '6': 32.257532013791185,
        '7': 32.25753818872632,
        '8': 32.257541225478754,
        '9': 32.25754131772213,
        '10': 32.25753869188921,
        '11': 32.25753359712819,
        '12': 32.25752629493516,
        '13': 32.25751704882068,
        '14': 32.257506114363366,
        '15': 32.25749372998851,
        '16': 32.25748010878266,
        '17': 32.25746543161741,
        '18': 32.25744984181035,
        '19': 32.25743344149587,
        '20': 32.257416289820085,
        '21': 32.25739840300932,
        '22': 32.25737975629724,
        '23': 32.25736028763018,
        '24': 32.25733990300862,
        '25': 32.25731848326523,
        '26': 32.257295892029354,
        '27': 32.25727198458663,
        '28': 32.25724661730935,
        '29': 32.25721965731417,
        '30': 32.25719099199339,
        '31': 32.25716053807063,
        '32': 32.25712824984684,
        '33': 32.25709412632952,
        '34': 32.257058216976155,
        '35': 32.25702062582931,
        '36': 32.25698151387591,
        '37': 32.2569410995227,
        '38': 32.25689965714445,
        '39': 32.256857513725514,
        '40': 32.256815043679964,
        '41': 32.256772661995925,
        '42': 32.256730815905016,
        '43': 32.25668997532598,
        '44': 32.25665062237093,
        '45': 32.25661324023136,
        '46': 32.256578301780145,
        '47': 32.25654625823105,
        '48': 32.25651752819419,
        '49': 32.25649248744862,
        '50': 32.25647145972768,
        '51': 32.25645470877739,
        '52': 32.25644243190452,
        '53': 32.25643475518233,
        '54': 32.25643173042789,
        '55': 32.25643333400954,
        '56': 32.256439467487084,
        '57': 32.25644996003254,
        '58': 32.25646457252894,
        '59': 32.256483003198234,
        '60': 32.2565048945703,
        '61': 32.25652984157297,
        '62': 32.25655740049908,
        '63': 32.25658709859165,
        '64': 32.25661844398183,
        '65': 32.25665093571623,
        '66': 32.25668407362034,
        '67': 32.25671736776216,
        '68': 32.256750347303125,
        '69': 32.256782568551934,
        '70': 32.256813622069,
        '71': 32.25684313870335,
        '72': 32.25687079447963,
        '73': 32.25689631428782,
        '74': 32.25691947436218,
        '75': 32.25694010356771,
        '76': 32.25695808354043,
        '77': 32.256973347752194,
        '78': 32.256985879591085,
        '79': 32.2569957095638,
        '80': 32.257002911737594,
        '81': 32.25700759954593,
        '82': 32.25700992108446,
        '83': 32.25701005402313,
        '84': 32.25700820025581,
        '85': 32.25700458040281,
        '86': 32.25699942827267,
        '87': 32.25699298538123,
        '88': 32.25698549561508,
        '89': 32.25697720011748,
        '90': 32.25696833246512,
        '91': 32.25695911419545,
        '92': 32.256949750737114,
        '93': 32.25694042778885,
        '94': 32.256931308187326,
        '95': 32.25692252929909,
        '96': 32.25691420096773,
        '97': 32.25690640404315,
        '98': 32.25689918951577,
        '99': 32.25689257827305,
        '100': 32.256886561490255,
        '101': 32.25688110165987,
        '102': 32.25687613425545,
        '103': 32.25687157001525,
        '104': 32.256867297819156,
        '105': 32.25686318811916,
        '106': 32.256859096868695,
        '107': 32.256854869882226,
        '108': 32.25685034754,
        '109': 32.256845369739956,
        '110': 32.25683978098425,
        '111': 32.256833435478605,
        '112': 32.25682620211292,
        '113': 32.25681796918885,
        '114': 32.256808648757975,
        '115': 32.25679818043963,
        '116': 32.256786534595264,
        '117': 32.25677371474978,
        '118': 32.256759759168624,
        '119': 32.25674474152041,
        '120': 32.256728770580644,
        '121': 32.25671198895978,
        '122': 32.25669457086737,
        '123': 32.25667671895487,
        '124': 32.25665866030738,
        '125': 32.25664064168251,
        '126': 32.25662292411838,
        '127': 32.256605777052854,
        '128': 32.256589472112125,
        '129': 32.256574276736494,
        '130': 32.25656044781606,
        '131': 32.25654822550773,
        '132': 32.25653782739846,
        '133': 32.256529443166855,
        '134': 32.256523229879164,
        '135': 32.25651930803501,
        '136': 32.25651775845406,
        '137': 32.2565186200703,
        '138': 32.25652188867315,
        '139': 32.25652751660937,
        '140': 32.256535413433994,
        '141': 32.25654544747548,
        '142': 32.25655744826007,
        '143': 32.256571209722296,
        '144': 32.256586494115474,
        '145': 32.25660303652484,
        '146': 32.25662054987972,
        '147': 32.25663873035706,
        '148': 32.25665726306814,
        '149': 32.256675827921434,
        '150': 32.25669410555842,
        '151': 32.25671178326339,
        '152': 32.25672856075372,
        '153': 32.25674415576244,
        '154': 32.25675830933075,
        '155': 32.25677079073238,
        '156': 32.256781401957085,
        '157': 32.25678998168347,
        '158': 32.25679640867601,
        '159': 32.25680060454403,
        '160': 32.25680253580469,
        '161': 32.25680221519621,
        '162': 32.2567997021943,
        '163': 32.256795102691015,
        '164': 32.25678856780601,
        '165': 32.25678029181085,
        '166': 32.25677050916189,
        '167': 32.25675949065374,
        '168': 32.25674753872357,
        '169': 32.25673498195818,
        '170': 32.256722168877005,
        '171': 32.25670946108717,
        '172': 32.25669722592948,
        '173': 32.25668582875512,
        '174': 32.25667562499276,
        '175': 32.25666695218159,
        '176': 32.25666012215821,
        '177': 32.25665541359286,
        '178': 32.256653065072044,
        '179': 32.25665326892067,
        '180': 32.25665616594563,
        '-180': 32.25665616594563,
        '-179': 32.25666184126646,
        '-178': 32.256670321374756,
        '-177': 32.256681572535385,
        '-176': 32.25669550060836,
        '-175': 32.25671195233278,
        '-174': 32.25673071807292,
        '-173': 32.25675153598514,
        '-172': 32.25677409752191,
        '-171': 32.256798054149,
        '-170': 32.25682302511464,
        '-169': 32.25684860607654,
        '-168': 32.25687437836579,
        '-167': 32.25689991864622,
        '-166': 32.25692480871555,
        '-165': 32.25694864518984,
        '-164': 32.2569710488168,
        '-163': 32.25699167317575,
        '-162': 32.25701021254147,
        '-161': 32.2570264087162,
        '-160': 32.2570400566667,
        '-159': 32.25705100884043,
        '-158': 32.257059178075444,
        '-157': 32.25706453906129,
        '-156': 32.257067128349334,
        '-155': 32.25706704295295,
        '-154': 32.257064437614716,
        '-153': 32.257059520851975,
        '-152': 32.257052549920004,
        '-151': 32.25704382485448,
        '-150': 32.257033681769606,
        '-149': 32.25702248559811,
        '-148': 32.25701062245993,
        '-147': 32.256998491842786,
        '-146': 32.25698649876725,
        '-145': 32.25697504609407,
        '-144': 32.25696452711312,
        '-143': 32.25695531853147,
        '-142': 32.25694777395619,
        '-141': 32.25694221794464,
        '-140': 32.25693894067322,
        '-139': 32.25693819325622,
        '-138': 32.25694018372958,
        '-137': 32.25694507370131,
        '-136': 32.25695297566047,
        '-135': 32.25696395093159,
        '-134': 32.256978008259,
        '-133': 32.25699510300771,
        '-132': 32.25701513697083,
        '-131': 32.25703795878048,
        '-130': 32.257063364925365,
        '-129': 32.257091101385825,
        '-128': 32.257120865902834,
        '-127': 32.25715231090176,
        '-126': 32.25718504709243,
        '-125': 32.257218647765576,
        '-124': 32.25725265379857,
        '-123': 32.2572865793738,
        '-122': 32.25731991839773,
        '-121': 32.257352151590396,
        '-120': 32.257382754193216,
        '-119': 32.25741120421796,
        '-118': 32.25743699113441,
        '-117': 32.257459624866755,
        '-116': 32.25747864494369,
        '-115': 32.25749362962366,
        '-114': 32.257504204796234,
        '-113': 32.25751005244674,
        '-112': 32.25751091846088,
        '-111': 32.257506619544976,
        '-110': 32.25749704904272,
        '-109': 32.257482181442874,
        '-108': 32.25746207539437,
        '-107': 32.25743687507469,
        '-106': 32.25740680979461,
        '-105': 32.257372191765455,
        '-104': 32.25733341200365,
        '-103': 32.25729093439907,
        '-102': 32.257245288027654,
        '-101': 32.25719705784239,
        '-100': 32.25714687392889,
        '-99': 32.25709539955902,
        '-98': 32.25704331831878,
        '-97': 32.25699132062084,
        '-96': 32.25694008993777,
        '-95': 32.256890289107844,
        '-94': 32.25684254706969,
        '-93': 32.2567974463755,
        '-92': 32.25675551181493,
        '-91': 32.256717200452556,
        '-90': 32.25668289334369,
        '-89': 32.25665288914556,
        '-88': 32.25662739978634,
        '-87': 32.25660654829462,
        '-86': 32.2565903688286,
        '-85': 32.25657880887999,
        '-84': 32.25657173356372,
        '-83': 32.256568931845074,
        '-82': 32.256570124499916,
        '-81': 32.256574973556035,
        '-80': 32.25658309292372,
        '-79': 32.256594059893324,
        '-78': 32.256607427158585,
        '-77': 32.25662273501497,
        '-76': 32.256639523385196,
        '-75': 32.25665734333665,
        '-74': 32.256675767778425,
        '-73': 32.256694401057864,
        '-72': 32.25671288721557,
        '-71': 32.25673091670418,
        '-70': 32.25674823142714,
        '-69': 32.256764628006415,
        '-68': 32.256779959243886,
        '-67': 32.25679413379502,
        '-66': 32.256807114126175,
        '-65': 32.256818912875715,
        '-64': 32.25682958778333,
        '-63': 32.25683923539013,
        '-62': 32.25684798374396,
        '-61': 32.25685598436778,
        '-60': 32.256863403765955,
        '-59': 32.25687041475053,
        '-58': 32.25687718787041,
        '-57': 32.256883883217604,
        '-56': 32.256890642869905,
        '-55': 32.256897584206534,
        '-54': 32.256904794304674,
        '-53': 32.25691232559056,
        '-52': 32.25692019287998,
        '-51': 32.25692837190066,
        '-50': 32.256936799344,
        '-49': 32.256945374447746,
        '-48': 32.256953962064195,
        '-47': 32.25696239712373,
        '-46': 32.25697049035968,
        '-45': 32.25697803512104,
        '-44': 32.25698481506379,
        '-43': 32.25699061248183,
        '-42': 32.256995217014946,
        '-41': 32.25699843445506,
        '-40': 32.257000095363466,
        '-39': 32.257000063211954,
        '-38': 32.25699824176926,
        '-37': 32.25699458147138,
        '-36': 32.256989084540294,
        '-35': 32.256981808648355,
        '-34': 32.25697286896703,
        '-33': 32.256962438484656,
        '-32': 32.256950746529405,
        '-31': 32.25693807548881,
        '-30': 32.256924755772594,
        '-29': 32.25691115912324,
        '-28': 32.256897690431536,
        '-27': 32.25688477826643,
        '-26': 32.25687286437238,
        '-25': 32.256862392426086,
        '-24': 32.256853796373434,
        '-23': 32.25684748868764,
        '-22': 32.256843848897624,
        '-21': 32.25684321273431,
        '-20': 32.256845862229106,
        '-19': 32.25685201707355,
        '-18': 32.25686182751562,
        '-17': 32.25687536902304,
        '-16': 32.256892638892246,
        '-15': 32.256913554923614,
        '-14': 32.256937956220156,
        '-13': 32.25696560610229,
        '-12': 32.2569961970657,
        '-11': 32.257029357646005,
        '-10': 32.25706466099475,
        '-9': 32.257101634918044,
        '-8': 32.257139773083864,
        '-7': 32.257178547067774,
        '-6': 32.25721741888176,
        '-5': 32.25725585361621,
        '-4': 32.257293331823234,
        '-3': 32.25732936127843,
        '-2': 32.2573634877798,
        '-1': 32.25739530467392
    },
    '86': {
        '0': 32.25727004849612,
        '1': 32.2573182146453,
        '2': 32.25736657138186,
        '3': 32.257414836875746,
        '4': 32.257462738845085,
        '5': 32.257510016163984,
        '6': 32.25755642013646,
        '7': 32.25760171544764,
        '8': 32.25764568081355,
        '9': 32.25768810935891,
        '10': 32.25772880876024,
        '11': 32.257767601196676,
        '12': 32.257804323155355,
        '13': 32.2578388251398,
        '14': 32.257870971330284,
        '15': 32.257900639242955,
        '16': 32.25792771943127,
        '17': 32.25795211526763,
        '18': 32.25797374283683,
        '19': 32.25799253096446,
        '20': 32.258008421394834,
        '21': 32.25802136912355,
        '22': 32.25803134288003,
        '23': 32.258038325745666,
        '24': 32.25804231588439,
        '25': 32.258043327354045,
        '26': 32.25804139095992,
        '27': 32.258036555105505,
        '28': 32.25802888659255,
        '29': 32.258018471318955,
        '30': 32.25800541482325,
        '31': 32.257989842626024,
        '32': 32.25797190032162,
        '33': 32.25795175337913,
        '34': 32.2579295866184,
        '35': 32.25790560333513,
        '36': 32.25788002405897,
        '37': 32.25785308493839,
        '38': 32.2578250357579,
        '39': 32.25779613760356,
        '40': 32.25776666020417,
        '41': 32.25773687898591,
        '42': 32.257707071887715,
        '43': 32.25767751599307,
        '44': 32.25764848404088,
        '45': 32.25762024088312,
        '46': 32.25759303996084,
        '47': 32.257567119870934,
        '48': 32.25754270109598,
        '49': 32.25751998296667,
        '50': 32.25749914092186,
        '51': 32.25748032412558,
        '52': 32.25746365349158,
        '53': 32.25744922015785,
        '54': 32.25743708444319,
        '55': 32.25742727530614,
        '56': 32.25741979031688,
        '57': 32.25741459613952,
        '58': 32.25741162951233,
        '59': 32.25741079870198,
        '60': 32.25741198539809,
        '61': 32.257415047005836,
        '62': 32.25741981928608,
        '63': 32.25742611928693,
        '64': 32.25743374850536,
        '65': 32.25744249621468,
        '66': 32.257452142892056,
        '67': 32.25746246368026,
        '68': 32.257473231819304,
        '69': 32.25748422198682,
        '70': 32.257495213489946,
        '71': 32.257505993256835,
        '72': 32.25751635858222,
        '73': 32.25752611958815,
        '74': 32.257535101368376,
        '75': 32.25754314579286,
        '76': 32.25755011295608,
        '77': 32.25755588226109,
        '78': 32.25756035313808,
        '79': 32.2575634454039,
        '80': 32.25756509927495,
        '81': 32.25756527505198,
        '82': 32.25756395250051,
        '83': 32.257561129955135,
        '84': 32.25755682317931,
        '85': 32.25755106401551,
        '86': 32.25754389886241,
        '87': 32.25753538701705,
        '88': 32.25752559892091,
        '89': 32.25751461434798,
        '90': 32.25750252057275,
        '91': 32.25748941055428,
        '92': 32.25747538117048,
        '93': 32.257460531534626,
        '94': 32.25744496142254,
        '95': 32.25742876983618,
        '96': 32.257412053725005,
        '97': 32.25739490688325,
        '98': 32.257377419035926,
        '99': 32.25735967512315,
        '100': 32.25734175478659,
        '101': 32.25732373205828,
        '102': 32.25730567524677,
        '103': 32.25728764701127,
        '104': 32.25726970461029,
        '105': 32.25725190030704,
        '106': 32.25723428191028,
        '107': 32.2572168934261,
        '108': 32.25719977579328,
        '109': 32.257182967673224,
        '110': 32.25716650626331,
        '111': 32.25715042810296,
        '112': 32.25713476984085,
        '113': 32.25711956893329,
        '114': 32.257104864245704,
        '115': 32.25709069653104,
        '116': 32.257077108763305,
        '117': 32.257064146307776,
        '118': 32.25705185691426,
        '119': 32.257040290525175,
        '120': 32.25702949889546,
        '121': 32.25701953502713,
        '122': 32.25701045242717,
        '123': 32.25700230420286,
        '124': 32.256995142014034,
        '125': 32.25698901490715,
        '126': 32.2569839680592,
        '127': 32.25698004146476,
        '128': 32.25697726860032,
        '129': 32.25697567510375,
        '130': 32.256975277505916,
        '131': 32.25697608205242,
        '132': 32.2569780836519,
        '133': 32.2569812649849,
        '134': 32.256985595804586,
        '135': 32.25699103245656,
        '136': 32.25699751764032,
        '137': 32.25700498042957,
        '138': 32.25701333656319,
        '139': 32.257022489012236,
        '140': 32.25703232882239,
        '141': 32.25704273622522,
        '142': 32.25705358200491,
        '143': 32.25706472910215,
        '144': 32.25707603443082,
        '145': 32.25708735087839,
        '146': 32.25709852945669,
        '147': 32.25710942156589,
        '148': 32.25711988133167,
        '149': 32.25712976797339,
        '150': 32.25713894815956,
        '151': 32.25714729830651,
        '152': 32.25715470677601,
        '153': 32.25716107592909,
        '154': 32.25716632399464,
        '155': 32.25717038671403,
        '156': 32.257173218726365,
        '157': 32.257174794662674,
        '158': 32.257175109921704,
        '159': 32.25717418110585,
        '160': 32.25717204610005,
        '161': 32.25716876378388,
        '162': 32.25716441337249,
        '163': 32.257159093389106,
        '164': 32.257152920278806,
        '165': 32.25714602667977,
        '166': 32.25713855937554,
        '167': 32.25713067695835,
        '168': 32.25712254723984,
        '169': 32.257114344451686,
        '170': 32.25710624628395,
        '171': 32.257098430813414,
        '172': 32.257091073377964,
        '173': 32.25708434345612,
        '174': 32.25707840161183,
        '175': 32.257073396565524,
        '176': 32.257069462451206,
        '177': 32.25706671631727,
        '178': 32.25706525592508,
        '179': 32.25706515789442,
        '180': 32.25706647623875,
        '-180': 32.25706647623875,
        '-179': 32.25706924132599,
        '-178': 32.25707345929199,
        '-177': 32.25707911192496,
        '-176': 32.25708615702898,
        '-175': 32.25709452926482,
        '-174': 32.25710414145549,
        '-173': 32.257114886334165,
        '-172': 32.25712663870144,
        '-171': 32.25713925795036,
        '-170': 32.25715259090862,
        '-169': 32.257166474940156,
        '-168': 32.257180741242294,
        '-167': 32.257195218270205,
        '-166': 32.257209735216925,
        '-165': 32.25722412547647,
        '-164': 32.257238230017535,
        '-163': 32.25725190059737,
        '-162': 32.25726500274934,
        '-161': 32.25727741848262,
        '-160': 32.25728904863922,
        '-159': 32.257299814861014,
        '-158': 32.25730966112848,
        '-157': 32.25731855484205,
        '-156': 32.25732648742677,
        '-155': 32.25733347445135,
        '-154': 32.25733955526244,
        '-153': 32.257344792144806,
        '-152': 32.2573492690276,
        '-151': 32.257353089765026,
        '-150': 32.25735637602762,
        '-149': 32.25735926484744,
        '-148': 32.25736190586511,
        '-147': 32.257364458332276,
        '-146': 32.25736708792526,
        '-145': 32.25736996342863,
        '-144': 32.257373253347794,
        '-143': 32.25737712251002,
        '-142': 32.25738172871207,
        '-141': 32.25738721947065,
        '-140': 32.257393728929465,
        '-139': 32.2574013749729,
        '-138': 32.257410256592806,
        '-137': 32.25742045155019,
        '-136': 32.257432014369186,
        '-135': 32.25744497469541,
        '-134': 32.25745933604575,
        '-133': 32.25747507497105,
        '-132': 32.25749214064771,
        '-131': 32.25751045490869,
        '-130': 32.25752991271814,
        '-129': 32.25755038308875,
        '-128': 32.25757171043427,
        '-127': 32.257593716344196,
        '-126': 32.2576162017612,
        '-125': 32.257638949536165,
        '-124': 32.25766172732941,
        '-123': 32.25768429082107,
        '-122': 32.257706387187625,
        '-121': 32.25772775879667,
        '-120': 32.257748147066216,
        '-119': 32.25776729643139,
        '-118': 32.25778495835659,
        '-117': 32.25780089532904,
        '-116': 32.25781488476721,
        '-115': 32.25782672277651,
        '-114': 32.257836227685566,
        '-113': 32.25784324329707,
        '-112': 32.257847641791,
        '-111': 32.257849326221454,
        '-110': 32.2578482325549,
        '-109': 32.25784433120394,
        '-108': 32.257837628019615,
        '-107': 32.25782816471427,
        '-106': 32.25781601869768,
        '-105': 32.25780130232018,
        '-104': 32.257784161528434,
        '-103': 32.25776477395069,
        '-102': 32.25774334644132,
        '-101': 32.25772011212472,
        '-100': 32.257695326990095,
        '-99': 32.25766926609847,
        '-98': 32.25764221947148,
        '-97': 32.25761448773855,
        '-96': 32.257586377624904,
        '-95': 32.25755819736557,
        '-94': 32.257530252132305,
        '-93': 32.25750283955987,
        '-92': 32.25747624545508,
        '-91': 32.257450739767435,
        '-90': 32.25742657289366,
        '-89': 32.25740397238001,
        '-88': 32.25738314007669,
        '-87': 32.25736424978734,
        '-86': 32.257347445445134,
        '-85': 32.25733283983434,
        '-84': 32.257320513863554,
        '-83': 32.257310516384564,
        '-82': 32.25730286453823,
        '-81': 32.257297544598295,
        '-80': 32.25729451327316,
        '-79': 32.257293699417495,
        '-78': 32.257295006097934,
        '-77': 32.25729831295193,
        '-76': 32.25730347877498,
        '-75': 32.257310344269825,
        '-74': 32.2573187348916,
        '-73': 32.257328463724086,
        '-72': 32.257339334327106,
        '-71': 32.25735114349893,
        '-70': 32.25736368390522,
        '-69': 32.25737674653286,
        '-68': 32.257390122935945,
        '-67': 32.25740360724952,
        '-66': 32.257416997956874,
        '-65': 32.257430099403855,
        '-64': 32.25744272306402,
        '-63': 32.25745468856528,
        '-62': 32.25746582449691,
        '-61': 32.2574759690216,
        '-60': 32.25748497032253,
        '-59': 32.257492686918965,
        '-58': 32.25749898788616,
        '-57': 32.25750375301626,
        '-56': 32.2575068729559,
        '-55': 32.25750824935442,
        '-54': 32.25750779505267,
        '-53': 32.257505434338036,
        '-52': 32.25750110328563,
        '-51': 32.25749475019861,
        '-50': 32.25748633615392,
        '-49': 32.25747583565204,
        '-48': 32.257463237361456,
        '-47': 32.2574485449415,
        '-46': 32.257431777920196,
        '-45': 32.25741297259679,
        '-44': 32.257392182933884,
        '-43': 32.25736948139941,
        '-42': 32.25734495971557,
        '-41': 32.25731872947045,
        '-40': 32.25729092254769,
        '-39': 32.25726169133113,
        '-38': 32.257231208644555,
        '-37': 32.25719966739088,
        '-36': 32.25716727986157,
        '-35': 32.25713427669379,
        '-34': 32.257100905462146,
        '-33': 32.2570674288998,
        '-32': 32.25703412275507,
        '-31': 32.257001273298684,
        '-30': 32.25696917450774,
        '-29': 32.256938124962346,
        '-28': 32.25690842449972,
        '-27': 32.256880370679575,
        '-26': 32.256854255121475,
        '-25': 32.256830359780615,
        '-24': 32.256808953232756,
        '-23': 32.256790287041355,
        '-22': 32.256774592279804,
        '-21': 32.25676207628089,
        '-20': 32.25675291968127,
        '-19': 32.256747273823535,
        '-18': 32.25674525857134,
        '-17': 32.25674696058375,
        '-16': 32.256752432085165,
        '-15': 32.256761690155834,
        '-14': 32.25677471655576,
        '-13': 32.2567914580827,
        '-12': 32.256811827452395,
        '-11': 32.25683570467701,
        '-10': 32.2568629389066,
        '-9': 32.25689335068801,
        '-8': 32.256926734586294,
        '-7': 32.25696286210681,
        '-6': 32.25700148485034,
        '-5': 32.25704233782957,
        '-4': 32.257085142874345,
        '-3': 32.2571296120533,
        '-2': 32.257175451041604,
        '-1': 32.25722236237011
    },
    '87': {
        '0': 32.25718382782523,
        '1': 32.25722319968664,
        '2': 32.25726387863815,
        '3': 32.25730567006942,
        '4': 32.2573483761695,
        '5': 32.25739179717968,
        '6': 32.25743573263039,
        '7': 32.257479982554024,
        '8': 32.25752434866722,
        '9': 32.257568635515824,
        '10': 32.25761265157762,
        '11': 32.257656210317535,
        '12': 32.257699131191444,
        '13': 32.25774124059461,
        '14': 32.257782372751805,
        '15': 32.25782237054587,
        '16': 32.2578610862823,
        '17': 32.257898382387246,
        '18': 32.25793413203673,
        '19': 32.25796821971442,
        '20': 32.25800054169613,
        '21': 32.258031006458395,
        '22': 32.25805953500907,
        '23': 32.25808606113738,
        '24': 32.25811053158173,
        '25': 32.25813290611258,
        '26': 32.2581531575291,
        '27': 32.25817127156757,
        '28': 32.258187246720674,
        '29': 32.2582010939669,
        '30': 32.25821283641002,
        '31': 32.25822250882934,
        '32': 32.25823015714239,
        '33': 32.258235837782244,
        '34': 32.2582396169934,
        '35': 32.25824157005023,
        '36': 32.25824178040413,
        '37': 32.25824033876586,
        '38': 32.2582373421309,
        '39': 32.258232892756894,
        '40': 32.25822709710291,
        '41': 32.25822006474123,
        '42': 32.258211907253106,
        '43': 32.2582027371205,
        '44': 32.258192666626364,
        '45': 32.25818180677585,
        '46': 32.258170266251064,
        '47': 32.25815815041194,
        '48': 32.25814556035498,
        '49': 32.25813259204131,
        '50': 32.25811933550424,
        '51': 32.258105874145976,
        '52': 32.25809228413144,
        '53': 32.258078633886015,
        '54': 32.25806498370243,
        '55': 32.258051385460504,
        '56': 32.25803788246158,
        '57': 32.25802450937805,
        '58': 32.258011292316446,
        '59': 32.25799824899088,
        '60': 32.2579853890023,
        '61': 32.25797271421714,
        '62': 32.25796021923778,
        '63': 32.257947891956114,
        '64': 32.25793571417993,
        '65': 32.257923662321645,
        '66': 32.25791170813781,
        '67': 32.25789981950735,
        '68': 32.257887961236364,
        '69': 32.257876095877414,
        '70': 32.257864184550904,
        '71': 32.2578521877573,
        '72': 32.2578400661685,
        '73': 32.25782778138833,
        '74': 32.25781529667249,
        '75': 32.257802577599065,
        '76': 32.25778959268271,
        '77': 32.25777631392556,
        '78': 32.25776271730014,
        '79': 32.25774878316056,
        '80': 32.25773449657898,
        '81': 32.25771984760665,
        '82': 32.25770483145895,
        '83': 32.25768944862557,
        '84': 32.257673704908434,
        '85': 32.2576576113898,
        '86': 32.25764118433499,
        '87': 32.25762444503442,
        '88': 32.25760741959013,
        '89': 32.257590138653036,
        '90': 32.25757263711674,
        '91': 32.25755495377477,
        '92': 32.25753713094753,
        '93': 32.25751921408566,
        '94': 32.257501251356196,
        '95': 32.2574832932177,
        '96': 32.25746539199018,
        '97': 32.257447601424936,
        '98': 32.257429976279695,
        '99': 32.25741257190257,
        '100': 32.25739544382942,
        '101': 32.257378647396706,
        '102': 32.257362237372966,
        '103': 32.25734626761027,
        '104': 32.25733079071679,
        '105': 32.25731585775131,
        '106': 32.2573015179392,
        '107': 32.257287818409786,
        '108': 32.25727480395419,
        '109': 32.257262516802164,
        '110': 32.257250996416836,
        '111': 32.25724027930546,
        '112': 32.25723039884496,
        '113': 32.257221385120175,
        '114': 32.257213264773576,
        '115': 32.25720606086538,
        '116': 32.25719979274258,
        '117': 32.25719447591665,
        '118': 32.257190121949385,
        '119': 32.257186738347265,
        '120': 32.25718432846455,
        '121': 32.257182891416655,
        '122': 32.257182422004824,
        '123': 32.257182910654215,
        '124': 32.2571843433677,
        '125': 32.25718670169786,
        '126': 32.25718996273988,
        '127': 32.25719409914866,
        '128': 32.25719907918286,
        '129': 32.25720486677897,
        '130': 32.257211421658454,
        '131': 32.25721869947031,
        '132': 32.25722665197153,
        '133': 32.25723522724736,
        '134': 32.257244369972305,
        '135': 32.257254021713,
        '136': 32.257264121272456,
        '137': 32.25727460507527,
        '138': 32.257285407591795,
        '139': 32.257296461799314,
        '140': 32.25730769967624,
        '141': 32.257319052726025,
        '142': 32.25733045252519,
        '143': 32.257341831290034,
        '144': 32.257353122455946,
        '145': 32.257364261261856,
        '146': 32.25737518533277,
        '147': 32.2573858352525,
        '148': 32.25739615511829,
        '149': 32.257406093069626,
        '150': 32.257415601782554,
        '151': 32.25742463892205,
        '152': 32.257433167544555,
        '153': 32.25744115644357,
        '154': 32.257448580432126,
        '155': 32.257455420556184,
        '156': 32.25746166423417,
        '157': 32.25746730531926,
        '158': 32.25747234408141,
        '159': 32.25747678710805,
        '160': 32.257480647123074,
        '161': 32.25748394272565,
        '162': 32.25748669805113,
        '163': 32.25748894235804,
        '164': 32.25749070954653,
        '165': 32.257492037614355,
        '166': 32.25749296805837,
        '167': 32.257493545230055,
        '168': 32.25749381565453,
        '169': 32.25749382732388,
        '170': 32.25749362897534,
        '171': 32.25749326936617,
        '172': 32.25749279655664,
        '173': 32.25749225721317,
        '174': 32.25749169594284,
        '175': 32.25749115467069,
        '176': 32.25749067207033,
        '177': 32.257490283057464,
        '178': 32.257490018355234,
        '179': 32.25748990413887,
        '180': 32.25748996176596,
        '-180': 32.25748996176596,
        '-179': 32.257490207596895,
        '-178': 32.25749065290886,
        '-177': 32.25749130390468,
        '-176': 32.25749216181647,
        '-175': 32.25749322310193,
        '-174': 32.257494479729715,
        '-173': 32.25749591954858,
        '-172': 32.25749752673324,
        '-171': 32.25749928229868,
        '-170': 32.25750116467323,
        '-169': 32.25750315031947,
        '-168': 32.257505214391315,
        '-167': 32.25750733141477,
        '-166': 32.257509475979404,
        '-165': 32.257511623427256,
        '-164': 32.257513750526215,
        '-163': 32.25751583611466,
        '-162': 32.25751786170522,
        '-161': 32.25751981203571,
        '-160': 32.2575216755569,
        '-159': 32.25752344484722,
        '-158': 32.25752511694629,
        '-157': 32.257526693600774,
        '-156': 32.25752818141726,
        '-155': 32.25752959191867,
        '-154': 32.25753094150296,
        '-153': 32.257532251303836,
        '-152': 32.257533546955635,
        '-151': 32.2575348582661,
        '-150': 32.25753621880213,
        '-149': 32.257537665395716,
        '-148': 32.25753923757799,
        '-147': 32.25754097695141,
        '-146': 32.25754292651053,
        '-145': 32.25754512992292,
        '-144': 32.25754763078285,
        '-143': 32.25755047185043,
        '-142': 32.25755369428913,
        '-141': 32.257557336915376,
        '-140': 32.257561435472674,
        '-139': 32.25756602194347,
        '-138': 32.257571123910296,
        '-137': 32.25757676397799,
        '-136': 32.257582959267,
        '-135': 32.257589720987184,
        '-134': 32.25759705409992,
        '-133': 32.25760495707513,
        '-132': 32.25761342174823,
        '-131': 32.25762243328044,
        '-130': 32.25763197022406,
        '-129': 32.25764200469303,
        '-128': 32.25765250263693,
        '-127': 32.25766342421495,
        '-126': 32.257674724265215,
        '-125': 32.25768635286236,
        '-124': 32.257698255955624,
        '-123': 32.25771037607779,
        '-122': 32.25772265311411,
        '-121': 32.25773502511966,
        '-120': 32.257747429172085,
        '-119': 32.25775980224636,
        '-118': 32.25777208209759,
        '-117': 32.25778420813751,
        '-116': 32.257796122290344,
        '-115': 32.25780776981411,
        '-114': 32.25781910007314,
        '-113': 32.25783006724944,
        '-112': 32.257840630979885,
        '-111': 32.25785075690897,
        '-110': 32.2578604171468,
        '-109': 32.25786959062413,
        '-108': 32.25787826333791,
        '-107': 32.25788642848241,
        '-106': 32.257894086462755,
        '-105': 32.25790124478996,
        '-104': 32.2579079178583,
        '-103': 32.25791412660774,
        '-102': 32.2579198980765,
        '-101': 32.25792526484996,
        '-100': 32.25793026441469,
        '-99': 32.257934938427255,
        '-98': 32.25793933190932,
        '-97': 32.257943492381614,
        '-96': 32.25794746895032,
        '-95': 32.257951311360195,
        '-94': 32.25795506902932,
        '-93': 32.25795879008043,
        '-92': 32.257962520384034,
        '-91': 32.25796630262764,
        '-90': 32.257970175425655,
        '-89': 32.25797417248331,
        '-88': 32.25797832182657,
        '-87': 32.25798264510976,
        '-86': 32.25798715701025,
        '-85': 32.257991864718846,
        '-84': 32.25799676753247,
        '-83': 32.258001856554124,
        '-82': 32.25800711450367,
        '-81': 32.25801251564085,
        '-80': 32.25801802580089,
        '-79': 32.25802360254076,
        '-78': 32.2580291953933,
        '-77': 32.2580347462246,
        '-76': 32.25804018968907,
        '-75': 32.258045453775395,
        '-74': 32.25805046043562,
        '-73': 32.25805512628934,
        '-72': 32.25805936339354,
        '-71': 32.25806308006922,
        '-70': 32.25806618177493,
        '-69': 32.25806857201793,
        '-68': 32.258070153293126,
        '-67': 32.25807082804087,
        '-66': 32.258070499614206,
        '-65': 32.258069073247334,
        '-64': 32.25806645701703,
        '-63': 32.25806256278937,
        '-62': 32.25805730714465,
        '-61': 32.25805061227403,
        '-60': 32.25804240684183,
        '-59': 32.2580326268077,
        '-58': 32.25802121620401,
        '-57': 32.25800812786315,
        '-56': 32.25799332409086,
        '-55': 32.25797677728117,
        '-54': 32.257958470469276,
        '-53': 32.25793839781862,
        '-52': 32.25791656503866,
        '-51': 32.25789298972994,
        '-50': 32.257867701653176,
        '-49': 32.257840742919285,
        '-48': 32.257812168097104,
        '-47': 32.25778204423612,
        '-46': 32.25775045080142,
        '-45': 32.25771747951854,
        '-44': 32.25768323412597,
        '-43': 32.25764783003398,
        '-42': 32.25761139388827,
        '-41': 32.25757406303841,
        '-40': 32.25753598491087,
        '-39': 32.25749731628804,
        '-38': 32.25745822249513,
        '-37': 32.257418876497724,
        '-36': 32.25737945791443,
        '-35': 32.257340151949336,
        '-34': 32.25730114825098,
        '-33': 32.25726263970495,
        '-32': 32.25722482116884,
        '-31': 32.25718788815929,
        '-30': 32.25715203550152,
        '-29': 32.25711745595328,
        '-28': 32.25708433881519,
        '-27': 32.25705286854079,
        '-26': 32.2570232233595,
        '-25': 32.25699557392646,
        '-24': 32.25697008201285,
        '-23': 32.2569468992504,
        '-22': 32.25692616594353,
        '-21': 32.25690800996163,
        '-20': 32.25689254572363,
        '-19': 32.25687987328581,
        '-18': 32.25687007754238,
        '-17': 32.25686322754775,
        '-16': 32.25685937596713,
        '-15': 32.25685855866104,
        '-14': 32.25686079440738,
        '-13': 32.25686608476325,
        '-12': 32.25687441406674,
        '-11': 32.25688574957727,
        '-10': 32.25690004175153,
        '-9': 32.25691722465035,
        '-8': 32.256937216470654,
        '-7': 32.25695992019456,
        '-6': 32.25698522434786,
        '-5': 32.25701300385787,
        '-4': 32.257043121000414,
        '-3': 32.25707542642542,
        '-2': 32.25710976024925,
        '-1': 32.25714595320306
    },
    '88': {
        '0': 32.25755751035407,
        '1': 32.25757326638638,
        '2': 32.25758980816993,
        '3': 32.25760707011901,
        '4': 32.257624984364,
        '5': 32.25764348109893,
        '6': 32.257662488935175,
        '7': 32.257681935258475,
        '8': 32.2577017465873,
        '9': 32.257721848930466,
        '10': 32.25774216814134,
        '11': 32.25776263026689,
        '12': 32.25778316188936,
        '13': 32.25780369045845,
        '14': 32.25782414461233,
        '15': 32.257844454485436,
        '16': 32.25786455200155,
        '17': 32.25788437115051,
        '18': 32.25790384824711,
        '19': 32.257922922170955,
        '20': 32.25794153458612,
        '21': 32.25795963013959,
        '22': 32.2579771566377,
        '23': 32.25799406519997,
        '24': 32.25801031038967,
        '25': 32.25802585032111,
        '26': 32.25804064674311,
        '27': 32.2580546650991,
        '28': 32.25806787456371,
        '29': 32.25808024805644,
        '30': 32.258091762232894,
        '31': 32.258102397454174,
        '32': 32.25811213773541,
        '33': 32.25812097067449,
        '34': 32.25812888736185,
        '35': 32.25813588227292,
        '36': 32.25814195314444,
        '37': 32.25814710083606,
        '38': 32.25815132917918,
        '39': 32.258154644814105,
        '40': 32.25815705701775,
        '41': 32.258158577523474,
        '42': 32.25815922033474,
        '43': 32.258159001534594,
        '44': 32.25815793909256,
        '45': 32.25815605267091,
        '46': 32.25815336343186,
        '47': 32.258149893847616,
        '48': 32.258145667514476,
        '49': 32.258140708972796,
        '50': 32.25813504353424,
        '51': 32.258128697117215,
        '52': 32.258121696092104,
        '53': 32.25811406713699,
        '54': 32.25810583710468,
        '55': 32.258097032902086,
        '56': 32.25808768138197,
        '57': 32.2580778092479,
        '58': 32.25806744297216,
        '59': 32.25805660872727,
        '60': 32.25804533233009,
        '61': 32.258033639199226,
        '62': 32.258021554324536,
        '63': 32.25800910224863,
        '64': 32.257996307059415,
        '65': 32.25798319239305,
        '66': 32.25796978144634,
        '67': 32.257956096997596,
        '68': 32.257942161434826,
        '69': 32.25792799679036,
        '70': 32.257913624780436,
        '71': 32.25789906684893,
        '72': 32.257884344213686,
        '73': 32.2578694779146,
        '74': 32.257854488862115,
        '75': 32.25783939788505,
        '76': 32.25782422577671,
        '77': 32.257808993338394,
        '78': 32.257793721419105,
        '79': 32.257778430950914,
        '80': 32.257763142979066,
        '81': 32.257747878686196,
        '82': 32.2577326594101,
        '83': 32.257717506654636,
        '84': 32.25770244209347,
        '85': 32.25768748756612,
        '86': 32.257672665066785,
        '87': 32.25765799672517,
        '88': 32.25764350477997,
        '89': 32.25762921154491,
        '90': 32.25761513936758,
        '91': 32.2576013105816,
        '92': 32.25758774745219,
        '93': 32.25757447211594,
        '94': 32.257561506515195,
        '95': 32.25754887232752,
        '96': 32.25753659089103,
        '97': 32.25752468312625,
        '98': 32.25751316945507,
        '99': 32.257502069717546,
        '100': 32.257491403087336,
        '101': 32.257481187986414,
        '102': 32.257471441999826,
        '103': 32.257462181791105,
        '104': 32.25745342301915,
        '105': 32.25744518025729,
        '106': 32.2574374669149,
        '107': 32.2574302951626,
        '108': 32.25742367586135,
        '109': 32.25741761849598,
        '110': 32.25741213111411,
        '111': 32.25740722027023,
        '112': 32.25740289097616,
        '113': 32.2573991466578,
        '114': 32.25739598911872,
        '115': 32.257393418511,
        '116': 32.257391433313494,
        '117': 32.257390030317794,
        '118': 32.25738920462224,
        '119': 32.25738894963405,
        '120': 32.257389257079524,
        '121': 32.2573901170228,
        '122': 32.257391517892934,
        '123': 32.25739344651922,
        '124': 32.25739588817484,
        '125': 32.257398826628915,
        '126': 32.25740224420621,
        '127': 32.25740612185494,
        '128': 32.257410439221985,
        '129': 32.25741517473531,
        '130': 32.25742030569327,
        '131': 32.2574258083603,
        '132': 32.25743165806844,
        '133': 32.25743782932429,
        '134': 32.257444295920806,
        '135': 32.25745103105302,
        '136': 32.25745800743735,
        '137': 32.25746519743359,
        '138': 32.25747257316873,
        '139': 32.25748010666194,
        '140': 32.257487769949854,
        '141': 32.2574955352112,
        '142': 32.25750337488997,
        '143': 32.25751126181633,
        '144': 32.25751916932417,
        '145': 32.25752707136471,
        '146': 32.257534942615116,
        '147': 32.2575427585814,
        '148': 32.257550495694844,
        '149': 32.25755813140111,
        '150': 32.257565644241666,
        '151': 32.25757301392659,
        '152': 32.25758022139844,
        '153': 32.257587248886864,
        '154': 32.25759407995328,
        '155': 32.25760069952591,
        '156': 32.257607093924435,
        '157': 32.25761325087484,
        '158': 32.25761915951413,
        '159': 32.25762481038527,
        '160': 32.25763019542272,
        '161': 32.25763530792894,
        '162': 32.25764014254221,
        '163': 32.25764469519675,
        '164': 32.257648963075496,
        '165': 32.25765294455643,
        '166': 32.25765663915353,
        '167': 32.25766004745284,
        '168': 32.257663171044996,
        '169': 32.25766601245504,
        '170': 32.257668575070454,
        '171': 32.257670863068604,
        '172': 32.257672881344476,
        '173': 32.25767463543968,
        '174': 32.25767613147374,
        '175': 32.25767737607838,
        '176': 32.25767837633584,
        '177': 32.2576791397218,
        '178': 32.2576796740535,
        '179': 32.257679987443694,
        '180': 32.25768008826088,
        '-180': 32.25768008826088,
        '-179': 32.25767998509597,
        '-178': 32.257679686735685,
        '-177': 32.257679202142675,
        '-176': 32.25767854044232,
        '-175': 32.257677710916035,
        '-174': 32.25767672300065,
        '-173': 32.257675586293544,
        '-172': 32.257674310563026,
        '-171': 32.2576729057631,
        '-170': 32.25767138205208,
        '-169': 32.257669749814276,
        '-168': 32.25766801968383,
        '-167': 32.25766620256947,
        '-166': 32.25766430968001,
        '-165': 32.25766235254881,
        '-164': 32.25766034305681,
        '-163': 32.25765829345291,
        '-162': 32.25765621637099,
        '-161': 32.25765412484259,
        '-160': 32.2576520323046,
        '-159': 32.25764995260119,
        '-158': 32.25764789997938,
        '-157': 32.257645889077914,
        '-156': 32.25764393490872,
        '-155': 32.257642052831265,
        '-154': 32.25764025851901,
        '-153': 32.25763856791857,
        '-152': 32.257636997201296,
        '-151': 32.257635562707804,
        '-150': 32.25763428088575,
        '-149': 32.25763316822128,
        '-148': 32.25763224116503,
        '-147': 32.257631516053024,
        '-146': 32.257631009023775,
        '-145': 32.25763073593199,
        '-144': 32.25763071226017,
        '-143': 32.25763095302917,
        '-142': 32.25763147270832,
        '-141': 32.257632285126704,
        '-140': 32.25763340338632,
        '-139': 32.25763483977828,
        '-138': 32.257636605702984,
        '-137': 32.25763871159538,
        '-136': 32.2576411668559,
        '-135': 32.257643979788185,
        '-134': 32.25764715754411,
        '-133': 32.257650706076895,
        '-132': 32.25765463010243,
        '-131': 32.25765893306959,
        '-130': 32.25766361713961,
        '-129': 32.25766868317452,
        '-128': 32.257674130734664,
        '-127': 32.25767995808527,
        '-126': 32.25768616221157,
        '-125': 32.25769273884217,
        '-124': 32.25769968248013,
        '-123': 32.25770698644097,
        '-122': 32.25771464289696,
        '-121': 32.25772264292698,
        '-120': 32.25773097657071,
        '-119': 32.25773963288653,
        '-118': 32.25774860001183,
        '-117': 32.25775786522503,
        '-116': 32.25776741500796,
        '-115': 32.25777723510782,
        '-114': 32.257787310597465,
        '-113': 32.25779762593359,
        '-112': 32.257808165011184,
        '-111': 32.25781891121419,
        '-110': 32.257829847461245,
        '-109': 32.257840956245936,
        '-108': 32.257852219671335,
        '-107': 32.25786361947814,
        '-106': 32.25787513706639,
        '-105': 32.25788675351082,
        '-104': 32.257898449569396,
        '-103': 32.25791020568593,
        '-102': 32.25792200198647,
        '-101': 32.257933818270445,
        '-100': 32.25794563399672,
        '-99': 32.257957428265776,
        '-98': 32.25796917979827,
        '-97': 32.257980866911495,
        '-96': 32.25799246749419,
        '-95': 32.25800395898126,
        '-94': 32.25801531832916,
        '-93': 32.25802652199328,
        '-92': 32.258037545908536,
        '-91': 32.258048365474146,
        '-90': 32.25805895554396,
        '-89': 32.25806929042331,
        '-88': 32.25807934387337,
        '-87': 32.2580890891243,
        '-86': 32.25809849889763,
        '-85': 32.25810754543909,
        '-84': 32.25811620056215,
        '-83': 32.258124435703195,
        '-82': 32.25813222198838,
        '-81': 32.258139530312796,
        '-80': 32.258146331431604,
        '-79': 32.25815259606347,
        '-78': 32.25815829500603,
        '-77': 32.2581633992627,
        '-76': 32.258167880180764,
        '-75': 32.25817170959988,
        '-74': 32.25817486000998,
        '-73': 32.258177304718046,
        '-72': 32.258179018022304,
        '-71': 32.25817997539288,
        '-70': 32.258180153657385,
        '-69': 32.25817953119034,
        '-68': 32.25817808810467,
        '-67': 32.25817580644377,
        '-66': 32.25817267037267,
        '-65': 32.258168666366586,
        '-64': 32.258163783395005,
        '-63': 32.25815801309985,
        '-62': 32.25815134996603,
        '-61': 32.25814379148242,
        '-60': 32.25813533829207,
        '-59': 32.258125994329596,
        '-58': 32.258115766944705,
        '-57': 32.258104667009846,
        '-56': 32.25809270901125,
        '-55': 32.25807991112145,
        '-54': 32.258066295252625,
        '-53': 32.258051887089415,
        '-52': 32.25803671610048,
        '-51': 32.25802081552787,
        '-50': 32.25800422235376,
        '-49': 32.25798697724381,
        '-48': 32.25796912446728,
        '-47': 32.25795071179321,
        '-46': 32.257931790363145,
        '-45': 32.25791241454038,
        '-44': 32.25789264173615,
        '-43': 32.25787253221337,
        '-42': 32.25785214886855,
        '-41': 32.25783155699293,
        '-40': 32.25781082401387,
        '-39': 32.25779001921763,
        '-38': 32.257769213455084,
        '-37': 32.257748478832106,
        '-36': 32.257727888385745,
        '-35': 32.25770751574879,
        '-34': 32.25768743480416,
        '-33': 32.257667719331415,
        '-32': 32.25764844264755,
        '-31': 32.25762967724425,
        '-30': 32.25761149442406,
        '-29': 32.25759396393772,
        '-28': 32.257577153625085,
        '-27': 32.257561129062076,
        '-26': 32.25754595321599,
        '-25': 32.25753168611154,
        '-24': 32.25751838450992,
        '-23': 32.25750610160317,
        '-22': 32.25749488672583,
        '-21': 32.2574847850862,
        '-20': 32.25747583751868,
        '-19': 32.25746808025932,
        '-18': 32.25746154474601,
        '-17': 32.257456257444574,
        '-16': 32.257452239702346,
        '-15': 32.25744950762984,
        '-14': 32.25744807201175,
        '-13': 32.257447938247445,
        '-12': 32.25744910632172,
        '-11': 32.257451570805706,
        '-10': 32.25745532088813,
        '-9': 32.25746034043621,
        '-8': 32.257466608086446,
        '-7': 32.25747409736363,
        '-6': 32.25748277682805,
        '-5': 32.25749261024927,
        '-4': 32.25750355680539,
        '-3': 32.25751557130628,
        '-2': 32.257528604439194,
        '-1': 32.25754260303518
    },
    '89': {
        '0': 32.257894987318465,
        '1': 32.25789676172635,
        '2': 32.257898640212794,
        '3': 32.25790061535691,
        '4': 32.25790267937462,
        '5': 32.25790482414704,
        '6': 32.25790704124974,
        '7': 32.25790932198302,
        '8': 32.257911657402865,
        '9': 32.25791403835257,
        '10': 32.25791645549469,
        '11': 32.25791889934361,
        '12': 32.257921360297935,
        '13': 32.25792382867331,
        '14': 32.25792629473485,
        '15': 32.257928748729526,
        '16': 32.25793118091828,
        '17': 32.257933581607425,
        '18': 32.25793594117972,
        '19': 32.25793825012452,
        '20': 32.25794049906733,
        '21': 32.25794267879836,
        '22': 32.25794478029994,
        '23': 32.25794679477305,
        '24': 32.257948713662614,
        '25': 32.25795052868136,
        '26': 32.257952231832775,
        '27': 32.257953815432096,
        '28': 32.257955272126516,
        '29': 32.257956594913395,
        '30': 32.2579577771574,
        '31': 32.25795881260571,
        '32': 32.257959695402086,
        '33': 32.257960420099074,
        '34': 32.25796098166883,
        '35': 32.257961375512416,
        '36': 32.25796159746741,
        '37': 32.25796164381415,
        '38': 32.25796151128048,
        '39': 32.25796119704492,
        '40': 32.257960698738586,
        '41': 32.25796001444557,
        '42': 32.25795914270217,
        '43': 32.257958082494596,
        '44': 32.257956833255726,
        '45': 32.25795539486047,
        '46': 32.25795376762013,
        '47': 32.25795195227563,
        '48': 32.25794994998984,
        '49': 32.25794776233901,
        '50': 32.25794539130303,
        '51': 32.25794283925538,
        '52': 32.257940108951885,
        '53': 32.25793720351908,
        '54': 32.257934126441825,
        '55': 32.257930881550394,
        '56': 32.25792747300707,
        '57': 32.25792390529228,
        '58': 32.25792018319039,
        '59': 32.25791631177504,
        '60': 32.25791229639437,
        '61': 32.257908142655744,
        '62': 32.257903856410586,
        '63': 32.2578994437388,
        '64': 32.25789491093308,
        '65': 32.257890264483386,
        '66': 32.25788551106097,
        '67': 32.25788065750271,
        '68': 32.25787571079524,
        '69': 32.257870678059135,
        '70': 32.25786556653325,
        '71': 32.257860383558956,
        '72': 32.257855136564565,
        '73': 32.257849833049804,
        '74': 32.257844480570455,
        '75': 32.25783908672296,
        '76': 32.25783365912948,
        '77': 32.25782820542273,
        '78': 32.25782273323119,
        '79': 32.25781725016448,
        '80': 32.25781176379886,
        '81': 32.257806281662965,
        '82': 32.257800811223674,
        '83': 32.25779535987226,
        '84': 32.25778993491079,
        '85': 32.25778454353865,
        '86': 32.25777919283944,
        '87': 32.25777388976811,
        '88': 32.25776864113825,
        '89': 32.25776345360994,
        '90': 32.25775833367762,
        '91': 32.25775328765849,
        '92': 32.25774832168122,
        '93': 32.25774344167496,
        '94': 32.257738653358814,
        '95': 32.25773396223171,
        '96': 32.25772937356275,
        '97': 32.257724892381844,
        '98': 32.25772052347104,
        '99': 32.25771627135631,
        '100': 32.257712140299645,
        '101': 32.257708134292095,
        '102': 32.25770425704698,
        '103': 32.25770051199398,
        '104': 32.257696902273736,
        '105': 32.25769343073303,
        '106': 32.25769009992077,
        '107': 32.257686912084544,
        '108': 32.25768386916784,
        '109': 32.257680972808195,
        '110': 32.2576782243357,
        '111': 32.25767562477265,
        '112': 32.257673174833634,
        '113': 32.25767087492653,
        '114': 32.25766872515417,
        '115': 32.25766672531699,
        '116': 32.257664874916024,
        '117': 32.25766317315714,
        '118': 32.25766161895568,
        '119': 32.25766021094203,
        '120': 32.25765894746781,
        '121': 32.257657826612906,
        '122': 32.25765684619315,
        '123': 32.257656003768645,
        '124': 32.257655296652786,
        '125': 32.25765472192199,
        '126': 32.25765427642588,
        '127': 32.25765395679824,
        '128': 32.25765375946832,
        '129': 32.257653680672796,
        '130': 32.25765371646815,
        '131': 32.25765386274347,
        '132': 32.25765411523364,
        '133': 32.25765446953291,
        '134': 32.25765492110873,
        '135': 32.2576554653159,
        '136': 32.25765609741083,
        '137': 32.257656812566054,
        '138': 32.25765760588482,
        '139': 32.25765847241574,
        '140': 32.25765940716748,
        '141': 32.2576604051234,
        '142': 32.25766146125613,
        '143': 32.257662570541974,
        '144': 32.25766372797527,
        '145': 32.257664928582365,
        '146': 32.25766616743547,
        '147': 32.25766743966611,
        '148': 32.25766874047832,
        '149': 32.25767006516151,
        '150': 32.257671409102706,
        '151': 32.257672767798574,
        '152': 32.257674136866875,
        '153': 32.257675512057375,
        '154': 32.25767688926235,
        '155': 32.25767826452644,
        '156': 32.25767963405595,
        '157': 32.25768099422759,
        '158': 32.257682341596656,
        '159': 32.25768367290439,
        '160': 32.257684985085106,
        '161': 32.25768627527224,
        '162': 32.25768754080415,
        '163': 32.25768877922903,
        '164': 32.25768998830929,
        '165': 32.25769116602533,
        '166': 32.257692310578655,
        '167': 32.257693420394325,
        '168': 32.25769449412289,
        '169': 32.25769553064169,
        '170': 32.257696529055494,
        '171': 32.257697488696685,
        '172': 32.2576984091248,
        '173': 32.257699290125444,
        '174': 32.257700131708916,
        '175': 32.25770093410802,
        '176': 32.25770169777558,
        '177': 32.25770242338131,
        '178': 32.25770311180835,
        '179': 32.25770376414925,
        '180': 32.25770438170143,
        '-180': 32.25770438170143,
        '-179': 32.25770496596247,
        '-178': 32.25770551862462,
        '-177': 32.25770604156922,
        '-176': 32.257706536860525,
        '-175': 32.257707006739246,
        '-174': 32.25770745361562,
        '-173': 32.257707880062355,
        '-172': 32.25770828880686,
        '-171': 32.25770868272347,
        '-170': 32.25770906482523,
        '-169': 32.257709438255254,
        '-168': 32.257709806277994,
        '-167': 32.25771017227003,
        '-166': 32.257710539710736,
        '-165': 32.25771091217246,
        '-164': 32.25771129331073,
        '-163': 32.257711686853995,
        '-162': 32.25771209659322,
        '-161': 32.25771252637124,
        '-160': 32.25771298007198,
        '-159': 32.25771346160933,
        '-158': 32.257713974916086,
        '-157': 32.257714523932506,
        '-156': 32.25771511259488,
        '-155': 32.25771574482397,
        '-154': 32.25771642451327,
        '-153': 32.257717155517355,
        '-152': 32.25771794164005,
        '-151': 32.25771878662271,
        '-150': 32.25771969413226,
        '-149': 32.257720667749766,
        '-148': 32.25772171095844,
        '-147': 32.257722827132284,
        '-146': 32.257724019524616,
        '-145': 32.257725291256705,
        '-144': 32.25772664530682,
        '-143': 32.257728084499234,
        '-142': 32.257729611493744,
        '-141': 32.25773122877531,
        '-140': 32.257732938644054,
        '-139': 32.257734743205695,
        '-138': 32.25773664436223,
        '-137': 32.25773864380318,
        '-136': 32.257740742997186,
        '-135': 32.25774294318412,
        '-134': 32.25774524536766,
        '-133': 32.25774765030853,
        '-132': 32.25775015851808,
        '-131': 32.25775277025262,
        '-130': 32.257755485508376,
        '-129': 32.25775830401685,
        '-128': 32.25776122524109,
        '-127': 32.25776424837248,
        '-126': 32.25776737232817,
        '-125': 32.25777059574929,
        '-124': 32.257773916999845,
        '-123': 32.25777733416624,
        '-122': 32.257780845057596,
        '-121': 32.25778444720677,
        '-120': 32.25778813787201,
        '-119': 32.25779191403958,
        '-118': 32.257795772426796,
        '-117': 32.25779970948597,
        '-116': 32.2578037214091,
        '-115': 32.25780780413317,
        '-114': 32.25781195334615,
        '-113': 32.257816164493924,
        '-112': 32.25782043278759,
        '-111': 32.2578247532117,
        '-110': 32.25782912053306,
        '-109': 32.2578335293102,
        '-108': 32.25783797390359,
        '-107': 32.25784244848633,
        '-106': 32.25784694705565,
        '-105': 32.25785146344489,
        '-104': 32.25785599133609,
        '-103': 32.257860524273305,
        '-102': 32.25786505567622,
        '-101': 32.257869578854546,
        '-100': 32.257874087022785,
        '-99': 32.257878573315644,
        '-98': 32.25788303080368,
        '-97': 32.257887452509706,
        '-96': 32.257891831425354,
        '-95': 32.25789616052822,
        '-94': 32.25790043279928,
        '-93': 32.257904641240664,
        '-92': 32.25790877889381,
        '-91': 32.2579128388577,
        '-90': 32.25791681430763,
        '-89': 32.257920698513765,
        '-88': 32.25792448486033,
        '-87': 32.257928166864474,
        '-86': 32.257931738195424,
        '-85': 32.25793519269356,
        '-84': 32.257938524389566,
        '-83': 32.257941727523324,
        '-82': 32.25794479656272,
        '-81': 32.257947726222284,
        '-80': 32.257950511481404,
        '-79': 32.25795314760244,
        '-78': 32.25795563014815,
        '-77': 32.25795795499888,
        '-76': 32.25796011836896,
        '-75': 32.25796211682278,
        '-74': 32.25796394728987,
        '-73': 32.25796560707947,
        '-72': 32.257967093894074,
        '-71': 32.25796840584217,
        '-70': 32.25796954145003,
        '-69': 32.25797049967223,
        '-68': 32.257971279901405,
        '-67': 32.25797188197647,
        '-66': 32.25797230618975,
        '-65': 32.25797255329281,
        '-64': 32.25797262450084,
        '-63': 32.25797252149553,
        '-62': 32.25797224642672,
        '-61': 32.25797180191219,
        '-60': 32.25797119103611,
        '-59': 32.25797041734571,
        '-58': 32.257969484846505,
        '-57': 32.25796839799559,
        '-56': 32.25796716169352,
        '-55': 32.25796578127429,
        '-54': 32.257964262493765,
        '-53': 32.25796261151626,
        '-52': 32.25796083489968,
        '-51': 32.25795893957877,
        '-50': 32.257956932846824,
        '-49': 32.257954822335925,
        '-48': 32.25795261599549,
        '-47': 32.25795032206943,
        '-46': 32.25794794907181,
        '-45': 32.25794550576131,
        '-44': 32.257943001114256,
        '-43': 32.25794044429657,
        '-42': 32.25793784463475,
        '-41': 32.25793521158557,
        '-40': 32.25793255470525,
        '-39': 32.257929883617635,
        '-38': 32.257927207981965,
        '-37': 32.257924537459886,
        '-36': 32.25792188168232,
        '-35': 32.257919250216,
        '-34': 32.257916652529936,
        '-33': 32.25791409796183,
        '-32': 32.25791159568491,
        '-31': 32.25790915467487,
        '-30': 32.2579067836773,
        '-29': 32.25790449117599,
        '-28': 32.25790228536163,
        '-27': 32.25790017410171,
        '-26': 32.257898164911275,
        '-25': 32.25789626492496,
        '-24': 32.257894480870235,
        '-23': 32.25789281904215,
        '-22': 32.257891285279584,
        '-21': 32.25788988494314,
        '-20': 32.257888622894754,
        '-19': 32.25788750347928,
        '-18': 32.257886530507825,
        '-17': 32.25788570724325,
        '-16': 32.25788503638764,
        '-15': 32.257884520072004,
        '-14': 32.25788415984806,
        '-13': 32.2578839566823,
        '-12': 32.25788391095228,
        '-11': 32.2578840224452,
        '-10': 32.257884290358675,
        '-9': 32.257884713303895,
        '-8': 32.25788528931081,
        '-7': 32.25788601583574,
        '-6': 32.25788688977109,
        '-5': 32.25788790745706,
        '-4': 32.25788906469568,
        '-3': 32.257890356766495,
        '-2': 32.25789177844452,
        '-1': 32.25789332401975
    },
    '90': {
        '0': 32.25783835997946,
        '1': 32.25783835997946,
        '2': 32.25783835997946,
        '3': 32.25783835997946,
        '4': 32.25783835997946,
        '5': 32.25783835997946,
        '6': 32.25783835997946,
        '7': 32.25783835997946,
        '8': 32.25783835997946,
        '9': 32.25783835997946,
        '10': 32.25783835997946,
        '11': 32.25783835997946,
        '12': 32.25783835997946,
        '13': 32.25783835997946,
        '14': 32.25783835997946,
        '15': 32.25783835997946,
        '16': 32.25783835997946,
        '17': 32.25783835997946,
        '18': 32.25783835997946,
        '19': 32.25783835997946,
        '20': 32.25783835997946,
        '21': 32.25783835997946,
        '22': 32.25783835997946,
        '23': 32.25783835997946,
        '24': 32.25783835997946,
        '25': 32.25783835997946,
        '26': 32.25783835997946,
        '27': 32.25783835997946,
        '28': 32.25783835997946,
        '29': 32.25783835997946,
        '30': 32.25783835997946,
        '31': 32.25783835997946,
        '32': 32.25783835997946,
        '33': 32.25783835997946,
        '34': 32.25783835997946,
        '35': 32.25783835997946,
        '36': 32.25783835997946,
        '37': 32.25783835997946,
        '38': 32.25783835997946,
        '39': 32.25783835997946,
        '40': 32.25783835997946,
        '41': 32.25783835997946,
        '42': 32.25783835997946,
        '43': 32.25783835997946,
        '44': 32.25783835997946,
        '45': 32.25783835997946,
        '46': 32.25783835997946,
        '47': 32.25783835997946,
        '48': 32.25783835997946,
        '49': 32.25783835997946,
        '50': 32.25783835997946,
        '51': 32.25783835997946,
        '52': 32.25783835997946,
        '53': 32.25783835997946,
        '54': 32.25783835997946,
        '55': 32.25783835997946,
        '56': 32.25783835997946,
        '57': 32.25783835997946,
        '58': 32.25783835997946,
        '59': 32.25783835997946,
        '60': 32.25783835997946,
        '61': 32.25783835997946,
        '62': 32.25783835997946,
        '63': 32.25783835997946,
        '64': 32.25783835997946,
        '65': 32.25783835997946,
        '66': 32.25783835997946,
        '67': 32.25783835997946,
        '68': 32.25783835997946,
        '69': 32.25783835997946,
        '70': 32.25783835997946,
        '71': 32.25783835997946,
        '72': 32.25783835997946,
        '73': 32.25783835997946,
        '74': 32.25783835997946,
        '75': 32.25783835997946,
        '76': 32.25783835997946,
        '77': 32.25783835997946,
        '78': 32.25783835997946,
        '79': 32.25783835997946,
        '80': 32.25783835997946,
        '81': 32.25783835997946,
        '82': 32.25783835997946,
        '83': 32.25783835997946,
        '84': 32.25783835997946,
        '85': 32.25783835997946,
        '86': 32.25783835997946,
        '87': 32.25783835997946,
        '88': 32.25783835997946,
        '89': 32.25783835997946,
        '90': 32.25783835997946,
        '91': 32.25783835997946,
        '92': 32.25783835997946,
        '93': 32.25783835997946,
        '94': 32.25783835997946,
        '95': 32.25783835997946,
        '96': 32.25783835997946,
        '97': 32.25783835997946,
        '98': 32.25783835997946,
        '99': 32.25783835997946,
        '100': 32.25783835997946,
        '101': 32.25783835997946,
        '102': 32.25783835997946,
        '103': 32.25783835997946,
        '104': 32.25783835997946,
        '105': 32.25783835997946,
        '106': 32.25783835997946,
        '107': 32.25783835997946,
        '108': 32.25783835997946,
        '109': 32.25783835997946,
        '110': 32.25783835997946,
        '111': 32.25783835997946,
        '112': 32.25783835997946,
        '113': 32.25783835997946,
        '114': 32.25783835997946,
        '115': 32.25783835997946,
        '116': 32.25783835997946,
        '117': 32.25783835997946,
        '118': 32.25783835997946,
        '119': 32.25783835997946,
        '120': 32.25783835997946,
        '121': 32.25783835997946,
        '122': 32.25783835997946,
        '123': 32.25783835997946,
        '124': 32.25783835997946,
        '125': 32.25783835997946,
        '126': 32.25783835997946,
        '127': 32.25783835997946,
        '128': 32.25783835997946,
        '129': 32.25783835997946,
        '130': 32.25783835997946,
        '131': 32.25783835997946,
        '132': 32.25783835997946,
        '133': 32.25783835997946,
        '134': 32.25783835997946,
        '135': 32.25783835997946,
        '136': 32.25783835997946,
        '137': 32.25783835997946,
        '138': 32.25783835997946,
        '139': 32.25783835997946,
        '140': 32.25783835997946,
        '141': 32.25783835997946,
        '142': 32.25783835997946,
        '143': 32.25783835997946,
        '144': 32.25783835997946,
        '145': 32.25783835997946,
        '146': 32.25783835997946,
        '147': 32.25783835997946,
        '148': 32.25783835997946,
        '149': 32.25783835997946,
        '150': 32.25783835997946,
        '151': 32.25783835997946,
        '152': 32.25783835997946,
        '153': 32.25783835997946,
        '154': 32.25783835997946,
        '155': 32.25783835997946,
        '156': 32.25783835997946,
        '157': 32.25783835997946,
        '158': 32.25783835997946,
        '159': 32.25783835997946,
        '160': 32.25783835997946,
        '161': 32.25783835997946,
        '162': 32.25783835997946,
        '163': 32.25783835997946,
        '164': 32.25783835997946,
        '165': 32.25783835997946,
        '166': 32.25783835997946,
        '167': 32.25783835997946,
        '168': 32.25783835997946,
        '169': 32.25783835997946,
        '170': 32.25783835997946,
        '171': 32.25783835997946,
        '172': 32.25783835997946,
        '173': 32.25783835997946,
        '174': 32.25783835997946,
        '175': 32.25783835997946,
        '176': 32.25783835997946,
        '177': 32.25783835997946,
        '178': 32.25783835997946,
        '179': 32.25783835997946,
        '180': 32.25783835997946,
        '-180': 32.25783835997946,
        '-179': 32.25783835997946,
        '-178': 32.25783835997946,
        '-177': 32.25783835997946,
        '-176': 32.25783835997946,
        '-175': 32.25783835997946,
        '-174': 32.25783835997946,
        '-173': 32.25783835997946,
        '-172': 32.25783835997946,
        '-171': 32.25783835997946,
        '-170': 32.25783835997946,
        '-169': 32.25783835997946,
        '-168': 32.25783835997946,
        '-167': 32.25783835997946,
        '-166': 32.25783835997946,
        '-165': 32.25783835997946,
        '-164': 32.25783835997946,
        '-163': 32.25783835997946,
        '-162': 32.25783835997946,
        '-161': 32.25783835997946,
        '-160': 32.25783835997946,
        '-159': 32.25783835997946,
        '-158': 32.25783835997946,
        '-157': 32.25783835997946,
        '-156': 32.25783835997946,
        '-155': 32.25783835997946,
        '-154': 32.25783835997946,
        '-153': 32.25783835997946,
        '-152': 32.25783835997946,
        '-151': 32.25783835997946,
        '-150': 32.25783835997946,
        '-149': 32.25783835997946,
        '-148': 32.25783835997946,
        '-147': 32.25783835997946,
        '-146': 32.25783835997946,
        '-145': 32.25783835997946,
        '-144': 32.25783835997946,
        '-143': 32.25783835997946,
        '-142': 32.25783835997946,
        '-141': 32.25783835997946,
        '-140': 32.25783835997946,
        '-139': 32.25783835997946,
        '-138': 32.25783835997946,
        '-137': 32.25783835997946,
        '-136': 32.25783835997946,
        '-135': 32.25783835997946,
        '-134': 32.25783835997946,
        '-133': 32.25783835997946,
        '-132': 32.25783835997946,
        '-131': 32.25783835997946,
        '-130': 32.25783835997946,
        '-129': 32.25783835997946,
        '-128': 32.25783835997946,
        '-127': 32.25783835997946,
        '-126': 32.25783835997946,
        '-125': 32.25783835997946,
        '-124': 32.25783835997946,
        '-123': 32.25783835997946,
        '-122': 32.25783835997946,
        '-121': 32.25783835997946,
        '-120': 32.25783835997946,
        '-119': 32.25783835997946,
        '-118': 32.25783835997946,
        '-117': 32.25783835997946,
        '-116': 32.25783835997946,
        '-115': 32.25783835997946,
        '-114': 32.25783835997946,
        '-113': 32.25783835997946,
        '-112': 32.25783835997946,
        '-111': 32.25783835997946,
        '-110': 32.25783835997946,
        '-109': 32.25783835997946,
        '-108': 32.25783835997946,
        '-107': 32.25783835997946,
        '-106': 32.25783835997946,
        '-105': 32.25783835997946,
        '-104': 32.25783835997946,
        '-103': 32.25783835997946,
        '-102': 32.25783835997946,
        '-101': 32.25783835997946,
        '-100': 32.25783835997946,
        '-99': 32.25783835997946,
        '-98': 32.25783835997946,
        '-97': 32.25783835997946,
        '-96': 32.25783835997946,
        '-95': 32.25783835997946,
        '-94': 32.25783835997946,
        '-93': 32.25783835997946,
        '-92': 32.25783835997946,
        '-91': 32.25783835997946,
        '-90': 32.25783835997946,
        '-89': 32.25783835997946,
        '-88': 32.25783835997946,
        '-87': 32.25783835997946,
        '-86': 32.25783835997946,
        '-85': 32.25783835997946,
        '-84': 32.25783835997946,
        '-83': 32.25783835997946,
        '-82': 32.25783835997946,
        '-81': 32.25783835997946,
        '-80': 32.25783835997946,
        '-79': 32.25783835997946,
        '-78': 32.25783835997946,
        '-77': 32.25783835997946,
        '-76': 32.25783835997946,
        '-75': 32.25783835997946,
        '-74': 32.25783835997946,
        '-73': 32.25783835997946,
        '-72': 32.25783835997946,
        '-71': 32.25783835997946,
        '-70': 32.25783835997946,
        '-69': 32.25783835997946,
        '-68': 32.25783835997946,
        '-67': 32.25783835997946,
        '-66': 32.25783835997946,
        '-65': 32.25783835997946,
        '-64': 32.25783835997946,
        '-63': 32.25783835997946,
        '-62': 32.25783835997946,
        '-61': 32.25783835997946,
        '-60': 32.25783835997946,
        '-59': 32.25783835997946,
        '-58': 32.25783835997946,
        '-57': 32.25783835997946,
        '-56': 32.25783835997946,
        '-55': 32.25783835997946,
        '-54': 32.25783835997946,
        '-53': 32.25783835997946,
        '-52': 32.25783835997946,
        '-51': 32.25783835997946,
        '-50': 32.25783835997946,
        '-49': 32.25783835997946,
        '-48': 32.25783835997946,
        '-47': 32.25783835997946,
        '-46': 32.25783835997946,
        '-45': 32.25783835997946,
        '-44': 32.25783835997946,
        '-43': 32.25783835997946,
        '-42': 32.25783835997946,
        '-41': 32.25783835997946,
        '-40': 32.25783835997946,
        '-39': 32.25783835997946,
        '-38': 32.25783835997946,
        '-37': 32.25783835997946,
        '-36': 32.25783835997946,
        '-35': 32.25783835997946,
        '-34': 32.25783835997946,
        '-33': 32.25783835997946,
        '-32': 32.25783835997946,
        '-31': 32.25783835997946,
        '-30': 32.25783835997946,
        '-29': 32.25783835997946,
        '-28': 32.25783835997946,
        '-27': 32.25783835997946,
        '-26': 32.25783835997946,
        '-25': 32.25783835997946,
        '-24': 32.25783835997946,
        '-23': 32.25783835997946,
        '-22': 32.25783835997946,
        '-21': 32.25783835997946,
        '-20': 32.25783835997946,
        '-19': 32.25783835997946,
        '-18': 32.25783835997946,
        '-17': 32.25783835997946,
        '-16': 32.25783835997946,
        '-15': 32.25783835997946,
        '-14': 32.25783835997946,
        '-13': 32.25783835997946,
        '-12': 32.25783835997946,
        '-11': 32.25783835997946,
        '-10': 32.25783835997946,
        '-9': 32.25783835997946,
        '-8': 32.25783835997946,
        '-7': 32.25783835997946,
        '-6': 32.25783835997946,
        '-5': 32.25783835997946,
        '-4': 32.25783835997946,
        '-3': 32.25783835997946,
        '-2': 32.25783835997946,
        '-1': 32.25783835997946
    },
    '-1': {
        '0': 32.08782722612312,
        '1': 32.087873782280106,
        '2': 32.087893716952586,
        '3': 32.08802317970163,
        '4': 32.08828113023419,
        '5': 32.08841542740929,
        '6': 32.08831728349141,
        '7': 32.088224549562604,
        '8': 32.08820955697587,
        '9': 32.08784716959842,
        '10': 32.08735634006348,
        '11': 32.08418935259422,
        '12': 32.08156894076396,
        '13': 32.08462357858311,
        '14': 32.08334176374729,
        '15': 32.083474510844376,
        '16': 32.08386710311813,
        '17': 32.08390773288955,
        '18': 32.0834708052478,
        '19': 32.08269312868196,
        '20': 32.08285808381882,
        '21': 32.08229126119086,
        '22': 32.08198710219864,
        '23': 32.08242525683439,
        '24': 32.08170907666108,
        '25': 32.081694350587455,
        '26': 32.08144502281877,
        '27': 32.081351564516005,
        '28': 32.07926584972118,
        '29': 32.07029646141918,
        '30': 32.071263669246065,
        '31': 32.07449336739309,
        '32': 32.08338980426136,
        '33': 32.08314066720666,
        '34': 32.08327257166904,
        '35': 32.07001791961064,
        '36': 32.06686163608494,
        '37': 32.07310441721462,
        '38': 32.07644466632781,
        '39': 32.08428449259834,
        '40': 32.086051405830496,
        '41': 32.086296011100615,
        '42': 32.08713925129903,
        '43': 32.0867280739025,
        '44': 32.0864164028419,
        '45': 32.08655472833076,
        '46': 32.08677277732941,
        '47': 32.08669980148804,
        '48': 32.086642250822415,
        '49': 32.0869813407074,
        '50': 32.08742036630415,
        '51': 32.08747768349351,
        '52': 32.08729697798388,
        '53': 32.08731518676875,
        '54': 32.08749027464833,
        '55': 32.087492376232774,
        '56': 32.087354881388265,
        '57': 32.08734281147739,
        '58': 32.087419671583746,
        '59': 32.08735700933048,
        '60': 32.08716771588621,
        '61': 32.08702193748116,
        '62': 32.08693549364861,
        '63': 32.086860534301856,
        '64': 32.08687145460594,
        '65': 32.08700711340784,
        '66': 32.087134749710835,
        '67': 32.08714397760803,
        '68': 32.08706182271812,
        '69': 32.086889011596774,
        '70': 32.08659783041793,
        '71': 32.08635353877993,
        '72': 32.08639946084115,
        '73': 32.08662931040419,
        '74': 32.0866407456091,
        '75': 32.08634750187331,
        '76': 32.086135854091516,
        '77': 32.08620540788681,
        '78': 32.086204028957624,
        '79': 32.08587413475884,
        '80': 32.085619332272444,
        '81': 32.085893661678156,
        '82': 32.08638763891036,
        '83': 32.0864898152308,
        '84': 32.08628621158865,
        '85': 32.08633236473545,
        '86': 32.08663753451282,
        '87': 32.08674808014627,
        '88': 32.08670219936472,
        '89': 32.086937286804776,
        '90': 32.08732105993996,
        '91': 32.08731433602668,
        '92': 32.08708881358101,
        '93': 32.08738807080333,
        '94': 32.088200397848674,
        '95': 32.0886485485269,
        '96': 32.08833507037549,
        '97': 32.08781957923134,
        '98': 32.08766823898067,
        '99': 32.08791757667484,
        '100': 32.08846536678127,
        '101': 32.07711155117393,
        '102': 32.0882759202572,
        '103': 32.08892329633319,
        '104': 32.088460459879755,
        '105': 32.08845611913491,
        '106': 32.088846721235846,
        '107': 32.08900822129053,
        '108': 32.08876243802195,
        '109': 32.08850740799916,
        '110': 32.08849063409867,
        '111': 32.0873084786982,
        '112': 32.087412201669494,
        '113': 32.088213463154624,
        '114': 32.087860548475085,
        '115': 32.08852311231783,
        '116': 32.087533100431656,
        '117': 32.08848780038436,
        '118': 32.08818613251427,
        '119': 32.08847928238045,
        '120': 32.0830830226273,
        '121': 32.09025487826327,
        '122': 32.085577720245304,
        '123': 32.087977625400356,
        '124': 32.086144768863406,
        '125': 32.08486640038002,
        '126': 32.08512821474982,
        '127': 32.087277038080735,
        '128': 32.08973682535288,
        '129': 32.09042326110097,
        '130': 32.089563880183675,
        '131': 32.08888528457631,
        '132': 32.0881012832,
        '133': 32.080577769675855,
        '134': 32.087069046710916,
        '135': 32.08845921774562,
        '136': 32.08694517738749,
        '137': 32.087913343596846,
        '138': 32.08733720258695,
        '139': 32.087192557458856,
        '140': 32.08767654827023,
        '141': 32.08813861632704,
        '142': 32.08830941692456,
        '143': 32.08849973488162,
        '144': 32.08870335933648,
        '145': 32.08866048173732,
        '146': 32.088500737157666,
        '147': 32.088410372196186,
        '148': 32.088182654946955,
        '149': 32.08778358860301,
        '150': 32.08762553459033,
        '151': 32.08776471109331,
        '152': 32.08772013100276,
        '153': 32.0874469852174,
        '154': 32.08749285142822,
        '155': 32.08795914102392,
        '156': 32.088315856812336,
        '157': 32.08832004916402,
        '158': 32.08816703212472,
        '159': 32.08787989964942,
        '160': 32.08746595694832,
        '161': 32.08732445994745,
        '162': 32.087623840521985,
        '163': 32.08780577953633,
        '164': 32.08751062535129,
        '165': 32.087278303826054,
        '166': 32.087566784960295,
        '167': 32.08791937074187,
        '168': 32.08784160416795,
        '169': 32.08762007857594,
        '170': 32.0876109267583,
        '171': 32.08759897626542,
        '172': 32.08742117985734,
        '173': 32.08737927147217,
        '174': 32.08759514418975,
        '175': 32.087728104590646,
        '176': 32.087620217475205,
        '177': 32.08753020632067,
        '178': 32.087587293283626,
        '179': 32.087623249469885,
        '180': 32.08756924098671,
        '-180': 32.08756924098671,
        '-179': 32.087524059616435,
        '-178': 32.087511440572136,
        '-177': 32.08752427552576,
        '-176': 32.08761784817952,
        '-175': 32.08773670574791,
        '-174': 32.087727025837935,
        '-173': 32.08762170286277,
        '-172': 32.08758931859177,
        '-171': 32.08762495225204,
        '-170': 32.08763079166233,
        '-169': 32.08765499735863,
        '-168': 32.087723888001186,
        '-167': 32.08769901463994,
        '-166': 32.08758126877836,
        '-165': 32.08758840118513,
        '-164': 32.08772655009652,
        '-163': 32.08775739284504,
        '-162': 32.08770143100418,
        '-161': 32.08780317743874,
        '-160': 32.08798071864581,
        '-159': 32.08795655836535,
        '-158': 32.08785556321145,
        '-157': 32.08798312282626,
        '-156': 32.08819206954712,
        '-155': 32.08817342291452,
        '-154': 32.08808210883166,
        '-153': 32.088179474861555,
        '-152': 32.08826171892799,
        '-151': 32.088113718017304,
        '-150': 32.088020903057895,
        '-149': 32.0882039716998,
        '-148': 32.08834156542565,
        '-147': 32.08821294790902,
        '-146': 32.08813863901685,
        '-145': 32.08829924612866,
        '-144': 32.088353430988114,
        '-143': 32.08814140108788,
        '-142': 32.088009521906,
        '-141': 32.08811930393494,
        '-140': 32.08818287354804,
        '-139': 32.088087728460714,
        '-138': 32.088057589225855,
        '-137': 32.088107546624855,
        '-136': 32.08802782037152,
        '-135': 32.087881665140834,
        '-134': 32.08789275157761,
        '-133': 32.08797691604634,
        '-132': 32.08793456248061,
        '-131': 32.087858809908724,
        '-130': 32.08788511357141,
        '-129': 32.08787928791017,
        '-128': 32.08775591537797,
        '-127': 32.087693452271445,
        '-126': 32.08775022707403,
        '-125': 32.08773126915992,
        '-124': 32.08761158852063,
        '-123': 32.08758868275085,
        '-122': 32.08764442327308,
        '-121': 32.08757815701127,
        '-120': 32.0874479352813,
        '-119': 32.087459293857926,
        '-118': 32.087535948598635,
        '-117': 32.08749661170713,
        '-116': 32.08744956660782,
        '-115': 32.087551601194605,
        '-114': 32.087655646145755,
        '-113': 32.08760529199903,
        '-112': 32.08754505793382,
        '-111': 32.08760551125133,
        '-110': 32.08765740027563,
        '-109': 32.08762165164491,
        '-108': 32.087616190669,
        '-107': 32.087654932973855,
        '-106': 32.0876258117902,
        '-105': 32.08758827376139,
        '-104': 32.08765390603083,
        '-103': 32.087681159862214,
        '-102': 32.08754472227698,
        '-101': 32.087468747104374,
        '-100': 32.087616422645056,
        '-99': 32.08771124958223,
        '-98': 32.08756160978466,
        '-97': 32.08747950928653,
        '-96': 32.08765690631508,
        '-95': 32.08771836032538,
        '-94': 32.08745425771616,
        '-93': 32.08732379927618,
        '-92': 32.0876338330966,
        '-91': 32.087947937926764,
        '-90': 32.08790667936339,
        '-89': 32.08782596024898,
        '-88': 32.08789579148282,
        '-87': 32.08773127889694,
        '-86': 32.08735350821951,
        '-85': 32.087507622180816,
        '-84': 32.08824129576169,
        '-83': 32.088442462384634,
        '-82': 32.08780408191076,
        '-81': 32.08784620171996,
        '-80': 32.08779769590131,
        '-79': 32.06152540243294,
        '-78': 32.078697280631175,
        '-77': 32.08489901080636,
        '-76': 32.084683069959276,
        '-75': 32.08582528025412,
        '-74': 32.08751947378624,
        '-73': 32.08757362107496,
        '-72': 32.087119690507954,
        '-71': 32.086546230296825,
        '-70': 32.08760508364956,
        '-69': 32.087334642336685,
        '-68': 32.08737411757494,
        '-67': 32.08733305359581,
        '-66': 32.08727028066445,
        '-65': 32.087243558667666,
        '-64': 32.08730645835447,
        '-63': 32.08727769690586,
        '-62': 32.08723941567657,
        '-61': 32.08704612284523,
        '-60': 32.08544800938443,
        '-59': 32.085583878078936,
        '-58': 32.08516705204282,
        '-57': 32.085867465560796,
        '-56': 32.08546025400435,
        '-55': 32.084472843255256,
        '-54': 32.08251779398531,
        '-53': 32.086217941688254,
        '-52': 32.08731122994014,
        '-51': 32.08766455737579,
        '-50': 32.08732122332863,
        '-49': 32.08724601466092,
        '-48': 32.087271783447456,
        '-47': 32.08786167511234,
        '-46': 32.08810616203301,
        '-45': 32.08796808717107,
        '-44': 32.0876841462168,
        '-43': 32.087335066892344,
        '-42': 32.087079943665756,
        '-41': 32.087065022934596,
        '-40': 32.087144652829735,
        '-39': 32.08709606040424,
        '-38': 32.0870230408059,
        '-37': 32.0871518208913,
        '-36': 32.08739868632179,
        '-35': 32.087524371610556,
        '-34': 32.087514864784325,
        '-33': 32.087489689544604,
        '-32': 32.0874740191334,
        '-31': 32.0875037670937,
        '-30': 32.08767565281475,
        '-29': 32.087925046873536,
        '-28': 32.08805287360611,
        '-27': 32.0880476979186,
        '-26': 32.088061728452004,
        '-25': 32.08808683355462,
        '-24': 32.08800598727078,
        '-23': 32.087886814613945,
        '-22': 32.08786870558825,
        '-21': 32.08788727122795,
        '-20': 32.08784620160578,
        '-19': 32.08788171720153,
        '-18': 32.08813256889973,
        '-17': 32.08843939835749,
        '-16': 32.08855321053484,
        '-15': 32.088460490588474,
        '-14': 32.08830569658888,
        '-13': 32.088182678844376,
        '-12': 32.08812547186805,
        '-11': 32.0881248690867,
        '-10': 32.088107349372414,
        '-9': 32.088038534583866,
        '-8': 32.087984320111595,
        '-7': 32.08795044649354,
        '-6': 32.08783185674635,
        '-5': 32.08764054645211,
        '-4': 32.08755074497456,
        '-3': 32.087617203822916,
        '-2': 32.08770884737437,
        '-1': 32.087763824471686
    },
    '-2': {
        '0': 32.08827715283643,
        '1': 32.088402826301355,
        '2': 32.08839863432616,
        '3': 32.088372135220986,
        '4': 32.08839316450513,
        '5': 32.088288587319596,
        '6': 32.088031534294515,
        '7': 32.087929454425286,
        '8': 32.08804925387678,
        '9': 32.08798472597565,
        '10': 32.0871637017541,
        '11': 32.08642166054723,
        '12': 32.08085148166,
        '13': 32.082083844328665,
        '14': 32.08420246044939,
        '15': 32.08367281999558,
        '16': 32.084260340823235,
        '17': 32.08344350950561,
        '18': 32.08361183465003,
        '19': 32.083463616032645,
        '20': 32.082587650057384,
        '21': 32.082149523704516,
        '22': 32.08147320964478,
        '23': 32.08240199912241,
        '24': 32.081558534854786,
        '25': 32.0816128477383,
        '26': 32.08137990145044,
        '27': 32.07936538382184,
        '28': 32.0769422453428,
        '29': 32.07459345001365,
        '30': 32.07511918059075,
        '31': 32.0739531620514,
        '32': 32.083492151630196,
        '33': 32.075477130828304,
        '34': 32.07410122741463,
        '35': 32.07176449132168,
        '36': 32.07989111470328,
        '37': 32.07298117334486,
        '38': 32.079337813999544,
        '39': 32.08517973954791,
        '40': 32.08715701036949,
        '41': 32.087262637646454,
        '42': 32.0870815800046,
        '43': 32.08676225214318,
        '44': 32.086687632529085,
        '45': 32.08691204690969,
        '46': 32.08707109767643,
        '47': 32.08693603100815,
        '48': 32.086822787940534,
        '49': 32.087079834055956,
        '50': 32.087482720580724,
        '51': 32.08759808579156,
        '52': 32.08747552818397,
        '53': 32.08746152907246,
        '54': 32.0875521653264,
        '55': 32.087478004867755,
        '56': 32.087278546313,
        '57': 32.087266779871555,
        '58': 32.087495538020036,
        '59': 32.08768470826535,
        '60': 32.08762959267274,
        '61': 32.08737695580774,
        '62': 32.08708197861409,
        '63': 32.086911088876036,
        '64': 32.08696383989442,
        '65': 32.08714456490073,
        '66': 32.087260143013545,
        '67': 32.08728477182335,
        '68': 32.087295959088856,
        '69': 32.08718953655783,
        '70': 32.08683348287225,
        '71': 32.08647976163556,
        '72': 32.08653201708034,
        '73': 32.086853362923485,
        '74': 32.08686372529685,
        '75': 32.08646019616224,
        '76': 32.08621710433504,
        '77': 32.08641066951502,
        '78': 32.086522267236106,
        '79': 32.08616997933074,
        '80': 32.08588040847232,
        '81': 32.08625571776838,
        '82': 32.08689715803104,
        '83': 32.086986339038376,
        '84': 32.08657837973929,
        '85': 32.08642464840791,
        '86': 32.086736289639454,
        '87': 32.08704226243791,
        '88': 32.08715076590015,
        '89': 32.08732923433813,
        '90': 32.087569765739566,
        '91': 32.087561121698535,
        '92': 32.087404347398085,
        '93': 32.08755863420714,
        '94': 32.088068705481874,
        '95': 32.088512196387484,
        '96': 32.08860734704364,
        '97': 32.08831817606751,
        '98': 32.08769606535434,
        '99': 32.08720106812543,
        '100': 32.087632932449864,
        '101': 32.08757178959155,
        '102': 32.08821644633843,
        '103': 32.089518420161575,
        '104': 32.08885266561142,
        '105': 32.08854751558237,
        '106': 32.0885769567559,
        '107': 32.08906486443282,
        '108': 32.08910850860133,
        '109': 32.08907695721098,
        '110': 32.089072400634215,
        '111': 32.08802968480685,
        '112': 32.08791280712508,
        '113': 32.08841848319427,
        '114': 32.08825335675567,
        '115': 32.08870489891491,
        '116': 32.08835284536549,
        '117': 32.0888068587843,
        '118': 32.08832171072757,
        '119': 32.08874871439442,
        '120': 32.07123378233103,
        '121': 32.0871349813809,
        '122': 32.08943640749397,
        '123': 32.08838121306608,
        '124': 32.088056780157274,
        '125': 32.088060024750575,
        '126': 32.08790350893714,
        '127': 32.08790149716581,
        '128': 32.08818348409669,
        '129': 32.088306827362146,
        '130': 32.08766048224837,
        '131': 32.08899988550947,
        '132': 32.089885261130675,
        '133': 32.08964778375517,
        '134': 32.087835028998974,
        '135': 32.088587990324605,
        '136': 32.08889582731846,
        '137': 32.08908762539691,
        '138': 32.08792038920803,
        '139': 32.08769170950738,
        '140': 32.087778693302155,
        '141': 32.0879557359742,
        '142': 32.088354254171506,
        '143': 32.088925776255095,
        '144': 32.089492961487544,
        '145': 32.08992742886893,
        '146': 32.09022476758776,
        '147': 32.08923347938964,
        '148': 32.08931715632534,
        '149': 32.08832150475136,
        '150': 32.08798780840939,
        '151': 32.08825113625614,
        '152': 32.08832553464735,
        '153': 32.08810220560512,
        '154': 32.088127702418326,
        '155': 32.088382695489294,
        '156': 32.08837383092031,
        '157': 32.088180012572195,
        '158': 32.08817105321956,
        '159': 32.088100324075235,
        '160': 32.08765941014474,
        '161': 32.08734633448812,
        '162': 32.087630177723284,
        '163': 32.087970584050915,
        '164': 32.087765691462394,
        '165': 32.08745086408424,
        '166': 32.08762022466428,
        '167': 32.08794265034327,
        '168': 32.08790810304359,
        '169': 32.08775412062439,
        '170': 32.08781146831732,
        '171': 32.08781401331704,
        '172': 32.08756125191165,
        '173': 32.087417225913555,
        '174': 32.087597754867744,
        '175': 32.08775484262342,
        '176': 32.087663312608335,
        '177': 32.08758477963446,
        '178': 32.08769060146717,
        '179': 32.087778796924226,
        '180': 32.087715679456906,
        '-180': 32.087715679456906,
        '-179': 32.08762639791296,
        '-178': 32.08760656568722,
        '-177': 32.08763939786806,
        '-176': 32.0877290383403,
        '-175': 32.08784753184169,
        '-174': 32.08789825333752,
        '-173': 32.08787165406678,
        '-172': 32.08784229860271,
        '-171': 32.087811730758176,
        '-170': 32.08777944013788,
        '-169': 32.08783562954129,
        '-168': 32.08794882493909,
        '-167': 32.0879153912556,
        '-166': 32.08773634074716,
        '-165': 32.08768004571133,
        '-164': 32.08779612825473,
        '-163': 32.087835692371506,
        '-162': 32.08777868733357,
        '-161': 32.08788058504028,
        '-160': 32.08811246053005,
        '-159': 32.08816427878351,
        '-158': 32.08804064718135,
        '-157': 32.08804465333712,
        '-156': 32.08820643481746,
        '-155': 32.08830433878075,
        '-154': 32.088322294895214,
        '-153': 32.08836094050985,
        '-152': 32.08833201563265,
        '-151': 32.088200015081,
        '-150': 32.0881821868282,
        '-149': 32.088332413341924,
        '-148': 32.08838139387027,
        '-147': 32.08827301968986,
        '-146': 32.08828946075289,
        '-145': 32.08844003071785,
        '-144': 32.08839282664968,
        '-143': 32.088156229607144,
        '-142': 32.088110299909715,
        '-141': 32.088283734659754,
        '-140': 32.08832649741602,
        '-139': 32.088195721195696,
        '-138': 32.088157649199985,
        '-137': 32.08820256026689,
        '-136': 32.08813157406875,
        '-135': 32.08804186658497,
        '-134': 32.08811410772539,
        '-133': 32.08819107645504,
        '-132': 32.08809702610032,
        '-131': 32.08800787009722,
        '-130': 32.088062141612,
        '-129': 32.08806105109797,
        '-128': 32.08791179330255,
        '-127': 32.087843499960975,
        '-126': 32.087916074177336,
        '-125': 32.087887807925455,
        '-124': 32.08774135471465,
        '-123': 32.0877346894381,
        '-122': 32.087852557488844,
        '-121': 32.0878272806891,
        '-120': 32.087691467204245,
        '-119': 32.087695833910665,
        '-118': 32.087777170544385,
        '-117': 32.087703766659125,
        '-116': 32.08757889465839,
        '-115': 32.08764093895224,
        '-114': 32.08778779396108,
        '-113': 32.087795771422655,
        '-112': 32.087739368846066,
        '-111': 32.087777263850775,
        '-110': 32.08783106163924,
        '-109': 32.08780144286573,
        '-108': 32.087771008741626,
        '-107': 32.08777974288527,
        '-106': 32.08775071238105,
        '-105': 32.08772516955519,
        '-104': 32.08779626193806,
        '-103': 32.08785039820147,
        '-102': 32.08777174874315,
        '-101': 32.0877326741248,
        '-100': 32.08785742555198,
        '-99': 32.08789497812872,
        '-98': 32.08768607342815,
        '-97': 32.08754919866377,
        '-96': 32.087707144866656,
        '-95': 32.08782687939003,
        '-94': 32.08765195446714,
        '-93': 32.08751689797325,
        '-92': 32.08769328210109,
        '-91': 32.08786630230627,
        '-90': 32.08779155287009,
        '-89': 32.08774889038451,
        '-88': 32.087852008936004,
        '-87': 32.0877724963357,
        '-86': 32.08761420515849,
        '-85': 32.08796371593008,
        '-84': 32.08855107964841,
        '-83': 32.08826918569483,
        '-82': 32.08728015145917,
        '-81': 32.08740574210741,
        '-80': 32.08916007159388,
        '-79': 32.06673342885017,
        '-78': 32.07919109122415,
        '-77': 32.08404160343232,
        '-76': 32.08529709450276,
        '-75': 32.087325944641094,
        '-74': 32.08803756579692,
        '-73': 32.087290818217056,
        '-72': 32.08721310362849,
        '-71': 32.08766490828941,
        '-70': 32.087697857161494,
        '-69': 32.087163858227456,
        '-68': 32.08675038570629,
        '-67': 32.08721371479041,
        '-66': 32.08770876314505,
        '-65': 32.08742041602443,
        '-64': 32.08733021873286,
        '-63': 32.08725709945336,
        '-62': 32.08740844867,
        '-61': 32.086621672531116,
        '-60': 32.08563912316218,
        '-59': 32.086678495671805,
        '-58': 32.0864065471249,
        '-57': 32.08687239324522,
        '-56': 32.08765216664302,
        '-55': 32.08796394388999,
        '-54': 32.087879625431775,
        '-53': 32.0873050960925,
        '-52': 32.087203474812725,
        '-51': 32.08751639694759,
        '-50': 32.087342319545606,
        '-49': 32.08726346689844,
        '-48': 32.08709092234945,
        '-47': 32.08692653200178,
        '-46': 32.086923452123834,
        '-45': 32.08688612915256,
        '-44': 32.08754776951776,
        '-43': 32.08774899590216,
        '-42': 32.087685384746905,
        '-41': 32.08763546621309,
        '-40': 32.0877355163001,
        '-39': 32.08772170177052,
        '-38': 32.08749298170738,
        '-37': 32.08733442088368,
        '-36': 32.08742860526833,
        '-35': 32.08762243428206,
        '-34': 32.08773537599025,
        '-33': 32.08772771535321,
        '-32': 32.087609760334146,
        '-31': 32.08746867878937,
        '-30': 32.087459915780414,
        '-29': 32.08758393473594,
        '-28': 32.08766889682896,
        '-27': 32.08766380961634,
        '-26': 32.08767472410259,
        '-25': 32.087693823785244,
        '-24': 32.08762479081554,
        '-23': 32.08755002025487,
        '-22': 32.08762653152678,
        '-21': 32.08778421681177,
        '-20': 32.0878526529835,
        '-19': 32.08786692198916,
        '-18': 32.08794913115714,
        '-17': 32.088052835696594,
        '-16': 32.08809457987685,
        '-15': 32.08814986108332,
        '-14': 32.088290197427526,
        '-13': 32.0884136452326,
        '-12': 32.088404787276204,
        '-11': 32.0882927748933,
        '-10': 32.08816323377247,
        '-9': 32.08806403618604,
        '-8': 32.0880071062398,
        '-7': 32.087947221503775,
        '-6': 32.087830386531195,
        '-5': 32.087730363874066,
        '-4': 32.08777781291598,
        '-3': 32.087930554288306,
        '-2': 32.08804243337712,
        '-1': 32.088128149627025
    },
    '-3': {
        '0': 32.08864878279718,
        '1': 32.08876198093292,
        '2': 32.088665165786686,
        '3': 32.0884422896694,
        '4': 32.088251284334945,
        '5': 32.08804964430449,
        '6': 32.08781599612131,
        '7': 32.08773952167163,
        '8': 32.08784144963859,
        '9': 32.08779304820403,
        '10': 32.08748410715327,
        '11': 32.0860285578147,
        '12': 32.08558668004428,
        '13': 32.083380440571936,
        '14': 32.08245271596544,
        '15': 32.083089038113116,
        '16': 32.08353011191275,
        '17': 32.08440550343413,
        '18': 32.08340595660156,
        '19': 32.08329831779838,
        '20': 32.082932406734564,
        '21': 32.08228561616539,
        '22': 32.08165375855457,
        '23': 32.08164378682635,
        '24': 32.08142719597282,
        '25': 32.08192336524165,
        '26': 32.08187007694438,
        '27': 32.081585726930506,
        '28': 32.07679185556644,
        '29': 32.07435239882477,
        '30': 32.07296104714646,
        '31': 32.073396243001106,
        '32': 32.07464296299736,
        '33': 32.07523688467774,
        '34': 32.07403786002358,
        '35': 32.071840963709825,
        '36': 32.07999953777842,
        '37': 32.075208950831275,
        '38': 32.08131029013119,
        '39': 32.08576031456421,
        '40': 32.08690101087298,
        '41': 32.087550650176325,
        '42': 32.08724261492478,
        '43': 32.08706760699204,
        '44': 32.08711201374244,
        '45': 32.08728256674984,
        '46': 32.08735338451156,
        '47': 32.08724086825329,
        '48': 32.087159773864435,
        '49': 32.08733061828565,
        '50': 32.087618603847666,
        '51': 32.08773471906808,
        '52': 32.08771495422105,
        '53': 32.08784038313515,
        '54': 32.08811209228038,
        '55': 32.088204141241015,
        '56': 32.08798231217694,
        '57': 32.08771035763765,
        '58': 32.087653680625145,
        '59': 32.087754663760386,
        '60': 32.08778794581574,
        '61': 32.08765177407288,
        '62': 32.08744340166647,
        '63': 32.08733255148962,
        '64': 32.087371386545584,
        '65': 32.08744309398743,
        '66': 32.087464346836974,
        '67': 32.087525990763766,
        '68': 32.087645792102805,
        '69': 32.08756263106367,
        '70': 32.08712951104888,
        '71': 32.08674098349322,
        '72': 32.086863749158816,
        '73': 32.087241745336996,
        '74': 32.08719360934791,
        '75': 32.08670157297196,
        '76': 32.08647088713716,
        '77': 32.08674911261319,
        '78': 32.086895346777546,
        '79': 32.086508236643006,
        '80': 32.08619588431134,
        '81': 32.08659799592064,
        '82': 32.08727874301322,
        '83': 32.087371705538196,
        '84': 32.086895547196534,
        '85': 32.08662339918464,
        '86': 32.08690714276474,
        '87': 32.08738436700824,
        '88': 32.087691442383324,
        '89': 32.087802395955045,
        '90': 32.08777622682135,
        '91': 32.08770769181058,
        '92': 32.087787826760746,
        '93': 32.088031677424716,
        '94': 32.08822378103082,
        '95': 32.08837821737915,
        '96': 32.08870974539896,
        '97': 32.08891894413045,
        '98': 32.08836816491612,
        '99': 32.087381137621264,
        '100': 32.087327145039836,
        '101': 32.0887395748303,
        '102': 32.084957577281585,
        '103': 32.090374756085936,
        '104': 32.08991220909543,
        '105': 32.089351668367264,
        '106': 32.089083180931276,
        '107': 32.089014577304766,
        '108': 32.088592535522245,
        '109': 32.089450221212374,
        '110': 32.08974809350225,
        '111': 32.08960125699068,
        '112': 32.08913473148326,
        '113': 32.089033314990196,
        '114': 32.0891940107073,
        '115': 32.08969669613238,
        '116': 32.08977298382678,
        '117': 32.08948541569415,
        '118': 32.08894939764943,
        '119': 32.07975209620335,
        '120': 32.07971131174588,
        '121': 32.09053568695927,
        '122': 32.0830739755511,
        '123': 32.089120125279145,
        '124': 32.0898741890075,
        '125': 32.09071554802673,
        '126': 32.09027000912339,
        '127': 32.08894102442674,
        '128': 32.087942725139584,
        '129': 32.08232252979226,
        '130': 32.087769585651884,
        '131': 32.088512942179655,
        '132': 32.08920847508488,
        '133': 32.088555963237596,
        '134': 32.08730314129616,
        '135': 32.08892115653681,
        '136': 32.08956255439067,
        '137': 32.08810982303157,
        '138': 32.08998680694325,
        '139': 32.08883677891194,
        '140': 32.08657911699856,
        '141': 32.08751586450929,
        '142': 32.08891456558957,
        '143': 32.089292065127374,
        '144': 32.08935862678179,
        '145': 32.0895245857375,
        '146': 32.090099788717694,
        '147': 32.090633554957904,
        '148': 32.090532343672244,
        '149': 32.090147039730105,
        '150': 32.090128094991634,
        '151': 32.090118522451476,
        '152': 32.08944227978648,
        '153': 32.08860586091412,
        '154': 32.088572582412034,
        '155': 32.08902894716832,
        '156': 32.0889766218086,
        '157': 32.08852295928228,
        '158': 32.08846118945817,
        '159': 32.08864098216988,
        '160': 32.08837515274442,
        '161': 32.08790045347689,
        '162': 32.087907154259035,
        '163': 32.088185704128094,
        '164': 32.088108097276304,
        '165': 32.08784168241693,
        '166': 32.08788746615417,
        '167': 32.08807391157063,
        '168': 32.0880325008629,
        '169': 32.0879696053107,
        '170': 32.08811990273564,
        '171': 32.088169598934,
        '172': 32.08790302548121,
        '173': 32.087678917207235,
        '174': 32.087750048066646,
        '175': 32.08784473996216,
        '176': 32.0877821910878,
        '177': 32.08778378468711,
        '178': 32.08793302154642,
        '179': 32.08798988202493,
        '180': 32.08788219704333,
        '-180': 32.08788219704332,
        '-179': 32.08781478175698,
        '-178': 32.087855214667506,
        '-177': 32.08787598882549,
        '-176': 32.0878571535436,
        '-175': 32.08788849043334,
        '-174': 32.08797402749777,
        '-173': 32.08805987845411,
        '-172': 32.08811293099858,
        '-171': 32.08809050910511,
        '-170': 32.08801291046003,
        '-169': 32.08801569204547,
        '-168': 32.08813045862109,
        '-167': 32.088187105319314,
        '-166': 32.088131971969105,
        '-165': 32.088149238943906,
        '-164': 32.08827089228424,
        '-163': 32.08826167570397,
        '-162': 32.08808895734824,
        '-161': 32.08805231822012,
        '-160': 32.088259601579985,
        '-159': 32.08844336866669,
        '-158': 32.08844523336776,
        '-157': 32.088423656142616,
        '-156': 32.088488830456356,
        '-155': 32.088554867734224,
        '-154': 32.08858264715217,
        '-153': 32.08861472951656,
        '-152': 32.08862616393058,
        '-151': 32.08860799424477,
        '-150': 32.088637635239884,
        '-149': 32.0886868330897,
        '-148': 32.088627008862346,
        '-147': 32.08851931576788,
        '-146': 32.08853738025859,
        '-145': 32.088595361792656,
        '-144': 32.08849680880118,
        '-143': 32.0883731325345,
        '-142': 32.08846173413029,
        '-141': 32.08860302669429,
        '-140': 32.08851045260851,
        '-139': 32.08831744503839,
        '-138': 32.08829854210277,
        '-137': 32.08834553560979,
        '-136': 32.088263939389876,
        '-135': 32.08821557869864,
        '-134': 32.08835032326217,
        '-133': 32.08843391730655,
        '-132': 32.08830666660556,
        '-131': 32.088220497660245,
        '-130': 32.08832611869569,
        '-129': 32.08837163390734,
        '-128': 32.08823087455331,
        '-127': 32.08814417207115,
        '-126': 32.088194752244334,
        '-125': 32.08815850166339,
        '-124': 32.088025892311464,
        '-123': 32.08804517576685,
        '-122': 32.088180185678254,
        '-121': 32.08815223529074,
        '-120': 32.08800282837917,
        '-119': 32.088001171096664,
        '-118': 32.08809183688489,
        '-117': 32.08802902371116,
        '-116': 32.08789738051013,
        '-115': 32.0879404975426,
        '-114': 32.088079101168574,
        '-113': 32.088095949695386,
        '-112': 32.088049216785365,
        '-111': 32.088082855666684,
        '-110': 32.0881173114396,
        '-109': 32.08806629735706,
        '-108': 32.08803992780368,
        '-107': 32.088086743013974,
        '-106': 32.088080341382465,
        '-105': 32.0880109738454,
        '-104': 32.088018995266644,
        '-103': 32.08808331777867,
        '-102': 32.088064376447775,
        '-101': 32.08801948879351,
        '-100': 32.08807486900693,
        '-99': 32.08811036999201,
        '-98': 32.08798291409537,
        '-97': 32.08785801609846,
        '-96': 32.08790717062658,
        '-95': 32.08795694984195,
        '-94': 32.08784173472935,
        '-93': 32.087745448382194,
        '-92': 32.08780581119899,
        '-91': 32.08782072411713,
        '-90': 32.08770671492818,
        '-89': 32.08771164868821,
        '-88': 32.08786231277451,
        '-87': 32.087877893436556,
        '-86': 32.08787538038938,
        '-85': 32.0882389142836,
        '-84': 32.08855667486624,
        '-83': 32.08798094631556,
        '-82': 32.08704831007562,
        '-81': 32.08745455922326,
        '-80': 32.08920483232195,
        '-79': 32.06137876777811,
        '-78': 32.082057929272494,
        '-77': 32.084317091286046,
        '-76': 32.085391562673856,
        '-75': 32.08749539225254,
        '-74': 32.08820522755909,
        '-73': 32.08721518419918,
        '-72': 32.08706021627178,
        '-71': 32.088028120416844,
        '-70': 32.08818937898839,
        '-69': 32.087421561931194,
        '-68': 32.08653739974754,
        '-67': 32.087623041873194,
        '-66': 32.08801768212074,
        '-65': 32.08767919559366,
        '-64': 32.08703284133649,
        '-63': 32.087012188011485,
        '-62': 32.08725914470887,
        '-61': 32.08690374210023,
        '-60': 32.087226059358414,
        '-59': 32.0878069495447,
        '-58': 32.087936574118096,
        '-57': 32.08744504387914,
        '-56': 32.08769164126935,
        '-55': 32.08644651970933,
        '-54': 32.085939718759995,
        '-53': 32.08635162991448,
        '-52': 32.086874334325294,
        '-51': 32.087045218266915,
        '-50': 32.08715638139591,
        '-49': 32.08711665063375,
        '-48': 32.086677443447584,
        '-47': 32.08621095412427,
        '-46': 32.08594821258361,
        '-45': 32.08654534956469,
        '-44': 32.086704450442625,
        '-43': 32.08729480998813,
        '-42': 32.08760145413039,
        '-41': 32.08797482908435,
        '-40': 32.088079603038096,
        '-39': 32.08860803428852,
        '-38': 32.08829954329372,
        '-37': 32.08787302377662,
        '-36': 32.087709501073206,
        '-35': 32.08777498221606,
        '-34': 32.08789013674166,
        '-33': 32.087981460904274,
        '-32': 32.08798560399363,
        '-31': 32.087873710317304,
        '-30': 32.08775503087115,
        '-29': 32.087714104061185,
        '-28': 32.087684094630546,
        '-27': 32.08763806473697,
        '-26': 32.08766390762216,
        '-25': 32.087742646334156,
        '-24': 32.08774303295951,
        '-23': 32.08769027435805,
        '-22': 32.08773673493476,
        '-21': 32.08787378066833,
        '-20': 32.08796835840055,
        '-19': 32.088012055713946,
        '-18': 32.08805956812133,
        '-17': 32.0880622395369,
        '-16': 32.088019742165834,
        '-15': 32.088103473675204,
        '-14': 32.088384885688676,
        '-13': 32.08864504377941,
        '-12': 32.088661545844296,
        '-11': 32.08849430986223,
        '-10': 32.08834371438967,
        '-9': 32.088302847533775,
        '-8': 32.08833451090776,
        '-7': 32.08835282194727,
        '-6': 32.08830645342369,
        '-5': 32.088255328951185,
        '-4': 32.08829433785372,
        '-3': 32.088386456204624,
        '-2': 32.0884367712427,
        '-1': 32.088496868124096
    },
    '-4': {
        '0': 32.08889465901118,
        '1': 32.0888610459806,
        '2': 32.088649934644806,
        '3': 32.08836842766965,
        '4': 32.088188536534645,
        '5': 32.08811256832237,
        '6': 32.08804509147373,
        '7': 32.087979546658595,
        '8': 32.087921719252165,
        '9': 32.08780128985067,
        '10': 32.0876312131763,
        '11': 32.08754835284907,
        '12': 32.08553338198362,
        '13': 32.08606249678875,
        '14': 32.08378988159752,
        '15': 32.08406992038395,
        '16': 32.08454443372385,
        '17': 32.08360398150769,
        '18': 32.08359199498831,
        '19': 32.08364366476871,
        '20': 32.08304519701444,
        '21': 32.08412796437079,
        '22': 32.08302501310518,
        '23': 32.08226757414402,
        '24': 32.081681850651584,
        '25': 32.08196434053216,
        '26': 32.08244727821912,
        '27': 32.08112796092618,
        '28': 32.081059514743835,
        '29': 32.07193117335339,
        '30': 32.07550463321042,
        '31': 32.075419155071415,
        '32': 32.075984533467214,
        '33': 32.076314847435164,
        '34': 32.076999183296174,
        '35': 32.07501865287316,
        '36': 32.07647184975055,
        '37': 32.07751015830677,
        '38': 32.08037461833319,
        '39': 32.08478157822651,
        '40': 32.088017271136685,
        '41': 32.087705613315094,
        '42': 32.08752047324019,
        '43': 32.08749362964969,
        '44': 32.08755895080933,
        '45': 32.08764337438145,
        '46': 32.087725024227076,
        '47': 32.08778819224592,
        '48': 32.08785093168357,
        '49': 32.08797367603028,
        '50': 32.0881219565282,
        '51': 32.0881711994524,
        '52': 32.08817461305428,
        '53': 32.088394247544514,
        '54': 32.088879076161895,
        '55': 32.08925006003703,
        '56': 32.089137814468494,
        '57': 32.08865273219643,
        '58': 32.08820623029639,
        '59': 32.088013548329606,
        '60': 32.08797825705869,
        '61': 32.08796357705625,
        '62': 32.08796001153456,
        '63': 32.08797951406729,
        '64': 32.0879523453022,
        '65': 32.087840891158,
        '66': 32.0877791929859,
        '67': 32.08791190178373,
        '68': 32.08807793422024,
        '69': 32.08791971665456,
        '70': 32.08744933136246,
        '71': 32.087185370448324,
        '72': 32.087424798473435,
        '73': 32.08770434934172,
        '74': 32.08746065034019,
        '75': 32.08695438527094,
        '76': 32.08689214399593,
        '77': 32.08726758324717,
        '78': 32.087354232093496,
        '79': 32.08691779951593,
        '80': 32.08662780107066,
        '81': 32.086975083482514,
        '82': 32.08750303662647,
        '83': 32.08755618139964,
        '84': 32.087219214405344,
        '85': 32.08703248839416,
        '86': 32.08721431430523,
        '87': 32.087591844791454,
        '88': 32.08793344614571,
        '89': 32.08803109275829,
        '90': 32.08783759898956,
        '91': 32.08771137982863,
        '92': 32.08803200609683,
        '93': 32.08849862184969,
        '94': 32.08851475025045,
        '95': 32.08830476288403,
        '96': 32.088656571337275,
        '97': 32.08940400763236,
        '98': 32.089399423413376,
        '99': 32.0884069363101,
        '100': 32.08774430350024,
        '101': 32.08840974009921,
        '102': 32.08974008191333,
        '103': 32.07607327929466,
        '104': 32.08955715831255,
        '105': 32.090291458497155,
        '106': 32.09022376583315,
        '107': 32.08944368678319,
        '108': 32.088962513061475,
        '109': 32.089119361715724,
        '110': 32.0895323867128,
        '111': 32.08975079478614,
        '112': 32.089785002678,
        '113': 32.08982568092057,
        '114': 32.08998439538987,
        '115': 32.090200845143585,
        '116': 32.09041124772145,
        '117': 32.090056742331676,
        '118': 32.08957697903017,
        '119': 32.08983703512513,
        '120': 32.09060733400621,
        '121': 32.09091222095096,
        '122': 32.087865649493814,
        '123': 32.08933131719993,
        '124': 32.08977871047903,
        '125': 32.090473306346524,
        '126': 32.09029409856095,
        '127': 32.08961950806574,
        '128': 32.089264652185804,
        '129': 32.088977842476346,
        '130': 32.08825941674976,
        '131': 32.087615270202626,
        '132': 32.08778154590461,
        '133': 32.07799539164337,
        '134': 32.08904639006515,
        '135': 32.08832609386132,
        '136': 32.07096328105461,
        '137': 32.05007738573668,
        '138': 32.06282995545311,
        '139': 32.06702380509095,
        '140': 32.08590812496673,
        '141': 32.0890967252619,
        '142': 32.08954437557563,
        '143': 32.08965404785565,
        '144': 32.088511720607514,
        '145': 32.08832621485657,
        '146': 32.08866961473831,
        '147': 32.089817728904144,
        '148': 32.09095800673928,
        '149': 32.091957996326094,
        '150': 32.09274058057214,
        '151': 32.09234072391637,
        '152': 32.09030650636334,
        '153': 32.08826652713332,
        '154': 32.08814218771297,
        '155': 32.08938681690819,
        '156': 32.089957112153485,
        '157': 32.08941988125152,
        '158': 32.08903609624837,
        '159': 32.08926931902654,
        '160': 32.089321128600965,
        '161': 32.08890364850816,
        '162': 32.088608375106915,
        '163': 32.08861705925609,
        '164': 32.08853586540749,
        '165': 32.0883499733101,
        '166': 32.08836753364125,
        '167': 32.08849071271288,
        '168': 32.08845911847076,
        '169': 32.08841708168212,
        '170': 32.088534357839094,
        '171': 32.088574784486426,
        '172': 32.088380339005035,
        '173': 32.08820837504948,
        '174': 32.08820943016391,
        '175': 32.08818638956436,
        '176': 32.08810322340817,
        '177': 32.08818433265621,
        '178': 32.08838252476257,
        '179': 32.088386103562016,
        '180': 32.08819586718581,
        '-180': 32.08819586718581,
        '-179': 32.08810897023766,
        '-178': 32.08817275649047,
        '-177': 32.08818115604222,
        '-176': 32.08811348408798,
        '-175': 32.08812911128844,
        '-174': 32.088250484804234,
        '-173': 32.088376709689726,
        '-172': 32.088457306998784,
        '-171': 32.0884637486116,
        '-170': 32.08838739231327,
        '-169': 32.08834063139606,
        '-168': 32.08843593554805,
        '-167': 32.08859232557239,
        '-166': 32.08868825348773,
        '-165': 32.088769271817306,
        '-164': 32.08886627226404,
        '-163': 32.08883138331916,
        '-162': 32.088612780338316,
        '-161': 32.0884402972522,
        '-160': 32.08849728474757,
        '-159': 32.08866207634153,
        '-158': 32.08875709049553,
        '-157': 32.0887877457745,
        '-156': 32.08880480232882,
        '-155': 32.0887929209909,
        '-154': 32.0887825390113,
        '-153': 32.08885045194533,
        '-152': 32.08898074883717,
        '-151': 32.08908629365083,
        '-150': 32.08912333425896,
        '-149': 32.0890831997736,
        '-148': 32.08898222171563,
        '-147': 32.088902146234844,
        '-146': 32.088888172194686,
        '-145': 32.08885079862981,
        '-144': 32.08875553878733,
        '-143': 32.088755004106105,
        '-142': 32.088893145549704,
        '-141': 32.08893128215139,
        '-140': 32.0887556962646,
        '-139': 32.08862511981624,
        '-138': 32.08869982699319,
        '-137': 32.08873689683706,
        '-136': 32.08858475940297,
        '-135': 32.08850072282327,
        '-134': 32.08863384619795,
        '-133': 32.08871376266459,
        '-132': 32.08858637077294,
        '-131': 32.08851312446089,
        '-130': 32.088638001193,
        '-129': 32.088715160651475,
        '-128': 32.08861621278399,
        '-127': 32.088550073433446,
        '-126': 32.08859763233386,
        '-125': 32.08858120874169,
        '-124': 32.08849762591755,
        '-123': 32.08853254330458,
        '-122': 32.088621931280386,
        '-121': 32.08853794089699,
        '-120': 32.08836104273572,
        '-119': 32.08834892080112,
        '-118': 32.08845289288137,
        '-117': 32.08845282861752,
        '-116': 32.08839453925995,
        '-115': 32.08843966026828,
        '-114': 32.088506276565546,
        '-113': 32.08846429614602,
        '-112': 32.088423990448746,
        '-111': 32.088487434070274,
        '-110': 32.08851918498599,
        '-109': 32.08844506958005,
        '-108': 32.08843193513658,
        '-107': 32.08854101269463,
        '-106': 32.08857720243873,
        '-105': 32.088465833570524,
        '-104': 32.08839191749492,
        '-103': 32.08842779186449,
        '-102': 32.08841275574266,
        '-101': 32.088300444042495,
        '-100': 32.08824763120389,
        '-99': 32.08829663403696,
        '-98': 32.08831466083269,
        '-97': 32.08826167116161,
        '-96': 32.088214868269546,
        '-95': 32.08817548926835,
        '-94': 32.08811390524086,
        '-93': 32.088090251854474,
        '-92': 32.08811307379846,
        '-91': 32.0880762025113,
        '-90': 32.088004119056514,
        '-89': 32.088058732170076,
        '-88': 32.08818583548533,
        '-87': 32.088189530620575,
        '-86': 32.088208173193806,
        '-85': 32.08846823679371,
        '-84': 32.088563196724365,
        '-83': 32.08797854404614,
        '-82': 32.08743306492113,
        '-81': 32.088256748396034,
        '-80': 32.07757955997712,
        '-79': 32.06928893842772,
        '-78': 32.07813856340724,
        '-77': 32.08473555473146,
        '-76': 32.08543989183297,
        '-75': 32.08749861222336,
        '-74': 32.08799312481469,
        '-73': 32.08743570949307,
        '-72': 32.08760427550469,
        '-71': 32.088867386392074,
        '-70': 32.08828417861256,
        '-69': 32.08742573684544,
        '-68': 32.08747425990336,
        '-67': 32.08804458436554,
        '-66': 32.088272388290164,
        '-65': 32.08754546575065,
        '-64': 32.08671590239674,
        '-63': 32.087224156493996,
        '-62': 32.08767956595788,
        '-61': 32.0876958405945,
        '-60': 32.087944526414304,
        '-59': 32.08826869974713,
        '-58': 32.08802152094275,
        '-57': 32.08730823225819,
        '-56': 32.0869960489629,
        '-55': 32.086554710476356,
        '-54': 32.08430775232155,
        '-53': 32.08572711500135,
        '-52': 32.08590700155409,
        '-51': 32.08636275790312,
        '-50': 32.087216425607586,
        '-49': 32.08680721605201,
        '-48': 32.08648763643661,
        '-47': 32.08558078552032,
        '-46': 32.084953686184086,
        '-45': 32.08688117028132,
        '-44': 32.08653253579045,
        '-43': 32.08679415312108,
        '-42': 32.087353941725425,
        '-41': 32.080513460326515,
        '-40': 32.087105109956774,
        '-39': 32.08797522847085,
        '-38': 32.08900496676628,
        '-37': 32.088641981065805,
        '-36': 32.088437753255945,
        '-35': 32.088300195721445,
        '-34': 32.08818810864421,
        '-33': 32.088243911066044,
        '-32': 32.0884141634332,
        '-31': 32.08846695716173,
        '-30': 32.08837376341736,
        '-29': 32.088276704158794,
        '-28': 32.08820373359926,
        '-27': 32.08813339098257,
        '-26': 32.08814221350102,
        '-25': 32.08824240628421,
        '-24': 32.08830117435556,
        '-23': 32.08827122715075,
        '-22': 32.08825467597003,
        '-21': 32.088289473526565,
        '-20': 32.08833603216009,
        '-19': 32.08843457918117,
        '-18': 32.088604349452176,
        '-17': 32.08870325885661,
        '-16': 32.08865647595245,
        '-15': 32.08864781575958,
        '-14': 32.088823863128916,
        '-13': 32.089019696725515,
        '-12': 32.08902074358235,
        '-11': 32.08888114274151,
        '-10': 32.08878121137997,
        '-9': 32.08877285848326,
        '-8': 32.08880938785287,
        '-7': 32.08884546925259,
        '-6': 32.08882969766361,
        '-5': 32.08874698898603,
        '-4': 32.08867390487461,
        '-3': 32.088680914667705,
        '-2': 32.0887447443352,
        '-1': 32.088825194018874
    },
    '-5': {
        '0': 32.0893277748536,
        '1': 32.089105112624146,
        '2': 32.08880969592924,
        '3': 32.08857189619985,
        '4': 32.08847774330822,
        '5': 32.088518656810606,
        '6': 32.08857844846031,
        '7': 32.08852732641971,
        '8': 32.08837074424069,
        '9': 32.088293435106046,
        '10': 32.088446819636125,
        '11': 32.08865415299545,
        '12': 32.088501633062734,
        '13': 32.085938817015176,
        '14': 32.08563726495521,
        '15': 32.0826038447256,
        '16': 32.0823203892401,
        '17': 32.08438718976112,
        '18': 32.08408374203479,
        '19': 32.08357919703324,
        '20': 32.08342462404733,
        '21': 32.08341926434821,
        '22': 32.083235049488664,
        '23': 32.08194935675102,
        '24': 32.08156761563741,
        '25': 32.08155973881939,
        '26': 32.08202552537702,
        '27': 32.08216419766208,
        '28': 32.08066665796339,
        '29': 32.068524566122775,
        '30': 32.077840452671836,
        '31': 32.07821118767649,
        '32': 32.07737698184261,
        '33': 32.075673646691875,
        '34': 32.07752288677634,
        '35': 32.07555706506486,
        '36': 32.07589558930254,
        '37': 32.07852640892246,
        '38': 32.08355916896864,
        '39': 32.08775107594073,
        '40': 32.08824983368269,
        '41': 32.08795553464084,
        '42': 32.087914308019755,
        '43': 32.08801351562493,
        '44': 32.08807221532533,
        '45': 32.088074338035696,
        '46': 32.088149246870294,
        '47': 32.08830635823371,
        '48': 32.08844259318924,
        '49': 32.08856517819029,
        '50': 32.088723484340065,
        '51': 32.08881947463128,
        '52': 32.088795267505525,
        '53': 32.0888708158507,
        '54': 32.08924397319104,
        '55': 32.08967801738695,
        '56': 32.08974845676274,
        '57': 32.08941186160321,
        '58': 32.0890003328897,
        '59': 32.08873567997213,
        '60': 32.088576778878796,
        '61': 32.08848830260965,
        '62': 32.08851398558325,
        '63': 32.088562776204924,
        '64': 32.0884434757487,
        '65': 32.08820820079619,
        '66': 32.08817428946913,
        '67': 32.088430488492904,
        '68': 32.08858493435258,
        '69': 32.08830163328912,
        '70': 32.08789012153593,
        '71': 32.08791931104962,
        '72': 32.08830340071928,
        '73': 32.08833330801021,
        '74': 32.087767141581985,
        '75': 32.08728692521213,
        '76': 32.08748519688694,
        '77': 32.08794247126336,
        '78': 32.08790320693207,
        '79': 32.08745390500914,
        '80': 32.08729633547632,
        '81': 32.08759272501527,
        '82': 32.08782983105973,
        '83': 32.08775306744537,
        '84': 32.08765871650379,
        '85': 32.08773136784052,
        '86': 32.087821524948815,
        '87': 32.0879317615388,
        '88': 32.08820659152585,
        '89': 32.08841882370189,
        '90': 32.08823849721485,
        '91': 32.087998610663085,
        '92': 32.088298919556934,
        '93': 32.088862561347256,
        '94': 32.088857839047705,
        '95': 32.088421714026154,
        '96': 32.08861233541346,
        '97': 32.0895892501329,
        '98': 32.0901572262773,
        '99': 32.08958791994291,
        '100': 32.08866775842871,
        '101': 32.08839603547325,
        '102': 32.088783925509965,
        '103': 32.08947456344264,
        '104': 32.079440574741746,
        '105': 32.090760892042006,
        '106': 32.09153838339588,
        '107': 32.09083105757942,
        '108': 32.089900858812264,
        '109': 32.08937721257473,
        '110': 32.08926751245271,
        '111': 32.08940215574736,
        '112': 32.08967744708396,
        '113': 32.08986196108747,
        '114': 32.08985253775755,
        '115': 32.089930439975724,
        '116': 32.09017618249246,
        '117': 32.09014853115331,
        '118': 32.089781475938146,
        '119': 32.08980034427109,
        '120': 32.08601780837042,
        '121': 32.090864186246215,
        '122': 32.09031184976535,
        '123': 32.089481594560105,
        '124': 32.08919913043706,
        '125': 32.08927555308154,
        '126': 32.0894309041532,
        '127': 32.09005369338987,
        '128': 32.0909508338796,
        '129': 32.0907055604606,
        '130': 32.08865608221909,
        '131': 32.08645098547159,
        '132': 32.08621923611517,
        '133': 32.08788391027639,
        '134': 32.089524512705104,
        '135': 32.089925962806575,
        '136': 32.089642076750785,
        '137': 32.08968614672758,
        '138': 32.08997601781139,
        '139': 32.09014287211807,
        '140': 32.08873650298192,
        '141': 32.06467384729515,
        '142': 32.07929469468689,
        '143': 32.07434287721675,
        '144': 32.08281391353828,
        '145': 32.087750666132344,
        '146': 32.08838477826134,
        '147': 32.08899408055695,
        '148': 32.08999489474621,
        '149': 32.09139455219483,
        '150': 32.0927977397498,
        '151': 32.09257359536525,
        '152': 32.08999099999985,
        '153': 32.08727928005105,
        '154': 32.0872384413974,
        '155': 32.089373019683315,
        '156': 32.09074496394785,
        '157': 32.09021085917466,
        '158': 32.08936746407235,
        '159': 32.08948276825901,
        '160': 32.08991778663852,
        '161': 32.089841348906134,
        '162': 32.08948784615131,
        '163': 32.089267255609194,
        '164': 32.08909044788288,
        '165': 32.08891410850409,
        '166': 32.088924381027475,
        '167': 32.08905820248518,
        '168': 32.089071271679394,
        '169': 32.08898247493232,
        '170': 32.08892982687709,
        '171': 32.08886568898111,
        '172': 32.08877043722863,
        '173': 32.088772955849365,
        '174': 32.088821098942276,
        '175': 32.08871017808542,
        '176': 32.0885460506266,
        '177': 32.08862984405248,
        '178': 32.0888669624955,
        '179': 32.08887852363339,
        '180': 32.0886550105735,
        '-180': 32.0886550105735,
        '-179': 32.088519975516334,
        '-178': 32.088543283334566,
        '-177': 32.088538006056744,
        '-176': 32.08850532601399,
        '-175': 32.08859069784228,
        '-174': 32.0887452845237,
        '-173': 32.08882249990935,
        '-172': 32.08883778731629,
        '-171': 32.08885655372336,
        '-170': 32.0888469587955,
        '-169': 32.08883303474433,
        '-168': 32.088935310140556,
        '-167': 32.08913481683893,
        '-166': 32.08925932071948,
        '-165': 32.08926106547613,
        '-164': 32.089250717028314,
        '-163': 32.08925627868762,
        '-162': 32.08919794381741,
        '-161': 32.08907697912097,
        '-160': 32.08898342357455,
        '-159': 32.088962035368844,
        '-158': 32.089004865052985,
        '-157': 32.089089276050345,
        '-156': 32.08914684590063,
        '-155': 32.089118671344295,
        '-154': 32.089075796568984,
        '-153': 32.08914226370767,
        '-152': 32.08929806163923,
        '-151': 32.089403463300386,
        '-150': 32.08939820713807,
        '-149': 32.08934883943941,
        '-148': 32.089329094342325,
        '-147': 32.08934319855608,
        '-146': 32.08934033408678,
        '-145': 32.08928194741239,
        '-144': 32.089218910519385,
        '-143': 32.08923429082064,
        '-142': 32.08927613355733,
        '-141': 32.0892116940565,
        '-140': 32.08909913931827,
        '-139': 32.08914529966558,
        '-138': 32.08931408040459,
        '-137': 32.08931773094018,
        '-136': 32.089108583673124,
        '-135': 32.0889835706077,
        '-134': 32.08906845222342,
        '-133': 32.08913068342156,
        '-132': 32.08904547302445,
        '-131': 32.08899657494189,
        '-130': 32.08907321315855,
        '-129': 32.08911065471557,
        '-128': 32.089049035014966,
        '-127': 32.08903151083205,
        '-126': 32.08907905979626,
        '-125': 32.089065638414226,
        '-124': 32.08901914209154,
        '-123': 32.08906225417934,
        '-122': 32.08911268499741,
        '-121': 32.089015511697184,
        '-120': 32.088865945710445,
        '-119': 32.08885045954926,
        '-118': 32.08891914576901,
        '-117': 32.08893096021359,
        '-116': 32.08892399837437,
        '-115': 32.088960586582004,
        '-114': 32.088948277729294,
        '-113': 32.088862438226315,
        '-112': 32.088866623306075,
        '-111': 32.08900022555341,
        '-110': 32.08906039473365,
        '-109': 32.08897431058433,
        '-108': 32.08894287268172,
        '-107': 32.08905116139976,
        '-106': 32.0891006464703,
        '-105': 32.08898771631621,
        '-104': 32.08888749206949,
        '-103': 32.08890565153809,
        '-102': 32.088891224030675,
        '-101': 32.08874950831254,
        '-100': 32.088630850085075,
        '-99': 32.08865783875161,
        '-98': 32.08872343306569,
        '-97': 32.08869194271373,
        '-96': 32.088597596377156,
        '-95': 32.08854370557303,
        '-94': 32.088554398006664,
        '-93': 32.088585864258285,
        '-92': 32.08859021084575,
        '-91': 32.08856449022716,
        '-90': 32.0885750822641,
        '-89': 32.088658194711506,
        '-88': 32.08871136234035,
        '-87': 32.08866849527581,
        '-86': 32.08870781757264,
        '-85': 32.0889240406383,
        '-84': 32.08891543704773,
        '-83': 32.08836839766396,
        '-82': 32.08801992081466,
        '-81': 32.08805577661221,
        '-80': 32.075535632561035,
        '-79': 32.082149489848135,
        '-78': 32.08645823738346,
        '-77': 32.08584067010617,
        '-76': 32.08596520941685,
        '-75': 32.087527133180195,
        '-74': 32.088581127604925,
        '-73': 32.08811142919611,
        '-72': 32.088333183904595,
        '-71': 32.08920933079414,
        '-70': 32.08885016659831,
        '-69': 32.08799148068121,
        '-68': 32.08814366218957,
        '-67': 32.08895867581341,
        '-66': 32.08896258266907,
        '-65': 32.08796002044289,
        '-64': 32.08729363564395,
        '-63': 32.08763363323769,
        '-62': 32.08789054699937,
        '-61': 32.088088372293385,
        '-60': 32.08819882569309,
        '-59': 32.08786083708585,
        '-58': 32.08759388888193,
        '-57': 32.08685278234419,
        '-56': 32.08625993852477,
        '-55': 32.08581648109919,
        '-54': 32.08567661816635,
        '-53': 32.08553183119911,
        '-52': 32.086078585540555,
        '-51': 32.086985041902125,
        '-50': 32.087197251252825,
        '-49': 32.08691592893497,
        '-48': 32.0850265265755,
        '-47': 32.08566329082838,
        '-46': 32.08617653969032,
        '-45': 32.08671099625332,
        '-44': 32.08627080100341,
        '-43': 32.08705028030675,
        '-42': 32.086759412490125,
        '-41': 32.08336869514225,
        '-40': 32.08499389637201,
        '-39': 32.08737418310276,
        '-38': 32.08918061299924,
        '-37': 32.08788080466864,
        '-36': 32.08949123046442,
        '-35': 32.08923679680164,
        '-34': 32.08877259140742,
        '-33': 32.08861127065622,
        '-32': 32.08880814784751,
        '-31': 32.088953367968514,
        '-30': 32.0888944317596,
        '-29': 32.088826774604584,
        '-28': 32.08881824030912,
        '-27': 32.08877813402905,
        '-26': 32.088739585383216,
        '-25': 32.088778792527066,
        '-24': 32.088833060937525,
        '-23': 32.08884212110363,
        '-22': 32.08885224177782,
        '-21': 32.08887048139057,
        '-20': 32.088873651031065,
        '-19': 32.0889596764781,
        '-18': 32.089196136650564,
        '-17': 32.089402437752135,
        '-16': 32.08939297460366,
        '-15': 32.08929505968474,
        '-14': 32.08932348017297,
        '-13': 32.089428319348656,
        '-12': 32.08944906132648,
        '-11': 32.08940066953393,
        '-10': 32.08935774162822,
        '-9': 32.08928284921498,
        '-8': 32.089166147897174,
        '-7': 32.08910174755997,
        '-6': 32.089092605252695,
        '-5': 32.089025482786546,
        '-4': 32.08892209271903,
        '-3': 32.08895850023756,
        '-2': 32.08916838467496,
        '-1': 32.0893521365743
    },
    '-6': {
        '0': 32.08998507943019,
        '1': 32.08963480485524,
        '2': 32.08932461638379,
        '3': 32.089149915558714,
        '4': 32.08905999393035,
        '5': 32.08905538635601,
        '6': 32.08912091197537,
        '7': 32.08911435624867,
        '8': 32.08898979437808,
        '9': 32.08902726357514,
        '10': 32.089480297298756,
        '11': 32.090012611001384,
        '12': 32.08996955137108,
        '13': 32.08909431991098,
        '14': 32.085187666739316,
        '15': 32.08102826422587,
        '16': 32.08450683104463,
        '17': 32.081229164458904,
        '18': 32.08052324250349,
        '19': 32.08153510487336,
        '20': 32.08393017872374,
        '21': 32.08287136882431,
        '22': 32.08211302041813,
        '23': 32.08147524048353,
        '24': 32.082285702747754,
        '25': 32.08227635814855,
        '26': 32.08218833098656,
        '27': 32.08275877786559,
        '28': 32.082138655975655,
        '29': 32.076708994931096,
        '30': 32.078521099542094,
        '31': 32.07775925192303,
        '32': 32.078527300379804,
        '33': 32.07771996426902,
        '34': 32.07379248199353,
        '35': 32.08057639669295,
        '36': 32.07903239609125,
        '37': 32.07899469501776,
        '38': 32.08495860045501,
        '39': 32.08922292873568,
        '40': 32.088686984482614,
        '41': 32.08841887907284,
        '42': 32.08846821249673,
        '43': 32.088660338594124,
        '44': 32.08873812300758,
        '45': 32.0886738180929,
        '46': 32.08863765603471,
        '47': 32.0886741617982,
        '48': 32.088716685712164,
        '49': 32.088849222741416,
        '50': 32.089168272798425,
        '51': 32.08946463040999,
        '52': 32.08946010070942,
        '53': 32.08928426353093,
        '54': 32.089297226614136,
        '55': 32.08952192055487,
        '56': 32.08967290833977,
        '57': 32.08966002319951,
        '58': 32.08962970158693,
        '59': 32.08957930468421,
        '60': 32.08937463846735,
        '61': 32.089113657729435,
        '62': 32.08903413287851,
        '63': 32.0890674908722,
        '64': 32.088931809933364,
        '65': 32.08869544096636,
        '66': 32.08875061723266,
        '67': 32.089097915439154,
        '68': 32.0891938385309,
        '69': 32.08880998516264,
        '70': 32.088528293946986,
        '71': 32.08887272431024,
        '72': 32.08935635210475,
        '73': 32.08910932641205,
        '74': 32.0882580305429,
        '75': 32.08783057959344,
        '76': 32.08822206750752,
        '77': 32.088641393569354,
        '78': 32.088418936816126,
        '79': 32.08800987426421,
        '80': 32.08810215516069,
        '81': 32.088458418641544,
        '82': 32.088449443149486,
        '83': 32.088194187070236,
        '84': 32.0882538571553,
        '85': 32.08856423187423,
        '86': 32.08863454885427,
        '87': 32.08855578207947,
        '88': 32.08880286927745,
        '89': 32.089192373256274,
        '90': 32.089106836615066,
        '91': 32.088697472463764,
        '92': 32.08873409416,
        '93': 32.089213712857365,
        '94': 32.08931235495638,
        '95': 32.088899831188385,
        '96': 32.08887495886662,
        '97': 32.089642472669674,
        '98': 32.09040225355046,
        '99': 32.09039354053109,
        '100': 32.08982830278776,
        '101': 32.08919879573915,
        '102': 32.08866140509027,
        '103': 32.088498777195994,
        '104': 32.08920769534947,
        '105': 32.09065991612798,
        '106': 32.09186638341115,
        '107': 32.09238781993693,
        '108': 32.09206774166334,
        '109': 32.09136825165574,
        '110': 32.09064128167545,
        '111': 32.09026285456859,
        '112': 32.09029654852983,
        '113': 32.09029434458608,
        '114': 32.09003102030533,
        '115': 32.08996715808505,
        '116': 32.0903434725361,
        '117': 32.09054040426933,
        '118': 32.09013501191409,
        '119': 32.089769131226156,
        '120': 32.09007259298482,
        '121': 32.09058181587088,
        '122': 32.09062794563611,
        '123': 32.09039250710099,
        '124': 32.090240505148024,
        '125': 32.09003594913514,
        '126': 32.08992357845252,
        '127': 32.09054419846435,
        '128': 32.0915206767262,
        '129': 32.091096326597345,
        '130': 32.08856896901621,
        '131': 32.08596850284497,
        '132': 32.08585730652439,
        '133': 32.08813048453098,
        '134': 32.09031436875178,
        '135': 32.090666634596296,
        '136': 32.08979930640397,
        '137': 32.089256385281246,
        '138': 32.08950846671285,
        '139': 32.08967714572296,
        '140': 32.08970172650007,
        '141': 32.089656309737,
        '142': 32.08978727863823,
        '143': 32.07262244773636,
        '144': 32.06508344383172,
        '145': 32.07331392168666,
        '146': 32.081941212833634,
        '147': 32.077856413585785,
        '148': 32.08857148060392,
        '149': 32.08849482269093,
        '150': 32.087245991298694,
        '151': 32.090022845312454,
        '152': 32.089194417414554,
        '153': 32.087046681528655,
        '154': 32.08722995780631,
        '155': 32.077760196511036,
        '156': 32.09117890626129,
        '157': 32.090559264875374,
        '158': 32.0894029804431,
        '159': 32.08939123347814,
        '160': 32.090063002823044,
        '161': 32.090323424220024,
        '162': 32.09013713376384,
        '163': 32.08993840756294,
        '164': 32.08973971835906,
        '165': 32.08948633065177,
        '166': 32.08938671822952,
        '167': 32.08949720783724,
        '168': 32.089582568714775,
        '169': 32.089508170533705,
        '170': 32.08934673947426,
        '171': 32.08918789647495,
        '172': 32.089140546987565,
        '173': 32.08928704829521,
        '174': 32.08945222241676,
        '175': 32.08936828179247,
        '176': 32.0891475153372,
        '177': 32.089143556966555,
        '178': 32.08933679696411,
        '179': 32.0893738302168,
        '180': 32.08918800378268,
        '-180': 32.08918800378268,
        '-179': 32.089046111117526,
        '-178': 32.089032659582486,
        '-177': 32.08900068152722,
        '-176': 32.08895748493949,
        '-175': 32.089034588518004,
        '-174': 32.08917095847364,
        '-173': 32.08921245349031,
        '-172': 32.08919510471059,
        '-171': 32.08922877423014,
        '-170': 32.08928332561907,
        '-169': 32.08933009757092,
        '-168': 32.089466066712866,
        '-167': 32.089689396678146,
        '-166': 32.08979426019942,
        '-165': 32.08967607707214,
        '-164': 32.089522777250544,
        '-163': 32.08954510075907,
        '-162': 32.08969198795744,
        '-161': 32.08975647825369,
        '-160': 32.089647815646615,
        '-159': 32.08947850979048,
        '-158': 32.08942793538759,
        '-157': 32.08954005754327,
        '-156': 32.089670923859025,
        '-155': 32.08968312900239,
        '-154': 32.08963371683325,
        '-153': 32.08965406159869,
        '-152': 32.089717985059096,
        '-151': 32.08970131068533,
        '-150': 32.089615998431704,
        '-149': 32.08959565536548,
        '-148': 32.089676677945576,
        '-147': 32.0897639560087,
        '-146': 32.0897994402753,
        '-145': 32.08982116117517,
        '-144': 32.08985293538328,
        '-143': 32.08984568440953,
        '-142': 32.08976077546229,
        '-141': 32.08966127892209,
        '-140': 32.089667404179195,
        '-139': 32.0897981165189,
        '-138': 32.089907251798856,
        '-137': 32.089857178891165,
        '-136': 32.08971109733929,
        '-135': 32.089633169341575,
        '-134': 32.08964852670708,
        '-133': 32.08965941247838,
        '-132': 32.08964374120531,
        '-131': 32.089651720579774,
        '-130': 32.089659757101906,
        '-129': 32.08961791127662,
        '-128': 32.08957763280105,
        '-127': 32.089594076953574,
        '-126': 32.089596867458106,
        '-125': 32.08952842781081,
        '-124': 32.089480788066545,
        '-123': 32.08952741474833,
        '-122': 32.08957488439209,
        '-121': 32.08953967463003,
        '-120': 32.08949580325161,
        '-119': 32.089506352296034,
        '-118': 32.08950409847851,
        '-117': 32.08945797031933,
        '-116': 32.08944574531009,
        '-115': 32.08947068490476,
        '-114': 32.08943845340149,
        '-113': 32.08938172583922,
        '-112': 32.08945073444751,
        '-111': 32.089624317926386,
        '-110': 32.08969978782589,
        '-109': 32.08962425340351,
        '-108': 32.089566623665036,
        '-107': 32.08960697198537,
        '-106': 32.089620878063634,
        '-105': 32.08953753725478,
        '-104': 32.08946357063688,
        '-103': 32.08946896467733,
        '-102': 32.08946582472867,
        '-101': 32.089389547123325,
        '-100': 32.089319046476646,
        '-99': 32.08932019358827,
        '-98': 32.08931752197654,
        '-97': 32.089228336318286,
        '-96': 32.089114882038594,
        '-95': 32.08908989154286,
        '-94': 32.08913962465367,
        '-93': 32.08915282387717,
        '-92': 32.08909651071679,
        '-91': 32.089060844546516,
        '-90': 32.089115389508514,
        '-89': 32.08919484302425,
        '-88': 32.08919099511969,
        '-87': 32.08915173660512,
        '-86': 32.08927373314169,
        '-85': 32.089554446652826,
        '-84': 32.089570569299305,
        '-83': 32.08900734305682,
        '-82': 32.08846194369836,
        '-81': 32.08571998143182,
        '-80': 32.08996867000856,
        '-79': 32.078119362719086,
        '-78': 32.06885694409315,
        '-77': 32.08049138566351,
        '-76': 32.08667173215006,
        '-75': 32.08768727149256,
        '-74': 32.088784836260515,
        '-73': 32.08872431110785,
        '-72': 32.088820923276764,
        '-71': 32.08918338029087,
        '-70': 32.08924597257889,
        '-69': 32.08899564580141,
        '-68': 32.0892891699188,
        '-67': 32.08911432025245,
        '-66': 32.08894395192119,
        '-65': 32.08875244575514,
        '-64': 32.088533301640126,
        '-63': 32.0884429717932,
        '-62': 32.08854195984172,
        '-61': 32.088606498090876,
        '-60': 32.0888471983081,
        '-59': 32.08800508568584,
        '-58': 32.08717732190614,
        '-57': 32.08730522445479,
        '-56': 32.08557254501331,
        '-55': 32.08633815153336,
        '-54': 32.085941376210165,
        '-53': 32.08658493114462,
        '-52': 32.0867440864601,
        '-51': 32.08723437322606,
        '-50': 32.08731528520698,
        '-49': 32.08758017524129,
        '-48': 32.0865929253509,
        '-47': 32.086595620288975,
        '-46': 32.08664327281072,
        '-45': 32.085777040795016,
        '-44': 32.08608690100519,
        '-43': 32.08689854997308,
        '-42': 32.08738174559781,
        '-41': 32.08177490966251,
        '-40': 32.08485383925134,
        '-39': 32.08701526711886,
        '-38': 32.083818107473014,
        '-37': 32.08954181288005,
        '-36': 32.08872059321519,
        '-35': 32.09020589921035,
        '-34': 32.08943689378417,
        '-33': 32.08904805001952,
        '-32': 32.08918230264366,
        '-31': 32.08930304030717,
        '-30': 32.08920851660824,
        '-29': 32.08916346161769,
        '-28': 32.08924178108849,
        '-27': 32.08926162674971,
        '-26': 32.08920602403207,
        '-25': 32.08919237719333,
        '-24': 32.089214483547856,
        '-23': 32.08924215778432,
        '-22': 32.089329392541785,
        '-21': 32.08944143446938,
        '-20': 32.08947164832775,
        '-19': 32.08949089333637,
        '-18': 32.08964061417853,
        '-17': 32.08981228563798,
        '-16': 32.089803199146054,
        '-15': 32.08969507285059,
        '-14': 32.089708742639175,
        '-13': 32.08982590303564,
        '-12': 32.089894298934695,
        '-11': 32.089913443814694,
        '-10': 32.08991251222172,
        '-9': 32.08978854658715,
        '-8': 32.089538960991995,
        '-7': 32.08938195120189,
        '-6': 32.08941536556646,
        '-5': 32.089459420706056,
        '-4': 32.08943222862673,
        '-3': 32.08953504684009,
        '-2': 32.08985127870998,
        '-1': 32.09009339574635
    },
    '-7': {
        '0': 32.090545064846225,
        '1': 32.09016023507722,
        '2': 32.08991638931998,
        '3': 32.0898345228745,
        '4': 32.089741422623014,
        '5': 32.08965637158302,
        '6': 32.08969542870211,
        '7': 32.089747745466966,
        '8': 32.08966285477245,
        '9': 32.089667191065836,
        '10': 32.09010343385657,
        '11': 32.090737644736,
        '12': 32.09088662658325,
        '13': 32.08916317146355,
        '14': 32.085664334547495,
        '15': 32.08216389165864,
        '16': 32.0815896449266,
        '17': 32.08427503681168,
        '18': 32.08068791018063,
        '19': 32.0807264401631,
        '20': 32.08323070172355,
        '21': 32.08291261006925,
        '22': 32.08134549462838,
        '23': 32.080468313949304,
        '24': 32.08251913774431,
        '25': 32.08006851567827,
        '26': 32.078613872166095,
        '27': 32.08355103058575,
        '28': 32.08312088807458,
        '29': 32.07578691351224,
        '30': 32.08194759559628,
        '31': 32.07978604251358,
        '32': 32.077697372863405,
        '33': 32.07839750893413,
        '34': 32.077159182217436,
        '35': 32.07921535870355,
        '36': 32.082009875854254,
        '37': 32.08483977105433,
        '38': 32.08820566614679,
        '39': 32.08727789463132,
        '40': 32.089313857683116,
        '41': 32.089068299961696,
        '42': 32.089116019206195,
        '43': 32.08935421834132,
        '44': 32.08950158970001,
        '45': 32.08945417893129,
        '46': 32.089344728629115,
        '47': 32.08925874146719,
        '48': 32.08921179980057,
        '49': 32.08934955422042,
        '50': 32.089767604100736,
        '51': 32.090182785492345,
        '52': 32.09021061964644,
        '53': 32.08992684887363,
        '54': 32.08974062438441,
        '55': 32.08978789793076,
        '56': 32.089884064911566,
        '57': 32.089976653025275,
        '58': 32.090157208992025,
        '59': 32.090288941185655,
        '60': 32.09012834748153,
        '61': 32.089800259152966,
        '62': 32.08966503697244,
        '63': 32.08972306734529,
        '64': 32.089660362899764,
        '65': 32.0894927814335,
        '66': 32.08957754456038,
        '67': 32.089879057681294,
        '68': 32.089873107086326,
        '69': 32.08945511481351,
        '70': 32.089288107857776,
        '71': 32.08976325872554,
        '72': 32.09019914271462,
        '73': 32.0897826510937,
        '74': 32.088891538109486,
        '75': 32.0886003423932,
        '76': 32.08905149636024,
        '77': 32.08930797379025,
        '78': 32.08891787012172,
        '79': 32.08859510795191,
        '80': 32.08893829092213,
        '81': 32.089418465907016,
        '82': 32.08932276657947,
        '83': 32.088942671542966,
        '84': 32.08897401331337,
        '85': 32.089307175004386,
        '86': 32.089365599482385,
        '87': 32.08924354957841,
        '88': 32.08947684839091,
        '89': 32.08991197750766,
        '90': 32.08987412282811,
        '91': 32.08940310071066,
        '92': 32.089264339359715,
        '93': 32.08965205785715,
        '94': 32.089906223029914,
        '95': 32.08970776011968,
        '96': 32.08958425030583,
        '97': 32.089947463386245,
        '98': 32.09049045059356,
        '99': 32.09081985283225,
        '100': 32.090899482439326,
        '101': 32.09064023939292,
        '102': 32.08985091990831,
        '103': 32.08887352096305,
        '104': 32.088568531579305,
        '105': 32.08938844462018,
        '106': 32.0909392263844,
        '107': 32.08300841531615,
        '108': 32.08563939966887,
        '109': 32.09328217768915,
        '110': 32.090526669113,
        '111': 32.091046138962014,
        '112': 32.08883831474035,
        '113': 32.091087386981755,
        '114': 32.091451095727265,
        '115': 32.09154651528157,
        '116': 32.09181319822819,
        '117': 32.09185778963681,
        '118': 32.09131748143588,
        '119': 32.09078004026179,
        '120': 32.090768476308966,
        '121': 32.09095384503411,
        '122': 32.091053804309446,
        '123': 32.09143261926217,
        '124': 32.0920687554234,
        '125': 32.09210835430706,
        '126': 32.091283828185276,
        '127': 32.09056573542497,
        '128': 32.090487540337016,
        '129': 32.09007620346981,
        '130': 32.08858154053909,
        '131': 32.08717287135498,
        '132': 32.087567032113675,
        '133': 32.08959899997488,
        '134': 32.09138076727473,
        '135': 32.09162821426214,
        '136': 32.09087588886967,
        '137': 32.090369024248545,
        '138': 32.09048375421752,
        '139': 32.09045638463491,
        '140': 32.09005057057675,
        '141': 32.090196814006106,
        '142': 32.09055531481096,
        '143': 32.090915573218815,
        '144': 32.090289737367925,
        '145': 32.09111482783562,
        '146': 32.07094888290295,
        '147': 32.09084596900508,
        '148': 32.08887107422707,
        '149': 32.08763347213298,
        '150': 32.08838767768894,
        '151': 32.089810040043886,
        '152': 32.08978351131681,
        '153': 32.08874902341115,
        '154': 32.088945559613336,
        '155': 32.090643948616176,
        '156': 32.09169745773214,
        '157': 32.08914905548319,
        '158': 32.08991276542926,
        '159': 32.089847457714754,
        '160': 32.09036928613704,
        '161': 32.09058541614276,
        '162': 32.090564801515185,
        '163': 32.09064082004381,
        '164': 32.09060474510601,
        '165': 32.09027953643505,
        '166': 32.08998462376363,
        '167': 32.089976013640005,
        '168': 32.090076118385255,
        '169': 32.090070985504816,
        '170': 32.08997230487625,
        '171': 32.08985301708824,
        '172': 32.0898035175259,
        '173': 32.08993849018228,
        '174': 32.09017265086667,
        '175': 32.090228794225375,
        '176': 32.09006487681348,
        '177': 32.089954849111926,
        '178': 32.090003495130276,
        '179': 32.08999265674074,
        '180': 32.08982941655284,
        '-180': 32.08982941655284,
        '-179': 32.08970853520282,
        '-178': 32.089717725066535,
        '-177': 32.089696930910634,
        '-176': 32.08958158099052,
        '-175': 32.08952015595193,
        '-174': 32.08959121135544,
        '-173': 32.08970282992186,
        '-172': 32.08978297854969,
        '-171': 32.08983315210914,
        '-170': 32.08984330498002,
        '-169': 32.089847751347776,
        '-168': 32.089963284508606,
        '-167': 32.09019736226897,
        '-166': 32.090347313440276,
        '-165': 32.09025831100436,
        '-164': 32.090063038377274,
        '-163': 32.090011963806084,
        '-162': 32.09015035861737,
        '-161': 32.09029014454105,
        '-160': 32.09026591181267,
        '-159': 32.09013139480453,
        '-158': 32.09007817270697,
        '-157': 32.09018537149423,
        '-156': 32.09032459004211,
        '-155': 32.09036160178484,
        '-154': 32.09034613401307,
        '-153': 32.09037649724975,
        '-152': 32.090389517576455,
        '-151': 32.090277554914415,
        '-150': 32.09012217160073,
        '-149': 32.09008658703702,
        '-148': 32.09014849041411,
        '-147': 32.09017506700403,
        '-146': 32.09019291664964,
        '-145': 32.09032446147362,
        '-144': 32.090507267033594,
        '-143': 32.090552682098185,
        '-142': 32.090468689949624,
        '-141': 32.09045247023181,
        '-140': 32.09054649739307,
        '-139': 32.090570464293705,
        '-138': 32.09044830206833,
        '-137': 32.090342492057815,
        '-136': 32.09036323443699,
        '-135': 32.090383204047896,
        '-134': 32.09028565521212,
        '-133': 32.09018593148265,
        '-132': 32.09022272000619,
        '-131': 32.09030511689813,
        '-130': 32.0902780346207,
        '-129': 32.0901944271303,
        '-128': 32.09019424723304,
        '-127': 32.09023284332029,
        '-126': 32.09017312408911,
        '-125': 32.09005309500961,
        '-124': 32.090017797343364,
        '-123': 32.09006332420187,
        '-122': 32.09008134610878,
        '-121': 32.09007936720348,
        '-120': 32.090130271800184,
        '-119': 32.090184079585946,
        '-118': 32.09014862281084,
        '-117': 32.09007687458049,
        '-116': 32.0900750942053,
        '-115': 32.09011504721151,
        '-114': 32.09011889439483,
        '-113': 32.0901278927306,
        '-112': 32.090214054200615,
        '-111': 32.09032296961122,
        '-110': 32.09035727556121,
        '-109': 32.09032752338358,
        '-108': 32.09030075202276,
        '-107': 32.09028533897698,
        '-106': 32.09025611519075,
        '-105': 32.09021417542684,
        '-104': 32.09016334095223,
        '-103': 32.09009835327149,
        '-102': 32.090044325947304,
        '-101': 32.09004123641435,
        '-100': 32.09007678824297,
        '-99': 32.090085632782554,
        '-98': 32.09002031796956,
        '-97': 32.08990258474474,
        '-96': 32.08980962535658,
        '-95': 32.08979639956511,
        '-94': 32.08981820108685,
        '-93': 32.089779796284574,
        '-92': 32.08968255980253,
        '-91': 32.089636708104685,
        '-90': 32.08968994342506,
        '-89': 32.0897484728896,
        '-88': 32.08972818435299,
        '-87': 32.08970513707654,
        '-86': 32.08984463044282,
        '-85': 32.09015057482714,
        '-84': 32.09027814597093,
        '-83': 32.08980375650075,
        '-82': 32.0889807090372,
        '-81': 32.08895652908047,
        '-80': 32.09047967457264,
        '-79': 32.058296181588744,
        '-78': 32.0810757874796,
        '-77': 32.07827105993661,
        '-76': 32.0784633622302,
        '-75': 32.087274695940806,
        '-74': 32.088451386389075,
        '-73': 32.089037707794446,
        '-72': 32.08907087097833,
        '-71': 32.08893189169184,
        '-70': 32.089440790659935,
        '-69': 32.08987284210029,
        '-68': 32.0894310623641,
        '-67': 32.08891251889012,
        '-66': 32.08895096202249,
        '-65': 32.08959249590309,
        '-64': 32.08960124875067,
        '-63': 32.089620850727634,
        '-62': 32.08945700584544,
        '-61': 32.08920243807826,
        '-60': 32.08884694600792,
        '-59': 32.089420344753414,
        '-58': 32.088060027088545,
        '-57': 32.08758575762456,
        '-56': 32.08702428996661,
        '-55': 32.08726797311828,
        '-54': 32.08683038642771,
        '-53': 32.08717382314756,
        '-52': 32.087779514465815,
        '-51': 32.08707857141683,
        '-50': 32.08721769333064,
        '-49': 32.08735988974843,
        '-48': 32.08632717027137,
        '-47': 32.086318568941806,
        '-46': 32.08440389364482,
        '-45': 32.08655988649941,
        '-44': 32.08669239946069,
        '-43': 32.08685042275259,
        '-42': 32.087445054655134,
        '-41': 32.08756305623698,
        '-40': 32.08583794829074,
        '-39': 32.08720118777107,
        '-38': 32.087927538137684,
        '-37': 32.08754944414504,
        '-36': 32.086115913715574,
        '-35': 32.09023437141553,
        '-34': 32.08999442153637,
        '-33': 32.08952346189687,
        '-32': 32.089647856202795,
        '-31': 32.089742621603115,
        '-30': 32.089616832513784,
        '-29': 32.08958027523546,
        '-28': 32.08968516865438,
        '-27': 32.08971777892863,
        '-26': 32.08968324345688,
        '-25': 32.08970744755187,
        '-24': 32.089735472019015,
        '-23': 32.089727457547625,
        '-22': 32.089818816626575,
        '-21': 32.090022817267105,
        '-20': 32.09014826879221,
        '-19': 32.09015676507697,
        '-18': 32.090200311918395,
        '-17': 32.09026817942725,
        '-16': 32.090225851095916,
        '-15': 32.090162960558295,
        '-14': 32.09026492072392,
        '-13': 32.090437526460974,
        '-12': 32.090473442266514,
        '-11': 32.0904271462955,
        '-10': 32.09043455729527,
        '-9': 32.09039152003923,
        '-8': 32.09019621064815,
        '-7': 32.090045226580564,
        '-6': 32.09011929321725,
        '-5': 32.09025834327918,
        '-4': 32.09028871627147,
        '-3': 32.09035671679519,
        '-2': 32.090589729101886,
        '-1': 32.090741411930274
    },
    '-8': {
        '0': 32.09095914335109,
        '1': 32.090613394841505,
        '2': 32.09047144921277,
        '3': 32.09051264238669,
        '4': 32.090504155791635,
        '5': 32.09044774978079,
        '6': 32.09050835485093,
        '7': 32.09060232515971,
        '8': 32.090505149774245,
        '9': 32.09034481308177,
        '10': 32.090512309205536,
        '11': 32.091006836695826,
        '12': 32.09134854947922,
        '13': 32.09130093190978,
        '14': 32.08632369061668,
        '15': 32.081924506145604,
        '16': 32.08067862637578,
        '17': 32.08468087286403,
        '18': 32.08303459038499,
        '19': 32.07972891195591,
        '20': 32.08090589501427,
        '21': 32.08314223050135,
        '22': 32.08079743272984,
        '23': 32.08109002876846,
        '24': 32.08199534596892,
        '25': 32.080140919048155,
        '26': 32.08317289382401,
        '27': 32.084600852880854,
        '28': 32.082634391058214,
        '29': 32.079364137301546,
        '30': 32.074960857003944,
        '31': 32.07462774745042,
        '32': 32.082736058285384,
        '33': 32.07834215712152,
        '34': 32.07570661275574,
        '35': 32.07898381565344,
        '36': 32.07385711948896,
        '37': 32.0879435311409,
        '38': 32.088318393772695,
        '39': 32.090218341248026,
        '40': 32.09016020264914,
        '41': 32.08985653941601,
        '42': 32.08979098892815,
        '43': 32.09001267195499,
        '44': 32.090243372358756,
        '45': 32.09028479900284,
        '46': 32.090232393609476,
        '47': 32.090217998692836,
        '48': 32.09026464721957,
        '49': 32.09043565708686,
        '50': 32.09077408241512,
        '51': 32.091070912341905,
        '52': 32.091059796399456,
        '53': 32.09084616849551,
        '54': 32.09077086618193,
        '55': 32.09087694090356,
        '56': 32.09091796950794,
        '57': 32.09085672722693,
        '58': 32.090900836237296,
        '59': 32.091041332340865,
        '60': 32.09101980922215,
        '61': 32.09080091806534,
        '62': 32.09063783775852,
        '63': 32.090611973038044,
        '64': 32.09054647208713,
        '65': 32.090439007567745,
        '66': 32.09050375500211,
        '67': 32.090674422770945,
        '68': 32.090583906756095,
        '69': 32.090235956846186,
        '70': 32.09015236313407,
        '71': 32.090523152517825,
        '72': 32.090744848765105,
        '73': 32.09030054621167,
        '74': 32.08964702838036,
        '75': 32.08957104337392,
        '76': 32.089968029339275,
        '77': 32.09004370090082,
        '78': 32.08962830788103,
        '79': 32.08943559271424,
        '80': 32.08985981927885,
        '81': 32.09033173377216,
        '82': 32.090259077180605,
        '83': 32.08991873757375,
        '84': 32.08987533535375,
        '85': 32.09006123668648,
        '86': 32.0900745947024,
        '87': 32.089983634796816,
        '88': 32.09012561068676,
        '89': 32.09036526480524,
        '90': 32.09028200922143,
        '91': 32.0899699338702,
        '92': 32.089948496947954,
        '93': 32.09030680004548,
        '94': 32.09059370921179,
        '95': 32.09058267375709,
        '96': 32.090534755004704,
        '97': 32.090656326490304,
        '98': 32.09086738766235,
        '99': 32.09114744082669,
        '100': 32.09154719275321,
        '101': 32.09179891110084,
        '102': 32.09145277221795,
        '103': 32.09051282111904,
        '104': 32.08955707997757,
        '105': 32.08920947696244,
        '106': 32.08974610581806,
        '107': 32.091010332203176,
        '108': 32.092385244955494,
        '109': 32.09314021774623,
        '110': 32.0931619723708,
        '111': 32.08898364405894,
        '112': 32.09211385766194,
        '113': 32.075742995605104,
        '114': 32.08475196739538,
        '115': 32.09322556396148,
        '116': 32.09323874559498,
        '117': 32.09297216720842,
        '118': 32.0925446455388,
        '119': 32.09246956725387,
        '120': 32.09257697819002,
        '121': 32.09211464045259,
        '122': 32.09127694601366,
        '123': 32.09127034290906,
        '124': 32.09227467298905,
        '125': 32.09275998866675,
        '126': 32.091665355210765,
        '127': 32.08997322720802,
        '128': 32.08917861218104,
        '129': 32.08932896668555,
        '130': 32.08960100079514,
        '131': 32.08987156880629,
        '132': 32.09059617411219,
        '133': 32.09167583614332,
        '134': 32.09241342592323,
        '135': 32.092437415295805,
        '136': 32.09208873583737,
        '137': 32.091906307387845,
        '138': 32.09191957027538,
        '139': 32.09190917048277,
        '140': 32.09158442379215,
        '141': 32.09115573521496,
        '142': 32.09120110232797,
        '143': 32.09132416078982,
        '144': 32.091635921534156,
        '145': 32.09205015159707,
        '146': 32.09247631099225,
        '147': 32.06740710837014,
        '148': 32.09161015725592,
        '149': 32.090246036157446,
        '150': 32.090354370739036,
        '151': 32.09156963384228,
        '152': 32.09212653709923,
        '153': 32.09167178439727,
        '154': 32.091531611524374,
        '155': 32.092182469265914,
        '156': 32.09251810813676,
        '157': 32.08851301753038,
        '158': 32.09131637183058,
        '159': 32.089098268349666,
        '160': 32.09129293504285,
        '161': 32.0910969202483,
        '162': 32.091131483258664,
        '163': 32.09157182188754,
        '164': 32.09181992994122,
        '165': 32.09154052496544,
        '166': 32.091158557044814,
        '167': 32.09102722685521,
        '168': 32.0909732858062,
        '169': 32.090848628225764,
        '170': 32.09078102903363,
        '171': 32.09078584861914,
        '172': 32.09074359102985,
        '173': 32.09074006167435,
        '174': 32.09091533854351,
        '175': 32.091104223406,
        '176': 32.091076502825416,
        '177': 32.09093419410734,
        '178': 32.09087637683872,
        '179': 32.090826479161784,
        '180': 32.090658713251415,
        '-180': 32.090658713251415,
        '-179': 32.09051725859866,
        '-178': 32.09055976876841,
        '-177': 32.09063611421296,
        '-176': 32.09054224938987,
        '-175': 32.09038600552984,
        '-174': 32.09042088123463,
        '-173': 32.09066120817775,
        '-172': 32.090880891235024,
        '-171': 32.0909079264217,
        '-170': 32.090778163879015,
        '-169': 32.09065137666802,
        '-168': 32.090658171627176,
        '-167': 32.0908008506547,
        '-166': 32.09096536857168,
        '-165': 32.091033530893064,
        '-164': 32.09098277355669,
        '-163': 32.09088345132547,
        '-162': 32.09082189671838,
        '-161': 32.0908233629676,
        '-160': 32.09084194428342,
        '-159': 32.090840328988016,
        '-158': 32.09085194182053,
        '-157': 32.09090951767797,
        '-156': 32.090964571215444,
        '-155': 32.090978680650615,
        '-154': 32.09102555252526,
        '-153': 32.09115663932493,
        '-152': 32.09124677736082,
        '-151': 32.09116245157138,
        '-150': 32.09099678494964,
        '-149': 32.09091641134743,
        '-148': 32.09087886324935,
        '-147': 32.090768099612916,
        '-146': 32.09069548121466,
        '-145': 32.090840373809655,
        '-144': 32.09108901010654,
        '-143': 32.09119624430703,
        '-142': 32.09121999321432,
        '-141': 32.09138413450534,
        '-140': 32.09157933670176,
        '-139': 32.091478944654234,
        '-138': 32.091145219009334,
        '-137': 32.09100565113175,
        '-136': 32.09115720384978,
        '-135': 32.09121485626102,
        '-134': 32.09098648618386,
        '-133': 32.09078035284872,
        '-132': 32.090837700031344,
        '-131': 32.09094954613953,
        '-130': 32.09089598055198,
        '-129': 32.09082209806338,
        '-128': 32.09089019973786,
        '-127': 32.090953916184105,
        '-126': 32.0908704121815,
        '-125': 32.09078595687843,
        '-124': 32.09083700024147,
        '-123': 32.09088895948104,
        '-122': 32.090819359201845,
        '-121': 32.09075574568135,
        '-120': 32.09081207093628,
        '-119': 32.090877395956014,
        '-118': 32.09085402092648,
        '-117': 32.09083323218063,
        '-116': 32.09088809572359,
        '-115': 32.090948984372645,
        '-114': 32.09098138495853,
        '-113': 32.0910395830967,
        '-112': 32.091100671360394,
        '-111': 32.09108808323541,
        '-110': 32.09105163328147,
        '-109': 32.09108724184505,
        '-108': 32.09113790677698,
        '-107': 32.091106157282546,
        '-106': 32.09105333409806,
        '-105': 32.09105530934456,
        '-104': 32.09101244591229,
        '-103': 32.09084009097083,
        '-102': 32.090678067585415,
        '-101': 32.09068041239718,
        '-100': 32.09076539539375,
        '-99': 32.090774996037624,
        '-98': 32.090715219089844,
        '-97': 32.090673077418685,
        '-96': 32.090653183001436,
        '-95': 32.09062226110595,
        '-94': 32.090584349363105,
        '-93': 32.09054403832923,
        '-92': 32.090500916156074,
        '-91': 32.090486145798636,
        '-90': 32.090517846675226,
        '-89': 32.09055570601848,
        '-88': 32.09055500885553,
        '-87': 32.09052270136301,
        '-86': 32.090536224058816,
        '-85': 32.09071201129823,
        '-84': 32.090958078788304,
        '-83': 32.090784480178606,
        '-82': 32.08992598540097,
        '-81': 32.08930014720267,
        '-80': 32.090349743056244,
        '-79': 32.08881573864378,
        '-78': 32.053249751576686,
        '-77': 32.06851561214804,
        '-76': 32.07969674431669,
        '-75': 32.08753809725194,
        '-74': 32.08822704951564,
        '-73': 32.08994731075006,
        '-72': 32.08974335151496,
        '-71': 32.089214474765015,
        '-70': 32.08972226409408,
        '-69': 32.090132765715985,
        '-68': 32.0899394535999,
        '-67': 32.08956077121841,
        '-66': 32.08936671643745,
        '-65': 32.08995904700955,
        '-64': 32.090467644679336,
        '-63': 32.09062441291832,
        '-62': 32.08999328827914,
        '-61': 32.089814694261634,
        '-60': 32.08983991787421,
        '-59': 32.089652798068926,
        '-58': 32.08965669509132,
        '-57': 32.087562836062375,
        '-56': 32.08824626142525,
        '-55': 32.087578873130106,
        '-54': 32.08564375733183,
        '-53': 32.08722279714027,
        '-52': 32.08502532368569,
        '-51': 32.08797706399436,
        '-50': 32.08850225519026,
        '-49': 32.08810129006576,
        '-48': 32.0881860780086,
        '-47': 32.08610701452837,
        '-46': 32.08587940006461,
        '-45': 32.086429779683755,
        '-44': 32.08658082578716,
        '-43': 32.08707514750151,
        '-42': 32.08762302209834,
        '-41': 32.087458493002906,
        '-40': 32.08706808730483,
        '-39': 32.08606908328688,
        '-38': 32.086056594824754,
        '-37': 32.085415345203415,
        '-36': 32.088521401488904,
        '-35': 32.09064941846095,
        '-34': 32.090528471679114,
        '-33': 32.090133120701,
        '-32': 32.09031890386954,
        '-31': 32.09043832866778,
        '-30': 32.09036223358382,
        '-29': 32.09038077065409,
        '-28': 32.09046227437324,
        '-27': 32.09041362428484,
        '-26': 32.090362612292466,
        '-25': 32.090466409280516,
        '-24': 32.09055244076651,
        '-23': 32.090498246784406,
        '-22': 32.09051943806557,
        '-21': 32.09074471234462,
        '-20': 32.090966019055585,
        '-19': 32.091038359942175,
        '-18': 32.09106722095281,
        '-17': 32.091087491601414,
        '-16': 32.091028827354855,
        '-15': 32.09101250957545,
        '-14': 32.09119588419649,
        '-13': 32.09138554132883,
        '-12': 32.091296279451456,
        '-11': 32.09106684545451,
        '-10': 32.0910257499828,
        '-9': 32.091122462023414,
        '-8': 32.09109062292083,
        '-7': 32.090976901570926,
        '-6': 32.09101007861752,
        '-5': 32.09113763112935,
        '-4': 32.091159760749974,
        '-3': 32.09113116901194,
        '-2': 32.091193317178394,
        '-1': 32.091203841905404
    },
    '-9': {
        '0': 32.091571462066,
        '1': 32.09131341556285,
        '2': 32.09124135435342,
        '3': 32.091352451350886,
        '4': 32.091432751784154,
        '5': 32.09144692388658,
        '6': 32.0915322109539,
        '7': 32.09164330448122,
        '8': 32.09157818908248,
        '9': 32.09138505222631,
        '10': 32.09138001569998,
        '11': 32.091677496734576,
        '12': 32.09204200045866,
        '13': 32.092299658699915,
        '14': 32.0919733943298,
        '15': 32.08531964191706,
        '16': 32.08104569727992,
        '17': 32.08394914509166,
        '18': 32.082809596308465,
        '19': 32.08023919783013,
        '20': 32.08143712707646,
        '21': 32.08150107576742,
        '22': 32.08150390640949,
        '23': 32.080977255210385,
        '24': 32.08192806653174,
        '25': 32.080366917000646,
        '26': 32.085295133186776,
        '27': 32.07297030284363,
        '28': 32.07580217595836,
        '29': 32.082540902957625,
        '30': 32.080243304897685,
        '31': 32.077395866771504,
        '32': 32.075553608646985,
        '33': 32.076537119003795,
        '34': 32.071440136813635,
        '35': 32.076373765048515,
        '36': 32.088822409576686,
        '37': 32.08628544077423,
        '38': 32.08717100603648,
        '39': 32.09021297136435,
        '40': 32.09125347899642,
        '41': 32.09083356216779,
        '42': 32.09063077439401,
        '43': 32.090822940800635,
        '44': 32.09109290730424,
        '45': 32.09114045911062,
        '46': 32.091085587916695,
        '47': 32.091167161873855,
        '48': 32.091378000532224,
        '49': 32.091600779414165,
        '50': 32.09180449454628,
        '51': 32.0919350976802,
        '52': 32.09190041003478,
        '53': 32.0917996268608,
        '54': 32.091869191870344,
        '55': 32.09208421338718,
        '56': 32.09213402305938,
        '57': 32.09192215218312,
        '58': 32.091761166865766,
        '59': 32.09190149490606,
        '60': 32.09215806487275,
        '61': 32.09219422655625,
        '62': 32.09194658075013,
        '63': 32.091604058777385,
        '64': 32.09136010033806,
        '65': 32.091313825625726,
        '66': 32.091433125357185,
        '67': 32.09152054404274,
        '68': 32.091404571575396,
        '69': 32.091217787515305,
        '70': 32.091241073276876,
        '71': 32.091419884647564,
        '72': 32.091364298274534,
        '73': 32.09096344890884,
        '74': 32.09063623544613,
        '75': 32.09072201637936,
        '76': 32.09095533749546,
        '77': 32.090898748475894,
        '78': 32.09063753926142,
        '79': 32.090610413684836,
        '80': 32.09089398714164,
        '81': 32.09111882862067,
        '82': 32.09107610436356,
        '83': 32.09097031879972,
        '84': 32.0909968501505,
        '85': 32.091058809752084,
        '86': 32.091039910430304,
        '87': 32.09101992822049,
        '88': 32.091045731712484,
        '89': 32.090986485060355,
        '90': 32.09081489228736,
        '91': 32.090762123378056,
        '92': 32.09097020748379,
        '93': 32.09123388284954,
        '94': 32.09132366806148,
        '95': 32.091332061230965,
        '96': 32.09144438806899,
        '97': 32.091595311984456,
        '98': 32.09164153365271,
        '99': 32.091677324137855,
        '100': 32.09188079998669,
        '101': 32.0921952867828,
        '102': 32.09239725687719,
        '103': 32.0923195569244,
        '104': 32.09186246033029,
        '105': 32.09106188445325,
        '106': 32.090304383311576,
        '107': 32.090116576348244,
        '108': 32.09054316786157,
        '109': 32.09109540175156,
        '110': 32.091453783352705,
        '111': 32.09177339324536,
        '112': 32.092184931294526,
        '113': 32.09254157422835,
        '114': 32.09277511261906,
        '115': 32.0929288666145,
        '116': 32.09283769134241,
        '117': 32.08924261685129,
        '118': 32.09246932704688,
        '119': 32.0932733969064,
        '120': 32.09395454921239,
        '121': 32.09313934931492,
        '122': 32.091246570488686,
        '123': 32.090274297734794,
        '124': 32.09099220842269,
        '125': 32.08974432808665,
        '126': 32.09042746338142,
        '127': 32.09016963555726,
        '128': 32.08974035694131,
        '129': 32.0905121806818,
        '130': 32.091729162214676,
        '131': 32.09269131998538,
        '132': 32.093204988303384,
        '133': 32.09330716885496,
        '134': 32.093149196069625,
        '135': 32.09294615071046,
        '136': 32.09280744171111,
        '137': 32.09275342332224,
        '138': 32.09281460088154,
        '139': 32.09292403468031,
        '140': 32.09293658980761,
        '141': 32.09283711440473,
        '142': 32.09266518132818,
        '143': 32.09235388053114,
        '144': 32.0921279498354,
        '145': 32.091909429973654,
        '146': 32.09271765928602,
        '147': 32.09313242402274,
        '148': 32.08898452217391,
        '149': 32.09417238469549,
        '150': 32.09393653592087,
        '151': 32.09426761803715,
        '152': 32.094386405024046,
        '153': 32.09388835044516,
        '154': 32.09340080403215,
        '155': 32.093323892016556,
        '156': 32.093256509017706,
        '157': 32.09300399384239,
        '158': 32.09289873973001,
        '159': 32.09287604777812,
        '160': 32.092467803717675,
        '161': 32.08709456335452,
        '162': 32.091935565061306,
        '163': 32.0925839360756,
        '164': 32.09298959937088,
        '165': 32.09284715115972,
        '166': 32.092674454250016,
        '167': 32.09266994124139,
        '168': 32.092438591771185,
        '169': 32.091940062781845,
        '170': 32.0916539753112,
        '171': 32.091697718191696,
        '172': 32.09169047253094,
        '173': 32.091540929535434,
        '174': 32.09155046564496,
        '175': 32.09173189278443,
        '176': 32.091773704251395,
        '177': 32.0916616423683,
        '178': 32.09166813506586,
        '179': 32.091772266445105,
        '180': 32.091701623628396,
        '-180': 32.09170162362839,
        '-179': 32.09149588042072,
        '-178': 32.091465707167714,
        '-177': 32.091629452929,
        '-176': 32.091701663223304,
        '-175': 32.09163167468464,
        '-174': 32.09168846652321,
        '-173': 32.09196304814377,
        '-172': 32.0921820810906,
        '-171': 32.092122192864096,
        '-170': 32.091908118891105,
        '-169': 32.09176694542989,
        '-168': 32.091717821999104,
        '-167': 32.09167133449828,
        '-166': 32.09166904459252,
        '-165': 32.09180937628019,
        '-164': 32.09200519490657,
        '-163': 32.09203468421526,
        '-162': 32.09184884684468,
        '-161': 32.09164620125808,
        '-160': 32.0915978902705,
        '-159': 32.09165965836769,
        '-158': 32.091707207823006,
        '-157': 32.09170224715154,
        '-156': 32.091669863097735,
        '-155': 32.09165541530153,
        '-154': 32.091737924019014,
        '-153': 32.09193890266715,
        '-152': 32.09211591959196,
        '-151': 32.092114975945535,
        '-150': 32.091998650855885,
        '-149': 32.09192468109188,
        '-148': 32.09186801693994,
        '-147': 32.0917205671808,
        '-146': 32.09157937232064,
        '-145': 32.09161540961122,
        '-144': 32.091741069841184,
        '-143': 32.091774733097125,
        '-142': 32.0918367771676,
        '-141': 32.09214046175428,
        '-140': 32.09247717582733,
        '-139': 32.092435513685565,
        '-138': 32.0920987644839,
        '-137': 32.09195310521279,
        '-136': 32.09208734421263,
        '-135': 32.09209340354122,
        '-134': 32.09183129838601,
        '-133': 32.091655305647635,
        '-132': 32.09173954380026,
        '-131': 32.09180241775559,
        '-130': 32.09168915772201,
        '-129': 32.091637536075645,
        '-128': 32.091760073036895,
        '-127': 32.09182143271549,
        '-126': 32.0917327702754,
        '-125': 32.091734403676185,
        '-124': 32.09189862715229,
        '-123': 32.091960929770075,
        '-122': 32.09181432130158,
        '-121': 32.09168999039255,
        '-120': 32.09171298122148,
        '-119': 32.091735189632566,
        '-118': 32.09169977446956,
        '-117': 32.091735201157746,
        '-116': 32.0918412514308,
        '-115': 32.09188597644918,
        '-114': 32.09189815453246,
        '-113': 32.09198536963433,
        '-112': 32.09205422978638,
        '-111': 32.09198117370119,
        '-110': 32.09190314991122,
        '-109': 32.091976493093505,
        '-108': 32.092061671437044,
        '-107': 32.09199707880282,
        '-106': 32.09191535264023,
        '-105': 32.091945934303155,
        '-104': 32.09191888566935,
        '-103': 32.09170310817069,
        '-102': 32.0915049628303,
        '-101': 32.09151101301093,
        '-100': 32.091572075361746,
        '-99': 32.0915244048398,
        '-98': 32.09148066631927,
        '-97': 32.09155184428572,
        '-96': 32.09161024198847,
        '-95': 32.091543620850366,
        '-94': 32.09145643685884,
        '-93': 32.09146002369822,
        '-92': 32.09150251202949,
        '-91': 32.091505327062215,
        '-90': 32.09148807274847,
        '-89': 32.09150788728271,
        '-88': 32.09156049424273,
        '-87': 32.09154952933735,
        '-86': 32.09142469415359,
        '-85': 32.09139869412363,
        '-84': 32.09170096355332,
        '-83': 32.091921583714615,
        '-82': 32.09128485999834,
        '-81': 32.090163869956086,
        '-80': 32.09036842854058,
        '-79': 32.09267856146508,
        '-78': 32.058121670982906,
        '-77': 32.06240359332622,
        '-76': 32.08142697101663,
        '-75': 32.087950917761525,
        '-74': 32.089421777299094,
        '-73': 32.090266874495235,
        '-72': 32.09007494089196,
        '-71': 32.08998190400096,
        '-70': 32.09040084264969,
        '-69': 32.091140185960754,
        '-68': 32.0915964500933,
        '-67': 32.09041739396243,
        '-66': 32.090341492113225,
        '-65': 32.09091835813181,
        '-64': 32.091353345954275,
        '-63': 32.09124885096615,
        '-62': 32.09112021742291,
        '-61': 32.09092951828021,
        '-60': 32.090321263203386,
        '-59': 32.09029459143998,
        '-58': 32.090044801883906,
        '-57': 32.08979686566339,
        '-56': 32.089035867902716,
        '-55': 32.08593878965247,
        '-54': 32.0873455649909,
        '-53': 32.088384046608596,
        '-52': 32.08903551902785,
        '-51': 32.088861260717756,
        '-50': 32.08914658981156,
        '-49': 32.088648649092896,
        '-48': 32.088093413580985,
        '-47': 32.08594804856915,
        '-46': 32.08845436926639,
        '-45': 32.08768356020937,
        '-44': 32.086835723242395,
        '-43': 32.08539306508323,
        '-42': 32.08787955806192,
        '-41': 32.08755743496487,
        '-40': 32.08811130762544,
        '-39': 32.08794095208874,
        '-38': 32.085546297004925,
        '-37': 32.087531171759075,
        '-36': 32.088403928685125,
        '-35': 32.09200033053206,
        '-34': 32.091179770614794,
        '-33': 32.09097486699022,
        '-32': 32.09121367300131,
        '-31': 32.09132448981672,
        '-30': 32.09131849333257,
        '-29': 32.091442863931924,
        '-28': 32.09152259802511,
        '-27': 32.09135941352711,
        '-26': 32.09122096641572,
        '-25': 32.091346525206646,
        '-24': 32.09149715374329,
        '-23': 32.09145830292835,
        '-22': 32.09145054991067,
        '-21': 32.09166439035129,
        '-20': 32.09190786579888,
        '-19': 32.09200184511189,
        '-18': 32.0920351170239,
        '-17': 32.09205885772286,
        '-16': 32.092013971369155,
        '-15': 32.09201923428689,
        '-14': 32.09222532125192,
        '-13': 32.092407073447795,
        '-12': 32.09223311139766,
        '-11': 32.09186742144245,
        '-10': 32.09175571046423,
        '-9': 32.09191330017375,
        '-8': 32.09197735748368,
        '-7': 32.09186052080398,
        '-6': 32.091804302989566,
        '-5': 32.09186755307552,
        '-4': 32.0918740735604,
        '-3': 32.0918048175237,
        '-2': 32.09178766995548,
        '-1': 32.091762910490154
    },
    '-10': {
        '0': 32.09252761368635,
        '1': 32.09237907642616,
        '2': 32.092336642763705,
        '3': 32.09242365891249,
        '4': 32.09249132276736,
        '5': 32.09250290670227,
        '6': 32.09256613230779,
        '7': 32.09268657954241,
        '8': 32.092744372984356,
        '9': 32.09273526598291,
        '10': 32.09279597294613,
        '11': 32.09296360745998,
        '12': 32.09315108851924,
        '13': 32.09337838792342,
        '14': 32.0923023810551,
        '15': 32.08288931850063,
        '16': 32.08291967431024,
        '17': 32.081729867514625,
        '18': 32.0795357522168,
        '19': 32.081503441528085,
        '20': 32.08100130298221,
        '21': 32.0819387468036,
        '22': 32.08208868427447,
        '23': 32.08327659853854,
        '24': 32.08181260394477,
        '25': 32.08216473971454,
        '26': 32.07837600805,
        '27': 32.08358684758166,
        '28': 32.08132059626495,
        '29': 32.078960868448355,
        '30': 32.07753118079479,
        '31': 32.07767892506392,
        '32': 32.08056813253827,
        '33': 32.083115060184355,
        '34': 32.088477979609294,
        '35': 32.08077823002902,
        '36': 32.08395434547991,
        '37': 32.085952968684936,
        '38': 32.08713888676421,
        '39': 32.088846260454226,
        '40': 32.0924748736933,
        '41': 32.09203226517646,
        '42': 32.09182294316606,
        '43': 32.09207802358822,
        '44': 32.09236687540708,
        '45': 32.092266983988345,
        '46': 32.0919855820902,
        '47': 32.09195963223044,
        '48': 32.092196386992875,
        '49': 32.092409981126394,
        '50': 32.09252640781319,
        '51': 32.09264914924531,
        '52': 32.09274329113606,
        '53': 32.09273508254975,
        '54': 32.092749203762935,
        '55': 32.09289258166853,
        '56': 32.09296455636425,
        '57': 32.0927525948385,
        '58': 32.092497790652445,
        '59': 32.092660256574085,
        '60': 32.09322136881397,
        '61': 32.09358745299849,
        '62': 32.09330722245397,
        '63': 32.0926320092709,
        '64': 32.09219366316946,
        '65': 32.0922652899265,
        '66': 32.09253261238086,
        '67': 32.09258042793211,
        '68': 32.092415340808856,
        '69': 32.09235962116244,
        '70': 32.09251123581224,
        '71': 32.092571542687686,
        '72': 32.09231039425227,
        '73': 32.091951317835004,
        '74': 32.09184729042633,
        '75': 32.09195204799301,
        '76': 32.09194722618834,
        '77': 32.09179403538815,
        '78': 32.09176165874904,
        '79': 32.09191241064177,
        '80': 32.09198255577436,
        '81': 32.09186965756191,
        '82': 32.09182826217305,
        '83': 32.09200924602617,
        '84': 32.0921849438229,
        '85': 32.092159588554914,
        '86': 32.092099309999504,
        '87': 32.09217510504659,
        '88': 32.092207113992785,
        '89': 32.09200909294415,
        '90': 32.09180722958506,
        '91': 32.091912750624914,
        '92': 32.09218264251623,
        '93': 32.092238952604525,
        '94': 32.09209210353492,
        '95': 32.092099626259035,
        '96': 32.09236460373213,
        '97': 32.0926100587598,
        '98': 32.09264693084471,
        '99': 32.092568729120174,
        '100': 32.092489808827,
        '101': 32.092491686777755,
        '102': 32.09279237675733,
        '103': 32.09348347102685,
        '104': 32.094077518480894,
        '105': 32.09384777644158,
        '106': 32.09274197119958,
        '107': 32.09150469779557,
        '108': 32.090802948701516,
        '109': 32.090674541409854,
        '110': 32.09086209743366,
        '111': 32.09115871498469,
        '112': 32.091360726317056,
        '113': 32.09138138441844,
        '114': 32.091422890606744,
        '115': 32.09159248089347,
        '116': 32.091580730410406,
        '117': 32.091332666665295,
        '118': 32.09160440905197,
        '119': 32.09286365195062,
        '120': 32.09351086305643,
        '121': 32.09349502966894,
        '122': 32.09165291079119,
        '123': 32.09046333482281,
        '124': 32.086566944838076,
        '125': 32.09193718517888,
        '126': 32.09231551561791,
        '127': 32.092247943440526,
        '128': 32.092532412334215,
        '129': 32.09326650235319,
        '130': 32.09400125026272,
        '131': 32.09443504325927,
        '132': 32.09448387171397,
        '133': 32.09418671121087,
        '134': 32.09379257489834,
        '135': 32.093557634192756,
        '136': 32.093444795416204,
        '137': 32.09333828466101,
        '138': 32.093332371808714,
        '139': 32.093490410281035,
        '140': 32.09368752941515,
        '141': 32.09394525905642,
        '142': 32.09436248056331,
        '143': 32.094530741708,
        '144': 32.09387051951589,
        '145': 32.09283313930793,
        '146': 32.09273502416419,
        '147': 32.09394847020548,
        '148': 32.09432058294711,
        '149': 32.09282913348381,
        '150': 32.09551684271949,
        '151': 32.092797588941735,
        '152': 32.095098096027286,
        '153': 32.094618534994474,
        '154': 32.09412688417605,
        '155': 32.09386119404945,
        '156': 32.093720435631845,
        '157': 32.093697912006206,
        '158': 32.09387538976337,
        '159': 32.09390522388477,
        '160': 32.09339575269577,
        '161': 32.09278376536376,
        '162': 32.09282816881249,
        '163': 32.093341627258596,
        '164': 32.09351976901094,
        '165': 32.093372402096044,
        '166': 32.09359571082939,
        '167': 32.09412117228765,
        '168': 32.09410302882434,
        '169': 32.09339612613251,
        '170': 32.092805831034106,
        '171': 32.09274096737315,
        '172': 32.09274745992667,
        '173': 32.09252880176932,
        '174': 32.09238359393437,
        '175': 32.09244506761001,
        '176': 32.09242072099658,
        '177': 32.09228439699003,
        '178': 32.09240441936949,
        '179': 32.09279460467627,
        '180': 32.092969989300364,
        '-180': 32.092969989300364,
        '-179': 32.092739737741304,
        '-178': 32.092504543999596,
        '-177': 32.09257770697307,
        '-176': 32.09277405608088,
        '-175': 32.092866844595235,
        '-174': 32.09294734365943,
        '-173': 32.09309870472162,
        '-172': 32.09313788242061,
        '-171': 32.092953614810966,
        '-170': 32.09277523977472,
        '-169': 32.092817743297445,
        '-168': 32.09290513905248,
        '-167': 32.09276168367778,
        '-166': 32.092514426265836,
        '-165': 32.092550376103524,
        '-164': 32.09291442941677,
        '-163': 32.09319784436656,
        '-162': 32.09309737443984,
        '-161': 32.09278820955744,
        '-160': 32.09260639282888,
        '-159': 32.09261160398516,
        '-158': 32.092645233402735,
        '-157': 32.09262693802602,
        '-156': 32.09259677551727,
        '-155': 32.092593271453644,
        '-154': 32.092645819829535,
        '-153': 32.09277981901818,
        '-152': 32.09292912470426,
        '-151': 32.09297292171921,
        '-150': 32.09292108985579,
        '-149': 32.092899393058985,
        '-148': 32.092919320676195,
        '-147': 32.092871265689226,
        '-146': 32.09275988886443,
        '-145': 32.092693973891045,
        '-144': 32.092647075919174,
        '-143': 32.09253233645038,
        '-142': 32.09249500724725,
        '-141': 32.09275521924902,
        '-140': 32.09315994690787,
        '-139': 32.093311027538896,
        '-138': 32.09315799304234,
        '-137': 32.09302119607898,
        '-136': 32.09301594861657,
        '-135': 32.09294720293738,
        '-134': 32.09280033846045,
        '-133': 32.09280054761592,
        '-132': 32.09292714253333,
        '-131': 32.09290586225544,
        '-130': 32.092742318084994,
        '-129': 32.09272546798583,
        '-128': 32.09286353029513,
        '-127': 32.092873277418214,
        '-126': 32.092753368175636,
        '-125': 32.092791996028026,
        '-124': 32.09298509998315,
        '-123': 32.09302492384459,
        '-122': 32.09287684989004,
        '-121': 32.09280524673506,
        '-120': 32.09284570609315,
        '-119': 32.09280063594592,
        '-118': 32.09270410637114,
        '-117': 32.09275692664195,
        '-116': 32.09289042423236,
        '-115': 32.092899159512925,
        '-114': 32.09286384677492,
        '-113': 32.092965255431295,
        '-112': 32.093081945076754,
        '-111': 32.09303094771152,
        '-110': 32.09295087398227,
        '-109': 32.09301279264265,
        '-108': 32.09305927742357,
        '-107': 32.092934647694406,
        '-106': 32.09281543721292,
        '-105': 32.092840730074315,
        '-104': 32.092818190140065,
        '-103': 32.09263640048066,
        '-102': 32.092526506991874,
        '-101': 32.09260766655982,
        '-100': 32.092641332747064,
        '-99': 32.092508158199735,
        '-98': 32.09244534771775,
        '-97': 32.09257184537656,
        '-96': 32.09265507900749,
        '-95': 32.09255410436379,
        '-94': 32.092451067690526,
        '-93': 32.092482559832725,
        '-92': 32.09252881452352,
        '-91': 32.09247880795827,
        '-90': 32.092403331608686,
        '-89': 32.092413915553024,
        '-88': 32.092519264449095,
        '-87': 32.09258593963583,
        '-86': 32.09245815353515,
        '-85': 32.0922979463732,
        '-84': 32.09254069066442,
        '-83': 32.093043329905186,
        '-82': 32.09278744840221,
        '-81': 32.09142068760107,
        '-80': 32.090583762510335,
        '-79': 32.092112192376526,
        '-78': 32.09185434609019,
        '-77': 32.04831048333336,
        '-76': 32.059801252989004,
        '-75': 32.08993698621205,
        '-74': 32.09042405786376,
        '-73': 32.08984864345875,
        '-72': 32.090328925371615,
        '-71': 32.09069045605342,
        '-70': 32.09094056689823,
        '-69': 32.09204015059878,
        '-68': 32.092139395756234,
        '-67': 32.09195264981328,
        '-66': 32.09190076919901,
        '-65': 32.09163479643105,
        '-64': 32.09154994239477,
        '-63': 32.09188607801758,
        '-62': 32.09215642339939,
        '-61': 32.09161757018635,
        '-60': 32.09047245351935,
        '-59': 32.0904305563393,
        '-58': 32.08918175075855,
        '-57': 32.090062629839686,
        '-56': 32.08936593031902,
        '-55': 32.08930444885376,
        '-54': 32.08874300728984,
        '-53': 32.08939785494714,
        '-52': 32.089801341235386,
        '-51': 32.08999496974219,
        '-50': 32.090081878619486,
        '-49': 32.08918587831092,
        '-48': 32.08919711521166,
        '-47': 32.089807425394696,
        '-46': 32.087409678901835,
        '-45': 32.08837889803811,
        '-44': 32.088775986440716,
        '-43': 32.088345575249434,
        '-42': 32.086318632160925,
        '-41': 32.085047623766705,
        '-40': 32.087877236943086,
        '-39': 32.08696877837237,
        '-38': 32.089136032029955,
        '-37': 32.09171493475717,
        '-36': 32.09295938830502,
        '-35': 32.09250473050968,
        '-34': 32.09202761941636,
        '-33': 32.09208509009004,
        '-32': 32.0923227374218,
        '-31': 32.092309140725305,
        '-30': 32.09226608092048,
        '-29': 32.092455229759985,
        '-28': 32.092582559603976,
        '-27': 32.092386860489235,
        '-26': 32.09217437662199,
        '-25': 32.09225335339317,
        '-24': 32.09242109919018,
        '-23': 32.092444241908964,
        '-22': 32.092488647179856,
        '-21': 32.09270231273862,
        '-20': 32.092891128405455,
        '-19': 32.09290621793385,
        '-18': 32.09288314679927,
        '-17': 32.09290662155986,
        '-16': 32.092894297003994,
        '-15': 32.092901443725495,
        '-14': 32.093064864542804,
        '-13': 32.09322101325316,
        '-12': 32.09307524069638,
        '-11': 32.09274014703307,
        '-10': 32.09260922780431,
        '-9': 32.0927289740613,
        '-8': 32.0927875897742,
        '-7': 32.09268287388716,
        '-6': 32.09261699276256,
        '-5': 32.092656735989,
        '-4': 32.09265164197951,
        '-3': 32.092578022604776,
        '-2': 32.09256673967295,
        '-1': 32.09259952572835
    },
    '-11': {
        '0': 32.09360806326374,
        '1': 32.09354908774691,
        '2': 32.09354398055232,
        '3': 32.09359267194888,
        '4': 32.0935984060557,
        '5': 32.093571351426775,
        '6': 32.09361721598874,
        '7': 32.093753179616805,
        '8': 32.09390577906119,
        '9': 32.094054263462766,
        '10': 32.094209119977364,
        '11': 32.094300866638775,
        '12': 32.09429661585573,
        '13': 32.09438519538823,
        '14': 32.0943187951675,
        '15': 32.082867830170876,
        '16': 32.07886244295934,
        '17': 32.08240359932284,
        '18': 32.081159448307226,
        '19': 32.08104348665855,
        '20': 32.081154698966984,
        '21': 32.082420006119484,
        '22': 32.08246878468245,
        '23': 32.081497155598754,
        '24': 32.08146313174136,
        '25': 32.08131852236641,
        '26': 32.07953246726992,
        '27': 32.08265809399791,
        '28': 32.081488113064026,
        '29': 32.0808422962743,
        '30': 32.081779190501024,
        '31': 32.08129437874343,
        '32': 32.07891880769852,
        '33': 32.08665031287018,
        '34': 32.0798394378753,
        '35': 32.078653566011575,
        '36': 32.08564393414781,
        '37': 32.085364392849286,
        '38': 32.09005085689442,
        '39': 32.091743523167196,
        '40': 32.09221125042912,
        '41': 32.09333625004063,
        '42': 32.09323351922937,
        '43': 32.09363718072052,
        '44': 32.09399124900914,
        '45': 32.09374435791177,
        '46': 32.09320852148856,
        '47': 32.09304495674527,
        '48': 32.09327262624435,
        '49': 32.09341868665538,
        '50': 32.093383531401,
        '51': 32.09347405183735,
        '52': 32.09371245275416,
        '53': 32.09378701747068,
        '54': 32.093674692754455,
        '55': 32.09368242392836,
        '56': 32.09381075699678,
        '57': 32.09369582833147,
        '58': 32.09334859002009,
        '59': 32.09334839394612,
        '60': 32.09396723634684,
        '61': 32.09458677566175,
        '62': 32.09445653162498,
        '63': 32.09374093350068,
        '64': 32.093298140580714,
        '65': 32.09351761284737,
        '66': 32.093892617758264,
        '67': 32.09385028654608,
        '68': 32.093543028527385,
        '69': 32.09348849223591,
        '70': 32.09371684714647,
        '71': 32.09375660918596,
        '72': 32.093419586877864,
        '73': 32.093094614799334,
        '74': 32.09309294592419,
        '75': 32.093159446997014,
        '76': 32.09297323745489,
        '77': 32.09275301200516,
        '78': 32.0928788707925,
        '79': 32.09317574090924,
        '80': 32.09316039033849,
        '81': 32.09287467634639,
        '82': 32.09283280437489,
        '83': 32.093133212277316,
        '84': 32.093285879013024,
        '85': 32.093063259793446,
        '86': 32.092872945925286,
        '87': 32.09302085064939,
        '88': 32.093208284665295,
        '89': 32.09312394143451,
        '90': 32.09299251378554,
        '91': 32.093095526619145,
        '92': 32.09322245631771,
        '93': 32.09310042905973,
        '94': 32.09297084478914,
        '95': 32.09319322201798,
        '96': 32.09359102321099,
        '97': 32.09374484592996,
        '98': 32.09367207081323,
        '99': 32.09365611049735,
        '100': 32.09365450888351,
        '101': 32.09350486307182,
        '102': 32.09351911149496,
        '103': 32.09415305028406,
        '104': 32.095125255928636,
        '105': 32.09555071023796,
        '106': 32.09503233357219,
        '107': 32.09406840188118,
        '108': 32.0933308487089,
        '109': 32.09304858708022,
        '110': 32.09310268455163,
        '111': 32.093209642124776,
        '112': 32.0930145609166,
        '113': 32.092471563257796,
        '114': 32.09203520365168,
        '115': 32.09202788878101,
        '116': 32.092107046831586,
        '117': 32.09194641110537,
        '118': 32.09201564363241,
        '119': 32.09282541523345,
        '120': 32.09377542480236,
        '121': 32.093821861470104,
        '122': 32.09309162166916,
        '123': 32.092728862510874,
        '124': 32.09321927310562,
        '125': 32.093958841881445,
        '126': 32.09446725375583,
        '127': 32.094925035078525,
        '128': 32.09540454866696,
        '129': 32.09557902615137,
        '130': 32.095385925012664,
        '131': 32.09517700183991,
        '132': 32.095095420631225,
        '133': 32.094959031803626,
        '134': 32.09474439815867,
        '135': 32.09460683282753,
        '136': 32.09453522995085,
        '137': 32.09446142071692,
        '138': 32.09446328138158,
        '139': 32.09449949290021,
        '140': 32.09439401403067,
        '141': 32.094374214132266,
        '142': 32.09490765659788,
        '143': 32.095658380545004,
        '144': 32.09555104592579,
        '145': 32.094395725067976,
        '146': 32.09344207876105,
        '147': 32.093665112166164,
        '148': 32.09448250815989,
        '149': 32.09488293841234,
        '150': 32.09485142110952,
        '151': 32.09488618491291,
        '152': 32.09497143400557,
        '153': 32.09484237031006,
        '154': 32.09460962118886,
        '155': 32.09448291484648,
        '156': 32.09442025995003,
        '157': 32.09441892199056,
        '158': 32.09456193604896,
        '159': 32.094618978461234,
        '160': 32.0943029002532,
        '161': 32.093927812504354,
        '162': 32.09397766899611,
        '163': 32.09413354957843,
        '164': 32.093759558772234,
        '165': 32.0932363856447,
        '166': 32.09358659766829,
        '167': 32.09474369479316,
        '168': 32.09546350635286,
        '169': 32.09513916008688,
        '170': 32.09450320834534,
        '171': 32.09426972128221,
        '172': 32.09422442690542,
        '173': 32.09402160651232,
        '174': 32.093848538104815,
        '175': 32.09385309251849,
        '176': 32.09376530086276,
        '177': 32.09352145617177,
        '178': 32.093545899625155,
        '179': 32.094004417189716,
        '180': 32.09439081118155,
        '-180': 32.09439081118155,
        '-179': 32.09425485737339,
        '-178': 32.09384450347457,
        '-177': 32.093657429360555,
        '-176': 32.09376210015382,
        '-175': 32.09390998135779,
        '-174': 32.093987553260426,
        '-173': 32.0940082370681,
        '-172': 32.093900621568785,
        '-171': 32.09364940145972,
        '-170': 32.093491105088276,
        '-169': 32.09363814911445,
        '-168': 32.09389080142197,
        '-167': 32.09385738030082,
        '-166': 32.093557640775984,
        '-165': 32.09346211004974,
        '-164': 32.09382243848947,
        '-163': 32.09427898388957,
        '-162': 32.09434545190857,
        '-161': 32.09403423289453,
        '-160': 32.093732483439375,
        '-159': 32.09364442525703,
        '-158': 32.09366270578424,
        '-157': 32.09367707959084,
        '-156': 32.09369891502071,
        '-155': 32.093720115532555,
        '-154': 32.09370465824671,
        '-153': 32.0936990155388,
        '-152': 32.09375953278054,
        '-151': 32.09382505086654,
        '-150': 32.09382324463859,
        '-149': 32.09380892175702,
        '-148': 32.093844888014864,
        '-147': 32.09387002742385,
        '-146': 32.09382750013878,
        '-145': 32.09377160412009,
        '-144': 32.09372265675654,
        '-143': 32.09361046720905,
        '-142': 32.09348504749319,
        '-141': 32.093549404078765,
        '-140': 32.09384049370341,
        '-139': 32.09411108460861,
        '-138': 32.094159158605066,
        '-137': 32.0940467243538,
        '-136': 32.093909275385336,
        '-135': 32.09380625714826,
        '-134': 32.09380974367641,
        '-133': 32.093953746120675,
        '-132': 32.09407485457743,
        '-131': 32.09400395034393,
        '-130': 32.09387897679635,
        '-129': 32.093930806507664,
        '-128': 32.09405717008892,
        '-127': 32.09400979875987,
        '-126': 32.09387397995665,
        '-125': 32.093904636106366,
        '-124': 32.09402924001701,
        '-123': 32.09400426492154,
        '-122': 32.093906485924535,
        '-121': 32.09395898717376,
        '-120': 32.09405055677374,
        '-119': 32.09395179622735,
        '-118': 32.09380622377594,
        '-117': 32.093880329272906,
        '-116': 32.09404999129335,
        '-115': 32.09405000650491,
        '-114': 32.09397995750019,
        '-113': 32.09406384899305,
        '-112': 32.094189410685566,
        '-111': 32.09415822498964,
        '-110': 32.094085646371795,
        '-109': 32.09412447841344,
        '-108': 32.094133628919906,
        '-107': 32.0939959891923,
        '-106': 32.093882128477944,
        '-105': 32.09388323245403,
        '-104': 32.093815773478454,
        '-103': 32.09364842277295,
        '-102': 32.09363281200994,
        '-101': 32.09379414323692,
        '-100': 32.093822704691775,
        '-99': 32.0936493877723,
        '-98': 32.09357587123648,
        '-97': 32.093696214597024,
        '-96': 32.093749308329535,
        '-95': 32.093642990261095,
        '-94': 32.093581590658246,
        '-93': 32.09362088336639,
        '-92': 32.09359501649017,
        '-91': 32.09348171667344,
        '-90': 32.09341946096341,
        '-89': 32.093446149109546,
        '-88': 32.093527773676094,
        '-87': 32.09363357028276,
        '-86': 32.093612249061835,
        '-85': 32.09340865192888,
        '-84': 32.093432637178424,
        '-83': 32.09396359790852,
        '-82': 32.09419838349659,
        '-81': 32.093107288381184,
        '-80': 32.091498932909985,
        '-79': 32.09169900970896,
        '-78': 32.094388332338774,
        '-77': 32.07134082850744,
        '-76': 32.054736165128574,
        '-75': 32.085647929743196,
        '-74': 32.0761167776583,
        '-73': 32.090173452028516,
        '-72': 32.08886481977949,
        '-71': 32.09016509484647,
        '-70': 32.09107037545956,
        '-69': 32.09225807921056,
        '-68': 32.09297310132101,
        '-67': 32.0937592494176,
        '-66': 32.09390938797004,
        '-65': 32.09315770614807,
        '-64': 32.092496460847094,
        '-63': 32.0913185706331,
        '-62': 32.092924651936265,
        '-61': 32.092535756763276,
        '-60': 32.09103031202343,
        '-59': 32.089831329359725,
        '-58': 32.09025731166127,
        '-57': 32.089935892018445,
        '-56': 32.08980397466018,
        '-55': 32.08861566162703,
        '-54': 32.09016890230123,
        '-53': 32.09071928666225,
        '-52': 32.091154364057054,
        '-51': 32.09118503933181,
        '-50': 32.09101463208349,
        '-49': 32.090424207562755,
        '-48': 32.09048233579911,
        '-47': 32.08893603226792,
        '-46': 32.086707888169485,
        '-45': 32.08810137838655,
        '-44': 32.08628867176809,
        '-43': 32.08922194839406,
        '-42': 32.08791890754103,
        '-41': 32.08779323547863,
        '-40': 32.09007707752496,
        '-39': 32.09096126091392,
        '-38': 32.08970695203224,
        '-37': 32.09357188959795,
        '-36': 32.09357929822367,
        '-35': 32.093259929995064,
        '-34': 32.09315661567201,
        '-33': 32.09345844662132,
        '-32': 32.093655952342054,
        '-31': 32.09345241640262,
        '-30': 32.09325392902787,
        '-29': 32.09338974127639,
        '-28': 32.09355491141939,
        '-27': 32.09343427564066,
        '-26': 32.093249676281445,
        '-25': 32.09328290146925,
        '-24': 32.093405828528596,
        '-23': 32.09344703397233,
        '-22': 32.09353034955669,
        '-21': 32.093735513946946,
        '-20': 32.093866176963544,
        '-19': 32.093810452143835,
        '-18': 32.0937388096563,
        '-17': 32.09376951808235,
        '-16': 32.09380838945647,
        '-15': 32.09382015677245,
        '-14': 32.09388821559506,
        '-13': 32.093952736355064,
        '-12': 32.09385346292089,
        '-11': 32.0936524436299,
        '-10': 32.09357237202145,
        '-9': 32.0936325057194,
        '-8': 32.0936691443478,
        '-7': 32.09366063086498,
        '-6': 32.09370895440754,
        '-5': 32.09376792706808,
        '-4': 32.093706177884656,
        '-3': 32.093579023722775,
        '-2': 32.09355061733555,
        '-1': 32.09360757930695
    },
    '-12': {
        '0': 32.09471129825391,
        '1': 32.09468233403345,
        '2': 32.09473611647937,
        '3': 32.09480068512994,
        '4': 32.09478552057429,
        '5': 32.094764004244794,
        '6': 32.094848707055725,
        '7': 32.09499862774444,
        '8': 32.095108386027455,
        '9': 32.09518427600796,
        '10': 32.095273738376164,
        '11': 32.09532611720482,
        '12': 32.09532057766084,
        '13': 32.09545137445523,
        '14': 32.092910591787565,
        '15': 32.07922338647775,
        '16': 32.08000922500186,
        '17': 32.07916383697401,
        '18': 32.08154308833639,
        '19': 32.08155841530269,
        '20': 32.08216045502743,
        '21': 32.08405224346144,
        '22': 32.08401758936057,
        '23': 32.08334442168477,
        '24': 32.080274336526735,
        '25': 32.081439915752654,
        '26': 32.08086625264564,
        '27': 32.08134126491954,
        '28': 32.08211924243063,
        '29': 32.0832815468132,
        '30': 32.08314199823604,
        '31': 32.08185378116736,
        '32': 32.08724016586712,
        '33': 32.083016781224536,
        '34': 32.0867881404763,
        '35': 32.083901973589406,
        '36': 32.087716223513674,
        '37': 32.089201942524916,
        '38': 32.090842795355854,
        '39': 32.091304303661154,
        '40': 32.0926735575511,
        '41': 32.0945745503627,
        '42': 32.09446037117548,
        '43': 32.094921255043374,
        '44': 32.095350509871935,
        '45': 32.0950948798338,
        '46': 32.09455858153023,
        '47': 32.094583803370234,
        '48': 32.0951032577129,
        '49': 32.09530848575995,
        '50': 32.094974434004705,
        '51': 32.09468915055045,
        '52': 32.09477249410021,
        '53': 32.09487252469953,
        '54': 32.09478983573149,
        '55': 32.09484485818635,
        '56': 32.095121309533745,
        '57': 32.09512459846957,
        '58': 32.094645523503786,
        '59': 32.09430860475025,
        '60': 32.09468341164592,
        '61': 32.095335420440875,
        '62': 32.09541886906352,
        '63': 32.094923234661366,
        '64': 32.09463637623884,
        '65': 32.09493697287165,
        '66': 32.09529526354796,
        '67': 32.09514997011526,
        '68': 32.09472905546491,
        '69': 32.09461265581478,
        '70': 32.094823399262864,
        '71': 32.094860730321074,
        '72': 32.094540080265965,
        '73': 32.09426062124033,
        '74': 32.094309367163234,
        '75': 32.094393915769096,
        '76': 32.094190947900074,
        '77': 32.09395307840799,
        '78': 32.094091248547485,
        '79': 32.09443557041037,
        '80': 32.09449223501573,
        '81': 32.09427940016914,
        '82': 32.094257226039225,
        '83': 32.09445681723878,
        '84': 32.09438414793646,
        '85': 32.09391725786591,
        '86': 32.09360339206331,
        '87': 32.09379855878786,
        '88': 32.09414167479866,
        '89': 32.094217779213146,
        '90': 32.09415355062711,
        '91': 32.09416855554465,
        '92': 32.094115847075265,
        '93': 32.09393945192704,
        '94': 32.09404030657672,
        '95': 32.09462035996848,
        '96': 32.09513550818255,
        '97': 32.09503023557719,
        '98': 32.094623083239206,
        '99': 32.094593309102855,
        '100': 32.09491760528709,
        '101': 32.09504658730616,
        '102': 32.09490784969226,
        '103': 32.094962711495924,
        '104': 32.09534808476293,
        '105': 32.09568015815558,
        '106': 32.09573219343426,
        '107': 32.09569842735659,
        '108': 32.09575071890546,
        '109': 32.09584925200237,
        '110': 32.09597522383082,
        '111': 32.09607815596744,
        '112': 32.09589273960107,
        '113': 32.09530285478501,
        '114': 32.09472867678138,
        '115': 32.09461562710398,
        '116': 32.094739937576854,
        '117': 32.09460844241678,
        '118': 32.094308459508554,
        '119': 32.094299648651194,
        '120': 32.09459838198527,
        '121': 32.09487840512086,
        '122': 32.095153363734404,
        '123': 32.095622006599356,
        '124': 32.09606332714332,
        '125': 32.09614127307541,
        '126': 32.096079698905896,
        '127': 32.09627844381757,
        '128': 32.09652068710724,
        '129': 32.0963465520351,
        '130': 32.09594620481573,
        '131': 32.09587440545089,
        '132': 32.09611421300993,
        '133': 32.095770043316804,
        '134': 32.095747716994644,
        '135': 32.095877474358176,
        '136': 32.09586442018699,
        '137': 32.09589614782088,
        '138': 32.09595440382645,
        '139': 32.09589332015001,
        '140': 32.095477757250826,
        '141': 32.09500849947926,
        '142': 32.095128249451804,
        '143': 32.0958513834438,
        '144': 32.096422488536874,
        '145': 32.09579884930033,
        '146': 32.09491665019804,
        '147': 32.09460734125528,
        '148': 32.09463511595615,
        '149': 32.09450543833238,
        '150': 32.09446315750238,
        '151': 32.09486413113523,
        '152': 32.095361316763466,
        '153': 32.09550805122233,
        '154': 32.095494038149425,
        '155': 32.095632329206026,
        '156': 32.095768117452536,
        '157': 32.09572489018787,
        '158': 32.095683951926624,
        '159': 32.0957201422185,
        '160': 32.09564082594276,
        '161': 32.09553096272012,
        '162': 32.0956525865174,
        '163': 32.0956504477653,
        '164': 32.09487438717008,
        '165': 32.09373576237144,
        '166': 32.09360607729337,
        '167': 32.0949161410835,
        '168': 32.09641082353387,
        '169': 32.09683683069373,
        '170': 32.096411761635174,
        '171': 32.096029729120836,
        '172': 32.09591443346379,
        '173': 32.09578980889289,
        '174': 32.09567797581173,
        '175': 32.09573946271454,
        '176': 32.09576508697207,
        '177': 32.09550930737454,
        '178': 32.095234960724746,
        '179': 32.095350109580494,
        '180': 32.095708446413276,
        '-180': 32.095708446413276,
        '-179': 32.09578442558792,
        '-178': 32.09543421620091,
        '-177': 32.095036438040914,
        '-176': 32.09490804831305,
        '-175': 32.094970914633755,
        '-174': 32.095039362139,
        '-173': 32.095089391800975,
        '-172': 32.09511554838704,
        '-171': 32.09500951756033,
        '-170': 32.09477609380685,
        '-169': 32.094647319912326,
        '-168': 32.094752627890834,
        '-167': 32.09486775057687,
        '-166': 32.09476870214075,
        '-165': 32.094659483420564,
        '-164': 32.09488446247158,
        '-163': 32.095322780244906,
        '-162': 32.095489012112175,
        '-161': 32.095225431284106,
        '-160': 32.0948833294861,
        '-159': 32.09477078572186,
        '-158': 32.0948126744918,
        '-157': 32.09484151005329,
        '-156': 32.09485433629236,
        '-155': 32.094862014280025,
        '-154': 32.094800926912576,
        '-153': 32.09470835169686,
        '-152': 32.094720790533664,
        '-151': 32.09482715336906,
        '-150': 32.0948677635375,
        '-149': 32.094804475310426,
        '-148': 32.09474853540082,
        '-147': 32.09473036955384,
        '-146': 32.09469260843109,
        '-145': 32.09467667432792,
        '-144': 32.09475680810072,
        '-143': 32.09483789870903,
        '-142': 32.09478264806623,
        '-141': 32.09468556621915,
        '-140': 32.09475752020572,
        '-139': 32.09498090278311,
        '-138': 32.09512161383995,
        '-137': 32.0950578901383,
        '-136': 32.09490413800395,
        '-135': 32.09483062173789,
        '-134': 32.094891944386525,
        '-133': 32.09500582834737,
        '-132': 32.09504415860711,
        '-131': 32.094991930831114,
        '-130': 32.09498412604243,
        '-129': 32.09508898700663,
        '-128': 32.09516571561845,
        '-127': 32.095102619739805,
        '-126': 32.0950335258869,
        '-125': 32.09509058100113,
        '-124': 32.09514489862259,
        '-123': 32.095070718319285,
        '-122': 32.09502688438564,
        '-121': 32.095150700314115,
        '-120': 32.09524251199202,
        '-119': 32.09512052630446,
        '-118': 32.09499899440104,
        '-117': 32.09512029297533,
        '-116': 32.09531309021257,
        '-115': 32.095318787178854,
        '-114': 32.095243485860806,
        '-113': 32.09528845742174,
        '-112': 32.09535561154751,
        '-111': 32.09529844868513,
        '-110': 32.0952352314949,
        '-109': 32.09527892363172,
        '-108': 32.095298332528834,
        '-107': 32.09521518970901,
        '-106': 32.09516312501963,
        '-105': 32.095155558076165,
        '-104': 32.095023276038106,
        '-103': 32.09481937980001,
        '-102': 32.094807388165385,
        '-101': 32.0949572510794,
        '-100': 32.09496762992979,
        '-99': 32.094825450575115,
        '-98': 32.09479992945858,
        '-97': 32.09489825359753,
        '-96': 32.094890237904835,
        '-95': 32.09480411551738,
        '-94': 32.09483569932058,
        '-93': 32.09489791555947,
        '-92': 32.09480592114127,
        '-91': 32.094697312778486,
        '-90': 32.094752814521264,
        '-89': 32.094814329528184,
        '-88': 32.09475623379178,
        '-87': 32.094777970354215,
        '-86': 32.09488098404155,
        '-85': 32.09471368057528,
        '-84': 32.09441204975767,
        '-83': 32.09467817386809,
        '-82': 32.095365565686535,
        '-81': 32.09512130835723,
        '-80': 32.093494955902244,
        '-79': 32.09234750390836,
        '-78': 32.09370661403185,
        '-77': 32.094469818197254,
        '-76': 32.047504567819196,
        '-75': 32.05448429661767,
        '-74': 32.09249865169104,
        '-73': 32.08814956400432,
        '-72': 32.08731322281104,
        '-71': 32.09107913018347,
        '-70': 32.092242318415536,
        '-69': 32.092179792828496,
        '-68': 32.09336068570633,
        '-67': 32.09478865742297,
        '-66': 32.09538828897228,
        '-65': 32.09534433842125,
        '-64': 32.094140744105054,
        '-63': 32.09396146526942,
        '-62': 32.091604479457956,
        '-61': 32.09276614729488,
        '-60': 32.091616123567924,
        '-59': 32.0921002124167,
        '-58': 32.09276924755455,
        '-57': 32.091684396577506,
        '-56': 32.09071534765257,
        '-55': 32.091124474222966,
        '-54': 32.0915059443015,
        '-53': 32.091630935062526,
        '-52': 32.091189742168375,
        '-51': 32.092231428491054,
        '-50': 32.09239730418815,
        '-49': 32.0919284912326,
        '-48': 32.090644409544275,
        '-47': 32.09067664272134,
        '-46': 32.08641268252908,
        '-45': 32.089997136549705,
        '-44': 32.0871381222767,
        '-43': 32.08971439020816,
        '-42': 32.086063988508926,
        '-41': 32.08846675785379,
        '-40': 32.09194164957007,
        '-39': 32.09241038185974,
        '-38': 32.093547647176315,
        '-37': 32.09471177508577,
        '-36': 32.09458269062979,
        '-35': 32.0943958578486,
        '-34': 32.09453929005871,
        '-33': 32.09495511007264,
        '-32': 32.095101860671406,
        '-31': 32.09479566947312,
        '-30': 32.09448306331236,
        '-29': 32.094500322628754,
        '-28': 32.09462604864206,
        '-27': 32.094588041647526,
        '-26': 32.09449743008231,
        '-25': 32.09452330130328,
        '-24': 32.094574897276324,
        '-23': 32.09455967179592,
        '-22': 32.094595104665494,
        '-21': 32.09474820348213,
        '-20': 32.094870249671324,
        '-19': 32.094860201068656,
        '-18': 32.094829721163954,
        '-17': 32.094885187084415,
        '-16': 32.09497306048535,
        '-15': 32.09501512861027,
        '-14': 32.09500185646533,
        '-13': 32.0949277410093,
        '-12': 32.09480367598361,
        '-11': 32.094711772281215,
        '-10': 32.09470629126402,
        '-9': 32.09473314704355,
        '-8': 32.094765143405695,
        '-7': 32.09487677355194,
        '-6': 32.09505429979364,
        '-5': 32.09511335280037,
        '-4': 32.094964218237116,
        '-3': 32.09477715844252,
        '-2': 32.09472405976249,
        '-1': 32.094741591551454
    },
    '-13': {
        '0': 32.09599290700035,
        '1': 32.09593880099987,
        '2': 32.09603047401474,
        '3': 32.09611796093667,
        '4': 32.096092076194935,
        '5': 32.09608213998691,
        '6': 32.096208705716236,
        '7': 32.096351974854805,
        '8': 32.09634626964697,
        '9': 32.096244005041406,
        '10': 32.09621284362996,
        '11': 32.09629834369643,
        '12': 32.0964653819542,
        '13': 32.096201998135214,
        '14': 32.08935108789649,
        '15': 32.077937323298656,
        '16': 32.080844594265265,
        '17': 32.08262678478133,
        '18': 32.08315587082867,
        '19': 32.08298323685776,
        '20': 32.082860581201494,
        '21': 32.08440118005307,
        '22': 32.08519280667196,
        '23': 32.08518533060112,
        '24': 32.084312480614685,
        '25': 32.084851626626445,
        '26': 32.082734122347695,
        '27': 32.08364176661284,
        '28': 32.08374735510793,
        '29': 32.08325756129518,
        '30': 32.083672317865165,
        '31': 32.0800464627302,
        '32': 32.08994246009079,
        '33': 32.084405706803324,
        '34': 32.0864593708933,
        '35': 32.08298485927688,
        '36': 32.08805347768238,
        '37': 32.090371781093076,
        '38': 32.09011118196686,
        '39': 32.091215189372164,
        '40': 32.093724915895024,
        '41': 32.095710301367696,
        '42': 32.095359601423134,
        '43': 32.09568240380172,
        '44': 32.09609237495181,
        '45': 32.095866238057205,
        '46': 32.09549331057799,
        '47': 32.09597948024205,
        '48': 32.097131049157355,
        '49': 32.097457028638324,
        '50': 32.09709690721459,
        '51': 32.09620274030072,
        '52': 32.095829352008145,
        '53': 32.09587004638014,
        '54': 32.09596951255465,
        '55': 32.09620697864407,
        '56': 32.096590467346736,
        '57': 32.096650261903534,
        '58': 32.09615816495891,
        '59': 32.09569089082558,
        '60': 32.09585383423533,
        '61': 32.096355836176514,
        '62': 32.09647573400917,
        '63': 32.09615953670038,
        '64': 32.09600984188452,
        '65': 32.096293823711726,
        '66': 32.096577213768406,
        '67': 32.09644463647556,
        '68': 32.09609614759432,
        '69': 32.095970402531066,
        '70': 32.09607892591138,
        '71': 32.09605866044216,
        '72': 32.09578259508474,
        '73': 32.095555674672916,
        '74': 32.09561318721474,
        '75': 32.09574091877939,
        '76': 32.09564116672122,
        '77': 32.095442373162655,
        '78': 32.09548583661561,
        '79': 32.09574392753689,
        '80': 32.09587874056991,
        '81': 32.09582278365359,
        '82': 32.09581632142475,
        '83': 32.09584797924397,
        '84': 32.09560807133061,
        '85': 32.09513253961197,
        '86': 32.09492782244817,
        '87': 32.095209755780246,
        '88': 32.09554627144965,
        '89': 32.095549188632425,
        '90': 32.095397360614015,
        '91': 32.09534049536876,
        '92': 32.09524739377472,
        '93': 32.095085139279476,
        '94': 32.095277521512784,
        '95': 32.09599766397936,
        '96': 32.09658306584376,
        '97': 32.0963472891524,
        '98': 32.095639328453416,
        '99': 32.09539131588042,
        '100': 32.095835316974515,
        '101': 32.096351498190366,
        '102': 32.09644216211956,
        '103': 32.096197227171096,
        '104': 32.095877436495954,
        '105': 32.095658715651325,
        '106': 32.09576087543843,
        '107': 32.096270755567744,
        '108': 32.09684782988813,
        '109': 32.097079748012625,
        '110': 32.09705567764895,
        '111': 32.097129699305235,
        '112': 32.097273942915834,
        '113': 32.09720295570877,
        '114': 32.09699088339983,
        '115': 32.09697226597494,
        '116': 32.09711334808246,
        '117': 32.0970512150631,
        '118': 32.09671253344516,
        '119': 32.09639872201032,
        '120': 32.09633133747077,
        '121': 32.09653463883358,
        '122': 32.09699130407697,
        '123': 32.09749016794995,
        '124': 32.09758436255418,
        '125': 32.09715693697315,
        '126': 32.09671168560291,
        '127': 32.096653295482255,
        '128': 32.09670835634458,
        '129': 32.09656754761195,
        '130': 32.09656175609957,
        '131': 32.096259771104535,
        '132': 32.09710018634199,
        '133': 32.09430881469446,
        '134': 32.094471591529654,
        '135': 32.096627315396475,
        '136': 32.09681684752971,
        '137': 32.09706191528006,
        '138': 32.09701897155525,
        '139': 32.09697328439696,
        '140': 32.09675174995579,
        '141': 32.096411542376906,
        '142': 32.09613319511913,
        '143': 32.09541518895318,
        '144': 32.09716090115261,
        '145': 32.09696430873344,
        '146': 32.09669627014482,
        '147': 32.0966545847591,
        '148': 32.09645478229103,
        '149': 32.09592443845592,
        '150': 32.095651749339574,
        '151': 32.0959943969979,
        '152': 32.096412344018034,
        '153': 32.09644664755297,
        '154': 32.09649423285892,
        '155': 32.09693656211778,
        '156': 32.09735816705267,
        '157': 32.09732562157345,
        '158': 32.09713706638999,
        '159': 32.09715690683615,
        '160': 32.09721078678791,
        '161': 32.09720619317273,
        '162': 32.09744284777366,
        '163': 32.09771142016204,
        '164': 32.09712327569745,
        '165': 32.095632379736855,
        '166': 32.0947239787817,
        '167': 32.09557538302591,
        '168': 32.09732024800813,
        '169': 32.09821307480109,
        '170': 32.09789620609807,
        '171': 32.097396711476264,
        '172': 32.097335034800345,
        '173': 32.0973580847662,
        '174': 32.097214346690954,
        '175': 32.09722102717163,
        '176': 32.09746340015047,
        '177': 32.09746465821024,
        '178': 32.09701786483082,
        '179': 32.0966411711671,
        '180': 32.09677861397222,
        '-180': 32.09677861397222,
        '-179': 32.097092680238404,
        '-178': 32.09702163149697,
        '-177': 32.096619647016595,
        '-176': 32.09633638791695,
        '-175': 32.09628501588915,
        '-174': 32.096281977905676,
        '-173': 32.096376777938346,
        '-172': 32.09672184276394,
        '-171': 32.09703155977619,
        '-170': 32.09682362614939,
        '-169': 32.096212149642376,
        '-168': 32.095837004286565,
        '-167': 32.0959292999578,
        '-166': 32.096071154377505,
        '-165': 32.096008749667604,
        '-164': 32.0960372562554,
        '-163': 32.09633232997662,
        '-162': 32.09653436031101,
        '-161': 32.096353991251654,
        '-160': 32.09606539275163,
        '-159': 32.09602434816113,
        '-158': 32.09613598564293,
        '-157': 32.09614981598501,
        '-156': 32.09609120311806,
        '-155': 32.09607386601899,
        '-154': 32.09603103553237,
        '-153': 32.0959219823329,
        '-152': 32.09589481811713,
        '-151': 32.096007449263396,
        '-150': 32.096080839913604,
        '-149': 32.09600949141367,
        '-148': 32.09591877277687,
        '-147': 32.09588297160055,
        '-146': 32.095811834277534,
        '-145': 32.09572728151427,
        '-144': 32.09580557848668,
        '-143': 32.09602889141553,
        '-142': 32.09613682691253,
        '-141': 32.09604138055355,
        '-140': 32.09596504485769,
        '-139': 32.096053249768474,
        '-138': 32.096158928798005,
        '-137': 32.096135488154616,
        '-136': 32.096069049875744,
        '-135': 32.09608462235089,
        '-134': 32.09613830694866,
        '-133': 32.096134248682816,
        '-132': 32.09609913919269,
        '-131': 32.09612502352574,
        '-130': 32.09621614823346,
        '-129': 32.096278709517755,
        '-128': 32.09625359249756,
        '-127': 32.096209871923044,
        '-126': 32.09625318842398,
        '-125': 32.09635352007549,
        '-124': 32.096378114779405,
        '-123': 32.09632214401625,
        '-122': 32.09633334606173,
        '-121': 32.096437489255244,
        '-120': 32.096464730291515,
        '-119': 32.09636572806436,
        '-118': 32.09633885684514,
        '-117': 32.0964853636541,
        '-116': 32.0966195204243,
        '-115': 32.096600429256604,
        '-114': 32.096555617490615,
        '-113': 32.096591853893116,
        '-112': 32.09659814626341,
        '-111': 32.096515844952144,
        '-110': 32.09648138154527,
        '-109': 32.09654283492658,
        '-108': 32.09656293428657,
        '-107': 32.096512241747504,
        '-106': 32.09650757630414,
        '-105': 32.09650749254344,
        '-104': 32.096359736463924,
        '-103': 32.09615713659118,
        '-102': 32.09612610981043,
        '-101': 32.09620544411211,
        '-100': 32.09617648546056,
        '-99': 32.096100992116575,
        '-98': 32.0961594758791,
        '-97': 32.09623184964607,
        '-96': 32.09613791917358,
        '-95': 32.09605244946218,
        '-94': 32.096165264698676,
        '-93': 32.096251908224005,
        '-92': 32.09611692937432,
        '-91': 32.09604297792193,
        '-90': 32.096229983681646,
        '-89': 32.09631407819229,
        '-88': 32.09606625342847,
        '-87': 32.095928736949055,
        '-86': 32.09614868311962,
        '-85': 32.09614571020697,
        '-84': 32.095619406639244,
        '-83': 32.09543492612004,
        '-82': 32.09623818104282,
        '-81': 32.09694561522282,
        '-80': 32.09609930062253,
        '-79': 32.09435155903763,
        '-78': 32.09403595210539,
        '-77': 32.096140494145175,
        '-76': 32.068081798535275,
        '-75': 32.052911879534996,
        '-74': 32.066146738700475,
        '-73': 32.06070409777474,
        '-72': 32.06249172003982,
        '-71': 32.08975594714544,
        '-70': 32.09449832813743,
        '-69': 32.09410869445691,
        '-68': 32.09446615722381,
        '-67': 32.09538262694167,
        '-66': 32.096447831121544,
        '-65': 32.096648832427775,
        '-64': 32.096272552472605,
        '-63': 32.09576307434148,
        '-62': 32.09523607475756,
        '-61': 32.09414534182827,
        '-60': 32.091326044541546,
        '-59': 32.09324148106131,
        '-58': 32.093341420255825,
        '-57': 32.09276261070567,
        '-56': 32.091763917755394,
        '-55': 32.091782771011225,
        '-54': 32.09292058851461,
        '-53': 32.092728965616374,
        '-52': 32.091743582564305,
        '-51': 32.093594183540084,
        '-50': 32.09333495262555,
        '-49': 32.091787760699795,
        '-48': 32.087944039480114,
        '-47': 32.08955292564286,
        '-46': 32.08753314259554,
        '-45': 32.08877572040858,
        '-44': 32.090738705999485,
        '-43': 32.08661767811801,
        '-42': 32.0829141327422,
        '-41': 32.090129889934865,
        '-40': 32.09107879697206,
        '-39': 32.09580957763108,
        '-38': 32.0961114321413,
        '-37': 32.09591528118373,
        '-36': 32.09575180678785,
        '-35': 32.09572267362456,
        '-34': 32.095980139124386,
        '-33': 32.0963423617487,
        '-32': 32.09643156319886,
        '-31': 32.09620014977784,
        '-30': 32.095966216256635,
        '-29': 32.09592201707263,
        '-28': 32.09594129366429,
        '-27': 32.09590023658476,
        '-26': 32.095866657530465,
        '-25': 32.095894682865314,
        '-24': 32.095899582806005,
        '-23': 32.09583458596717,
        '-22': 32.09579461501653,
        '-21': 32.0958611795738,
        '-20': 32.095985507418874,
        '-19': 32.09607997001679,
        '-18': 32.09612695129829,
        '-17': 32.0961709387556,
        '-16': 32.096255164085264,
        '-15': 32.09635348021188,
        '-14': 32.096359801266296,
        '-13': 32.09621882338099,
        '-12': 32.09604958082653,
        '-11': 32.096008854700756,
        '-10': 32.096062435850115,
        '-9': 32.09607481848549,
        '-8': 32.096082905315406,
        '-7': 32.096227762967025,
        '-6': 32.09643286536644,
        '-5': 32.09645178781049,
        '-4': 32.096264129035106,
        '-3': 32.096116428223155,
        '-2': 32.096117016461996,
        '-1': 32.096103905517076
    },
    '-14': {
        '0': 32.097473355630235,
        '1': 32.09739690249931,
        '2': 32.0974849807785,
        '3': 32.09754045163093,
        '4': 32.09745651433502,
        '5': 32.09740377900417,
        '6': 32.097507875114246,
        '7': 32.09761544745579,
        '8': 32.09754948795617,
        '9': 32.097396181936844,
        '10': 32.097375509245964,
        '11': 32.09756697225435,
        '12': 32.09789562348088,
        '13': 32.092058085920684,
        '14': 32.089995320151935,
        '15': 32.08349642494926,
        '16': 32.0819134337441,
        '17': 32.08244947363507,
        '18': 32.08286180193892,
        '19': 32.08330282804861,
        '20': 32.08455454048737,
        '21': 32.084971739002555,
        '22': 32.086618169352924,
        '23': 32.08677972518857,
        '24': 32.08648475953659,
        '25': 32.085734858013595,
        '26': 32.085200140256795,
        '27': 32.08532344234499,
        '28': 32.0857617743644,
        '29': 32.08549853248975,
        '30': 32.08539133787204,
        '31': 32.090599913560716,
        '32': 32.08666335016563,
        '33': 32.08528041518098,
        '34': 32.08689320591357,
        '35': 32.0932260466007,
        '36': 32.09105484634341,
        '37': 32.090349342782446,
        '38': 32.09249634345501,
        '39': 32.09440001581668,
        '40': 32.094248835702025,
        '41': 32.09687213977527,
        '42': 32.096293002768746,
        '43': 32.09644890171333,
        '44': 32.09676736756495,
        '45': 32.09646981493078,
        '46': 32.09611244349269,
        '47': 32.09687216293465,
        '48': 32.09835506723567,
        '49': 32.075352193424166,
        '50': 32.09852697748177,
        '51': 32.0977424982798,
        '52': 32.097024673386464,
        '53': 32.09704489082619,
        '54': 32.09733811138645,
        '55': 32.09764764993539,
        '56': 32.09787995961883,
        '57': 32.097822599886484,
        '58': 32.09749075854223,
        '59': 32.09733070348502,
        '60': 32.0976052980355,
        '61': 32.09793263852169,
        '62': 32.09785087407748,
        '63': 32.09751725684029,
        '64': 32.09742457933963,
        '65': 32.09766533233266,
        '66': 32.09788978837029,
        '67': 32.09786338574588,
        '68': 32.097706721183386,
        '69': 32.0976068902375,
        '70': 32.09755416016124,
        '71': 32.09742534254073,
        '72': 32.09720415546131,
        '73': 32.09703524947184,
        '74': 32.097044220049696,
        '75': 32.097149384582096,
        '76': 32.09715918218889,
        '77': 32.097057379348946,
        '78': 32.09702310197747,
        '79': 32.0971268831193,
        '80': 32.09722260493386,
        '81': 32.097214010954644,
        '82': 32.09717175994383,
        '83': 32.09709690792625,
        '84': 32.096890586252506,
        '85': 32.09668587320904,
        '86': 32.09681330524157,
        '87': 32.09722772173225,
        '88': 32.0974016164344,
        '89': 32.09707929108638,
        '90': 32.09669099716147,
        '91': 32.0966559328885,
        '92': 32.09675040709221,
        '93': 32.09664955194394,
        '94': 32.09662754047752,
        '95': 32.09707081693841,
        '96': 32.09763060517796,
        '97': 32.0975960842076,
        '98': 32.096973354297425,
        '99': 32.096517270161115,
        '100': 32.096707073131334,
        '101': 32.097251244978395,
        '102': 32.09761189139523,
        '103': 32.09753865091325,
        '104': 32.097101960136015,
        '105': 32.096638441808125,
        '106': 32.09661623029088,
        '107': 32.097134764520106,
        '108': 32.09764236391718,
        '109': 32.097569975259944,
        '110': 32.09711662285156,
        '111': 32.09694582592124,
        '112': 32.097245649366904,
        '113': 32.097611734118246,
        '114': 32.09776018878793,
        '115': 32.097802434186036,
        '116': 32.097838552605545,
        '117': 32.097796949344534,
        '118': 32.09771560849849,
        '119': 32.097767959014,
        '120': 32.09798281444096,
        '121': 32.098234040442186,
        '122': 32.09843146779132,
        '123': 32.09848177194484,
        '124': 32.098265447757704,
        '125': 32.097902524891815,
        '126': 32.09773899448795,
        '127': 32.097267568245734,
        '128': 32.09776312282217,
        '129': 32.097533649994816,
        '130': 32.09698723547257,
        '131': 32.097806160753414,
        '132': 32.09728114060057,
        '133': 32.09573428798858,
        '134': 32.09652769475994,
        '135': 32.09738729656362,
        '136': 32.098277009927514,
        '137': 32.09814044199715,
        '138': 32.09780213787448,
        '139': 32.097700250943895,
        '140': 32.09786727165056,
        '141': 32.09806953749499,
        '142': 32.097854466891185,
        '143': 32.09677401781003,
        '144': 32.09843822405118,
        '145': 32.09821099966282,
        '146': 32.09821717913276,
        '147': 32.09849031508246,
        '148': 32.098470676825436,
        '149': 32.09797921706571,
        '150': 32.09760538197546,
        '151': 32.09766853430617,
        '152': 32.097685781797225,
        '153': 32.09741013918978,
        '154': 32.097428450480194,
        '155': 32.09806239897298,
        '156': 32.098635058186154,
        '157': 32.09857829214795,
        '158': 32.098343723335546,
        '159': 32.0984459369346,
        '160': 32.0985649284982,
        '161': 32.098436481430504,
        '162': 32.09859787416099,
        '163': 32.099227071161444,
        '164': 32.0992758995362,
        '165': 32.09804926986453,
        '166': 32.09678182666933,
        '167': 32.097130932292366,
        '168': 32.098669366850785,
        '169': 32.09948141096054,
        '170': 32.09896539934631,
        '171': 32.09838184686629,
        '172': 32.09857681157698,
        '173': 32.09883956601417,
        '174': 32.09849831939695,
        '175': 32.09814981026207,
        '176': 32.0984525322669,
        '177': 32.098856199210644,
        '178': 32.09856752958497,
        '179': 32.09795642520349,
        '180': 32.097912064540544,
        '-180': 32.097912064540544,
        '-179': 32.098360610277396,
        '-178': 32.09851452122994,
        '-177': 32.09822848146358,
        '-176': 32.09802228238467,
        '-175': 32.097981740163696,
        '-174': 32.097747614737486,
        '-173': 32.09751668845962,
        '-172': 32.0979010914761,
        '-171': 32.09867798653707,
        '-170': 32.098810174806815,
        '-169': 32.09800678716382,
        '-168': 32.0972188034478,
        '-167': 32.09717927942914,
        '-166': 32.09744006098984,
        '-165': 32.09737416278456,
        '-164': 32.0972006771186,
        '-163': 32.09734952793534,
        '-162': 32.0975868145158,
        '-161': 32.097505217745265,
        '-160': 32.09729611115663,
        '-159': 32.09734854172224,
        '-158': 32.09754595370226,
        '-157': 32.0975669274635,
        '-156': 32.09748145965795,
        '-155': 32.09751106123574,
        '-154': 32.09755886121982,
        '-153': 32.09745285647357,
        '-152': 32.097325971735444,
        '-151': 32.09735086752545,
        '-150': 32.0974163661344,
        '-149': 32.097399274273755,
        '-148': 32.09741737952083,
        '-147': 32.09752186706734,
        '-146': 32.09750373307849,
        '-145': 32.09728692678341,
        '-144': 32.09714965755658,
        '-143': 32.09728288536138,
        '-142': 32.09747484093012,
        '-141': 32.09748409462833,
        '-140': 32.09739336765307,
        '-139': 32.09736247971659,
        '-138': 32.09734957202208,
        '-137': 32.097298368776656,
        '-136': 32.097310368809794,
        '-135': 32.09742716761792,
        '-134': 32.097501269185365,
        '-133': 32.09745873881541,
        '-132': 32.097448386472635,
        '-131': 32.09756980220181,
        '-130': 32.09767894956826,
        '-129': 32.0976295802762,
        '-128': 32.09751915990333,
        '-127': 32.09752446729745,
        '-126': 32.097630133756645,
        '-125': 32.09768862675049,
        '-124': 32.09766789518688,
        '-123': 32.09767452292636,
        '-122': 32.097749268535466,
        '-121': 32.09779422446626,
        '-120': 32.09775244238233,
        '-119': 32.0977277329474,
        '-118': 32.09781752996079,
        '-117': 32.09793250900813,
        '-116': 32.09793705174043,
        '-115': 32.097882634220234,
        '-114': 32.097906758808264,
        '-113': 32.0979743434566,
        '-112': 32.09795278651199,
        '-111': 32.09787971539393,
        '-110': 32.097892778024395,
        '-109': 32.09795321406161,
        '-108': 32.09792110479979,
        '-107': 32.09783495192588,
        '-106': 32.09781975612595,
        '-105': 32.09781303660245,
        '-104': 32.097697036891,
        '-103': 32.097575846400844,
        '-102': 32.097591609249115,
        '-101': 32.09763436388092,
        '-100': 32.097574193240575,
        '-99': 32.09755058493027,
        '-98': 32.09766835784149,
        '-97': 32.09770607167001,
        '-96': 32.097525345906135,
        '-95': 32.09740489238072,
        '-94': 32.09753332798585,
        '-93': 32.09760961971206,
        '-92': 32.09742944838116,
        '-91': 32.09734398422311,
        '-90': 32.09757467814478,
        '-89': 32.09766997418347,
        '-88': 32.09731649271536,
        '-87': 32.097047260009674,
        '-86': 32.097326706969675,
        '-85': 32.09757831190626,
        '-84': 32.09713210142097,
        '-83': 32.096590981655254,
        '-82': 32.09703362537833,
        '-81': 32.09812060424895,
        '-80': 32.09829515631496,
        '-79': 32.09703057659107,
        '-78': 32.095794064419636,
        '-77': 32.0963057270886,
        '-76': 32.09373309487462,
        '-75': 32.07405823059215,
        '-74': 32.06016162115435,
        '-73': 32.062654824611,
        '-72': 32.06505760857091,
        '-71': 32.05446441560132,
        '-70': 32.05857993363136,
        '-69': 32.085645866010914,
        '-68': 32.093458452540766,
        '-67': 32.095251852243784,
        '-66': 32.09590968400278,
        '-65': 32.096768528421016,
        '-64': 32.097254983970444,
        '-63': 32.09724544047732,
        '-62': 32.09639399774937,
        '-61': 32.09569270747435,
        '-60': 32.09557008273415,
        '-59': 32.09289926088207,
        '-58': 32.0921164897003,
        '-57': 32.092662347782905,
        '-56': 32.09315776310317,
        '-55': 32.09277819271086,
        '-54': 32.092276422267744,
        '-53': 32.09389573289583,
        '-52': 32.09419965988116,
        '-51': 32.09503107388068,
        '-50': 32.09469288318005,
        '-49': 32.09185091386727,
        '-48': 32.09147415128704,
        '-47': 32.091954836892874,
        '-46': 32.0881755687779,
        '-45': 32.09123331403174,
        '-44': 32.09286078326218,
        '-43': 32.092043572524126,
        '-42': 32.091960480272306,
        '-41': 32.09483677048234,
        '-40': 32.09094046470612,
        '-39': 32.09764848931258,
        '-38': 32.09720232671503,
        '-37': 32.09689332859672,
        '-36': 32.09686923832884,
        '-35': 32.09709509289897,
        '-34': 32.09741113986809,
        '-33': 32.09760300432932,
        '-32': 32.09760227022832,
        '-31': 32.09753690298695,
        '-30': 32.09751434479128,
        '-29': 32.09748678754776,
        '-28': 32.097394259371704,
        '-27': 32.09729268169,
        '-26': 32.09725580428049,
        '-25': 32.097264994458456,
        '-24': 32.09726429952091,
        '-23': 32.09723740443428,
        '-22': 32.097198911979625,
        '-21': 32.09718810265167,
        '-20': 32.097259704996794,
        '-19': 32.09739440878401,
        '-18': 32.09747425722279,
        '-17': 32.097456964342875,
        '-16': 32.09747715944723,
        '-15': 32.09762232025875,
        '-14': 32.097735304790255,
        '-13': 32.09764883245648,
        '-12': 32.09748583248437,
        '-11': 32.09746949171955,
        '-10': 32.09755786028092,
        '-9': 32.097564267017276,
        '-8': 32.097526974372066,
        '-7': 32.09761742853717,
        '-6': 32.097764982474594,
        '-5': 32.097743489091265,
        '-4': 32.09758747508665,
        '-3': 32.097546328805045,
        '-2': 32.09763836004004,
        '-1': 32.0976293830293
    },
    '-15': {
        '0': 32.098974488831416,
        '1': 32.098929477510865,
        '2': 32.0989989044853,
        '3': 32.09899349033202,
        '4': 32.0988570249634,
        '5': 32.098767663953474,
        '6': 32.098816981526454,
        '7': 32.09886148619764,
        '8': 32.09878784957123,
        '9': 32.09871017138216,
        '10': 32.098805383950875,
        '11': 32.09910256927257,
        '12': 32.09949131304511,
        '13': 32.095011959870234,
        '14': 32.08484555052577,
        '15': 32.08602333250305,
        '16': 32.08531592561585,
        '17': 32.08513943576588,
        '18': 32.08469921824558,
        '19': 32.085817076618476,
        '20': 32.086491114610325,
        '21': 32.0872925006817,
        '22': 32.08812905863664,
        '23': 32.0884424389309,
        '24': 32.0876495355156,
        '25': 32.08712829066129,
        '26': 32.08824302590186,
        '27': 32.08719163776245,
        '28': 32.08742943174008,
        '29': 32.09107158671892,
        '30': 32.09012839006435,
        '31': 32.08796238730836,
        '32': 32.086339870584546,
        '33': 32.09321415669602,
        '34': 32.0899913976348,
        '35': 32.093394995786106,
        '36': 32.09225462200265,
        '37': 32.09209462211858,
        '38': 32.09125049553411,
        '39': 32.094196044454236,
        '40': 32.09726800151733,
        '41': 32.098222802202876,
        '42': 32.09770122350813,
        '43': 32.09788175032987,
        '44': 32.09817896165729,
        '45': 32.09780651097827,
        '46': 32.09731564077401,
        '47': 32.09793202742802,
        '48': 32.09915757019449,
        '49': 32.08888648210673,
        '50': 32.09428914745963,
        '51': 32.09916126871891,
        '52': 32.098522365711446,
        '53': 32.098675602784446,
        '54': 32.099100637774406,
        '55': 32.09932885138081,
        '56': 32.09923306083519,
        '57': 32.098930598957175,
        '58': 32.09877253721116,
        '59': 32.09907183957914,
        '60': 32.099610410553666,
        '61': 32.09978633304744,
        '62': 32.099418621958066,
        '63': 32.09899841256641,
        '64': 32.098974977331515,
        '65': 32.09921692801885,
        '66': 32.099379130769016,
        '67': 32.099394735019814,
        '68': 32.099357884044494,
        '69': 32.099251721126926,
        '70': 32.09904482103634,
        '71': 32.098830172124146,
        '72': 32.09868953137167,
        '73': 32.09859568270662,
        '74': 32.09853864632679,
        '75': 32.09856494098817,
        '76': 32.09864552043812,
        '77': 32.0986854327352,
        '78': 32.09867556508223,
        '79': 32.09866983127014,
        '80': 32.09864869051339,
        '81': 32.0985639032366,
        '82': 32.09844089145321,
        '83': 32.09830532863878,
        '84': 32.0981645404534,
        '85': 32.09817833945721,
        '86': 32.098554761614864,
        '87': 32.099076180931824,
        '88': 32.099120605930004,
        '89': 32.098502490472086,
        '90': 32.097897990586866,
        '91': 32.097963250928096,
        '92': 32.098395059625524,
        '93': 32.09845813322962,
        '94': 32.09814662038227,
        '95': 32.09810266996154,
        '96': 32.098509212878675,
        '97': 32.09881062138358,
        '98': 32.098607776945265,
        '99': 32.098200778213425,
        '100': 32.098066620330904,
        '101': 32.09826595037495,
        '102': 32.09853490451874,
        '103': 32.0986234436201,
        '104': 32.09845070157106,
        '105': 32.09819833447927,
        '106': 32.09823087727538,
        '107': 32.09863570953197,
        '108': 32.09894709216615,
        '109': 32.0986901702787,
        '110': 32.098084364151575,
        '111': 32.09778537789991,
        '112': 32.09801287896891,
        '113': 32.09837363743354,
        '114': 32.09849097570278,
        '115': 32.09836343488915,
        '116': 32.09813192841574,
        '117': 32.09793561754551,
        '118': 32.098010207128794,
        '119': 32.0985248840392,
        '120': 32.09926794237586,
        '121': 32.09978960811474,
        '122': 32.09986993358785,
        '123': 32.099653703190285,
        '124': 32.09943100464083,
        '125': 32.099455009111566,
        '126': 32.095871131590194,
        '127': 32.09689339778033,
        '128': 32.09761871274842,
        '129': 32.09911526303152,
        '130': 32.09800195968543,
        '131': 32.09676522925268,
        '132': 32.09706622296087,
        '133': 32.097387314070716,
        '134': 32.098201757294234,
        '135': 32.09889396316736,
        '136': 32.09974942189868,
        '137': 32.09959761169419,
        '138': 32.09908394640535,
        '139': 32.09883134558702,
        '140': 32.09900302077189,
        '141': 32.099343443295275,
        '142': 32.09946105312833,
        '143': 32.09866209936179,
        '144': 32.099608644939806,
        '145': 32.09674301136745,
        '146': 32.099522496515334,
        '147': 32.09962922666218,
        '148': 32.09976423630482,
        '149': 32.09966325662216,
        '150': 32.099551277016424,
        '151': 32.099535301063916,
        '152': 32.099287687968335,
        '153': 32.09882228850866,
        '154': 32.09877738101774,
        '155': 32.0993436464164,
        '156': 32.099764946032046,
        '157': 32.09956580216991,
        '158': 32.09935476501605,
        '159': 32.09963695462992,
        '160': 32.09983293832538,
        '161': 32.099455060825754,
        '162': 32.09923577703296,
        '163': 32.09984050194515,
        '164': 32.100390873930195,
        '165': 32.0997976420024,
        '166': 32.098842152609166,
        '167': 32.09681715267097,
        '168': 32.10051200382687,
        '169': 32.10105947450002,
        '170': 32.10025549107901,
        '171': 32.09961006841245,
        '172': 32.10007572717761,
        '173': 32.100587501042114,
        '174': 32.100085682156795,
        '175': 32.09933625446851,
        '176': 32.09948679185882,
        '177': 32.10009898933834,
        '178': 32.10006513709911,
        '179': 32.09955176241426,
        '180': 32.09951595903726,
        '-180': 32.09951595903726,
        '-179': 32.09992680655485,
        '-178': 32.09999811174041,
        '-177': 32.09977026250731,
        '-176': 32.09990736949238,
        '-175': 32.10015529054436,
        '-174': 32.0996204328632,
        '-173': 32.098591415778415,
        '-172': 32.09843822022372,
        '-171': 32.099403136278966,
        '-170': 32.100062474792885,
        '-169': 32.09951754876782,
        '-168': 32.09866653945949,
        '-167': 32.09857205466865,
        '-166': 32.098882456914474,
        '-165': 32.098797419699885,
        '-164': 32.098498621278125,
        '-163': 32.09857684331866,
        '-162': 32.098861919473194,
        '-161': 32.098842441534195,
        '-160': 32.098650068029606,
        '-159': 32.09872188060728,
        '-158': 32.09895924188063,
        '-157': 32.099010042802206,
        '-156': 32.098971853150665,
        '-155': 32.09911678480699,
        '-154': 32.099291996919284,
        '-153': 32.09921233828709,
        '-152': 32.09900679218467,
        '-151': 32.0989447587201,
        '-150': 32.0989648784983,
        '-149': 32.09893587852367,
        '-148': 32.09900919009767,
        '-147': 32.09926914163387,
        '-146': 32.0994076920919,
        '-145': 32.09918208828048,
        '-144': 32.09885755918225,
        '-143': 32.098800629648316,
        '-142': 32.09894614007674,
        '-141': 32.09901511017956,
        '-140': 32.09895749408056,
        '-139': 32.09886655399353,
        '-138': 32.098741046408456,
        '-137': 32.09860653562646,
        '-136': 32.09860748777264,
        '-135': 32.09875954886539,
        '-134': 32.09887221070958,
        '-133': 32.09887791313765,
        '-132': 32.098943456434355,
        '-131': 32.099115292967994,
        '-130': 32.09918472687035,
        '-129': 32.099067137707415,
        '-128': 32.09898436184617,
        '-127': 32.09908317453447,
        '-126': 32.09918383209445,
        '-125': 32.09911716526842,
        '-124': 32.09902665000137,
        '-123': 32.09909631349478,
        '-122': 32.09921935861855,
        '-121': 32.09920366101431,
        '-120': 32.09912430057351,
        '-119': 32.09918674192236,
        '-118': 32.09935443773367,
        '-117': 32.09940495321904,
        '-116': 32.099311094390316,
        '-115': 32.0992778041512,
        '-114': 32.09938141429868,
        '-113': 32.099451514917824,
        '-112': 32.09938858910047,
        '-111': 32.099332958167324,
        '-110': 32.0993896466378,
        '-109': 32.09943329271582,
        '-108': 32.09935149183282,
        '-107': 32.09924957274867,
        '-106': 32.09922942381694,
        '-105': 32.09919749425911,
        '-104': 32.09909046598803,
        '-103': 32.09904447554389,
        '-102': 32.09912689581544,
        '-101': 32.099168746918295,
        '-100': 32.09908773636761,
        '-99': 32.09907085701467,
        '-98': 32.09919223724487,
        '-97': 32.099205537820445,
        '-96': 32.098998441274574,
        '-95': 32.098856637608435,
        '-94': 32.09895036178243,
        '-93': 32.098994440794534,
        '-92': 32.098797852696904,
        '-91': 32.098677539328165,
        '-90': 32.098866596132694,
        '-89': 32.098994504221864,
        '-88': 32.09871383519622,
        '-87': 32.09839822154228,
        '-86': 32.09856085187161,
        '-85': 32.09892737777723,
        '-84': 32.0988232942141,
        '-83': 32.09831262672213,
        '-82': 32.09820937002385,
        '-81': 32.098874899815264,
        '-80': 32.0995884276243,
        '-79': 32.09940907235174,
        '-78': 32.098406642323845,
        '-77': 32.09772852267006,
        '-76': 32.098395172067825,
        '-75': 32.094865614596465,
        '-74': 32.067732059093444,
        '-73': 32.05374704340095,
        '-72': 32.05597130530104,
        '-71': 32.06022208621119,
        '-70': 32.06175603063268,
        '-69': 32.051273573384634,
        '-68': 32.08521265057853,
        '-67': 32.095067193116655,
        '-66': 32.095435021279,
        '-65': 32.09624327967214,
        '-64': 32.09713959952915,
        '-63': 32.09708552627868,
        '-62': 32.09780185258831,
        '-61': 32.09794521040683,
        '-60': 32.09711816385524,
        '-59': 32.09333183763005,
        '-58': 32.09669266665418,
        '-57': 32.09669942687938,
        '-56': 32.09513208530015,
        '-55': 32.091498735200446,
        '-54': 32.09197863206456,
        '-53': 32.094063545373935,
        '-52': 32.095901413691415,
        '-51': 32.09655766105009,
        '-50': 32.093643710452625,
        '-49': 32.08783240375986,
        '-48': 32.08901684306827,
        '-47': 32.09285978427582,
        '-46': 32.09030639848964,
        '-45': 32.09172790626013,
        '-44': 32.09428780059379,
        '-43': 32.089376692728756,
        '-42': 32.091359460337706,
        '-41': 32.09083143863558,
        '-40': 32.095231147466876,
        '-39': 32.098747567962086,
        '-38': 32.09833900955551,
        '-37': 32.09802159227526,
        '-36': 32.098194483359016,
        '-35': 32.09865530082567,
        '-34': 32.09898051222479,
        '-33': 32.09897054730535,
        '-32': 32.09884970030743,
        '-31': 32.0988889583289,
        '-30': 32.0990088183828,
        '-29': 32.09897453120347,
        '-28': 32.09879864406031,
        '-27': 32.09867211710597,
        '-26': 32.098639432669664,
        '-25': 32.09861910821217,
        '-24': 32.09862471354303,
        '-23': 32.098701101046416,
        '-22': 32.09875747082103,
        '-21': 32.098713658756104,
        '-20': 32.098686109658956,
        '-19': 32.09878630264822,
        '-18': 32.0988865436805,
        '-17': 32.098846537015994,
        '-16': 32.098802768259944,
        '-15': 32.09893609042326,
        '-14': 32.09911597864408,
        '-13': 32.09910434414523,
        '-12': 32.0989696906865,
        '-11': 32.09895326793104,
        '-10': 32.0990468514229,
        '-9': 32.099060458880885,
        '-8': 32.09900588932232,
        '-7': 32.09904595486374,
        '-6': 32.0991405624282,
        '-5': 32.09910994730935,
        '-4': 32.09899560723865,
        '-3': 32.09900000237951,
        '-2': 32.09910463282216,
        '-1': 32.09909983677028
    },
    '-16': {
        '0': 32.100486121720905,
        '1': 32.10049876386806,
        '2': 32.100535358430896,
        '3': 32.100483861241116,
        '4': 32.100375672679476,
        '5': 32.100341636690864,
        '6': 32.10037119984662,
        '7': 32.10033098084517,
        '8': 32.10021049409254,
        '9': 32.100164139644384,
        '10': 32.10031556835611,
        '11': 32.100656020345326,
        '12': 32.099967912971316,
        '13': 32.09562007507925,
        '14': 32.088841254958105,
        '15': 32.089357359560516,
        '16': 32.087741419584226,
        '17': 32.08774980824338,
        '18': 32.08822700669316,
        '19': 32.08853316960003,
        '20': 32.088319180692366,
        '21': 32.08893126345795,
        '22': 32.08956801371695,
        '23': 32.089813635777816,
        '24': 32.089654845475565,
        '25': 32.08912152519427,
        '26': 32.09055741031628,
        '27': 32.09081919912201,
        '28': 32.08781288302655,
        '29': 32.09571196998144,
        '30': 32.09264646289211,
        '31': 32.09613760605352,
        '32': 32.09493485609136,
        '33': 32.09622976235958,
        '34': 32.0959481830244,
        '35': 32.09045377765393,
        '36': 32.09472379096097,
        '37': 32.094131326048185,
        '38': 32.09629709727588,
        '39': 32.09782581165267,
        '40': 32.10000783998654,
        '41': 32.0998124370208,
        '42': 32.09958970473749,
        '43': 32.099934360596315,
        '44': 32.10030437293031,
        '45': 32.10005400050347,
        '46': 32.099329103146125,
        '47': 32.09900348656036,
        '48': 32.098297328483476,
        '49': 32.09539864459576,
        '50': 32.10125510889958,
        '51': 32.10045510651638,
        '52': 32.10016387548711,
        '53': 32.10048059535592,
        '54': 32.10091751495844,
        '55': 32.101063801911785,
        '56': 32.10082224952284,
        '57': 32.10042636407323,
        '58': 32.100356808461534,
        '59': 32.1008443438218,
        '60': 32.10142276348936,
        '61': 32.10141774704927,
        '62': 32.10087325692262,
        '63': 32.100485282368496,
        '64': 32.10060739999562,
        '65': 32.10088658616756,
        '66': 32.10095681165418,
        '67': 32.10090601620768,
        '68': 32.10088380974721,
        '69': 32.100777634499295,
        '70': 32.100519763095946,
        '71': 32.10029850674382,
        '72': 32.100239804784664,
        '73': 32.10021286000373,
        '74': 32.10012242624887,
        '75': 32.10009116771351,
        '76': 32.10020831959648,
        '77': 32.10035920395941,
        '78': 32.10041390831898,
        '79': 32.10037112802412,
        '80': 32.1002641101939,
        '81': 32.10010992155757,
        '82': 32.099945811615925,
        '83': 32.09977237427394,
        '84': 32.09957172005081,
        '85': 32.09951284710739,
        '86': 32.09986146297312,
        '87': 32.100441193239455,
        '88': 32.10056486259796,
        '89': 32.09993398595581,
        '90': 32.099250124543246,
        '91': 32.09934992926384,
        '92': 32.10000463978244,
        '93': 32.10026911428751,
        '94': 32.09987178205992,
        '95': 32.09949753414216,
        '96': 32.09966617743561,
        '97': 32.100064855506574,
        '98': 32.10018666352387,
        '99': 32.10002220286207,
        '100': 32.099840658527505,
        '101': 32.09973664981721,
        '102': 32.09966760712278,
        '103': 32.099633405946896,
        '104': 32.099629549994525,
        '105': 32.0996459103298,
        '106': 32.09977070374461,
        '107': 32.10005061539225,
        '108': 32.100270320716916,
        '109': 32.100182552228254,
        '110': 32.0999295569947,
        '111': 32.0998912860688,
        '112': 32.1001267819927,
        '113': 32.10030801217399,
        '114': 32.10019454444659,
        '115': 32.09984517169439,
        '116': 32.09940715234609,
        '117': 32.09905710997899,
        '118': 32.09910344146092,
        '119': 32.09974137178819,
        '120': 32.10067364498707,
        '121': 32.10131180883612,
        '122': 32.10140175680701,
        '123': 32.101215965067496,
        '124': 32.1011612570815,
        '125': 32.09976197294589,
        '126': 32.09577750091287,
        '127': 32.098620453481274,
        '128': 32.0996110216778,
        '129': 32.099540942305204,
        '130': 32.09900192915642,
        '131': 32.09911549484594,
        '132': 32.09806803092185,
        '133': 32.09842476322063,
        '134': 32.098623096256674,
        '135': 32.09936946578609,
        '136': 32.10039887654112,
        '137': 32.101227151280106,
        '138': 32.100944953787916,
        '139': 32.10074707479222,
        '140': 32.10063480555794,
        '141': 32.10050417709247,
        '142': 32.10031943792119,
        '143': 32.10030089062159,
        '144': 32.09943397019001,
        '145': 32.09521617130948,
        '146': 32.101099922268524,
        '147': 32.10078701825504,
        '148': 32.10092625973117,
        '149': 32.10123638260049,
        '150': 32.10148818039208,
        '151': 32.1015541359166,
        '152': 32.101284836203774,
        '153': 32.10084704298975,
        '154': 32.100756326861585,
        '155': 32.101090740467576,
        '156': 32.101225889623784,
        '157': 32.10087515198513,
        '158': 32.10067740135714,
        '159': 32.101059762138604,
        '160': 32.10133012788722,
        '161': 32.10084538529535,
        '162': 32.10028698047634,
        '163': 32.10057046880379,
        '164': 32.101174474328225,
        '165': 32.10101648107381,
        '166': 32.100498226611755,
        '167': 32.10096826120227,
        '168': 32.10230034728775,
        '169': 32.102848339688975,
        '170': 32.102067284873726,
        '171': 32.10136838413943,
        '172': 32.10174746338489,
        '173': 32.102269613508675,
        '174': 32.10187762184917,
        '175': 32.101190599792325,
        '176': 32.101247406771336,
        '177': 32.10169401416286,
        '178': 32.10163614537367,
        '179': 32.10131718291208,
        '180': 32.10145508777957,
        '-180': 32.10145508777956,
        '-179': 32.10171275503276,
        '-178': 32.101419236555564,
        '-177': 32.10114941570859,
        '-176': 32.10181686410687,
        '-175': 32.102638843077486,
        '-174': 32.10197221441964,
        '-173': 32.10010098695917,
        '-172': 32.09917302281232,
        '-171': 32.100040497234026,
        '-170': 32.101094707779616,
        '-169': 32.100889087735716,
        '-168': 32.100116916376905,
        '-167': 32.100017776151546,
        '-166': 32.10037868394785,
        '-165': 32.10034945554261,
        '-164': 32.100064428651116,
        '-163': 32.100158161020865,
        '-162': 32.10047991717051,
        '-161': 32.10046894999082,
        '-160': 32.100233192500795,
        '-159': 32.10024824705095,
        '-158': 32.10044994299891,
        '-157': 32.10049033278886,
        '-156': 32.10047572683682,
        '-155': 32.100685248394804,
        '-154': 32.10094123480901,
        '-153': 32.10093515898053,
        '-152': 32.100810515036954,
        '-151': 32.10082940983492,
        '-150': 32.10084659340101,
        '-149': 32.10066870814272,
        '-148': 32.100540944984004,
        '-147': 32.10072941750583,
        '-146': 32.100981279131794,
        '-145': 32.10091963700655,
        '-144': 32.100656333084366,
        '-143': 32.10054282396381,
        '-142': 32.100601311457574,
        '-141': 32.10062102739971,
        '-140': 32.10055715222248,
        '-139': 32.100475595022665,
        '-138': 32.100344521850275,
        '-137': 32.10017171443611,
        '-136': 32.10011059028934,
        '-135': 32.10020884422256,
        '-134': 32.100305415779694,
        '-133': 32.10033187384826,
        '-132': 32.10041632840978,
        '-131': 32.100564847283394,
        '-130': 32.10059074404629,
        '-129': 32.10049019699982,
        '-128': 32.100510134063676,
        '-127': 32.10069767993644,
        '-126': 32.10077515475355,
        '-125': 32.100631317615274,
        '-124': 32.10053676808229,
        '-123': 32.10066581836548,
        '-122': 32.10079447749753,
        '-121': 32.100722604930674,
        '-120': 32.10063473683021,
        '-119': 32.100748351451955,
        '-118': 32.10091575001722,
        '-117': 32.10090163228913,
        '-116': 32.10080457398794,
        '-115': 32.10085754757044,
        '-114': 32.1009987689396,
        '-113': 32.100994745354164,
        '-112': 32.100863580139865,
        '-111': 32.10082579192723,
        '-110': 32.10090567551056,
        '-109': 32.100920611121595,
        '-108': 32.10083735514731,
        '-107': 32.1008082408039,
        '-106': 32.100847550744646,
        '-105': 32.100798093272786,
        '-104': 32.1006571233203,
        '-103': 32.10061355166876,
        '-102': 32.100697353909645,
        '-101': 32.10071235628163,
        '-100': 32.100601726127394,
        '-99': 32.100553345945514,
        '-98': 32.10063285875056,
        '-97': 32.10064562813102,
        '-96': 32.100503630302946,
        '-95': 32.100399115274236,
        '-94': 32.10044505481591,
        '-93': 32.1004637703578,
        '-92': 32.10033063161519,
        '-91': 32.100228924288224,
        '-90': 32.10033310117634,
        '-89': 32.10048414629266,
        '-88': 32.10042357456517,
        '-87': 32.10019131017625,
        '-86': 32.100077025338855,
        '-85': 32.100229856020526,
        '-84': 32.1004412347474,
        '-83': 32.100367976473784,
        '-82': 32.10002015663104,
        '-81': 32.099917935488314,
        '-80': 32.100454937833206,
        '-79': 32.10113487027943,
        '-78': 32.10099331387567,
        '-77': 32.09996776688836,
        '-76': 32.09919740048515,
        '-75': 32.09957579565431,
        '-74': 32.09926694138856,
        '-73': 32.085252169214804,
        '-72': 32.06726352666007,
        '-71': 32.05832020838459,
        '-70': 32.065758030065425,
        '-69': 32.06593329260589,
        '-68': 32.079885276656015,
        '-67': 32.08189583188193,
        '-66': 32.09690255604893,
        '-65': 32.09727350625879,
        '-64': 32.09774686133038,
        '-63': 32.09773097352629,
        '-62': 32.09717049530908,
        '-61': 32.098534010190455,
        '-60': 32.09930956444751,
        '-59': 32.09869081596628,
        '-58': 32.09924307687709,
        '-57': 32.09885509676583,
        '-56': 32.09881511197122,
        '-55': 32.09702622871443,
        '-54': 32.09644771929187,
        '-53': 32.09575388386849,
        '-52': 32.09716278652565,
        '-51': 32.095508291775964,
        '-50': 32.09351021398182,
        '-49': 32.091982337219555,
        '-48': 32.087290870616215,
        '-47': 32.091036008532704,
        '-46': 32.095015840009694,
        '-45': 32.095437163447826,
        '-44': 32.09220270783453,
        '-43': 32.091333495609256,
        '-42': 32.09118001434245,
        '-41': 32.09647414607187,
        '-40': 32.0995349575217,
        '-39': 32.10006120254537,
        '-38': 32.10000593430962,
        '-37': 32.09973196399135,
        '-36': 32.099939878160015,
        '-35': 32.10041550744048,
        '-34': 32.100667797415035,
        '-33': 32.10052833402739,
        '-32': 32.100345518083245,
        '-31': 32.100409907325414,
        '-30': 32.100523828391815,
        '-29': 32.100404168466746,
        '-28': 32.10016774015396,
        '-27': 32.1000747347076,
        '-26': 32.10008617470825,
        '-25': 32.100044705405324,
        '-24': 32.10003813393738,
        '-23': 32.10020122476942,
        '-22': 32.100375163814455,
        '-21': 32.10034735698849,
        '-20': 32.10024380412774,
        '-19': 32.10031046342518,
        '-18': 32.10047595193602,
        '-17': 32.10050645906135,
        '-16': 32.10044132858872,
        '-15': 32.10049014054368,
        '-14': 32.10061791300894,
        '-13': 32.100614207571994,
        '-12': 32.10049056383844,
        '-11': 32.10044946826604,
        '-10': 32.10051872834714,
        '-9': 32.10054927832801,
        '-8': 32.10052432565976,
        '-7': 32.10055661693985,
        '-6': 32.10062186443599,
        '-5': 32.1005906345034,
        '-4': 32.1004908550719,
        '-3': 32.10046790392672,
        '-2': 32.10052027253308,
        '-1': 32.10052592965015
    },
    '-17': {
        '0': 32.102177894725145,
        '1': 32.10219494820172,
        '2': 32.10215055907239,
        '3': 32.10205472319981,
        '4': 32.10202004906491,
        '5': 32.10209065002246,
        '6': 32.102132084695334,
        '7': 32.102018588040394,
        '8': 32.1018264423913,
        '9': 32.101732578793026,
        '10': 32.10183520373896,
        '11': 32.10215707387519,
        '12': 32.09848800689969,
        '13': 32.09570482104174,
        '14': 32.09332576853894,
        '15': 32.09128447432014,
        '16': 32.09079516820223,
        '17': 32.09031715661644,
        '18': 32.09051832473266,
        '19': 32.09068530959184,
        '20': 32.09064337108273,
        '21': 32.091012400413476,
        '22': 32.09146376012409,
        '23': 32.09182255723387,
        '24': 32.092119434851334,
        '25': 32.09255731288685,
        '26': 32.09025094880958,
        '27': 32.08970630406083,
        '28': 32.09682434645297,
        '29': 32.09315074220859,
        '30': 32.09121185614731,
        '31': 32.08948623085845,
        '32': 32.09410127937222,
        '33': 32.09653889718192,
        '34': 32.09788326354831,
        '35': 32.0995756163672,
        '36': 32.09890963587886,
        '37': 32.10058547270777,
        '38': 32.100737139545785,
        '39': 32.101662134640534,
        '40': 32.101670432049815,
        '41': 32.101530749407104,
        '42': 32.101593265277366,
        '43': 32.10199953697751,
        '44': 32.10237533408149,
        '45': 32.09981984249105,
        '46': 32.10061046245019,
        '47': 32.100408254997134,
        '48': 32.09025748210797,
        '49': 32.09397740812484,
        '50': 32.10228535805599,
        '51': 32.101732364857575,
        '52': 32.10175400444124,
        '53': 32.10206265273666,
        '54': 32.10230089604688,
        '55': 32.102391996497055,
        '56': 32.10233481935785,
        '57': 32.10218173433103,
        '58': 32.10220044401955,
        '59': 32.102559422611286,
        '60': 32.10289900085445,
        '61': 32.102733306815935,
        '62': 32.10222897013607,
        '63': 32.102026575665775,
        '64': 32.10230230997067,
        '65': 32.10258946754996,
        '66': 32.10257083273718,
        '67': 32.10245444375278,
        '68': 32.10244401205069,
        '69': 32.10239529533494,
        '70': 32.10219449503737,
        '71': 32.10201334542577,
        '72': 32.10197835136525,
        '73': 32.10195985282152,
        '74': 32.1018662452166,
        '75': 32.101827321239284,
        '76': 32.101936489874575,
        '77': 32.10207763822921,
        '78': 32.10211660568399,
        '79': 32.102051019648414,
        '80': 32.10192701106745,
        '81': 32.101787727560954,
        '82': 32.10167378063652,
        '83': 32.10152636785955,
        '84': 32.101241568360976,
        '85': 32.100990383184396,
        '86': 32.10118179285097,
        '87': 32.10179974402033,
        '88': 32.102145024302565,
        '89': 32.10172249047735,
        '90': 32.10106142293406,
        '91': 32.10103577761089,
        '92': 32.10160103348313,
        '93': 32.10191851381847,
        '94': 32.101629422811016,
        '95': 32.10124882118001,
        '96': 32.10124347851399,
        '97': 32.10143921566886,
        '98': 32.101528015721854,
        '99': 32.101540657311794,
        '100': 32.1015576680156,
        '101': 32.1014532805182,
        '102': 32.101197830351296,
        '103': 32.10102697673058,
        '104': 32.10108492133955,
        '105': 32.10121646277062,
        '106': 32.101269464524485,
        '107': 32.101301540753745,
        '108': 32.1013751024504,
        '109': 32.10145059038368,
        '110': 32.10156994513796,
        '111': 32.10184169369128,
        '112': 32.10216725995789,
        '113': 32.10227107832661,
        '114': 32.10206163843606,
        '115': 32.10170835643628,
        '116': 32.101374790889224,
        '117': 32.10116479637081,
        '118': 32.101272685144984,
        '119': 32.1018115476764,
        '120': 32.10249243572162,
        '121': 32.10284182383051,
        '122': 32.10277724914138,
        '123': 32.10209138044546,
        '124': 32.102537502465836,
        '125': 32.10084039923333,
        '126': 32.10003386441633,
        '127': 32.09817754376606,
        '128': 32.098204955329955,
        '129': 32.10047924787139,
        '130': 32.09877100403655,
        '131': 32.1004567033968,
        '132': 32.09977636276372,
        '133': 32.09971523384445,
        '134': 32.10014214220704,
        '135': 32.09962571153026,
        '136': 32.10143214993381,
        '137': 32.1010515724851,
        '138': 32.10232515049014,
        '139': 32.10283635840696,
        '140': 32.10274276253076,
        '141': 32.10212671921664,
        '142': 32.1014235634826,
        '143': 32.10123472039642,
        '144': 32.1012004343962,
        '145': 32.09876666428194,
        '146': 32.10302053741239,
        '147': 32.10242674479383,
        '148': 32.10248813832461,
        '149': 32.10292425441111,
        '150': 32.10323908962662,
        '151': 32.10327813400373,
        '152': 32.10309622038027,
        '153': 32.1028440972389,
        '154': 32.10278103387352,
        '155': 32.10294619740751,
        '156': 32.10296479735617,
        '157': 32.102632384435495,
        '158': 32.10238245430669,
        '159': 32.10259439977112,
        '160': 32.10283237053335,
        '161': 32.10254147570111,
        '162': 32.10211187569522,
        '163': 32.10227723201556,
        '164': 32.10275175855065,
        '165': 32.10263959340718,
        '166': 32.10209650638478,
        '167': 32.10226758772814,
        '168': 32.103387368940204,
        '169': 32.10425760797908,
        '170': 32.1040559130901,
        '171': 32.103444800767114,
        '172': 32.10328336691313,
        '173': 32.10337942267164,
        '174': 32.10327286992354,
        '175': 32.103204153220965,
        '176': 32.103420748001476,
        '177': 32.10346084633727,
        '178': 32.103060876659676,
        '179': 32.102865213817495,
        '180': 32.1032356795333,
        '-180': 32.1032356795333,
        '-179': 32.10334447153525,
        '-178': 32.10267523440593,
        '-177': 32.10239318107272,
        '-176': 32.10358185679734,
        '-175': 32.10494375177297,
        '-174': 32.10429712723724,
        '-173': 32.10200250203024,
        '-172': 32.10071224657567,
        '-171': 32.10156709581141,
        '-170': 32.102790450731554,
        '-169': 32.102646316517095,
        '-168': 32.101773448110464,
        '-167': 32.101566166066156,
        '-166': 32.10193434900641,
        '-165': 32.10200208001047,
        '-164': 32.101808437084316,
        '-163': 32.10193782803998,
        '-162': 32.10225703195697,
        '-161': 32.10223647909602,
        '-160': 32.1019852025337,
        '-159': 32.10196310231429,
        '-158': 32.10210779528744,
        '-157': 32.10208867594185,
        '-156': 32.102016116297925,
        '-155': 32.10216411179268,
        '-154': 32.102383661049366,
        '-153': 32.10243574219912,
        '-152': 32.102498904816706,
        '-151': 32.10275139705518,
        '-150': 32.102873224501685,
        '-149': 32.102556018202165,
        '-148': 32.1021175602672,
        '-147': 32.102040252851836,
        '-146': 32.10224421494435,
        '-145': 32.10234001664396,
        '-144': 32.1022787941006,
        '-143': 32.10225952770381,
        '-142': 32.10227568811397,
        '-141': 32.10220378577165,
        '-140': 32.102109235464525,
        '-139': 32.1020968276979,
        '-138': 32.10207608026822,
        '-137': 32.101961139246136,
        '-136': 32.10186920155396,
        '-135': 32.10189284609971,
        '-134': 32.1019295573571,
        '-133': 32.10191652628826,
        '-132': 32.101954275943484,
        '-131': 32.102053764477844,
        '-130': 32.10207046681848,
        '-129': 32.10201050389747,
        '-128': 32.10206528657813,
        '-127': 32.1022355381561,
        '-126': 32.102287475207746,
        '-125': 32.10218836315271,
        '-124': 32.10219111511545,
        '-123': 32.10236460092163,
        '-122': 32.102452560735784,
        '-121': 32.10234849098634,
        '-120': 32.10229438571909,
        '-119': 32.10242651546098,
        '-118': 32.10253118727149,
        '-117': 32.102464682303946,
        '-116': 32.102425541538445,
        '-115': 32.10256316755772,
        '-114': 32.10267658543083,
        '-113': 32.102577454201075,
        '-112': 32.10243192722629,
        '-111': 32.10245117242102,
        '-110': 32.10252618131086,
        '-109': 32.10247267653692,
        '-108': 32.10238822503375,
        '-107': 32.10244741628401,
        '-106': 32.102549136270596,
        '-105': 32.10249123984938,
        '-104': 32.10233499339365,
        '-103': 32.10228279312365,
        '-102': 32.10231955057277,
        '-101': 32.10227458520945,
        '-100': 32.10214432919862,
        '-99': 32.10207526424951,
        '-98': 32.102095139491965,
        '-97': 32.102103850496704,
        '-96': 32.102072441406285,
        '-95': 32.10205036653256,
        '-94': 32.102040295432886,
        '-93': 32.10202069514672,
        '-92': 32.10200760411757,
        '-91': 32.10199716951129,
        '-90': 32.1019784966753,
        '-89': 32.10203041485442,
        '-88': 32.102179147535956,
        '-87': 32.10219264235354,
        '-86': 32.101894344156456,
        '-85': 32.1016333618072,
        '-84': 32.10187194666496,
        '-83': 32.10230388833529,
        '-82': 32.10217152005756,
        '-81': 32.10160311221228,
        '-80': 32.10164307048515,
        '-79': 32.10254375841443,
        '-78': 32.103102131095206,
        '-77': 32.10239374644166,
        '-76': 32.10114539945597,
        '-75': 32.10050294806887,
        '-74': 32.10044621583651,
        '-73': 32.10054705113854,
        '-72': 32.098244664175766,
        '-71': 32.07737385103274,
        '-70': 32.059464283436306,
        '-69': 32.06664364533221,
        '-68': 32.06318929143917,
        '-67': 32.085494269816174,
        '-66': 32.082800398946745,
        '-65': 32.100154188634505,
        '-64': 32.099078955912766,
        '-63': 32.09909234132901,
        '-62': 32.09973473092591,
        '-61': 32.099892364041956,
        '-60': 32.1009570338267,
        '-59': 32.10161945510888,
        '-58': 32.101568130229154,
        '-57': 32.101538457229346,
        '-56': 32.101168210532556,
        '-55': 32.10037880690974,
        '-54': 32.0955468285295,
        '-53': 32.09690189881774,
        '-52': 32.09593916280875,
        '-51': 32.09482014382308,
        '-50': 32.09641048458203,
        '-49': 32.09413180388514,
        '-48': 32.09272134328152,
        '-47': 32.09250123870226,
        '-46': 32.09584404787866,
        '-45': 32.09465558843316,
        '-44': 32.092353432430095,
        '-43': 32.092886566844534,
        '-42': 32.09600139860885,
        '-41': 32.098672863310235,
        '-40': 32.09828102692745,
        '-39': 32.10236517972483,
        '-38': 32.1020872324245,
        '-37': 32.101805695664375,
        '-36': 32.10181418243823,
        '-35': 32.10208156298176,
        '-34': 32.10223550429413,
        '-33': 32.102119249590366,
        '-32': 32.10201433174333,
        '-31': 32.10211618135073,
        '-30': 32.10218151911461,
        '-29': 32.10197585529076,
        '-28': 32.101698499781406,
        '-27': 32.10162483091387,
        '-26': 32.10165694754524,
        '-25': 32.10159726675968,
        '-24': 32.10156927843223,
        '-23': 32.101762491629216,
        '-22': 32.10200637228472,
        '-21': 32.102020914084996,
        '-20': 32.1019062876678,
        '-19': 32.10196905916284,
        '-18': 32.10219779031412,
        '-17': 32.10231241206094,
        '-16': 32.10224430935374,
        '-15': 32.10218748548298,
        '-14': 32.102218898590124,
        '-13': 32.10221374507169,
        '-12': 32.102129558389734,
        '-11': 32.102078653459685,
        '-10': 32.10210767138179,
        '-9': 32.1021428994474,
        '-8': 32.10215442223354,
        '-7': 32.10217619908376,
        '-6': 32.10218218870517,
        '-5': 32.10211976986451,
        '-4': 32.102038031609716,
        '-3': 32.10203000403789,
        '-2': 32.102087978517325,
        '-1': 32.10214244541473
    },
    '-18': {
        '0': 32.10402530275787,
        '1': 32.103977293355946,
        '2': 32.10383322659974,
        '3': 32.10369420164989,
        '4': 32.10370138730769,
        '5': 32.10382451020255,
        '6': 32.10387398064384,
        '7': 32.10376247797555,
        '8': 32.10360039970271,
        '9': 32.10351412571633,
        '10': 32.10355714724452,
        '11': 32.1038093723597,
        '12': 32.10142835909926,
        '13': 32.09218810757835,
        '14': 32.091399051199446,
        '15': 32.09314066410618,
        '16': 32.092781893027826,
        '17': 32.09230286301431,
        '18': 32.09239820019546,
        '19': 32.09252951882962,
        '20': 32.092791711929166,
        '21': 32.09306774408479,
        '22': 32.09327517279647,
        '23': 32.093832698736165,
        '24': 32.094313077987,
        '25': 32.093427071593496,
        '26': 32.09446786631302,
        '27': 32.09776999397807,
        '28': 32.094855958187715,
        '29': 32.09306952057167,
        '30': 32.09400071189976,
        '31': 32.09098319465116,
        '32': 32.09132091557899,
        '33': 32.090452219435164,
        '34': 32.09844645897481,
        '35': 32.10314549107936,
        '36': 32.10361778433133,
        '37': 32.103823673341985,
        '38': 32.10362761990995,
        '39': 32.10337127450919,
        '40': 32.10321318439349,
        '41': 32.10323110345984,
        '42': 32.1034456722143,
        '43': 32.10372585754227,
        '44': 32.10393080815808,
        '45': 32.10147602518864,
        '46': 32.097925092429094,
        '47': 32.09447419938625,
        '48': 32.095885853200556,
        '49': 32.10042071991328,
        '50': 32.10354109062521,
        '51': 32.1031761112089,
        '52': 32.10343396205381,
        '53': 32.103623907836685,
        '54': 32.103530895216004,
        '55': 32.10350239709481,
        '56': 32.10369946637626,
        '57': 32.10391408890849,
        '58': 32.10405309411591,
        '59': 32.104215301443816,
        '60': 32.10430926264005,
        '61': 32.1041358536531,
        '62': 32.103857797376165,
        '63': 32.1038745409135,
        '64': 32.104195095401245,
        '65': 32.10441254210793,
        '66': 32.10434434620963,
        '67': 32.10423331610576,
        '68': 32.10424833537847,
        '69': 32.10423744505282,
        '70': 32.10408653762324,
        '71': 32.103926885276955,
        '72': 32.10386031728945,
        '73': 32.103805572249804,
        '74': 32.103720702712174,
        '75': 32.10369686198581,
        '76': 32.10375495970291,
        '77': 32.10378714519397,
        '78': 32.10373849760245,
        '79': 32.103650874671445,
        '80': 32.10354837706462,
        '81': 32.103449920562376,
        '82': 32.10339961651654,
        '83': 32.10332701527457,
        '84': 32.10308061995599,
        '85': 32.10279760176031,
        '86': 32.10292048397163,
        '87': 32.10352062735731,
        '88': 32.1039609717778,
        '89': 32.1036989773303,
        '90': 32.10311093947952,
        '91': 32.10295379913068,
        '92': 32.10326368464985,
        '93': 32.103439523075394,
        '94': 32.103253276996334,
        '95': 32.10306468895844,
        '96': 32.10307150726702,
        '97': 32.103051918081086,
        '98': 32.102927336222045,
        '99': 32.102934727877496,
        '100': 32.10309883362336,
        '101': 32.10311261073985,
        '102': 32.10291131823524,
        '103': 32.102837030873445,
        '104': 32.103044253642345,
        '105': 32.10322496948968,
        '106': 32.10312971289675,
        '107': 32.10293356003747,
        '108': 32.102882866554125,
        '109': 32.10295966099811,
        '110': 32.10308037284326,
        '111': 32.103274824830756,
        '112': 32.10349179835959,
        '113': 32.103540148198064,
        '114': 32.10338029904783,
        '115': 32.103215801100674,
        '116': 32.10320982550761,
        '117': 32.10336470301926,
        '118': 32.10368710742387,
        '119': 32.104149014682406,
        '120': 32.10448542995588,
        '121': 32.104409142828594,
        '122': 32.104069488812215,
        '123': 32.10338105225527,
        '124': 32.1033833752984,
        '125': 32.102988937583625,
        '126': 32.10272982420592,
        '127': 32.09938202542849,
        '128': 32.099309722029716,
        '129': 32.10032214335175,
        '130': 32.099723027012516,
        '131': 32.101898687979194,
        '132': 32.10156182302888,
        '133': 32.10196507061053,
        '134': 32.102017479279056,
        '135': 32.101949519399504,
        '136': 32.10167137780652,
        '137': 32.101380817352315,
        '138': 32.10294204388577,
        '139': 32.104314014880664,
        '140': 32.10450670922521,
        '141': 32.104057879976025,
        '142': 32.102939754399124,
        '143': 32.10232167950158,
        '144': 32.1016128474219,
        '145': 32.09889222491212,
        '146': 32.10485096283659,
        '147': 32.10428489727559,
        '148': 32.10424539309558,
        '149': 32.104510301198744,
        '150': 32.10458661742447,
        '151': 32.10450057064683,
        '152': 32.10445683817913,
        '153': 32.10442406430562,
        '154': 32.10437861985061,
        '155': 32.104449586349375,
        '156': 32.104575107555085,
        '157': 32.1044851755998,
        '158': 32.10420014541681,
        '159': 32.10405763886349,
        '160': 32.10412328456165,
        '161': 32.10413332214569,
        '162': 32.1041295293256,
        '163': 32.10446501847628,
        '164': 32.104969557981484,
        '165': 32.10488110208024,
        '166': 32.10402467736779,
        '167': 32.10336569025497,
        '168': 32.103822411423415,
        '169': 32.10503440102383,
        '170': 32.10586566801322,
        '171': 32.1057449644211,
        '172': 32.10503735949163,
        '173': 32.10444841405696,
        '174': 32.10446444573136,
        '175': 32.10502213227305,
        '176': 32.105428141699754,
        '177': 32.10505440501674,
        '178': 32.09745453740925,
        '179': 32.104204287347606,
        '180': 32.104740787646904,
        '-180': 32.104740787646904,
        '-179': 32.10476195617593,
        '-178': 32.10400318006538,
        '-177': 32.10394146195962,
        '-176': 32.10545095602555,
        '-175': 32.106838848692156,
        '-174': 32.105976942953305,
        '-173': 32.10362908846592,
        '-172': 32.10259612610488,
        '-171': 32.10375035460262,
        '-170': 32.10503992591624,
        '-169': 32.104740739651234,
        '-168': 32.10365819715906,
        '-167': 32.10330245708184,
        '-166': 32.10362238283963,
        '-165': 32.10372555895456,
        '-164': 32.10357276698795,
        '-163': 32.10367789477439,
        '-162': 32.10394169403952,
        '-161': 32.103922888857085,
        '-160': 32.103735794684304,
        '-159': 32.1037612011292,
        '-158': 32.10389421687614,
        '-157': 32.103828375173016,
        '-156': 32.1036840517624,
        '-155': 32.10371765240304,
        '-154': 32.10381155818415,
        '-153': 32.103813882642854,
        '-152': 32.10395130107059,
        '-151': 32.1043571701244,
        '-150': 32.10461880853401,
        '-149': 32.10434783771985,
        '-148': 32.103827401926964,
        '-147': 32.103581494025306,
        '-146': 32.103643883992724,
        '-145': 32.10372157353225,
        '-144': 32.103748014055796,
        '-143': 32.10381499822364,
        '-142': 32.10384318628057,
        '-141': 32.10374096434118,
        '-140': 32.10365534914235,
        '-139': 32.10372519809907,
        '-138': 32.103815789331264,
        '-137': 32.103772425839416,
        '-136': 32.103689041826215,
        '-135': 32.10368908579472,
        '-134': 32.10369978171672,
        '-133': 32.10365281456632,
        '-132': 32.1036473149339,
        '-131': 32.10373239297474,
        '-130': 32.10378555162765,
        '-129': 32.103751660181125,
        '-128': 32.10374278266843,
        '-127': 32.10379758364145,
        '-126': 32.10381835350639,
        '-125': 32.10381650157917,
        '-124': 32.103917462374426,
        '-123': 32.10407184246731,
        '-122': 32.104090299444465,
        '-121': 32.10400346787628,
        '-120': 32.1040339088473,
        '-119': 32.10418002784782,
        '-118': 32.10421217273563,
        '-117': 32.104118568136855,
        '-116': 32.104140104961694,
        '-115': 32.10430236461806,
        '-114': 32.104346140588866,
        '-113': 32.104212627755274,
        '-112': 32.104158942491104,
        '-111': 32.10427187994498,
        '-110': 32.10430410598264,
        '-109': 32.10414571825944,
        '-108': 32.10403439567635,
        '-107': 32.10411533559287,
        '-106': 32.10419035782593,
        '-105': 32.10410137856074,
        '-104': 32.10399379622695,
        '-103': 32.103999436536895,
        '-102': 32.10400058424205,
        '-101': 32.10389898384918,
        '-100': 32.10380131430175,
        '-99': 32.10377774983366,
        '-98': 32.10374564605735,
        '-97': 32.103705417046825,
        '-96': 32.10376087930638,
        '-95': 32.103839843153494,
        '-94': 32.103771684089175,
        '-93': 32.103663004185044,
        '-92': 32.10374611337722,
        '-91': 32.10387580314769,
        '-90': 32.103741123050575,
        '-89': 32.1035379861547,
        '-88': 32.10371177879393,
        '-87': 32.104038085973365,
        '-86': 32.10385677687184,
        '-85': 32.103313637173486,
        '-84': 32.10330291257791,
        '-83': 32.10391552597882,
        '-82': 32.104146212572985,
        '-81': 32.10362525049519,
        '-80': 32.1033619735972,
        '-79': 32.10402040320823,
        '-78': 32.1047116631684,
        '-77': 32.1044708539737,
        '-76': 32.103739331187946,
        '-75': 32.103242902102565,
        '-74': 32.10260112131674,
        '-73': 32.10149324513456,
        '-72': 32.10113163472085,
        '-71': 32.102794973192395,
        '-70': 32.0846355720951,
        '-69': 32.05569467611549,
        '-68': 32.06781278858562,
        '-67': 32.069207111466575,
        '-66': 32.078498436957936,
        '-65': 32.08574902601153,
        '-64': 32.08911774817853,
        '-63': 32.099407714410475,
        '-62': 32.10059025766137,
        '-61': 32.101344204681425,
        '-60': 32.10038383937958,
        '-59': 32.09975183632035,
        '-58': 32.10229262816586,
        '-57': 32.10365466014412,
        '-56': 32.10315018291494,
        '-55': 32.10216525776626,
        '-54': 32.1002358229218,
        '-53': 32.094754197668074,
        '-52': 32.09585397550322,
        '-51': 32.09647606522225,
        '-50': 32.097321404626534,
        '-49': 32.09663435166244,
        '-48': 32.0951620353282,
        '-47': 32.0958218959946,
        '-46': 32.095237601007796,
        '-45': 32.0962585176384,
        '-44': 32.09139562671595,
        '-43': 32.09327552769579,
        '-42': 32.09766418479166,
        '-41': 32.10069424573223,
        '-40': 32.10339732849938,
        '-39': 32.10420880434812,
        '-38': 32.104119110228794,
        '-37': 32.10376166288381,
        '-36': 32.10353744861163,
        '-35': 32.10361131742152,
        '-34': 32.10374610823555,
        '-33': 32.10375894790054,
        '-32': 32.10378599185547,
        '-31': 32.10392734757611,
        '-30': 32.103979801215644,
        '-29': 32.1037747159844,
        '-28': 32.10349235279166,
        '-27': 32.10336758061991,
        '-26': 32.10333578219041,
        '-25': 32.10325252638665,
        '-24': 32.10322465381305,
        '-23': 32.103402972335175,
        '-22': 32.10363917631747,
        '-21': 32.1036876964535,
        '-20': 32.1036151781981,
        '-19': 32.10368050393904,
        '-18': 32.10389468402162,
        '-17': 32.10401541626085,
        '-16': 32.10394351746362,
        '-15': 32.103841588939204,
        '-14': 32.103849647015295,
        '-13': 32.10391452836761,
        '-12': 32.10393620607484,
        '-11': 32.10391074702355,
        '-10': 32.10388710942183,
        '-9': 32.10389078545652,
        '-8': 32.103909212138475,
        '-7': 32.10389455657729,
        '-6': 32.10380595684072,
        '-5': 32.10368975031712,
        '-4': 32.10365845883838,
        '-3': 32.10375129925222,
        '-2': 32.10388870790435,
        '-1': 32.10398778555674
    },
    '-19': {
        '0': 32.105806395405104,
        '1': 32.10571307020297,
        '2': 32.10555594050782,
        '3': 32.10543185029116,
        '4': 32.10545491861095,
        '5': 32.10557569141901,
        '6': 32.10564230862938,
        '7': 32.1056255531769,
        '8': 32.1056133382808,
        '9': 32.10562466412403,
        '10': 32.10563942760152,
        '11': 32.10576632705862,
        '12': 32.106108691784435,
        '13': 32.09976682466779,
        '14': 32.09357831514252,
        '15': 32.094357841455405,
        '16': 32.09479168452931,
        '17': 32.09425427509901,
        '18': 32.09397077897591,
        '19': 32.09377100614943,
        '20': 32.09376794079748,
        '21': 32.09487133650011,
        '22': 32.09563691685853,
        '23': 32.09586767303758,
        '24': 32.09628290452773,
        '25': 32.095472159902734,
        '26': 32.094954296694226,
        '27': 32.095023736055886,
        '28': 32.09523680075243,
        '29': 32.09413503357684,
        '30': 32.09397529147539,
        '31': 32.091552208871036,
        '32': 32.097137353001216,
        '33': 32.098753381395284,
        '34': 32.10221263360648,
        '35': 32.103925010224344,
        '36': 32.10544753004216,
        '37': 32.10541433233465,
        '38': 32.10518229864879,
        '39': 32.104904870094806,
        '40': 32.104788531132726,
        '41': 32.104945073647784,
        '42': 32.105225672345114,
        '43': 32.10533316424644,
        '44': 32.10529840167233,
        '45': 32.100113349794256,
        '46': 32.09890255699981,
        '47': 32.09329070149937,
        '48': 32.097946155860186,
        '49': 32.106023348179136,
        '50': 32.10499019200079,
        '51': 32.10484383284293,
        '52': 32.10534211459611,
        '53': 32.1055562853509,
        '54': 32.105317652273484,
        '55': 32.105182753722076,
        '56': 32.10542719808728,
        '57': 32.10575351880806,
        '58': 32.10589907131188,
        '59': 32.10593904773746,
        '60': 32.105962518605594,
        '61': 32.10592774830281,
        '62': 32.105897649836784,
        '63': 32.10602646624291,
        '64': 32.106256510275685,
        '65': 32.106356291238214,
        '66': 32.1062862231588,
        '67': 32.106223728005176,
        '68': 32.10622251552818,
        '69': 32.10614988432528,
        '70': 32.105972144898836,
        '71': 32.10581812378904,
        '72': 32.10574326907531,
        '73': 32.10567834468691,
        '74': 32.10561020345486,
        '75': 32.10559028454887,
        '76': 32.105586553085864,
        '77': 32.1055271855037,
        '78': 32.10544201575756,
        '79': 32.10538638182047,
        '80': 32.10531802033785,
        '81': 32.10520968549036,
        '82': 32.105136470991894,
        '83': 32.10509701283372,
        '84': 32.10497140555567,
        '85': 32.104834944862205,
        '86': 32.10499827199937,
        '87': 32.10546826868979,
        '88': 32.1057285832606,
        '89': 32.10543375708397,
        '90': 32.10497587878983,
        '91': 32.10490284447296,
        '92': 32.10509999324619,
        '93': 32.1050946666371,
        '94': 32.10488888398502,
        '95': 32.10485295630973,
        '96': 32.10497984183003,
        '97': 32.104920260764736,
        '98': 32.10467540968378,
        '99': 32.10459634886253,
        '100': 32.104715272817295,
        '101': 32.104718820742185,
        '102': 32.104607450968615,
        '103': 32.10473522397646,
        '104': 32.10510750099661,
        '105': 32.10527032632946,
        '106': 32.10505661882384,
        '107': 32.104860402463885,
        '108': 32.10496625616962,
        '109': 32.10513506432338,
        '110': 32.10508885046053,
        '111': 32.10493463385397,
        '112': 32.104857937946115,
        '113': 32.10480603153409,
        '114': 32.104721791518635,
        '115': 32.10474723420612,
        '116': 32.10498181955911,
        '117': 32.105326335070494,
        '118': 32.105695021508026,
        '119': 32.106073874412836,
        '120': 32.10629431326956,
        '121': 32.10612845143401,
        '122': 32.10464274195229,
        '123': 32.103826046150495,
        '124': 32.10377276058987,
        '125': 32.1039767036337,
        '126': 32.104008763919545,
        '127': 32.102035704964145,
        '128': 32.10258053696115,
        '129': 32.101656616649336,
        '130': 32.101545171349855,
        '131': 32.102414026993294,
        '132': 32.10300701589422,
        '133': 32.103018528735944,
        '134': 32.102302234198945,
        '135': 32.103644745019565,
        '136': 32.10363314697211,
        '137': 32.10294448392254,
        '138': 32.103104815060895,
        '139': 32.10454078365133,
        '140': 32.10588654201632,
        '141': 32.10570364540203,
        '142': 32.10462893111452,
        '143': 32.102737353901766,
        '144': 32.09933005160791,
        '145': 32.10247951504577,
        '146': 32.10050789957196,
        '147': 32.106229487137824,
        '148': 32.10612268570202,
        '149': 32.10616207342499,
        '150': 32.10602287782832,
        '151': 32.105913478272385,
        '152': 32.10602909637696,
        '153': 32.10608097851755,
        '154': 32.105869467869894,
        '155': 32.10574990675822,
        '156': 32.10600644416028,
        '157': 32.10627387636927,
        '158': 32.1061275653408,
        '159': 32.105792912500675,
        '160': 32.105711665911656,
        '161': 32.10583553622295,
        '162': 32.10594683945072,
        '163': 32.10624304444607,
        '164': 32.106861830543714,
        '165': 32.10715955126263,
        '166': 32.10637403382957,
        '167': 32.10497620524251,
        '168': 32.10448696457898,
        '169': 32.10565909079415,
        '170': 32.10744967685463,
        '171': 32.10820114601055,
        '172': 32.10750197529633,
        '173': 32.10647740602513,
        '174': 32.10633099788719,
        '175': 32.10695480612367,
        '176': 32.10722262434708,
        '177': 32.10656379504406,
        '178': 32.10575597169621,
        '179': 32.105766750082694,
        '180': 32.10627200620825,
        '-180': 32.10627200620825,
        '-179': 32.10623544402315,
        '-178': 32.105807094267035,
        '-177': 32.10631253222246,
        '-176': 32.1079010748502,
        '-175': 32.10863819589976,
        '-174': 32.10709782167214,
        '-173': 32.10476541311186,
        '-172': 32.10426350373382,
        '-171': 32.105778294689024,
        '-170': 32.10701699522213,
        '-169': 32.10656796741627,
        '-168': 32.10547976432837,
        '-167': 32.10517308115244,
        '-166': 32.10547843224136,
        '-165': 32.105540611367985,
        '-164': 32.10535603014815,
        '-163': 32.10540094144379,
        '-162': 32.105598655630146,
        '-161': 32.10559929721953,
        '-160': 32.105517495896564,
        '-159': 32.10562696893325,
        '-158': 32.105768688664064,
        '-157': 32.10568198952925,
        '-156': 32.105516440821496,
        '-155': 32.10549720370212,
        '-154': 32.10549173073245,
        '-153': 32.10537232140978,
        '-152': 32.105384643597134,
        '-151': 32.10569574367507,
        '-150': 32.10597519511754,
        '-149': 32.10587491591313,
        '-148': 32.105569127515274,
        '-147': 32.10541002696888,
        '-146': 32.105390486116356,
        '-145': 32.105333642670345,
        '-144': 32.10528733991045,
        '-143': 32.10535432476218,
        '-142': 32.105417118494906,
        '-141': 32.10536619568094,
        '-140': 32.10533984803327,
        '-139': 32.10545395369624,
        '-138': 32.10555731217714,
        '-137': 32.10550793581057,
        '-136': 32.10542756566379,
        '-135': 32.10545141351517,
        '-134': 32.10548717641408,
        '-133': 32.105436744426676,
        '-132': 32.105405349428096,
        '-131': 32.10549039985953,
        '-130': 32.105589453315986,
        '-129': 32.105588152271764,
        '-128': 32.10553725761498,
        '-127': 32.10552062117191,
        '-126': 32.10553924110067,
        '-125': 32.105596827714386,
        '-124': 32.105704063297495,
        '-123': 32.10577737829017,
        '-122': 32.10574402867644,
        '-121': 32.10571795895234,
        '-120': 32.105831949214085,
        '-119': 32.10596948955239,
        '-118': 32.10594814386015,
        '-117': 32.10586670499429,
        '-116': 32.10593313003891,
        '-115': 32.10606097989716,
        '-114': 32.10602656436513,
        '-113': 32.105917963222616,
        '-112': 32.10598841772942,
        '-111': 32.106152350003676,
        '-110': 32.10610471038567,
        '-109': 32.10588102735838,
        '-108': 32.10579945447364,
        '-107': 32.10588379321204,
        '-106': 32.10586082773408,
        '-105': 32.105711260371294,
        '-104': 32.10568852179992,
        '-103': 32.105790567076156,
        '-102': 32.10575757312867,
        '-101': 32.10560205422106,
        '-100': 32.10558670947599,
        '-99': 32.10567884009743,
        '-98': 32.10561136957855,
        '-97': 32.1054661749364,
        '-96': 32.10555650956393,
        '-95': 32.10575758321976,
        '-94': 32.10567055874394,
        '-93': 32.105427782860566,
        '-92': 32.10551850751776,
        '-91': 32.10581148046818,
        '-90': 32.10569184230033,
        '-89': 32.10524464664081,
        '-88': 32.10523572359533,
        '-87': 32.10571841655539,
        '-86': 32.10582640043437,
        '-85': 32.10530713931882,
        '-84': 32.10503375773984,
        '-83': 32.10546409101372,
        '-82': 32.10583646138466,
        '-81': 32.10559107826848,
        '-80': 32.105354824734825,
        '-79': 32.105677291896,
        '-78': 32.106012540427756,
        '-77': 32.10591369688077,
        '-76': 32.10596753440213,
        '-75': 32.106393267002225,
        '-74': 32.10604955276833,
        '-73': 32.10435200230752,
        '-72': 32.102992011245796,
        '-71': 32.103902285319236,
        '-70': 32.09258927501389,
        '-69': 32.06022619643183,
        '-68': 32.071348337262854,
        '-67': 32.07159393630988,
        '-66': 32.07004986260811,
        '-65': 32.081586069563045,
        '-64': 32.09238043533472,
        '-63': 32.10087622861193,
        '-62': 32.10156486384071,
        '-61': 32.10187199176031,
        '-60': 32.10372935632211,
        '-59': 32.10404532058226,
        '-58': 32.1048501938409,
        '-57': 32.105546121215866,
        '-56': 32.10501739856795,
        '-55': 32.099231123646774,
        '-54': 32.100338643228206,
        '-53': 32.09880873280565,
        '-52': 32.099528635441196,
        '-51': 32.10024242009711,
        '-50': 32.09947313718556,
        '-49': 32.097980886744644,
        '-48': 32.0977443713811,
        '-47': 32.095307565178544,
        '-46': 32.095988257451324,
        '-45': 32.09858186187253,
        '-44': 32.09880764742982,
        '-43': 32.099219780612685,
        '-42': 32.10260598271363,
        '-41': 32.09745836186151,
        '-40': 32.10531055747234,
        '-39': 32.105976857740316,
        '-38': 32.105870397966754,
        '-37': 32.10550817206846,
        '-36': 32.105297565514256,
        '-35': 32.10538765506904,
        '-34': 32.10557591880334,
        '-33': 32.105668185624346,
        '-32': 32.10570556755281,
        '-31': 32.105770990114166,
        '-30': 32.1057867132191,
        '-29': 32.10564960611367,
        '-28': 32.10541973436404,
        '-27': 32.10522497284624,
        '-26': 32.10509035147096,
        '-25': 32.10499693470752,
        '-24': 32.10499797569011,
        '-23': 32.10512626241483,
        '-22': 32.10527530926796,
        '-21': 32.10532517906951,
        '-20': 32.10532290021401,
        '-19': 32.10539622162772,
        '-18': 32.105537025288776,
        '-17': 32.1056110505207,
        '-16': 32.10556619292796,
        '-15': 32.105510989622225,
        '-14': 32.10556929439966,
        '-13': 32.10572946449674,
        '-12': 32.105862104696264,
        '-11': 32.10586407825879,
        '-10': 32.105771559143186,
        '-9': 32.10570888093804,
        '-8': 32.10571434714142,
        '-7': 32.10568171630901,
        '-6': 32.105537141746375,
        '-5': 32.105395889053455,
        '-4': 32.105425616458426,
        '-3': 32.10560862054626,
        '-2': 32.105775696547106,
        '-1': 32.105832614872405
    },
    '-20': {
        '0': 32.10749258693166,
        '1': 32.10745628850259,
        '2': 32.10740723786198,
        '3': 32.10736708587441,
        '4': 32.10742388107728,
        '5': 32.10756315331066,
        '6': 32.10768308821766,
        '7': 32.10776053356532,
        '8': 32.10783503095609,
        '9': 32.107872548011855,
        '10': 32.10783455828029,
        '11': 32.1078289057897,
        '12': 32.107969084791584,
        '13': 32.10812802327893,
        '14': 32.0976289514153,
        '15': 32.096466016742774,
        '16': 32.09453433556342,
        '17': 32.09346532639844,
        '18': 32.095377833926996,
        '19': 32.09548919479559,
        '20': 32.09592655034645,
        '21': 32.09661344521779,
        '22': 32.097906317684334,
        '23': 32.097887079481644,
        '24': 32.097964450236816,
        '25': 32.09813890826894,
        '26': 32.09848571541351,
        '27': 32.097462463782904,
        '28': 32.09603385240031,
        '29': 32.094116103529366,
        '30': 32.09751654573303,
        '31': 32.096370561848495,
        '32': 32.09931271006391,
        '33': 32.10017015038039,
        '34': 32.105726474040914,
        '35': 32.107067781856905,
        '36': 32.10687262426672,
        '37': 32.10671279458858,
        '38': 32.1066452424816,
        '39': 32.106614629306364,
        '40': 32.10666062752202,
        '41': 32.106886014700315,
        '42': 32.10715601040612,
        '43': 32.10717058489828,
        '44': 32.10701972124414,
        '45': 32.10485962660509,
        '46': 32.10003000775706,
        '47': 32.09261706835315,
        '48': 32.10203421691674,
        '49': 32.10764622661662,
        '50': 32.10662266231792,
        '51': 32.10662307182299,
        '52': 32.10725643700766,
        '53': 32.107671220672785,
        '54': 32.10767145028021,
        '55': 32.107643236967064,
        '56': 32.10776584008413,
        '57': 32.10784773696381,
        '58': 32.107795902300374,
        '59': 32.10776283154468,
        '60': 32.10783548675121,
        '61': 32.10793043370796,
        '62': 32.10800631248133,
        '63': 32.10810838253616,
        '64': 32.108208896892926,
        '65': 32.108231470129596,
        '66': 32.108208160123084,
        '67': 32.108212092535666,
        '68': 32.108186123595445,
        '69': 32.10803485528763,
        '70': 32.10782481726371,
        '71': 32.107701115826934,
        '72': 32.10766851676259,
        '73': 32.10763050758909,
        '74': 32.107568663835195,
        '75': 32.107518708238324,
        '76': 32.10745974837692,
        '77': 32.10738067604384,
        '78': 32.10734852928397,
        '79': 32.107372392225614,
        '80': 32.10733503435163,
        '81': 32.10718852837987,
        '82': 32.10705060282822,
        '83': 32.10699266282634,
        '84': 32.106953096105215,
        '85': 32.10697037500734,
        '86': 32.10719268960868,
        '87': 32.10749491532961,
        '88': 32.10746780255902,
        '89': 32.107044076954715,
        '90': 32.10673260749257,
        '91': 32.10688697602767,
        '92': 32.10714802569545,
        '93': 32.10704638017963,
        '94': 32.106762330424154,
        '95': 32.10674604027927,
        '96': 32.106916845828906,
        '97': 32.10686267422801,
        '98': 32.1065976707966,
        '99': 32.1064752837099,
        '100': 32.10651390617077,
        '101': 32.10646330791007,
        '102': 32.106420105873156,
        '103': 32.10668876298375,
        '104': 32.10708364900146,
        '105': 32.10708494279463,
        '106': 32.10672958673727,
        '107': 32.10663967936208,
        '108': 32.10703225533453,
        '109': 32.10737255326442,
        '110': 32.1072247611656,
        '111': 32.10683976869787,
        '112': 32.10663664876331,
        '113': 32.10663997767372,
        '114': 32.10671754190821,
        '115': 32.106899566596624,
        '116': 32.10716476602879,
        '117': 32.10731540765869,
        '118': 32.10733119977285,
        '119': 32.10747429423317,
        '120': 32.10748731083493,
        '121': 32.10694844357561,
        '122': 32.106651839733026,
        '123': 32.10633058458228,
        '124': 32.104671346177724,
        '125': 32.10562812417447,
        '126': 32.10514137411133,
        '127': 32.104441894291995,
        '128': 32.10424506435213,
        '129': 32.103458097460525,
        '130': 32.10417349446907,
        '131': 32.10346420894143,
        '132': 32.103865421046976,
        '133': 32.10418670831994,
        '134': 32.10416034440215,
        '135': 32.10489898675382,
        '136': 32.105389122423766,
        '137': 32.10531781091418,
        '138': 32.10512184487913,
        '139': 32.10452884793936,
        '140': 32.10662230946843,
        '141': 32.1070260364224,
        '142': 32.10621728676585,
        '143': 32.104679275999466,
        '144': 32.10132750753123,
        '145': 32.101379735618984,
        '146': 32.10482480736185,
        '147': 32.107206105994514,
        '148': 32.10801724208721,
        '149': 32.10822386078056,
        '150': 32.1080632692807,
        '151': 32.10804573642276,
        '152': 32.1082432197057,
        '153': 32.108201470037535,
        '154': 32.10770339729832,
        '155': 32.10732120719149,
        '156': 32.10758460035637,
        '157': 32.108102662911435,
        '158': 32.10820764758722,
        '159': 32.10801024967784,
        '160': 32.10801743699659,
        '161': 32.10810871354839,
        '162': 32.10785819223027,
        '163': 32.107619703592924,
        '164': 32.10814747124827,
        '165': 32.10908213813702,
        '166': 32.109016681567,
        '167': 32.10754602547329,
        '168': 32.10624533708936,
        '169': 32.1067997394641,
        '170': 32.108785300647284,
        '171': 32.110225581641224,
        '172': 32.11007321413077,
        '173': 32.10920662699327,
        '174': 32.10888562700796,
        '175': 32.10909005506312,
        '176': 32.10893185646555,
        '177': 32.10819543330071,
        '178': 32.10763415002358,
        '179': 32.107725838685305,
        '180': 32.10796593271361,
        '-180': 32.10796593271361,
        '-179': 32.10788636469174,
        '-178': 32.108094668637506,
        '-177': 32.10932764391013,
        '-176': 32.11074449096132,
        '-175': 32.110460699042434,
        '-174': 32.10816199634725,
        '-173': 32.10600166329297,
        '-172': 32.106029305534356,
        '-171': 32.1076079065409,
        '-170': 32.10850913857084,
        '-169': 32.107942223664544,
        '-168': 32.10712502756689,
        '-167': 32.10709340843439,
        '-166': 32.107426739076836,
        '-165': 32.107415382656654,
        '-164': 32.10722055762577,
        '-163': 32.107281380448455,
        '-162': 32.107476328939256,
        '-161': 32.10751149167042,
        '-160': 32.10750775192901,
        '-159': 32.10764887085908,
        '-158': 32.1077439053571,
        '-157': 32.107607139854395,
        '-156': 32.10744208733051,
        '-155': 32.10744850606274,
        '-154': 32.10745236959137,
        '-153': 32.10728623329926,
        '-152': 32.107146542970746,
        '-151': 32.10722705179822,
        '-150': 32.10735842239869,
        '-149': 32.10733544478455,
        '-148': 32.107265101690835,
        '-147': 32.107291818937995,
        '-146': 32.10730146310755,
        '-145': 32.107182615523016,
        '-144': 32.107079977578074,
        '-143': 32.10712612400236,
        '-142': 32.10720331619818,
        '-141': 32.10720038420337,
        '-140': 32.107223218696575,
        '-139': 32.10733895345141,
        '-138': 32.107395580558304,
        '-137': 32.107302906240264,
        '-136': 32.10722116621396,
        '-135': 32.10727411688537,
        '-134': 32.107333688029556,
        '-133': 32.1072758724648,
        '-132': 32.107206062907956,
        '-131': 32.10725027528048,
        '-130': 32.10733917560153,
        '-129': 32.10736160814095,
        '-128': 32.107348298385205,
        '-127': 32.10737389189151,
        '-126': 32.107432797837696,
        '-125': 32.10749582880423,
        '-124': 32.1075533809954,
        '-123': 32.107576304835185,
        '-122': 32.10756678281323,
        '-121': 32.107611547405874,
        '-120': 32.107737369101685,
        '-119': 32.10780999362618,
        '-118': 32.107753174127275,
        '-117': 32.10771813614406,
        '-116': 32.107817395964226,
        '-115': 32.10788746266566,
        '-114': 32.107789418887705,
        '-113': 32.10771257995652,
        '-112': 32.10783953254309,
        '-111': 32.107959750943195,
        '-110': 32.10782427731532,
        '-109': 32.10763061571513,
        '-108': 32.10768208141778,
        '-107': 32.10781557153696,
        '-106': 32.107707107287546,
        '-105': 32.10750668891435,
        '-104': 32.1075619166516,
        '-103': 32.107728340042996,
        '-102': 32.10762799767405,
        '-101': 32.10740260245254,
        '-100': 32.10746995819293,
        '-99': 32.10769224284228,
        '-98': 32.10760024238499,
        '-97': 32.10732398941248,
        '-96': 32.107404176905845,
        '-95': 32.10773989761884,
        '-94': 32.107705738975085,
        '-93': 32.10733509888615,
        '-92': 32.10732378812768,
        '-91': 32.10772867042303,
        '-90': 32.10779261305786,
        '-89': 32.107326645536766,
        '-88': 32.10710320459596,
        '-87': 32.10746864382703,
        '-86': 32.107722260515914,
        '-85': 32.10741100636534,
        '-84': 32.107117407249824,
        '-83': 32.1073259984814,
        '-82': 32.10758688931822,
        '-81': 32.10749240342855,
        '-80': 32.10741166960094,
        '-79': 32.10754575895645,
        '-78': 32.10740952500903,
        '-77': 32.107042083725425,
        '-76': 32.10741207011582,
        '-75': 32.10857516582616,
        '-74': 32.10884584569128,
        '-73': 32.107261706155384,
        '-72': 32.10559784943701,
        '-71': 32.10611186236837,
        '-70': 32.09819150793294,
        '-69': 32.06717658046807,
        '-68': 32.072758413091854,
        '-67': 32.07096823004559,
        '-66': 32.06888129911119,
        '-65': 32.07719907910294,
        '-64': 32.09547418238793,
        '-63': 32.10121650201708,
        '-62': 32.103626875593655,
        '-61': 32.105740661857105,
        '-60': 32.10672788982522,
        '-59': 32.106801513784134,
        '-58': 32.10710209600202,
        '-57': 32.10565439083616,
        '-56': 32.10692761873703,
        '-55': 32.10407817526812,
        '-54': 32.10138165146154,
        '-53': 32.102213133462016,
        '-52': 32.102600541716136,
        '-51': 32.10356251598405,
        '-50': 32.10296848925694,
        '-49': 32.1017951021803,
        '-48': 32.10187444169898,
        '-47': 32.09660109528431,
        '-46': 32.1006689975301,
        '-45': 32.09987046293667,
        '-44': 32.09727682174759,
        '-43': 32.09857359742346,
        '-42': 32.09907381333305,
        '-41': 32.10331455377801,
        '-40': 32.10761218306382,
        '-39': 32.10761681209151,
        '-38': 32.10738099317145,
        '-37': 32.1072153658117,
        '-36': 32.10729194876111,
        '-35': 32.10751444532734,
        '-34': 32.10770138150826,
        '-33': 32.107757928651665,
        '-32': 32.107696630657486,
        '-31': 32.10758851529799,
        '-30': 32.10749911302741,
        '-29': 32.10742578801422,
        '-28': 32.107305960446745,
        '-27': 32.107117043855276,
        '-26': 32.10693957033938,
        '-25': 32.106873200515096,
        '-24': 32.1069117797884,
        '-23': 32.10696216653248,
        '-22': 32.10697793744435,
        '-21': 32.10700401616403,
        '-20': 32.107080430839275,
        '-19': 32.107176128662914,
        '-18': 32.10724544333122,
        '-17': 32.10728562169511,
        '-16': 32.10731228393508,
        '-15': 32.107344989088055,
        '-14': 32.10743502030964,
        '-13': 32.107615054597574,
        '-12': 32.10779200250718,
        '-11': 32.10780707997455,
        '-10': 32.10765746875896,
        '-9': 32.107528058873434,
        '-8': 32.10752429819407,
        '-7': 32.107522604599666,
        '-6': 32.107406281562675,
        '-5': 32.10729491141195,
        '-4': 32.10736120124181,
        '-3': 32.10753962622185,
        '-2': 32.10762246341218,
        '-1': 32.10756551765136
    },
    '-21': {
        '0': 32.109308878116366,
        '1': 32.109392639935045,
        '2': 32.109459719036415,
        '3': 32.10948081116685,
        '4': 32.10957953687763,
        '5': 32.10978919825443,
        '6': 32.1099745309436,
        '7': 32.11002364972212,
        '8': 32.109955981883125,
        '9': 32.10983381070705,
        '10': 32.109715919704286,
        '11': 32.10968489309341,
        '12': 32.10976907544726,
        '13': 32.10986626736997,
        '14': 32.1076951503403,
        '15': 32.10188184556646,
        '16': 32.09531212873213,
        '17': 32.0963867968464,
        '18': 32.09679311672115,
        '19': 32.09611867262522,
        '20': 32.097476947195325,
        '21': 32.09859907046189,
        '22': 32.09830348191454,
        '23': 32.09907295338168,
        '24': 32.099893160909545,
        '25': 32.100381701478256,
        '26': 32.10063449570379,
        '27': 32.098455053476634,
        '28': 32.099285161406044,
        '29': 32.099612136305545,
        '30': 32.10158136721258,
        '31': 32.104401126968895,
        '32': 32.10479491736906,
        '33': 32.10606303673085,
        '34': 32.10858842915345,
        '35': 32.10904120943445,
        '36': 32.108665527013606,
        '37': 32.108474345809704,
        '38': 32.10862299403742,
        '39': 32.10885327370104,
        '40': 32.10899966707691,
        '41': 32.10914057116706,
        '42': 32.10927656109794,
        '43': 32.10926476359906,
        '44': 32.10866091294831,
        '45': 32.106058992127664,
        '46': 32.10342876013789,
        '47': 32.09873862181704,
        '48': 32.107751538437164,
        '49': 32.109291060714064,
        '50': 32.10852935450442,
        '51': 32.10853587531919,
        '52': 32.10903793440073,
        '53': 32.109557735105774,
        '54': 32.109945331535386,
        '55': 32.110201514317325,
        '56': 32.11020825085633,
        '57': 32.1099326260316,
        '58': 32.10963769647049,
        '59': 32.1095994194426,
        '60': 32.1097623522509,
        '61': 32.10989246044354,
        '62': 32.109924368987194,
        '63': 32.109949077302645,
        '64': 32.10999186526802,
        '65': 32.11001621611026,
        '66': 32.11004991118818,
        '67': 32.11011725915736,
        '68': 32.11012924453907,
        '69': 32.110007059286595,
        '70': 32.109835135947975,
        '71': 32.10974535595018,
        '72': 32.109730226398156,
        '73': 32.10969747886023,
        '74': 32.10962057582702,
        '75': 32.10952398232092,
        '76': 32.109418384258674,
        '77': 32.109343441654474,
        '78': 32.10935828329894,
        '79': 32.1094138184603,
        '80': 32.10937540719204,
        '81': 32.109232896314026,
        '82': 32.10911854814242,
        '83': 32.10907981584485,
        '84': 32.10905859976277,
        '85': 32.10911678137263,
        '86': 32.10936404112903,
        '87': 32.10960245411785,
        '88': 32.109449370481386,
        '89': 32.10896362499957,
        '90': 32.10870488523547,
        '91': 32.108930041244335,
        '92': 32.109191365557784,
        '93': 32.10906244029928,
        '94': 32.10879196152485,
        '95': 32.108791214233335,
        '96': 32.108905650131874,
        '97': 32.10875038010375,
        '98': 32.10842900039432,
        '99': 32.10831983115942,
        '100': 32.10840994667805,
        '101': 32.10845092056666,
        '102': 32.10854340792558,
        '103': 32.10890575342883,
        '104': 32.10924264299823,
        '105': 32.109071161873814,
        '106': 32.108596031848094,
        '107': 32.1085119261852,
        '108': 32.108910193860666,
        '109': 32.109126267575796,
        '110': 32.10880246731657,
        '111': 32.10839420085177,
        '112': 32.10840249136039,
        '113': 32.1087427508804,
        '114': 32.10913158766606,
        '115': 32.109500012272065,
        '116': 32.10972599379188,
        '117': 32.10862984468664,
        '118': 32.10823834266906,
        '119': 32.10720240658701,
        '120': 32.10738484434549,
        '121': 32.10769259465366,
        '122': 32.107418069734905,
        '123': 32.106939546072454,
        '124': 32.10621012940413,
        '125': 32.10612084102971,
        '126': 32.10535609434594,
        '127': 32.10565945716114,
        '128': 32.10569122675184,
        '129': 32.106405390229874,
        '130': 32.106185878966,
        '131': 32.10540389174444,
        '132': 32.10471499164735,
        '133': 32.10504707173574,
        '134': 32.10576921302892,
        '135': 32.10456915522214,
        '136': 32.1067977041016,
        '137': 32.10733841480784,
        '138': 32.10786145987473,
        '139': 32.107216872923395,
        '140': 32.10696519436379,
        '141': 32.10817347716414,
        '142': 32.10795077001851,
        '143': 32.10736062455165,
        '144': 32.10710054404395,
        '145': 32.10624553930422,
        '146': 32.10807008109044,
        '147': 32.107993940417686,
        '148': 32.10768034375761,
        '149': 32.11030968083454,
        '150': 32.1103160504082,
        '151': 32.11034556619751,
        '152': 32.1105027423895,
        '153': 32.11036731589632,
        '154': 32.1097878938538,
        '155': 32.10933684021578,
        '156': 32.10952298556808,
        '157': 32.109986518741856,
        '158': 32.11013411864634,
        '159': 32.11012862831651,
        '160': 32.110408610071104,
        '161': 32.110605956081635,
        '162': 32.110042864390024,
        '163': 32.10919116135734,
        '164': 32.10937199279582,
        '165': 32.109523711098845,
        '166': 32.111526897197166,
        '167': 32.11063876710188,
        '168': 32.10905598534204,
        '169': 32.108708389160995,
        '170': 32.10991328893327,
        '171': 32.11123459444,
        '172': 32.11157199877729,
        '173': 32.11127437020052,
        '174': 32.111069196320635,
        '175': 32.11091910079994,
        '176': 32.11046906351286,
        '177': 32.10992388305987,
        '178': 32.1097450645522,
        '179': 32.10982133350949,
        '180': 32.10972153763866,
        '-180': 32.10972153763866,
        '-179': 32.10969479654864,
        '-178': 32.110545244400534,
        '-177': 32.112168092234015,
        '-176': 32.1129957899746,
        '-175': 32.11171155332377,
        '-174': 32.109224333962864,
        '-173': 32.10780619031711,
        '-172': 32.10845354346273,
        '-171': 32.10978705365472,
        '-170': 32.110077698935434,
        '-169': 32.10937313558702,
        '-168': 32.10890618823454,
        '-167': 32.10912932256148,
        '-166': 32.109392081856804,
        '-165': 32.109272581807126,
        '-164': 32.10914458125493,
        '-163': 32.10933150366501,
        '-162': 32.10957931842204,
        '-161': 32.10962925515054,
        '-160': 32.109636994716794,
        '-159': 32.10973488349345,
        '-158': 32.10972840494666,
        '-157': 32.10951586042172,
        '-156': 32.10934904127753,
        '-155': 32.10940367064298,
        '-154': 32.109483938064336,
        '-153': 32.109395901318635,
        '-152': 32.10925549086529,
        '-151': 32.10920495129028,
        '-150': 32.109161214906145,
        '-149': 32.10905741951081,
        '-148': 32.10902193627618,
        '-147': 32.109118164442975,
        '-146': 32.10919161434891,
        '-145': 32.10914974573907,
        '-144': 32.1091211882752,
        '-143': 32.10918777523937,
        '-142': 32.10923373194621,
        '-141': 32.10919987679697,
        '-140': 32.10921085573038,
        '-139': 32.109313080781156,
        '-138': 32.10935783662232,
        '-137': 32.109279984799045,
        '-136': 32.109230109685825,
        '-135': 32.10929656556207,
        '-134': 32.109347394625054,
        '-133': 32.109279838368266,
        '-132': 32.10919283300183,
        '-131': 32.10918655343562,
        '-130': 32.109205063037166,
        '-129': 32.10919170017913,
        '-128': 32.10920591910732,
        '-127': 32.109285672676776,
        '-126': 32.10936324452677,
        '-125': 32.10939380662428,
        '-124': 32.10942276322283,
        '-123': 32.109485097876906,
        '-122': 32.1095646190611,
        '-121': 32.10964999455057,
        '-120': 32.10971607018405,
        '-119': 32.10970592495128,
        '-118': 32.10964714557048,
        '-117': 32.10967046113609,
        '-116': 32.10978330401546,
        '-115': 32.10980381024521,
        '-114': 32.109679725673196,
        '-113': 32.109628207864596,
        '-112': 32.10974599887022,
        '-111': 32.10978427113162,
        '-110': 32.109599053178655,
        '-109': 32.10948004937698,
        '-108': 32.10965287214418,
        '-107': 32.10981924057124,
        '-106': 32.10966599302507,
        '-105': 32.10946264773401,
        '-104': 32.10957888225637,
        '-103': 32.10976524878493,
        '-102': 32.10959788224407,
        '-101': 32.109317318336494,
        '-100': 32.109429857931254,
        '-99': 32.109731972627976,
        '-98': 32.109629909357565,
        '-97': 32.1092602793679,
        '-96': 32.10930167701737,
        '-95': 32.109724710475874,
        '-94': 32.10978925696285,
        '-93': 32.10937108731047,
        '-92': 32.10920169076299,
        '-91': 32.10955908656227,
        '-90': 32.10979762658788,
        '-89': 32.10953592671891,
        '-88': 32.10927836042436,
        '-87': 32.109407828487086,
        '-86': 32.10954094917299,
        '-85': 32.109387339577914,
        '-84': 32.10931295929562,
        '-83': 32.10952523543479,
        '-82': 32.10961901318513,
        '-81': 32.109443380331804,
        '-80': 32.10944030357901,
        '-79': 32.109611777439156,
        '-78': 32.10929660938574,
        '-77': 32.108609059211275,
        '-76': 32.10875643469118,
        '-75': 32.109973981744865,
        '-74': 32.11055528061065,
        '-73': 32.10932006077309,
        '-72': 32.107802225122484,
        '-71': 32.10824168417557,
        '-70': 32.10326703355706,
        '-69': 32.08056058741534,
        '-68': 32.07132432696576,
        '-67': 32.07446763908842,
        '-66': 32.07221200713739,
        '-65': 32.087758815761795,
        '-64': 32.10342661083688,
        '-63': 32.10321643394341,
        '-62': 32.10624843859973,
        '-61': 32.10846684886332,
        '-60': 32.10914528316101,
        '-59': 32.10896744989677,
        '-58': 32.109190841291024,
        '-57': 32.108244840414194,
        '-56': 32.10816232232719,
        '-55': 32.10458822245939,
        '-54': 32.10398725319725,
        '-53': 32.104015779712114,
        '-52': 32.10545928345495,
        '-51': 32.105141984439,
        '-50': 32.104202965766724,
        '-49': 32.10332982258102,
        '-48': 32.10401803654826,
        '-47': 32.098244488444536,
        '-46': 32.10202200542674,
        '-45': 32.10072282327534,
        '-44': 32.0994698417871,
        '-43': 32.101439914521265,
        '-42': 32.10722651169135,
        '-41': 32.10964679306457,
        '-40': 32.10956732103266,
        '-39': 32.10911790830928,
        '-38': 32.10882404539669,
        '-37': 32.108982322256495,
        '-36': 32.1093424551615,
        '-35': 32.10955086781709,
        '-34': 32.10959859260855,
        '-33': 32.10961942196731,
        '-32': 32.10957191947025,
        '-31': 32.1093866629582,
        '-30': 32.10919494718962,
        '-29': 32.10914360257729,
        '-28': 32.10914231892763,
        '-27': 32.109035337033006,
        '-26': 32.1088904313713,
        '-25': 32.10887463091145,
        '-24': 32.108947841362706,
        '-23': 32.108936275722705,
        '-22': 32.10884871414119,
        '-21': 32.10886064146528,
        '-20': 32.1090013967379,
        '-19': 32.10910818079,
        '-18': 32.1091110068482,
        '-17': 32.10912646381884,
        '-16': 32.109222637878666,
        '-15': 32.109318081301325,
        '-14': 32.10938073901312,
        '-13': 32.10950062719644,
        '-12': 32.10966927720281,
        '-11': 32.109709260152236,
        '-10': 32.10955345085328,
        '-9': 32.10939038702801,
        '-8': 32.10938020430725,
        '-7': 32.10941963077305,
        '-6': 32.10936565537548,
        '-5': 32.10930478313284,
        '-4': 32.10937914966491,
        '-3': 32.109498248710935,
        '-2': 32.109475060752736,
        '-1': 32.10934692686392
    },
    '-22': {
        '0': 32.111380867540475,
        '1': 32.11153538941164,
        '2': 32.111608097287494,
        '3': 32.111605188804454,
        '4': 32.111735536249206,
        '5': 32.11203886855976,
        '6': 32.11225460859074,
        '7': 32.11214498916745,
        '8': 32.11178161623108,
        '9': 32.11143660812989,
        '10': 32.111307797770834,
        '11': 32.1114016219242,
        '12': 32.111593208561295,
        '13': 32.11176483777446,
        '14': 32.11193452244147,
        '15': 32.10341824200462,
        '16': 32.09853622312705,
        '17': 32.09878843131407,
        '18': 32.095888537826006,
        '19': 32.09670786657836,
        '20': 32.098507740429675,
        '21': 32.09947688697314,
        '22': 32.10049533108104,
        '23': 32.10125289857558,
        '24': 32.10181598591085,
        '25': 32.101117920963624,
        '26': 32.10030446381014,
        '27': 32.10077156519552,
        '28': 32.10288444632094,
        '29': 32.105097977124224,
        '30': 32.10574828950726,
        '31': 32.10684520106172,
        '32': 32.10778306688724,
        '33': 32.11010812463961,
        '34': 32.10986793126335,
        '35': 32.110834505935884,
        '36': 32.1110195629482,
        '37': 32.11086335558895,
        '38': 32.11109003117109,
        '39': 32.11139606398717,
        '40': 32.111516198613636,
        '41': 32.11147462087284,
        '42': 32.11139382044429,
        '43': 32.11137446923788,
        '44': 32.108284888569976,
        '45': 32.108363282214604,
        '46': 32.10541130781793,
        '47': 32.0991090079621,
        '48': 32.11183651686255,
        '49': 32.11112224128497,
        '50': 32.11074987173213,
        '51': 32.11080397145917,
        '52': 32.11102086434711,
        '53': 32.111358005946194,
        '54': 32.111872234468265,
        '55': 32.112301235634625,
        '56': 32.11224578103521,
        '57': 32.111771998668935,
        '58': 32.11141845402783,
        '59': 32.11150724925651,
        '60': 32.11178956834602,
        '61': 32.11190142391019,
        '62': 32.11184716201609,
        '63': 32.11183571026705,
        '64': 32.11190306054016,
        '65': 32.11194631889559,
        '66': 32.111965161733266,
        '67': 32.11203157119776,
        '68': 32.1121105577536,
        '69': 32.11210119409115,
        '70': 32.11200188298888,
        '71': 32.11189700946035,
        '72': 32.11182709070392,
        '73': 32.11177031174758,
        '74': 32.11170127682866,
        '75': 32.11160346336986,
        '76': 32.11148353166953,
        '77': 32.11139997206421,
        '78': 32.11139276867566,
        '79': 32.111390753872804,
        '80': 32.11131150382909,
        '81': 32.11122218518722,
        '82': 32.11122987486866,
        '83': 32.11126301454951,
        '84': 32.11120218900898,
        '85': 32.1111750062146,
        '86': 32.111387462037875,
        '87': 32.11166878327634,
        '88': 32.11159764261331,
        '89': 32.111161811381905,
        '90': 32.11084537177272,
        '91': 32.11090131291594,
        '92': 32.11100861528188,
        '93': 32.11089224010624,
        '94': 32.11078607621789,
        '95': 32.110917809695124,
        '96': 32.111000669312276,
        '97': 32.11071335801115,
        '98': 32.11030986004824,
        '99': 32.11023560146344,
        '100': 32.11044554993436,
        '101': 32.11063060203123,
        '102': 32.11082514550311,
        '103': 32.111198264880734,
        '104': 32.11149316051986,
        '105': 32.1113394502605,
        '106': 32.11096249793353,
        '107': 32.110889311466465,
        '108': 32.11103567834886,
        '109': 32.11081774613157,
        '110': 32.11019043420657,
        '111': 32.10983626711313,
        '112': 32.11018008148678,
        '113': 32.11088738264035,
        '114': 32.109753490320685,
        '115': 32.1117934648762,
        '116': 32.1105807934554,
        '117': 32.107166006331084,
        '118': 32.10712636768311,
        '119': 32.106560444368434,
        '120': 32.10638124214254,
        '121': 32.107956389127985,
        '122': 32.108831990829614,
        '123': 32.10920858700184,
        '124': 32.108404192620554,
        '125': 32.1078276584907,
        '126': 32.10756953956403,
        '127': 32.10700242379335,
        '128': 32.1075611836714,
        '129': 32.10790527807714,
        '130': 32.10657390871742,
        '131': 32.105798293779955,
        '132': 32.10562067032939,
        '133': 32.10607643136278,
        '134': 32.107146907558295,
        '135': 32.10736860686453,
        '136': 32.10821144750588,
        '137': 32.109191883238864,
        '138': 32.10983020400834,
        '139': 32.11020197798504,
        '140': 32.11015190863016,
        '141': 32.10948349783368,
        '142': 32.10935187840689,
        '143': 32.10920616104059,
        '144': 32.10852217302348,
        '145': 32.10922457995807,
        '146': 32.109464506552705,
        '147': 32.110243690847824,
        '148': 32.109994821986156,
        '149': 32.10977534615946,
        '150': 32.11225471220788,
        '151': 32.112224606601,
        '152': 32.112284280406485,
        '153': 32.11218572475441,
        '154': 32.11183715900133,
        '155': 32.11159557407838,
        '156': 32.11172599121511,
        '157': 32.1118998007615,
        '158': 32.11176926543511,
        '159': 32.11168793825098,
        '160': 32.11212010684104,
        '161': 32.11259526961885,
        '162': 32.112218667711836,
        '163': 32.11123318720188,
        '164': 32.11101135131989,
        '165': 32.1121213788128,
        '166': 32.1133462166659,
        '167': 32.11326977959742,
        '168': 32.112117201139164,
        '169': 32.111239662407286,
        '170': 32.11128157931644,
        '171': 32.111739723967275,
        '172': 32.112067271020926,
        '173': 32.1122757780561,
        '174': 32.1124128536518,
        '175': 32.11228111689475,
        '176': 32.11192852414163,
        '177': 32.111767211299664,
        '178': 32.111893024082946,
        '179': 32.11187049774509,
        '180': 32.11160286275851,
        '-180': 32.11160286275851,
        '-179': 32.11183189902866,
        '-178': 32.11306746916652,
        '-177': 32.11435986015503,
        '-176': 32.114078087964195,
        '-175': 32.11205430952001,
        '-174': 32.11012458371133,
        '-173': 32.110032968542626,
        '-172': 32.11137551406687,
        '-171': 32.112318441115285,
        '-170': 32.11198484283396,
        '-169': 32.1112301813957,
        '-168': 32.11107777053648,
        '-167': 32.11136706353922,
        '-166': 32.11139435414746,
        '-165': 32.111137497289526,
        '-164': 32.11111817631562,
        '-163': 32.11143854103232,
        '-162': 32.11169003501365,
        '-161': 32.11168585608694,
        '-160': 32.11164819868163,
        '-159': 32.11167830546983,
        '-158': 32.11159467417485,
        '-157': 32.111368534829865,
        '-156': 32.111249542965616,
        '-155': 32.11134404365573,
        '-154': 32.11145294409061,
        '-153': 32.11143462759935,
        '-152': 32.11138619987793,
        '-151': 32.111367799104514,
        '-150': 32.111273746073465,
        '-149': 32.1110881565007,
        '-148': 32.11097730881722,
        '-147': 32.11102006826666,
        '-146': 32.11110094676026,
        '-145': 32.11114899606972,
        '-144': 32.111229831658065,
        '-143': 32.111335036048416,
        '-142': 32.11134013082967,
        '-141': 32.11124579444634,
        '-140': 32.111214705219005,
        '-139': 32.111299009277374,
        '-138': 32.11136606265879,
        '-137': 32.111352758925094,
        '-136': 32.11135675537985,
        '-135': 32.111417139045045,
        '-134': 32.111437763798286,
        '-133': 32.11138911020684,
        '-132': 32.111365382002035,
        '-131': 32.111387219366215,
        '-130': 32.11136094134162,
        '-129': 32.111279830453505,
        '-128': 32.11125653461037,
        '-127': 32.11131331302213,
        '-126': 32.11134312956077,
        '-125': 32.11131849674225,
        '-124': 32.111345279323444,
        '-123': 32.11146956046246,
        '-122': 32.111602436395565,
        '-121': 32.11166508452047,
        '-120': 32.11166679668589,
        '-119': 32.11164273729717,
        '-118': 32.11163646752874,
        '-117': 32.111697892116275,
        '-116': 32.11178676366192,
        '-115': 32.111777723797616,
        '-114': 32.11167415116566,
        '-113': 32.11165115494141,
        '-112': 32.111748136346336,
        '-111': 32.111753890093794,
        '-110': 32.11158904124275,
        '-109': 32.1115096819285,
        '-108': 32.11166254849847,
        '-107': 32.11176831087275,
        '-106': 32.11160394004425,
        '-105': 32.1114495102172,
        '-104': 32.111598962880976,
        '-103': 32.111776497662675,
        '-102': 32.11160654108795,
        '-101': 32.11134420132951,
        '-100': 32.11146034883834,
        '-99': 32.11175777594981,
        '-98': 32.11167868052224,
        '-97': 32.11132683642598,
        '-96': 32.11132751919351,
        '-95': 32.11171412024591,
        '-94': 32.111847136211374,
        '-93': 32.11152420128913,
        '-92': 32.111289040052114,
        '-91': 32.111451071478385,
        '-90': 32.111639075726124,
        '-89': 32.11157596654942,
        '-88': 32.111496425996876,
        '-87': 32.11152701199907,
        '-86': 32.111436996517845,
        '-85': 32.11127114643294,
        '-84': 32.11141717526492,
        '-83': 32.11177767581108,
        '-82': 32.11175829734334,
        '-81': 32.11136614466706,
        '-80': 32.111315146203886,
        '-79': 32.111675390932206,
        '-78': 32.11159119564183,
        '-77': 32.110905952199616,
        '-76': 32.110753880088645,
        '-75': 32.11162878655853,
        '-74': 32.11217049315801,
        '-73': 32.111186040514674,
        '-72': 32.10980587228362,
        '-71': 32.11003636842377,
        '-70': 32.09516442401635,
        '-69': 32.08341367984404,
        '-68': 32.06905465841186,
        '-67': 32.065360411220645,
        '-66': 32.07674603558841,
        '-65': 32.08094233056683,
        '-64': 32.10298728445423,
        '-63': 32.10740708268058,
        '-62': 32.10864113892419,
        '-61': 32.110234051925545,
        '-60': 32.11082613733445,
        '-59': 32.11072775839307,
        '-58': 32.11110378320076,
        '-57': 32.10948517384242,
        '-56': 32.106969985336065,
        '-55': 32.10701490220001,
        '-54': 32.10715124357825,
        '-53': 32.107028926651104,
        '-52': 32.107075214397995,
        '-51': 32.106428860042534,
        '-50': 32.105857589790006,
        '-49': 32.10552473156741,
        '-48': 32.10290551353516,
        '-47': 32.10516765229955,
        '-46': 32.098381297643826,
        '-45': 32.10320405859311,
        '-44': 32.10047102310408,
        '-43': 32.1084067801859,
        '-42': 32.10588179335802,
        '-41': 32.11183444129224,
        '-40': 32.111387858104315,
        '-39': 32.11071814939406,
        '-38': 32.11052532307234,
        '-37': 32.110927075686625,
        '-36': 32.111311253507075,
        '-35': 32.111269422452935,
        '-34': 32.11111001121752,
        '-33': 32.11120344236235,
        '-32': 32.11137012338427,
        '-31': 32.11128378583778,
        '-30': 32.11105436495924,
        '-29': 32.11098479913279,
        '-28': 32.111043063548976,
        '-27': 32.11099880813835,
        '-26': 32.11088330392449,
        '-25': 32.110902149526645,
        '-24': 32.111018031552874,
        '-23': 32.11101520547607,
        '-22': 32.110912750841464,
        '-21': 32.11093871818048,
        '-20': 32.111110194737904,
        '-19': 32.1111965145429,
        '-18': 32.11112848928255,
        '-17': 32.11110657661375,
        '-16': 32.11123400569809,
        '-15': 32.11135937540382,
        '-14': 32.11138648585619,
        '-13': 32.1114406032413,
        '-12': 32.111585583585764,
        '-11': 32.11165125789545,
        '-10': 32.111521073524024,
        '-9': 32.11135528881633,
        '-8': 32.111332764258776,
        '-7': 32.111377740185446,
        '-6': 32.11134972854519,
        '-5': 32.11131329384722,
        '-4': 32.111384941771774,
        '-3': 32.11147316569537,
        '-2': 32.111428681636795,
        '-1': 32.11133441782559
    },
    '-23': {
        '0': 32.113596554112355,
        '1': 32.11372470915434,
        '2': 32.113720163628216,
        '3': 32.113680040312815,
        '4': 32.113867943657716,
        '5': 32.11425827164374,
        '6': 32.11445258324107,
        '7': 32.11415912797541,
        '8': 32.11356986944296,
        '9': 32.113139675802,
        '10': 32.113107967588014,
        '11': 32.11334810265363,
        '12': 32.1136168656412,
        '13': 32.11383603373289,
        '14': 32.114104752573944,
        '15': 32.109741018359045,
        '16': 32.10381057298941,
        '17': 32.09521533085749,
        '18': 32.09969206929237,
        '19': 32.09949254996973,
        '20': 32.100683165246586,
        '21': 32.10111822023765,
        '22': 32.10196309261451,
        '23': 32.10295378512388,
        '24': 32.10366498400354,
        '25': 32.10330455714176,
        '26': 32.1018212872994,
        '27': 32.10283904474182,
        '28': 32.10525257868036,
        '29': 32.104589436722264,
        '30': 32.10181935999519,
        '31': 32.10957283793982,
        '32': 32.110884841126904,
        '33': 32.11254514677674,
        '34': 32.11267234243133,
        '35': 32.11236282534278,
        '36': 32.113517380121436,
        '37': 32.11339123882763,
        '38': 32.113518454375495,
        '39': 32.11372626993528,
        '40': 32.113781666272345,
        '41': 32.1136145048717,
        '42': 32.11335947682962,
        '43': 32.11333630177728,
        '44': 32.10880738179239,
        '45': 32.10460473725626,
        '46': 32.10552963447469,
        '47': 32.10899883134245,
        '48': 32.11363982879365,
        '49': 32.113017387558685,
        '50': 32.113105390744636,
        '51': 32.11340612618028,
        '52': 32.11345799197473,
        '53': 32.113465603774806,
        '54': 32.113765929190784,
        '55': 32.11411883511959,
        '56': 32.114037655273734,
        '57': 32.1136012431173,
        '58': 32.113392211005376,
        '59': 32.11365599750418,
        '60': 32.11400856478277,
        '61': 32.11406900526073,
        '62': 32.113954702933704,
        '63': 32.1139575717625,
        '64': 32.114068832026845,
        '65': 32.11409273281526,
        '66': 32.11402012791089,
        '67': 32.11401467359575,
        '68': 32.11412038619071,
        '69': 32.1142012881081,
        '70': 32.11414813122686,
        '71': 32.11400150381386,
        '72': 32.113872156887446,
        '73': 32.11382063890672,
        '74': 32.11381384215243,
        '75': 32.11377251380492,
        '76': 32.11367458017043,
        '77': 32.11358521761934,
        '78': 32.11354031907855,
        '79': 32.11347440131002,
        '80': 32.11336179174065,
        '81': 32.113323327566434,
        '82': 32.113425016319326,
        '83': 32.113486222244084,
        '84': 32.11333692946924,
        '85': 32.11316899750288,
        '86': 32.113297871164654,
        '87': 32.11361323998675,
        '88': 32.113661218561994,
        '89': 32.113315345870085,
        '90': 32.11294976346198,
        '91': 32.11283929425265,
        '92': 32.11282767182231,
        '93': 32.11276979177885,
        '94': 32.11283593329771,
        '95': 32.11308498783188,
        '96': 32.11317063960212,
        '97': 32.11285978692361,
        '98': 32.11248777047764,
        '99': 32.11248111078875,
        '100': 32.11273502764538,
        '101': 32.11289796404744,
        '102': 32.11297709721408,
        '103': 32.11317289362325,
        '104': 32.11337307285154,
        '105': 32.11335263881228,
        '106': 32.113268383706185,
        '107': 32.11337425069306,
        '108': 32.11341349393199,
        '109': 32.11294708457701,
        '110': 32.112217156604295,
        '111': 32.11199835071485,
        '112': 32.112538362469394,
        '113': 32.113289407576495,
        '114': 32.11308233816673,
        '115': 32.1130080364215,
        '116': 32.11225717401092,
        '117': 32.11127567396902,
        '118': 32.10644151923,
        '119': 32.10562914479619,
        '120': 32.10881628584658,
        '121': 32.10861518536895,
        '122': 32.109895362726064,
        '123': 32.109474159564826,
        '124': 32.109434258927394,
        '125': 32.10982338642249,
        '126': 32.109205002788755,
        '127': 32.109409059915535,
        '128': 32.108703071423015,
        '129': 32.10891506275613,
        '130': 32.10811158742221,
        '131': 32.10729592362268,
        '132': 32.10731897445666,
        '133': 32.10775308489854,
        '134': 32.10694278981238,
        '135': 32.10717865147416,
        '136': 32.110234531370175,
        '137': 32.111223755157575,
        '138': 32.11135491142779,
        '139': 32.11291193462264,
        '140': 32.11306609880489,
        '141': 32.11282127476291,
        '142': 32.11245131496108,
        '143': 32.11051737115235,
        '144': 32.11104262606283,
        '145': 32.111483951084075,
        '146': 32.11028287232876,
        '147': 32.11095611699593,
        '148': 32.111697319662895,
        '149': 32.112874449071555,
        '150': 32.1125818274808,
        '151': 32.11410062568832,
        '152': 32.114064142594906,
        '153': 32.113980269346115,
        '154': 32.113817752210004,
        '155': 32.11376426673967,
        '156': 32.11389892004725,
        '157': 32.11391668860515,
        '158': 32.11358533220261,
        '159': 32.11329197889498,
        '160': 32.113577224563166,
        '161': 32.114193094825296,
        '162': 32.114287515122506,
        '163': 32.11365551315797,
        '164': 32.11315342618877,
        '165': 32.1135470286847,
        '166': 32.11448275940009,
        '167': 32.11502117033674,
        '168': 32.114779225652285,
        '169': 32.11409869632805,
        '170': 32.11342419243016,
        '171': 32.11301416395761,
        '172': 32.11303549135059,
        '173': 32.113429206200536,
        '174': 32.113791336807715,
        '175': 32.113818072773846,
        '176': 32.11374499454995,
        '177': 32.11391521485089,
        '178': 32.11411074915115,
        '179': 32.113934964765726,
        '180': 32.113759381303794,
        '-180': 32.113759381303794,
        '-179': 32.114431005796156,
        '-178': 32.11576554528726,
        '-177': 32.11619618945536,
        '-176': 32.114655455154605,
        '-175': 32.1122914867584,
        '-174': 32.11137202368582,
        '-173': 32.11256908121023,
        '-172': 32.114241284983116,
        '-171': 32.11462148588197,
        '-170': 32.113848172802655,
        '-169': 32.11327381740572,
        '-168': 32.11344635937153,
        '-167': 32.11369027072478,
        '-166': 32.11345886756627,
        '-165': 32.11312877757924,
        '-164': 32.11322262729981,
        '-163': 32.11357755901431,
        '-162': 32.11372645859723,
        '-161': 32.113631835275875,
        '-160': 32.11356565618309,
        '-159': 32.11356207892803,
        '-158': 32.113458206357116,
        '-157': 32.11330562474797,
        '-156': 32.11330966285894,
        '-155': 32.11344136742106,
        '-154': 32.11348079298192,
        '-153': 32.11339992214835,
        '-152': 32.11337027328281,
        '-151': 32.11341833206265,
        '-150': 32.11338891670954,
        '-149': 32.11325085344258,
        '-148': 32.11315163804377,
        '-147': 32.113152596312844,
        '-146': 32.113166904641936,
        '-145': 32.113173525001784,
        '-144': 32.11324705941298,
        '-143': 32.113357993201994,
        '-142': 32.1133771770017,
        '-141': 32.11330292359063,
        '-140': 32.11327138138601,
        '-139': 32.11332599969461,
        '-138': 32.11337724036984,
        '-137': 32.11339296361944,
        '-136': 32.113427670347555,
        '-135': 32.11346773007837,
        '-134': 32.11345048257021,
        '-133': 32.11342731975041,
        '-132': 32.11350206317371,
        '-131': 32.11362069104025,
        '-130': 32.11363241452254,
        '-129': 32.11354415608951,
        '-128': 32.113498523191154,
        '-127': 32.113516333235125,
        '-126': 32.11348963278516,
        '-125': 32.11342345775381,
        '-124': 32.11344771515045,
        '-123': 32.11357288818469,
        '-122': 32.113656815468154,
        '-121': 32.11364212432361,
        '-120': 32.1136271934113,
        '-119': 32.11367734623883,
        '-118': 32.11374483838528,
        '-117': 32.1137882035772,
        '-116': 32.11381096968394,
        '-115': 32.11379364634783,
        '-114': 32.11373503047022,
        '-113': 32.11371584122324,
        '-112': 32.113774094198405,
        '-111': 32.11380096610747,
        '-110': 32.11372030906138,
        '-109': 32.113647294940165,
        '-108': 32.113680363082295,
        '-107': 32.113697048416036,
        '-106': 32.113590264681456,
        '-105': 32.113520119636014,
        '-104': 32.11363901465352,
        '-103': 32.11376174026758,
        '-102': 32.11366198239858,
        '-101': 32.1135089115222,
        '-100': 32.11359483876823,
        '-99': 32.113794194566466,
        '-98': 32.11376568485649,
        '-97': 32.113568276890966,
        '-96': 32.113561661406955,
        '-95': 32.11376664367146,
        '-94': 32.11385478324957,
        '-93': 32.113740577474275,
        '-92': 32.11365399246056,
        '-91': 32.11364805707499,
        '-90': 32.11357544137593,
        '-89': 32.11351916318773,
        '-88': 32.113665248900695,
        '-87': 32.11379883881164,
        '-86': 32.11358529952864,
        '-85': 32.11329694904233,
        '-84': 32.11348034769281,
        '-83': 32.11391409425338,
        '-82': 32.11382659229031,
        '-81': 32.11325083304649,
        '-80': 32.11309663796848,
        '-79': 32.11359531545097,
        '-78': 32.113843865913154,
        '-77': 32.113419335295326,
        '-76': 32.1132188937293,
        '-75': 32.11384291549121,
        '-74': 32.114291691345656,
        '-73': 32.1134763265047,
        '-72': 32.11216783705782,
        '-71': 32.112067193624085,
        '-70': 32.098588216589846,
        '-69': 32.08879063546121,
        '-68': 32.08661717572444,
        '-67': 32.06988402025966,
        '-66': 32.08169496292498,
        '-65': 32.07255767905066,
        '-64': 32.10940041995459,
        '-63': 32.11017704195328,
        '-62': 32.111040363000406,
        '-61': 32.11232850338788,
        '-60': 32.11269902409517,
        '-59': 32.11253976439195,
        '-58': 32.112792161944924,
        '-57': 32.112773054954026,
        '-56': 32.111331962123856,
        '-55': 32.10918802705201,
        '-54': 32.10965874736357,
        '-53': 32.10882931786782,
        '-52': 32.108991829640146,
        '-51': 32.109152763230796,
        '-50': 32.108037417941105,
        '-49': 32.10568415716462,
        '-48': 32.107983683782045,
        '-47': 32.106171637591686,
        '-46': 32.10661102860447,
        '-45': 32.104770851502266,
        '-44': 32.11410367507208,
        '-43': 32.11379762502375,
        '-42': 32.11281929580274,
        '-41': 32.113641007765594,
        '-40': 32.11322180935897,
        '-39': 32.112712139244834,
        '-38': 32.112712403138715,
        '-37': 32.11316909124429,
        '-36': 32.113377141190774,
        '-35': 32.11306346176089,
        '-34': 32.11277899473615,
        '-33': 32.11297769137207,
        '-32': 32.11333342023911,
        '-31': 32.1133457346624,
        '-30': 32.11311027645962,
        '-29': 32.11302181004199,
        '-28': 32.11308820480472,
        '-27': 32.11304546243146,
        '-26': 32.11291424304189,
        '-25': 32.11294136706309,
        '-24': 32.113111522536,
        '-23': 32.11317523035205,
        '-22': 32.113111740094965,
        '-21': 32.11314627682121,
        '-20': 32.11330977001724,
        '-19': 32.11337471559705,
        '-18': 32.113276644016906,
        '-17': 32.11323679218473,
        '-16': 32.11337451168504,
        '-15': 32.11352368712846,
        '-14': 32.113553614756405,
        '-13': 32.11357313152804,
        '-12': 32.11366720874896,
        '-11': 32.11370238022652,
        '-10': 32.11357482195718,
        '-9': 32.11342230620618,
        '-8': 32.11339724923618,
        '-7': 32.11342553980411,
        '-6': 32.11338566283061,
        '-5': 32.11334671291891,
        '-4': 32.113417236147285,
        '-3': 32.11351558473089,
        '-2': 32.11351750780021,
        '-1': 32.113499746343784
    },
    '-24': {
        '0': 32.115832512545424,
        '1': 32.11588925152505,
        '2': 32.11583472104854,
        '3': 32.11583811576396,
        '4': 32.116129945951805,
        '5': 32.116570286008155,
        '6': 32.11669673059644,
        '7': 32.116286070033205,
        '8': 32.115660212429326,
        '9': 32.11531442682808,
        '10': 32.11538544435464,
        '11': 32.115623227038164,
        '12': 32.115798930849444,
        '13': 32.11597007986596,
        '14': 32.11630578631632,
        '15': 32.11182725511931,
        '16': 32.10621662520766,
        '17': 32.1024425509449,
        '18': 32.103564687034385,
        '19': 32.10393615934728,
        '20': 32.10366543580519,
        '21': 32.10425725340836,
        '22': 32.10467102347113,
        '23': 32.104772257906355,
        '24': 32.10477559211372,
        '25': 32.10471808229157,
        '26': 32.10510072311384,
        '27': 32.10631919437088,
        '28': 32.102821253957984,
        '29': 32.10330820517647,
        '30': 32.10277232624861,
        '31': 32.111619976317336,
        '32': 32.11348273705018,
        '33': 32.114831466915774,
        '34': 32.11513402173392,
        '35': 32.11520318718628,
        '36': 32.11587989676895,
        '37': 32.11573289643382,
        '38': 32.11566858937376,
        '39': 32.11571905157861,
        '40': 32.11576108670682,
        '41': 32.11561644925044,
        '42': 32.11531091469152,
        '43': 32.115258511073485,
        '44': 32.114400770177504,
        '45': 32.111753089137615,
        '46': 32.10737501064267,
        '47': 32.10461667507126,
        '48': 32.11552675013526,
        '49': 32.114961973663824,
        '50': 32.11535218790825,
        '51': 32.1159171687567,
        '52': 32.115978335050436,
        '53': 32.11576880366645,
        '54': 32.11580960052872,
        '55': 32.11603060037336,
        '56': 32.116007234145634,
        '57': 32.115752229162965,
        '58': 32.115698951226406,
        '59': 32.11597858645911,
        '60': 32.116228396180034,
        '61': 32.11618721186578,
        '62': 32.11605555299698,
        '63': 32.11610201362536,
        '64': 32.11624450731049,
        '65': 32.11624970798984,
        '66': 32.1161287761949,
        '67': 32.11609001053818,
        '68': 32.116203176749494,
        '69': 32.11631729787156,
        '70': 32.116287411635405,
        '71': 32.11614407649614,
        '72': 32.116025604176585,
        '73': 32.11601338459714,
        '74': 32.11605474370055,
        '75': 32.11603948862266,
        '76': 32.115944062633474,
        '77': 32.11585220093629,
        '78': 32.11580962258547,
        '79': 32.115754037862516,
        '80': 32.11566383254437,
        '81': 32.1156467544487,
        '82': 32.11573643087489,
        '83': 32.11574028421946,
        '84': 32.115517032265785,
        '85': 32.115290853513905,
        '86': 32.11538382716533,
        '87': 32.115688178967105,
        '88': 32.11575854871929,
        '89': 32.115452211079266,
        '90': 32.115109500954944,
        '91': 32.11501405431078,
        '92': 32.11505289710149,
        '93': 32.115059992391366,
        '94': 32.11511065180773,
        '95': 32.11524430144901,
        '96': 32.11525042627418,
        '97': 32.11502601030581,
        '98': 32.11484635445631,
        '99': 32.11496036621789,
        '100': 32.11517816209739,
        '101': 32.11519271266586,
        '102': 32.1150642221764,
        '103': 32.11502994363001,
        '104': 32.115072313670126,
        '105': 32.115069226576324,
        '106': 32.1151421691882,
        '107': 32.115401389736206,
        '108': 32.1155469574837,
        '109': 32.11525233698941,
        '110': 32.11481858156412,
        '111': 32.11487202191667,
        '112': 32.11544230480737,
        '113': 32.11591933627866,
        '114': 32.1158923532706,
        '115': 32.11411403205591,
        '116': 32.112962014327366,
        '117': 32.1107933454867,
        '118': 32.112112505906694,
        '119': 32.110741412750855,
        '120': 32.11008231504602,
        '121': 32.11052686032511,
        '122': 32.11072877240595,
        '123': 32.11156574172956,
        '124': 32.110873417982276,
        '125': 32.11142392847265,
        '126': 32.111359895964355,
        '127': 32.11100235165547,
        '128': 32.11075861431031,
        '129': 32.110287948559915,
        '130': 32.109272670746,
        '131': 32.10730082178091,
        '132': 32.10677563376599,
        '133': 32.10817901454252,
        '134': 32.109474813855236,
        '135': 32.111897426993416,
        '136': 32.113374376708286,
        '137': 32.11425676789681,
        '138': 32.11476434391297,
        '139': 32.11492704154004,
        '140': 32.11510253476379,
        '141': 32.11522588106906,
        '142': 32.11368884246823,
        '143': 32.11350469633215,
        '144': 32.11300244762612,
        '145': 32.11317704138183,
        '146': 32.11177200236564,
        '147': 32.11251078336552,
        '148': 32.111836322710246,
        '149': 32.11315933037841,
        '150': 32.115418018280856,
        '151': 32.11588162569184,
        '152': 32.116427812709354,
        '153': 32.116170520541715,
        '154': 32.11581387836189,
        '155': 32.1156262393038,
        '156': 32.11576966321618,
        '157': 32.11599860323741,
        '158': 32.115941016468895,
        '159': 32.11567387519148,
        '160': 32.11567531572376,
        '161': 32.116105660344154,
        '162': 32.11648247612788,
        '163': 32.116295839056804,
        '164': 32.115707202926245,
        '165': 32.115377975536134,
        '166': 32.1157167421597,
        '167': 32.116478079630426,
        '168': 32.11702648122856,
        '169': 32.11689280632304,
        '170': 32.116184850501135,
        '171': 32.115525033677095,
        '172': 32.1154431087919,
        '173': 32.115803338212146,
        '174': 32.116052547588964,
        '175': 32.11602482124013,
        '176': 32.11608501156051,
        '177': 32.11635361319188,
        '178': 32.11637707194725,
        '179': 32.11601047737654,
        '180': 32.11605632489101,
        '-180': 32.11605632489101,
        '-179': 32.11718060562649,
        '-178': 32.11845216019805,
        '-177': 32.118060888467554,
        '-176': 32.115768918788085,
        '-175': 32.113667365431134,
        '-174': 32.113789675805656,
        '-173': 32.11562369159942,
        '-172': 32.11690110352946,
        '-171': 32.11649916198982,
        '-170': 32.11549051861885,
        '-169': 32.1152697774201,
        '-168': 32.115737021390906,
        '-167': 32.115909544095324,
        '-166': 32.11554749882163,
        '-165': 32.115289697844354,
        '-164': 32.11548736724953,
        '-163': 32.11576372028231,
        '-162': 32.11576595987569,
        '-161': 32.115660528810466,
        '-160': 32.11567619754213,
        '-159': 32.1156908478015,
        '-158': 32.115562160299184,
        '-157': 32.1154675835015,
        '-156': 32.115589984075754,
        '-155': 32.11575428440793,
        '-154': 32.11569987098794,
        '-153': 32.11550303692649,
        '-152': 32.11542040056422,
        '-151': 32.11547517077401,
        '-150': 32.11550006000562,
        '-149': 32.115469053875564,
        '-148': 32.115487846763216,
        '-147': 32.11553034283718,
        '-146': 32.115467640521125,
        '-145': 32.115325891370176,
        '-144': 32.115267569583395,
        '-143': 32.115330817012314,
        '-142': 32.115400178466366,
        '-141': 32.115418575374584,
        '-140': 32.11543320606352,
        '-139': 32.115452710082614,
        '-138': 32.115442476967594,
        '-137': 32.11543626136884,
        '-136': 32.11548180360936,
        '-135': 32.11551703522223,
        '-134': 32.115471891216906,
        '-133': 32.11543542684272,
        '-132': 32.115533775280234,
        '-131': 32.11570098556889,
        '-130': 32.11577234060486,
        '-129': 32.11574869354315,
        '-128': 32.11575491077128,
        '-127': 32.11579104735063,
        '-126': 32.115758457492426,
        '-125': 32.115692757080616,
        '-124': 32.115722882708916,
        '-123': 32.11581672670529,
        '-122': 32.11581824983839,
        '-121': 32.115737187003084,
        '-120': 32.115749028670905,
        '-119': 32.115886536798676,
        '-118': 32.11598013725817,
        '-117': 32.11594587724863,
        '-116': 32.11589506091783,
        '-115': 32.1159025052866,
        '-114': 32.11589255387893,
        '-113': 32.11582647669694,
        '-112': 32.11579441327247,
        '-111': 32.115837659468355,
        '-110': 32.11586103448429,
        '-109': 32.11580389987509,
        '-108': 32.11574210773843,
        '-107': 32.11574880643244,
        '-106': 32.1157900540184,
        '-105': 32.11581442584404,
        '-104': 32.11582696704738,
        '-103': 32.11583794351754,
        '-102': 32.1158415880073,
        '-101': 32.11585503693777,
        '-100': 32.1158888817715,
        '-99': 32.11590817052532,
        '-98': 32.115905488639406,
        '-97': 32.11593339115954,
        '-96': 32.11597644336013,
        '-95': 32.11593432223836,
        '-94': 32.11584507590608,
        '-93': 32.1159055235785,
        '-92': 32.116096849247896,
        '-91': 32.116084993654866,
        '-90': 32.115769384081084,
        '-89': 32.1155839582804,
        '-88': 32.11583750526378,
        '-87': 32.11611441372581,
        '-86': 32.115909986391934,
        '-85': 32.11553650856759,
        '-84': 32.115638509398146,
        '-83': 32.11602894732638,
        '-82': 32.1159367428591,
        '-81': 32.115368061392815,
        '-80': 32.11517846617351,
        '-79': 32.11561752078106,
        '-78': 32.11590116999616,
        '-77': 32.11563987089719,
        '-76': 32.115570697394375,
        '-75': 32.1161660069365,
        '-74': 32.11654716659577,
        '-73': 32.115801923240404,
        '-72': 32.11463307875437,
        '-71': 32.11454330951595,
        '-70': 32.101892618227346,
        '-69': 32.092671757094514,
        '-68': 32.07865463061107,
        '-67': 32.07944026789491,
        '-66': 32.07537437307008,
        '-65': 32.108401780514264,
        '-64': 32.11138613377273,
        '-63': 32.11292615295411,
        '-62': 32.11409707764282,
        '-61': 32.11509595801425,
        '-60': 32.11508295812567,
        '-59': 32.11479020135399,
        '-58': 32.11506520583251,
        '-57': 32.115057251430834,
        '-56': 32.11397449380138,
        '-55': 32.1114727905629,
        '-54': 32.11245215187609,
        '-53': 32.110722236941825,
        '-52': 32.1118383331575,
        '-51': 32.10814151449968,
        '-50': 32.109597356384896,
        '-49': 32.108664930729894,
        '-48': 32.1084565257501,
        '-47': 32.10817277368767,
        '-46': 32.115838113825795,
        '-45': 32.11577608226312,
        '-44': 32.11546276675492,
        '-43': 32.11516346529047,
        '-42': 32.11516957343932,
        '-41': 32.11529824648443,
        '-40': 32.11520169835721,
        '-39': 32.11501576485779,
        '-38': 32.11515572624912,
        '-37': 32.11553996095606,
        '-36': 32.11562620859856,
        '-35': 32.11528607230725,
        '-34': 32.115041078303975,
        '-33': 32.115232990733276,
        '-32': 32.115511930534524,
        '-31': 32.11545569049235,
        '-30': 32.11522120341847,
        '-29': 32.11518202181512,
        '-28': 32.115290075185676,
        '-27': 32.11525664061301,
        '-26': 32.11511957859761,
        '-25': 32.11515695025473,
        '-24': 32.11536601046256,
        '-23': 32.11547245304885,
        '-22': 32.115413026020484,
        '-21': 32.11540598688295,
        '-20': 32.11553036270937,
        '-19': 32.11560479685857,
        '-18': 32.11554810517973,
        '-17': 32.11553159409899,
        '-16': 32.11566820717297,
        '-15': 32.11582741516437,
        '-14': 32.115878397268624,
        '-13': 32.115877399294774,
        '-12': 32.11588929305311,
        '-11': 32.1158426294333,
        '-10': 32.11570150512783,
        '-9': 32.115588953046284,
        '-8': 32.11559289452969,
        '-7': 32.11561732342589,
        '-6': 32.11556968313601,
        '-5': 32.1155346695192,
        '-4': 32.11560822818689,
        '-3': 32.11571177063625,
        '-2': 32.11574023289324,
        '-1': 32.115753052607914
    },
    '-25': {
        '0': 32.11808986696021,
        '1': 32.11810047126295,
        '2': 32.11806137182301,
        '3': 32.118157970775314,
        '4': 32.118528420340134,
        '5': 32.118937574073605,
        '6': 32.118963634336126,
        '7': 32.1185261066416,
        '8': 32.11801074758872,
        '9': 32.11780272754701,
        '10': 32.11787586382417,
        '11': 32.11795747654647,
        '12': 32.11796491377599,
        '13': 32.11810136705462,
        '14': 32.11852346935349,
        '15': 32.11518710384021,
        '16': 32.10921690810657,
        '17': 32.10373391623309,
        '18': 32.10663318815978,
        '19': 32.10693652006981,
        '20': 32.107483138573684,
        '21': 32.107810181471336,
        '22': 32.10750430027556,
        '23': 32.10758392516026,
        '24': 32.10726881547356,
        '25': 32.10565593607168,
        '26': 32.106944960024194,
        '27': 32.10767687527481,
        '28': 32.107652065526786,
        '29': 32.10854952313675,
        '30': 32.106576915875436,
        '31': 32.11050533905655,
        '32': 32.11493666659156,
        '33': 32.11723843028309,
        '34': 32.11775182756111,
        '35': 32.11819955624853,
        '36': 32.11818948797423,
        '37': 32.11796767370499,
        '38': 32.117716166264,
        '39': 32.117639133884275,
        '40': 32.11773667086217,
        '41': 32.11774717099675,
        '42': 32.11751366713874,
        '43': 32.11737716261084,
        '44': 32.11785585521134,
        '45': 32.11655997510194,
        '46': 32.11802583302343,
        '47': 32.118761607582556,
        '48': 32.11766300613086,
        '49': 32.117136701338225,
        '50': 32.11750273106158,
        '51': 32.11804403753286,
        '52': 32.11810593479506,
        '53': 32.11787949412714,
        '54': 32.11787592663505,
        '55': 32.11811240774359,
        '56': 32.11823350932487,
        '57': 32.11815329834423,
        '58': 32.11811222612465,
        '59': 32.11819636997039,
        '60': 32.118220173960665,
        '61': 32.11810988778671,
        '62': 32.1180559126093,
        '63': 32.118185367720805,
        '64': 32.118349664686235,
        '65': 32.118371953074934,
        '66': 32.118312349166686,
        '67': 32.11834054016971,
        '68': 32.11847207266705,
        '69': 32.11857195342122,
        '70': 32.11855444264481,
        '71': 32.118469056631696,
        '72': 32.118410848629296,
        '73': 32.11841016600832,
        '74': 32.118409283188484,
        '75': 32.11833449953891,
        '76': 32.11819710192016,
        '77': 32.118091298904694,
        '78': 32.118064863047714,
        '79': 32.118057381125894,
        '80': 32.118022264843745,
        '81': 32.118011294774654,
        '82': 32.118036365633365,
        '83': 32.11797054206401,
        '84': 32.1177609184969,
        '85': 32.11762725000383,
        '86': 32.11779645749498,
        '87': 32.1180959596272,
        '88': 32.11811078307136,
        '89': 32.11776413829504,
        '90': 32.11743509017563,
        '91': 32.117417848136306,
        '92': 32.11756276187099,
        '93': 32.11758865541195,
        '94': 32.11746970436153,
        '95': 32.11732985736331,
        '96': 32.117203355669254,
        '97': 32.117113834737935,
        '98': 32.117177173344245,
        '99': 32.117399455769416,
        '100': 32.11755650053815,
        '101': 32.11749274203821,
        '102': 32.117358380326436,
        '103': 32.117334103760705,
        '104': 32.11733095055512,
        '105': 32.11722061268314,
        '106': 32.11714052140072,
        '107': 32.11724742496621,
        '108': 32.117371431744836,
        '109': 32.11730142649802,
        '110': 32.11724974219541,
        '111': 32.11757770723368,
        '112': 32.11814021381435,
        '113': 32.11838734610982,
        '114': 32.11784513469897,
        '115': 32.11637673432002,
        '116': 32.11491351900035,
        '117': 32.11361866475604,
        '118': 32.113233107403495,
        '119': 32.11208132121777,
        '120': 32.11190483319285,
        '121': 32.11263482260147,
        '122': 32.112361416097215,
        '123': 32.112998677348465,
        '124': 32.11292528146281,
        '125': 32.112653867482926,
        '126': 32.11310239588682,
        '127': 32.11340013788822,
        '128': 32.112517153008135,
        '129': 32.11038882669353,
        '130': 32.11196033524279,
        '131': 32.11227682021205,
        '132': 32.11103766755613,
        '133': 32.11157662450584,
        '134': 32.1135506898628,
        '135': 32.11490059004374,
        '136': 32.11625515599882,
        '137': 32.11676667565823,
        '138': 32.11721051579313,
        '139': 32.11695966578314,
        '140': 32.117191861672076,
        '141': 32.11665423844114,
        '142': 32.11553548018644,
        '143': 32.11606128040573,
        '144': 32.115400795069334,
        '145': 32.113924702482606,
        '146': 32.1142864924144,
        '147': 32.112699599398276,
        '148': 32.11096201897228,
        '149': 32.11506465060016,
        '150': 32.11713896460247,
        '151': 32.11509574298406,
        '152': 32.11828987702428,
        '153': 32.11866014445111,
        '154': 32.11792683874921,
        '155': 32.11739051387116,
        '156': 32.11745381401292,
        '157': 32.11798776618365,
        '158': 32.118429152808574,
        '159': 32.1184335793151,
        '160': 32.118270691820896,
        '161': 32.11839775234845,
        '162': 32.11877256248063,
        '163': 32.11886474019948,
        '164': 32.11838243868004,
        '165': 32.11775371223163,
        '166': 32.11769739072221,
        '167': 32.118368090950355,
        '168': 32.11912301659093,
        '169': 32.119248081653154,
        '170': 32.11879363148107,
        '171': 32.11845462150729,
        '172': 32.11862327575173,
        '173': 32.118908471497434,
        '174': 32.118814372209165,
        '175': 32.11853475287289,
        '176': 32.11857327429122,
        '177': 32.11878710678443,
        '178': 32.118561349228266,
        '179': 32.118040846465746,
        '180': 32.11830129846119,
        '-180': 32.11830129846118,
        '-179': 32.1197026136495,
        '-178': 32.12079729755005,
        '-177': 32.119917062696324,
        '-176': 32.11759858481013,
        '-175': 32.116262720348665,
        '-174': 32.11721727211637,
        '-173': 32.11902046786869,
        '-172': 32.11946619349185,
        '-171': 32.11836125983117,
        '-170': 32.11735427383524,
        '-169': 32.11747176582252,
        '-168': 32.118024507771686,
        '-167': 32.11803835407333,
        '-166': 32.11765658873372,
        '-165': 32.11757198767807,
        '-164': 32.1178285049194,
        '-163': 32.11794200099928,
        '-162': 32.117822756918216,
        '-161': 32.11782972544028,
        '-160': 32.11803226935256,
        '-159': 32.11808265346295,
        '-158': 32.11786946995081,
        '-157': 32.117734993981315,
        '-156': 32.11788819675736,
        '-155': 32.11806468439982,
        '-154': 32.11798855616405,
        '-153': 32.11779564142115,
        '-152': 32.11774119162166,
        '-151': 32.11779079171756,
        '-150': 32.11778528930389,
        '-149': 32.11777560857219,
        '-148': 32.11788923132409,
        '-147': 32.11802297850769,
        '-146': 32.11796592068989,
        '-145': 32.117740682995745,
        '-144': 32.11756807376088,
        '-143': 32.1175486967256,
        '-142': 32.117596277906614,
        '-141': 32.11763571084562,
        '-140': 32.11766566737067,
        '-139': 32.117664620163886,
        '-138': 32.11761679241353,
        '-137': 32.117595131778906,
        '-136': 32.117659218095355,
        '-135': 32.11772416856862,
        '-134': 32.11768873992949,
        '-133': 32.117624590374696,
        '-132': 32.11766631607484,
        '-131': 32.11778493044724,
        '-130': 32.11785599799916,
        '-129': 32.117882155339146,
        '-128': 32.11794681105879,
        '-127': 32.118012848035306,
        '-126': 32.117987623413526,
        '-125': 32.1179350601865,
        '-124': 32.11798844021055,
        '-123': 32.11809235144845,
        '-122': 32.118082623927286,
        '-121': 32.117998245032,
        '-120': 32.1180381769588,
        '-119': 32.118200565518784,
        '-118': 32.11825807543127,
        '-117': 32.11814417902406,
        '-116': 32.11806770089619,
        '-115': 32.11814255985226,
        '-114': 32.118191378182104,
        '-113': 32.118072110295216,
        '-112': 32.11793977963065,
        '-111': 32.11797711657852,
        '-110': 32.118076939982636,
        '-109': 32.11803794846159,
        '-108': 32.11792672183198,
        '-107': 32.11797263776101,
        '-106': 32.11817152644671,
        '-105': 32.1182679624418,
        '-104': 32.11815427919749,
        '-103': 32.11804487607414,
        '-102': 32.11814413997737,
        '-101': 32.118321668954025,
        '-100': 32.118306294641926,
        '-99': 32.118119973158116,
        '-98': 32.11807345782035,
        '-97': 32.118278074226254,
        '-96': 32.118417754689446,
        '-95': 32.118216601887084,
        '-94': 32.11792980017851,
        '-93': 32.1180083609869,
        '-92': 32.11836151708494,
        '-91': 32.11842844638123,
        '-90': 32.11806312169439,
        '-89': 32.11781272556271,
        '-88': 32.11804559795814,
        '-87': 32.118334954613324,
        '-86': 32.118175934305754,
        '-85': 32.117847235672286,
        '-84': 32.117915295924995,
        '-83': 32.11822907324688,
        '-82': 32.11819244947825,
        '-81': 32.11784269464022,
        '-80': 32.11777655384685,
        '-79': 32.11803502999918,
        '-78': 32.1180245998613,
        '-77': 32.11770385643398,
        '-76': 32.11780937978977,
        '-75': 32.11847431405058,
        '-74': 32.1186523982183,
        '-73': 32.117684156679836,
        '-72': 32.11662324970152,
        '-71': 32.1170209398455,
        '-70': 32.09842851425638,
        '-69': 32.081205029746684,
        '-68': 32.08248729606186,
        '-67': 32.080839620624,
        '-66': 32.091740098158155,
        '-65': 32.10879685257906,
        '-64': 32.11399270343187,
        '-63': 32.11542299644031,
        '-62': 32.117027704702004,
        '-61': 32.11782530880669,
        '-60': 32.1176454296825,
        '-59': 32.117325989029624,
        '-58': 32.11757811901844,
        '-57': 32.11800201009815,
        '-56': 32.114426165210425,
        '-55': 32.1147738279402,
        '-54': 32.11252454991174,
        '-53': 32.110348486479744,
        '-52': 32.11036266195694,
        '-51': 32.112976898246615,
        '-50': 32.10917733571775,
        '-49': 32.10885504801249,
        '-48': 32.11831147025421,
        '-47': 32.11803577781692,
        '-46': 32.117695697434726,
        '-45': 32.11740884666742,
        '-44': 32.11711844398928,
        '-43': 32.11692388627555,
        '-42': 32.116985531030444,
        '-41': 32.11719939280753,
        '-40': 32.117305499684875,
        '-39': 32.11731207099369,
        '-38': 32.11745705213636,
        '-37': 32.11773681019985,
        '-36': 32.117860506186936,
        '-35': 32.1177431050827,
        '-34': 32.11765203318985,
        '-33': 32.11772946865677,
        '-32': 32.1177483870659,
        '-31': 32.117547133107585,
        '-30': 32.11735926792985,
        '-29': 32.11743723869449,
        '-28': 32.11763097490924,
        '-27': 32.11765763267299,
        '-26': 32.117574988217825,
        '-25': 32.11764041481077,
        '-24': 32.11783956996671,
        '-23': 32.117914856118205,
        '-22': 32.117807716559554,
        '-21': 32.11773492431167,
        '-20': 32.11781282602005,
        '-19': 32.117905498589586,
        '-18': 32.117905493933115,
        '-17': 32.11790738368857,
        '-16': 32.118007764109436,
        '-15': 32.11813725195977,
        '-14': 32.11819301571787,
        '-13': 32.11817882842838,
        '-12': 32.11812792599915,
        '-11': 32.118030782148665,
        '-10': 32.11791824623686,
        '-9': 32.11788062656533,
        '-8': 32.11792680275251,
        '-7': 32.117951694259105,
        '-6': 32.117913315161594,
        '-5': 32.117906913238116,
        '-4': 32.11798752902339,
        '-3': 32.11806377065855,
        '-2': 32.11806240233613,
        '-1': 32.11805203953041
    },
    '-26': {
        '0': 32.120399360555794,
        '1': 32.12041610700919,
        '2': 32.12041261118454,
        '3': 32.120547482458704,
        '4': 32.1208796414876,
        '5': 32.12116001075572,
        '6': 32.12108917754614,
        '7': 32.120718085942386,
        '8': 32.12038978375556,
        '9': 32.12029618113051,
        '10': 32.12029443696659,
        '11': 32.120211928447375,
        '12': 32.12015092051727,
        '13': 32.12035665733997,
        '14': 32.12085856967499,
        '15': 32.11883420262656,
        '16': 32.11293670399762,
        '17': 32.108825834308924,
        '18': 32.11143272406381,
        '19': 32.11013712712837,
        '20': 32.110729562273235,
        '21': 32.11107731069281,
        '22': 32.11069483849287,
        '23': 32.11011721391612,
        '24': 32.10911867441894,
        '25': 32.108385896431756,
        '26': 32.105666601659244,
        '27': 32.10468812859077,
        '28': 32.107092547523315,
        '29': 32.10580641461715,
        '30': 32.10489288138931,
        '31': 32.10804913027423,
        '32': 32.114483411483874,
        '33': 32.12036184327565,
        '34': 32.12044542487373,
        '35': 32.12056415388601,
        '36': 32.120502432552755,
        '37': 32.120195992770384,
        '38': 32.11982172786115,
        '39': 32.11968363771261,
        '40': 32.11987154777905,
        '41': 32.120083821799845,
        '42': 32.11999285922681,
        '43': 32.11976130409909,
        '44': 32.11989582222548,
        '45': 32.120493961053135,
        '46': 32.12096447166096,
        '47': 32.12075087231883,
        '48': 32.12008710965951,
        '49': 32.11968269927902,
        '50': 32.119792984238025,
        '51': 32.12001111419756,
        '52': 32.11997731721623,
        '53': 32.1198643849512,
        '54': 32.119994259527104,
        '55': 32.12031277557853,
        '56': 32.12051400517857,
        '57': 32.1204851934596,
        '58': 32.12035977364622,
        '59': 32.12024552366073,
        '60': 32.120154121086905,
        '61': 32.120143794154245,
        '62': 32.12029343487049,
        '63': 32.120530525751434,
        '64': 32.120675945461805,
        '65': 32.120685384940266,
        '66': 32.12069209899478,
        '67': 32.120785005070495,
        '68': 32.12088988088252,
        '69': 32.12091381366804,
        '70': 32.12088080888133,
        '71': 32.12086223429143,
        '72': 32.120863374863156,
        '73': 32.120837430730006,
        '74': 32.12075396976915,
        '75': 32.120618215071325,
        '76': 32.1204673879153,
        '77': 32.120359226091075,
        '78': 32.12032037425218,
        '79': 32.12031426857096,
        '80': 32.120301880959126,
        '81': 32.120293178904234,
        '82': 32.12027937456051,
        '83': 32.120193301782365,
        '84': 32.12004976995946,
        '85': 32.12003670411148,
        '86': 32.12028041546279,
        '87': 32.12056548359892,
        '88': 32.12052199890122,
        '89': 32.12011988549037,
        '90': 32.1197467816745,
        '91': 32.11971104817257,
        '92': 32.11987578779191,
        '93': 32.11990754205915,
        '94': 32.11970749278588,
        '95': 32.11944706424547,
        '96': 32.11931572681741,
        '97': 32.119382284848555,
        '98': 32.11959714541017,
        '99': 32.1198031359489,
        '100': 32.11984583309003,
        '101': 32.119775967563356,
        '102': 32.11981365319866,
        '103': 32.12001131159345,
        '104': 32.12012521083879,
        '105': 32.11996296735162,
        '106': 32.11966702707802,
        '107': 32.119464501547405,
        '108': 32.11933881328991,
        '109': 32.119208144540465,
        '110': 32.11924127207375,
        '111': 32.119644932990965,
        '112': 32.120230354913936,
        '113': 32.1205528816783,
        '114': 32.12046334373453,
        '115': 32.118620808800884,
        '116': 32.11696771874077,
        '117': 32.116187605406395,
        '118': 32.11533365916184,
        '119': 32.11443210350728,
        '120': 32.11435807024212,
        '121': 32.11497609596269,
        '122': 32.11541838474768,
        '123': 32.115198483434774,
        '124': 32.11498495787748,
        '125': 32.11544435922203,
        '126': 32.11509144208541,
        '127': 32.11455406262892,
        '128': 32.11543822551511,
        '129': 32.11447685306128,
        '130': 32.11279800644774,
        '131': 32.112785587976155,
        '132': 32.11344029159545,
        '133': 32.113814370552475,
        '134': 32.11557886595122,
        '135': 32.11776857681543,
        '136': 32.11873985497868,
        '137': 32.11934323507921,
        '138': 32.11979631743357,
        '139': 32.11959188214793,
        '140': 32.11937763255246,
        '141': 32.11889530378057,
        '142': 32.11873267663377,
        '143': 32.118139589109326,
        '144': 32.11697480544966,
        '145': 32.11676108563116,
        '146': 32.11719201552796,
        '147': 32.115919740518166,
        '148': 32.11579689114596,
        '149': 32.1171936771173,
        '150': 32.1179917459284,
        '151': 32.118088543779315,
        '152': 32.11914136795026,
        '153': 32.12091137583007,
        '154': 32.1203408718647,
        '155': 32.11960206840891,
        '156': 32.11948954596321,
        '157': 32.11999067352533,
        '158': 32.1205603553007,
        '159': 32.12071633191897,
        '160': 32.12056762379609,
        '161': 32.12058786240287,
        '162': 32.12090633623681,
        '163': 32.12109357753712,
        '164': 32.12078141396481,
        '165': 32.12026671736598,
        '166': 32.12019770070564,
        '167': 32.12070663273474,
        '168': 32.1212004483852,
        '169': 32.12116474518425,
        '170': 32.120873768804664,
        '171': 32.120955166163725,
        '172': 32.121413047656816,
        '173': 32.12159317374528,
        '174': 32.12120224616227,
        '175': 32.12079948928941,
        '176': 32.12089911344144,
        '177': 32.1210822519544,
        '178': 32.12069869328302,
        '179': 32.12018235185654,
        '180': 32.1206791961642,
        '-180': 32.12067919616419,
        '-179': 32.122156613657445,
        '-178': 32.122901382689896,
        '-177': 32.12166225134972,
        '-176': 32.11960818248184,
        '-175': 32.119065505564215,
        '-174': 32.12051413554744,
        '-173': 32.122007542108435,
        '-172': 32.12181005708863,
        '-171': 32.12050094538623,
        '-170': 32.119791675308264,
        '-169': 32.120121416559044,
        '-168': 32.120480938619764,
        '-167': 32.12022420163886,
        '-166': 32.119868359843814,
        '-165': 32.11996919477447,
        '-164': 32.12021542333881,
        '-163': 32.12012113143697,
        '-162': 32.11990416403563,
        '-161': 32.1200445576653,
        '-160': 32.12040484057328,
        '-159': 32.120450559014856,
        '-158': 32.120133147212464,
        '-157': 32.119929214254974,
        '-156': 32.12005480813161,
        '-155': 32.12020905006554,
        '-154': 32.12016551120787,
        '-153': 32.12010913161403,
        '-152': 32.12021352900285,
        '-151': 32.12031040421971,
        '-150': 32.1202276931287,
        '-149': 32.12012965576396,
        '-148': 32.12023861293148,
        '-147': 32.120450252191866,
        '-146': 32.12050404584212,
        '-145': 32.120374632780546,
        '-144': 32.120236108747974,
        '-143': 32.12015654337317,
        '-142': 32.12007176284244,
        '-141': 32.11998159875332,
        '-140': 32.119947996993034,
        '-139': 32.11994737308637,
        '-138': 32.119915131318756,
        '-137': 32.11989105953361,
        '-136': 32.11994919060777,
        '-135': 32.1200349201882,
        '-134': 32.12004097073423,
        '-133': 32.119994731512314,
        '-132': 32.120002967764606,
        '-131': 32.1200622573443,
        '-130': 32.12009412291159,
        '-129': 32.12011298851566,
        '-128': 32.120177793158504,
        '-127': 32.120231067716574,
        '-126': 32.12018464383894,
        '-125': 32.120119487418236,
        '-124': 32.120184205800236,
        '-123': 32.12032780609758,
        '-122': 32.120368564286316,
        '-121': 32.12031195467096,
        '-120': 32.120338185359415,
        '-119': 32.12045904219339,
        '-118': 32.12047611840178,
        '-117': 32.120348397396114,
        '-116': 32.12030293358326,
        '-115': 32.12044713687067,
        '-114': 32.12055614757321,
        '-113': 32.120442954517124,
        '-112': 32.12028621730378,
        '-111': 32.12033084357473,
        '-110': 32.1204665372914,
        '-109': 32.12042344792745,
        '-108': 32.12025996782278,
        '-107': 32.12029004129554,
        '-106': 32.120542679932846,
        '-105': 32.12066881756531,
        '-104': 32.12049294505132,
        '-103': 32.12031930813885,
        '-102': 32.12046179351424,
        '-101': 32.120726076645205,
        '-100': 32.120691922318294,
        '-99': 32.120383137236395,
        '-98': 32.12026007317559,
        '-97': 32.12050754470103,
        '-96': 32.12072837147176,
        '-95': 32.12055545527441,
        '-94': 32.12023399537672,
        '-93': 32.12023431520774,
        '-92': 32.12050246157068,
        '-91': 32.12056543625583,
        '-90': 32.1203175815654,
        '-89': 32.12017643395215,
        '-88': 32.120354545570315,
        '-87': 32.12050476249289,
        '-86': 32.12034855188407,
        '-85': 32.12016844173825,
        '-84': 32.12028597939423,
        '-83': 32.12048064506712,
        '-82': 32.12042669235486,
        '-81': 32.12032897550379,
        '-80': 32.12050440375711,
        '-79': 32.120651832630294,
        '-78': 32.12032490792929,
        '-77': 32.11993147905185,
        '-76': 32.12027621077175,
        '-75': 32.12104746476625,
        '-74': 32.12088243048397,
        '-73': 32.11942388090763,
        '-72': 32.11831482416387,
        '-71': 32.11929254113956,
        '-70': 32.11079483441724,
        '-69': 32.083727220074785,
        '-68': 32.08496566665683,
        '-67': 32.07497683496359,
        '-66': 32.103721759082944,
        '-65': 32.10922811160857,
        '-64': 32.117087009194734,
        '-63': 32.11808275408917,
        '-62': 32.11926438574308,
        '-61': 32.11992187427531,
        '-60': 32.11983761322576,
        '-59': 32.11977652597945,
        '-58': 32.12027275378219,
        '-57': 32.11986438380565,
        '-56': 32.11841383015629,
        '-55': 32.11773789035151,
        '-54': 32.1160411003132,
        '-53': 32.1147716087795,
        '-52': 32.112721658138646,
        '-51': 32.11060707647767,
        '-50': 32.11294587242759,
        '-49': 32.109233168139866,
        '-48': 32.120624403362676,
        '-47': 32.120158592487925,
        '-46': 32.11967289908996,
        '-45': 32.11940930523687,
        '-44': 32.119335970761426,
        '-43': 32.11931968739581,
        '-42': 32.119351951671284,
        '-41': 32.11944822745315,
        '-40': 32.119530677136666,
        '-39': 32.1195619761658,
        '-38': 32.1196347797696,
        '-37': 32.1198078119806,
        '-36': 32.11999551478055,
        '-35': 32.120111514886176,
        '-34': 32.120170845532144,
        '-33': 32.12016933044427,
        '-32': 32.1200369130175,
        '-31': 32.1198181362404,
        '-30': 32.11972283331082,
        '-29': 32.119856080489704,
        '-28': 32.12004932821026,
        '-27': 32.12011218329807,
        '-26': 32.12010799474406,
        '-25': 32.12019851118324,
        '-24': 32.12033883792988,
        '-23': 32.120348483943594,
        '-22': 32.12022164850248,
        '-21': 32.1201425953032,
        '-20': 32.12019909852784,
        '-19': 32.120279563917705,
        '-18': 32.120283799335795,
        '-17': 32.120265370852096,
        '-16': 32.12030829121562,
        '-15': 32.120392927290986,
        '-14': 32.120446946774706,
        '-13': 32.12043846243226,
        '-12': 32.12037580430887,
        '-11': 32.12029075679378,
        '-10': 32.12024483480332,
        '-9': 32.12027599645051,
        '-8': 32.120330166601185,
        '-7': 32.12032868085538,
        '-6': 32.120299264608214,
        '-5': 32.12033470151042,
        '-4': 32.12042355973333,
        '-3': 32.12045326295458,
        '-2': 32.120399606061454,
        '-1': 32.12036693209834
    },
    '-27': {
        '0': 32.12277281908791,
        '1': 32.12284017448552,
        '2': 32.1228609981096,
        '3': 32.12294516800851,
        '4': 32.1231198402529,
        '5': 32.123211187178924,
        '6': 32.12308305626077,
        '7': 32.122862355723775,
        '8': 32.12276192523504,
        '9': 32.12276851722155,
        '10': 32.12270008751805,
        '11': 32.12254336663666,
        '12': 32.122531978655104,
        '13': 32.122835753210175,
        '14': 32.12331706717209,
        '15': 32.12367399936346,
        '16': 32.115385411154676,
        '17': 32.112458548209965,
        '18': 32.11570606272376,
        '19': 32.111549813447866,
        '20': 32.114427159094674,
        '21': 32.1141826122255,
        '22': 32.113606484412486,
        '23': 32.11254164113085,
        '24': 32.109952463722635,
        '25': 32.11052491880198,
        '26': 32.10854487953357,
        '27': 32.10982351922782,
        '28': 32.109006940765475,
        '29': 32.108865878258605,
        '30': 32.10725035139371,
        '31': 32.11145736572123,
        '32': 32.11640684915412,
        '33': 32.122846441541334,
        '34': 32.12281757413802,
        '35': 32.12288660583496,
        '36': 32.122796855116825,
        '37': 32.1224482600546,
        '38': 32.12203565872319,
        '39': 32.1219000032599,
        '40': 32.1221481884419,
        '41': 32.12247340198089,
        '42': 32.12249356880955,
        '43': 32.12224238519406,
        '44': 32.1221354904615,
        '45': 32.122410415042395,
        '46': 32.12280790608466,
        '47': 32.12293083618435,
        '48': 32.12273660307815,
        '49': 32.12248319771458,
        '50': 32.12232160209344,
        '51': 32.12219441797389,
        '52': 32.122094337915605,
        '53': 32.12215261705839,
        '54': 32.122403234888125,
        '55': 32.122660328832666,
        '56': 32.12273403656612,
        '57': 32.12263607371328,
        '58': 32.12249471393861,
        '59': 32.122400600443804,
        '60': 32.12242249384562,
        '61': 32.12263224478725,
        '62': 32.12298181732172,
        '63': 32.1232588362371,
        '64': 32.123307117563265,
        '65': 32.12322350225627,
        '66': 32.12320541719222,
        '67': 32.12327046980058,
        '68': 32.123279297198195,
        '69': 32.12319294636491,
        '70': 32.12313287126653,
        '71': 32.12317348445264,
        '72': 32.12322920114996,
        '73': 32.1231958013644,
        '74': 32.12308773917703,
        '75': 32.122978796003046,
        '76': 32.12289403463943,
        '77': 32.122816857124256,
        '78': 32.12274285126807,
        '79': 32.12268209525552,
        '80': 32.12264415187148,
        '81': 32.12263297734317,
        '82': 32.12262084464111,
        '83': 32.12255215013531,
        '84': 32.12244098137491,
        '85': 32.12242344575161,
        '86': 32.12259151128926,
        '87': 32.122788671623084,
        '88': 32.12272872028527,
        '89': 32.122362687501834,
        '90': 32.12198203861672,
        '91': 32.12187369754759,
        '92': 32.12199087881887,
        '93': 32.1220692519213,
        '94': 32.12197837142151,
        '95': 32.12183939042142,
        '96': 32.121838152395874,
        '97': 32.12201648828086,
        '98': 32.12223521318996,
        '99': 32.12230366478145,
        '100': 32.12218248957314,
        '101': 32.12206605820187,
        '102': 32.122177265485995,
        '103': 32.122465413587314,
        '104': 32.1226351579129,
        '105': 32.122514518874816,
        '106': 32.122232860224194,
        '107': 32.12196038411504,
        '108': 32.12167709598276,
        '109': 32.12136328871228,
        '110': 32.12122908396845,
        '111': 32.121501336018376,
        '112': 32.122077820501424,
        '113': 32.12262997040487,
        '114': 32.12216501631157,
        '115': 32.120674357438666,
        '116': 32.12022923940686,
        '117': 32.1185494855182,
        '118': 32.11744081229939,
        '119': 32.11651454804554,
        '120': 32.11666294454884,
        '121': 32.11739205350802,
        '122': 32.117016422885925,
        '123': 32.11711790411469,
        '124': 32.11741898106312,
        '125': 32.117567605518765,
        '126': 32.11759959975226,
        '127': 32.11716342012687,
        '128': 32.118020382510934,
        '129': 32.1182949130117,
        '130': 32.116789789331285,
        '131': 32.11709154589265,
        '132': 32.11712302637132,
        '133': 32.11759224150316,
        '134': 32.11918690881399,
        '135': 32.120408962834524,
        '136': 32.12124346585142,
        '137': 32.121741617700316,
        '138': 32.12205131523783,
        '139': 32.1219554091079,
        '140': 32.121737919614745,
        '141': 32.121360533828685,
        '142': 32.121403536041015,
        '143': 32.12072909306704,
        '144': 32.12034497557056,
        '145': 32.12010493983349,
        '146': 32.1201355329716,
        '147': 32.11950923399511,
        '148': 32.11930928551203,
        '149': 32.11956084479089,
        '150': 32.11996472857379,
        '151': 32.12012862767574,
        '152': 32.118388754567654,
        '153': 32.1235349274804,
        '154': 32.12303096424542,
        '155': 32.12237657104917,
        '156': 32.12213723388307,
        '157': 32.12231650282504,
        '158': 32.122561929351114,
        '159': 32.12260072297715,
        '160': 32.12255231912804,
        '161': 32.122704824322426,
        '162': 32.123031443653474,
        '163': 32.123151669800556,
        '164': 32.12287109974202,
        '165': 32.122544242798185,
        '166': 32.12264897535304,
        '167': 32.12310191652116,
        '168': 32.123336546257846,
        '169': 32.123117306977996,
        '170': 32.12290617635288,
        '171': 32.12316586930108,
        '172': 32.12360745428246,
        '173': 32.12359003479469,
        '174': 32.12313495479225,
        '175': 32.12293894677051,
        '176': 32.12325214188135,
        '177': 32.12340851179812,
        '178': 32.12294767719101,
        '179': 32.12263156325545,
        '180': 32.12346742532308,
        '-180': 32.12346742532307,
        '-179': 32.124908541720586,
        '-178': 32.12512117459366,
        '-177': 32.1234205128747,
        '-176': 32.1215047426953,
        '-175': 32.12145182033734,
        '-174': 32.12306585642667,
        '-173': 32.12425392719199,
        '-172': 32.123832728245794,
        '-171': 32.12279532107414,
        '-170': 32.122538841833176,
        '-169': 32.122946487924146,
        '-168': 32.1229937893484,
        '-167': 32.12250431170773,
        '-166': 32.12224208742865,
        '-165': 32.12251196364819,
        '-164': 32.12271497542614,
        '-163': 32.122444985698934,
        '-162': 32.122157160350795,
        '-161': 32.122347357403775,
        '-160': 32.122718249413964,
        '-159': 32.122696203000416,
        '-158': 32.122342569253384,
        '-157': 32.12216796830952,
        '-156': 32.122292726045956,
        '-155': 32.12238122092021,
        '-154': 32.122316221396204,
        '-153': 32.122367967580345,
        '-152': 32.1226333472442,
        '-151': 32.12280572763333,
        '-150': 32.12267812718424,
        '-149': 32.12248650230352,
        '-148': 32.122530300812215,
        '-147': 32.12274908536309,
        '-146': 32.122908483156564,
        '-145': 32.12296428227277,
        '-144': 32.12299179081519,
        '-143': 32.12294167312895,
        '-142': 32.12273366619654,
        '-141': 32.12247810860738,
        '-140': 32.122359265370555,
        '-139': 32.12236666983792,
        '-138': 32.12235061381043,
        '-137': 32.12228360041872,
        '-136': 32.12226527980091,
        '-135': 32.12231288335522,
        '-134': 32.1223440966584,
        '-133': 32.12234598245785,
        '-132': 32.12238142074622,
        '-131': 32.122442450822696,
        '-130': 32.12246360937417,
        '-129': 32.122463431544595,
        '-128': 32.12250726439176,
        '-127': 32.12255156602324,
        '-126': 32.12250439636146,
        '-125': 32.12242511723921,
        '-124': 32.122466073997764,
        '-123': 32.122610800187424,
        '-122': 32.122679589406225,
        '-121': 32.122623249566026,
        '-120': 32.12259105996186,
        '-119': 32.122650669530415,
        '-118': 32.122673647330046,
        '-117': 32.12260003818065,
        '-116': 32.12258383479402,
        '-115': 32.122721153518114,
        '-114': 32.12284501477055,
        '-113': 32.122792363437796,
        '-112': 32.122696111548976,
        '-111': 32.12276445392924,
        '-110': 32.12290539985346,
        '-109': 32.12286571187391,
        '-108': 32.12267737336079,
        '-107': 32.12263899619094,
        '-106': 32.12282583290638,
        '-105': 32.122945130395244,
        '-104': 32.122806670532725,
        '-103': 32.12264386340244,
        '-102': 32.122740275434836,
        '-101': 32.12295761509276,
        '-100': 32.122941974849944,
        '-99': 32.12268824388139,
        '-98': 32.12255576176507,
        '-97': 32.12271278934158,
        '-96': 32.12290038137089,
        '-95': 32.122865859055985,
        '-94': 32.12273141600533,
        '-93': 32.12272309295412,
        '-92': 32.12278038920023,
        '-91': 32.12272302868741,
        '-90': 32.12262844966538,
        '-89': 32.122695708064946,
        '-88': 32.12283436778085,
        '-87': 32.12277960031522,
        '-86': 32.1225881869664,
        '-85': 32.12258453851258,
        '-84': 32.12276512738507,
        '-83': 32.1227543660063,
        '-82': 32.12251175353564,
        '-81': 32.12251606223494,
        '-80': 32.122911185207165,
        '-79': 32.12307339943701,
        '-78': 32.1226181652869,
        '-77': 32.122283410662206,
        '-76': 32.12289390416245,
        '-75': 32.12375868212207,
        '-74': 32.12330788608146,
        '-73': 32.12150916677279,
        '-72': 32.120454379184885,
        '-71': 32.12184443041767,
        '-70': 32.10779945436764,
        '-69': 32.08715389716397,
        '-68': 32.07787913102451,
        '-67': 32.09141326947655,
        '-66': 32.08959674802767,
        '-65': 32.11913995712176,
        '-64': 32.120733314620445,
        '-63': 32.12094877069371,
        '-62': 32.12143036907513,
        '-61': 32.12202991092941,
        '-60': 32.122118133221115,
        '-59': 32.12228891198777,
        '-58': 32.1227121869144,
        '-57': 32.12238878688362,
        '-56': 32.12149213676523,
        '-55': 32.119992535618465,
        '-54': 32.11926274252633,
        '-53': 32.11909392752142,
        '-52': 32.11362368524509,
        '-51': 32.113481665223375,
        '-50': 32.116665561927576,
        '-49': 32.11951393187801,
        '-48': 32.122894291472065,
        '-47': 32.12245742207124,
        '-46': 32.12190736789777,
        '-45': 32.12167961514038,
        '-44': 32.121800011180625,
        '-43': 32.12194615060737,
        '-42': 32.12194296126875,
        '-41': 32.12189572247591,
        '-40': 32.1219112477624,
        '-39': 32.12195460245775,
        '-38': 32.121988929800935,
        '-37': 32.122064310332014,
        '-36': 32.12221953742464,
        '-35': 32.12240417626515,
        '-34': 32.12253374494695,
        '-33': 32.12256535687975,
        '-32': 32.12251516349826,
        '-31': 32.122444592806474,
        '-30': 32.12241790672382,
        '-29': 32.122440273505426,
        '-28': 32.122462923009714,
        '-27': 32.1224716190519,
        '-26': 32.12251574425468,
        '-25': 32.122610525751504,
        '-24': 32.12267750731911,
        '-23': 32.12264983398255,
        '-22': 32.12258291173847,
        '-21': 32.12257932286885,
        '-20': 32.122640010881575,
        '-19': 32.12267543086346,
        '-18': 32.122646663084666,
        '-17': 32.12261334992513,
        '-16': 32.122639799758005,
        '-15': 32.122717395926514,
        '-14': 32.12278707562876,
        '-13': 32.12279577130603,
        '-12': 32.12273552649175,
        '-11': 32.122660698250264,
        '-10': 32.12264528331009,
        '-9': 32.12268936033444,
        '-8': 32.1227089001434,
        '-7': 32.1226644659058,
        '-6': 32.122638882844086,
        '-5': 32.12270864275453,
        '-4': 32.122800275059454,
        '-3': 32.122790327078306,
        '-2': 32.12270703594506,
        '-1': 32.1226916194736
    },
    '-28': {
        '0': 32.12524136336278,
        '1': 32.12537628255734,
        '2': 32.12542027775039,
        '3': 32.12542055915798,
        '4': 32.12539776481541,
        '5': 32.125296975066476,
        '6': 32.12514716175521,
        '7': 32.12510374224437,
        '8': 32.125219125558225,
        '9': 32.125308749892376,
        '10': 32.12520114809974,
        '11': 32.125026512682844,
        '12': 32.12507229247774,
        '13': 32.12538969572036,
        '14': 32.12572958496824,
        '15': 32.12587758884878,
        '16': 32.12340840261038,
        '17': 32.1192161312603,
        '18': 32.11846854164906,
        '19': 32.11670500908089,
        '20': 32.116709015625005,
        '21': 32.11621346549658,
        '22': 32.11431541694802,
        '23': 32.112923459227794,
        '24': 32.111474313759345,
        '25': 32.1130653994138,
        '26': 32.11244307021305,
        '27': 32.11171726905207,
        '28': 32.11013135973581,
        '29': 32.10958233609005,
        '30': 32.11368561903963,
        '31': 32.116007403239436,
        '32': 32.12030733656736,
        '33': 32.12506984131138,
        '34': 32.125084976010605,
        '35': 32.12524362573395,
        '36': 32.12518702172917,
        '37': 32.12482396557207,
        '38': 32.12442731595411,
        '39': 32.124336573807646,
        '40': 32.12457820659049,
        '41': 32.12484091327743,
        '42': 32.12484762705365,
        '43': 32.124675028077085,
        '44': 32.12463007579144,
        '45': 32.12486489261232,
        '46': 32.1252252443338,
        '47': 32.12546219710696,
        '48': 32.12546336974488,
        '49': 32.12525639509118,
        '50': 32.124934752610834,
        '51': 32.12466516916207,
        '52': 32.124639699733756,
        '53': 32.12487842639321,
        '54': 32.12514175197548,
        '55': 32.12517202575247,
        '56': 32.12498851176033,
        '57': 32.12482537778351,
        '58': 32.124824251226755,
        '59': 32.124941551108925,
        '60': 32.12513311070695,
        '61': 32.12542702552246,
        '62': 32.125760417542246,
        '63': 32.12593537472834,
        '64': 32.12585950425565,
        '65': 32.12569686764875,
        '66': 32.12564546968131,
        '67': 32.125670706569196,
        '68': 32.125612080989725,
        '69': 32.125473176614584,
        '70': 32.125420013183486,
        '71': 32.12551128577136,
        '72': 32.125609664841704,
        '73': 32.12559456904006,
        '74': 32.1255157669681,
        '75': 32.125474043600605,
        '76': 32.125463762391455,
        '77': 32.12541312990519,
        '78': 32.125307332308466,
        '79': 32.125198360963246,
        '80': 32.12513310026479,
        '81': 32.125118120406526,
        '82': 32.12512112344844,
        '83': 32.12508384040503,
        '84': 32.1249814592071,
        '85': 32.12488460993025,
        '86': 32.1248920335349,
        '87': 32.124971579737846,
        '88': 32.12495162921899,
        '89': 32.12473381557131,
        '90': 32.12444799838036,
        '91': 32.124313471280786,
        '92': 32.1243835336919,
        '93': 32.124510917947454,
        '94': 32.12455402220061,
        '95': 32.12453732481381,
        '96': 32.124582329720894,
        '97': 32.12472440113205,
        '98': 32.12484358165505,
        '99': 32.124796057149986,
        '100': 32.12460402264272,
        '101': 32.124461828594534,
        '102': 32.12450855601965,
        '103': 32.12464619078119,
        '104': 32.124674473595974,
        '105': 32.12456615759436,
        '106': 32.124463675565806,
        '107': 32.124401799369714,
        '108': 32.12422747782203,
        '109': 32.12388680004555,
        '110': 32.12361864870655,
        '111': 32.12370705362219,
        '112': 32.12415417811873,
        '113': 32.12474605843566,
        '114': 32.125315757662634,
        '115': 32.12321297683665,
        '116': 32.1229012794766,
        '117': 32.12076272580738,
        '118': 32.120613027443355,
        '119': 32.118782543509624,
        '120': 32.12014886128287,
        '121': 32.119912106774485,
        '122': 32.11967730673232,
        '123': 32.12005970053896,
        '124': 32.12098159360417,
        '125': 32.12074736647845,
        '126': 32.12015994373716,
        '127': 32.12042084429169,
        '128': 32.11945209351795,
        '129': 32.12058613776335,
        '130': 32.11969792565968,
        '131': 32.11991873657437,
        '132': 32.120061037948275,
        '133': 32.12151276861561,
        '134': 32.12228010720082,
        '135': 32.123602117147335,
        '136': 32.124106684851576,
        '137': 32.124648814813646,
        '138': 32.12450106939656,
        '139': 32.124092187507294,
        '140': 32.12416101185031,
        '141': 32.12374892405847,
        '142': 32.124285519725824,
        '143': 32.12362696608827,
        '144': 32.12348707899932,
        '145': 32.123269017732945,
        '146': 32.123283501738854,
        '147': 32.12336516874004,
        '148': 32.12284124293102,
        '149': 32.12267739010626,
        '150': 32.12234702961983,
        '151': 32.12156715629848,
        '152': 32.12106665440163,
        '153': 32.12546797294304,
        '154': 32.12556812876261,
        '155': 32.12507171778411,
        '156': 32.12490171484719,
        '157': 32.12493445480889,
        '158': 32.124911762195765,
        '159': 32.124813308183406,
        '160': 32.12486646560675,
        '161': 32.12518170072433,
        '162': 32.125496991480674,
        '163': 32.12543842291661,
        '164': 32.12503071735193,
        '165': 32.124755091703854,
        '166': 32.124974135190804,
        '167': 32.1254523218155,
        '168': 32.12567127906625,
        '169': 32.1255378272107,
        '170': 32.12545771741541,
        '171': 32.125652966099544,
        '172': 32.12578843381263,
        '173': 32.12554849006751,
        '174': 32.12525964844436,
        '175': 32.12544366198152,
        '176': 32.12589205667641,
        '177': 32.1258534326905,
        '178': 32.1253062707637,
        '179': 32.12531661611434,
        '180': 32.12652889490926,
        '-180': 32.12652889490926,
        '-179': 32.127813581121366,
        '-178': 32.127381690888456,
        '-177': 32.1252251768249,
        '-176': 32.123458273963095,
        '-175': 32.123789974154825,
        '-174': 32.12544900113938,
        '-173': 32.12636733332848,
        '-172': 32.125864663331065,
        '-171': 32.125127948962344,
        '-170': 32.12518287349391,
        '-169': 32.125560433256645,
        '-168': 32.12538867806541,
        '-167': 32.12485397217964,
        '-166': 32.124758931645545,
        '-165': 32.12514908054207,
        '-164': 32.12530436241396,
        '-163': 32.12496017841805,
        '-162': 32.12467619958086,
        '-161': 32.124852098198026,
        '-160': 32.12511024956949,
        '-159': 32.12499275067657,
        '-158': 32.12469934374469,
        '-157': 32.12467146044843,
        '-156': 32.1248434556398,
        '-155': 32.1248356702664,
        '-154': 32.12466183190786,
        '-153': 32.12469862743297,
        '-152': 32.125015274703564,
        '-151': 32.12523590933668,
        '-150': 32.12513331137346,
        '-149': 32.12493792968351,
        '-148': 32.12492478488966,
        '-147': 32.12504829682968,
        '-146': 32.12516274650694,
        '-145': 32.12529756181764,
        '-144': 32.12548560552047,
        '-143': 32.12555814531059,
        '-142': 32.125371841415934,
        '-141': 32.12508275142254,
        '-140': 32.12494694089434,
        '-139': 32.124957628484566,
        '-138': 32.124913672821506,
        '-137': 32.12476561723059,
        '-136': 32.12465476724201,
        '-135': 32.12464870374161,
        '-134': 32.1246670912583,
        '-133': 32.124669170782504,
        '-132': 32.124710011483465,
        '-131': 32.124789902893845,
        '-130': 32.124830086858765,
        '-129': 32.124827815617934,
        '-128': 32.12486800679364,
        '-127': 32.12494939809004,
        '-126': 32.12496740561764,
        '-125': 32.12491215503337,
        '-124': 32.124910354567554,
        '-123': 32.12500468223017,
        '-122': 32.125061868788734,
        '-121': 32.12499296003998,
        '-120': 32.1249065213776,
        '-119': 32.12492475834706,
        '-118': 32.124988243960715,
        '-117': 32.12498440241437,
        '-116': 32.12495105005322,
        '-115': 32.124989868969614,
        '-114': 32.125067497616534,
        '-113': 32.12507974431951,
        '-112': 32.12505534147683,
        '-111': 32.12510885622248,
        '-110': 32.125214820836675,
        '-109': 32.12522186874936,
        '-108': 32.125109103838255,
        '-107': 32.12504116338137,
        '-106': 32.12510984147531,
        '-105': 32.1251913296566,
        '-104': 32.12515479899089,
        '-103': 32.12506659426098,
        '-102': 32.12506100199289,
        '-101': 32.12512100750613,
        '-100': 32.12513416848991,
        '-99': 32.12508956315961,
        '-98': 32.12506583943637,
        '-97': 32.12507490050477,
        '-96': 32.125076845235014,
        '-95': 32.125112353780125,
        '-94': 32.12523730402557,
        '-93': 32.12534241617195,
        '-92': 32.125259616755386,
        '-91': 32.12507619153445,
        '-90': 32.125081645326446,
        '-89': 32.125302174829685,
        '-88': 32.12538312615486,
        '-87': 32.125140678971974,
        '-86': 32.124924733919514,
        '-85': 32.12508078762674,
        '-84': 32.12531371515671,
        '-83': 32.12511042173181,
        '-82': 32.124657881745804,
        '-81': 32.12466000601783,
        '-80': 32.12516378536811,
        '-79': 32.12535337923073,
        '-78': 32.1248886177295,
        '-77': 32.124646444983725,
        '-76': 32.12535842456291,
        '-75': 32.12613200434138,
        '-74': 32.125506407797886,
        '-73': 32.12383832923418,
        '-72': 32.12331446343362,
        '-71': 32.11919568394851,
        '-70': 32.114256031065075,
        '-69': 32.07934278935536,
        '-68': 32.09678543799641,
        '-67': 32.11494447488658,
        '-66': 32.10076106619521,
        '-65': 32.12072297080838,
        '-64': 32.12366061398694,
        '-63': 32.12393605422703,
        '-62': 32.124491082875345,
        '-61': 32.124786306650954,
        '-60': 32.12489570385486,
        '-59': 32.12512586312702,
        '-58': 32.12495329964821,
        '-57': 32.12488224992099,
        '-56': 32.12373923474922,
        '-55': 32.122550937050214,
        '-54': 32.12078683013318,
        '-53': 32.12059000633628,
        '-52': 32.11871464594744,
        '-51': 32.117813161407646,
        '-50': 32.11370426370044,
        '-49': 32.11954827427789,
        '-48': 32.125515844071955,
        '-47': 32.12508026674118,
        '-46': 32.12441078139373,
        '-45': 32.12409586553072,
        '-44': 32.124251164676664,
        '-43': 32.12447297107379,
        '-42': 32.124471529123944,
        '-41': 32.12437410143685,
        '-40': 32.1243889477792,
        '-39': 32.124490870421575,
        '-38': 32.12455710057072,
        '-37': 32.12459829988421,
        '-36': 32.12469941408187,
        '-35': 32.124848394537935,
        '-34': 32.124965240080144,
        '-33': 32.1250435199373,
        '-32': 32.12513939869013,
        '-31': 32.12523595085052,
        '-30': 32.125227987244955,
        '-29': 32.12507243749079,
        '-28': 32.12488095705946,
        '-27': 32.124807339518206,
        '-26': 32.124873214722214,
        '-25': 32.12496090505153,
        '-24': 32.12497384399094,
        '-23': 32.12494520757447,
        '-22': 32.12496184321821,
        '-21': 32.12503078512776,
        '-20': 32.12507810406943,
        '-19': 32.12506110178133,
        '-18': 32.12502126136863,
        '-17': 32.12502184885354,
        '-16': 32.12508194646233,
        '-15': 32.12517860002326,
        '-14': 32.125265801377886,
        '-13': 32.12528446147692,
        '-12': 32.12520964337953,
        '-11': 32.125102707185505,
        '-10': 32.125057176344505,
        '-9': 32.12507472602775,
        '-8': 32.12506640876331,
        '-7': 32.125005182502086,
        '-6': 32.124985045915956,
        '-5': 32.125065708980436,
        '-4': 32.12515170881849,
        '-3': 32.12513015371077,
        '-2': 32.125059574449004,
        '-1': 32.12509155899253
    },
    '-29': {
        '0': 32.12779931131616,
        '1': 32.12797554131897,
        '2': 32.12804720755688,
        '3': 32.127994246187036,
        '4': 32.127823239597845,
        '5': 32.127582704275554,
        '6': 32.127430189347564,
        '7': 32.12752009761768,
        '8': 32.12776778514018,
        '9': 32.12788013281955,
        '10': 32.12772417373117,
        '11': 32.1275319469731,
        '12': 32.127596205671466,
        '13': 32.12786991862615,
        '14': 32.12805791835356,
        '15': 32.12805049469785,
        '16': 32.12803617247654,
        '17': 32.12502095927243,
        '18': 32.12144068602736,
        '19': 32.122142811889105,
        '20': 32.117777394450606,
        '21': 32.11845175204569,
        '22': 32.11857040176521,
        '23': 32.11474679245525,
        '24': 32.11757969207213,
        '25': 32.11615733500104,
        '26': 32.11482104297822,
        '27': 32.11325567766179,
        '28': 32.11185466180041,
        '29': 32.098695778684906,
        '30': 32.11604701770874,
        '31': 32.12423289852785,
        '32': 32.127651717779045,
        '33': 32.12730080880847,
        '34': 32.1274998090753,
        '35': 32.12782161011858,
        '36': 32.127776224543716,
        '37': 32.12734498595312,
        '38': 32.126955057971664,
        '39': 32.126940670993015,
        '40': 32.127171821125664,
        '41': 32.12729250608656,
        '42': 32.12720709382444,
        '43': 32.127164875282375,
        '44': 32.127383834949036,
        '45': 32.127767381144395,
        '46': 32.12806033002647,
        '47': 32.12814183846,
        '48': 32.12804571456657,
        '49': 32.127808616286536,
        '50': 32.12748615569013,
        '51': 32.127274691203944,
        '52': 32.12738804195519,
        '53': 32.12774424665412,
        '54': 32.127960904971815,
        '55': 32.12779423529143,
        '56': 32.12745665013963,
        '57': 32.12734811423008,
        '58': 32.12756785398912,
        '59': 32.12787195019645,
        '60': 32.12805433269685,
        '61': 32.12814614020239,
        '62': 32.128218657776834,
        '63': 32.128216553739904,
        '64': 32.12810021153574,
        '65': 32.12798743444345,
        '66': 32.12799934307389,
        '67': 32.12806578694534,
        '68': 32.12803869090082,
        '69': 32.1279353904791,
        '70': 32.12790913958737,
        '71': 32.128002461274285,
        '72': 32.12808886618926,
        '73': 32.128076502736505,
        '74': 32.12802687284731,
        '75': 32.12802093423421,
        '76': 32.128024356924925,
        '77': 32.127960500026866,
        '78': 32.127837463239715,
        '79': 32.12772667994654,
        '80': 32.12766580195921,
        '81': 32.12764434952522,
        '82': 32.12764292027265,
        '83': 32.12763158958036,
        '84': 32.1275714920809,
        '85': 32.12747600979515,
        '86': 32.127430455253645,
        '87': 32.12748232085314,
        '88': 32.12754151420966,
        '89': 32.12747207164552,
        '90': 32.12728045289953,
        '91': 32.12713189882413,
        '92': 32.12715272489784,
        '93': 32.12727891387703,
        '94': 32.12734738856692,
        '95': 32.12729746656183,
        '96': 32.12721796675029,
        '97': 32.12719878475923,
        '98': 32.12720584063224,
        '99': 32.12715156820729,
        '100': 32.127048052051826,
        '101': 32.127001516623345,
        '102': 32.12704014324626,
        '103': 32.12704429563839,
        '104': 32.126921704601834,
        '105': 32.126774384815015,
        '106': 32.12676528109266,
        '107': 32.126851602841434,
        '108': 32.126809644899765,
        '109': 32.12655665518441,
        '110': 32.12629662044941,
        '111': 32.12626976640186,
        '112': 32.126491158476554,
        '113': 32.12683691643294,
        '114': 32.12724424084449,
        '115': 32.125450743988296,
        '116': 32.12496976777251,
        '117': 32.12435736230202,
        '118': 32.123489417393024,
        '119': 32.122704727869575,
        '120': 32.122867276154864,
        '121': 32.12296374862333,
        '122': 32.122918412743076,
        '123': 32.12295286511102,
        '124': 32.12293607509292,
        '125': 32.124032688159154,
        '126': 32.124694541279645,
        '127': 32.12399916014931,
        '128': 32.12308411904949,
        '129': 32.12260870481857,
        '130': 32.12308452018338,
        '131': 32.12349089499719,
        '132': 32.124532971016514,
        '133': 32.12558479643664,
        '134': 32.12593766149598,
        '135': 32.12624370114706,
        '136': 32.1263767374185,
        '137': 32.127427441223396,
        '138': 32.12722380904986,
        '139': 32.12705640267952,
        '140': 32.12682792267605,
        '141': 32.12617852144133,
        '142': 32.12612192061575,
        '143': 32.12701810137765,
        '144': 32.12597339059356,
        '145': 32.12617647626475,
        '146': 32.12649966736166,
        '147': 32.12662004606952,
        '148': 32.12584518344719,
        '149': 32.12574402837977,
        '150': 32.12545070031161,
        '151': 32.123240022593016,
        '152': 32.12037335386822,
        '153': 32.127961020682875,
        '154': 32.12769829724367,
        '155': 32.1272058210781,
        '156': 32.12725765658477,
        '157': 32.12751748157859,
        '158': 32.12758921186131,
        '159': 32.12751034687298,
        '160': 32.127598644850266,
        '161': 32.12792625127205,
        '162': 32.128150460344614,
        '163': 32.12794222959401,
        '164': 32.127455545226404,
        '165': 32.12719708056201,
        '166': 32.1274321164251,
        '167': 32.127898412650566,
        '168': 32.128194833532746,
        '169': 32.128273913905424,
        '170': 32.12834424271945,
        '171': 32.128411181042914,
        '172': 32.12826259900027,
        '173': 32.12795906788401,
        '174': 32.12793761020639,
        '175': 32.128349468088935,
        '176': 32.12863086439504,
        '177': 32.12824445029423,
        '178': 32.127700700197536,
        '179': 32.1281233778065,
        '180': 32.12955655149678,
        '-180': 32.129556551496776,
        '-179': 32.130406387360644,
        '-178': 32.129258009265435,
        '-177': 32.126896158078935,
        '-176': 32.125636239332934,
        '-175': 32.12657100379876,
        '-174': 32.12832328149507,
        '-173': 32.128930921431916,
        '-172': 32.12821787458505,
        '-171': 32.1275443475805,
        '-170': 32.127683965776946,
        '-169': 32.127999963634885,
        '-168': 32.127774339752314,
        '-167': 32.12734249227692,
        '-166': 32.12738420513729,
        '-165': 32.127769948042136,
        '-164': 32.12784256865021,
        '-163': 32.12751084271852,
        '-162': 32.12731534016379,
        '-161': 32.12748114592338,
        '-160': 32.12760325254731,
        '-159': 32.12740621845322,
        '-158': 32.12720958561409,
        '-157': 32.127340992669716,
        '-156': 32.1275612203434,
        '-155': 32.12747906211385,
        '-154': 32.12721579354752,
        '-153': 32.127202280895524,
        '-152': 32.12748923190256,
        '-151': 32.12770920471869,
        '-150': 32.12766818127097,
        '-149': 32.12755474629629,
        '-148': 32.127530608568954,
        '-147': 32.127503905254954,
        '-146': 32.127424274955054,
        '-145': 32.12745950449919,
        '-144': 32.127689051292045,
        '-143': 32.127889001269835,
        '-142': 32.127843563235814,
        '-141': 32.127669542973145,
        '-140': 32.12760175848231,
        '-139': 32.12761787350384,
        '-138': 32.12752977124266,
        '-137': 32.12733776935371,
        '-136': 32.12722370586595,
        '-135': 32.12723499571719,
        '-134': 32.127232490573824,
        '-133': 32.1271596719944,
        '-132': 32.12712859016218,
        '-131': 32.1271936276006,
        '-130': 32.12725191708811,
        '-129': 32.12724343791763,
        '-128': 32.127262342214266,
        '-127': 32.12737066201533,
        '-126': 32.12746554172309,
        '-125': 32.12745191936556,
        '-124': 32.12740855579567,
        '-123': 32.12744426907234,
        '-122': 32.127502049736414,
        '-121': 32.12746346665721,
        '-120': 32.12736885331381,
        '-119': 32.12736241378234,
        '-118': 32.12745324237304,
        '-117': 32.12749939840534,
        '-116': 32.127436529704674,
        '-115': 32.1273696267444,
        '-114': 32.12739565893571,
        '-113': 32.127463062109804,
        '-112': 32.12747699509131,
        '-111': 32.12744980188476,
        '-110': 32.127460988848114,
        '-109': 32.12752087183393,
        '-108': 32.12755788437178,
        '-107': 32.127528368540425,
        '-106': 32.12747813745894,
        '-105': 32.12748178970247,
        '-104': 32.12754467177287,
        '-103': 32.12758327410441,
        '-102': 32.12752216228025,
        '-101': 32.1274155711616,
        '-100': 32.127418804973544,
        '-99': 32.1275792260661,
        '-98': 32.12771288576029,
        '-97': 32.12761810663474,
        '-96': 32.127396904939346,
        '-95': 32.12737752810215,
        '-94': 32.12764863668335,
        '-93': 32.127879781387506,
        '-92': 32.127781924887586,
        '-91': 32.127558445263446,
        '-90': 32.127599976655965,
        '-89': 32.127839055656835,
        '-88': 32.12782104410406,
        '-87': 32.12746235426895,
        '-86': 32.12726594888249,
        '-85': 32.12753286497101,
        '-84': 32.127805772483505,
        '-83': 32.12755990640421,
        '-82': 32.12711122425642,
        '-81': 32.127178130759354,
        '-80': 32.12768078818403,
        '-79': 32.127791875290164,
        '-78': 32.1273233913193,
        '-77': 32.12716098499891,
        '-76': 32.12778727987121,
        '-75': 32.12820924104818,
        '-74': 32.12735814731621,
        '-73': 32.126087773435344,
        '-72': 32.12644849443065,
        '-71': 32.11951392672013,
        '-70': 32.092019407660445,
        '-69': 32.0976702159364,
        '-68': 32.10208721436962,
        '-67': 32.11008769517783,
        '-66': 32.123689275154845,
        '-65': 32.124846327544546,
        '-64': 32.126671497095295,
        '-63': 32.12687755969643,
        '-62': 32.126993016599315,
        '-61': 32.12740368414074,
        '-60': 32.12751219729051,
        '-59': 32.12731485122828,
        '-58': 32.12678049615739,
        '-57': 32.126915759131265,
        '-56': 32.12668949283681,
        '-55': 32.12433842285244,
        '-54': 32.12257525110526,
        '-53': 32.123540957111736,
        '-52': 32.123503872924346,
        '-51': 32.11959699423161,
        '-50': 32.11718716731729,
        '-49': 32.12796904291399,
        '-48': 32.12805920393505,
        '-47': 32.1276383066844,
        '-46': 32.12697091266812,
        '-45': 32.1266393517576,
        '-44': 32.12677862167553,
        '-43': 32.12699771440946,
        '-42': 32.126993131455556,
        '-41': 32.126885661626126,
        '-40': 32.12690314705399,
        '-39': 32.127038497146984,
        '-38': 32.12716725996371,
        '-37': 32.12728759669568,
        '-36': 32.12745617091261,
        '-35': 32.12761274942581,
        '-34': 32.12765839129119,
        '-33': 32.12764798270725,
        '-32': 32.12772475067109,
        '-31': 32.127870106775205,
        '-30': 32.12788676544013,
        '-29': 32.12767923283883,
        '-28': 32.127414981709244,
        '-27': 32.12731739731728,
        '-26': 32.12739180097595,
        '-25': 32.12746770523696,
        '-24': 32.127457156067216,
        '-23': 32.12743893586727,
        '-22': 32.12748776171469,
        '-21': 32.12754972692267,
        '-20': 32.127543326117426,
        '-19': 32.127492749326656,
        '-18': 32.12748220102763,
        '-17': 32.1275331459062,
        '-16': 32.12760540439087,
        '-15': 32.127679826285465,
        '-14': 32.12775346879521,
        '-13': 32.12777736714568,
        '-12': 32.12769737291913,
        '-11': 32.12755914798823,
        '-10': 32.12747770688584,
        '-9': 32.1274829835494,
        '-8': 32.12748815499328,
        '-7': 32.12744370331173,
        '-6': 32.1274220068097,
        '-5': 32.12748581371081,
        '-4': 32.12756686522285,
        '-3': 32.127572703714435,
        '-2': 32.12754850401627,
        '-1': 32.12761869107187
    },
    '-30': {
        '0': 32.1303467820239,
        '1': 32.13051035878296,
        '2': 32.130605086851716,
        '3': 32.13055652926907,
        '4': 32.13034331318061,
        '5': 32.130065940610656,
        '6': 32.12994233268271,
        '7': 32.13010128830288,
        '8': 32.13037391507697,
        '9': 32.130437669106264,
        '10': 32.130220869525075,
        '11': 32.130018959542134,
        '12': 32.130108588214064,
        '13': 32.13038056856557,
        '14': 32.13051799867938,
        '15': 32.13044971082435,
        '16': 32.13041185797003,
        '17': 32.13056092685952,
        '18': 32.124040818249014,
        '19': 32.121349007539855,
        '20': 32.12073679262192,
        '21': 32.121906346296015,
        '22': 32.120344280086364,
        '23': 32.120466013989486,
        '24': 32.11924572169052,
        '25': 32.115954945508115,
        '26': 32.115426382577596,
        '27': 32.116764092538794,
        '28': 32.11368671299323,
        '29': 32.10852834316749,
        '30': 32.118543728825806,
        '31': 32.13068960269244,
        '32': 32.12985098179156,
        '33': 32.12967071149801,
        '34': 32.1301269389166,
        '35': 32.13058277378506,
        '36': 32.13045790216417,
        '37': 32.12986809391363,
        '38': 32.129437624095765,
        '39': 32.12951664390278,
        '40': 32.1298174808329,
        '41': 32.12990545839461,
        '42': 32.12981250937165,
        '43': 32.12992443718611,
        '44': 32.13036458457094,
        '45': 32.130788596234666,
        '46': 32.13084769053694,
        '47': 32.13060652038667,
        '48': 32.130352642416156,
        '49': 32.13018591054131,
        '50': 32.13002884570088,
        '51': 32.12993437376083,
        '52': 32.1300812176008,
        '53': 32.13042412312842,
        '54': 32.1306207254239,
        '55': 32.130463625947954,
        '56': 32.13020471134865,
        '57': 32.13024668411344,
        '58': 32.130610306329494,
        '59': 32.13092435940589,
        '60': 32.13091434638816,
        '61': 32.13068446410531,
        '62': 32.130481309305274,
        '63': 32.13038543899,
        '64': 32.130345831612836,
        '65': 32.13036051598564,
        '66': 32.13045842048793,
        '67': 32.13057580901823,
        '68': 32.13060595860695,
        '69': 32.13055376539256,
        '70': 32.130520749868886,
        '71': 32.13054366192413,
        '72': 32.13055995481233,
        '73': 32.130539181234916,
        '74': 32.13053481615976,
        '75': 32.1305713253115,
        '76': 32.130578788371864,
        '77': 32.13049655104572,
        '78': 32.130369081434026,
        '79': 32.130277921885266,
        '80': 32.1302337809398,
        '81': 32.13019683862864,
        '82': 32.13015752579266,
        '83': 32.130131515849385,
        '84': 32.13011132706559,
        '85': 32.13009427107764,
        '86': 32.13013005529446,
        '87': 32.1302540578882,
        '88': 32.130378135293476,
        '89': 32.130344876611346,
        '90': 32.130128805622135,
        '91': 32.12990817128075,
        '92': 32.12987055257794,
        '93': 32.129988263910235,
        '94': 32.13005979172859,
        '95': 32.12995943237855,
        '96': 32.129766653791265,
        '97': 32.12963024709818,
        '98': 32.129583816869555,
        '99': 32.129560311137595,
        '100': 32.12953784972191,
        '101': 32.129567301516595,
        '102': 32.129647395505295,
        '103': 32.12967624868544,
        '104': 32.12958883105501,
        '105': 32.129467416897576,
        '106': 32.12942434792579,
        '107': 32.12942221175106,
        '108': 32.129324810803084,
        '109': 32.12911449828912,
        '110': 32.12893197969235,
        '111': 32.128875159211226,
        '112': 32.12888905669197,
        '113': 32.12891856645006,
        '114': 32.12903873250437,
        '115': 32.129273805359624,
        '116': 32.126721804621276,
        '117': 32.12693159847063,
        '118': 32.12594157272947,
        '119': 32.12544194677846,
        '120': 32.12486848908767,
        '121': 32.125356266427715,
        '122': 32.125883917782694,
        '123': 32.12572123451454,
        '124': 32.12627758813403,
        '125': 32.12753384529213,
        '126': 32.12721205140686,
        '127': 32.12663433181761,
        '128': 32.12635548591616,
        '129': 32.12666732199771,
        '130': 32.1273929357896,
        '131': 32.128076324286276,
        '132': 32.12802355682103,
        '133': 32.12857043326955,
        '134': 32.12839434802825,
        '135': 32.12839668537587,
        '136': 32.128751259470086,
        '137': 32.128926902646626,
        '138': 32.12793848146842,
        '139': 32.12793923887916,
        '140': 32.12972757622208,
        '141': 32.12935497445617,
        '142': 32.1293825510115,
        '143': 32.12894944258582,
        '144': 32.1284236752955,
        '145': 32.128978035282515,
        '146': 32.12930835023257,
        '147': 32.12928371575629,
        '148': 32.12881326230549,
        '149': 32.128319213648105,
        '150': 32.12666278805938,
        '151': 32.124407485880255,
        '152': 32.121218702529895,
        '153': 32.12975080818625,
        '154': 32.12979236200498,
        '155': 32.12922639539125,
        '156': 32.12948844388451,
        '157': 32.13004141282738,
        '158': 32.130295333459685,
        '159': 32.13024962301399,
        '160': 32.13027695012513,
        '161': 32.130494216936405,
        '162': 32.1306052526652,
        '163': 32.13036192387474,
        '164': 32.12995555324045,
        '165': 32.12980296138627,
        '166': 32.130043568034,
        '167': 32.13042870141867,
        '168': 32.13069447266308,
        '169': 32.1308399435241,
        '170': 32.13095145667761,
        '171': 32.130958960074516,
        '172': 32.13079324817625,
        '173': 32.13066466181731,
        '174': 32.13084780465541,
        '175': 32.13115805746543,
        '176': 32.13104518579811,
        '177': 32.13046280019421,
        '178': 32.13026870956885,
        '179': 32.13119844567298,
        '180': 32.13254359366684,
        '-180': 32.132543593666824,
        '-179': 32.132585872849454,
        '-178': 32.13070152621921,
        '-177': 32.128480085656605,
        '-176': 32.128088226955846,
        '-175': 32.12973088607347,
        '-174': 32.131473217543274,
        '-173': 32.13164436097996,
        '-172': 32.13064694115702,
        '-171': 32.12998902550066,
        '-170': 32.13020181425801,
        '-169': 32.13051527844563,
        '-168': 32.13031734323791,
        '-167': 32.1299856387502,
        '-166': 32.130056804809904,
        '-165': 32.130327927285926,
        '-164': 32.13029264085696,
        '-163': 32.130007646686444,
        '-162': 32.12992754411772,
        '-161': 32.1300982600002,
        '-160': 32.13011592701778,
        '-159': 32.12986241452868,
        '-158': 32.12972141077395,
        '-157': 32.12992389001961,
        '-156': 32.13015455010541,
        '-155': 32.13006983782268,
        '-154': 32.129841067897196,
        '-153': 32.12984803481108,
        '-152': 32.13008553776121,
        '-151': 32.1302439318785,
        '-150': 32.13022824719521,
        '-149': 32.13021244545189,
        '-148': 32.13023295214862,
        '-147': 32.13011508742504,
        '-146': 32.129855158343815,
        '-145': 32.129729180781716,
        '-144': 32.12988716603339,
        '-143': 32.130117300163214,
        '-142': 32.13018090537208,
        '-141': 32.13014463430635,
        '-140': 32.130177762910236,
        '-139': 32.13022335025416,
        '-138': 32.13012730976094,
        '-137': 32.12995981602057,
        '-136': 32.129925605745946,
        '-135': 32.13001093270767,
        '-134': 32.12999673058763,
        '-133': 32.12982856403841,
        '-132': 32.12971030260757,
        '-131': 32.12976805716398,
        '-130': 32.129861753333664,
        '-129': 32.12984504525924,
        '-128': 32.129804812552074,
        '-127': 32.1298802440279,
        '-126': 32.13000286331275,
        '-125': 32.13001240131533,
        '-124': 32.12993245611727,
        '-123': 32.12992391359861,
        '-122': 32.13000683496786,
        '-121': 32.130034592724776,
        '-120': 32.12995572309005,
        '-119': 32.12990685674226,
        '-118': 32.12997910938417,
        '-117': 32.13005774070648,
        '-116': 32.13000844300129,
        '-115': 32.12990342841215,
        '-114': 32.12991334141081,
        '-113': 32.13003323548983,
        '-112': 32.13007896513333,
        '-111': 32.129967658504164,
        '-110': 32.12985406019063,
        '-109': 32.12990853795774,
        '-108': 32.130060148234826,
        '-107': 32.130089452872504,
        '-106': 32.12994950245783,
        '-105': 32.12984835541471,
        '-104': 32.12995210433948,
        '-103': 32.13012579524327,
        '-102': 32.130112467187985,
        '-101': 32.12992321584915,
        '-100': 32.12985519021165,
        '-99': 32.130058064901526,
        '-98': 32.130271219019995,
        '-97': 32.13017320805813,
        '-96': 32.12987507683335,
        '-95': 32.12979975689482,
        '-94': 32.13007216741575,
        '-93': 32.13032863952095,
        '-92': 32.13026311237886,
        '-91': 32.130077514105025,
        '-90': 32.13011842580746,
        '-89': 32.13027965154115,
        '-88': 32.130170813669366,
        '-87': 32.12981316768042,
        '-86': 32.12968751810565,
        '-85': 32.12996948515164,
        '-84': 32.130197968970165,
        '-83': 32.13001081752963,
        '-82': 32.129777426110444,
        '-81': 32.129997664034455,
        '-80': 32.13039233622162,
        '-79': 32.13029663172191,
        '-78': 32.129847772656525,
        '-77': 32.129900828681585,
        '-76': 32.13053026119688,
        '-75': 32.130571005574694,
        '-74': 32.12938671733039,
        '-73': 32.12835830234505,
        '-72': 32.129400475836306,
        '-71': 32.12920067682464,
        '-70': 32.09532901246674,
        '-69': 32.107601941865674,
        '-68': 32.117441789744596,
        '-67': 32.12414349847943,
        '-66': 32.12645078931659,
        '-65': 32.129115502918296,
        '-64': 32.12350416959774,
        '-63': 32.12953526082091,
        '-62': 32.12914415689727,
        '-61': 32.1297666594332,
        '-60': 32.13009876395276,
        '-59': 32.129688701571354,
        '-58': 32.12890646321668,
        '-57': 32.12915291962838,
        '-56': 32.12854579486782,
        '-55': 32.12908403984,
        '-54': 32.12958975975316,
        '-53': 32.13001957584147,
        '-52': 32.12989171103206,
        '-51': 32.129849744362325,
        '-50': 32.130125881664206,
        '-49': 32.13017832418181,
        '-48': 32.13014161230376,
        '-47': 32.129824395058165,
        '-46': 32.12941949786255,
        '-45': 32.129290334819686,
        '-44': 32.12947160012707,
        '-43': 32.12964912272088,
        '-42': 32.129617657302305,
        '-41': 32.12950460205473,
        '-40': 32.1295022676256,
        '-39': 32.129618265223336,
        '-38': 32.129792507263076,
        '-37': 32.13005412646011,
        '-36': 32.130395346144944,
        '-35': 32.13062998753755,
        '-34': 32.130581103415,
        '-33': 32.13035474221037,
        '-32': 32.1302370372633,
        '-31': 32.13031967553261,
        '-30': 32.130401060686786,
        '-29': 32.130300337858294,
        '-28': 32.1301065231863,
        '-27': 32.13001921093879,
        '-26': 32.13006650215867,
        '-25': 32.1301188550025,
        '-24': 32.13011418079366,
        '-23': 32.130115033510116,
        '-22': 32.13015238149402,
        '-21': 32.13015910362095,
        '-20': 32.13010007690065,
        '-19': 32.13005189180154,
        '-18': 32.13008484928033,
        '-17': 32.13015273394692,
        '-16': 32.13017931172795,
        '-15': 32.130183643074595,
        '-14': 32.13022240774676,
        '-13': 32.13025738515228,
        '-12': 32.13019722052346,
        '-11': 32.130058299325626,
        '-10': 32.129966314517745,
        '-9': 32.12997752881328,
        '-8': 32.130006306703606,
        '-7': 32.12997466083872,
        '-6': 32.12993520708446,
        '-5': 32.12997356650265,
        '-4': 32.13006576146327,
        '-3': 32.13012501617904,
        '-2': 32.130145661199464,
        '-1': 32.130206643196956
    },
    '-31': {
        '0': 32.13282143335093,
        '1': 32.13294076821789,
        '2': 32.133052376184004,
        '3': 32.133052438122164,
        '4': 32.13289372538569,
        '5': 32.13267703300864,
        '6': 32.13260902843041,
        '7': 32.132778859979,
        '8': 32.13300056713108,
        '9': 32.13299750220836,
        '10': 32.132759700857534,
        '11': 32.132586951768396,
        '12': 32.13271199417967,
        '13': 32.133003446793346,
        '14': 32.1331581742021,
        '15': 32.13309764593389,
        '16': 32.13301696993052,
        '17': 32.133079234621384,
        '18': 32.12904701933322,
        '19': 32.13001696839566,
        '20': 32.12297188945723,
        '21': 32.122356147310725,
        '22': 32.120688591705004,
        '23': 32.120275374719306,
        '24': 32.12021545189258,
        '25': 32.118629964512806,
        '26': 32.11959632892869,
        '27': 32.11910894962024,
        '28': 32.110522256276276,
        '29': 32.12385822109512,
        '30': 32.13003591680321,
        '31': 32.13263573627855,
        '32': 32.13201199605618,
        '33': 32.13216719190013,
        '34': 32.132886385254636,
        '35': 32.133397274035204,
        '36': 32.13313891383111,
        '37': 32.13238214122226,
        '38': 32.13189507749791,
        '39': 32.1320344829128,
        '40': 32.13242635872602,
        '41': 32.132603135500325,
        '42': 32.132649334373006,
        '43': 32.132952407453814,
        '44': 32.13349161106159,
        '45': 32.13376105035008,
        '46': 32.13345255176906,
        '47': 32.132890797314595,
        '48': 32.13259838703766,
        '49': 32.13264941202704,
        '50': 32.13271945370685,
        '51': 32.13265364466435,
        '52': 32.132653710157,
        '53': 32.13286906523918,
        '54': 32.133112392211906,
        '55': 32.133157653820184,
        '56': 32.13311427295959,
        '57': 32.133258837357914,
        '58': 32.133580530558426,
        '59': 32.13374714365444,
        '60': 32.13354629514932,
        '61': 32.13315588469463,
        '62': 32.132895643086925,
        '63': 32.13286512342062,
        '64': 32.13294052798985,
        '65': 32.133014257840934,
        '66': 32.13308634379173,
        '67': 32.13316133225648,
        '68': 32.133196344192896,
        '69': 32.13316989883331,
        '70': 32.13311342480792,
        '71': 32.133056336623014,
        '72': 32.13300746887449,
        '73': 32.13299903347735,
        '74': 32.133072315356046,
        '75': 32.13319341238746,
        '76': 32.13325206507054,
        '77': 32.13318638904314,
        '78': 32.13305871251157,
        '79': 32.13296662443502,
        '80': 32.13292426739702,
        '81': 32.13287903494891,
        '82': 32.13280642518438,
        '83': 32.13273165450298,
        '84': 32.13268456841269,
        '85': 32.132692360896485,
        '86': 32.13279241326829,
        '87': 32.13297327120018,
        '88': 32.13310398019666,
        '89': 32.13301684488777,
        '90': 32.13271112587156,
        '91': 32.13241035140848,
        '92': 32.13234274559342,
        '93': 32.13248930178379,
        '94': 32.1326192779566,
        '95': 32.13256782036066,
        '96': 32.13240083768198,
        '97': 32.132282474221746,
        '98': 32.132257966319266,
        '99': 32.13223859692661,
        '100': 32.13216206549591,
        '101': 32.132075731914846,
        '102': 32.13204871703886,
        '103': 32.13207609509583,
        '104': 32.13210728039195,
        '105': 32.13211271870117,
        '106': 32.13207452534587,
        '107': 32.13196143654113,
        '108': 32.131780794570105,
        '109': 32.13161959398076,
        '110': 32.13154903400437,
        '111': 32.131506452565944,
        '112': 32.13137695977877,
        '113': 32.13120419331526,
        '114': 32.1311991176751,
        '115': 32.131476794548725,
        '116': 32.13007559583297,
        '117': 32.12876767450962,
        '118': 32.129047779688904,
        '119': 32.128819799111845,
        '120': 32.128446397268554,
        '121': 32.12770722947497,
        '122': 32.12842248069297,
        '123': 32.128136178484546,
        '124': 32.1296808926461,
        '125': 32.13042852244858,
        '126': 32.130710323037555,
        '127': 32.13026452081231,
        '128': 32.13015569643133,
        '129': 32.13029839598888,
        '130': 32.1309498136807,
        '131': 32.13176409698752,
        '132': 32.13201318336826,
        '133': 32.131100976774476,
        '134': 32.13075132557618,
        '135': 32.13066057032458,
        '136': 32.13077487136782,
        '137': 32.131220291217936,
        '138': 32.13245753443071,
        '139': 32.12955633937578,
        '140': 32.13289951179749,
        '141': 32.13250682804912,
        '142': 32.13166461103752,
        '143': 32.13120033238294,
        '144': 32.130702529845195,
        '145': 32.13109927265416,
        '146': 32.13070114236134,
        '147': 32.13151815363001,
        '148': 32.131155862413806,
        '149': 32.129628071047364,
        '150': 32.129963595947,
        '151': 32.12874135623626,
        '152': 32.12946979862207,
        '153': 32.13337486184809,
        '154': 32.13221933411258,
        '155': 32.13176419745837,
        '156': 32.132158694483486,
        '157': 32.13274101081611,
        '158': 32.132930165913,
        '159': 32.13281171734722,
        '160': 32.13279410148142,
        '161': 32.132962116481494,
        '162': 32.13301509606186,
        '163': 32.13276691986536,
        '164': 32.132445502989505,
        '165': 32.13239963408649,
        '166': 32.13266506051988,
        '167': 32.13297423873927,
        '168': 32.13312325928688,
        '169': 32.13315324323456,
        '170': 32.13317163975527,
        '171': 32.13318931100784,
        '172': 32.13322110743209,
        '173': 32.13336073232864,
        '174': 32.13356796422747,
        '175': 32.13353367004426,
        '176': 32.13308688591694,
        '177': 32.13272082131994,
        '178': 32.1332509188506,
        '179': 32.13464221190239,
        '180': 32.135534704112686,
        '-180': 32.135534704112686,
        '-179': 32.13455355701768,
        '-178': 32.13212800709507,
        '-177': 32.130403500943494,
        '-176': 32.130963545141306,
        '-175': 32.13301833778546,
        '-174': 32.13437026506991,
        '-173': 32.13396119763316,
        '-172': 32.1328095619342,
        '-171': 32.13238647535575,
        '-170': 32.13281317880946,
        '-169': 32.13314462408351,
        '-168': 32.1329118901879,
        '-167': 32.132597829428946,
        '-166': 32.132663225268594,
        '-165': 32.13285909029766,
        '-164': 32.13278211810931,
        '-163': 32.13256739541861,
        '-162': 32.13258371435622,
        '-161': 32.13276101360817,
        '-160': 32.13271806206293,
        '-159': 32.132430025826565,
        '-158': 32.132286435422884,
        '-157': 32.13246274184051,
        '-156': 32.13266474162593,
        '-155': 32.13263081243001,
        '-154': 32.13252124581144,
        '-153': 32.13258857554868,
        '-152': 32.132752183492435,
        '-151': 32.13278668686796,
        '-150': 32.1327302384232,
        '-149': 32.13277862169511,
        '-148': 32.13288206556098,
        '-147': 32.13279071929019,
        '-146': 32.1324925643408,
        '-145': 32.13228483161947,
        '-144': 32.13234088036092,
        '-143': 32.132488083742494,
        '-142': 32.13253357526512,
        '-141': 32.13255136386434,
        '-140': 32.13266673852726,
        '-139': 32.13277478975734,
        '-138': 32.13271613367879,
        '-137': 32.132586937201175,
        '-136': 32.13260158101361,
        '-135': 32.13271884374732,
        '-134': 32.132688712417504,
        '-133': 32.1324723421592,
        '-132': 32.13233423656677,
        '-131': 32.13243723185216,
        '-130': 32.132598253241575,
        '-129': 32.13259374653441,
        '-128': 32.13250075908228,
        '-127': 32.13252969984105,
        '-126': 32.13266050413792,
        '-125': 32.13269056022188,
        '-124': 32.13258648619471,
        '-123': 32.13253657831705,
        '-122': 32.13262851129413,
        '-121': 32.132703459450866,
        '-120': 32.13262506441049,
        '-119': 32.13250686864153,
        '-118': 32.13252343683724,
        '-117': 32.132625382078075,
        '-116': 32.132629382113336,
        '-115': 32.13253030216027,
        '-114': 32.132516897642134,
        '-113': 32.132655652254435,
        '-112': 32.132753850776304,
        '-111': 32.1326427564111,
        '-110': 32.13245368679594,
        '-109': 32.1324444897808,
        '-108': 32.1326156014038,
        '-107': 32.132696583164154,
        '-106': 32.13254484053762,
        '-105': 32.1323697753941,
        '-104': 32.13243360249801,
        '-103': 32.1326591008597,
        '-102': 32.13273337579123,
        '-101': 32.13256618817202,
        '-100': 32.13241876845588,
        '-99': 32.13250382422664,
        '-98': 32.1326640882632,
        '-97': 32.132626775215414,
        '-96': 32.132441383452516,
        '-95': 32.13241140966018,
        '-94': 32.13261975448935,
        '-93': 32.13279837615867,
        '-92': 32.13274985684023,
        '-91': 32.13263666538622,
        '-90': 32.13267001054191,
        '-89': 32.13273272532905,
        '-88': 32.132594630474784,
        '-87': 32.132363052846884,
        '-86': 32.13235672320434,
        '-85': 32.13255916434598,
        '-84': 32.132608555343545,
        '-83': 32.13242944412141,
        '-82': 32.13242464971861,
        '-81': 32.1327684693959,
        '-80': 32.13296039286757,
        '-79': 32.1325923856973,
        '-78': 32.132214852291824,
        '-77': 32.13265430681346,
        '-76': 32.13352429493267,
        '-75': 32.133401871088424,
        '-74': 32.13194013356127,
        '-73': 32.13091086755187,
        '-72': 32.1321444676743,
        '-71': 32.12231808859711,
        '-70': 32.101708849680655,
        '-69': 32.11306338016099,
        '-68': 32.12578444733118,
        '-67': 32.12603765626987,
        '-66': 32.12929737500795,
        '-65': 32.12696967548495,
        '-64': 32.12938352025671,
        '-63': 32.13172480256462,
        '-62': 32.13122532405088,
        '-61': 32.13211574029293,
        '-60': 32.13274436780523,
        '-59': 32.131973481207275,
        '-58': 32.13200901347415,
        '-57': 32.131484081485326,
        '-56': 32.130724533932394,
        '-55': 32.13198372221853,
        '-54': 32.12996799102295,
        '-53': 32.13058239926154,
        '-52': 32.13270931289145,
        '-51': 32.13288592822323,
        '-50': 32.13268785099756,
        '-49': 32.13241582395888,
        '-48': 32.1321703837906,
        '-47': 32.13198559832558,
        '-46': 32.131918728609,
        '-45': 32.13202398930198,
        '-44': 32.13221348108938,
        '-43': 32.13230649634273,
        '-42': 32.132253061984905,
        '-41': 32.13217915479049,
        '-40': 32.13218930703462,
        '-39': 32.132270273108574,
        '-38': 32.13242917499548,
        '-37': 32.13275039245044,
        '-36': 32.133196662115274,
        '-35': 32.1334938407675,
        '-34': 32.13339741657445,
        '-33': 32.1330253188248,
        '-32': 32.13275357770597,
        '-31': 32.13277685951147,
        '-30': 32.13292485827725,
        '-29': 32.132947761138354,
        '-28': 32.132829607850525,
        '-27': 32.132727404193034,
        '-26': 32.13271943065193,
        '-25': 32.13275149448427,
        '-24': 32.13277735103311,
        '-23': 32.13281095630061,
        '-22': 32.13284156620127,
        '-21': 32.13281943537334,
        '-20': 32.13275565703082,
        '-19': 32.13273667260095,
        '-18': 32.132794514231705,
        '-17': 32.132847901947336,
        '-16': 32.132829823756424,
        '-15': 32.13279484907879,
        '-14': 32.13281888598431,
        '-13': 32.1328548651255,
        '-12': 32.13279506039735,
        '-11': 32.13264956841967,
        '-10': 32.13254731576639,
        '-9': 32.13254945776388,
        '-8': 32.132570804236394,
        '-7': 32.13252906310638,
        '-6': 32.132477879273736,
        '-5': 32.13251849297863,
        '-4': 32.13264144675337,
        '-3': 32.13274160023889,
        '-2': 32.13276487679363,
        '-1': 32.132765682483914
    },
    '-32': {
        '0': 32.135326350017486,
        '1': 32.13541761777533,
        '2': 32.13553503853904,
        '3': 32.135573416464304,
        '4': 32.135482598589476,
        '5': 32.13533739937819,
        '6': 32.13528984440157,
        '7': 32.13539939380599,
        '8': 32.13553340649143,
        '9': 32.13551234577827,
        '10': 32.13535004184572,
        '11': 32.135255543113836,
        '12': 32.135365920287036,
        '13': 32.13557205523655,
        '14': 32.13567631142428,
        '15': 32.13564374222898,
        '16': 32.135601408426055,
        '17': 32.135645905264724,
        '18': 32.13577472827471,
        '19': 32.13331001811995,
        '20': 32.12119151830872,
        '21': 32.12402590853489,
        '22': 32.12207341178729,
        '23': 32.12578825153194,
        '24': 32.12056251755636,
        '25': 32.12173450294651,
        '26': 32.124902770883324,
        '27': 32.12541914124766,
        '28': 32.126769076495265,
        '29': 32.13352051516847,
        '30': 32.135260637893396,
        '31': 32.13459881278983,
        '32': 32.13444531638905,
        '33': 32.1349826992326,
        '34': 32.13583763402478,
        '35': 32.13627151017333,
        '36': 32.13588523264967,
        '37': 32.135073857866615,
        '38': 32.13459450810311,
        '39': 32.13472262896232,
        '40': 32.13507100652578,
        '41': 32.135263081132834,
        '42': 32.13545926358613,
        '43': 32.13597709101183,
        '44': 32.13661302854412,
        '45': 32.136749546068195,
        '46': 32.13617257000194,
        '47': 32.13543157416451,
        '48': 32.13518702664248,
        '49': 32.13542815369542,
        '50': 32.13561049172317,
        '51': 32.13544682448756,
        '52': 32.13522766395107,
        '53': 32.13532187923494,
        '54': 32.135669195085086,
        '55': 32.1359501695188,
        '56': 32.13605629213357,
        '57': 32.136134004932806,
        '58': 32.13623637220067,
        '59': 32.13620103090062,
        '60': 32.13593473507483,
        '61': 32.13562050954705,
        '62': 32.13551203418618,
        '63': 32.13562640024753,
        '64': 32.13576690199456,
        '65': 32.13579420859747,
        '66': 32.1357516129389,
        '67': 32.13573629083001,
        '68': 32.13576084277634,
        '69': 32.13577313103628,
        '70': 32.135739451812064,
        '71': 32.13566976771547,
        '72': 32.13560494021246,
        '73': 32.13560514176263,
        '74': 32.13570839748795,
        '75': 32.13586922966916,
        '76': 32.13597226200651,
        '77': 32.13594227636401,
        '78': 32.13582136196913,
        '79': 32.135710893291325,
        '80': 32.13565881893827,
        '81': 32.13563267631986,
        '82': 32.13558259826789,
        '83': 32.13549587297759,
        '84': 32.13540330332219,
        '85': 32.13536580275372,
        '86': 32.13543666491678,
        '87': 32.135586206328206,
        '88': 32.13566923517066,
        '89': 32.13553648407509,
        '90': 32.13521392960475,
        '91': 32.13492322668601,
        '92': 32.134868379586216,
        '93': 32.13501972083498,
        '94': 32.13516024953247,
        '95': 32.13513881241743,
        '96': 32.13501826710399,
        '97': 32.13495553102504,
        '98': 32.13499178686815,
        '99': 32.13501336064359,
        '100': 32.1348990172883,
        '101': 32.13465495379851,
        '102': 32.13440247688292,
        '103': 32.1342694756529,
        '104': 32.134296556573425,
        '105': 32.134410755777196,
        '106': 32.13448160778979,
        '107': 32.134442704198214,
        '108': 32.13437088767342,
        '109': 32.13439356669399,
        '110': 32.13449122751904,
        '111': 32.13446878110014,
        '112': 32.13421028274165,
        '113': 32.13391424052191,
        '114': 32.133939165326055,
        '115': 32.13438698094914,
        '116': 32.13464534123495,
        '117': 32.13300374695897,
        '118': 32.13246012475624,
        '119': 32.131452759974586,
        '120': 32.13091948169163,
        '121': 32.130891933324634,
        '122': 32.13153590384889,
        '123': 32.13078220957271,
        '124': 32.13323205023843,
        '125': 32.13398817667402,
        '126': 32.134536674572765,
        '127': 32.134974762064395,
        '128': 32.13468558657515,
        '129': 32.134646649292414,
        '130': 32.134757449808575,
        '131': 32.134834349971555,
        '132': 32.134669979775424,
        '133': 32.13435460129929,
        '134': 32.13358028851243,
        '135': 32.132605578500225,
        '136': 32.133586751151284,
        '137': 32.13410625807023,
        '138': 32.134323563778054,
        '139': 32.13261959947172,
        '140': 32.13380740769456,
        '141': 32.13402850160701,
        '142': 32.13416824324326,
        '143': 32.134268061755435,
        '144': 32.133963053657105,
        '145': 32.13366187009915,
        '146': 32.13295957195103,
        '147': 32.13375044790073,
        '148': 32.13350276622278,
        '149': 32.131493936411545,
        '150': 32.13210379980645,
        '151': 32.1342560538391,
        '152': 32.13493183131202,
        '153': 32.13559390582632,
        '154': 32.13475836698911,
        '155': 32.13467704931359,
        '156': 32.1351565477159,
        '157': 32.135522894750025,
        '158': 32.13546643418743,
        '159': 32.1353033905246,
        '160': 32.135418904021236,
        '161': 32.13571630562653,
        '162': 32.13578355726537,
        '163': 32.135475135769944,
        '164': 32.13510680889584,
        '165': 32.13505394915853,
        '166': 32.13532431722629,
        '167': 32.135618390119554,
        '168': 32.13570480385843,
        '169': 32.1356141582663,
        '170': 32.13551897791555,
        '171': 32.13556199627579,
        '172': 32.13578741320205,
        '173': 32.13607994274272,
        '174': 32.13614432379691,
        '175': 32.135766346142454,
        '176': 32.13525697047765,
        '177': 32.13541600680812,
        '178': 32.136653202938625,
        '179': 32.13812376745227,
        '180': 32.13822042621231,
        '-180': 32.13822042621231,
        '-179': 32.13632278621931,
        '-178': 32.13383896100383,
        '-177': 32.132975373528815,
        '-176': 32.13436931002818,
        '-175': 32.136382308577275,
        '-174': 32.1369951537485,
        '-173': 32.136012848480306,
        '-172': 32.134942010627235,
        '-171': 32.134939070299,
        '-170': 32.13557803605561,
        '-169': 32.135797781684516,
        '-168': 32.13540287678799,
        '-167': 32.13507129846594,
        '-166': 32.13520121757567,
        '-165': 32.13543878415066,
        '-164': 32.13539664842246,
        '-163': 32.13525179765582,
        '-162': 32.13533759720883,
        '-161': 32.135539155186315,
        '-160': 32.135498248577875,
        '-159': 32.135219523509384,
        '-158': 32.13506088546251,
        '-157': 32.13517259464369,
        '-156': 32.135321858273926,
        '-155': 32.13532205166911,
        '-154': 32.13529586054123,
        '-153': 32.13537724051831,
        '-152': 32.13544303902656,
        '-151': 32.13535470258311,
        '-150': 32.135251102643565,
        '-149': 32.13533746731162,
        '-148': 32.13551404950022,
        '-147': 32.13549755838208,
        '-146': 32.13525988681148,
        '-145': 32.135065327761296,
        '-144': 32.13505830759542,
        '-143': 32.13508994433475,
        '-142': 32.13503537952115,
        '-141': 32.13502070681343,
        '-140': 32.13517123648907,
        '-139': 32.13534428341954,
        '-138': 32.13533516316049,
        '-137': 32.135211748620016,
        '-136': 32.135194981586196,
        '-135': 32.13528008409929,
        '-134': 32.135248950385495,
        '-133': 32.1350638154307,
        '-132': 32.13497231889002,
        '-131': 32.13512451122524,
        '-130': 32.13532372326446,
        '-129': 32.135326887326286,
        '-128': 32.135212464022,
        '-127': 32.13522885406983,
        '-126': 32.13538530619817,
        '-125': 32.13544981046607,
        '-124': 32.13533677074286,
        '-123': 32.13524088772265,
        '-122': 32.13531036867445,
        '-121': 32.1354056154525,
        '-120': 32.13533331891208,
        '-119': 32.135165985849845,
        '-118': 32.13512883929983,
        '-117': 32.135243026307805,
        '-116': 32.13529995394129,
        '-115': 32.1352012563885,
        '-114': 32.13511598316219,
        '-113': 32.13520501709264,
        '-112': 32.135352613041995,
        '-111': 32.13533424984173,
        '-110': 32.135164422701614,
        '-109': 32.13508361049581,
        '-108': 32.13519865159397,
        '-107': 32.13532358932517,
        '-106': 32.135261745614315,
        '-105': 32.13509970170515,
        '-104': 32.1350726924084,
        '-103': 32.13521367064502,
        '-102': 32.13531386919725,
        '-101': 32.135235242968456,
        '-100': 32.135091262208675,
        '-99': 32.135047021449076,
        '-98': 32.13508282055826,
        '-97': 32.13508177536136,
        '-96': 32.13505396669826,
        '-95': 32.135107590693785,
        '-94': 32.13523404635722,
        '-93': 32.13529879112169,
        '-92': 32.13526333458776,
        '-91': 32.13524184112299,
        '-90': 32.135278511248934,
        '-89': 32.13525167699799,
        '-88': 32.13511605519454,
        '-87': 32.13505499468577,
        '-86': 32.13519145387346,
        '-85': 32.135306819850534,
        '-84': 32.1351474405008,
        '-83': 32.13491792216093,
        '-82': 32.13505394292818,
        '-81': 32.1354554171022,
        '-80': 32.135460140044195,
        '-79': 32.13488342385653,
        '-78': 32.13457796448711,
        '-77': 32.13531316779137,
        '-76': 32.13637120704457,
        '-75': 32.136192665150276,
        '-74': 32.134628998214495,
        '-73': 32.133613040710934,
        '-72': 32.1348230866193,
        '-71': 32.12558375460297,
        '-70': 32.09196400149616,
        '-69': 32.11537741652369,
        '-68': 32.12919964747454,
        '-67': 32.12904699630588,
        '-66': 32.13215427750004,
        '-65': 32.12636909364568,
        '-64': 32.13292120053214,
        '-63': 32.133697836744744,
        '-62': 32.13375908685091,
        '-61': 32.135038465666355,
        '-60': 32.13472039184943,
        '-59': 32.13508839418247,
        '-58': 32.13527857127636,
        '-57': 32.134678046510054,
        '-56': 32.134917479238275,
        '-55': 32.13490456055182,
        '-54': 32.13488220969442,
        '-53': 32.13438083132824,
        '-52': 32.13572933719477,
        '-51': 32.135880675701,
        '-50': 32.13557774716419,
        '-49': 32.135062485216686,
        '-48': 32.134684986844064,
        '-47': 32.13459870709574,
        '-46': 32.13470823457474,
        '-45': 32.134848307135286,
        '-44': 32.134910159399325,
        '-43': 32.134876938965526,
        '-42': 32.13481832440564,
        '-41': 32.13481385009674,
        '-40': 32.134855503619285,
        '-39': 32.134886436740665,
        '-38': 32.134945641270185,
        '-37': 32.135160867583856,
        '-36': 32.135529161822504,
        '-35': 32.13581085901092,
        '-34': 32.13577235620111,
        '-33': 32.13549131174537,
        '-32': 32.13528562746167,
        '-31': 32.135335976719084,
        '-30': 32.135503122069466,
        '-29': 32.13555446036533,
        '-28': 32.135452604305236,
        '-27': 32.13533895571772,
        '-26': 32.13531736248948,
        '-25': 32.135365575367,
        '-24': 32.135427046601926,
        '-23': 32.13548091606812,
        '-22': 32.135512869264026,
        '-21': 32.13550347414024,
        '-20': 32.135473139681494,
        '-19': 32.13547552706649,
        '-18': 32.13551874874512,
        '-17': 32.135549048497765,
        '-16': 32.13554063730117,
        '-15': 32.135539313945415,
        '-14': 32.13557091032983,
        '-13': 32.1355666680676,
        '-12': 32.13545311369173,
        '-11': 32.135279666491506,
        '-10': 32.135170370965504,
        '-9': 32.13515784595511,
        '-8': 32.1351543323307,
        '-7': 32.13510468248507,
        '-6': 32.13507986150732,
        '-5': 32.135165536184154,
        '-4': 32.135319919511296,
        '-3': 32.13541460075482,
        '-2': 32.135393070423994,
        '-1': 32.13532812712061
    },
    '-33': {
        '0': 32.13797772957736,
        '1': 32.13806948848591,
        '2': 32.13815825842685,
        '3': 32.138171194424906,
        '4': 32.138092082854065,
        '5': 32.13796931206096,
        '6': 32.1378849607165,
        '7': 32.1378841592351,
        '8': 32.13793059179954,
        '9': 32.137959212581656,
        '10': 32.13796441077542,
        '11': 32.13798950300363,
        '12': 32.13803144128917,
        '13': 32.13802608269935,
        '14': 32.13795917839841,
        '15': 32.13792606661461,
        '16': 32.138021821142864,
        '17': 32.138218788390915,
        '18': 32.13839228143444,
        '19': 32.13700762262591,
        '20': 32.13268668880671,
        '21': 32.130032985240284,
        '22': 32.13375724544611,
        '23': 32.12936028754591,
        '24': 32.13031279845763,
        '25': 32.13428894082327,
        '26': 32.13264298744692,
        '27': 32.134685253425694,
        '28': 32.138189975126245,
        '29': 32.13779449037681,
        '30': 32.13736125457703,
        '31': 32.13719178123442,
        '32': 32.13750314214702,
        '33': 32.13823711395543,
        '34': 32.13896315278458,
        '35': 32.13913887229623,
        '36': 32.138631650307914,
        '37': 32.13791079271748,
        '38': 32.13756243047554,
        '39': 32.13766126557153,
        '40': 32.13782012458459,
        '41': 32.13784820450857,
        '42': 32.13805896270731,
        '43': 32.1387371851485,
        '44': 32.13952857160785,
        '45': 32.13971841013138,
        '46': 32.13912049870491,
        '47': 32.138363682870335,
        '48': 32.13813899094354,
        '49': 32.1383972979876,
        '50': 32.13854262573041,
        '51': 32.138287212966816,
        '52': 32.137988034906286,
        '53': 32.138095181131085,
        '54': 32.13855565477922,
        '55': 32.13894471416931,
        '56': 32.13901398564373,
        '57': 32.138874878821504,
        '58': 32.13870308653598,
        '59': 32.13851340922182,
        '60': 32.138291228114014,
        '61': 32.13815328496227,
        '62': 32.138225068451135,
        '63': 32.138435211671926,
        '64': 32.13857094154675,
        '65': 32.138527959421594,
        '66': 32.1384061281027,
        '67': 32.13835125144751,
        '68': 32.13838919849522,
        '69': 32.13844571800701,
        '70': 32.138460511046596,
        '71': 32.138431472945264,
        '72': 32.138385789069275,
        '73': 32.13835903223881,
        '74': 32.13838646715597,
        '75': 32.13847009955057,
        '76': 32.13855430932783,
        '77': 32.138567085062625,
        '78': 32.13849389051664,
        '79': 32.13839230369034,
        '80': 32.13832865613571,
        '81': 32.13831405566759,
        '82': 32.13830626023092,
        '83': 32.138263226376324,
        '84': 32.138191178753786,
        '85': 32.13814431938258,
        '86': 32.13816947538287,
        '87': 32.13823844902848,
        '88': 32.138247010365774,
        '89': 32.13811196619034,
        '90': 32.13787656131021,
        '91': 32.13769016002478,
        '92': 32.13765930896158,
        '93': 32.13773301542157,
        '94': 32.137760774861036,
        '95': 32.13765961782855,
        '96': 32.137499895885846,
        '97': 32.137422180973836,
        '98': 32.13748665688497,
        '99': 32.13760821451713,
        '100': 32.13762543135777,
        '101': 32.137436126446914,
        '102': 32.13709418986119,
        '103': 32.13678531992367,
        '104': 32.13667807024782,
        '105': 32.13677343822853,
        '106': 32.136924316941375,
        '107': 32.13702900384857,
        '108': 32.13715968743863,
        '109': 32.137421426746144,
        '110': 32.13770421159869,
        '111': 32.137715129423,
        '112': 32.137351288712445,
        '113': 32.13695184072107,
        '114': 32.137013276534844,
        '115': 32.13763000478496,
        '116': 32.135450236770716,
        '117': 32.13551204980846,
        '118': 32.13500606529648,
        '119': 32.1347580723396,
        '120': 32.13390032127388,
        '121': 32.13514060404941,
        '122': 32.1351695879437,
        '123': 32.135378060752345,
        '124': 32.13637802300194,
        '125': 32.138180071757304,
        '126': 32.138213416227586,
        '127': 32.13789046144206,
        '128': 32.137464283397044,
        '129': 32.13723197320107,
        '130': 32.13722244812749,
        '131': 32.137254073335825,
        '132': 32.13722936442567,
        '133': 32.13725576685794,
        '134': 32.13746994121784,
        '135': 32.13700499923019,
        '136': 32.13629693774947,
        '137': 32.1360322925973,
        '138': 32.138254764098946,
        '139': 32.132889695268666,
        '140': 32.13671199750662,
        '141': 32.13724645936671,
        '142': 32.13704695919766,
        '143': 32.13672402375527,
        '144': 32.136696753472144,
        '145': 32.13686124794711,
        '146': 32.13645956073123,
        '147': 32.136311017215974,
        '148': 32.13544576115508,
        '149': 32.13268527370999,
        '150': 32.133540169195484,
        '151': 32.13665160859946,
        '152': 32.13842809436415,
        '153': 32.13752508694215,
        '154': 32.13718527564268,
        '155': 32.1375266904242,
        '156': 32.13800833862046,
        '157': 32.13808994713442,
        '158': 32.1378417466206,
        '159': 32.13777174929291,
        '160': 32.13813596322372,
        '161': 32.1386157625367,
        '162': 32.13872415700418,
        '163': 32.13838162607594,
        '164': 32.137966255404464,
        '165': 32.13785943817976,
        '166': 32.138078214751644,
        '167': 32.138356745708734,
        '168': 32.13846450024942,
        '169': 32.138388535781225,
        '170': 32.138292688725556,
        '171': 32.13837244079372,
        '172': 32.138675506174124,
        '173': 32.13897129473167,
        '174': 32.138890563308344,
        '175': 32.138392896960205,
        '176': 32.13807601209894,
        '177': 32.13869835439488,
        '178': 32.14015090575662,
        '179': 32.14112304698812,
        '180': 32.14027172271458,
        '-180': 32.140271722714594,
        '-179': 32.13786569850268,
        '-178': 32.135882172082795,
        '-177': 32.136071809610605,
        '-176': 32.13804832408197,
        '-175': 32.13970998171769,
        '-174': 32.13957992574433,
        '-173': 32.138285783302045,
        '-172': 32.13750365871385,
        '-171': 32.137874649839695,
        '-170': 32.13851812291145,
        '-169': 32.1384689990683,
        '-168': 32.137902214895895,
        '-167': 32.13763213443506,
        '-166': 32.13789079024756,
        '-165': 32.1381638248095,
        '-164': 32.13808724698815,
        '-163': 32.1379288115,
        '-162': 32.13804220401063,
        '-161': 32.13828808679835,
        '-160': 32.138302703724165,
        '-159': 32.138080915859454,
        '-158': 32.13793977149735,
        '-157': 32.138010770655555,
        '-156': 32.13810639172765,
        '-155': 32.13808929213706,
        '-154': 32.13806377600184,
        '-153': 32.13811468356545,
        '-152': 32.1381238790367,
        '-151': 32.13800570459168,
        '-150': 32.13791853689603,
        '-149': 32.13803265876355,
        '-148': 32.13822307046559,
        '-147': 32.13822637691491,
        '-146': 32.138033320944096,
        '-145': 32.13788298519664,
        '-144': 32.1378786782395,
        '-143': 32.137862794991904,
        '-142': 32.13773622690872,
        '-141': 32.13765906783736,
        '-140': 32.13778378436654,
        '-139': 32.13797409298574,
        '-138': 32.13799379593029,
        '-137': 32.13786292954457,
        '-136': 32.13780086097175,
        '-135': 32.13786540557858,
        '-134': 32.13789004599767,
        '-133': 32.13780292158297,
        '-132': 32.137768666948105,
        '-131': 32.13790543731091,
        '-130': 32.138059142493226,
        '-129': 32.13803261978569,
        '-128': 32.13790592560103,
        '-127': 32.137915863227164,
        '-126': 32.13807670082563,
        '-125': 32.138151608470345,
        '-124': 32.13802457612268,
        '-123': 32.137881716321495,
        '-122': 32.13791817538885,
        '-121': 32.13803927430209,
        '-120': 32.138022280570574,
        '-119': 32.13787147278897,
        '-118': 32.137806016894416,
        '-117': 32.137910407860225,
        '-116': 32.13800172324222,
        '-115': 32.13791628086121,
        '-114': 32.1377651124397,
        '-113': 32.13776685101353,
        '-112': 32.13791965188933,
        '-111': 32.13800712312113,
        '-110': 32.13791676131119,
        '-109': 32.137792272071266,
        '-108': 32.137813237992646,
        '-107': 32.13794534857475,
        '-106': 32.13801198848867,
        '-105': 32.137947047613935,
        '-104': 32.13785809923314,
        '-103': 32.13785068068972,
        '-102': 32.137897837880224,
        '-101': 32.13791231757026,
        '-100': 32.137866098706866,
        '-99': 32.13779199434657,
        '-98': 32.137726085508035,
        '-97': 32.13769920711511,
        '-96': 32.13773702218102,
        '-95': 32.13781359811514,
        '-94': 32.13784993416441,
        '-93': 32.13782143398571,
        '-92': 32.13781556911198,
        '-91': 32.13789305633188,
        '-90': 32.13794516441017,
        '-89': 32.13783513871478,
        '-88': 32.137664918641754,
        '-87': 32.13769604384217,
        '-86': 32.13793550267036,
        '-85': 32.13803478490231,
        '-84': 32.13779656438285,
        '-83': 32.137570416371865,
        '-82': 32.13779305138663,
        '-81': 32.138233594171275,
        '-80': 32.1381956607744,
        '-79': 32.1376076816188,
        '-78': 32.13735962454975,
        '-77': 32.13807284134848,
        '-76': 32.13893000815203,
        '-75': 32.13854063838512,
        '-74': 32.1370005857805,
        '-73': 32.13619982454463,
        '-72': 32.137516396391945,
        '-71': 32.127540567811195,
        '-70': 32.09348304751163,
        '-69': 32.12615662518796,
        '-68': 32.13110443997011,
        '-67': 32.13182232705528,
        '-66': 32.1256924184668,
        '-65': 32.12573165361254,
        '-64': 32.13598706911746,
        '-63': 32.136749776311405,
        '-62': 32.13670828704499,
        '-61': 32.13743234312745,
        '-60': 32.13836543901321,
        '-59': 32.137741113798256,
        '-58': 32.138019492926965,
        '-57': 32.138192625879036,
        '-56': 32.137909613207114,
        '-55': 32.136195953455335,
        '-54': 32.138168948925546,
        '-53': 32.138452453304964,
        '-52': 32.13871440073081,
        '-51': 32.13877936622846,
        '-50': 32.138387466050474,
        '-49': 32.13780910076295,
        '-48': 32.1374809369677,
        '-47': 32.137506422836985,
        '-46': 32.137652786407216,
        '-45': 32.13769208265033,
        '-44': 32.13760154751748,
        '-43': 32.13748904476751,
        '-42': 32.13744469882987,
        '-41': 32.13747051167208,
        '-40': 32.13749592984369,
        '-39': 32.13746061814502,
        '-38': 32.13740497400045,
        '-37': 32.13744597199444,
        '-36': 32.137625644180495,
        '-35': 32.13782662655087,
        '-34': 32.137903480687164,
        '-33': 32.13787087284304,
        '-32': 32.13787831380522,
        '-31': 32.13799739213003,
        '-30': 32.13812349814403,
        '-29': 32.138126732925265,
        '-28': 32.13802799096223,
        '-27': 32.13796037528868,
        '-26': 32.13799701271894,
        '-25': 32.13808877514463,
        '-24': 32.13815899985015,
        '-23': 32.13819071355112,
        '-22': 32.13820764300984,
        '-21': 32.13822242477679,
        '-20': 32.13823171323376,
        '-19': 32.13823405111385,
        '-18': 32.138231687879106,
        '-17': 32.1382321454819,
        '-16': 32.138253189409845,
        '-15': 32.1382969086845,
        '-14': 32.13831223386137,
        '-13': 32.13822728090819,
        '-12': 32.13804634088343,
        '-11': 32.137874883245615,
        '-10': 32.13780526801164,
        '-9': 32.137808094835826,
        '-8': 32.13779239184033,
        '-7': 32.13775329269349,
        '-6': 32.137783854252966,
        '-5': 32.1379260675644,
        '-4': 32.1380824143999,
        '-3': 32.13812419414191,
        '-2': 32.13804696205772,
        '-1': 32.13796611846714
    },
    '-34': {
        '0': 32.140697952579764,
        '1': 32.14078686001296,
        '2': 32.14080386902829,
        '3': 32.14074563965366,
        '4': 32.140660683545704,
        '5': 32.14057633733502,
        '6': 32.14049443895993,
        '7': 32.140431774505096,
        '8': 32.140430005788915,
        '9': 32.14052079494361,
        '10': 32.140681624354876,
        '11': 32.140818830795396,
        '12': 32.140812680840256,
        '13': 32.14062635723013,
        '14': 32.14038545330522,
        '15': 32.1403041902257,
        '16': 32.14049150471956,
        '17': 32.140848119251885,
        '18': 32.141180231223395,
        '19': 32.13319420621646,
        '20': 32.13914997907598,
        '21': 32.13668767632587,
        '22': 32.13863990446992,
        '23': 32.13966366536604,
        '24': 32.13805686429232,
        '25': 32.14016606507622,
        '26': 32.140362956432945,
        '27': 32.14054483376365,
        '28': 32.14051365756578,
        '29': 32.140313992568935,
        '30': 32.14017789216131,
        '31': 32.140338155119636,
        '32': 32.14085510837575,
        '33': 32.14151934864952,
        '34': 32.141920108030334,
        '35': 32.1417501318794,
        '36': 32.141137744317206,
        '37': 32.140586677768916,
        '38': 32.14046397784538,
        '39': 32.14060957977868,
        '40': 32.140612966329925,
        '41': 32.14044366716457,
        '42': 32.14055833344078,
        '43': 32.1412548638924,
        '44': 32.14212512428126,
        '45': 32.142422966655325,
        '46': 32.14194251944781,
        '47': 32.14126132541156,
        '48': 32.141022906287354,
        '49': 32.14120923916087,
        '50': 32.14132369617427,
        '51': 32.141144190679455,
        '52': 32.14099234977785,
        '53': 32.14122344158361,
        '54': 32.14171184612117,
        '55': 32.142013094685595,
        '56': 32.14189982498152,
        '57': 32.14154246951631,
        '58': 32.141210110536385,
        '59': 32.140996952194435,
        '60': 32.14088580969485,
        '61': 32.14090286605938,
        '62': 32.141071457590826,
        '63': 32.14128670555297,
        '64': 32.14137685199942,
        '65': 32.14129587962446,
        '66': 32.14116651391458,
        '67': 32.141115927195614,
        '68': 32.14114218602782,
        '69': 32.14117081731,
        '70': 32.141173410179995,
        '71': 32.14117170797161,
        '72': 32.14116428942358,
        '73': 32.14112154253243,
        '74': 32.14105363865893,
        '75': 32.14102512155461,
        '76': 32.141076293947954,
        '77': 32.14116223474889,
        '78': 32.14119863945241,
        '79': 32.141153375977744,
        '80': 32.14106810209641,
        '81': 32.140997599030506,
        '82': 32.14095588328436,
        '83': 32.14092851902353,
        '84': 32.140913308310274,
        '85': 32.140923236481235,
        '86': 32.140950451677625,
        '87': 32.14095056998994,
        '88': 32.140881655368325,
        '89': 32.140756025040965,
        '90': 32.14063711028519,
        '91': 32.14057683438356,
        '92': 32.1405630973316,
        '93': 32.140534980701204,
        '94': 32.14044206779458,
        '95': 32.140283465570725,
        '96': 32.14010570334639,
        '97': 32.13998408089173,
        '98': 32.13999102753002,
        '99': 32.14013321464808,
        '100': 32.14029760962242,
        '101': 32.14030413087724,
        '102': 32.140071497939296,
        '103': 32.13972725379958,
        '104': 32.13949849846393,
        '105': 32.13948069392637,
        '106': 32.13957271200741,
        '107': 32.13966977414622,
        '108': 32.13983558040497,
        '109': 32.14017140493121,
        '110': 32.140544351035125,
        '111': 32.14061830743534,
        '112': 32.14026458935951,
        '113': 32.13984389327902,
        '114': 32.13990082545853,
        '115': 32.140091684050844,
        '116': 32.138829185524614,
        '117': 32.138184332808876,
        '118': 32.13852706881531,
        '119': 32.137789803131305,
        '120': 32.140174796679304,
        '121': 32.1401483070422,
        '122': 32.14024483373626,
        '123': 32.14032278921948,
        '124': 32.140357325033236,
        '125': 32.140311204278845,
        '126': 32.140117296530335,
        '127': 32.1398149484664,
        '128': 32.13958377735056,
        '129': 32.139570920085845,
        '130': 32.13974037347871,
        '131': 32.1399520902738,
        '132': 32.14014767381198,
        '133': 32.14038607295853,
        '134': 32.14070952387851,
        '135': 32.14104838010071,
        '136': 32.14018677401454,
        '137': 32.14133942077433,
        '138': 32.140067979229016,
        '139': 32.13607176695749,
        '140': 32.14018078267722,
        '141': 32.14009025213441,
        '142': 32.13986032924783,
        '143': 32.139513341786596,
        '144': 32.139721994941816,
        '145': 32.13953429473634,
        '146': 32.138997961855445,
        '147': 32.138035733978384,
        '148': 32.13799319050296,
        '149': 32.13634212637488,
        '150': 32.132550645476186,
        '151': 32.14066113432006,
        '152': 32.140349562461715,
        '153': 32.1396934395666,
        '154': 32.139831861747396,
        '155': 32.140410061366005,
        '156': 32.14074412002233,
        '157': 32.14056473296394,
        '158': 32.140240788393946,
        '159': 32.14027832836382,
        '160': 32.14073262675508,
        '161': 32.14120115739291,
        '162': 32.141314599300046,
        '163': 32.14109611777577,
        '164': 32.140835861870556,
        '165': 32.14074816576534,
        '166': 32.14083092619127,
        '167': 32.140974217410886,
        '168': 32.14108620426229,
        '169': 32.14113201627564,
        '170': 32.141159877303394,
        '171': 32.14129732728375,
        '172': 32.14160343380191,
        '173': 32.141889350883744,
        '174': 32.141849047285014,
        '175': 32.141517342345395,
        '176': 32.14145697525198,
        '177': 32.14216517369084,
        '178': 32.14321862567515,
        '179': 32.1433624505604,
        '180': 32.14183627712943,
        '-180': 32.14183627712943,
        '-179': 32.139536538680254,
        '-178': 32.138409573266074,
        '-177': 32.13944713361994,
        '-176': 32.14154251156949,
        '-175': 32.14266718550286,
        '-174': 32.14205664863677,
        '-173': 32.140833916177726,
        '-172': 32.140449630632794,
        '-171': 32.140999237469806,
        '-170': 32.14143889391265,
        '-169': 32.14113506478224,
        '-168': 32.14058323215613,
        '-167': 32.14052204315279,
        '-166': 32.14089056393659,
        '-165': 32.14105785294686,
        '-164': 32.140798346102024,
        '-163': 32.140537773724866,
        '-162': 32.14064243147778,
        '-161': 32.140910938391706,
        '-160': 32.140957240249776,
        '-159': 32.14078403089347,
        '-158': 32.14068905320126,
        '-157': 32.14077007929843,
        '-156': 32.140837540532644,
        '-155': 32.14077982546514,
        '-154': 32.140722223066824,
        '-153': 32.14076371107708,
        '-152': 32.140801839659304,
        '-151': 32.140745466713426,
        '-150': 32.140708842306324,
        '-149': 32.14081874001327,
        '-148': 32.140960535598865,
        '-147': 32.14092346571539,
        '-146': 32.14073355026402,
        '-145': 32.140624101923244,
        '-144': 32.14067734263072,
        '-143': 32.140714075749784,
        '-142': 32.140605056314534,
        '-141': 32.14048988163171,
        '-140': 32.140547450941924,
        '-139': 32.14069791670913,
        '-138': 32.14072154292959,
        '-137': 32.14059793866568,
        '-136': 32.14052386026297,
        '-135': 32.140601593240284,
        '-134': 32.14070710077164,
        '-133': 32.14072126831602,
        '-132': 32.14071385509531,
        '-131': 32.14078479134445,
        '-130': 32.14086074275878,
        '-129': 32.140810853176774,
        '-128': 32.140688715060975,
        '-127': 32.14067529409209,
        '-126': 32.14078854047818,
        '-125': 32.14083069744763,
        '-124': 32.14068349909777,
        '-123': 32.140501800054416,
        '-122': 32.140497663520705,
        '-121': 32.14063622366998,
        '-120': 32.14069906907497,
        '-119': 32.140608767325844,
        '-118': 32.140523754037915,
        '-117': 32.14057939765595,
        '-116': 32.14068263971417,
        '-115': 32.140662753089046,
        '-114': 32.14053456473796,
        '-113': 32.140478305066104,
        '-112': 32.140577203891475,
        '-111': 32.14070107898014,
        '-110': 32.140691054532354,
        '-109': 32.1405710349194,
        '-108': 32.14050313017276,
        '-107': 32.14057459902166,
        '-106': 32.140704364279834,
        '-105': 32.14075912287893,
        '-104': 32.14070465466394,
        '-103': 32.1406216639894,
        '-102': 32.14060285074234,
        '-101': 32.14065631170827,
        '-100': 32.14070252556841,
        '-99': 32.14066288056127,
        '-98': 32.140556184299,
        '-97': 32.14048898946299,
        '-96': 32.14052436347159,
        '-95': 32.14058726070484,
        '-94': 32.140562453506185,
        '-93': 32.140476688236106,
        '-92': 32.140481976321645,
        '-91': 32.14061351782947,
        '-90': 32.14068002306354,
        '-89': 32.14052021832305,
        '-88': 32.140290164182005,
        '-87': 32.140297782260554,
        '-86': 32.14054657868893,
        '-85': 32.14068285749584,
        '-84': 32.140518790045085,
        '-83': 32.14037187714297,
        '-82': 32.140613344008315,
        '-81': 32.14103137065366,
        '-80': 32.14105773105101,
        '-79': 32.140661206522815,
        '-78': 32.14052537829518,
        '-77': 32.14102031911377,
        '-76': 32.14140988882687,
        '-75': 32.140719515928616,
        '-74': 32.13931904030703,
        '-73': 32.138885062408995,
        '-72': 32.140384710059024,
        '-71': 32.13468581743644,
        '-70': 32.115118259844344,
        '-69': 32.130311533804345,
        '-68': 32.13449348025464,
        '-67': 32.13590807537071,
        '-66': 32.13514687277731,
        '-65': 32.13822159665132,
        '-64': 32.13959057346194,
        '-63': 32.139447127860045,
        '-62': 32.139390723633745,
        '-61': 32.14012717027525,
        '-60': 32.14044038966453,
        '-59': 32.14090003492579,
        '-58': 32.140349039367756,
        '-57': 32.13997070603117,
        '-56': 32.14047242210372,
        '-55': 32.13907310759324,
        '-54': 32.14003239918745,
        '-53': 32.141286110464584,
        '-52': 32.14144548335555,
        '-51': 32.1412896984169,
        '-50': 32.14074359772501,
        '-49': 32.1402053728055,
        '-48': 32.140053609494366,
        '-47': 32.14022982624804,
        '-46': 32.14040427655239,
        '-45': 32.14039662743177,
        '-44': 32.140293376312336,
        '-43': 32.14023435497416,
        '-42': 32.140239125746206,
        '-41': 32.14024766442052,
        '-40': 32.14022230878083,
        '-39': 32.14016807841178,
        '-38': 32.14010910879964,
        '-37': 32.140081176393224,
        '-36': 32.140117394921994,
        '-35': 32.14021768729417,
        '-34': 32.140351166689676,
        '-33': 32.14049593623477,
        '-32': 32.14064744378927,
        '-31': 32.1407783245055,
        '-30': 32.140831826584076,
        '-29': 32.14078977092201,
        '-28': 32.14072257220334,
        '-27': 32.14072858231337,
        '-26': 32.14082566537944,
        '-25': 32.1409357439908,
        '-24': 32.140981202711856,
        '-23': 32.14096771540818,
        '-22': 32.14095885472683,
        '-21': 32.140990784199005,
        '-20': 32.1410345844982,
        '-19': 32.14103993520578,
        '-18': 32.14100066166492,
        '-17': 32.14096584549565,
        '-16': 32.14097897738569,
        '-15': 32.141009426986855,
        '-14': 32.14096770824185,
        '-13': 32.140810593851775,
        '-12': 32.140614618386124,
        '-11': 32.14050524123469,
        '-10': 32.140511430430074,
        '-9': 32.140538493717656,
        '-8': 32.14050827880376,
        '-7': 32.14047535418767,
        '-6': 32.14054607279603,
        '-5': 32.14070814038132,
        '-4': 32.14081927306107,
        '-3': 32.14078310772102,
        '-2': 32.14067175814882,
        '-1': 32.14062977327536
    },
    '-35': {
        '0': 32.14334231487805,
        '1': 32.1434040966474,
        '2': 32.14334703151939,
        '3': 32.14325019752017,
        '4': 32.14322280662075,
        '5': 32.143268475451976,
        '6': 32.14330667304181,
        '7': 32.14329612573507,
        '8': 32.14329088258942,
        '9': 32.1433705018622,
        '10': 32.14353610677184,
        '11': 32.143684045748174,
        '12': 32.143684379924366,
        '13': 32.143501102350655,
        '14': 32.14324842818603,
        '15': 32.14311672103363,
        '16': 32.143221627301855,
        '17': 32.14352001656186,
        '18': 32.14386765258597,
        '19': 32.14413080847808,
        '20': 32.14422581467384,
        '21': 32.144098185853956,
        '22': 32.14374679048367,
        '23': 32.14329535746667,
        '24': 32.142967725202574,
        '25': 32.14292374845811,
        '26': 32.14310908201956,
        '27': 32.14330308957555,
        '28': 32.14332832802816,
        '29': 32.143210766689705,
        '30': 32.143152038337654,
        '31': 32.14335501667279,
        '32': 32.1438314689447,
        '33': 32.144325878263615,
        '34': 32.14446371309514,
        '35': 32.144083143017326,
        '36': 32.14345482101392,
        '37': 32.14307594753161,
        '38': 32.14316315385647,
        '39': 32.143419563863475,
        '40': 32.14342324889091,
        '41': 32.14320079326143,
        '42': 32.14322698807182,
        '43': 32.143788786619396,
        '44': 32.14452626949126,
        '45': 32.144794208194675,
        '46': 32.14441761439573,
        '47': 32.14387636261182,
        '48': 32.14370283257639,
        '49': 32.14389856967981,
        '50': 32.14408717439088,
        '51': 32.14409502643737,
        '52': 32.144134161187466,
        '53': 32.14440384233518,
        '54': 32.14474042482296,
        '55': 32.14480400681622,
        '56': 32.144501646350484,
        '57': 32.14406965763767,
        '58': 32.1437734736725,
        '59': 32.143674704996435,
        '60': 32.1437083718225,
        '61': 32.14383932129384,
        '62': 32.14404519839931,
        '63': 32.14422842984062,
        '64': 32.14427147196062,
        '65': 32.14417659122224,
        '66': 32.144063178328736,
        '67': 32.14401113929542,
        '68': 32.14397947478618,
        '69': 32.143908749902934,
        '70': 32.14383306748256,
        '71': 32.143825391360046,
        '72': 32.14386959181897,
        '73': 32.143868381198445,
        '74': 32.143786545246364,
        '75': 32.14371976318184,
        '76': 32.14378161821152,
        '77': 32.14395648592917,
        '78': 32.144107897997515,
        '79': 32.14412083130709,
        '80': 32.14399894015469,
        '81': 32.14382702166693,
        '82': 32.14368328827959,
        '83': 32.14360637662831,
        '84': 32.143607087002984,
        '85': 32.143663064489424,
        '86': 32.14370682982063,
        '87': 32.14366676063237,
        '88': 32.143544907487076,
        '89': 32.14342951101339,
        '90': 32.14340047507187,
        '91': 32.14343399035478,
        '92': 32.14343307739805,
        '93': 32.1433493103159,
        '94': 32.14323298247937,
        '95': 32.14314693296415,
        '96': 32.143076483227496,
        '97': 32.14297516798932,
        '98': 32.14287619690019,
        '99': 32.14287980098473,
        '100': 32.143000101611804,
        '101': 32.143088928389105,
        '102': 32.14298376162523,
        '103': 32.1427104638609,
        '104': 32.14246065047152,
        '105': 32.14235834842413,
        '106': 32.14233551780113,
        '107': 32.14229111961533,
        '108': 32.14228794187001,
        '109': 32.142462768931594,
        '110': 32.14275144239379,
        '111': 32.142861412748125,
        '112': 32.142624866054184,
        '113': 32.14229456436611,
        '114': 32.142326601548994,
        '115': 32.14284109777153,
        '116': 32.1434210551594,
        '117': 32.143339328219234,
        '118': 32.14292753344289,
        '119': 32.142466671650475,
        '120': 32.14213725707044,
        '121': 32.14217311699001,
        '122': 32.1423417681827,
        '123': 32.14240684299601,
        '124': 32.142301655465346,
        '125': 32.14207511456479,
        '126': 32.14180729698221,
        '127': 32.14160296882646,
        '128': 32.14157168271045,
        '129': 32.141741948749306,
        '130': 32.14202034594721,
        '131': 32.14228129392973,
        '132': 32.14248820272322,
        '133': 32.14270109066295,
        '134': 32.14298547175266,
        '135': 32.143346992294994,
        '136': 32.14373216538002,
        '137': 32.14397706581425,
        '138': 32.14412742267288,
        '139': 32.141124852667545,
        '140': 32.14283367259296,
        '141': 32.142646280082104,
        '142': 32.14250400558206,
        '143': 32.14274725845684,
        '144': 32.14264696165129,
        '145': 32.14228170792883,
        '146': 32.14190552504069,
        '147': 32.14188023179784,
        '148': 32.139067773237215,
        '149': 32.13886320666857,
        '150': 32.13891114126934,
        '151': 32.14366009864884,
        '152': 32.142654732913805,
        '153': 32.142418016692346,
        '154': 32.142944007533956,
        '155': 32.14355680677543,
        '156': 32.14366122962982,
        '157': 32.1433052337564,
        '158': 32.14298310435186,
        '159': 32.14301997839269,
        '160': 32.14329145214879,
        '161': 32.14350826574271,
        '162': 32.143587521960356,
        '163': 32.14364071154009,
        '164': 32.14371127349778,
        '165': 32.143703546973846,
        '166': 32.14358053620299,
        '167': 32.14347699214921,
        '168': 32.14352784563226,
        '169': 32.143682862941155,
        '170': 32.1438125921331,
        '171': 32.14393913177205,
        '172': 32.144197444910624,
        '173': 32.144558299667736,
        '174': 32.14478436195347,
        '175': 32.144781986054134,
        '176': 32.14482396275537,
        '177': 32.145158913286345,
        '178': 32.14543895503902,
        '179': 32.14490176775523,
        '180': 32.14336563713914,
        '-180': 32.14336563713915,
        '-179': 32.14182576118526,
        '-178': 32.14163823203926,
        '-177': 32.14301702896565,
        '-176': 32.144660454150085,
        '-175': 32.14511141192167,
        '-174': 32.144299254009375,
        '-173': 32.143426918012835,
        '-172': 32.143426922587935,
        '-171': 32.14396499732019,
        '-170': 32.14413413541107,
        '-169': 32.14373343174092,
        '-168': 32.14340463966047,
        '-167': 32.143610863318855,
        '-166': 32.14399534309584,
        '-165': 32.14396027400971,
        '-164': 32.14352523832259,
        '-163': 32.1432385072464,
        '-162': 32.14337354632452,
        '-161': 32.14361405681532,
        '-160': 32.143592454646786,
        '-159': 32.143397489592445,
        '-158': 32.14334472735505,
        '-157': 32.14347006207121,
        '-156': 32.14353336937594,
        '-155': 32.14343832937603,
        '-154': 32.14336193573169,
        '-153': 32.143435659444435,
        '-152': 32.143542025068825,
        '-151': 32.14353862206428,
        '-150': 32.14349640438447,
        '-149': 32.14355056695899,
        '-148': 32.14363852353997,
        '-147': 32.14358728587421,
        '-146': 32.143414328282454,
        '-145': 32.14333450087506,
        '-144': 32.143440545027715,
        '-143': 32.14356256413061,
        '-142': 32.14353105061127,
        '-141': 32.14342687404859,
        '-140': 32.14343273897823,
        '-139': 32.1435378883755,
        '-138': 32.14356424479625,
        '-137': 32.14345579321328,
        '-136': 32.14336632330712,
        '-135': 32.14342212822566,
        '-134': 32.143545180459455,
        '-133': 32.14359546198914,
        '-132': 32.143577366279665,
        '-131': 32.143588615445566,
        '-130': 32.143633173890514,
        '-129': 32.1436234306738,
        '-128': 32.1435531881833,
        '-127': 32.1435290917969,
        '-126': 32.143590632700906,
        '-125': 32.14361652022524,
        '-124': 32.14349449307,
        '-123': 32.14330776027063,
        '-122': 32.14324293325833,
        '-121': 32.14333872544715,
        '-120': 32.14343696669403,
        '-119': 32.14340256639982,
        '-118': 32.14329591677799,
        '-117': 32.1432677101366,
        '-116': 32.143346745129776,
        '-115': 32.143418086224635,
        '-114': 32.143396186994785,
        '-113': 32.143335588498445,
        '-112': 32.143339590339615,
        '-111': 32.14341295597692,
        '-110': 32.143458417494585,
        '-109': 32.14340758355311,
        '-108': 32.1433108807137,
        '-107': 32.14328047219926,
        '-106': 32.14336158608602,
        '-105': 32.14348191045663,
        '-104': 32.143534105632845,
        '-103': 32.14349695728128,
        '-102': 32.143453079256886,
        '-101': 32.14347665925908,
        '-100': 32.14352983580733,
        '-99': 32.143512824746345,
        '-98': 32.14341384537468,
        '-97': 32.1433462633116,
        '-96': 32.143395415303765,
        '-95': 32.14348181551016,
        '-94': 32.14346039883031,
        '-93': 32.14333813791827,
        '-92': 32.143280633420375,
        '-91': 32.14336247503611,
        '-90': 32.143425846852175,
        '-89': 32.14329821992404,
        '-88': 32.14307611432782,
        '-87': 32.1430261667863,
        '-86': 32.14320538641899,
        '-85': 32.143368222781405,
        '-84': 32.14333529196165,
        '-83': 32.143272457086326,
        '-82': 32.14341921705803,
        '-81': 32.14366988736538,
        '-80': 32.14371032818603,
        '-79': 32.14355581834769,
        '-78': 32.14359499686498,
        '-77': 32.14392150833338,
        '-76': 32.14396212045757,
        '-75': 32.143202661737945,
        '-74': 32.14217299767759,
        '-73': 32.14215443049168,
        '-72': 32.14290542948618,
        '-71': 32.13925772495498,
        '-70': 32.103272937506354,
        '-69': 32.13180234959842,
        '-68': 32.138623954623824,
        '-67': 32.13925424234117,
        '-66': 32.139678238836666,
        '-65': 32.14127026363168,
        '-64': 32.14232163108351,
        '-63': 32.14233041888331,
        '-62': 32.142434629401464,
        '-61': 32.14310390935365,
        '-60': 32.14344954123491,
        '-59': 32.14345022950657,
        '-58': 32.143577289311004,
        '-57': 32.14400944947855,
        '-56': 32.1441302413052,
        '-55': 32.14406981307658,
        '-54': 32.14400272190062,
        '-53': 32.144051289197854,
        '-52': 32.14399932544264,
        '-51': 32.14358025968678,
        '-50': 32.142927633466634,
        '-49': 32.14249448343243,
        '-48': 32.142525985594844,
        '-47': 32.14280741317962,
        '-46': 32.14298758865549,
        '-45': 32.14298517584216,
        '-44': 32.14296359481317,
        '-43': 32.14302276357697,
        '-42': 32.143083916528425,
        '-41': 32.143062111791,
        '-40': 32.14300448721227,
        '-39': 32.14300210927892,
        '-38': 32.143052837642735,
        '-37': 32.14308614320418,
        '-36': 32.14308463592506,
        '-35': 32.14311091799365,
        '-34': 32.143217443943556,
        '-33': 32.14337909581719,
        '-32': 32.14352512914594,
        '-31': 32.143606218515664,
        '-30': 32.14362003451209,
        '-29': 32.14359908466706,
        '-28': 32.143592002522375,
        '-27': 32.14363747266862,
        '-26': 32.14373056227849,
        '-25': 32.14381748402357,
        '-24': 32.143843978835015,
        '-23': 32.14381463842362,
        '-22': 32.14379078238714,
        '-21': 32.14382029377089,
        '-20': 32.143879757381214,
        '-19': 32.14390241452969,
        '-18': 32.14386126899266,
        '-17': 32.1438008874878,
        '-16': 32.14377071433443,
        '-15': 32.14374643111887,
        '-14': 32.143656107894984,
        '-13': 32.1434904061723,
        '-12': 32.14334362959904,
        '-11': 32.14330777506101,
        '-10': 32.143350736176856,
        '-9': 32.14335617079692,
        '-8': 32.143286492063325,
        '-7': 32.14324149245534,
        '-6': 32.14331515014533,
        '-5': 32.14344410736638,
        '-4': 32.14347199192216,
        '-3': 32.14335555409496,
        '-2': 32.14322936321135,
        '-1': 32.14323457066815
    },
    '-36': {
        '0': 32.145960601895766,
        '1': 32.14599546869882,
        '2': 32.14592291937432,
        '3': 32.145865017503354,
        '4': 32.14594067506448,
        '5': 32.146125657101926,
        '6': 32.14629621593054,
        '7': 32.14637087339717,
        '8': 32.14637079300397,
        '9': 32.14636057847348,
        '10': 32.146373879553764,
        '11': 32.14640109324423,
        '12': 32.146415001019434,
        '13': 32.14638879794336,
        '14': 32.14630829486803,
        '15': 32.146193601738126,
        '16': 32.14611154771155,
        '17': 32.14614758747441,
        '18': 32.14634137858223,
        '19': 32.14663157198193,
        '20': 32.14686313484642,
        '21': 32.14687635241317,
        '22': 32.146629901240686,
        '23': 32.1462558257595,
        '24': 32.145975568214844,
        '25': 32.14592959590718,
        '26': 32.14606969157189,
        '27': 32.14621320827177,
        '28': 32.14620790431464,
        '29': 32.146064632778454,
        '30': 32.145954573205955,
        '31': 32.14606853368357,
        '32': 32.146428038868116,
        '33': 32.14680346193146,
        '34': 32.14686675078874,
        '35': 32.146506121787255,
        '36': 32.14599379898786,
        '37': 32.14575868286265,
        '38': 32.1459445052064,
        '39': 32.146258057198374,
        '40': 32.14633142919666,
        '41': 32.14618166352917,
        '42': 32.146179266408616,
        '43': 32.14654354730769,
        '44': 32.147017327326864,
        '45': 32.14716310103245,
        '46': 32.14690549917845,
        '47': 32.14661622575193,
        '48': 32.146648910626425,
        '49': 32.14694632183009,
        '50': 32.14719773967852,
        '51': 32.14726398186265,
        '52': 32.14728060460344,
        '53': 32.14736578623696,
        '54': 32.147407224565455,
        '55': 32.14722678831266,
        '56': 32.14685250940864,
        '57': 32.14650657959674,
        '58': 32.146356870112164,
        '59': 32.14638764280499,
        '60': 32.14651219975212,
        '61': 32.146696548535935,
        '62': 32.1469175359354,
        '63': 32.14708640506867,
        '64': 32.14711325160694,
        '65': 32.1470255115551,
        '66': 32.146937876670115,
        '67': 32.14689842985672,
        '68': 32.14683946400891,
        '69': 32.146708517492854,
        '70': 32.14657702097325,
        '71': 32.14655311704256,
        '72': 32.14662302718551,
        '73': 32.14666152566914,
        '74': 32.146608652868956,
        '75': 32.14656433353214,
        '76': 32.146662143450065,
        '77': 32.14688750294419,
        '78': 32.14707619861666,
        '79': 32.14708531160408,
        '80': 32.14692057870534,
        '81': 32.14669436262421,
        '82': 32.14650982228576,
        '83': 32.14641182310104,
        '84': 32.14640453482927,
        '85': 32.14645570294873,
        '86': 32.14648882274993,
        '87': 32.14643159940852,
        '88': 32.14630204606747,
        '89': 32.14621010157492,
        '90': 32.14623335635914,
        '91': 32.14631099264017,
        '92': 32.14631042355489,
        '93': 32.14619811989324,
        '94': 32.14608674161341,
        '95': 32.14608266988999,
        '96': 32.1461359282307,
        '97': 32.14610540664677,
        '98': 32.14595731701667,
        '99': 32.145819095763656,
        '100': 32.14580458067164,
        '101': 32.14585107769081,
        '102': 32.145801060599275,
        '103': 32.145617534376754,
        '104': 32.14542884136305,
        '105': 32.145341764590526,
        '106': 32.145297860969805,
        '107': 32.145184481673255,
        '108': 32.14503077734534,
        '109': 32.144980785126464,
        '110': 32.14506367700837,
        '111': 32.14510631506411,
        '112': 32.14495682279244,
        '113': 32.144730116954264,
        '114': 32.14470810617012,
        '115': 32.14498083853358,
        '116': 32.14528974499484,
        '117': 32.14529737872705,
        '118': 32.14496200625149,
        '119': 32.144567419889185,
        '120': 32.14440091371555,
        '121': 32.14448345631132,
        '122': 32.144628601285014,
        '123': 32.14467971443662,
        '124': 32.1446244677945,
        '125': 32.1445252310327,
        '126': 32.14443097309087,
        '127': 32.14437351442603,
        '128': 32.14438348549833,
        '129': 32.144461273244005,
        '130': 32.144553846014276,
        '131': 32.144599601007414,
        '132': 32.14459945073117,
        '133': 32.14463198590936,
        '134': 32.14480490793847,
        '135': 32.14518993449707,
        '136': 32.1457627825191,
        '137': 32.14603115690382,
        '138': 32.14675872847988,
        '139': 32.14679833699408,
        '140': 32.14637949871257,
        '141': 32.14510983387674,
        '142': 32.14556498401429,
        '143': 32.14554095774795,
        '144': 32.145621056931624,
        '145': 32.145338781714706,
        '146': 32.145097188040815,
        '147': 32.144783440503815,
        '148': 32.143540052662146,
        '149': 32.13636190874584,
        '150': 32.14549873784297,
        '151': 32.14597720624825,
        '152': 32.145182196677275,
        '153': 32.14536261593406,
        '154': 32.14613005315016,
        '155': 32.14667514084426,
        '156': 32.146610295041434,
        '157': 32.14622694571065,
        '158': 32.14599043297217,
        '159': 32.14599311062789,
        '160': 32.14601248672566,
        '161': 32.14595931947989,
        '162': 32.14602585206846,
        '163': 32.14634570720857,
        '164': 32.1467027912767,
        '165': 32.1467542476907,
        '166': 32.14648151888119,
        '167': 32.14623176800555,
        '168': 32.14627198006088,
        '169': 32.146466290283975,
        '170': 32.14652146395024,
        '171': 32.1464449862125,
        '172': 32.14655751013459,
        '173': 32.14703512651238,
        '174': 32.14703978782076,
        '175': 32.14794083006664,
        '176': 32.14786483317976,
        '177': 32.14754412085051,
        '178': 32.14702140959061,
        '179': 32.1462017461888,
        '180': 32.145268314458235,
        '-180': 32.145268314458235,
        '-179': 32.14485166399378,
        '-178': 32.14544791387729,
        '-177': 32.14669299847077,
        '-176': 32.147541826192715,
        '-175': 32.147341358856046,
        '-174': 32.14653865732232,
        '-173': 32.14610616978626,
        '-172': 32.1463847903495,
        '-171': 32.14680123684191,
        '-170': 32.14674677495127,
        '-169': 32.14637775421049,
        '-168': 32.14629191408638,
        '-167': 32.146628559396206,
        '-166': 32.14688832629421,
        '-165': 32.14667528035641,
        '-164': 32.14625473954136,
        '-163': 32.14614999994711,
        '-162': 32.14640847061403,
        '-161': 32.14658942888419,
        '-160': 32.14642492895585,
        '-159': 32.14616125041642,
        '-158': 32.14613947655578,
        '-157': 32.14630261059108,
        '-156': 32.146343414652115,
        '-155': 32.14619624708825,
        '-154': 32.14610612837502,
        '-153': 32.14621991037232,
        '-152': 32.1463666597701,
        '-151': 32.146343306978984,
        '-150': 32.14622304681769,
        '-149': 32.14621115732272,
        '-148': 32.14630977194707,
        '-147': 32.14632985613714,
        '-146': 32.14620968463977,
        '-145': 32.14612021417271,
        '-144': 32.14619857930226,
        '-143': 32.14634072419257,
        '-142': 32.14637155077128,
        '-141': 32.14630959232636,
        '-140': 32.14631383975388,
        '-139': 32.14641614967404,
        '-138': 32.14646807304616,
        '-137': 32.14637513771034,
        '-136': 32.14624444881213,
        '-135': 32.146225010388264,
        '-134': 32.14629896798156,
        '-133': 32.146334469725424,
        '-132': 32.14629249688627,
        '-131': 32.146264138028194,
        '-130': 32.14630736849133,
        '-129': 32.146359480017786,
        '-128': 32.14635336391857,
        '-127': 32.146332444420324,
        '-126': 32.146373113181156,
        '-125': 32.14644170064317,
        '-124': 32.14642259220197,
        '-123': 32.14628859720025,
        '-122': 32.146159598348746,
        '-121': 32.146152490429344,
        '-120': 32.14622759506023,
        '-119': 32.14624438381405,
        '-118': 32.146150308450345,
        '-117': 32.14605007975438,
        '-116': 32.14606884047161,
        '-115': 32.14619187619415,
        '-114': 32.14628406051685,
        '-113': 32.146257174412355,
        '-112': 32.1461718926913,
        '-111': 32.146151354854624,
        '-110': 32.14622044963781,
        '-109': 32.14627528592411,
        '-108': 32.14622204327173,
        '-107': 32.14610755021386,
        '-106': 32.14606953505919,
        '-105': 32.14616513238081,
        '-104': 32.14629942741144,
        '-103': 32.146348184015544,
        '-102': 32.146309081528315,
        '-101': 32.14627864043276,
        '-100': 32.146299308833186,
        '-99': 32.14630654249823,
        '-98': 32.14625302991643,
        '-97': 32.14621151204275,
        '-96': 32.14627788996675,
        '-95': 32.14640476190447,
        '-94': 32.14642976274847,
        '-93': 32.1462895029363,
        '-92': 32.14611677567695,
        '-91': 32.14606528072831,
        '-90': 32.14610510877524,
        '-89': 32.14608663166804,
        '-88': 32.14597628986742,
        '-87': 32.14591041396332,
        '-86': 32.14599093487547,
        '-85': 32.146138883253606,
        '-84': 32.146220641420605,
        '-83': 32.14623080149524,
        '-82': 32.14624587362689,
        '-81': 32.14626347505396,
        '-80': 32.146235326010164,
        '-79': 32.14623547493834,
        '-78': 32.146396611853746,
        '-77': 32.14661062025806,
        '-76': 32.146511212456026,
        '-75': 32.14597793081132,
        '-74': 32.14554128076739,
        '-73': 32.14595160764408,
        '-72': 32.145903824595955,
        '-71': 32.12964425655852,
        '-70': 32.13059057467714,
        '-69': 32.13315637769394,
        '-68': 32.138304081003746,
        '-67': 32.14290368731887,
        '-66': 32.1428753359858,
        '-65': 32.14348413607711,
        '-64': 32.14491116065896,
        '-63': 32.14543335760464,
        '-62': 32.14582119969183,
        '-61': 32.146140117349894,
        '-60': 32.14648703143353,
        '-59': 32.146638632043185,
        '-58': 32.14685400178309,
        '-57': 32.14702359575728,
        '-56': 32.14695277667151,
        '-55': 32.14674203931866,
        '-54': 32.146586462313245,
        '-53': 32.14654747679025,
        '-52': 32.1464048101376,
        '-51': 32.145981112984934,
        '-50': 32.145468999887406,
        '-49': 32.145247021704705,
        '-48': 32.145414994562124,
        '-47': 32.145690309807804,
        '-46': 32.145786214678694,
        '-45': 32.145730707838474,
        '-44': 32.145726692661235,
        '-43': 32.14582423745748,
        '-42': 32.14588244258537,
        '-41': 32.145817370841755,
        '-40': 32.14573290316676,
        '-39': 32.14576080509665,
        '-38': 32.14587689531996,
        '-37': 32.14595737790116,
        '-36': 32.145963034632395,
        '-35': 32.14597680116197,
        '-34': 32.14606743889401,
        '-33': 32.14619671887854,
        '-32': 32.14628858175857,
        '-31': 32.146333828203396,
        '-30': 32.14638069092137,
        '-29': 32.14645128966511,
        '-28': 32.14651923795944,
        '-27': 32.14656618369205,
        '-26': 32.14661284579044,
        '-25': 32.14667565483031,
        '-24': 32.14672684339153,
        '-23': 32.14673039752707,
        '-22': 32.14670245916393,
        '-21': 32.146698956481515,
        '-20': 32.14673963274248,
        '-19': 32.146776922144824,
        '-18': 32.146757503189285,
        '-17': 32.146689224177614,
        '-16': 32.14661812087968,
        '-15': 32.14655551717399,
        '-14': 32.14647094421614,
        '-13': 32.14636214495302,
        '-12': 32.14628447496295,
        '-11': 32.146276572994836,
        '-10': 32.146288852013,
        '-9': 32.146240211065646,
        '-8': 32.14614006056399,
        '-7': 32.14609154400714,
        '-6': 32.14614610054728,
        '-5': 32.14620808351324,
        '-4': 32.14614390764903,
        '-3': 32.14596503196578,
        '-2': 32.14583101037127,
        '-1': 32.145853905652984
    },
    '-37': {
        '0': 32.14870867217266,
        '1': 32.148747536205,
        '2': 32.148736883131804,
        '3': 32.14876806664115,
        '4': 32.148898219594784,
        '5': 32.1490780972563,
        '6': 32.14921937097977,
        '7': 32.14928514928474,
        '8': 32.14928586207315,
        '9': 32.14922653801213,
        '10': 32.149114355807775,
        '11': 32.14901029792871,
        '12': 32.14900879863718,
        '13': 32.14912724491379,
        '14': 32.149245724836966,
        '15': 32.14921014963573,
        '16': 32.14900829654729,
        '17': 32.14881266918431,
        '18': 32.14881943749724,
        '19': 32.14904539013828,
        '20': 32.1493000718156,
        '21': 32.14936509171612,
        '22': 32.149197052999575,
        '23': 32.14895558443509,
        '24': 32.14884430820558,
        '25': 32.14893176367553,
        '26': 32.149113761425625,
        '27': 32.14922377437265,
        '28': 32.14917331947781,
        '29': 32.14901456990154,
        '30': 32.14889994207305,
        '31': 32.14896895552042,
        '32': 32.149222192697565,
        '33': 32.14948016855443,
        '34': 32.149509014651095,
        '35': 32.149247648183945,
        '36': 32.148908101503835,
        '37': 32.14879105233577,
        '38': 32.14897584894021,
        '39': 32.149241126744286,
        '40': 32.14933390280212,
        '41': 32.14926994612949,
        '42': 32.149286467178314,
        '43': 32.14950945939762,
        '44': 32.14977923975946,
        '45': 32.149869919575295,
        '46': 32.14980336278607,
        '47': 32.14982701832935,
        '48': 32.15008419451031,
        '49': 32.15042097726806,
        '50': 32.150576243553985,
        '51': 32.15048189325525,
        '52': 32.15028365557398,
        '53': 32.15010600898964,
        '54': 32.149915780983264,
        '55': 32.14964486659111,
        '56': 32.14934661977126,
        '57': 32.149148994243944,
        '58': 32.14909783561782,
        '59': 32.14913282323668,
        '60': 32.14920776373614,
        '61': 32.14934567446223,
        '62': 32.149546907842485,
        '63': 32.149719766398654,
        '64': 32.149769888827414,
        '65': 32.149723237027374,
        '66': 32.14968810897209,
        '67': 32.14970203511241,
        '68': 32.14968933559453,
        '69': 32.14959173527891,
        '70': 32.149472293157686,
        '71': 32.149431846381496,
        '72': 32.149462050882974,
        '73': 32.14946081682291,
        '74': 32.14939675007772,
        '75': 32.14938057713395,
        '76': 32.14952399358567,
        '77': 32.14976910844422,
        '78': 32.14992307628809,
        '79': 32.1498611493084,
        '80': 32.14964629155477,
        '81': 32.14943749597578,
        '82': 32.149328081288346,
        '83': 32.149304381211806,
        '84': 32.14931993919855,
        '85': 32.14934571527762,
        '86': 32.14934994562331,
        '87': 32.14929473212739,
        '88': 32.14919099829469,
        '89': 32.1491192864978,
        '90': 32.14914335818694,
        '91': 32.149212857728635,
        '92': 32.149205435858775,
        '93': 32.149079501125286,
        '94': 32.14893698482944,
        '95': 32.1488973213495,
        '96': 32.14894235512701,
        '97': 32.14894377741333,
        '98': 32.1488392288135,
        '99': 32.1487154467803,
        '100': 32.148684037947255,
        '101': 32.148725319677254,
        '102': 32.14871924778331,
        '103': 32.148616322191536,
        '104': 32.148504329417065,
        '105': 32.148479283125916,
        '106': 32.148509167524914,
        '107': 32.148483498559074,
        '108': 32.14836921574803,
        '109': 32.148244183214416,
        '110': 32.14816809569771,
        '111': 32.1480929538056,
        '112': 32.14795093137467,
        '113': 32.147783860774595,
        '114': 32.147707382700645,
        '115': 32.147747134739696,
        '116': 32.147785175886376,
        '117': 32.14770859148557,
        '118': 32.147562688847906,
        '119': 32.14749663578433,
        '120': 32.14757408704301,
        '121': 32.14770264964351,
        '122': 32.14776564553044,
        '123': 32.147767826921495,
        '124': 32.14780746031465,
        '125': 32.147933347479515,
        '126': 32.14808288253798,
        '127': 32.14816202731166,
        '128': 32.14814120162296,
        '129': 32.14805265610164,
        '130': 32.14792597986803,
        '131': 32.14776292238113,
        '132': 32.14757413352803,
        '133': 32.14741894641026,
        '134': 32.14739832089767,
        '135': 32.147598985601654,
        '136': 32.1480176286121,
        '137': 32.14852310920297,
        '138': 32.14892466026568,
        '139': 32.14913017731843,
        '140': 32.149198817053374,
        '141': 32.14838109020068,
        '142': 32.14791010701671,
        '143': 32.147558710956936,
        '144': 32.14742737158899,
        '145': 32.14732040456819,
        '146': 32.1463210271221,
        '147': 32.14171665039553,
        '148': 32.13639170671051,
        '149': 32.14229517545962,
        '150': 32.14952251750684,
        '151': 32.148328509449385,
        '152': 32.14782698911528,
        '153': 32.14826060554791,
        '154': 32.14904544081595,
        '155': 32.149441367340366,
        '156': 32.14928491986592,
        '157': 32.1489799608047,
        '158': 32.14888339213315,
        '159': 32.14891644401409,
        '160': 32.14883483316681,
        '161': 32.14868499756689,
        '162': 32.14877107743166,
        '163': 32.149188462346494,
        '164': 32.14961115296537,
        '165': 32.14967438877577,
        '166': 32.14944982964644,
        '167': 32.14934824985229,
        '168': 32.14955545820683,
        '169': 32.14976610137739,
        '170': 32.14960417897965,
        '171': 32.14918921345069,
        '172': 32.14907911516891,
        '173': 32.14961841029793,
        '174': 32.1504902916548,
        '175': 32.15033794082352,
        '176': 32.15076134455327,
        '177': 32.149903064523755,
        '178': 32.14888413663375,
        '179': 32.148125292713296,
        '180': 32.14792506057988,
        '-180': 32.14792506057988,
        '-179': 32.14841835392058,
        '-178': 32.149389337708854,
        '-177': 32.15021926409102,
        '-176': 32.15031317804521,
        '-175': 32.149700507217254,
        '-174': 32.149059569102235,
        '-173': 32.14899929356767,
        '-172': 32.14940796068808,
        '-171': 32.14967885865461,
        '-170': 32.14951371786911,
        '-169': 32.14924758310826,
        '-168': 32.14930231707908,
        '-167': 32.1495704944821,
        '-166': 32.14959570860326,
        '-165': 32.14926390498435,
        '-164': 32.148995504819815,
        '-163': 32.14915857529895,
        '-162': 32.149546293103896,
        '-161': 32.14966149281922,
        '-160': 32.14939075509563,
        '-159': 32.149116056901406,
        '-158': 32.1491469949495,
        '-157': 32.14931514103055,
        '-156': 32.14928121882128,
        '-155': 32.14905105023896,
        '-154': 32.14893893379756,
        '-153': 32.149076630556145,
        '-152': 32.14922260503329,
        '-151': 32.14913593240171,
        '-150': 32.14893242919023,
        '-149': 32.1489010233706,
        '-148': 32.14907927103577,
        '-147': 32.14921659794827,
        '-146': 32.14914604091539,
        '-145': 32.14899791437744,
        '-144': 32.148973789326185,
        '-143': 32.1490606859896,
        '-142': 32.149102486173064,
        '-141': 32.14906886633736,
        '-140': 32.1490871296142,
        '-139': 32.14920945571899,
        '-138': 32.14930232005033,
        '-137': 32.149236207861804,
        '-136': 32.149081058617554,
        '-135': 32.149010424536634,
        '-134': 32.14906337596915,
        '-133': 32.14911439673835,
        '-132': 32.14907454747563,
        '-131': 32.149011246008804,
        '-130': 32.14902330389347,
        '-129': 32.14908370068939,
        '-128': 32.14909240894318,
        '-127': 32.149045866992346,
        '-126': 32.14905186675703,
        '-125': 32.14915903319305,
        '-124': 32.149257454636086,
        '-123': 32.14921179069013,
        '-122': 32.14905004235989,
        '-121': 32.14894025892414,
        '-120': 32.14897649813535,
        '-119': 32.14906104044186,
        '-118': 32.14904347785763,
        '-117': 32.14893014145898,
        '-116': 32.14888013876949,
        '-115': 32.14898946467011,
        '-114': 32.14915166205716,
        '-113': 32.149186849137585,
        '-112': 32.14907379908835,
        '-111': 32.148976564105894,
        '-110': 32.14902563773155,
        '-109': 32.14914287839043,
        '-108': 32.14914636741044,
        '-107': 32.14899776910585,
        '-106': 32.14886028677975,
        '-105': 32.1488882168456,
        '-104': 32.14903101258093,
        '-103': 32.14911174766221,
        '-102': 32.149063405199676,
        '-101': 32.148998019998295,
        '-100': 32.14902914581534,
        '-99': 32.14911413301781,
        '-98': 32.14913826568863,
        '-97': 32.149101239433215,
        '-96': 32.14911830469395,
        '-95': 32.14922941532925,
        '-94': 32.14930450694102,
        '-93': 32.14920275875313,
        '-92': 32.1489727156855,
        '-91': 32.14880927062878,
        '-90': 32.14881895216939,
        '-89': 32.14890694478536,
        '-88': 32.148923202592506,
        '-87': 32.148856026834274,
        '-86': 32.14882492104667,
        '-85': 32.14891256388471,
        '-84': 32.14906486644428,
        '-83': 32.14915884075779,
        '-82': 32.14912952692895,
        '-81': 32.149026831407305,
        '-80': 32.14897540174366,
        '-79': 32.14906395804144,
        '-78': 32.149224869769675,
        '-77': 32.14924196099174,
        '-76': 32.148981966621335,
        '-75': 32.148663410279816,
        '-74': 32.14877585444989,
        '-73': 32.14821565864853,
        '-72': 32.14756444901632,
        '-71': 32.132526384799995,
        '-70': 32.13201874301922,
        '-69': 32.13622492902859,
        '-68': 32.142282800720864,
        '-67': 32.1462364268359,
        '-66': 32.14632540219825,
        '-65': 32.14559637507469,
        '-64': 32.14784137354067,
        '-63': 32.14805910527216,
        '-62': 32.148225637124106,
        '-61': 32.148077956371786,
        '-60': 32.14795012138791,
        '-59': 32.14859183810543,
        '-58': 32.14954442737724,
        '-57': 32.14985157517011,
        '-56': 32.149685774107226,
        '-55': 32.14930714090964,
        '-54': 32.14899561560253,
        '-53': 32.14887925700967,
        '-52': 32.14880423241189,
        '-51': 32.148614995988176,
        '-50': 32.14842039846766,
        '-49': 32.14844350794123,
        '-48': 32.14868220484023,
        '-47': 32.14888380763898,
        '-46': 32.14886767282817,
        '-45': 32.148732352316316,
        '-44': 32.14867666821483,
        '-43': 32.14872220840388,
        '-42': 32.148726088211056,
        '-41': 32.1486200495117,
        '-40': 32.14850573883121,
        '-39': 32.14849227737628,
        '-38': 32.14854649152744,
        '-37': 32.148571326706715,
        '-36': 32.14856650635125,
        '-35': 32.14862028838984,
        '-34': 32.14875978006821,
        '-33': 32.14890008000712,
        '-32': 32.14896809342621,
        '-31': 32.14900791543098,
        '-30': 32.14910883523672,
        '-29': 32.149266941806886,
        '-28': 32.14938647015249,
        '-27': 32.1494158830416,
        '-26': 32.149417961033734,
        '-25': 32.14947540982417,
        '-24': 32.149572682615805,
        '-23': 32.14962245568797,
        '-22': 32.14959277892181,
        '-21': 32.14955149973435,
        '-20': 32.14957013059623,
        '-19': 32.14962762772421,
        '-18': 32.14964415481174,
        '-17': 32.14958663964333,
        '-16': 32.14949875969607,
        '-15': 32.14943107046191,
        '-14': 32.149385103118476,
        '-13': 32.14934117309893,
        '-12': 32.14930292492148,
        '-11': 32.149280489778675,
        '-10': 32.14925257194419,
        '-9': 32.14919038382515,
        '-8': 32.149115947994716,
        '-7': 32.14908764712973,
        '-6': 32.14910799647995,
        '-5': 32.14908873387399,
        '-4': 32.14895208784123,
        '-3': 32.1487476129173,
        '-2': 32.14861554616567,
        '-1': 32.14862721917915
    },
    '-38': {
        '0': 32.151563340852,
        '1': 32.15162088249814,
        '2': 32.15170238684102,
        '3': 32.15182153749987,
        '4': 32.15193508548171,
        '5': 32.1519744483799,
        '6': 32.15193959024249,
        '7': 32.1519107248641,
        '8': 32.1519407168221,
        '9': 32.15197530594653,
        '10': 32.15192957386686,
        '11': 32.15182874333791,
        '12': 32.15180787131309,
        '13': 32.15194116769473,
        '14': 32.15211533887362,
        '15': 32.152132726666466,
        '16': 32.151942128961174,
        '17': 32.15171605373185,
        '18': 32.151665496801996,
        '19': 32.15180223173786,
        '20': 32.151928917099326,
        '21': 32.1518705742321,
        '22': 32.15168004236628,
        '23': 32.15158577446374,
        '24': 32.15174749698599,
        '25': 32.15209071338643,
        '26': 32.15238413645355,
        '27': 32.15245619509809,
        '28': 32.152326929383044,
        '29': 32.15215692130146,
        '30': 32.15210045873089,
        '31': 32.15219754939579,
        '32': 32.15236053021817,
        '33': 32.152440079177936,
        '34': 32.15233591547696,
        '35': 32.15209094340787,
        '36': 32.15187909296614,
        '37': 32.151860779806555,
        '38': 32.15202962826457,
        '39': 32.152225084328975,
        '40': 32.152314776352995,
        '41': 32.15233564118211,
        '42': 32.152419064876035,
        '43': 32.15260156525206,
        '44': 32.15277872616444,
        '45': 32.15286564793834,
        '46': 32.15293434651655,
        '47': 32.15312039602452,
        '48': 32.15341333699513,
        '49': 32.15362100922261,
        '50': 32.1535716038993,
        '51': 32.153297811765135,
        '52': 32.15297702192029,
        '53': 32.15272910604154,
        '54': 32.152537830592955,
        '55': 32.152356208443436,
        '56': 32.15220322036151,
        '57': 32.152116628548946,
        '58': 32.15207036443147,
        '59': 32.15201025501032,
        '60': 32.15195475252581,
        '61': 32.1519880385101,
        '62': 32.152135806753044,
        '63': 32.15230625151902,
        '64': 32.15239471165925,
        '65': 32.152408350403284,
        '66': 32.15243358792588,
        '67': 32.15249901192274,
        '68': 32.15253673062391,
        '69': 32.15249178258037,
        '70': 32.1524062356727,
        '71': 32.15234954705078,
        '72': 32.15230954191258,
        '73': 32.15222548739702,
        '74': 32.15212440980776,
        '75': 32.152142212707865,
        '76': 32.152357384223166,
        '77': 32.15264552396545,
        '78': 32.15277070928981,
        '79': 32.15262956760872,
        '80': 32.152357087129616,
        '81': 32.152171808119896,
        '82': 32.15215675100883,
        '83': 32.15222558626466,
        '84': 32.15226789699738,
        '85': 32.15226210390094,
        '86': 32.15223995343867,
        '87': 32.15220543011289,
        '88': 32.15214061305193,
        '89': 32.152066247765,
        '90': 32.152035963086675,
        '91': 32.15205773138678,
        '92': 32.15206423232259,
        '93': 32.15199109282254,
        '94': 32.151860380699574,
        '95': 32.15175145546307,
        '96': 32.151700153877954,
        '97': 32.15166795550215,
        '98': 32.15161771707228,
        '99': 32.1515772560306,
        '100': 32.151594428810206,
        '101': 32.15165202380378,
        '102': 32.15167368323171,
        '103': 32.15162070793332,
        '104': 32.151546537097225,
        '105': 32.15153316069891,
        '106': 32.15159528950884,
        '107': 32.151673161432,
        '108': 32.15170578939655,
        '109': 32.15167897113668,
        '110': 32.151605061049814,
        '111': 32.15149624215312,
        '112': 32.15137653129683,
        '113': 32.15128689577251,
        '114': 32.15124088483664,
        '115': 32.15118977345074,
        '116': 32.15107782542406,
        '117': 32.15094002993118,
        '118': 32.15089882097658,
        '119': 32.15102675144102,
        '120': 32.15123549419725,
        '121': 32.15134991934724,
        '122': 32.151299212070775,
        '123': 32.15119690010245,
        '124': 32.15121028154973,
        '125': 32.151376409798,
        '126': 32.151576553597934,
        '127': 32.151676634060216,
        '128': 32.151652370171824,
        '129': 32.15156956224644,
        '130': 32.15147974290154,
        '131': 32.151369500296454,
        '132': 32.151205249689,
        '133': 32.15099969906071,
        '134': 32.150821927726085,
        '135': 32.15074584625429,
        '136': 32.15078860579156,
        '137': 32.15089897161253,
        '138': 32.15102029095961,
        '139': 32.15117684466458,
        '140': 32.151472499959404,
        '141': 32.151629709375364,
        '142': 32.15138414188401,
        '143': 32.15120034126436,
        '144': 32.150963656597135,
        '145': 32.15230803963518,
        '146': 32.14946405901867,
        '147': 32.152267671153936,
        '148': 32.15271302309612,
        '149': 32.152456352535594,
        '150': 32.15169090923654,
        '151': 32.15096029614247,
        '152': 32.15084431357754,
        '153': 32.15135353320774,
        '154': 32.15193877808077,
        '155': 32.152096510729265,
        '156': 32.1518574993196,
        '157': 32.15162283481902,
        '158': 32.15162480967346,
        '159': 32.1517091940961,
        '160': 32.151654854311914,
        '161': 32.15153184154564,
        '162': 32.15159570750805,
        '163': 32.15189321065701,
        '164': 32.15216536187704,
        '165': 32.15220878368084,
        '166': 32.15218695458078,
        '167': 32.15241575076591,
        '168': 32.152873129541646,
        '169': 32.15311583868509,
        '170': 32.15279505819508,
        '171': 32.152158446936625,
        '172': 32.15187659806348,
        '173': 32.15233884876665,
        '174': 32.1532143393878,
        '175': 32.151682717343405,
        '176': 32.14797124193252,
        '177': 32.15126460779071,
        '178': 32.14278067847625,
        '179': 32.151259045548684,
        '180': 32.15149642084344,
        '-180': 32.15149642084344,
        '-179': 32.15225052290241,
        '-178': 32.15303957711425,
        '-177': 32.153329356369454,
        '-176': 32.15293141662302,
        '-175': 32.15221137937699,
        '-174': 32.15178379286408,
        '-173': 32.151913037147004,
        '-172': 32.15228167258497,
        '-171': 32.15242192114243,
        '-170': 32.15228098933506,
        '-169': 32.15220234962046,
        '-168': 32.152378521873906,
        '-167': 32.15255077805629,
        '-166': 32.15238495351452,
        '-165': 32.151991263620786,
        '-164': 32.15183092265231,
        '-163': 32.152108222790716,
        '-162': 32.15248747852347,
        '-161': 32.15253621175871,
        '-160': 32.1522832671431,
        '-159': 32.152128154982016,
        '-158': 32.15225823538733,
        '-157': 32.15240809009809,
        '-156': 32.152277173226906,
        '-155': 32.151976213059655,
        '-154': 32.1518599413157,
        '-153': 32.15200970189583,
        '-152': 32.15212456005512,
        '-151': 32.15196724297342,
        '-150': 32.151709746063396,
        '-149': 32.15168845668903,
        '-148': 32.151934145624956,
        '-147': 32.152140171268194,
        '-146': 32.15208272457584,
        '-145': 32.15188573453574,
        '-144': 32.15179734149504,
        '-143': 32.15185025026183,
        '-142': 32.151884813356084,
        '-141': 32.151836687327645,
        '-140': 32.15182648466014,
        '-139': 32.151938584046164,
        '-138': 32.15205875716201,
        '-137': 32.15203042338372,
        '-136': 32.15189083370601,
        '-135': 32.15182786299188,
        '-134': 32.15191911745698,
        '-133': 32.15203320726275,
        '-132': 32.1520255260332,
        '-131': 32.15193342481395,
        '-130': 32.151896031078,
        '-129': 32.15193830834145,
        '-128': 32.15194660054298,
        '-127': 32.151862531247005,
        '-126': 32.15179251036028,
        '-125': 32.151856817043964,
        '-124': 32.15199391912565,
        '-123': 32.15201666199777,
        '-122': 32.151862545165905,
        '-121': 32.15169347295122,
        '-120': 32.151694570811046,
        '-119': 32.15183540640257,
        '-118': 32.151916458844696,
        '-117': 32.15183973397342,
        '-116': 32.15173776506788,
        '-115': 32.151787684029564,
        '-114': 32.151965443972315,
        '-113': 32.15207512234084,
        '-112': 32.15201072853422,
        '-111': 32.151890887534265,
        '-110': 32.15188511194574,
        '-109': 32.1519770316565,
        '-108': 32.15199460679416,
        '-107': 32.15186247034279,
        '-106': 32.15171934212911,
        '-105': 32.151731622085165,
        '-104': 32.15186208327227,
        '-103': 32.151920003595855,
        '-102': 32.15182694884821,
        '-101': 32.15172819438013,
        '-100': 32.15179242592581,
        '-99': 32.151976998300825,
        '-98': 32.152084413810044,
        '-97': 32.152029775341596,
        '-96': 32.151947084974076,
        '-95': 32.151990317695116,
        '-94': 32.152110537502864,
        '-93': 32.15212023813696,
        '-92': 32.15195532050902,
        '-91': 32.15177347712165,
        '-90': 32.15175402105455,
        '-89': 32.1518683523018,
        '-88': 32.151924181832015,
        '-87': 32.15181961001439,
        '-86': 32.15167398408843,
        '-85': 32.15167277376516,
        '-84': 32.15183125016471,
        '-83': 32.151976051960396,
        '-82': 32.15196578988224,
        '-81': 32.15187549061833,
        '-80': 32.15190613788894,
        '-79': 32.15210611246804,
        '-78': 32.15224068765083,
        '-77': 32.15202178939496,
        '-76': 32.15150097401409,
        '-75': 32.1511665399761,
        '-74': 32.15152808358006,
        '-73': 32.151414183889905,
        '-72': 32.14987700444182,
        '-71': 32.13340802872562,
        '-70': 32.14135807309105,
        '-69': 32.15001864254845,
        '-68': 32.14863286353518,
        '-67': 32.14868909817984,
        '-66': 32.14942570319463,
        '-65': 32.14940915275798,
        '-64': 32.150620323738515,
        '-63': 32.149948107800725,
        '-62': 32.147392736888854,
        '-61': 32.15016604487885,
        '-60': 32.149998890851606,
        '-59': 32.15088984131187,
        '-58': 32.15138365375191,
        '-57': 32.15270353289473,
        '-56': 32.15257487745401,
        '-55': 32.15211240089672,
        '-54': 32.15165740548375,
        '-53': 32.15144742376832,
        '-52': 32.15142031523892,
        '-51': 32.15141613272005,
        '-50': 32.151426840077555,
        '-49': 32.15154466977432,
        '-48': 32.15174503742059,
        '-47': 32.15186574349622,
        '-46': 32.15181434687297,
        '-45': 32.1516864805021,
        '-44': 32.151624784787614,
        '-43': 32.15163514506264,
        '-42': 32.15161763024092,
        '-41': 32.15153503969522,
        '-40': 32.15145620888712,
        '-39': 32.15143160907843,
        '-38': 32.15141765556733,
        '-37': 32.15136940746903,
        '-36': 32.151342544023606,
        '-35': 32.15142771387402,
        '-34': 32.151607172963985,
        '-33': 32.151757042826176,
        '-32': 32.151807548215494,
        '-31': 32.15183361533525,
        '-30': 32.15194161024348,
        '-29': 32.15211176155757,
        '-28': 32.1522210844272,
        '-27': 32.15221392465664,
        '-26': 32.15217867394948,
        '-25': 32.15222499987,
        '-24': 32.15233635811977,
        '-23': 32.15240096718129,
        '-22': 32.15237100560299,
        '-21': 32.15232715825634,
        '-20': 32.15236624909253,
        '-19': 32.15247263076213,
        '-18': 32.152543212750814,
        '-17': 32.15251870892169,
        '-16': 32.152442294765926,
        '-15': 32.15238593998437,
        '-14': 32.15236302087177,
        '-13': 32.15233815098426,
        '-12': 32.15229385111268,
        '-11': 32.152249865181524,
        '-10': 32.15222414122539,
        '-9': 32.1522094867919,
        '-8': 32.15219393250394,
        '-7': 32.15217341944143,
        '-6': 32.15212983956443,
        '-5': 32.15202534799975,
        '-4': 32.15184938057271,
        '-3': 32.15165925719802,
        '-2': 32.1515402426878,
        '-1': 32.15152222951589
    },
    '-39': {
        '0': 32.15439994376086,
        '1': 32.15444778651355,
        '2': 32.15457358510682,
        '3': 32.15473402787282,
        '4': 32.154818711369316,
        '5': 32.1547501032087,
        '6': 32.15459083758978,
        '7': 32.154507818617134,
        '8': 32.15460205468033,
        '9': 32.15479747191983,
        '10': 32.154931749928345,
        '11': 32.15494396382052,
        '12': 32.15492593941513,
        '13': 32.15497738785224,
        '14': 32.15506138356385,
        '15': 32.15505598016209,
        '16': 32.154933636338235,
        '17': 32.15482103938835,
        '18': 32.15484261570375,
        '19': 32.15494270613288,
        '20': 32.154930822791,
        '21': 32.154722350880114,
        '22': 32.15448869728022,
        '23': 32.15451522967653,
        '24': 32.154902078477186,
        '25': 32.15543327736278,
        '26': 32.15576090941944,
        '27': 32.15571399557147,
        '28': 32.155425325719506,
        '29': 32.155177401577035,
        '30': 32.155146223825966,
        '31': 32.15528177717934,
        '32': 32.15539496967843,
        '33': 32.155335398177584,
        '34': 32.15510600133043,
        '35': 32.154845871073384,
        '36': 32.15471471890921,
        '37': 32.15477250785229,
        '38': 32.15494930095221,
        '39': 32.15512996753291,
        '40': 32.155268872722154,
        '41': 32.15541079007223,
        '42': 32.15559777671633,
        '43': 32.15578542845286,
        '44': 32.155887069086326,
        '45': 32.15589393267279,
        '46': 32.15590262220016,
        '47': 32.15599336670929,
        '48': 32.15611029672359,
        '49': 32.15611064299748,
        '50': 32.15593304596339,
        '51': 32.15567316795246,
        '52': 32.15547349619761,
        '53': 32.15537518640498,
        '54': 32.15531479068624,
        '55': 32.155240240846624,
        '56': 32.15516960289404,
        '57': 32.15512643738102,
        '58': 32.15507691634414,
        '59': 32.15497976460081,
        '60': 32.154872470820635,
        '61': 32.15484913102434,
        '62': 32.154944784126755,
        '63': 32.15508776288077,
        '64': 32.155190054292916,
        '65': 32.155247833548415,
        '66': 32.15531380232905,
        '67': 32.15539434909056,
        '68': 32.15543098138221,
        '69': 32.15538968008934,
        '70': 32.15531341387126,
        '71': 32.15525205331186,
        '72': 32.15518293018002,
        '73': 32.155063433142026,
        '74': 32.15495219328474,
        '75': 32.15499892135536,
        '76': 32.15526434540307,
        '77': 32.155589651538556,
        '78': 32.15571614351211,
        '79': 32.15554840462518,
        '80': 32.15525466864647,
        '81': 32.15508219404924,
        '82': 32.1551068250008,
        '83': 32.155200852558295,
        '84': 32.15522152731555,
        '85': 32.15516659165376,
        '86': 32.15512526639876,
        '87': 32.15513105163633,
        '88': 32.15512401600517,
        '89': 32.15505256799682,
        '90': 32.15495567206086,
        '91': 32.15491287678517,
        '92': 32.15493917668191,
        '93': 32.15496725590477,
        '94': 32.15493131132536,
        '95': 32.154837176541776,
        '96': 32.15473916753916,
        '97': 32.15467193315636,
        '98': 32.15462713842345,
        '99': 32.154586259131484,
        '100': 32.15454920464717,
        '101': 32.15452325883189,
        '102': 32.154500853722496,
        '103': 32.15446370170033,
        '104': 32.154407256652895,
        '105': 32.154353618776106,
        '106': 32.154338665913244,
        '107': 32.15438426400219,
        '108': 32.154474152965946,
        '109': 32.15455362853232,
        '110': 32.15456701579657,
        '111': 32.154512741982344,
        '112': 32.15445630607014,
        '113': 32.15446522298234,
        '114': 32.15452357532981,
        '115': 32.154535188262074,
        '116': 32.154435900723115,
        '117': 32.15428998533445,
        '118': 32.15423826436012,
        '119': 32.15433851733979,
        '120': 32.1544864140081,
        '121': 32.15452335660967,
        '122': 32.15441057791963,
        '123': 32.15426331991843,
        '124': 32.154210151030135,
        '125': 32.15425393591567,
        '126': 32.154295788072766,
        '127': 32.15427248784335,
        '128': 32.15422795155469,
        '129': 32.15424239304723,
        '130': 32.15432436818787,
        '131': 32.154399157933156,
        '132': 32.15439258511006,
        '133': 32.154301192833245,
        '134': 32.15417729144365,
        '135': 32.15406373134284,
        '136': 32.15395648050216,
        '137': 32.15383045158039,
        '138': 32.15370120803771,
        '139': 32.153660532326334,
        '140': 32.15383125906608,
        '141': 32.15424949119382,
        '142': 32.15477588680558,
        '143': 32.15515755757624,
        '144': 32.15522854496245,
        '145': 32.15505804426317,
        '146': 32.15486554214168,
        '147': 32.15477303388277,
        '148': 32.15468204680856,
        '149': 32.15444166019295,
        '150': 32.15411157228964,
        '151': 32.15397005965116,
        '152': 32.15421041636696,
        '153': 32.1546776243528,
        '154': 32.15498914015397,
        '155': 32.154927934365325,
        '156': 32.15465122793797,
        '157': 32.15447484541123,
        '158': 32.1545105874483,
        '159': 32.15459617579185,
        '160': 32.154562823934384,
        '161': 32.15445863212316,
        '162': 32.15444383606989,
        '163': 32.15454281917372,
        '164': 32.15462676917243,
        '165': 32.15465209031911,
        '166': 32.15478719291967,
        '167': 32.155192257380584,
        '168': 32.155716943992296,
        '169': 32.15595730899669,
        '170': 32.1556807578049,
        '171': 32.15512906927221,
        '172': 32.15481070078725,
        '173': 32.15499342075727,
        '174': 32.15545969461645,
        '175': 32.153213556556594,
        '176': 32.14967441781605,
        '177': 32.15331879828868,
        '178': 32.155076978745086,
        '179': 32.15510795116331,
        '180': 32.15544582000391,
        '-180': 32.15544582000391,
        '-179': 32.15589356972745,
        '-178': 32.15614282951113,
        '-177': 32.155958993108726,
        '-176': 32.15539363249522,
        '-175': 32.15480319203278,
        '-174': 32.15455446018133,
        '-173': 32.15467465040387,
        '-172': 32.15486044880365,
        '-171': 32.1548683679286,
        '-170': 32.154806558037826,
        '-169': 32.154941616886454,
        '-168': 32.155274433087655,
        '-167': 32.15547681307965,
        '-166': 32.15530760550985,
        '-165': 32.154954052222514,
        '-164': 32.15481542294749,
        '-163': 32.1550018468933,
        '-162': 32.155223057411824,
        '-161': 32.155209954830525,
        '-160': 32.155075277838314,
        '-159': 32.155115361985445,
        '-158': 32.155351860198806,
        '-157': 32.155479509148876,
        '-156': 32.15529884561056,
        '-155': 32.155011469736486,
        '-154': 32.15494731418288,
        '-153': 32.1551055694859,
        '-152': 32.15516062790132,
        '-151': 32.15492583018242,
        '-150': 32.15462223307274,
        '-149': 32.154580692511935,
        '-148': 32.15480047202403,
        '-147': 32.154970151113346,
        '-146': 32.15489476402332,
        '-145': 32.154724948101595,
        '-144': 32.15470172825121,
        '-143': 32.15481674923729,
        '-142': 32.154863750894116,
        '-141': 32.15476461599839,
        '-140': 32.154676644308076,
        '-139': 32.154742544383204,
        '-138': 32.15487238339047,
        '-137': 32.15487917876109,
        '-136': 32.15475832642646,
        '-135': 32.15469694951101,
        '-134': 32.15480332415332,
        '-133': 32.154951989214,
        '-132': 32.15496362248492,
        '-131': 32.15485405574612,
        '-130': 32.154797719991855,
        '-129': 32.15486986049516,
        '-128': 32.154944158118425,
        '-127': 32.154886949197326,
        '-126': 32.15475866006636,
        '-125': 32.15472743390901,
        '-124': 32.154819199301045,
        '-123': 32.154867758849775,
        '-122': 32.154746151576624,
        '-121': 32.15455766898078,
        '-120': 32.154512408356844,
        '-119': 32.1546500741206,
        '-118': 32.1547865598051,
        '-117': 32.15475843158928,
        '-116': 32.15463451698276,
        '-115': 32.15461311957388,
        '-114': 32.15475287219177,
        '-113': 32.15490248363693,
        '-112': 32.15491053885367,
        '-111': 32.154815541777744,
        '-110': 32.154763328830434,
        '-109': 32.15479090267804,
        '-108': 32.15479324962751,
        '-107': 32.154710042825634,
        '-106': 32.154642872367916,
        '-105': 32.15471436977453,
        '-104': 32.154866189848626,
        '-103': 32.15490387874362,
        '-102': 32.154756043093876,
        '-101': 32.15459543690885,
        '-100': 32.15463489795925,
        '-99': 32.15485327854757,
        '-98': 32.15501703362238,
        '-97': 32.154975972751174,
        '-96': 32.15484562062399,
        '-95': 32.15484075065502,
        '-94': 32.154989111858754,
        '-93': 32.15510518236766,
        '-92': 32.15504609606729,
        '-91': 32.15489695180946,
        '-90': 32.154841378951126,
        '-89': 32.15490442655142,
        '-88': 32.1549217940104,
        '-87': 32.154775498132025,
        '-86': 32.15457233883335,
        '-85': 32.15451674144701,
        '-84': 32.15464167938839,
        '-83': 32.15476151761739,
        '-82': 32.15472576977959,
        '-81': 32.15464400469836,
        '-80': 32.15475757751457,
        '-79': 32.15508002419191,
        '-78': 32.15526989861239,
        '-77': 32.15497494729079,
        '-76': 32.15430826557237,
        '-75': 32.15387518379131,
        '-74': 32.15421877937029,
        '-73': 32.15438169099313,
        '-72': 32.15084523446026,
        '-71': 32.14224922549765,
        '-70': 32.14494800934045,
        '-69': 32.15104484628135,
        '-68': 32.15190301297821,
        '-67': 32.15133423830104,
        '-66': 32.15265039957877,
        '-65': 32.1531100751977,
        '-64': 32.15398352381746,
        '-63': 32.15426497770424,
        '-62': 32.154846140087535,
        '-61': 32.154751916315206,
        '-60': 32.15473316118505,
        '-59': 32.15499539927705,
        '-58': 32.15547706266397,
        '-57': 32.15580875991885,
        '-56': 32.15567133835397,
        '-55': 32.155138221840204,
        '-54': 32.154586394021095,
        '-53': 32.1543043417779,
        '-52': 32.15427052537521,
        '-51': 32.154305017015304,
        '-50': 32.154320425913085,
        '-49': 32.154353393148476,
        '-48': 32.15442133121758,
        '-47': 32.1544636177002,
        '-46': 32.154436148003896,
        '-45': 32.15438182393033,
        '-44': 32.15436388595338,
        '-43': 32.15437830968218,
        '-42': 32.154382849735455,
        '-41': 32.154381605688826,
        '-40': 32.15441750441849,
        '-39': 32.15448482438701,
        '-38': 32.15451451780153,
        '-37': 32.154474364644244,
        '-36': 32.15443478797604,
        '-35': 32.15448620378611,
        '-34': 32.15461544602224,
        '-33': 32.15472194708279,
        '-32': 32.15475717555757,
        '-31': 32.154788384900414,
        '-30': 32.15489283872986,
        '-29': 32.155034203101735,
        '-28': 32.155102203483374,
        '-27': 32.15506395385613,
        '-26': 32.15501367556427,
        '-25': 32.155048652568794,
        '-24': 32.15514263570792,
        '-23': 32.155190254677585,
        '-22': 32.15515774208242,
        '-21': 32.15513187386274,
        '-20': 32.15520403678094,
        '-19': 32.15534981922741,
        '-18': 32.15546072436791,
        '-17': 32.155474351872115,
        '-16': 32.15542935213202,
        '-15': 32.15539097747999,
        '-14': 32.15536646177794,
        '-13': 32.15532079784293,
        '-12': 32.15525025798588,
        '-11': 32.15519980454379,
        '-10': 32.15520463832538,
        '-9': 32.155242215263584,
        '-8': 32.15525437191371,
        '-7': 32.15520059301534,
        '-6': 32.15507884709792,
        '-5': 32.15491156575407,
        '-4': 32.15473190318401,
        '-3': 32.15457661605665,
        '-2': 32.1544701618654,
        '-1': 32.15441292848314
    },
    '-40': {
        '0': 32.157297746041564,
        '1': 32.15731080434001,
        '2': 32.157392407976964,
        '3': 32.157504028253555,
        '4': 32.15755874246993,
        '5': 32.15750139414261,
        '6': 32.1573892588885,
        '7': 32.1573619595778,
        '8': 32.15750591652043,
        '9': 32.157758740013215,
        '10': 32.1579703685282,
        '11': 32.158053888846574,
        '12': 32.1580493809656,
        '13': 32.15803705291135,
        '14': 32.1580283258016,
        '15': 32.15798092137382,
        '16': 32.1579030370083,
        '17': 32.15787986986705,
        '18': 32.1579608573183,
        '19': 32.158054480003685,
        '20': 32.158001188770946,
        '21': 32.15777648218953,
        '22': 32.15758328375483,
        '23': 32.15768607235152,
        '24': 32.158132892428576,
        '25': 32.15866250985081,
        '26': 32.15891403600983,
        '27': 32.15873984094751,
        '28': 32.158318202426585,
        '29': 32.15797260869662,
        '30': 32.157890500788795,
        '31': 32.15800264807531,
        '32': 32.15809943208816,
        '33': 32.15803896048232,
        '34': 32.157853081162514,
        '35': 32.15768578333149,
        '36': 32.15765291764155,
        '37': 32.15775893263089,
        '38': 32.15793043911929,
        '39': 32.15811068786996,
        '40': 32.15830742057823,
        '41': 32.158545788356186,
        '42': 32.15879061158683,
        '43': 32.158944465369714,
        '44': 32.15893960385172,
        '45': 32.15881789225571,
        '46': 32.15869265718639,
        '47': 32.15862797353089,
        '48': 32.158581354318244,
        '49': 32.15848181613772,
        '50': 32.15834036395156,
        '51': 32.15824470373985,
        '52': 32.1582434710438,
        '53': 32.15827600581919,
        '54': 32.15824477535676,
        '55': 32.15813538518572,
        '56': 32.15802620802551,
        '57': 32.15798164867975,
        '58': 32.15797782703536,
        '59': 32.157954724546585,
        '60': 32.15790947255871,
        '61': 32.1578971790046,
        '62': 32.157948092700714,
        '63': 32.15802853492604,
        '64': 32.15809805678593,
        '65': 32.158167107491195,
        '66': 32.15826313985711,
        '67': 32.15836033699159,
        '68': 32.15839109080305,
        '69': 32.15833392943307,
        '70': 32.15824725790025,
        '71': 32.15818954139318,
        '72': 32.15814019128727,
        '73': 32.158048709982396,
        '74': 32.15795215905978,
        '75': 32.15797780878096,
        '76': 32.15818832599631,
        '77': 32.15845901514165,
        '78': 32.15857176626918,
        '79': 32.15844354805255,
        '80': 32.158219355073705,
        '81': 32.15810998392203,
        '82': 32.1581650176391,
        '83': 32.158247393805475,
        '84': 32.15822254964589,
        '85': 32.15811751811599,
        '86': 32.15806309734907,
        '87': 32.15811373386366,
        '88': 32.15818048162781,
        '89': 32.15815039632695,
        '90': 32.158028491599154,
        '91': 32.15792576091805,
        '92': 32.157921612492565,
        '93': 32.15797765450455,
        '94': 32.15799783146867,
        '95': 32.157944216777295,
        '96': 32.1578646314833,
        '97': 32.15781354945244,
        '98': 32.157778019877995,
        '99': 32.15770023869767,
        '100': 32.157560975171194,
        '101': 32.15741593000004,
        '102': 32.15733877230575,
        '103': 32.157337428848315,
        '104': 32.157342196355124,
        '105': 32.1572827122872,
        '106': 32.15716898903368,
        '107': 32.157083461430425,
        '108': 32.1570889668011,
        '109': 32.15715583601004,
        '110': 32.15719513658702,
        '111': 32.157163544778186,
        '112': 32.15711600152484,
        '113': 32.157138710366574,
        '114': 32.15723982233547,
        '115': 32.1573322770338,
        '116': 32.15733320587793,
        '117': 32.1572583210947,
        '118': 32.15719481440433,
        '119': 32.15719089204731,
        '120': 32.15720526979183,
        '121': 32.15717692516334,
        '122': 32.15711425416271,
        '123': 32.15707993573337,
        '124': 32.15709387676719,
        '125': 32.157092197947556,
        '126': 32.1570063169635,
        '127': 32.15686195652328,
        '128': 32.15676591545511,
        '129': 32.1567920506399,
        '130': 32.15690573599354,
        '131': 32.157013300113015,
        '132': 32.15706681203736,
        '133': 32.157098107842685,
        '134': 32.157156349869915,
        '135': 32.157236744222295,
        '136': 32.1572790118314,
        '137': 32.157222319871316,
        '138': 32.15705569146202,
        '139': 32.15683361385358,
        '140': 32.15666275214017,
        '141': 32.15666239108066,
        '142': 32.15689912885721,
        '143': 32.15732865686776,
        '144': 32.15662369169576,
        '145': 32.15812171413113,
        '146': 32.15817332677491,
        '147': 32.15793985124555,
        '148': 32.15678535986836,
        '149': 32.15711408304964,
        '150': 32.15691535706376,
        '151': 32.15703725004225,
        '152': 32.15739565369404,
        '153': 32.15774617476035,
        '154': 32.15786848772409,
        '155': 32.15774889858904,
        '156': 32.15757062201155,
        '157': 32.157512595775096,
        '158': 32.15757495382196,
        '159': 32.1576197984587,
        '160': 32.15755895883118,
        '161': 32.15745190036048,
        '162': 32.15740891297289,
        '163': 32.15745004722843,
        '164': 32.15751156475093,
        '165': 32.157572917237445,
        '166': 32.157706091842726,
        '167': 32.15796373620551,
        '168': 32.15826047334359,
        '169': 32.15842507451766,
        '170': 32.15837060897081,
        '171': 32.15816586764214,
        '172': 32.157926151943194,
        '173': 32.15768753872516,
        '174': 32.1574396199206,
        '175': 32.157250479750125,
        '176': 32.15095497719733,
        '177': 32.1576073080902,
        '178': 32.15813293908879,
        '179': 32.1586165887985,
        '180': 32.15888065307847,
        '-180': 32.15888065307847,
        '-179': 32.15889155553327,
        '-178': 32.15869253485709,
        '-177': 32.15833812156962,
        '-176': 32.15792621335143,
        '-175': 32.157616197832404,
        '-174': 32.157515923169115,
        '-173': 32.15755232263415,
        '-172': 32.157541337174194,
        '-171': 32.15742577065354,
        '-170': 32.15737514450902,
        '-169': 32.157574673371556,
        '-168': 32.15795122090374,
        '-167': 32.158211720777885,
        '-166': 32.158175809582836,
        '-165': 32.15797864436143,
        '-164': 32.15787756787331,
        '-163': 32.157927983427676,
        '-162': 32.157966542036434,
        '-161': 32.157901305221294,
        '-160': 32.157872366158195,
        '-159': 32.158039135634155,
        '-158': 32.158304725479056,
        '-157': 32.158401197840654,
        '-156': 32.15824637158061,
        '-155': 32.15807017478867,
        '-154': 32.158108287341605,
        '-153': 32.158272665101535,
        '-152': 32.15826229008453,
        '-151': 32.15797411230304,
        '-150': 32.157652196122456,
        '-149': 32.157570896735635,
        '-148': 32.15768960204821,
        '-147': 32.157745965964445,
        '-146': 32.157637742810344,
        '-145': 32.15755153866074,
        '-144': 32.157670575040626,
        '-143': 32.15789014834175,
        '-142': 32.15794772121072,
        '-141': 32.15778136212773,
        '-140': 32.15761234045458,
        '-139': 32.15764239320097,
        '-138': 32.1577919331836,
        '-137': 32.157832771229074,
        '-136': 32.15771115628594,
        '-135': 32.15760681732151,
        '-134': 32.15766360133455,
        '-133': 32.15778677774737,
        '-132': 32.15778946704455,
        '-131': 32.15766793221641,
        '-130': 32.15761069182615,
        '-129': 32.15773183877653,
        '-128': 32.15790713772483,
        '-127': 32.157940414185816,
        '-126': 32.15782364978402,
        '-125': 32.15773119756462,
        '-124': 32.15776682069648,
        '-123': 32.15782301813215,
        '-122': 32.15774512164718,
        '-121': 32.157563493790036,
        '-120': 32.157466444170744,
        '-119': 32.157550838635196,
        '-118': 32.157693631142116,
        '-117': 32.15771668888217,
        '-116': 32.157616371522636,
        '-115': 32.157551700581394,
        '-114': 32.15762258686667,
        '-113': 32.157745988556705,
        '-112': 32.15778303283421,
        '-111': 32.157721308846064,
        '-110': 32.15766274178816,
        '-109': 32.15766144063644,
        '-108': 32.15766261099366,
        '-107': 32.15762368139973,
        '-106': 32.15761506035209,
        '-105': 32.157724474989095,
        '-104': 32.15789211875138,
        '-103': 32.15793632695796,
        '-102': 32.15777632000165,
        '-101': 32.15756322116684,
        '-100': 32.157522262301214,
        '-99': 32.157688449189685,
        '-98': 32.15787281628693,
        '-97': 32.15790334163125,
        '-96': 32.15782800215186,
        '-95': 32.15782404139192,
        '-94': 32.157948689871084,
        '-93': 32.15806681090698,
        '-92': 32.15803994476036,
        '-91': 32.157908798205625,
        '-90': 32.15782330441346,
        '-89': 32.15782867813389,
        '-88': 32.15781135952064,
        '-87': 32.15767984101623,
        '-86': 32.157520984466515,
        '-85': 32.15749815574971,
        '-84': 32.15761812295301,
        '-83': 32.1576975870911,
        '-82': 32.15760841820572,
        '-81': 32.15748688258896,
        '-80': 32.157592766515386,
        '-79': 32.157946252993575,
        '-78': 32.1582060847803,
        '-77': 32.158013919568674,
        '-76': 32.15745445792453,
        '-75': 32.157072429011436,
        '-74': 32.15735395000294,
        '-73': 32.15679235301734,
        '-72': 32.146788802203034,
        '-71': 32.149216867711466,
        '-70': 32.15302437716621,
        '-69': 32.1501757276267,
        '-68': 32.151090928305095,
        '-67': 32.15596092046831,
        '-66': 32.15587388544068,
        '-65': 32.156561818907214,
        '-64': 32.15691800944272,
        '-63': 32.15734048016925,
        '-62': 32.157793707417525,
        '-61': 32.157902109506864,
        '-60': 32.15805702887428,
        '-59': 32.158349040577306,
        '-58': 32.1586801378026,
        '-57': 32.15876816962058,
        '-56': 32.158434112324045,
        '-55': 32.15783041378002,
        '-54': 32.157317635676165,
        '-53': 32.157124836886524,
        '-52': 32.157181392028455,
        '-51': 32.15727073562682,
        '-50': 32.15727343732699,
        '-49': 32.15722353463534,
        '-48': 32.15718739824122,
        '-47': 32.15717029150013,
        '-46': 32.157145695886875,
        '-45': 32.15711204153549,
        '-44': 32.15708454749474,
        '-43': 32.157064161084136,
        '-42': 32.15705360521542,
        '-41': 32.157086820604384,
        '-40': 32.15719783550543,
        '-39': 32.15735630796476,
        '-38': 32.15747272181676,
        '-37': 32.15748890833178,
        '-36': 32.157440809405145,
        '-35': 32.15740957054377,
        '-34': 32.157430173134536,
        '-33': 32.15747999567801,
        '-32': 32.15754554805455,
        '-31': 32.15765162687748,
        '-30': 32.1578041541817,
        '-29': 32.157942169090575,
        '-28': 32.15799223954627,
        '-27': 32.15796451714747,
        '-26': 32.15795184730018,
        '-25': 32.15801788077271,
        '-24': 32.15811795263806,
        '-23': 32.15816043050315,
        '-22': 32.15812887482687,
        '-21': 32.158104005140686,
        '-20': 32.15816012973103,
        '-19': 32.15827514407617,
        '-18': 32.15836552527567,
        '-17': 32.158387234964565,
        '-16': 32.15836769008447,
        '-15': 32.15834374357158,
        '-14': 32.158308434756684,
        '-13': 32.15824016699502,
        '-12': 32.15815978669231,
        '-11': 32.15812282538935,
        '-10': 32.1581519412616,
        '-9': 32.158200719886885,
        '-8': 32.15819635086296,
        '-7': 32.15810643398534,
        '-6': 32.1579552813995,
        '-5': 32.15778818551117,
        '-4': 32.157636434104646,
        '-3': 32.15751147023255,
        '-2': 32.157413362787466,
        '-1': 32.157339642167
    },
    '-41': {
        '0': 32.16042334494585,
        '1': 32.16043672331436,
        '2': 32.160429988200555,
        '3': 32.16040768832132,
        '4': 32.16038562200948,
        '5': 32.16037687145329,
        '6': 32.16040085350596,
        '7': 32.16048495228479,
        '8': 32.160634918208835,
        '9': 32.16080776595836,
        '10': 32.16093608439111,
        '11': 32.16098818741666,
        '12': 32.160991464962954,
        '13': 32.16098703956742,
        '14': 32.160976145313526,
        '15': 32.16093279894594,
        '16': 32.16086431897545,
        '17': 32.1608264077599,
        '18': 32.160855530882564,
        '19': 32.16090354105229,
        '20': 32.160878991405475,
        '21': 32.160770431364824,
        '22': 32.16070425694546,
        '23': 32.16084076361913,
        '24': 32.16119628368882,
        '25': 32.161582698974534,
        '26': 32.16174646393603,
        '27': 32.16158112658477,
        '28': 32.16120941622389,
        '29': 32.160864896202845,
        '30': 32.160698929439555,
        '31': 32.160693033978035,
        '32': 32.16073219636051,
        '33': 32.160737988138756,
        '34': 32.160727217497,
        '35': 32.16076143645243,
        '36': 32.1608649489483,
        '37': 32.16100371231745,
        '38': 32.16113647386406,
        '39': 32.16126730238151,
        '40': 32.16143177030131,
        '41': 32.16163111970007,
        '42': 32.16179722704182,
        '43': 32.16184300126782,
        '44': 32.1617517585277,
        '45': 32.161602511154776,
        '46': 32.161494273508126,
        '47': 32.16145282022466,
        '48': 32.161428943237105,
        '49': 32.1613852808239,
        '50': 32.16135460218867,
        '51': 32.16138805457238,
        '52': 32.16146051430439,
        '53': 32.16146768297828,
        '54': 32.16133679472185,
        '55': 32.16111978025227,
        '56': 32.16094878308257,
        '57': 32.16090259369324,
        '58': 32.16094147537551,
        '59': 32.16097630009555,
        '60': 32.160970947409574,
        '61': 32.160953963765515,
        '62': 32.16095250716875,
        '63': 32.16095925986394,
        '64': 32.16097218590889,
        '65': 32.16102572495116,
        '66': 32.1611454138771,
        '67': 32.1612852548755,
        '68': 32.16135237651579,
        '69': 32.16130667267111,
        '70': 32.16120720735232,
        '71': 32.16113786679855,
        '72': 32.16110889915087,
        '73': 32.16106790710123,
        '74': 32.16100226837491,
        '75': 32.160981936208586,
        '76': 32.16106950777125,
        '77': 32.16121480894855,
        '78': 32.16128914786214,
        '79': 32.16123414334734,
        '80': 32.161137754475085,
        '81': 32.16113090090951,
        '82': 32.16122694861264,
        '83': 32.16130550699748,
        '84': 32.161262987285994,
        '85': 32.16114231260437,
        '86': 32.16108113993643,
        '87': 32.161145147403744,
        '88': 32.161251622287466,
        '89': 32.16127029902818,
        '90': 32.161170971512014,
        '91': 32.16104197496859,
        '92': 32.16097272227123,
        '93': 32.16095505687528,
        '94': 32.160917718130065,
        '95': 32.160833499763285,
        '96': 32.16075495026754,
        '97': 32.16073678540412,
        '98': 32.160751597554416,
        '99': 32.16071307519773,
        '100': 32.16058366912044,
        '101': 32.16043434527165,
        '102': 32.16037655677159,
        '103': 32.16043789637687,
        '104': 32.16052347433214,
        '105': 32.160510975594185,
        '106': 32.160376319284275,
        '107': 32.16021226589074,
        '108': 32.16012342815355,
        '109': 32.16011853052948,
        '110': 32.16011763686634,
        '111': 32.1600559732187,
        '112': 32.159958667005434,
        '113': 32.15990514302664,
        '114': 32.15993649576295,
        '115': 32.16001787366506,
        '116': 32.16008745934189,
        '117': 32.16011705957843,
        '118': 32.16011284577914,
        '119': 32.160079619541996,
        '120': 32.16001818544912,
        '121': 32.15996010167945,
        '122': 32.15996966252285,
        '123': 32.160079316038804,
        '124': 32.16023014367959,
        '125': 32.160308769314405,
        '126': 32.16025841193906,
        '127': 32.1601374876093,
        '128': 32.16005188094957,
        '129': 32.16004064422319,
        '130': 32.160052072557576,
        '131': 32.16003092597922,
        '132': 32.15999882293959,
        '133': 32.16002763931454,
        '134': 32.16014904093049,
        '135': 32.16031779601217,
        '136': 32.16046077640272,
        '137': 32.16052797309644,
        '138': 32.160480234515056,
        '139': 32.160266777561766,
        '140': 32.159880507959485,
        '141': 32.15946029090944,
        '142': 32.15929174128565,
        '143': 32.15962419250307,
        '144': 32.160429256384404,
        '145': 32.16031513484381,
        '146': 32.161863660107684,
        '147': 32.16137074915265,
        '148': 32.160142941265164,
        '149': 32.160367791161356,
        '150': 32.15994774851047,
        '151': 32.15994629174063,
        '152': 32.160184610043835,
        '153': 32.16039807140904,
        '154': 32.16045638916251,
        '155': 32.160419929091546,
        '156': 32.16041762008023,
        '157': 32.1604917734501,
        '158': 32.16056530343896,
        '159': 32.160546269812066,
        '160': 32.16044204324813,
        '161': 32.16035854037835,
        '162': 32.16039489308636,
        '163': 32.160552416590484,
        '164': 32.160743629150794,
        '165': 32.16087170906188,
        '166': 32.16089704190427,
        '167': 32.16085448596332,
        '168': 32.16083636512534,
        '169': 32.16094270781455,
        '170': 32.16118691533265,
        '171': 32.16141136848617,
        '172': 32.16133860933864,
        '173': 32.160335825457544,
        '174': 32.15748945165536,
        '175': 32.155796708521684,
        '176': 32.156664178111086,
        '177': 32.1600501354269,
        '178': 32.16095307071502,
        '179': 32.16156097151216,
        '180': 32.1616661724256,
        '-180': 32.1616661724256,
        '-179': 32.16143544934041,
        '-178': 32.161151891471484,
        '-177': 32.160962899486066,
        '-176': 32.1608625416543,
        '-175': 32.16081421680548,
        '-174': 32.160802145929374,
        '-173': 32.16078578866752,
        '-172': 32.16070010280492,
        '-171': 32.1605480048382,
        '-170': 32.16044362267922,
        '-169': 32.16050099373072,
        '-168': 32.16069084721522,
        '-167': 32.16086380157969,
        '-166': 32.16092211124757,
        '-165': 32.160911969689735,
        '-164': 32.16091796700454,
        '-163': 32.16092553062355,
        '-162': 32.16086124565905,
        '-161': 32.16074857394628,
        '-160': 32.16072602048284,
        '-159': 32.16086648020357,
        '-158': 32.16104847912187,
        '-157': 32.16109148159965,
        '-156': 32.16099633168557,
        '-155': 32.16095279616132,
        '-154': 32.16107805979903,
        '-153': 32.16123529845337,
        '-152': 32.16119598371681,
        '-151': 32.16094146093477,
        '-150': 32.16069406911871,
        '-149': 32.16062990558308,
        '-148': 32.16066534112931,
        '-147': 32.160609827033475,
        '-146': 32.16046515849559,
        '-145': 32.16044049034572,
        '-144': 32.16064829394937,
        '-143': 32.16090439535973,
        '-142': 32.160930001275894,
        '-141': 32.16070418961442,
        '-140': 32.16049669443317,
        '-139': 32.160530594853924,
        '-138': 32.160715579300366,
        '-137': 32.16079124097188,
        '-136': 32.16067171638414,
        '-135': 32.16053234448345,
        '-134': 32.160547626106045,
        '-133': 32.160656942990464,
        '-132': 32.1606709596666,
        '-131': 32.16055125122247,
        '-130': 32.16046863017331,
        '-129': 32.16056642166204,
        '-128': 32.16076183868308,
        '-127': 32.16085486985057,
        '-126': 32.16078699672786,
        '-125': 32.160697248490706,
        '-124': 32.160713782282194,
        '-123': 32.160778842618264,
        '-122': 32.16074339492013,
        '-121': 32.160588673725584,
        '-120': 32.16046459484947,
        '-119': 32.160496313295106,
        '-118': 32.16062595787799,
        '-117': 32.160697420554136,
        '-116': 32.16065746099379,
        '-115': 32.16060224222893,
        '-114': 32.16062583121157,
        '-113': 32.160693872422954,
        '-112': 32.160708644371496,
        '-111': 32.160654049973566,
        '-110': 32.160609425189996,
        '-109': 32.160624957381955,
        '-108': 32.160651801269765,
        '-107': 32.16062980900115,
        '-106': 32.160595333721176,
        '-105': 32.16063645168795,
        '-104': 32.16074857230095,
        '-103': 32.16080390154644,
        '-102': 32.160702655453676,
        '-101': 32.160515878592776,
        '-100': 32.160422292776715,
        '-99': 32.16050754935373,
        '-98': 32.16067691945892,
        '-97': 32.16078399725982,
        '-96': 32.16079927903207,
        '-95': 32.16080624959599,
        '-94': 32.16085558838302,
        '-93': 32.16089136530244,
        '-92': 32.16084397957982,
        '-91': 32.160743251077314,
        '-90': 32.16068254827563,
        '-89': 32.16068618266586,
        '-88': 32.160677522654794,
        '-87': 32.16060253169872,
        '-86': 32.160531347016274,
        '-85': 32.1605754906102,
        '-84': 32.16071832562541,
        '-83': 32.16080358779145,
        '-82': 32.16073248627739,
        '-81': 32.160621206319355,
        '-80': 32.1606811718882,
        '-79': 32.1609340337922,
        '-78': 32.16112550668632,
        '-77': 32.160998143615224,
        '-76': 32.16063388255109,
        '-75': 32.1604418519642,
        '-74': 32.16075384313349,
        '-73': 32.15992094158677,
        '-72': 32.157245276256106,
        '-71': 32.15054272890224,
        '-70': 32.15155543949884,
        '-69': 32.1494748265671,
        '-68': 32.15207080781291,
        '-67': 32.15294706422377,
        '-66': 32.15737440631941,
        '-65': 32.160641913416434,
        '-64': 32.16018532514742,
        '-63': 32.16018566638305,
        '-62': 32.16080742616839,
        '-61': 32.16110363056476,
        '-60': 32.16134050605634,
        '-59': 32.16148880229398,
        '-58': 32.16147598722887,
        '-57': 32.16120065667909,
        '-56': 32.16068415987593,
        '-55': 32.16014710412041,
        '-54': 32.15986049565018,
        '-53': 32.15991085714039,
        '-52': 32.16014265096461,
        '-51': 32.16033339932664,
        '-50': 32.160390990415095,
        '-49': 32.16037080784084,
        '-48': 32.16034837555146,
        '-47': 32.16032929884742,
        '-46': 32.160278700501514,
        '-45': 32.160187007201536,
        '-44': 32.160078176578224,
        '-43': 32.15997775095892,
        '-42': 32.159905593000126,
        '-41': 32.15989180312901,
        '-40': 32.159963981622674,
        '-39': 32.16010441988941,
        '-38': 32.16023992741582,
        '-37': 32.16029546683108,
        '-36': 32.1602575216094,
        '-35': 32.160178989867084,
        '-34': 32.160131146355226,
        '-33': 32.16016111198035,
        '-32': 32.16028118907863,
        '-31': 32.16046745567292,
        '-30': 32.16065819454329,
        '-29': 32.160780299330625,
        '-28': 32.16080869615601,
        '-27': 32.1607996294256,
        '-26': 32.160841670980325,
        '-25': 32.16096331126676,
        '-24': 32.16110245426928,
        '-23': 32.161177770460505,
        '-22': 32.16117769278053,
        '-21': 32.16116231686183,
        '-20': 32.16118522714128,
        '-19': 32.16123800418553,
        '-18': 32.1612775442413,
        '-17': 32.161283640671876,
        '-16': 32.16126745076976,
        '-15': 32.161235141596734,
        '-14': 32.16117489102135,
        '-13': 32.16108962909519,
        '-12': 32.16101901685525,
        '-11': 32.16100465268597,
        '-10': 32.161037981289894,
        '-9': 32.16106297762863,
        '-8': 32.16103428575915,
        '-7': 32.16095714756919,
        '-6': 32.16086342044957,
        '-5': 32.160765930919666,
        '-4': 32.1606559783388,
        '-3': 32.16053931138269,
        '-2': 32.16044957218273,
        '-1': 32.160414784320764
    },
    '-42': {
        '0': 32.1636382235471,
        '1': 32.163713326544574,
        '2': 32.163663405605945,
        '3': 32.163520735156055,
        '4': 32.163402775000904,
        '5': 32.16340182523693,
        '6': 32.163516948729374,
        '7': 32.163678471615455,
        '8': 32.16381549246857,
        '9': 32.16389638780865,
        '10': 32.16392640613618,
        '11': 32.163929377413965,
        '12': 32.16393175572699,
        '13': 32.163946411064494,
        '14': 32.163960325589215,
        '15': 32.16394376675845,
        '16': 32.16388155326562,
        '17': 32.16379497666717,
        '18': 32.16372580752207,
        '19': 32.16369824615887,
        '20': 32.16370471775578,
        '21': 32.16373282464066,
        '22': 32.163796309384125,
        '23': 32.163923892257955,
        '24': 32.16411204520024,
        '25': 32.16429743898681,
        '26': 32.16438863469833,
        '27': 32.16433179676357,
        '28': 32.1641478784839,
        '29': 32.16391049798739,
        '30': 32.163694532647874,
        '31': 32.163546184576425,
        '32': 32.16348940601568,
        '33': 32.163539988262286,
        '34': 32.163698330506286,
        '35': 32.16392962379258,
        '36': 32.16416576274051,
        '37': 32.164342918970966,
        '38': 32.1644442374109,
        '39': 32.1645019490428,
        '40': 32.16455162247386,
        '41': 32.16458605385151,
        '42': 32.16456346278583,
        '43': 32.16446642432663,
        '44': 32.16434425191949,
        '45': 32.16428110942061,
        '46': 32.16431594138654,
        '47': 32.16440423573615,
        '48': 32.16447064202968,
        '49': 32.164493263989144,
        '50': 32.16451645118741,
        '51': 32.16457403601135,
        '52': 32.164621463951484,
        '53': 32.16457235170677,
        '54': 32.164404678395364,
        '55': 32.16420510133464,
        '56': 32.164087440557694,
        '57': 32.16407646440302,
        '58': 32.16409412879489,
        '59': 32.16405966321454,
        '60': 32.163976764888936,
        '61': 32.16390731339247,
        '62': 32.16388239296038,
        '63': 32.16387556797851,
        '64': 32.16386596582447,
        '65': 32.163888996227215,
        '66': 32.16399114922991,
        '67': 32.16414593072711,
        '68': 32.164253368911055,
        '69': 32.16423980367974,
        '70': 32.16413804140375,
        '71': 32.16404695288315,
        '72': 32.16402207195164,
        '73': 32.16403280211444,
        '74': 32.16402854010779,
        '75': 32.1640142293545,
        '76': 32.16403436540114,
        '77': 32.16409742703859,
        '78': 32.16415397591168,
        '79': 32.16416216283872,
        '80': 32.1641480998417,
        '81': 32.164172104110165,
        '82': 32.164241618146725,
        '83': 32.16428980603969,
        '84': 32.164255939464226,
        '85': 32.16416753846884,
        '86': 32.16411609257718,
        '87': 32.164151606777715,
        '88': 32.164224458044124,
        '89': 32.16424404695172,
        '90': 32.164179495218406,
        '91': 32.16408096256187,
        '92': 32.164004847113155,
        '93': 32.16394466941516,
        '94': 32.16385390559221,
        '95': 32.16372427545492,
        '96': 32.163612491808706,
        '97': 32.16357718010926,
        '98': 32.16360484319162,
        '99': 32.163620559737744,
        '100': 32.163574943163674,
        '101': 32.16350339437998,
        '102': 32.163484536511746,
        '103': 32.163544713026425,
        '104': 32.163620208882755,
        '105': 32.16362172200918,
        '106': 32.16352742291915,
        '107': 32.16340070503275,
        '108': 32.163318137756605,
        '109': 32.163293303573205,
        '110': 32.16327779808403,
        '111': 32.16322585754539,
        '112': 32.163142224880026,
        '113': 32.16306730565113,
        '114': 32.163033309564284,
        '115': 32.163046638121635,
        '116': 32.16310029480549,
        '117': 32.16317895603779,
        '118': 32.16324690612346,
        '119': 32.16325547807146,
        '120': 32.16318944676377,
        '121': 32.16310446043709,
        '122': 32.16309217489476,
        '123': 32.163190481820116,
        '124': 32.16333782795275,
        '125': 32.16343409178802,
        '126': 32.16344388981281,
        '127': 32.16342045674259,
        '128': 32.16342386937991,
        '129': 32.163441155343,
        '130': 32.16341226606751,
        '131': 32.163324171611954,
        '132': 32.16324202322947,
        '133': 32.16323091865021,
        '134': 32.16327480809263,
        '135': 32.163308841017916,
        '136': 32.163322735158516,
        '137': 32.16337833130175,
        '138': 32.16348873795262,
        '139': 32.16351453335718,
        '140': 32.16326241568575,
        '141': 32.16274000073458,
        '142': 32.16228499451,
        '143': 32.16235645933418,
        '144': 32.16312291752134,
        '145': 32.164237771598835,
        '146': 32.15709491123792,
        '147': 32.15529797420252,
        '148': 32.16314712936759,
        '149': 32.16362228474675,
        '150': 32.163041638982804,
        '151': 32.16289178758142,
        '152': 32.16300417529308,
        '153': 32.16313644123792,
        '154': 32.16319400840444,
        '155': 32.16323390593735,
        '156': 32.163317343287524,
        '157': 32.16340785671823,
        '158': 32.163416751603805,
        '159': 32.16331836367124,
        '160': 32.163195134526624,
        '161': 32.163168841003014,
        '162': 32.16329699113719,
        '163': 32.16352988596872,
        '164': 32.16374650397257,
        '165': 32.16382635772499,
        '166': 32.163721830476845,
        '167': 32.163513301062835,
        '168': 32.16340424131167,
        '169': 32.16360165852176,
        '170': 32.16411648893654,
        '171': 32.164657363278224,
        '172': 32.154606265641924,
        '173': 32.146146394787074,
        '174': 32.16291889018307,
        '175': 32.162839751996835,
        '176': 32.16289309749665,
        '177': 32.16353411767995,
        '178': 32.16425847608869,
        '179': 32.164601725098876,
        '180': 32.16450800804644,
        '-180': 32.16450800804644,
        '-179': 32.16427047351324,
        '-178': 32.164172858875894,
        '-177': 32.164238545849095,
        '-176': 32.164305473099844,
        '-175': 32.164260682690156,
        '-174': 32.164142374287444,
        '-173': 32.16404127386919,
        '-172': 32.16397773785362,
        '-171': 32.16390653699273,
        '-170': 32.163802147670395,
        '-169': 32.16369308220858,
        '-168': 32.1636212910106,
        '-167': 32.163607518082365,
        '-166': 32.16365981106452,
        '-165': 32.163774211657326,
        '-164': 32.163902830956324,
        '-163': 32.163955187875395,
        '-162': 32.16387917967666,
        '-161': 32.163737260273436,
        '-160': 32.1636566776036,
        '-159': 32.16368709372876,
        '-158': 32.16374529970011,
        '-157': 32.16373310107788,
        '-156': 32.16367800574892,
        '-155': 32.16369787153721,
        '-154': 32.1638259909169,
        '-153': 32.16393945849072,
        '-152': 32.16390679825055,
        '-151': 32.16376388648786,
        '-150': 32.163669871589235,
        '-149': 32.16369098121867,
        '-148': 32.16371044096173,
        '-147': 32.163602694522396,
        '-146': 32.1634383448555,
        '-145': 32.163420262373926,
        '-144': 32.163609602079205,
        '-143': 32.163807314861685,
        '-142': 32.16377597450993,
        '-141': 32.163535732877314,
        '-140': 32.16335028677005,
        '-139': 32.16340922492235,
        '-138': 32.163606416008605,
        '-137': 32.163690196388636,
        '-136': 32.1635818232999,
        '-135': 32.163453437091476,
        '-134': 32.16348427364658,
        '-133': 32.16362937152403,
        '-132': 32.16369625189955,
        '-131': 32.16360689739827,
        '-130': 32.16349134409722,
        '-129': 32.16350453663965,
        '-128': 32.16362692615807,
        '-127': 32.16370933140485,
        '-126': 32.16368015041007,
        '-125': 32.16362581109013,
        '-124': 32.163648952863554,
        '-123': 32.16371617469205,
        '-122': 32.16370745695735,
        '-121': 32.163590976999586,
        '-120': 32.16347735772587,
        '-119': 32.16348077279047,
        '-118': 32.16357857671808,
        '-117': 32.16365397278801,
        '-116': 32.16365159596109,
        '-115': 32.16363149354919,
        '-114': 32.16366131646451,
        '-113': 32.16371153749852,
        '-112': 32.16370224298127,
        '-111': 32.163627762670934,
        '-110': 32.1635754456004,
        '-109': 32.16360774892203,
        '-108': 32.16367243997159,
        '-107': 32.163669092424,
        '-106': 32.16358368328893,
        '-105': 32.16350803557143,
        '-104': 32.1635172533746,
        '-103': 32.16357030676636,
        '-102': 32.163566246078915,
        '-101': 32.1634798982076,
        '-100': 32.163398879880724,
        '-99': 32.16341804783774,
        '-98': 32.16353026312097,
        '-97': 32.16364465094868,
        '-96': 32.163692564571505,
        '-95': 32.16368298534633,
        '-94': 32.16365855484879,
        '-93': 32.16363664671559,
        '-92': 32.16361470033484,
        '-91': 32.163606441659084,
        '-90': 32.16363334501852,
        '-89': 32.163680668582046,
        '-88': 32.1636990538087,
        '-87': 32.163670464852494,
        '-86': 32.163648237981086,
        '-85': 32.163697856470264,
        '-84': 32.16380461707825,
        '-83': 32.163880448905964,
        '-82': 32.16387900102032,
        '-81': 32.163867037086916,
        '-80': 32.16393478940674,
        '-79': 32.16404386300585,
        '-78': 32.16402851726549,
        '-77': 32.16379738940151,
        '-76': 32.163510717539026,
        '-75': 32.163487132228994,
        '-74': 32.16240520786681,
        '-73': 32.16454622662807,
        '-72': 32.159803395157454,
        '-71': 32.154327789698435,
        '-70': 32.15600629636152,
        '-69': 32.15180170181171,
        '-68': 32.1520199728213,
        '-67': 32.159155839233335,
        '-66': 32.15964933869258,
        '-65': 32.16360797396223,
        '-64': 32.163384714723655,
        '-63': 32.16345768826894,
        '-62': 32.163751124620404,
        '-61': 32.164068745679465,
        '-60': 32.16424456021223,
        '-59': 32.16420503481724,
        '-58': 32.16394451798935,
        '-57': 32.16351706542767,
        '-56': 32.16306219415194,
        '-55': 32.16277266804709,
        '-54': 32.162768385419035,
        '-53': 32.16299156506731,
        '-52': 32.16325601946868,
        '-51': 32.16341486816841,
        '-50': 32.163468129732685,
        '-49': 32.163503902490966,
        '-48': 32.16356359673583,
        '-47': 32.16359518403297,
        '-46': 32.16353063214682,
        '-45': 32.16337305998095,
        '-44': 32.163190347420276,
        '-43': 32.1630436502515,
        '-42': 32.16294846063336,
        '-41': 32.16290024954661,
        '-40': 32.16290702133837,
        '-39': 32.1629770850005,
        '-38': 32.16308542120611,
        '-37': 32.16317462403975,
        '-36': 32.163199626673226,
        '-35': 32.16316975890988,
        '-34': 32.163144883141534,
        '-33': 32.163189212711984,
        '-32': 32.16332082790394,
        '-31': 32.163493895555845,
        '-30': 32.16362801283614,
        '-29': 32.16366836995278,
        '-28': 32.16363225518322,
        '-27': 32.16359787769006,
        '-26': 32.16363807027043,
        '-25': 32.163758629233385,
        '-24': 32.16390184968,
        '-23': 32.164008089137525,
        '-22': 32.164066755358014,
        '-21': 32.16410719887193,
        '-20': 32.16415384537486,
        '-19': 32.164203810585725,
        '-18': 32.16424239982191,
        '-17': 32.164260269260026,
        '-16': 32.16424767543383,
        '-15': 32.16418902928185,
        '-14': 32.1640837784617,
        '-13': 32.163969378866035,
        '-12': 32.163900780743234,
        '-11': 32.16389527222937,
        '-10': 32.163910716487926,
        '-9': 32.16389456425837,
        '-8': 32.1638475081555,
        '-7': 32.16381690029214,
        '-6': 32.16382324787453,
        '-5': 32.16381799145626,
        '-4': 32.16373894013188,
        '-3': 32.16360128542103,
        '-2': 32.16350384072906,
        '-1': 32.16352735883518
    },
    '-43': {
        '0': 32.1666297025353,
        '1': 32.166754933534605,
        '2': 32.16673247558265,
        '3': 32.166587573127416,
        '4': 32.16646105876026,
        '5': 32.1664662757595,
        '6': 32.16659482700215,
        '7': 32.16675334724983,
        '8': 32.16686522566157,
        '9': 32.166921621988514,
        '10': 32.16694970497566,
        '11': 32.16696246214881,
        '12': 32.16695315826757,
        '13': 32.16692349306164,
        '14': 32.1668932017559,
        '15': 32.16687521118662,
        '16': 32.16685448885851,
        '17': 32.16680598370692,
        '18': 32.16673411083108,
        '19': 32.166683996361144,
        '20': 32.16670468852014,
        '21': 32.16680030448736,
        '22': 32.16692092036177,
        '23': 32.16700376468557,
        '24': 32.167023578134355,
        '25': 32.16700338080188,
        '26': 32.166978313079056,
        '27': 32.16695152201257,
        '28': 32.16688744457458,
        '29': 32.1667509408712,
        '30': 32.166556867891536,
        '31': 32.16638339322838,
        '32': 32.166332182635664,
        '33': 32.166462948762884,
        '34': 32.16675166401259,
        '35': 32.167103909464394,
        '36': 32.16741235286866,
        '37': 32.167614198032744,
        '38': 32.16770706969484,
        '39': 32.16771855014758,
        '40': 32.167665158858085,
        '41': 32.16754407666823,
        '42': 32.167364745736585,
        '43': 32.16718008441595,
        '44': 32.16706999592576,
        '45': 32.16707965833248,
        '46': 32.16717363235875,
        '47': 32.16726327346387,
        '48': 32.167289500587,
        '49': 32.16727484534433,
        '50': 32.167284487154106,
        '51': 32.167339053918965,
        '52': 32.16738402152021,
        '53': 32.16735753469658,
        '54': 32.16727579151322,
        '55': 32.167224075711104,
        '56': 32.16725274150512,
        '57': 32.16730212527485,
        '58': 32.1672580096457,
        '59': 32.16708392558886,
        '60': 32.16687523275638,
        '61': 32.16676507301737,
        '62': 32.16678803172063,
        '63': 32.16685893741078,
        '64': 32.16688537046307,
        '65': 32.16687311370605,
        '66': 32.16689974424593,
        '67': 32.166998917207465,
        '68': 32.16710549403475,
        '69': 32.16712900377502,
        '70': 32.1670607415618,
        '71': 32.16698265839667,
        '72': 32.16697284861098,
        '73': 32.16702660358382,
        '74': 32.16708188572402,
        '75': 32.167103890056964,
        '76': 32.167119115214895,
        '77': 32.16716601314183,
        '78': 32.167236320109375,
        '79': 32.16728272573608,
        '80': 32.167275813256545,
        '81': 32.16723400219511,
        '82': 32.16719443990544,
        '83': 32.16717071394679,
        '84': 32.16715121023291,
        '85': 32.167129590622096,
        '86': 32.1671175624899,
        '87': 32.16712265814892,
        '88': 32.167127888637644,
        '89': 32.167108035415076,
        '90': 32.1670634669106,
        '91': 32.167023610734944,
        '92': 32.167010237403254,
        '93': 32.167005446006264,
        '94': 32.166967021154456,
        '95': 32.16687568615048,
        '96': 32.16675870116942,
        '97': 32.166662707583775,
        '98': 32.16660897302549,
        '99': 32.16658152380836,
        '100': 32.16655514391894,
        '101': 32.16652362346577,
        '102': 32.16649568878399,
        '103': 32.16647194849097,
        '104': 32.16643875826899,
        '105': 32.16638729997318,
        '106': 32.16632927227844,
        '107': 32.166285409234106,
        '108': 32.16626178190102,
        '109': 32.16624659212149,
        '110': 32.16623112064533,
        '111': 32.1662231013432,
        '112': 32.166230963776,
        '113': 32.16624191994039,
        '114': 32.16623186455777,
        '115': 32.166202221291144,
        '116': 32.16619283036557,
        '117': 32.16624067596298,
        '118': 32.16632626194082,
        '119': 32.16637888240968,
        '120': 32.16634779202588,
        '121': 32.16626015271921,
        '122': 32.16619255327962,
        '123': 32.16618550438463,
        '124': 32.16620422402204,
        '125': 32.166193981518944,
        '126': 32.16615743170633,
        '127': 32.16615117958493,
        '128': 32.16620505869569,
        '129': 32.166274248584855,
        '130': 32.16629480134559,
        '131': 32.166271097612096,
        '132': 32.16626999172634,
        '133': 32.16631751797689,
        '134': 32.1663394667513,
        '135': 32.16625079722305,
        '136': 32.166100304049024,
        '137': 32.16606783326762,
        '138': 32.16626426277511,
        '139': 32.16654544088583,
        '140': 32.16659313120479,
        '141': 32.1662400237861,
        '142': 32.165711428856554,
        '143': 32.16548938990104,
        '144': 32.16588195986082,
        '145': 32.166708219934556,
        '146': 32.166286253131275,
        '147': 32.16599221329028,
        '148': 32.167123352595226,
        '149': 32.166486184487795,
        '150': 32.166064117205785,
        '151': 32.16598817383217,
        '152': 32.16611093171299,
        '153': 32.166235809827356,
        '154': 32.16629642467271,
        '155': 32.16633397392161,
        '156': 32.16636992035908,
        '157': 32.16635759381695,
        '158': 32.16625831206059,
        '159': 32.16612248328184,
        '160': 32.16606026808781,
        '161': 32.16613162406696,
        '162': 32.166282208844066,
        '163': 32.166391458149164,
        '164': 32.16637310922091,
        '165': 32.166231473746684,
        '166': 32.166050483760486,
        '167': 32.16595854153001,
        '168': 32.16608871560028,
        '169': 32.16650735973135,
        '170': 32.16712131890836,
        '171': 32.16553563137554,
        '172': 32.154956395307416,
        '173': 32.1664202440172,
        '174': 32.16717654231058,
        '175': 32.16697956710058,
        '176': 32.16719070606821,
        '177': 32.16762362139422,
        '178': 32.16791132213711,
        '179': 32.16786821756395,
        '180': 32.16765027490665,
        '-180': 32.16765027490665,
        '-179': 32.16755659850372,
        '-178': 32.16769966792939,
        '-177': 32.16789829057082,
        '-176': 32.16788642888626,
        '-175': 32.167590630758795,
        '-174': 32.16718524246616,
        '-173': 32.16690189931978,
        '-172': 32.16682036259851,
        '-171': 32.16684088135426,
        '-170': 32.16681804786094,
        '-169': 32.16669408516827,
        '-168': 32.166524101344805,
        '-167': 32.1664137897858,
        '-166': 32.16644001718617,
        '-165': 32.16659770399051,
        '-164': 32.166794667533196,
        '-163': 32.16690925372104,
        '-162': 32.1668838482632,
        '-161': 32.16677170417987,
        '-160': 32.16667851220666,
        '-159': 32.16665387315315,
        '-158': 32.1666563162516,
        '-157': 32.166630246547356,
        '-156': 32.16658889945702,
        '-155': 32.166589624354785,
        '-154': 32.16663898088416,
        '-153': 32.16666981296986,
        '-152': 32.16663419871376,
        '-151': 32.16658266354275,
        '-150': 32.16660312373974,
        '-149': 32.16668870965472,
        '-148': 32.166723243787246,
        '-147': 32.16662850829787,
        '-146': 32.166486228373294,
        '-145': 32.16645512742202,
        '-144': 32.166568459488126,
        '-143': 32.166674645409216,
        '-142': 32.16661667003035,
        '-141': 32.16643349299743,
        '-140': 32.16632213311072,
        '-139': 32.1663983180257,
        '-138': 32.16655548504082,
        '-137': 32.16660100813806,
        '-136': 32.16649570317519,
        '-135': 32.16639412337734,
        '-134': 32.16644241536725,
        '-133': 32.16659709648035,
        '-132': 32.166688899366925,
        '-131': 32.166635608813166,
        '-130': 32.16652911854834,
        '-129': 32.16650280101587,
        '-128': 32.16656883244941,
        '-127': 32.16663095693478,
        '-126': 32.16662998446641,
        '-125': 32.16661336870008,
        '-124': 32.16664612704873,
        '-123': 32.16670453349049,
        '-122': 32.16670651846779,
        '-121': 32.16663126702674,
        '-120': 32.16655497781253,
        '-119': 32.166551276747825,
        '-118': 32.16659618330959,
        '-117': 32.16660994085277,
        '-116': 32.16657495802959,
        '-115': 32.166561927622475,
        '-114': 32.166624584819246,
        '-113': 32.16670963046463,
        '-112': 32.16671351944362,
        '-111': 32.166618594221404,
        '-110': 32.166527186606444,
        '-109': 32.16654072866241,
        '-108': 32.16663392253562,
        '-107': 32.166683976410035,
        '-106': 32.166618740838985,
        '-105': 32.166499909435096,
        '-104': 32.166444009207645,
        '-103': 32.1664861710868,
        '-102': 32.166552327049,
        '-101': 32.16656052689207,
        '-100': 32.166516488924,
        '-99': 32.16649193790431,
        '-98': 32.16652766886828,
        '-97': 32.16658601842896,
        '-96': 32.16660113777892,
        '-95': 32.16655532214443,
        '-94': 32.16649352012812,
        '-93': 32.166473528402676,
        '-92': 32.16651630640137,
        '-91': 32.16660056494427,
        '-90': 32.16668878211677,
        '-89': 32.16675260192595,
        '-88': 32.16678486862728,
        '-87': 32.166798569655334,
        '-86': 32.16681109111095,
        '-85': 32.16682273754793,
        '-84': 32.16681713116182,
        '-83': 32.16679511221205,
        '-82': 32.16680118886886,
        '-81': 32.166885546204234,
        '-80': 32.16701793907981,
        '-79': 32.167060438215714,
        '-78': 32.16687861095691,
        '-77': 32.166513215309706,
        '-76': 32.16622041743948,
        '-75': 32.16628685334642,
        '-74': 32.1642578446766,
        '-73': 32.1674114587444,
        '-72': 32.15967053839348,
        '-71': 32.15841786001747,
        '-70': 32.15693395101872,
        '-69': 32.158486857871,
        '-68': 32.160980890858156,
        '-67': 32.16642583490121,
        '-66': 32.16522653470721,
        '-65': 32.16620579459147,
        '-64': 32.16641592426121,
        '-63': 32.16651006041406,
        '-62': 32.166763308655526,
        '-61': 32.1669844982298,
        '-60': 32.16702234996858,
        '-59': 32.1668429881431,
        '-58': 32.16651646816437,
        '-57': 32.166169967262626,
        '-56': 32.16594098313373,
        '-55': 32.16591632805079,
        '-54': 32.166069919004734,
        '-53': 32.16626672596461,
        '-52': 32.16636605318584,
        '-51': 32.16634306236565,
        '-50': 32.16630036042166,
        '-49': 32.16634784594763,
        '-48': 32.16647748326439,
        '-47': 32.16656923002908,
        '-46': 32.16651689346521,
        '-45': 32.16633208273194,
        '-44': 32.16612075516636,
        '-43': 32.16597562199585,
        '-42': 32.165909050060954,
        '-41': 32.16588343708472,
        '-40': 32.16587908370088,
        '-39': 32.16591323178836,
        '-38': 32.16600048548781,
        '-37': 32.16611701067472,
        '-36': 32.16621667641664,
        '-35': 32.166278304867475,
        '-34': 32.16632695752678,
        '-33': 32.16640438445217,
        '-32': 32.166520394780974,
        '-31': 32.16663601767263,
        '-30': 32.16669587373597,
        '-29': 32.16667841497143,
        '-28': 32.166616142740395,
        '-27': 32.166567997202364,
        '-26': 32.16657255791387,
        '-25': 32.16662678049702,
        '-24': 32.166704635142914,
        '-23': 32.166787705747666,
        '-22': 32.16687376890671,
        '-21': 32.16696328087186,
        '-20': 32.16705073853561,
        '-19': 32.16713246158321,
        '-18': 32.16721049273028,
        '-17': 32.16727610996438,
        '-16': 32.16729520743577,
        '-15': 32.167230778600285,
        '-14': 32.167091399413096,
        '-13': 32.166944880461315,
        '-12': 32.166862699143564,
        '-11': 32.16684793318631,
        '-10': 32.16683576986774,
        '-9': 32.16677676320273,
        '-8': 32.16670488892018,
        '-7': 32.16669826695155,
        '-6': 32.1667706378861,
        '-5': 32.16682973716854,
        '-4': 32.166770223750326,
        '-3': 32.16660332046007,
        '-2': 32.16646578830259,
        '-1': 32.16648242243508
    },
    '-44': {
        '0': 32.16943683378623,
        '1': 32.16950764893752,
        '2': 32.16950554514562,
        '3': 32.16944691234981,
        '4': 32.169421786745154,
        '5': 32.16948316981441,
        '6': 32.1695930712409,
        '7': 32.169680026128134,
        '8': 32.169725495124545,
        '9': 32.169770478967024,
        '10': 32.16984186706851,
        '11': 32.16989977964878,
        '12': 32.16987789290635,
        '13': 32.16976972003876,
        '14': 32.16965381108771,
        '15': 32.169621907783764,
        '16': 32.16968879028145,
        '17': 32.1697843864633,
        '18': 32.16983441008186,
        '19': 32.16983714142788,
        '20': 32.16985274331161,
        '21': 32.16992264145203,
        '22': 32.17001271250896,
        '23': 32.17004283304255,
        '24': 32.169967588049914,
        '25': 32.169819304208204,
        '26': 32.1696701826797,
        '27': 32.16955952185598,
        '28': 32.169466548347856,
        '29': 32.16935594368561,
        '30': 32.16924352643357,
        '31': 32.16920856727172,
        '32': 32.16933528259808,
        '33': 32.16963793717282,
        '34': 32.17003934618329,
        '35': 32.170420492379826,
        '36': 32.17069443000508,
        '37': 32.17084187960718,
        '38': 32.17088827199526,
        '39': 32.170857342145815,
        '40': 32.17075219479062,
        '41': 32.1705793200634,
        '42': 32.17038140021823,
        '43': 32.17023158923966,
        '44': 32.17018173344495,
        '45': 32.170212409448,
        '46': 32.17024342405037,
        '47': 32.170207760950674,
        '48': 32.17012012067597,
        '49': 32.170064080847624,
        '50': 32.17010185688905,
        '51': 32.17020031924197,
        '52': 32.1702609273722,
        '53': 32.17022988092956,
        '54': 32.17016546755203,
        '55': 32.170174967297484,
        '56': 32.170280326417284,
        '57': 32.170366737615154,
        '58': 32.170289097768034,
        '59': 32.17003287790713,
        '60': 32.16975129362618,
        '61': 32.16962707376532,
        '62': 32.16970043409282,
        '63': 32.16984490041934,
        '64': 32.169910095482024,
        '65': 32.169870075858526,
        '66': 32.16982151962582,
        '67': 32.169853361225506,
        '68': 32.1699477853802,
        '69': 32.17001808235528,
        '70': 32.17002057913255,
        '71': 32.17000024921875,
        '72': 32.170024028910035,
        '73': 32.170094537987254,
        '74': 32.1701513813785,
        '75': 32.17015165952031,
        '76': 32.170125357607354,
        '77': 32.1701381617967,
        '78': 32.17020939025776,
        '79': 32.1702837519645,
        '80': 32.17028823904775,
        '81': 32.17020776714434,
        '82': 32.17009839704135,
        '83': 32.1700313866304,
        '84': 32.17003207203459,
        '85': 32.17007080624641,
        '86': 32.17010118541513,
        '87': 32.17009892697816,
        '88': 32.17006936276492,
        '89': 32.17003113566092,
        '90': 32.170000781643516,
        '91': 32.169988776623285,
        '92': 32.16999948238135,
        '93': 32.170027061991355,
        '94': 32.17005113983079,
        '95': 32.17004175339976,
        '96': 32.16997605619913,
        '97': 32.1698570858913,
        '98': 32.16971892808361,
        '99': 32.16960890048648,
        '100': 32.16955454450784,
        '101': 32.169540272164475,
        '102': 32.169517884076974,
        '103': 32.16944875378803,
        '104': 32.16934196801893,
        '105': 32.16924841545065,
        '106': 32.169210510998845,
        '107': 32.16921653794988,
        '108': 32.16921124752338,
        '109': 32.169157141557115,
        '110': 32.16908100739878,
        '111': 32.16904937073311,
        '112': 32.16909410421864,
        '113': 32.169171043195284,
        '114': 32.16920346873764,
        '115': 32.16916603411549,
        '116': 32.16911354064623,
        '117': 32.16911875567991,
        '118': 32.16918951060566,
        '119': 32.169261420906246,
        '120': 32.16927330942411,
        '121': 32.169233314352354,
        '122': 32.169196867174485,
        '123': 32.16918878300504,
        '124': 32.169171888822625,
        '125': 32.16910329180623,
        '126': 32.169003213688015,
        '127': 32.16894170001777,
        '128': 32.16895578111557,
        '129': 32.16900598408036,
        '130': 32.16903443230525,
        '131': 32.169049631368395,
        '132': 32.16911682512304,
        '133': 32.169252652293025,
        '134': 32.169363427499846,
        '135': 32.16933400534987,
        '136': 32.16918398522271,
        '137': 32.169092097550525,
        '138': 32.169217725410086,
        '139': 32.16949640011134,
        '140': 32.169656366669734,
        '141': 32.16948055673173,
        '142': 32.16905389741263,
        '143': 32.16871997764585,
        '144': 32.16876605464808,
        '145': 32.169148354522456,
        '146': 32.16953611278472,
        '147': 32.16962427785273,
        '148': 32.16939666960769,
        '149': 32.16909454278092,
        '150': 32.16896103826771,
        '151': 32.16904022341884,
        '152': 32.169206183436664,
        '153': 32.16933279740585,
        '154': 32.169393987303025,
        '155': 32.169414518223256,
        '156': 32.16938450429836,
        '157': 32.169271442592546,
        '158': 32.169108536525336,
        '159': 32.16901894135956,
        '160': 32.16910770756032,
        '161': 32.16932742518025,
        '162': 32.16948140169926,
        '163': 32.16938999010916,
        '164': 32.16906598065651,
        '165': 32.1687271528582,
        '166': 32.168634449297656,
        '167': 32.16890439250667,
        '168': 32.169444737956375,
        '169': 32.155852343564604,
        '170': 32.15681770874988,
        '171': 32.16204493349692,
        '172': 32.17045178774024,
        '173': 32.17026179516798,
        '174': 32.17021529517468,
        '175': 32.17040953111539,
        '176': 32.17073286086454,
        '177': 32.170940043508764,
        '178': 32.170867665068236,
        '179': 32.17060046754278,
        '180': 32.17041068730373,
        '-180': 32.17041068730373,
        '-179': 32.17050477471159,
        '-178': 32.17081663478506,
        '-177': 32.17105441222929,
        '-176': 32.170958058209415,
        '-175': 32.17051925491623,
        '-174': 32.16996696417083,
        '-173': 32.16956071050058,
        '-172': 32.16940052780288,
        '-171': 32.16940527422192,
        '-170': 32.16942988111957,
        '-169': 32.16938836612735,
        '-168': 32.169293473675005,
        '-167': 32.16921969667188,
        '-166': 32.169238953102926,
        '-165': 32.16936740673433,
        '-164': 32.16954930943537,
        '-163': 32.169693932477266,
        '-162': 32.1697459670145,
        '-161': 32.16972795550127,
        '-160': 32.16970855414683,
        '-159': 32.16972808620922,
        '-158': 32.16976416488709,
        '-157': 32.16977223039875,
        '-156': 32.16974309581639,
        '-155': 32.169703596547066,
        '-154': 32.16966941596389,
        '-153': 32.16962562045375,
        '-152': 32.16956521289533,
        '-151': 32.16952330190833,
        '-150': 32.16954270789258,
        '-149': 32.16960793682902,
        '-148': 32.16964493427567,
        '-147': 32.16960461972854,
        '-146': 32.16952733319418,
        '-145': 32.169495896086666,
        '-144': 32.169529202372736,
        '-143': 32.16955602037518,
        '-142': 32.16951052616213,
        '-141': 32.169427174168234,
        '-140': 32.169404459102296,
        '-139': 32.169476960774,
        '-138': 32.16956206115183,
        '-137': 32.16955733103743,
        '-136': 32.16946833558477,
        '-135': 32.16940257747843,
        '-134': 32.16943573224659,
        '-133': 32.16952044604975,
        '-132': 32.16955150931069,
        '-131': 32.16949948265873,
        '-130': 32.169441828596554,
        '-129': 32.16945957787025,
        '-128': 32.16953982528245,
        '-127': 32.16960566595071,
        '-126': 32.16962112840074,
        '-125': 32.1696266314084,
        '-124': 32.1696663456372,
        '-123': 32.16971842677214,
        '-122': 32.16972730409467,
        '-121': 32.16968770620272,
        '-120': 32.16965567302653,
        '-119': 32.16966686093327,
        '-118': 32.16967900308563,
        '-117': 32.169628625687004,
        '-116': 32.16953220870575,
        '-115': 32.16948835033615,
        '-114': 32.169560533897,
        '-113': 32.16968414739475,
        '-112': 32.169725495906384,
        '-111': 32.16963360569936,
        '-110': 32.1695032228271,
        '-109': 32.16946939778134,
        '-108': 32.1695569078485,
        '-107': 32.16966049527689,
        '-106': 32.16967226435368,
        '-105': 32.1696003563191,
        '-104': 32.16954480271911,
        '-103': 32.16957340340445,
        '-102': 32.16965101120935,
        '-101': 32.16969573158414,
        '-100': 32.169679031645884,
        '-99': 32.16964661506649,
        '-98': 32.16964697056104,
        '-97': 32.16966655639367,
        '-96': 32.16965134099389,
        '-95': 32.16958166692872,
        '-94': 32.169504986884014,
        '-93': 32.1694872959238,
        '-92': 32.16954192111294,
        '-91': 32.169617706015565,
        '-90': 32.16965796368954,
        '-89': 32.16966258207237,
        '-88': 32.16968388670132,
        '-87': 32.16975946006161,
        '-86': 32.16985463483575,
        '-85': 32.16988315106052,
        '-84': 32.169797127124404,
        '-83': 32.169658288491775,
        '-82': 32.16960331389828,
        '-81': 32.169712967727484,
        '-80': 32.16990530300059,
        '-79': 32.169978584648554,
        '-78': 32.1697962030888,
        '-77': 32.16944905614592,
        '-76': 32.16922070876274,
        '-75': 32.16935746872895,
        '-74': 32.16984655760778,
        '-73': 32.16801645838578,
        '-72': 32.16223158088207,
        '-71': 32.161348154508616,
        '-70': 32.1593603308323,
        '-69': 32.165796570779015,
        '-68': 32.16624211407227,
        '-67': 32.167249882865256,
        '-66': 32.16652538727383,
        '-65': 32.169462695344166,
        '-64': 32.16945248001028,
        '-63': 32.16962270718223,
        '-62': 32.169843716895244,
        '-61': 32.1699394567883,
        '-60': 32.169817998689915,
        '-59': 32.16952318021293,
        '-58': 32.16918797887893,
        '-57': 32.16895132119099,
        '-56': 32.168893440912925,
        '-55': 32.16900240218431,
        '-54': 32.16917688003364,
        '-53': 32.169281658777095,
        '-52': 32.169243056112045,
        '-51': 32.169113114159956,
        '-50': 32.16902982222615,
        '-49': 32.16909199934794,
        '-48': 32.16926337175208,
        '-47': 32.169402883530786,
        '-46': 32.16939242368861,
        '-45': 32.16923475679505,
        '-44': 32.169032295851686,
        '-43': 32.16888460122481,
        '-42': 32.16881574062431,
        '-41': 32.1687910066265,
        '-40': 32.16877988426997,
        '-39': 32.16878688495188,
        '-38': 32.168828299706206,
        '-37': 32.1688996604743,
        '-36': 32.168978504727214,
        '-35': 32.16905370537979,
        '-34': 32.16913902657529,
        '-33': 32.169252320934106,
        '-32': 32.16938692833559,
        '-31': 32.169511263382766,
        '-30': 32.169597548632105,
        '-29': 32.16964596998674,
        '-28': 32.16967754331754,
        '-27': 32.16970736725617,
        '-26': 32.16973230277355,
        '-25': 32.16974650226401,
        '-24': 32.16976089449335,
        '-23': 32.16979689779311,
        '-22': 32.16985984273207,
        '-21': 32.16992890703455,
        '-20': 32.169982773915734,
        '-19': 32.17003065737746,
        '-18': 32.17010239306002,
        '-17': 32.170199710449815,
        '-16': 32.17026892316267,
        '-15': 32.170242510943076,
        '-14': 32.170114632008634,
        '-13': 32.16996147731842,
        '-12': 32.16987037791286,
        '-11': 32.16985091393005,
        '-10': 32.169833113833675,
        '-9': 32.16976031676072,
        '-8': 32.16966686133781,
        '-7': 32.16964106030091,
        '-6': 32.16971085165784,
        '-5': 32.16979072540492,
        '-4': 32.16976390962943,
        '-3': 32.16961259437258,
        '-2': 32.16944486937462,
        '-1': 32.169382755706295
    },
    '-45': {
        '0': 32.17239427314811,
        '1': 32.17232613789618,
        '2': 32.17226179254027,
        '3': 32.17224466937018,
        '4': 32.172316731916595,
        '5': 32.17245096257617,
        '6': 32.17255861420628,
        '7': 32.172577101699055,
        '8': 32.17253931398143,
        '9': 32.17253656790728,
        '10': 32.17260983330987,
        '11': 32.17269362706189,
        '12': 32.1726834322323,
        '13': 32.17255752571179,
        '14': 32.17241806838848,
        '15': 32.172401404064544,
        '16': 32.17254894769084,
        '17': 32.17277107644242,
        '18': 32.17293544895694,
        '19': 32.172983120906075,
        '20': 32.17295781163523,
        '21': 32.17293465664373,
        '22': 32.172936733424756,
        '23': 32.172924404970516,
        '24': 32.17285217231442,
        '25': 32.172718799691744,
        '26': 32.17255960165054,
        '27': 32.17240526491395,
        '28': 32.172266602813195,
        '29': 32.17216315066817,
        '30': 32.17215152803801,
        '31': 32.172303562078625,
        '32': 32.172642070483775,
        '33': 32.17309702908585,
        '34': 32.17353306151338,
        '35': 32.17383124450847,
        '36': 32.173955293594595,
        '37': 32.173948505586175,
        '38': 32.17387497170844,
        '39': 32.17376778673763,
        '40': 32.173630855589934,
        '41': 32.17347947428814,
        '42': 32.17336289215465,
        '43': 32.173333343672375,
        '44': 32.17338933188641,
        '45': 32.1734593754201,
        '46': 32.17345952625878,
        '47': 32.17337919607149,
        '48': 32.17330587689234,
        '49': 32.17334731104242,
        '50': 32.17351487044407,
        '51': 32.17368777560958,
        '52': 32.17371159987104,
        '53': 32.17354742731707,
        '54': 32.173320775050634,
        '55': 32.17320742565172,
        '56': 32.17326047837733,
        '57': 32.173354419444266,
        '58': 32.17330995136964,
        '59': 32.173075946197606,
        '60': 32.17278203099387,
        '61': 32.17260981211597,
        '62': 32.17262051199201,
        '63': 32.17271659738476,
        '64': 32.17276443084755,
        '65': 32.17273400545105,
        '66': 32.172707613129525,
        '67': 32.17276585147928,
        '68': 32.17289218318783,
        '69': 32.17300041871045,
        '70': 32.1730388575599,
        '71': 32.17304158668105,
        '72': 32.17307165234455,
        '73': 32.17313373371824,
        '74': 32.173165604983794,
        '75': 32.17311729085654,
        '76': 32.17302032143582,
        '77': 32.172962623857124,
        '78': 32.17299652254977,
        '79': 32.173083967313396,
        '80': 32.17313853111301,
        '81': 32.17311487132137,
        '82': 32.17304841271217,
        '83': 32.173008955904876,
        '84': 32.173026091390966,
        '85': 32.173067085446746,
        '86': 32.17308248744559,
        '87': 32.1730610160056,
        '88': 32.17303327213323,
        '89': 32.17302749583602,
        '90': 32.17303374572303,
        '91': 32.17301824234368,
        '92': 32.17296865614164,
        '93': 32.17291463299371,
        '94': 32.1728962365827,
        '95': 32.17291382613366,
        '96': 32.172917879257156,
        '97': 32.17285658615883,
        '98': 32.17273451990863,
        '99': 32.1726178307715,
        '100': 32.172573894538125,
        '101': 32.17260407096159,
        '102': 32.17264075226892,
        '103': 32.17261552642243,
        '104': 32.17252878448181,
        '105': 32.17244598535969,
        '106': 32.172422769754014,
        '107': 32.17243995422362,
        '108': 32.1724219052145,
        '109': 32.172321111216206,
        '110': 32.17217599262758,
        '111': 32.172075331006575,
        '112': 32.17206617873756,
        '113': 32.17210731543711,
        '114': 32.172120392211575,
        '115': 32.172079747757365,
        '116': 32.17203740089699,
        '117': 32.17205577183887,
        '118': 32.1721297711767,
        '119': 32.172192415436776,
        '120': 32.17219707405217,
        '121': 32.17217525378519,
        '122': 32.172197082232195,
        '123': 32.17228083167345,
        '124': 32.17236293306246,
        '125': 32.172368273031935,
        '126': 32.17229715880425,
        '127': 32.172222801370744,
        '128': 32.17220268762571,
        '129': 32.172214857510006,
        '130': 32.17219969436469,
        '131': 32.17215339783493,
        '132': 32.172148983524416,
        '133': 32.17224830434687,
        '134': 32.17241026964793,
        '135': 32.17251671500481,
        '136': 32.17249801692688,
        '137': 32.17241183647365,
        '138': 32.17237408968839,
        '139': 32.17241639536199,
        '140': 32.17243984384571,
        '141': 32.172328533710264,
        '142': 32.17209683073447,
        '143': 32.1718966345873,
        '144': 32.17186722763149,
        '145': 32.17199144673325,
        '146': 32.172116887271685,
        '147': 32.172115555986146,
        '148': 32.172004972155364,
        '149': 32.17190777328625,
        '150': 32.171913296385355,
        '151': 32.172002769900324,
        '152': 32.17210466788123,
        '153': 32.17218664766036,
        '154': 32.172262416795135,
        '155': 32.172320580390476,
        '156': 32.17229579468633,
        '157': 32.172149360089804,
        '158': 32.171968605090136,
        '159': 32.17193587100974,
        '160': 32.17215312635061,
        '161': 32.17249430522508,
        '162': 32.17266970051128,
        '163': 32.17248328366732,
        '164': 32.17204992762574,
        '165': 32.17174900687176,
        '166': 32.171929164237,
        '167': 32.172613861888664,
        '168': 32.168972824225406,
        '169': 32.164286106404965,
        '170': 32.16755852152958,
        '171': 32.17294509460095,
        '172': 32.17288760995548,
        '173': 32.172531395704326,
        '174': 32.17256440829733,
        '175': 32.17285154909436,
        '176': 32.173110138190204,
        '177': 32.173123430639016,
        '178': 32.17289089183559,
        '179': 32.17261145300937,
        '180': 32.17251644053221,
        '-180': 32.17251644053221,
        '-179': 32.17268562714329,
        '-178': 32.1729935284581,
        '-177': 32.17322050557176,
        '-176': 32.17322195877382,
        '-175': 32.173011760383226,
        '-174': 32.17271217523797,
        '-173': 32.172445309938645,
        '-172': 32.172268215400756,
        '-171': 32.17217974482256,
        '-170': 32.172153752601524,
        '-169': 32.17215676699565,
        '-168': 32.172156298477645,
        '-167': 32.17213954217491,
        '-166': 32.172129185742506,
        '-165': 32.17216602030267,
        '-164': 32.172264836374254,
        '-163': 32.17239267838763,
        '-162': 32.172500157290344,
        '-161': 32.17257185833228,
        '-160': 32.1726350442106,
        '-159': 32.17271660778103,
        '-158': 32.17280320782735,
        '-157': 32.17285397313355,
        '-156': 32.172846855149956,
        '-155': 32.17279903285035,
        '-154': 32.17274014336255,
        '-153': 32.17267945408304,
        '-152': 32.17261047170419,
        '-151': 32.172540236223654,
        '-150': 32.172496324776326,
        '-149': 32.17249831611354,
        '-148': 32.17253189408528,
        '-147': 32.17256061570519,
        '-146': 32.17255975588324,
        '-145': 32.17253094623357,
        '-144': 32.17248800445537,
        '-143': 32.172442677875665,
        '-142': 32.17240853230694,
        '-141': 32.17240388154443,
        '-140': 32.17243393322675,
        '-139': 32.17247288504308,
        '-138': 32.17248225005293,
        '-137': 32.1724557829349,
        '-136': 32.172433368462166,
        '-135': 32.17245426639145,
        '-134': 32.172501199564216,
        '-133': 32.17251178138302,
        '-132': 32.172454792581334,
        '-131': 32.17237949397786,
        '-130': 32.172368898617975,
        '-129': 32.172445922781584,
        '-128': 32.17254633309262,
        '-127': 32.172594134682846,
        '-126': 32.17258727308803,
        '-125': 32.17258832456076,
        '-124': 32.172636300051145,
        '-123': 32.172696038515674,
        '-122': 32.17270985104428,
        '-121': 32.172679387978356,
        '-120': 32.1726647418624,
        '-119': 32.172698939535735,
        '-118': 32.17273353673098,
        '-117': 32.172696174291865,
        '-116': 32.17259310599767,
        '-115': 32.17252114997538,
        '-114': 32.172560590308294,
        '-113': 32.17267347563869,
        '-112': 32.17273534217804,
        '-111': 32.17267110692053,
        '-110': 32.17253885527216,
        '-109': 32.172464854353585,
        '-108': 32.17250705687596,
        '-107': 32.17260306200865,
        '-106': 32.17265275567142,
        '-105': 32.17262966080663,
        '-104': 32.1725940098885,
        '-103': 32.17260752850929,
        '-102': 32.1726624402512,
        '-101': 32.17270487365347,
        '-100': 32.17270967223188,
        '-99': 32.17270673355252,
        '-98': 32.17273058562668,
        '-97': 32.1727654465436,
        '-96': 32.17276049174079,
        '-95': 32.17269680339652,
        '-94': 32.17261915975173,
        '-93': 32.17258790195661,
        '-92': 32.172606218401825,
        '-91': 32.17261331751269,
        '-90': 32.1725590362068,
        '-89': 32.17247622317534,
        '-88': 32.172460628340055,
        '-87': 32.1725678566927,
        '-86': 32.17273594127069,
        '-85': 32.172827530378726,
        '-84': 32.172763382837346,
        '-83': 32.17261362751371,
        '-82': 32.17254203763172,
        '-81': 32.17264444569807,
        '-80': 32.17284093747278,
        '-79': 32.17294249939699,
        '-78': 32.17284119298554,
        '-77': 32.17263793909546,
        '-76': 32.172569917881724,
        '-75': 32.17279475103009,
        '-74': 32.17323658209636,
        '-73': 32.17188117773556,
        '-72': 32.167151458975766,
        '-71': 32.1657281923922,
        '-70': 32.16778569115509,
        '-69': 32.169038389820415,
        '-68': 32.16812782229243,
        '-67': 32.16730692955865,
        '-66': 32.17227189663426,
        '-65': 32.172347270142104,
        '-64': 32.17245037891336,
        '-63': 32.17268603837578,
        '-62': 32.1728796029943,
        '-61': 32.17286508228448,
        '-60': 32.172609336946536,
        '-59': 32.17222611018347,
        '-58': 32.17188501273471,
        '-57': 32.171705316454315,
        '-56': 32.17170812322928,
        '-55': 32.1718312153278,
        '-54': 32.17197349659906,
        '-53': 32.17204626428253,
        '-52': 32.17201992701064,
        '-51': 32.171943141728924,
        '-50': 32.17190931587508,
        '-49': 32.17198289051766,
        '-48': 32.172143450347946,
        '-47': 32.17229969503517,
        '-46': 32.17236180671405,
        '-45': 32.17230543015971,
        '-44': 32.17217404869016,
        '-43': 32.17203115335442,
        '-42': 32.171916019841504,
        '-41': 32.1718368991116,
        '-40': 32.17178790035564,
        '-39': 32.17175958263548,
        '-38': 32.17173790892182,
        '-37': 32.171709075957274,
        '-36': 32.171676284770605,
        '-35': 32.17166846103614,
        '-34': 32.171721631210296,
        '-33': 32.17184565953064,
        '-32': 32.17201189911852,
        '-31': 32.17217795895475,
        '-30': 32.17232365254491,
        '-29': 32.17245819017041,
        '-28': 32.17259242675565,
        '-27': 32.17271266224066,
        '-26': 32.172791338515026,
        '-25': 32.172823423261754,
        '-24': 32.17284118134229,
        '-23': 32.172882314399345,
        '-22': 32.17294518677734,
        '-21': 32.17298816316963,
        '-20': 32.17298204445659,
        '-19': 32.172956475356166,
        '-18': 32.17297801033244,
        '-17': 32.17307383897063,
        '-16': 32.173186370940215,
        '-15': 32.17321967661869,
        '-14': 32.17313635298438,
        '-13': 32.17299905847589,
        '-12': 32.172906526263006,
        '-11': 32.17289240039023,
        '-10': 32.17289948076876,
        '-9': 32.17285614688978,
        '-8': 32.172763801837384,
        '-7': 32.172693311112106,
        '-6': 32.172697647137824,
        '-5': 32.17274643591281,
        '-4': 32.172758627939864,
        '-3': 32.172690635518414,
        '-2': 32.17257478574919,
        '-1': 32.17246987984891
    },
    '-46': {
        '0': 32.175537247453846,
        '1': 32.175372561982584,
        '2': 32.17520504944455,
        '3': 32.17513023547395,
        '4': 32.17520170273767,
        '5': 32.17537113129752,
        '6': 32.17551836784284,
        '7': 32.17555524006593,
        '8': 32.17550168128989,
        '9': 32.17545406212134,
        '10': 32.17547851826545,
        '11': 32.175543526555394,
        '12': 32.17556333570922,
        '13': 32.175502553474885,
        '14': 32.175426135807086,
        '15': 32.175439737484105,
        '16': 32.17558358730396,
        '17': 32.17578962570331,
        '18': 32.17594129333752,
        '19': 32.1759704619307,
        '20': 32.17589819631732,
        '21': 32.17579423151103,
        '22': 32.1757107475529,
        '23': 32.17565531687393,
        '24': 32.17561106583772,
        '25': 32.175563852204114,
        '26': 32.175506959300144,
        '27': 32.17543626031242,
        '28': 32.17536187452427,
        '29': 32.17533003941013,
        '30': 32.1754179017241,
        '31': 32.17568213877655,
        '32': 32.176096401209875,
        '33': 32.17654000645897,
        '34': 32.1768621772727,
        '35': 32.17697539817676,
        '36': 32.17690055849146,
        '37': 32.1767295914268,
        '38': 32.17654670108564,
        '39': 32.17638228983043,
        '40': 32.176233219207134,
        '41': 32.17611252937006,
        '42': 32.176064109096856,
        '43': 32.17612100272752,
        '44': 32.176254347161006,
        '45': 32.17637942370798,
        '46': 32.176429536331156,
        '47': 32.17643002752567,
        '48': 32.176487897190256,
        '49': 32.17668702314705,
        '50': 32.1769802268664,
        '51': 32.17719239308107,
        '52': 32.17715459736837,
        '53': 32.17685800522401,
        '54': 32.176480289186536,
        '55': 32.176246343532874,
        '56': 32.17624292241429,
        '57': 32.176356670655366,
        '58': 32.17638983594052,
        '59': 32.17623868978784,
        '60': 32.17596680823793,
        '61': 32.17571998651103,
        '62': 32.17558803801706,
        '63': 32.17555558041625,
        '64': 32.175570123881926,
        '65': 32.17562587675181,
        '66': 32.175759383576576,
        '67': 32.17597076577303,
        '68': 32.17617833741449,
        '69': 32.17627481746541,
        '70': 32.17622981014551,
        '71': 32.17612282556181,
        '72': 32.17606529570811,
        '73': 32.176093252841426,
        '74': 32.1761438449989,
        '75': 32.17613497134491,
        '76': 32.17605638867904,
        '77': 32.17597546532842,
        '78': 32.17596036588076,
        '79': 32.17601136493926,
        '80': 32.17607214549518,
        '81': 32.17609765502984,
        '82': 32.176095905440015,
        '83': 32.17610075555305,
        '84': 32.17611757076632,
        '85': 32.17611267766647,
        '86': 32.17606013765842,
        '87': 32.17598661203967,
        '88': 32.17595390755897,
        '89': 32.17599147457612,
        '90': 32.176053845494856,
        '91': 32.17605681988813,
        '92': 32.175961243712884,
        '93': 32.17581748094743,
        '94': 32.17572061864206,
        '95': 32.175719342647845,
        '96': 32.17577147453448,
        '97': 32.175791574001785,
        '98': 32.17573998927514,
        '99': 32.1756600712413,
        '100': 32.175626473729835,
        '101': 32.17566235036524,
        '102': 32.17571498559127,
        '103': 32.17571426794954,
        '104': 32.17564794177831,
        '105': 32.1755708625929,
        '106': 32.17554022171807,
        '107': 32.17555113255894,
        '108': 32.17554478402001,
        '109': 32.17547763697522,
        '110': 32.175370786851374,
        '111': 32.175283430839336,
        '112': 32.17524436828705,
        '113': 32.175224799889804,
        '114': 32.17518491464813,
        '115': 32.1751351325656,
        '116': 32.17513145281929,
        '117': 32.17520340779391,
        '118': 32.17530075513655,
        '119': 32.17533233016585,
        '120': 32.175264149049944,
        '121': 32.175166487261855,
        '122': 32.175148359570805,
        '123': 32.17524436831949,
        '124': 32.1753781589086,
        '125': 32.17544483851614,
        '126': 32.175421026365136,
        '127': 32.17537958569768,
        '128': 32.17539536597863,
        '129': 32.1754535239186,
        '130': 32.175468015081364,
        '131': 32.17538900849659,
        '132': 32.17527394184564,
        '133': 32.1752378134096,
        '134': 32.17533533118762,
        '135': 32.175502656153554,
        '136': 32.17561741001419,
        '137': 32.17560721603456,
        '138': 32.17549541249402,
        '139': 32.17535421341341,
        '140': 32.175235015327225,
        '141': 32.17515024504486,
        '142': 32.17510255332516,
        '143': 32.17510265372651,
        '144': 32.17514927601288,
        '145': 32.175208658199715,
        '146': 32.175235365467515,
        '147': 32.17521605561806,
        '148': 32.17517888012319,
        '149': 32.175152412562646,
        '150': 32.175128061307255,
        '151': 32.175081257330795,
        '152': 32.17502753071058,
        '153': 32.17503050702923,
        '154': 32.175128612291026,
        '155': 32.17525888088498,
        '156': 32.17528829452127,
        '157': 32.17515235697785,
        '158': 32.17495912893863,
        '159': 32.17491905535192,
        '160': 32.17513554983663,
        '161': 32.17546318727257,
        '162': 32.17560799843837,
        '163': 32.17541083538981,
        '164': 32.17504880967233,
        '165': 32.174935101081765,
        '166': 32.17537176720833,
        '167': 32.17052139443807,
        '168': 32.17550999554851,
        '169': 32.175837055759445,
        '170': 32.17464356010582,
        '171': 32.17611387335119,
        '172': 32.17531855057757,
        '173': 32.17495149667069,
        '174': 32.175025113440455,
        '175': 32.175275754779605,
        '176': 32.175414619667244,
        '177': 32.175334871077034,
        '178': 32.17513105001328,
        '179': 32.17496225298504,
        '180': 32.17490780145329,
        '-180': 32.17490780145329,
        '-179': 32.174936890788146,
        '-178': 32.174987090549855,
        '-177': 32.17504633714578,
        '-176': 32.17514927435649,
        '-175': 32.175304270896156,
        '-174': 32.17544714673467,
        '-173': 32.17548616851995,
        '-172': 32.17539553205151,
        '-171': 32.175252284129044,
        '-170': 32.175168360104664,
        '-169': 32.17518521404507,
        '-168': 32.1752426480049,
        '-167': 32.17525318044091,
        '-166': 32.17519803443083,
        '-165': 32.17514117520222,
        '-164': 32.175153841720515,
        '-163': 32.17524054768803,
        '-162': 32.175346667645584,
        '-161': 32.17542745762239,
        '-160': 32.17549130272523,
        '-159': 32.17557117742335,
        '-158': 32.17566916357337,
        '-157': 32.17574641579339,
        '-156': 32.17576774225577,
        '-155': 32.17574102646245,
        '-154': 32.17570246659895,
        '-153': 32.17566997993099,
        '-152': 32.175626334877705,
        '-151': 32.175553353498884,
        '-150': 32.175472272728385,
        '-149': 32.175435731957954,
        '-148': 32.1754743017185,
        '-147': 32.1755556242365,
        '-146': 32.175603945316695,
        '-145': 32.175565299623116,
        '-144': 32.17545658008884,
        '-143': 32.17535138307769,
        '-142': 32.17531457447719,
        '-141': 32.17534502268466,
        '-140': 32.175380018337144,
        '-139': 32.17535981141131,
        '-138': 32.17529261099416,
        '-137': 32.17525352586481,
        '-136': 32.175310499767875,
        '-135': 32.17544697461006,
        '-134': 32.17556513962999,
        '-133': 32.17557716183079,
        '-132': 32.17549511260904,
        '-131': 32.17542136085295,
        '-130': 32.17544304461455,
        '-129': 32.175541964701694,
        '-128': 32.175619639012574,
        '-127': 32.17561005579684,
        '-126': 32.1755519412824,
        '-125': 32.175538201593056,
        '-124': 32.17560349535163,
        '-123': 32.17568358070135,
        '-122': 32.175694020342,
        '-121': 32.17563265995673,
        '-120': 32.175582272310635,
        '-119': 32.17561090000481,
        '-118': 32.17569080437697,
        '-117': 32.17573313302953,
        '-116': 32.17569468499266,
        '-115': 32.17562736411154,
        '-114': 32.17561265911787,
        '-113': 32.175662663047504,
        '-112': 32.17570656851414,
        '-111': 32.17567574764798,
        '-110': 32.17558347980551,
        '-109': 32.175506955090135,
        '-108': 32.17549874302393,
        '-107': 32.17553454337808,
        '-106': 32.17555269335174,
        '-105': 32.175529417089926,
        '-104': 32.1754988719535,
        '-103': 32.175502781435895,
        '-102': 32.17553971575182,
        '-101': 32.17557540525448,
        '-100': 32.17559254944155,
        '-99': 32.175610592205544,
        '-98': 32.175650611624896,
        '-97': 32.17569559177342,
        '-96': 32.17570360430722,
        '-95': 32.17566157349475,
        '-94': 32.17561021137138,
        '-93': 32.17560151487762,
        '-92': 32.17563444657769,
        '-91': 32.17564842644573,
        '-90': 32.175591264931576,
        '-89': 32.17548803442043,
        '-88': 32.17542755550834,
        '-87': 32.17547211582226,
        '-86': 32.175586664633514,
        '-85': 32.17567031647628,
        '-84': 32.175662964157134,
        '-83': 32.17561204500557,
        '-82': 32.17562082749614,
        '-81': 32.175728300690615,
        '-80': 32.1758534410764,
        '-79': 32.17587602194974,
        '-78': 32.17577810869886,
        '-77': 32.17569305957458,
        '-76': 32.17579415628642,
        '-75': 32.175838747230955,
        '-74': 32.17632261388606,
        '-73': 32.16853095621523,
        '-72': 32.16725646530951,
        '-71': 32.167480976716256,
        '-70': 32.170055374904116,
        '-69': 32.172185438246885,
        '-68': 32.171979177404964,
        '-67': 32.17559968217603,
        '-66': 32.1753982779894,
        '-65': 32.1753384102554,
        '-64': 32.17548473713474,
        '-63': 32.17574841296785,
        '-62': 32.17592831727898,
        '-61': 32.175865088178654,
        '-60': 32.17556417067341,
        '-59': 32.175178875309875,
        '-58': 32.17488324159871,
        '-57': 32.174756021446996,
        '-56': 32.174763516966046,
        '-55': 32.17482424483913,
        '-54': 32.174876972455806,
        '-53': 32.17490065226016,
        '-52': 32.17489752314929,
        '-51': 32.17487704412994,
        '-50': 32.174855823219424,
        '-49': 32.17485938961948,
        '-48': 32.17491072893063,
        '-47': 32.175010652175594,
        '-46': 32.17512803017714,
        '-45': 32.17521136336709,
        '-44': 32.17521710071043,
        '-43': 32.175138012114914,
        '-42': 32.17501215553347,
        '-41': 32.17490161093003,
        '-40': 32.17485037454314,
        '-39': 32.17485180578536,
        '-38': 32.17485609489059,
        '-37': 32.17481732127435,
        '-36': 32.174740641602,
        '-35': 32.1746838573575,
        '-34': 32.174708171787216,
        '-33': 32.17482384409786,
        '-32': 32.17498362383603,
        '-31': 32.17512878256018,
        '-30': 32.175238507058694,
        '-29': 32.17533244039547,
        '-28': 32.175430164886805,
        '-27': 32.17551979862262,
        '-26': 32.175574650051196,
        '-25': 32.17559647014261,
        '-24': 32.17562767174957,
        '-23': 32.175709175664636,
        '-22': 32.17582861125552,
        '-21': 32.175923780277756,
        '-20': 32.17594765918292,
        '-19': 32.17592640222394,
        '-18': 32.17594015918683,
        '-17': 32.176038532399915,
        '-16': 32.176179640421886,
        '-15': 32.176263225317705,
        '-14': 32.17622817096443,
        '-13': 32.17611270219052,
        '-12': 32.17601380932615,
        '-11': 32.175990309597594,
        '-10': 32.17601185007648,
        '-9': 32.17600179207788,
        '-8': 32.17592016589227,
        '-7': 32.17579796637891,
        '-6': 32.175697368203544,
        '-5': 32.17565272764202,
        '-4': 32.17565366527602,
        '-3': 32.17567005185155,
        '-2': 32.17567414725144,
        '-1': 32.17563865488328
    },
    '-47': {
        '0': 32.17856324144838,
        '1': 32.17842877092457,
        '2': 32.178230994108766,
        '3': 32.17809026829591,
        '4': 32.17810418985489,
        '5': 32.178267448221376,
        '6': 32.17847392419029,
        '7': 32.17860283301089,
        '8': 32.1786107858602,
        '9': 32.1785482682203,
        '10': 32.17849572889582,
        '11': 32.178490155357665,
        '12': 32.17851110401612,
        '13': 32.17852533463312,
        '14': 32.178531140498734,
        '15': 32.178555506943404,
        '16': 32.1786160938343,
        '17': 32.17869577904752,
        '18': 32.17875566989687,
        '19': 32.17876690109678,
        '20': 32.17872652763193,
        '21': 32.17864880793936,
        '22': 32.178551668389645,
        '23': 32.178454836530626,
        '24': 32.17838214151293,
        '25': 32.17835270008239,
        '26': 32.17836572467115,
        '27': 32.178402112012144,
        '28': 32.17845187831256,
        '29': 32.17854124268472,
        '30': 32.17872118998462,
        '31': 32.1790141325249,
        '32': 32.179365183070466,
        '33': 32.1796529720786,
        '34': 32.17976427121704,
        '35': 32.179673381140866,
        '36': 32.17945653525709,
        '37': 32.17922845113942,
        '38': 32.179058967176154,
        '39': 32.1789431341562,
        '40': 32.178843354240506,
        '41': 32.178752088884,
        '42': 32.178707831399514,
        '43': 32.178749977835054,
        '44': 32.178865078916246,
        '45': 32.17898836860617,
        '46': 32.17906660281726,
        '47': 32.1791188414789,
        '48': 32.179224525568664,
        '49': 32.17943750426754,
        '50': 32.17970569942431,
        '51': 32.17988427562478,
        '52': 32.17984728130094,
        '53': 32.17960336117726,
        '54': 32.17930525349678,
        '55': 32.17913457642752,
        '56': 32.179159572058204,
        '57': 32.17929057335514,
        '58': 32.17936701008994,
        '59': 32.1792897292041,
        '60': 32.179081219440555,
        '61': 32.17883899426887,
        '62': 32.178651642815545,
        '63': 32.17856448121866,
        '64': 32.1786046200364,
        '65': 32.17880056451981,
        '66': 32.17914751360397,
        '67': 32.1795540366923,
        '68': 32.179852425112514,
        '69': 32.17989876242843,
        '70': 32.17968580086106,
        '71': 32.17935931847956,
        '72': 32.17911177043804,
        '73': 32.17904391412363,
        '74': 32.17911414551177,
        '75': 32.179207312828275,
        '76': 32.179243259994216,
        '77': 32.17922306202284,
        '78': 32.17918990264163,
        '79': 32.179168388415206,
        '80': 32.17915057193464,
        '81': 32.1791280622003,
        '82': 32.17911506041874,
        '83': 32.179127882201996,
        '84': 32.17914986013635,
        '85': 32.17913493817472,
        '86': 32.17905714997241,
        '87': 32.17895176183041,
        '88': 32.1788934581401,
        '89': 32.178924154139565,
        '90': 32.17900409372021,
        '91': 32.17904300112719,
        '92': 32.1789859778644,
        '93': 32.17886842912607,
        '94': 32.17878260393079,
        '95': 32.17878818685849,
        '96': 32.178856770062644,
        '97': 32.17890434553681,
        '98': 32.17887511730939,
        '99': 32.17878978488535,
        '100': 32.17871306470125,
        '101': 32.17868135845224,
        '102': 32.178669431664524,
        '103': 32.17862713843801,
        '104': 32.17853979788018,
        '105': 32.17844430854755,
        '106': 32.17838744760343,
        '107': 32.17837794854055,
        '108': 32.17838595671971,
        '109': 32.178383878342,
        '110': 32.17837450206506,
        '111': 32.17837283458815,
        '112': 32.17837046530667,
        '113': 32.178336484050924,
        '114': 32.17826226696333,
        '115': 32.178194229625916,
        '116': 32.17820107155213,
        '117': 32.178297245482696,
        '118': 32.17840722683175,
        '119': 32.17842394206356,
        '120': 32.17831286325669,
        '121': 32.17815317708675,
        '122': 32.17806499792152,
        '123': 32.17809263547501,
        '124': 32.17816611065222,
        '125': 32.178183030439016,
        '126': 32.178123203830864,
        '127': 32.17807120045995,
        '128': 32.1781210361321,
        '129': 32.17826590628648,
        '130': 32.178393772388375,
        '131': 32.178396853286465,
        '132': 32.17828198481098,
        '133': 32.17816840295939,
        '134': 32.17817634073121,
        '135': 32.178317760393334,
        '136': 32.178490942549665,
        '137': 32.178573806162454,
        '138': 32.17852211685893,
        '139': 32.178389795976166,
        '140': 32.17827094493083,
        '141': 32.17822692199169,
        '142': 32.178256024134754,
        '143': 32.17831438577162,
        '144': 32.17835905334115,
        '145': 32.17837971179848,
        '146': 32.17839985016169,
        '147': 32.178446630804594,
        '148': 32.178510641576516,
        '149': 32.17853385270605,
        '150': 32.17845163398351,
        '151': 32.17826447567137,
        '152': 32.17806865606038,
        '153': 32.177994164707926,
        '154': 32.17808716456731,
        '155': 32.178249701714165,
        '156': 32.17831613949094,
        '157': 32.1782128038469,
        '158': 32.178044387744144,
        '159': 32.178001732878705,
        '160': 32.17816288754804,
        '161': 32.17838757183973,
        '162': 32.17843529185312,
        '163': 32.17821635794259,
        '164': 32.17793001235593,
        '165': 32.177926406249135,
        '166': 32.178389310576165,
        '167': 32.17912617404908,
        '168': 32.17665621147286,
        '169': 32.1796638451873,
        '170': 32.17909704333609,
        '171': 32.17835597030051,
        '172': 32.17788773439774,
        '173': 32.17787735342417,
        '174': 32.17816442998005,
        '175': 32.17844218701947,
        '176': 32.17852974533589,
        '177': 32.1784732061284,
        '178': 32.17841859957724,
        '179': 32.178418046828625,
        '180': 32.178374126439444,
        '-180': 32.178374126439444,
        '-179': 32.17816901153015,
        '-178': 32.17783088564063,
        '-177': 32.17755326301966,
        '-176': 32.1775351176467,
        '-175': 32.17779523374912,
        '-174': 32.17814554314267,
        '-173': 32.17835336878292,
        '-172': 32.17833737320131,
        '-171': 32.1782128633216,
        '-170': 32.178155578661446,
        '-169': 32.17823016346012,
        '-168': 32.17835118063916,
        '-167': 32.1783970202514,
        '-166': 32.17834181460837,
        '-165': 32.17826817655011,
        '-164': 32.17826398559187,
        '-163': 32.17832866861597,
        '-162': 32.17838927092888,
        '-161': 32.17839496667776,
        '-160': 32.178374953766486,
        '-159': 32.1783975478232,
        '-158': 32.178486282568116,
        '-157': 32.178592861212934,
        '-156': 32.17865295766636,
        '-155': 32.17865352377313,
        '-154': 32.17863366681733,
        '-153': 32.17862604378756,
        '-152': 32.17861609952413,
        '-151': 32.17856980604301,
        '-150': 32.178492947514926,
        '-149': 32.178443440240365,
        '-148': 32.17847273552043,
        '-147': 32.178559275468814,
        '-146': 32.17861425375573,
        '-145': 32.1785642490071,
        '-144': 32.17842835900383,
        '-143': 32.17830587703429,
        '-142': 32.17828047250494,
        '-141': 32.17833602466055,
        '-140': 32.1783727615717,
        '-139': 32.17831211072039,
        '-138': 32.17818500394074,
        '-137': 32.178109808565274,
        '-136': 32.17817652079077,
        '-135': 32.17835301522475,
        '-134': 32.17851169635434,
        '-133': 32.178552754862416,
        '-132': 32.17849815646868,
        '-131': 32.17845889492276,
        '-130': 32.178511601983814,
        '-129': 32.17861785399946,
        '-128': 32.17867284678101,
        '-127': 32.17862755467524,
        '-126': 32.178546785453676,
        '-125': 32.178537195266024,
        '-124': 32.17862576068909,
        '-123': 32.178725879710576,
        '-122': 32.178730450851454,
        '-121': 32.17862915492067,
        '-120': 32.178521023334945,
        '-119': 32.17851080671021,
        '-118': 32.17860411406315,
        '-117': 32.178711285873476,
        '-116': 32.17874589745755,
        '-115': 32.17870509313151,
        '-114': 32.178651800742585,
        '-113': 32.17863427250094,
        '-112': 32.17863997189147,
        '-111': 32.1786268641266,
        '-110': 32.178581361176136,
        '-109': 32.17853058802388,
        '-108': 32.178502313881246,
        '-107': 32.17849006629886,
        '-106': 32.17846711209761,
        '-105': 32.17842681065832,
        '-104': 32.178394998962744,
        '-103': 32.178399100645926,
        '-102': 32.178434436003194,
        '-101': 32.178469771843766,
        '-100': 32.17848288439881,
        '-99': 32.178480309494184,
        '-98': 32.17847896239467,
        '-97': 32.1784781984826,
        '-96': 32.17846256809008,
        '-95': 32.17843304229231,
        '-94': 32.17842306902568,
        '-93': 32.17847051198309,
        '-92': 32.1785719046531,
        '-91': 32.1786731070554,
        '-90': 32.17871313186618,
        '-89': 32.178677184582895,
        '-88': 32.17860310575011,
        '-87': 32.17853820489666,
        '-86': 32.17849918631349,
        '-85': 32.17847957578603,
        '-84': 32.178486070372585,
        '-83': 32.17854527818913,
        '-82': 32.17865938209938,
        '-81': 32.1787638301339,
        '-80': 32.17875855676303,
        '-79': 32.17860928222872,
        '-78': 32.17842151853586,
        '-77': 32.17838970373811,
        '-76': 32.178638870675776,
        '-75': 32.1790968860323,
        '-74': 32.179446978640655,
        '-73': 32.176845438338354,
        '-72': 32.15962526278845,
        '-71': 32.16704260030396,
        '-70': 32.17121749316067,
        '-69': 32.175153749113164,
        '-68': 32.17745898774146,
        '-67': 32.17660799586701,
        '-66': 32.17841998179621,
        '-65': 32.17833830018013,
        '-64': 32.17848371672884,
        '-63': 32.178767624406625,
        '-62': 32.17897950174845,
        '-61': 32.17895750642498,
        '-60': 32.17871482075318,
        '-59': 32.17840843553493,
        '-58': 32.17819266301382,
        '-57': 32.17810142267321,
        '-56': 32.178058089329994,
        '-55': 32.17797936836132,
        '-54': 32.17785827601593,
        '-53': 32.17775165528176,
        '-52': 32.17770306432565,
        '-51': 32.17769109226824,
        '-50': 32.17765553696546,
        '-49': 32.17756882056515,
        '-48': 32.17747546201214,
        '-47': 32.17745703741643,
        '-46': 32.177554383706855,
        '-45': 32.177720770985765,
        '-44': 32.1778521959438,
        '-43': 32.17787086520676,
        '-42': 32.17778904426925,
        '-41': 32.17769667153996,
        '-40': 32.17768361501421,
        '-39': 32.17776595386326,
        '-38': 32.17788137193192,
        '-37': 32.17795438870896,
        '-36': 32.17796750980271,
        '-35': 32.17797132223331,
        '-34': 32.17802807998248,
        '-33': 32.17814830425917,
        '-32': 32.17828330281394,
        '-31': 32.178376213664215,
        '-30': 32.17841424758179,
        '-29': 32.17842821459033,
        '-28': 32.17844648195115,
        '-27': 32.17846080355389,
        '-26': 32.1784449301807,
        '-25': 32.17840269922223,
        '-24': 32.17838442719326,
        '-23': 32.17844446233379,
        '-22': 32.17858223217246,
        '-21': 32.17873424759709,
        '-20': 32.17883262568809,
        '-19': 32.17887152928051,
        '-18': 32.178909986171966,
        '-17': 32.17900570148574,
        '-16': 32.17914904395302,
        '-15': 32.17926726523287,
        '-14': 32.17929446734178,
        '-13': 32.179234011741926,
        '-12': 32.179150574555365,
        '-11': 32.17910427982581,
        '-10': 32.17909613728623,
        '-9': 32.1790751184349,
        '-8': 32.178991008069644,
        '-7': 32.17883802958489,
        '-6': 32.178656101073244,
        '-5': 32.17850250182124,
        '-4': 32.178423138367414,
        '-3': 32.17843406273765,
        '-2': 32.17850889113355,
        '-1': 32.17857877354138
    },
    '-48': {
        '0': 32.18140886038865,
        '1': 32.181379314972006,
        '2': 32.18126361835538,
        '3': 32.18114501850514,
        '4': 32.18112804153793,
        '5': 32.18125324103905,
        '6': 32.18145955577832,
        '7': 32.18162790432968,
        '8': 32.18166897510648,
        '9': 32.18158263623871,
        '10': 32.18144607932541,
        '11': 32.181349824541755,
        '12': 32.18133728324685,
        '13': 32.18138920781737,
        '14': 32.181452545254395,
        '15': 32.181483702580394,
        '16': 32.18147591850927,
        '17': 32.181457821962475,
        '18': 32.18146807374411,
        '19': 32.18152283454495,
        '20': 32.181597944599844,
        '21': 32.1816414055202,
        '22': 32.18161111266023,
        '23': 32.181509834838415,
        '24': 32.18138702944198,
        '25': 32.18130405629512,
        '26': 32.18129364471157,
        '27': 32.18135059954562,
        '28': 32.1814585422968,
        '29': 32.1816184110752,
        '30': 32.18184153060823,
        '31': 32.18211028935656,
        '32': 32.18235166238646,
        '33': 32.182464876724715,
        '34': 32.18239235208639,
        '35': 32.182173396798234,
        '36': 32.181926328942616,
        '37': 32.181766336165055,
        '38': 32.18172634355825,
        '39': 32.18174777914515,
        '40': 32.18174578730193,
        '41': 32.181687359976294,
        '42': 32.18161288695525,
        '43': 32.18158770494231,
        '44': 32.18163564076771,
        '45': 32.18171940407469,
        '46': 32.18178305376413,
        '47': 32.18180991399417,
        '48': 32.18183596658422,
        '49': 32.18190584332416,
        '50': 32.182018054537046,
        '51': 32.182117751777135,
        '52': 32.18214691395002,
        '53': 32.182102256743946,
        '54': 32.182041614872,
        '55': 32.18203076958921,
        '56': 32.182082650871635,
        '57': 32.18214774261541,
        '58': 32.182161689469154,
        '59': 32.18209980138118,
        '60': 32.181987550511145,
        '61': 32.18186887766675,
        '62': 32.181778789897095,
        '63': 32.18175204569371,
        '64': 32.18184419073802,
        '65': 32.18211412683928,
        '66': 32.18255869124489,
        '67': 32.18305849647855,
        '68': 32.18340886288799,
        '69': 32.18343956001772,
        '70': 32.18313648710648,
        '71': 32.182660145381206,
        '72': 32.182239285591464,
        '73': 32.182025196762574,
        '74': 32.18202053736088,
        '75': 32.182123286283776,
        '76': 32.182225802018515,
        '77': 32.18227790274708,
        '78': 32.182279327653944,
        '79': 32.18224099675477,
        '80': 32.182169559459716,
        '81': 32.18208276327369,
        '82': 32.18201840335471,
        '83': 32.18200997372921,
        '84': 32.18205043001278,
        '85': 32.18208928542599,
        '86': 32.18207560159547,
        '87': 32.18200652764415,
        '88': 32.181930042281955,
        '89': 32.1818971502416,
        '90': 32.181912920660125,
        '91': 32.18193727362831,
        '92': 32.18193270506515,
        '93': 32.18190572523024,
        '94': 32.18189676516596,
        '95': 32.18193052064105,
        '96': 32.18198213960901,
        '97': 32.18199704529562,
        '98': 32.18194362398898,
        '99': 32.1818429585407,
        '100': 32.18174567041804,
        '101': 32.18168278193612,
        '102': 32.18164267289227,
        '103': 32.181594169618364,
        '104': 32.181524975687154,
        '105': 32.181452121577166,
        '106': 32.18139783336861,
        '107': 32.18136457934162,
        '108': 32.181339683388934,
        '109': 32.18131936965665,
        '110': 32.18131614317093,
        '111': 32.18133569052584,
        '112': 32.18135315829495,
        '113': 32.181327085626826,
        '114': 32.18124585247931,
        '115': 32.18115506340185,
        '116': 32.18112481850084,
        '117': 32.181180518208635,
        '118': 32.18126922562211,
        '119': 32.18130474996792,
        '120': 32.181251663951834,
        '121': 32.18116089539634,
        '122': 32.18111537043075,
        '123': 32.18114008507036,
        '124': 32.18117343969074,
        '125': 32.18113484449397,
        '126': 32.18101972801253,
        '127': 32.18091908004375,
        '128': 32.18093658789304,
        '129': 32.1810843202736,
        '130': 32.181263630603226,
        '131': 32.18135320324893,
        '132': 32.181318710931194,
        '133': 32.181238508975554,
        '134': 32.18122464515315,
        '135': 32.18131912669675,
        '136': 32.18146251287546,
        '137': 32.181555855115064,
        '138': 32.181549362422786,
        '139': 32.18147550519855,
        '140': 32.181405644975456,
        '141': 32.181380678445954,
        '142': 32.18138200210976,
        '143': 32.18136403688339,
        '144': 32.18131176741238,
        '145': 32.181266643467204,
        '146': 32.18129388194394,
        '147': 32.18141633598334,
        '148': 32.18157316265161,
        '149': 32.181647904278236,
        '150': 32.18155536352582,
        '151': 32.18132000330168,
        '152': 32.18107226832264,
        '153': 32.18095175688302,
        '154': 32.18099291400224,
        '155': 32.181098192064375,
        '156': 32.18113308012498,
        '157': 32.181060358927304,
        '158': 32.18098113874539,
        '159': 32.18103098561232,
        '160': 32.181225508776286,
        '161': 32.18141579743376,
        '162': 32.18141862627333,
        '163': 32.18120897958501,
        '164': 32.18098012819715,
        '165': 32.18098734320536,
        '166': 32.18130280880717,
        '167': 32.18171511384079,
        '168': 32.181888863469624,
        '169': 32.1816572045376,
        '170': 32.18118343213793,
        '171': 32.18083652503144,
        '172': 32.180885351494894,
        '173': 32.18128428751646,
        '174': 32.18173825224673,
        '175': 32.18197611112209,
        '176': 32.18197324676294,
        '177': 32.18192311171036,
        '178': 32.182002689546586,
        '179': 32.182171275883746,
        '180': 32.182201568663956,
        '-180': 32.182201568663956,
        '-179': 32.18191044402399,
        '-178': 32.1813610997523,
        '-177': 32.180837047467016,
        '-176': 32.180609871553656,
        '-175': 32.18071926034801,
        '-174': 32.180966341273134,
        '-173': 32.181112660436064,
        '-172': 32.18108595566853,
        '-171': 32.18100437706801,
        '-170': 32.1810210380498,
        '-169': 32.181162679495074,
        '-168': 32.181321374605545,
        '-167': 32.18138615641692,
        '-166': 32.18135976876934,
        '-165': 32.181338048574304,
        '-164': 32.18138813013856,
        '-163': 32.18146893495863,
        '-162': 32.18148144420324,
        '-161': 32.18138757410506,
        '-160': 32.18126243167948,
        '-159': 32.18122262870507,
        '-158': 32.181310603279876,
        '-157': 32.181458783494726,
        '-156': 32.18156515618309,
        '-155': 32.18159172183753,
        '-154': 32.18158166455496,
        '-153': 32.181589235744795,
        '-152': 32.18161209658467,
        '-151': 32.18160345077628,
        '-150': 32.18154219228555,
        '-149': 32.1814711593076,
        '-148': 32.18145346863822,
        '-147': 32.1814959963417,
        '-146': 32.18153173863528,
        '-145': 32.181488677445074,
        '-144': 32.18137315270432,
        '-143': 32.18127336588018,
        '-142': 32.18127194877211,
        '-141': 32.181356943005916,
        '-140': 32.181427814581205,
        '-139': 32.181395478954535,
        '-138': 32.1812746797417,
        '-137': 32.18117167719274,
        '-136': 32.181178132771194,
        '-135': 32.181280701308296,
        '-134': 32.181379937512304,
        '-133': 32.181396935968294,
        '-132': 32.18135367274564,
        '-131': 32.18134252309975,
        '-130': 32.18142054322593,
        '-129': 32.18154503152117,
        '-128': 32.18162053200768,
        '-127': 32.18160444181217,
        '-126': 32.18155388016354,
        '-125': 32.1815615502382,
        '-124': 32.18165107343823,
        '-123': 32.181747163406094,
        '-122': 32.181753989246154,
        '-121': 32.181657377095554,
        '-120': 32.18154210064219,
        '-119': 32.181508308950804,
        '-118': 32.181576912238604,
        '-117': 32.18167906351268,
        '-116': 32.181730945172674,
        '-115': 32.18170929662091,
        '-114': 32.18165470772372,
        '-113': 32.18161465857924,
        '-112': 32.181596957113584,
        '-111': 32.181578268836134,
        '-110': 32.1815436519277,
        '-109': 32.18150419100996,
        '-108': 32.18147658626415,
        '-107': 32.181457923301814,
        '-106': 32.18143053116275,
        '-105': 32.181389617544845,
        '-104': 32.18135642783865,
        '-103': 32.18135811876381,
        '-102': 32.181396874167284,
        '-101': 32.18144371235617,
        '-100': 32.18146330351243,
        '-99': 32.181442065816924,
        '-98': 32.18139230655409,
        '-97': 32.18133530118819,
        '-96': 32.181287223797085,
        '-95': 32.181260949595504,
        '-94': 32.1812724523819,
        '-93': 32.181335295575764,
        '-92': 32.18144526334129,
        '-91': 32.18157316782235,
        '-90': 32.18167623599778,
        '-89': 32.181718384041474,
        '-88': 32.181683730407045,
        '-87': 32.181581135402496,
        '-86': 32.18144751679306,
        '-85': 32.18134671455034,
        '-84': 32.18134562077097,
        '-83': 32.18146256456285,
        '-82': 32.18162416471328,
        '-81': 32.181688437033735,
        '-80': 32.181549039979586,
        '-79': 32.18125032302177,
        '-78': 32.18100115190042,
        '-77': 32.18104157459099,
        '-76': 32.18145020395764,
        '-75': 32.18177286150684,
        '-74': 32.18186067817469,
        '-73': 32.172870246580274,
        '-72': 32.17340103557214,
        '-71': 32.17555233818579,
        '-70': 32.17463912735517,
        '-69': 32.176165952573214,
        '-68': 32.1808210685068,
        '-67': 32.18027321171231,
        '-66': 32.18096033553362,
        '-65': 32.181170623822084,
        '-64': 32.18128126333945,
        '-63': 32.18156906203502,
        '-62': 32.1818310269118,
        '-61': 32.181897786508976,
        '-60': 32.18176341563871,
        '-59': 32.181558445424336,
        '-58': 32.18140620822463,
        '-57': 32.181310520408964,
        '-56': 32.181179124033946,
        '-55': 32.18094560225883,
        '-54': 32.18065810640801,
        '-53': 32.18044477005043,
        '-52': 32.18039313789853,
        '-51': 32.180465027660276,
        '-50': 32.18053049474425,
        '-49': 32.18048794695411,
        '-48': 32.18035660803623,
        '-47': 32.18025625734258,
        '-46': 32.18029531803677,
        '-45': 32.18047093813551,
        '-44': 32.1806700076657,
        '-43': 32.18076623813806,
        '-42': 32.18072379072026,
        '-41': 32.180617685568535,
        '-40': 32.18056058257016,
        '-39': 32.18060829470614,
        '-38': 32.18072770874446,
        '-37': 32.18084564629365,
        '-36': 32.180921801019096,
        '-35': 32.18097472487418,
        '-34': 32.18104488897483,
        '-33': 32.18114310754069,
        '-32': 32.18124105879605,
        '-31': 32.18130912534889,
        '-30': 32.18135371067666,
        '-29': 32.1814089946213,
        '-28': 32.18149194616428,
        '-27': 32.18157347187402,
        '-26': 32.18160287682129,
        '-25': 32.181563477596676,
        '-24': 32.181499527430674,
        '-23': 32.1814816410692,
        '-22': 32.181542429725944,
        '-21': 32.18164709440032,
        '-20': 32.18172912069565,
        '-19': 32.18175506100472,
        '-18': 32.181754068460734,
        '-17': 32.18178554127288,
        '-16': 32.18188036702005,
        '-15': 32.18201340388776,
        '-14': 32.182128455538894,
        '-13': 32.182184948036365,
        '-12': 32.18218132418481,
        '-11': 32.18214121263844,
        '-10': 32.18208514528879,
        '-9': 32.18201592821066,
        '-8': 32.18192371222151,
        '-7': 32.18179864523367,
        '-6': 32.18164136126886,
        '-5': 32.18147070704215,
        '-4': 32.181325913859865,
        '-3': 32.18125288095923,
        '-2': 32.181272109281124,
        '-1': 32.18135020926188
    },
    '-49': {
        '0': 32.18432887941729,
        '1': 32.18436509804209,
        '2': 32.18436359183759,
        '3': 32.1843312992488,
        '4': 32.184318114932694,
        '5': 32.18436552983073,
        '6': 32.18445927322924,
        '7': 32.184531507639136,
        '8': 32.18451338225958,
        '9': 32.18439347538656,
        '10': 32.18423239131585,
        '11': 32.184121077484704,
        '12': 32.184116255642195,
        '13': 32.184202967551094,
        '14': 32.184311139939176,
        '15': 32.18437122546576,
        '16': 32.18436570345853,
        '17': 32.184338035570335,
        '18': 32.18435364942873,
        '19': 32.18444420354521,
        '20': 32.184579323732294,
        '21': 32.18468829005461,
        '22': 32.18471371262786,
        '23': 32.18465307608127,
        '24': 32.18455542643603,
        '25': 32.18448037404358,
        '26': 32.184458791875045,
        '27': 32.184488575709665,
        '28': 32.18456065031462,
        '29': 32.18467897620307,
        '30': 32.18484702154525,
        '31': 32.18503420001177,
        '32': 32.18516560384104,
        '33': 32.18516184859836,
        '34': 32.1850048544077,
        '35': 32.184770347989186,
        '36': 32.18458806413928,
        '37': 32.18455265064749,
        '38': 32.18465573596486,
        '39': 32.18479630207855,
        '40': 32.1848616460173,
        '41': 32.184812182093026,
        '42': 32.18470239447395,
        '43': 32.18462662285718,
        '44': 32.18464061669569,
        '45': 32.18472423537173,
        '46': 32.18480917960306,
        '47': 32.184839452640176,
        '48': 32.18481106525092,
        '49': 32.1847643881248,
        '50': 32.18474646273892,
        '51': 32.184780064318105,
        '52': 32.184859207980175,
        '53': 32.18496153528884,
        '54': 32.18505825502794,
        '55': 32.18511725647104,
        '56': 32.18511096206049,
        '57': 32.18503476440478,
        '58': 32.18492096924066,
        '59': 32.184826088592885,
        '60': 32.18479226289502,
        '61': 32.18481588149212,
        '62': 32.184859145099935,
        '63': 32.18490098076333,
        '64': 32.18497783336235,
        '65': 32.18516383378665,
        '66': 32.18549469383877,
        '67': 32.18590136316624,
        '68': 32.18622347687312,
        '69': 32.18263794932501,
        '70': 32.186108351102305,
        '71': 32.18572876683077,
        '72': 32.18533733167193,
        '73': 32.18506445571756,
        '74': 32.1849413822965,
        '75': 32.18492318086144,
        '76': 32.184952996663235,
        '77': 32.185001484448264,
        '78': 32.1850571359238,
        '79': 32.18509947983048,
        '80': 32.18509827656487,
        '81': 32.18504251570412,
        '82': 32.18496246868164,
        '83': 32.18491259412094,
        '84': 32.18492619974553,
        '85': 32.18498538174421,
        '86': 32.185036014904206,
        '87': 32.18503300341503,
        '88': 32.184973229620695,
        '89': 32.18488971713135,
        '90': 32.184819928703526,
        '91': 32.18478150753545,
        '92': 32.184772766665056,
        '93': 32.184785992272175,
        '94': 32.184812913319234,
        '95': 32.18483926550958,
        '96': 32.18484362825871,
        '97': 32.18481073493047,
        '98': 32.18474802857731,
        '99': 32.18468468995614,
        '100': 32.18464915938471,
        '101': 32.18464605023072,
        '102': 32.18465621357253,
        '103': 32.184659055686026,
        '104': 32.18465150930011,
        '105': 32.18464259427336,
        '106': 32.184632147622466,
        '107': 32.184602458072035,
        '108': 32.18453693606413,
        '109': 32.18444587059278,
        '110': 32.18436621589225,
        '111': 32.18432894187772,
        '112': 32.18432617078377,
        '113': 32.184315916353576,
        '114': 32.18426413754851,
        '115': 32.18418176765254,
        '116': 32.184116387139625,
        '117': 32.18410550118028,
        '118': 32.18414058104369,
        '119': 32.18418042035272,
        '120': 32.1841984559059,
        '121': 32.18421047652819,
        '122': 32.18425041687642,
        '123': 32.18432096346506,
        '124': 32.18437695740667,
        '125': 32.184366008837884,
        '126': 32.184286815314344,
        '127': 32.184201641201135,
        '128': 32.18418424470567,
        '129': 32.18425245970855,
        '130': 32.18435315139904,
        '131': 32.184415237458424,
        '132': 32.1844175281759,
        '133': 32.18440387356575,
        '134': 32.184432573761136,
        '135': 32.18451318473829,
        '136': 32.18459443648738,
        '137': 32.18461352417267,
        '138': 32.184555791347684,
        '139': 32.184465836815214,
        '140': 32.18440131841501,
        '141': 32.18437709178249,
        '142': 32.184355121894505,
        '143': 32.184289757217726,
        '144': 32.18418393618335,
        '145': 32.184100250240185,
        '146': 32.18411146803707,
        '147': 32.18423130573524,
        '148': 32.18438800052571,
        '149': 32.18446996778186,
        '150': 32.18441066603891,
        '151': 32.184241689190415,
        '152': 32.1840654694333,
        '153': 32.183967917084075,
        '154': 32.1839483127319,
        '155': 32.18393326594217,
        '156': 32.18386501190723,
        '157': 32.18377867862589,
        '158': 32.18378474405794,
        '159': 32.18396101868855,
        '160': 32.18425471106313,
        '161': 32.184500600011155,
        '162': 32.18455690475984,
        '163': 32.184438919836175,
        '164': 32.1843154835017,
        '165': 32.18435455724371,
        '166': 32.1845547179085,
        '167': 32.18472938152611,
        '168': 32.184676149485036,
        '169': 32.184383041970754,
        '170': 32.18407251636746,
        '171': 32.18402446440907,
        '172': 32.18432797363378,
        '173': 32.18479010827238,
        '174': 32.185094461928635,
        '175': 32.185072322526416,
        '176': 32.18484092288177,
        '177': 32.18468258366178,
        '178': 32.18477717295579,
        '179': 32.18503511960964,
        '180': 32.18518230711391,
        '-180': 32.18518230711391,
        '-179': 32.18501280437875,
        '-178': 32.18457350654481,
        '-177': 32.184116729224925,
        '-176': 32.18387769350232,
        '-175': 32.1838901673186,
        '-174': 32.184001133368696,
        '-173': 32.18404592467386,
        '-172': 32.1839993413808,
        '-171': 32.18396305903179,
        '-170': 32.18402654636319,
        '-169': 32.18416159338824,
        '-168': 32.18425925117729,
        '-167': 32.18425731899832,
        '-166': 32.18421118511521,
        '-165': 32.18422874671923,
        '-164': 32.18434234469321,
        '-163': 32.184460716953005,
        '-162': 32.18445752303273,
        '-161': 32.18430519740233,
        '-160': 32.184113180456215,
        '-159': 32.18402972227376,
        '-158': 32.184107843422986,
        '-157': 32.184269218471336,
        '-156': 32.184393915579975,
        '-155': 32.18443547414375,
        '-154': 32.18444438232023,
        '-153': 32.18448843440417,
        '-152': 32.18456693879408,
        '-151': 32.18461282884357,
        '-150': 32.18457340871739,
        '-149': 32.18447431920175,
        '-148': 32.18439454873826,
        '-147': 32.18438199232058,
        '-146': 32.184404618551326,
        '-145': 32.18439116790111,
        '-144': 32.18431555740442,
        '-143': 32.1842312801518,
        '-142': 32.18421726933178,
        '-141': 32.184293242658285,
        '-140': 32.184395377233464,
        '-139': 32.184437988891666,
        '-138': 32.184395470735915,
        '-137': 32.18431942367293,
        '-136': 32.184277387673156,
        '-135': 32.184282793677674,
        '-134': 32.18429120221096,
        '-133': 32.18426227061817,
        '-132': 32.18421470966619,
        '-131': 32.18421139076891,
        '-130': 32.18429089846911,
        '-129': 32.184421698045156,
        '-128': 32.18452999666687,
        '-127': 32.1845712957111,
        '-126': 32.18456872618531,
        '-125': 32.18457927833691,
        '-124': 32.184628122258964,
        '-123': 32.184683547254245,
        '-122': 32.18469696690008,
        '-121': 32.18466001618758,
        '-120': 32.18461468581757,
        '-119': 32.18460761858338,
        '-118': 32.18464153851951,
        '-117': 32.18467661306729,
        '-116': 32.18467623764644,
        '-115': 32.184643601469595,
        '-114': 32.18461025207246,
        '-113': 32.184594747961924,
        '-112': 32.184581611127136,
        '-111': 32.184543588774986,
        '-110': 32.184478434249556,
        '-109': 32.18441562435189,
        '-108': 32.18438491002376,
        '-107': 32.18438331431624,
        '-106': 32.18437885578231,
        '-105': 32.184347452933814,
        '-104': 32.18430233052944,
        '-103': 32.18428349182335,
        '-102': 32.18431763036218,
        '-101': 32.184389210969435,
        '-100': 32.184450874585046,
        '-99': 32.18446198742514,
        '-98': 32.18441919229822,
        '-97': 32.18435406204922,
        '-96': 32.18430470068696,
        '-95': 32.184288677697936,
        '-94': 32.18429850977468,
        '-93': 32.18431850227016,
        '-92': 32.184344161296586,
        '-91': 32.18438518902726,
        '-90': 32.18444850013988,
        '-89': 32.184517149826,
        '-88': 32.18454951342433,
        '-87': 32.18450978516675,
        '-86': 32.184410419866694,
        '-85': 32.1843242005961,
        '-84': 32.18433726400924,
        '-83': 32.1844664646961,
        '-82': 32.18461353086397,
        '-81': 32.18461878886708,
        '-80': 32.1843970520095,
        '-79': 32.184049070861334,
        '-78': 32.18383411836859,
        '-77': 32.18399284357767,
        '-76': 32.18454728963338,
        '-75': 32.18199803047772,
        '-74': 32.18308220640529,
        '-73': 32.177137467781364,
        '-72': 32.1742165238735,
        '-71': 32.179031996840415,
        '-70': 32.178925958090396,
        '-69': 32.18204175563984,
        '-68': 32.18205857377262,
        '-67': 32.18438399635554,
        '-66': 32.184165966133946,
        '-65': 32.18402986960746,
        '-64': 32.184098518011204,
        '-63': 32.184344104334166,
        '-62': 32.18460754100636,
        '-61': 32.184738692502194,
        '-60': 32.18471530943322,
        '-59': 32.18462903856564,
        '-58': 32.18456515424975,
        '-57': 32.18450641919622,
        '-56': 32.184359886600966,
        '-55': 32.18407592189633,
        '-54': 32.18373360433859,
        '-53': 32.18349922820907,
        '-52': 32.18348998404073,
        '-51': 32.183669365395666,
        '-50': 32.183873988278734,
        '-49': 32.18395063684111,
        '-48': 32.18388207713442,
        '-47': 32.18379197806948,
        '-46': 32.18382859878451,
        '-45': 32.18403212517318,
        '-44': 32.18429908607982,
        '-43': 32.18446747718859,
        '-42': 32.18444298217757,
        '-41': 32.18426024822666,
        '-40': 32.184038939633844,
        '-39': 32.18388578103968,
        '-38': 32.1838292109723,
        '-37': 32.18382971039628,
        '-36': 32.18383658184346,
        '-35': 32.183830161858864,
        '-34': 32.18381884122857,
        '-33': 32.18381235270415,
        '-32': 32.18381098040312,
        '-31': 32.183821332741935,
        '-30': 32.183871393877006,
        '-29': 32.1839956340782,
        '-28': 32.18419716612918,
        '-27': 32.18442646989581,
        '-26': 32.184606160484925,
        '-25': 32.18468612595168,
        '-24': 32.18467994778169,
        '-23': 32.18464816817536,
        '-22': 32.18464354043299,
        '-21': 32.18466869781342,
        '-20': 32.1846833129725,
        '-19': 32.18465052302402,
        '-18': 32.18457792544875,
        '-17': 32.1845174322016,
        '-16': 32.1845273033538,
        '-15': 32.18462988157959,
        '-14': 32.18479610478334,
        '-13': 32.184962783512546,
        '-12': 32.18506690611382,
        '-11': 32.185076500290386,
        '-10': 32.18500404222973,
        '-9': 32.18489651525346,
        '-8': 32.184805506352376,
        '-7': 32.18475334127156,
        '-6': 32.18471942088785,
        '-5': 32.18466101907783,
        '-4': 32.18455510963121,
        '-3': 32.1844253128556,
        '-2': 32.18432664785606,
        '-1': 32.18429852425488
    },
    '-50': {
        '0': 32.187386081210285,
        '1': 32.18739995076871,
        '2': 32.18744848184966,
        '3': 32.18747951710563,
        '4': 32.18746868240326,
        '5': 32.187422465519774,
        '6': 32.18735670332696,
        '7': 32.187278249132916,
        '8': 32.187188374692994,
        '9': 32.18710009854148,
        '10': 32.18704575439653,
        '11': 32.18706087179595,
        '12': 32.18715530627835,
        '13': 32.18729793607016,
        '14': 32.187431968793405,
        '15': 32.18751174377641,
        '16': 32.18753216217083,
        '17': 32.187526609706396,
        '18': 32.187535331388695,
        '19': 32.187571472608745,
        '20': 32.187613922338876,
        '21': 32.18763201722997,
        '22': 32.18761804348364,
        '23': 32.187596217545966,
        '24': 32.187599321920246,
        '25': 32.18763607953814,
        '26': 32.187682599639636,
        '27': 32.18770803001953,
        '28': 32.187709114913645,
        '29': 32.18771637859239,
        '30': 32.187760994839635,
        '31': 32.18783267384037,
        '32': 32.18787372212618,
        '33': 32.18782415553322,
        '34': 32.18768272856387,
        '35': 32.18752683716943,
        '36': 32.18746503937149,
        '37': 32.18755494123464,
        '38': 32.18775341698487,
        '39': 32.18794283537354,
        '40': 32.1880148334795,
        '41': 32.187945990347735,
        '42': 32.18780773949654,
        '43': 32.18770738238729,
        '44': 32.18771035446288,
        '45': 32.18780266972653,
        '46': 32.187914445499906,
        '47': 32.187977073197644,
        '48': 32.187967694708924,
        '49': 32.18791423169767,
        '50': 32.1878682870593,
        '51': 32.18787162615294,
        '52': 32.187935732752095,
        '53': 32.18803797384275,
        '54': 32.18813020259803,
        '55': 32.18815792881863,
        '56': 32.18808819611197,
        '57': 32.18793481198147,
        '58': 32.18776071013038,
        '59': 32.18764587242281,
        '60': 32.18763633394543,
        '61': 32.187712454273,
        '62': 32.18780634552691,
        '63': 32.187859017278676,
        '64': 32.18787047259737,
        '65': 32.187897949899096,
        '66': 32.18800181697505,
        '67': 32.18818645482544,
        '68': 32.1883891356306,
        '69': 32.18852626250094,
        '70': 32.188554180928975,
        '71': 32.18849095006546,
        '72': 32.18838545476618,
        '73': 32.18827052417565,
        '74': 32.18814759456679,
        '75': 32.18801306908057,
        '76': 32.18789104838912,
        '77': 32.187830764142575,
        '78': 32.18786567023716,
        '79': 32.18797404844221,
        '80': 32.18808352374021,
        '81': 32.18812103518702,
        '82': 32.18806626400169,
        '83': 32.187962720089885,
        '84': 32.187878992949315,
        '85': 32.18785541906753,
        '86': 32.18787980542927,
        '87': 32.187906388346484,
        '88': 32.187895703251286,
        '89': 32.18784150033386,
        '90': 32.18776844750497,
        '91': 32.18770959955145,
        '92': 32.18768359795752,
        '93': 32.1876852607073,
        '94': 32.187691436266824,
        '95': 32.187676681502865,
        '96': 32.18763047950703,
        '97': 32.18756621321296,
        '98': 32.18751409795227,
        '99': 32.18749988080835,
        '100': 32.18752424140529,
        '101': 32.187561331395116,
        '102': 32.18758051153822,
        '103': 32.18757313284038,
        '104': 32.18755849820157,
        '105': 32.187561329774105,
        '106': 32.187581927001176,
        '107': 32.18759017625309,
        '108': 32.187552572042996,
        '109': 32.18746746144003,
        '110': 32.18737278237018,
        '111': 32.187316003635914,
        '112': 32.18731352962757,
        '113': 32.18733819130286,
        '114': 32.187346214680794,
        '115': 32.18731665109108,
        '116': 32.187265712227266,
        '117': 32.18722555040463,
        '118': 32.18721246237763,
        '119': 32.18721668951706,
        '120': 32.18721984894924,
        '121': 32.187216825814474,
        '122': 32.187217925160425,
        '123': 32.187232502110845,
        '124': 32.18725636614219,
        '125': 32.18727823130658,
        '126': 32.18729540485473,
        '127': 32.18731685661169,
        '128': 32.187347887987826,
        '129': 32.187375698119446,
        '130': 32.18737788145846,
        '131': 32.18735002161286,
        '132': 32.187322294444826,
        '133': 32.18734043593507,
        '134': 32.187422270944914,
        '135': 32.18753087680385,
        '136': 32.187595500862614,
        '137': 32.18756742956383,
        '138': 32.18746224153811,
        '139': 32.18734881476771,
        '140': 32.187292745196494,
        '141': 32.18730351256744,
        '142': 32.1873315772786,
        '143': 32.18731651672807,
        '144': 32.1872420162173,
        '145': 32.18714900602577,
        '146': 32.18709735621516,
        '147': 32.18711281688579,
        '148': 32.18716751430974,
        '149': 32.18720965255687,
        '150': 32.18721174167895,
        '151': 32.187189803738036,
        '152': 32.18717508326923,
        '153': 32.187168425316884,
        '154': 32.187128669257895,
        '155': 32.18701586950653,
        '156': 32.18685266881982,
        '157': 32.186738372571185,
        '158': 32.18678527139193,
        '159': 32.18702107844848,
        '160': 32.18734583045594,
        '161': 32.18759533087399,
        '162': 32.18766871847185,
        '163': 32.1876101461113,
        '164': 32.18756485410702,
        '165': 32.18764133653898,
        '166': 32.18780421569099,
        '167': 32.187903445094186,
        '168': 32.18782146411071,
        '169': 32.18760255874351,
        '170': 32.18743385354757,
        '171': 32.187480834225056,
        '172': 32.187722630846054,
        '173': 32.187945774941454,
        '174': 32.187917072665115,
        '175': 32.18758913870829,
        '176': 32.18715343601967,
        '177': 32.18688753704108,
        '178': 32.18692808327686,
        '179': 32.18716804687022,
        '180': 32.18736327517305,
        '-180': 32.18736327517305,
        '-179': 32.18734483775873,
        '-178': 32.18714570976574,
        '-177': 32.18694046295316,
        '-176': 32.18687309403464,
        '-175': 32.18694165441515,
        '-174': 32.18703637381631,
        '-173': 32.18707099599332,
        '-172': 32.18706374656292,
        '-171': 32.18709161714481,
        '-170': 32.18718133984758,
        '-169': 32.1872646608059,
        '-168': 32.18725025446393,
        '-167': 32.18713362409723,
        '-166': 32.18702082827918,
        '-165': 32.187031217526915,
        '-164': 32.18717448353416,
        '-163': 32.18733005460395,
        '-162': 32.18735555688343,
        '-161': 32.18721969935532,
        '-160': 32.18702945001475,
        '-159': 32.18692766747189,
        '-158': 32.186965494311835,
        '-157': 32.187073448789874,
        '-156': 32.18715001861573,
        '-155': 32.18716836383898,
        '-154': 32.187191193162256,
        '-153': 32.18728614378779,
        '-152': 32.187438196194165,
        '-151': 32.18755418474514,
        '-150': 32.187553834068545,
        '-149': 32.18745077442486,
        '-148': 32.187339584753495,
        '-147': 32.18730377523201,
        '-146': 32.187339456348155,
        '-145': 32.18736949821645,
        '-144': 32.18732853840417,
        '-143': 32.18722996459215,
        '-142': 32.18715007371234,
        '-141': 32.18715131006681,
        '-140': 32.18722438121849,
        '-139': 32.18730294955831,
        '-138': 32.1873283803444,
        '-137': 32.18729669092409,
        '-136': 32.187247021260994,
        '-135': 32.187214476267386,
        '-134': 32.18720121437952,
        '-133': 32.18719094831546,
        '-132': 32.18718161134727,
        '-131': 32.18719496517247,
        '-130': 32.18725311812204,
        '-129': 32.187350724733975,
        '-128': 32.18745461220217,
        '-127': 32.18753026737235,
        '-126': 32.18756641012712,
        '-125': 32.187575227344716,
        '-124': 32.18757501806942,
        '-123': 32.18757790274068,
        '-122': 32.187591767557215,
        '-121': 32.1876238675519,
        '-120': 32.18767308207324,
        '-119': 32.18771879327297,
        '-118': 32.18772718188333,
        '-117': 32.18767918624334,
        '-116': 32.18759488637773,
        '-115': 32.187523843262554,
        '-114': 32.18750273056309,
        '-113': 32.18751917140668,
        '-112': 32.18752074178952,
        '-111': 32.187465066880875,
        '-110': 32.18736341117467,
        '-109': 32.187273188384324,
        '-108': 32.18724441776533,
        '-107': 32.18727191220635,
        '-106': 32.187300692090034,
        '-105': 32.18728013047287,
        '-104': 32.18721444972087,
        '-103': 32.18716075047339,
        '-102': 32.18717642515135,
        '-101': 32.18726556297145,
        '-100': 32.187373589180986,
        '-99': 32.187433441358095,
        '-98': 32.187419954722486,
        '-97': 32.187364363994746,
        '-96': 32.18731947328603,
        '-95': 32.18731061789569,
        '-94': 32.1873179328069,
        '-93': 32.187304213375334,
        '-92': 32.187259419993346,
        '-91': 32.187217024264775,
        '-90': 32.18722375849062,
        '-89': 32.18728999851754,
        '-88': 32.18737087308439,
        '-87': 32.18740348867957,
        '-86': 32.187371143116636,
        '-85': 32.18733094987915,
        '-84': 32.187365960785506,
        '-83': 32.18749389893228,
        '-82': 32.18761921647139,
        '-81': 32.1875932480738,
        '-80': 32.187353189072866,
        '-79': 32.18702308232373,
        '-78': 32.18686764471819,
        '-77': 32.18710469671534,
        '-76': 32.18771382122983,
        '-75': 32.18840590461698,
        '-74': 32.18672353313622,
        '-73': 32.17444422382405,
        '-72': 32.18117224016701,
        '-71': 32.17973856805374,
        '-70': 32.18266543198034,
        '-69': 32.186762299470686,
        '-68': 32.186720076730225,
        '-67': 32.18725072079717,
        '-66': 32.18728085165802,
        '-65': 32.18729089065574,
        '-64': 32.18737425644708,
        '-63': 32.18754831905736,
        '-62': 32.18773847652692,
        '-61': 32.18786178596096,
        '-60': 32.18791097691384,
        '-59': 32.18794805933508,
        '-58': 32.18801798972901,
        '-57': 32.18807909152107,
        '-56': 32.18803050531009,
        '-55': 32.18781576801805,
        '-54': 32.18750025550925,
        '-53': 32.18723935499497,
        '-52': 32.18715782884338,
        '-51': 32.18724800033832,
        '-50': 32.18738124829091,
        '-49': 32.18742520982502,
        '-48': 32.18736284388208,
        '-47': 32.18730730931121,
        '-46': 32.187399202173204,
        '-45': 32.187675667945946,
        '-44': 32.18802355184235,
        '-43': 32.188254664050696,
        '-42': 32.18823888886472,
        '-41': 32.18798859374164,
        '-40': 32.187635752639444,
        '-39': 32.1873325897994,
        '-38': 32.18715934460059,
        '-37': 32.187101454490545,
        '-36': 32.18709289991537,
        '-35': 32.18707471481566,
        '-34': 32.18702310615323,
        '-33': 32.18694234359674,
        '-32': 32.18684832061464,
        '-31': 32.18676387952105,
        '-30': 32.18672142116855,
        '-29': 32.18675514684933,
        '-28': 32.18687874846722,
        '-27': 32.18706674814313,
        '-26': 32.18726140596785,
        '-25': 32.18740572602273,
        '-24': 32.18747788570077,
        '-23': 32.18749923719444,
        '-22': 32.18751043774888,
        '-21': 32.18753691324555,
        '-20': 32.18757179559235,
        '-19': 32.18758771534807,
        '-18': 32.18756524377961,
        '-17': 32.1875148038044,
        '-16': 32.18747592414143,
        '-15': 32.18749407671633,
        '-14': 32.18758946031185,
        '-13': 32.18773763100643,
        '-12': 32.18787698838096,
        '-11': 32.18794327555071,
        '-10': 32.18791194895732,
        '-9': 32.187818412121636,
        '-8': 32.187736540016886,
        '-7': 32.187725889797235,
        '-6': 32.18778578229474,
        '-5': 32.18785394535541,
        '-4': 32.18785388102893,
        '-3': 32.187754579656755,
        '-2': 32.18759460642163,
        '-1': 32.187451939606824
    },
    '-51': {
        '0': 32.190409375243505,
        '1': 32.190355086972154,
        '2': 32.190377213616394,
        '3': 32.19041714144536,
        '4': 32.19040864357282,
        '5': 32.1903206634556,
        '6': 32.19017393601873,
        '7': 32.190026725216484,
        '8': 32.189943440581665,
        '9': 32.189964784947186,
        '10': 32.190091928327796,
        '11': 32.190288462380735,
        '12': 32.19049752616748,
        '13': 32.190666890640124,
        '14': 32.190770809579334,
        '15': 32.19081677614177,
        '16': 32.190832123423974,
        '17': 32.19083865115309,
        '18': 32.19083415126305,
        '19': 32.19079704440316,
        '20': 32.19071268640087,
        '21': 32.19059972961327,
        '22': 32.190510545658356,
        '23': 32.190498747983355,
        '24': 32.19057620242608,
        '25': 32.19069597087213,
        '26': 32.190780508079456,
        '27': 32.19077703288886,
        '28': 32.19069601746209,
        '29': 32.190599519105035,
        '30': 32.19054772080017,
        '31': 32.1905493272862,
        '32': 32.190559926800844,
        '33': 32.190530785928935,
        '34': 32.190464511453534,
        '35': 32.190425466402054,
        '36': 32.19049129765783,
        '37': 32.19068443636583,
        '38': 32.1909422667324,
        '39': 32.191153520227,
        '40': 32.19123325078964,
        '41': 32.19117739134472,
        '42': 32.19105641793581,
        '43': 32.190958116795834,
        '44': 32.19092777150964,
        '45': 32.19094980292793,
        '46': 32.19097627490957,
        '47': 32.19097091663989,
        '48': 32.19093224118334,
        '49': 32.190884443394836,
        '50': 32.19085298613502,
        '51': 32.19084843583661,
        '52': 32.19086669322217,
        '53': 32.1908962640865,
        '54': 32.190921377815805,
        '55': 32.19092163762721,
        '56': 32.19087754126099,
        '57': 32.19078492583425,
        '58': 32.19066707460604,
        '59': 32.190568716772574,
        '60': 32.19052988123722,
        '61': 32.19055773417758,
        '62': 32.190620218460104,
        '63': 32.190668551249125,
        '64': 32.19067108745391,
        '65': 32.190632123206505,
        '66': 32.19058422804328,
        '67': 32.190566128205724,
        '68': 32.1906066608406,
        '69': 32.19072213488095,
        '70': 32.19091633602115,
        '71': 32.191171154729986,
        '72': 32.19143360105729,
        '73': 32.19162071296946,
        '74': 32.19165560327241,
        '75': 32.191518302065184,
        '76': 32.19127216190316,
        '77': 32.19103679690421,
        '78': 32.190918473512895,
        '79': 32.19094539324749,
        '80': 32.191055144687994,
        '81': 32.191142794861406,
        '82': 32.19113265027332,
        '83': 32.19102188503753,
        '84': 32.19086986221689,
        '85': 32.19074909688038,
        '86': 32.1906980269616,
        '87': 32.19070669123359,
        '88': 32.190737003593895,
        '89': 32.19075529428808,
        '90': 32.19075203513445,
        '91': 32.19073871518401,
        '92': 32.19072984896601,
        '93': 32.19072663907544,
        '94': 32.1907148750822,
        '95': 32.19067795040878,
        '96': 32.19061420507964,
        '97': 32.19054357332046,
        '98': 32.19049560327337,
        '99': 32.19048592743025,
        '100': 32.19050012328297,
        '101': 32.190501037510366,
        '102': 32.19045726778261,
        '103': 32.19037076748529,
        '104': 32.19027908468225,
        '105': 32.190227721588414,
        '106': 32.190234504695326,
        '107': 32.190276359740494,
        '108': 32.19031003087503,
        '109': 32.19030827187093,
        '110': 32.190279617287125,
        '111': 32.190255693010755,
        '112': 32.19026000286069,
        '113': 32.19028778411192,
        '114': 32.19031384725747,
        '115': 32.19031793020013,
        '116': 32.190301900557614,
        '117': 32.19028346800208,
        '118': 32.19027475575858,
        '119': 32.19026744537545,
        '120': 32.19023806709918,
        '121': 32.19016783901815,
        '122': 32.19006018215914,
        '123': 32.18994356700157,
        '124': 32.18985955685887,
        '125': 32.18984373408079,
        '126': 32.189907692942946,
        '127': 32.19002901141582,
        '128': 32.19015615149697,
        '129': 32.19023180116406,
        '130': 32.19022662108757,
        '131': 32.190162088117184,
        '132': 32.190101211623684,
        '133': 32.190106622110505,
        '134': 32.19019363146151,
        '135': 32.19031566845435,
        '136': 32.19039743939356,
        '137': 32.19039241065069,
        '138': 32.19031905409381,
        '139': 32.190245979200895,
        '140': 32.19023773493898,
        '141': 32.190305104559,
        '142': 32.19039868311405,
        '143': 32.190447774041424,
        '144': 32.190410832230725,
        '145': 32.19029875477504,
        '146': 32.1901592753213,
        '147': 32.19004289804618,
        '148': 32.18997989964387,
        '149': 32.18997958625019,
        '150': 32.19003878466555,
        '151': 32.19014158325556,
        '152': 32.19025033774451,
        '153': 32.19030760140192,
        '154': 32.190265115414405,
        '155': 32.19012762909115,
        '156': 32.18997291442978,
        '157': 32.18991632954767,
        '158': 32.19003189394106,
        '159': 32.19028730773385,
        '160': 32.190552354396345,
        '161': 32.190686590040926,
        '162': 32.19064316488981,
        '163': 32.19050333793315,
        '164': 32.19040831931923,
        '165': 32.19044258580482,
        '166': 32.190568616098254,
        '167': 32.19067079032184,
        '168': 32.19066839164012,
        '169': 32.19059219274202,
        '170': 32.19054995907837,
        '171': 32.1906099199636,
        '172': 32.190713072072555,
        '173': 32.19070510434298,
        '174': 32.190469691162704,
        '175': 32.19004612679884,
        '176': 32.18962051686999,
        '177': 32.18939010618879,
        '178': 32.18941522362623,
        '179': 32.18958579482392,
        '180': 32.189726512236376,
        '-180': 32.189726512236376,
        '-179': 32.18974194364249,
        '-178': 32.18967396050523,
        '-177': 32.189632612876345,
        '-176': 32.18968003113924,
        '-175': 32.18978183185435,
        '-174': 32.18986313160844,
        '-173': 32.189898503497645,
        '-172': 32.189935983460956,
        '-171': 32.19003098592105,
        '-170': 32.19016547087748,
        '-169': 32.19024620433497,
        '-168': 32.19019319094324,
        '-167': 32.190030938201105,
        '-166': 32.18988478644613,
        '-165': 32.18987632203693,
        '-164': 32.1900143972267,
        '-163': 32.19018676278143,
        '-162': 32.190261092419455,
        '-161': 32.19019875802582,
        '-160': 32.19007516517054,
        '-159': 32.18999484244089,
        '-158': 32.18999169186723,
        '-157': 32.190013035573855,
        '-156': 32.1899955586394,
        '-155': 32.189946891228075,
        '-154': 32.18994432370512,
        '-153': 32.190051446293246,
        '-152': 32.190240511116,
        '-151': 32.19040374748246,
        '-150': 32.190446592520594,
        '-149': 32.19037260434566,
        '-148': 32.19027738468121,
        '-147': 32.19025796804981,
        '-146': 32.190325060674844,
        '-145': 32.190399682090295,
        '-144': 32.19039249323399,
        '-143': 32.190287135148196,
        '-142': 32.190150882813995,
        '-141': 32.19006913723657,
        '-140': 32.19007026583955,
        '-139': 32.19010928512121,
        '-138': 32.19011805792073,
        '-137': 32.19006910266849,
        '-136': 32.189995067846645,
        '-135': 32.18995340196425,
        '-134': 32.1899752538197,
        '-133': 32.19004472509313,
        '-132': 32.190120416588954,
        '-131': 32.19017333003879,
        '-130': 32.19020681947233,
        '-129': 32.19024589629109,
        '-128': 32.19031004348643,
        '-127': 32.19039358497304,
        '-126': 32.19046806197854,
        '-125': 32.19050383734368,
        '-124': 32.19049519423546,
        '-123': 32.19047010570605,
        '-122': 32.190474150696865,
        '-121': 32.19053573162025,
        '-120': 32.190637295409985,
        '-119': 32.19071910050877,
        '-118': 32.19072000993729,
        '-117': 32.19062701330527,
        '-116': 32.19049106570397,
        '-115': 32.19039025314904,
        '-114': 32.19036713381804,
        '-113': 32.19039419806916,
        '-112': 32.19040041843846,
        '-111': 32.19033748966488,
        '-110': 32.19022609664778,
        '-109': 32.19013856017906,
        '-108': 32.19013236296355,
        '-107': 32.19019565585384,
        '-106': 32.19025608500341,
        '-105': 32.19024603905425,
        '-104': 32.190164869190376,
        '-103': 32.1900809963074,
        '-102': 32.19007175115723,
        '-101': 32.19015515391384,
        '-100': 32.19027410160043,
        '-99': 32.19034518879182,
        '-98': 32.19032693930851,
        '-97': 32.1902475240031,
        '-96': 32.19017136411817,
        '-95': 32.190139576484384,
        '-94': 32.190140179284114,
        '-93': 32.190132712807625,
        '-92': 32.19009898850432,
        '-91': 32.19006708711688,
        '-90': 32.19008287695309,
        '-89': 32.190156916083794,
        '-88': 32.190242906372006,
        '-87': 32.19027659962765,
        '-86': 32.190243247926425,
        '-85': 32.19020526299094,
        '-84': 32.19025067725364,
        '-83': 32.19039856194296,
        '-82': 32.190551154685515,
        '-81': 32.190555739529245,
        '-80': 32.190343593184096,
        '-79': 32.19002971405132,
        '-78': 32.18986857846917,
        '-77': 32.190073189357236,
        '-76': 32.19062894796989,
        '-75': 32.190428212287564,
        '-74': 32.18793044291195,
        '-73': 32.17894989024782,
        '-72': 32.18781415993583,
        '-71': 32.187684760158184,
        '-70': 32.18720195883735,
        '-69': 32.18951328802971,
        '-68': 32.189795801582626,
        '-67': 32.190198606251876,
        '-66': 32.19053942801386,
        '-65': 32.190759397546415,
        '-64': 32.19089296789087,
        '-63': 32.19098491543024,
        '-62': 32.191045725502605,
        '-61': 32.191073263529,
        '-60': 32.19109194779182,
        '-59': 32.19114864768898,
        '-58': 32.19126186900741,
        '-57': 32.19138087584227,
        '-56': 32.19140855565361,
        '-55': 32.19127810563157,
        '-54': 32.191014797025495,
        '-53': 32.19072183410971,
        '-52': 32.19049892218797,
        '-51': 32.19036688522894,
        '-50': 32.19026806610016,
        '-49': 32.19014184933463,
        '-48': 32.19000305789671,
        '-47': 32.189945771376294,
        '-46': 32.19006240299932,
        '-45': 32.19034682392088,
        '-44': 32.190669248882664,
        '-43': 32.19085191895566,
        '-42': 32.190789780421866,
        '-41': 32.19052267321841,
        '-40': 32.19020542460201,
        '-39': 32.19000316917335,
        '-38': 32.18999283232857,
        '-37': 32.19013753539637,
        '-36': 32.190337433682856,
        '-35': 32.190504633353854,
        '-34': 32.19060426862644,
        '-33': 32.19064405380639,
        '-32': 32.19063942761901,
        '-31': 32.190593130984276,
        '-30': 32.190503779904645,
        '-29': 32.19038609942301,
        '-28': 32.1902758954697,
        '-27': 32.1902107714784,
        '-26': 32.1902029857298,
        '-25': 32.190229666295444,
        '-24': 32.190251081054356,
        '-23': 32.19024363282646,
        '-22': 32.19022118948827,
        '-21': 32.190226500983464,
        '-20': 32.19029725722421,
        '-19': 32.19043167806334,
        '-18': 32.190581084558055,
        '-17': 32.190678572130615,
        '-16': 32.1906856028814,
        '-15': 32.19062211891385,
        '-14': 32.190554950965264,
        '-13': 32.19054994278867,
        '-12': 32.190622958120564,
        '-11': 32.19072870066564,
        '-10': 32.19079784881773,
        '-9': 32.19079353363261,
        '-8': 32.190740798875225,
        '-7': 32.19070392581021,
        '-6': 32.19072999702969,
        '-5': 32.190806000472016,
        '-4': 32.19086584346393,
        '-3': 32.19084192841821,
        '-2': 32.19071917424108,
        '-1': 32.19054832511109
    },
    '-52': {
        '0': 32.19342146481077,
        '1': 32.19331840994674,
        '2': 32.19329317170043,
        '3': 32.19332244768424,
        '4': 32.19334133546743,
        '5': 32.193294619514916,
        '6': 32.19318136354951,
        '7': 32.19306118286227,
        '8': 32.19301818642555,
        '9': 32.19310714466187,
        '10': 32.193317388952764,
        '11': 32.19357752229584,
        '12': 32.19379726887055,
        '13': 32.19391902518991,
        '14': 32.19394590368869,
        '15': 32.19392905849871,
        '16': 32.19392475185076,
        '17': 32.19395255556648,
        '18': 32.19398519405264,
        '19': 32.193977108139656,
        '20': 32.193908888758095,
        '21': 32.1938110063395,
        '22': 32.19374506737372,
        '23': 32.19375474007709,
        '24': 32.193825271565885,
        '25': 32.19388699799262,
        '26': 32.19386445261321,
        '27': 32.19373455765179,
        '28': 32.193547128486045,
        '29': 32.19338947243813,
        '30': 32.193322434699716,
        '31': 32.193339725511464,
        '32': 32.19338366051585,
        '33': 32.19340301025164,
        '34': 32.19340268253218,
        '35': 32.193441906077766,
        '36': 32.193582022112054,
        '37': 32.1938285708096,
        '38': 32.19411720104389,
        '39': 32.194355114369024,
        '40': 32.19448276685443,
        '41': 32.19450529727548,
        '42': 32.19447162502989,
        '43': 32.19442467392125,
        '44': 32.19436829043387,
        '45': 32.19427727874486,
        '46': 32.19413611958254,
        '47': 32.19396709394211,
        '48': 32.19382089262819,
        '49': 32.19373890370289,
        '50': 32.193722151385096,
        '51': 32.19373448972339,
        '52': 32.193735595482124,
        '53': 32.193712944383634,
        '54': 32.193684847671996,
        '55': 32.19367452508711,
        '56': 32.19368149329687,
        '57': 32.19367764115841,
        '58': 32.19363168382955,
        '59': 32.19353975614144,
        '60': 32.193434692279304,
        '61': 32.19336518297262,
        '62': 32.193360943912204,
        '63': 32.19341034146843,
        '64': 32.1934663291073,
        '65': 32.19347616945151,
        '66': 32.193416287806826,
        '67': 32.19331292483581,
        '68': 32.193237956395144,
        '69': 32.1932800118668,
        '70': 32.19350067723729,
        '71': 32.193893954331116,
        '72': 32.19437123872996,
        '73': 32.19478724955656,
        '74': 32.19500204746323,
        '75': 32.194949818323686,
        '76': 32.19467448226669,
        '77': 32.19430763410683,
        '78': 32.19399916785249,
        '79': 32.193841639540835,
        '80': 32.19383319089907,
        '81': 32.19389741315245,
        '82': 32.19394131481687,
        '83': 32.19391119009837,
        '84': 32.19381370000867,
        '85': 32.193696880238114,
        '86': 32.19361153964353,
        '87': 32.1935809999033,
        '88': 32.19359533943301,
        '89': 32.19362713381867,
        '90': 32.193652972162106,
        '91': 32.19366523178994,
        '92': 32.19366875550842,
        '93': 32.1936688578481,
        '94': 32.193662533293484,
        '95': 32.19364067679074,
        '96': 32.19359890784507,
        '97': 32.19354626225423,
        '98': 32.193501861080954,
        '99': 32.193479997174485,
        '100': 32.19347564450662,
        '101': 32.193464427901546,
        '102': 32.193420114149475,
        '103': 32.193337102426554,
        '104': 32.19323916283237,
        '105': 32.193165605045266,
        '106': 32.19314454248653,
        '107': 32.19317391922902,
        '108': 32.19322456963749,
        '109': 32.193261409146466,
        '110': 32.19326501760362,
        '111': 32.193237996886516,
        '112': 32.193195228729174,
        '113': 32.193150213490185,
        '114': 32.19310977393642,
        '115': 32.193078455048955,
        '116': 32.19306380666007,
        '117': 32.19307396365225,
        '118': 32.193108105183384,
        '119': 32.19314881741874,
        '120': 32.19316526226881,
        '121': 32.19312807007673,
        '122': 32.19302804875496,
        '123': 32.19288738838982,
        '124': 32.19275531553242,
        '125': 32.19268771893162,
        '126': 32.19271896433228,
        '127': 32.192840504376015,
        '128': 32.19300062876225,
        '129': 32.193129770027696,
        '130': 32.19317935169336,
        '131': 32.19315002700006,
        '132': 32.19308881385591,
        '133': 32.19305511667687,
        '134': 32.19307913023744,
        '135': 32.1931433654457,
        '136': 32.19320109140646,
        '137': 32.193216611963514,
        '138': 32.19319497287367,
        '139': 32.19317755627039,
        '140': 32.193207729739896,
        '141': 32.19329419770525,
        '142': 32.19340018151178,
        '143': 32.19346527957549,
        '144': 32.193442641338244,
        '145': 32.193325893147176,
        '146': 32.193151427010164,
        '147': 32.19297963660218,
        '148': 32.1928685812858,
        '149': 32.19285149896951,
        '150': 32.19292333894628,
        '151': 32.193039503836665,
        '152': 32.193131376417995,
        '153': 32.193139526523034,
        '154': 32.19305260392137,
        '155': 32.19292669183342,
        '156': 32.19286259950229,
        '157': 32.1929440112851,
        '158': 32.19317289824293,
        '159': 32.19345145771495,
        '160': 32.19363394153488,
        '161': 32.19362025408078,
        '162': 32.19342576848147,
        '163': 32.193173084805025,
        '164': 32.1930085222595,
        '165': 32.19300543166634,
        '166': 32.19312761545051,
        '167': 32.193276736123714,
        '168': 32.19337780943055,
        '169': 32.193427298868066,
        '170': 32.193465834459744,
        '171': 32.19350885489223,
        '172': 32.19350808169431,
        '173': 32.19338686584906,
        '174': 32.19311976850397,
        '175': 32.19277974828185,
        '176': 32.19250153346519,
        '177': 32.192386150912775,
        '178': 32.192428174946215,
        '179': 32.1925294158374,
        '180': 32.19258536635919,
        '-180': 32.19258536635919,
        '-179': 32.19256590234681,
        '-178': 32.192520594953265,
        '-177': 32.19251244080174,
        '-176': 32.19255069828892,
        '-175': 32.192589391168035,
        '-174': 32.19258829941899,
        '-173': 32.19256826149907,
        '-172': 32.192598125230795,
        '-171': 32.19272184435402,
        '-170': 32.192899337162636,
        '-169': 32.193026430103934,
        '-168': 32.19302286607987,
        '-167': 32.19290767916936,
        '-166': 32.19278866217978,
        '-165': 32.1927723875582,
        '-164': 32.19287652912051,
        '-163': 32.19302355794343,
        '-162': 32.193116895617415,
        '-161': 32.19312379916905,
        '-160': 32.19308801950499,
        '-159': 32.193068341358355,
        '-158': 32.193072061128326,
        '-157': 32.1930529966455,
        '-156': 32.19297356553937,
        '-155': 32.19286253961139,
        '-154': 32.19280357657856,
        '-153': 32.19286064834546,
        '-152': 32.19301277231322,
        '-151': 32.1931646822741,
        '-150': 32.19322726904258,
        '-149': 32.19319357894319,
        '-148': 32.193139609534335,
        '-147': 32.193150274701964,
        '-146': 32.19324058486504,
        '-145': 32.19334425898371,
        '-144': 32.19337684124647,
        '-143': 32.193311656553,
        '-142': 32.19319870286596,
        '-141': 32.19311308343369,
        '-140': 32.19308487131915,
        '-139': 32.193075989762555,
        '-138': 32.193022785338655,
        '-137': 32.19290218214587,
        '-136': 32.19276021641286,
        '-135': 32.19267817517869,
        '-134': 32.19270726168595,
        '-133': 32.19282807837223,
        '-132': 32.19296732698553,
        '-131': 32.193055265626846,
        '-130': 32.1930758808064,
        '-129': 32.19307109069397,
        '-128': 32.19309931864979,
        '-127': 32.1931844980538,
        '-126': 32.19329683815397,
        '-125': 32.193379495481366,
        '-124': 32.19339799970628,
        '-123': 32.193370934480576,
        '-122': 32.1933553761565,
        '-121': 32.1933977759064,
        '-120': 32.193490934916206,
        '-119': 32.193574903859805,
        '-118': 32.19358396437437,
        '-117': 32.19350171134877,
        '-116': 32.19337674305774,
        '-115': 32.193283532503365,
        '-114': 32.19326092283252,
        '-113': 32.19328197142359,
        '-112': 32.19328244603629,
        '-111': 32.193222823162095,
        '-110': 32.19312734060952,
        '-109': 32.19306338769814,
        '-108': 32.19307884843895,
        '-107': 32.19315462340175,
        '-106': 32.19321724182133,
        '-105': 32.193202326818046,
        '-104': 32.19311278946371,
        '-103': 32.193019120681534,
        '-102': 32.19300029311694,
        '-101': 32.193076444499816,
        '-100': 32.19319140710489,
        '-99': 32.19325875499669,
        '-98': 32.193229959450086,
        '-97': 32.193126572436555,
        '-96': 32.19301324715789,
        '-95': 32.1929413574317,
        '-94': 32.192915610267285,
        '-93': 32.192909446712314,
        '-92': 32.19290631538587,
        '-91': 32.192920230370014,
        '-90': 32.19297303880224,
        '-89': 32.19305382030434,
        '-88': 32.19310943937023,
        '-87': 32.193087668726704,
        '-86': 32.192998455462394,
        '-85': 32.192929408448286,
        '-84': 32.19298382233773,
        '-83': 32.19318202716324,
        '-82': 32.19341435658219,
        '-81': 32.193504897872735,
        '-80': 32.193353337085114,
        '-79': 32.19304341155829,
        '-78': 32.192815531663264,
        '-77': 32.1929026354813,
        '-76': 32.19334449058208,
        '-75': 32.18956298425323,
        '-74': 32.19167387931503,
        '-73': 32.18645187937416,
        '-72': 32.19200041878299,
        '-71': 32.19073992186592,
        '-70': 32.19129076788559,
        '-69': 32.191522112982945,
        '-68': 32.192718853831074,
        '-67': 32.19313821930596,
        '-66': 32.19352385103357,
        '-65': 32.193761319378766,
        '-64': 32.19384662724406,
        '-63': 32.193831886850475,
        '-62': 32.193766429625214,
        '-61': 32.1936785017677,
        '-60': 32.193011954602504,
        '-59': 32.19318702132178,
        '-58': 32.19348685418239,
        '-57': 32.19346962505847,
        '-56': 32.193429142835505,
        '-55': 32.19333263869058,
        '-54': 32.19318331754448,
        '-53': 32.19301622948997,
        '-52': 32.19286385615661,
        '-51': 32.192726480357855,
        '-50': 32.192580140250584,
        '-49': 32.19241706876681,
        '-48': 32.19227675265213,
        '-47': 32.19222868828219,
        '-46': 32.19231203672977,
        '-45': 32.192483105116395,
        '-44': 32.192624151025285,
        '-43': 32.19261911795128,
        '-42': 32.19244291773426,
        '-41': 32.19219295118918,
        '-40': 32.19203308066821,
        '-39': 32.19208855868713,
        '-38': 32.19236915802464,
        '-37': 32.19277494911344,
        '-36': 32.19317413214238,
        '-35': 32.19348867584973,
        '-34': 32.193723618450306,
        '-33': 32.193926427144355,
        '-32': 32.19411988295012,
        '-31': 32.19426913774722,
        '-30': 32.19430971641901,
        '-29': 32.194209260133036,
        '-28': 32.19400720063472,
        '-27': 32.1937957787145,
        '-26': 32.19365555552299,
        '-25': 32.19359745594693,
        '-24': 32.19355974630733,
        '-23': 32.19346489412216,
        '-22': 32.19329239293187,
        '-21': 32.19310835856632,
        '-20': 32.19302425958301,
        '-19': 32.19311239130434,
        '-18': 32.19334209920815,
        '-17': 32.19358868567694,
        '-16': 32.19371344284588,
        '-15': 32.19365834322927,
        '-14': 32.19348549620611,
        '-13': 32.193331448834435,
        '-12': 32.19331004581648,
        '-11': 32.19343578639252,
        '-10': 32.1936226116246,
        '-9': 32.19375449399007,
        '-8': 32.193770548658925,
        '-7': 32.193700253766295,
        '-6': 32.193627137961,
        '-5': 32.193615763216144,
        '-4': 32.19366201373437,
        '-3': 32.19370321169649,
        '-2': 32.19367466728561,
        '-1': 32.1935641554642
    },
    '-53': {
        '0': 32.19653844257075,
        '1': 32.19643290173243,
        '2': 32.19638693627816,
        '3': 32.19641707600283,
        '4': 32.196478750703584,
        '5': 32.19650456918058,
        '6': 32.196459155806465,
        '7': 32.19636812667872,
        '8': 32.1963000397071,
        '9': 32.196315946524905,
        '10': 32.19642459150255,
        '11': 32.196575916045774,
        '12': 32.19669650244735,
        '13': 32.19673997171052,
        '14': 32.19671555048405,
        '15': 32.1966755922184,
        '16': 32.196674004724585,
        '17': 32.19672859224846,
        '18': 32.19681538185438,
        '19': 32.19689622688805,
        '20': 32.1969537664495,
        '21': 32.197002355291175,
        '22': 32.19706489648094,
        '23': 32.197136468659785,
        '24': 32.197169914191996,
        '25': 32.19710233259235,
        '26': 32.196906292167355,
        '27': 32.19662467798388,
        '28': 32.19635635211307,
        '29': 32.19619739165181,
        '30': 32.19617999928263,
        '31': 32.1962563739582,
        '32': 32.19634116742912,
        '33': 32.196379694721564,
        '34': 32.196388136631214,
        '35': 32.19643474882137,
        '36': 32.196579257298765,
        '37': 32.19682107127716,
        '38': 32.197097760974934,
        '39': 32.197331828135866,
        '40': 32.197483758284555,
        '41': 32.19756682136154,
        '42': 32.19761506900421,
        '43': 32.197637889794976,
        '44': 32.197605232845284,
        '45': 32.19747826907264,
        '46': 32.197257589950944,
        '47': 32.19700339106388,
        '48': 32.196805322533955,
        '49': 32.19672345251283,
        '50': 32.196747775822836,
        '51': 32.196809304961974,
        '52': 32.196833497248186,
        '53': 32.196792752842946,
        '54': 32.19671722539914,
        '55': 32.19665886701086,
        '56': 32.19664164379072,
        '57': 32.196639948488915,
        '58': 32.196601644371,
        '59': 32.196494807879915,
        '60': 32.19633894956983,
        '61': 32.19619548131006,
        '62': 32.196125045318915,
        '63': 32.1961442499301,
        '64': 32.196213439506465,
        '65': 32.196263697491595,
        '66': 32.19624434554844,
        '67': 32.19616017057367,
        '68': 32.19607577750634,
        '69': 32.19608460857277,
        '70': 32.196259229472005,
        '71': 32.19660829568115,
        '72': 32.19706191757949,
        '73': 32.19749386831965,
        '74': 32.197771922868014,
        '75': 32.197813705415804,
        '76': 32.19762157377583,
        '77': 32.19727943003563,
        '78': 32.19691323676777,
        '79': 32.19663553185747,
        '80': 32.19650173227629,
        '81': 32.196498136775325,
        '82': 32.19656333765295,
        '83': 32.19662733235567,
        '84': 32.19664576878531,
        '85': 32.19661304615021,
        '86': 32.19655216618504,
        '87': 32.196492304414605,
        '88': 32.19644986081722,
        '89': 32.196423558373574,
        '90': 32.196403210656406,
        '91': 32.19638252501189,
        '92': 32.19636485865807,
        '93': 32.196357877400025,
        '94': 32.19636299321715,
        '95': 32.19637021869102,
        '96': 32.19636446509597,
        '97': 32.19633889180623,
        '98': 32.19630363804967,
        '99': 32.196280988182124,
        '100': 32.19628914287154,
        '101': 32.19632701696857,
        '102': 32.19637273732349,
        '103': 32.19639797876522,
        '104': 32.196387533537774,
        '105': 32.19634919816507,
        '106': 32.19630669236211,
        '107': 32.19628143163762,
        '108': 32.196276942498166,
        '109': 32.19627676711621,
        '110': 32.19625615585446,
        '111': 32.19619840654287,
        '112': 32.1961049733337,
        '113': 32.195994266590574,
        '114': 32.19589193329755,
        '115': 32.19581970240605,
        '116': 32.195788739987066,
        '117': 32.195799027823476,
        '118': 32.19584224027715,
        '119': 32.1959045467283,
        '120': 32.195967917098436,
        '121': 32.196011767422185,
        '122': 32.19601804509474,
        '123': 32.195980224508915,
        '124': 32.19591155624159,
        '125': 32.1958448307434,
        '126': 32.195819179190565,
        '127': 32.19585855940515,
        '128': 32.195955052871575,
        '129': 32.19606992602987,
        '130': 32.19615446550191,
        '131': 32.196178067406024,
        '132': 32.196144593566295,
        '133': 32.196085845439505,
        '134': 32.196037810156625,
        '135': 32.19601788515605,
        '136': 32.19601946695839,
        '137': 32.19602576519423,
        '138': 32.196029524759325,
        '139': 32.19604163996318,
        '140': 32.19608164457044,
        '141': 32.19615795173271,
        '142': 32.19625368410565,
        '143': 32.196329689212085,
        '144': 32.196344166610906,
        '145': 32.19627731622111,
        '146': 32.1961461817712,
        '147': 32.196000110374804,
        '148': 32.19589734786084,
        '149': 32.19587353963977,
        '150': 32.195919064225805,
        '151': 32.19598050778701,
        '152': 32.19599042101051,
        '153': 32.195912074314414,
        '154': 32.19577202116005,
        '155': 32.195654688241326,
        '156': 32.19565425103608,
        '157': 32.19580940613338,
        '158': 32.19606490911182,
        '159': 32.196292413586086,
        '160': 32.19636479709142,
        '161': 32.196238050743915,
        '162': 32.19598415954255,
        '163': 32.1957492694028,
        '164': 32.195663934275316,
        '165': 32.19576709713089,
        '166': 32.19599460862971,
        '167': 32.19623394593088,
        '168': 32.19639829019762,
        '169': 32.19646415011629,
        '170': 32.19645297520536,
        '171': 32.19638592153449,
        '172': 32.19625944920152,
        '173': 32.19606308583588,
        '174': 32.19581596746604,
        '175': 32.195578772413675,
        '176': 32.195421670998655,
        '177': 32.195373749345194,
        '178': 32.19540100311375,
        '179': 32.19543589379128,
        '180': 32.19543337368364,
        '-180': 32.19543337368364,
        '-179': 32.19540251163382,
        '-178': 32.19538553917151,
        '-177': 32.19540671395359,
        '-176': 32.195443026737436,
        '-175': 32.19544747909332,
        '-174': 32.195402790095734,
        '-173': 32.19535052139222,
        '-172': 32.195361646953096,
        '-171': 32.19547133767804,
        '-170': 32.195638655755374,
        '-169': 32.195772449803364,
        '-168': 32.19580415091602,
        '-167': 32.19574338242963,
        '-166': 32.195666719419805,
        '-165': 32.19565088044001,
        '-164': 32.195711702090414,
        '-163': 32.19580227180442,
        '-162': 32.19586615558895,
        '-161': 32.19589062889725,
        '-160': 32.1959088425206,
        '-159': 32.1959531966542,
        '-158': 32.196011208737445,
        '-157': 32.19603153721529,
        '-156': 32.19597598366131,
        '-155': 32.19586541320529,
        '-154': 32.19577152616781,
        '-153': 32.19575865963183,
        '-152': 32.195829059567636,
        '-151': 32.195923050282865,
        '-150': 32.19597403511473,
        '-149': 32.19596697739654,
        '-148': 32.195946835911236,
        '-147': 32.19597149703639,
        '-146': 32.19605545907491,
        '-145': 32.19615713914115,
        '-144': 32.196219500703826,
        '-143': 32.1962230766804,
        '-142': 32.1962002193138,
        '-141': 32.19619780032925,
        '-140': 32.196225154689195,
        '-139': 32.19623821035241,
        '-138': 32.19617706100664,
        '-137': 32.19602452938267,
        '-136': 32.19583296293269,
        '-135': 32.195693342650785,
        '-134': 32.19567037034586,
        '-133': 32.19575622759315,
        '-132': 32.19588004766125,
        '-131': 32.19596427724545,
        '-130': 32.195981905944905,
        '-129': 32.19596987283703,
        '-128': 32.19599095639487,
        '-127': 32.19607781156292,
        '-126': 32.196205322059654,
        '-125': 32.19631234020929,
        '-124': 32.1963527711635,
        '-123': 32.19633255147163,
        '-122': 32.19630199240899,
        '-121': 32.1963109514062,
        '-120': 32.196365549716354,
        '-119': 32.19642342717731,
        '-118': 32.19643119071947,
        '-117': 32.19637120380186,
        '-116': 32.19627685197667,
        '-115': 32.1962032508086,
        '-114': 32.196179856086445,
        '-113': 32.19618748097354,
        '-112': 32.196180030368744,
        '-111': 32.19613042315093,
        '-110': 32.19605777815574,
        '-109': 32.19600995198415,
        '-108': 32.19601698770046,
        '-107': 32.196059008967,
        '-106': 32.196080192021505,
        '-105': 32.19603860324519,
        '-104': 32.19594779283408,
        '-103': 32.195871484435195,
        '-102': 32.19587342861507,
        '-101': 32.19596427256042,
        '-100': 32.19609048078857,
        '-99': 32.19617413016625,
        '-98': 32.19616928173999,
        '-97': 32.19608870158618,
        '-96': 32.195983238211326,
        '-95': 32.19589790416349,
        '-94': 32.195845523933116,
        '-93': 32.19581687318989,
        '-92': 32.19580836082089,
        '-91': 32.19583201290697,
        '-90': 32.195894028924535,
        '-89': 32.195966115734485,
        '-88': 32.195988709540444,
        '-87': 32.19591783255487,
        '-86': 32.195780011725276,
        '-85': 32.195678689844875,
        '-84': 32.19572813604133,
        '-83': 32.19595566886801,
        '-82': 32.19625354618321,
        '-81': 32.19643513565989,
        '-80': 32.19636876231087,
        '-79': 32.196091286057566,
        '-78': 32.19580585420464,
        '-77': 32.195751044543954,
        '-76': 32.19603017169396,
        '-75': 32.19652744061771,
        '-74': 32.192925514682244,
        '-73': 32.192842866094914,
        '-72': 32.194193260995846,
        '-71': 32.19568708251352,
        '-70': 32.19550336585302,
        '-69': 32.19416772799278,
        '-68': 32.196013890467825,
        '-67': 32.196132192458116,
        '-66': 32.19620766576499,
        '-65': 32.19617719980446,
        '-64': 32.19607008472873,
        '-63': 32.195956119285334,
        '-62': 32.19587780147148,
        '-61': 32.19582095358542,
        '-60': 32.195736540217744,
        '-59': 32.19558594597956,
        '-58': 32.195372020941925,
        '-57': 32.19513891444599,
        '-56': 32.194948898112465,
        '-55': 32.194853910081804,
        '-54': 32.19487437072061,
        '-53': 32.19499005829362,
        '-52': 32.19514601355216,
        '-51': 32.19527553312488,
        '-50': 32.19533414952306,
        '-49': 32.195325728032024,
        '-48': 32.19529868571921,
        '-47': 32.19530745567418,
        '-46': 32.19536328142294,
        '-45': 32.195414505787475,
        '-44': 32.19538023345912,
        '-43': 32.19521958477339,
        '-42': 32.19498417249599,
        '-41': 32.1948059955569,
        '-40': 32.19481896376098,
        '-39': 32.19506620172042,
        '-38': 32.19546280241093,
        '-37': 32.19584669015291,
        '-36': 32.19608444196864,
        '-35': 32.1961554532464,
        '-34': 32.196152409925986,
        '-33': 32.19619916167083,
        '-32': 32.196350372330535,
        '-31': 32.19655107963328,
        '-30': 32.19668699298575,
        '-29': 32.19668445993141,
        '-28': 32.19657888031598,
        '-27': 32.196494006308335,
        '-26': 32.19654452314845,
        '-25': 32.196736625405386,
        '-24': 32.196946903611675,
        '-23': 32.197002560595394,
        '-22': 32.1968082381316,
        '-21': 32.1964259002234,
        '-20': 32.196045230134835,
        '-19': 32.195861293507356,
        '-18': 32.19594520694963,
        '-17': 32.19620021642211,
        '-16': 32.19643403150211,
        '-15': 32.19649401126658,
        '-14': 32.19636764557194,
        '-13': 32.196178920361504,
        '-12': 32.19608907719883,
        '-11': 32.196178773628084,
        '-10': 32.19639738044412,
        '-9': 32.196610449532315,
        '-8': 32.19670246843137,
        '-7': 32.196655220971486,
        '-6': 32.196547185975305,
        '-5': 32.19648279345311,
        '-4': 32.196511408282966,
        '-3': 32.196597470063445,
        '-2': 32.19665862181251,
        '-1': 32.1966357706567
    },
    '-54': {
        '0': 32.19955234415508,
        '1': 32.19947490925633,
        '2': 32.199439200194846,
        '3': 32.19947610121346,
        '4': 32.1995566029535,
        '5': 32.19961364104016,
        '6': 32.1995911715629,
        '7': 32.1994829951604,
        '8': 32.199334178048204,
        '9': 32.199207410014495,
        '10': 32.19914177512701,
        '11': 32.199133895947085,
        '12': 32.19915147138564,
        '13': 32.199164031750506,
        '14': 32.19916504566115,
        '15': 32.199170210884375,
        '16': 32.19919825582952,
        '17': 32.19925426842553,
        '18': 32.19933040730732,
        '19': 32.19942038863834,
        '20': 32.19952962981641,
        '21': 32.19966633076192,
        '22': 32.19981811034156,
        '23': 32.19993690406131,
        '24': 32.199953630223945,
        '25': 32.19982123074214,
        '26': 32.19955749193091,
        '27': 32.199251648540624,
        '28': 32.19902129767824,
        '29': 32.19894395551834,
        '30': 32.199010869335524,
        '31': 32.19913834212679,
        '32': 32.19923038740603,
        '33': 32.19924728817475,
        '34': 32.19922927288259,
        '35': 32.19925873757522,
        '36': 32.199391928745335,
        '37': 32.19961393023767,
        '38': 32.19985070614674,
        '39': 32.20002541260864,
        '40': 32.20011147457457,
        '41': 32.20014019072498,
        '42': 32.20016046934386,
        '43': 32.20018858758018,
        '44': 32.200192763044015,
        '45': 32.200125761324095,
        '46': 32.19997632594662,
        '47': 32.199793287580235,
        '48': 32.199658966798516,
        '49': 32.199631312434896,
        '50': 32.19970088690465,
        '51': 32.19979723016682,
        '52': 32.19983948784268,
        '53': 32.1997915646911,
        '54': 32.199680289467885,
        '55': 32.19956607371155,
        '56': 32.19949280973465,
        '57': 32.1994573628402,
        '58': 32.19941998870948,
        '59': 32.19934303654003,
        '60': 32.199224459315886,
        '61': 32.19910006089378,
        '62': 32.19901541054753,
        '63': 32.19899182439947,
        '64': 32.19901297561485,
        '65': 32.19904042428027,
        '66': 32.19904411885134,
        '67': 32.199024865510204,
        '68': 32.199014444260726,
        '69': 32.19905607176292,
        '70': 32.199179621098025,
        '71': 32.19938566234673,
        '72': 32.1996436574214,
        '73': 32.19990120168886,
        '74': 32.20009857991802,
        '75': 32.200184878494674,
        '76': 32.200133581797914,
        '77': 32.199954186884774,
        '78': 32.19969398444922,
        '79': 32.19942528424435,
        '80': 32.19921992375295,
        '81': 32.19912144045955,
        '82': 32.199129166592684,
        '83': 32.19920336686155,
        '84': 32.19928855278654,
        '85': 32.19934114843873,
        '86': 32.199345664794556,
        '87': 32.19931215617099,
        '88': 32.199260717525675,
        '89': 32.19920646889826,
        '90': 32.19915535394796,
        '91': 32.199110316724614,
        '92': 32.199078351627925,
        '93': 32.19906926320994,
        '94': 32.199086159097746,
        '95': 32.19911720917019,
        '96': 32.19913893111503,
        '97': 32.19913173748644,
        '98': 32.199096642450826,
        '99': 32.19905862974881,
        '100': 32.19905130415683,
        '101': 32.19909241263578,
        '102': 32.19916835977325,
        '103': 32.199240330113426,
        '104': 32.19926873748601,
        '105': 32.19923873172117,
        '106': 32.19916843594324,
        '107': 32.19909437453312,
        '108': 32.19904532630269,
        '109': 32.19902384844949,
        '110': 32.199008215896484,
        '111': 32.198971908559045,
        '112': 32.19890507897775,
        '113': 32.198821960058496,
        '114': 32.198749681167726,
        '115': 32.198708243908605,
        '116': 32.1986975406785,
        '117': 32.19870090880384,
        '118': 32.19870115220748,
        '119': 32.19869507012006,
        '120': 32.19869418382208,
        '121': 32.19871121559618,
        '122': 32.19874429836642,
        '123': 32.19877349802208,
        '124': 32.198774398852585,
        '125': 32.19873890798587,
        '126': 32.19868614180701,
        '127': 32.1986528559917,
        '128': 32.19866871795248,
        '129': 32.198734275153825,
        '130': 32.198818419179425,
        '131': 32.19887790230742,
        '132': 32.1988850470066,
        '133': 32.19884397139161,
        '134': 32.198784734823846,
        '135': 32.198741392274655,
        '136': 32.198731125032545,
        '137': 32.19874922265652,
        '138': 32.19878134228039,
        '139': 32.19882095087982,
        '140': 32.19887647298998,
        '141': 32.19896158384191,
        '142': 32.19907619848984,
        '143': 32.19919424627399,
        '144': 32.19927068300988,
        '145': 32.19926650868531,
        '146': 32.19917624791858,
        '147': 32.19903786801903,
        '148': 32.19891483613293,
        '149': 32.19885871214368,
        '150': 32.19887599991385,
        '151': 32.198923236544154,
        '152': 32.19893699381754,
        '153': 32.198880728672634,
        '154': 32.19877561238198,
        '155': 32.19869024773812,
        '156': 32.198691995812624,
        '157': 32.19879216720283,
        '158': 32.19892622611223,
        '159': 32.198988863374964,
        '160': 32.19890491747017,
        '161': 32.198688373963364,
        '162': 32.19844584817139,
        '163': 32.198317609294904,
        '164': 32.198393037760184,
        '165': 32.19865617303529,
        '166': 32.19899619877852,
        '167': 32.199272603984696,
        '168': 32.199389111176686,
        '169': 32.19932998959418,
        '170': 32.19914459673038,
        '171': 32.19890335008143,
        '172': 32.198661758371394,
        '173': 32.19845194564325,
        '174': 32.198292752506056,
        '175': 32.198196549441754,
        '176': 32.1981626300364,
        '177': 32.198168198936955,
        '178': 32.198174906016334,
        '179': 32.19815351320381,
        '180': 32.19810738982455,
        '-180': 32.19810738982455,
        '-179': 32.198071091965254,
        '-178': 32.19808080616762,
        '-177': 32.1981408189637,
        '-176': 32.19821711837665,
        '-175': 32.198266332210345,
        '-174': 32.1982750878579,
        '-173': 32.1982730698085,
        '-172': 32.19830569377761,
        '-171': 32.19838993015647,
        '-170': 32.19849416832846,
        '-169': 32.198562403715925,
        '-168': 32.19856230796211,
        '-167': 32.19851377910327,
        '-166': 32.19847165876041,
        '-165': 32.198478313066566,
        '-164': 32.19852957319132,
        '-163': 32.19858475950221,
        '-162': 32.198609937201105,
        '-161': 32.19861237977663,
        '-160': 32.19863298647101,
        '-159': 32.19870378910763,
        '-158': 32.198811341761385,
        '-157': 32.198901773229935,
        '-156': 32.19892436716339,
        '-155': 32.19887449454944,
        '-154': 32.19879728128072,
        '-153': 32.198749497522215,
        '-152': 32.19875464252092,
        '-151': 32.198790540473226,
        '-150': 32.198817286564946,
        '-149': 32.19881667787562,
        '-148': 32.19880637118186,
        '-147': 32.19881851195058,
        '-146': 32.198866715392924,
        '-145': 32.198934536396145,
        '-144': 32.19899546970656,
        '-143': 32.1990426101545,
        '-142': 32.1990964737039,
        '-141': 32.19918112138518,
        '-140': 32.19929063441937,
        '-139': 32.19937956117041,
        '-138': 32.199390746504406,
        '-137': 32.19930003269013,
        '-136': 32.19914041573338,
        '-135': 32.198983842108504,
        '-134': 32.198893651774505,
        '-133': 32.19888482609774,
        '-132': 32.19892236501592,
        '-131': 32.19895661048697,
        '-130': 32.19896493173655,
        '-129': 32.198965985822845,
        '-128': 32.198997264249584,
        '-127': 32.19907745712427,
        '-126': 32.19918662684896,
        '-125': 32.19928060801002,
        '-124': 32.19932632505652,
        '-123': 32.19932721872144,
        '-122': 32.199317142660846,
        '-121': 32.199328584706514,
        '-120': 32.19936312199368,
        '-119': 32.1993897591016,
        '-118': 32.1993723488483,
        '-117': 32.19930197316634,
        '-116': 32.19920603679466,
        '-115': 32.19912643366031,
        '-114': 32.199086257722435,
        '-113': 32.199074135216904,
        '-112': 32.19905891436096,
        '-111': 32.19902001021179,
        '-110': 32.19896507616855,
        '-109': 32.198919230972784,
        '-108': 32.198897184069956,
        '-107': 32.198886076765476,
        '-106': 32.198857055594466,
        '-105': 32.198796160300006,
        '-104': 32.198725436659785,
        '-103': 32.19869214307685,
        '-102': 32.19873212666516,
        '-101': 32.19883763682107,
        '-100': 32.19895771052746,
        '-99': 32.19903201672225,
        '-98': 32.19903073033846,
        '-97': 32.19896881135146,
        '-96': 32.19888636925839,
        '-95': 32.19881606754721,
        '-94': 32.19876710515161,
        '-93': 32.19873606987999,
        '-92': 32.19872704938628,
        '-91': 32.19875440383521,
        '-90': 32.19882118394996,
        '-89': 32.19889572935137,
        '-88': 32.19891813625866,
        '-87': 32.198843984753225,
        '-86': 32.19869408643484,
        '-85': 32.198562574270326,
        '-84': 32.19856221599911,
        '-83': 32.19873846776893,
        '-82': 32.19901849578868,
        '-81': 32.1992439610392,
        '-80': 32.199274510718894,
        '-79': 32.19909014599498,
        '-78': 32.19881450781726,
        '-77': 32.19863688503791,
        '-76': 32.19868695012753,
        '-75': 32.19895271461469,
        '-74': 32.19929912026443,
        '-73': 32.199567886361464,
        '-72': 32.195102100668684,
        '-71': 32.19966455874604,
        '-70': 32.197913941144364,
        '-69': 32.19833604688871,
        '-68': 32.198666614387996,
        '-67': 32.19931256344597,
        '-66': 32.19902483171916,
        '-65': 32.19866799429939,
        '-64': 32.19836755761796,
        '-63': 32.19824179301502,
        '-62': 32.19831962292344,
        '-61': 32.1985145264959,
        '-60': 32.198673101698326,
        '-59': 32.19866556010073,
        '-58': 32.19846047074075,
        '-57': 32.1981404318662,
        '-56': 32.197854186638494,
        '-55': 32.197736774172085,
        '-54': 32.197842869529325,
        '-53': 32.19812657802019,
        '-52': 32.198473950221086,
        '-51': 32.19876708863415,
        '-50': 32.19894333637674,
        '-49': 32.199016681773976,
        '-48': 32.19905052396212,
        '-47': 32.199101048033036,
        '-46': 32.19917094228235,
        '-45': 32.19920838030196,
        '-44': 32.199155157876376,
        '-43': 32.19900962835596,
        '-42': 32.198853232885114,
        '-41': 32.198810854492,
        '-40': 32.19896392351621,
        '-39': 32.1992763567884,
        '-38': 32.19959303698053,
        '-37': 32.19972329108721,
        '-36': 32.19955913393631,
        '-35': 32.19914704767314,
        '-34': 32.19865965458013,
        '-33': 32.19828354053944,
        '-32': 32.19810160527702,
        '-31': 32.1980550191336,
        '-30': 32.198012907777226,
        '-29': 32.197897267862,
        '-28': 32.19776645887127,
        '-27': 32.197788043469146,
        '-26': 32.19811321617551,
        '-25': 32.198741628919265,
        '-24': 32.19947936645376,
        '-23': 32.20003089928501,
        '-22': 32.20017086022268,
        '-21': 32.19988199745851,
        '-20': 32.19936552967144,
        '-19': 32.19891616813504,
        '-18': 32.19874552901652,
        '-17': 32.19887008641771,
        '-16': 32.199131462245596,
        '-15': 32.19932408508812,
        '-14': 32.19933517499632,
        '-13': 32.19920259333952,
        '-12': 32.199062071934804,
        '-11': 32.19903499364266,
        '-10': 32.19914347151446,
        '-9': 32.199309480442274,
        '-8': 32.19942718552965,
        '-7': 32.19944548587301,
        '-6': 32.19939793317469,
        '-5': 32.19936403227787,
        '-4': 32.19939963980169,
        '-3': 32.1994936823383,
        '-2': 32.199582375801945,
        '-1': 32.19960471492957
    },
    '-55': {
        '0': 32.20220641606513,
        '1': 32.202160263523474,
        '2': 32.20214508085814,
        '3': 32.20217483966474,
        '4': 32.20222571444442,
        '5': 32.20224799571266,
        '6': 32.20219753013402,
        '7': 32.2020639291935,
        '8': 32.2018765278454,
        '9': 32.20168643009236,
        '10': 32.20153950791507,
        '11': 32.20145864381626,
        '12': 32.201443112083105,
        '13': 32.20147901228277,
        '14': 32.201548862650064,
        '15': 32.20163418883137,
        '16': 32.20171502119143,
        '17': 32.20177405695817,
        '18': 32.201806840037214,
        '19': 32.20182916434585,
        '20': 32.20187023077386,
        '21': 32.20195008432893,
        '22': 32.20205560968083,
        '23': 32.20213649433371,
        '24': 32.202131676412606,
        '25': 32.202013276045925,
        '26': 32.20181693625436,
        '27': 32.201631712320584,
        '28': 32.20154977876621,
        '29': 32.201607599358255,
        '30': 32.201760876361874,
        '31': 32.201914382500156,
        '32': 32.20198817028121,
        '33': 32.20197325218923,
        '34': 32.20193566198301,
        '35': 32.20196521855985,
        '36': 32.20210666729956,
        '37': 32.20232432601276,
        '38': 32.20252602990316,
        '39': 32.202627366481586,
        '40': 32.20260759157635,
        '41': 32.20251679908067,
        '42': 32.20243254526564,
        '43': 32.20240224185202,
        '44': 32.20241568696615,
        '45': 32.20242534829856,
        '46': 32.20239340506507,
        '47': 32.202325287582326,
        '48': 32.20226356481331,
        '49': 32.20225003602069,
        '50': 32.20228918905228,
        '51': 32.202343034620874,
        '52': 32.202360192896116,
        '53': 32.20231451606625,
        '54': 32.20222266638735,
        '55': 32.20212890238606,
        '56': 32.20207172084094,
        '57': 32.20205928167463,
        '58': 32.20207044119235,
        '59': 32.20207631240338,
        '60': 32.20206246894967,
        '61': 32.202035090886,
        '62': 32.20201030767823,
        '63': 32.20199963810804,
        '64': 32.202004652864936,
        '65': 32.20202269153934,
        '66': 32.20205445811521,
        '67': 32.202103837939596,
        '68': 32.20216985929749,
        '69': 32.202240314568364,
        '70': 32.20229631237756,
        '71': 32.20232660132728,
        '72': 32.20233948301956,
        '73': 32.20235939056101,
        '74': 32.20240688670328,
        '75': 32.20247584878032,
        '76': 32.20252698286914,
        '77': 32.20250671361115,
        '78': 32.20238165432031,
        '79': 32.20216568051312,
        '80': 32.201919837026885,
        '81': 32.201723277476475,
        '82': 32.201633178908665,
        '83': 32.20165865762454,
        '84': 32.201763278642595,
        '85': 32.201891049720025,
        '86': 32.20199650918729,
        '87': 32.20206034243228,
        '88': 32.20208550664623,
        '89': 32.20208336462531,
        '90': 32.20206365211077,
        '91': 32.20203445993872,
        '92': 32.20200706911784,
        '93': 32.20199600816972,
        '94': 32.2020107101714,
        '95': 32.202045394452,
        '96': 32.2020780787881,
        '97': 32.20208312973291,
        '98': 32.20204964891169,
        '99': 32.20199111100644,
        '100': 32.20193697341339,
        '101': 32.20191106080181,
        '102': 32.20191297948685,
        '103': 32.201917662109416,
        '104': 32.20189446733315,
        '105': 32.20183163066362,
        '106': 32.20174695652025,
        '107': 32.20167585681153,
        '108': 32.201645465888454,
        '109': 32.20165472051348,
        '110': 32.20167596872302,
        '111': 32.20167699655627,
        '112': 32.201646314607274,
        '113': 32.20160179245595,
        '114': 32.20157561690287,
        '115': 32.20158715516041,
        '116': 32.20162508582766,
        '117': 32.201653306085205,
        '118': 32.201636761308805,
        '119': 32.201567693194185,
        '120': 32.201472139789274,
        '121': 32.20139153128166,
        '122': 32.201353347353674,
        '123': 32.20135316590728,
        '124': 32.201361488983714,
        '125': 32.20134944954961,
        '126': 32.201312954464264,
        '127': 32.20127633255815,
        '128': 32.20127281895999,
        '129': 32.20131736895589,
        '130': 32.20139315580834,
        '131': 32.20146226645658,
        '132': 32.201492283920835,
        '133': 32.20147842691196,
        '134': 32.20144488544917,
        '135': 32.20142547425359,
        '136': 32.20143959210933,
        '137': 32.20148235528567,
        '138': 32.201535656849614,
        '139': 32.2015895541234,
        '140': 32.201654645622874,
        '141': 32.20175312567449,
        '142': 32.201893870397065,
        '143': 32.202051534016256,
        '144': 32.202169275926686,
        '145': 32.20218843402264,
        '146': 32.20208753445689,
        '147': 32.20190321776793,
        '148': 32.20171550789006,
        '149': 32.20160377915701,
        '150': 32.20160121399303,
        '151': 32.20167843427009,
        '152': 32.20176783047568,
        '153': 32.20181157327713,
        '154': 32.20179892244384,
        '155': 32.201765770804094,
        '156': 32.20175718473451,
        '157': 32.20178211569502,
        '158': 32.2017969017719,
        '159': 32.201733772757485,
        '160': 32.201556311159244,
        '161': 32.201301085465424,
        '162': 32.201071398431495,
        '163': 32.20098175955314,
        '164': 32.20108716990624,
        '165': 32.20134371196404,
        '166': 32.201626981435226,
        '167': 32.20179694152929,
        '168': 32.20176887151714,
        '169': 32.201549515353626,
        '170': 32.20122309271836,
        '171': 32.20090318009012,
        '172': 32.20068198561137,
        '173': 32.20060137440392,
        '174': 32.20065075189038,
        '175': 32.20078222079603,
        '176': 32.20093151479692,
        '177': 32.20103912749529,
        '178': 32.201069586286685,
        '179': 32.20102408901987,
        '180': 32.2009386633627,
        '-180': 32.2009386633627,
        '-179': 32.200864654290235,
        '-178': 32.20084038846863,
        '-177': 32.20087197555617,
        '-176': 32.20093677272699,
        '-175': 32.20100599381736,
        '-174': 32.20106715937136,
        '-173': 32.20112740329637,
        '-172': 32.201196238699104,
        '-171': 32.20126595787179,
        '-170': 32.20131086838255,
        '-169': 32.201308955234005,
        '-168': 32.20126685484911,
        '-167': 32.201222964348,
        '-166': 32.20122150969992,
        '-165': 32.20127734006543,
        '-164': 32.2013622340823,
        '-163': 32.201426901479024,
        '-162': 32.20144198628805,
        '-161': 32.20142357751434,
        '-160': 32.20142073036295,
        '-159': 32.20147466912886,
        '-158': 32.20158372250368,
        '-157': 32.20170319622227,
        '-156': 32.20177962632283,
        '-155': 32.201790308117424,
        '-154': 32.20175613776784,
        '-153': 32.2017199229048,
        '-152': 32.20171127044217,
        '-151': 32.201727907554606,
        '-150': 32.20174602321597,
        '-149': 32.2017462066197,
        '-148': 32.20173026264615,
        '-147': 32.20171594472533,
        '-146': 32.20171846376089,
        '-145': 32.20173863627158,
        '-144': 32.201768581965474,
        '-143': 32.201807064909985,
        '-142': 32.201866586248244,
        '-141': 32.20196256205965,
        '-140': 32.202093114332314,
        '-139': 32.20222866346635,
        '-138': 32.20232339294028,
        '-137': 32.202342006961246,
        '-136': 32.202281681132845,
        '-135': 32.20217257789161,
        '-134': 32.20205749604377,
        '-133': 32.201967186995354,
        '-132': 32.20190939957463,
        '-131': 32.20187659417147,
        '-130': 32.201861676016186,
        '-129': 32.20186639541401,
        '-128': 32.20189621810537,
        '-127': 32.20194915750086,
        '-126': 32.20201155907798,
        '-125': 32.20206645873761,
        '-124': 32.20210717597405,
        '-123': 32.20214263593214,
        '-122': 32.202187704350756,
        '-121': 32.20224577806703,
        '-120': 32.20229927006962,
        '-119': 32.20231820087759,
        '-118': 32.20228180578183,
        '-117': 32.20219604519782,
        '-116': 32.20209209163605,
        '-115': 32.20200606349429,
        '-114': 32.20195573716017,
        '-113': 32.20193232624719,
        '-112': 32.201912525898436,
        '-111': 32.20187911060012,
        '-110': 32.20183224133213,
        '-109': 32.20178341515334,
        '-108': 32.20174044711452,
        '-107': 32.20169973601879,
        '-106': 32.20165402763391,
        '-105': 32.2016075528513,
        '-104': 32.20158151274086,
        '-103': 32.20160064856379,
        '-102': 32.20166975763754,
        '-101': 32.20176079610068,
        '-100': 32.20182474128495,
        '-99': 32.20182203648408,
        '-98': 32.20174876374199,
        '-97': 32.20163795664951,
        '-96': 32.20153555977753,
        '-95': 32.201471259218714,
        '-94': 32.20144745150526,
        '-93': 32.20145218122316,
        '-92': 32.20147973091825,
        '-91': 32.201536196087766,
        '-90': 32.20162326185805,
        '-89': 32.20171720067767,
        '-88': 32.2017689630359,
        '-87': 32.201734630175906,
        '-86': 32.20161622154109,
        '-85': 32.20147718166353,
        '-84': 32.20141159240965,
        '-83': 32.2014823076217,
        '-82': 32.20167167887578,
        '-81': 32.201884011240104,
        '-80': 32.20200219901412,
        '-79': 32.20196018214661,
        '-78': 32.201780727302136,
        '-77': 32.20155469078483,
        '-76': 32.20138190361224,
        '-75': 32.20131968408208,
        '-74': 32.201372789627754,
        '-73': 32.20152047302532,
        '-72': 32.201745752066074,
        '-71': 32.20195416421707,
        '-70': 32.202228695149415,
        '-69': 32.20191639122115,
        '-68': 32.198056153313956,
        '-67': 32.201478121546714,
        '-66': 32.20210864841583,
        '-65': 32.20162504130811,
        '-64': 32.20127290996233,
        '-63': 32.20122115677732,
        '-62': 32.201499455145346,
        '-61': 32.201976822603434,
        '-60': 32.20243023239895,
        '-59': 32.202663514289135,
        '-58': 32.20260705263516,
        '-57': 32.202342838479574,
        '-56': 32.202045880835826,
        '-55': 32.2018814266082,
        '-54': 32.20191919699071,
        '-53': 32.20211069885948,
        '-52': 32.20233554696577,
        '-51': 32.20248255891894,
        '-50': 32.20251404521387,
        '-49': 32.202475809782996,
        '-48': 32.20245138669255,
        '-47': 32.202494928874096,
        '-46': 32.20259058754981,
        '-45': 32.20266779723123,
        '-44': 32.20266231596744,
        '-43': 32.20257813792826,
        '-42': 32.202500708722894,
        '-41': 32.20254368590457,
        '-40': 32.20276035653377,
        '-39': 32.2030822871399,
        '-38': 32.20333607804476,
        '-37': 32.203337773552754,
        '-36': 32.20300739924053,
        '-35': 32.20242520804213,
        '-34': 32.20178570048128,
        '-33': 32.20127516602102,
        '-32': 32.20095525179999,
        '-31': 32.200736090319566,
        '-30': 32.20046264309181,
        '-29': 32.200056588375226,
        '-28': 32.199611275699716,
        '-27': 32.19936384434074,
        '-26': 32.19955165990082,
        '-25': 32.20024353600272,
        '-24': 32.20126062005811,
        '-23': 32.20224744558787,
        '-22': 32.20285569695349,
        '-21': 32.20292836440727,
        '-20': 32.20257129340575,
        '-19': 32.202072425524456,
        '-18': 32.20172588753798,
        '-17': 32.2016737235564,
        '-16': 32.20185781698906,
        '-15': 32.202096483721284,
        '-14': 32.20222041792643,
        '-13': 32.20217420019301,
        '-12': 32.202025817414224,
        '-11': 32.201895541458256,
        '-10': 32.20186640458597,
        '-9': 32.20193933646857,
        '-8': 32.20205363248284,
        '-7': 32.20214410286352,
        '-6': 32.20218612207181,
        '-5': 32.202198623894986,
        '-4': 32.20221265342598,
        '-3': 32.202238239709466,
        '-2': 32.20225779804001,
        '-1': 32.20224785362599
    },
    '-56': {
        '0': 32.204700786189974,
        '1': 32.20467919801431,
        '2': 32.20467785434292,
        '3': 32.20467778314745,
        '4': 32.204656412585614,
        '5': 32.204595961020665,
        '6': 32.2044918675486,
        '7': 32.20435554867627,
        '8': 32.204209200243156,
        '9': 32.204076370882724,
        '10': 32.20397503275145,
        '11': 32.203916891672016,
        '12': 32.20391036459506,
        '13': 32.20396105472883,
        '14': 32.2040665009333,
        '15': 32.20420915467686,
        '16': 32.20435607074433,
        '17': 32.20447034588151,
        '18': 32.20452949073118,
        '19': 32.20453780361001,
        '20': 32.204521558564494,
        '21': 32.20450801939811,
        '22': 32.204503152752594,
        '23': 32.204486535929384,
        '24': 32.20443034197674,
        '25': 32.204329821938394,
        '26': 32.204220689402725,
        '27': 32.2041655467693,
        '28': 32.20421417404923,
        '29': 32.204364693857656,
        '30': 32.204556157645,
        '31': 32.20470278401208,
        '32': 32.204749375370376,
        '33': 32.20470941135299,
        '34': 32.204657034964676,
        '35': 32.20467616977872,
        '36': 32.20480135084034,
        '37': 32.20499191715633,
        '38': 32.20515797975335,
        '39': 32.205219785929906,
        '40': 32.20515883799353,
        '41': 32.20502623350047,
        '42': 32.204905124544936,
        '43': 32.20485611523588,
        '44': 32.204883725190236,
        '45': 32.20494363898571,
        '46': 32.204980032535026,
        '47': 32.20496307293896,
        '48': 32.20490137973997,
        '49': 32.2048262732079,
        '50': 32.204765278175486,
        '51': 32.20472640077607,
        '52': 32.2047018036372,
        '53': 32.20468254460744,
        '54': 32.20466913648153,
        '55': 32.20466965160348,
        '56': 32.204689741278955,
        '57': 32.20472552901573,
        '58': 32.20476603553042,
        '59': 32.20480199574575,
        '60': 32.20483229613023,
        '61': 32.204862567891524,
        '62': 32.20489866227992,
        '63': 32.20494238721152,
        '64': 32.204993503328744,
        '65': 32.20505426286666,
        '66': 32.20512895975559,
        '67': 32.205215697277346,
        '68': 32.205297008091854,
        '69': 32.20534074927024,
        '70': 32.20531662435973,
        '71': 32.20522012079087,
        '72': 32.205085760731706,
        '73': 32.204975207232565,
        '74': 32.20494254855072,
        '75': 32.204997355339835,
        '76': 32.20509140539789,
        '77': 32.20514153393633,
        '78': 32.205077170414526,
        '79': 32.204883576634586,
        '80': 32.204613510234395,
        '81': 32.204360210845614,
        '82': 32.20420920480588,
        '83': 32.20419849997525,
        '84': 32.20430877119646,
        '85': 32.20448407464609,
        '86': 32.2046650725393,
        '87': 32.204812997740135,
        '88': 32.20491396108897,
        '89': 32.204969051287165,
        '90': 32.20498360919427,
        '91': 32.20496462773567,
        '92': 32.204924443083414,
        '93': 32.204882123633176,
        '94': 32.20485687410408,
        '95': 32.20485683530145,
        '96': 32.204872884812715,
        '97': 32.20488422746352,
        '98': 32.20487285036314,
        '99': 32.20483588885278,
        '100': 32.204786012116095,
        '101': 32.204739667271454,
        '102': 32.20470329437025,
        '103': 32.20466949340334,
        '104': 32.204626601776155,
        '105': 32.20457320045421,
        '106': 32.20452398080403,
        '107': 32.20450004228824,
        '108': 32.20451009227893,
        '109': 32.20453810674639,
        '110': 32.204549617971395,
        '111': 32.204514679941965,
        '112': 32.204431522247624,
        '113': 32.204332421027324,
        '114': 32.204265272496066,
        '115': 32.20426229572074,
        '116': 32.20431762279808,
        '117': 32.20438999710432,
        '118': 32.20442907754851,
        '119': 32.20440701099531,
        '120': 32.204333537256844,
        '121': 32.204245346947715,
        '122': 32.20417917664361,
        '123': 32.204149068208004,
        '124': 32.204143465952505,
        '125': 32.20414166401092,
        '126': 32.20413441810211,
        '127': 32.20413157014213,
        '128': 32.20415123701696,
        '129': 32.20420064506264,
        '130': 32.20426547247428,
        '131': 32.204317321754935,
        '132': 32.20433379454355,
        '133': 32.20431502471187,
        '134': 32.20428314969336,
        '135': 32.20426502018271,
        '136': 32.20427227762567,
        '137': 32.204295376478875,
        '138': 32.20431680385265,
        '139': 32.20433243669041,
        '140': 32.204361929012244,
        '141': 32.20443648123473,
        '142': 32.20457044011453,
        '143': 32.204738113642065,
        '144': 32.2048765748201,
        '145': 32.204917918892356,
        '146': 32.204832216668414,
        '147': 32.20465184307802,
        '148': 32.20445800560889,
        '149': 32.204335346851835,
        '150': 32.20432317046585,
        '151': 32.20439550993826,
        '152': 32.2044832811743,
        '153': 32.20452285959403,
        '154': 32.204497076079114,
        '155': 32.204440292594285,
        '156': 32.204405230525,
        '157': 32.204417014899256,
        '158': 32.204449251147494,
        '159': 32.20444081200262,
        '160': 32.20434192376042,
        '161': 32.20415600845841,
        '162': 32.203945795013816,
        '163': 32.203797114856265,
        '164': 32.20376369982173,
        '165': 32.20382996207815,
        '166': 32.20391685206683,
        '167': 32.20392737267739,
        '168': 32.20380319001325,
        '169': 32.20355840987022,
        '170': 32.20327268943296,
        '171': 32.20305084701775,
        '172': 32.202973490611,
        '173': 32.203063988073225,
        '174': 32.2032843812737,
        '175': 32.20355689610717,
        '176': 32.2037975814772,
        '177': 32.20394699069943,
        '178': 32.203987059710585,
        '179': 32.20393985943963,
        '180': 32.203850822624034,
        '-180': 32.203850822624034,
        '-179': 32.20376522214024,
        '-178': 32.203709762633196,
        '-177': 32.20368827120204,
        '-176': 32.20369164314709,
        '-175': 32.20371251668636,
        '-174': 32.20375213551371,
        '-173': 32.203813990311325,
        '-172': 32.20389116337724,
        '-171': 32.20396131154571,
        '-170': 32.203997699892824,
        '-169': 32.20399008575368,
        '-168': 32.20395802599178,
        '-167': 32.20394242945023,
        '-166': 32.20397807996804,
        '-165': 32.20406694888738,
        '-164': 32.20417413998892,
        '-163': 32.204251822911154,
        '-162': 32.204274058500545,
        '-161': 32.20425568632631,
        '-160': 32.2042403033822,
        '-159': 32.20426672287945,
        '-158': 32.204340283495824,
        '-157': 32.20443099892075,
        '-156': 32.204498594019256,
        '-155': 32.20452328552774,
        '-154': 32.20451795345552,
        '-153': 32.204513854177854,
        '-152': 32.204533660744495,
        '-151': 32.204574420941285,
        '-150': 32.20461281904847,
        '-149': 32.20462589402291,
        '-148': 32.20460873619573,
        '-147': 32.204575487967375,
        '-146': 32.20454549973177,
        '-145': 32.20452859827555,
        '-144': 32.204522253647994,
        '-143': 32.2045213536321,
        '-142': 32.20452975384837,
        '-141': 32.20456170515251,
        '-140': 32.20463080178263,
        '-139': 32.204735361569995,
        '-138': 32.204852542263765,
        '-137': 32.20494673869135,
        '-136': 32.20498704538298,
        '-135': 32.20496245242021,
        '-134': 32.204885921811254,
        '-133': 32.20478646475759,
        '-132': 32.20469528657312,
        '-131': 32.20463370199593,
        '-130': 32.20460747420302,
        '-129': 32.20460812358944,
        '-128': 32.204619356811186,
        '-127': 32.20462603876228,
        '-126': 32.204622567056944,
        '-125': 32.204616768739655,
        '-124': 32.2046261116398,
        '-123': 32.20466660560218,
        '-122': 32.2047400492718,
        '-121': 32.20482823736832,
        '-120': 32.20489977528806,
        '-119': 32.20492692678435,
        '-118': 32.20490203493105,
        '-117': 32.2048420496835,
        '-116': 32.2047774144867,
        '-115': 32.20473280300152,
        '-114': 32.20471326486121,
        '-113': 32.204705113595786,
        '-112': 32.20468946007953,
        '-111': 32.20465685224994,
        '-110': 32.20461175717686,
        '-109': 32.20456517946508,
        '-108': 32.20452409774626,
        '-107': 32.20448839329283,
        '-106': 32.20445777477607,
        '-105': 32.204440425918435,
        '-104': 32.204452061282026,
        '-103': 32.20450244840555,
        '-102': 32.204579348733375,
        '-101': 32.20464534164767,
        '-100': 32.20465480189777,
        '-99': 32.204581988517205,
        '-98': 32.204440302064924,
        '-97': 32.204277344832,
        '-96': 32.204147956319325,
        '-95': 32.2040841396334,
        '-94': 32.20408294976612,
        '-93': 32.20411891540815,
        '-92': 32.20416822862774,
        '-91': 32.204223765490724,
        '-90': 32.204289647287865,
        '-89': 32.204363034981256,
        '-88': 32.204422670631466,
        '-87': 32.204437925614535,
        '-86': 32.20439351253879,
        '-85': 32.20430955926405,
        '-84': 32.20423806216878,
        '-83': 32.20423437940144,
        '-82': 32.204322126671016,
        '-81': 32.204475556223564,
        '-80': 32.20463088452663,
        '-79': 32.20471778456889,
        '-78': 32.204690603709324,
        '-77': 32.204543648934326,
        '-76': 32.20430948610802,
        '-75': 32.204049476733786,
        '-74': 32.20384340471761,
        '-73': 32.2037751031311,
        '-72': 32.20390671272026,
        '-71': 32.204243595053306,
        '-70': 32.20470857417962,
        '-69': 32.20515138161082,
        '-68': 32.20540472757072,
        '-67': 32.205367472177315,
        '-66': 32.205069358879854,
        '-65': 32.20467284061825,
        '-64': 32.20440039180572,
        '-63': 32.204421645057856,
        '-62': 32.204763236255786,
        '-61': 32.20529449098451,
        '-60': 32.20579827727793,
        '-59': 32.20608585430835,
        '-58': 32.20608944059703,
        '-57': 32.205881798408015,
        '-56': 32.20561745776509,
        '-55': 32.20543631347838,
        '-54': 32.20538923691259,
        '-53': 32.20542718315397,
        '-52': 32.20545328287799,
        '-51': 32.20539860348326,
        '-50': 32.20527030525059,
        '-49': 32.205141950485014,
        '-48': 32.205095384114486,
        '-47': 32.205156258020665,
        '-46': 32.2052697563899,
        '-45': 32.205335961977454,
        '-44': 32.20528272654125,
        '-43': 32.205125510950154,
        '-42': 32.20496889233434,
        '-41': 32.204942223165524,
        '-40': 32.2051085522228,
        '-39': 32.205409233911844,
        '-38': 32.20568773929915,
        '-37': 32.20578423500325,
        '-36': 32.205641288743955,
        '-35': 32.205347208165584,
        '-34': 32.20508051950134,
        '-33': 32.204985576824726,
        '-32': 32.20506099869281,
        '-31': 32.20514166023209,
        '-30': 32.20499730636267,
        '-29': 32.204492123148974,
        '-28': 32.20370276684122,
        '-27': 32.20291156272024,
        '-26': 32.20246769319907,
        '-25': 32.202594230978924,
        '-24': 32.20325697217487,
        '-23': 32.20417575885058,
        '-22': 32.20497213037774,
        '-21': 32.205364814136395,
        '-20': 32.205299247989394,
        '-19': 32.20494288352631,
        '-18': 32.204562180192774,
        '-17': 32.2043650445684,
        '-16': 32.204403172169634,
        '-15': 32.20458091947556,
        '-14': 32.20474703466215,
        '-13': 32.2047995954526,
        '-12': 32.20473850475736,
        '-11': 32.20464395030456,
        '-10': 32.20460902780401,
        '-9': 32.20467761013457,
        '-8': 32.20482477092798,
        '-7': 32.20498254712788,
        '-6': 32.205085112916436,
        '-5': 32.205101908721005,
        '-4': 32.20504307158222,
        '-3': 32.20494284460611,
        '-2': 32.20483782211182,
        '-1': 32.20475339538222
    },
    '-57': {
        '0': 32.20733537055432,
        '1': 32.20734020367835,
        '2': 32.207344739349985,
        '3': 32.207303878818934,
        '4': 32.20719920186605,
        '5': 32.207046487935166,
        '6': 32.20688487730849,
        '7': 32.20675450049512,
        '8': 32.20667569781269,
        '9': 32.20664193962411,
        '10': 32.206630179774606,
        '11': 32.20662126503214,
        '12': 32.20661625799296,
        '13': 32.20663703668873,
        '14': 32.20671023037918,
        '15': 32.20684539750584,
        '16': 32.2070229073387,
        '17': 32.207200569822916,
        '18': 32.207334904422645,
        '19': 32.207402551683366,
        '20': 32.2074075397713,
        '21': 32.207370950391784,
        '22': 32.20731280267455,
        '23': 32.20724133272022,
        '24': 32.207157874422144,
        '25': 32.20707161593122,
        '26': 32.207008958037726,
        '27': 32.207005431493904,
        '28': 32.20708248157676,
        '29': 32.207225805860446,
        '30': 32.207384184835135,
        '31': 32.20749454690896,
        '32': 32.207519308341276,
        '33': 32.20747082091603,
        '34': 32.20740457909374,
        '35': 32.207383889145426,
        '36': 32.20743905611975,
        '37': 32.207548687822516,
        '38': 32.207655651553445,
        '39': 32.20770621166797,
        '40': 32.20768520656115,
        '41': 32.20762380796958,
        '42': 32.20757617856624,
        '43': 32.20758252840835,
        '44': 32.20764379875217,
        '45': 32.20772320103358,
        '46': 32.20777073034046,
        '47': 32.20775268310881,
        '48': 32.20766798704741,
        '49': 32.20754465144872,
        '50': 32.20742288605533,
        '51': 32.20733716137597,
        '52': 32.207305838519794,
        '53': 32.20732939327392,
        '54': 32.20739355654571,
        '55': 32.20747430438218,
        '56': 32.2075445069834,
        '57': 32.207582712508824,
        '58': 32.20758188387875,
        '59': 32.20755314275671,
        '60': 32.20752071825243,
        '61': 32.20750972584622,
        '62': 32.20753392704682,
        '63': 32.20759112765591,
        '64': 32.20766823453543,
        '65': 32.20775060616922,
        '66': 32.20782757268378,
        '67': 32.20789050608783,
        '68': 32.20792776288859,
        '69': 32.207924868505245,
        '70': 32.207874051495345,
        '71': 32.207787532163536,
        '72': 32.207702209822315,
        '73': 32.20766661494499,
        '74': 32.20771347306743,
        '75': 32.20783407840354,
        '76': 32.20797331934421,
        '77': 32.20805289153416,
        '78': 32.20801178487488,
        '79': 32.20784045969721,
        '80': 32.207587757124166,
        '81': 32.207336323953356,
        '82': 32.20716184782487,
        '83': 32.20710039653867,
        '84': 32.207140985948975,
        '85': 32.20724303428639,
        '86': 32.2073632892322,
        '87': 32.20747411045416,
        '88': 32.20756488552241,
        '89': 32.207632013762066,
        '90': 32.20766980479339,
        '91': 32.20767068172066,
        '92': 32.20763299710003,
        '93': 32.20756757336892,
        '94': 32.20749547110931,
        '95': 32.207437543201245,
        '96': 32.20740368278984,
        '97': 32.20739000930042,
        '98': 32.2073855462473,
        '99': 32.20738221032969,
        '100': 32.20737979172402,
        '101': 32.207382655309054,
        '102': 32.20739246064625,
        '103': 32.20740452945983,
        '104': 32.20741166229064,
        '105': 32.20741169943107,
        '106': 32.20741081664102,
        '107': 32.2074177060038,
        '108': 32.20743215651474,
        '109': 32.20743796503918,
        '110': 32.20740848194428,
        '111': 32.207323763688315,
        '112': 32.20718825560488,
        '113': 32.207035445365406,
        '114': 32.206913845804124,
        '115': 32.206861714046944,
        '116': 32.20688645572474,
        '117': 32.206962060483534,
        '118': 32.20704578081864,
        '119': 32.20710240352379,
        '120': 32.207120207403726,
        '121': 32.20710982656071,
        '122': 32.20708996414879,
        '123': 32.2070725069848,
        '124': 32.20705797094687,
        '125': 32.207042431635145,
        '126': 32.207027468648135,
        '127': 32.20702291285456,
        '128': 32.207039336064796,
        '129': 32.20707690905821,
        '130': 32.207121120206864,
        '131': 32.20715046861011,
        '132': 32.20715100968753,
        '133': 32.20712618419525,
        '134': 32.20709346529867,
        '135': 32.20706995326122,
        '136': 32.20705862569732,
        '137': 32.20704730991214,
        '138': 32.20702251567077,
        '139': 32.20698747958154,
        '140': 32.20696855582401,
        '141': 32.207001591002886,
        '142': 32.207105416638726,
        '143': 32.20726143859256,
        '144': 32.207416593903474,
        '145': 32.20751146731794,
        '146': 32.20751659439581,
        '147': 32.20745190953526,
        '148': 32.2073740180135,
        '149': 32.20733781445592,
        '150': 32.20735783827206,
        '151': 32.20739667097263,
        '152': 32.20739014339961,
        '153': 32.207293609512725,
        '154': 32.207117863433176,
        '155': 32.20692896425265,
        '156': 32.20681007151518,
        '157': 32.20680929616676,
        '158': 32.20690766237111,
        '159': 32.207028422520615,
        '160': 32.20708186352159,
        '161': 32.207016826415625,
        '162': 32.206846707863704,
        '163': 32.20663538644808,
        '164': 32.206454726931284,
        '165': 32.20634240279438,
        '166': 32.206286186574616,
        '167': 32.206241753479354,
        '168': 32.20616917476806,
        '169': 32.20606293392829,
        '170': 32.205956853952166,
        '171': 32.20590275957658,
        '172': 32.20593792278087,
        '173': 32.20606165751558,
        '174': 32.206234141350485,
        '175': 32.206396619146595,
        '176': 32.20650035753894,
        '177': 32.206528186962295,
        '178': 32.206497942114396,
        '179': 32.206447689738965,
        '180': 32.20641243300382,
        '-180': 32.20641243300382,
        '-179': 32.20640600791426,
        '-178': 32.20641814845703,
        '-177': 32.20642721126036,
        '-176': 32.20641922130053,
        '-175': 32.206399884166906,
        '-174': 32.206391074424864,
        '-173': 32.2064143464999,
        '-172': 32.206473732085975,
        '-171': 32.20655113063179,
        '-170': 32.206618495101765,
        '-169': 32.20665810438108,
        '-168': 32.206675435915486,
        '-167': 32.20669431908803,
        '-166': 32.20673771111622,
        '-165': 32.206808992240475,
        '-164': 32.206888644944456,
        '-163': 32.20694913616367,
        '-162': 32.20697621650436,
        '-161': 32.20697957786506,
        '-160': 32.20698437423456,
        '-159': 32.207010666685335,
        '-158': 32.20705766919939,
        '-157': 32.20710563172078,
        '-156': 32.20713360705817,
        '-155': 32.20713824900172,
        '-154': 32.207137957697036,
        '-153': 32.20715872331028,
        '-152': 32.2072129527372,
        '-151': 32.20728821113243,
        '-150': 32.207354775691144,
        '-149': 32.20738596167837,
        '-148': 32.20737545792207,
        '-147': 32.2073389018296,
        '-146': 32.20729971446289,
        '-145': 32.20727129878093,
        '-144': 32.20724969075154,
        '-143': 32.207221669561086,
        '-142': 32.207180849628394,
        '-141': 32.207138203367634,
        '-140': 32.20711809245662,
        '-139': 32.207142165024294,
        '-138': 32.20721269364475,
        '-137': 32.207307482343076,
        '-136': 32.20739042288042,
        '-135': 32.20743118110195,
        '-134': 32.20742161959547,
        '-133': 32.207378929153705,
        '-132': 32.207333970081024,
        '-131': 32.20731232289824,
        '-130': 32.207319586355965,
        '-129': 32.20733949220257,
        '-128': 32.207345538116854,
        '-127': 32.207318791176945,
        '-126': 32.207260906397366,
        '-125': 32.207194301645075,
        '-124': 32.207149217593845,
        '-123': 32.20714553847255,
        '-122': 32.20718074490554,
        '-121': 32.2072317441085,
        '-120': 32.20726950802401,
        '-119': 32.20727700161796,
        '-118': 32.207258567887855,
        '-117': 32.207234683517896,
        '-116': 32.207226130092316,
        '-115': 32.20723903993207,
        '-114': 32.20726146615311,
        '-113': 32.20727347219295,
        '-112': 32.207262603264944,
        '-111': 32.20723274079173,
        '-110': 32.20719984469884,
        '-109': 32.207178702374335,
        '-108': 32.20717202432693,
        '-107': 32.20717105737252,
        '-106': 32.20716690067236,
        '-105': 32.207162107732415,
        '-104': 32.20717121467956,
        '-103': 32.20720791592721,
        '-102': 32.20726859669928,
        '-101': 32.2073267199238,
        '-100': 32.207345392260734,
        '-99': 32.2073012409833,
        '-98': 32.20720266266195,
        '-97': 32.20708806826889,
        '-96': 32.207003745433624,
        '-95': 32.20697601782664,
        '-94': 32.20699692538682,
        '-93': 32.2070330063898,
        '-92': 32.20705014513466,
        '-91': 32.20703634935202,
        '-90': 32.20700680690706,
        '-89': 32.20698922884916,
        '-88': 32.20700168029293,
        '-87': 32.20703944830419,
        '-86': 32.20707964487139,
        '-85': 32.207098973404364,
        '-84': 32.2070913811787,
        '-83': 32.207073633853945,
        '-82': 32.20707578213135,
        '-81': 32.207122877401176,
        '-80': 32.20721830438675,
        '-79': 32.20733700119451,
        '-78': 32.2074318322435,
        '-77': 32.20745162504077,
        '-76': 32.20736550751859,
        '-75': 32.20718451829043,
        '-74': 32.20696902944076,
        '-73': 32.20681279828363,
        '-72': 32.20680414992679,
        '-71': 32.20697925727699,
        '-70': 32.20729289859979,
        '-69': 32.20762869254525,
        '-68': 32.20785130916272,
        '-67': 32.20787704591558,
        '-66': 32.207723350864356,
        '-65': 32.20750526611207,
        '-64': 32.207375928110345,
        '-63': 32.20744242791134,
        '-62': 32.20770546215213,
        '-61': 32.20805884064427,
        '-60': 32.20834949120557,
        '-59': 32.2084620803708,
        '-58': 32.20837798116987,
        '-57': 32.20817516452297,
        '-56': 32.20797270571366,
        '-55': 32.2078572654729,
        '-54': 32.207838030012944,
        '-53': 32.20785608310293,
        '-52': 32.20783790167827,
        '-51': 32.20775425756573,
        '-50': 32.20764344165855,
        '-49': 32.20758262199019,
        '-48': 32.207626967213216,
        '-47': 32.20775938761344,
        '-46': 32.207888558162374,
        '-45': 32.20790159707577,
        '-44': 32.207740059953856,
        '-43': 32.20744883163414,
        '-42': 32.207161309672124,
        '-41': 32.20702429111761,
        '-40': 32.20710739423149,
        '-39': 32.207356680810776,
        '-38': 32.207627723919316,
        '-37': 32.207782488857916,
        '-36': 32.20778915036979,
        '-35': 32.20775567154663,
        '-34': 32.2078658295687,
        '-33': 32.20824936560662,
        '-32': 32.20886624216676,
        '-31': 32.20948497236239,
        '-30': 32.20978231342286,
        '-29': 32.209516585215326,
        '-28': 32.20867648127187,
        '-27': 32.20751495376632,
        '-26': 32.20644131845142,
        '-25': 32.205826753491095,
        '-24': 32.205829164376944,
        '-23': 32.206331854915604,
        '-22': 32.20702391671618,
        '-21': 32.20756999548376,
        '-20': 32.2077715727625,
        '-19': 32.20763583844742,
        '-18': 32.2073286021572,
        '-17': 32.20705473832074,
        '-16': 32.20694348811857,
        '-15': 32.20700070506838,
        '-14': 32.20714122862533,
        '-13': 32.207265820033825,
        '-12': 32.20732762989711,
        '-11': 32.20734996244088,
        '-10': 32.20739389550047,
        '-9': 32.20750536245097,
        '-8': 32.207678823230715,
        '-7': 32.20785833840078,
        '-6': 32.20797087101543,
        '-5': 32.207968217478644,
        '-4': 32.20785241485125,
        '-3': 32.20767235199654,
        '-2': 32.207496597251364,
        '-1': 32.20737906313136
    },
    '-58': {
        '0': 32.20994840480519,
        '1': 32.209979645250556,
        '2': 32.20998804411635,
        '3': 32.2099244962918,
        '4': 32.20977829233898,
        '5': 32.209584231991734,
        '6': 32.209403875396546,
        '7': 32.20929204776037,
        '8': 32.209268217147375,
        '9': 32.209309250007244,
        '10': 32.209367071313174,
        '11': 32.20939972506119,
        '12': 32.20939648619678,
        '13': 32.20938190245225,
        '14': 32.20939723073086,
        '15': 32.2094719518768,
        '16': 32.209603764969685,
        '17': 32.20975916736938,
        '18': 32.20989290806009,
        '19': 32.20997268670998,
        '20': 32.20999331989147,
        '21': 32.209973095235576,
        '22': 32.20993765424135,
        '23': 32.20990438578135,
        '24': 32.20987791759101,
        '25': 32.20985738815414,
        '26': 32.209846746273904,
        '27': 32.20985761747902,
        '28': 32.209901307698395,
        '29': 32.20997640137092,
        '30': 32.21006306400343,
        '31': 32.21013066432857,
        '32': 32.21015505204465,
        '33': 32.21013379522495,
        '34': 32.21008831071271,
        '35': 32.21005087417063,
        '36': 32.210045241419635,
        '37': 32.21007401276369,
        '38': 32.2101207381368,
        '39': 32.210164092138605,
        '40': 32.21019322522416,
        '41': 32.21021344673354,
        '42': 32.21023914597202,
        '43': 32.21028015598458,
        '44': 32.210331835837515,
        '45': 32.21037563881336,
        '46': 32.21038919803043,
        '47': 32.21035892875644,
        '48': 32.21028763472379,
        '49': 32.21019385300358,
        '50': 32.21010473961756,
        '51': 32.210046684437096,
        '52': 32.2100370695081,
        '53': 32.21007876782621,
        '54': 32.21015823511991,
        '55': 32.21024836963823,
        '56': 32.21031690844361,
        '57': 32.21033870810832,
        '58': 32.21030693179367,
        '59': 32.210236894207604,
        '60': 32.210159244852434,
        '61': 32.21010523670742,
        '62': 32.210092010465495,
        '63': 32.21011613204118,
        '64': 32.21015844006939,
        '65': 32.21019615563888,
        '66': 32.21021429059979,
        '67': 32.21021029562641,
        '68': 32.21019167365793,
        '69': 32.21017107638645,
        '70': 32.210163465793,
        '71': 32.21018576530936,
        '72': 32.21025529252928,
        '73': 32.21038340095145,
        '74': 32.21056555748279,
        '75': 32.21077447561295,
        '76': 32.21096367477413,
        '77': 32.211083121366315,
        '78': 32.211100018470134,
        '79': 32.21101289951659,
        '80': 32.21085030747454,
        '81': 32.210654941176045,
        '82': 32.21046353875833,
        '83': 32.21029506331227,
        '84': 32.2101531848654,
        '85': 32.21003846196911,
        '86': 32.20995889912128,
        '87': 32.20992963370908,
        '88': 32.20996163004855,
        '89': 32.21004835606212,
        '90': 32.210161543508754,
        '91': 32.21026074284511,
        '92': 32.21031140366292,
        '93': 32.21030013865591,
        '94': 32.2102379902375,
        '95': 32.21015094722496,
        '96': 32.21006518423726,
        '97': 32.20999666100203,
        '98': 32.2099499338365,
        '99': 32.209923664113894,
        '100': 32.20991620492596,
        '101': 32.20992647889356,
        '102': 32.20995080856914,
        '103': 32.20998040872554,
        '104': 32.21000349529684,
        '105': 32.21001135300705,
        '106': 32.210003511972154,
        '107': 32.20998726105469,
        '108': 32.20997118679814,
        '109': 32.20995755572724,
        '110': 32.20993974841878,
        '111': 32.20990723383942,
        '112': 32.209854610079255,
        '113': 32.20978782867087,
        '114': 32.209722666930205,
        '115': 32.20967632469879,
        '116': 32.209658114330765,
        '117': 32.20966561896524,
        '118': 32.20968824796598,
        '119': 32.20971452303085,
        '120': 32.20973717884978,
        '121': 32.209752866422804,
        '122': 32.20975837213604,
        '123': 32.20974832397472,
        '124': 32.20971770774738,
        '125': 32.20966761791682,
        '126': 32.209608873614606,
        '127': 32.209559000455485,
        '128': 32.209533256988195,
        '129': 32.209535702126985,
        '130': 32.209557133022564,
        '131': 32.20958191854739,
        '132': 32.209598851095386,
        '133': 32.20960772022839,
        '134': 32.20961647231048,
        '135': 32.20963141727764,
        '136': 32.20964899077965,
        '137': 32.20965690618106,
        '138': 32.209645104820595,
        '139': 32.209618288049036,
        '140': 32.20959906128549,
        '141': 32.209616803314994,
        '142': 32.20968837310648,
        '143': 32.209804367483244,
        '144': 32.20993233576393,
        '145': 32.21003676183389,
        '146': 32.21010270582241,
        '147': 32.210145593244185,
        '148': 32.21019807400966,
        '149': 32.2102811688341,
        '150': 32.210379710851456,
        '151': 32.210441534339104,
        '152': 32.210405028381864,
        '153': 32.21023990939231,
        '154': 32.20997497129723,
        '155': 32.209692561630554,
        '156': 32.20948965316571,
        '157': 32.209426867596584,
        '158': 32.209495479054674,
        '159': 32.2096222621377,
        '160': 32.209709369968536,
        '161': 32.20968571064433,
        '162': 32.20954050364319,
        '163': 32.209321847389795,
        '164': 32.20910472190666,
        '165': 32.20894997120143,
        '166': 32.208878355855575,
        '167': 32.20887159351797,
        '168': 32.2088944954385,
        '169': 32.20892054474387,
        '170': 32.2089441415077,
        '171': 32.20897412701417,
        '172': 32.209016566903834,
        '173': 32.20906130801946,
        '174': 32.209082996004824,
        '175': 32.209056433934165,
        '176': 32.2089758736847,
        '177': 32.20886468216531,
        '178': 32.20876760615663,
        '179': 32.20872878734929,
        '180': 32.208767922107185,
        '-180': 32.208767922107185,
        '-179': 32.20886877200469,
        '-178': 32.20898734069345,
        '-177': 32.20907545053991,
        '-176': 32.20910618245878,
        '-175': 32.20908648140845,
        '-174': 32.20905007615241,
        '-173': 32.20903590509018,
        '-172': 32.209065827957986,
        '-171': 32.20913500521434,
        '-170': 32.20921936151295,
        '-169': 32.20929325870062,
        '-168': 32.20934465480541,
        '-167': 32.2093783575386,
        '-166': 32.20940750915002,
        '-165': 32.20944177553477,
        '-164': 32.20948174200737,
        '-163': 32.20952257113277,
        '-162': 32.20956178610143,
        '-161': 32.20960297333511,
        '-160': 32.209651602874786,
        '-159': 32.20970699103468,
        '-158': 32.209758679790845,
        '-157': 32.20979221091162,
        '-156': 32.20980097071811,
        '-155': 32.209794535516984,
        '-154': 32.20979544456694,
        '-153': 32.20982496327994,
        '-152': 32.209887497684896,
        '-151': 32.209965418814264,
        '-150': 32.210029002293666,
        '-149': 32.210055079925375,
        '-148': 32.21004131129966,
        '-147': 32.21000595436705,
        '-146': 32.209973539748056,
        '-145': 32.209957086095365,
        '-144': 32.2099498338469,
        '-143': 32.209932283770705,
        '-142': 32.20988903737234,
        '-141': 32.20982286477836,
        '-140': 32.2097555964244,
        '-139': 32.209715051315726,
        '-138': 32.20971721189797,
        '-137': 32.20975613889169,
        '-136': 32.20980856330952,
        '-135': 32.20984981260075,
        '-134': 32.20986996323222,
        '-133': 32.20987912963883,
        '-132': 32.2098983998392,
        '-131': 32.209942798271115,
        '-130': 32.2100081005014,
        '-129': 32.21007074503825,
        '-128': 32.21010128184655,
        '-127': 32.21008267106177,
        '-126': 32.21002137663287,
        '-125': 32.20994384252068,
        '-124': 32.209880545495146,
        '-123': 32.20984802077622,
        '-122': 32.20984055351016,
        '-121': 32.209836682349994,
        '-120': 32.20981563687918,
        '-119': 32.20977215901523,
        '-118': 32.20971949246027,
        '-117': 32.209678822807916,
        '-116': 32.209663186717314,
        '-115': 32.2096678692264,
        '-114': 32.20967454435083,
        '-113': 32.20966617273032,
        '-112': 32.20964140589839,
        '-111': 32.209617224823724,
        '-110': 32.20961709227268,
        '-109': 32.20965290092742,
        '-108': 32.20971420293036,
        '-107': 32.20977339582615,
        '-106': 32.20980392502859,
        '-105': 32.20979866148065,
        '-104': 32.20977497635606,
        '-103': 32.20976246330947,
        '-102': 32.20978196932049,
        '-101': 32.209831137650845,
        '-100': 32.209887008985646,
        '-99': 32.20992365966686,
        '-98': 32.20993166015571,
        '-97': 32.20992468067712,
        '-96': 32.209928031757656,
        '-95': 32.209957387827615,
        '-94': 32.21000351627238,
        '-93': 32.21003494724013,
        '-92': 32.21001792593635,
        '-91': 32.20994064743426,
        '-90': 32.20982531095019,
        '-89': 32.20971920727439,
        '-88': 32.20966950777601,
        '-87': 32.2096967287506,
        '-86': 32.20978263017097,
        '-85': 32.20987961301561,
        '-84': 32.20993626895448,
        '-83': 32.20992495283219,
        '-82': 32.20985658307249,
        '-81': 32.20977498449502,
        '-80': 32.20973379880907,
        '-79': 32.20976790098165,
        '-78': 32.20987421827307,
        '-77': 32.210012424125566,
        '-76': 32.21012596670377,
        '-75': 32.21017287439816,
        '-74': 32.21014951645647,
        '-73': 32.21009315959984,
        '-72': 32.210060494756135,
        '-71': 32.210093663939666,
        '-70': 32.2101940722751,
        '-69': 32.21032099944672,
        '-68': 32.2104173549407,
        '-67': 32.21044730949105,
        '-66': 32.21042152312492,
        '-65': 32.210392213984875,
        '-64': 32.21041932079878,
        '-63': 32.21052859197586,
        '-62': 32.210689304126845,
        '-61': 32.21082793430241,
        '-60': 32.210871076481276,
        '-59': 32.210791122758174,
        '-58': 32.21062516261234,
        '-57': 32.21045394286294,
        '-56': 32.21035340469461,
        '-55': 32.210349566805704,
        '-54': 32.21040603214607,
        '-53': 32.210452530638584,
        '-52': 32.21043587399909,
        '-51': 32.21035917933337,
        '-50': 32.2102818904366,
        '-49': 32.210278474011616,
        '-48': 32.21038179652372,
        '-47': 32.2105496079008,
        '-46': 32.210680035084714,
        '-45': 32.21067107061052,
        '-44': 32.21048880607664,
        '-43': 32.210199642458115,
        '-42': 32.20994097202958,
        '-41': 32.20984285382126,
        '-40': 32.20994686678232,
        '-39': 32.210175120808984,
        '-38': 32.21037512791798,
        '-37': 32.21041921413734,
        '-36': 32.21029900021729,
        '-35': 32.21015178086954,
        '-34': 32.21019218245447,
        '-33': 32.21057994083602,
        '-32': 32.21129868465283,
        '-31': 32.21212232827259,
        '-30': 32.21270038514766,
        '-29': 32.212725600412355,
        '-28': 32.21209662550379,
        '-27': 32.21098607425877,
        '-26': 32.209773202429176,
        '-25': 32.20887344881303,
        '-24': 32.20855266521444,
        '-23': 32.20882049993226,
        '-22': 32.209452108774336,
        '-21': 32.21011739639632,
        '-20': 32.21054289852878,
        '-19': 32.21062224610515,
        '-18': 32.21042797173089,
        '-17': 32.21013511167178,
        '-16': 32.20991142956339,
        '-15': 32.20983737811848,
        '-14': 32.2098914935862,
        '-13': 32.209994386134845,
        '-12': 32.21007324467925,
        '-11': 32.210104814155876,
        '-10': 32.21011583386595,
        '-9': 32.21014949780714,
        '-8': 32.21022585477206,
        '-7': 32.21032343031152,
        '-6': 32.21039217737991,
        '-5': 32.210386762331005,
        '-4': 32.210297254230014,
        '-3': 32.21015748501271,
        '-2': 32.2100261864849,
        '-1': 32.20995276257075
    },
    '-59': {
        '0': 32.21230072873853,
        '1': 32.21232982226044,
        '2': 32.21233818564172,
        '3': 32.21229302810641,
        '4': 32.21218923414008,
        '5': 32.21205680995622,
        '6': 32.211946838977646,
        '7': 32.2119039795153,
        '8': 32.21194202234673,
        '9': 32.21203721633561,
        '10': 32.212143120881535,
        '11': 32.21221755449505,
        '12': 32.21224506917935,
        '13': 32.21224169890623,
        '14': 32.21224023205061,
        '15': 32.21226648125908,
        '16': 32.21232216819425,
        '17': 32.21238501495495,
        '18': 32.21242500930232,
        '19': 32.21242536220625,
        '20': 32.212394166342634,
        '21': 32.2123594972221,
        '22': 32.21235183467264,
        '23': 32.212385688663005,
        '24': 32.212452101963606,
        '25': 32.212525916928314,
        '26': 32.21258197279547,
        '27': 32.212609280225486,
        '28': 32.21261466835969,
        '29': 32.21261508530698,
        '30': 32.21262505459119,
        '31': 32.2126479736628,
        '32': 32.212676188499124,
        '33': 32.21269836303537,
        '34': 32.212708197522915,
        '35': 32.212708664004126,
        '36': 32.21270976265142,
        '37': 32.21272217210584,
        '38': 32.212751152328266,
        '39': 32.21279395683341,
        '40': 32.2128413236463,
        '41': 32.212881454067265,
        '42': 32.21290432500645,
        '43': 32.2129048146486,
        '44': 32.21288394248325,
        '45': 32.21284801750567,
        '46': 32.21280591531908,
        '47': 32.212765447532675,
        '48': 32.21273058129517,
        '49': 32.21270124642911,
        '50': 32.21267606903678,
        '51': 32.212656208338714,
        '52': 32.21264718630869,
        '53': 32.21265657150935,
        '54': 32.212688362291544,
        '55': 32.21273785879774,
        '56': 32.21279128009319,
        '57': 32.21283155236293,
        '58': 32.21284729478792,
        '59': 32.212839265814665,
        '60': 32.21281968911066,
        '61': 32.21280444645424,
        '62': 32.21280299423002,
        '63': 32.21281254516905,
        '64': 32.21282029332986,
        '65': 32.21281217283192,
        '66': 32.2127825176829,
        '67': 32.21273876399135,
        '68': 32.21269886445281,
        '69': 32.21268365040367,
        '70': 32.212708857668794,
        '71': 32.21278067645436,
        '72': 32.212895748459495,
        '73': 32.21304395681398,
        '74': 32.21321172393042,
        '75': 32.21338454324436,
        '76': 32.213548615267264,
        '77': 32.213691649258045,
        '78': 32.21380246212329,
        '79': 32.21386917848473,
        '80': 32.21387721121912,
        '81': 32.213809777903776,
        '82': 32.21365352212494,
        '83': 32.21340881764577,
        '84': 32.21309989533918,
        '85': 32.21277735301817,
        '86': 32.212507763572084,
        '87': 32.21235185686543,
        '88': 32.21234036555436,
        '89': 32.212459913337206,
        '90': 32.212657330529645,
        '91': 32.212861233501755,
        '92': 32.213010261581196,
        '93': 32.21307395539647,
        '94': 32.21305702920678,
        '95': 32.21298758872231,
        '96': 32.21289838635696,
        '97': 32.212812343777095,
        '98': 32.21273868430797,
        '99': 32.21267812729698,
        '100': 32.21263027879837,
        '101': 32.21259677672058,
        '102': 32.21257871336778,
        '103': 32.21257209444975,
        '104': 32.21256663900328,
        '105': 32.21255026760805,
        '106': 32.212516794518315,
        '107': 32.21247144774737,
        '108': 32.21242998041276,
        '109': 32.21241130381623,
        '110': 32.21242769353974,
        '111': 32.21247795472506,
        '112': 32.21254694507844,
        '113': 32.21261124704988,
        '114': 32.212647941138776,
        '115': 32.212642689708645,
        '116': 32.21259437412232,
        '117': 32.21251513931747,
        '118': 32.212426027428556,
        '119': 32.212349416912176,
        '120': 32.212300555047435,
        '121': 32.212281394058266,
        '122': 32.212279827587075,
        '123': 32.21227549437982,
        '124': 32.212249986815024,
        '125': 32.21219642413521,
        '126': 32.21212309339997,
        '127': 32.212048963790465,
        '128': 32.2119937097422,
        '129': 32.21196827658354,
        '130': 32.2119715183969,
        '131': 32.21199421246992,
        '132': 32.212026736008404,
        '133': 32.21206451511613,
        '134': 32.212107626245455,
        '135': 32.21215588656123,
        '136': 32.21220451806371,
        '137': 32.21224495944444,
        '138': 32.21227089554329,
        '139': 32.21228463567266,
        '140': 32.21229778090897,
        '141': 32.21232411599334,
        '142': 32.2123691150147,
        '143': 32.21242426917518,
        '144': 32.21247204549219,
        '145': 32.21249965104617,
        '146': 32.21251222377325,
        '147': 32.21253458849136,
        '148': 32.21259740808999,
        '149': 32.2127145758934,
        '150': 32.212866453052314,
        '151': 32.21300194883634,
        '152': 32.21306145304346,
        '153': 32.213008907184474,
        '154': 32.21285399726028,
        '155': 32.212650009799646,
        '156': 32.212467088399144,
        '157': 32.212355706088374,
        '158': 32.21232146395167,
        '159': 32.21232564716338,
        '160': 32.21231044824892,
        '161': 32.21223319589453,
        '162': 32.21208934788403,
        '163': 32.21191188963874,
        '164': 32.211749654712094,
        '165': 32.21163936262232,
        '166': 32.21158844581205,
        '167': 32.21157736217166,
        '168': 32.211577161588984,
        '169': 32.21156910524853,
        '170': 32.2115534508829,
        '171': 32.211543100807376,
        '172': 32.21154843716372,
        '173': 32.21156526426105,
        '174': 32.21157498905065,
        '175': 32.21155726882378,
        '176': 32.211506461809044,
        '177': 32.211440210101074,
        '178': 32.211393277524024,
        '179': 32.211399229002815,
        '180': 32.21147059918974,
        '-180': 32.21147059918974,
        '-179': 32.21158969481119,
        '-178': 32.21171608052081,
        '-177': 32.211806850223,
        '-176': 32.21183814579598,
        '-175': 32.211815768222934,
        '-174': 32.21176943436704,
        '-173': 32.21173498384619,
        '-172': 32.211735530740285,
        '-171': 32.211772193200105,
        '-170': 32.21182827532206,
        '-169': 32.21188234696748,
        '-168': 32.21192106662456,
        '-167': 32.211944357603834,
        '-166': 32.21196172200511,
        '-165': 32.2119843097838,
        '-164': 32.2120189066955,
        '-163': 32.21206690329051,
        '-162': 32.21212669608641,
        '-161': 32.21219573818176,
        '-160': 32.212270079001826,
        '-159': 32.21234275643252,
        '-158': 32.2124043045903,
        '-157': 32.212446963761714,
        '-156': 32.21247030242441,
        '-155': 32.21248346241186,
        '-154': 32.2125007934972,
        '-153': 32.21253255460315,
        '-152': 32.21257685134583,
        '-151': 32.21261915839875,
        '-150': 32.21264089573033,
        '-149': 32.21263188913317,
        '-148': 32.212598148268285,
        '-147': 32.212559101541565,
        '-146': 32.21253561153471,
        '-145': 32.21253667742141,
        '-144': 32.212553881650095,
        '-143': 32.21256738580077,
        '-142': 32.2125593015229,
        '-141': 32.21252523623958,
        '-140': 32.21247629797953,
        '-139': 32.21243071316726,
        '-138': 32.21240150439157,
        '-137': 32.21238919352291,
        '-136': 32.212384522524275,
        '-135': 32.212378669437214,
        '-134': 32.21237284128667,
        '-133': 32.212379506384146,
        '-132': 32.212413656224825,
        '-131': 32.21248000823152,
        '-130': 32.21256556065506,
        '-129': 32.21264373001793,
        '-128': 32.21268845189685,
        '-127': 32.212689472793045,
        '-126': 32.21265864934692,
        '-125': 32.21262261070281,
        '-124': 32.212606070439136,
        '-123': 32.21261639646304,
        '-122': 32.21263938455133,
        '-121': 32.21264883582633,
        '-120': 32.212623403479974,
        '-119': 32.21255946472978,
        '-118': 32.21247174907619,
        '-117': 32.21238208351501,
        '-116': 32.212304886286006,
        '-115': 32.212240098669426,
        '-114': 32.21217855109185,
        '-113': 32.21211531656739,
        '-112': 32.212060246240114,
        '-111': 32.212036426206005,
        '-110': 32.21206596382149,
        '-109': 32.21215226739335,
        '-108': 32.21227169280753,
        '-107': 32.21238200279569,
        '-106': 32.21244389123715,
        '-105': 32.21244253954144,
        '-104': 32.21239542249948,
        '-103': 32.212341191523194,
        '-102': 32.212316727576784,
        '-101': 32.21233703219581,
        '-100': 32.21239034497858,
        '-99': 32.2124503717634,
        '-98': 32.212496067051454,
        '-97': 32.21252486937324,
        '-96': 32.21255057555203,
        '-95': 32.21258826229502,
        '-94': 32.21263793036201,
        '-93': 32.21267948578421,
        '-92': 32.212683742651045,
        '-91': 32.21263283049334,
        '-90': 32.2125363822597,
        '-89': 32.21243184469743,
        '-88': 32.21236693459048,
        '-87': 32.21237350497352,
        '-86': 32.21244789212703,
        '-85': 32.212549677929495,
        '-84': 32.21262062922525,
        '-83': 32.212614295908395,
        '-82': 32.21252072020724,
        '-81': 32.21237316492224,
        '-80': 32.212233236660666,
        '-79': 32.212162090692665,
        '-78': 32.21219252615443,
        '-77': 32.21231598244743,
        '-76': 32.212490241261435,
        '-75': 32.21266254017841,
        '-74': 32.21279494355082,
        '-73': 32.21287854081215,
        '-72': 32.21293035426321,
        '-71': 32.21297725586397,
        '-70': 32.21303833348695,
        '-69': 32.21311666738007,
        '-68': 32.213203940334196,
        '-67': 32.213291969044676,
        '-66': 32.21338064473249,
        '-65': 32.2134752134831,
        '-64': 32.21357489542483,
        '-63': 32.21366284957643,
        '-62': 32.21370810957803,
        '-61': 32.21368207619474,
        '-60': 32.21358061426226,
        '-59': 32.21343613230758,
        '-58': 32.21330797279817,
        '-57': 32.21325269948625,
        '-56': 32.213290263943016,
        '-55': 32.21338758575479,
        '-54': 32.21347277530909,
        '-53': 32.21347497617689,
        '-52': 32.21336764922259,
        '-51': 32.2131884810847,
        '-50': 32.21302125335483,
        '-49': 32.212947718366394,
        '-48': 32.21299720989534,
        '-47': 32.213125536724746,
        '-46': 32.21323883248889,
        '-45': 32.21325084365517,
        '-44': 32.21313990031849,
        '-43': 32.21296902720066,
        '-42': 32.21285277270771,
        '-41': 32.21288673963773,
        '-40': 32.213081224188265,
        '-39': 32.21334212867533,
        '-38': 32.213516990003335,
        '-37': 32.21348480971365,
        '-36': 32.21323849944772,
        '-35': 32.21290755661873,
        '-34': 32.21269944418731,
        '-33': 32.212785167170495,
        '-32': 32.213190702238585,
        '-31': 32.21375859419475,
        '-30': 32.21420899204683,
        '-29': 32.214274809526806,
        '-28': 32.21384234837945,
        '-27': 32.213022058066606,
        '-26': 32.21210905340751,
        '-25': 32.21145089165632,
        '-24': 32.21128912914494,
        '-23': 32.211654337182175,
        '-22': 32.21236468388328,
        '-21': 32.21312305333492,
        '-20': 32.2136579870957,
        '-19': 32.21383604268177,
        '-18': 32.21369462976566,
        '-17': 32.213390166187104,
        '-16': 32.213099791512505,
        '-15': 32.21293308900749,
        '-14': 32.21289649723285,
        '-13': 32.21291869615394,
        '-12': 32.21291157337368,
        '-11': 32.212826696769895,
        '-10': 32.2126768470645,
        '-9': 32.21251711700005,
        '-8': 32.21240407820389,
        '-7': 32.21236112021212,
        '-6': 32.21236988199053,
        '-5': 32.212388971123616,
        '-4': 32.21238430100983,
        '-3': 32.21235015625911,
        '-2': 32.2123083178439,
        '-1': 32.21228787514159
    },
    '-60': {
        '0': 32.21460342602711,
        '1': 32.21458624648551,
        '2': 32.21458746247883,
        '3': 32.21459420232383,
        '4': 32.21459857475527,
        '5': 32.214607067415926,
        '6': 32.21463790864436,
        '7': 32.21470838988566,
        '8': 32.21482121391413,
        '9': 32.214959579582704,
        '10': 32.21509470755233,
        '11': 32.215201043571035,
        '12': 32.21526918410428,
        '13': 32.21530811403188,
        '14': 32.215335551125335,
        '15': 32.21536320714664,
        '16': 32.21538722490895,
        '17': 32.21539060543821,
        '18': 32.21535635728873,
        '19': 32.21528272259602,
        '20': 32.2151900498634,
        '21': 32.21511391543082,
        '22': 32.21508773278776,
        '23': 32.21512481757923,
        '24': 32.215210461164965,
        '25': 32.215308721151025,
        '26': 32.21538008845848,
        '27': 32.21540018033018,
        '28': 32.215369569955755,
        '29': 32.215310427924486,
        '30': 32.21525316000013,
        '31': 32.21522121163965,
        '32': 32.21522212224017,
        '33': 32.21524840121,
        '34': 32.2152859457646,
        '35': 32.215323952122155,
        '36': 32.21536037352399,
        '37': 32.215400357375565,
        '38': 32.21544947786256,
        '39': 32.215506487835945,
        '40': 32.21556035257146,
        '41': 32.21559371846129,
        '42': 32.21559123016981,
        '43': 32.21554822655734,
        '44': 32.2154748384934,
        '45': 32.21539273161316,
        '46': 32.215325642690885,
        '47': 32.21528841925948,
        '48': 32.215280445042865,
        '49': 32.21528722376996,
        '50': 32.21528944842665,
        '51': 32.215274560465474,
        '52': 32.21524421932871,
        '53': 32.2152133821864,
        '54': 32.21520161248288,
        '55': 32.2152218362675,
        '56': 32.21527318185908,
        '57': 32.215341877322565,
        '58': 32.21540916094716,
        '59': 32.21546101259356,
        '60': 32.215493837784955,
        '61': 32.21551328486345,
        '62': 32.215527952834336,
        '63': 32.215542770566344,
        '64': 32.21555643461039,
        '65': 32.215564026232705,
        '66': 32.215562221149014,
        '67': 32.215552908317925,
        '68': 32.21554248273414,
        '69': 32.21553738302282,
        '70': 32.21553925347192,
        '71': 32.215543545755374,
        '72': 32.21554320861055,
        '73': 32.21553575564376,
        '74': 32.21552949546833,
        '75': 32.215544501184375,
        '76': 32.21560614722256,
        '77': 32.21573271081453,
        '78': 32.21592197324365,
        '79': 32.216143375703986,
        '80': 32.21634109810393,
        '81': 32.216449445686045,
        '82': 32.21641638574359,
        '83': 32.21622628615186,
        '84': 32.21591146062672,
        '85': 32.215545560841605,
        '86': 32.21521942619377,
        '87': 32.21500846299516,
        '88': 32.21494556090376,
        '89': 32.215011807517975,
        '90': 32.21514907705973,
        '91': 32.21528793456365,
        '92': 32.21537693724228,
        '93': 32.21539945948704,
        '94': 32.21537157051979,
        '95': 32.21532493366013,
        '96': 32.21528614315278,
        '97': 32.215264286565294,
        '98': 32.215252121524614,
        '99': 32.21523743322665,
        '100': 32.21521541609595,
        '101': 32.215193503175065,
        '102': 32.21518599399582,
        '103': 32.215202895880104,
        '104': 32.21524102951638,
        '105': 32.21528363044028,
        '106': 32.215308964026825,
        '107': 32.215302711783195,
        '108': 32.215266622207245,
        '109': 32.21521825852753,
        '110': 32.2151819013934,
        '111': 32.21517557807855,
        '112': 32.21520112077335,
        '113': 32.215242356016304,
        '114': 32.21527226853246,
        '115': 32.21526558591629,
        '116': 32.21521077337721,
        '117': 32.2151158582562,
        '118': 32.21500543501366,
        '119': 32.21491024711084,
        '120': 32.2148541210018,
        '121': 32.21484423474817,
        '122': 32.214869127164235,
        '123': 32.21490513800502,
        '124': 32.214927873863125,
        '125': 32.21492291884169,
        '126': 32.21489066767763,
        '127': 32.214843526592915,
        '128': 32.21479788997747,
        '129': 32.21476583280203,
        '130': 32.21475093014729,
        '131': 32.21474954264572,
        '132': 32.21475542706838,
        '133': 32.21476394791745,
        '134': 32.214773347864366,
        '135': 32.21478333061564,
        '136': 32.21479334007001,
        '137': 32.21480272540258,
        '138': 32.214812722765565,
        '139': 32.21482786709763,
        '140': 32.214854247845174,
        '141': 32.21489447113085,
        '142': 32.21494255446455,
        '143': 32.21498343013999,
        '144': 32.21499953996883,
        '145': 32.21498211503015,
        '146': 32.21494043350909,
        '147': 32.214902050998745,
        '148': 32.214901666994834,
        '149': 32.21496340490191,
        '150': 32.215086240089335,
        '151': 32.215241492085035,
        '152': 32.2153846969852,
        '153': 32.21547569188153,
        '154': 32.21549576019936,
        '155': 32.21545250898228,
        '156': 32.215370709254,
        '157': 32.215275800816244,
        '158': 32.215180711043274,
        '159': 32.21508362779039,
        '160': 32.21497657366679,
        '161': 32.21485735579253,
        '162': 32.214735564138344,
        '163': 32.21462791631097,
        '164': 32.214546127097236,
        '165': 32.21448617600198,
        '166': 32.21442742032399,
        '167': 32.2143436527358,
        '168': 32.21422010617594,
        '169': 32.21406592941069,
        '170': 32.21391381598564,
        '171': 32.21380600237505,
        '172': 32.21377406242331,
        '173': 32.213823714421366,
        '174': 32.213932930436826,
        '175': 32.21406387771731,
        '176': 32.21418141555949,
        '177': 32.21426767129789,
        '178': 32.214325301712734,
        '179': 32.21436921407965,
        '180': 32.214413225776,
        '-180': 32.214413225776,
        '-179': 32.21446037812449,
        '-178': 32.214502359600814,
        '-177': 32.21452716625739,
        '-176': 32.21452892764345,
        '-175': 32.21451297421174,
        '-174': 32.2144929566452,
        '-173': 32.21448240226871,
        '-172': 32.214486712260666,
        '-171': 32.21450097087959,
        '-170': 32.21451472098418,
        '-169': 32.21452010460963,
        '-168': 32.21451772312877,
        '-167': 32.21451646240179,
        '-166': 32.21452781467753,
        '-165': 32.21455882545953,
        '-164': 32.214608307797725,
        '-163': 32.214668326510434,
        '-162': 32.21472928802196,
        '-161': 32.21478491324475,
        '-160': 32.21483412916187,
        '-159': 32.21487953615387,
        '-158': 32.21492441403154,
        '-157': 32.214970593338826,
        '-156': 32.215018053446364,
        '-155': 32.21506537883639,
        '-154': 32.215109782180626,
        '-153': 32.21514651876799,
        '-152': 32.215168925187335,
        '-151': 32.21517045334265,
        '-150': 32.21514851351549,
        '-149': 32.21510788780654,
        '-148': 32.21506081915017,
        '-147': 32.215022579396006,
        '-146': 32.215004432124445,
        '-145': 32.21500814779844,
        '-144': 32.21502570126585,
        '-143': 32.215044545411516,
        '-142': 32.215055073089225,
        '-141': 32.21505531551756,
        '-140': 32.21504986804409,
        '-139': 32.215044222816566,
        '-138': 32.215039110952155,
        '-137': 32.215029515200115,
        '-136': 32.2150094904873,
        '-135': 32.21497928427034,
        '-134': 32.214948941088444,
        '-133': 32.214934557815376,
        '-132': 32.21494853240838,
        '-131': 32.21498997291967,
        '-130': 32.21504231474216,
        '-129': 32.21508108441345,
        '-128': 32.215088073767376,
        '-127': 32.21506337730234,
        '-126': 32.215027266545036,
        '-125': 32.21500977922446,
        '-124': 32.21503359589973,
        '-123': 32.215100279586146,
        '-122': 32.21518818866223,
        '-121': 32.215263307988145,
        '-120': 32.21529636782321,
        '-119': 32.21527602623383,
        '-118': 32.21521087326774,
        '-117': 32.21512050547767,
        '-116': 32.215022852897015,
        '-115': 32.21492667829176,
        '-114': 32.21483366714532,
        '-113': 32.2147470728101,
        '-112': 32.21467882998598,
        '-111': 32.2146480829855,
        '-110': 32.21467056805888,
        '-109': 32.21474565917496,
        '-108': 32.21485074931952,
        '-107': 32.214948617034146,
        '-106': 32.21500494992576,
        '-105': 32.21500596402812,
        '-104': 32.21496516306165,
        '-103': 32.21491465136466,
        '-102': 32.21488605415111,
        '-101': 32.214892702531664,
        '-100': 32.21492391242821,
        '-99': 32.21495441319429,
        '-98': 32.214962331621386,
        '-97': 32.214943884496286,
        '-96': 32.21491540662002,
        '-95': 32.214901788310854,
        '-94': 32.21491923392547,
        '-93': 32.21496378333723,
        '-92': 32.21501312538388,
        '-91': 32.215040559817105,
        '-90': 32.21503216191458,
        '-89': 32.21499617686668,
        '-88': 32.21495855430785,
        '-87': 32.21494714686393,
        '-86': 32.21497408233035,
        '-85': 32.21502698345833,
        '-84': 32.215074485366294,
        '-83': 32.21508312551395,
        '-82': 32.21503612714689,
        '-81': 32.214943601513795,
        '-80': 32.21483866067792,
        '-79': 32.214761847232936,
        '-78': 32.214742575696846,
        '-77': 32.21478746669996,
        '-76': 32.21488109813691,
        '-75': 32.21499753686022,
        '-74': 32.215115277384534,
        '-73': 32.21522709285923,
        '-72': 32.21534010872291,
        '-71': 32.215467505152155,
        '-70': 32.21561780167424,
        '-69': 32.21578808363077,
        '-68': 32.21596403869915,
        '-67': 32.21612504933284,
        '-66': 32.216250114084,
        '-65': 32.21632150637037,
        '-64': 32.216326450846424,
        '-63': 32.216259578792496,
        '-62': 32.2161280809429,
        '-61': 32.21595761611089,
        '-60': 32.21579326594758,
        '-59': 32.21568990189766,
        '-58': 32.215691671646994,
        '-57': 32.21580838172179,
        '-56': 32.216001973948636,
        '-55': 32.21619428829722,
        '-54': 32.216297291393055,
        '-53': 32.216253754586205,
        '-52': 32.2160678716041,
        '-51': 32.215807704932296,
        '-50': 32.215574886045424,
        '-49': 32.2154550158798,
        '-48': 32.21547446366402,
        '-47': 32.21558779735946,
        '-46': 32.21570439230872,
        '-45': 32.2157408631478,
        '-44': 32.2156702863674,
        '-43': 32.215539449393376,
        '-42': 32.2154425420363,
        '-41': 32.21546479555677,
        '-40': 32.21562823147623,
        '-39': 32.21587236165658,
        '-38': 32.21608375345953,
        '-37': 32.21615985646058,
        '-36': 32.216070841959734,
        '-35': 32.21588185180406,
        '-34': 32.21571913401173,
        '-33': 32.21569598433938,
        '-32': 32.21583968860544,
        '-31': 32.21606330034922,
        '-30': 32.21620306364043,
        '-29': 32.216105577007696,
        '-28': 32.21571904368592,
        '-27': 32.215137655103206,
        '-26': 32.214571325034036,
        '-25': 32.21425255235278,
        '-24': 32.21432652451009,
        '-23': 32.21478056343333,
        '-22': 32.21544888738317,
        '-21': 32.21608942914304,
        '-20': 32.21649267505241,
        '-19': 32.2165679050296,
        '-18': 32.21636669369343,
        '-17': 32.21603787760041,
        '-16': 32.21574307654898,
        '-15': 32.21557890798871,
        '-14': 32.21554368015054,
        '-13': 32.215559196920786,
        '-12': 32.21552853679725,
        '-11': 32.21539440794145,
        '-10': 32.215167138402734,
        '-9': 32.214911739312335,
        '-8': 32.21470688338498,
        '-7': 32.21460201997565,
        '-6': 32.21459615700103,
        '-5': 32.21464659497014,
        '-4': 32.214698170182636,
        '-3': 32.21471360646553,
        '-2': 32.21468800150604,
        '-1': 32.21464226534702
    },
    '-61': {
        '0': 32.21709325753797,
        '1': 32.217037310444546,
        '2': 32.21704605797314,
        '3': 32.21711163904664,
        '4': 32.217215291858366,
        '5': 32.21733978713023,
        '6': 32.217475895072525,
        '7': 32.21762059540479,
        '8': 32.21777066060473,
        '9': 32.21791780568603,
        '10': 32.21804952018479,
        '11': 32.21815487868157,
        '12': 32.218230651744435,
        '13': 32.218282689093,
        '14': 32.218320968879986,
        '15': 32.218351422539,
        '16': 32.21837030363336,
        '17': 32.21836553959623,
        '18': 32.21832497141648,
        '19': 32.218246619652724,
        '20': 32.218144299950325,
        '21': 32.21804434760985,
        '22': 32.217974466957145,
        '23': 32.217950558051996,
        '24': 32.21796880579412,
        '25': 32.21800747366272,
        '26': 32.21803746006764,
        '27': 32.21803595247426,
        '28': 32.217996137347235,
        '29': 32.21792848859156,
        '30': 32.21785395124067,
        '31': 32.21779350399828,
        '32': 32.217759865622334,
        '33': 32.217755090905726,
        '34': 32.21777397736342,
        '35': 32.21780994360328,
        '36': 32.217859154609485,
        '37': 32.21792042959363,
        '38': 32.21799158989501,
        '39': 32.218065401571295,
        '40': 32.21812863195962,
        '41': 32.21816578107843,
        '42': 32.21816596425174,
        '43': 32.218129093427976,
        '44': 32.21806741541872,
        '45': 32.21800082530059,
        '46': 32.217947933211626,
        '47': 32.217917561194284,
        '48': 32.21790547857891,
        '49': 32.21789845563652,
        '50': 32.217883541410075,
        '51': 32.217857260195146,
        '52': 32.21782917778862,
        '53': 32.217817353614436,
        '54': 32.21783791526312,
        '55': 32.21789461273689,
        '56': 32.21797450913349,
        '57': 32.21805264674774,
        '58': 32.218103473409386,
        '59': 32.218112980233975,
        '60': 32.21808512827236,
        '61': 32.21803938934922,
        '62': 32.218001111922625,
        '63': 32.21799007112855,
        '64': 32.218012971155126,
        '65': 32.21806286850591,
        '66': 32.21812439905288,
        '67': 32.21818084120227,
        '68': 32.21821897475971,
        '69': 32.21823009960749,
        '70': 32.218208646967184,
        '71': 32.21815145687113,
        '72': 32.21805995612399,
        '73': 32.21794474518346,
        '74': 32.21782931836399,
        '75': 32.21774873950594,
        '76': 32.21774096669581,
        '77': 32.217832447023916,
        '78': 32.21802356934795,
        '79': 32.21828126779525,
        '80': 32.21854414598276,
        '81': 32.218740351801145,
        '82': 32.218812268826234,
        '83': 32.218737959573616,
        '84': 32.21853961719601,
        '85': 32.218274461786876,
        '86': 32.21801145335243,
        '87': 32.217804076266106,
        '88': 32.21767162369674,
        '89': 32.21759740629248,
        '90': 32.21754389274298,
        '91': 32.21747625850955,
        '92': 32.217381757183894,
        '93': 32.21727501483358,
        '94': 32.21718734830442,
        '95': 32.21714707675537,
        '96': 32.217162555834214,
        '97': 32.21721767482897,
        '98': 32.217282060387745,
        '99': 32.217329625533715,
        '100': 32.217354356837475,
        '101': 32.21737398145251,
        '102': 32.21741923513746,
        '103': 32.21751469156253,
        '104': 32.21766183552019,
        '105': 32.21783376080793,
        '106': 32.21798450760768,
        '107': 32.21806831152691,
        '108': 32.21805911569476,
        '109': 32.21796098874093,
        '110': 32.217805059643915,
        '111': 32.217635330312454,
        '112': 32.21749074400529,
        '113': 32.21739188614684,
        '114': 32.2173376111843,
        '115': 32.21731165165485,
        '116': 32.2172946165533,
        '117': 32.21727487209379,
        '118': 32.21725322002046,
        '119': 32.21723999208699,
        '120': 32.21724712530044,
        '121': 32.21728001048697,
        '122': 32.2173334746191,
        '123': 32.21739363433368,
        '124': 32.21744410107803,
        '125': 32.217472901869534,
        '126': 32.21747653350076,
        '127': 32.21745954797619,
        '128': 32.217430662953575,
        '129': 32.21739805376396,
        '130': 32.21736636923072,
        '131': 32.21733645417502,
        '132': 32.21730696272075,
        '133': 32.21727621947517,
        '134': 32.21724321398448,
        '135': 32.217207840504656,
        '136': 32.21717128014206,
        '137': 32.21713706051785,
        '138': 32.21711213405378,
        '139': 32.21710641085761,
        '140': 32.217129604536396,
        '141': 32.2171860468262,
        '142': 32.217270149768055,
        '143': 32.217365826690894,
        '144': 32.21745154901834,
        '145': 32.21750948099773,
        '146': 32.21753425402484,
        '147': 32.21753642485241,
        '148': 32.21753815936667,
        '149': 32.21756290509719,
        '150': 32.21762424390666,
        '151': 32.21771960889546,
        '152': 32.21783176599681,
        '153': 32.21793662801484,
        '154': 32.21801276674767,
        '155': 32.21804778936816,
        '156': 32.21803940327052,
        '157': 32.21799251560614,
        '158': 32.217915679029495,
        '159': 32.21781943650845,
        '160': 32.21771649195199,
        '161': 32.217621347945446,
        '162': 32.21754703055313,
        '163': 32.217498961973625,
        '164': 32.217469202034046,
        '165': 32.217435664546166,
        '166': 32.21736906695701,
        '167': 32.217246019064476,
        '168': 32.21706239267972,
        '169': 32.21683977690236,
        '170': 32.2166206223115,
        '171': 32.21645330635605,
        '172': 32.21637369186954,
        '173': 32.21639173056718,
        '174': 32.21648912422849,
        '175': 32.21662844737304,
        '176': 32.21676862173406,
        '177': 32.21687918633166,
        '178': 32.21694747957565,
        '179': 32.216977281783784,
        '180': 32.21698186891862,
        '-180': 32.21698186891862,
        '-179': 32.216976358574094,
        '-178': 32.21697302072937,
        '-177': 32.216980208090874,
        '-176': 32.21700300715355,
        '-175': 32.217043263713556,
        '-174': 32.21709826618198,
        '-173': 32.21715953271332,
        '-172': 32.21721398147559,
        '-171': 32.217248465510025,
        '-170': 32.21725607966053,
        '-169': 32.21724070926338,
        '-168': 32.21721658438026,
        '-167': 32.21720222673322,
        '-166': 32.21721157594998,
        '-165': 32.21724705496635,
        '-164': 32.21729850804971,
        '-163': 32.21734871735353,
        '-162': 32.21738250837181,
        '-161': 32.217394508938796,
        '-160': 32.21739152479105,
        '-159': 32.21738857758272,
        '-158': 32.21740102254064,
        '-157': 32.21743693786897,
        '-156': 32.217493395580114,
        '-155': 32.21755793254992,
        '-154': 32.21761399173281,
        '-153': 32.21764757537585,
        '-152': 32.21765229835662,
        '-151': 32.217631085527806,
        '-150': 32.217594219183184,
        '-149': 32.21755475058933,
        '-148': 32.217523164900385,
        '-147': 32.21750348035613,
        '-146': 32.21749250558168,
        '-145': 32.21748271037899,
        '-144': 32.2174674097681,
        '-143': 32.21744553473452,
        '-142': 32.21742312975897,
        '-141': 32.21741030573244,
        '-140': 32.217415056478046,
        '-139': 32.217437568617925,
        '-138': 32.21746884026287,
        '-137': 32.217495095368136,
        '-136': 32.217505813803996,
        '-135': 32.21750048230671,
        '-134': 32.21748938597049,
        '-133': 32.217487152283844,
        '-132': 32.21750245057356,
        '-131': 32.217530254849805,
        '-130': 32.217552204163425,
        '-129': 32.217545996423844,
        '-128': 32.21749905467022,
        '-127': 32.21741848440995,
        '-126': 32.217330852823515,
        '-125': 32.21727094691454,
        '-124': 32.21726514389337,
        '-123': 32.2173183983776,
        '-122': 32.217411959168,
        '-121': 32.2175128363755,
        '-120': 32.217589428062475,
        '-119': 32.21762458469795,
        '-118': 32.217619531536485,
        '-117': 32.217587953194,
        '-116': 32.217545348860064,
        '-115': 32.217500874379894,
        '-114': 32.21745621553489,
        '-113': 32.217410664860935,
        '-112': 32.21736727319196,
        '-111': 32.2173346050078,
        '-110': 32.217322313896595,
        '-109': 32.21733373146625,
        '-108': 32.217361311324574,
        '-107': 32.2173891172203,
        '-106': 32.217401653227725,
        '-105': 32.217393592750696,
        '-104': 32.21737376098997,
        '-103': 32.21736009669303,
        '-102': 32.21736817487235,
        '-101': 32.21740035251823,
        '-100': 32.21744264141694,
        '-99': 32.21747181685531,
        '-98': 32.21746888190579,
        '-97': 32.217430880851595,
        '-96': 32.217373838634884,
        '-95': 32.21732484065255,
        '-94': 32.21730772007905,
        '-93': 32.217330541768085,
        '-92': 32.21738187472292,
        '-91': 32.21743759334801,
        '-90': 32.21747391467161,
        '-89': 32.217479150543504,
        '-88': 32.21745794852439,
        '-87': 32.21742654409321,
        '-86': 32.21740271752155,
        '-85': 32.217396691528386,
        '-84': 32.217407871196826,
        '-83': 32.21742827449369,
        '-82': 32.2174494166484,
        '-81': 32.217467772344236,
        '-80': 32.21748552712104,
        '-79': 32.21750687052267,
        '-78': 32.21753316937799,
        '-77': 32.21756100751733,
        '-76': 32.217584953185344,
        '-75': 32.21760347702985,
        '-74': 32.21762396749406,
        '-73': 32.21766290721554,
        '-72': 32.21773995141573,
        '-71': 32.217868246470395,
        '-70': 32.2180456612894,
        '-69': 32.21825133006872,
        '-68': 32.21844931369031,
        '-67': 32.218597924741665,
        '-66': 32.2186611828295,
        '-65': 32.21861882599894,
        '-64': 32.21847271356009,
        '-63': 32.218248905345966,
        '-62': 32.217995154943864,
        '-61': 32.21777320614964,
        '-60': 32.21764539702249,
        '-59': 32.21765684960616,
        '-58': 32.21781779560658,
        '-57': 32.218093451861535,
        '-56': 32.21840866412728,
        '-55': 32.21866979923299,
        '-54': 32.218798312218574,
        '-53': 32.218762945721444,
        '-52': 32.21859528064875,
        '-51': 32.21837890970457,
        '-50': 32.21821428791548,
        '-49': 32.2181738349544,
        '-48': 32.21826832193602,
        '-47': 32.218441662772115,
        '-46': 32.21859796700532,
        '-45': 32.21864846275889,
        '-44': 32.21855524223703,
        '-43': 32.218349805771425,
        '-42': 32.21811707974524,
        '-41': 32.21795351487366,
        '-40': 32.217921421566096,
        '-39': 32.218023325866554,
        '-38': 32.21820872141895,
        '-37': 32.21840738682976,
        '-36': 32.21856871562769,
        '-35': 32.218683768044315,
        '-34': 32.218777709781975,
        '-33': 32.218878677174224,
        '-32': 32.218984081505965,
        '-31': 32.21904780093854,
        '-30': 32.218999637030535,
        '-29': 32.21878850302957,
        '-28': 32.21842474531321,
        '-27': 32.21799470474469,
        '-26': 32.21763431431694,
        '-25': 32.21747110848887,
        '-24': 32.2175628737614,
        '-23': 32.21786527171293,
        '-22': 32.218247106745466,
        '-21': 32.218547495473906,
        '-20': 32.21864725399784,
        '-19': 32.21851934712825,
        '-18': 32.218234247467535,
        '-17': 32.21791943027988,
        '-16': 32.217695326934766,
        '-15': 32.217620710776934,
        '-14': 32.217673594530375,
        '-13': 32.217773777604236,
        '-12': 32.21783147158298,
        '-11': 32.21779452793771,
        '-10': 32.21767023515473,
        '-9': 32.217513348911346,
        '-8': 32.217390569658996,
        '-7': 32.21734296527554,
        '-6': 32.21736653148644,
        '-5': 32.21741914535716,
        '-4': 32.21744713097171,
        '-3': 32.2174149934231,
        '-2': 32.21732223296818,
        '-1': 32.21720015561013
    },
    '-62': {
        '0': 32.219617967621055,
        '1': 32.219617325660984,
        '2': 32.21968592157015,
        '3': 32.219800291844365,
        '4': 32.21993026749955,
        '5': 32.220053600548,
        '6': 32.2201633653932,
        '7': 32.22026540376621,
        '8': 32.22036899363144,
        '9': 32.22047731226524,
        '10': 32.22058346287269,
        '11': 32.220673766457885,
        '12': 32.22073542727841,
        '13': 32.22076334058069,
        '14': 32.220761892780025,
        '15': 32.22074111320134,
        '16': 32.22071010911088,
        '17': 32.220672162565435,
        '18': 32.22062448104954,
        '19': 32.220562450963186,
        '20': 32.22048540267811,
        '21': 32.22040006909501,
        '22': 32.22031946445339,
        '23': 32.22025773267987,
        '24': 32.220223852771156,
        '25': 32.22021762181893,
        '26': 32.22022992049524,
        '27': 32.22024689372458,
        '28': 32.22025577101524,
        '29': 32.22024957752708,
        '30': 32.220228971213594,
        '31': 32.220201087676095,
        '32': 32.220176565033064,
        '33': 32.22016625428662,
        '34': 32.220178595255156,
        '35': 32.22021785923731,
        '36': 32.220283050188876,
        '37': 32.22036742314121,
        '38': 32.22045901109466,
        '39': 32.22054269521235,
        '40': 32.22060385598512,
        '41': 32.22063266588237,
        '42': 32.22062725503557,
        '43': 32.220594004175936,
        '44': 32.22054436784846,
        '45': 32.22048946219943,
        '46': 32.22043515231128,
        '47': 32.22038051785877,
        '48': 32.220320990627556,
        '49': 32.220254790249484,
        '50': 32.22018897900128,
        '51': 32.22014098792867,
        '52': 32.22013343327171,
        '53': 32.22018366227128,
        '54': 32.220292851593214,
        '55': 32.220440618396566,
        '56': 32.220589042919805,
        '57': 32.22069553256438,
        '58': 32.22072932165107,
        '59': 32.22068411702487,
        '60': 32.22058084645645,
        '61': 32.22045904732964,
        '62': 32.22036083374345,
        '63': 32.220314808153745,
        '64': 32.220326993613725,
        '65': 32.22038210569471,
        '66': 32.220453338497045,
        '67': 32.220515031987446,
        '68': 32.220551983062684,
        '69': 32.220561795230864,
        '70': 32.22055082587575,
        '71': 32.220527583382164,
        '72': 32.22049811711441,
        '73': 32.22046588724773,
        '74': 32.220435228854726,
        '75': 32.220414949966944,
        '76': 32.220418390157256,
        '77': 32.22045854165862,
        '78': 32.22054023228476,
        '79': 32.220653814179194,
        '80': 32.2207747059739,
        '81': 32.22087033902093,
        '82': 32.220911994133225,
        '83': 32.22088596863202,
        '84': 32.220798349377645,
        '85': 32.22067071988925,
        '86': 32.22052889648694,
        '87': 32.220390664152674,
        '88': 32.220259187566235,
        '89': 32.22012570452632,
        '90': 32.219979820342665,
        '91': 32.21982116435586,
        '92': 32.21966505495338,
        '93': 32.219538007048335,
        '94': 32.21946479173463,
        '95': 32.21945403231264,
        '96': 32.2194908605547,
        '97': 32.21954182433251,
        '98': 32.21957065469579,
        '99': 32.21955723634893,
        '100': 32.219509725680616,
        '101': 32.219462737171376,
        '102': 32.21946145979825,
        '103': 32.219538869686744,
        '104': 32.21969702933693,
        '105': 32.21990178413507,
        '106': 32.22009383715924,
        '107': 32.22021142784776,
        '108': 32.220214509718645,
        '109': 32.22009990331326,
        '110': 32.21990134631262,
        '111': 32.21967529195529,
        '112': 32.219479343352674,
        '113': 32.219352685694275,
        '114': 32.219306022628864,
        '115': 32.219323636489904,
        '116': 32.219374809325245,
        '117': 32.21942841172717,
        '118': 32.21946422852767,
        '119': 32.21947716127695,
        '120': 32.219474224940456,
        '121': 32.21946734080736,
        '122': 32.21946605526421,
        '123': 32.21947335469327,
        '124': 32.21948558152182,
        '125': 32.21949537312316,
        '126': 32.21949551824428,
        '127': 32.21948187326235,
        '128': 32.21945450451103,
        '129': 32.21941724331096,
        '130': 32.21937631770563,
        '131': 32.21933863645327,
        '132': 32.21931002475476,
        '133': 32.21929364418054,
        '134': 32.21928903914191,
        '135': 32.21929242965924,
        '136': 32.219298614093276,
        '137': 32.219304059583386,
        '138': 32.21930983555125,
        '139': 32.21932266393173,
        '140': 32.219352994388416,
        '141': 32.219410515395744,
        '142': 32.219499085972714,
        '143': 32.219613721024494,
        '144': 32.21974144444865,
        '145': 32.219865873373976,
        '146': 32.21997339267692,
        '147': 32.22005790304831,
        '148': 32.22012191628599,
        '149': 32.22017376411025,
        '150': 32.22022269702323,
        '151': 32.2202745191942,
        '152': 32.220329724833974,
        '153': 32.22038443397242,
        '154': 32.2204328902357,
        '155': 32.22046979466645,
        '156': 32.220491434541806,
        '157': 32.22049574772916,
        '158': 32.22048220053088,
        '159': 32.22045214117664,
        '160': 32.22040939808545,
        '161': 32.220360147022205,
        '162': 32.220311203138344,
        '163': 32.220266953380545,
        '164': 32.22022641770887,
        '165': 32.22018242113361,
        '166': 32.22012402669064,
        '167': 32.22004157229579,
        '168': 32.21993195610673,
        '169': 32.219801354202694,
        '170': 32.21966374239959,
        '171': 32.21953580922583,
        '172': 32.219430792865,
        '173': 32.21935428996058,
        '174': 32.21930387949649,
        '175': 32.21927224416057,
        '176': 32.219251676243935,
        '177': 32.219237459364294,
        '178': 32.21922872552116,
        '179': 32.219227150204205,
        '180': 32.21923506287109,
        '-180': 32.21923506287109,
        '-179': 32.219254471786236,
        '-178': 32.21928732878163,
        '-177': 32.219336060978826,
        '-176': 32.21940301151418,
        '-175': 32.21948831291532,
        '-174': 32.21958725201395,
        '-173': 32.21968919715971,
        '-172': 32.219779733987274,
        '-171': 32.21984583939052,
        '-170': 32.21988178266333,
        '-169': 32.21989242424181,
        '-168': 32.21989155185011,
        '-167': 32.21989549541154,
        '-166': 32.21991504372529,
        '-165': 32.21994994950676,
        '-164': 32.21998916895127,
        '-163': 32.220016958942786,
        '-162': 32.22002171862889,
        '-161': 32.22000285927346,
        '-160': 32.21997195393229,
        '-159': 32.21994743904993,
        '-158': 32.219945554476354,
        '-157': 32.21997217319788,
        '-156': 32.22001967982563,
        '-155': 32.220070439992,
        '-154': 32.220105079826034,
        '-153': 32.220111463345965,
        '-152': 32.22009001672405,
        '-151': 32.22005291770528,
        '-150': 32.220017663831975,
        '-149': 32.21999820987109,
        '-148': 32.2199979935389,
        '-147': 32.22000825509272,
        '-146': 32.22001252558862,
        '-145': 32.219995165979405,
        '-144': 32.21994978633125,
        '-143': 32.219883294764635,
        '-142': 32.219813372483344,
        '-141': 32.219760451450554,
        '-140': 32.219738175155676,
        '-139': 32.21974728146315,
        '-138': 32.219776189646346,
        '-137': 32.21980801680621,
        '-136': 32.2198301505678,
        '-135': 32.219840917803346,
        '-134': 32.21984937433038,
        '-133': 32.21986814122503,
        '-132': 32.21990338934559,
        '-131': 32.21994805936557,
        '-130': 32.219982870374814,
        '-129': 32.219985310555366,
        '-128': 32.21994205286257,
        '-127': 32.2198579008455,
        '-126': 32.21975594255394,
        '-125': 32.219668305625234,
        '-124': 32.219622108981625,
        '-123': 32.21962789029309,
        '-122': 32.219676386527475,
        '-121': 32.21974488298529,
        '-120': 32.21980915639606,
        '-119': 32.219854313136395,
        '-118': 32.21987902333657,
        '-117': 32.21989186528624,
        '-116': 32.21990307078368,
        '-115': 32.219917181465355,
        '-114': 32.21993085730327,
        '-113': 32.21993638859962,
        '-112': 32.21992783425403,
        '-111': 32.21990540380522,
        '-110': 32.21987534453219,
        '-109': 32.21984587758121,
        '-108': 32.21982236367849,
        '-107': 32.219805154774654,
        '-106': 32.21979145725774,
        '-105': 32.21977956978959,
        '-104': 32.21977213935748,
        '-103': 32.21977576707532,
        '-102': 32.21979688828246,
        '-101': 32.21983655960738,
        '-100': 32.219887757376725,
        '-99': 32.21993734999381,
        '-98': 32.21997197896562,
        '-97': 32.21998456525622,
        '-96': 32.21997766264707,
        '-95': 32.2199617061567,
        '-94': 32.219949183628096,
        '-93': 32.21994804776134,
        '-92': 32.21995794446417,
        '-91': 32.21997103035284,
        '-90': 32.21997650319658,
        '-89': 32.21996609966127,
        '-88': 32.219937727153805,
        '-87': 32.21989590674282,
        '-86': 32.21984967103541,
        '-85': 32.2198097038052,
        '-84': 32.21978624470327,
        '-83': 32.21978804500793,
        '-82': 32.21982151838003,
        '-81': 32.21988910447192,
        '-80': 32.2199868713437,
        '-79': 32.22010275436701,
        '-78': 32.22021743602117,
        '-77': 32.22030901944158,
        '-76': 32.22036060987154,
        '-75': 32.22036783447434,
        '-74': 32.22034254111166,
        '-73': 32.22031011639295,
        '-72': 32.22030062715964,
        '-71': 32.22033696076652,
        '-70': 32.220424780952186,
        '-69': 32.220548572166265,
        '-68': 32.22067559895551,
        '-67': 32.22076647213375,
        '-66': 32.220788616522285,
        '-65': 32.22072815179617,
        '-64': 32.22059650871856,
        '-63': 32.22042982663648,
        '-62': 32.22028104731232,
        '-61': 32.2202062491924,
        '-60': 32.22024817862913,
        '-59': 32.22042117947139,
        '-58': 32.21520052544053,
        '-57': 32.22103397058404,
        '-56': 32.221336908527356,
        '-55': 32.221535822007255,
        '-54': 32.221585278543,
        '-53': 32.22148861213177,
        '-52': 32.221299845517954,
        '-51': 32.22110560615411,
        '-50': 32.220992314768885,
        '-49': 32.221011257183434,
        '-48': 32.2211564951754,
        '-47': 32.22136606739752,
        '-46': 32.22154704337623,
        '-45': 32.22161414470968,
        '-44': 32.221525141646964,
        '-43': 32.221297502218874,
        '-42': 32.220999445351175,
        '-41': 32.220720469111264,
        '-40': 32.22053567887367,
        '-39': 32.22048021989208,
        '-38': 32.22054398767963,
        '-37': 32.220685981573034,
        '-36': 32.220858048403684,
        '-35': 32.22102440564538,
        '-34': 32.22116770059319,
        '-33': 32.2212815342809,
        '-32': 32.22135777761618,
        '-31': 32.22137964944575,
        '-30': 32.22132694458048,
        '-29': 32.221190923509496,
        '-28': 32.22098881026132,
        '-27': 32.22076651562711,
        '-26': 32.22058433315969,
        '-25': 32.220490598913905,
        '-24': 32.22049660045028,
        '-23': 32.220567132253414,
        '-22': 32.220633800554644,
        '-21': 32.22062618037382,
        '-20': 32.22050585997471,
        '-19': 32.22028606991897,
        '-18': 32.22002657013249,
        '-17': 32.21980614686356,
        '-16': 32.21968661672999,
        '-15': 32.219686392542,
        '-14': 32.219776248451495,
        '-13': 32.219898011616394,
        '-12': 32.21999508123741,
        '-11': 32.2200382855751,
        '-10': 32.22003424746516,
        '-9': 32.2200137058453,
        '-8': 32.22000820581779,
        '-7': 32.22002921897899,
        '-6': 32.2200613212271,
        '-5': 32.220072374298695,
        '-4': 32.22003391523923,
        '-3': 32.219939619100664,
        '-2': 32.21981144384952,
        '-1': 32.21969046347053
    },
    '-63': {
        '0': 32.2221319871133,
        '1': 32.22228449782415,
        '2': 32.222455615114484,
        '3': 32.22259707266181,
        '4': 32.22267467679597,
        '5': 32.22268281922806,
        '6': 32.222645708210216,
        '7': 32.22260493395199,
        '8': 32.22259956900483,
        '9': 32.222648361210766,
        '10': 32.22274214950299,
        '11': 32.22284934847089,
        '12': 32.22293102660527,
        '13': 32.222957912560965,
        '14': 32.22292148228567,
        '15': 32.22283484356473,
        '16': 32.22272435201228,
        '17': 32.22261706371867,
        '18': 32.22253035510942,
        '19': 32.222468081543894,
        '20': 32.222423856624694,
        '21': 32.222388499171984,
        '22': 32.22235710636545,
        '23': 32.222332084308555,
        '24': 32.22232104490417,
        '25': 32.222331278384324,
        '26': 32.22236414376454,
        '27': 32.2224125345247,
        '28': 32.222462860675265,
        '29': 32.22250069670061,
        '30': 32.22251747665642,
        '31': 32.222515116655224,
        '32': 32.22250633826813,
        '33': 32.22251029813398,
        '34': 32.22254511046218,
        '35': 32.22262018634321,
        '36': 32.22273149034626,
        '37': 32.22286175486566,
        '38': 32.22298577865922,
        '39': 32.22307888530358,
        '40': 32.22312524310097,
        '41': 32.223122661965,
        '42': 32.223081829517255,
        '43': 32.22302028110118,
        '44': 32.22295378176616,
        '45': 32.22288915132809,
        '46': 32.222822136877774,
        '47': 32.222741725388644,
        '48': 32.222639155115694,
        '49': 32.222517266959706,
        '50': 32.22239511243735,
        '51': 32.22230450679389,
        '52': 32.222278895528795,
        '53': 32.222338852335604,
        '54': 32.22248077300359,
        '55': 32.222674550007795,
        '56': 32.222872295847644,
        '57': 32.22302512961176,
        '58': 32.22310106482817,
        '59': 32.22309617684315,
        '60': 32.223034072271744,
        '61': 32.22295398656853,
        '62': 32.22289307606847,
        '63': 32.22287108708516,
        '64': 32.222884277629326,
        '65': 32.222910881913755,
        '66': 32.22292482298897,
        '67': 32.22291055079215,
        '68': 32.222871645443774,
        '69': 32.22282918322589,
        '70': 32.22281103742103,
        '71': 32.22283766676914,
        '72': 32.22291142115221,
        '73': 32.223014389475125,
        '74': 32.223115497952215,
        '75': 32.22318317944839,
        '76': 32.223197607833114,
        '77': 32.22315718982071,
        '78': 32.22307712886724,
        '79': 32.22298167729172,
        '80': 32.22289426134682,
        '81': 32.22282990461399,
        '82': 32.222792496076245,
        '83': 32.22277672260992,
        '84': 32.22277245610031,
        '85': 32.22276899348717,
        '86': 32.22275766488772,
        '87': 32.22273294315806,
        '88': 32.22269312573661,
        '89': 32.222641332770074,
        '90': 32.22258635330783,
        '91': 32.222541842826196,
        '92': 32.22252254727671,
        '93': 32.22253782168203,
        '94': 32.22258489247189,
        '95': 32.22264563089577,
        '96': 32.22268988871813,
        '97': 32.22268563015084,
        '98': 32.22261242895541,
        '99': 32.22247237475199,
        '100': 32.22229267275013,
        '101': 32.222117498469295,
        '102': 32.221991610119375,
        '103': 32.22194242307248,
        '104': 32.22196850734539,
        '105': 32.22203995773003,
        '106': 32.22211086246887,
        '107': 32.22213860796236,
        '108': 32.22210162455565,
        '109': 32.22200788706359,
        '110': 32.221890666874764,
        '111': 32.22179369351322,
        '112': 32.22175240220066,
        '113': 32.22177933594909,
        '114': 32.2218595790451,
        '115': 32.221957513468624,
        '116': 32.222031388284975,
        '117': 32.222049269938644,
        '118': 32.22199997395147,
        '119': 32.221895243288856,
        '120': 32.221763314876284,
        '121': 32.2216373426783,
        '122': 32.22154363506897,
        '123': 32.22149399073517,
        '124': 32.221484209919616,
        '125': 32.22149826684482,
        '126': 32.2215157352062,
        '127': 32.221519424675776,
        '128': 32.22150073932089,
        '129': 32.22146149887164,
        '130': 32.22141226946212,
        '131': 32.22136821770087,
        '132': 32.22134399050697,
        '133': 32.22134917869427,
        '134': 32.221385634473506,
        '135': 32.221447345075646,
        '136': 32.22152279218039,
        '137': 32.22159891921091,
        '138': 32.22166526359792,
        '139': 32.22171676582271,
        '140': 32.22175433690437,
        '141': 32.221783243984106,
        '142': 32.22181030375483,
        '143': 32.221841282160526,
        '144': 32.22187957642558,
        '145': 32.22192640362018,
        '146': 32.22198185029145,
        '147': 32.22204576660382,
        '148': 32.22211781389848,
        '149': 32.22219674650954,
        '150': 32.22227968684174,
        '151': 32.22236225868337,
        '152': 32.222439880567215,
        '153': 32.222509648622854,
        '154': 32.22257164848799,
        '155': 32.22262866770041,
        '156': 32.222684125069115,
        '157': 32.22273912197363,
        '158': 32.22279020045482,
        '159': 32.22282922643956,
        '160': 32.22284584498939,
        '161': 32.22283167412517,
        '162': 32.22278447609676,
        '163': 32.22271042978854,
        '164': 32.22262335745363,
        '165': 32.22254096607666,
        '166': 32.22247931335654,
        '167': 32.22244736883834,
        '168': 32.22244353921485,
        '169': 32.22245543824162,
        '170': 32.22246321749233,
        '171': 32.222445681737796,
        '172': 32.22238744789323,
        '173': 32.22228484737384,
        '174': 32.222148389017576,
        '175': 32.22200053411434,
        '176': 32.22186913260495,
        '177': 32.22177860845979,
        '178': 32.22174210987391,
        '179': 32.22175771586652,
        '180': 32.221810275428346,
        '-180': 32.221810275428346,
        '-179': 32.221878099480925,
        '-178': 32.221941593243535,
        '-177': 32.22199007409654,
        '-176': 32.22202395297415,
        '-175': 32.22205174961979,
        '-174': 32.22208391058629,
        '-173': 32.22212680952344,
        '-172': 32.22217990831616,
        '-171': 32.22223710987377,
        '-170': 32.22229095757833,
        '-169': 32.22233687442817,
        '-168': 32.222374881549264,
        '-167': 32.22240798118029,
        '-166': 32.22243854917615,
        '-165': 32.222465343281065,
        '-164': 32.22248333424321,
        '-163': 32.22248674983873,
        '-162': 32.222473589525116,
        '-161': 32.22244872720443,
        '-160': 32.22242330744627,
        '-159': 32.222410180523894,
        '-158': 32.222417475084164,
        '-157': 32.222443719278594,
        '-156': 32.222477389370816,
        '-155': 32.222501596366136,
        '-154': 32.222501929510535,
        '-153': 32.22247368437821,
        '-152': 32.222424790408866,
        '-151': 32.22237273602497,
        '-150': 32.22233671371051,
        '-149': 32.222328623509085,
        '-148': 32.22234725953493,
        '-147': 32.2223785742163,
        '-146': 32.222402022145275,
        '-145': 32.2224000216908,
        '-144': 32.22236600260593,
        '-143': 32.22230714803741,
        '-142': 32.22224052946022,
        '-141': 32.22218462302316,
        '-140': 32.222150532647134,
        '-139': 32.22213739933648,
        '-138': 32.22213430199338,
        '-137': 32.22212752694647,
        '-136': 32.222109189605085,
        '-135': 32.22208240775737,
        '-134': 32.22206004569393,
        '-133': 32.22205755807191,
        '-132': 32.22208377986211,
        '-131': 32.22213478699588,
        '-130': 32.222194416765156,
        '-129': 32.22224146392652,
        '-128': 32.22225990032047,
        '-127': 32.222246744257895,
        '-126': 32.22221341488115,
        '-125': 32.222179895465885,
        '-124': 32.22216486434958,
        '-123': 32.22217700810382,
        '-122': 32.22221190188293,
        '-121': 32.22225566131003,
        '-120': 32.22229288690055,
        '-119': 32.22231431087271,
        '-118': 32.222320119973965,
        '-117': 32.222317694199205,
        '-116': 32.22231575794381,
        '-115': 32.22231878487193,
        '-114': 32.22232495763982,
        '-113': 32.22232853960921,
        '-112': 32.22232474316731,
        '-111': 32.222313755163505,
        '-110': 32.222301279597616,
        '-109': 32.22229524321273,
        '-108': 32.222300725280085,
        '-107': 32.22231626415286,
        '-106': 32.22233386916226,
        '-105': 32.22234289444003,
        '-104': 32.2223357277394,
        '-103': 32.22231226031695,
        '-102': 32.2222808036796,
        '-101': 32.22225500359859,
        '-100': 32.222248278045825,
        '-99': 32.22226835838549,
        '-98': 32.222314240836496,
        '-97': 32.222376585419354,
        '-96': 32.222441084992354,
        '-95': 32.222493271795074,
        '-94': 32.222522951615844,
        '-93': 32.22252685127743,
        '-92': 32.22250880898732,
        '-91': 32.222477630960086,
        '-90': 32.22244342044662,
        '-89': 32.22241367612778,
        '-88': 32.222390643533025,
        '-87': 32.22237112250521,
        '-86': 32.22234909713263,
        '-85': 32.22232032695499,
        '-84': 32.22228690033332,
        '-83': 32.222259331431026,
        '-82': 32.22225450821167,
        '-81': 32.22228956470776,
        '-80': 32.22237384098513,
        '-79': 32.2225024657996,
        '-78': 32.22265491287375,
        '-77': 32.2228000099165,
        '-76': 32.222906056918,
        '-75': 32.222952239780824,
        '-74': 32.2229366373789,
        '-73': 32.22287733303877,
        '-72': 32.22280597028133,
        '-71': 32.22275626971997,
        '-70': 32.22275211680726,
        '-69': 32.22279993149787,
        '-68': 32.22288816577002,
        '-67': 32.22299383192508,
        '-66': 32.22309322135139,
        '-65': 32.22317249337597,
        '-64': 32.2232340290134,
        '-63': 32.223296096175524,
        '-62': 32.223385759239264,
        '-61': 32.22352729135367,
        '-60': 32.22372997312374,
        '-59': 32.223979680150855,
        '-58': 32.22423790171025,
        '-57': 32.22444972043563,
        '-56': 32.224559295594695,
        '-55': 32.224529043907324,
        '-54': 32.224355189872455,
        '-53': 32.22407357588425,
        '-52': 32.22375255968165,
        '-51': 32.22347323524924,
        '-50': 32.223303409553424,
        '-49': 32.223274661915646,
        '-48': 32.2233715741467,
        '-47': 32.223537959535776,
        '-46': 32.22369818725065,
        '-45': 32.22378534342115,
        '-44': 32.22376492731823,
        '-43': 32.2236446219256,
        '-42': 32.223466733231014,
        '-41': 32.22328745469511,
        '-40': 32.22315268150904,
        '-39': 32.22308107955131,
        '-38': 32.22306112706772,
        '-37': 32.2230619440844,
        '-36': 32.22305135359715,
        '-35': 32.223011856070066,
        '-34': 32.22294718203187,
        '-33': 32.22287749131674,
        '-32': 32.22282711323329,
        '-31': 32.22281194997902,
        '-30': 32.2228328586782,
        '-29': 32.222877314289946,
        '-28': 32.22292694076803,
        '-27': 32.22296576166242,
        '-26': 32.22298459141696,
        '-25': 32.22298016448688,
        '-24': 32.22295119408846,
        '-23': 32.22289528812121,
        '-22': 32.22280954986004,
        '-21': 32.22269463155972,
        '-20': 32.22255911342393,
        '-19': 32.22242034436353,
        '-18': 32.22229988821412,
        '-17': 32.222215247880314,
        '-16': 32.2221723133464,
        '-15': 32.222163102002156,
        '-14': 32.22217055003312,
        '-13': 32.22217795591006,
        '-12': 32.222177705341295,
        '-11': 32.22217405966302,
        '-10': 32.22217817237447,
        '-9': 32.22219822781202,
        '-8': 32.22223085241459,
        '-7': 32.22225964527467,
        '-6': 32.22226275487624,
        '-5': 32.22222600596013,
        '-4': 32.22215428543419,
        '-3': 32.222074080657094,
        '-2': 32.22202439324456,
        '-1': 32.22203952016395
    },
    '-64': {
        '0': 32.22487044239567,
        '1': 32.225152232526135,
        '2': 32.22538852567583,
        '3': 32.22551549148908,
        '4': 32.22550365693806,
        '5': 32.22537016863746,
        '6': 32.22517278692799,
        '7': 32.22498786609177,
        '8': 32.22488139082476,
        '9': 32.22488496401321,
        '10': 32.22498626344589,
        '11': 32.22513708227411,
        '12': 32.22527461044853,
        '13': 32.22534643187797,
        '14': 32.22532893911297,
        '15': 32.22523252880351,
        '16': 32.225093081573114,
        '17': 32.224954950793865,
        '18': 32.224853532851775,
        '19': 32.22480452816983,
        '20': 32.22480308856644,
        '21': 32.224831278293536,
        '22': 32.22486890228903,
        '23': 32.22490212976578,
        '24': 32.22492639545854,
        '25': 32.224943489333384,
        '26': 32.224955744674766,
        '27': 32.22496139370464,
        '28': 32.22495408351254,
        '29': 32.22492694846643,
        '30': 32.224878921236964,
        '31': 32.22481951606483,
        '32': 32.22476884872761,
        '33': 32.224751907686624,
        '34': 32.22478897828853,
        '35': 32.22488625942564,
        '36': 32.22503104641022,
        '37': 32.22519414722002,
        '38': 32.2253391472754,
        '39': 32.22543507345901,
        '40': 32.22546731863578,
        '41': 32.2254421966298,
        '42': 32.22538307751866,
        '43': 32.22531964696243,
        '44': 32.225274883083834,
        '45': 32.22525546208896,
        '46': 32.22524990305899,
        '47': 32.2252353681837,
        '48': 32.2251901068331,
        '49': 32.225105846212806,
        '50': 32.224994275952014,
        '51': 32.224884323806464,
        '52': 32.22481109637829,
        '53': 32.22480127229901,
        '54': 32.224861536096505,
        '55': 32.22497540357528,
        '56': 32.225110002689405,
        '57': 32.2252297684988,
        '58': 32.225310768840046,
        '59': 32.22534908254836,
        '60': 32.225359496762024,
        '61': 32.22536544378056,
        '62': 32.22538532449143,
        '63': 32.22542209999673,
        '64': 32.225461402191904,
        '65': 32.22547918593119,
        '66': 32.2254551734116,
        '67': 32.22538536298504,
        '68': 32.225287162194405,
        '69': 32.225194143667245,
        '70': 32.22514237106524,
        '71': 32.22515433533582,
        '72': 32.225227833294525,
        '73': 32.22533500987634,
        '74': 32.225432279538886,
        '75': 32.22547704510926,
        '76': 32.22544422378042,
        '77': 32.22533580848483,
        '78': 32.225179809956046,
        '79': 32.22501943291199,
        '80': 32.22489721729273,
        '81': 32.224840529035454,
        '82': 32.22485371979646,
        '83': 32.22491920709937,
        '84': 32.22500613435257,
        '85': 32.22508265477209,
        '86': 32.22512711518955,
        '87': 32.22513446926765,
        '88': 32.225116388126764,
        '89': 32.22509577944218,
        '90': 32.22509801443657,
        '91': 32.22514179199056,
        '92': 32.22523233852408,
        '93': 32.225358827283976,
        '94': 32.225496750506,
        '95': 32.225614663604425,
        '96': 32.225683428056534,
        '97': 32.22568511913126,
        '98': 32.225618549533976,
        '99': 32.2254992102316,
        '100': 32.22535329992851,
        '101': 32.22520784227158,
        '102': 32.225080720037866,
        '103': 32.22497484810662,
        '104': 32.22487924319868,
        '105': 32.224776855002816,
        '106': 32.22465589245754,
        '107': 32.2245194669467,
        '108': 32.22438870206308,
        '109': 32.224297067535204,
        '110': 32.22427754444798,
        '111': 32.22434763000314,
        '112': 32.224498567018024,
        '113': 32.22469381866953,
        '114': 32.22487820396801,
        '115': 32.22499477849717,
        '116': 32.22500333609689,
        '117': 32.22489367382923,
        '118': 32.22468877781153,
        '119': 32.22443696064541,
        '120': 32.224196088010466,
        '121': 32.22401573691907,
        '122': 32.22392346501526,
        '123': 32.22391939255805,
        '124': 32.22397997321655,
        '125': 32.22406855665217,
        '126': 32.224148344939465,
        '127': 32.22419318758842,
        '128': 32.22419313055081,
        '129': 32.22415393456102,
        '130': 32.224091933212264,
        '131': 32.22402686663875,
        '132': 32.22397542577017,
        '133': 32.2239473759713,
        '134': 32.223944804500924,
        '135': 32.22396382054045,
        '136': 32.22399734490462,
        '137': 32.22403761016908,
        '138': 32.22407753413001,
        '139': 32.22411092419034,
        '140': 32.22413214603261,
        '141': 32.224136145881545,
        '142': 32.224119436588396,
        '143': 32.22408197190417,
        '144': 32.22402907833258,
        '145': 32.22397220272702,
        '146': 32.22392745883648,
        '147': 32.223911818442424,
        '148': 32.22393795252308,
        '149': 32.22400962742189,
        '150': 32.224119686653836,
        '151': 32.22425180992027,
        '152': 32.22438571439371,
        '153': 32.22450390931009,
        '154': 32.224597279505936,
        '155': 32.22466711996239,
        '156': 32.22472269877871,
        '157': 32.22477538212603,
        '158': 32.22483193394695,
        '159': 32.22489006499611,
        '160': 32.22493839837521,
        '161': 32.224961105419055,
        '162': 32.22494536839415,
        '163': 32.22488846160596,
        '164': 32.224801221523286,
        '165': 32.22470604544923,
        '166': 32.224629781033315,
        '167': 32.22459404789272,
        '168': 32.224606768226074,
        '169': 32.22465843135797,
        '170': 32.22472491171784,
        '171': 32.22477611969332,
        '172': 32.224787375478385,
        '173': 32.2247491365776,
        '174': 32.22467115775078,
        '175': 32.2245792302769,
        '176': 32.22450556960185,
        '177': 32.22447652357561,
        '178': 32.22450244914692,
        '179': 32.22457379806921,
        '180': 32.22466494291945,
        '-180': 32.22466494291945,
        '-179': 32.224744104201044,
        '-178': 32.22478527134737,
        '-177': 32.22477731809453,
        '-176': 32.22472687561867,
        '-175': 32.22465429290082,
        '-174': 32.22458490552575,
        '-173': 32.224539558689834,
        '-172': 32.224528193755624,
        '-171': 32.22454852661811,
        '-170': 32.224589398567645,
        '-169': 32.22463648009972,
        '-168': 32.22467748292354,
        '-167': 32.224704938773876,
        '-166': 32.22471625656701,
        '-165': 32.224712187899115,
        '-164': 32.224695285833626,
        '-163': 32.224669337527146,
        '-162': 32.22463962146398,
        '-161': 32.22461298639293,
        '-160': 32.224596743825295,
        '-159': 32.22459621328444,
        '-158': 32.22461190500775,
        '-157': 32.224637998743205,
        '-156': 32.224663464974455,
        '-155': 32.224675939777995,
        '-154': 32.22466693736615,
        '-153': 32.22463602336002,
        '-152': 32.22459178246424,
        '-151': 32.22454879523618,
        '-150': 32.22452175037918,
        '-149': 32.224519296741605,
        '-148': 32.224540491785035,
        '-147': 32.22457553797941,
        '-146': 32.22461044792276,
        '-145': 32.22463335382728,
        '-144': 32.22463936174225,
        '-143': 32.224631593301744,
        '-142': 32.22461801740727,
        '-141': 32.22460586898126,
        '-140': 32.22459674390475,
        '-139': 32.22458514331222,
        '-138': 32.22456143271028,
        '-137': 32.22451779271563,
        '-136': 32.224454023562465,
        '-135': 32.224379968465726,
        '-134': 32.22431295533484,
        '-133': 32.224271212517095,
        '-132': 32.22426635994653,
        '-131': 32.22429865767321,
        '-130': 32.224357362262204,
        '-129': 32.22442596936809,
        '-128': 32.22448965667263,
        '-127': 32.22454118978566,
        '-126': 32.22458246425613,
        '-125': 32.22462122110681,
        '-124': 32.22466500336012,
        '-123': 32.22471575885819,
        '-122': 32.22476795833088,
        '-121': 32.22481104634915,
        '-120': 32.22483465678506,
        '-119': 32.224833627840205,
        '-118': 32.22481016785654,
        '-117': 32.224772286807195,
        '-116': 32.224729749211996,
        '-115': 32.22469008262238,
        '-114': 32.22465689690329,
        '-113': 32.22463116486472,
        '-112': 32.22461418169746,
        '-111': 32.22460982574938,
        '-110': 32.22462414363668,
        '-109': 32.22466195556301,
        '-108': 32.22472215728696,
        '-107': 32.224794530338016,
        '-106': 32.224860466564216,
        '-105': 32.224898209258484,
        '-104': 32.2248909083876,
        '-103': 32.22483415423975,
        '-102': 32.22473953487246,
        '-101': 32.224632260360885,
        '-100': 32.22454334711268,
        '-99': 32.22449916799719,
        '-98': 32.224512343311424,
        '-97': 32.22457748772069,
        '-96': 32.224673449200225,
        '-95': 32.224771157404255,
        '-94': 32.22484404813838,
        '-93': 32.224877081484316,
        '-92': 32.22487097683978,
        '-91': 32.22484020495188,
        '-90': 32.22480576166974,
        '-89': 32.22478582941486,
        '-88': 32.22478827097691,
        '-87': 32.2248081206781,
        '-86': 32.22483110822844,
        '-85': 32.224841618882564,
        '-84': 32.22483147327676,
        '-83': 32.22480541713847,
        '-82': 32.2247805415155,
        '-81': 32.22477950639048,
        '-80': 32.22482028581728,
        '-79': 32.22490691123233,
        '-78': 32.225025511155664,
        '-77': 32.22514782563084,
        '-76': 32.22524119356644,
        '-75': 32.2252811605868,
        '-74': 32.22526160494421,
        '-73': 32.225198198609114,
        '-72': 32.225123718195206,
        '-71': 32.22507702758433,
        '-70': 32.225090058677964,
        '-69': 32.22517778853881,
        '-68': 32.22533483467323,
        '-67': 32.22553951759885,
        '-66': 32.22576326514765,
        '-65': 32.225981258909286,
        '-64': 32.22617997229493,
        '-63': 32.22635872130236,
        '-62': 32.2265248883103,
        '-61': 32.22668509465618,
        '-60': 32.221832072698085,
        '-59': 32.21373135851478,
        '-58': 32.22133286001997,
        '-57': 32.227001926627615,
        '-56': 32.226858844197075,
        '-55': 32.226594046079896,
        '-54': 32.22623252267277,
        '-53': 32.225826321315665,
        '-52': 32.225442921348034,
        '-51': 32.225146989165395,
        '-50': 32.224981485018795,
        '-49': 32.224954926035075,
        '-48': 32.225039794794995,
        '-47': 32.22518309760306,
        '-46': 32.22532538739002,
        '-45': 32.225421099402226,
        '-44': 32.22545236751086,
        '-43': 32.22543105186495,
        '-42': 32.22538856619759,
        '-41': 32.22535822381968,
        '-40': 32.225357986029046,
        '-39': 32.225381251288425,
        '-38': 32.22539971810528,
        '-37': 32.225376961792996,
        '-36': 32.22528658865124,
        '-35': 32.225126801966425,
        '-34': 32.2249248080114,
        '-33': 32.22472893865465,
        '-32': 32.224591644955574,
        '-31': 32.22455029881538,
        '-30': 32.22461346363105,
        '-29': 32.22475781800515,
        '-28': 32.224936489821715,
        '-27': 32.22509516015209,
        '-26': 32.22518969541461,
        '-25': 32.22519907927582,
        '-24': 32.22512969643043,
        '-23': 32.22501039550395,
        '-22': 32.22488086140547,
        '-21': 32.22477767264043,
        '-20': 32.22472266463784,
        '-19': 32.22471708850251,
        '-18': 32.22474306520793,
        '-17': 32.224771561868856,
        '-16': 32.22477408948952,
        '-15': 32.22473403763665,
        '-14': 32.22465346882039,
        '-13': 32.22455254648539,
        '-12': 32.22446138306304,
        '-11': 32.22440719570127,
        '-10': 32.224402011830975,
        '-9': 32.22443655106186,
        '-8': 32.224483762253584,
        '-7': 32.22451138989855,
        '-6': 32.22449855265447,
        '-5': 32.22444874690123,
        '-4': 32.224392462269094,
        '-3': 32.22437677402627,
        '-2': 32.22444522403919,
        '-1': 32.22461631012471
    },
    '-65': {
        '0': 32.2275983498238,
        '1': 32.22787075525646,
        '2': 32.22807374622162,
        '3': 32.22814745569154,
        '4': 32.22807132894206,
        '5': 32.22787296885491,
        '6': 32.22761891793118,
        '7': 32.227390852052075,
        '8': 32.22725652214325,
        '9': 32.22724675606536,
        '10': 32.22734713851214,
        '11': 32.22750685028813,
        '12': 32.2276602461046,
        '13': 32.22775199582891,
        '14': 32.22775587526394,
        '15': 32.227680624790146,
        '16': 32.22756202085709,
        '17': 32.22744585377206,
        '18': 32.227369579302085,
        '19': 32.22734996177222,
        '20': 32.22738060669492,
        '21': 32.227438674687505,
        '22': 32.22749639567213,
        '23': 32.22753174776717,
        '24': 32.22753407388349,
        '25': 32.227503497162196,
        '26': 32.22744612082578,
        '27': 32.227368675859026,
        '28': 32.2272758646072,
        '29': 32.22717156446524,
        '30': 32.227062516385885,
        '31': 32.22696149308955,
        '32': 32.22688706536728,
        '33': 32.22685889030787,
        '34': 32.226890003773576,
        '35': 32.22697959035183,
        '36': 32.2271100605547,
        '37': 32.22725069631986,
        '38': 32.22736727315153,
        '39': 32.22743422143922,
        '40': 32.227444399016534,
        '41': 32.22741218819545,
        '42': 32.22736822997554,
        '43': 32.22734759175344,
        '44': 32.227376016809046,
        '45': 32.227459837729164,
        '46': 32.227583682556464,
        '47': 32.227716846278405,
        '48': 32.22782554428172,
        '49': 32.22788581476235,
        '50': 32.2278916798955,
        '51': 32.227855377071585,
        '52': 32.227800036371406,
        '53': 32.22774849502666,
        '54': 32.22771350499991,
        '55': 32.22769371322623,
        '56': 32.22767691613896,
        '57': 32.227648585139896,
        '58': 32.22760119065663,
        '59': 32.227539581484734,
        '60': 32.227479708896155,
        '61': 32.2274413196448,
        '62': 32.227438250741486,
        '63': 32.22747117370975,
        '64': 32.22752646165692,
        '65': 32.22758185973637,
        '66': 32.227616291363425,
        '67': 32.227619071333315,
        '68': 32.22759404188987,
        '69': 32.22755659597532,
        '70': 32.22752502689297,
        '71': 32.22751046359514,
        '72': 32.227510439268514,
        '73': 32.22750948936818,
        '74': 32.2274868411233,
        '75': 32.22742780320231,
        '76': 32.22733352015269,
        '77': 32.22722425100574,
        '78': 32.227134070237305,
        '79': 32.227098655208465,
        '80': 32.22714094449861,
        '81': 32.22726055430849,
        '82': 32.227431454496575,
        '83': 32.227609142898295,
        '84': 32.22774480746629,
        '85': 32.2278012743931,
        '86': 32.227764947244516,
        '87': 32.227649587259066,
        '88': 32.22749091665529,
        '89': 32.227334370472846,
        '90': 32.22722061858713,
        '91': 32.22717395779118,
        '92': 32.22719729133502,
        '93': 32.22727479989808,
        '94': 32.22738057912886,
        '95': 32.227489489425665,
        '96': 32.227565664615064,
        '97': 32.2276671032593,
        '98': 32.2277402474869,
        '99': 32.227814564767804,
        '100': 32.227892282967346,
        '101': 32.227962593395816,
        '102': 32.22800185624032,
        '103': 32.22798084682932,
        '104': 32.22787686457777,
        '105': 32.227686148915915,
        '106': 32.227431293670065,
        '107': 32.22715970123649,
        '108': 32.226932242168736,
        '109': 32.22680509611413,
        '110': 32.226810768111925,
        '111': 32.226945207998966,
        '112': 32.22716626114684,
        '113': 32.22740483161224,
        '114': 32.2275855343263,
        '115': 32.22765001874105,
        '116': 32.22757499299154,
        '117': 32.22737876446116,
        '118': 32.22711419124869,
        '119': 32.22685076573035,
        '120': 32.2266523004766,
        '121': 32.22655797198925,
        '122': 32.2265728907038,
        '123': 32.22667061528622,
        '124': 32.22680562874183,
        '125': 32.22693043268106,
        '126': 32.22701078589395,
        '127': 32.2270339700335,
        '128': 32.22700809245144,
        '129': 32.226953996052124,
        '130': 32.22689394448628,
        '131': 32.22684198011837,
        '132': 32.22679960104508,
        '133': 32.22675783792852,
        '134': 32.22670405614209,
        '135': 32.226629975599295,
        '136': 32.22653715701351,
        '137': 32.22643754239664,
        '138': 32.22634892288728,
        '139': 32.2262874548814,
        '140': 32.22626062568588,
        '141': 32.22626387045847,
        '142': 32.22628246379066,
        '143': 32.226298019036726,
        '144': 32.22629688952829,
        '145': 32.22627684593025,
        '146': 32.22624901666496,
        '147': 32.22623400198772,
        '148': 32.226253527092794,
        '149': 32.22632095903918,
        '150': 32.22643462919458,
        '151': 32.226576875381596,
        '152': 32.226719435190596,
        '153': 32.22683321706592,
        '154': 32.22689863825998,
        '155': 32.22691243035271,
        '156': 32.226888209917306,
        '157': 32.226850622018446,
        '158': 32.22682544769844,
        '159': 32.22682965506317,
        '160': 32.226865320030996,
        '161': 32.226919690706055,
        '162': 32.22697112739397,
        '163': 32.22699828594287,
        '164': 32.22698869780936,
        '165': 32.22694330805731,
        '166': 32.22687534737155,
        '167': 32.22680433867743,
        '168': 32.226748038413156,
        '169': 32.22671585302598,
        '170': 32.226706468657646,
        '171': 32.22671046694423,
        '172': 32.226716450248695,
        '173': 32.226717658820924,
        '174': 32.22671590543059,
        '175': 32.22672093934976,
        '176': 32.22674549627308,
        '177': 32.226798321847475,
        '178': 32.226878448760374,
        '179': 32.22697350205149,
        '180': 32.227063026142304,
        '-180': 32.227063026142304,
        '-179': 32.22712557651918,
        '-178': 32.22714662222264,
        '-177': 32.22712392952412,
        '-176': 32.22706819927257,
        '-175': 32.22699878973592,
        '-174': 32.22693642708343,
        '-173': 32.22689596371582,
        '-172': 32.226882018947315,
        '-171': 32.22688891031702,
        '-170': 32.22690437880763,
        '-169': 32.22691511787273,
        '-168': 32.22691166103648,
        '-167': 32.22689085077012,
        '-166': 32.22685545194093,
        '-165': 32.226811770518445,
        '-164': 32.2267667898488,
        '-163': 32.226726140471946,
        '-162': 32.226693428600846,
        '-161': 32.22667058579148,
        '-160': 32.226658450273774,
        '-159': 32.226656933032885,
        '-158': 32.226664675172366,
        '-157': 32.22667865732635,
        '-156': 32.2266944002833,
        '-155': 32.22670707898488,
        '-154': 32.226713259695806,
        '-153': 32.226712454267236,
        '-152': 32.22670761113433,
        '-151': 32.22670411864425,
        '-150': 32.22670765440419,
        '-149': 32.226721853411604,
        '-148': 32.226746912041285,
        '-147': 32.22677978365293,
        '-146': 32.22681578427322,
        '-145': 32.22685066847303,
        '-144': 32.2268819888058,
        '-143': 32.226908980710505,
        '-142': 32.226931106166134,
        '-141': 32.22694625360803,
        '-140': 32.22694992136909,
        '-139': 32.226936268734974,
        '-138': 32.226900887154805,
        '-137': 32.2268440596423,
        '-136': 32.226772751916805,
        '-135': 32.22669996889964,
        '-134': 32.226641294274664,
        '-133': 32.22660984374468,
        '-132': 32.22661177125412,
        '-131': 32.22664435740528,
        '-130': 32.226697575916255,
        '-129': 32.22675841244954,
        '-128': 32.22681591426763,
        '-127': 32.22686464800415,
        '-126': 32.22690510035747,
        '-125': 32.226941133012744,
        '-124': 32.22697607936744,
        '-123': 32.227009680438876,
        '-122': 32.227037496983876,
        '-121': 32.2270530264,
        '-120': 32.227051268755865,
        '-119': 32.2270317281766,
        '-118': 32.22699919891834,
        '-117': 32.226961946382666,
        '-116': 32.226928338659214,
        '-115': 32.22690380752839,
        '-114': 32.2268897656088,
        '-113': 32.226884927269374,
        '-112': 32.2268880431198,
        '-111': 32.22690017990153,
        '-110': 32.22692487683035,
        '-109': 32.22696571389449,
        '-108': 32.22702240575956,
        '-107': 32.2270876259824,
        '-106': 32.227146734477486,
        '-105': 32.227181356343614,
        '-104': 32.22717588881172,
        '-103': 32.227124397099196,
        '-102': 32.22703481646546,
        '-101': 32.22692823967878,
        '-100': 32.22683301797704,
        '-99': 32.226775608105605,
        '-98': 32.2267715868956,
        '-97': 32.22682035083517,
        '-96': 32.22690563946888,
        '-95': 32.227001722074256,
        '-94': 32.22708282131685,
        '-93': 32.227132064590094,
        '-92': 32.22714649477929,
        '-91': 32.22713634058388,
        '-90': 32.22711914463674,
        '-89': 32.22711146545866,
        '-88': 32.22712180771333,
        '-87': 32.22714779100752,
        '-86': 32.22717861645987,
        '-85': 32.22720148297084,
        '-84': 32.22720880763098,
        '-83': 32.227202732089765,
        '-82': 32.227194642559574,
        '-81': 32.22719976279147,
        '-80': 32.22722925612722,
        '-79': 32.227283600860055,
        '-78': 32.227350630945764,
        '-77': 32.22740966336608,
        '-76': 32.22744039745078,
        '-75': 32.227432978599985,
        '-74': 32.22739481674981,
        '-73': 32.22735085246339,
        '-72': 32.227336555836466,
        '-71': 32.227385952658466,
        '-70': 32.22751912736272,
        '-69': 32.22773402957494,
        '-68': 32.228005828817075,
        '-67': 32.22829415503698,
        '-66': 32.2285555101947,
        '-65': 32.2287561852183,
        '-64': 32.228880895125464,
        '-63': 32.2170262723431,
        '-62': 32.22156052055829,
        '-61': 32.22842092104969,
        '-60': 32.22883998184627,
        '-59': 32.2287596269247,
        '-58': 32.22864097481231,
        '-57': 32.228464653055916,
        '-56': 32.22821882852455,
        '-55': 32.22791006268351,
        '-54': 32.22756801639463,
        '-53': 32.22724123734718,
        '-52': 32.22698464049163,
        '-51': 32.22684266887036,
        '-50': 32.226834169169315,
        '-49': 32.226944832193496,
        '-48': 32.22713055182385,
        '-47': 32.22733108389819,
        '-46': 32.22748942695306,
        '-45': 32.22756997811371,
        '-44': 32.227568765938116,
        '-43': 32.227511946767194,
        '-42': 32.22744322100657,
        '-41': 32.22740513865337,
        '-40': 32.22742167394491,
        '-39': 32.227488934116906,
        '-38': 32.22757758249257,
        '-37': 32.22764580926248,
        '-36': 32.22765741072664,
        '-35': 32.22759749571964,
        '-34': 32.22747936061682,
        '-33': 32.227339724965965,
        '-32': 32.2272242826487,
        '-31': 32.22716944498647,
        '-30': 32.22718765234195,
        '-29': 32.22726214325136,
        '-28': 32.227353249753534,
        '-27': 32.227413709500155,
        '-26': 32.22740697825516,
        '-25': 32.227321433873286,
        '-24': 32.227175038565846,
        '-23': 32.22700874916736,
        '-22': 32.226871268538346,
        '-21': 32.226800984127365,
        '-20': 32.22681194858005,
        '-19': 32.22688917984279,
        '-18': 32.226995002910144,
        '-17': 32.22708394937785,
        '-16': 32.2271204434435,
        '-15': 32.22709238473056,
        '-14': 32.22701526330389,
        '-13': 32.22692510644695,
        '-12': 32.22686301450617,
        '-11': 32.226857561456434,
        '-10': 32.22691243917169,
        '-9': 32.22700480975755,
        '-8': 32.22709550032998,
        '-7': 32.227147101320476,
        '-6': 32.22714233864984,
        '-5': 32.227094478987084,
        '-4': 32.22704448239249,
        '-3': 32.22704509783839,
        '-2': 32.227137873795456,
        '-1': 32.22733260514936
    },
    '-66': {
        '0': 32.22965583933821,
        '1': 32.22981474871616,
        '2': 32.22992934379414,
        '3': 32.22995976293629,
        '4': 32.22989479774945,
        '5': 32.229756681494024,
        '6': 32.22959290653779,
        '7': 32.229458320573436,
        '8': 32.229394648687176,
        '9': 32.22941552466353,
        '10': 32.22950273555585,
        '11': 32.229614736341595,
        '12': 32.22970356057649,
        '13': 32.22973308921166,
        '14': 32.22969148005127,
        '15': 32.22959332877042,
        '16': 32.22947152936532,
        '17': 32.22936291515264,
        '18': 32.229293908940726,
        '19': 32.22927184195299,
        '20': 32.229284760845914,
        '21': 32.2293088064319,
        '22': 32.229319267202186,
        '23': 32.22930037595634,
        '24': 32.22925007604008,
        '25': 32.22917860720411,
        '26': 32.2291025492941,
        '27': 32.22903766620709,
        '28': 32.22899386989674,
        '29': 32.22897407807095,
        '30': 32.22897655892301,
        '31': 32.22899865287314,
        '32': 32.22903933008715,
        '33': 32.22909900435038,
        '34': 32.229176803632825,
        '35': 32.22926713389007,
        '36': 32.229357999515614,
        '37': 32.22943281401688,
        '38': 32.229475684834625,
        '39': 32.22947824323575,
        '40': 32.22944497636507,
        '41': 32.229394307211294,
        '42': 32.22935430650446,
        '43': 32.229354209194355,
        '44': 32.229414815188065,
        '45': 32.22954148882897,
        '46': 32.229722502323035,
        '47': 32.22993328839926,
        '48': 32.23014472917958,
        '49': 32.23033199433851,
        '50': 32.23048037080163,
        '51': 32.2305860209303,
        '52': 32.22981161312492,
        '53': 32.226814440697574,
        '54': 32.22578500788243,
        '55': 32.227242576521036,
        '56': 32.23052002081058,
        '57': 32.230358814048245,
        '58': 32.23014641748798,
        '59': 32.229903192405665,
        '60': 32.22966131641412,
        '61': 32.229457479134986,
        '62': 32.229323104237686,
        '63': 32.22927573247021,
        '64': 32.2293146910099,
        '65': 32.229422361115454,
        '66': 32.2295700996698,
        '67': 32.22972619751679,
        '68': 32.22986287307536,
        '69': 32.22996026579325,
        '70': 32.23000712880955,
        '71': 32.229999507438045,
        '72': 32.22993934161181,
        '73': 32.229834373843325,
        '74': 32.22969934787146,
        '75': 32.2295570605581,
        '76': 32.229437222078595,
        '77': 32.229371722343586,
        '78': 32.22938658704565,
        '79': 32.22949286046592,
        '80': 32.2296798908517,
        '81': 32.22991429648176,
        '82': 32.23014616010638,
        '83': 32.23032134613889,
        '84': 32.23039633143634,
        '85': 32.2303506618473,
        '86': 32.23019270018541,
        '87': 32.229956583909136,
        '88': 32.22969142992121,
        '89': 32.22944660894547,
        '90': 32.229258276095024,
        '91': 32.22914174984156,
        '92': 32.22909200556803,
        '93': 32.229091395544756,
        '94': 32.2291209497864,
        '95': 32.22917032143129,
        '96': 32.228898357440755,
        '97': 32.22899484289636,
        '98': 32.229027752001215,
        '99': 32.229231507879526,
        '100': 32.22947730293505,
        '101': 32.23017249765296,
        '102': 32.22798629304356,
        '103': 32.226018825841294,
        '104': 32.23019650123234,
        '105': 32.23015362398917,
        '106': 32.229868406410255,
        '107': 32.229558432324986,
        '108': 32.229297581576226,
        '109': 32.22914558803736,
        '110': 32.22912929200388,
        '111': 32.22923424108762,
        '112': 32.22536288485728,
        '113': 32.223737500876375,
        '114': 32.22969983607787,
        '115': 32.22971041481187,
        '116': 32.22961761809307,
        '117': 32.22945712436097,
        '118': 32.229286769145276,
        '119': 32.22916485404796,
        '120': 32.22912910721845,
        '121': 32.229183910149075,
        '122': 32.229300198456215,
        '123': 32.22942765717187,
        '124': 32.2295142873918,
        '125': 32.22952582464263,
        '126': 32.229457771273964,
        '127': 32.22933578622037,
        '128': 32.22920466411419,
        '129': 32.229110407053234,
        '130': 32.22908235104662,
        '131': 32.22912208987504,
        '132': 32.22920320817555,
        '133': 32.22928148363159,
        '134': 32.229311717711866,
        '135': 32.229264632236,
        '136': 32.22913752464016,
        '137': 32.22895492794575,
        '138': 32.22875931961888,
        '139': 32.22859599302652,
        '140': 32.228497627802014,
        '141': 32.228474305141624,
        '142': 32.22851244440492,
        '143': 32.228582521220545,
        '144': 32.22865211174409,
        '145': 32.22869891735461,
        '146': 32.22871866741156,
        '147': 32.22872501586243,
        '148': 32.22874182144287,
        '149': 32.22879122487509,
        '150': 32.2288825317932,
        '151': 32.229006483850426,
        '152': 32.229137266139475,
        '153': 32.22924147806915,
        '154': 32.22929053738428,
        '155': 32.229271668651144,
        '156': 32.229193227769215,
        '157': 32.22908234542866,
        '158': 32.22897581983171,
        '159': 32.22890769162498,
        '160': 32.22889804896593,
        '161': 32.22894697285454,
        '162': 32.229035472667725,
        '163': 32.2291326421935,
        '164': 32.229206115079535,
        '165': 32.22923199303938,
        '166': 32.229200997550194,
        '167': 32.22911929891579,
        '168': 32.229004542977044,
        '169': 32.22887919596142,
        '170': 32.22876390439471,
        '171': 32.22867303448303,
        '172': 32.228613289599586,
        '173': 32.228584937266206,
        '174': 32.228584298587315,
        '175': 32.22860605806138,
        '176': 32.22864453884635,
        '177': 32.22869395040183,
        '178': 32.22874826395513,
        '179': 32.228801485262764,
        '180': 32.22884866832717,
        '-180': 32.22884866832717,
        '-179': 32.22888734089249,
        '-178': 32.228918514346056,
        '-177': 32.22894644648235,
        '-176': 32.228976858897674,
        '-175': 32.22901412174695,
        '-174': 32.2290585769467,
        '-173': 32.22910530085114,
        '-172': 32.2291450888981,
        '-171': 32.2291674865762,
        '-170': 32.22916473653362,
        '-169': 32.229135022287,
        '-168': 32.22908361578049,
        '-167': 32.22902139300161,
        '-166': 32.228961285072586,
        '-165': 32.228914083845545,
        '-164': 32.22888524587645,
        '-163': 32.228873857201336,
        '-162': 32.228873976670904,
        '-161': 32.228877606417846,
        '-160': 32.228877965533485,
        '-159': 32.228871778813314,
        '-158': 32.22885985841115,
        '-157': 32.22884604689935,
        '-156': 32.228835235706875,
        '-155': 32.22883141560907,
        '-154': 32.22883651462711,
        '-153': 32.22885029757675,
        '-152': 32.22887110309,
        '-151': 32.22889689999204,
        '-150': 32.22892613889644,
        '-149': 32.22895808900371,
        '-148': 32.22899263112715,
        '-147': 32.229029684632984,
        '-146': 32.229068519776405,
        '-145': 32.22910718050871,
        '-144': 32.22914219058407,
        '-143': 32.229168685738,
        '-142': 32.229181090111624,
        '-141': 32.229174372208895,
        '-140': 32.22914572596965,
        '-139': 32.22909625592086,
        '-138': 32.2290320259976,
        '-137': 32.22896382456242,
        '-136': 32.22890530872013,
        '-135': 32.22886977410647,
        '-134': 32.22886643669703,
        '-133': 32.228897504558425,
        '-132': 32.22895721265447,
        '-131': 32.229033351310356,
        '-130': 32.22911086140236,
        '-129': 32.22917620776065,
        '-128': 32.2292208889248,
        '-127': 32.2292427998367,
        '-126': 32.2292451062316,
        '-125': 32.22923339350097,
        '-124': 32.22921259506342,
        '-123': 32.22918521824363,
        '-122': 32.229151651214046,
        '-121': 32.229112208590074,
        '-120': 32.22906961948059,
        '-119': 32.22903037203851,
        '-118': 32.22900386999041,
        '-117': 32.22899947709956,
        '-116': 32.22902267039166,
        '-115': 32.229072108755986,
        '-114': 32.22913913214214,
        '-113': 32.229210151021135,
        '-112': 32.22927106150715,
        '-111': 32.229311865675555,
        '-110': 32.22932955512661,
        '-109': 32.22932809464568,
        '-108': 32.22931565572724,
        '-107': 32.229300476974124,
        '-106': 32.22928729576289,
        '-105': 32.229275947883046,
        '-104': 32.2292626495699,
        '-103': 32.229243182743886,
        '-102': 32.22921631182571,
        '-101': 32.229185680718906,
        '-100': 32.22915919315432,
        '-99': 32.229146098793095,
        '-98': 32.22915311077925,
        '-97': 32.22918135106623,
        '-96': 32.22922554417078,
        '-95': 32.22927584244125,
        '-94': 32.22932145831965,
        '-93': 32.22935446865674,
        '-92': 32.229372120192565,
        '-91': 32.22937671508553,
        '-90': 32.2293733302451,
        '-89': 32.229366659459245,
        '-88': 32.22935865943925,
        '-87': 32.22934822925476,
        '-86': 32.22933306368392,
        '-85': 32.22931262232198,
        '-84': 32.229290453559535,
        '-83': 32.22927429813017,
        '-82': 32.22927344607259,
        '-81': 32.22929427657929,
        '-80': 32.229336074049456,
        '-79': 32.22938946567729,
        '-78': 32.229438929364896,
        '-77': 32.22946906833552,
        '-76': 32.22947247039274,
        '-75': 32.22945582947823,
        '-74': 32.22944121738587,
        '-73': 32.229461017165825,
        '-72': 32.22954750966673,
        '-71': 32.22972046163447,
        '-70': 32.22997729028959,
        '-69': 32.230289867791704,
        '-68': 32.23060985266156,
        '-67': 32.23088137049162,
        '-66': 32.231057120380676,
        '-65': 32.22839745221609,
        '-64': 32.214922461457554,
        '-63': 32.2231655387131,
        '-62': 32.2302835675219,
        '-61': 32.23000526401406,
        '-60': 32.23036691462556,
        '-59': 32.23025109516401,
        '-58': 32.230157571817045,
        '-57': 32.23005805068916,
        '-56': 32.22992727405326,
        '-55': 32.22975693196696,
        '-54': 32.22956286804524,
        '-53': 32.229382411148556,
        '-52': 32.229262385213076,
        '-51': 32.22924197485117,
        '-50': 32.22933678061488,
        '-49': 32.229530222521845,
        '-48': 32.2297759369502,
        '-47': 32.230010842698775,
        '-46': 32.230174589714316,
        '-45': 32.23022864579781,
        '-44': 32.23016827805722,
        '-43': 32.23002318331643,
        '-42': 32.229846604284845,
        '-41': 32.22969691419537,
        '-40': 32.22961830461799,
        '-39': 32.22962737545259,
        '-38': 32.22971006288149,
        '-37': 32.22982938613906,
        '-36': 32.22994049159155,
        '-35': 32.23000696099853,
        '-34': 32.230012251650905,
        '-33': 32.22996240018709,
        '-32': 32.22987977175132,
        '-31': 32.22979117819115,
        '-30': 32.22971572085341,
        '-29': 32.22965748063371,
        '-28': 32.22960585929626,
        '-27': 32.22954298961125,
        '-26': 32.22945460249719,
        '-25': 32.22933931122509,
        '-24': 32.22921200789919,
        '-23': 32.22909963182857,
        '-22': 32.22903088305678,
        '-21': 32.229024135851155,
        '-20': 32.22907873237481,
        '-19': 32.229173593904015,
        '-18': 32.2292741485177,
        '-17': 32.22934511864102,
        '-16': 32.22936415769672,
        '-15': 32.22933075946208,
        '-14': 32.22926659123402,
        '-13': 32.2292067964658,
        '-12': 32.229185537791594,
        '-11': 32.229221576171994,
        '-10': 32.22930990046321,
        '-9': 32.229423134599266,
        '-8': 32.2295224974383,
        '-7': 32.22957405756144,
        '-6': 32.229563609106734,
        '-5': 32.2295037899013,
        '-4': 32.229430066895176,
        '-3': 32.229386774172134,
        '-2': 32.22940864019445,
        '-1': 32.22950536707753
    },
    '-67': {
        '0': 32.23118761346139,
        '1': 32.231246476046884,
        '2': 32.23129631884819,
        '3': 32.23132292372623,
        '4': 32.231325530697546,
        '5': 32.23131719885179,
        '6': 32.231318788648885,
        '7': 32.23134906953114,
        '8': 32.23141519804617,
        '9': 32.23150774643579,
        '10': 32.23160259741293,
        '11': 32.231669141750764,
        '12': 32.23168154666402,
        '13': 32.23162854180678,
        '14': 32.23151775487949,
        '15': 32.23137286542111,
        '16': 32.23122478224301,
        '17': 32.23110045324533,
        '18': 32.23101379610047,
        '19': 32.230962262082286,
        '20': 32.230930164205596,
        '21': 32.230897138694075,
        '22': 32.23084811307508,
        '23': 32.23078070897937,
        '24': 32.230707220809315,
        '25': 32.23065060245345,
        '26': 32.23063627330392,
        '27': 32.23068305762074,
        '28': 32.230796657628424,
        '29': 32.230967810806064,
        '30': 32.23117531803918,
        '31': 32.23139231321335,
        '32': 32.231593193695915,
        '33': 32.23175882610903,
        '34': 32.23187874904663,
        '35': 32.231950517936944,
        '36': 32.23197741752745,
        '37': 32.23196608150094,
        '38': 32.2319250872622,
        '39': 32.23186468957037,
        '40': 32.23179704144278,
        '41': 32.23173594818566,
        '42': 32.23169553274114,
        '43': 32.231687934714245,
        '44': 32.231720882731324,
        '45': 32.23179625000652,
        '46': 32.23191034554148,
        '47': 32.23205586549443,
        '48': 32.232224552626846,
        '49': 32.22897914026809,
        '50': 32.23260368837936,
        '51': 32.230635477739604,
        '52': 32.224398265874356,
        '53': 32.21995756821703,
        '54': 32.21559042675063,
        '55': 32.2161802710998,
        '56': 32.22773182536948,
        '57': 32.232784973836765,
        '58': 32.23287405654602,
        '59': 32.23256473909931,
        '60': 32.23223560638506,
        '61': 32.23193844441666,
        '62': 32.23172052053471,
        '63': 32.2316136317086,
        '64': 32.23162692833391,
        '65': 32.23174541045699,
        '66': 32.23193423463977,
        '67': 32.232147305158016,
        '68': 32.2323376285231,
        '69': 32.232466822008014,
        '70': 32.23251184419978,
        '71': 32.23246805599304,
        '72': 32.23234867627856,
        '73': 32.232181282040266,
        '74': 32.232002192464094,
        '75': 32.23184955927939,
        '76': 32.23175600643245,
        '77': 32.231741849347465,
        '78': 32.231810192214674,
        '79': 32.23194529032935,
        '80': 32.232115192360425,
        '81': 32.23227873413799,
        '82': 32.2323956243702,
        '83': 32.23243715650307,
        '84': 32.232394141526825,
        '85': 32.23161168188068,
        '86': 32.23129545137892,
        '87': 32.23027142199275,
        '88': 32.22672571668276,
        '89': 32.225624813115694,
        '90': 32.22516796148503,
        '91': 32.22242381086883,
        '92': 32.22166924012225,
        '93': 32.22205685605793,
        '94': 32.223632270320486,
        '95': 32.22173866760841,
        '96': 32.22210288386759,
        '97': 32.21965274022555,
        '98': 32.2203523065618,
        '99': 32.22071635888898,
        '100': 32.22480849835946,
        '101': 32.219787470915506,
        '102': 32.21843788026756,
        '103': 32.21689923522077,
        '104': 32.21599296510938,
        '105': 32.216589695668404,
        '106': 32.217313703086546,
        '107': 32.221048165218264,
        '108': 32.223500912311465,
        '109': 32.22660680353934,
        '110': 32.22745997039943,
        '111': 32.227187570376515,
        '112': 32.22275620632622,
        '113': 32.22056119681591,
        '114': 32.22412744087814,
        '115': 32.23019808025166,
        '116': 32.230938418071894,
        '117': 32.23109141678452,
        '118': 32.23145962237771,
        '119': 32.231222971432416,
        '120': 32.23108053172001,
        '121': 32.23103131872384,
        '122': 32.23029131813953,
        '123': 32.227898683254864,
        '124': 32.22729211542762,
        '125': 32.22476718318483,
        '126': 32.2219847176845,
        '127': 32.22822004346546,
        '128': 32.23097579823114,
        '129': 32.23084847704369,
        '130': 32.22669408435804,
        '131': 32.21990201094517,
        '132': 32.21839117327044,
        '133': 32.21772614839277,
        '134': 32.220345106351196,
        '135': 32.218819816705725,
        '136': 32.21945362049153,
        '137': 32.219641109643725,
        '138': 32.220030940866366,
        '139': 32.22101769381747,
        '140': 32.22263288456612,
        '141': 32.22440075799043,
        '142': 32.225890450257836,
        '143': 32.22972630537104,
        '144': 32.23113205192853,
        '145': 32.23106477967611,
        '146': 32.23095764916468,
        '147': 32.2308295872567,
        '148': 32.23071513814563,
        '149': 32.23065145290215,
        '150': 32.230664043181406,
        '151': 32.23075666349638,
        '152': 32.23090902090608,
        '153': 32.231083036226906,
        '154': 32.231235251356,
        '155': 32.23133084612085,
        '156': 32.23135432565838,
        '157': 32.231313324757,
        '158': 32.231234553076646,
        '159': 32.231153682225624,
        '160': 32.231102950433325,
        '161': 32.23110078659028,
        '162': 32.231146752044445,
        '163': 32.231223038970285,
        '164': 32.2313014601125,
        '165': 32.23135314218109,
        '166': 32.231357532320644,
        '167': 32.231307928253955,
        '168': 32.23121219866422,
        '169': 32.23108906681382,
        '170': 32.23096167826539,
        '171': 32.23085076745995,
        '172': 32.23076950424832,
        '173': 32.23072126121272,
        '174': 32.230700479185245,
        '175': 32.230695899039546,
        '176': 32.230694911518434,
        '177': 32.23068770471667,
        '178': 32.23067016056042,
        '179': 32.230644901649995,
        '180': 32.230620371684644,
        '-180': 32.230620371684644,
        '-179': 32.230608261339576,
        '-178': 32.23061993398176,
        '-177': 32.230662741414534,
        '-176': 32.23073720728641,
        '-175': 32.23083593386129,
        '-174': 32.230944716293415,
        '-173': 32.231045761993606,
        '-172': 32.23112225007816,
        '-171': 32.23116294633094,
        '-170': 32.231165432937395,
        '-169': 32.23113683926392,
        '-168': 32.231091714408315,
        '-167': 32.231047627648366,
        '-166': 32.23101987931461,
        '-165': 32.23101704153923,
        '-164': 32.231038777167875,
        '-163': 32.23107659056031,
        '-162': 32.23111713873939,
        '-161': 32.23114686124917,
        '-160': 32.23115629514748,
        '-159': 32.231142658295404,
        '-158': 32.23110999986968,
        '-157': 32.2310671345999,
        '-156': 32.23102433879621,
        '-155': 32.23099011809363,
        '-154': 32.230969171154946,
        '-153': 32.23096208827739,
        '-152': 32.230966599138554,
        '-151': 32.230979608449886,
        '-150': 32.23099903579314,
        '-149': 32.231024657120194,
        '-148': 32.23105762609558,
        '-147': 32.231098930213825,
        '-146': 32.231147490470626,
        '-145': 32.23119878680004,
        '-144': 32.2312447335796,
        '-143': 32.23127510090525,
        '-142': 32.23128022208053,
        '-141': 32.231254226353926,
        '-140': 32.23119775412774,
        '-139': 32.231119155403185,
        '-138': 32.2310335546195,
        '-137': 32.23095979486035,
        '-136': 32.23091596929091,
        '-135': 32.230914779342704,
        '-134': 32.23096012429758,
        '-133': 32.231046025677585,
        '-132': 32.23115828144408,
        '-131': 32.23127835050401,
        '-130': 32.231388206000716,
        '-129': 32.23147456557828,
        '-128': 32.23153116167465,
        '-127': 32.23155848217799,
        '-126': 32.23156140162191,
        '-125': 32.23154593443503,
        '-124': 32.231516628083725,
        '-123': 32.23147573936573,
        '-122': 32.23142445006821,
        '-121': 32.231365361695445,
        '-120': 32.23130482081681,
        '-119': 32.23125359635715,
        '-118': 32.231225104109605,
        '-117': 32.23123148158393,
        '-116': 32.23127887999959,
        '-115': 32.23136388055238,
        '-114': 32.23147269185394,
        '-113': 32.23158381341611,
        '-112': 32.23167353783406,
        '-111': 32.23172254516183,
        '-110': 32.23172137415693,
        '-109': 32.231672925300586,
        '-108': 32.23159120602074,
        '-107': 32.23149684639537,
        '-106': 32.231410984709356,
        '-105': 32.23134956112309,
        '-104': 32.23131974894088,
        '-103': 32.231319376437604,
        '-102': 32.231339125375,
        '-101': 32.23136644048642,
        '-100': 32.23138971719279,
        '-99': 32.23140149929859,
        '-98': 32.23139995457242,
        '-97': 32.23138853831888,
        '-96': 32.23137426087412,
        '-95': 32.23136522048471,
        '-94': 32.23136806569432,
        '-93': 32.23138592057495,
        '-92': 32.23141715732982,
        '-91': 32.231455283628726,
        '-90': 32.23149009209047,
        '-89': 32.231510020915856,
        '-88': 32.23150535737893,
        '-87': 32.23147153680331,
        '-86': 32.231411508697576,
        '-85': 32.231336156565376,
        '-84': 32.23126219432158,
        '-83': 32.23120777466807,
        '-82': 32.23118698513954,
        '-81': 32.2312050936576,
        '-80': 32.23125647407668,
        '-79': 32.231326426006284,
        '-78': 32.23139674914773,
        '-77': 32.23145339490667,
        '-76': 32.23149341019387,
        '-75': 32.23152823935974,
        '-74': 32.23158146622276,
        '-73': 32.23168101128186,
        '-72': 32.23184799352979,
        '-71': 32.23208607946676,
        '-70': 32.23237546784137,
        '-69': 32.23267442700422,
        '-68': 32.22774524041785,
        '-67': 32.23062663476096,
        '-66': 32.214006064914166,
        '-65': 32.23008905288083,
        '-64': 32.23233551700843,
        '-63': 32.23202438972949,
        '-62': 32.231831788764154,
        '-61': 32.23170055653467,
        '-60': 32.23210381624746,
        '-59': 32.23213576440702,
        '-58': 32.232225462250405,
        '-57': 32.23231962884918,
        '-56': 32.23236683339936,
        '-55': 32.23233550267418,
        '-54': 32.232224826244554,
        '-53': 32.23206482667464,
        '-52': 32.23190561886747,
        '-51': 32.23179964302798,
        '-50': 32.23178309496897,
        '-49': 32.23186302988414,
        '-48': 32.23201458953824,
        '-47': 32.23218922228038,
        '-46': 32.23233091374139,
        '-45': 32.232394698844324,
        '-44': 32.232361046843,
        '-43': 32.2322413052646,
        '-42': 32.23207263819368,
        '-41': 32.23190459843145,
        '-40': 32.231782295072286,
        '-39': 32.23173207493914,
        '-38': 32.231754451473044,
        '-37': 32.23182620417168,
        '-36': 32.2319102316729,
        '-35': 32.2319691188313,
        '-34': 32.23197739344156,
        '-33': 32.231928361082275,
        '-32': 32.23183374802036,
        '-31': 32.23171717164922,
        '-30': 32.23160460663928,
        '-29': 32.23151575166109,
        '-28': 32.23145935575721,
        '-27': 32.23143360045542,
        '-26': 32.231430435489756,
        '-25': 32.23144125345276,
        '-24': 32.23146104326413,
        '-23': 32.231489201467305,
        '-22': 32.23152697419849,
        '-21': 32.23157321729621,
        '-20': 32.231621022987,
        '-19': 32.23165738525449,
        '-18': 32.2316666316719,
        '-17': 32.23163648532445,
        '-16': 32.231564183855404,
        '-15': 32.23145975208657,
        '-14': 32.231344508560184,
        '-13': 32.23124481838287,
        '-12': 32.23118318351213,
        '-11': 32.23117008197316,
        '-10': 32.23119990912734,
        '-9': 32.23125288374622,
        '-8': 32.2313024225706,
        '-7': 32.23132522342057,
        '-6': 32.231310094493196,
        '-5': 32.23126197697767,
        '-4': 32.2311995122964,
        '-3': 32.23114714492469,
        '-2': 32.23112502908107,
        '-1': 32.23114094653324
    },
    '-68': {
        '0': 32.233176379476404,
        '1': 32.23317547336076,
        '2': 32.23317616852331,
        '3': 32.23318622043865,
        '4': 32.233216974156164,
        '5': 32.23327984947306,
        '6': 32.23338133052937,
        '7': 32.2335184043778,
        '8': 32.23367655935614,
        '9': 32.23383169851725,
        '10': 32.23395588057217,
        '11': 32.23402522828088,
        '12': 32.234027299922346,
        '13': 32.23396518295124,
        '14': 32.23385662629083,
        '15': 32.233728315491035,
        '16': 32.233607232044065,
        '17': 32.233512194769254,
        '18': 32.23344867657735,
        '19': 32.23340879495017,
        '20': 32.233376432950635,
        '21': 32.23333550420088,
        '22': 32.23327817958358,
        '23': 32.23320990241401,
        '24': 32.23314922011882,
        '25': 32.2331223853765,
        '26': 32.23315459542565,
        '27': 32.23326093686844,
        '28': 32.23344017030183,
        '29': 32.233673447578795,
        '30': 32.23392833363994,
        '31': 32.23416675403181,
        '32': 32.23435431971882,
        '33': 32.23446824237188,
        '34': 32.234501731132475,
        '35': 32.234464022799806,
        '36': 32.23437655139838,
        '37': 32.2342667622678,
        '38': 32.23416146853348,
        '39': 32.23408143354459,
        '40': 32.23403824133995,
        '41': 32.23403376988081,
        '42': 32.234061946961944,
        '43': 32.234112065365366,
        '44': 32.23153191001161,
        '45': 32.22735637415722,
        '46': 32.226023744711405,
        '47': 32.2242499760167,
        '48': 32.2269693623758,
        '49': 32.22662821943584,
        '50': 32.22234549975126,
        '51': 32.218228588867355,
        '52': 32.21669457900021,
        '53': 32.21573115814568,
        '54': 32.2142984446581,
        '55': 32.21859864167399,
        '56': 32.22154565518185,
        '57': 32.21965404989453,
        '58': 32.21901278747047,
        '59': 32.22185110507494,
        '60': 32.22218519647667,
        '61': 32.221343162812296,
        '62': 32.222820637230996,
        '63': 32.22370651040665,
        '64': 32.22587380906137,
        '65': 32.226512749558644,
        '66': 32.22780470707884,
        '67': 32.22993305862665,
        '68': 32.22936033906106,
        '69': 32.23052850644476,
        '70': 32.23465007145768,
        '71': 32.23447269467577,
        '72': 32.23427070914689,
        '73': 32.23408340506555,
        '74': 32.23394732021388,
        '75': 32.233887277466664,
        '76': 32.233910277963375,
        '77': 32.23400368410759,
        '78': 32.23413819330609,
        '79': 32.23427507997534,
        '80': 32.23419398484677,
        '81': 32.22882843401398,
        '82': 32.227044318486016,
        '83': 32.22511143254532,
        '84': 32.22281021796584,
        '85': 32.22126662625825,
        '86': 32.220634653626455,
        '87': 32.21926627161892,
        '88': 32.21953271479016,
        '89': 32.21910857289606,
        '90': 32.21830242195355,
        '91': 32.21761288907363,
        '92': 32.21718767648012,
        '93': 32.21661531735653,
        '94': 32.216342410776754,
        '95': 32.21576340367876,
        '96': 32.21462161569187,
        '97': 32.213571947871124,
        '98': 32.21485029720561,
        '99': 32.21699223544445,
        '100': 32.21624185586469,
        '101': 32.21561992184691,
        '102': 32.21551081645161,
        '103': 32.21403031740017,
        '104': 32.21323906866241,
        '105': 32.21279128732806,
        '106': 32.213111642909,
        '107': 32.214205871508845,
        '108': 32.2154070219946,
        '109': 32.21791153668103,
        '110': 32.217793636655024,
        '111': 32.21843111969051,
        '112': 32.22022798913965,
        '113': 32.221959726085664,
        '114': 32.221987700928345,
        '115': 32.22223109249813,
        '116': 32.22210777470206,
        '117': 32.222287533077655,
        '118': 32.22303011985908,
        '119': 32.22281701217224,
        '120': 32.220971431081374,
        '121': 32.219703725170625,
        '122': 32.218449591170355,
        '123': 32.217710468823604,
        '124': 32.216684094464505,
        '125': 32.216282394054794,
        '126': 32.21709968058064,
        '127': 32.21702909322745,
        '128': 32.217282181915465,
        '129': 32.21716976978768,
        '130': 32.21640829204548,
        '131': 32.21648277073559,
        '132': 32.214840432979535,
        '133': 32.21352072469932,
        '134': 32.21350877612957,
        '135': 32.2135886640084,
        '136': 32.21403529551161,
        '137': 32.21385641412489,
        '138': 32.214226047504624,
        '139': 32.215294209260314,
        '140': 32.21697211680271,
        '141': 32.218831357040514,
        '142': 32.22166003538518,
        '143': 32.22723674618275,
        '144': 32.230143807594274,
        '145': 32.22593996424216,
        '146': 32.22568102414444,
        '147': 32.232262916186706,
        '148': 32.232431677715795,
        '149': 32.23218315952102,
        '150': 32.23206711598079,
        '151': 32.232101083619405,
        '152': 32.232270612700056,
        '153': 32.232533045477645,
        '154': 32.23282912766395,
        '155': 32.23309870542869,
        '156': 32.23329566371571,
        '157': 32.233397783916935,
        '158': 32.23340906402111,
        '159': 32.23335454751135,
        '160': 32.23326998621423,
        '161': 32.23318999945069,
        '162': 32.23313844230522,
        '163': 32.23312356989652,
        '164': 32.23313876880924,
        '165': 32.23316778442138,
        '166': 32.23319210244995,
        '167': 32.23319778544196,
        '168': 32.23317961988522,
        '169': 32.23314159905339,
        '170': 32.233094092898114,
        '171': 32.23304909377994,
        '172': 32.23301538472775,
        '173': 32.232995288865474,
        '174': 32.232983969189455,
        '175': 32.232971334340604,
        '176': 32.232945772837084,
        '177': 32.23289842254481,
        '178': 32.232826597358425,
        '179': 32.232735318374544,
        '180': 32.23263650439491,
        '-180': 32.23263650439491,
        '-179': 32.23254607895398,
        '-178': 32.2324798527852,
        '-177': 32.23244938330235,
        '-176': 32.2324590084767,
        '-175': 32.23250490389702,
        '-174': 32.23257641390851,
        '-173': 32.232659229390684,
        '-172': 32.23273942734363,
        '-171': 32.232807127534706,
        '-170': 32.23285865224243,
        '-169': 32.23289656765505,
        '-168': 32.232927687368914,
        '-167': 32.232959797147196,
        '-166': 32.232998280300365,
        '-165': 32.23304383220018,
        '-164': 32.233092041768884,
        '-163': 32.2331349321617,
        '-162': 32.2331638417309,
        '-161': 32.23317255184845,
        '-160': 32.23315950521481,
        '-159': 32.23312832657174,
        '-158': 32.23308651326693,
        '-157': 32.2330428535545,
        '-156': 32.233004592742496,
        '-155': 32.23297542874537,
        '-154': 32.23295506263226,
        '-153': 32.23294040001692,
        '-152': 32.23292784204189,
        '-151': 32.23291567375318,
        '-150': 32.23290551709322,
        '-149': 32.23290218031293,
        '-148': 32.23291186762031,
        '-147': 32.232939375367664,
        '-146': 32.23298534559232,
        '-145': 32.23304470418146,
        '-144': 32.233107047891025,
        '-143': 32.23315908008234,
        '-142': 32.233188456398956,
        '-141': 32.23318784709847,
        '-140': 32.23315785412831,
        '-139': 32.23310771375229,
        '-138': 32.23305338998304,
        '-137': 32.23301350728745,
        '-136': 32.2330043032406,
        '-135': 32.2330351482988,
        '-134': 32.23310603954364,
        '-133': 32.233207855896424,
        '-132': 32.23332526066742,
        '-131': 32.23344125903804,
        '-130': 32.233541873854236,
        '-129': 32.23361939556994,
        '-128': 32.23367320535482,
        '-127': 32.23370807487161,
        '-126': 32.23373078080771,
        '-125': 32.23374648370863,
        '-124': 32.23375636921938,
        '-123': 32.233757507580464,
        '-122': 32.23374495773779,
        '-121': 32.23371518880259,
        '-120': 32.23366929700882,
        '-119': 32.2336145110109,
        '-118': 32.23356311513731,
        '-117': 32.233528942693766,
        '-116': 32.23352260244291,
        '-115': 32.233547197956355,
        '-114': 32.23359623285366,
        '-113': 32.23365467123074,
        '-112': 32.23370300335892,
        '-111': 32.23372306813465,
        '-110': 32.23370371563591,
        '-109': 32.23364440287379,
        '-108': 32.23355549812526,
        '-107': 32.233455174650764,
        '-106': 32.2333639089184,
        '-105': 32.233298370601126,
        '-104': 32.23326665215376,
        '-103': 32.23326630592421,
        '-102': 32.23328572373294,
        '-101': 32.233308331503245,
        '-100': 32.23331822161835,
        '-99': 32.233305455988564,
        '-98': 32.23326942949922,
        '-97': 32.23321930387703,
        '-96': 32.23317139948055,
        '-95': 32.23314430966932,
        '-94': 32.233153144519065,
        '-93': 32.2332045599355,
        '-92': 32.23329403101198,
        '-91': 32.23340623786789,
        '-90': 32.233518592500175,
        '-89': 32.23360705328173,
        '-88': 32.23365267939514,
        '-87': 32.233647073547345,
        '-86': 32.233595069119836,
        '-85': 32.23351372991764,
        '-84': 32.23342778813633,
        '-83': 32.23336275924897,
        '-82': 32.233337790041624,
        '-81': 32.233360513612745,
        '-80': 32.233425660372234,
        '-79': 32.23351800483623,
        '-78': 32.23361875460769,
        '-77': 32.2337132029511,
        '-76': 32.23379685241254,
        '-75': 32.23387756548046,
        '-74': 32.2339725781115,
        '-73': 32.23410104429702,
        '-72': 32.2342745421438,
        '-71': 32.23448900497506,
        '-70': 32.23472139182023,
        '-69': 32.23493306459852,
        '-68': 32.23507938431184,
        '-67': 32.22686235835165,
        '-66': 32.222891918016344,
        '-65': 32.234074542631205,
        '-64': 32.234085916465666,
        '-63': 32.23382400362117,
        '-62': 32.23366397532095,
        '-61': 32.23359220281661,
        '-60': 32.23403185246797,
        '-59': 32.23413794101002,
        '-58': 32.23429941810901,
        '-57': 32.23445389743534,
        '-56': 32.23454114299631,
        '-55': 32.23452107605731,
        '-54': 32.234385537080776,
        '-53': 32.23416021225801,
        '-52': 32.23389629265358,
        '-51': 32.23365470280766,
        '-50': 32.23348803173412,
        '-49': 32.233425873546175,
        '-48': 32.233467977349946,
        '-47': 32.23358689806483,
        '-46': 32.23373868225575,
        '-45': 32.233877597504346,
        '-44': 32.233969850952235,
        '-43': 32.234001937617975,
        '-42': 32.23398138626538,
        '-41': 32.23393042144028,
        '-40': 32.233875446838454,
        '-39': 32.23383646537906,
        '-38': 32.233820229231334,
        '-37': 32.23381926618899,
        '-36': 32.23381662886966,
        '-35': 32.23379413058419,
        '-34': 32.23374070740216,
        '-33': 32.23365772637314,
        '-32': 32.233559391722544,
        '-31': 32.233468317613685,
        '-30': 32.23340809339104,
        '-29': 32.23339563065939,
        '-28': 32.23343595620713,
        '-27': 32.233521033532774,
        '-26': 32.23363263969547,
        '-25': 32.23374791446222,
        '-24': 32.233845447103434,
        '-23': 32.2339098856029,
        '-22': 32.23393389894018,
        '-21': 32.23391748107506,
        '-20': 32.233865560090216,
        '-19': 32.2337852991581,
        '-18': 32.23368424650893,
        '-17': 32.23356979555378,
        '-16': 32.233449624561146,
        '-15': 32.233332277631646,
        '-14': 32.233227044161154,
        '-13': 32.23314276033783,
        '-12': 32.233085836416834,
        '-11': 32.23305835352515,
        '-10': 32.23305719237977,
        '-9': 32.23307477909635,
        '-8': 32.23310132920083,
        '-7': 32.23312777452253,
        '-6': 32.23314821126492,
        '-5': 32.23316089656679,
        '-4': 32.23316747847995,
        '-3': 32.23317097318657,
        '-2': 32.23317360799885,
        '-1': 32.23317572091873
    },
    '-69': {
        '0': 32.23581957029469,
        '1': 32.23574659268016,
        '2': 32.23566734002595,
        '3': 32.23560119270669,
        '4': 32.2355680601053,
        '5': 32.23558291408137,
        '6': 32.23565099144068,
        '7': 32.23576528450758,
        '8': 32.235907379189506,
        '9': 32.236051702613274,
        '10': 32.23617211589221,
        '11': 32.23624892474656,
        '12': 32.23627411628752,
        '13': 32.23625310688714,
        '14': 32.23620238377726,
        '15': 32.236143785503835,
        '16': 32.23609731955959,
        '17': 32.23607493850024,
        '18': 32.236077380458376,
        '19': 32.23609511701482,
        '20': 32.23611300668766,
        '21': 32.23611693783097,
        '22': 32.23610003198732,
        '23': 32.23606612522534,
        '24': 32.236029200102365,
        '25': 32.23600886584331,
        '26': 32.23602337700119,
        '27': 32.23608255729505,
        '28': 32.23618306040888,
        '29': 32.23630764544475,
        '30': 32.236428843803104,
        '31': 32.23628308729329,
        '32': 32.23654357203873,
        '33': 32.23205389764202,
        '34': 32.23557382764441,
        '35': 32.23597579975857,
        '36': 32.23603459483002,
        '37': 32.23586714237767,
        '38': 32.23574867966207,
        '39': 32.23570123117367,
        '40': 32.23069670310871,
        '41': 32.22521758033846,
        '42': 32.222852857104144,
        '43': 32.221534464272565,
        '44': 32.219456972107345,
        '45': 32.218733687284164,
        '46': 32.217422751120445,
        '47': 32.21810316962309,
        '48': 32.21949996842867,
        '49': 32.219594652266615,
        '50': 32.21873505497876,
        '51': 32.2154742205995,
        '52': 32.213463183079924,
        '53': 32.21172334613425,
        '54': 32.21162614021093,
        '55': 32.21232735580573,
        '56': 32.21213639842036,
        '57': 32.21209042565657,
        '58': 32.21181010108376,
        '59': 32.21382653316837,
        '60': 32.21555721813463,
        '61': 32.216480367086255,
        '62': 32.21706742606693,
        '63': 32.21820309602466,
        '64': 32.21970839737766,
        '65': 32.220748649025914,
        '66': 32.222451448041056,
        '67': 32.22450492487917,
        '68': 32.227978230159195,
        '69': 32.23370608994759,
        '70': 32.23542797837846,
        '71': 32.2352171522007,
        '72': 32.23501669172756,
        '73': 32.23490884687088,
        '74': 32.2355328768143,
        '75': 32.23567271224822,
        '76': 32.23591557986441,
        '77': 32.236211343499846,
        '78': 32.235052610314874,
        '79': 32.22704899245559,
        '80': 32.22334413792675,
        '81': 32.22069830745334,
        '82': 32.21929023381847,
        '83': 32.21825164780092,
        '84': 32.2174681152806,
        '85': 32.21638155583414,
        '86': 32.21566248534296,
        '87': 32.215489821954044,
        '88': 32.215828525221184,
        '89': 32.21544039400368,
        '90': 32.214967487463575,
        '91': 32.21459325567923,
        '92': 32.21442321361108,
        '93': 32.213779810347674,
        '94': 32.21279936722243,
        '95': 32.21232899238255,
        '96': 32.2116701044699,
        '97': 32.21186149267662,
        '98': 32.21219387099059,
        '99': 32.213512919734725,
        '100': 32.21425115463848,
        '101': 32.21370251047134,
        '102': 32.21288957671365,
        '103': 32.212101908640435,
        '104': 32.21197015740319,
        '105': 32.21236287555323,
        '106': 32.213045720112355,
        '107': 32.2134250083212,
        '108': 32.21332855685466,
        '109': 32.21363361877162,
        '110': 32.214015482791595,
        '111': 32.21546314067608,
        '112': 32.21664241375048,
        '113': 32.21693560935216,
        '114': 32.21697452720802,
        '115': 32.21704717550333,
        '116': 32.21708739839827,
        '117': 32.21692252581448,
        '118': 32.217255539773284,
        '119': 32.216917858919984,
        '120': 32.21651308853165,
        '121': 32.21611363577003,
        '122': 32.215277402493165,
        '123': 32.21458557368204,
        '124': 32.21411703691945,
        '125': 32.21352693828074,
        '126': 32.21352497464965,
        '127': 32.21343352617064,
        '128': 32.21366459564423,
        '129': 32.213786607529755,
        '130': 32.2131606936933,
        '131': 32.21301529318753,
        '132': 32.21197229075699,
        '133': 32.21151898244118,
        '134': 32.21065432960715,
        '135': 32.211152338635806,
        '136': 32.21185829049968,
        '137': 32.21231579445302,
        '138': 32.21231722695233,
        '139': 32.21316013072351,
        '140': 32.2145657014736,
        '141': 32.21614110686725,
        '142': 32.21806994045719,
        '143': 32.22070563127111,
        '144': 32.222375678164084,
        '145': 32.22276378556145,
        '146': 32.22450431818705,
        '147': 32.22446911442477,
        '148': 32.224514296027756,
        '149': 32.223413848828656,
        '150': 32.22441320631765,
        '151': 32.22758875691775,
        '152': 32.228520616986316,
        '153': 32.2289477933425,
        '154': 32.23025541088804,
        '155': 32.23332858034331,
        '156': 32.23518625529155,
        '157': 32.23532400583911,
        '158': 32.23535086643487,
        '159': 32.23528280786214,
        '160': 32.235154286666244,
        '161': 32.235007090774914,
        '162': 32.234879032442194,
        '163': 32.23479550361486,
        '164': 32.23476568345938,
        '165': 32.2347836715921,
        '166': 32.23483339558242,
        '167': 32.23489524789014,
        '168': 32.23495223973517,
        '169': 32.23499396747162,
        '170': 32.23501762399413,
        '171': 32.23502631185015,
        '172': 32.235025706268175,
        '173': 32.23502047049429,
        '174': 32.23501169781689,
        '175': 32.23499615005682,
        '176': 32.234967384550195,
        '177': 32.23491823831421,
        '178': 32.23484375070007,
        '179': 32.234743543011334,
        '180': 32.234622917163826,
        '-180': 32.234622917163826,
        '-179': 32.234492378945035,
        '-178': 32.23436578083901,
        '-177': 32.2342576630927,
        '-176': 32.23418054459634,
        '-175': 32.234142845890126,
        '-174': 32.23414786154119,
        '-173': 32.234193842722476,
        '-172': 32.23427492877189,
        '-171': 32.23438248302105,
        '-170': 32.23450639111119,
        '-169': 32.23463604582956,
        '-168': 32.23476098691608,
        '-167': 32.23487137554722,
        '-166': 32.23495856646201,
        '-165': 32.235015956975865,
        '-164': 32.235040076239414,
        '-163': 32.23503162724039,
        '-162': 32.234996029949926,
        '-161': 32.23494303242135,
        '-160': 32.23488518395633,
        '-159': 32.234835339754184,
        '-158': 32.23480375810742,
        '-157': 32.23479560362305,
        '-156': 32.23480966397631,
        '-155': 32.23483879134721,
        '-154': 32.234872068474004,
        '-153': 32.23489813629805,
        '-152': 32.234908701412664,
        '-151': 32.2349011253263,
        '-150': 32.23487924456638,
        '-149': 32.23485211669712,
        '-148': 32.23483106021367,
        '-147': 32.23482593181257,
        '-146': 32.23484186286932,
        '-145': 32.23487755287368,
        '-144': 32.23492572011759,
        '-143': 32.234975596598474,
        '-142': 32.235016660614605,
        '-141': 32.235042362403576,
        '-140': 32.23505257049298,
        '-139': 32.23505386911761,
        '-138': 32.23505754468057,
        '-137': 32.235075879620354,
        '-136': 32.23511796197105,
        '-135': 32.2351864125877,
        '-134': 32.23527615311787,
        '-133': 32.23537566979335,
        '-132': 32.23547039068833,
        '-131': 32.235547071434546,
        '-130': 32.23559772828358,
        '-129': 32.23562179835991,
        '-128': 32.235625805036115,
        '-127': 32.2356206607734,
        '-126': 32.23561755557783,
        '-125': 32.23562386964315,
        '-124': 32.23564053749722,
        '-123': 32.235661777711016,
        '-122': 32.23567726599174,
        '-121': 32.23567596668228,
        '-120': 32.23565025600663,
        '-119': 32.23559887706278,
        '-118': 32.23552769264543,
        '-117': 32.23544799116009,
        '-116': 32.235372966961584,
        '-115': 32.235313628202704,
        '-114': 32.23527555983352,
        '-113': 32.235257632186965,
        '-112': 32.23525302777288,
        '-111': 32.23525212643478,
        '-110': 32.23524614337514,
        '-109': 32.235230182782395,
        '-108': 32.235204624711066,
        '-107': 32.23517440143411,
        '-106': 32.23514650487505,
        '-105': 32.235126716195225,
        '-104': 32.23511683401321,
        '-103': 32.23511350302279,
        '-102': 32.235109174941066,
        '-101': 32.235094967799235,
        '-100': 32.23506448991061,
        '-99': 32.235017298483655,
        '-98': 32.23496070527606,
        '-97': 32.23490911617339,
        '-96': 32.23488085435119,
        '-95': 32.23489323328349,
        '-94': 32.23495726262582,
        '-93': 32.23507358931072,
        '-92': 32.23523101482991,
        '-91': 32.235408244972504,
        '-90': 32.2355786052843,
        '-89': 32.23571655800001,
        '-88': 32.235804253810564,
        '-87': 32.23583624189408,
        '-86': 32.23582090699268,
        '-85': 32.235778103383,
        '-84': 32.235733567637894,
        '-83': 32.23571168733199,
        '-82': 32.23572876426595,
        '-81': 32.23578883284175,
        '-80': 32.23588335934186,
        '-79': 32.2359949499295,
        '-78': 32.23610389496903,
        '-77': 32.236195396723836,
        '-76': 32.236265012940535,
        '-75': 32.23632034761644,
        '-74': 32.23637821519791,
        '-73': 32.2364580337411,
        '-72': 32.23535905795842,
        '-71': 32.22758747411353,
        '-70': 32.23690005625931,
        '-69': 32.23706704200293,
        '-68': 32.23719101050337,
        '-67': 32.237026794129385,
        '-66': 32.22265795144396,
        '-65': 32.220073114274065,
        '-64': 32.23172304318202,
        '-63': 32.236226601641214,
        '-62': 32.236035630779206,
        '-61': 32.2360139342175,
        '-60': 32.2360696432815,
        '-59': 32.236017521758434,
        '-58': 32.23600550122568,
        '-57': 32.23599692007894,
        '-56': 32.23595454716621,
        '-55': 32.235852812728744,
        '-54': 32.23568637550665,
        '-53': 32.23547240402574,
        '-52': 32.23524596022558,
        '-51': 32.23505004355237,
        '-50': 32.23492347557896,
        '-49': 32.234890364587294,
        '-48': 32.234954234790166,
        '-47': 32.23509829231544,
        '-46': 32.23529129844928,
        '-45': 32.235496808732236,
        '-44': 32.23568266671197,
        '-43': 32.23582785384406,
        '-42': 32.235924950710505,
        '-41': 32.23597810274419,
        '-40': 32.23599790834809,
        '-39': 32.23599553601211,
        '-38': 32.23597836242685,
        '-37': 32.23594857613163,
        '-36': 32.23590487758231,
        '-35': 32.23584614280863,
        '-34': 32.23577517448859,
        '-33': 32.23570069574172,
        '-32': 32.23563650289734,
        '-31': 32.23559786869625,
        '-30': 32.23559641920365,
        '-29': 32.235635370269605,
        '-28': 32.23570696372421,
        '-27': 32.235793217992146,
        '-26': 32.235869977274064,
        '-25': 32.23591311914795,
        '-24': 32.23590505562312,
        '-23': 32.235839572914784,
        '-22': 32.235723604139814,
        '-21': 32.23557550220498,
        '-20': 32.23542043471102,
        '-19': 32.2352843157042,
        '-18': 32.23518799366457,
        '-17': 32.235143185195646,
        '-16': 32.235151009533055,
        '-15': 32.23520318118359,
        '-14': 32.235285213664035,
        '-13': 32.23538056297472,
        '-12': 32.23547456389631,
        '-11': 32.23555723898636,
        '-10': 32.23562446951163,
        '-9': 32.235677473081495,
        '-8': 32.23572092682907,
        '-7': 32.23576034735307,
        '-6': 32.235799468447475,
        '-5': 32.23583834281055,
        '-4': 32.23587273565743,
        '-3': 32.235895082710634,
        '-2': 32.23589688128613,
        '-1': 32.23587194234997
    },
    '-70': {
        '0': 32.23755815021815,
        '1': 32.237922070876714,
        '2': 32.237366848473414,
        '3': 32.23781237913494,
        '4': 32.23771849506384,
        '5': 32.2376655195253,
        '6': 32.23765869329837,
        '7': 32.23737947617672,
        '8': 32.237592535780706,
        '9': 32.237823227683634,
        '10': 32.237566334117695,
        '11': 32.237910208941855,
        '12': 32.237594934149676,
        '13': 32.23747490151035,
        '14': 32.23739055610188,
        '15': 32.237135337306995,
        '16': 32.236915764952684,
        '17': 32.237043468326554,
        '18': 32.23704274851527,
        '19': 32.23729003131502,
        '20': 32.237317418977426,
        '21': 32.237355190688604,
        '22': 32.237891255212965,
        '23': 32.23788258001192,
        '24': 32.237862216228656,
        '25': 32.23784188773001,
        '26': 32.23783383122776,
        '27': 32.23784605698651,
        '28': 32.23719079661407,
        '29': 32.23734705763506,
        '30': 32.23735852534929,
        '31': 32.23752108967107,
        '32': 32.237586970935624,
        '33': 32.23451378191485,
        '34': 32.22949077465238,
        '35': 32.228225542642846,
        '36': 32.22692773774674,
        '37': 32.229099389142334,
        '38': 32.23098482134596,
        '39': 32.23152013305551,
        '40': 32.228175333887016,
        '41': 32.22406674424124,
        '42': 32.22089581663373,
        '43': 32.2187215247241,
        '44': 32.21705604045177,
        '45': 32.21550532712674,
        '46': 32.21469232682547,
        '47': 32.21418599250619,
        '48': 32.215260711303024,
        '49': 32.21538347340231,
        '50': 32.21496593494316,
        '51': 32.21395465769025,
        '52': 32.212719419117896,
        '53': 32.21194156033634,
        '54': 32.211017524040614,
        '55': 32.21038555012469,
        '56': 32.21033364922306,
        '57': 32.210474127162115,
        '58': 32.211015752891086,
        '59': 32.21178061752384,
        '60': 32.21233216129694,
        '61': 32.21274080811415,
        '62': 32.21409949318992,
        '63': 32.215835516055904,
        '64': 32.21818246888019,
        '65': 32.223564252037164,
        '66': 32.22709818923046,
        '67': 32.230923020087744,
        '68': 32.23702658805764,
        '69': 32.236945159509396,
        '70': 32.236631002007556,
        '71': 32.23636055505115,
        '72': 32.23626906803808,
        '73': 32.23522782014496,
        '74': 32.231077701966605,
        '75': 32.22967886005452,
        '76': 32.227387998942355,
        '77': 32.22558772734025,
        '78': 32.22356664039719,
        '79': 32.22191109822819,
        '80': 32.220372818117404,
        '81': 32.218947594868915,
        '82': 32.217470107620976,
        '83': 32.21629455482506,
        '84': 32.21515868178296,
        '85': 32.214493883779475,
        '86': 32.21395516593851,
        '87': 32.213706192862965,
        '88': 32.213395758759994,
        '89': 32.21331528980633,
        '90': 32.21272160150955,
        '91': 32.21250105977892,
        '92': 32.211603351183925,
        '93': 32.21102650952144,
        '94': 32.21062341964381,
        '95': 32.210160117858564,
        '96': 32.21023532308893,
        '97': 32.21079119596234,
        '98': 32.21133373610484,
        '99': 32.21144856546658,
        '100': 32.21173622339128,
        '101': 32.21175630393994,
        '102': 32.211692713752434,
        '103': 32.21140641006477,
        '104': 32.21146664731291,
        '105': 32.211177675221315,
        '106': 32.21091368053887,
        '107': 32.21129581333104,
        '108': 32.21193090259678,
        '109': 32.21171530956205,
        '110': 32.21230174653326,
        '111': 32.213004020860126,
        '112': 32.213989913656405,
        '113': 32.21431569108005,
        '114': 32.21483329519068,
        '115': 32.21513555475574,
        '116': 32.215420415986614,
        '117': 32.215158066263854,
        '118': 32.21503020952744,
        '119': 32.2147322892586,
        '120': 32.214243063788565,
        '121': 32.21388210821233,
        '122': 32.21325750886878,
        '123': 32.21308642305329,
        '124': 32.21233668733749,
        '125': 32.211999298734554,
        '126': 32.211733607668656,
        '127': 32.21165588980719,
        '128': 32.2120241872372,
        '129': 32.21190221747833,
        '130': 32.21178577892517,
        '131': 32.21138984358297,
        '132': 32.21137860699599,
        '133': 32.21106484645486,
        '134': 32.21087794307122,
        '135': 32.21208141080151,
        '136': 32.21212528169201,
        '137': 32.21211578585407,
        '138': 32.21208217022024,
        '139': 32.212455963437705,
        '140': 32.21357333662017,
        '141': 32.21490788480272,
        '142': 32.21686460109982,
        '143': 32.218554436997316,
        '144': 32.21983676373448,
        '145': 32.22086379932465,
        '146': 32.22108356062103,
        '147': 32.2207055044303,
        '148': 32.221561245422905,
        '149': 32.22181491542938,
        '150': 32.2224117328239,
        '151': 32.222548310517666,
        '152': 32.22410352811435,
        '153': 32.225020913073315,
        '154': 32.22352766528899,
        '155': 32.22247438976976,
        '156': 32.222828640845044,
        '157': 32.223521132914506,
        '158': 32.2229371116187,
        '159': 32.22118266080149,
        '160': 32.233170477915095,
        '161': 32.23667142188435,
        '162': 32.23656349020238,
        '163': 32.236510590951006,
        '164': 32.236519907225315,
        '165': 32.23658290205308,
        '166': 32.236679307722916,
        '167': 32.23678359363962,
        '168': 32.23687194005519,
        '169': 32.2369278421343,
        '170': 32.23694505484263,
        '171': 32.23692744863608,
        '172': 32.23688621542234,
        '173': 32.236835510636745,
        '174': 32.23678789704754,
        '175': 32.23675084196562,
        '176': 32.236725092593716,
        '177': 32.23670516641385,
        '178': 32.23668161782936,
        '179': 32.23664432399652,
        '180': 32.236585855415996,
        '-180': 32.236585855415996,
        '-179': 32.23650407009214,
        '-178': 32.23640333937904,
        '-177': 32.236294185374746,
        '-176': 32.23619148214679,
        '-175': 32.23611166357222,
        '-174': 32.23606954227911,
        '-173': 32.23607536912753,
        '-172': 32.23613267341558,
        '-171': 32.2362372587025,
        '-170': 32.236377526610255,
        '-169': 32.236536091096795,
        '-168': 32.236692447854956,
        '-167': 32.23682629162393,
        '-166': 32.23692094439288,
        '-165': 32.23696629252316,
        '-164': 32.23696065774453,
        '-163': 32.23691116691379,
        '-162': 32.23683243928688,
        '-161': 32.23674374668982,
        '-160': 32.23666515559419,
        '-159': 32.23661344139074,
        '-158': 32.2365986851335,
        '-157': 32.23662236318174,
        '-156': 32.236677418320625,
        '-155': 32.23675032207639,
        '-154': 32.23682462333478,
        '-153': 32.23688507368539,
        '-152': 32.23692125099835,
        '-151': 32.23692973331124,
        '-150': 32.236914281165724,
        '-149': 32.23688405764757,
        '-148': 32.23685048436944,
        '-147': 32.23682372469,
        '-146': 32.23680987738716,
        '-145': 32.23680972052214,
        '-144': 32.23681933768979,
        '-143': 32.23683234495812,
        '-142': 32.23684291226903,
        '-141': 32.23684850908018,
        '-140': 32.23685139075887,
        '-139': 32.236858257415236,
        '-138': 32.236878130255015,
        '-137': 32.236919103907624,
        '-136': 32.23698504357645,
        '-135': 32.237073362098506,
        '-134': 32.23717470283986,
        '-133': 32.237274761607935,
        '-132': 32.237357792023225,
        '-131': 32.23741077756711,
        '-130': 32.23742700603557,
        '-129': 32.23740793511899,
        '-128': 32.2373627484033,
        '-127': 32.23730570917707,
        '-126': 32.23725209920098,
        '-125': 32.23721396316289,
        '-124': 32.237196928629785,
        '-123': 32.23719902289569,
        '-122': 32.23721177837017,
        '-121': 32.23722321298497,
        '-120': 32.23722171780722,
        '-119': 32.23719965191292,
        '-118': 32.23715559548987,
        '-117': 32.237094680034375,
        '-116': 32.23702703313716,
        '-115': 32.236964937997875,
        '-114': 32.23691963817823,
        '-113': 32.23689872780746,
        '-112': 32.23690478073515,
        '-111': 32.236935410713,
        '-110': 32.23698448972827,
        '-109': 32.23704394106023,
        '-108': 32.237105457855144,
        '-107': 32.237161672510624,
        '-106': 32.23720662560222,
        '-105': 32.2372357160224,
        '-104': 32.23724552279493,
        '-103': 32.23723389516526,
        '-102': 32.23720051501144,
        '-101': 32.23714782740922,
        '-100': 32.237081941429814,
        '-99': 32.23701295257967,
        '-98': 32.23695420990006,
        '-97': 32.23692034359163,
        '-96': 32.23692429855909,
        '-95': 32.23697404284289,
        '-94': 32.23706988292334,
        '-93': 32.23720330602429,
        '-92': 32.237357950423075,
        '-91': 32.23751274526658,
        '-90': 32.2376466139476,
        '-89': 32.23774359572379,
        '-88': 32.237796989803165,
        '-87': 32.23781127200131,
        '-86': 32.2378010705111,
        '-85': 32.23778728728889,
        '-84': 32.23779129536865,
        '-83': 32.23782877727091,
        '-82': 32.237904984888466,
        '-81': 32.238012897389545,
        '-80': 32.2381349833517,
        '-79': 32.23824823741722,
        '-78': 32.23833115823204,
        '-77': 32.23837067203017,
        '-76': 32.23836690831217,
        '-75': 32.23514620199403,
        '-74': 32.23811605342663,
        '-73': 32.238197537893804,
        '-72': 32.238229964654,
        '-71': 32.23794372602139,
        '-70': 32.235746655862485,
        '-69': 32.238823462399644,
        '-68': 32.23365798095721,
        '-67': 32.22700143493216,
        '-66': 32.22218898600277,
        '-65': 32.21749659454971,
        '-64': 32.21939257053533,
        '-63': 32.228958180640134,
        '-62': 32.23861507906474,
        '-61': 32.23859174726898,
        '-60': 32.23829385193699,
        '-59': 32.23800902221801,
        '-58': 32.23775229513256,
        '-57': 32.23744891535967,
        '-56': 32.237341366932974,
        '-55': 32.237184480368,
        '-54': 32.23705804720889,
        '-53': 32.236964319427074,
        '-52': 32.236908538388384,
        '-51': 32.236896570532615,
        '-50': 32.23693161754529,
        '-49': 32.23701139255505,
        '-48': 32.237126942873815,
        '-47': 32.23726365962465,
        '-46': 32.237404194094744,
        '-45': 32.23753230514754,
        '-44': 32.23763634147687,
        '-43': 32.23771122256317,
        '-42': 32.23775834997235,
        '-41': 32.237783635958756,
        '-40': 32.23779449587555,
        '-39': 32.23779697348238,
        '-38': 32.23779404274426,
        '-37': 32.237785613897096,
        '-36': 32.237770068209656,
        '-35': 32.23774652577351,
        '-34': 32.23771675117435,
        '-33': 32.23768573898747,
        '-32': 32.23766054663147,
        '-31': 32.23764766202453,
        '-30': 32.237649838444455,
        '-29': 32.237663652636314,
        '-28': 32.23767891057139,
        '-27': 32.23768046588811,
        '-26': 32.23765220957645,
        '-25': 32.237582207261966,
        '-24': 32.237467471021084,
        '-23': 32.237316830235464,
        '-22': 32.23715083261796,
        '-21': 32.23699842623513,
        '-20': 32.23689109813389,
        '-19': 32.236855895611434,
        '-18': 32.23690910891206,
        '-17': 32.23705224853082,
        '-16': 32.23727135432246,
        '-15': 32.237539803457025,
        '-14': 32.23782388694131,
        '-13': 32.23808974336804,
        '-12': 32.238309946019164,
        '-11': 32.23846819464544,
        '-10': 32.23856110511666,
        '-9': 32.238596862275905,
        '-8': 32.23859129824594,
        '-7': 32.23856257899451,
        '-6': 32.23852597706797,
        '-5': 32.238490113568254,
        '-4': 32.238455600327825,
        '-3': 32.23841632470513,
        '-2': 32.23836287518095,
        '-1': 32.23802379162729
    },
    '-71': {
        '0': 32.23914313518643,
        '1': 32.23936540217797,
        '2': 32.2390990212191,
        '3': 32.236247232212484,
        '4': 32.234206196545756,
        '5': 32.23399189007371,
        '6': 32.23365326641753,
        '7': 32.23445914301381,
        '8': 32.2333863885504,
        '9': 32.23210098063615,
        '10': 32.231097112688474,
        '11': 32.230367822219044,
        '12': 32.229326396752235,
        '13': 32.23375098511204,
        '14': 32.23289777302938,
        '15': 32.2325327545689,
        '16': 32.23271869899778,
        '17': 32.23245272310232,
        '18': 32.23212013553757,
        '19': 32.232999278674185,
        '20': 32.234606271708344,
        '21': 32.23445511236572,
        '22': 32.23423592918773,
        '23': 32.234764783594635,
        '24': 32.2351355814385,
        '25': 32.236721239831546,
        '26': 32.23840126045597,
        '27': 32.23537595436551,
        '28': 32.23453485783946,
        '29': 32.232817362227486,
        '30': 32.23045119501079,
        '31': 32.22904353043334,
        '32': 32.2277131847916,
        '33': 32.22613502933158,
        '34': 32.225240106277845,
        '35': 32.22416494928455,
        '36': 32.22299085768811,
        '37': 32.22183342380335,
        '38': 32.22219514000962,
        '39': 32.22333504855672,
        '40': 32.222175745883675,
        '41': 32.22137863309307,
        '42': 32.21946827031976,
        '43': 32.21895946816682,
        '44': 32.216755863983835,
        '45': 32.21585556834763,
        '46': 32.2150117220859,
        '47': 32.214120190572054,
        '48': 32.21347165704498,
        '49': 32.213084356449144,
        '50': 32.21253219278432,
        '51': 32.21217832066884,
        '52': 32.211295545425095,
        '53': 32.211160168086025,
        '54': 32.21076659281027,
        '55': 32.21052669891309,
        '56': 32.21054030293132,
        '57': 32.21113895166502,
        '58': 32.21218602715793,
        '59': 32.213478189636454,
        '60': 32.21462187610589,
        '61': 32.215750581592026,
        '62': 32.21710236225401,
        '63': 32.21911507698225,
        '64': 32.221309060619305,
        '65': 32.22314302638771,
        '66': 32.22556822515,
        '67': 32.22921014656444,
        '68': 32.23595609502957,
        '69': 32.23873809814422,
        '70': 32.23804339457828,
        '71': 32.237930978524105,
        '72': 32.23377217058387,
        '73': 32.22887430133003,
        '74': 32.22614987533225,
        '75': 32.22403135474887,
        '76': 32.22241669951719,
        '77': 32.22095259049674,
        '78': 32.21992706745558,
        '79': 32.218744019456445,
        '80': 32.21719633238366,
        '81': 32.21595576701382,
        '82': 32.2148392265179,
        '83': 32.2141772246606,
        '84': 32.21332575210511,
        '85': 32.21259641411725,
        '86': 32.2122646018247,
        '87': 32.212088924445666,
        '88': 32.2118061512132,
        '89': 32.21139874593478,
        '90': 32.21106340853406,
        '91': 32.21089809161105,
        '92': 32.21045609624307,
        '93': 32.21009570936625,
        '94': 32.20974742519921,
        '95': 32.20955620505219,
        '96': 32.20996421219373,
        '97': 32.21029773202418,
        '98': 32.210396132218676,
        '99': 32.210456239612896,
        '100': 32.210710388077935,
        '101': 32.211008141702614,
        '102': 32.21104752168593,
        '103': 32.21084965682483,
        '104': 32.21054428755621,
        '105': 32.210332555135366,
        '106': 32.21018188600392,
        '107': 32.21031370661408,
        '108': 32.210637759805536,
        '109': 32.21071082046433,
        '110': 32.21113939311665,
        '111': 32.211475751288035,
        '112': 32.21232696508406,
        '113': 32.21295797786653,
        '114': 32.213612235087744,
        '115': 32.21411926978684,
        '116': 32.21431881886553,
        '117': 32.21440251749669,
        '118': 32.21431064369028,
        '119': 32.21409565766814,
        '120': 32.213816842206235,
        '121': 32.21317980755266,
        '122': 32.212611080653666,
        '123': 32.21209782392564,
        '124': 32.211495959940805,
        '125': 32.21101159978282,
        '126': 32.21067443858886,
        '127': 32.21051089412873,
        '128': 32.21075971836986,
        '129': 32.211230111517764,
        '130': 32.21124640315706,
        '131': 32.21136235827314,
        '132': 32.211061130284044,
        '133': 32.211446929027964,
        '134': 32.211858361830735,
        '135': 32.21279705128864,
        '136': 32.212790032731014,
        '137': 32.21277744245999,
        '138': 32.213265891923356,
        '139': 32.213647405219085,
        '140': 32.21456000056694,
        '141': 32.21589009364554,
        '142': 32.216857737678964,
        '143': 32.217680719907996,
        '144': 32.21861363579872,
        '145': 32.21915591594788,
        '146': 32.219227887329225,
        '147': 32.21957473736908,
        '148': 32.21983079545733,
        '149': 32.220129639954024,
        '150': 32.22018615576688,
        '151': 32.22024976066435,
        '152': 32.22044376264597,
        '153': 32.2203110223399,
        '154': 32.22048566176784,
        '155': 32.22046641985087,
        '156': 32.219937838822936,
        '157': 32.219645787909435,
        '158': 32.21871166664507,
        '159': 32.21990738362641,
        '160': 32.22091910373278,
        '161': 32.23319058097959,
        '162': 32.23836721179974,
        '163': 32.225788835125556,
        '164': 32.238122321350886,
        '165': 32.22685348477079,
        '166': 32.223502571771526,
        '167': 32.23835169672807,
        '168': 32.23923797255851,
        '169': 32.239244555015915,
        '170': 32.2391907668884,
        '171': 32.23908864956779,
        '172': 32.238959312795586,
        '173': 32.23882784831369,
        '174': 32.23871756983227,
        '175': 32.23864495156006,
        '176': 32.23861639247912,
        '177': 32.238627441965996,
        '178': 32.23866452031844,
        '179': 32.23870860272662,
        '180': 32.23873993182591,
        '-180': 32.23873993182591,
        '-179': 32.238742657838756,
        '-178': 32.23870839183482,
        '-177': 32.23863795585406,
        '-176': 32.2385410420501,
        '-175': 32.238433951677145,
        '-174': 32.23833597771803,
        '-173': 32.23826524846406,
        '-172': 32.23823492306774,
        '-171': 32.238250520787716,
        '-170': 32.23830890428149,
        '-169': 32.23839908098738,
        '-168': 32.23850460748326,
        '-167': 32.23860705418703,
        '-166': 32.23868977605352,
        '-165': 32.23874118161693,
        '-164': 32.23875681034878,
        '-163': 32.238739795188486,
        '-162': 32.23869964923815,
        '-161': 32.23864969522565,
        '-160': 32.238603767004335,
        '-159': 32.238572978331774,
        '-158': 32.238563329945805,
        '-157': 32.23857471071381,
        '-156': 32.23860149059034,
        '-155': 32.23863449087048,
        '-154': 32.238663758681014,
        '-153': 32.238681366365675,
        '-152': 32.238683463067765,
        '-151': 32.238671028500136,
        '-150': 32.238649159012105,
        '-149': 32.23862514722283,
        '-148': 32.23860597356455,
        '-147': 32.2385960036163,
        '-146': 32.23859562240086,
        '-145': 32.238601249251595,
        '-144': 32.23860674665119,
        '-143': 32.238605792097594,
        '-142': 32.23859446041066,
        '-141': 32.238573168218764,
        '-140': 32.23854729930303,
        '-139': 32.23852621568635,
        '-138': 32.238520852482026,
        '-137': 32.23854054587275,
        '-136': 32.23859001300289,
        '-135': 32.23866740186064,
        '-134': 32.2387640507761,
        '-133': 32.23886611812713,
        '-132': 32.23895770311582,
        '-131': 32.239024638671566,
        '-130': 32.239057929963074,
        '-129': 32.23905590002878,
        '-128': 32.239024462605194,
        '-127': 32.238975465628464,
        '-126': 32.23892358316626,
        '-125': 32.238882623416096,
        '-124': 32.23886225598918,
        '-123': 32.238866010648415,
        '-122': 32.23889101501551,
        '-121': 32.23892944035476,
        '-120': 32.23897116046771,
        '-119': 32.23900682953028,
        '-118': 32.239030525034664,
        '-117': 32.23904128167364,
        '-116': 32.23904319124401,
        '-115': 32.239044149425204,
        '-114': 32.239053673923664,
        '-113': 32.23908041173131,
        '-112': 32.23912996163143,
        '-111': 32.23920348501269,
        '-110': 32.23929733031402,
        '-109': 32.239403637522415,
        '-108': 32.239511690572186,
        '-107': 32.239609685870086,
        '-106': 32.239686583574894,
        '-105': 32.2397337724671,
        '-104': 32.23974636417955,
        '-103': 32.23972400099477,
        '-102': 32.239671098667635,
        '-101': 32.23959646308444,
        '-100': 32.239512245636725,
        '-99': 32.239432266245984,
        '-98': 32.239369847276144,
        '-97': 32.239335450096554,
        '-96': 32.239334544882425,
        '-95': 32.23936621435844,
        '-94': 32.239422941076,
        '-93': 32.239491832309774,
        '-92': 32.23955721874157,
        '-91': 32.239604191787514,
        '-90': 32.239622321321654,
        '-89': 32.23960862696492,
        '-88': 32.23956893943376,
        '-87': 32.239517103113954,
        '-86': 32.239471983898135,
        '-85': 32.23945283772242,
        '-84': 32.23947410624571,
        '-83': 32.23954098123105,
        '-82': 32.239647013080656,
        '-81': 32.23977461342113,
        '-80': 32.23989860012058,
        '-79': 32.23999212620413,
        '-78': 32.24003363730445,
        '-77': 32.240013116966146,
        '-76': 32.23596966493937,
        '-75': 32.23679843542765,
        '-74': 32.239707697111655,
        '-73': 32.23955709361999,
        '-72': 32.23946669909848,
        '-71': 32.23867071685621,
        '-70': 32.23908502390067,
        '-69': 32.230763375265795,
        '-68': 32.240261067411474,
        '-67': 32.23393950795821,
        '-66': 32.22808614415947,
        '-65': 32.22393466756706,
        '-64': 32.22117408542738,
        '-63': 32.225888277067035,
        '-62': 32.235900469930485,
        '-61': 32.24046021088387,
        '-60': 32.24043499038501,
        '-59': 32.240081386465874,
        '-58': 32.23975396765218,
        '-57': 32.23948566160495,
        '-56': 32.23929778077132,
        '-55': 32.239198325914145,
        '-54': 32.239182515657454,
        '-53': 32.239234965354655,
        '-52': 32.23933293133324,
        '-51': 32.239450065303394,
        '-50': 32.239560221092404,
        '-49': 32.23964094865527,
        '-48': 32.239676359500244,
        '-47': 32.23965906120571,
        '-46': 32.23959088163497,
        '-45': 32.23948219072757,
        '-44': 32.23934981184605,
        '-43': 32.23921378216897,
        '-42': 32.23909350927298,
        '-41': 32.23900408412879,
        '-40': 32.23895355785583,
        '-39': 32.238941820758654,
        '-38': 32.23896135520438,
        '-37': 32.23899965751956,
        '-36': 32.23904267462022,
        '-35': 32.23907831951965,
        '-34': 32.2390991136462,
        '-33': 32.239103270840694,
        '-32': 32.23909401532183,
        '-31': 32.23907747018904,
        '-30': 32.23905989380023,
        '-29': 32.23904523628653,
        '-28': 32.23903387058559,
        '-27': 32.23902295393075,
        '-26': 32.23900832004209,
        '-25': 32.23898726569096,
        '-24': 32.238961251446675,
        '-23': 32.23893750066087,
        '-22': 32.23892877316815,
        '-21': 32.238951128456,
        '-20': 32.239020117349924,
        '-19': 32.23914636288257,
        '-18': 32.23933174958261,
        '-17': 32.2395673520566,
        '-16': 32.23983381599002,
        '-15': 32.240104271090836,
        '-14': 32.240349181978104,
        '-13': 32.24054201681154,
        '-12': 32.240664379896614,
        '-11': 32.24055742829506,
        '-10': 32.24001391678299,
        '-9': 32.239930976245525,
        '-8': 32.23985470472343,
        '-7': 32.237844739945466,
        '-6': 32.23868878804185,
        '-5': 32.23919011172035,
        '-4': 32.23923135165004,
        '-3': 32.23923381474742,
        '-2': 32.2393709962875,
        '-1': 32.23947131107342
    },
    '-72': {
        '0': 32.23181578824136,
        '1': 32.22976541198553,
        '2': 32.22832359324325,
        '3': 32.22749954455605,
        '4': 32.22541226660991,
        '5': 32.22202038483306,
        '6': 32.21684611561269,
        '7': 32.2212698615255,
        '8': 32.2224438278337,
        '9': 32.21733687292827,
        '10': 32.2193348102349,
        '11': 32.21965952393316,
        '12': 32.21557627120267,
        '13': 32.21859977662781,
        '14': 32.22075507469468,
        '15': 32.22199458809589,
        '16': 32.2221677541406,
        '17': 32.22192811894081,
        '18': 32.22342892384441,
        '19': 32.22544262242887,
        '20': 32.22666690021467,
        '21': 32.22723299312325,
        '22': 32.22824404559874,
        '23': 32.227482471277526,
        '24': 32.22738570471681,
        '25': 32.22927510023102,
        '26': 32.22654536291624,
        '27': 32.22775986107259,
        '28': 32.226801106804004,
        '29': 32.224246619248305,
        '30': 32.22393038914626,
        '31': 32.2231941871855,
        '32': 32.22313058825766,
        '33': 32.22258297301617,
        '34': 32.221683729960084,
        '35': 32.22158531379036,
        '36': 32.21849566926693,
        '37': 32.21752654897836,
        '38': 32.21778744390021,
        '39': 32.2176545278752,
        '40': 32.21776609468995,
        '41': 32.217440949036984,
        '42': 32.21704479988949,
        '43': 32.2161172064635,
        '44': 32.21559454764268,
        '45': 32.21478445199998,
        '46': 32.21409405301545,
        '47': 32.21347157607543,
        '48': 32.212668618819464,
        '49': 32.212159501009026,
        '50': 32.21148514627673,
        '51': 32.21099219563972,
        '52': 32.21077397912242,
        '53': 32.210934851597784,
        '54': 32.21139938817383,
        '55': 32.21198343355369,
        '56': 32.212767430703835,
        '57': 32.21352924322275,
        '58': 32.21477322053595,
        '59': 32.21641484090632,
        '60': 32.21834560994363,
        '61': 32.220200683573516,
        '62': 32.22177501545894,
        '63': 32.22385581461443,
        '64': 32.22550581471389,
        '65': 32.22737385075559,
        '66': 32.230604811312425,
        '67': 32.23512093190238,
        '68': 32.23970151625997,
        '69': 32.23978578614307,
        '70': 32.239869800643625,
        '71': 32.23601012974551,
        '72': 32.23081619528558,
        '73': 32.228856246396205,
        '74': 32.22654700914353,
        '75': 32.22507894813614,
        '76': 32.22278323174705,
        '77': 32.2206595178544,
        '78': 32.21899896479821,
        '79': 32.21755232534214,
        '80': 32.21621179771364,
        '81': 32.21507215262404,
        '82': 32.21400231279623,
        '83': 32.212982638339334,
        '84': 32.21227826423218,
        '85': 32.21176393559782,
        '86': 32.21126167170949,
        '87': 32.21079010846418,
        '88': 32.210272173942975,
        '89': 32.21036721075437,
        '90': 32.21019445226445,
        '91': 32.21007617732728,
        '92': 32.20969428326037,
        '93': 32.209476648793746,
        '94': 32.20932326100224,
        '95': 32.209603057571655,
        '96': 32.20965314483458,
        '97': 32.20997730672206,
        '98': 32.210191152258375,
        '99': 32.210437290662234,
        '100': 32.2107114286352,
        '101': 32.21103026126044,
        '102': 32.21109519352932,
        '103': 32.2110072575434,
        '104': 32.21099824933681,
        '105': 32.210996541373625,
        '106': 32.211108103512764,
        '107': 32.210942235075905,
        '108': 32.21104350287599,
        '109': 32.21112491712818,
        '110': 32.21120864814069,
        '111': 32.21134828214438,
        '112': 32.21166936856252,
        '113': 32.21209240471004,
        '114': 32.212473224205176,
        '115': 32.21317102503201,
        '116': 32.21375643820631,
        '117': 32.214031745041346,
        '118': 32.21396714461185,
        '119': 32.21379369574529,
        '120': 32.21345343420722,
        '121': 32.21265341740523,
        '122': 32.21196866455911,
        '123': 32.21142558793199,
        '124': 32.21076677469171,
        '125': 32.21035342182217,
        '126': 32.210101465504266,
        '127': 32.21009847201329,
        '128': 32.21021380355387,
        '129': 32.210600092086715,
        '130': 32.210761272931556,
        '131': 32.211268151247644,
        '132': 32.21141939520166,
        '133': 32.212015177438005,
        '134': 32.212667890218405,
        '135': 32.213083785410774,
        '136': 32.21371511437599,
        '137': 32.21485370983947,
        '138': 32.21532166521406,
        '139': 32.215781089847894,
        '140': 32.21615458710857,
        '141': 32.21631299743844,
        '142': 32.21702809556628,
        '143': 32.217694420458976,
        '144': 32.21785987110129,
        '145': 32.218139731876306,
        '146': 32.218622938229004,
        '147': 32.21897320232643,
        '148': 32.219134121931724,
        '149': 32.219284819752254,
        '150': 32.21911820223788,
        '151': 32.21924126674358,
        '152': 32.21925366949984,
        '153': 32.219272995366254,
        '154': 32.21918923063424,
        '155': 32.21930451779123,
        '156': 32.21899306570664,
        '157': 32.218603337917955,
        '158': 32.21842877603763,
        '159': 32.219929748742345,
        '160': 32.22189128720512,
        '161': 32.22407729320593,
        '162': 32.22992793690518,
        '163': 32.23144938395074,
        '164': 32.22612631707159,
        '165': 32.22389268835509,
        '166': 32.22292632667989,
        '167': 32.22128536490172,
        '168': 32.22241707068822,
        '169': 32.22137032602384,
        '170': 32.24128757718697,
        '171': 32.24137740570572,
        '172': 32.24117074951903,
        '173': 32.24096991011816,
        '174': 32.2408012820665,
        '175': 32.24068382946395,
        '176': 32.24062579856738,
        '177': 32.240623618999,
        '178': 32.24066315489206,
        '179': 32.24072297075364,
        '180': 32.24077887421693,
        '-180': 32.24077887421693,
        '-179': 32.240808770660315,
        '-178': 32.24079685100512,
        '-177': 32.240736323653714,
        '-176': 32.240630241940224,
        '-175': 32.24049038916663,
        '-174': 32.24033457483673,
        '-173': 32.240182988716256,
        '-172': 32.24005440005426,
        '-171': 32.23996295871801,
        '-170': 32.2399161706997,
        '-169': 32.23991433096242,
        '-168': 32.23995136980627,
        '-167': 32.24001677711941,
        '-166': 32.24009807320495,
        '-165': 32.24018323268131,
        '-164': 32.2402625452835,
        '-163': 32.24032958837312,
        '-162': 32.24038123922144,
        '-161': 32.24041690596052,
        '-160': 32.2404373418848,
        '-159': 32.2404434832498,
        '-158': 32.24043569938343,
        '-157': 32.240413682786574,
        '-156': 32.2403769826401,
        '-155': 32.2403259623584,
        '-154': 32.2402628059219,
        '-153': 32.240192156653755,
        '-152': 32.24012106219795,
        '-151': 32.24005809932521,
        '-150': 32.24001180712716,
        '-149': 32.239988793310694,
        '-148': 32.2399920232252,
        '-147': 32.24001980521794,
        '-146': 32.24006583781748,
        '-145': 32.24012041709329,
        '-144': 32.24017258619574,
        '-143': 32.24021273178402,
        '-142': 32.240234975562686,
        '-141': 32.240238724669275,
        '-140': 32.24022893661474,
        '-139': 32.24021497732295,
        '-138': 32.240208320134556,
        '-137': 32.24021964888662,
        '-136': 32.2402561007739,
        '-135': 32.240319363514885,
        '-134': 32.24040512814251,
        '-133': 32.24050404871309,
        '-132': 32.240603967227315,
        '-131': 32.24069283246794,
        '-130': 32.24076156433533,
        '-129': 32.24080613739729,
        '-128': 32.24082837057221,
        '-127': 32.24083525395362,
        '-126': 32.24083702418869,
        '-125': 32.24084451444707,
        '-124': 32.24086647251043,
        '-123': 32.24090752192153,
        '-122': 32.24096724896519,
        '-121': 32.241040590866625,
        '-120': 32.241119364005954,
        '-119': 32.241194493949365,
        '-118': 32.24125835905358,
        '-117': 32.24130666571957,
        '-116': 32.24133942272343,
        '-115': 32.24136082570641,
        '-114': 32.24137813296485,
        '-113': 32.24139984269096,
        '-112': 32.24143361991314,
        '-111': 32.241484446545215,
        '-110': 32.241553387454104,
        '-109': 32.24163720951512,
        '-108': 32.24172890126689,
        '-107': 32.24181895994115,
        '-106': 32.24189717300031,
        '-105': 32.24195454181247,
        '-104': 32.24198498114734,
        '-103': 32.24198647500047,
        '-102': 32.239956917371714,
        '-101': 32.23758392055583,
        '-100': 32.240018150705595,
        '-99': 32.241763057351065,
        '-98': 32.241602832170045,
        '-97': 32.23903759426537,
        '-96': 32.24169907270219,
        '-95': 32.24169003284345,
        '-94': 32.24168459228086,
        '-93': 32.24167273792483,
        '-92': 32.24164466367411,
        '-91': 32.241593526900125,
        '-90': 32.24151777237501,
        '-89': 32.24142239701809,
        '-88': 32.24131871818089,
        '-87': 32.241222517199866,
        '-86': 32.24115082252637,
        '-85': 32.241117970094024,
        '-84': 32.24113183567837,
        '-83': 32.241191194041726,
        '-82': 32.241284984217074,
        '-81': 32.241393868850814,
        '-80': 32.24149394658954,
        '-79': 32.24156193233124,
        '-78': 32.24158069800271,
        '-77': 32.241543883268264,
        '-76': 32.241458405396514,
        '-75': 32.24039348698257,
        '-74': 32.238298491866075,
        '-73': 32.24101924614846,
        '-72': 32.24087202417557,
        '-71': 32.240940163642726,
        '-70': 32.238671270102934,
        '-69': 32.236377177808194,
        '-68': 32.24157363716116,
        '-67': 32.241653684436585,
        '-66': 32.231984981751,
        '-65': 32.22772598494357,
        '-64': 32.22689833617395,
        '-63': 32.23505961290262,
        '-62': 32.24163130750141,
        '-61': 32.24193722168013,
        '-60': 32.2420796550873,
        '-59': 32.24182895179031,
        '-58': 32.241621335298284,
        '-57': 32.24148908172586,
        '-56': 32.241450037868916,
        '-55': 32.24150471802123,
        '-54': 32.24163658422605,
        '-53': 32.24181521889238,
        '-52': 32.242001750838405,
        '-51': 32.24215555378522,
        '-50': 32.24224106959284,
        '-49': 32.24223362861635,
        '-48': 32.24212333289562,
        '-47': 32.24191640106316,
        '-46': 32.241633797850184,
        '-45': 32.24130742474762,
        '-44': 32.24097456272339,
        '-43': 32.24067156317451,
        '-42': 32.240427919922276,
        '-41': 32.240261787045156,
        '-40': 32.24017773466908,
        '-39': 32.24016710014633,
        '-38': 32.24021077746156,
        '-37': 32.24028380019416,
        '-36': 32.240360721075874,
        '-35': 32.2404206562905,
        '-34': 32.24045097864634,
        '-33': 32.24044898410206,
        '-32': 32.24042133869857,
        '-31': 32.24038161964407,
        '-30': 32.240346671276804,
        '-29': 32.24033270693397,
        '-28': 32.240352057221564,
        '-27': 32.24041121403955,
        '-26': 32.24051042624414,
        '-25': 32.2406446805794,
        '-24': 32.24080556668062,
        '-23': 32.240983363484276,
        '-22': 32.24116872853194,
        '-21': 32.241353592816296,
        '-20': 32.24153118328701,
        '-19': 32.24169540922099,
        '-18': 32.241840060260294,
        '-17': 32.2419583111275,
        '-16': 32.24204290149387,
        '-15': 32.242087103701664,
        '-14': 32.242086289690505,
        '-13': 32.24203965839047,
        '-12': 32.24155686858609,
        '-11': 32.24138696999949,
        '-10': 32.235849231553814,
        '-9': 32.23598849356075,
        '-8': 32.235863205934876,
        '-7': 32.23263820922351,
        '-6': 32.2291143839545,
        '-5': 32.22583019991584,
        '-4': 32.23093562696184,
        '-3': 32.23096388465595,
        '-2': 32.23289644853337,
        '-1': 32.23812837247934
    },
    '-73': {
        '0': 32.223854048000405,
        '1': 32.221521551943766,
        '2': 32.219368873781846,
        '3': 32.217792619215885,
        '4': 32.21618792414903,
        '5': 32.21510196900409,
        '6': 32.214190108090364,
        '7': 32.213551365925554,
        '8': 32.21364844343745,
        '9': 32.213801554799716,
        '10': 32.213788261404765,
        '11': 32.213480354342444,
        '12': 32.21296555536052,
        '13': 32.212415295946364,
        '14': 32.212558495114436,
        '15': 32.21345570439016,
        '16': 32.214729831669416,
        '17': 32.21477530814177,
        '18': 32.2150453590566,
        '19': 32.21575390436168,
        '20': 32.21633189714804,
        '21': 32.21684573018584,
        '22': 32.217069530046196,
        '23': 32.216053021755116,
        '24': 32.21490616603292,
        '25': 32.21314792123873,
        '26': 32.21275150644923,
        '27': 32.21590644597401,
        '28': 32.21803193186727,
        '29': 32.21913803440424,
        '30': 32.21933257423512,
        '31': 32.218484536358,
        '32': 32.21701045571777,
        '33': 32.217349642910484,
        '34': 32.217122800855506,
        '35': 32.21639023605746,
        '36': 32.21544170265407,
        '37': 32.215042643424674,
        '38': 32.21485940679775,
        '39': 32.21505962301269,
        '40': 32.2147460605654,
        '41': 32.21441584735897,
        '42': 32.214048007816196,
        '43': 32.21365839755558,
        '44': 32.21330747918321,
        '45': 32.21301666395949,
        '46': 32.21247262464297,
        '47': 32.211974483734004,
        '48': 32.21157311965702,
        '49': 32.21118167033889,
        '50': 32.211100733007854,
        '51': 32.211363115733846,
        '52': 32.211907121443275,
        '53': 32.2125884156553,
        '54': 32.21342411577076,
        '55': 32.21427098852276,
        '56': 32.21522025168681,
        '57': 32.216062458227995,
        '58': 32.21730793564589,
        '59': 32.219155898967195,
        '60': 32.22037225427873,
        '61': 32.22284770004921,
        '62': 32.225675826514696,
        '63': 32.22771072899715,
        '64': 32.23013491427671,
        '65': 32.23217448901225,
        '66': 32.23582820028622,
        '67': 32.23997499088718,
        '68': 32.24064442202462,
        '69': 32.227672721567394,
        '70': 32.23298066214367,
        '71': 32.23098354673047,
        '72': 32.229226329886004,
        '73': 32.22748590891946,
        '74': 32.22513597300493,
        '75': 32.22288961033076,
        '76': 32.21976175942468,
        '77': 32.21862574654096,
        '78': 32.21753111747898,
        '79': 32.21655238894204,
        '80': 32.21554514970839,
        '81': 32.21480169034454,
        '82': 32.21375336454479,
        '83': 32.21292648998848,
        '84': 32.21253170150461,
        '85': 32.211732885589946,
        '86': 32.21112863314555,
        '87': 32.21059013446699,
        '88': 32.21024225113204,
        '89': 32.20988094843317,
        '90': 32.20959303123386,
        '91': 32.209173973582125,
        '92': 32.209039312685384,
        '93': 32.20908383620829,
        '94': 32.209185213208215,
        '95': 32.20944390975037,
        '96': 32.20972529640173,
        '97': 32.21001074968674,
        '98': 32.210427023035656,
        '99': 32.21070125033131,
        '100': 32.21101362142676,
        '101': 32.21122359792016,
        '102': 32.21149230402658,
        '103': 32.2118523115736,
        '104': 32.21207917541996,
        '105': 32.212035384762935,
        '106': 32.21193946154976,
        '107': 32.21187448619553,
        '108': 32.21172986502821,
        '109': 32.21134431967841,
        '110': 32.211395734347946,
        '111': 32.211692920589876,
        '112': 32.21155791974201,
        '113': 32.21176567137114,
        '114': 32.21201937915683,
        '115': 32.21217931363455,
        '116': 32.2123938148848,
        '117': 32.21251145778714,
        '118': 32.21263232984381,
        '119': 32.21235214561478,
        '120': 32.212034941783266,
        '121': 32.211696163304154,
        '122': 32.21130928901618,
        '123': 32.210919228115536,
        '124': 32.210584288092264,
        '125': 32.210430969481976,
        '126': 32.210438900168874,
        '127': 32.2105012278641,
        '128': 32.21066932976204,
        '129': 32.21065267187603,
        '130': 32.2112685957129,
        '131': 32.211953793272805,
        '132': 32.21258619237594,
        '133': 32.21302333026579,
        '134': 32.21340623916465,
        '135': 32.214663390069134,
        '136': 32.2150502373896,
        '137': 32.21571513014656,
        '138': 32.21611618372928,
        '139': 32.21668689111809,
        '140': 32.21677268093758,
        '141': 32.21744103037405,
        '142': 32.2179357270186,
        '143': 32.2182469208813,
        '144': 32.21841689041997,
        '145': 32.218633621605996,
        '146': 32.2187876290958,
        '147': 32.21886623075931,
        '148': 32.21905324679989,
        '149': 32.21918117422937,
        '150': 32.21919333148376,
        '151': 32.219232266691634,
        '152': 32.21900190154695,
        '153': 32.21889811479575,
        '154': 32.218827806941,
        '155': 32.21891722416078,
        '156': 32.21893794317115,
        '157': 32.21890414632443,
        '158': 32.21907354356657,
        '159': 32.219219847234555,
        '160': 32.21976365527978,
        '161': 32.22140790508578,
        '162': 32.225825161973425,
        '163': 32.22501428311404,
        '164': 32.223704536460964,
        '165': 32.21717637698754,
        '166': 32.21991472487906,
        '167': 32.222793828288516,
        '168': 32.23915222410671,
        '169': 32.23804969258873,
        '170': 32.243411171703,
        '171': 32.24321280950629,
        '172': 32.242995400310036,
        '173': 32.24278176091642,
        '174': 32.242592557568514,
        '175': 32.24244295875695,
        '176': 32.242340366566204,
        '177': 32.242283565512174,
        '178': 32.2422634293115,
        '179': 32.24226501502815,
        '180': 32.242270607392385,
        '-180': 32.242270607392385,
        '-179': 32.242263108219156,
        '-178': 32.24222912732166,
        '-177': 32.24216122344602,
        '-176': 32.24205894009044,
        '-175': 32.241928534810086,
        '-174': 32.241781555914706,
        '-173': 32.241632624968325,
        '-172': 32.241496899210254,
        '-171': 32.241387698485795,
        '-170': 32.24131469369075,
        '-169': 32.241282895798555,
        '-168': 32.24129249702992,
        '-167': 32.24133944254586,
        '-166': 32.24141648909175,
        '-165': 32.24151445810895,
        '-164': 32.241623417544105,
        '-163': 32.24173361280207,
        '-162': 32.24183608323918,
        '-161': 32.241923011081674,
        '-160': 32.24198792343577,
        '-159': 32.242025886029595,
        '-158': 32.24203378747223,
        '-157': 32.24201073056334,
        '-156': 32.24195845104861,
        '-155': 32.24188160770437,
        '-154': 32.24178775955186,
        '-153': 32.241686881703345,
        '-152': 32.24159036743678,
        '-151': 32.24150959824516,
        '-150': 32.24145429974692,
        '-149': 32.2414309992042,
        '-148': 32.241441926843564,
        '-147': 32.24148464233875,
        '-146': 32.24155252696275,
        '-145': 32.24163609117643,
        '-144': 32.24172485300828,
        '-143': 32.24180939507816,
        '-142': 32.24188314885995,
        '-141': 32.24194350405261,
        '-140': 32.24199199142492,
        '-139': 32.24203350498784,
        '-138': 32.24207476071838,
        '-137': 32.24212237576428,
        '-136': 32.24218104547354,
        '-135': 32.24225227017281,
        '-134': 32.242333944205306,
        '-133': 32.24242090085054,
        '-132': 32.24250626389956,
        '-131': 32.24258325192053,
        '-130': 32.24264696697893,
        '-129': 32.24269570470511,
        '-128': 32.24273144521549,
        '-127': 32.24275939250749,
        '-126': 32.242786670161436,
        '-125': 32.24282049274436,
        '-124': 32.24286626278821,
        '-123': 32.24292606161493,
        '-122': 32.2429979062795,
        '-121': 32.24307596050403,
        '-120': 32.24315166147303,
        '-119': 32.243215511867355,
        '-118': 32.2432591370642,
        '-117': 32.243277153278584,
        '-116': 32.24326844189618,
        '-115': 32.24323656238671,
        '-114': 32.24318922622311,
        '-113': 32.2431369529578,
        '-112': 32.243091194027585,
        '-111': 32.24306230745819,
        '-110': 32.243057781360854,
        '-109': 32.243081038136076,
        '-108': 32.243131022837225,
        '-107': 32.24320261728016,
        '-106': 32.24328775955941,
        '-105': 32.24337701768067,
        '-104': 32.2434612894197,
        '-103': 32.24133700317054,
        '-102': 32.24320391233229,
        '-101': 32.2433119654847,
        '-100': 32.24336296259258,
        '-99': 32.242722503877175,
        '-98': 32.24345938329364,
        '-97': 32.24344101695441,
        '-96': 32.24340785604039,
        '-95': 32.24339985364262,
        '-94': 32.24331344819494,
        '-93': 32.24335793103986,
        '-92': 32.243287512586406,
        '-91': 32.24211951227177,
        '-90': 32.241120974409284,
        '-89': 32.240934441672756,
        '-88': 32.242761585210566,
        '-87': 32.24287921176284,
        '-86': 32.24305759361123,
        '-85': 32.24303120861198,
        '-84': 32.243036086528285,
        '-83': 32.24307023876294,
        '-82': 32.243126299779945,
        '-81': 32.243192761475534,
        '-80': 32.2432562970732,
        '-79': 32.23979405464278,
        '-78': 32.24332994337245,
        '-77': 32.243107592886176,
        '-76': 32.24315912967407,
        '-75': 32.23998640662517,
        '-74': 32.2432654677897,
        '-73': 32.243271952903065,
        '-72': 32.24320526642961,
        '-71': 32.24309153828701,
        '-70': 32.243068729457484,
        '-69': 32.24331549280436,
        '-68': 32.24141203014065,
        '-67': 32.23836355517932,
        '-66': 32.233952307232514,
        '-65': 32.230405303051214,
        '-64': 32.22712684948833,
        '-63': 32.2297237306365,
        '-62': 32.23371067152809,
        '-61': 32.23619198706377,
        '-60': 32.24310902557887,
        '-59': 32.243170556077416,
        '-58': 32.24308346188918,
        '-57': 32.24309000095358,
        '-56': 32.24319492439634,
        '-55': 32.243386568363235,
        '-54': 32.24363827130452,
        '-53': 32.24391239588609,
        '-52': 32.244166277360556,
        '-51': 32.24435907787218,
        '-50': 32.24445836697645,
        '-49': 32.2444452985907,
        '-48': 32.24431750288176,
        '-47': 32.24408921357249,
        '-46': 32.243788636186764,
        '-45': 32.24345304593114,
        '-44': 32.24312249988555,
        '-43': 32.242833285569695,
        '-42': 32.24261226229258,
        '-41': 32.24247307463606,
        '-40': 32.24241486006821,
        '-39': 32.242423601457496,
        '-38': 32.24247578025885,
        '-37': 32.24254356367327,
        '-36': 32.24260049186106,
        '-35': 32.24262657076752,
        '-34': 32.24261183115439,
        '-33': 32.242557749166345,
        '-32': 32.2424763654328,
        '-31': 32.242387394678325,
        '-30': 32.24231399227884,
        '-29': 32.242278064740766,
        '-28': 32.24229604038159,
        '-27': 32.2423758604404,
        '-26': 32.242515655371086,
        '-25': 32.242704209543795,
        '-24': 32.2429229721625,
        '-23': 32.24314911398017,
        '-22': 32.243359002512896,
        '-21': 32.243531482204126,
        '-20': 32.243650476307934,
        '-19': 32.24352438202502,
        '-18': 32.24322207392144,
        '-17': 32.2431433877394,
        '-16': 32.24313720486161,
        '-15': 32.24277366147915,
        '-14': 32.24248600773773,
        '-13': 32.24047313912162,
        '-12': 32.238857172562945,
        '-11': 32.236683119837686,
        '-10': 32.232821375761624,
        '-9': 32.22903702563648,
        '-8': 32.22780533542989,
        '-7': 32.22609205906804,
        '-6': 32.222444158424295,
        '-5': 32.22204138793995,
        '-4': 32.22427564939185,
        '-3': 32.22594122732377,
        '-2': 32.22687377057894,
        '-1': 32.2259913876211
    },
    '-74': {
        '0': 32.22065467163758,
        '1': 32.22002564965602,
        '2': 32.21936419490492,
        '3': 32.21804305567611,
        '4': 32.21701099112438,
        '5': 32.21587855008974,
        '6': 32.21506223517635,
        '7': 32.21437939806809,
        '8': 32.213787664864334,
        '9': 32.2131134001574,
        '10': 32.212700060247464,
        '11': 32.212357837804674,
        '12': 32.212152453837476,
        '13': 32.21188914969462,
        '14': 32.21186943731383,
        '15': 32.21203025856967,
        '16': 32.21217450754456,
        '17': 32.212292726844886,
        '18': 32.21238015796711,
        '19': 32.21263889622712,
        '20': 32.21281146718858,
        '21': 32.21297131934373,
        '22': 32.21311078200982,
        '23': 32.21309312055726,
        '24': 32.212938339204534,
        '25': 32.212804780767335,
        '26': 32.21298978206551,
        '27': 32.21364586279431,
        '28': 32.21459733244011,
        '29': 32.2150148933559,
        '30': 32.215526094369864,
        '31': 32.21555301785845,
        '32': 32.215079003110965,
        '33': 32.21433304689891,
        '34': 32.21421322463626,
        '35': 32.21396393286466,
        '36': 32.213349270907464,
        '37': 32.21298864479116,
        '38': 32.212359145700646,
        '39': 32.21222295334312,
        '40': 32.211901502642526,
        '41': 32.212025367172146,
        '42': 32.21196827174004,
        '43': 32.21175666748954,
        '44': 32.21166424751304,
        '45': 32.21150288028816,
        '46': 32.21155991545613,
        '47': 32.2113940907272,
        '48': 32.21145959624377,
        '49': 32.21153951709545,
        '50': 32.2118459337364,
        '51': 32.21235372030183,
        '52': 32.21335676752334,
        '53': 32.21440897360132,
        '54': 32.21537586799942,
        '55': 32.216305102374825,
        '56': 32.21773117562646,
        '57': 32.219130724113306,
        '58': 32.219984040066,
        '59': 32.22136218469517,
        '60': 32.222127677993264,
        '61': 32.22381464827523,
        '62': 32.225586404140536,
        '63': 32.22671051854456,
        '64': 32.228072604492645,
        '65': 32.230588722658204,
        '66': 32.23333607226244,
        '67': 32.23368242490679,
        '68': 32.23225257446574,
        '69': 32.23157368253098,
        '70': 32.22935858738667,
        '71': 32.22786807917598,
        '72': 32.22646834057647,
        '73': 32.22536974125233,
        '74': 32.22448778514768,
        '75': 32.22328385486436,
        '76': 32.22061622543066,
        '77': 32.218960086388634,
        '78': 32.21770416462044,
        '79': 32.2168519143698,
        '80': 32.21589818251022,
        '81': 32.215141582349915,
        '82': 32.21434694404131,
        '83': 32.21364195248986,
        '84': 32.21283724569857,
        '85': 32.21230373078209,
        '86': 32.21171926975588,
        '87': 32.211211202633216,
        '88': 32.2106510981806,
        '89': 32.210455363817786,
        '90': 32.21002199241113,
        '91': 32.209712394892534,
        '92': 32.20933688665109,
        '93': 32.20916423146374,
        '94': 32.20911171229817,
        '95': 32.20929804651296,
        '96': 32.20960624162653,
        '97': 32.21001458000054,
        '98': 32.21040350830366,
        '99': 32.21085909742071,
        '100': 32.211200183447524,
        '101': 32.21142625334799,
        '102': 32.21194395915646,
        '103': 32.21237813910783,
        '104': 32.21283820273318,
        '105': 32.212807726111045,
        '106': 32.21277106055584,
        '107': 32.21266432669992,
        '108': 32.212578829534685,
        '109': 32.21249369267319,
        '110': 32.21235215679273,
        '111': 32.212290032158535,
        '112': 32.21216965089835,
        '113': 32.21209450379433,
        '114': 32.212128768491745,
        '115': 32.21210906962625,
        '116': 32.212112747954905,
        '117': 32.21206847911756,
        '118': 32.21188058605452,
        '119': 32.21188520156727,
        '120': 32.211810757284,
        '121': 32.21162458433424,
        '122': 32.21150014263081,
        '123': 32.211329388372,
        '124': 32.21127493400181,
        '125': 32.211343776809755,
        '126': 32.211497113693156,
        '127': 32.211811875421034,
        '128': 32.21203778177408,
        '129': 32.212477619535896,
        '130': 32.21294985621388,
        '131': 32.213245014238446,
        '132': 32.213793748571995,
        '133': 32.214193014661475,
        '134': 32.21426582543172,
        '135': 32.21511031919169,
        '136': 32.21578490181593,
        '137': 32.216416917864244,
        '138': 32.21685757875322,
        '139': 32.21693347277185,
        '140': 32.217335892895434,
        '141': 32.21806595904904,
        '142': 32.21858760271009,
        '143': 32.218921287292844,
        '144': 32.21916117770421,
        '145': 32.21934532191518,
        '146': 32.2194361814876,
        '147': 32.219461941092575,
        '148': 32.2195456550735,
        '149': 32.21950936789271,
        '150': 32.219579380110105,
        '151': 32.21962651555841,
        '152': 32.219892018233104,
        '153': 32.22032014216791,
        '154': 32.22095207813431,
        '155': 32.22140135879284,
        '156': 32.22244050867189,
        '157': 32.222567964810025,
        '158': 32.22315040011427,
        '159': 32.22376674667372,
        '160': 32.225026974998634,
        '161': 32.22424627730347,
        '162': 32.230082153775754,
        '163': 32.22238769537995,
        '164': 32.229072551164094,
        '165': 32.23510735615784,
        '166': 32.24463466076569,
        '167': 32.24466297248084,
        '168': 32.24465204816919,
        '169': 32.24460277714355,
        '170': 32.244519439225556,
        '171': 32.24440936585442,
        '172': 32.244282074690446,
        '173': 32.24414802898941,
        '174': 32.244017213641705,
        '175': 32.24389779346861,
        '176': 32.24379510605344,
        '177': 32.2437111726843,
        '178': 32.243644803418725,
        '179': 32.243592250873434,
        '180': 32.24354825946971,
        '-180': 32.24354825946971,
        '-179': 32.2435072857656,
        '-178': 32.24346464510707,
        '-177': 32.24341737195516,
        '-176': 32.24336465581536,
        '-175': 32.24330781265343,
        '-174': 32.2432498498729,
        '-173': 32.243194759643764,
        '-172': 32.24314671527994,
        '-171': 32.24310934269139,
        '-170': 32.24308519824629,
        '-169': 32.24307551886576,
        '-168': 32.24308023797931,
        '-167': 32.24309820048799,
        '-166': 32.24312747519379,
        '-165': 32.24316566072189,
        '-164': 32.24321010835532,
        '-163': 32.24325803210777,
        '-162': 32.24330652760439,
        '-161': 32.24335256110068,
        '-160': 32.243393006111766,
        '-159': 32.24342479253788,
        '-158': 32.243445195207876,
        '-157': 32.24345223640707,
        '-156': 32.2434451258775,
        '-155': 32.24342462802669,
        '-154': 32.243393241535706,
        '-153': 32.24335510536969,
        '-152': 32.24331560222512,
        '-151': 32.24328070219444,
        '-150': 32.24325615732992,
        '-149': 32.24324670306783,
        '-148': 32.2432554313253,
        '-147': 32.24328346773847,
        '-146': 32.24333001806808,
        '-145': 32.2433927621459,
        '-144': 32.24346848975064,
        '-143': 32.24355381372457,
        '-142': 32.243645778068206,
        '-141': 32.24374220888667,
        '-140': 32.24384172758166,
        '-139': 32.243943440882845,
        '-138': 32.244046416375454,
        '-137': 32.24414911954361,
        '-136': 32.2442490095067,
        '-135': 32.24434245760014,
        '-134': 32.24442507187014,
        '-133': 32.244492400596904,
        '-132': 32.244540876811016,
        '-131': 32.24456878268908,
        '-130': 32.24457698102277,
        '-129': 32.2445691918396,
        '-128': 32.24455168155906,
        '-127': 32.244400841263285,
        '-126': 32.2443474476924,
        '-125': 32.241545826147984,
        '-124': 32.24399141057839,
        '-123': 32.24435094895926,
        '-122': 32.24210459280694,
        '-121': 32.240451848774704,
        '-120': 32.244573066867176,
        '-119': 32.24445192571131,
        '-118': 32.2447936028628,
        '-117': 32.244721412214574,
        '-116': 32.24473920730738,
        '-115': 32.24466771817529,
        '-114': 32.24457527851767,
        '-113': 32.24447320769016,
        '-112': 32.24437484809092,
        '-111': 32.24429355200334,
        '-110': 32.244240681852716,
        '-109': 32.24422396196392,
        '-108': 32.2442464471749,
        '-107': 32.24430625578437,
        '-106': 32.24439707493839,
        '-105': 32.24450931227102,
        '-104': 32.24463166282918,
        '-103': 32.2447528028522,
        '-102': 32.24486292005091,
        '-101': 32.24176764148675,
        '-100': 32.241432450329526,
        '-99': 32.23911161593465,
        '-98': 32.23768060208769,
        '-97': 32.2367384431193,
        '-96': 32.23508588053355,
        '-95': 32.23284896273195,
        '-94': 32.232964529306805,
        '-93': 32.23347288535873,
        '-92': 32.23353614145732,
        '-91': 32.23265954204435,
        '-90': 32.233129199168886,
        '-89': 32.23391388513408,
        '-88': 32.2341949610641,
        '-87': 32.234124948149244,
        '-86': 32.23438237487672,
        '-85': 32.23562538817875,
        '-84': 32.237318342764496,
        '-83': 32.239481369764015,
        '-82': 32.241142785447735,
        '-81': 32.23749664759496,
        '-80': 32.235472765083614,
        '-79': 32.23639675615944,
        '-78': 32.23849114495237,
        '-77': 32.24124433753335,
        '-76': 32.240078135113485,
        '-75': 32.23855812764517,
        '-74': 32.238680181195626,
        '-73': 32.23730752811218,
        '-72': 32.235972916990484,
        '-71': 32.23447389262093,
        '-70': 32.233848020729965,
        '-69': 32.23135451330212,
        '-68': 32.229038843506835,
        '-67': 32.22735982394315,
        '-66': 32.22523317149703,
        '-65': 32.22871626232199,
        '-64': 32.230012689809435,
        '-63': 32.23201220349026,
        '-62': 32.23769288693706,
        '-61': 32.24451657171154,
        '-60': 32.244579093809165,
        '-59': 32.24438660875841,
        '-58': 32.24428690940475,
        '-57': 32.244292824819865,
        '-56': 32.24440364756889,
        '-55': 32.244604968911844,
        '-54': 32.24487063312165,
        '-53': 32.24516653265383,
        '-52': 32.245455645602455,
        '-51': 32.245703497168115,
        '-50': 32.24588314845431,
        '-49': 32.245978894031474,
        '-48': 32.24598807140816,
        '-47': 32.24592071206193,
        '-46': 32.24579713734361,
        '-45': 32.245643956983955,
        '-44': 32.245489199727736,
        '-43': 32.24535744583915,
        '-42': 32.24526581444473,
        '-41': 32.2452214881196,
        '-40': 32.245221164568505,
        '-39': 32.245252465504365,
        '-38': 32.24529697348173,
        '-37': 32.24533427587917,
        '-36': 32.24534622544623,
        '-35': 32.24532060888756,
        '-34': 32.245253549246684,
        '-33': 32.245150225390304,
        '-32': 32.24502382054456,
        '-31': 32.24489294699481,
        '-30': 32.24477807170551,
        '-29': 32.24469763688414,
        '-28': 32.244664602383736,
        '-27': 32.244684032893325,
        '-26': 32.24475213825514,
        '-25': 32.24485689619739,
        '-24': 32.244980099206785,
        '-23': 32.24510042497444,
        '-22': 32.245196973905585,
        '-21': 32.24496923472812,
        '-20': 32.24459902506209,
        '-19': 32.24462048373011,
        '-18': 32.244523507661974,
        '-17': 32.24432188851261,
        '-16': 32.24384286753609,
        '-15': 32.243816547803924,
        '-14': 32.24143378998874,
        '-13': 32.238018558415654,
        '-12': 32.235524032637244,
        '-11': 32.23407639607156,
        '-10': 32.2323092395729,
        '-9': 32.230021088371714,
        '-8': 32.227358252418796,
        '-7': 32.22549465510446,
        '-6': 32.225533309489116,
        '-5': 32.21860647460611,
        '-4': 32.21798734060106,
        '-3': 32.21737580395192,
        '-2': 32.218304782147435,
        '-1': 32.21933861472463
    },
    '-75': {
        '0': 32.218301097335484,
        '1': 32.21789905332449,
        '2': 32.21737839115085,
        '3': 32.2168822038651,
        '4': 32.21622591809105,
        '5': 32.21555361120871,
        '6': 32.21490385369158,
        '7': 32.21438259065974,
        '8': 32.2138113746595,
        '9': 32.213325013229955,
        '10': 32.212797716283234,
        '11': 32.212450148491655,
        '12': 32.21203223299916,
        '13': 32.211741594496786,
        '14': 32.211394787362764,
        '15': 32.211233198589696,
        '16': 32.2110329656012,
        '17': 32.21089035570061,
        '18': 32.21091754190052,
        '19': 32.2109289002817,
        '20': 32.21105675973857,
        '21': 32.21109607592404,
        '22': 32.211182536026236,
        '23': 32.2112392579325,
        '24': 32.21131229810814,
        '25': 32.21145146220085,
        '26': 32.21164099943165,
        '27': 32.21190137541985,
        '28': 32.21198597879924,
        '29': 32.21215841323486,
        '30': 32.21221061250925,
        '31': 32.212602586988154,
        '32': 32.21240970105743,
        '33': 32.212361190096686,
        '34': 32.212242834097935,
        '35': 32.21217894987254,
        '36': 32.21195588549911,
        '37': 32.21160830619931,
        '38': 32.21132959325814,
        '39': 32.21115037706141,
        '40': 32.21113198166533,
        '41': 32.21104465946583,
        '42': 32.21103507831394,
        '43': 32.211172389162364,
        '44': 32.21131677591741,
        '45': 32.2114319346214,
        '46': 32.21154368221034,
        '47': 32.21189026011926,
        '48': 32.21212313892557,
        '49': 32.212598934113714,
        '50': 32.212934231765864,
        '51': 32.213439771431474,
        '52': 32.21432268853712,
        '53': 32.215373850022786,
        '54': 32.21670633064772,
        '55': 32.217665051150185,
        '56': 32.21896048838393,
        '57': 32.220031795065594,
        '58': 32.22067489384251,
        '59': 32.221376625978735,
        '60': 32.22302147480114,
        '61': 32.224687996888214,
        '62': 32.225255639399684,
        '63': 32.22608136102138,
        '64': 32.22646111582482,
        '65': 32.226942725443834,
        '66': 32.22739486588682,
        '67': 32.22720818847257,
        '68': 32.22684985026775,
        '69': 32.22744917991263,
        '70': 32.226656538136716,
        '71': 32.22617932138789,
        '72': 32.225530675313365,
        '73': 32.22495040274327,
        '74': 32.22401222045301,
        '75': 32.2220899461213,
        '76': 32.22052185415232,
        '77': 32.21872983259122,
        '78': 32.21756467097413,
        '79': 32.21707140003428,
        '80': 32.2163970792552,
        '81': 32.215830695416216,
        '82': 32.21532587596275,
        '83': 32.21442884114221,
        '84': 32.21401608036192,
        '85': 32.21335351676293,
        '86': 32.21298809217546,
        '87': 32.21244508953543,
        '88': 32.2120496330502,
        '89': 32.21168558625645,
        '90': 32.2113632965972,
        '91': 32.21056371265345,
        '92': 32.21016808918172,
        '93': 32.20968808247131,
        '94': 32.20944579224419,
        '95': 32.20958076810276,
        '96': 32.20983524221557,
        '97': 32.21013703829735,
        '98': 32.21050677784909,
        '99': 32.210915931844795,
        '100': 32.21135823648461,
        '101': 32.21182142910372,
        '102': 32.2123104280996,
        '103': 32.2127092573178,
        '104': 32.213117979426315,
        '105': 32.2135017228824,
        '106': 32.2136923913583,
        '107': 32.21375340008579,
        '108': 32.21377680145148,
        '109': 32.21379058744922,
        '110': 32.21376649975182,
        '111': 32.21353668644205,
        '112': 32.21336676656045,
        '113': 32.21319605030431,
        '114': 32.21310063433274,
        '115': 32.21286878259113,
        '116': 32.21276753260239,
        '117': 32.2127864427344,
        '118': 32.21267877702007,
        '119': 32.21260785099464,
        '120': 32.212593183765144,
        '121': 32.21256166235136,
        '122': 32.21255003494315,
        '123': 32.21259462456211,
        '124': 32.21279261585779,
        '125': 32.213100817923646,
        '126': 32.21339697610207,
        '127': 32.21376357351782,
        '128': 32.21412496286687,
        '129': 32.2146420471683,
        '130': 32.21514662657956,
        '131': 32.21556562116456,
        '132': 32.21598090480709,
        '133': 32.21616329657803,
        '134': 32.2161972688443,
        '135': 32.216509228739326,
        '136': 32.216623787800565,
        '137': 32.21729333895911,
        '138': 32.217801283986965,
        '139': 32.21795131054409,
        '140': 32.2182602608591,
        '141': 32.21877808572943,
        '142': 32.219291423796946,
        '143': 32.219709246907364,
        '144': 32.21991295937406,
        '145': 32.220212281395575,
        '146': 32.220415920429815,
        '147': 32.220700317441555,
        '148': 32.22079016182273,
        '149': 32.220927115220256,
        '150': 32.2212719939194,
        '151': 32.22170136337204,
        '152': 32.222362130076945,
        '153': 32.22311538920231,
        '154': 32.22423341599911,
        '155': 32.22464259262419,
        '156': 32.2269041560798,
        '157': 32.22768962105331,
        '158': 32.22973202138012,
        '159': 32.23046165229816,
        '160': 32.23326140018718,
        '161': 32.23593846011487,
        '162': 32.23691697386316,
        '163': 32.2451341540442,
        '164': 32.245175931112875,
        '165': 32.24522110998729,
        '166': 32.24526967225453,
        '167': 32.24532047722014,
        '168': 32.24537122612117,
        '169': 32.24541874587696,
        '170': 32.245459431418126,
        '171': 32.24548976016797,
        '172': 32.245506793026564,
        '173': 32.245508591428596,
        '174': 32.24549450334183,
        '175': 32.245465295754315,
        '176': 32.245423132042205,
        '177': 32.245371406947726,
        '178': 32.24531445983364,
        '179': 32.24525719064082,
        '180': 32.24520460568618,
        '-180': 32.24520460568618,
        '-179': 32.24516132473606,
        '-178': 32.24513108769761,
        '-177': 32.2451163076744,
        '-176': 32.24511772401046,
        '-175': 32.24513421028694,
        '-174': 32.24516278437254,
        '-173': 32.24519884861747,
        '-172': 32.24523665889522,
        '-171': 32.24526998524596,
        '-170': 32.24529289069181,
        '-169': 32.24530052596756,
        '-168': 32.24528982357003,
        '-167': 32.24525997955102,
        '-166': 32.24521263710594,
        '-165': 32.245151729219714,
        '-164': 32.24508299145726,
        '-163': 32.245013210787356,
        '-162': 32.24494932170967,
        '-161': 32.24489748797606,
        '-160': 32.24486231132417,
        '-159': 32.244846287011015,
        '-158': 32.24484958361617,
        '-157': 32.24487016974245,
        '-156': 32.24490425339874,
        '-155': 32.24494695161246,
        '-154': 32.24499307663412,
        '-153': 32.2450379155187,
        '-152': 32.245077891787616,
        '-151': 32.24511102682359,
        '-150': 32.24513715713458,
        '-149': 32.245157903070414,
        '-148': 32.24517641744924,
        '-147': 32.24519696403516,
        '-146': 32.24522438469125,
        '-145': 32.24526351254073,
        '-144': 32.24531858118174,
        '-143': 32.245392672109574,
        '-142': 32.24548723800071,
        '-141': 32.245601739805565,
        '-140': 32.24573343895541,
        '-139': 32.24587738808412,
        '-138': 32.2460266620582,
        '-137': 32.245869301546215,
        '-136': 32.23968881759748,
        '-135': 32.23866770659847,
        '-134': 32.24324433455405,
        '-133': 32.23856758420405,
        '-132': 32.23837812671068,
        '-131': 32.240470892330684,
        '-130': 32.240692026598616,
        '-129': 32.240244121187466,
        '-128': 32.24015982203503,
        '-127': 32.23838748981717,
        '-126': 32.23752760518989,
        '-125': 32.238255938308484,
        '-124': 32.2379488779984,
        '-123': 32.23779066198012,
        '-122': 32.237626384015655,
        '-121': 32.23760059319594,
        '-120': 32.23707648979624,
        '-119': 32.23800492238889,
        '-118': 32.237149491845635,
        '-117': 32.23695967033391,
        '-116': 32.237876118893695,
        '-115': 32.24065739548358,
        '-114': 32.24512592032667,
        '-113': 32.24101260570786,
        '-112': 32.24415757974072,
        '-111': 32.24565135679355,
        '-110': 32.24557533224879,
        '-109': 32.24588931967069,
        '-108': 32.245889639296294,
        '-107': 32.24570766810617,
        '-106': 32.24571333569188,
        '-105': 32.24605080017422,
        '-104': 32.246133855968004,
        '-103': 32.24621514292711,
        '-102': 32.24628530877524,
        '-101': 32.24598316829077,
        '-100': 32.24610421328184,
        '-99': 32.243875873431044,
        '-98': 32.24027332447916,
        '-97': 32.23831718168706,
        '-96': 32.23886379230446,
        '-95': 32.238826041512645,
        '-94': 32.238463930428836,
        '-93': 32.23781310204399,
        '-92': 32.23778633402033,
        '-91': 32.2368566006213,
        '-90': 32.23633556841262,
        '-89': 32.23563166155224,
        '-88': 32.234687793610426,
        '-87': 32.23382062244707,
        '-86': 32.23409584066209,
        '-85': 32.234861442667956,
        '-84': 32.23553833238928,
        '-83': 32.23626830064984,
        '-82': 32.237074816028496,
        '-81': 32.237458315608514,
        '-80': 32.23702291206027,
        '-79': 32.23975329696393,
        '-78': 32.24201916477345,
        '-77': 32.240896785043766,
        '-76': 32.24012511219625,
        '-75': 32.23861451414031,
        '-74': 32.236888481304845,
        '-73': 32.234726512372376,
        '-72': 32.234593967425255,
        '-71': 32.23372143570042,
        '-70': 32.23292797095322,
        '-69': 32.231898723731234,
        '-68': 32.230614359080796,
        '-67': 32.22990064098077,
        '-66': 32.232766851271755,
        '-65': 32.234829168974,
        '-64': 32.24637318930918,
        '-63': 32.246224306816195,
        '-62': 32.24626698745875,
        '-61': 32.245843587828844,
        '-60': 32.24565322913974,
        '-59': 32.24558251239729,
        '-58': 32.245400886911185,
        '-57': 32.24531364134744,
        '-56': 32.24532360066461,
        '-55': 32.24542322677175,
        '-54': 32.24559601740052,
        '-53': 32.24581908985326,
        '-52': 32.246066539826984,
        '-51': 32.24631304674245,
        '-50': 32.246537166121854,
        '-49': 32.24672381212956,
        '-48': 32.24686557857284,
        '-47': 32.24696274905391,
        '-46': 32.247022071092545,
        '-45': 32.24705457562593,
        '-44': 32.247072876259246,
        '-43': 32.247088455585086,
        '-42': 32.24710942689503,
        '-41': 32.24713915348077,
        '-40': 32.2471759351625,
        '-39': 32.24721376546582,
        '-38': 32.24724396169198,
        '-37': 32.24725731129919,
        '-36': 32.24724629038551,
        '-35': 32.24720690864172,
        '-34': 32.2471398184063,
        '-33': 32.24705047590048,
        '-32': 32.24694833047155,
        '-31': 32.24684520638168,
        '-30': 32.24675319557396,
        '-29': 32.246682470513825,
        '-28': 32.24663943782798,
        '-27': 32.24662558554393,
        '-26': 32.24663724376233,
        '-25': 32.24666630687014,
        '-24': 32.246367791657434,
        '-23': 32.24642828240246,
        '-22': 32.24633148020675,
        '-21': 32.24624277503129,
        '-20': 32.246281895497425,
        '-19': 32.24617944317217,
        '-18': 32.2435568843949,
        '-17': 32.24142263576639,
        '-16': 32.238850678562194,
        '-15': 32.23586545534909,
        '-14': 32.23436863517192,
        '-13': 32.23280929285429,
        '-12': 32.22567785266011,
        '-11': 32.224067360505536,
        '-10': 32.22288094723274,
        '-9': 32.221798724208824,
        '-8': 32.22096350653804,
        '-7': 32.220912831239616,
        '-6': 32.220689254469896,
        '-5': 32.22008809492891,
        '-4': 32.22001370007766,
        '-3': 32.21976786466824,
        '-2': 32.21935690450108,
        '-1': 32.218792278328365
    },
    '-76': {
        '0': 32.21970479206623,
        '1': 32.219118910420576,
        '2': 32.21848728933896,
        '3': 32.218006134930924,
        '4': 32.21748251348409,
        '5': 32.21683947530251,
        '6': 32.2165915868933,
        '7': 32.21598860087998,
        '8': 32.21584477263312,
        '9': 32.215380125620776,
        '10': 32.215070155779834,
        '11': 32.214758644597886,
        '12': 32.214213852773426,
        '13': 32.21388585959041,
        '14': 32.21356256996836,
        '15': 32.21312580552983,
        '16': 32.212710869165875,
        '17': 32.212270473141615,
        '18': 32.212057318970636,
        '19': 32.2117448689291,
        '20': 32.21160703750973,
        '21': 32.211488957151055,
        '22': 32.21135234913416,
        '23': 32.21124643960177,
        '24': 32.21119854403861,
        '25': 32.21112488549103,
        '26': 32.21114517659633,
        '27': 32.21126086757812,
        '28': 32.21133567482843,
        '29': 32.21135846883796,
        '30': 32.21149358069006,
        '31': 32.21156487426611,
        '32': 32.211559781352236,
        '33': 32.211597781117995,
        '34': 32.21155548659671,
        '35': 32.21132831884128,
        '36': 32.21121360317199,
        '37': 32.21093063581107,
        '38': 32.21102460844836,
        '39': 32.211069384465446,
        '40': 32.211122398026326,
        '41': 32.211119416932895,
        '42': 32.21123884133188,
        '43': 32.21151843349456,
        '44': 32.2117646597859,
        '45': 32.21202753392689,
        '46': 32.21233781466703,
        '47': 32.21267632784096,
        '48': 32.21302401609155,
        '49': 32.21358378479636,
        '50': 32.21397169439605,
        '51': 32.214752747256135,
        '52': 32.215308488709844,
        '53': 32.21595945650534,
        '54': 32.21656070901191,
        '55': 32.217341213215214,
        '56': 32.217863157653035,
        '57': 32.218609593626056,
        '58': 32.2192864672965,
        '59': 32.21980348182987,
        '60': 32.220840261783245,
        '61': 32.221490695349345,
        '62': 32.22260872867455,
        '63': 32.22315859187839,
        '64': 32.2236612021065,
        '65': 32.22422238054566,
        '66': 32.2241586238833,
        '67': 32.224230125461624,
        '68': 32.22474069774983,
        '69': 32.22444698565186,
        '70': 32.224004279579695,
        '71': 32.22346125779914,
        '72': 32.22292751371337,
        '73': 32.22226105180642,
        '74': 32.22158388817832,
        '75': 32.22071661280302,
        '76': 32.21971347953556,
        '77': 32.21918483520121,
        '78': 32.2183778628669,
        '79': 32.21783101554524,
        '80': 32.21704051352571,
        '81': 32.2162969791841,
        '82': 32.215776231182005,
        '83': 32.21526647568579,
        '84': 32.21489600192237,
        '85': 32.21438681723222,
        '86': 32.213925252618196,
        '87': 32.21367911708679,
        '88': 32.213223404633325,
        '89': 32.212977528338264,
        '90': 32.21243751652934,
        '91': 32.21179395928781,
        '92': 32.21128435211308,
        '93': 32.21077739128029,
        '94': 32.21034680620227,
        '95': 32.21009675045058,
        '96': 32.21026993435398,
        '97': 32.21055865000317,
        '98': 32.210890087341134,
        '99': 32.211334122551406,
        '100': 32.21170943131785,
        '101': 32.21236192330372,
        '102': 32.212752786053116,
        '103': 32.21325904321843,
        '104': 32.21371188697951,
        '105': 32.214005768565684,
        '106': 32.21442501162076,
        '107': 32.21455333318521,
        '108': 32.21473174759142,
        '109': 32.21487507572641,
        '110': 32.214835508341324,
        '111': 32.21475507275246,
        '112': 32.2145578081141,
        '113': 32.214331496185274,
        '114': 32.21414306027535,
        '115': 32.21410689704157,
        '116': 32.21382791167175,
        '117': 32.21373224505898,
        '118': 32.21379476663018,
        '119': 32.213821671711905,
        '120': 32.21402602118014,
        '121': 32.21430235156001,
        '122': 32.214540832422536,
        '123': 32.21491186410348,
        '124': 32.21528238233596,
        '125': 32.2156114939189,
        '126': 32.2157501329177,
        '127': 32.216059219206585,
        '128': 32.21642990369906,
        '129': 32.21693087320499,
        '130': 32.217444114035196,
        '131': 32.21772492516486,
        '132': 32.21814901020455,
        '133': 32.21848745614618,
        '134': 32.21872420540682,
        '135': 32.218680498226284,
        '136': 32.21876123656295,
        '137': 32.21902445859554,
        '138': 32.21911060447316,
        '139': 32.219444581306455,
        '140': 32.21982237745089,
        '141': 32.22000791874399,
        '142': 32.22028955324154,
        '143': 32.22060248422872,
        '144': 32.22091332753743,
        '145': 32.22108926834164,
        '146': 32.22132230113057,
        '147': 32.221664300479425,
        '148': 32.22190515379276,
        '149': 32.22210725863338,
        '150': 32.222372434269836,
        '151': 32.22272009897273,
        '152': 32.223420332834635,
        '153': 32.22400392546307,
        '154': 32.22462660683686,
        '155': 32.22525156789009,
        '156': 32.22753005049809,
        '157': 32.22646499244432,
        '158': 32.22632868744766,
        '159': 32.22878869951126,
        '160': 32.23174622358727,
        '161': 32.235439459238286,
        '162': 32.24592155283958,
        '163': 32.246572044414165,
        '164': 32.246538264669766,
        '165': 32.24652503040913,
        '166': 32.246537254256054,
        '167': 32.2465767538855,
        '168': 32.24664194581366,
        '169': 32.246728002905755,
        '170': 32.246827438411465,
        '171': 32.246931036763435,
        '172': 32.24702900703983,
        '173': 32.24711220812762,
        '174': 32.24717328776005,
        '175': 32.24720759067867,
        '176': 32.24721372182811,
        '177': 32.24719369453787,
        '178': 32.247152645632426,
        '179': 32.24709815322365,
        '180': 32.24703924237759,
        '-180': 32.24703924237759,
        '-179': 32.24698520318655,
        '-178': 32.24694437029936,
        '-177': 32.24692301946931,
        '-176': 32.24692452390927,
        '-175': 32.24694888217347,
        '-174': 32.246992683200716,
        '-173': 32.247049518441585,
        '-172': 32.24711079261226,
        '-171': 32.247166831273496,
        '-170': 32.24720814254142,
        '-169': 32.24722666781926,
        '-168': 32.24721685614844,
        '-167': 32.247176419208,
        '-166': 32.24710666644369,
        '-165': 32.24701237655885,
        '-164': 32.24690122468568,
        '-163': 32.24678284494245,
        '-162': 32.24666765705002,
        '-161': 32.246565616191674,
        '-160': 32.2464850531094,
        '-159': 32.246431755744666,
        '-158': 32.24640840730311,
        '-157': 32.24641444423282,
        '-156': 32.24644633908108,
        '-155': 32.2464982560893,
        '-154': 32.24656297962424,
        '-153': 32.246632983234925,
        '-152': 32.246701493767326,
        '-151': 32.24676341115823,
        '-150': 32.246815968159574,
        '-149': 32.246859051113915,
        '-148': 32.24689514756719,
        '-147': 32.24692893316799,
        '-146': 32.244528824231615,
        '-145': 32.24122945591902,
        '-144': 32.240384303553014,
        '-143': 32.23867061039172,
        '-142': 32.237779281135595,
        '-141': 32.24050100634957,
        '-140': 32.23694115033224,
        '-139': 32.233787882209135,
        '-138': 32.232002961790144,
        '-137': 32.23272935300556,
        '-136': 32.22897635805552,
        '-135': 32.22931246130266,
        '-134': 32.231191171795196,
        '-133': 32.22522845943698,
        '-132': 32.229158939174596,
        '-131': 32.23185677118837,
        '-130': 32.23092040285197,
        '-129': 32.22806056684551,
        '-128': 32.2263138216419,
        '-127': 32.2262680621,
        '-126': 32.22558900427035,
        '-125': 32.22654001041719,
        '-124': 32.22669111900048,
        '-123': 32.22780306771271,
        '-122': 32.229115936266105,
        '-121': 32.22946160239251,
        '-120': 32.23035798356092,
        '-119': 32.231208865395224,
        '-118': 32.231660378230224,
        '-117': 32.2324319562667,
        '-116': 32.23277911347354,
        '-115': 32.23536941796342,
        '-114': 32.23579284406073,
        '-113': 32.23553998390382,
        '-112': 32.23723694138411,
        '-111': 32.23823041013967,
        '-110': 32.238962707193,
        '-109': 32.239328739841255,
        '-108': 32.23950453632314,
        '-107': 32.23959261511121,
        '-106': 32.239116167928,
        '-105': 32.238951421572544,
        '-104': 32.23881992051177,
        '-103': 32.23851349623307,
        '-102': 32.23814826744131,
        '-101': 32.239136581574826,
        '-100': 32.23943400830988,
        '-99': 32.23918368166128,
        '-98': 32.238925825465465,
        '-97': 32.239445254028425,
        '-96': 32.23924292090855,
        '-95': 32.239560018043626,
        '-94': 32.238957604375805,
        '-93': 32.23771328927974,
        '-92': 32.23688994549917,
        '-91': 32.23675866855212,
        '-90': 32.23600041087971,
        '-89': 32.23542607801734,
        '-88': 32.23507658323837,
        '-87': 32.23503264550975,
        '-86': 32.23567904393799,
        '-85': 32.2366339406987,
        '-84': 32.23768012103051,
        '-83': 32.238583821763726,
        '-82': 32.238680482294775,
        '-81': 32.239433565433764,
        '-80': 32.24209705156179,
        '-79': 32.243901956243484,
        '-78': 32.245306307457646,
        '-77': 32.2451086954021,
        '-76': 32.24460270710917,
        '-75': 32.243307953629156,
        '-74': 32.241982451460146,
        '-73': 32.24013493202237,
        '-72': 32.23931159682151,
        '-71': 32.23868848338129,
        '-70': 32.23742844628339,
        '-69': 32.241300955254665,
        '-68': 32.240808044391805,
        '-67': 32.245672603281555,
        '-66': 32.24795026102903,
        '-65': 32.24775972901622,
        '-64': 32.24760751558047,
        '-63': 32.24733384805683,
        '-62': 32.247047667052065,
        '-61': 32.24676028341155,
        '-60': 32.24649439904702,
        '-59': 32.24636246922588,
        '-58': 32.24617026364046,
        '-57': 32.24598109405947,
        '-56': 32.24601339348946,
        '-55': 32.24610501307763,
        '-54': 32.24643110780349,
        '-53': 32.24655563728255,
        '-52': 32.24671623598461,
        '-51': 32.24689675069381,
        '-50': 32.247081757599474,
        '-49': 32.24725818398832,
        '-48': 32.247416405415585,
        '-47': 32.24755071455242,
        '-46': 32.2476591655221,
        '-45': 32.24774289793771,
        '-44': 32.24780512120071,
        '-43': 32.24784997976123,
        '-42': 32.24788151859073,
        '-41': 32.24790292731301,
        '-40': 32.247916170583366,
        '-39': 32.24792202594472,
        '-38': 32.24792046549483,
        '-37': 32.24791125045916,
        '-36': 32.247894570524565,
        '-35': 32.247871558790294,
        '-34': 32.247844547488576,
        '-33': 32.24781699140084,
        '-32': 32.247793062097394,
        '-31': 32.247776991080364,
        '-30': 32.24777230152391,
        '-29': 32.24778111168727,
        '-28': 32.247803586649695,
        '-27': 32.24783778048043,
        '-26': 32.247090637576335,
        '-25': 32.24431259635772,
        '-24': 32.24200760638815,
        '-23': 32.24105103116958,
        '-22': 32.24240085594168,
        '-21': 32.24175675970914,
        '-20': 32.239545811719,
        '-19': 32.23777563204386,
        '-18': 32.23727154606439,
        '-17': 32.235977270700786,
        '-16': 32.23452641395568,
        '-15': 32.233540480312335,
        '-14': 32.231443658876394,
        '-13': 32.22878009375531,
        '-12': 32.2278901273772,
        '-11': 32.22663119767953,
        '-10': 32.22575058487498,
        '-9': 32.224932838505964,
        '-8': 32.22450047981121,
        '-7': 32.223857930396484,
        '-6': 32.22336425588561,
        '-5': 32.22287747824075,
        '-4': 32.222311265460924,
        '-3': 32.2218670385555,
        '-2': 32.22112300280172,
        '-1': 32.22049834664202
    },
    '-77': {
        '0': 32.22176586619582,
        '1': 32.221206545996985,
        '2': 32.220807415854765,
        '3': 32.22026170576968,
        '4': 32.21995453345322,
        '5': 32.21964109367092,
        '6': 32.21945747711617,
        '7': 32.21895246460317,
        '8': 32.218827306018206,
        '9': 32.21850199249043,
        '10': 32.21823911942135,
        '11': 32.21778040214392,
        '12': 32.217418965701796,
        '13': 32.21689719219618,
        '14': 32.2167102941613,
        '15': 32.216063227025245,
        '16': 32.21561851873091,
        '17': 32.215190054790135,
        '18': 32.21472187392856,
        '19': 32.21437739214575,
        '20': 32.2140536184939,
        '21': 32.21370372005104,
        '22': 32.213478202888446,
        '23': 32.213230748249025,
        '24': 32.213144916758395,
        '25': 32.21306905213026,
        '26': 32.21297177383858,
        '27': 32.21280188653283,
        '28': 32.21256015057708,
        '29': 32.2123506213368,
        '30': 32.212290193913894,
        '31': 32.2122156299883,
        '32': 32.21212784000061,
        '33': 32.212039860198246,
        '34': 32.21194587960233,
        '35': 32.211790216318875,
        '36': 32.21166871373647,
        '37': 32.21155538121176,
        '38': 32.211473772726485,
        '39': 32.211496787051225,
        '40': 32.211504230859155,
        '41': 32.211627440043074,
        '42': 32.2118757777916,
        '43': 32.212177351409046,
        '44': 32.21237930519991,
        '45': 32.21274303522976,
        '46': 32.213045349597145,
        '47': 32.213356357425006,
        '48': 32.213756640225384,
        '49': 32.214145252204595,
        '50': 32.214522464731694,
        '51': 32.21489873566426,
        '52': 32.2153653209294,
        '53': 32.21561937717735,
        '54': 32.216115608993,
        '55': 32.216621594819856,
        '56': 32.21708661370798,
        '57': 32.2175204478829,
        '58': 32.21845820501986,
        '59': 32.21885866730906,
        '60': 32.21954977680847,
        '61': 32.220318603028716,
        '62': 32.22082062661004,
        '63': 32.22126691070621,
        '64': 32.22151464222783,
        '65': 32.22113796138925,
        '66': 32.221176346138215,
        '67': 32.22109309384583,
        '68': 32.22117056942241,
        '69': 32.221015099568355,
        '70': 32.220779871873376,
        '71': 32.22077098742985,
        '72': 32.22083150069296,
        '73': 32.22036192561452,
        '74': 32.219884909754576,
        '75': 32.21978220094003,
        '76': 32.21947670351883,
        '77': 32.219238254666486,
        '78': 32.218941170339484,
        '79': 32.21795235301002,
        '80': 32.217178806225725,
        '81': 32.21662543313695,
        '82': 32.216111359927304,
        '83': 32.21554277086504,
        '84': 32.2153583060395,
        '85': 32.21506616291775,
        '86': 32.21480079045282,
        '87': 32.21444607210018,
        '88': 32.214020937327014,
        '89': 32.21377185490668,
        '90': 32.21309220690186,
        '91': 32.2130798250839,
        '92': 32.21240287656601,
        '93': 32.21192761570176,
        '94': 32.21146252251243,
        '95': 32.21114060307835,
        '96': 32.211124013246476,
        '97': 32.211258781470356,
        '98': 32.21156757244366,
        '99': 32.21184455046141,
        '100': 32.2123610287111,
        '101': 32.212735273070685,
        '102': 32.21321504476902,
        '103': 32.21388198693988,
        '104': 32.214067383759854,
        '105': 32.21427370671336,
        '106': 32.214379074892626,
        '107': 32.214750797967326,
        '108': 32.2149831690936,
        '109': 32.215293703534584,
        '110': 32.215481995953965,
        '111': 32.21555639508212,
        '112': 32.21561165086603,
        '113': 32.2155746891457,
        '114': 32.21555662909539,
        '115': 32.21553751086076,
        '116': 32.215536258346596,
        '117': 32.21555852057678,
        '118': 32.21573673838634,
        '119': 32.21576411917117,
        '120': 32.21599459997487,
        '121': 32.216241983335856,
        '122': 32.216648721245555,
        '123': 32.21682889621346,
        '124': 32.21707091975608,
        '125': 32.21731929485542,
        '126': 32.21753936486615,
        '127': 32.21771849171732,
        '128': 32.21832180193811,
        '129': 32.21874631245516,
        '130': 32.21930203354451,
        '131': 32.219766468899564,
        '132': 32.22003113028423,
        '133': 32.220444653402765,
        '134': 32.220710187848134,
        '135': 32.221026997725936,
        '136': 32.221028594750955,
        '137': 32.221358755055405,
        '138': 32.221397686432276,
        '139': 32.221414161448585,
        '140': 32.22145406939765,
        '141': 32.22163350948864,
        '142': 32.22173501120624,
        '143': 32.22203555854563,
        '144': 32.222205944844205,
        '145': 32.22226969451593,
        '146': 32.22241081975073,
        '147': 32.22264026404894,
        '148': 32.222815636978304,
        '149': 32.22310530814828,
        '150': 32.22336223134621,
        '151': 32.22376024036796,
        '152': 32.22414802349918,
        '153': 32.22455481096439,
        '154': 32.22498927612281,
        '155': 32.225309102215796,
        '156': 32.226010751531305,
        '157': 32.22665299334522,
        '158': 32.22716192539302,
        '159': 32.22921724148208,
        '160': 32.23242888564334,
        '161': 32.235448947177595,
        '162': 32.24519736977229,
        '163': 32.24866499206824,
        '164': 32.248574777485594,
        '165': 32.24849940063689,
        '166': 32.24844697177329,
        '167': 32.2484223788003,
        '168': 32.24842678697298,
        '169': 32.24845759043253,
        '170': 32.24850885149108,
        '171': 32.248572131568736,
        '172': 32.248637606832254,
        '173': 32.24869532765782,
        '174': 32.24873646518265,
        '175': 32.24875439213582,
        '176': 32.248745468038514,
        '177': 32.248709437583756,
        '178': 32.24864940048398,
        '179': 32.24857136500584,
        '180': 32.2484834490254,
        '-180': 32.2484834490254,
        '-179': 32.2483948353278,
        '-178': 32.24831461677722,
        '-177': 32.24825067840982,
        '-176': 32.24820875618004,
        '-175': 32.248191787127055,
        '-174': 32.24819962647913,
        '-173': 32.248229158872874,
        '-172': 32.24827477978905,
        '-171': 32.248329176189934,
        '-170': 32.24838429832059,
        '-169': 32.24843239246026,
        '-168': 32.24846695980483,
        '-167': 32.24848351996424,
        '-166': 32.24848008667813,
        '-165': 32.24845730408566,
        '-164': 32.248418238503184,
        '-163': 32.24836786673751,
        '-162': 32.248312341248685,
        '-161': 32.248258139791794,
        '-160': 32.248211219084716,
        '-159': 32.248176287343554,
        '-158': 32.24815629029336,
        '-157': 32.24815217270564,
        '-156': 32.24816293743773,
        '-155': 32.24818598204103,
        '-154': 32.2482176550121,
        '-153': 32.24825394470571,
        '-152': 32.24829119745535,
        '-151': 32.248326760305325,
        '-150': 32.24470781196735,
        '-149': 32.24702431174347,
        '-148': 32.24842035532476,
        '-147': 32.24836311308023,
        '-146': 32.24849573455785,
        '-145': 32.24385608448377,
        '-144': 32.24113440899086,
        '-143': 32.239280626493226,
        '-142': 32.23576685305557,
        '-141': 32.2335641182905,
        '-140': 32.23377042817931,
        '-139': 32.23297176121135,
        '-138': 32.23235362800388,
        '-137': 32.23149280254257,
        '-136': 32.230765324534154,
        '-135': 32.23015387908734,
        '-134': 32.22919793441492,
        '-133': 32.22924046746154,
        '-132': 32.229229591164284,
        '-131': 32.22952272364422,
        '-130': 32.229083997385615,
        '-129': 32.22851857143442,
        '-128': 32.22803811540553,
        '-127': 32.22799583814653,
        '-126': 32.22018243204304,
        '-125': 32.22714480501892,
        '-124': 32.227701296406444,
        '-123': 32.228018494276405,
        '-122': 32.22859985526644,
        '-121': 32.22950144570439,
        '-120': 32.23021116298021,
        '-119': 32.23147965378846,
        '-118': 32.232186783080785,
        '-117': 32.23176918651195,
        '-116': 32.23412593522356,
        '-115': 32.234925106677395,
        '-114': 32.23554190564916,
        '-113': 32.236362806070154,
        '-112': 32.23680511013248,
        '-111': 32.23707647836731,
        '-110': 32.23697032133117,
        '-109': 32.23698767511555,
        '-108': 32.23698114767232,
        '-107': 32.2369535131954,
        '-106': 32.236400241217375,
        '-105': 32.236513827571855,
        '-104': 32.23630149056298,
        '-103': 32.23583144554777,
        '-102': 32.23554590851168,
        '-101': 32.235078938549066,
        '-100': 32.23488455804548,
        '-99': 32.2348420000687,
        '-98': 32.23493306070975,
        '-97': 32.23515087057022,
        '-96': 32.23490315167462,
        '-95': 32.23455779603702,
        '-94': 32.234734552912,
        '-93': 32.23464711373061,
        '-92': 32.23477038902682,
        '-91': 32.23513239970832,
        '-90': 32.23590043082812,
        '-89': 32.236501849416925,
        '-88': 32.2376869277516,
        '-87': 32.2390326728208,
        '-86': 32.24018698105629,
        '-85': 32.24106195061886,
        '-84': 32.241480907253724,
        '-83': 32.24132076742194,
        '-82': 32.24097777909313,
        '-81': 32.23783744522859,
        '-80': 32.236722112565566,
        '-79': 32.237225760722005,
        '-78': 32.24175429725812,
        '-77': 32.247446815335415,
        '-76': 32.247749849008905,
        '-75': 32.24767509475269,
        '-74': 32.24769057935926,
        '-73': 32.24775502905981,
        '-72': 32.24781339331934,
        '-71': 32.247898440912216,
        '-70': 32.2480103392749,
        '-69': 32.24808748987158,
        '-68': 32.248139332320875,
        '-67': 32.248166785773606,
        '-66': 32.24819374600128,
        '-65': 32.24818699103498,
        '-64': 32.24811728564705,
        '-63': 32.248020009762264,
        '-62': 32.247863130007254,
        '-61': 32.247748525595924,
        '-60': 32.24761750032804,
        '-59': 32.24753257626849,
        '-58': 32.247423435379346,
        '-57': 32.24738905317896,
        '-56': 32.247373466749465,
        '-55': 32.247428173639996,
        '-54': 32.24753027188489,
        '-53': 32.247653652329376,
        '-52': 32.24776976886478,
        '-51': 32.24790927826113,
        '-50': 32.248041806255465,
        '-49': 32.24839039340978,
        '-48': 32.24849192232922,
        '-47': 32.248572899155924,
        '-46': 32.24862961731028,
        '-45': 32.24866074075487,
        '-44': 32.24866709808488,
        '-43': 32.24865130932632,
        '-42': 32.24861732250511,
        '-41': 32.24856993338679,
        '-40': 32.24851434647902,
        '-39': 32.24845581281608,
        '-38': 32.24839935556361,
        '-37': 32.24834957333291,
        '-36': 32.24831049737912,
        '-35': 32.248285474777944,
        '-34': 32.248277055169005,
        '-33': 32.24828687150282,
        '-32': 32.248315534738744,
        '-31': 32.243126454171595,
        '-30': 32.23976341963037,
        '-29': 32.23806133729753,
        '-28': 32.23697540750142,
        '-27': 32.235793741972635,
        '-26': 32.23448129854292,
        '-25': 32.23350863496822,
        '-24': 32.23347845385706,
        '-23': 32.23309404725402,
        '-22': 32.23262677627096,
        '-21': 32.232389878383145,
        '-20': 32.23210139457862,
        '-19': 32.23178359839645,
        '-18': 32.23130758367404,
        '-17': 32.2309790273254,
        '-16': 32.23068841599768,
        '-15': 32.23025430880755,
        '-14': 32.22964545411012,
        '-13': 32.22883924820189,
        '-12': 32.2279332070056,
        '-11': 32.22717581593576,
        '-10': 32.22706830022684,
        '-9': 32.226687774735794,
        '-8': 32.22629574215418,
        '-7': 32.22574187836582,
        '-6': 32.225161712238375,
        '-5': 32.22458251092418,
        '-4': 32.22382197585417,
        '-3': 32.223315465972824,
        '-2': 32.22275159758339,
        '-1': 32.222000015410856
    },
    '-78': {
        '0': 32.22364832291227,
        '1': 32.22336808979398,
        '2': 32.22282464643241,
        '3': 32.222850180414326,
        '4': 32.22254795943603,
        '5': 32.22212918918829,
        '6': 32.22190486110932,
        '7': 32.221630109356006,
        '8': 32.22127282736566,
        '9': 32.22098464825458,
        '10': 32.22058638201267,
        '11': 32.2201548785378,
        '12': 32.21976057426728,
        '13': 32.21928546045942,
        '14': 32.218837167695895,
        '15': 32.2183641239419,
        '16': 32.218109394999,
        '17': 32.217437586853045,
        '18': 32.2171878027302,
        '19': 32.2169348269962,
        '20': 32.21661494454852,
        '21': 32.21626266145967,
        '22': 32.21598018088895,
        '23': 32.21574571203819,
        '24': 32.21557542794888,
        '25': 32.215301944105654,
        '26': 32.215150587277854,
        '27': 32.21517479515889,
        '28': 32.21474169809627,
        '29': 32.21478488987441,
        '30': 32.214663958576395,
        '31': 32.21430576880323,
        '32': 32.2143056229341,
        '33': 32.214169473931875,
        '34': 32.21388936062099,
        '35': 32.21373083917055,
        '36': 32.21352546628823,
        '37': 32.21340768993294,
        '38': 32.213107496794976,
        '39': 32.212939859881,
        '40': 32.21289567029976,
        '41': 32.212833645783235,
        '42': 32.21284395800314,
        '43': 32.21312724732954,
        '44': 32.21330825028353,
        '45': 32.21351653522678,
        '46': 32.21389199889063,
        '47': 32.214060030547365,
        '48': 32.214202055271336,
        '49': 32.21439928151783,
        '50': 32.21469330760818,
        '51': 32.214863947765416,
        '52': 32.21502480521758,
        '53': 32.2152494752093,
        '54': 32.21568235807978,
        '55': 32.21596298342287,
        '56': 32.21633620448659,
        '57': 32.21677365905794,
        '58': 32.21722592941489,
        '59': 32.21746082686365,
        '60': 32.21783058012154,
        '61': 32.2179593501047,
        '62': 32.21808613137831,
        '63': 32.218125959886414,
        '64': 32.217932668903,
        '65': 32.21820834511707,
        '66': 32.21821094024923,
        '67': 32.218219229940146,
        '68': 32.21819998944945,
        '69': 32.21831344845288,
        '70': 32.21864079509677,
        '71': 32.218406472141105,
        '72': 32.21861479592711,
        '73': 32.219109950479094,
        '74': 32.219242557056575,
        '75': 32.21888922579887,
        '76': 32.21906745696247,
        '77': 32.21862276004578,
        '78': 32.21842844718274,
        '79': 32.21821437143194,
        '80': 32.217485230032416,
        '81': 32.21700452238384,
        '82': 32.21676527924031,
        '83': 32.21661635968659,
        '84': 32.216242806719094,
        '85': 32.21563152228812,
        '86': 32.21526475352129,
        '87': 32.2148986511744,
        '88': 32.214574855574504,
        '89': 32.21468225200448,
        '90': 32.21413833122191,
        '91': 32.213735146719266,
        '92': 32.21342020702654,
        '93': 32.21296321473226,
        '94': 32.2124400452062,
        '95': 32.212180559452705,
        '96': 32.21211084547956,
        '97': 32.212236517323575,
        '98': 32.21233858508734,
        '99': 32.21240608547458,
        '100': 32.21263507868758,
        '101': 32.21312571736306,
        '102': 32.213579749896745,
        '103': 32.21397681460208,
        '104': 32.21451506520873,
        '105': 32.215057428187684,
        '106': 32.21519316456561,
        '107': 32.21525939071162,
        '108': 32.215878603076725,
        '109': 32.21623158966541,
        '110': 32.216503215653546,
        '111': 32.21675139588484,
        '112': 32.21703840605943,
        '113': 32.217197994569794,
        '114': 32.21737047553316,
        '115': 32.21744585282236,
        '116': 32.217507487604195,
        '117': 32.21755809939583,
        '118': 32.217700581056796,
        '119': 32.21767256707715,
        '120': 32.21789596675012,
        '121': 32.21804268428758,
        '122': 32.21813506455239,
        '123': 32.21823290557741,
        '124': 32.218504429616765,
        '125': 32.21870187188169,
        '126': 32.218828225519744,
        '127': 32.219168243084276,
        '128': 32.219632843080134,
        '129': 32.22006243760201,
        '130': 32.22064152481907,
        '131': 32.22112141221832,
        '132': 32.22161846011577,
        '133': 32.22206885695755,
        '134': 32.222511616404475,
        '135': 32.222805746735915,
        '136': 32.223024315189825,
        '137': 32.22315126334756,
        '138': 32.223283321494215,
        '139': 32.22332673095531,
        '140': 32.22347167946012,
        '141': 32.2236666807534,
        '142': 32.2234870337333,
        '143': 32.22374948914236,
        '144': 32.223765670941255,
        '145': 32.22385511298395,
        '146': 32.22396169311401,
        '147': 32.224178030642854,
        '148': 32.224513027351385,
        '149': 32.225063367510124,
        '150': 32.22543805121644,
        '151': 32.226000771715086,
        '152': 32.22645676633953,
        '153': 32.22703546046552,
        '154': 32.22756193302094,
        '155': 32.22825643005873,
        '156': 32.22915918582732,
        '157': 32.22915963436449,
        '158': 32.22725140868989,
        '159': 32.22912031273796,
        '160': 32.23025644300589,
        '161': 32.230625154815705,
        '162': 32.23201568584643,
        '163': 32.23724044943684,
        '164': 32.23942980278496,
        '165': 32.25026128239049,
        '166': 32.25017219464979,
        '167': 32.2500990802023,
        '168': 32.250045504156375,
        '169': 32.25001204113835,
        '170': 32.24999639349026,
        '171': 32.24999387032979,
        '172': 32.24999812128086,
        '173': 32.25000202888038,
        '174': 32.24999865194059,
        '175': 32.24998210944776,
        '176': 32.24994830492736,
        '177': 32.249895413052535,
        '178': 32.249824080703235,
        '179': 32.24973732981987,
        '180': 32.249640184879894,
        '-180': 32.249640184879894,
        '-179': 32.24953907937543,
        '-178': 32.24944111959157,
        '-177': 32.24935329761835,
        '-176': 32.24928174755531,
        '-175': 32.249231129437426,
        '-174': 32.24920420610277,
        '-173': 32.24920165179979,
        '-172': 32.24922210137636,
        '-171': 32.249262419309645,
        '-170': 32.24931814235401,
        '-169': 32.24938403126302,
        '-168': 32.249454657901616,
        '-167': 32.249524954842464,
        '-166': 32.24959066464443,
        '-165': 32.2496486436415,
        '-164': 32.24969699749969,
        '-163': 32.249735049783254,
        '-162': 32.24976316699314,
        '-161': 32.24978248109152,
        '-160': 32.249794562878314,
        '-159': 32.24980109612981,
        '-158': 32.249803604689774,
        '-157': 32.24663624930301,
        '-156': 32.2450252564995,
        '-155': 32.24418156597406,
        '-154': 32.24520787308543,
        '-153': 32.243339571767734,
        '-152': 32.2421176664765,
        '-151': 32.24305974335783,
        '-150': 32.24455860597629,
        '-149': 32.245219980449264,
        '-148': 32.243488316504326,
        '-147': 32.24245089102338,
        '-146': 32.241958059096085,
        '-145': 32.24286107903268,
        '-144': 32.239821825296175,
        '-143': 32.23991908882673,
        '-142': 32.24006783389545,
        '-141': 32.24024582021864,
        '-140': 32.239317264930705,
        '-139': 32.23847144605818,
        '-138': 32.23796684654054,
        '-137': 32.23754615318929,
        '-136': 32.23634565307392,
        '-135': 32.236030975703926,
        '-134': 32.23535631492478,
        '-133': 32.23470569600315,
        '-132': 32.23433322945757,
        '-131': 32.233998974099045,
        '-130': 32.233595670436664,
        '-129': 32.23322913111768,
        '-128': 32.23275258581332,
        '-127': 32.23290843001937,
        '-126': 32.23297229415501,
        '-125': 32.232744125214296,
        '-124': 32.23270952218567,
        '-123': 32.23278625930864,
        '-122': 32.2328702760512,
        '-121': 32.23298764134973,
        '-120': 32.23297157367711,
        '-119': 32.232847203554236,
        '-118': 32.232963688118346,
        '-117': 32.2332264174415,
        '-116': 32.233734389632446,
        '-115': 32.234234535217205,
        '-114': 32.23461722667642,
        '-113': 32.23492624775156,
        '-112': 32.23527753336923,
        '-111': 32.23559584836975,
        '-110': 32.235705240603444,
        '-109': 32.23572257784715,
        '-108': 32.235784052391885,
        '-107': 32.235196064631275,
        '-106': 32.235092299309315,
        '-105': 32.23502865236598,
        '-104': 32.2348223168854,
        '-103': 32.235067289899504,
        '-102': 32.234447233793,
        '-101': 32.234333037685595,
        '-100': 32.234327548837015,
        '-99': 32.23427647361403,
        '-98': 32.233874496285615,
        '-97': 32.233817503992626,
        '-96': 32.23385204100288,
        '-95': 32.23424052485251,
        '-94': 32.23435597869472,
        '-93': 32.23448096425725,
        '-92': 32.23440225519932,
        '-91': 32.23404747970787,
        '-90': 32.23412213574792,
        '-89': 32.23318740032547,
        '-88': 32.23142183108125,
        '-87': 32.23058143299949,
        '-86': 32.220259875009575,
        '-85': 32.24552339970018,
        '-84': 32.248435447241015,
        '-83': 32.2454346211894,
        '-82': 32.24511444825076,
        '-81': 32.24900530091555,
        '-80': 32.24902679865095,
        '-79': 32.24883753661076,
        '-78': 32.248979843339185,
        '-77': 32.249338357461085,
        '-76': 32.24777458228946,
        '-75': 32.24647777737173,
        '-74': 32.247674902109175,
        '-73': 32.24897057847395,
        '-72': 32.24898908788244,
        '-71': 32.24897177964174,
        '-70': 32.24897572576417,
        '-69': 32.24899658903276,
        '-68': 32.248969218913,
        '-67': 32.24905137475808,
        '-66': 32.24907825600673,
        '-65': 32.24906847408529,
        '-64': 32.24908272370273,
        '-63': 32.24905189145452,
        '-62': 32.24905998218725,
        '-61': 32.249020155042246,
        '-60': 32.248987652910536,
        '-59': 32.248977188325696,
        '-58': 32.248992670463004,
        '-57': 32.2490064483781,
        '-56': 32.24904991199734,
        '-55': 32.24911215872227,
        '-54': 32.24918041836563,
        '-53': 32.24924023574224,
        '-52': 32.24933652830724,
        '-51': 32.24941223995229,
        '-50': 32.2495719335497,
        '-49': 32.249869952656084,
        '-48': 32.247265078645995,
        '-47': 32.24540617911441,
        '-46': 32.24562625221178,
        '-45': 32.24879476200398,
        '-44': 32.2500863334901,
        '-43': 32.250019747963165,
        '-42': 32.24993503716366,
        '-41': 32.24983763707288,
        '-40': 32.24973370484283,
        '-39': 32.24962969189777,
        '-38': 32.24953192167866,
        '-37': 32.249446198081436,
        '-36': 32.24937746571126,
        '-35': 32.24383140155604,
        '-34': 32.241452531684914,
        '-33': 32.23843131584365,
        '-32': 32.2389599233417,
        '-31': 32.23953084575975,
        '-30': 32.24032315679605,
        '-29': 32.24066613257563,
        '-28': 32.240817982619774,
        '-27': 32.240571725917825,
        '-26': 32.239962976359685,
        '-25': 32.239715203859944,
        '-24': 32.23903615118059,
        '-23': 32.23852947776658,
        '-22': 32.23749551542838,
        '-21': 32.23616562463701,
        '-20': 32.23506509212468,
        '-19': 32.23415349283385,
        '-18': 32.233441197374496,
        '-17': 32.23303966594006,
        '-16': 32.23269624670627,
        '-15': 32.23231975820573,
        '-14': 32.2320613161503,
        '-13': 32.231617035238436,
        '-12': 32.230996474914114,
        '-11': 32.23043189973616,
        '-10': 32.23000469666358,
        '-9': 32.22949428462457,
        '-8': 32.22887349798748,
        '-7': 32.22801573885662,
        '-6': 32.22725028339056,
        '-5': 32.226594437085225,
        '-4': 32.225914812372785,
        '-3': 32.22536071769279,
        '-2': 32.225070606975585,
        '-1': 32.22424298555573
    },
    '-79': {
        '0': 32.22616349412774,
        '1': 32.22568631312485,
        '2': 32.225258906880306,
        '3': 32.22486925699115,
        '4': 32.22444350252055,
        '5': 32.224108722284896,
        '6': 32.223688420914215,
        '7': 32.2232886123902,
        '8': 32.22296506095249,
        '9': 32.22274389432325,
        '10': 32.2221068742871,
        '11': 32.22176917172401,
        '12': 32.221255622823364,
        '13': 32.22099128540821,
        '14': 32.22064499507753,
        '15': 32.22020972752624,
        '16': 32.219992069330644,
        '17': 32.21958184887655,
        '18': 32.21929568631015,
        '19': 32.21885389304627,
        '20': 32.21857158769096,
        '21': 32.218429665259414,
        '22': 32.218125268045796,
        '23': 32.218081411480284,
        '24': 32.21782177778366,
        '25': 32.21743484512414,
        '26': 32.21731175233945,
        '27': 32.217167297030834,
        '28': 32.216928670706274,
        '29': 32.216624649075406,
        '30': 32.21643621976046,
        '31': 32.216211979787396,
        '32': 32.21599309821225,
        '33': 32.215801131461575,
        '34': 32.21561760856829,
        '35': 32.21554530281951,
        '36': 32.21532335706318,
        '37': 32.2151447983727,
        '38': 32.21496982345436,
        '39': 32.21490932645561,
        '40': 32.21468977400133,
        '41': 32.214521539117364,
        '42': 32.21445291137641,
        '43': 32.21435009758771,
        '44': 32.214341774830814,
        '45': 32.214415494180656,
        '46': 32.214438142260754,
        '47': 32.21460052641163,
        '48': 32.214619799340944,
        '49': 32.21473931235058,
        '50': 32.2147995976773,
        '51': 32.214995629484505,
        '52': 32.215068889647526,
        '53': 32.21512496474568,
        '54': 32.215168766385425,
        '55': 32.215295982041276,
        '56': 32.2154403649027,
        '57': 32.21551471330881,
        '58': 32.21562249017117,
        '59': 32.21575480397713,
        '60': 32.21593156955814,
        '61': 32.215888544698814,
        '62': 32.21610704159686,
        '63': 32.21610812054474,
        '64': 32.21618926726448,
        '65': 32.216011247737306,
        '66': 32.216093209130136,
        '67': 32.21569197759597,
        '68': 32.21562095157523,
        '69': 32.21578553719808,
        '70': 32.21593080291322,
        '71': 32.21576290011555,
        '72': 32.21574767171576,
        '73': 32.215665180549614,
        '74': 32.21564037136781,
        '75': 32.21532396882004,
        '76': 32.215286798521305,
        '77': 32.21527066847075,
        '78': 32.21550177235165,
        '79': 32.216134339772594,
        '80': 32.21583533567766,
        '81': 32.2156550289295,
        '82': 32.21599504784943,
        '83': 32.21582008751348,
        '84': 32.21566010437961,
        '85': 32.21575118040467,
        '86': 32.21560136829469,
        '87': 32.21545718378808,
        '88': 32.21533385667892,
        '89': 32.215086700611295,
        '90': 32.214906935639156,
        '91': 32.214614356466576,
        '92': 32.21424369566812,
        '93': 32.21394323723221,
        '94': 32.21373185485244,
        '95': 32.21371056575955,
        '96': 32.213637692285104,
        '97': 32.213675259345784,
        '98': 32.213842817597516,
        '99': 32.21418834416141,
        '100': 32.214474827376286,
        '101': 32.214754979530575,
        '102': 32.21509855585289,
        '103': 32.21543098746348,
        '104': 32.21581623572525,
        '105': 32.216255524074526,
        '106': 32.21668798895654,
        '107': 32.217011611777146,
        '108': 32.21738710411218,
        '109': 32.21777417306987,
        '110': 32.21795222514035,
        '111': 32.21819805112639,
        '112': 32.218467669672584,
        '113': 32.21870968233106,
        '114': 32.21890567094823,
        '115': 32.219069958008475,
        '116': 32.21926939158986,
        '117': 32.219461075737236,
        '118': 32.219592861545436,
        '119': 32.21969363383138,
        '120': 32.219822113047854,
        '121': 32.22003596971988,
        '122': 32.2202197037308,
        '123': 32.220427696725466,
        '124': 32.22058103327728,
        '125': 32.22081076057384,
        '126': 32.22097332764603,
        '127': 32.22121623573273,
        '128': 32.221564424410715,
        '129': 32.22195096698884,
        '130': 32.222379109438954,
        '131': 32.22278139488457,
        '132': 32.223323212207184,
        '133': 32.22373657185437,
        '134': 32.22419939385638,
        '135': 32.224527664386294,
        '136': 32.2247818462446,
        '137': 32.22509521635683,
        '138': 32.22524951321278,
        '139': 32.22531122979026,
        '140': 32.225570520601494,
        '141': 32.22565204025018,
        '142': 32.225534498446486,
        '143': 32.22551947042117,
        '144': 32.22574609310936,
        '145': 32.2257162561979,
        '146': 32.22584834396218,
        '147': 32.22624546486236,
        '148': 32.22646311671022,
        '149': 32.226639419173935,
        '150': 32.227122027140375,
        '151': 32.2277517580423,
        '152': 32.228286862069375,
        '153': 32.229403881368796,
        '154': 32.23024358488865,
        '155': 32.23214023736866,
        '156': 32.234357701695785,
        '157': 32.23839644552237,
        '158': 32.24020573800296,
        '159': 32.243121655001964,
        '160': 32.24643679649907,
        '161': 32.24398041782166,
        '162': 32.251282948529806,
        '163': 32.25140858885376,
        '164': 32.25122732924642,
        '165': 32.25109102855934,
        '166': 32.25108764842628,
        '167': 32.25113186075316,
        '168': 32.25110631133189,
        '169': 32.25106373036774,
        '170': 32.251044743543495,
        '171': 32.251057817012466,
        '172': 32.25104925699434,
        '173': 32.25107566814856,
        '174': 32.251081937354996,
        '175': 32.251083899767245,
        '176': 32.251077802462866,
        '177': 32.25106095324649,
        '178': 32.25103199721196,
        '179': 32.25099103309302,
        '180': 32.250939566271164,
        '-180': 32.250939566271164,
        '-179': 32.25088031311797,
        '-178': 32.25081688653385,
        '-177': 32.250753403530624,
        '-176': 32.25069406147924,
        '-175': 32.25064272985308,
        '-174': 32.250602599257526,
        '-173': 32.250575920164955,
        '-172': 32.25056385144944,
        '-171': 32.250566425182235,
        '-170': 32.25058262090703,
        '-169': 32.250610531301064,
        '-168': 32.25063747922609,
        '-167': 32.25068073710552,
        '-166': 32.2507372306867,
        '-165': 32.25078377670564,
        '-164': 32.25035238253727,
        '-163': 32.24757945868105,
        '-162': 32.24970663455535,
        '-161': 32.25086565113788,
        '-160': 32.25079259441197,
        '-159': 32.2507824447161,
        '-158': 32.25077481092652,
        '-157': 32.25078042474821,
        '-156': 32.25092153468857,
        '-155': 32.25088545341761,
        '-154': 32.2505721072077,
        '-153': 32.24930487874533,
        '-152': 32.249250001552355,
        '-151': 32.249528449158625,
        '-150': 32.2489882577009,
        '-149': 32.24664973035167,
        '-148': 32.245558419396204,
        '-147': 32.24496657129434,
        '-146': 32.24427816724545,
        '-145': 32.24340258314217,
        '-144': 32.2435126430817,
        '-143': 32.2442945258683,
        '-142': 32.24485783552413,
        '-141': 32.24472641013314,
        '-140': 32.245092167694665,
        '-139': 32.24470021481206,
        '-138': 32.24452008460816,
        '-137': 32.244318409743286,
        '-136': 32.242719900300834,
        '-135': 32.24131190662403,
        '-134': 32.24070140092737,
        '-133': 32.24028288995182,
        '-132': 32.239269480605124,
        '-131': 32.23862300689687,
        '-130': 32.23799139198217,
        '-129': 32.23703247695388,
        '-128': 32.236100904333014,
        '-127': 32.235873955814434,
        '-126': 32.235835677994885,
        '-125': 32.236035118507814,
        '-124': 32.235782633802806,
        '-123': 32.23593415832125,
        '-122': 32.23586949812427,
        '-121': 32.23570608618799,
        '-120': 32.23545037568022,
        '-119': 32.23499820604337,
        '-118': 32.23450920985121,
        '-117': 32.234113915639895,
        '-116': 32.2337117481226,
        '-115': 32.23315308831851,
        '-114': 32.23281526931966,
        '-113': 32.233036218258455,
        '-112': 32.23320482352391,
        '-111': 32.23304405649874,
        '-110': 32.233186146186334,
        '-109': 32.23293939053027,
        '-108': 32.23289413898179,
        '-107': 32.2330333688065,
        '-106': 32.23306590766581,
        '-105': 32.23292121400468,
        '-104': 32.23263828905259,
        '-103': 32.2327396710174,
        '-102': 32.23228193028158,
        '-101': 32.23229091179661,
        '-100': 32.23242816679205,
        '-99': 32.23255715405941,
        '-98': 32.23271336074026,
        '-97': 32.232973243556536,
        '-96': 32.23341398708434,
        '-95': 32.23345621655078,
        '-94': 32.23354270981008,
        '-93': 32.23369235564062,
        '-92': 32.23334817948038,
        '-91': 32.2328742399636,
        '-90': 32.23224061750864,
        '-89': 32.231700957582866,
        '-88': 32.23239913769305,
        '-87': 32.234225122801405,
        '-86': 32.238839395986524,
        '-85': 32.24339154966734,
        '-84': 32.249502427140236,
        '-83': 32.250667329631995,
        '-82': 32.25016698528719,
        '-81': 32.25006827300615,
        '-80': 32.24996901419051,
        '-79': 32.2500538619941,
        '-78': 32.25009253783194,
        '-77': 32.2500669319227,
        '-76': 32.25001918322679,
        '-75': 32.25005155296726,
        '-74': 32.249992606650906,
        '-73': 32.249973696716914,
        '-72': 32.250004228604176,
        '-71': 32.25009314588844,
        '-70': 32.24781195225806,
        '-69': 32.248781667008494,
        '-68': 32.250250329898975,
        '-67': 32.25029532899472,
        '-66': 32.250271170634186,
        '-65': 32.25025874809315,
        '-64': 32.25023845628281,
        '-63': 32.25021150071707,
        '-62': 32.250219991660366,
        '-61': 32.25019504757872,
        '-60': 32.250229602630284,
        '-59': 32.250274743994034,
        '-58': 32.250270914992605,
        '-57': 32.25029940170346,
        '-56': 32.25034956128046,
        '-55': 32.2504299974908,
        '-54': 32.2505180017411,
        '-53': 32.250600125287036,
        '-52': 32.250733099465414,
        '-51': 32.250740537734835,
        '-50': 32.249879181929884,
        '-49': 32.24757809881639,
        '-48': 32.24608052374595,
        '-47': 32.245059984220035,
        '-46': 32.245405415034995,
        '-45': 32.245740156583274,
        '-44': 32.24660134618171,
        '-43': 32.250672704592915,
        '-42': 32.25052924887598,
        '-41': 32.25042547572019,
        '-40': 32.25032546803862,
        '-39': 32.25023407894361,
        '-38': 32.250166255410086,
        '-37': 32.25011639460229,
        '-36': 32.24989628221239,
        '-35': 32.24985291342052,
        '-34': 32.24881549947763,
        '-33': 32.24773579989186,
        '-32': 32.247746260734026,
        '-31': 32.24838094676382,
        '-30': 32.24851592689532,
        '-29': 32.24795672415754,
        '-28': 32.24515350240498,
        '-27': 32.242378420016415,
        '-26': 32.239951960775834,
        '-25': 32.23982244258142,
        '-24': 32.239732875581026,
        '-23': 32.239933894182144,
        '-22': 32.239887851287754,
        '-21': 32.23970456964954,
        '-20': 32.239423424078986,
        '-19': 32.23886154461838,
        '-18': 32.238230581425206,
        '-17': 32.23753997674192,
        '-16': 32.23716318848517,
        '-15': 32.236755665793,
        '-14': 32.236195345844045,
        '-13': 32.235158069842726,
        '-12': 32.23380531279579,
        '-11': 32.23300654019503,
        '-10': 32.232408360247234,
        '-9': 32.23146613686582,
        '-8': 32.230667032612054,
        '-7': 32.22986216360368,
        '-6': 32.22927723289401,
        '-5': 32.22866356946635,
        '-4': 32.2281971464384,
        '-3': 32.22742765786488,
        '-2': 32.227055862884384,
        '-1': 32.22664049501946
    },
    '-80': {
        '0': 32.228964328046466,
        '1': 32.22865668551638,
        '2': 32.22791847658976,
        '3': 32.22725641804446,
        '4': 32.22683312148018,
        '5': 32.226356117486205,
        '6': 32.225906500049206,
        '7': 32.225443816265454,
        '8': 32.225018308231476,
        '9': 32.22455882534127,
        '10': 32.22419615360669,
        '11': 32.223828692265904,
        '12': 32.223667968683905,
        '13': 32.22341035258684,
        '14': 32.22320675937863,
        '15': 32.22278382061924,
        '16': 32.22257520074281,
        '17': 32.222236667938674,
        '18': 32.221979462347186,
        '19': 32.22176217084007,
        '20': 32.22150286760873,
        '21': 32.22125090000567,
        '22': 32.22091418896266,
        '23': 32.22072404365663,
        '24': 32.220336096604576,
        '25': 32.22012357444888,
        '26': 32.21998522645627,
        '27': 32.21962828203607,
        '28': 32.219487801344926,
        '29': 32.21913047414442,
        '30': 32.21891131995396,
        '31': 32.21873103331549,
        '32': 32.21852075738091,
        '33': 32.218211615368375,
        '34': 32.21798702726852,
        '35': 32.21769674512104,
        '36': 32.217482906963866,
        '37': 32.217285048848105,
        '38': 32.21707234329061,
        '39': 32.21684360375523,
        '40': 32.21660715493389,
        '41': 32.216471873735394,
        '42': 32.216192812335905,
        '43': 32.215969405587465,
        '44': 32.215748724352586,
        '45': 32.21559946583146,
        '46': 32.21544951111551,
        '47': 32.21526794800796,
        '48': 32.21525713945625,
        '49': 32.21528704407017,
        '50': 32.2152791113898,
        '51': 32.21529709105457,
        '52': 32.21528423690667,
        '53': 32.21527519329303,
        '54': 32.2153149007327,
        '55': 32.21531693532234,
        '56': 32.21527525885297,
        '57': 32.215314372005494,
        '58': 32.21525493044713,
        '59': 32.215401037856374,
        '60': 32.21546996003107,
        '61': 32.215430022307274,
        '62': 32.21543004006467,
        '63': 32.2154056768722,
        '64': 32.215422995014784,
        '65': 32.215406054210284,
        '66': 32.215460486655246,
        '67': 32.2154596273959,
        '68': 32.215448991025546,
        '69': 32.21536364939765,
        '70': 32.215260946311886,
        '71': 32.21517917709507,
        '72': 32.21499609160294,
        '73': 32.215075976036566,
        '74': 32.21481352750334,
        '75': 32.21464580300501,
        '76': 32.214191486482214,
        '77': 32.21392900649688,
        '78': 32.21393230072923,
        '79': 32.21387060574907,
        '80': 32.21394838694514,
        '81': 32.21406603195539,
        '82': 32.21422411005635,
        '83': 32.21450312040456,
        '84': 32.21487150901097,
        '85': 32.21511506976481,
        '86': 32.215261740279026,
        '87': 32.2154808220709,
        '88': 32.21556775282374,
        '89': 32.21565203186125,
        '90': 32.215702164314756,
        '91': 32.215768473744575,
        '92': 32.21582148736238,
        '93': 32.215883359735024,
        '94': 32.21594692537876,
        '95': 32.21604625719141,
        '96': 32.21608462552356,
        '97': 32.21610699655038,
        '98': 32.216228894284974,
        '99': 32.21624210455074,
        '100': 32.21651335076631,
        '101': 32.216792152499465,
        '102': 32.21702881597337,
        '103': 32.21742481819566,
        '104': 32.21784707463488,
        '105': 32.21796942588762,
        '106': 32.2183839410647,
        '107': 32.21862151539424,
        '108': 32.218950170286554,
        '109': 32.21915314336773,
        '110': 32.219266698804226,
        '111': 32.21946937551026,
        '112': 32.21957699878875,
        '113': 32.219729947733235,
        '114': 32.22000924637788,
        '115': 32.22025463245576,
        '116': 32.220448431658376,
        '117': 32.22065470510779,
        '118': 32.220938118870855,
        '119': 32.221131267809646,
        '120': 32.22133947933861,
        '121': 32.221718166958226,
        '122': 32.22196749946026,
        '123': 32.2222816471568,
        '124': 32.222530419406766,
        '125': 32.22270359691531,
        '126': 32.222870524329345,
        '127': 32.22294789339254,
        '128': 32.22293223423214,
        '129': 32.223284085109874,
        '130': 32.223928266012095,
        '131': 32.22405194786359,
        '132': 32.224762287895004,
        '133': 32.22513624857836,
        '134': 32.2258583510997,
        '135': 32.226290610707615,
        '136': 32.22658463460968,
        '137': 32.22679245466034,
        '138': 32.227139263840705,
        '139': 32.227336245566164,
        '140': 32.22756035110534,
        '141': 32.227746755442006,
        '142': 32.22791173682151,
        '143': 32.227950263431545,
        '144': 32.22813029365431,
        '145': 32.22841586269617,
        '146': 32.228476964834,
        '147': 32.22892081697225,
        '148': 32.22925261194145,
        '149': 32.230439629510556,
        '150': 32.23134774801158,
        '151': 32.23171949013953,
        '152': 32.23260918444562,
        '153': 32.23491972803122,
        '154': 32.235977521869565,
        '155': 32.23236991655654,
        '156': 32.24144342398631,
        '157': 32.243688623735814,
        '158': 32.24170684065037,
        '159': 32.23956116337317,
        '160': 32.24593786313833,
        '161': 32.252022442442374,
        '162': 32.252029990037116,
        '163': 32.251932507981984,
        '164': 32.251896522062864,
        '165': 32.25188647797374,
        '166': 32.25193745874943,
        '167': 32.251922186763274,
        '168': 32.25190472143256,
        '169': 32.251877254268635,
        '170': 32.251891477872924,
        '171': 32.2518967673124,
        '172': 32.251922322492064,
        '173': 32.251955989325225,
        '174': 32.25200519279654,
        '175': 32.25204664493953,
        '176': 32.25210757069739,
        '177': 32.25214457624982,
        '178': 32.25217540214528,
        '179': 32.252198160548694,
        '180': 32.2522116708149,
        '-180': 32.2522116708149,
        '-179': 32.2522154877807,
        '-178': 32.252209861277194,
        '-177': 32.25218551768547,
        '-176': 32.25217411930416,
        '-175': 32.25212666004999,
        '-174': 32.25210557059589,
        '-173': 32.252082165636644,
        '-172': 32.252037711247304,
        '-171': 32.25198356025635,
        '-170': 32.251920619454054,
        '-169': 32.25185957368036,
        '-168': 32.251820903062644,
        '-167': 32.251774236105724,
        '-166': 32.25173958603236,
        '-165': 32.25170649938808,
        '-164': 32.25162402521181,
        '-163': 32.2515525131829,
        '-162': 32.25155239292744,
        '-161': 32.25153249610363,
        '-160': 32.25152316904809,
        '-159': 32.25152454595065,
        '-158': 32.25154698556979,
        '-157': 32.251520016130115,
        '-156': 32.25147433974769,
        '-155': 32.25144064854249,
        '-154': 32.25127766555629,
        '-153': 32.25125865632706,
        '-152': 32.25124214512874,
        '-151': 32.25120788106938,
        '-150': 32.25115570104592,
        '-149': 32.251186434028035,
        '-148': 32.2509052803263,
        '-147': 32.25040275683157,
        '-146': 32.250032239928544,
        '-145': 32.250136966560675,
        '-144': 32.24930074033589,
        '-143': 32.24911070536887,
        '-142': 32.24851497519722,
        '-141': 32.24832237993897,
        '-140': 32.24816907338174,
        '-139': 32.247782511426344,
        '-138': 32.247244314084135,
        '-137': 32.247020505150054,
        '-136': 32.246465270351095,
        '-135': 32.245701298990625,
        '-134': 32.24431560143469,
        '-133': 32.24324956641804,
        '-132': 32.242322348558695,
        '-131': 32.24148420499179,
        '-130': 32.24070517216821,
        '-129': 32.24021752179895,
        '-128': 32.23952524023862,
        '-127': 32.23898064805665,
        '-126': 32.23894564182295,
        '-125': 32.23870005723437,
        '-124': 32.238473464905724,
        '-123': 32.23810100234441,
        '-122': 32.23777168589481,
        '-121': 32.23757373350032,
        '-120': 32.23734309166308,
        '-119': 32.2371186520973,
        '-118': 32.23669753555762,
        '-117': 32.23615091547149,
        '-116': 32.23573301569372,
        '-115': 32.23545637693083,
        '-114': 32.23531420011857,
        '-113': 32.2351992970229,
        '-112': 32.23494430635402,
        '-111': 32.234513434748244,
        '-110': 32.23412332835722,
        '-109': 32.23375763774817,
        '-108': 32.23339921309268,
        '-107': 32.232938932399854,
        '-106': 32.23239782645099,
        '-105': 32.23215940456259,
        '-104': 32.231838012099665,
        '-103': 32.23169354327014,
        '-102': 32.23173234314646,
        '-101': 32.23177808905509,
        '-100': 32.232017883376066,
        '-99': 32.23221409687121,
        '-98': 32.23229092438886,
        '-97': 32.23219318470075,
        '-96': 32.23226081637778,
        '-95': 32.23197493501019,
        '-94': 32.23154584178918,
        '-93': 32.23151869708126,
        '-92': 32.231660820054216,
        '-91': 32.23199333625425,
        '-90': 32.23267979785279,
        '-89': 32.234197951087204,
        '-88': 32.23518540914321,
        '-87': 32.2363535090293,
        '-86': 32.236836186214134,
        '-85': 32.23889199692676,
        '-84': 32.240158262463495,
        '-83': 32.240901189556,
        '-82': 32.24070920248969,
        '-81': 32.2468741546048,
        '-80': 32.249833627958566,
        '-79': 32.25116564791357,
        '-78': 32.24959656903649,
        '-77': 32.25094093850237,
        '-76': 32.25101172206658,
        '-75': 32.25089996566809,
        '-74': 32.250806488684944,
        '-73': 32.2508206276088,
        '-72': 32.25084956461283,
        '-71': 32.25088139106437,
        '-70': 32.250884111051384,
        '-69': 32.25088667535191,
        '-68': 32.25086783186727,
        '-67': 32.25087756863368,
        '-66': 32.2509258959291,
        '-65': 32.25119524402854,
        '-64': 32.25130208186748,
        '-63': 32.25130827352103,
        '-62': 32.251275931645935,
        '-61': 32.25080206260551,
        '-60': 32.25107142200638,
        '-59': 32.25105375364455,
        '-58': 32.25109387908609,
        '-57': 32.251121531740225,
        '-56': 32.25110636770247,
        '-55': 32.25115906042455,
        '-54': 32.251278485493394,
        '-53': 32.25150347558477,
        '-52': 32.25164993966763,
        '-51': 32.24952062048016,
        '-50': 32.24801601378341,
        '-49': 32.24673926727437,
        '-48': 32.24550619440007,
        '-47': 32.244486973023676,
        '-46': 32.24471190628311,
        '-45': 32.24603876340023,
        '-44': 32.24951964282206,
        '-43': 32.25128096058477,
        '-42': 32.25124252140408,
        '-41': 32.25124709992101,
        '-40': 32.251195526569944,
        '-39': 32.2511102774459,
        '-38': 32.25107461475295,
        '-37': 32.25099981331459,
        '-36': 32.25095880936944,
        '-35': 32.25087255933675,
        '-34': 32.25085381281379,
        '-33': 32.25083288670355,
        '-32': 32.25084081251311,
        '-31': 32.25080710842561,
        '-30': 32.25034748515492,
        '-29': 32.24973463863457,
        '-28': 32.24853325762901,
        '-27': 32.24841100152114,
        '-26': 32.24797181364201,
        '-25': 32.24701274577124,
        '-24': 32.24666554972003,
        '-23': 32.245989253532066,
        '-22': 32.245236028542344,
        '-21': 32.244708624126865,
        '-20': 32.24233356665375,
        '-19': 32.24099188399809,
        '-18': 32.23981315213486,
        '-17': 32.23898849716405,
        '-16': 32.23831455439068,
        '-15': 32.2376585669515,
        '-14': 32.23638201779997,
        '-13': 32.23576767016343,
        '-12': 32.23545005515394,
        '-11': 32.2349221875603,
        '-10': 32.23415259065066,
        '-9': 32.23335285275071,
        '-8': 32.23272485678,
        '-7': 32.23200572490744,
        '-6': 32.231469053863094,
        '-5': 32.23101470626985,
        '-4': 32.23059352522371,
        '-3': 32.230096025713735,
        '-2': 32.2297162765173,
        '-1': 32.229304670368414
    },
    '-81': {
        '0': 32.231111658239485,
        '1': 32.2302589017129,
        '2': 32.2296084612816,
        '3': 32.22920253498998,
        '4': 32.22882042443087,
        '5': 32.228423278035166,
        '6': 32.22830560962716,
        '7': 32.228054065645196,
        '8': 32.22796256274406,
        '9': 32.22785967550714,
        '10': 32.227583699910646,
        '11': 32.227285899904835,
        '12': 32.22705651674887,
        '13': 32.22679336938224,
        '14': 32.226666917870496,
        '15': 32.226351970962305,
        '16': 32.226089300127306,
        '17': 32.22582636317732,
        '18': 32.22540944275055,
        '19': 32.2254635278948,
        '20': 32.22508674598918,
        '21': 32.22484377731264,
        '22': 32.22462204300897,
        '23': 32.224450904960094,
        '24': 32.223975808651026,
        '25': 32.223591056352646,
        '26': 32.22341829529491,
        '27': 32.223063822029964,
        '28': 32.2228422087548,
        '29': 32.222411022584645,
        '30': 32.22209542050873,
        '31': 32.22146234418446,
        '32': 32.221291918355966,
        '33': 32.22097910536918,
        '34': 32.220697091371584,
        '35': 32.220133575723395,
        '36': 32.22001691814608,
        '37': 32.21953882163189,
        '38': 32.219436892269,
        '39': 32.21902340362657,
        '40': 32.21880282099658,
        '41': 32.21849116352587,
        '42': 32.21825910274172,
        '43': 32.21802472058336,
        '44': 32.21767596636059,
        '45': 32.2174951539569,
        '46': 32.21724968150864,
        '47': 32.21705096925077,
        '48': 32.21674828159484,
        '49': 32.21647429735427,
        '50': 32.21628134478029,
        '51': 32.21610094630752,
        '52': 32.2159255326326,
        '53': 32.21576787694294,
        '54': 32.21566091747867,
        '55': 32.21553640094836,
        '56': 32.215477159228996,
        '57': 32.215444299843845,
        '58': 32.215367956372816,
        '59': 32.215349138341665,
        '60': 32.215265872683155,
        '61': 32.21531850429218,
        '62': 32.215252161016984,
        '63': 32.215285969381966,
        '64': 32.215254740325115,
        '65': 32.21522526624177,
        '66': 32.21528446100987,
        '67': 32.2152566277378,
        '68': 32.21527962118389,
        '69': 32.215218954209,
        '70': 32.215082286905364,
        '71': 32.21498926950073,
        '72': 32.21483803745512,
        '73': 32.21461856388735,
        '74': 32.21436208056278,
        '75': 32.214242042796315,
        '76': 32.21427074023973,
        '77': 32.214329479801435,
        '78': 32.21445139150339,
        '79': 32.214588773088124,
        '80': 32.2147341759461,
        '81': 32.21490001627742,
        '82': 32.21510833140243,
        '83': 32.215289652138964,
        '84': 32.21544461722545,
        '85': 32.215603555781996,
        '86': 32.215776021893944,
        '87': 32.215981197341364,
        '88': 32.216207598335316,
        '89': 32.216463734215175,
        '90': 32.21669741706164,
        '91': 32.21688684114115,
        '92': 32.21714177538754,
        '93': 32.21734996527672,
        '94': 32.217480362315385,
        '95': 32.217613488388494,
        '96': 32.217749409405364,
        '97': 32.21796939435493,
        '98': 32.21808213320668,
        '99': 32.21824013354596,
        '100': 32.21837368408311,
        '101': 32.218594990014665,
        '102': 32.21874336819811,
        '103': 32.21881970358966,
        '104': 32.218915642464125,
        '105': 32.219082220892375,
        '106': 32.219279383649656,
        '107': 32.21946695373928,
        '108': 32.219715783597366,
        '109': 32.22009669358038,
        '110': 32.220296790715246,
        '111': 32.22039719882339,
        '112': 32.22048928597667,
        '113': 32.22064440158479,
        '114': 32.22076244702587,
        '115': 32.220854583010805,
        '116': 32.221033058850445,
        '117': 32.221289033681046,
        '118': 32.22156317316682,
        '119': 32.221987829485364,
        '120': 32.222341797178515,
        '121': 32.2226660104855,
        '122': 32.22301108383388,
        '123': 32.22331610016149,
        '124': 32.223610494336995,
        '125': 32.22387271550448,
        '126': 32.22410094463314,
        '127': 32.2243737798609,
        '128': 32.22478978441844,
        '129': 32.225326223330605,
        '130': 32.22581896491227,
        '131': 32.22615454771866,
        '132': 32.22644213329757,
        '133': 32.226751020787425,
        '134': 32.22702991900747,
        '135': 32.22728888139747,
        '136': 32.22758924096309,
        '137': 32.22805368857582,
        '138': 32.22837115837995,
        '139': 32.22868579469889,
        '140': 32.2290107972587,
        '141': 32.22929896254785,
        '142': 32.22956368811449,
        '143': 32.229868631958546,
        '144': 32.23025679209068,
        '145': 32.23089172621239,
        '146': 32.23133021087463,
        '147': 32.23180482140154,
        '148': 32.23267854241176,
        '149': 32.23362662724971,
        '150': 32.23499021934294,
        '151': 32.23622072468677,
        '152': 32.238284712866985,
        '153': 32.24006679120512,
        '154': 32.24110794428796,
        '155': 32.242566907356206,
        '156': 32.24261027782014,
        '157': 32.23572525272308,
        '158': 32.232425438760835,
        '159': 32.24186813918118,
        '160': 32.2406902184275,
        '161': 32.25302979856605,
        '162': 32.25300736041718,
        '163': 32.25296459141953,
        '164': 32.25293487613883,
        '165': 32.25287096331793,
        '166': 32.25280658339628,
        '167': 32.25279552426242,
        '168': 32.252759761721165,
        '169': 32.25273200455109,
        '170': 32.25271398574438,
        '171': 32.25269662048601,
        '172': 32.25270043394014,
        '173': 32.25270480638825,
        '174': 32.25273905434735,
        '175': 32.2527611853969,
        '176': 32.25279960710202,
        '177': 32.25285214328298,
        '178': 32.25288615925397,
        '179': 32.252929738290554,
        '180': 32.25297064898569,
        '-180': 32.25297064898569,
        '-179': 32.25299689950349,
        '-178': 32.25304740252115,
        '-177': 32.25307036395342,
        '-176': 32.25310543227078,
        '-175': 32.25311167626445,
        '-174': 32.2530889918359,
        '-173': 32.25307807008514,
        '-172': 32.25303891881895,
        '-171': 32.25302284561793,
        '-170': 32.25299026423653,
        '-169': 32.252982674277895,
        '-168': 32.25293037326124,
        '-167': 32.25286490526275,
        '-166': 32.25280772490956,
        '-165': 32.25274994278709,
        '-164': 32.252672540941184,
        '-163': 32.25259692479488,
        '-162': 32.2525242046256,
        '-161': 32.252455402410405,
        '-160': 32.252401537936976,
        '-159': 32.25235326391911,
        '-158': 32.252321290208215,
        '-157': 32.25229591046233,
        '-156': 32.25226720662243,
        '-155': 32.252235145373156,
        '-154': 32.25219946149467,
        '-153': 32.25218999548557,
        '-152': 32.252195927297436,
        '-151': 32.25220624770063,
        '-150': 32.2521086658568,
        '-149': 32.25214473249535,
        '-148': 32.25178735133563,
        '-147': 32.250833032012835,
        '-146': 32.25079769544484,
        '-145': 32.25066902654667,
        '-144': 32.25065863744382,
        '-143': 32.250229931417934,
        '-142': 32.249969282890575,
        '-141': 32.24999811138933,
        '-140': 32.249608764772994,
        '-139': 32.249499768004334,
        '-138': 32.249156193992725,
        '-137': 32.248245418277776,
        '-136': 32.24787102368881,
        '-135': 32.24683083669793,
        '-134': 32.24618789028993,
        '-133': 32.24591286189186,
        '-132': 32.24563244285165,
        '-131': 32.24532694142112,
        '-130': 32.24502685771199,
        '-129': 32.244721847382515,
        '-128': 32.24441127205469,
        '-127': 32.243457084588236,
        '-126': 32.24307131064177,
        '-125': 32.24248389249337,
        '-124': 32.241854893196034,
        '-123': 32.24144549653082,
        '-122': 32.240829406350706,
        '-121': 32.24057155100873,
        '-120': 32.23998335705481,
        '-119': 32.23970138774777,
        '-118': 32.23945267793306,
        '-117': 32.238853648105305,
        '-116': 32.23836052512025,
        '-115': 32.23783348578299,
        '-114': 32.23689055748046,
        '-113': 32.236140751145804,
        '-112': 32.235424877052836,
        '-111': 32.23495768296891,
        '-110': 32.234519097956294,
        '-109': 32.23415144858294,
        '-108': 32.23367419518413,
        '-107': 32.233562988182676,
        '-106': 32.23326198379544,
        '-105': 32.2328511913896,
        '-104': 32.23250081637486,
        '-103': 32.23232986681637,
        '-102': 32.23196151763892,
        '-101': 32.23162492044639,
        '-100': 32.23118509591617,
        '-99': 32.23091136411428,
        '-98': 32.23113391547459,
        '-97': 32.23162721022733,
        '-96': 32.23220655014926,
        '-95': 32.23271812706715,
        '-94': 32.233008824123495,
        '-93': 32.233745481081755,
        '-92': 32.23448329038976,
        '-91': 32.235041154521255,
        '-90': 32.235279179083726,
        '-89': 32.23579628818706,
        '-88': 32.2362918642643,
        '-87': 32.23676901388493,
        '-86': 32.23923271496062,
        '-85': 32.240926185291414,
        '-84': 32.242115514098806,
        '-83': 32.24256107431852,
        '-82': 32.24299328602113,
        '-81': 32.24408127601859,
        '-80': 32.24531098973266,
        '-79': 32.246582253094054,
        '-78': 32.24797625935961,
        '-77': 32.249543338685456,
        '-76': 32.25075704927373,
        '-75': 32.25120159585818,
        '-74': 32.25117868698344,
        '-73': 32.251384232603144,
        '-72': 32.251502971755464,
        '-71': 32.25172524172035,
        '-70': 32.25175613205602,
        '-69': 32.2517560140188,
        '-68': 32.251683319758946,
        '-67': 32.251749670313714,
        '-66': 32.25177265261019,
        '-65': 32.25178261075363,
        '-64': 32.251790017615896,
        '-63': 32.25179553880484,
        '-62': 32.25184052878824,
        '-61': 32.251865371424216,
        '-60': 32.251770078376154,
        '-59': 32.251697330900306,
        '-58': 32.251648124398976,
        '-57': 32.251643492572384,
        '-56': 32.25166378169451,
        '-55': 32.25162839324154,
        '-54': 32.25166875175227,
        '-53': 32.25178454305366,
        '-52': 32.25173252318613,
        '-51': 32.25175452491219,
        '-50': 32.25177877436626,
        '-49': 32.25181427217919,
        '-48': 32.25181942179743,
        '-47': 32.251833544504514,
        '-46': 32.25183538571169,
        '-45': 32.25187461856003,
        '-44': 32.25188987137562,
        '-43': 32.25188065399065,
        '-42': 32.2519275971244,
        '-41': 32.251949753126894,
        '-40': 32.25215963252661,
        '-39': 32.25201149562854,
        '-38': 32.25136426640391,
        '-37': 32.2512802830047,
        '-36': 32.24995026558058,
        '-35': 32.24760774124501,
        '-34': 32.2462147922495,
        '-33': 32.244609489571125,
        '-32': 32.244156994859146,
        '-31': 32.24346310002369,
        '-30': 32.24321570710289,
        '-29': 32.243304254355856,
        '-28': 32.24270872017435,
        '-27': 32.24194517508711,
        '-26': 32.241792288805975,
        '-25': 32.24171509188074,
        '-24': 32.24150195878665,
        '-23': 32.24137567647539,
        '-22': 32.241407369645906,
        '-21': 32.240213053243934,
        '-20': 32.23963172768175,
        '-19': 32.23895599068849,
        '-18': 32.23793288275683,
        '-17': 32.236470853242636,
        '-16': 32.23601391216815,
        '-15': 32.23588408320114,
        '-14': 32.235918473506715,
        '-13': 32.23561036445713,
        '-12': 32.23549384635048,
        '-11': 32.23513275828597,
        '-10': 32.23500066562975,
        '-9': 32.23474240637104,
        '-8': 32.23471064974978,
        '-7': 32.23424732675696,
        '-6': 32.233725929076584,
        '-5': 32.23342937235506,
        '-4': 32.23284216072916,
        '-3': 32.23223786137359,
        '-2': 32.23191061903004,
        '-1': 32.23144709473871
    },
    '-82': {
        '0': 32.2320981057622,
        '1': 32.231579240078986,
        '2': 32.23116605217865,
        '3': 32.23081933672419,
        '4': 32.23052003273723,
        '5': 32.23014797049558,
        '6': 32.22973430200924,
        '7': 32.22943115564616,
        '8': 32.229259114827826,
        '9': 32.22915767959068,
        '10': 32.22906603476111,
        '11': 32.22897354565636,
        '12': 32.22892988126849,
        '13': 32.22888340105686,
        '14': 32.22881250280276,
        '15': 32.22878631158496,
        '16': 32.228803072086215,
        '17': 32.22881038881182,
        '18': 32.22878613366533,
        '19': 32.22873852765328,
        '20': 32.22862533632029,
        '21': 32.22841457769836,
        '22': 32.228155364768085,
        '23': 32.22785662452068,
        '24': 32.22742644164733,
        '25': 32.226854093352834,
        '26': 32.22634152420894,
        '27': 32.22601006373961,
        '28': 32.22575882468579,
        '29': 32.225568043493396,
        '30': 32.22535747234265,
        '31': 32.22506723613808,
        '32': 32.22459712342074,
        '33': 32.224008754482355,
        '34': 32.223505264233765,
        '35': 32.22303698708329,
        '36': 32.22261485607,
        '37': 32.22216887347156,
        '38': 32.22169972895625,
        '39': 32.22112720698176,
        '40': 32.220664092525496,
        '41': 32.22034122297424,
        '42': 32.22010862250201,
        '43': 32.21990627904052,
        '44': 32.21967427982385,
        '45': 32.21934273691769,
        '46': 32.218983300401845,
        '47': 32.2186475273024,
        '48': 32.21832648709418,
        '49': 32.218041633913266,
        '50': 32.21778417560631,
        '51': 32.21754531728856,
        '52': 32.21732629138475,
        '53': 32.2171786662145,
        '54': 32.21707302565312,
        '55': 32.21695947611037,
        '56': 32.21687863168756,
        '57': 32.21677983736245,
        '58': 32.21665237715118,
        '59': 32.21655575978517,
        '60': 32.21647837900678,
        '61': 32.21634762452615,
        '62': 32.21624201273956,
        '63': 32.21614891212955,
        '64': 32.21607572228361,
        '65': 32.21602978866451,
        '66': 32.215968002630724,
        '67': 32.215887839663566,
        '68': 32.21582748868363,
        '69': 32.2157548035545,
        '70': 32.215628002646795,
        '71': 32.21548668183118,
        '72': 32.21539121513861,
        '73': 32.21538232688385,
        '74': 32.215440641637436,
        '75': 32.21554726650832,
        '76': 32.2156635088423,
        '77': 32.21583181819619,
        '78': 32.216003909263016,
        '79': 32.216192198519224,
        '80': 32.21641915929733,
        '81': 32.21664650073005,
        '82': 32.216845782415085,
        '83': 32.21706902367539,
        '84': 32.21728703371649,
        '85': 32.21747017959233,
        '86': 32.21766916706875,
        '87': 32.217853459461764,
        '88': 32.2180123094179,
        '89': 32.21816491626318,
        '90': 32.218299898713695,
        '91': 32.21840567605528,
        '92': 32.21852106517963,
        '93': 32.21866466520941,
        '94': 32.21881473114106,
        '95': 32.21903047617392,
        '96': 32.21927028826539,
        '97': 32.21948269512036,
        '98': 32.219677126642644,
        '99': 32.219863317348306,
        '100': 32.2200917226112,
        '101': 32.22029185609415,
        '102': 32.22053495499919,
        '103': 32.22076117006429,
        '104': 32.220920984055304,
        '105': 32.2210256780194,
        '106': 32.221127069557376,
        '107': 32.22123666240524,
        '108': 32.22136601311165,
        '109': 32.221506497966224,
        '110': 32.221710053940676,
        '111': 32.22195787974933,
        '112': 32.222261377764035,
        '113': 32.22252079243064,
        '114': 32.222676614923834,
        '115': 32.222810473740715,
        '116': 32.22292303356102,
        '117': 32.223004640454505,
        '118': 32.22312614255512,
        '119': 32.22331776614986,
        '120': 32.2235993979628,
        '121': 32.22385946809775,
        '122': 32.224077008624455,
        '123': 32.22431159983812,
        '124': 32.224673144079574,
        '125': 32.225069533216654,
        '126': 32.225428763997016,
        '127': 32.22576968645404,
        '128': 32.22605062946138,
        '129': 32.22623004118532,
        '130': 32.226357447444556,
        '131': 32.226512932136956,
        '132': 32.226786984265075,
        '133': 32.22713912303842,
        '134': 32.22746859010096,
        '135': 32.227826453546434,
        '136': 32.228223682270865,
        '137': 32.22862099236098,
        '138': 32.22910050049132,
        '139': 32.22959291252866,
        '140': 32.22986731193288,
        '141': 32.230026044445026,
        '142': 32.23018137827488,
        '143': 32.23042510747306,
        '144': 32.230727445141724,
        '145': 32.23096724479837,
        '146': 32.231285583755366,
        '147': 32.23174226072875,
        '148': 32.23226525355989,
        '149': 32.23288316770235,
        '150': 32.233452417892536,
        '151': 32.23426381291073,
        '152': 32.23473858618737,
        '153': 32.234328055544104,
        '154': 32.234101494826994,
        '155': 32.23356121352971,
        '156': 32.2332817337636,
        '157': 32.22987420589308,
        '158': 32.237494873489986,
        '159': 32.23239873225552,
        '160': 32.2415580318943,
        '161': 32.24800448878883,
        '162': 32.24610143446136,
        '163': 32.25154254363733,
        '164': 32.25408104594554,
        '165': 32.25396202650475,
        '166': 32.253920245141735,
        '167': 32.25388759223757,
        '168': 32.25377568370353,
        '169': 32.253708354679276,
        '170': 32.25364730851241,
        '171': 32.253564012803864,
        '172': 32.25349019264079,
        '173': 32.253436888758564,
        '174': 32.25341467491955,
        '175': 32.25340334253021,
        '176': 32.2533923726119,
        '177': 32.25338098217879,
        '178': 32.25335796545945,
        '179': 32.25337253675759,
        '180': 32.25340297470034,
        '-180': 32.25340297470034,
        '-179': 32.253447687282,
        '-178': 32.25348484019633,
        '-177': 32.25353309508453,
        '-176': 32.25358088662088,
        '-175': 32.25360669181196,
        '-174': 32.25360941059452,
        '-173': 32.25360839963545,
        '-172': 32.25360303288997,
        '-171': 32.25360305094146,
        '-170': 32.25358809643706,
        '-169': 32.253558278800575,
        '-168': 32.25354427223672,
        '-167': 32.25354659545785,
        '-166': 32.25355581080606,
        '-165': 32.253542373710864,
        '-164': 32.25351728704041,
        '-163': 32.253471366947274,
        '-162': 32.253425777054275,
        '-161': 32.25339152604969,
        '-160': 32.25336942996293,
        '-159': 32.253390535761255,
        '-158': 32.2534149461136,
        '-157': 32.25344305530333,
        '-156': 32.25344472976868,
        '-155': 32.253409870883985,
        '-154': 32.25333830036798,
        '-153': 32.25325999991428,
        '-152': 32.25188984780903,
        '-151': 32.251199564924924,
        '-150': 32.25031850598582,
        '-149': 32.25018648146106,
        '-148': 32.24992267113507,
        '-147': 32.24966783647929,
        '-146': 32.24995730587594,
        '-145': 32.25068929821031,
        '-144': 32.25091240309927,
        '-143': 32.25090946437181,
        '-142': 32.25058925412404,
        '-141': 32.250144005231725,
        '-140': 32.24970544292032,
        '-139': 32.24921327208664,
        '-138': 32.2491333126195,
        '-137': 32.24899082149521,
        '-136': 32.24859438980124,
        '-135': 32.24830890841184,
        '-134': 32.24805422661478,
        '-133': 32.24760857798296,
        '-132': 32.247195054155945,
        '-131': 32.24720846115924,
        '-130': 32.24705251566073,
        '-129': 32.24648471979789,
        '-128': 32.24647567524436,
        '-127': 32.24636799300175,
        '-126': 32.245928835872405,
        '-125': 32.24567341028848,
        '-124': 32.24566190388697,
        '-123': 32.2454793717549,
        '-122': 32.24531749990531,
        '-121': 32.245165887240745,
        '-120': 32.24480199834589,
        '-119': 32.24413491386745,
        '-118': 32.242922327134295,
        '-117': 32.242023853685524,
        '-116': 32.24138952061688,
        '-115': 32.24062591997594,
        '-114': 32.240016888375344,
        '-113': 32.23920963330862,
        '-112': 32.238346524105616,
        '-111': 32.237549596898376,
        '-110': 32.23671840812191,
        '-109': 32.23587353171776,
        '-108': 32.23533836654294,
        '-107': 32.23459733467665,
        '-106': 32.23373063604304,
        '-105': 32.232989886555046,
        '-104': 32.232343445089626,
        '-103': 32.23215348888833,
        '-102': 32.23224641479474,
        '-101': 32.23251916446395,
        '-100': 32.23287866912653,
        '-99': 32.23322172224617,
        '-98': 32.233667604828156,
        '-97': 32.23420438921644,
        '-96': 32.23464852707234,
        '-95': 32.234938128872436,
        '-94': 32.23525441229447,
        '-93': 32.23568805891019,
        '-92': 32.236269543070236,
        '-91': 32.23672642476569,
        '-90': 32.23759566832737,
        '-89': 32.23860563714384,
        '-88': 32.23909067107174,
        '-87': 32.23979091205794,
        '-86': 32.24056691050347,
        '-85': 32.24104677172848,
        '-84': 32.24176851835473,
        '-83': 32.2432194298412,
        '-82': 32.244481387721436,
        '-81': 32.245131435116086,
        '-80': 32.2458179057468,
        '-79': 32.2470168993424,
        '-78': 32.247900112777096,
        '-77': 32.248932859138854,
        '-76': 32.249852266167785,
        '-75': 32.25053668143626,
        '-74': 32.2507428714032,
        '-73': 32.2504093931169,
        '-72': 32.24999030534884,
        '-71': 32.24969696363583,
        '-70': 32.250114808660804,
        '-69': 32.250818367938734,
        '-68': 32.25153389021033,
        '-67': 32.25216972527769,
        '-66': 32.25251303195781,
        '-65': 32.252512931522155,
        '-64': 32.25253333527812,
        '-63': 32.25254396590544,
        '-62': 32.252524807786486,
        '-61': 32.25251663757868,
        '-60': 32.25251986839698,
        '-59': 32.252504623638266,
        '-58': 32.25249159198714,
        '-57': 32.252491317700084,
        '-56': 32.25247383168642,
        '-55': 32.25250007491225,
        '-54': 32.2525499785165,
        '-53': 32.2525932312563,
        '-52': 32.252690392811516,
        '-51': 32.25262886197517,
        '-50': 32.25238803407893,
        '-49': 32.252159554998165,
        '-48': 32.2522361185988,
        '-47': 32.25279915080296,
        '-46': 32.25287711713183,
        '-45': 32.25290416250203,
        '-44': 32.252920039833036,
        '-43': 32.252893730473424,
        '-42': 32.25194463789921,
        '-41': 32.24787770257264,
        '-40': 32.24540554360661,
        '-39': 32.24433519562025,
        '-38': 32.243149517886394,
        '-37': 32.243172788261404,
        '-36': 32.242787113785646,
        '-35': 32.242134018855985,
        '-34': 32.24121358930402,
        '-33': 32.24077409965876,
        '-32': 32.24055289475563,
        '-31': 32.24007516824715,
        '-30': 32.23952330400976,
        '-29': 32.23908983119318,
        '-28': 32.239118902427016,
        '-27': 32.23941902440183,
        '-26': 32.2396471775167,
        '-25': 32.239804006766235,
        '-24': 32.23940509146777,
        '-23': 32.2386734362398,
        '-22': 32.238175478978334,
        '-21': 32.23770958025368,
        '-20': 32.23719528570239,
        '-19': 32.23668337238275,
        '-18': 32.236396212762706,
        '-17': 32.23615194758984,
        '-16': 32.235667592026665,
        '-15': 32.23500350773724,
        '-14': 32.23445225747253,
        '-13': 32.23417498114069,
        '-12': 32.23402974402375,
        '-11': 32.233874600424244,
        '-10': 32.23373933868161,
        '-9': 32.23363360607196,
        '-8': 32.23355704898372,
        '-7': 32.23348924765892,
        '-6': 32.23340994804658,
        '-5': 32.23331928644939,
        '-4': 32.23321760068724,
        '-3': 32.23307512988232,
        '-2': 32.23287240956135,
        '-1': 32.23256994968451
    },
    '-83': {
        '0': 32.23052366859268,
        '1': 32.23014349350553,
        '2': 32.22980248952164,
        '3': 32.2295118079244,
        '4': 32.2292623140965,
        '5': 32.22905482624269,
        '6': 32.228879909918724,
        '7': 32.22869768035992,
        '8': 32.2285285632029,
        '9': 32.228412928502266,
        '10': 32.228350564178356,
        '11': 32.22832084677436,
        '12': 32.22832309326609,
        '13': 32.22831604240575,
        '14': 32.228318754948035,
        '15': 32.228309753296614,
        '16': 32.22828758379697,
        '17': 32.228250674118385,
        '18': 32.22822765247664,
        '19': 32.22819664214324,
        '20': 32.228155946499655,
        '21': 32.22809380603273,
        '22': 32.22794803506639,
        '23': 32.227767621888454,
        '24': 32.22760170795407,
        '25': 32.22740869761806,
        '26': 32.22711685088739,
        '27': 32.22680613619246,
        '28': 32.226465811581946,
        '29': 32.2261055383341,
        '30': 32.22581600303191,
        '31': 32.225576948545566,
        '32': 32.22530767268074,
        '33': 32.225058989173625,
        '34': 32.2247909390913,
        '35': 32.22443340480967,
        '36': 32.224017441381115,
        '37': 32.223594427391426,
        '38': 32.22311482661387,
        '39': 32.22261001752845,
        '40': 32.222121553806176,
        '41': 32.22174154157563,
        '42': 32.221461131365174,
        '43': 32.2212311259656,
        '44': 32.22100236462887,
        '45': 32.22080647262693,
        '46': 32.220594280154664,
        '47': 32.22036705710411,
        '48': 32.22012599549558,
        '49': 32.219872178213244,
        '50': 32.21962673468863,
        '51': 32.21939043129022,
        '52': 32.219163830990624,
        '53': 32.218957359408,
        '54': 32.218771094307606,
        '55': 32.21862503138702,
        '56': 32.21846822472349,
        '57': 32.218340296283074,
        '58': 32.21822002686349,
        '59': 32.218096034758,
        '60': 32.21797690566498,
        '61': 32.217850845161145,
        '62': 32.217716017054535,
        '63': 32.21758060302913,
        '64': 32.21748307036909,
        '65': 32.21740132662404,
        '66': 32.21734368475801,
        '67': 32.217348934965905,
        '68': 32.21737526023839,
        '69': 32.21742151789647,
        '70': 32.21747676318823,
        '71': 32.21752027462146,
        '72': 32.21757209519117,
        '73': 32.21765261216743,
        '74': 32.21773201588638,
        '75': 32.217821221084364,
        '76': 32.21790109375646,
        '77': 32.217972928247676,
        '78': 32.21805838686477,
        '79': 32.21815902274144,
        '80': 32.21826632445821,
        '81': 32.21836165700823,
        '82': 32.21846670892005,
        '83': 32.21858282723148,
        '84': 32.218721277505026,
        '85': 32.21889310811187,
        '86': 32.21904853382796,
        '87': 32.21918801730451,
        '88': 32.21934206427188,
        '89': 32.219480346482094,
        '90': 32.219632907577015,
        '91': 32.219758909810494,
        '92': 32.21987795231556,
        '93': 32.22002971971257,
        '94': 32.220223531849555,
        '95': 32.22044848367824,
        '96': 32.22073408386315,
        '97': 32.22101899321469,
        '98': 32.221241949166995,
        '99': 32.22146300641881,
        '100': 32.22165144661008,
        '101': 32.22181709870229,
        '102': 32.22199014472361,
        '103': 32.22214030480238,
        '104': 32.222267740662424,
        '105': 32.22238283878108,
        '106': 32.222496088985366,
        '107': 32.22259785790075,
        '108': 32.22270887138363,
        '109': 32.22280947707628,
        '110': 32.22294063246173,
        '111': 32.22320387775128,
        '112': 32.2235592891222,
        '113': 32.22392647221928,
        '114': 32.22425525310321,
        '115': 32.224515565131995,
        '116': 32.224667138115784,
        '117': 32.22476051327833,
        '118': 32.22486633719068,
        '119': 32.225014761471456,
        '120': 32.225195456095996,
        '121': 32.22543844134926,
        '122': 32.22565247765885,
        '123': 32.22581702491259,
        '124': 32.22598227293222,
        '125': 32.2261176349251,
        '126': 32.22621279461792,
        '127': 32.22628783256791,
        '128': 32.22639324503058,
        '129': 32.22652913985969,
        '130': 32.22676647169636,
        '131': 32.227075307911385,
        '132': 32.22738543830343,
        '133': 32.227667178810655,
        '134': 32.227911146178016,
        '135': 32.22814843261144,
        '136': 32.22841016294013,
        '137': 32.228697150268076,
        '138': 32.22898995462564,
        '139': 32.229279142646554,
        '140': 32.229585447876254,
        '141': 32.22986880764078,
        '142': 32.23013945078732,
        '143': 32.23035683913678,
        '144': 32.23054078218814,
        '145': 32.23073102475532,
        '146': 32.230956940917146,
        '147': 32.23115669176197,
        '148': 32.23133893866033,
        '149': 32.23157280385254,
        '150': 32.23179586294833,
        '151': 32.232016355045786,
        '152': 32.23256606974311,
        '153': 32.23349381208621,
        '154': 32.23456532329203,
        '155': 32.23566778525401,
        '156': 32.23595012216249,
        '157': 32.23806153843499,
        '158': 32.2408787312908,
        '159': 32.24091054863186,
        '160': 32.23916874519428,
        '161': 32.22633092317112,
        '162': 32.236770897279875,
        '163': 32.23898883842737,
        '164': 32.247580270152326,
        '165': 32.24779697339128,
        '166': 32.2291392007309,
        '167': 32.243540170511515,
        '168': 32.25284596616593,
        '169': 32.25475720487144,
        '170': 32.2546565556713,
        '171': 32.254558947095376,
        '172': 32.25444574291997,
        '173': 32.25436887312057,
        '174': 32.2542991130672,
        '175': 32.25424741498905,
        '176': 32.25419411592476,
        '177': 32.254129394685506,
        '178': 32.25404316266011,
        '179': 32.25397562448141,
        '180': 32.25394654358737,
        '-180': 32.25394654358737,
        '-179': 32.25392495360196,
        '-178': 32.25393028129182,
        '-177': 32.25396162642112,
        '-176': 32.25397758124282,
        '-175': 32.25400747034678,
        '-174': 32.25393908562894,
        '-173': 32.25334681853966,
        '-172': 32.253645246006776,
        '-171': 32.25406523236047,
        '-170': 32.254120736153844,
        '-169': 32.25410441249973,
        '-168': 32.25407650594093,
        '-167': 32.254097401707675,
        '-166': 32.25410625887481,
        '-165': 32.254113141745286,
        '-164': 32.254118105198145,
        '-163': 32.254141518229495,
        '-162': 32.254143133516884,
        '-161': 32.25413331862916,
        '-160': 32.254011210653,
        '-159': 32.253868121372655,
        '-158': 32.25386614862482,
        '-157': 32.25394484878146,
        '-156': 32.2539527059772,
        '-155': 32.25385952227337,
        '-154': 32.25365527219104,
        '-153': 32.2534512414291,
        '-152': 32.25329797167167,
        '-151': 32.25315492831457,
        '-150': 32.25287028746069,
        '-149': 32.252686634788546,
        '-148': 32.25254312693026,
        '-147': 32.252540738260684,
        '-146': 32.252305134894186,
        '-145': 32.25147216423026,
        '-144': 32.250436218662514,
        '-143': 32.25013769581307,
        '-142': 32.24960587250816,
        '-141': 32.248567900957106,
        '-140': 32.24852033663179,
        '-139': 32.248472522057185,
        '-138': 32.248970667465926,
        '-137': 32.24913550132805,
        '-136': 32.249402092029555,
        '-135': 32.249700014070854,
        '-134': 32.24968588519372,
        '-133': 32.249511686513515,
        '-132': 32.24947087829048,
        '-131': 32.24913913567645,
        '-130': 32.24888057899511,
        '-129': 32.248523508132756,
        '-128': 32.24807812699788,
        '-127': 32.24770619865858,
        '-126': 32.24734706776121,
        '-125': 32.24695015787032,
        '-124': 32.24653562188276,
        '-123': 32.24612360098635,
        '-122': 32.245693810826765,
        '-121': 32.24510470337,
        '-120': 32.24451794874232,
        '-119': 32.2436404584908,
        '-118': 32.24281586314355,
        '-117': 32.242266522359465,
        '-116': 32.241830794073174,
        '-115': 32.24142789237631,
        '-114': 32.241057866431966,
        '-113': 32.240559037413874,
        '-112': 32.23984041178217,
        '-111': 32.2387704999898,
        '-110': 32.23817780489431,
        '-109': 32.23778917110663,
        '-108': 32.23713928300622,
        '-107': 32.23616693724469,
        '-106': 32.235215088662,
        '-105': 32.234565953116636,
        '-104': 32.234127697551834,
        '-103': 32.23417229657051,
        '-102': 32.234476405953224,
        '-101': 32.234988469684055,
        '-100': 32.23548504464206,
        '-99': 32.23573255594071,
        '-98': 32.2359120411987,
        '-97': 32.236032795533454,
        '-96': 32.23613460895522,
        '-95': 32.23623723531578,
        '-94': 32.23643142176554,
        '-93': 32.237020498757566,
        '-92': 32.23770127277261,
        '-91': 32.23838312381361,
        '-90': 32.238763282169494,
        '-89': 32.238963924279645,
        '-88': 32.239491643162815,
        '-87': 32.24051942998557,
        '-86': 32.241027220568114,
        '-85': 32.24149157178374,
        '-84': 32.24216656525148,
        '-83': 32.242628852449656,
        '-82': 32.24311222739384,
        '-81': 32.24345609951295,
        '-80': 32.243823267482966,
        '-79': 32.24433593050506,
        '-78': 32.24510601534611,
        '-77': 32.246033020044685,
        '-76': 32.246520985072,
        '-75': 32.24726764164187,
        '-74': 32.24808104961443,
        '-73': 32.24851641984014,
        '-72': 32.24887685986776,
        '-71': 32.24906104980425,
        '-70': 32.24928096365939,
        '-69': 32.24951603764621,
        '-68': 32.249856885139074,
        '-67': 32.250161615500545,
        '-66': 32.250541080255424,
        '-65': 32.25115672770601,
        '-64': 32.25199820281412,
        '-63': 32.25275186775655,
        '-62': 32.25337708377244,
        '-61': 32.25337821277162,
        '-60': 32.25338207155927,
        '-59': 32.25338861165233,
        '-58': 32.25339781292472,
        '-57': 32.250173769914426,
        '-56': 32.247377319996325,
        '-55': 32.246100184661074,
        '-54': 32.244967216556724,
        '-53': 32.243958139994675,
        '-52': 32.24295155486656,
        '-51': 32.241896796058526,
        '-50': 32.24084425250707,
        '-49': 32.242199884445014,
        '-48': 32.24722833802649,
        '-47': 32.24806215160517,
        '-46': 32.248351062490975,
        '-45': 32.24853967347088,
        '-44': 32.24883999734017,
        '-43': 32.24894812396494,
        '-42': 32.24851959154484,
        '-41': 32.24686594835254,
        '-40': 32.24278310222418,
        '-39': 32.24126774960469,
        '-38': 32.24094352434305,
        '-37': 32.24092999303048,
        '-36': 32.24066024903111,
        '-35': 32.240487895956996,
        '-34': 32.240291139355676,
        '-33': 32.24007971826979,
        '-32': 32.23984317561753,
        '-31': 32.23963179249286,
        '-30': 32.23948577589824,
        '-29': 32.239344209705784,
        '-28': 32.23926761820426,
        '-27': 32.23920528595565,
        '-26': 32.23915712099851,
        '-25': 32.23908260612492,
        '-24': 32.23896149594043,
        '-23': 32.23879381274105,
        '-22': 32.23858972519534,
        '-21': 32.23831897157321,
        '-20': 32.23794121028683,
        '-19': 32.237537490203884,
        '-18': 32.23713830133327,
        '-17': 32.236713482108556,
        '-16': 32.236243019509025,
        '-15': 32.2357979229471,
        '-14': 32.23537845660202,
        '-13': 32.23500514447051,
        '-12': 32.23470866249266,
        '-11': 32.23440858536615,
        '-10': 32.23410542555995,
        '-9': 32.23377957299676,
        '-8': 32.23346202148691,
        '-7': 32.23315354290217,
        '-6': 32.232824706293805,
        '-5': 32.23246638067223,
        '-4': 32.23207961914033,
        '-3': 32.23167564064019,
        '-2': 32.23127580734229,
        '-1': 32.230901506081636
    },
    '-84': {
        '0': 32.230494056905485,
        '1': 32.230212819861485,
        '2': 32.229948635601225,
        '3': 32.229712654381565,
        '4': 32.22949573246556,
        '5': 32.22931888977641,
        '6': 32.22918281859506,
        '7': 32.229077970552744,
        '8': 32.22900472966609,
        '9': 32.228943128712615,
        '10': 32.22888311724885,
        '11': 32.22881446621165,
        '12': 32.22875703885118,
        '13': 32.228710345720806,
        '14': 32.228673734876544,
        '15': 32.22865649540423,
        '16': 32.228657692636546,
        '17': 32.22866618445767,
        '18': 32.228650545608424,
        '19': 32.2285994562147,
        '20': 32.22852173216173,
        '21': 32.228466547885084,
        '22': 32.228402327464664,
        '23': 32.22830760733919,
        '24': 32.2282114538978,
        '25': 32.22809252313768,
        '26': 32.22794975496643,
        '27': 32.22777210056772,
        '28': 32.227568835860914,
        '29': 32.227308977669175,
        '30': 32.226981891272935,
        '31': 32.22663770689933,
        '32': 32.2263469242923,
        '33': 32.22609939916513,
        '34': 32.225814437923006,
        '35': 32.22546200980573,
        '36': 32.22503243944194,
        '37': 32.22456669647722,
        '38': 32.22411597238246,
        '39': 32.223660865079864,
        '40': 32.22323256289054,
        '41': 32.22282192809171,
        '42': 32.22247036551464,
        '43': 32.22220920424011,
        '44': 32.22203948513662,
        '45': 32.221911753172265,
        '46': 32.22178661857,
        '47': 32.221644824764674,
        '48': 32.221527600982064,
        '49': 32.221425587831625,
        '50': 32.22132929790206,
        '51': 32.22121900283618,
        '52': 32.22111519212686,
        '53': 32.221017993556266,
        '54': 32.22091726146979,
        '55': 32.22081276420269,
        '56': 32.220724283864435,
        '57': 32.220641146995604,
        '58': 32.22058281180994,
        '59': 32.22053830862087,
        '60': 32.22050664506186,
        '61': 32.22048673522754,
        '62': 32.22043702930657,
        '63': 32.22038663337379,
        '64': 32.22031415388829,
        '65': 32.22026893274239,
        '66': 32.2202498741232,
        '67': 32.22022566376549,
        '68': 32.22015499522201,
        '69': 32.220128044327346,
        '70': 32.220083601974444,
        '71': 32.220041459429176,
        '72': 32.220011489136155,
        '73': 32.22001385436312,
        '74': 32.22002848488929,
        '75': 32.22005569429079,
        '76': 32.22010606249933,
        '77': 32.22014989967021,
        '78': 32.2201879528812,
        '79': 32.22020086406213,
        '80': 32.22022996505963,
        '81': 32.22028632087346,
        '82': 32.22035070876826,
        '83': 32.22040390091207,
        '84': 32.220446839597386,
        '85': 32.22049050989823,
        '86': 32.22054581862393,
        '87': 32.22063367776227,
        '88': 32.22072439970348,
        '89': 32.22084877911993,
        '90': 32.221027394554284,
        '91': 32.221270611074495,
        '92': 32.22151799493699,
        '93': 32.221709005434015,
        '94': 32.22188398592435,
        '95': 32.222022600529044,
        '96': 32.22213473206894,
        '97': 32.22225039890679,
        '98': 32.22234907359387,
        '99': 32.22245058771987,
        '100': 32.22258484959351,
        '101': 32.222731251197196,
        '102': 32.222889376081234,
        '103': 32.22308911361786,
        '104': 32.223299748402745,
        '105': 32.22351077391045,
        '106': 32.22373190104743,
        '107': 32.22392233405947,
        '108': 32.22409180854938,
        '109': 32.22426018390224,
        '110': 32.22439680943399,
        '111': 32.22454179206161,
        '112': 32.22469483638586,
        '113': 32.22485566696231,
        '114': 32.225003815451636,
        '115': 32.22517948589897,
        '116': 32.22535215968586,
        '117': 32.225501460358515,
        '118': 32.22567781429975,
        '119': 32.22586093933774,
        '120': 32.22609126203905,
        '121': 32.22631829448144,
        '122': 32.226532054781806,
        '123': 32.226722639101204,
        '124': 32.22683979450098,
        '125': 32.22695465303407,
        '126': 32.22705756412477,
        '127': 32.227138961721536,
        '128': 32.22718935989328,
        '129': 32.2272397683742,
        '130': 32.22732124957256,
        '131': 32.22743459042088,
        '132': 32.22761091926664,
        '133': 32.227840954181204,
        '134': 32.22807499193323,
        '135': 32.22830371647603,
        '136': 32.22851775635549,
        '137': 32.22866725104223,
        '138': 32.22878305764148,
        '139': 32.228885802227744,
        '140': 32.228965659417334,
        '141': 32.22907325625113,
        '142': 32.2292287446905,
        '143': 32.229411693464,
        '144': 32.22959138912545,
        '145': 32.22975714523472,
        '146': 32.22992841822532,
        '147': 32.23012451668812,
        '148': 32.230303990506236,
        '149': 32.23052632967273,
        '150': 32.230830747762184,
        '151': 32.231215991381426,
        '152': 32.231620138686374,
        '153': 32.2320115905308,
        '154': 32.23249021840792,
        '155': 32.2329841194449,
        '156': 32.23334058536495,
        '157': 32.23378116041737,
        '158': 32.233971401608954,
        '159': 32.232171398611705,
        '160': 32.23366954589465,
        '161': 32.236433401011226,
        '162': 32.23791434970175,
        '163': 32.23863829942656,
        '164': 32.240001435884636,
        '165': 32.239627288015484,
        '166': 32.235251647890685,
        '167': 32.232296742919004,
        '168': 32.23013596587156,
        '169': 32.24030528602844,
        '170': 32.243849417821004,
        '171': 32.2499707548696,
        '172': 32.24932382574155,
        '173': 32.251316495521834,
        '174': 32.24238780118546,
        '175': 32.25523302755629,
        '176': 32.25168636384574,
        '177': 32.255373804775786,
        '178': 32.25531180686157,
        '179': 32.25526315160664,
        '180': 32.255217906703855,
        '-180': 32.255217906703855,
        '-179': 32.255186251632665,
        '-178': 32.25512769665209,
        '-177': 32.25502188696457,
        '-176': 32.25494950810362,
        '-175': 32.2548799456994,
        '-174': 32.254833098248426,
        '-173': 32.254778275326466,
        '-172': 32.25473533014054,
        '-171': 32.25470389337238,
        '-170': 32.254663388001966,
        '-169': 32.254633708952596,
        '-168': 32.254594343168705,
        '-167': 32.25455515598404,
        '-166': 32.25452606281934,
        '-165': 32.254496810679306,
        '-164': 32.25448753384889,
        '-163': 32.25445775564937,
        '-162': 32.25443782579963,
        '-161': 32.25442780810396,
        '-160': 32.254407587185064,
        '-159': 32.254346973978556,
        '-158': 32.25424613948093,
        '-157': 32.25412549484168,
        '-156': 32.2540054643051,
        '-155': 32.25385593295816,
        '-154': 32.253727661257024,
        '-153': 32.25365118465684,
        '-152': 32.25355594518159,
        '-151': 32.25347246201898,
        '-150': 32.25333015910088,
        '-149': 32.253149421399705,
        '-148': 32.25312244615431,
        '-147': 32.25310785720217,
        '-146': 32.253095666993815,
        '-145': 32.25308598086274,
        '-144': 32.25212881106712,
        '-143': 32.251689793548714,
        '-142': 32.25148596820334,
        '-141': 32.25124449802577,
        '-140': 32.25144043779223,
        '-139': 32.2513056918209,
        '-138': 32.25116368225919,
        '-137': 32.25083246801234,
        '-136': 32.250766838589094,
        '-135': 32.25044116859648,
        '-134': 32.24997666733593,
        '-133': 32.24968657124095,
        '-132': 32.24907549218962,
        '-131': 32.248537485529376,
        '-130': 32.24765797264877,
        '-129': 32.24706345270017,
        '-128': 32.24677395410816,
        '-127': 32.24675896508565,
        '-126': 32.24669480958603,
        '-125': 32.24664190686237,
        '-124': 32.24631695048356,
        '-123': 32.24590165530131,
        '-122': 32.24570919237485,
        '-121': 32.24533492738529,
        '-120': 32.24479883943375,
        '-119': 32.244313027003514,
        '-118': 32.24384694640827,
        '-117': 32.243420614581396,
        '-116': 32.242922605108845,
        '-115': 32.24217070668965,
        '-114': 32.24125572138562,
        '-113': 32.24050101601281,
        '-112': 32.24002772127221,
        '-111': 32.23943115788101,
        '-110': 32.23896388498719,
        '-109': 32.2386357719059,
        '-108': 32.23825443515071,
        '-107': 32.23782971108657,
        '-106': 32.23735120202337,
        '-105': 32.23706130224823,
        '-104': 32.236969829831956,
        '-103': 32.236985487218114,
        '-102': 32.23696642419379,
        '-101': 32.23687193159866,
        '-100': 32.23674223129569,
        '-99': 32.23667825916615,
        '-98': 32.23650797662941,
        '-97': 32.23628187496947,
        '-96': 32.236010066377744,
        '-95': 32.23568251654413,
        '-94': 32.235865567953525,
        '-93': 32.236539190786225,
        '-92': 32.236702747577326,
        '-91': 32.23688237909002,
        '-90': 32.237826656442074,
        '-89': 32.2391519126658,
        '-88': 32.23950401287583,
        '-87': 32.23826706483122,
        '-86': 32.24023331110644,
        '-85': 32.2404197891193,
        '-84': 32.240768070215275,
        '-83': 32.241157517277856,
        '-82': 32.241518022814276,
        '-81': 32.24168848752064,
        '-80': 32.24195247445396,
        '-79': 32.24215887742682,
        '-78': 32.24241929711558,
        '-77': 32.24303726569333,
        '-76': 32.24343701425849,
        '-75': 32.244012882897735,
        '-74': 32.24443149873505,
        '-73': 32.24478386832333,
        '-72': 32.245120506500854,
        '-71': 32.24548176278154,
        '-70': 32.24580687169005,
        '-69': 32.246105768649144,
        '-68': 32.24623675248507,
        '-67': 32.24694752537416,
        '-66': 32.24870291367797,
        '-65': 32.25165463765682,
        '-64': 32.25233487409725,
        '-63': 32.25240114983698,
        '-62': 32.25220709708397,
        '-61': 32.251600869846236,
        '-60': 32.25096653163241,
        '-59': 32.24999054268662,
        '-58': 32.24914802251879,
        '-57': 32.24512301715243,
        '-56': 32.24119149321384,
        '-55': 32.24130503552512,
        '-54': 32.24146101448321,
        '-53': 32.24154829154245,
        '-52': 32.24190049564856,
        '-51': 32.24263903628373,
        '-50': 32.2429653789808,
        '-49': 32.24318282274079,
        '-48': 32.243402612023395,
        '-47': 32.243483216769874,
        '-46': 32.24355606385218,
        '-45': 32.24337843329308,
        '-44': 32.242596305437125,
        '-43': 32.24168479704463,
        '-42': 32.241301029087566,
        '-41': 32.24100999576186,
        '-40': 32.24070020587431,
        '-39': 32.24032078080256,
        '-38': 32.23999272260062,
        '-37': 32.23978643536636,
        '-36': 32.23962056470329,
        '-35': 32.23949462226932,
        '-34': 32.23934738070852,
        '-33': 32.239208618001406,
        '-32': 32.23905750137874,
        '-31': 32.23886305229278,
        '-30': 32.23867522297354,
        '-29': 32.23846301324474,
        '-28': 32.23827637873917,
        '-27': 32.23811469189025,
        '-26': 32.237977343138425,
        '-25': 32.23781314318193,
        '-24': 32.23763166255664,
        '-23': 32.23737166762136,
        '-22': 32.23700234499194,
        '-21': 32.23654355957973,
        '-20': 32.23609622446606,
        '-19': 32.235751203841055,
        '-18': 32.235508348878426,
        '-17': 32.235266397361976,
        '-16': 32.234995040468654,
        '-15': 32.2347146764884,
        '-14': 32.23442557110515,
        '-13': 32.23413821826103,
        '-12': 32.23378229153443,
        '-11': 32.23340898657518,
        '-10': 32.23304935926756,
        '-9': 32.23275477931847,
        '-8': 32.232505910419285,
        '-7': 32.23226329693019,
        '-6': 32.23201790980242,
        '-5': 32.23177090057247,
        '-4': 32.23153357972861,
        '-3': 32.231286978936694,
        '-2': 32.23103236585382,
        '-1': 32.230760908235936
    },
    '-85': {
        '0': 32.23091214919508,
        '1': 32.23076151396748,
        '2': 32.23062291093428,
        '3': 32.23049697050133,
        '4': 32.230384275289545,
        '5': 32.23030556560021,
        '6': 32.230220868094776,
        '7': 32.230140676592555,
        '8': 32.23006529563855,
        '9': 32.229984837798554,
        '10': 32.2298893274259,
        '11': 32.22981922553546,
        '12': 32.22974416184659,
        '13': 32.2297044149847,
        '14': 32.22966944103867,
        '15': 32.229638931838984,
        '16': 32.22961250540718,
        '17': 32.229579608809516,
        '18': 32.22953973773523,
        '19': 32.22950244750287,
        '20': 32.22945704809004,
        '21': 32.22942314413827,
        '22': 32.22939001332623,
        '23': 32.2293065095762,
        '24': 32.229202350278776,
        '25': 32.229036535585685,
        '26': 32.228828743164925,
        '27': 32.228598701285904,
        '28': 32.22836619759324,
        '29': 32.22814097989124,
        '30': 32.22791265594911,
        '31': 32.22767091241437,
        '32': 32.22737520013998,
        '33': 32.22706590600256,
        '34': 32.22676328596379,
        '35': 32.226437149005484,
        '36': 32.22608770172987,
        '37': 32.225705120013465,
        '38': 32.22535037557765,
        '39': 32.225044072570554,
        '40': 32.224756344621355,
        '41': 32.224477573644776,
        '42': 32.224198169760676,
        '43': 32.22390855923107,
        '44': 32.2236092727069,
        '45': 32.22337153171123,
        '46': 32.2231857224283,
        '47': 32.22303209899643,
        '48': 32.22292117039156,
        '49': 32.22284319067479,
        '50': 32.222798448900846,
        '51': 32.22274676757322,
        '52': 32.22270848321499,
        '53': 32.222663454753075,
        '54': 32.222631849548534,
        '55': 32.22258325733456,
        '56': 32.2225376761445,
        '57': 32.22249482775791,
        '58': 32.22245436048278,
        '59': 32.222415856973534,
        '60': 32.222378843687785,
        '61': 32.22234280175055,
        '62': 32.222317278932735,
        '63': 32.222301703465675,
        '64': 32.22227529520105,
        '65': 32.2222576814518,
        '66': 32.22223820789832,
        '67': 32.22221635285015,
        '68': 32.22219163885449,
        '69': 32.22218384724074,
        '70': 32.22219262077606,
        '71': 32.222197469197475,
        '72': 32.22219818328473,
        '73': 32.22221483994427,
        '74': 32.22221708776568,
        '75': 32.222235272063784,
        '76': 32.22227961379493,
        '77': 32.22234020576364,
        '78': 32.22241731376134,
        '79': 32.22249106387369,
        '80': 32.22255174050733,
        '81': 32.22258967502671,
        '82': 32.22264573940321,
        '83': 32.22270022267558,
        '84': 32.22273342991996,
        '85': 32.222766070634364,
        '86': 32.22281884372662,
        '87': 32.22287203101338,
        '88': 32.222895790661916,
        '89': 32.22294103748367,
        '90': 32.222967752897944,
        '91': 32.222996462299434,
        '92': 32.223067831984935,
        '93': 32.22313158797422,
        '94': 32.223218182440775,
        '95': 32.223297419440335,
        '96': 32.22337943471166,
        '97': 32.22345410899773,
        '98': 32.22353146211025,
        '99': 32.223621460636956,
        '100': 32.223713825163664,
        '101': 32.223818427994225,
        '102': 32.22393500354122,
        '103': 32.22404305336613,
        '104': 32.22415234759939,
        '105': 32.22426253590357,
        '106': 32.22439345378042,
        '107': 32.22452452847076,
        '108': 32.22465538553071,
        '109': 32.22479575788821,
        '110': 32.22490488402894,
        '111': 32.224992527816624,
        '112': 32.225078583629895,
        '113': 32.22519308365327,
        '114': 32.225335793901,
        '115': 32.225466114005286,
        '116': 32.225594006185716,
        '117': 32.22571938547734,
        '118': 32.22585232897308,
        '119': 32.22595246175258,
        '120': 32.22605019368578,
        '121': 32.22613558892903,
        '122': 32.226239186888904,
        '123': 32.22631077215461,
        '124': 32.22640121304782,
        '125': 32.22646041592952,
        '126': 32.226519153831696,
        '127': 32.22662844173835,
        '128': 32.226738323163666,
        '129': 32.2268695760887,
        '130': 32.22702279543631,
        '131': 32.22717838626662,
        '132': 32.227316753055995,
        '133': 32.227438483829744,
        '134': 32.22750374670171,
        '135': 32.22755345498301,
        '136': 32.22759817260527,
        '137': 32.22762820476213,
        '138': 32.22768426937835,
        '139': 32.22777671005485,
        '140': 32.22791578268232,
        '141': 32.228091456076015,
        '142': 32.22824311345546,
        '143': 32.22841101146161,
        '144': 32.22856462349959,
        '145': 32.228713716248116,
        '146': 32.22884776658403,
        '147': 32.22895616306335,
        '148': 32.22906861696754,
        '149': 32.229174375010615,
        '150': 32.22930303498287,
        '151': 32.2294336473662,
        '152': 32.22961595500642,
        '153': 32.22983907596369,
        '154': 32.23014265343528,
        '155': 32.23048550759469,
        '156': 32.23095786797074,
        '157': 32.2315489537533,
        '158': 32.2320357328508,
        '159': 32.23237713804955,
        '160': 32.232693937708,
        '161': 32.23308679038524,
        '162': 32.233838485605006,
        '163': 32.23475665992826,
        '164': 32.23504213132443,
        '165': 32.23953979830633,
        '166': 32.24138179384967,
        '167': 32.2419330998226,
        '168': 32.24199294108965,
        '169': 32.24018550473289,
        '170': 32.23623839522753,
        '171': 32.24001470769406,
        '172': 32.232871741358636,
        '173': 32.22529217671374,
        '174': 32.22962923804172,
        '175': 32.22726935100587,
        '176': 32.22488613672212,
        '177': 32.221853348158376,
        '178': 32.23240349013774,
        '179': 32.23464192867961,
        '180': 32.2326515592873,
        '-180': 32.2326515592873,
        '-179': 32.2368978085593,
        '-178': 32.2392819839783,
        '-177': 32.24644889786928,
        '-176': 32.24609950360527,
        '-175': 32.24092509497787,
        '-174': 32.22646330157288,
        '-173': 32.23239833805568,
        '-172': 32.22379149497954,
        '-171': 32.235414229608715,
        '-170': 32.24335117051913,
        '-169': 32.24879221033923,
        '-168': 32.25231247888226,
        '-167': 32.24980557039906,
        '-166': 32.25301285181508,
        '-165': 32.255442167137765,
        '-164': 32.255414700483755,
        '-163': 32.25537817757709,
        '-162': 32.25532270010828,
        '-161': 32.25527882704173,
        '-160': 32.2552265616726,
        '-159': 32.25518635925338,
        '-158': 32.25514834250909,
        '-157': 32.255112745615705,
        '-156': 32.25507979945734,
        '-155': 32.25504972851962,
        '-154': 32.255022747712005,
        '-153': 32.2550091680904,
        '-152': 32.25498895800534,
        '-151': 32.25496228564323,
        '-150': 32.25492929744844,
        '-149': 32.254799140974946,
        '-148': 32.25466288774266,
        '-147': 32.25451049590539,
        '-146': 32.25427124499424,
        '-145': 32.253975464471786,
        '-144': 32.25369388349497,
        '-143': 32.25336579320852,
        '-142': 32.2530315338833,
        '-141': 32.25280217526897,
        '-140': 32.25253604763287,
        '-139': 32.252364382844945,
        '-138': 32.252064572555575,
        '-137': 32.25150494359723,
        '-136': 32.25070543562264,
        '-135': 32.25018135155198,
        '-134': 32.24974031214602,
        '-133': 32.24925056657055,
        '-132': 32.248580334894804,
        '-131': 32.24795170053706,
        '-130': 32.247242979081186,
        '-129': 32.246726820850284,
        '-128': 32.246291656952565,
        '-127': 32.24581579616281,
        '-126': 32.245288776679,
        '-125': 32.24465969527658,
        '-124': 32.244626023090994,
        '-123': 32.24370083784642,
        '-122': 32.241378218286,
        '-121': 32.239559178137746,
        '-120': 32.23884001848717,
        '-119': 32.23882613741832,
        '-118': 32.23835443624168,
        '-117': 32.237778667226564,
        '-116': 32.23740205716438,
        '-115': 32.23691100202585,
        '-114': 32.236325635471275,
        '-113': 32.236111047066274,
        '-112': 32.23583236550436,
        '-111': 32.23580303912837,
        '-110': 32.23606351140808,
        '-109': 32.23594641042994,
        '-108': 32.235765254533895,
        '-107': 32.235348218648234,
        '-106': 32.23508973750076,
        '-105': 32.23488879149895,
        '-104': 32.234452297804665,
        '-103': 32.234225253515035,
        '-102': 32.234106694028384,
        '-101': 32.23308589270894,
        '-100': 32.23400361197668,
        '-99': 32.23431260990313,
        '-98': 32.2341647583926,
        '-97': 32.23414658236127,
        '-96': 32.234035947826975,
        '-95': 32.23396450715881,
        '-94': 32.23412454026316,
        '-93': 32.23559770205692,
        '-92': 32.236605495500555,
        '-91': 32.2360869880401,
        '-90': 32.23631648437398,
        '-89': 32.237536759379,
        '-88': 32.237898628094335,
        '-87': 32.23843319043246,
        '-86': 32.23947419776404,
        '-85': 32.2404660324376,
        '-84': 32.24156049937631,
        '-83': 32.242282739588845,
        '-82': 32.242764275079296,
        '-81': 32.243288221183256,
        '-80': 32.24375358401246,
        '-79': 32.244049221819694,
        '-78': 32.24423578763706,
        '-77': 32.24426271569555,
        '-76': 32.244251242140486,
        '-75': 32.24420127856239,
        '-74': 32.24413292472282,
        '-73': 32.24406625647782,
        '-72': 32.2439303564623,
        '-71': 32.243765480670774,
        '-70': 32.24340971131172,
        '-69': 32.24255962083839,
        '-68': 32.241952910633515,
        '-67': 32.24157927721135,
        '-66': 32.2403873690755,
        '-65': 32.24030754297669,
        '-64': 32.238469254108416,
        '-63': 32.2403503247557,
        '-62': 32.24243368301404,
        '-61': 32.242919913115166,
        '-60': 32.24307243319046,
        '-59': 32.24293169008604,
        '-58': 32.242659483171664,
        '-57': 32.24228623724768,
        '-56': 32.24182219579902,
        '-55': 32.24143935649104,
        '-54': 32.2411884391513,
        '-53': 32.24097868482494,
        '-52': 32.240840647311515,
        '-51': 32.240744253513185,
        '-50': 32.240639229550375,
        '-49': 32.24051573498191,
        '-48': 32.240374034030694,
        '-47': 32.24018406307794,
        '-46': 32.23998648727538,
        '-45': 32.23974109902545,
        '-44': 32.239437981246354,
        '-43': 32.23914802930326,
        '-42': 32.23890167363366,
        '-41': 32.238709089288626,
        '-40': 32.23853997981729,
        '-39': 32.23836399597872,
        '-38': 32.2381204116031,
        '-37': 32.237768651796195,
        '-36': 32.23739947312266,
        '-35': 32.23710358558599,
        '-34': 32.23688068768661,
        '-33': 32.236710212211506,
        '-32': 32.236531105836136,
        '-31': 32.236332807468706,
        '-30': 32.236155266802804,
        '-29': 32.23599796852711,
        '-28': 32.235890707765414,
        '-27': 32.23578237396702,
        '-26': 32.23563194775129,
        '-25': 32.235459080266736,
        '-24': 32.23528343692814,
        '-23': 32.23509436227137,
        '-22': 32.234860995504164,
        '-21': 32.23461319944067,
        '-20': 32.23436065516478,
        '-19': 32.23414344621976,
        '-18': 32.23394102051732,
        '-17': 32.23374300485847,
        '-16': 32.23355932941758,
        '-15': 32.23339999967307,
        '-14': 32.233234630494735,
        '-13': 32.23305315091033,
        '-12': 32.23288603990729,
        '-11': 32.23271327195411,
        '-10': 32.232555368051955,
        '-9': 32.23237222591829,
        '-8': 32.232164283585185,
        '-7': 32.23196239122044,
        '-6': 32.23177722250723,
        '-5': 32.231609384871014,
        '-4': 32.231439299613896,
        '-3': 32.23128787516271,
        '-2': 32.2311659229081,
        '-1': 32.23104381463368
    },
    '-86': {
        '0': 32.23066924848736,
        '1': 32.23061560897596,
        '2': 32.23056163284648,
        '3': 32.23050743675557,
        '4': 32.23046323968375,
        '5': 32.23042914239876,
        '6': 32.23037490506939,
        '7': 32.23032081860807,
        '8': 32.230256829250415,
        '9': 32.23018297386356,
        '10': 32.230119489420304,
        '11': 32.23007648342078,
        '12': 32.23004382278061,
        '13': 32.230011353414504,
        '14': 32.22997901152369,
        '15': 32.22994671491902,
        '16': 32.22992447546858,
        '17': 32.22989195835276,
        '18': 32.22985914866428,
        '19': 32.22983602263934,
        '20': 32.229802217196585,
        '21': 32.229757588050894,
        '22': 32.22970199137426,
        '23': 32.22963528720277,
        '24': 32.229537120351885,
        '25': 32.22941748002397,
        '26': 32.22928636902323,
        '27': 32.22912347331595,
        '28': 32.22891860972604,
        '29': 32.228681838474444,
        '30': 32.228433352509555,
        '31': 32.22819336692526,
        '32': 32.22795178941758,
        '33': 32.227728882004804,
        '34': 32.22749438168504,
        '35': 32.22724837917712,
        '36': 32.2269606609517,
        '37': 32.22669201233054,
        '38': 32.22642236377204,
        '39': 32.22619230837849,
        '40': 32.22601212304502,
        '41': 32.225871877427394,
        '42': 32.22576164679276,
        '43': 32.225661401802014,
        '44': 32.225571323987666,
        '45': 32.22549158742093,
        '46': 32.225402143968815,
        '47': 32.225323353525766,
        '48': 32.22524524073836,
        '49': 32.2251578098619,
        '50': 32.225081355761496,
        '51': 32.22500583243113,
        '52': 32.22493127114018,
        '53': 32.22486777854559,
        '54': 32.224815327584736,
        '55': 32.22476375817316,
        '56': 32.22471298610069,
        '57': 32.22466290005724,
        '58': 32.224623467468994,
        '59': 32.22458442507449,
        '60': 32.22454559366707,
        '61': 32.224516881449226,
        '62': 32.22448797568002,
        '63': 32.224458658515324,
        '64': 32.22442870714167,
        '65': 32.224418106435266,
        '66': 32.22441653223808,
        '67': 32.224413667659825,
        '68': 32.224409311432524,
        '69': 32.22441338181544,
        '70': 32.224405502401126,
        '71': 32.224405735777744,
        '72': 32.224413959661916,
        '73': 32.22441997337198,
        '74': 32.224413603915664,
        '75': 32.22440491533044,
        '76': 32.22441410396842,
        '77': 32.22441087464456,
        '78': 32.224415479902,
        '79': 32.22440778390025,
        '80': 32.22440808994991,
        '81': 32.224406413580105,
        '82': 32.2244129966879,
        '83': 32.224407790537235,
        '84': 32.22442127290279,
        '85': 32.224433420615036,
        '86': 32.224444423354356,
        '87': 32.2244746770207,
        '88': 32.2245041733169,
        '89': 32.2245230047303,
        '90': 32.22454145968057,
        '91': 32.22453951848595,
        '92': 32.22456784780049,
        '93': 32.22457610621668,
        '94': 32.224594732315005,
        '95': 32.224633948091174,
        '96': 32.22466356589431,
        '97': 32.22470387152459,
        '98': 32.22473474155044,
        '99': 32.22475623092316,
        '100': 32.224788570525725,
        '101': 32.22482168393965,
        '102': 32.224855577212004,
        '103': 32.224880147686875,
        '104': 32.22489537835741,
        '105': 32.224901243624196,
        '106': 32.22491790179488,
        '107': 32.22490493746438,
        '108': 32.22489260007935,
        '109': 32.22488085574781,
        '110': 32.224859581871655,
        '111': 32.22482875863953,
        '112': 32.224808563861686,
        '113': 32.22481918656417,
        '114': 32.2248303647055,
        '115': 32.224852227554756,
        '116': 32.224905015118075,
        '117': 32.22494843281538,
        '118': 32.22500276322922,
        '119': 32.22506812552805,
        '120': 32.22511438631822,
        '121': 32.225171984123335,
        '122': 32.22524110201728,
        '123': 32.225291669581075,
        '124': 32.225344089076316,
        '125': 32.22540868516137,
        '126': 32.22547561290729,
        '127': 32.22553503644521,
        '128': 32.22561748501358,
        '129': 32.22572321760213,
        '130': 32.225842400296315,
        '131': 32.22594501217735,
        '132': 32.22605146933232,
        '133': 32.22615190139488,
        '134': 32.22626668654158,
        '135': 32.226385908031894,
        '136': 32.22649961926517,
        '137': 32.22661802095164,
        '138': 32.22673109655321,
        '139': 32.226848971743735,
        '140': 32.22697164052906,
        '141': 32.22709905398965,
        '142': 32.22722102640878,
        '143': 32.22733741874765,
        '144': 32.2274480475852,
        '145': 32.22755268609596,
        '146': 32.227661161213106,
        '147': 32.227752974630484,
        '148': 32.227847973612164,
        '149': 32.22794578296095,
        '150': 32.22804599846155,
        '151': 32.22814819118015,
        '152': 32.228272113627256,
        '153': 32.22841730741823,
        '154': 32.22858330419744,
        '155': 32.228799941965356,
        '156': 32.22901623263472,
        '157': 32.229241807460326,
        '158': 32.22943577715966,
        '159': 32.229688652529845,
        '160': 32.229969692607106,
        '161': 32.230318935161726,
        '162': 32.23062479666916,
        '163': 32.230967802789785,
        '164': 32.23117572487034,
        '165': 32.231349372616286,
        '166': 32.23147836942883,
        '167': 32.23160294620328,
        '168': 32.23175326571488,
        '169': 32.23190895493001,
        '170': 32.23197885855482,
        '171': 32.232023591332684,
        '172': 32.232113930178016,
        '173': 32.23213858406627,
        '174': 32.23212792625478,
        '175': 32.231242279564135,
        '176': 32.23143435152551,
        '177': 32.23149019551535,
        '178': 32.231086195462616,
        '179': 32.23114316791626,
        '180': 32.231347652574996,
        '-180': 32.231347652574996,
        '-179': 32.23150761815198,
        '-178': 32.23153222402696,
        '-177': 32.231168796754744,
        '-176': 32.2307413267275,
        '-175': 32.23036134245307,
        '-174': 32.22993806642513,
        '-173': 32.22960325712566,
        '-172': 32.22959990002392,
        '-171': 32.23028220092216,
        '-170': 32.23181223462237,
        '-169': 32.23308790338281,
        '-168': 32.23330038187234,
        '-167': 32.233228764224044,
        '-166': 32.23187228536781,
        '-165': 32.233104184767846,
        '-164': 32.234184295366425,
        '-163': 32.235112893707225,
        '-162': 32.23296814838517,
        '-161': 32.231390676032504,
        '-160': 32.24448617595329,
        '-159': 32.24637902510532,
        '-158': 32.2395755245122,
        '-157': 32.238821949812376,
        '-156': 32.23783782720064,
        '-155': 32.22743607386017,
        '-154': 32.24564341920682,
        '-153': 32.24775012961602,
        '-152': 32.24794792020498,
        '-151': 32.245144854221195,
        '-150': 32.24369987548494,
        '-149': 32.238466758371835,
        '-148': 32.240030229091616,
        '-147': 32.23828081690598,
        '-146': 32.23388638891341,
        '-145': 32.22904097559683,
        '-144': 32.23151422123965,
        '-143': 32.233778157554916,
        '-142': 32.23634811152283,
        '-141': 32.23234100698577,
        '-140': 32.229609965234154,
        '-139': 32.23019547440058,
        '-138': 32.23442119728629,
        '-137': 32.23239290732509,
        '-136': 32.22821389689137,
        '-135': 32.22922021930462,
        '-134': 32.236413446700205,
        '-133': 32.2403037595497,
        '-132': 32.24144505323143,
        '-131': 32.24255653446934,
        '-130': 32.242333410081464,
        '-129': 32.236801775029164,
        '-128': 32.23541562430949,
        '-127': 32.23993358584575,
        '-126': 32.23755515766409,
        '-125': 32.23583319365419,
        '-124': 32.23463596553492,
        '-123': 32.23170891055236,
        '-122': 32.231782919253355,
        '-121': 32.23197674582469,
        '-120': 32.232280177851884,
        '-119': 32.23317838314597,
        '-118': 32.2337614667887,
        '-117': 32.2338878164346,
        '-116': 32.23384047428681,
        '-115': 32.23360933259305,
        '-114': 32.23342693528594,
        '-113': 32.233222555058305,
        '-112': 32.232945707767406,
        '-111': 32.232647019293246,
        '-110': 32.2323569104498,
        '-109': 32.232065381926226,
        '-108': 32.23173212666639,
        '-107': 32.23143814188538,
        '-106': 32.23124421020931,
        '-105': 32.23114036930037,
        '-104': 32.23116719749564,
        '-103': 32.23141580804322,
        '-102': 32.232229980904954,
        '-101': 32.2335694862898,
        '-100': 32.235080795485764,
        '-99': 32.236369889948044,
        '-98': 32.23709322507393,
        '-97': 32.237493516528474,
        '-96': 32.23773263010025,
        '-95': 32.238043181376206,
        '-94': 32.2382433386112,
        '-93': 32.23846460790593,
        '-92': 32.238565557018084,
        '-91': 32.238596791621546,
        '-90': 32.238709982101355,
        '-89': 32.238925374167046,
        '-88': 32.23912167512778,
        '-87': 32.239420177158536,
        '-86': 32.239699543183164,
        '-85': 32.239959716853264,
        '-84': 32.24005908526591,
        '-83': 32.240068310746445,
        '-82': 32.24008837300941,
        '-81': 32.24013936016222,
        '-80': 32.24028178872225,
        '-79': 32.24053572515956,
        '-78': 32.24092122816578,
        '-77': 32.24166058936279,
        '-76': 32.2427637880524,
        '-75': 32.24328004807564,
        '-74': 32.24345180787456,
        '-73': 32.243481109500834,
        '-72': 32.243387976261765,
        '-71': 32.243232898904964,
        '-70': 32.24292467522076,
        '-69': 32.24232155421354,
        '-68': 32.241656016606264,
        '-67': 32.24109986723831,
        '-66': 32.240652997465446,
        '-65': 32.240143408349404,
        '-64': 32.239520494337334,
        '-63': 32.23899659348209,
        '-62': 32.23853125569881,
        '-61': 32.238164953791724,
        '-60': 32.23793818352768,
        '-59': 32.237921795094984,
        '-58': 32.23794402103784,
        '-57': 32.23799489841709,
        '-56': 32.238034165711994,
        '-55': 32.23807213777582,
        '-54': 32.23809893550405,
        '-53': 32.238094595657834,
        '-52': 32.2380492853932,
        '-51': 32.23798351014165,
        '-50': 32.23789757085802,
        '-49': 32.23778167122166,
        '-48': 32.23763612647119,
        '-47': 32.23746125195262,
        '-46': 32.23726746255913,
        '-45': 32.23706516238828,
        '-44': 32.23692535589541,
        '-43': 32.23678769038845,
        '-42': 32.23662211104197,
        '-41': 32.236438949838465,
        '-40': 32.23623840975183,
        '-39': 32.23602066455221,
        '-38': 32.2358060560876,
        '-37': 32.23559469130255,
        '-36': 32.23540684144067,
        '-35': 32.235202147121456,
        '-34': 32.2350311065665,
        '-33': 32.234903785470976,
        '-32': 32.23481001889422,
        '-31': 32.234749709078514,
        '-30': 32.23470253032961,
        '-29': 32.23462792957012,
        '-28': 32.23454592010037,
        '-27': 32.23441589620596,
        '-26': 32.23421742758289,
        '-25': 32.2339603646317,
        '-24': 32.23368484574422,
        '-23': 32.23341080357924,
        '-22': 32.23314806786663,
        '-21': 32.23291657037413,
        '-20': 32.23270594543364,
        '-19': 32.2325260372225,
        '-18': 32.23236649849255,
        '-17': 32.2322169950027,
        '-16': 32.232087414350346,
        '-15': 32.23197756257977,
        '-14': 32.2318468528822,
        '-13': 32.23173554989383,
        '-12': 32.23162332264342,
        '-11': 32.23153028347272,
        '-10': 32.23141594232841,
        '-9': 32.231320675870826,
        '-8': 32.231234362137585,
        '-7': 32.23116711545955,
        '-6': 32.23108864782807,
        '-5': 32.23099900992265,
        '-4': 32.23089827155366,
        '-3': 32.23082694903795,
        '-2': 32.230764929289286,
        '-1': 32.230722431260155
    },
    '-87': {
        '0': 32.230286020565416,
        '1': 32.23024076293318,
        '2': 32.23017583758313,
        '3': 32.23011135259202,
        '4': 32.230047227071374,
        '5': 32.22996318706599,
        '6': 32.22988944611085,
        '7': 32.2298259294794,
        '8': 32.22976246535196,
        '9': 32.22969898077868,
        '10': 32.229645503664536,
        '11': 32.22962216477525,
        '12': 32.22959859867826,
        '13': 32.229574738585555,
        '14': 32.22956062051167,
        '15': 32.22953597998485,
        '16': 32.229500755227896,
        '17': 32.22947509115185,
        '18': 32.22944883108234,
        '19': 32.22942192278311,
        '20': 32.229404420482325,
        '21': 32.22937607327286,
        '22': 32.22934694280252,
        '23': 32.22929678440251,
        '24': 32.22924577267925,
        '25': 32.22918377583401,
        '26': 32.229130980155226,
        '27': 32.22906715924808,
        '28': 32.22898219525743,
        '29': 32.228906396015944,
        '30': 32.22881954934709,
        '31': 32.22873176354506,
        '32': 32.228653152306656,
        '33': 32.228553410589896,
        '34': 32.2284527660848,
        '35': 32.2283411326271,
        '36': 32.2282185329941,
        '37': 32.22809509888463,
        '38': 32.22796075136468,
        '39': 32.227815518909146,
        '40': 32.22766953707722,
        '41': 32.22754304804686,
        '42': 32.22740576001248,
        '43': 32.2272880191578,
        '44': 32.227179742656396,
        '45': 32.22707084423848,
        '46': 32.226981554329114,
        '47': 32.22689167140379,
        '48': 32.22682141668865,
        '49': 32.226760685078375,
        '50': 32.22669936533814,
        '51': 32.226667769024445,
        '52': 32.2266355580289,
        '53': 32.226602709295456,
        '54': 32.22658940856145,
        '55': 32.22657540543802,
        '56': 32.22658087388721,
        '57': 32.22658555165426,
        '58': 32.22657927859937,
        '59': 32.22658221376085,
        '60': 32.22658418870498,
        '61': 32.22657503158391,
        '62': 32.22657489259916,
        '63': 32.22658370445815,
        '64': 32.22658118272056,
        '65': 32.22657736798218,
        '66': 32.22657219399045,
        '67': 32.226565596783225,
        '68': 32.22655751561655,
        '69': 32.22655800211347,
        '70': 32.2265467880526,
        '71': 32.226544043950526,
        '72': 32.226539622080494,
        '73': 32.226523382158085,
        '74': 32.22651551553047,
        '75': 32.22650589764937,
        '76': 32.22648441304018,
        '77': 32.226471277676445,
        '78': 32.226456393644604,
        '79': 32.22643977969547,
        '80': 32.226411356948994,
        '81': 32.22639137541226,
        '82': 32.22635966736295,
        '83': 32.226326392892666,
        '84': 32.22629161340719,
        '85': 32.22625539777429,
        '86': 32.226207717222685,
        '87': 32.22615875940255,
        '88': 32.22611871623425,
        '89': 32.22606747311626,
        '90': 32.22602533517887,
        '91': 32.22597219824318,
        '92': 32.22590816923565,
        '93': 32.22585356099683,
        '94': 32.22578828091233,
        '95': 32.225732644101186,
        '96': 32.225686763142,
        '97': 32.22563055270767,
        '98': 32.2255843279971,
        '99': 32.225528006815274,
        '100': 32.22548190285862,
        '101': 32.22544613091297,
        '102': 32.2253905150667,
        '103': 32.22534546345595,
        '104': 32.22530099448487,
        '105': 32.22525722370859,
        '106': 32.225224361318865,
        '107': 32.2251924267566,
        '108': 32.225151441383424,
        '109': 32.22512170862602,
        '110': 32.22510334242457,
        '111': 32.2250762719677,
        '112': 32.22506079838303,
        '113': 32.22505703629166,
        '114': 32.22505500927108,
        '115': 32.225064924320634,
        '116': 32.225086896356515,
        '117': 32.225110949804225,
        '118': 32.225137199697656,
        '119': 32.22516576002846,
        '120': 32.22519674329287,
        '121': 32.22523025996206,
        '122': 32.22527650691829,
        '123': 32.225315410455785,
        '124': 32.22535716029255,
        '125': 32.22540185206363,
        '126': 32.22544957569621,
        '127': 32.22549032594641,
        '128': 32.22553426715625,
        '129': 32.22559155513249,
        '130': 32.2256521591865,
        '131': 32.225706037999586,
        '132': 32.225763316270026,
        '133': 32.22581392873884,
        '134': 32.2258780643962,
        '135': 32.225935541738814,
        '136': 32.226006522494025,
        '137': 32.22607079460742,
        '138': 32.22615858130841,
        '139': 32.22622945675772,
        '140': 32.22630352184516,
        '141': 32.226400864956325,
        '142': 32.22650119197474,
        '143': 32.22659428195781,
        '144': 32.22667998990952,
        '145': 32.22676825021779,
        '146': 32.22684879500412,
        '147': 32.226931531927754,
        '148': 32.22701626216258,
        '149': 32.2271027753795,
        '150': 32.22717065465228,
        '151': 32.22723986349093,
        '152': 32.22731016449729,
        '153': 32.22738131411649,
        '154': 32.22744296287896,
        '155': 32.22750495920906,
        '156': 32.22755694750667,
        '157': 32.22759867282459,
        '158': 32.22763998648686,
        '159': 32.2276705358719,
        '160': 32.227700182233725,
        '161': 32.22771858283509,
        '162': 32.227745722329765,
        '163': 32.22776116762686,
        '164': 32.22777481713343,
        '165': 32.227796583712745,
        '166': 32.22783639552094,
        '167': 32.22788397505069,
        '168': 32.22793916796122,
        '169': 32.22799172286168,
        '170': 32.22804151426695,
        '171': 32.22806820674507,
        '172': 32.228102045174246,
        '173': 32.228132840912565,
        '174': 32.22815042029056,
        '175': 32.22816485426798,
        '176': 32.22817611683635,
        '177': 32.22819431349131,
        '178': 32.22819922016502,
        '179': 32.22822120475308,
        '180': 32.2282501889031,
        '-180': 32.2282501889031,
        '-179': 32.228306455507074,
        '-178': 32.2284204179112,
        '-177': 32.22861238703589,
        '-176': 32.228862220597065,
        '-175': 32.22914978569198,
        '-174': 32.22948530808368,
        '-173': 32.22987902287415,
        '-172': 32.23033105666555,
        '-171': 32.231286697526635,
        '-170': 32.23181531259163,
        '-169': 32.232240784867315,
        '-168': 32.23274537463341,
        '-167': 32.23338993951995,
        '-166': 32.23403298859136,
        '-165': 32.234391380287974,
        '-164': 32.2337874187073,
        '-163': 32.23272722324436,
        '-162': 32.23203041121694,
        '-161': 32.23184880034038,
        '-160': 32.232081330815426,
        '-159': 32.23235386322121,
        '-158': 32.23257549732236,
        '-157': 32.23268567722764,
        '-156': 32.23279579211042,
        '-155': 32.2336038522585,
        '-154': 32.235150450121694,
        '-153': 32.23608030496493,
        '-152': 32.23618105867107,
        '-151': 32.23619121024154,
        '-150': 32.23635358747316,
        '-149': 32.23605126816674,
        '-148': 32.23559788175964,
        '-147': 32.23605541139812,
        '-146': 32.235340461947516,
        '-145': 32.23407009661725,
        '-144': 32.23366009950806,
        '-143': 32.23316997486394,
        '-142': 32.23252895156458,
        '-141': 32.23199991781014,
        '-140': 32.23164350462727,
        '-139': 32.23164165576906,
        '-138': 32.231812342561255,
        '-137': 32.232165638921046,
        '-136': 32.232479082594736,
        '-135': 32.23272230165425,
        '-134': 32.232875031987774,
        '-133': 32.23294733895275,
        '-132': 32.23291895717258,
        '-131': 32.23283028196276,
        '-130': 32.23276214619151,
        '-129': 32.23268417415168,
        '-128': 32.23260643146592,
        '-127': 32.23254909511231,
        '-126': 32.23251212591478,
        '-125': 32.232495488529665,
        '-124': 32.23247893393784,
        '-123': 32.23244221863158,
        '-122': 32.23239543130257,
        '-121': 32.232338556812515,
        '-120': 32.23228169398072,
        '-119': 32.23221472984335,
        '-118': 32.23214777397893,
        '-117': 32.23209094088022,
        '-116': 32.23204424174423,
        '-115': 32.232017800699175,
        '-114': 32.23199142248439,
        '-113': 32.23198534791751,
        '-112': 32.23198949767373,
        '-111': 32.232014011191666,
        '-110': 32.2320387072505,
        '-109': 32.232083837212116,
        '-108': 32.23212922282649,
        '-107': 32.23220522429081,
        '-106': 32.23231187851576,
        '-105': 32.23242900587583,
        '-104': 32.23259707096699,
        '-103': 32.23276556592631,
        '-102': 32.23296484131149,
        '-101': 32.233174703849684,
        '-100': 32.23342549553707,
        '-99': 32.233717229050995,
        '-98': 32.23404991080651,
        '-97': 32.2344336494395,
        '-96': 32.234777453494154,
        '-95': 32.23510151911733,
        '-94': 32.235365376154775,
        '-93': 32.235558871543795,
        '-92': 32.23572239414552,
        '-91': 32.23584577412153,
        '-90': 32.23590872054052,
        '-89': 32.235901042877764,
        '-88': 32.23586309765429,
        '-87': 32.23574423338311,
        '-86': 32.23560501509082,
        '-85': 32.23551611623177,
        '-84': 32.235426865954764,
        '-83': 32.2353371449077,
        '-82': 32.235277165889485,
        '-81': 32.23522657821565,
        '-80': 32.23519536509438,
        '-79': 32.23515305812668,
        '-78': 32.235119751391,
        '-77': 32.23508520230327,
        '-76': 32.23503917029073,
        '-75': 32.23497141834291,
        '-74': 32.234912167561696,
        '-73': 32.23482085341599,
        '-72': 32.23472771191976,
        '-71': 32.23464276184779,
        '-70': 32.23457603203654,
        '-69': 32.234537562257486,
        '-68': 32.234537404028416,
        '-67': 32.23457550852912,
        '-66': 32.23462150254328,
        '-65': 32.23463491680747,
        '-64': 32.23460563799388,
        '-63': 32.234564018590554,
        '-62': 32.23448986653296,
        '-61': 32.23443378798969,
        '-60': 32.23442617650838,
        '-59': 32.23444688473801,
        '-58': 32.234475784162754,
        '-57': 32.23454331253155,
        '-56': 32.234629371617125,
        '-55': 32.23469366391952,
        '-54': 32.234736345445214,
        '-53': 32.23473737019496,
        '-52': 32.23469692207884,
        '-51': 32.23464551357442,
        '-50': 32.23459344712747,
        '-49': 32.234530820027295,
        '-48': 32.23445784162961,
        '-47': 32.234384828250995,
        '-46': 32.23430189041869,
        '-45': 32.234239549013395,
        '-44': 32.2341778098296,
        '-43': 32.23411688227103,
        '-42': 32.23403677211697,
        '-41': 32.23395788056343,
        '-40': 32.23388039938533,
        '-39': 32.23380451170179,
        '-38': 32.23374048768701,
        '-37': 32.23366829507563,
        '-36': 32.23360827743836,
        '-35': 32.23356057051832,
        '-34': 32.23349501272402,
        '-33': 32.233431905315726,
        '-32': 32.233361249856124,
        '-31': 32.23326294162207,
        '-30': 32.23315723543029,
        '-29': 32.233023997798824,
        '-28': 32.23290363571851,
        '-27': 32.23277598544686,
        '-26': 32.23266123776628,
        '-25': 32.23254929413658,
        '-24': 32.23246031760066,
        '-23': 32.23237409420868,
        '-22': 32.23228049100709,
        '-21': 32.232189546624305,
        '-20': 32.2320810177023,
        '-19': 32.2319750155241,
        '-18': 32.231841189918875,
        '-17': 32.231709728564184,
        '-16': 32.23157045052531,
        '-15': 32.23143335192037,
        '-14': 32.23129833451843,
        '-13': 32.231175388647046,
        '-12': 32.23107450348765,
        '-11': 32.23096530128704,
        '-10': 32.230867860867825,
        '-9': 32.23079217092785,
        '-8': 32.23071794454414,
        '-7': 32.23064507922311,
        '-6': 32.230593660186095,
        '-5': 32.23054340310855,
        '-4': 32.230484116716895,
        '-3': 32.230435893026296,
        '-2': 32.23037845070526,
        '-1': 32.23033188758083
    },
    '-88': {
        '0': 32.22953963936821,
        '1': 32.229487437167485,
        '2': 32.22944642247331,
        '3': 32.229406455480934,
        '4': 32.22935739480739,
        '5': 32.22929918673243,
        '6': 32.229251955837896,
        '7': 32.22919546964169,
        '8': 32.22914985318443,
        '9': 32.229104963003266,
        '10': 32.229080926080506,
        '11': 32.229057508944614,
        '12': 32.22904474935405,
        '13': 32.2290425954044,
        '14': 32.22905099580939,
        '15': 32.22905980795795,
        '16': 32.229079073409345,
        '17': 32.229098650494926,
        '18': 32.22911849013689,
        '19': 32.22914863763161,
        '20': 32.22916885938702,
        '21': 32.229189202413835,
        '22': 32.22919952726348,
        '23': 32.22921997870427,
        '24': 32.22924041990372,
        '25': 32.22926080930525,
        '26': 32.22926091467313,
        '27': 32.229270983418296,
        '28': 32.22926068862837,
        '29': 32.22925018511382,
        '30': 32.2292192423718,
        '31': 32.22916782354055,
        '32': 32.22908579494799,
        '33': 32.22898321918609,
        '34': 32.228849964111625,
        '35': 32.22869609493302,
        '36': 32.228521579862246,
        '37': 32.228316288772206,
        '38': 32.22813068789802,
        '39': 32.22794455117295,
        '40': 32.22777805072973,
        '41': 32.22762106071632,
        '42': 32.22748365544842,
        '43': 32.227375910548496,
        '44': 32.22726749970319,
        '45': 32.22718870029533,
        '46': 32.22712938769599,
        '47': 32.22708953807576,
        '48': 32.22703882250432,
        '49': 32.22700752144517,
        '50': 32.22697540723589,
        '51': 32.22695255798964,
        '52': 32.226918745000724,
        '53': 32.22688404682387,
        '54': 32.2268585432386,
        '55': 32.226822005537464,
        '56': 32.226784514332195,
        '57': 32.226746048014284,
        '58': 32.22671668941365,
        '59': 32.226686315067894,
        '60': 32.226654905862134,
        '61': 32.22662244375642,
        '62': 32.22658891191328,
        '63': 32.226554294830464,
        '64': 32.226528682848496,
        '65': 32.226501959311484,
        '66': 32.226484218011684,
        '67': 32.226455241158604,
        '68': 32.22643523098117,
        '69': 32.226414078289494,
        '70': 32.22639178068445,
        '71': 32.22637844278385,
        '72': 32.2263639624762,
        '73': 32.22633824032762,
        '74': 32.22632149335663,
        '75': 32.22631373209753,
        '76': 32.22629476113798,
        '77': 32.22627470151431,
        '78': 32.226273781141,
        '79': 32.226251605595785,
        '80': 32.22623851306491,
        '81': 32.226224428039906,
        '82': 32.22619927862541,
        '83': 32.22618330711666,
        '84': 32.22616644754503,
        '85': 32.22614874072848,
        '86': 32.22614033331865,
        '87': 32.22612106584708,
        '88': 32.226101090770285,
        '89': 32.22608046008502,
        '90': 32.226059228338144,
        '91': 32.226037452506105,
        '92': 32.226015191864334,
        '93': 32.22601271029368,
        '94': 32.225989665794636,
        '95': 32.225966326649804,
        '96': 32.22594275984673,
        '97': 32.22592913398745,
        '98': 32.22590531842974,
        '99': 32.22589158407627,
        '100': 32.22586780358161,
        '101': 32.225854248424845,
        '102': 32.22584089243054,
        '103': 32.22581771099072,
        '104': 32.22581507300088,
        '105': 32.22579265960661,
        '106': 32.22579093604188,
        '107': 32.225779682275224,
        '108': 32.22576906862273,
        '109': 32.22576926343311,
        '110': 32.22577024016881,
        '111': 32.225782164332934,
        '112': 32.225795007157814,
        '113': 32.2257987391152,
        '114': 32.22580352054955,
        '115': 32.22581950876456,
        '116': 32.22583666778613,
        '117': 32.22584495984802,
        '118': 32.22585453452122,
        '119': 32.22587553768917,
        '120': 32.22589792260634,
        '121': 32.22591163969249,
        '122': 32.22591673041984,
        '123': 32.22592332651683,
        '124': 32.22592136725116,
        '125': 32.2259209759727,
        '126': 32.22592217751701,
        '127': 32.225904804458324,
        '128': 32.225899154364164,
        '129': 32.22587495655911,
        '130': 32.225842311083746,
        '131': 32.22581131300249,
        '132': 32.225792052497816,
        '133': 32.22575423876146,
        '134': 32.225728139858774,
        '135': 32.225703642814594,
        '136': 32.22568072356972,
        '137': 32.22566944755065,
        '138': 32.22566978116415,
        '139': 32.22568168601807,
        '140': 32.22569502389969,
        '141': 32.22572993711547,
        '142': 32.225756087421274,
        '143': 32.22578351264061,
        '144': 32.2258323435774,
        '145': 32.225882323640626,
        '146': 32.225933383670316,
        '147': 32.22598545100107,
        '148': 32.22602835230001,
        '149': 32.2260721054754,
        '150': 32.22612672717196,
        '151': 32.22616183919118,
        '152': 32.226197551322265,
        '153': 32.22624387541153,
        '154': 32.22627042511245,
        '155': 32.22630740860084,
        '156': 32.22634463659339,
        '157': 32.226382018894505,
        '158': 32.22640936438735,
        '159': 32.22644678559822,
        '160': 32.22647399143698,
        '161': 32.22651109824512,
        '162': 32.22653781638704,
        '163': 32.226564164355786,
        '164': 32.22659006106289,
        '165': 32.226615428061265,
        '166': 32.22664018983777,
        '167': 32.2266642740873,
        '168': 32.226677507168645,
        '169': 32.22668992808326,
        '170': 32.226711581049145,
        '171': 32.22672219838521,
        '172': 32.22674193837227,
        '173': 32.226750540765124,
        '174': 32.226758067332504,
        '175': 32.226774585519145,
        '176': 32.22678995425631,
        '177': 32.22679403616822,
        '178': 32.22679691273491,
        '179': 32.22679856328953,
        '180': 32.226798971806,
        '-180': 32.226798971806,
        '-179': 32.22678801879546,
        '-178': 32.226785913233876,
        '-177': 32.2267926530101,
        '-176': 32.22679813173884,
        '-175': 32.22680235518801,
        '-174': 32.2268154417555,
        '-173': 32.22681718767718,
        '-172': 32.22683793629138,
        '-171': 32.22682716393088,
        '-170': 32.226835438457414,
        '-169': 32.22686278670278,
        '-168': 32.22688902005639,
        '-167': 32.22694449732453,
        '-166': 32.22699892533216,
        '-165': 32.22708266716709,
        '-164': 32.227185651365204,
        '-163': 32.22727758949117,
        '-162': 32.22737874000119,
        '-161': 32.22748914411296,
        '-160': 32.22758862550358,
        '-159': 32.22768733592415,
        '-158': 32.227785318332906,
        '-157': 32.227882615848436,
        '-156': 32.22797927155237,
        '-155': 32.2280854372944,
        '-154': 32.228180937503126,
        '-153': 32.22828603200818,
        '-152': 32.22840076184169,
        '-151': 32.228535275982445,
        '-150': 32.22866939600765,
        '-149': 32.228823377621666,
        '-148': 32.228966931694785,
        '-147': 32.22908998566471,
        '-146': 32.229202683038174,
        '-145': 32.22930505728116,
        '-144': 32.22936681533831,
        '-143': 32.229418313589875,
        '-142': 32.229459581355755,
        '-141': 32.22947043038526,
        '-140': 32.22947110373744,
        '-139': 32.229461626483435,
        '-138': 32.229431914599786,
        '-137': 32.22941231348542,
        '-136': 32.229372521014426,
        '-135': 32.22934287917946,
        '-134': 32.22929308405097,
        '-133': 32.22925347437884,
        '-132': 32.22921395848143,
        '-131': 32.22916444404187,
        '-130': 32.2291352649679,
        '-129': 32.22908600656132,
        '-128': 32.229067213350056,
        '-127': 32.22903846922408,
        '-126': 32.229019996582366,
        '-125': 32.22901180372111,
        '-124': 32.22902400424469,
        '-123': 32.229036392285344,
        '-122': 32.22907929194351,
        '-121': 32.229132495724976,
        '-120': 32.22919600759816,
        '-119': 32.2292698306081,
        '-118': 32.22933375478699,
        '-117': 32.22941809927443,
        '-116': 32.2294925459619,
        '-115': 32.229567199684574,
        '-114': 32.229621845903104,
        '-113': 32.22969690513573,
        '-112': 32.22975194801513,
        '-111': 32.22980718015659,
        '-110': 32.22986259353903,
        '-109': 32.229918178561334,
        '-108': 32.229994136474645,
        '-107': 32.2300500293866,
        '-106': 32.23012626767668,
        '-105': 32.23021272868931,
        '-104': 32.230309394161445,
        '-103': 32.23043645716226,
        '-102': 32.230583789486744,
        '-101': 32.23073115373247,
        '-100': 32.230898737479,
        '-99': 32.23105619173579,
        '-98': 32.231183271218804,
        '-97': 32.231279942468355,
        '-96': 32.23134617020951,
        '-95': 32.23140213246935,
        '-94': 32.2314477910651,
        '-93': 32.231493213883034,
        '-92': 32.231528252116405,
        '-91': 32.23155286315082,
        '-90': 32.231577111114376,
        '-89': 32.23161105941941,
        '-88': 32.23164455489384,
        '-87': 32.2316674435428,
        '-86': 32.231699895231756,
        '-85': 32.231721647440395,
        '-84': 32.231732653932596,
        '-83': 32.23174297721661,
        '-82': 32.23173235597559,
        '-81': 32.23172096285988,
        '-80': 32.231718863663026,
        '-79': 32.23170580123502,
        '-78': 32.231691845023896,
        '-77': 32.23166685048385,
        '-76': 32.231650999891386,
        '-75': 32.23161393831083,
        '-74': 32.231585961858016,
        '-73': 32.23155693816559,
        '-72': 32.23152684655226,
        '-71': 32.23149567028928,
        '-70': 32.23146339683017,
        '-69': 32.23144012503012,
        '-68': 32.23141574389532,
        '-67': 32.23140036113225,
        '-66': 32.231373769306416,
        '-65': 32.231356192316305,
        '-64': 32.231327433722576,
        '-63': 32.23130772660262,
        '-62': 32.231286991103765,
        '-61': 32.23126525860973,
        '-60': 32.23124256559106,
        '-59': 32.23122905777139,
        '-58': 32.23121467656577,
        '-57': 32.23121968009649,
        '-56': 32.23122391551359,
        '-55': 32.23123754558757,
        '-54': 32.2312606333289,
        '-53': 32.231293245627,
        '-52': 32.231335452983544,
        '-51': 32.231377228134235,
        '-50': 32.23139844790442,
        '-49': 32.23141939621474,
        '-48': 32.231440156309425,
        '-47': 32.23146081341787,
        '-46': 32.23146125676694,
        '-45': 32.23147187223306,
        '-44': 32.231462453730224,
        '-43': 32.23146338552345,
        '-42': 32.231454561726466,
        '-41': 32.23144616911349,
        '-40': 32.23143829584527,
        '-39': 32.23142093349121,
        '-38': 32.231394168954026,
        '-37': 32.23135808722696,
        '-36': 32.23131277103937,
        '-35': 32.23126839407476,
        '-34': 32.23121493907757,
        '-33': 32.23116257272722,
        '-32': 32.23111136426207,
        '-31': 32.23105128744334,
        '-30': 32.23098240518519,
        '-29': 32.23091486726509,
        '-28': 32.23083863635398,
        '-27': 32.23076385273861,
        '-26': 32.230690560807886,
        '-25': 32.23061880037388,
        '-24': 32.23054860661041,
        '-23': 32.2304699210124,
        '-22': 32.230413036400485,
        '-21': 32.230347706895294,
        '-20': 32.2302941262564,
        '-19': 32.23024221769908,
        '-18': 32.230191988653495,
        '-17': 32.230153529861276,
        '-16': 32.23010666436953,
        '-15': 32.23006147454346,
        '-14': 32.230028038187264,
        '-13': 32.22997607954285,
        '-12': 32.22993584354562,
        '-11': 32.22989722189399,
        '-10': 32.229860190273435,
        '-9': 32.229824721128146,
        '-8': 32.22980087114388,
        '-7': 32.229778519656186,
        '-6': 32.2297475431504,
        '-5': 32.22972807781791,
        '-4': 32.22968982021392,
        '-3': 32.22966298988841,
        '-2': 32.22961727869428,
        '-1': 32.22958290321021
    },
    '-89': {
        '0': 32.22913180123295,
        '1': 32.22911458267248,
        '2': 32.2290877287706,
        '3': 32.22906132121593,
        '4': 32.229045441229054,
        '5': 32.229019898436555,
        '6': 32.228994772933255,
        '7': 32.2289700539794,
        '8': 32.22894573034876,
        '9': 32.228911699740976,
        '10': 32.22888813119007,
        '11': 32.228864921607645,
        '12': 32.228831967252816,
        '13': 32.228809436398244,
        '14': 32.22878722468199,
        '15': 32.22876531817612,
        '16': 32.228743702669355,
        '17': 32.22871227244565,
        '18': 32.22869119520103,
        '19': 32.228660273434194,
        '20': 32.22863967483729,
        '21': 32.22860920117807,
        '22': 32.228578928517,
        '23': 32.22854884136773,
        '24': 32.22851892416353,
        '25': 32.228489161281495,
        '26': 32.228459537066506,
        '27': 32.228419943543464,
        '28': 32.228390549568005,
        '29': 32.22837133988203,
        '30': 32.22833202128879,
        '31': 32.22829276317884,
        '32': 32.22826364308788,
        '33': 32.22822446008624,
        '34': 32.22818529186582,
        '35': 32.22813603024195,
        '36': 32.22808675350381,
        '37': 32.22803744712402,
        '38': 32.2279780032078,
        '39': 32.227908407380085,
        '40': 32.227828645551845,
        '41': 32.22774879783292,
        '42': 32.22764866307052,
        '43': 32.22754841602018,
        '44': 32.22744804417859,
        '45': 32.22734753540392,
        '46': 32.22722668930828,
        '47': 32.227125871613694,
        '48': 32.227014788439504,
        '49': 32.22691361810545,
        '50': 32.226812256140285,
        '51': 32.22671069291524,
        '52': 32.22662910877578,
        '53': 32.22656749549917,
        '54': 32.22649556061653,
        '55': 32.22644358119077,
        '56': 32.22639136037607,
        '57': 32.22635908185172,
        '58': 32.226316454273494,
        '59': 32.22628366249801,
        '60': 32.226260701778344,
        '61': 32.22623747245709,
        '62': 32.22621397070357,
        '63': 32.226200288907435,
        '64': 32.226186328946525,
        '65': 32.22619228032584,
        '66': 32.22619795035338,
        '67': 32.226203338201394,
        '68': 32.22620844366341,
        '69': 32.22622336320315,
        '70': 32.226238001936196,
        '71': 32.22626245769009,
        '72': 32.22628663668682,
        '73': 32.22631054181653,
        '74': 32.226344272930284,
        '75': 32.22637773790775,
        '76': 32.22640084502338,
        '77': 32.22642369595361,
        '78': 32.226466489664205,
        '79': 32.22648884663053,
        '80': 32.22651096689468,
        '81': 32.226532858082045,
        '82': 32.22656462499999,
        '83': 32.22658608307757,
        '84': 32.226607338132524,
        '85': 32.22662839985312,
        '86': 32.22664927839638,
        '87': 32.22665988765986,
        '88': 32.22668043208076,
        '89': 32.22670082638816,
        '90': 32.226721082385126,
        '91': 32.226731115518255,
        '92': 32.22674103497864,
        '93': 32.22676095021811,
        '94': 32.22677068088699,
        '95': 32.22678033694,
        '96': 32.2267798351988,
        '97': 32.226779285965165,
        '98': 32.22678879958741,
        '99': 32.2267982932874,
        '100': 32.22679768443599,
        '101': 32.226797083574674,
        '102': 32.226806601135365,
        '103': 32.22680605800392,
        '104': 32.226795468082216,
        '105': 32.226795037971605,
        '106': 32.22679468475988,
        '107': 32.22678432546495,
        '108': 32.22677406963416,
        '109': 32.22677402643223,
        '110': 32.226764015851515,
        '111': 32.22676424269515,
        '112': 32.22675452638044,
        '113': 32.22675507084811,
        '114': 32.2267456948013,
        '115': 32.226736505069596,
        '116': 32.22673760790212,
        '117': 32.22673891677946,
        '118': 32.22675053687608,
        '119': 32.22675228453642,
        '120': 32.22676435985878,
        '121': 32.2267766740859,
        '122': 32.2267892339104,
        '123': 32.22681214144318,
        '124': 32.22682520991429,
        '125': 32.22683854007521,
        '126': 32.22686223200769,
        '127': 32.226876096796666,
        '128': 32.226890233080944,
        '129': 32.22691473883446,
        '130': 32.226929422861616,
        '131': 32.226944381646874,
        '132': 32.22696971103548,
        '133': 32.22697512120822,
        '134': 32.22700099530197,
        '135': 32.22700694561133,
        '136': 32.22701316135564,
        '137': 32.22702973492158,
        '138': 32.22703646881969,
        '139': 32.22704345401953,
        '140': 32.22704058791797,
        '141': 32.2270480568294,
        '142': 32.227035563658056,
        '143': 32.22703339020023,
        '144': 32.227021334613006,
        '145': 32.22701958121001,
        '146': 32.2269978300363,
        '147': 32.2269863613064,
        '148': 32.22697506765563,
        '149': 32.22695384106506,
        '150': 32.226942864040105,
        '151': 32.22692193073343,
        '152': 32.226901126006005,
        '153': 32.22687034019346,
        '154': 32.22684975499784,
        '155': 32.22682926032751,
        '156': 32.22680884307144,
        '157': 32.226788489966985,
        '158': 32.226778285190335,
        '159': 32.226747922628526,
        '160': 32.22673777913505,
        '161': 32.226727646113154,
        '162': 32.226707412266755,
        '163': 32.2266972597958,
        '164': 32.226676979674394,
        '165': 32.22666675462678,
        '166': 32.226666571889595,
        '167': 32.22666632071275,
        '168': 32.226665988549854,
        '169': 32.226675661470885,
        '170': 32.226685229284726,
        '171': 32.22670477883063,
        '172': 32.226734299021224,
        '173': 32.22675358176323,
        '174': 32.22678281376249,
        '175': 32.226811886240355,
        '176': 32.22685088838284,
        '177': 32.22688971211273,
        '178': 32.22692834857817,
        '179': 32.22697688858669,
        '180': 32.22702522521563,
        '-180': 32.22702522521563,
        '-179': 32.22706325170094,
        '-178': 32.227111159757406,
        '-177': 32.22716894321751,
        '-176': 32.227216397238514,
        '-175': 32.22728381519875,
        '-174': 32.22733079369998,
        '-173': 32.227387627374966,
        '-172': 32.22744421268852,
        '-171': 32.22750054627531,
        '-170': 32.22756672529013,
        '-169': 32.22762254736383,
        '-168': 32.227678110549036,
        '-167': 32.22773341340688,
        '-166': 32.22779855522681,
        '-165': 32.22785333497429,
        '-164': 32.22790785274988,
        '-163': 32.227952008417596,
        '-162': 32.228006003484275,
        '-161': 32.228049638115614,
        '-160': 32.228082913622536,
        '-159': 32.22812603285572,
        '-158': 32.228158796771496,
        '-157': 32.22818120768302,
        '-156': 32.22820336887343,
        '-155': 32.228225283291906,
        '-154': 32.22824695412547,
        '-153': 32.22825828406198,
        '-152': 32.22827947811736,
        '-151': 32.22828023789429,
        '-150': 32.22829086949517,
        '-149': 32.22830127625321,
        '-148': 32.228311462334766,
        '-147': 32.2283214319933,
        '-146': 32.22832108880868,
        '-145': 32.22834073936506,
        '-144': 32.2283500858336,
        '-143': 32.22836933408435,
        '-142': 32.22837828702345,
        '-141': 32.22839715046567,
        '-140': 32.22841582796991,
        '-139': 32.228434323760865,
        '-138': 32.228472843420334,
        '-137': 32.228490988085234,
        '-136': 32.228519063842064,
        '-135': 32.22854697374749,
        '-134': 32.228584822147916,
        '-133': 32.228622511795855,
        '-132': 32.22866004598503,
        '-131': 32.228687327109355,
        '-130': 32.22873466021553,
        '-129': 32.228761645155835,
        '-128': 32.22879858654464,
        '-127': 32.22883538592437,
        '-126': 32.228872045313246,
        '-125': 32.22889846579679,
        '-124': 32.22892474960687,
        '-123': 32.22895089801875,
        '-122': 32.2289769120499,
        '-121': 32.229002792456846,
        '-120': 32.229018439098866,
        '-119': 32.22903395286732,
        '-118': 32.229059434329166,
        '-117': 32.22907468196826,
        '-116': 32.22908979584369,
        '-115': 32.22909467459177,
        '-114': 32.22910951825442,
        '-113': 32.22913432545457,
        '-112': 32.22913879310655,
        '-111': 32.22915322093747,
        '-110': 32.22916750641671,
        '-109': 32.22917154688845,
        '-108': 32.22917544037741,
        '-107': 32.22918928460812,
        '-106': 32.229202976351296,
        '-105': 32.22921651262413,
        '-104': 32.22923999057713,
        '-103': 32.229263306606654,
        '-102': 32.22927635709295,
        '-101': 32.22931953946217,
        '-100': 32.22934234900193,
        '-99': 32.229385182881266,
        '-98': 32.22942783701733,
        '-97': 32.22947030772961,
        '-96': 32.22952269143962,
        '-95': 32.22958498443823,
        '-94': 32.22963698296245,
        '-93': 32.22968878360377,
        '-92': 32.22974038296333,
        '-91': 32.22979177777596,
        '-90': 32.229842964936616,
        '-89': 32.2298737417531,
        '-88': 32.229924505147835,
        '-87': 32.22996495301098,
        '-86': 32.23000518303922,
        '-85': 32.230024993959084,
        '-84': 32.2300445838169,
        '-83': 32.230074050959885,
        '-82': 32.23008309588515,
        '-81': 32.23010201682118,
        '-80': 32.23011061486422,
        '-79': 32.23012908918174,
        '-78': 32.23013724209597,
        '-77': 32.23014517432646,
        '-76': 32.230152887768526,
        '-75': 32.230150285965365,
        '-74': 32.23015756973117,
        '-73': 32.23016464360862,
        '-72': 32.23017151168331,
        '-71': 32.23016808017103,
        '-70': 32.230174551341854,
        '-69': 32.2301707342734,
        '-68': 32.230166733882,
        '-67': 32.230162557239936,
        '-66': 32.23015821202692,
        '-65': 32.230163804228326,
        '-64': 32.230138952076885,
        '-63': 32.23013415341298,
        '-62': 32.23012922291873,
        '-61': 32.230124171249734,
        '-60': 32.23010891264709,
        '-59': 32.23009355617031,
        '-58': 32.23008821080821,
        '-57': 32.23007269589203,
        '-56': 32.230067217935535,
        '-55': 32.23005159785858,
        '-54': 32.23003594642411,
        '-53': 32.23002027829516,
        '-52': 32.230004608524176,
        '-51': 32.229988952522156,
        '-50': 32.22997332602628,
        '-49': 32.22995774506598,
        '-48': 32.229942225927616,
        '-47': 32.22992678511798,
        '-46': 32.22991143932675,
        '-45': 32.22989620538781,
        '-44': 32.229881100239965,
        '-43': 32.2298661408869,
        '-42': 32.22986143838708,
        '-41': 32.22984682152613,
        '-40': 32.22983240145494,
        '-39': 32.22980810148964,
        '-38': 32.22979412558539,
        '-37': 32.22977030335971,
        '-36': 32.229756837716096,
        '-35': 32.22974365133441,
        '-34': 32.22972066721789,
        '-33': 32.229708086435686,
        '-32': 32.229695831128325,
        '-31': 32.22968391592822,
        '-30': 32.22967235502242,
        '-29': 32.22964097803803,
        '-28': 32.22963016659391,
        '-27': 32.22960965721882,
        '-26': 32.22958955402352,
        '-25': 32.22957996001371,
        '-24': 32.22956070286149,
        '-23': 32.22954188442393,
        '-22': 32.229523514237236,
        '-21': 32.22950560114962,
        '-20': 32.22948815330374,
        '-19': 32.229471178121,
        '-18': 32.22944459146623,
        '-17': 32.229438671745065,
        '-16': 32.229413061008586,
        '-15': 32.2293980359035,
        '-14': 32.22938350933974,
        '-13': 32.229359393720934,
        '-12': 32.22934587215472,
        '-11': 32.22933285544047,
        '-10': 32.22931025380094,
        '-9': 32.22929824773647,
        '-8': 32.229286745902336,
        '-7': 32.229265656321836,
        '-6': 32.22924506681407,
        '-5': 32.22922497415882,
        '-4': 32.22921546462957,
        '-3': 32.2291963531441,
        '-2': 32.229177724581135,
        '-1': 32.229149482688875
    },
    '-90': {
        '0': 32.228906688017744,
        '1': 32.228906688017744,
        '2': 32.228906688017744,
        '3': 32.228906688017744,
        '4': 32.228906688017744,
        '5': 32.228906688017744,
        '6': 32.228906688017744,
        '7': 32.228906688017744,
        '8': 32.228906688017744,
        '9': 32.228906688017744,
        '10': 32.228906688017744,
        '11': 32.228906688017744,
        '12': 32.228906688017744,
        '13': 32.228906688017744,
        '14': 32.228906688017744,
        '15': 32.228906688017744,
        '16': 32.228906688017744,
        '17': 32.228906688017744,
        '18': 32.228906688017744,
        '19': 32.228906688017744,
        '20': 32.228906688017744,
        '21': 32.228906688017744,
        '22': 32.228906688017744,
        '23': 32.228906688017744,
        '24': 32.228906688017744,
        '25': 32.228906688017744,
        '26': 32.228906688017744,
        '27': 32.228906688017744,
        '28': 32.228906688017744,
        '29': 32.228906688017744,
        '30': 32.228906688017744,
        '31': 32.228906688017744,
        '32': 32.228906688017744,
        '33': 32.228906688017744,
        '34': 32.228906688017744,
        '35': 32.228906688017744,
        '36': 32.228906688017744,
        '37': 32.228906688017744,
        '38': 32.228906688017744,
        '39': 32.228906688017744,
        '40': 32.228906688017744,
        '41': 32.228906688017744,
        '42': 32.228906688017744,
        '43': 32.228906688017744,
        '44': 32.228906688017744,
        '45': 32.228906688017744,
        '46': 32.228906688017744,
        '47': 32.228906688017744,
        '48': 32.228906688017744,
        '49': 32.228906688017744,
        '50': 32.228906688017744,
        '51': 32.228906688017744,
        '52': 32.228906688017744,
        '53': 32.228906688017744,
        '54': 32.228906688017744,
        '55': 32.228906688017744,
        '56': 32.228906688017744,
        '57': 32.228906688017744,
        '58': 32.228906688017744,
        '59': 32.228906688017744,
        '60': 32.228906688017744,
        '61': 32.228906688017744,
        '62': 32.228906688017744,
        '63': 32.228906688017744,
        '64': 32.228906688017744,
        '65': 32.228906688017744,
        '66': 32.228906688017744,
        '67': 32.228906688017744,
        '68': 32.228906688017744,
        '69': 32.228906688017744,
        '70': 32.228906688017744,
        '71': 32.228906688017744,
        '72': 32.228906688017744,
        '73': 32.228906688017744,
        '74': 32.228906688017744,
        '75': 32.228906688017744,
        '76': 32.228906688017744,
        '77': 32.228906688017744,
        '78': 32.228906688017744,
        '79': 32.228906688017744,
        '80': 32.228906688017744,
        '81': 32.228906688017744,
        '82': 32.228906688017744,
        '83': 32.228906688017744,
        '84': 32.228906688017744,
        '85': 32.228906688017744,
        '86': 32.228906688017744,
        '87': 32.228906688017744,
        '88': 32.228906688017744,
        '89': 32.228906688017744,
        '90': 32.228906688017744,
        '91': 32.228906688017744,
        '92': 32.228906688017744,
        '93': 32.228906688017744,
        '94': 32.228906688017744,
        '95': 32.228906688017744,
        '96': 32.228906688017744,
        '97': 32.228906688017744,
        '98': 32.228906688017744,
        '99': 32.228906688017744,
        '100': 32.228906688017744,
        '101': 32.228906688017744,
        '102': 32.228906688017744,
        '103': 32.228906688017744,
        '104': 32.228906688017744,
        '105': 32.228906688017744,
        '106': 32.228906688017744,
        '107': 32.228906688017744,
        '108': 32.228906688017744,
        '109': 32.228906688017744,
        '110': 32.228906688017744,
        '111': 32.228906688017744,
        '112': 32.228906688017744,
        '113': 32.228906688017744,
        '114': 32.228906688017744,
        '115': 32.228906688017744,
        '116': 32.228906688017744,
        '117': 32.228906688017744,
        '118': 32.228906688017744,
        '119': 32.228906688017744,
        '120': 32.228906688017744,
        '121': 32.228906688017744,
        '122': 32.228906688017744,
        '123': 32.228906688017744,
        '124': 32.228906688017744,
        '125': 32.228906688017744,
        '126': 32.228906688017744,
        '127': 32.228906688017744,
        '128': 32.228906688017744,
        '129': 32.228906688017744,
        '130': 32.228906688017744,
        '131': 32.228906688017744,
        '132': 32.228906688017744,
        '133': 32.228906688017744,
        '134': 32.228906688017744,
        '135': 32.228906688017744,
        '136': 32.228906688017744,
        '137': 32.228906688017744,
        '138': 32.228906688017744,
        '139': 32.228906688017744,
        '140': 32.228906688017744,
        '141': 32.228906688017744,
        '142': 32.228906688017744,
        '143': 32.228906688017744,
        '144': 32.228906688017744,
        '145': 32.228906688017744,
        '146': 32.228906688017744,
        '147': 32.228906688017744,
        '148': 32.228906688017744,
        '149': 32.228906688017744,
        '150': 32.228906688017744,
        '151': 32.228906688017744,
        '152': 32.228906688017744,
        '153': 32.228906688017744,
        '154': 32.228906688017744,
        '155': 32.228906688017744,
        '156': 32.228906688017744,
        '157': 32.228906688017744,
        '158': 32.228906688017744,
        '159': 32.228906688017744,
        '160': 32.228906688017744,
        '161': 32.228906688017744,
        '162': 32.228906688017744,
        '163': 32.228906688017744,
        '164': 32.228906688017744,
        '165': 32.228906688017744,
        '166': 32.228906688017744,
        '167': 32.228906688017744,
        '168': 32.228906688017744,
        '169': 32.228906688017744,
        '170': 32.228906688017744,
        '171': 32.228906688017744,
        '172': 32.228906688017744,
        '173': 32.228906688017744,
        '174': 32.228906688017744,
        '175': 32.228906688017744,
        '176': 32.228906688017744,
        '177': 32.228906688017744,
        '178': 32.228906688017744,
        '179': 32.228906688017744,
        '180': 32.228906688017744,
        '-180': 32.228906688017744,
        '-179': 32.228906688017744,
        '-178': 32.228906688017744,
        '-177': 32.228906688017744,
        '-176': 32.228906688017744,
        '-175': 32.228906688017744,
        '-174': 32.228906688017744,
        '-173': 32.228906688017744,
        '-172': 32.228906688017744,
        '-171': 32.228906688017744,
        '-170': 32.228906688017744,
        '-169': 32.228906688017744,
        '-168': 32.228906688017744,
        '-167': 32.228906688017744,
        '-166': 32.228906688017744,
        '-165': 32.228906688017744,
        '-164': 32.228906688017744,
        '-163': 32.228906688017744,
        '-162': 32.228906688017744,
        '-161': 32.228906688017744,
        '-160': 32.228906688017744,
        '-159': 32.228906688017744,
        '-158': 32.228906688017744,
        '-157': 32.228906688017744,
        '-156': 32.228906688017744,
        '-155': 32.228906688017744,
        '-154': 32.228906688017744,
        '-153': 32.228906688017744,
        '-152': 32.228906688017744,
        '-151': 32.228906688017744,
        '-150': 32.228906688017744,
        '-149': 32.228906688017744,
        '-148': 32.228906688017744,
        '-147': 32.228906688017744,
        '-146': 32.228906688017744,
        '-145': 32.228906688017744,
        '-144': 32.228906688017744,
        '-143': 32.228906688017744,
        '-142': 32.228906688017744,
        '-141': 32.228906688017744,
        '-140': 32.228906688017744,
        '-139': 32.228906688017744,
        '-138': 32.228906688017744,
        '-137': 32.228906688017744,
        '-136': 32.228906688017744,
        '-135': 32.228906688017744,
        '-134': 32.228906688017744,
        '-133': 32.228906688017744,
        '-132': 32.228906688017744,
        '-131': 32.228906688017744,
        '-130': 32.228906688017744,
        '-129': 32.228906688017744,
        '-128': 32.228906688017744,
        '-127': 32.228906688017744,
        '-126': 32.228906688017744,
        '-125': 32.228906688017744,
        '-124': 32.228906688017744,
        '-123': 32.228906688017744,
        '-122': 32.228906688017744,
        '-121': 32.228906688017744,
        '-120': 32.228906688017744,
        '-119': 32.228906688017744,
        '-118': 32.228906688017744,
        '-117': 32.228906688017744,
        '-116': 32.228906688017744,
        '-115': 32.228906688017744,
        '-114': 32.228906688017744,
        '-113': 32.228906688017744,
        '-112': 32.228906688017744,
        '-111': 32.228906688017744,
        '-110': 32.228906688017744,
        '-109': 32.228906688017744,
        '-108': 32.228906688017744,
        '-107': 32.228906688017744,
        '-106': 32.228906688017744,
        '-105': 32.228906688017744,
        '-104': 32.228906688017744,
        '-103': 32.228906688017744,
        '-102': 32.228906688017744,
        '-101': 32.228906688017744,
        '-100': 32.228906688017744,
        '-99': 32.228906688017744,
        '-98': 32.228906688017744,
        '-97': 32.228906688017744,
        '-96': 32.228906688017744,
        '-95': 32.228906688017744,
        '-94': 32.228906688017744,
        '-93': 32.228906688017744,
        '-92': 32.228906688017744,
        '-91': 32.228906688017744,
        '-90': 32.228906688017744,
        '-89': 32.228906688017744,
        '-88': 32.228906688017744,
        '-87': 32.228906688017744,
        '-86': 32.228906688017744,
        '-85': 32.228906688017744,
        '-84': 32.228906688017744,
        '-83': 32.228906688017744,
        '-82': 32.228906688017744,
        '-81': 32.228906688017744,
        '-80': 32.228906688017744,
        '-79': 32.228906688017744,
        '-78': 32.228906688017744,
        '-77': 32.228906688017744,
        '-76': 32.228906688017744,
        '-75': 32.228906688017744,
        '-74': 32.228906688017744,
        '-73': 32.228906688017744,
        '-72': 32.228906688017744,
        '-71': 32.228906688017744,
        '-70': 32.228906688017744,
        '-69': 32.228906688017744,
        '-68': 32.228906688017744,
        '-67': 32.228906688017744,
        '-66': 32.228906688017744,
        '-65': 32.228906688017744,
        '-64': 32.228906688017744,
        '-63': 32.228906688017744,
        '-62': 32.228906688017744,
        '-61': 32.228906688017744,
        '-60': 32.228906688017744,
        '-59': 32.228906688017744,
        '-58': 32.228906688017744,
        '-57': 32.228906688017744,
        '-56': 32.228906688017744,
        '-55': 32.228906688017744,
        '-54': 32.228906688017744,
        '-53': 32.228906688017744,
        '-52': 32.228906688017744,
        '-51': 32.228906688017744,
        '-50': 32.228906688017744,
        '-49': 32.228906688017744,
        '-48': 32.228906688017744,
        '-47': 32.228906688017744,
        '-46': 32.228906688017744,
        '-45': 32.228906688017744,
        '-44': 32.228906688017744,
        '-43': 32.228906688017744,
        '-42': 32.228906688017744,
        '-41': 32.228906688017744,
        '-40': 32.228906688017744,
        '-39': 32.228906688017744,
        '-38': 32.228906688017744,
        '-37': 32.228906688017744,
        '-36': 32.228906688017744,
        '-35': 32.228906688017744,
        '-34': 32.228906688017744,
        '-33': 32.228906688017744,
        '-32': 32.228906688017744,
        '-31': 32.228906688017744,
        '-30': 32.228906688017744,
        '-29': 32.228906688017744,
        '-28': 32.228906688017744,
        '-27': 32.228906688017744,
        '-26': 32.228906688017744,
        '-25': 32.228906688017744,
        '-24': 32.228906688017744,
        '-23': 32.228906688017744,
        '-22': 32.228906688017744,
        '-21': 32.228906688017744,
        '-20': 32.228906688017744,
        '-19': 32.228906688017744,
        '-18': 32.228906688017744,
        '-17': 32.228906688017744,
        '-16': 32.228906688017744,
        '-15': 32.228906688017744,
        '-14': 32.228906688017744,
        '-13': 32.228906688017744,
        '-12': 32.228906688017744,
        '-11': 32.228906688017744,
        '-10': 32.228906688017744,
        '-9': 32.228906688017744,
        '-8': 32.228906688017744,
        '-7': 32.228906688017744,
        '-6': 32.228906688017744,
        '-5': 32.228906688017744,
        '-4': 32.228906688017744,
        '-3': 32.228906688017744,
        '-2': 32.228906688017744,
        '-1': 32.228906688017744
    }
};
var degToRad = function (deg) { return (deg * Math.PI) / 180; };
function lookupGravity(lat, long) {
    lat = Math.round(lat);
    long = Math.round(long);
    if (table[lat] && table[lat][long]) {
        // TODO this should interpolate
        return table[lat][long];
    }
    return 32.174;
}
exports.lookupGravity = lookupGravity;
